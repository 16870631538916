import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";

import Simple from "../../components/Tickets";
import { getBetsState } from "../../store/selectors/betData";
import { ticketReservedListRequest } from "../../store/actions/tickets";

const useStyles = makeStyles({
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  }
});

const ReservedModule = ({ reserved, inProgress, ticketReservedListRequest, t }) => {
  const classes = useStyles();

  let reservedTickets = null;
  if (reserved.length > 0) {
    reservedTickets = reserved.map((ticket, i) => {
      if (!ticket.winnerFun) return null;
      return <Simple key={i} ticket={ticket} winnerFun={true} />;
    });
  }

  React.useEffect(() => {
    ticketReservedListRequest({ winnerFun: true });
  }, []); // eslint-disable-line

  return (
    <div>
      {inProgress && <div className={classes.empty}>
        <CircularProgress />
      </div>}
      {!inProgress && reserved.length === 0 && <div className={classes.empty}>{t("There are no reserved tickets")}</div>}
      {reservedTickets}
    </div>
  );
};

const mapStateToProps = state => {
  const bst = getBetsState(state);

  return {
    reserved: bst.tickets.ticketsReserved,
    inProgress: bst.tickets.requestInProgress.reserved
  };
};

const actionCreators = {
  ticketReservedListRequest,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(ReservedModule));
