import React from 'react';
import createSvgIcon from './createSvgIcon';

/**
 * @ignore - internal component.
 */
export default createSvgIcon(
  <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />,
  'KeyboardArrowLeft',
);
