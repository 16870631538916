/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 19/11/2018
 * Time: 13:22
 */
import React, {Component, Fragment} from 'react';
import ChatBubble from "./Parts/ChatBubble";
import ComplaintForm from "./Parts/ComplaintForm";

const COMPLAINT_FORM = 1;
const MENU_BUTTON = 2;

class LiveChatContainer extends Component {

    render() {


        switch (this.props.type) {
            case MENU_BUTTON:
                return ( <div id={'live-chat-menu-button'}>
                    {window.config && window.config.live_chat_left_menu_button_id_DESKTOP && <ChatBubble parentId={'live-chat-menu-button'} buttonId={window.config.live_chat_left_menu_button_id_DESKTOP}/>}
                </div> );
            case COMPLAINT_FORM:
                return (<ComplaintForm/>);
            default:
                window.config.live_chat_bubble_id_DESKTOP = 'f9cjga9w';
                return ( <div id={'live-chat'}>
                    {window.config && window.config.live_chat_bubble_id_DESKTOP &&  <ChatBubble parentId={'live-chat'}
                                        buttonId={window.config.live_chat_bubble_id_DESKTOP}
                                        // whitelist={[
                                        //     '/',
                                        //     '/winner-fun',
                                        //     '/cazino',
                                        //     '/pariuri',
                                        //     '/pariuri/live-match/highlights',
                                        //     '/lotto/home',
                                        //     '/virtuale',
                                        //     '/cazino_live',
                                        //     '/pariuri/prematch',
                                        //     '/pariuri/pre-match'
                                        // ]}
                                        blacklist={[
                                            '/withdraw'
                                        ]}
                                        dynamicPostion={true}
                                />}
                        </div> );
        }
    }
}

export default LiveChatContainer;
