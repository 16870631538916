import React from 'react';
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withTranslation } from 'react-i18next';


import "./breadcrumbs.scss";

import { getText, styleToObj, openItem } from "../../utils/functions";

const Breadcrumbs = (props) => {
  const { data, i18n, source } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  return (
    <div className={`component-breadcrumbs component`}
      style={styleToObj(data.style)}
    >
      <div className="do-scroll-if-necessary">
        <ul className="breadcrumbs-list">
          {items.length !== 0 && items.map((item, i) => {
            if (i === 0) {
              return <li className="cb-item first-element over" key={i}>
                <div
                  className="cb-back"
                  data-type={"breadcrumbs_back"}
                  data-item={JSON.stringify({
                    redirect_history_back: data && data.redirect_history_back ? data.redirect_history_back : false,
                    url: data && data.redirect_url ? data.redirect_url : false,
                  })}
                  onClick={openItem}
                >
                  <ArrowBackIosIcon />
                </div>
                <div className="txt">{getText(item, `data.title.text.${lang}`, "")}</div>
              </li>;
            }
            return <li key={i} className="cb-item regular-element"
              data-type={"navigate"}
              data-item={JSON.stringify({ url: item && item.data && item.data.url ? item.data.url : "" })}
              onClick={openItem}
            >{getText(item, `data.title.text.${lang}`, "")}&nbsp;&nbsp;</li>
          })}
          {items.length === 0 && <div>No data source</div>}
        </ul>
      </div>
      {data.has_description && <div className="cb-footer" style={styleToObj(data.description.style)}>
        {getText(data, `description.text.${lang}`, "")}
      </div>}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
  }
};

export default withTranslation()(connect(mapStateToProps)(Breadcrumbs));