import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";

import Page from "./Page";
import LottoHome from "../views/LottoHome";
import LottoDetails from "../views/LottoDetails";
import webStyle from "../utils/webStyle";
import BetsFull from "../components/BetSlip/LottoBetsFull";
import LeftNav from "../components/LeftNav";

const positionRelative = {
  position: "relative"
};

function LottoLayout(props) {
  const { lottoEvents, selectedSystem } = props;

  const classes = webStyle();
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const history = useHistory();

  const routes = (<React.Fragment>
    <Route exact path="/lotto/details/:systemId/:id" component={LottoDetails} />
    <Route exact path="/lotto/home">
      <LottoHome />
    </Route>
  </React.Fragment>);

  let cnt = null;
  if (isDesktop) {
    const tabsMenu = (<LeftNav />);
    cnt = (
      <div className={`${classes.wrapper} d-flex flex-row flex-norwap`}>
        <div className={classes.tabsDesktop}>{tabsMenu}</div>
        <div className={`${classes.cnt} ${classes.px}`}>
          <Switch>
            {routes}
          </Switch>
        </div>
        <div className={classes.betSlip}>
          <div>
            <BetsFull desktop={true} />
          </div>
        </div>
      </div>
    );

  } else {
    cnt = (
      <React.Fragment>
        <div style={positionRelative}>
          <Route
            render={({ location }) => {
              return (
                <Switch location={location}>
                  {routes}
                </Switch>
              );
            }}
          />
        </div>
      </React.Fragment>)
  }

  return (
    <Page doRender={[lottoEvents, selectedSystem, isDesktop, selectedSystem, history]}>
      {cnt}
    </Page>
  );
}

LottoLayout.propTypes = {
  lottoEvents: PropTypes.array,
};

LottoLayout.defaultProps = {
  lottoEvents: [],
};

export default withTranslation()(
  withRouter(LottoLayout)
);
