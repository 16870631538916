import { leaderBoardConstants } from "./constants";

export const loadLeaderBoard = ({ tournamentId }) => ({
  type: leaderBoardConstants.LOAD,
  tournamentId: tournamentId,
});

export const loadingLeaderBoard = () => ({
  type: leaderBoardConstants.LOADING
});

export const loadedLeaderBoard = ({ tournamentId, data }) => ({
  type: leaderBoardConstants.LOADED,
  tournamentId: tournamentId,
  data,
});

export const loadPlayerLeaderBoard = ({ tournamentId, inGame }) => ({
  type: leaderBoardConstants.LOAD_PLAYER,
  tournamentId: tournamentId,
  inGame: inGame
});

export const loadingPlayerLeaderBoard = () => ({
  type: leaderBoardConstants.LOADING_PLAYER
});

export const loadedPlayerLeaderBoard = ({ tournamentId, data }) => ({
  type: leaderBoardConstants.LOADED_PLAYER,
  tournamentId: tournamentId,
  data,
});

export const updatePlayerLeaderBoard = ({ tournamentId, data }) => ({
  type: leaderBoardConstants.LOADED_PLAYER,
  tournamentId: tournamentId,
  data,
});

