import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import isEqual from "lodash/fp/isEqual";

import { stdLiveScore } from "../../utils/matchUtils";
import { makeGetMatchStatus, makeGetStateMatch } from "../../store/selectors/betData";

import SimpleProgress from "../SimpleProgress/SimpleProgress";

const useStyles = makeStyles({
	eventProgress: {
		display: "flex",
		flexWrap: "nowrap",
		justifyContent: "space-around",
		alignItems: "center",
		alignContent: "center",
		margin: "4px 0 6px"
	},
});

const Intervals = props => {
	const classes = useStyles();
	const { match, matchStatus } = props;

	if (!match) return null;

	//console.log("intervals", match.idMatch);

	const scoreData = stdLiveScore(match, matchStatus);

	return (
		<div className={classes.eventProgress}>
			{scoreData.intervals.map((v, i) => {
				return <SimpleProgress key={i} value={v} />;
			})}
		</div>
	);
};

Intervals.propTypes = {
	dark: PropTypes.bool
};

Intervals.defaultProps = {
	dark: false
};

const getMatchStatus = makeGetMatchStatus();
const getStateMatch = makeGetStateMatch();
const mapStateToProps = (state, props) => ({
	match: getStateMatch(state, props),
	matchStatus: getMatchStatus(state, props)
});

export default connect(mapStateToProps)(Intervals);
