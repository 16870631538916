let lspGenerator = null;

export function LSPGenerator() {
  if (!lspGenerator) {
    try {
      if (window.config.cordova || process.env.NODE_ENV === "development") {
        lspGenerator = new window.STATSCORE.LSP.Generator({ authToken: '3389066021472304b2963054ffb2a75f' });
      } else {
        lspGenerator = new window.STATSCORE.LSP.Generator();
      }
    } catch (err) {
      console.log(err);
    }
  }
  return lspGenerator;
}