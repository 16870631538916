import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { NavLink, withRouter, Link } from 'react-router-dom';
import Icons from "../../../assets/Icons/Icons";
import * as stateActions from "../../../store/actions";
import i18next from "i18next";

class Menus extends Component {
    constructor(props) {
        super(props);

        this.urlPath = window.config.front_url;

        this.customerSupportItem = undefined;
        this.profileItem = undefined;

        this.menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');

        this.parent = "sideMenu";
    }

    setItems() {
        if (!(Object.keys(this.props.application.menu).length > 1)) {
            return false;
        }

        let menuItems = this.props.application.menu;

        if (!this.customerSupportItem) {
            this.customerSupportItem = menuItems["customer-support"];
        }

        if (!this.profileItem) {
            this.profileItem = menuItems["profile"];
        }

        return true;
    }

    getCustomerSupportLink(codeItem) {
        if (!codeItem) {
            return null;
        }

        if (!this.customerSupportItem) {
            if (!this.setItems()) {
                return null;
            }
        }

        let subpage = this.customerSupportItem.submenus.find((sItem) => {
            return codeItem === sItem.code;
        });

        if (!subpage) {
            return null;
        }

        let urlString = `/${this.customerSupportItem.code}/${subpage.code}`;

        let menuArrow = this.menuArrow;

        let butttonText = this.props.t(subpage.name);
        if ("data-protection" === codeItem) {
            butttonText = this.props.t("security of information");
        }
        if ("faq" === codeItem) {
            butttonText = this.props.t("FAQ");
        }
        if ("about-us" === codeItem) {
            butttonText = this.props.t("contact");
        }

        if (this.props.buttonText && this.props.buttonText.length > 0) {
            butttonText = this.props.buttonText;
        }

        // if (this.props.pageCode === 'terms&conditions') {
        //     urlString = window.config.cdn_address;
        // }

        if ("simple" === this.parent) {
            return (
                <Link to={urlString} onClick={this.csSubpageHandler.bind(this, subpage)}>
                    {this.props.t(butttonText)}
                </Link>
            );
        }

        if ("newPage" === this.parent) {
            return (
                <a href={urlString} target={"_blank"}>
                    {this.props.t(butttonText)}
                </a>
            );
        }

        return (
            <NavLink to={urlString} activeClassName="selected" className="row" onClick={this.csSubpageHandler.bind(this, subpage)}>
                <span className={this.iconSupportClassName()}> </span>
                <span className={this.textSupportClassName()}>{this.props.t(butttonText)}</span>
                <span className="col-2 arrow">{menuArrow}</span>
            </NavLink>
        );
    }

    getProfileLink(codeItem) {
        if (!codeItem) {
            return null;
        }

        if (!this.profileItem) {
            if (!this.setItems()) {
                return null;
            }
        }

        let subpage = this.profileItem.submenus.find((sItem) => {
            return codeItem === sItem.code;
        });

        if (!subpage) {
            return null;
        }

        let urlString = `/${this.profileItem.code}/${subpage.code}`;

        let menuArrow = this.menuArrow;

        let butttonText = this.props.t(subpage.name);
        if (this.props.buttonText && this.props.buttonText.length > 0) {
            butttonText = this.props.buttonText;
        }

        if ("simple" === this.parent) {
            return (
                <Link to={urlString} onClick={this.profileSubpageHandler.bind(this, subpage)}>
                    {this.props.t(butttonText)}
                </Link>
            );
        }

        if ("newPage" === this.parent) {
            return (
                <a href={urlString} target={"_blank"}>
                    {this.props.t(butttonText)}
                </a>
            );
        }

        return (
            <NavLink to={urlString} activeClassName="selected" className="row" onClick={this.profileSubpageHandler.bind(this, subpage)}>
                <span className={this.iconSupportClassName()}> </span>
                <span className={this.textSupportClassName()}>{this.props.t(butttonText)}</span>
                <span className="col-2 arrow">{menuArrow}</span>
            </NavLink>
        );
    }

    csSubpageHandler(subpage, event) {
        if (event) {
            event.nativeEvent.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
        }

        if (subpage.code === 'cookies') {
            return this.props.showCookiesInfo();

        }

        if (subpage.code === 'terms&conditions') {
            let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
            let win = window.open(url, '_blank');
            if(win !== null){
                win.focus();
            }
            return false;
        }

        let urlString = `/${this.customerSupportItem.code}/${subpage.code}`;

        this.props.history.push(urlString);

        if ("leftMenu" === this.parent) {
            this.props.hideLetfMenu();
        }

        this.props.onPageChanged(this.customerSupportItem);

        return false;
    }

    profileSubpageHandler(subpage, event) {
        if (event) {
            event.nativeEvent.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
        }

        let urlString = `/${this.profileItem.code}/${subpage.code}`;

        this.props.history.push(urlString);

        if ("leftMenu" === this.parent) {
            this.props.hideLetfMenu();
        }

        this.props.onPageChanged(this.profileItem);

        return false;
    }

    getResetPasswordLink() {
        if ("simple" === this.parent) {
            return (
                <Link to={"/reset-password"} onClick={this.forgotPasswordHandler.bind(this)}>
                    {this.props.t("reset-password")}
                </Link>
            );
        }

        if ("newPage" === this.parent) {
            return (
                <a href={"/reset-password"} target={"_blank"}>
                    {this.props.t("reset-password")}
                </a>
            );
        }

        return (
            <NavLink to={"/reset-password"} activeClassName="selected" className="row" onClick={this.forgotPasswordHandler.bind(this)}>
                <span className={this.iconSupportClassName()}> </span>
                <span className={this.textSupportClassName()}>{this.props.t("reset-password")}</span>
                <span className="col-2 arrow">{this.menuArrow}</span>
            </NavLink>
        );
    }

    forgotPasswordHandler(event) {
        if (event) {
            event.nativeEvent.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
        }

        this.props.onModalClosed();
        this.props.clearPage();

        this.props.history.push('/reset-password');

        if ("leftMenu" === this.parent) {
            this.props.hideLetfMenu();
        }

        return false;
    }

    iconSupportClassName() {
        let iconClass = ["icon"];

        switch (this.parent) {
            case "leftMenu":
                iconClass.push("col-2");
                break;
            default:
                iconClass.push("col-3");
                break;
        }

        return iconClass.join(" ");
    }

    textSupportClassName() {
        let textClass = ["ptext"];

        switch (this.parent) {
            case "leftMenu":
                textClass.push("col-8");
                break;
            default:
                textClass.push("col-7");
                break;
        }

        return textClass.join(" ");
    }

    render() {
        if (this.props.parent) {
            this.parent = this.props.parent;
        }

        let pageType = this.props.pageType;
        if (!pageType) {
            return null;
        }

        let pageCode = this.props.pageCode;

        if ("profile" === pageType) {
            return this.getProfileLink(pageCode);
        }

        if ("cs" === pageType) {
            return this.getCustomerSupportLink(pageCode);
        }

        if ("reset-password" === pageType) {
            return this.getResetPasswordLink();
        }

        return null;
    }
}

const mapStateToProps = state => {
    return {
        application: state.application
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onModalClosed: () => dispatch(stateActions.resetModal()),
        clearPage: () => dispatch(stateActions.clearMenuPage()),
        hideLetfMenu: () => dispatch(stateActions.closeLeftMenu()),
        showCookiesInfo: () => dispatch(stateActions.toggleCookiesInfo()),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Menus)));
