import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Icons from "./../../../../../../assets/Icons/Icons";
import "./Pending.scss";
import ValueCurrency, { NO_SMALL_FORMAT } from "../../../Wallet/Parts/ValueCurrency";
// import * as actions from "./../../../../../../store/actions/index";
import { Button, ButtonGroup, Popover, PopoverBody } from "reactstrap";
import * as stateActions from "../../../../../../store/actions";
import Utils from "../../../../../../utils/Utils";
import moment from "moment";

class Pending extends Component {
    constructor(props) {
        super(props);

        let ttStates = [];
        this.props.withdrawals.pendingRequests.forEach((elm, index) => {
            ttStates.push(false);
        });

        this.state = {
            tooltipsState: {
                ...ttStates,
            },
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.withdrawals.pendingRequests !== this.props.withdrawals.pendingRequests) {
            Utils.delayedFunction('requestTaxes',this.requestTaxes.bind(this),0);
        }
    }

    requestTaxes() {

        this.props.withdrawals.pendingRequests.forEach((withdraw) => {
            if (typeof this.props.taxes[withdraw.transaction_id] === "undefined") {
                this.props.requestTaxService(1,withdraw.amount, withdraw.transaction_id);
            }
        })
    }

    cancelHandler(request, evt) {
        // console.log(request);
        // this.props.cancelAmount(request.id, request.amount);
        this.props.openModal(request);
    }

    partialHandler(request, evt) {
        // console.log(request);
        this.props.openPartialModal(request);
    }

    getList() {
        if (!(this.props.withdrawals.pendingRequests.length > 0)) {
            return (
                <li>
                    <div className={"notAvailable"}>
                        <p>{this.props.t("List is empty for the moment.")}</p>
                    </div>
                </li>
            );
        }

        let list = [];
        let locations = this.props.withdrawals.locations;
        let infoBtnIcon = Icons.get("questionmarkCircle", "btnInfo questionmark-icon");

        this.props.withdrawals.pendingRequests.forEach((withdraw, index) => {
            let location = false;
            if (withdraw.src === 'pending-withdrawals') {

                location = locations.find((loc) => {
                    if (!loc) {
                        return false;
                    }
                    if (typeof loc == "undefined" || typeof withdraw == "undefined") {
                        return false;
                    }
                    return loc.id === withdraw.location_id;
                });
            }

            // if (!location) {
            //     return false;
            // }
            let buttons = [];

            if (location || withdraw.src === 'pending-viva-withdrawals') {
                buttons.push(<div className={"buttons"} key={`${index}-pending-buttons-${withdraw.id}`}>
                    <div className={"buttonPlace"}>
                        <Button outline color={"danger"} onClick={this.cancelHandler.bind(this, withdraw)} className={"cancelButton"}>{this.props.t("Cancel")}</Button>
                    </div>
                    <div className={"buttonPlace"}>
                        <ButtonGroup>
                            <Button color={"success"} className={"wbtn actionBtn"} onClick={this.partialHandler.bind(this, withdraw)}>{this.props.t("Fast deposit")}</Button>
                            <Button color={"success"} className={`wbtn infoBtn info-tooltip-${index}`} id={`info-tooltip-${index}`} onClick={this.toggleInfoHandler.bind(this, true, index)}>{infoBtnIcon}</Button>
                        </ButtonGroup>
                        {this.tooltipContent(index)}
                    </div>
                </div>)
            }

            let item = (
                <li key={`${index}-pending-${withdraw.id}`}>
                    <div className={"amount"}>
                        <ValueCurrency value={withdraw.amount} currency={this.props.wallet.currency} type={NO_SMALL_FORMAT} />
                        <small className="tax">
                            (Tax:&nbsp;
                                {typeof this.props.taxes[withdraw.transaction_id] !== 'undefined' &&
                                <span>-<ValueCurrency value={this.props.taxes[withdraw.transaction_id]} currency={this.props.wallet.currency} type={NO_SMALL_FORMAT}/></span>}
                                {typeof this.props.taxes[withdraw.transaction_id] === 'undefined' && <span>{this.props.t('calculating...')}</span>}
                            )
                        </small>
                    </div>
                    <div className="date">{moment.unix(withdraw.date).format("DD / MM / YYYY")}</div>
                    <div className={'clearfix'}></div>
                    <div className={"method"}>{location ? location.formattedAddress : 'Online'}</div>
                    {buttons}
                </li>
            );

            list.push(item);
        });

        return list;
    }

    tooltipContent(index) {
        let tooltipState = this.state.tooltipsState[index];

        return (
            <Popover placement="right" isOpen={tooltipState} target={`info-tooltip-${index}`} className="pendingPopover">
                <PopoverBody className="pendingBody">
                    <div className="warning-close-support">
                        <button type="button" className="close close-button" data-dismiss="modal" aria-label="Close" onClick={this.toggleInfoHandler.bind(this, false, index)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <h2>{this.props.t("")}</h2>
                    <p>{this.props.t("You can release part of the blocked funds in order to play the game, without delaying the withdraw operation.")}</p>
                </PopoverBody>
            </Popover>
        );
    }

    toggleInfoHandler(status, index) {
        let states = this.state.tooltipsState;

        states[index] = status;

        this.setState({
            ...this.state,
            tooltipsState: states
        });
    }

    render() {
        if (!this.props.withdrawals.pendingRequests) {
            return <Fragment />
        }

        if (!(this.props.withdrawals.pendingRequests.length > 0)) {
            return <Fragment />
        }

        let pendingIcon = Icons.get("wPending", "pending-requests gray-icon-common");

        return (
            <li className={"pending-support"}>
                <div className={"pending-section wsection"}>
                    <div className={"head-area"}>
                        <div className={"icon"}>{pendingIcon}</div>
                        <div className={"texts-support"}>
                            <div className={"title"}>{this.props.t("Withdrawals in pending")}</div>
                            <div className={"subtitle"}>{this.props.t("Your requests")}</div>
                        </div>
                    </div>
                    <div className={"list-area"}>
                        <ul className={"wlist"}>
                            {this.getList()}
                        </ul>
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
        taxes: state.transactions.taxes
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // cancelAmount: (id, amount) => dispatch(stateActions.requestCancelWithdrawal(id, amount)),
        openModal: (element) => dispatch(stateActions.cancelWithdrawalModal(true, element)),
        openPartialModal: (element) => dispatch(stateActions.partialWithdrawalModal(true, element)),
        requestTaxService: (type, amount,id) => dispatch(stateActions.requestTaxService(type, amount,id)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Pending));