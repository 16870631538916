import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./content_slider.scss";

import { getText, getStyle, styleToObj, openItem, checkItemAvailability, getIndexById } from "../../utils/functions";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const ContentSlider = (props) => {
  const { data, i18n, source } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, source]);

  if (items.length === 0) return null;

  const filteredItems = items.filter(item => (checkItemAvailability(item)));
  if (filteredItems.length === 0) return null;

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`component-content-slider component ${data.hide_margin_shadows ? 'hide-margins' : ''}`} style={styleToObj(data.style)}>
      <div className="before" style={data.before ? styleToObj(data.before.style) : empty}></div>
      <div className="cgl-header">
        <div className="title"
          style={styleToObj(data.title.style)}
        >
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          {filteredItems.length !== 0 && <span>({filteredItems.length})</span>}
          <LobbyArrow />
        </div>}
      </div>

      <div className="cgl-list" style={styleToObj(data.data_source_style)}>
        <div className={`cgl-grid ${data.thumbnail_size === "large" ? "large" : ""}`}>
          {filteredItems.slice(0, 5).map((item, i) => {

            const realIndex = getIndexById(items, item.id);

            let itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;

            if (data.card_width) {
              itemStyle = Object.assign({ minWidth: `${data.card_width}px` }, itemStyle);
            }

            let outerStyle = empty;

            if (data.card_height) {
              outerStyle = { paddingTop: `${data.card_height}%` };
            }

            return <div className={`cgl-item ${item.isEmpty ? "empty" : ""}`}
              key={i}
              style={itemStyle}
              data-type={"navigate"}
              data-item={JSON.stringify({ url: item && item.data && item.data.url ? item.data.url : "" })}
              onClick={openItem}
            >
              <div className="outer" style={outerStyle}>
                <div className="inner">
                  {!!item.data.thumbnail && <img src={item.data.thumbnail} alt="" />}
                  <div className="item-text-wrapper" style={styleToObj(getStyle(data, `data_source_items[${realIndex}].text_container.style`, empty))}>
                    <div className="item-text" style={styleToObj(getStyle(data, `data_source_items[${realIndex}].title.style`, empty))}>
                      {getText(item, `data.title.text.${lang}`, "")}
                    </div>
                    <div className="item-subtext" style={styleToObj(getStyle(data, `data_source_items[${realIndex}].subtitle.style`, empty))}>
                      {getText(item, `data.subtitle.text.${lang}`, "")}
                    </div>
                  </div>
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>

      {data.show_footer && <div className="cgl-footer" style={styleToObj(data.footer.style)}>
        {getText(data, `footer.text.${lang}`, "")}
      </div>}

      <div className="after" style={data.after ? styleToObj(data.after.style) : empty}></div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
  }
};

export default withTranslation()(connect(mapStateToProps)(ContentSlider));