import * as actionTypes from '../actions/actionTypes';

const initialState = {
    width: 0,
    height: 0,
    spineAnimation: false,
    hide: true,
    default: {
        imgPath: "/img/background-banner_res.png",
        loaderPath: "/img/dual_ring200px.gif",
        style: {
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat',
        }
    },
    overwrite: {
        imgPath: false,
        loaderPath: false,
        style: false,
    },
    showMessage: false,
    bannerType: 'spine',

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.mainBanner.RESIZE:
            return {
                ...state,
                width: action.width,
                height: action.height,
            };

        case actionTypes.mainBanner.SPINE_ANIMATION:
            return {
                ...state,
                spineAnimation: action.status
            };
        case actionTypes.mainBanner.HIDE:
            return {
                ...state,
                hide: true,
            };
        case actionTypes.mainBanner.SHOW:
            return {
                ...state,
                hide: false,
                bannerType: (action.mbType) ? action.bType : state.bannerType,
            };
        case actionTypes.mainBanner.SHOW_MESSAGE:
            return {
                ...state,
                showMessage: true,
            };
        case actionTypes.mainBanner.CHANGE:
            if (typeof action.overwrite !== "undefined") {
                let overwrite = {...state.overwrite};

                Object.keys(overwrite).forEach((key) => {
                    overwrite[key] = typeof action.overwrite[key] === "undefined" ? false : action.overwrite[key];
                });

                return {
                    ...state,
                    overwrite: overwrite
                };
            } else {
                return state;
            }
        case actionTypes.mainBanner.CHANGE_BANNER_TYPE:
            return {
                ...state,
                bannerType: action.bannerType,
            };
        default:
            return state;
    }
};

export default reducer;