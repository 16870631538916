import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import moment from "moment";

import Logo from "./assets/winner-fun.png";
import { getWinnerFunState } from "../../store/selectors/betData";

const useStyles = makeStyles({
  root: {
    height: "42px",
    width: "50px",
    backgroundColor: "rgba(0,0,0,0.4)",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    margin: "0 0 0 .25rem",
    justifyContent: "center",
  },
  bilete: {
    color: "#fff",
    marginLeft: "3px",
    width: "38px"
  },
  anchorOriginBottomLeftRectangular: {
    transform: "scale(1) translate(-50%, 0)",
    left: "0",
    bottom: "0",
    transformOrigin: "0% 100%"
  },
  badge: {
    height: "15px",
    display: "flex",
    padding: "0 3px",
    zIndex: "1",
    position: "absolute",
    flexWrap: "wrap",
    fontSize: "0.65rem",
    minWidth: "15px",
    boxSizing: "border-box",
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    fontWeight: "500",
    lineHeight: "1",
    alignContent: "center",
    borderRadius: "10px",
    flexDirection: "row",
    justifyContent: "center",
    width: "15px",
    bottom: "-3px",
    right: "-2px",
    "&.badge-mobile": {
      bottom: "auto",
      top: "5px",
      right: "0px",
    }
  },
  isMobile: {
    backgroundColor: "transparent",
    height: "45px",
    position: "relative",
    top: "-3px",
    width: "40px",
    "&>button": {
      paddingBottom: "2px!important",
    }
  },
  block: {
    display: "block",
    height: "27px",
    width: "auto"
  }
});

const WFSeasonWidget = (props) => {
  const classes = useStyles();
  const { winCoinsSystems, mobile, seasons, onClick, t } = props;

  let hasEvent = false;
  const now = moment().valueOf();
  seasons.forEach((season, i) => {
    if (parseInt(season.endDate) < now) return;
    season.events.forEach((event) => {
      if (parseInt(event.endDate) < now) return;
      if (now < parseInt(event.startDate)) return;
      hasEvent = true;
    });
  });

  let canCollect = 0;

  winCoinsSystems.data.forEach((system, i) => {
    if (system && system.type === "boost") {
      if (hasEvent) {
        const dt = moment(system.next_boost_time, "x");
        const dtNow = moment();
        if (dt.isBefore(dtNow) || system.next_boost_time === 0) {
          if (system.coin_system.prizes !== system.boost) { // already expired
            canCollect += 1;
          }
        }
      }
    } else if (system.amount !== 0) {
      canCollect += 1;
    }
  });

  return (<div className={`${classes.root} ${mobile ? classes.isMobile : ''} wf-season-widget`}>
    <ButtonBase className={classes.bilete} onClick={onClick}>
      <div className="wf-season-widget-wrapper">
        <div className={canCollect !== 0 ? "pulse_anim wf-season-widget-logo" : "wf-season-widget-logo"}>
          <Badge
            classes={{
              badge: `${classes.badge} ${mobile ? 'badge-mobile' : ''}`,
              anchorOriginBottomLeftRectangular: classes.anchorOriginBottomLeftRectangular
            }}
            color="secondary"
            badgeContent={canCollect}
            anchorOrigin={{
              horizontal: mobile ? "right" : "left",
              vertical: mobile ? "top" : "bottom"
            }}
            className={classes.margin}
          >
            <img src={Logo} alt="" className={classes.block} />
          </Badge>
        </div>
        {mobile && <div className="wf-season-widget-text">{t("Season")}</div>}
      </div>
    </ButtonBase>
  </div>);
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);


  return {
    seasons: wfs.config && wfs.config.seasons ? wfs.config.seasons : [],
    winCoinsSystems: wfs.winCoinsSystems,
  }
};

export default withTranslation()(connect(mapStateToProps)(WFSeasonWidget));