import * as actionTypes from '../actions/actionTypes';

const initialState = {
    show: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.spentTime.TOGGLE_DISPLAY:
            return {
                ...state,
                show: action.onScreen,
            };
        default:
            return state;
    }
};

export default reducer;
