import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ModalHeader from "../modal-header";
import { formatBetTitle } from "../../utils/formatters";
import { ReactComponent as SearchIcon } from "./assets/search.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0",
      borderRadius: "0",
    }
  },
  rootFilters: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0"
    },
    "&.MuiDialog-root": {
      zIndex: "1303!important"
    }
  },
  minHeight: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "1024px"
    }
  },
  minHeightSmall: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "612px"
    }
  },
  search: {
    backgroundColor: "#fff",
    padding: "8px 23px",
    height: "46px",
    position: "relative",
    borderBottom: "1px solid #EDF0F0",
    display: "flex",
    alignItems: "center",
    /*
    "&::after": {
      content: '""',
      position: "absolute",
      zIndex: "-1",
      boxShadow: "0 0 40px rgba(0,0,0,0.8)",
      bottom: "0px",
      width: "80%",
      height: "50%",
      borderRadius: "100%",
      left: "10%",
      right: "10%",
    }
    */
  },
  searchInput: {
    width: "calc(100% - 25px)",
    fontSize: "20px",
    color: "#000",
    border: "0",
    backgroundColor: "#fff"
  },
  searchIcon: {
    verticalAlign: "middle",
    "& path": {
      fill: "#2545BD!important"
    }
  },
  content: {
    height: "calc(100% - 102px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "0",
    scrollbarColor: "var(--ns-scrollbar-thumb-bg) var(--ns-scrollbar-track-bg)",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-track": {
      background: "var(--ns-scrollbar-track-bg)",
      borderRadius: "0px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "var(--ns-scrollbar-thumb-bg)",
      borderRadius: "3px"
    }
  },
  dialogItem: {
    cursor: "pointer",
    backgroundColor: "#F9F9FB",
    padding: "16px 26px",
    borderBottom: "1px solid #EDF0F0",
    "&:active": {
      backgroundColor: "#F9F9FB",
      position: "relative",
      top: "3px"
    }
  },
  title: {
    fontSize: "14px",
    color: "#313131",
    fontWeight: "600",
    textTransform: "uppercase"
  },
  subtitle: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "600"
  },
  dialogItemActive: {
    backgroundColor: "#008A41",
    "& .title": {
      color: "#fff",
      "& .bullet": {
        backgroundColor: "#c6f5f0",
        color: "#457e78",
      }
    },
    "& .subtitle": {
      color: "#fff"
    }
  },
  betsWrapper: {
    backgroundColor: "#fff",
    borderRadius: "13px",
    "& > div:first-child": {
      //borderTopLeftRadius: "13px",
      //borderTopRightRadius: "13px"
    },
    "& > div:last-child": {
      //borderBottomLeftRadius: "13px",
      //borderBottomRightRadius: "13px",
      borderBottomWidth: "0"
    }
  },
  header: {
    borderLeft: "3px solid #F8991C",
    padding: "5px 10px",
    fontSize: "14px",
    color: "#E8E8E8",
    fontWeight: "600",
    background: "#7E8080"
  },
  probe: {
    width: "1px",
    height: "1px",
  },
  dropShadow: {
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
  },
  bullet: {
    borderRadius: "50%",
    backgroundColor: "#EDF0F0",
    color: "#666",
    fontSize: "10px",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "0 0 auto",
    marginLeft: "10px"
  },
  mt: {
    marginTop: "20px"
  },
  pb: {
    paddingBottom: "17px"
  }
});

const BetSearch = props => {
  const classes = useStyles();
  const { open, markets, selectedBetId, match, bets, handleSelect, t } = props;

  //console.log("BetSearch props", props);

  const isDesktop = useMediaQuery("(min-width:1024px)");
  const fullScreen = isDesktop ? false : true;

  const [localBets, setLocalBets] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(open);
  const [filter, setFilter] = React.useState("");
  const [dropShadow, setDropShadow] = React.useState(false);
  const probe = React.createRef();

  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);
  React.useEffect(() => {
    const tmp = markets.map(([midBet, m], index) => {
      return {
        idBet: midBet,
        title: formatBetTitle(m.bet, match, bets),
        count: m.count,
        name: m.name,
        class: `${classes.dialogItem} ${selectedBetId === midBet ? classes.dialogItemActive : ""}`,
        index
      };
    });
    setLocalBets(tmp);
  }, [markets, selectedBetId, match, bets, classes]);

  const fireEvent = (stuck) => {
    setDropShadow(oldVal => {
      if (stuck !== oldVal) {
        return stuck;
      }
      return oldVal;
    });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (probe && probe.current !== null) {
        const observer = new IntersectionObserver(
          (records, observer) => {
            for (const record of records) {
              const targetInfo = record.boundingClientRect;
              const rootBoundsInfo = record.rootBounds;

              if (targetInfo && rootBoundsInfo) {

                // Started sticking.
                if (targetInfo.bottom < rootBoundsInfo.top) {
                  fireEvent(true);
                }

                // Stopped sticking.
                if (
                  targetInfo.bottom >= rootBoundsInfo.top &&
                  targetInfo.bottom < rootBoundsInfo.bottom
                ) {
                  fireEvent(false);
                }
              }
            }
          },
          { threshold: [0], root: probe.current.parentElement }
        );
        observer.observe(probe.current); // attach observer to our probe
      }
    }

    return () => {
      mounted = false;
    }
  }, [probe]); //eslint-disable-line

  if (!open) return null;

  const renderBets = localBets.filter(mb => {
    if (mb.title.toLowerCase().indexOf(filter.toLocaleLowerCase()) > -1) {
      return true;
    }
    return false;
  });

  const handleClose = () => {
    handleSelect(-1);
  };

  const filterChange = e => {
    setFilter(e.target.value);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      disableEnforceFocus
      open={openDialog}
      TransitionComponent={Transition}
      maxWidth={!fullScreen ? "lg" : false}
      className={`${classes.root} ${isDesktop ? classes.minHeight : ""} betsModal`}
      onClose={handleClose}
    >
      <ModalHeader title={t("Search Bets")} type="none" goBack={handleClose} />
      <div className={`${classes.search} ${dropShadow ? classes.dropShadow : ''}`}>
        <input
          onChange={filterChange}
          value={filter}
          placeholder={t('search "final" or "goluri"')}
          type="text"
          className={classes.searchInput}
        />
        <SearchIcon className={classes.searchIcon} />
      </div>
      <div className={classes.content}>
        <div className={classes.probe} ref={probe}></div>
        <div className={`${classes.header} ${classes.mt}`}>{t("Recommended")}</div>
        <div className={classes.betsWrapper}>
          {renderBets.slice(0, 5).map(mb => (
            <div key={mb.index} className={`${mb.class} ${classes.pb}`} onClick={() => handleSelect(mb.index)}>
              <div className={`${classes.title} title d-flex flex-row flex-nowrap align-items-center justify-content-between`}>
                <div>{mb.title}</div>
                {mb.count > 1 && <div className={`${classes.bullet} bullet`}>{mb.count}</div>}
              </div>
            </div>
          ))}
        </div>

        {renderBets.slice(5).length > 0 && (
          <React.Fragment>
            <div className={`${classes.header} ${classes.mt}`}>{t("All")}</div>
            <div className={classes.betsWrapper}>
              {renderBets.slice(5).map(mb => (
                <div key={mb.index} className={mb.class} onClick={() => handleSelect(mb.index)}>
                  <div className={`${classes.title} title`}>{mb.title}</div>
                  <div className={`${classes.subtitle} subtitle`}>
                    {mb.count > 1 ? t("common to {{COUNT}} events", { COUNT: mb.count }) : mb.name}
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    </Dialog>
  );
};

export default withTranslation()(BetSearch);
