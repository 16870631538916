import * as actionTypes from './actionTypes';

export const freeBetsLoaded = (freeBets) => ({
  type: actionTypes.freeBets.FREE_BETS_LOADED,
  freeBets: freeBets
});
export const freeBetsFetch = () => ({
  type: actionTypes.freeBets.FREE_BETS_FETCH
});

export const freeBetsFetchFilters = (free_bets) => ({
  type: actionTypes.freeBets.FREE_BETS_FETCH_FILTERS,
  free_bets
});


export const freeBetRemove = (bonusCode) => ({
  type: actionTypes.freeBets.FREE_BET_REMOVE,
  code: bonusCode
});

export const freeBetsFetchHistory = (page) => ({
  type: actionTypes.freeBets.FREE_BETS_FETCH_HISTORY,
  page: page
});

export const freeBetsLoadedHistory = (data) => ({
  type: actionTypes.freeBets.FREE_BETS_SET_HISTORY,
  items: data.items,
  page: data.page,
});

export const freeBetsFiltersLoaded = (freeBetsFilters) => ({
  type: actionTypes.freeBets.FREE_BETS_FILTERS_LOADED,
  freeBetsFilters: freeBetsFilters
});

export const freeBetsFiltersUpdate = (freeBetsFilters) => ({
  type: actionTypes.freeBets.FREE_BETS_FILTERS_UPDATE,
  freeBetsFilters: freeBetsFilters
});