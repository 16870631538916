import { favNumbersConstants } from "./constants";

export const fetchFavNumbers = () => ({
  type: favNumbersConstants.FETCH_FAV_NUMBERS,
});

export const loadFavNumbers = items => ({
  type: favNumbersConstants.LOAD_FAV_NUMBERS,
  items
});

export const setFavNumbers = (name, numbers) => ({
  type: favNumbersConstants.SET_FAV_NUMBERS,
  name,
  numbers
});

// delete fav numbers from NAMEed lottery system list using the index
export const deleteFavNumbers = (name, index) => ({
  type: favNumbersConstants.DEL_FAV_NUMBERS,
  name,
  index
});


