import React, {Component, Fragment} from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from "react-redux";
import Icons from "../../../../../../assets/Icons/Icons";
import './PlayerPromoTickets.scss';
import BackendClient from "../../../../../../BackendClient";

const WEEK_IN_MS = 604800000;
const DAY_IN_MS = 86400000;
const DAY_MONDAY = 1;

class PlayerPromoTickets extends Component {

    componentDidMount() {
        const axios = BackendClient.getInstance();
        
        axios({
            url: process.env.CFW.casinoApiUrl + '/player/happy-hours-awards/list',
            method: 'post',
            data: {
                startTime: parseInt(this.getDateThisMonday()/1000).toString(),
                endTime: parseInt(new Date().getTime()/1000).toString()
            }
        }).catch(e => {
            console.log(`[CATCHED ERROR] => ${e}`);
        });
    }

    getDateThisMonday() {

        // start with today's midnight
        let today = new Date();
        today.setHours(0, 0, 0);

        // We start the week with Monday, we assign 7 when its a Sunday (0)
        let dayToday = today.getDay() || 7;

        // Calculate milliseconds elapsed since Monday
        let msSinceMonday = ((dayToday - DAY_MONDAY) * DAY_IN_MS);

        // Subtract today midnight with the diff to get midnight of the Monday
        let mondayTime = today.getTime() - msSinceMonday;

        return mondayTime;
    }


    render() {

        return (
            <div className={'PlayerPromoTickets'}>
                <div className="title">
                    {this.props.t("Biletele tale deja in urna")}
                </div>
                <div className="ticketsNumber">
                    {Icons.get('preeurotickets') }
                    <div className={'count'}>{this.props.preEuroTickets}</div>
                    <div className="desc">in urna</div>
                </div>
            </div>
        );
    }
};
const mapStateToProps = state => {
    return {
        preEuroTickets: state.application.preEuroTickets
    }
};

export default withTranslation()(connect(mapStateToProps, null)(PlayerPromoTickets));
