import React from 'react';
import PropTypes from 'prop-types';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import "./index.scss";
import { useScrollBoost } from "../ScrollBooster";

const Scroller = (props) => {
  const { className, settings, children } = props;

  const rootRef = React.useRef(null);

  const [viewport, scrollBoosterState] = useScrollBoost({
    direction: 'horizontal',
    scrollMode: 'native',
    pointerMode: 'mouse',
    forceNoBounce: true,
    emulateScroll: true,
    avoidClassNames: ["myBetElement"],
    ...settings
  });

  const handleClick = (direction) => () => {
    if (direction === "left") {
      if (rootRef && rootRef.current) {
        let scrollLeft = rootRef.current.children[0].scrollLeft

        scrollBoosterState.scrollTo({
          x: scrollLeft - 370,
          y: 0
        });
      }
    } else {
      if (rootRef && rootRef.current) {
        let scrollLeft = rootRef.current.children[0].scrollLeft

        scrollBoosterState.scrollTo({
          x: scrollLeft + 370,
          y: 0
        });
      }
    }
  }

  return (
    <div className={`drag-scroller`} ref={rootRef}>
      <div className={`${className}`} ref={viewport}>
        {children}
      </div>
      <div className="scroller-arrow arrow-left" onClick={handleClick("left")}><ArrowLeftIcon /></div>
      <div className="scroller-arrow arrow-right" onClick={handleClick("right")}><ArrowRightIcon /></div>
    </div>
  );
}

Scroller.propTypes = {
  className: PropTypes.string,
  settings: PropTypes.object,
};

Scroller.defaultProps = {
  className: "",
  settings: {}
};

export default Scroller;