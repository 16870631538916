import React, { Component } from 'react';
import { connect } from 'react-redux';
import Game from './../Game/Game';
import Icons from '../../../assets/Icons/Icons';
import "./HotEarnings.scss";
import { withTranslation } from "react-i18next";

class HotEarnings extends Component {
    constructor(props) {
        super(props);

        this.limit = 6;
    }

    render() {
        if (!(this.props.games.hotGames.length > 0)) {
            return null;
        }

        let hotIcon = Icons.get('fire', 'fire-red');

        let stateHotGames = this.props.games.hotGames;
        let hotGames = [];
        stateHotGames.every((element, index) => {
            if (index + 1 > this.limit) {
                return false;
            }

            hotGames.push(<Game key={"hot_" + element.id} gameItem={element} section="hotGames" />);
            return true;
        });

        return (
            <div className="HotEarnings">
                <h1>
                    <span className="icon">{hotIcon}</span>
                    <span className="text">{this.props.t("hot earnings")}</span>
                    <div style={{
                        clear: "both",
                    }}></div>
                </h1>
                <div className="GamesCards">{hotGames}</div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        games: state.games,
    }
};

export default withTranslation()(connect(mapStateToProps)(HotEarnings));