import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withResizeDetector } from "react-resize-detector";
import Slider from "react-slick";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";

//import Img2 from "../../../assets/Footbal_Men_Two_Legs_492484_25_A2_Rectangle_80_pattern@2x.png";

import { getBetsState } from "../../../store/selectors/betData";

import Img1 from "../../../assets/banners/carousel1.jpg";
import Img2 from "../../../assets/banners/carousel2.jpg";
import Img3 from "../../../assets/banners/carousel3.jpg";

import BetBanner from "../components/BetBanner";

const useStyles = makeStyles({
  root: {
    width: "calc(100% + 28px)",
    margin: "0 -14px 10px -14px",
    padding: "0 14px",
    color: "#fff",
    overflow: "hidden",
    position: "relative"
  },
  ovVisible: {
    "& .slick-list": {
      overflow: "visible"
    }
  },
  card: {
    width: "340px",
    height: "160px",
    padding: "0 5px",
    overflow: "visible",
    marginBottom: "5px",
    "& .first": {
      width: "100%",
      height: "160px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: "10px",
      boxShadow: "0px 3px 5px 0px rgba(0,0,0,0.5)"
    },
    "& .second": {
      width: "100%",
      height: "160px"
    }
  },
  winner: {
    textAlign: "right",
    margin: "8px 9px 8px 0",
    //background: "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)",
    borderRadius: "5px",
    fontSize: "10px",
    fontWeight: 600,
    padding: "3px 12px",
    textTransform: "uppercase"
  },
  betWrapper: {
    paddingBottom: "5px",
    width: "100%",
    background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.6) 100%)",
    borderRadius: "0 0 10px 10px"
  },
  noMargin: {
    margin: "0!important"
  },
  arrow: {
    position: "absolute",
    top: "calc(50% - 10px)",
    cursor: "pointer",
    color: "#fff",
    backgroundColor: "#ccc",
    padding: "3px 0 0",
    borderRadius: "50%",
    textAlign: "center",
    width: "30px",
    height: "30px",
    "&:hover": {
      backgroundColor: "#aaa"
    }
  },
  arrowLeft: {
    left: "0"
  },
  arrowRight: {
    right: "0"
  },
  ovHidden: {
    overflow: "hidden"
  }
});

let host = process.env.CFW.betsBannersImagesUrl;
if (host.substr(-1) !== "/") host += "/";

const configDef = [
  {
    image: {
      desktop: "",
      mobile: ""
    },
    hasMatch: true,
    link: ""
  },
  {
    image: {
      desktop: "",
      mobile: ""
    },
    hasMatch: true,
    link: ""
  },
  {
    image: {
      desktop: "",
      mobile: ""
    },
    hasMatch: true,
    link: ""
  }
];

const PromotionsWinner = props => {
  const classes = useStyles();
  const { config, matches, width } = props;

  const history = useHistory();
  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  let match_carousel = configDef;
  if (config && typeof config["match_carousel"] !== "undefined") {
    match_carousel = config["match_carousel"];
  }

  const bgStyle1 = { backgroundImage: `url(${Img1})` };
  const bgStyle2 = { backgroundImage: `url(${Img2})` };
  const bgStyle3 = { backgroundImage: `url(${Img3})` };
  if (isDesktop1024) {
    if (match_carousel[0].image.desktop) {
      bgStyle1.backgroundImage = `url('${host}${match_carousel[0].image.desktop}')`;
    }
    if (match_carousel[1].image.desktop) {
      bgStyle2.backgroundImage = `url('${host}${match_carousel[1].image.desktop}')`;
    }
    if (match_carousel[2].image.desktop) {
      bgStyle3.backgroundImage = `url('${host}${match_carousel[2].image.desktop}')`;
    }
  } else {
    if (match_carousel[0].image.mobile) {
      bgStyle1.backgroundImage = `url('${host}${match_carousel[0].image.mobile}')`;
    }
    if (match_carousel[1].image.mobile) {
      bgStyle2.backgroundImage = `url('${host}${match_carousel[1].image.mobile}')`;
    }
    if (match_carousel[2].image.mobile) {
      bgStyle3.backgroundImage = `url('${host}${match_carousel[2].image.mobile}')`;
    }
  }

  let slider = React.createRef();

  const increment = () => {
    if (slider && slider.current) {
      slider.current.slickNext();
    }
  };

  const decrement = () => {
    if (slider && slider.current) {
      slider.current.slickPrev();
    }
  };

  const slidesToShow = width > 1150 ? 3 : width > 580 ? 2 : 1;
  const showArrows = matches.length > slidesToShow && slidesToShow !== 1 ? true : false;

  //console.log("width", width, "slidesToShow", slidesToShow);

  const slick_settings = {
    dots: false,
    arrows: false,
    autoplay: false,
    easing: "easeOutQuart",
    draggable: true,
    infinite: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1
  };

  const styles = [bgStyle1, bgStyle2, bgStyle3];

  const redirectTo = i => () => {
    if (
      match_carousel &&
      match_carousel[i] &&
      !match_carousel[i].hasMatch &&
      match_carousel[i].link
    ) {
      if (match_carousel[i].link[0] === "/") {
        history.push(match_carousel[i].link);
      } else {
        window.location = match_carousel[i].link;
      }
    }
  };

  return (
    <div
      className={`${classes.root} ${slidesToShow === 1 && window.screen.availWidth < 1024 ? classes.ovVisible : ""
        } boxWrapper PromotionsWinner`}
    >
      <Slider ref={slider} {...slick_settings}>
        {matches.slice(0, 3).map((m, i) => {
          return (
            <div
              className={`${classes.card}`}
              key={i}
              onClick={redirectTo(i)}
            >
              <div
                className={`${classes.ovHidden} first d-flex flex-column justify-content-between align-items-end h100`}
                style={styles[i]}
              >
                {/*<div className={classes.winner}>{t("WINNER AVANTAJ")}</div>*/}
                <div className={classes.winner}></div>
                {match_carousel[i].hasMatch && (
                  <div className={classes.betWrapper}>
                    <BetBanner match={m} />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
      {matches.length > 0 && showArrows && (
        <React.Fragment>
          <div className={`${classes.arrow} ${classes.arrowLeft}`} onClick={decrement}>
            <ChevronLeftIcon />
          </div>
          <div className={`${classes.arrow} ${classes.arrowRight}`} onClick={increment}>
            <ChevronRightIcon />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

PromotionsWinner.propTypes = {};

PromotionsWinner.defaultProps = {};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const bst = getBetsState(state);

    return {
      config: bst.config.homepageGroupsImages
    };
  };
  return mapStateToProps;
};

export default withTranslation()(
  connect(makeMapStateToProps)(withResizeDetector(PromotionsWinner))
);
