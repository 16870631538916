import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { connect } from "react-redux";

import { getBetsState } from "../../../bets/store/selectors/betData";
import { betsSlipToggleSystem } from "../../../bets/store/actions/betsSlip";

const TicketSystem = props => {
  const { system, systems, systemName, mType, toggleSystem, provider } = props;

  const handleToggle = () => {
    toggleSystem(mType, system, provider);
  };

  return (
    <div
      className={`system clearfix ${systems.indexOf(system) > -1 ? 'active' : ''}`}
      onClick={handleToggle}>
      {systemName}
    </div>
  )
};

const makeMapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const bs = bst.betsSlip;

  return {
    systems: props.mType && props.mType !== "none" ? bs.tickets[bs.currentTicket][props.mType].systems : bs.tickets[bs.currentTicket].systems,
  };
};

const actionCreators = {
  toggleSystem: betsSlipToggleSystem
};

export default connect(makeMapStateToProps, actionCreators)(TicketSystem);
