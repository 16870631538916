import React, {useState, useEffect, Fragment} from 'react';
import i18next from 'i18next';
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import * as stateActions from "../../store/actions";
import Translate from '../../utils/Translate';
import Icons from '../../assets/Icons/Icons';
import LanguageSwitcher from "../AppSettings/LanguageSwitcher/LanguageSwitcher";
import './Footer.scss';
import GTM from "../../utils/GTM";
import {productID, productMainURL} from "../../constants/Products";

const Footer = (props) => {

    const [customerSupportItem, setCustomerSupportItem] = useState(undefined);
    const [cazinoItem, setCazinoItem] = useState(undefined);

    useEffect(() => props.history.listen((location) => {
        urlChanged(location.pathname);
    }), []);
    useEffect(() => {
        urlChanged(props.history.location.pathname);
    }, []);

    const urlChanged = (pathname) => {
        GTM.pushPage({
            path: pathname,
            title: pathname
        });
        let productId = false;

        if (pathname === productMainURL.HOME) {
            productId = productID.HOME
        } else if (pathname.indexOf(productMainURL.LIVE_CASINO) === 0 || pathname.indexOf('cazino-live') === 1) {
            productId = productID.LIVE_CASINO
        } else if (pathname.indexOf(productMainURL.CASINO) === 0 || pathname.indexOf('/play') === 0) {
            productId = productID.CASINO
        } else if (pathname.indexOf(productMainURL.LOTTO) === 0) {
            productId = productID.LOTTO
        } else if (pathname.indexOf(productMainURL.LIVE_SPORT) === 0) {
            productId = productID.LIVE_SPORT
        } else if (pathname.indexOf(productMainURL.SPORT) === 0) {
            productId = productID.SPORT
        } else if (pathname.indexOf(productMainURL.LOTTO) === 0) {
            productId = productID.LOTTO
        } else if (pathname.indexOf(productMainURL.WINNER_FUN) === 0) {
            productId = productID.WINNER_FUN
        } else if (pathname.indexOf(productMainURL.CUSTOMER_SUPPORT) === 0 || pathname.indexOf('locations') === 1) {
            productId = productID.CUSTOMER_SUPPORT
        }
        if(productId) {
            props.setProductId(productId)
        }
    }

    useEffect(() => {
        if (customerSupportItem === undefined) {
            setCustomerSupportItem(props.application.menu["customer-support"]);
        }
        if (cazinoItem === undefined) {
            setCazinoItem(props.application.menu["cazino"]);
        }
    }, [Object.keys(props.application.menu).length > 1]);

    const t = (text) => {
        return text;
    }

    const handleLink = (page, subpage, event) => {
        event.preventDefault();
        event.stopPropagation();

        if (subpage.code === 'cookies') {
            props.showCookiesInfo();
            return;
        }
        let urlString = `/${page}/${subpage.code}`;
        props.history.push(urlString);
        props.onSubpageChanged(subpage);
    };

    const handleMPLink = (pageCode, event) => {
        event.preventDefault();
        event.stopPropagation();


        if (pageCode === 'deposit-oktocash') {
            props.history.push(`/${pageCode}`);

        }else {
            let pages = props.application.menu;
            let page = pages[pageCode];
            if (!page) {
                return false;
            }
            props.onPageChanged(page);
            props.history.push(`/${page.code}`);

        }

    }

    const handleMainPageLink = (pageCode, title) => {
        return <NavLink to={`/${pageCode}`} onClick={handleMPLink.bind(this, pageCode)}>{title}</NavLink>;
    }

    const handleCustomerSupportLink = (pageCode) => {
        if (customerSupportItem === undefined) { return null; }

        let subpage = customerSupportItem.submenus.find((spage) => {
            return pageCode === spage.code;
        });

        if (!subpage) { return null; }

        let urlString = `/${customerSupportItem.code}/${subpage.code}`;
        let butttonText = <Translate text={t(subpage.name)} />;

        switch (pageCode) {
            case "about-us": butttonText = <Translate text={t("contact us")} />; break;
            case "politicadeconfidentialitate": butttonText = <Translate text={t("Privacy Policy")} />; break;
            case "game-rules": butttonText = <Translate text={t("game rules")} />; break;
            default: break;
        }

        return <NavLink to={urlString} onClick={handleLink.bind(this, customerSupportItem.code, subpage)}>{butttonText}</NavLink>;
    }

    const handleCazinoLink = (pageCode) => {
        if (cazinoItem === undefined) { return null; }

        let subpage = cazinoItem.submenus.find((spage) => {
            return pageCode === spage.code;
        });

        if (!subpage) { return null; }

        let urlString = `/${cazinoItem.code}/${subpage.code}`;
        let butttonText = <Translate text={t(subpage.name)} />;

        switch (pageCode) {
            case "jocuri": butttonText = <Translate text={t("Casino")} />; break;
            default: break;
        }

        return <NavLink to={urlString} onClick={handleLink.bind(this, cazinoItem.code, subpage)}>{butttonText}</NavLink>;
    }

    const handleTermsAndConditions = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        let win = window.open(url, '_blank');
        if(win !== null){
            win.focus();
        }
    }

    const handleBonusRules = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/bonusRules/Regulament%20General%20pentru%20Bonusuri.pdf';
        let win = window.open(url, '_blank');
        if(win !== null){
            win.focus();
        }
    }

    return (
        <div className={"Footer"}>
            <div className="FooterContent">
                <div className="FooterHeader">
                    <LanguageSwitcher className="FooterLanguage" />
                    <img className="FooterLogo" src={`${window.config.front_url}/img/mobile/footer/powered_newton.png`} alt="powered_newton" />
                </div>

                <div className="Links">
                    <div>
                        <h3>{<Translate text={t('Legal')} />}</h3>
                        <ul>
                            <li>{handleCustomerSupportLink("politicadeconfidentialitate")}</li>
                            <li><a onClick={() => handleTermsAndConditions()}><Translate text={t("terms & conditions")} /></a></li>
                            <li><a onClick={() => handleBonusRules()}><Translate text={t("Bonus rules")} /></a></li>
                            <li>{handleCustomerSupportLink("game-rules")}</li>
                            <li>{handleCustomerSupportLink("sportsbook-rules")}</li>
                            <li>{handleCustomerSupportLink("payment-methods")}</li>
                        </ul>
                    </div>
                    <div>
                        <h3>{<Translate text={t('Support')} />}</h3>
                        <ul>
                            <li>{handleCustomerSupportLink("faq")}</li>
                            <li>{handleCustomerSupportLink("about-us")}</li>
                            <li>{handleCustomerSupportLink("responsible-gaming")}</li>
                            {window.config && window.config.okto_enabled === '1' && <li>{handleMainPageLink('deposit-oktocash', <Translate text={t('Cash Deposit')} />)}</li>}
                            {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <li>{handleMainPageLink('locations', <Translate text={t('locations')} />)}</li>}

                        </ul>
                    </div>
                    {/*<div>*/}
                    {/*    <h3>{<Translate text={t('Products')} />}</h3>*/}
                    {/*    <ul>*/}
                    {/*        <li>{handleCazinoLink("jocuri")}</li>*/}
                    {/*        <li>{handleCustomerSupportLink("sportsbook")}</li>*/}
                    {/*        <li>{handleMainPageLink('cazino_live', <Translate text={t('LIVE Casino')} />)}</li>*/}
                    {/*        <li>{handleCazinoLink("promotii")}</li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
                <div className="PaymentMethods">
                    <h3>{<Translate text={t('payment methods')} />}</h3>
                    <div className="IconsContainer">
                        {Icons.get('maestro', 'paymentIcon')}
                        {Icons.get('masterCard', 'paymentIcon')}
                        {Icons.get('visa', 'paymentIcon')}
                        {window.config && window.config.okto_enabled === '1' && <Fragment>
                            {Icons.get('oktocash', 'paymentIcon')}
                        </Fragment>}
                        {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <Fragment>
                            {Icons.get('casinoIcon', 'paymentIcon')}
                        </Fragment>}
                    </div>
                </div>
                <div className="footer-images">
                    <div className="Images">
                        <img src={`${window.config.front_url}/img/mobile/footer/egt_logo.png`} alt="egt_logo" />
                        <a href="https://onjn.gov.ro/" target="_blank" rel="noopener noreferrer">
                            <img src={`${window.config.front_url}/img/mobile/footer/onjn.png`} alt="onjn" />
                        </a>
                        <img src={`${window.config.front_url}/img/mobile/footer/18years.png`} alt="18years" />
                        <p>
                            <Translate text={t('Only for players over 18 years old')} /><br />
                            <Translate text={t('Gambling can create addiction. Play Responsible!')} />
                        </p>
                    </div>
                    <div className="Images ANPC">
                        <div className="text">
                            <div>ANPC:</div>
                            <div>Solutionarea litigiilor</div>
                        </div>
                        <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">
                            <img src={`${window.config.front_url}/img/mobile/footer/SOL.png`} alt="SOL" />
                        </a>
                        <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noopener noreferrer">
                            <img src={`${window.config.front_url}/img/mobile/footer/pictograma-anpc.png`} alt="SAL" />
                        </a>
                    </div>
                </div>

                <div className="Text">
                    <p>
                        <Translate text={t("This website is operated by ${companyName}, registered in Malta under number ${companyRegNumber}, at ${companyAddress}. In Romania, ${companyName} owns license number ${license_number}, issued on ${license_date}, by the National Gambling Office (${openTagOnjn}http://onjn.gov.ro/${closeTagOnjn}), valid until ${license_expiration_date}. Please be informed that gambling might be prohibited in your area. It is your responsibility to obey the law. Gambling may be addictive and impact your financials. Please play responsibly!")} placeholder={{
                            companyName: window.config.NOG_company_name ?? process.env.CFW.company.name,
                            companyRegNumber: window.config.NOG_company_cui ?? process.env.CFW.company.register_number,
                            companyAddress: window.config.NOG_company_address ?? process.env.CFW.company.address,
                            openTagOnjn: '<a href="http://onjn.gov.ro/" target="_blank">',
                            closeTagOnjn: '</a>',
                            license_number: window.config.NOG_license_number,
                            license_date: window.config.NOG_license_date_start,
                            license_expiration_date: window.config.NOG_license_date_end
                        }} />
                    </p>
                </div>
                <div id="FooterCookies">
                    {/* {cookies && <Cookies show close={() => handleCookies()} />} */}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
        showCookiesInfo: () => dispatch(stateActions.toggleCookiesInfo()),
        setProductId : (productId) => dispatch(stateActions.setLastProductId(productId))
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer)));
