import {
	takeEvery,
	put,
	delay,
	cancel,
	fork,
	take,
	cancelled,
	takeLatest
} from 'redux-saga/effects';
import axios from 'axios';
import getStore from '../../store';
import { livePlayerLogin, livePlayerLogout } from '../actions/live';
import {
	prematchAuthTicketSocket,
	prematchConnectTicketSocket,
	prematchDisconnectTicketSocket
} from '../actions/prematch';
import { authSuccess, authWinnerFunSuccess, authClear, authEndCycle } from '../actions/auth';
import { appSaveErrorLog, appPlayerInitialize } from '../actions/app';
import { ticketOpenedListRequest, ticketWinnerFunOpenedListRequest } from '../actions/tickets';
//import { liveAuthSuccess, prematchAuthSuccess } from "../store/actions/auth";
import { getBetsState } from '../selectors/betData';

import { sportsbookTokenUrl, nSoftAuth, nSoftAuthWinnerFun } from '../../api/config/nsoft';
import { debug, uuidv4 } from '../../utils';

import { authConstants } from '../actions/constants';
import { authentication } from '../../../store/actions/actionTypes';

import { saveLocalData, loadLocalData, clearLocalData } from '../../../utils/localData';

const expBackoffDelay = p => {
	const timeBase = p.timeBase || 5000;
	const timeMultiple = p.timeMultiple || 2;
	const attempt = p.attempt || 1;
	const maxDelay = p.maxDelay || 60000;
	const jitter = p.jitter || true;

	let prevDelay = timeBase * Math.pow(timeMultiple, attempt - 1);
	prevDelay = Math.min(prevDelay, maxDelay);

	let delay = timeBase * Math.pow(timeMultiple, attempt);
	delay = Math.min(delay, maxDelay);

	if (jitter) {
		delay = prevDelay + Math.round(Math.random() * (delay - prevDelay));
	}

	return delay;
};

const loadNsoftAuth = key => {
	if (!(window && window.config && window.config.tokenCacheEnabled === '1')) {
		return null;
	}

	return loadLocalData(key, {
		expiration: window.config.tokenCacheExpiration,
	});
};

const saveNsoftAuth = (key, data) => {
	if (!(window && window.config && window.config.tokenCacheEnabled === '1')) {
		return;
	}

	saveLocalData(key, data);
};

const clearNsoftAuth = key => {
	clearLocalData(key);
};

function* nSoftCycleAuthSaga() {
	try {
		let attempt = 0;

		while (true) {
			const store = getStore();
			const state = store.getState();

			//console.log("NSoft auth", state);

			const bst = getBetsState(state);

			if (
				!(
					state.authentication &&
					['user', 'token'].indexOf(state.authentication.auth_type) > -1
				) ||
				(bst.auth.details !== null && bst.auth.winnerFunDetails !== null)
			) {
				//console.log("skipping Nsoft authentication");
				yield put(authEndCycle());
				return;
			}

			try {
				yield put(appPlayerInitialize('Bearer ' + state.authentication.access_token));

				let rData = bst.auth.details;

				if (bst.auth.details === null) {
					rData = yield loadNsoftAuth('nsoft_auth');

					if (rData === null) {
						// real money authentication
						const sm = yield axios.get(sportsbookTokenUrl + '/sportsbook-token', {
							headers: {
								Authorization: 'Bearer ' + state.authentication.access_token
							}
						});

						const { headers, data } = yield axios.post(
							nSoftAuth.serviceUrl + '/users/b2b/authenticate.json?requestUuid=' + uuidv4(),
							{
								token: sm.data.tempToken,
								id: sm.data.id,
								authStrategy: 'tempToken'
							},
							{
								headers: {
									'SEVEN-TP-TOKEN': sm.data.tempToken,
									'HTTP-X-SEVEN-CLUB-UUID': nSoftAuth.companyUuid
								}
							}
						);

						//console.log("prematch data", data);

						data.accessToken = headers['access-token'];
						data.smToken = sm.data.tempToken;
						data.tpToken = headers['x-nsft-seven-tp-token'];
						data.tpUserID = sm.data.id;

						//console.log("NSOft prematch auth", data);

						//store.dispatch(prematchAuthSuccess(data));
						rData = data;

						saveNsoftAuth('nsoft_auth', rData);
					}

					yield put(authSuccess(rData));

					yield put(prematchConnectTicketSocket(rData.Uuid));
				}

				if (nSoftAuth.companyUuid === nSoftAuthWinnerFun.companyUuid) {
					debug('same winner fun company');

					yield put(authWinnerFunSuccess(rData));
				} else {
					debug('winner fun auth');

					let rData = yield loadNsoftAuth('nsoft_wf_auth');

					if (rData === null) {
						// winner fun authentication
						const wfSm = yield axios.get(sportsbookTokenUrl + '/sportsbook-token', {
							headers: {
								Authorization: 'Bearer ' + state.authentication.access_token
							}
						});

						const { headers, data } = yield axios.post(
							nSoftAuthWinnerFun.serviceUrl + '/users/b2b/authenticate.json?requestUuid=' + uuidv4(),
							{
								token: wfSm.data.tempToken,
								id: wfSm.data.id,
								authStrategy: 'tempToken'
							},
							{
								headers: {
									'SEVEN-TP-TOKEN': wfSm.data.tempToken,
									'HTTP-X-SEVEN-CLUB-UUID': nSoftAuthWinnerFun.companyUuid
								}
							}
						);

						data.accessToken = headers['access-token'];
						data.smToken = wfSm.data.tempToken;
						data.tpToken = headers['x-nsft-seven-tp-token'];
						data.tpUserID = wfSm.data.id;

						rData = data;

						saveNsoftAuth('nsoft_wf_auth', rData);
					}

					yield put(authWinnerFunSuccess(rData));
				}

				yield put(livePlayerLogin());

				yield put(ticketOpenedListRequest());
				yield put(ticketWinnerFunOpenedListRequest());

				yield put(authEndCycle());
			} catch (e) {
				//console.log("error", e); //util.inspect(e, false, null, true));
				yield put(appSaveErrorLog('', e.toString(), JSON.stringify(e)));
			}

			let d = expBackoffDelay({ attempt: attempt++ });
			debug('auth retry delay', d);
			yield delay(d);
		}
	} finally {
		if (yield cancelled()) {
			debug('auth cycle end');
		}
	}
}

function* nSoftStartAuthSaga() {
	const bgAuthTask = yield fork(nSoftCycleAuthSaga);
	yield take([authConstants.END_CYCLE, authentication.CLEAR]);
	yield cancel(bgAuthTask);
}

function* nSoftClearAuthSaga() {
	clearNsoftAuth('nsoft_auth');
	clearNsoftAuth('nsoft_wf_auth');

	yield put(authClear());
	yield put(livePlayerLogout());
	yield put(prematchConnectTicketSocket());
}

function* authenticateSaga() {
	const store = getStore();
	const state = store.getState();

	yield put(appPlayerInitialize('Bearer ' + state.authentication.access_token));
}

export default function* watchAuthSaga() {
	//console.log("watching auth");
	yield takeLatest(authentication.AUTHENTICATE, authenticateSaga);
	// yield takeEvery(authentication.CLEAR, nSoftClearAuthSaga);
}
