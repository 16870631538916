import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { getBetsState, getSuperBannerMatches, getLiveMatches, getPrematchMatches } from "../../bets/store/selectors/betData";

import { colorToNumber } from "../../bets/utils/banner";

import PixiCarousel from "../../bets/components/PixiCarousel";

//import SlotBanner from "../../bets/components/Banner/SlotBanner3";
import SlotBanner from "../../bets/components/Banner/SlotBannerWeb";
import FootballBanner from "../../bets/components/Banner/FootballBanner";

import WebBannerFootball from "../../bets/assets/banners/banner-sports-web.jpg";
import MobileBannerFootball from "../../bets/assets/banners/banner-sports-mobile.jpg";

import WebBannerWelcome from "../../bets/assets/banners/egypt-quest-web.jpg";
import MobileBannerWelcome from "../../bets/assets/banners/egypt-quest-mobile.jpg";

import isArray from "lodash/fp/isArray";
import { createIntervalTicker, removeIntervalTicker } from "../../utils/intervals";

import moment from "moment";

const useStyles = makeStyles({
  root: {},
  pb12: {
    paddingBottom: "14px"
  }
});

const defBannerData = [
  {
    slideType: "sport",
    timeout: 0,
    image: {
      desktop: "",
      mobile: ""
    },
    hasMatch: true,
    link: ""
  }
];

let bannersHost = process.env.CFW.betsBannersImagesUrl;
if (bannersHost.substr(-1) !== "/") bannersHost += "/";

const DEBUG = false;

const filterSlides = as => {
  // current time
  const now = moment.utc().valueOf();

  return as.filter(s => {
    DEBUG && console.log("filter banner", s, now);

    // check if the slide is active
    if (!s.isActive) {
      DEBUG && console.log("banner is not active");
      return false;
    }

    // check if slide is time dependent
    if (!s.timeEnabled) {
      DEBUG && console.log("banner not time dependent");
      return true;
    }

    // normalize to milliseconds
    const st = s.startTime * 1000;
    const d = s.duration * 60 * 1000;

    DEBUG && console.log("st", st, "d", d);

    // check if slide should be shown via start time
    if (st <= now && (d === 0 || st + d >= now)) {
      DEBUG && console.log("banner shown via start time");
      return true;
    }

    // check if recurrent
    if (!s.recurrent) {
      DEBUG && console.log("banner not recurrent");
      return false;
    }

    // compute recurrent start time for today
    let cDay = moment().day();

    // fix sunday
    if (cDay == 0) {
      cDay = 7;
    }

    // check if current day is in day selection
    const cIdx = s.daySelection.find(d => d === cDay);
    if (cIdx == -1) {
      DEBUG && console.log("banner not shown because of day selection");
      return false;
    }

    // compute relative start time
    const stDiff =
      st -
      moment
        .utc(st)
        .startOf("day")
        .valueOf();

    // build today start time
    const tst = moment
      .utc()
      .startOf("day")
      .add(stDiff)
      .valueOf();

    // check if slide should be shown via today start time
    if (tst <= now && tst + d >= now) {
      DEBUG && console.log("banner shown on today start time");
      return true;
    }

    DEBUG && console.log("banner not shown because not on today start time", tst, d, now);

    // dont show it
    return false;
  });
};

const Slider = props => {
  const classes = useStyles(props);
  const { eventPrefix, config, superBannerMatches, liveMatches, prematchMatches, paddingClass, fullWidth, t } = props;

  const [allSlides, setAllSlides] = React.useState(defBannerData);
  const [selectedSlides, setSelectedSlides] = React.useState(defBannerData);
  const [slides, setSlides] = React.useState([]);
  const history = useHistory();

  const navigateToLink = index => {
    const elem = selectedSlides[index];

    if (elem && !elem.hasMatch && elem.link) {
      if (elem.link[0] === "/") {
        history.push(elem.link);
      } else {
        window.location = elem.link;
      }
    }
  };

  const buildSlides = React.useCallback(() => {
    let sbMatchIndex = 0;

    const vs = selectedSlides.map((s, i) => {
      let slideType = "sport";
      if (typeof s.slideType !== "undefined") {
        slideType = s.slideType;
      }

      let slide = null;

      if (slideType === "regular") {
        slide = (
          <SlotBanner
            key={"supper_banner_" + i}
            webImage={s.image.desktop ? bannersHost + s.image.desktop : WebBannerWelcome}
            mobileImage={s.image.mobile ? bannersHost + s.image.mobile : MobileBannerWelcome}
            blendColor={colorToNumber(s.blendColor) || 0x613029}
            eventName={eventPrefix + "_slotBanner_" + i}
            timeout={parseInt(s.timeout, 10)}
            config={s}
            fullWidth={fullWidth}
          />
        );
      } else if (slideType === "sport" && superBannerMatches.length > 0) {
        let pMatch = null;

        if (s.selectedMatch) {
          const sm = s.selectedMatch;

          if (sm.live) {
            // live event

            // we show it until it ends
            if (sm.match_id in liveMatches) {
              pMatch = liveMatches[sm.match_id];
            }
          } else {
            // prematch event
            if (sm.match_id in prematchMatches) {
              // still in prematch state

              // show until in prematch state or until live event ends
              if (sm.showUntil !== "live_only") {
                pMatch = prematchMatches[sm.match_id];
              }
            }

            if (sm.br_id in liveMatches) {
              // event in live state

              // show if not restricted to prematch state
              if (sm.showUntil !== "live_start") {
                pMatch = liveMatches[sm.br_id];
              }
            }
          }

          if (pMatch === null) {
            return null;
          } else if (!pMatch.active) {
            return null;
          }
        }

        if (pMatch === null) {
          if (sbMatchIndex > superBannerMatches.length - 1) {
            sbMatchIndex = 0;
          }

          pMatch = superBannerMatches[sbMatchIndex];
        }

        if (!pMatch?.active) {
          return null;
        }

        slide = (
          <FootballBanner
            key={"supper_banner_" + i}
            hasMatch={s.hasMatch}
            webImage={s.image.desktop ? bannersHost + s.image.desktop : WebBannerFootball}
            mobileImage={s.image.mobile ? bannersHost + s.image.mobile : MobileBannerFootball}
            blendColor={colorToNumber(s.blendColor) || 0x0f345a}
            eventName={eventPrefix + "_footballBannerBets_" + i}
            timeout={parseInt(s.timeout, 10)}
            pMatch={pMatch}
            fullWidth={fullWidth}
          />
        );

        sbMatchIndex++;
      }

      return slide;
    });

    return vs.filter(s => s !== null);
  }, [selectedSlides]);

  React.useEffect(() => {
    const tid = createIntervalTicker({
      name: eventPrefix + "_slider",
      interval: 10 * 1000,
      add: 1000,
      callback: () => {
        const ss = filterSlides(config);
        setSelectedSlides(l => ss);
      },
      options: {
        replace: true
      }
    });

    return () => {
      DEBUG && console.log("unmount", tid);
      removeIntervalTicker(tid);
    };
  }, [allSlides]);

  React.useEffect(() => {
    let as = defBannerData;
    if (config && isArray(config) && config.length >= 1) {
      as = config;
    }
    setAllSlides(l => as);

    const ss = filterSlides(as);

    setSelectedSlides(l => (l = ss));

    DEBUG && console.log("as", as, "ss", ss);
  }, [config]);

  React.useEffect(() => {
    const vs = buildSlides();

    DEBUG && console.log("vs", vs);

    setSlides(vs);
  }, [selectedSlides]);

  if (slides.length === 0) {
    return null;
  }

  return (
    <div className={clsx(classes.root, paddingClass && classes[paddingClass])}>
      <PixiCarousel onClick={navigateToLink}>{slides}</PixiCarousel>
    </div>
  );
};

Slider.propTypes = {
  eventPrefix: PropTypes.string.isRequired,
  config: PropTypes.array.isRequired
};

const emptyArray = [];

const mapStateToProps = state => {
  const bst = getBetsState(state);

  if (typeof bst === "undefined") {
    return {
      superBannerMatches: emptyArray,
      liveMatches: emptyArray,
      prematchMatches: emptyArray,
    };
  }

  return {
    superBannerMatches: getSuperBannerMatches(state),
    liveMatches: getLiveMatches(state),
    prematchMatches: getPrematchMatches(state),
  };
};

export default connect(mapStateToProps)(Slider);
