import React from 'react';

import "./SimpleProgress.scss";

const SimpleProgress = (props) => {
  const style = {
    transform: `translateX(-${100 - props.value}%)`
  }

  return (
    <div className="simple-progress">
      <div className="simple-progress-bar" style={style}></div>
    </div>
  );
}

export default React.memo(SimpleProgress);