/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 07/11/2018
 * Time: 14:18
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    BONUS_STATUS_ACTIVE,
    BONUS_STATUS_EXPIRED,
    BONUS_STATUS_FORFEITED,
    BONUS_STATUS_INACTIVE,
    BONUS_STATUS_WAGERED
} from "../Bonus"
import TimeAgo from 'timeago-react';
// import timeago from "timeago.js";
import BonusAvailability from "./BonusAvailability";
import ForfeitBonus from "./ForfeitBonus";
import Icons from "../../../../../../assets/Icons/Icons";
import { withTranslation } from "react-i18next";
import ValueCurrency from "../ValueCurrency";
// import Menus from "../../../../../Utils/Menus/Menus";
import {NavLink} from "react-router-dom";
import i18next from "i18next";
// import language.
// timeago.register('ro', require('timeago.js/locales/ro'));

class BonusWidget extends Component {

    getTextStatus(type, translated) {


        switch (parseInt(type, 10)) {
            case BONUS_STATUS_ACTIVE:
                // check if we have a startDate
                if (typeof this.props.items.startDate !== "undefined") {
                    if (this.props.items.startDate > new Date().getTime()) {
                        return translated ? this.props.t("pending") : "pending";
                    }
                }
                return translated ? this.props.t("active") : "active";
            case BONUS_STATUS_EXPIRED:
                return translated ? this.props.t("expired") : "expired";
            case BONUS_STATUS_FORFEITED:
                return translated ? this.props.t("forfeited") : "forfeited";
            case BONUS_STATUS_INACTIVE:
                return translated ? this.props.t("under investigation") : "under investigation";
            case BONUS_STATUS_WAGERED:
                return translated ? this.props.t("wagered") : "wagered";
            default:
                break;
        }
    }
    getComponent(type, status = undefined) {
        let comp = [];
        switch (type) {
            case "status":
                status = typeof status === "undefined" ? this.props.items.status : status;
                let iconStatus = false;
                if (status === BONUS_STATUS_WAGERED) {
                    iconStatus = Icons.get('piggy-bank', 'piggy-bank');
                }
                comp.push(

                    <div className={"status " + this.getTextStatus(status)} key={"status_" + this.props.items.name}>
                        {this.getTextStatus(status, true)}
                    </div>
                );
                if (iconStatus) {
                    comp.push(
                        <div className="statusIcon" key={"statusIcon_" + this.props.items.name}>
                            {iconStatus}
                        </div>
                    )
                }

                break;
            case "footer":

                let cms_data = this.props.items.cms_data && this.props.items.cms_data.length ? this.props.items.cms_data[0] : false;
                comp.push(<div className="footer" key={"footer_" + this.props.items.name}>
                    <div className="left">
                        <NavLink target="_blank" to={{pathname: cms_data && cms_data.terms_url ? cms_data.terms_url : window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf'}}>{this.props.t("Termeni si Conditii")}</NavLink>
                    </div>
                    <div className="right">
                        <ForfeitBonus bonusId={this.props.items.id} />
                    </div>
                </div>);
                break;
            case "bonusWithDetails":
                let statusComponent = this.getComponent('status');
                let footerComponent = this.getComponent('footer');
                let ringFenceAmount = 0;
                if (this.props.items.ringFenceAmount) { ringFenceAmount =  this.props.items.ringFenceAmount}
                let percent = parseInt((this.props.items.wager / this.props.items.wagerTarget) * 100, 10);
                if (percent > 100) { percent = 100 }
                let className = (typeof this.props.pending[this.props.items.id] !== "undefined" && this.props.pending[this.props.items.id] ? " pending" : "")
                comp.push(<div className={"bonus-widget" + className} key={"bonus_" + this.props.items.name}>
                    <div className="col-12">{statusComponent}</div>
                    <div className="col-4">
                        <div className={"current"}>
                            {this.props.t("Current bonus")}
                            <div className="value">
                                {Icons.get('diamond', 'DiamondIcon')}
                                <ValueCurrency value={this.props.items.amountGranted} currency={this.props.currency} />
                            </div>
                            <div className="value RingFence">
                                {Icons.get('lockedMoney', 'LockIcon')}
                                <ValueCurrency value={ringFenceAmount} currency={this.props.currency} />
                            </div>
                        </div>
                        <div className="left">
                            <BonusAvailability endDate={this.props.items.endDate} bonusId={this.props.items.id} />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className={"details " + this.getTextStatus(this.props.items.status)}>
                            {this.props.t("Rolling conditions")}:
                            <br />
                            <strong>{(this.props.items.wager ? this.props.items.wager : 0)}</strong>/<ValueCurrency value={this.props.items.wagerTarget} currency={this.props.currency} />
                            <div className="progress">
                                <div className="progress-bar" style={{ width: percent + "%" }}> </div>
                            </div>
                            <div className="percent">{percent}%</div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="title">
                            {this.props.items.name}
                        </div>
                        <div className="value">
                            <ValueCurrency value={this.props.items.amount} currency={this.props.currency} />
                        </div>
                    </div>
                    <div className="clearfix"> </div>
                    {footerComponent}

                </div>);
                break;
            case "bonusWrapper":

                let statusComp = this.getComponent('status', status);
                let bonus = [];
                let filters = this.props.filters[status]; // TODO: fix this;
                // Object.keys(this.props.filters).forEach((key) => {
                //     console.log("STATUS", status, key, status === key);
                // });
                if (typeof filters !== "undefined") {
                    let exclamationPoint;
                    let className = '';
                    if (status === BONUS_STATUS_WAGERED) {
                        exclamationPoint = Icons.get('checkmark', 'checkmark');
                    } else {
                        exclamationPoint = Icons.get('exclamationPoint', 'exclamationPoint');
                        className = 'error';
                    }

                    let amountGranted = [];
                    for (let i = 0; i < filters.length; i++) {
                        if (status === BONUS_STATUS_WAGERED) {
                            amountGranted.push(
                                <span className="details">|
                                    <ValueCurrency value={this.props.items[filters[i]].amountGranted} currency={this.props.currency} />
                                </span>
                            );
                        }
                        bonus.push(
                            <div key={"bonus_grouped_" + this.props.items[filters[i]].name}>
                                <div className="title">
                                    {this.props.items[filters[i]].name}
                                    <span className="details">|</span>
                                    <span className="value">
                                        <ValueCurrency value={this.props.items[filters[i]].amount} currency={this.props.currency} />
                                    </span>
                                    {amountGranted}
                                </div>
                                <div className={"timeago " + className}>
                                    {exclamationPoint}
                                    <TimeAgo datetime={new Date().getTime() - this.props.items[filters[i]].endDate} />
                                </div>
                            </div>
                        )
                    }
                    comp.push(<div className={"bonus-widget " + this.getTextStatus(status)} key={"bonus_" + this.getTextStatus(status)}>
                        <div className="col-12">
                            {statusComp}
                            {bonus}
                        </div>
                        <div className="footer simple-footer" > </div>
                    </div>);
                }

                break;
            case "grouped":

                for (let i = 0; i < this.props.filters.length; i++) {
                    if (typeof this.props.filters[i] !== "undefined" && this.props.filters[i].length) {

                        comp.push(this.getComponent('bonusWrapper', i));
                    }
                }
                break;
            default:
                break;
        }

        return comp;
    }
    render() {


        return (
            <div>
                {this.props.filters ? this.getComponent('grouped') : this.getComponent('bonusWithDetails')}
            </div>
        );
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//     };
// };

const mapStateToProps = state => {
    return {
        pending: state.wallet.triggers.bonuses.forfeit.pending,
        currency: state.wallet.currency
    }
};
export default withTranslation()(connect(mapStateToProps, null)(BonusWidget));
