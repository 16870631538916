import React from 'react';
import isEqual from "lodash/fp/isEqual";
import moment from "moment";
import LockIcon from '@material-ui/icons/Lock';

import "./DrawsSelector.scss";

const noop = () => { };

const DrawsSelector = (props) => {
  const { events, count, onDecrement, onIncrement, onMax, onCount } = props;

  const getTime = (event) => {
    if (event) {
      return moment(event.event_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
    }
    return "";
  };

  if (events.length === 0) return null;

  return (
    <div className="lotto-draws-selector">
      {count > 0 && <div className="lsd-button" onClick={onDecrement}>-</div>}

      {count === 0 && <div className="lds-event" onClick={onIncrement}>{getTime(events[0])}</div>}
      {count === 0 && <div className={`${events.length > 1 ? 'lds-event' : 'lsd-spread'}`} onClick={events.length > 1 ? () => onCount(2) : noop}>{events.length > 1 ? getTime(events[1]) : null}</div>}
      {count === 0 && events.length <= 4 && <div className={`${events.length > 2 ? 'lds-event' : 'lsd-spread'}`} onClick={events.length > 2 ? () => onCount(3) : noop}>{events.length > 2 ? getTime(events[2]) : null}</div>}
      {count === 0 && events.length > 4 && <div className='lsd-spread'>...</div>}
      {count === 0 && <div className={`${events.length > 3 ? 'lds-event' : 'lsd-spread'}`} onClick={events.length === 4 ? () => onCount(4) : () => onCount(3)}>{events.length > 3 ? events.length === 4 ? getTime(events[3]) : getTime(events[2]) : ''}</div>}

      {count !== 0 && events.length < 4 && <div className="lds-event selected">{getTime(events[0])}</div>}
      {count !== 0 && events.length < 4 && <div className={`${events.length > 1 ? `lds-event ${count >= 2 ? 'selected' : ''}` : 'lsd-spread'}`}>{events.length > 1 ? getTime(events[1]) : null}</div>}
      {count !== 0 && events.length < 4 && <div className={`${events.length > 2 ? `lds-event ${count === 3 ? 'selected' : ''}` : 'lsd-spread'}`}>{events.length > 2 ? getTime(events[2]) : null}</div>}

      {count === 1 && events.length >= 4 && <div className="lds-event selected lsd-cn">{getTime(events[count - 1])}</div>}
      {count === 2 && events.length >= 4 && <div className="lds-two">
        <div className="lds-event selected abs-1"></div>
        <div className="lds-event selected lsd-cn">{getTime(events[count - 1])}</div>
      </div>}

      {count >= 3 && events.length >= 4 && <div className="lds-three">
        <div className="lds-event selected abs-1"></div>
        <div className="lds-event selected abs-2"></div>
        <div className="lds-event selected lsd-cn">{getTime(events[count - 1])}</div>
      </div>}

      {count !== 0 && events.length >= 4 && <div className="lsd-spread">...</div>}
      {count !== 0 && events.length >= 4 && count !== events.length && <div className={`lds-event`} onClick={onIncrement}>{getTime(events[count])}</div>}
      {count !== 0 && events.length >= 4 && count === events.length && <div className={`lds-event lds-lock`} ><LockIcon /></div>}

      <div className={`lsd-button no-r-m ${count < events.length ? '' : 'disabled'}`} onClick={onIncrement}>+</div>
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  if (prevProps.count !== nextProps.count) {
    return false;
  }
  if (prevProps.onDecrement !== nextProps.onDecrement) {
    return false;
  }
  if (prevProps.onIncrement !== nextProps.onIncrement) {
    return false;
  }
  if (prevProps.events.length !== nextProps.events.length) {
    return false;
  } else {
    if (!isEqual(prevProps.events, nextProps.events)) {
      return false;
    }
  }
  return true;
}

export default React.memo(DrawsSelector, areEqual);