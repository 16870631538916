import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cloneDeep from "lodash/fp/cloneDeep";

import { betsSlipConstants } from "../../store/actions/constants";
import { getBetsState, makeGetBets, makeGetStateMatch } from "../../store/selectors/betData";
import { formatOddValue, formatOddName } from "../../utils/formatters";
import getStore from "../../store";

import { ReactComponent as GreenArrow } from "./assets/greenArrow.svg";
import { ReactComponent as RedArrow } from "./assets/redArrow.svg";
import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";

import {
  gsap,
  TimelineMax,
  TweenMax,
  Power3,
  Power2,
  Linear,
  Draggable
} from "gsap/all";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear);

const useStyles = makeStyles({
  button: {
    minWidth: props => {
      if (props.banner) {
        return props.total <= 2 ? "136px" : "88px";
      }
      if (props.bannerWP) {
        return props.total <= 2 ? "calc(50% - 10px)" : "calc(33% - 10px)";
      }
      if (props.details) {
        return "initial";
      }
      return props.total <= 2 ? "70px" : props.isActualDesktop ? "48px" : "42px";
    },
    width: props => {
      if (props.isActualDesktop && props.sp) {
        return "calc(100% / 6 - 3px)";
      } else if (props.isActualDesktop) {
        return "calc(100% / 3 - 2px)";
      }
      return "auto";
    },
    borderRadius: "7px",
    border: "1px solid transparent",
    position: "relative",
    flex: "1 1 auto",
    height: "48px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  wrapper: {
    width: "100%",
    padding: "6px 2px 6px 2px"
  },
  bgBanner: {
    backgroundColor: "#e8f7fb"
  },
  bgRegular: {
    backgroundColor: "#e7edf2"
  },
  bgDark: {
    backgroundColor: "#1E2751"
  },
  betType: {
    display: "inline-block",
    color: "#7F838A",
    fontSize: "14px",
    fontWeight: "600",
    marginRight: "5px",
    opacity: "1",
    height: "17px",
    lineHeight: "normal",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    minWidth: "0",
    "&.details": {
      fontSize: "14px",
      fontWeight: "600"
    },
    "&.hideOddName": {
      opacity: "0",
      height: "0",
      overflow: "hidden"
    }
  },
  betTypeTransition: {
    transition: "opacity .4s ease, height .3s ease",
  },
  betTypeDesktop: {
    backgroundColor: "#d8e2ed",
    margin: "-9px -3px 6px",
    fontSize: "12px",
    borderRadius: "7px 7px 0 0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: "0",
    paddingTop: "1px",
    textAlign: "center",
  },
  betTypeDesktopDark: {
    backgroundColor: "#11162d",
    margin: "-10px -3px 6px",
    fontSize: "12px",
    borderRadius: "7px 7px 0 0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
  betValue: {
    display: "inline-block",
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0 auto",
    "& svg": {
      color: "#666",
      height: "16px",
      position: "relative",
      top: "3px"
    },
    "&.details": {
      fontSize: "15px"
    }
  },
  dark: {
    "& .betType": {
      color: "#C0CEE5"
    },
    "& .betValue": {
      color: "#fff"
    }
  },
  active: {
    backgroundColor: "#06D2BD",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.31)",
    "& .betType": {
      color: "#fff"
    },
    "& .betValue": {
      color: "#fff"
    }
  },
  activeDesktop: {
    "& .betType": {
      backgroundColor: "#059486"
    }
  },
  highlighted: {
    border: "1px solid #9BBDEF"
  },
  arrow: {
    position: "absolute",
    left: "calc(50% - 7px)",
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: "2px",
    transform: "translate3d(0,0,0)",
    "& svg": {
      width: "14px",
      height: "7px"
    },
    "&.aGreenUK": {
      top: "-15px"
    },
    "&.aRedUK": {
      top: "17px"
    },
    "&.aGreen": {
      right: "5px",
      left: "auto"
    },
    "&.aRed": {
      left: "5px"
    }
  },
  colorGreen: {
    color: "#01AE3B!important",
    //marginLeft: "3px"
  },
  colorRed: {
    color: "#DF2222!important",
    //marginLeft: "3px"
  },
  lock: {
    position: "absolute",
    backgroundColor: "transparent",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: "7px",
    textAlign: "center",
    color: "#666",
    paddingTop: "2px",
    pointerEvents: "none"
  },
  bannerLock: {
    backgroundColor: "#a9d3fe"
  },
  lightLock: {
    backgroundColor: "#EDF2F9"
  },
  darkLock: {
    backgroundColor: "#171d38"
  },
  bannerLockColor: {
    fill: "#4867A4",
    color: "#4867A4!important"
  },
  lightLockColor: {
    fill: "#707070",
    color: "#707070!important"
  },
  darkLockColor: {
    fill: "#7D9AC7",
    color: "#7D9AC7!important"
  },
  relative: {
    position: "relative"
  },
  trn: {
    textAlign: "center",
    paddingTop: "1px"
  },
  disabled: {
    opacity: ".8",
    cursor: "auto"
  }
});

const onBetToggle = (e) => {
  e.stopPropagation();

  const target = e.currentTarget;

  if (target) {
    const mBoActive = target.dataset.mboactive;
    const mType = target.dataset.mtype;
    const idSport = target.dataset.idsport;
    const idMatch = target.dataset.idmatch;
    const idBet = target.dataset.idbet;
    const idMb = target.dataset.idmb;
    const idBo = target.dataset.idbo;
    const idMbo = target.dataset.idmbo;

    if (mBoActive === "1" || mBoActive === "true") {
      console.log(mBoActive, mType, idSport, idMatch, idBet, idMb, idBo, idMbo);
      const store = getStore();
      const bst = getBetsState(store.getState());

      let m = null;

      if (mType === "live") {
        if (idMatch in bst.live.matches) {
          m = bst.live.matches[idMatch];
        }
      } else {
        if (idMatch in bst.prematch.matches) {
          m = bst.prematch.matches[idMatch];
        }
      }

      if (m === null) {
        return;
      }

      store.dispatch({
        type: betsSlipConstants.TOGGLE,
        mType,
        idSport,
        idMatch,
        idBet,
        idMb,
        idBo,
        idMbo,
        match: cloneDeep(m),
        matches: {
          live: bst.live.matches,
          prematch: bst.prematch.matches
        },
        combiningAllowed: bst.nsoft.combiningAllowed
      });
    }
  }
};

const Bet = props => {
  const {
    id,
    idSport,
    idMatch,
    idBet,
    idMb,
    bet,
    banner,
    highlighted,
    dark,
    bets,
    selected,
    match,
    className,
    //outcomeAbbr,
    details,
    bannerWP,
    scrolled
  } = props;

  let isDesktop = useMediaQuery('(min-width:1281px');
  let isActualDesktop = isDesktop;
  isDesktop = true;
  if (banner || bannerWP || details) isDesktop = false;
  const classes = useStyles({ ...props, isActualDesktop });


  //console.log("isActualDesktop", isActualDesktop);

  const greenArrow = React.createRef();
  const redArrow = React.createRef();
  const buttonRef = React.createRef();

  const [isInView, setIsInView] = React.useState(true);
  const [changeDir, setChangeDir] = React.useState(
    typeof bet.changeDir !== "undefined" ? bet.changeDir : ""
  );
  const [hideOddName, setHideOddName] = React.useState(isActualDesktop ? false : scrolled ? false : true);
  React.useEffect(() => {
    setChangeDir(bet.changeDir);
  }, [bet.changeDir]);
  React.useEffect(() => {
    if (!isActualDesktop) {
      if (scrolled) {
        setHideOddName(false);
      } else {
        setHideOddName(true);
      }
    } else {
      setHideOddName(false);
    }
  }, [scrolled, isActualDesktop]);

  let bg = banner ? classes.bgBanner : dark ? classes.bgDark : classes.bgRegular;

  let status = "";
  let darkCls = "";
  let highlightedCls = "";

  if (selected.find(e => e.idMbo === bet.idMbo)) {
    status = `${classes.active} ${isDesktop ? classes.activeDesktop : ''}`;
  }

  if (dark) {
    darkCls = classes.dark;
  }

  if (highlighted) {
    highlightedCls = classes.highlighted;
  }

  let mboActive = bet.mboActive;

  const matchBets = match.matchBets.filter(m => m.idMb === idMb);
  if (matchBets && matchBets.length) {
    if (!matchBets[0].mbActive) {
      // should we disable all bets?
      mboActive = false;
    }
  }

  let lockColor = "";
  if (mboActive === false) {
    bg = banner ? classes.bannerLock : dark ? classes.darkLock : classes.lightLock;
    lockColor = banner
      ? classes.bannerLockColor
      : dark
        ? classes.darkLockColor
        : classes.lightLockColor;
  }

  const buttonClass = `${classes.button} ${bg} ${status} ${darkCls} ${highlightedCls} myBetElement`;

  let arrow = "";
  let color = "";
  if (banner === false && bet && bet.mboActive && isInView) {
    if (changeDir === "up") {
      arrow = (
        <div
          className={`${classes.arrow} ${isActualDesktop ? 'aGreen' : 'aGreenUK'}`}
          //key={`key_${bet.mboOddValue}`}
          ref={greenArrow}
        >
          <GreenArrow />
        </div>
      );
      color = "colorGreen";
    } else if (changeDir === "down") {
      arrow = (
        <div
          className={`${classes.arrow} ${isActualDesktop ? 'aRed' : 'aRedUK'}`}
          //key={`key_${bet.mboOddValue}`}
          ref={redArrow}
        >
          <RedArrow />
        </div>
      );
      color = "colorRed";
    }
  }

  React.useEffect(() => {
    let tlMotion;
    let tlScale;
    if (greenArrow && greenArrow.current) {
      const elem = greenArrow.current;
      if (elem) {
        tlMotion = gsap.timeline({
          repeat: 2, onComplete: () => {
            setChangeDir("");
          }
        });
        tlScale = gsap.timeline();
        tlScale.to(elem, { scaleX: 0.1, scaleY: 0.1, duration: 0 })
          .to(elem, { scaleX: 1, scaleY: 1, duration: 0.4, ease: 'ease-out' });
        tlMotion.to(elem, { y: "+=2", duration: 0.25, ease: 'ease-out' })
          .to(elem, { y: "-=2", duration: 0.25, ease: 'ease-out' })
          .to(elem, { y: "-=1", duration: 0.25, ease: 'ease-out' })
          .to(elem, { y: "+=1", duration: 0.25, ease: 'ease-out' });
      }
    }
    return () => {
      if (tlMotion) tlMotion.kill();
      if (tlScale) tlScale.kill();
    }
  }, [greenArrow]);
  React.useEffect(() => {
    let tlMotion;
    let tlScale;
    if (redArrow && redArrow.current) {
      const elem = redArrow.current;
      if (elem) {
        tlMotion = gsap.timeline({
          repeat: 2, onComplete: () => {
            setChangeDir("");
          }
        });
        tlScale = gsap.timeline();
        tlScale.to(elem, { scaleX: 0.1, scaleY: 0.1, duration: 0 })
          .to(elem, { scaleX: 1, scaleY: 1, duration: 0.4, ease: 'ease-out' });
        tlMotion.to(elem, { y: "-=2", duration: 0.25, ease: 'ease-out' })
          .to(elem, { y: "+=2", duration: 0.25, ease: 'ease-out' })
          .to(elem, { y: "+=1", duration: 0.25, ease: 'ease-out' })
          .to(elem, { y: "-=1", duration: 0.25, ease: 'ease-out' });
      }
    }
    return () => {
      if (tlMotion) tlMotion.kill();
      if (tlScale) tlScale.kill();
    }
  }, [redArrow]);

  React.useEffect(() => {
    const fireInView = (inView) => {
      setIsInView(old => {
        if (old !== inView) {
          return inView;
        }
        return old;
      })
    };

    let observer = null;
    if (buttonRef && buttonRef.current) {
      observer = new IntersectionObserver(
        (records, observer) => {
          for (const record of records) {
            if (record.intersectionRatio > 0.1) {
              fireInView(true);
            } else {
              fireInView(false);
            }
          }
        },
        { threshold: [0.1] }
      );
      observer.observe(buttonRef.current); // attach observer to our probe
    }
    return () => {
      if (observer) observer.disconnect(); // cleanup observer
    };
  }, [buttonRef]);

  const oddName = formatOddName(idBet, bet, match, bets);

  const cnt = (
    <React.Fragment>
      <div className={`${classes.wrapper} d-flex ${isDesktop ? 'flex-column' : 'flex-row'} flex-nowrap justify-content-between`}>
        <div className={`${classes.betType} ${isActualDesktop ? classes.trn : classes.betTypeTransition} ${isDesktop ? dark ? classes.betTypeDesktopDark : classes.betTypeDesktop : ''} betType ${lockColor} ${details ? "details" : ""} ${hideOddName ? 'hideOddName' : ''}`}>
          {oddName}
        </div>
        <div
          className={`d-flex flex-row flex-nowrap align-items-center ${!mboActive ? "flex-grow-1" : ""
            } ${classes.relative}`}
        >
          {arrow}
          <div className={`${classes.betValue} betValue ${color} ${details ? "details" : ""}`}>
            {mboActive && formatOddValue(bet.mboOddValue)}
            {!mboActive && <LockOutlinedIcon className={lockColor} />}
          </div>
        </div>
      </div>
      {mboActive === false && <div className={classes.lock}></div>}
    </React.Fragment>
  );

  return (
    <div
      id={id}
      className={clsx(className, buttonClass, !mboActive ? classes.disabled : '')}
      onClick={onBetToggle}
      data-mboactive={mboActive}
      data-mtype={match.mType}
      data-idsport={idSport}
      data-idmatch={idMatch}
      data-idbet={idBet}
      data-idmb={idMb}
      data-idbo={bet.idBo}
      data-idmbo={bet.idMbo}
      ref={buttonRef}
      title={oddName}
    >
      {cnt}
    </div>
  );
};

Bet.propTypes = {
  id: PropTypes.string,
  bet: PropTypes.object,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

Bet.defaultProps = {
  id: "",
  idSport: "0",
  idBet: "0",
  bet: {},
  banner: false,
  highlighted: false,
  active: false,
  dark: false,
  onClick: () => { }
};

const getBets = makeGetBets();
const getStateMatch = makeGetStateMatch();

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  return {
    bets: getBets(state, props),
    match: getStateMatch(state, props),
    selected:
      props.mType === "live"
        ? bst.betsSlip.tickets[bst.betsSlip.currentTicket].live.selected
        : bst.betsSlip.tickets[bst.betsSlip.currentTicket].prematch.selected,
    outcomeAbbr: bst.config.outcomeAbbr
  };
};

export default connect(mapStateToProps)(Bet);
