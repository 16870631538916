import React from 'react';
import moment from 'moment';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import "./cta-promotions-desktop.scss";
import { ReactComponent as CalendarIcon } from "../assets/calendar.svg";
import SimpleTimer from "./simple_timer";
import evBusMain from "../../utils/evbus";

const urlPart = `https://${window.config.cdn_address}/${window.config.brand_name_short.toLowerCase()}/winter-time/img-bg-winterpromo-desktop.png`;

const style = {
  backgroundImage: `url(${urlPart})`
}

const WinterCTA = (props) => {
  const { t, winterStatus } = props;
  const [mounted, setMounted] = React.useState(false);

  const nodeRef = React.useCallback(node => {
    if (node !== null) {
      setMounted(true);
    }
  }, []);

  React.useEffect(() => {
    if (mounted) {
      const day = moment().format("D");
      const month = moment().format("MMM");

      let el = document.querySelector(".promo-winter-cta-promotions-desktop .winter-calendar-day");
      if (el) {
        el.innerHTML = day;
      }
      el = document.querySelector(".promo-winter-cta-promotions-desktop .winter-calendar-month");
      if (el) {
        el.innerHTML = month.toLocaleUpperCase();
      }
    }
  }, [mounted]);

  const reloadStatus = () => { };

  if (!winterStatus) return null;
  if (winterStatus && winterStatus.campaign_settings && winterStatus.campaign_settings.winter) {
    if (winterStatus.campaign_settings.winter.start_date && winterStatus.campaign_settings.winter.end_date) {
      const now = moment().valueOf();
      const start_start = winterStatus.campaign_settings.winter.start_date;
      const end_date = winterStatus.campaign_settings.winter.end_date;

      if (!(start_start <= now && now <= end_date)) {
        return null;
      }
    }
  }

  const checkUnlockState = () => {
    let unlock = false;

    const day = moment().format("D");

    if (winterStatus) {

      let dayIndex = 0;
      const prizes = {};
      const prizesArray = [];

      Object.keys(winterStatus.winter_prizes).forEach(id => {
        prizesArray.push(winterStatus.winter_prizes[id]);
      });

      try {
        prizesArray.sort((a, b) => a.ts - b.ts);
      } catch (err) {/*noop*/ }

      prizesArray.forEach((p, index) => {
        prizes[index + 1] = false;
        if (winterStatus && winterStatus.winter_status && winterStatus.winter_status.prizes) {
          if (winterStatus.winter_status.prizes.indexOf(p.id) > -1) {
            prizes[index + 1] = true;
          }
        }
        if (p.day === day.toString()) {
          dayIndex = index + 1;
        }
      });

      if (!prizes[dayIndex]) {
        unlock = true;
      }
    }

    return unlock;
  };
  const canUnlock = checkUnlockState();
  const endOfDay = moment().endOf("day").valueOf();

  const openPromo = () => {
    if (window.config.platformType === 1) {
      evBusMain.emit("OPEN-WINTER-TIME-DIALOG");
      return;
    }
  };

  return (
    <div className="promo-winter-cta-promotions-desktop" style={style} ref={nodeRef} onClick={openPromo}>
      <div className="calendar">
        <CalendarIcon />
      </div>
      <div className="footer">
        {canUnlock &&
          <div className="promo-now">
            <div className="promo-button">{t("ACTIVATE AWARD")}</div>
            <div className="promo-arrow">
              <NavigateNextIcon />
            </div>
          </div>
        }
        {!canUnlock && <div className="promo-count-down">
          <div className="txt">{t("Next prize in")}</div>
          <div className="count"><SimpleTimer date={moment(endOfDay + 10, "x")} onLive={reloadStatus} /></div>
        </div>}
      </div>
    </div>
  );
}

const mapState = (state) => {
  return {
    winterStatus: state && state.promo && state.promo.winter && state.promo.winter.status ? state.promo.winter.status : null,
  }
};

export default connect(mapState)(withTranslation()(WinterCTA));