import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import WonIcon from '@material-ui/icons/CheckCircleOutline';
import LostIcon from '@material-ui/icons/HighlightOff';
import OpenIcon from '@material-ui/icons/AccessTime';

//import TicketInProgress from "./assets/ticket-in-progress.svg";
//import TicketSuccess from "./assets/ticket-success.svg";
//import TicketFailed from "./assets/ticket-failed.svg";
//import TicketStart from "./assets/photo-camera.svg";
import { ticketCheckCodeClose, ticketCheckCode } from "../../../bets/store/actions/tickets";

import { getBetsState } from "../../../bets/store/selectors/betData";
import { normalizePreMatchTicket } from "../../../bets/utils/normalizeTickets";
import "./index.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    zIndex: "1301!important",
    "& .MuiPaper-rounded": {
      borderRadius: "0"
    }
  },
  dialogTitle: {
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "0",
    marginBottom: "1em",
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogHeaderInProgress: {
    background: "#FF196E",
    padding: "5px 10px",
    "& .dh-logo": {
      width: "38px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff",
      position: "relative"
    },
    "& .dh-text:after": {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "bottom",
      animation: "dhEllipsis steps(4,end) 900ms infinite",
      content: '"\\2026"',
      width: "0px",
      position: "absolute"
    }
  },
  mt: {
    marginTop: "1em"
  },
  mt2: {
    marginTop: "3em"
  },
  dialogHeaderSuccess: {
    background: "#558707",
    padding: "5px 10px",
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff"
    }
  },
  dialogHeaderReoffered: {
    background: "#958707",
    padding: "5px 10px",
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff"
    }
  },
  dialogHeaderFailed: {
    background: "#FF196E",
    padding: "5px 10px",
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff"
    }
  },
  dialogHeaderStart: {
    background: "#285C98",
    padding: "5px 10px",
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#fff"
    }
  },
  dialogBodyInProgress: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "600",
      margin: "0 0 2em"
    }
  },
  dialogBodySuccess: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogBodyReoffered: {
    "& .db-text": {
      color: "#47214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    },
  },
  dialogBodyFailed: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogOK: {
    width: "100%",
    padding: "13px 0",
    borderRadius: "0",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "2em 0 1em",
    background: "linear-gradient(90deg, #295A91 0%, #2174E0 100%)"
  },
  ticketData: {
    textAlign: "left"
  },
  muted: {
    opacity: .8
  },
  won: {
    color: "#06D2BD",
  },
  lost: {
    color: "#FF196E"
  },
  fRight: {
    float: "right"
  },
  title: {
    marginBottom: "5px",
    fontSize: "13px"
  },
  subtitle: {
    marginBottom: "5px",
  },
  status: {
    margin: "5px 0",
    fontSize: "13px"
  },
  result: {
    display: "inline-block",
    marginRight: "5px",
    background: "#777",
    padding: "2px 3px",
    borderRadius: "2px",
    color: "#fff",
    fontWeight: "normal",
  },
  win: {
    fontSize: "15px",
    margin: "10px 0"
  },
  info: {
    height: "15px",
    width: "15px",
    verticalAlign: "middle",
    position: "relative",
    top: "-1px"
  },
  smaller: {
    fontSize: "11px",
    opacity: .7,
    margin: "0 0 5px",
  },
  input: {
    background: "#E6EEF1",
    color: "#17214D",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "8px 20px",
    borderRadius: "10px",
    border: 0
  }
});

const TicketModal = props => {
  const { openTicketCheck, ticketCheckCodeStatus, ticketCodeData, ticketCheckCodeClose, ticketCheckCode, onModalClose, bst, i18n, t } = props;
  const classes = useStyles();

  const [search, setSearch] = React.useState("");
  const handleSearchValueChange = (e) => {
    setSearch(e.target.value);
  }
  const doSearch = () => {
    if (search) {
      ticketCheckCode(search);
    }
  };
  const handleKeyDown = (event) => {
    if (event && event.key === 'Enter') {
      doSearch();
    }
  }

  if (!openTicketCheck) return null;

  const handleModalClose = () => {
    setSearch("");
    ticketCheckCodeClose();
    onModalClose();
  }

  let nt = {}; // hold normalized ticket data
  if (ticketCheckCodeStatus === "done") {
    nt = normalizePreMatchTicket({ ...ticketCodeData, payment: ticketCodeData.paymentAmount }, bst);
  }

  //console.log("ticketCheckCodeStatus", ticketCheckCodeStatus, "ticketCodeData", ticketCodeData);

  const formatValue = v => {
    const f = parseFloat(v);
    if (isNaN(f)) return 0;
    return f.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
  };

  let content = null;
  if (ticketCheckCodeStatus === "") {
    content = (
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogBodyInProgress}>
          <div className="db-text">
            {t("Type in bellow your ticket code")}
          </div>
          <div className="db-text text-center">
            <input
              onChange={handleSearchValueChange}
              onKeyDown={handleKeyDown}
              value={search}
              type="text"
              placeholder={t("ticket code")}
              className={classes.input}
            />
          </div>
          <div>
            <ButtonBase className={classes.dialogOK} onClick={doSearch}>
              {t("Search Ticket")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>
    );
  } else {
    content = (
      <DialogContent className={classes.dialogContent}>
        {ticketCheckCodeStatus === "pending" && (
          <div className={classes.dialogBodyInProgress}>
            <div className="db-text">
              {t("We're checking the ticket status. Please wait.")}
            </div>
          </div>
        )}
        {ticketCheckCodeStatus === "done" && (
          <div className={classes.dialogBodySuccess}>
            <div className={classes.ticketData}>
              {/* t("LOST") t("WON") t.("PAID OUT") t.("OPEN") */}
              <div className={classes.title}>
                {nt.type === "SIMPLU" ? t("SIMPLE") : t("SYSTEM")} | <span className={nt.status === "LOST" ? classes.lost : nt.status === "WON" ? classes.won : ''}>&nbsp;{nt.origStatus ? t(nt.origStatus) : t(nt.status)}</span>
                {nt.origStatus === "PAID OUT" && <span>&nbsp;| {t("Winnings")}: {formatValue(ticketCodeData.winnings)}&nbsp;| {t("Payout")}: {formatValue(ticketCodeData.payout)}</span>}
                {!nt.origStatus && nt.status === "WON" && <span>&nbsp;| {t("Winnings")}: {formatValue(ticketCodeData.winnings)}</span>}
                {ticketCodeData.cashBack === 1 && ticketCodeData.payout && <span>&nbsp;| {t("Cashbacked")}: {formatValue(ticketCodeData.payout)}</span>}
              </div>
              <div className={classes.subtitle}>
                <span className={classes.muted}>{t("Ticket Code")}:&nbsp;</span>
                {t(nt.idHash)}
                <span className={classes.muted}>&nbsp;| {moment(nt.createdAt).format("DD/MM/YYYY HH:mm")}</span>
              </div>
              <table className={`tcm-resp-table ${i18n.language === 'ro' ? 'ro' : ''}`}>
                <thead>
                  <tr>
                    <th>{t("Match")}</th>
                    <th>{t("Date/Hour")}</th>
                    <th>{t("Bet")}</th>
                    <th>{t("Outcome")}</th>
                    <th>{t("Odd")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {nt.bets.map((b, i) => {
                    const teams = [];
                    b.teams.forEach(team => teams.push(team.teamDisplayName));
                    const match = teams.join(" - ");

                    let res = b.status === "OPEN" ? (<span className={classes.result}>-:-</span>) : null;
                    if (b.results && b.results.length) {
                      b.results.forEach((r, i) => {
                        const hRes = r.halfResult.join(":");
                        res = (<span key={i} className={classes.result}>{r.halfName} {hRes}</span>);
                      });
                    }

                    return (
                      <tr key={i}>
                        <td>{match}</td>
                        <td>{moment(b.startAt).format("DD/MM/YYYY HH:mm")}</td>
                        <td>{b.betDisplayName}</td>
                        <td>{b.outcomeDisplayName}</td>
                        <td>{formatValue(b.oddValue)}</td>
                        <td className={"text-right"}>
                          {res}
                          {b.status === "WON" && <WonIcon className={classes.won} />}
                          {b.status === "LOST" && <LostIcon className={classes.lost} />}
                          {b.status === "OPEN" && <OpenIcon className={classes.open} />}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className={classes.status}>
                <span>{t("Stake")}</span>
                <span className={classes.fRight}>{formatValue(nt.stake)}</span>
              </div>
              {!!ticketCodeData.paymentTax && <div className={`${classes.status} ${classes.smaller}`}>
                <span>{t("T.A.")}</span>
                <span className={classes.fRight}>{formatValue(ticketCodeData.paymentTax)}</span>
              </div>}
              <div className={`${classes.status} ${classes.win}`}>
                <span>{t("Possible Winning")}</span>
                <span className={classes.fRight}>{formatValue(nt.winning)}</span>
              </div>
              <div className={classes.status}>
                <span>{t("Bonus")} ({nt.maxBonusPercentage}%)</span>
                <span className={classes.fRight}>{formatValue(nt.maxBonusPercentage ? (nt.bonusWonAmount ? nt.bonusWonAmount : nt.maxBonus) : 0)}</span>
              </div>
              {nt.bonusEligibles && nt.bonusEligibles.length !== 0 && <div className={classes.status}>
                <span>{t("Available Bonus")}: {nt.bonusEligibles.join(" + ")}</span>
              </div>}
            </div>
            <div>
              <ButtonBase className={classes.dialogOK} onClick={handleModalClose}>
                {t("OK")}
              </ButtonBase>
            </div>
          </div>
        )}
        {ticketCheckCodeStatus === "error" && (
          <div className={classes.dialogBodyFailed}>
            <div className="db-text">
              {t("An error occurred")}{": "}{t(ticketCodeData.toString())}
            </div>
            <div className={`${classes.mt} db-text`}>
              {t("If the problem persists, please contact the Customer Support department.")}
            </div>
            <div>
              <ButtonBase className={classes.dialogOK} onClick={handleModalClose}>
                {t("OK")}
              </ButtonBase>
            </div>
          </div>
        )}
      </DialogContent>
    );
  }

  return (
    <Dialog
      open={openTicketCheck}
      fullWidth={true}
      maxWidth={"md"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`${classes.root} betsModal`}
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">
        {ticketCheckCodeStatus === "pending" && (
          <div className={classes.dialogHeaderInProgress}>
            <div className="dh-text">{t("Ticket verification in progress")}</div>
          </div>
        )}
        {ticketCheckCodeStatus === "done" && (
          <div className={nt.status === "LOST" ? classes.dialogHeaderFailed : classes.dialogHeaderSuccess}>
            <div className="dh-text">{t("Ticket Info")}</div>
          </div>
        )}
        {ticketCheckCodeStatus === "error" && (
          <div className={classes.dialogHeaderFailed}>
            <div className="dh-text">{t("Verification failed")}</div>
          </div>
        )}
        {ticketCheckCodeStatus === "" && (
          <div className={classes.dialogHeaderStart}>
            <div className="dh-text">{t("Ticket Verification")}</div>
          </div>
        )}
      </DialogTitle>
      {content}
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  return {
    bst: bst,
    ticketCheckCodeStatus: bst.tickets.ticketCheckCodeStatus,
    ticketCodeData: bst.tickets.ticketCodeData
  };
};

const actionCreators = {
  ticketCheckCodeClose,
  ticketCheckCode
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(TicketModal));
