class ClientDetection {

    /**
     * realtime width and height
     * @returns {string}
     */
    static getScreenSize() {
        return window.screen.width + 'x' + window.screen.height;
    }

    /**
     * @returns {{name: string, majorVersion, version: string}}
     */
    static getBrowser() {

        if (typeof this.browser !== "undefined") return this.browser;

        let userAgent = window.navigator.userAgent;
        let browser = window.navigator.appName;
        let version = '' + parseFloat(window.navigator.appVersion);
        let nameOffset, verOffset, ix;

        // OperaViva
        if ((verOffset = userAgent.indexOf('Opera')) !== -1) {
            browser = 'Opera';
            version = userAgent.substring(verOffset + 6);
            if ((verOffset = userAgent.indexOf('Version')) !== -1) {
                version = userAgent.substring(verOffset + 8);
            }
        }
        // Opera Next
        if ((verOffset = userAgent.indexOf('OPR')) !== -1) {
            browser = 'Opera';
            version = userAgent.substring(verOffset + 4);
        }
        // Edge
        else if ((verOffset = userAgent.indexOf('Edge')) !== -1) {
            browser = 'Microsoft Edge';
            version = userAgent.substring(verOffset + 5);
        }
        // MSIE
        else if ((verOffset = userAgent.indexOf('MSIE')) !== -1) {
            browser = 'Microsoft Internet Explorer';
            version = userAgent.substring(verOffset + 5);
        }
        // Chrome
        else if ((verOffset = userAgent.indexOf('Chrome')) !== -1) {
            browser = 'Chrome';
            version = userAgent.substring(verOffset + 7);
        }
        // Safari
        else if ((verOffset = userAgent.indexOf('Safari')) !== -1) {
            browser = 'Safari';
            version = userAgent.substring(verOffset + 7);
            if ((verOffset = userAgent.indexOf('Version')) !== -1) {
                version = userAgent.substring(verOffset + 8);
            }
        }
        // Firefox
        else if ((verOffset = userAgent.indexOf('Firefox')) !== -1) {
            browser = 'Firefox';
            version = userAgent.substring(verOffset + 8);
        }
        // MSIE 11+
        else if (userAgent.indexOf('Trident/') !== -1) {
            browser = 'Microsoft Internet Explorer';
            version = userAgent.substring(userAgent.indexOf('rv:') + 3);
        }
        // Other browsers
        else if ((nameOffset = userAgent.lastIndexOf(' ') + 1) < (verOffset = userAgent.lastIndexOf('/'))) {
            browser = userAgent.substring(nameOffset, verOffset);
            version = userAgent.substring(verOffset + 1);
            if (browser.toLowerCase() === browser.toUpperCase()) {
                browser = navigator.appName;
            }
        }
        // trim the version string
        if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix);
        if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix);
        if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix);

        let majorVersion = parseInt('' + version, 10);
        if (isNaN(majorVersion)) {
            version = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        this.browser = { name: browser, version: version, majorVersion: majorVersion };

        return this.browser;
    }

    /**
     *
     * @returns {boolean | *}
     */
    static getMobile() {
        if (typeof this.mobile !== "undefined") return this.mobile;
        let navigatorVersion = window.navigator.appVersion;

        this.mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(navigatorVersion);

        return this.mobile;
    }

    /**
     *
     * @returns {{name: string, version: string}}
     */
    static getOS() {
        if (typeof this.os !== "undefined") {
            return this.os;
        }

        let navigatorVersion = navigator.appVersion;
        let userAgent = navigator.userAgent;

        let os = 'Unknown';
        let clientStrings = [
            { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Linux', r: /(Linux|X11)/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
        ];
        for (let id in clientStrings) {
            let cs = clientStrings[id];
            if (cs.r.test(userAgent)) {
                os = cs.s;
                break;
            }
        }

        let osVersion = "";

        if (/Windows/.test(os)) {
            try {
                osVersion = /Windows (.*)/.exec(os)[1];
            } catch (err) { };
            os = 'Windows';
        }
        switch (os) {
            case 'Mac OS X':
                osVersion = /Mac OS X ([\.\_\d]+)/.exec(userAgent);
                if (osVersion) {
                    osVersion = osVersion[1];
                } else {
                    osVersion = 0;
                }
                break;

            case 'Android':
                osVersion = /Android ([\.\_\d]+)/.exec(userAgent);
                if (osVersion) {
                    osVersion = osVersion[1];
                } else {
                    osVersion = 0;
                }
                break;

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(navigatorVersion);
                if (osVersion) {
                    osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
                } else {
                    osVersion = 0;
                }
                break;
        }

        return { name: os, version: osVersion };
    }

}

export default ClientDetection;

