import axios from 'axios';

class VivaAPI {
    constructor() {
        this.initialized = false;
        this.vivaInstance = null;
        this.store = null;
        this.vivaBaseUrl = process.env.CFW.vivaApiUrl;
        this.accessToken = null;
    }

    setStore(store) {
        this.store = store;
        this.init();
    }

    setToken(token) {
        if(token){
            this.accessToken = token;
            this.init();
        }
    }



    getToken() {
        return this.accessToken;
    }

    init() {


        if (!this.store) {
            return;
        }

        if (this.initialized) {
            return;
        }
        if(this.accessToken){
            this.initialized = true;
            let headers = {};
            headers['content-type'] = 'application/json';
            headers['Access-Control-Allow-Origin'] = '*';
            headers['X-Requested-With'] = 'XMLHttpRequest';
            headers['Authorization'] = 'Bearer ' + this.accessToken;
            let self = this;
            this.vivaInstance = axios.create({
                baseURL: self.vivaBaseUrl,
                headers: {
                    common: headers
                }
            });
        }


        this.activateRequestInterceptors();

    }

    activateRequestInterceptors() {
        if (!this.vivaInstance) {
            return;
        }

        this.vivaInstance.interceptors.request.use(request => {
            if (!request.hasOwnProperty("data")) {
                request.data = {};
            }

            return request;
        }, error => {
            console.error(`[ERROR] Server request error => ${error}`);
            console.error(`[NETWORK] Error, network disconnected!`);

            return Promise.reject(error);
        });
    }

    getInstance() {
        return this.vivaInstance;
    }

    getStore() {
        return this.store;
    }
}

export default new VivaAPI();
