import { takeEvery } from "redux-saga/effects";
import openSocket from "socket.io-client";

import { lottoConfig } from "../../api/config/lotto";

import { appConstants, authConstants } from "../actions/constants";
import { ticketStoreRecentlyWon, ticketDrawFinished } from "../actions/tickets";
import { drawingEventsSet } from "../actions/drawing-events";

import getStore from "../../store";
import { getLottoState } from "../selectors/lottoData";

import { createIntervalTicker } from "../../../utils/intervals";

import evBus from "../../utils/evbus";

const DEBUG = false;
let socket = null;
let processedEvents = [];
let draws = {};
let drawDripTimer = 0;

export const connect = () => {
	//console.log("connect lotto socket");

	if (socket !== null) {
		return;
	}

	socket = openSocket(lottoConfig.wsUrl, {
		path: lottoConfig.wsPath,
		forceNew: true,
		transports: ["websocket"],
		query: {
			protocol: lottoConfig.wsProtocol
		}
	});

	//console.log("lotto socket", socket);

	socket.on("connect", () => {
		//console.log("Lotto connected");

		const { auth } = getLottoState(getStore().getState());

		if (auth.token !== null) {
			socket.emit("login", {
				token: auth.token
			});
		}
	});

	socket.on("connect_error", error => {
		console.log("Lotto socket connect error", error);
	});

	socket.on("event_processed", function (data) {
		DEBUG && console.log("event processed", data.preview, data);
		const { lottoEvents } = getLottoState(getStore().getState());

		if (!data.r) {
			let r = 0;
			for (let i = 0; i < lottoEvents.items.length; i++) {
				if (lottoEvents.items[i].event_id === data.event_id) {
					r = lottoEvents.items[i].r;
					break;
				}
			}

			if (!r) {
				for (let i = 0; i < lottoEvents.expiredItems.length; i++) {
					if (lottoEvents.expiredItems[i].event_id === data.event_id) {
						r = lottoEvents.expiredItems[i].r;
						break;
					}
				}
			}

			if (r) {
				data.r = r
			}
		}

		data.started = false;
		data.current = 0;
		data.drawn = [];

		if (document.hidden) { // don't trigger any animations. there is no one to see them
			draws[data.event_id] = true;
			if (!data.preview) {
				getStore().dispatch(ticketDrawFinished(data));
			}
			return;
		}

		if (data.event_id in draws) {
			// draw was already shown. If this is not a preview trigger draw finished again to move tickets
			if (!data.preview) {
				DEBUG && console.log("received real results");
				
				// compare results
				const peIdx = processedEvents.findIndex(pe => pe.event_id === data.event_id);

				let sameResults = false;

				if (peIdx !== -1) {
					DEBUG && console.log(`event found on position ${peIdx}`);

					const previewNumbers = [...processedEvents[peIdx].numbers];
					const realNumbers = [...data.numbers];

					previewNumbers.sort();
					realNumbers.sort();

					if (previewNumbers.length === realNumbers.length) {
						sameResults = true;

						for (let i = 0; i < previewNumbers.length; i++) {
							if (previewNumbers[i] !== realNumbers[i]) {
								sameResults = false;
								break
							}
						}
					}

					if (!sameResults) {
						// results differ
						DEBUG && console.log("results differ");

						// send draw end
						evBus.emit("LOTTO_DRAW", { ...processedEvents[peIdx], status: "end" });

						// take out from process events
						processedEvents.splice(peIdx, 1)

						// force settlements with real numbers
						getStore().dispatch(ticketDrawFinished(data));
					} else {
						DEBUG && console.log("same results");
						
						processedEvents[peIdx].preview = false;
					}

					return;
				}

				// draw finished. settle with real numbers
				getStore().dispatch(ticketDrawFinished(data));
			}
		} else {
			// put event in draws
			draws[data.event_id] = true;

			// first time we see results. trigger draw
			evBus.emit("LOTTO_DRAW", { ...data, status: "start" });

			processedEvents.push(data);
			getStore().dispatch(drawingEventsSet(JSON.parse(JSON.stringify(processedEvents))));
		}
	});

	socket.on("ticket_won", data => {
		//console.log("Lotto ticket won", data);

		getStore().dispatch(ticketStoreRecentlyWon(data));
	});

	socket.on("manual_authorization", data => {
		//console.log("Lotto manual authorization", data);

		evBus.emit("ws.notification.authorization", data);
	});
};

export const disconnect = () => {
	if (socket !== null) {
		socket.disconnect();
		socket = null;
	}
};

const BALL_DRAW_INTERVAL = 5 * 1000;

const drawDrip = () => {
	createIntervalTicker({
		name: "lotto draw drip",
		interval: BALL_DRAW_INTERVAL,
		callback: cp => {
			if (processedEvents.length === 0) {
				return;
			}
	
			DEBUG && console.log(`draw drip: timeDiff: ${cp.timeDiff}`, processedEvents);
	
			// how many balls to advance in the time interval
			let cballAdv = Math.ceil(cp.timeDiff/BALL_DRAW_INTERVAL);

			const rpe = [];
	
			processedEvents.forEach(pe => {
				let ballAdv = cballAdv;

				if (!pe.started) {
					DEBUG && console.log(`pe start ${ballAdv}`, pe);
	
					// adjust how many balls we are showing
					ballAdv = ballAdv - 1;
					
					// mark the draw as started
					pe.started = true;

					// if there are 0 remaining balls then we just trigger draw start
					// ball animations will be shown when the timer fires next
					if (ballAdv === 0) {
						evBus.emit("LOTTO_DRAW", { ...pe, status: "start" });
						rpe.push(pe);
						return;
					}
				} 
				
				if (ballAdv > (pe.numbers.length - pe.drawn.length)) {
					DEBUG && console.log(`pe end ${ballAdv}, ${pe.numbers.length - pe.drawn.length}`, pe);
	
					evBus.emit("LOTTO_DRAW", { ...pe, status: "end" });
	
					getStore().dispatch(ticketDrawFinished(pe));
					return;
				}
	
				while (ballAdv > 0) {
					if (pe.current) pe.drawn.push(pe.current);
					pe.current = pe.numbers[pe.drawn.length];

					DEBUG && console.log(`pe draw ${ballAdv}`, pe.current, pe);
					ballAdv--;
				}
	
				evBus.emit("LOTTO_DRAW", { ...pe, status: "draw" });
	
				rpe.push(pe);
			});

			processedEvents = rpe;
			getStore().dispatch(drawingEventsSet(JSON.parse(JSON.stringify(processedEvents))));
		}
	});
};

function* lottoInitializeSaga() {
	//console.log("connect");

	yield connect();
	yield drawDrip();
}

function* lottoPlayerLoginSaga(action) {
	//console.log("lotto ws login", action, socket);

	if (socket !== null) {
		// authenticate websocket
		yield socket.emit("login", {
			token: action.token
		});
	}
}

function* lottoPlayerLogoutSaga() {
	if (socket !== null) {
		// authenticate websocket
		yield socket.emit("logout");
	}
}

export default function* watchWS() {
	//console.log("[saga] watching ws");
	yield takeEvery(appConstants.INITIALIZE, lottoInitializeSaga);
	yield takeEvery(authConstants.PLAYER_LOGIN, lottoPlayerLoginSaga);
	yield takeEvery(authConstants.PLAYER_LOGOUT, lottoPlayerLogoutSaga);
}
