import React from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./index.scss";

import { getTicketsModules } from "./ticketsModules";
import ModalHeader from "./components/ModalHeader";
import TabsMenu from "./components/TabsMenu";

//import Surprise from "./Surprise";
//import Cashout from "./Cashout";
import Opened from "./Opened";
import Settled from "./Settled";
import Reserved from "./Reserved";

const useStyles = makeStyles({
  root: {}
});

const MyTickets = ({ auth, t }) => {
  const classes = useStyles();
  const history = useHistory();

  const categories = [
    { sportName: t("Opened") },
    { sportName: t("Settled") },
  ];

  if (!window.config.ticketsHideReservedTab || window.config.ticketsHideReservedTab === "0") {
    categories.push({ sportName: t("Reserved") });
  }

  const goBack = () => {
    history.goBack();
  };
  const onAction = () => { };

  const modules = getTicketsModules();

  const [viewIndex, setViewIndex] = React.useState(0);
  const [category, setCategory] = React.useState(0); // eslint-disable-line
  const handleCategoryChange = index => {
    setCategory(index);
  };

  if (auth && ["user", "token"].indexOf(auth.auth_type) === -1) {
    window.location.hash = "";
    return null;
  }

  return (
    <div className={`${classes.root} myTickets`}>
      <ModalHeader
        title={t("My Tickets")}
        active={false}
        type={"none"}
        goBack={goBack}
        onAction={onAction}
        theme={modules["options"].theme}
      />
      <TabsMenu value={category} tabs={categories} onChange={handleCategoryChange} centered theme={modules["options"].theme} />
      <SwipeableViews index={category} onChangeIndex={handleCategoryChange}>
        <Opened index={category} viewIndex={viewIndex} setViewIndex={setViewIndex} />
        <Settled index={category} viewIndex={viewIndex} setViewIndex={setViewIndex} />
        <Reserved index={category} viewIndex={viewIndex} setViewIndex={setViewIndex} />
      </SwipeableViews>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication
  };
};

export default connect(mapStateToProps)(withTranslation()(MyTickets));
