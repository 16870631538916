import { winCoinsConstants } from "./constants";

export const winCoinsSystemsLoad = (data) => ({
  type: winCoinsConstants.LOAD,
  data
});

export const winCoinsSystemsLoaded = (data) => ({
  type: winCoinsConstants.LOADED,
  data
});

export const winCoinsSystemsClear = (data) => ({
  type: winCoinsConstants.CLEAR,
  data
});

export const winCoinsCollectDaily = (data) => ({
  type: winCoinsConstants.COLLECT_DAILY,
  data
});

export const winCoinsCollectHourly = (data) => ({
  type: winCoinsConstants.COLLECT_HOURLY,
  data
});

export const winCoinsCollectBoost = (data) => ({
  type: winCoinsConstants.COLLECT_BOOST,
  data
});

export const winCoinsUpdateSystem = ({ id, data }) => ({
  type: winCoinsConstants.UPDATE_SYSTEM,
  id,
  data
});

export const winCoinsCollectingStatus = (data) => ({
  type: winCoinsConstants.STATUS,
  data
});

export const winCoinsCollectReferrer = (data) => ({
  type: winCoinsConstants.COLLECT_REFERRER,
  data
});

