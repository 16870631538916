import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Live from "./Live";
import Prematch from "./Prematch";
import LiveHeader from "./LiveHeader";
import PrematchHeader from "./PrematchHeader";
import BetSelect from "../BetSelect/indexUK";
import Favorite from "../../elements/Favorite";
import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";
import { showMatch } from "../../utils/betUtils";
import { ReactComponent as InfoOff } from "../../assets/icons/info-off.svg";

import {
	makeGetSports,
	makeGetCategories,
	makeGetTournaments,
	makeGetSelectedBet,
	makeMatchDetailsOpened,
	makeGetMatchBettingStatus,
	makeGetMatchBets,
	makeGetStateMatch
} from "../../store/selectors/betData";
import { registerExecution } from "./waterfall";
import { emptyDesktop, emptyMobile } from "../../utils/empty";

import { formatTicketBetTitle } from "../../utils/formatters";

const useStyles = makeStyles({
	placeHolder: {
		minHeight: "92px",
		padding: props => (props.padding ? props.padding : "5px 12px 5px 18px"),
		backgroundColor: props => (props.bgColor ? props.bgColor : props.dark ? "#101320" : "#fff"),
		borderRadius: 7,
		margin: props => (props.margin ? props.margin : "0 0 10px 0"),
		cursor: "pointer",
		"-webkit-tap-highlight-color": "transparent"
	},
	eventWrapper: {
		padding: props => (props.padding ? props.padding : "5px 12px 5px 18px"),
		backgroundColor: props => (props.bgColor ? props.bgColor : props.dark ? "#101320" : "#fff"),
		borderRadius: 7,
		margin: props => (props.margin ? props.margin : "0 0 10px 0"),
		cursor: "pointer",
		"-webkit-tap-highlight-color": "transparent"
	},
	dark: {
		backgroundColor: "#070D29",
		color: "#FFFFFF"
	},
	betSelectWrapper: {
		padding: "0px 2px 0px 0px",
		//marginBottom: "4px"
	},
	betsLocked: {
		height: "50px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center"
	},
	lockIcon: {
		color: "#999",
		"& svg": {
			fill: "#999"
		}
	},
	lockText: {
		color: "#999",
		fontSize: "12px"
	},
	betOdd: {
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center",
		justifyContent: "space-between",
		fontSize: "9px",
		fontWeight: "600",
		marginBottom: "4px",
		"& .bet-title": {
			flex: "2 1 auto",
			color: "#979DAB",
			textTransform: "uppercase"
		},
		"& .bet-outcome": {
			color: "#FF196E",
			minWidth: "30px",
			paddingLeft: "10px",
			textAlign: "right"
		},
		"& .bet-value": {
			color: "#17214D",
			minWidth: "30px",
			paddingLeft: "10px",
			textAlign: "right",
			whiteSpace: "nowrap"
		}
	},
	view: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		"&>div": {
			flex: "1 1 auto"
		},
		"&>div:first-child": {
			//paddingRight: "8px",
			width: "50%"
		},
		"&>div:nth-child(2)": {
			paddingLeft: "4px",
			width: "50%"
		}
	},
	desktopView: {
		"&>div:first-child": {
			//paddingRight: "8px",
			width: "calc(45% - 65px)"
		},
		"&>div:nth-child(2)": {
			paddingLeft: "4px",
			width: "55%"
		}
	},
	titleArrange: {
		marginTop: "4px",
		height: "20px",
		marginBottom: "6px"
	},
	fl: {
		overflow: "hidden",
		width: "100%",
		"& .item": {
			display: "block",
			float: "left"
		},
		"&.items_1": {
			"& .item": {
				width: "100%"
			}
		},
		"&.items_2": {
			"& .item": {
				width: "50%"
			}
		},
		"&.items_3": {
			"& .item": {
				width: "33%"
			}
		}
	},
	fav: {
		position: "relative",
		width: "65px",
		borderLeft: "1px solid #DFE7EF",
		"&.dark": {
			borderLeftColor: "#202640"
		},
		padding: "0 0 0 5px",
		"& .favIcon": {
			position: "absolute",
			top: "5px",
			right: "0"
		},
		"& .betCount": {
			position: "absolute",
			right: "0",
			bottom: "5px",
			color: "#5F78A9",
			fontSize: "16px",
			fontWeight: "bold"
		}
	},
	skeletonElem: {
		backgroundColor: "#ccc",
		marginBottom: "7px"
	},
	desc: {
		borderTop: "1px solid #EEF0F1",
		margin: "0 -12px 0 -18px",
		padding: "5px 12px 0 18px",
		fontSize: "10px",
		fontStyle: "italic",
		color: "#989898",
		"&.dark": {
			borderTop: "1px solid #1E2751",
		}
	},
	descIconWrapper: {
		cursor: "pointer",
		padding: "10px 20px 0 10px",
		margin: "-10px -10px 0 -10px",
		"&.dark": {
			"& circle": {
				fill: "#1E2751"
			}
		}
	},
	descCollapsed: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis"
	},
	messages: {

	},
	evaluateMessageError: {
		color: "#FF1A6C",
		fontSize: "10px",
		fontWeight: "600",
	},
	evaluateMessageWarning: {
		color: "#F8991D",
		fontSize: "10px",
		fontWeight: "600",
	},
	evaluateBorderWarning: {
		border: "2px solid #F8991D"
	},
	evaluateBorderError: {
		border: "2px solid #E286A7"
	},
});

const Match = props => {
	const classes = useStyles(props);

	const {
		showMatchDetails,
		sports,
		categories,
		tournaments,
		match,
		dark,
		simplified,
		oddSelected,
		refDate,
		showFixed,
		inBetSlip,
		fixedOdd,
		disableDetails,
		inGroup,
		showFav,
		className,
		bv,
		rv,
		t,
		matchGroup,
		matchDetailOpened,
		matchBettingStatus,
		matchBets,
		evaluateMessages,
		evaluateValid,
	} = props;

	let isDesktop = true;

	let isDesktop1600 = useMediaQuery("(min-width:1600px)");

	const isDesktop1920 = useMediaQuery("(min-width:1920px)");
	const isDesktop2560 = useMediaQuery("(min-width:2560px)");
	const [isMounted, setIsMounted] = React.useState(false);

	let hasDescription = match.description ? true : false;
	const [showInfo, setShowInfo] = React.useState(false);
	const toggleShowInfo = (e) => {
		if (e && typeof e.stopPropagation === "function") e.stopPropagation();
		setShowInfo(v => !v);
	};

	let columns = 1;
	let sp = false;
	if (isDesktop2560) {
		columns = 3;
		if (matchDetailOpened) {
			columns = 2;
		}
	} else if (isDesktop1920) {
		columns = 2;
		if (matchDetailOpened) {
			columns = 1;
		}
	}
	if (matchGroup.indexOf("_wa") > -1 || matchGroup.indexOf("_wp") > -1) {
		columns = 1;
		sp = true;
	}

	if (inBetSlip) {
		isDesktop = false;
		isDesktop1600 = false;
		columns = 1;
	}
	const history = useHistory();

	React.useEffect(() => {
		let active = true;
		registerExecution(() => {
			if (active) setIsMounted(true);
		});
		return () => {
			active = false;
		};
	}, []); // eslint-disable-state

	if (!match) return null;

	// check if the match has the selected bet
	/*
	if (groupSelectedBet !== null) {
		if (!match.matchBets.find(mb => mb.idBet === groupSelectedBet.midBet)) {
			return null;
		}
	}
	*/

	const titleParts = [];
	if (sports && typeof sports[match.idSport] !== "undefined" && sports[match.idSport].sportName)
		titleParts.push(sports[match.idSport].sportName);
	if (
		categories &&
		typeof categories[match.idCategory] !== "undefined" &&
		categories[match.idCategory].categoryName
	)
		titleParts.push(categories[match.idCategory].categoryName);
	if (
		tournaments &&
		typeof tournaments[match.idTournament] !== "undefined" &&
		tournaments[match.idTournament].tournamentName
	)
		titleParts.push(tournaments[match.idTournament].tournamentName);

	const title = titleParts.join(" / ");

	const sMatch = showMatch(match, history);
	const onMatchDetails = () => {
		if (inBetSlip) return;
		if (typeof showMatchDetails === "function") {
			showMatchDetails(match.mType, match.idMatch);
			return;
		}
		sMatch();
	};
	const favorite = (
		<Favorite
			mType={match.mType}
			dark={dark}
			idMatch={match.idMatch}
			winnerPlus={match.winnerPlus}
			winnerAdv={match.winnerAdv}
			winnerSpecial={match.winnerSpecial}
			displayId={match.displayId}
			isDesktop={isDesktop1600}
			justFav={true}
		/>
	);

	if (!isMounted) {
		let cnt = null;
		if (isDesktop1600) {
			cnt = emptyDesktop;
		} else {
			cnt = emptyMobile;
		}
		return (
			<div className={classes.placeHolder}>
				{cnt}
			</div>
		);
	}

	return (
		<div
			className={clsx(
				"layoutUK",
				classes.eventWrapper,
				{
					[classes.dark]: dark,
					"wf-dark-mode": dark,
					"wf-light-mode": !dark,
					"wf-live-match": match.mType === "live",
					"wf-pre-match": match.mType !== "live",
				},
				className,
				"match-card-container",
				evaluateMessages && evaluateMessages.length !== 0 ? evaluateValid ? classes.evaluateBorderWarning : classes.evaluateBorderError : ""
			)}
			data-id={match.idMatch}
			data-type={match.mType}
			onClick={onMatchDetails}
		>
			<div>
				<div
					className={
						isDesktop ? `${classes.view} ${isDesktop1600 ? classes.desktopView : ""}` : ""
					}
				>

					<div>
						{match.mType === "live" ? (
							<React.Fragment>
								{!isDesktop1600 && (
									<LiveHeader
										idMatch={match.idMatch}
										mType={match.mType}
										title={title}
										dark={dark}
										disableDetails={disableDetails}
										inGroup={inGroup}
										showFav={showFav}
										inBetSlip={inBetSlip}
										fixedOdd={fixedOdd}
									/>
								)}
								<Live
									idMatch={match.idMatch}
									mType={match.mType}
									title={title}
									dark={dark}
									disableDetails={disableDetails}
									inGroup={inGroup}
									showFav={showFav}
									inBetSlip={inBetSlip}
								/>
							</React.Fragment>
						) : (
							<React.Fragment>
								{!isDesktop1600 && (
									<PrematchHeader
										idMatch={match.idMatch}
										mType={match.mType}
										title={title}
										dark={dark}
										refDate={refDate}
										disableDetails={disableDetails}
										inGroup={inGroup}
										showFav={showFav}
										inBetSlip={inBetSlip}
										fixedOdd={fixedOdd}
									/>)}
								<Prematch
									idMatch={match.idMatch}
									mType={match.mType}
									title={title}
									dark={dark}
									refDate={refDate}
									disableDetails={disableDetails}
									inGroup={inGroup}
									showFav={showFav}
									inBetSlip={inBetSlip}
								/>
							</React.Fragment>
						)}
						{simplified === false && !!fixedOdd === true && (
							<div className={classes.betSelectWrapper}>
								<div className={classes.betOdd}>
									<div className="bet-title">
										{formatTicketBetTitle(fixedOdd.bet)}
									</div>
									<div className="bet-outcome">
										{fixedOdd.betOutcome.betOutcomeDisplayName}
									</div>
									<div className="bet-value">{fixedOdd.oddValue}</div>
								</div>
							</div>
						)}
					</div>
					<div>
						{simplified === false && (
							<div className={classes.betSelectWrapper}>
								{!!fixedOdd === false && matchBettingStatus && matchBets.length > 0 && !props.hasError && (
									<div className={`${classes.fl} items_${columns}`}>
										{isMounted &&
											[...Array(columns).keys()].map(k => (
												<div className="item" key={k}>
													<BetSelect
														idMatch={match.idMatch}
														mType={match.mType}
														dark={dark}
														oddSelected={oddSelected}
														showFixed={showFixed}
														betSlip={inBetSlip}
														bv={bv}
														rv={rv}
														matchGroup={`${matchGroup}_${k}`}
														lastInGroup={
															isDesktop1600 ? false : k === columns - 1
														}
														sp={sp}
													/>
												</div>
											))}
									</div>
								)}
								{!!fixedOdd === false &&
									(!matchBettingStatus || matchBets.length === 0) && (
										<React.Fragment>
											<div
												className={`d-flex flex-row flex-nowrap align-items-center justify-content-between ${classes.titleArrange}`}
											>
												<div>&nbsp;</div>
												<div>
													{!isDesktop1600 && !inBetSlip && (
														<React.Fragment>{favorite}</React.Fragment>
													)}
												</div>
											</div>
											<div className={classes.betsLocked}>
												<div className={classes.lockIcon}>
													<LockOutlinedIcon />
												</div>
												<div className={classes.lockText}>
													{matchBets.length === 0
														? t("NO BETS AVAILABLE")
														: t("EVENT BETS LOCKED")}
												</div>
											</div>
										</React.Fragment>
									)}
							</div>
						)}
					</div>
					{isDesktop1600 && (
						<div className={`${classes.fav} ${dark ? "dark" : ""}`}>
							<div className="favIcon">{favorite}</div>
							<div className="betCount">
								+
							{match && matchBets && matchBets.length && matchBettingStatus
									? matchBets.length
									: 0}
							</div>
						</div>
					)}
				</div>
				{evaluateMessages && evaluateMessages.length !== 0 && <div className={classes.messages}>
					{
						evaluateMessages.map((m, i) => (<div className={evaluateValid ? classes.evaluateMessageWarning : classes.evaluateMessageError} key={i}>{m}</div>))
					}
				</div>}
			</div>
			{hasDescription && <div className={`d-flex flex-row align-items-start ${classes.desc} ${dark ? 'dark' : ''}`}>
				<div
					className={`${classes.descIconWrapper} ${dark ? 'dark' : ''}`}
					onClick={toggleShowInfo}
				>
					{/*showInfo && <InfoOn />*/}
					{/*!showInfo && <InfoOff />*/}
					<InfoOff />
				</div>
				<div className={`w100 ${!showInfo ? /*classes.descCollapsed*/ '' : ''}`}>{match.description}</div>
			</div>}
		</div>
	);
};

Match.propTypes = {
	simplified: PropTypes.bool,
	oddSelected: PropTypes.string,
	matchGroup: PropTypes.string
};

Match.defaultProps = {
	simplified: false,
	oddSelected: "",
	matchGroup: "global"
	//match: {}
};

const getSports = makeGetSports();
const getCategories = makeGetCategories();
const getTournaments = makeGetTournaments();
const getSelectedBet = makeGetSelectedBet();
const getMatchDetailsOpened = makeMatchDetailsOpened();
const getMatchBettingStatus = makeGetMatchBettingStatus();
const getMatchBets = makeGetMatchBets();
const getStateMatch = makeGetStateMatch();

const mapStateToProps = (state, props) => ({
	sports: getSports(state, props),
	categories: getCategories(state, props),
	tournaments: getTournaments(state, props),
	groupSelectedBet: getSelectedBet(state, props),
	matchDetailOpened: getMatchDetailsOpened(state),
	matchBettingStatus: getMatchBettingStatus(state, props),
	match: getStateMatch(state, props),
	matchBets: getMatchBets(state, props)
});

export default withTranslation()(connect(mapStateToProps)(Match));
