import { combineReducers } from "redux";

import tournaments_groups from "./tournaments_groups";
import tournament from "./tournament";
import leaderboard from "./leader_board";
import history from "./history";

export const tournamentsMissionsRootReducer = combineReducers({
  groups: tournaments_groups,
  tournament,
  leaderboard,
  history,
});

const rootReducer = combineReducers({
  tournamentsMissions: tournamentsMissionsRootReducer
});

export default rootReducer;
