import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import ClearIcon from "@material-ui/icons/Clear";
import { withRouter } from 'react-router-dom';

import "./account-limits.scss";

import { ReactComponent as WarningIcon } from "../assets/limit-warning.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*
X60S20X4J.6499
X61W6KG5Q.6805
*/

const DepositTicketDialog = (props) => {
  const { open, onClose, t, history, player, limits, amount } = props;

  let isValidKYC = false;
  if (window.config.platformType.toString() === "1") {
    isValidKYC = player.accountVerified && player.accountStatus === 1 && player.accountSubStatus === 3;
  } else {
    isValidKYC = player.verified && player.account_status === 1 && player.account_sub_status === 3;
  }

  let limitId = limits["1"] && limits["1"].id ? "1" : limits["2"] && limits["2"].id ? "2" : limits["3"] && limits["3"].id ? "3" : null;
  let limit = limitId ? limits[limitId] : null;

  let limitAmmount = 0;
  if (!isValidKYC && limits["KYC"] && limits["KYC"]["limit_amount"]) {
    limitAmmount = limits["KYC"]["limit_amount"];
  } else if (limit && limit["limit_amount"]) {
    limitAmmount = limit["limit_amount"];
  }

  const fixNow = () => {
    onClose();
    if (!isValidKYC) {
      history.push("/profile/documents");
      return;
    }
    history.push("/profile/responsibly");
  };

  const onCloseDialog = () => {
    onClose();
    history.push("/");
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={onCloseDialog}
      TransitionComponent={Transition}
      className="account-limits-dialog"
    >
      <div className="account-limits-dialog-body">
        <div className={"clear"} onClick={onCloseDialog}>
          <ClearIcon />
        </div>
        <div className="logo">
          <WarningIcon />
        </div>
        <h2 className="title">{t("Deposit is not possible")}</h2>
        <div className="divider"><div>{t("Deposit limits exceeded")}</div></div>
        <div className="limits">
          <div className="left-limit">
            <div className="subtitle">{t("Ticket value")}:</div>
            <div className="value">{amount} <span className="currency">{"Lei"}</span></div>
          </div>
          <div className="right-limit">
            <div className="subtitle">{t("Available limit")}:</div>
            <div className="value">{limitAmmount} <span className="currency">{"Lei"}</span></div>
          </div>
        </div>
        <div className="explanation">
          <div><b>{t("Limit type")}:</b></div>
          {!isValidKYC && <div>- {t("requires identity verification")}</div>}
          {limitId !== null && <div>- {t("requires the modification of the deposit limits set by you. The limit increase will be applied 48 hours after the change")}</div>}
        </div>
        <div className="regular-button" onClick={fixNow}>{t("Solve now")}</div>
        <div className="cancel-button" onClick={onCloseDialog}>{t("dCancel")}</div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  return {
    player: state.profile,
  };
};

const actionsBuilder = {
};

export default withTranslation()(connect(mapStateToProps, actionsBuilder)(withRouter(DepositTicketDialog)));