import React from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./happy-hours.scss";
import TermsDialog from "../../components/terms-dialog";

import { closeGameLauncher } from "../../../../store/actions/games";
import DialogSwitchTournament from "../../../../tournaments-missions/dialogs/dialog-switch-tournament";

import IconDots from "../../assets/icon-dots.svg";
import IconClose from "../../assets/icon-close.svg";

const PortraitMode = ({ data, t, closeGameLauncher, history }) => {

  const [openTournamentsDialog, setOpenTournamentsDialog] = React.useState(false);
  const handleCloseTournamentsDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(false);
  };
  const showMenu = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(true);
  };
  const closeGame = () => {
    closeGameLauncher();
    if (window._CanGoBack) {
      history.goBack();
      return;
    }
    history.push("/cazino/jocuri");
  };

  const [state, setState] = React.useState(data);
  React.useEffect(() => {
    setState(data);
  }, [data]);

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  }

  if (!state.enabled) return null;

  const prize = data.prize_current.custom;

  return (
    <div className={`hh-parent hh-spc`} >
      <div className={`hh-root hh-portrait hh-special`} >
        <div className="hh-special-prize" onClick={openDialog}>
          <div className="hh-sp-image">
            <img src={prize.image} alt="" />
          </div>
          <div className="hh-sp-title">{data.name}</div>
          <div className="hh-sp-footer">
            {prize.description}
          </div>
          <div className="hh-sp-help">?</div>
        </div>
      </div>
      <div className="hh-sfooter-txt">
        <div className="hh-fi" onClick={closeGame}>
          <img src={IconClose} alt="" />
        </div>
        <div className="hh-fi" onClick={showMenu}>
          <img src={IconDots} alt="" />
        </div>
      </div>
      {openTournamentsDialog && <DialogSwitchTournament open={openTournamentsDialog} onClose={handleCloseTournamentsDialog} />}
      <TermsDialog open={open} prize={prize} name={data.name} onClose={closeDialog} />
    </div>
  );
};

const actions = {
  closeGameLauncher
};

export default connect(null, actions)(withRouter(withTranslation()(PortraitMode)));
