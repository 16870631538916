import React from 'react';
import { connect } from "react-redux";

import WinPanelOnlineSlots from "../branding/online-slots/win-panel";
import WinPanelBetsPrematch from "../branding/bets-prematch/win-panel";
import WinPanelBetsLive from "../branding/bets-live/win-panel";
import { makeGetPrizeWonByType, makeGetEventByType } from "../selectors/happy-hour";

const WinPanel = (props) => {
  const { data, event_type, prizeWon } = props;

  if (!prizeWon) return null;
  if (!data) return null;
  const happyHourBetsEnabled =
    window.config
      && typeof window.config.happyHourBetsEnabled !== "undefined"
      && window.config.happyHourBetsEnabled === "1" ? true : false;


  if (event_type === "bets-prematch") {
    if (!happyHourBetsEnabled) return null;
    return <WinPanelBetsPrematch data={data} prizeWon={prizeWon} />;
  }

  if (event_type === "bets-live") {
    if (!happyHourBetsEnabled) return null;
    return <WinPanelBetsLive data={data} prizeWon={prizeWon} />;
  }

  return <WinPanelOnlineSlots data={data} prizeWon={prizeWon} />;
}

const makeMapStateToProps = () => {
  const getPrizeWonByType = makeGetPrizeWonByType();
  const getEventsByType = makeGetEventByType();

  return (state, props) => ({
    prizeWon: getPrizeWonByType(state, props),
    data: getEventsByType(state, props),
  });
};

export default connect(makeMapStateToProps)(WinPanel);