export const tournamentGroupsConstants = {
  LOAD: "TM_LOAD_TOURNAMENTS_GROUPS",
  LOADING: "TM_LOADING_TOURNAMENTS_GROUPS",
  LOADED: "TM_LOADED_TOURNAMENTS_GROUPS",
  RESET_LOADED: "TM_RESET_LOADED_TOURNAMENTS_GROUPS",
  RERENDER: "TM_RERENDER",
  TOURNAMENT_ENDED: "TM_TOURNAMENT_ENDED",
  TOURNAMENT_CREATED: "TM_TOURNAMENT_CREATED",
  HAPPY_HOURS_TOGGLE: "TM_HAPPY_HOURS_TOGGLE",
};

export const leaderBoardConstants = {
  LOAD: "TM_LOAD_LEADER_BOARD",
  LOADING: "TM_LOADING_LEADER_BOARD",
  LOADED: "TM_LOADED_LEADER_BOARD",

  LOAD_PLAYER: "TM_LOAD_PLAYER_LEADER_BOARD",
  LOADING_PLAYER: "TM_LOADING_PLAYER_LEADER_BOARD",
  LOADED_PLAYER: "TM_LOADED_PLAYER_LEADER_BOARD",
};

export const tournamentConstants = {
  ENROLL: "TM_ENROLL_TOURNAMENT",
  ENROLL_RESPONSE: "TM_ENROLL_TOURNAMENT_RESPONSE",
  ENROLL_ERROR: "TM_ENROLL_TOURNAMENT_ERROR",
  ENROLL_CLEAR_ERROR: "TM_ENROLL_TOURNAMENT_CLEAR_ERROR",
  ACTIVATE: "TM_ACTIVATE_TOURNAMENT",
  ACTIVATE_RESPONSE: "TM_ACTIVATE_TOURNAMENT_RESPONSE",
  ACTIVATE_ERROR: "TM_ACTIVATE_TOURNAMENT_ERROR",
  ACTIVATE_CLEAR_ERROR: "TM_ACTIVATE_TOURNAMENT_CLEAR_ERROR",
};

export const historyConstants = {
  LOAD: "TM_LOAD_HISTORY",
  LOADING: "TM_LOADING_HISTORY",
  LOADED: "TM_LOADED_HISTORY",

  LOAD_LEADER_BOARD: "TM_LOAD_HISTORY_LEADER_BOARD",
  LOADING_LEADER_BOARD: "TM_LOADING_HISTORY_LEADER_BOARD",
  LOADED_LEADER_BOARD: "TM_LOADED_HISTORY_LEADER_BOARD",

  CLAIM_PRIZE: "TM_HISTORY_CLAIM_BOARD",
  CLAIM_PRIZE_BY_ID: "TM_HISTORY_CLAIM_PRIZE_BY_ID",
  CLAIMED_PRIZE: "TM_HISTORY_CLAIMED_BOARD",

  LOAD_PRIZES: "TM_LOAD_PRIZES",
  LOADED_PRIZES: "TM_LOADED_PRIZES",
};
