import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import { config as presets } from "react-spring";
import { withRouter } from "react-router-dom";

import "./component.scss";
import Debugger from "../../../utils/logger";
import { loadTournamentsGroups } from "../../store/actions/tournaments_groups";
import { loadPlayerLeaderBoard } from "../../store/actions/leader_board";
import { tournamentActivate, tournamentEnroll } from "../../store/actions/tournament";
import { doLogin } from "../../../store/actions/authentication";
import { getBetsState } from "../../../bets/store/selectors/betData";
import { betsBonusRequestEvaluation } from "../../../bets/store/actions/betsSlip";
import { getData, getText } from "../../../lobby/utils/functions";
import DefaultAvatar from "../../assets/img-avatar-default.png";

import Timer from "../timer";

import CheckOn from "./assets/check-on.svg";
import Warning from "./assets/warning.svg";
import Error from "./assets/error.svg";
import StarUp from "./assets/star-up.svg";
import StarFilledBig from "./assets/start-filled-big.svg";
import StarFilled from "./assets/start-filled.svg";
import StarEmpty from "./assets/start-empty.svg";
import TextTransition from "../text-transition";
import ScalableText from '../scalable-text';
import { cloneDeep } from 'lodash';
import BoosterInfo from './booster-info';
import RulesPopup from "./rules-popup";
import RankingInfo from "./ranking-info";

import Indicator from "./assets/icon-svg-arrow.svg";

const logger = Debugger(Debugger.DEBUG, "tm-sb");

const ERROR = "ERROR";
const NOT_ENROLLED = "NOT_ENROLLED";
const NOT_ACTIVE = "NOT_ACTIVE";
const INFO = "INFO";

const convertArgs = (args, t) => {
  let index = 0;
  let txt = args[0].replace(/%s/g, (...args) => { return `{{PARM${index++}}}`; });
  let params = {};

  for (let i = 1; i < args.length; i++) {
    params[`PARM${i - 1}`] = args[i];
  }

  return t(txt, params);
}

const SportBetslipComponent = (props) => {
  const { groups, groupsLoaded, t, i18n, tournamentActivate, tournamentEnroll, authentication,
    history, doLogin, loadTournamentsGroups, tournamentEvaluate, tournamentEvaluateInProgress, eventNo, stake, odd, betsBonusRequestEvaluation,
    ranking, loadPlayerLeaderBoard
  } = props;

  const [group, setGroup] = React.useState(null);
  const [tournament, setTournament] = React.useState(null);

  const [rulesOpen, setRulesOpen] = React.useState(false);
  const [showErrorDetails, setShowErrorDetails] = React.useState(false);
  const [textIndex, setTextIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setTextIndex(index => index + 1),
      6000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  React.useEffect(() => {
    if (!groupsLoaded) {
      loadTournamentsGroups();
    }
  }, [groupsLoaded]); // eslint-disable-line

  React.useEffect(() => {
    let filteredGroups = groups.filter(g => g.type === 3);
    let done = false;

    if (filteredGroups.length) {
      filteredGroups.forEach(g => {
        if (done) return;

        if (!g) return;

        const tournaments = [...g.tournaments];

        tournaments.sort((a, b) => {
          if (a && a.start_date && b && b.end_date) {
            return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
          }
          return 0;
        });

        const now = moment().valueOf();
        const activeTournaments = tournaments.filter(t => {
          if (t && parseInt(t.end_date, 10) > now) return true;
          return false;
        });
        if (!activeTournaments.length) return;

        const t = activeTournaments[0];

        setGroup(g);
        setTournament(t);
        done = true;

        if (typeof ranking[t.id] === "undefined") {
          loadPlayerLeaderBoard({ tournamentId: t.id, inGame: true });
        }
      })
    }
  }, [groups, ranking, loadPlayerLeaderBoard]);


  if (!group || !tournament) return null;
  if (eventNo === 0) return null;

  let nextStake = 0;
  let nextOdd = 0;
  let nextEventNo = 0;

  let supportedTypes = ["stake", "odd", "eventNo"];
  let starsData = [];
  let descriptions = [];
  let descriptionsIndex = [];
  let max_level_text = getText(tournament, `meta.stars.max_level_text.${i18n.language}`, "")

  if (tournament.meta && tournament.meta.stars && tournament.meta.stars.items) {
    tournament.meta.stars.items.forEach((item, itemIndex) => {
      const container = {
        text: getText(item, `container_name.${i18n.language}`, ""),
        stars: 0,
        value: "",
        starsTotal: item.stars.length,
        type: item.container_type,
      };

      if (!item.container_type || supportedTypes.indexOf(item.container_type) === -1) return;
      let compareWith = 0;

      switch (item.container_type) {
        case "stake":
          compareWith = stake;
          break;
        case "odd":
          compareWith = odd;
          break;
        case "eventNo":
          compareWith = eventNo;
          break;
        default:
        /* noop */
      }

      if (!compareWith) compareWith = 0;

      let gotIt = false;

      for (let i = 0; i < item.stars.length; i++) {
        const value = item.stars && item.stars[i].value ? parseFloat(item.stars[i].value) : 0;
        if (value && value > compareWith) {
          container.stars = i;
          container.value = getText(item.stars[i], `value_string.${i18n.language}`, "");
          const description = getText(item.stars[i], `description.${i18n.language}`, "");
          if (description) {
            descriptions.push(description);
            descriptionsIndex.push(itemIndex);
          }
          gotIt = true;
          break;
        }
      }

      if (!gotIt) {
        container.stars = item.stars.length;
        container.value = getText(item.stars[item.stars.length - 1], `value_string.${i18n.language}`, "");
      }

      starsData.push(container);
    });
  }

  const toggleShowErrorDetails = () => {
    setShowErrorDetails(v => !v);
  };

  const showRules = () => setRulesOpen(true);
  const hideRules = () => setRulesOpen(false);

  const checkIfLogin = () => { // check if suer is logged in. if not redirect/popup to the login page (with redirect back configured)
    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      if (window.config.platformType !== 1) {
        localStorage.setItem("redirect_after_login", window.location.pathname);
        history.push("/connect");
        return true;
      } else {
        doLogin();
        return true;
      }
    }
    return false;
  };

  const handleJoinPlay = () => { // do enroll or activate or directly play; depending on the tournament/player state
    if (checkIfLogin()) return;

    if (!tournament.enrolled) {
      tournamentEnroll({
        tournamentId: tournament.id,
        activate: true,
        cb: () => {
          betsBonusRequestEvaluation(true);
        },
      });
    } else if (!tournament.activated) {
      tournamentActivate({
        tournamentId: tournament.player_mission_id,
        cb: () => {
          betsBonusRequestEvaluation(true);
        },
      });
    }
  };

  let state = NOT_ENROLLED;

  const enrolled = tournament.enrolled;
  const activated = tournament.activated;
  const name = tournament.name;
  let isStarted = false;
  const now = moment().valueOf();
  if (tournament.start_date <= now && now < tournament.end_date) {
    isStarted = true;
  }
  const total_prizes = tournament.meta.levels ? tournament.meta.levels : 0;
  let eligible = tournamentEvaluate && tournamentEvaluate.data && tournamentEvaluate.data.eligible ? true : false;
  let eligibility_errors = tournamentEvaluate && tournamentEvaluate.data && tournamentEvaluate.data.errors ? tournamentEvaluate.data.errors : [];
  let boostScore = eligible && tournamentEvaluate.data.boosters ? tournamentEvaluate.data.boosters.boostScore : 0;
  let extraScore = 0;
  const player = ranking && typeof ranking[tournament.id] !== "undefined" && typeof ranking[tournament.id].player !== "undefined" ? ranking[tournament.id].player : null;
  const top = ranking && typeof ranking[tournament.id] !== "undefined" && typeof ranking[tournament.id].top !== "undefined" ? ranking[tournament.id].top : null;
  const next_prize = ranking && typeof ranking[tournament.id] !== "undefined" && typeof ranking[tournament.id].next_prize !== "undefined" ? ranking[tournament.id].next_prize : null;
  const avatar_image = getData(tournament, "meta.ui.avatar_image.url", DefaultAvatar);

  try {
    let base_value_per_leu = parseFloat(getData(tournament, "meta.base_value_per_leu", 0));
    let current_value_per_leu = boostScore / stake;
    let bonus_points_percentage = current_value_per_leu / base_value_per_leu - 1;
    let bonus_points_scalar = bonus_points_percentage * boostScore / (1 + bonus_points_percentage);

    if (bonus_points_scalar) extraScore = bonus_points_scalar;
    if (extraScore < 0) extraScore = 0;
  } catch (err) {/*noop*/ }


  let boosterInfo = null;
  let rules = tournament && tournament.meta && tournament.meta.ui && tournament.meta.ui.extended_rules ? tournament.meta.ui.extended_rules : {};

  if (tournamentEvaluate && tournamentEvaluate.data && tournamentEvaluate.data.boosters && tournamentEvaluate.data.boosters.objectives) {
    let total = 0;
    try {
      const keys = Object.keys(tournamentEvaluate.data.boosters.objectives);

      let found = false;

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        let objective = tournamentEvaluate.data.boosters.objectives[key];

        for (let j = 0; j < objective.boosters.length; j++) {
          const booster = objective.boosters[j];

          if (booster && booster.type === 2 && booster.ui_meta && booster.ui_meta.type === "general") {
            if (!boosterInfo) boosterInfo = cloneDeep(booster.ui_meta);
            total += booster.value;
          }
        }
      }

    } catch (err) {
      /* noop */
    }

    if (boosterInfo) boosterInfo.value = total;
  }

  if (enrolled) state = NOT_ACTIVE;
  if (activated) state = INFO;

  if (!isStarted) state = NOT_ACTIVE;

  let enroll_blocked = group.block_enroll === true;

  if (!eligible && !tournamentEvaluateInProgress) state = ERROR;

  logger.debug({ tournament, state, tournamentEvaluate, boosterInfo });

  if (state === ERROR) {
    return <div className="tournament-missions-components-sport-betslip-component error">
      <div className="tm-header">
        <div className="tm-type">
          <img src={Warning} alt="" />
          {t("tournament")}
        </div>
        <div className="tm-name">
          {name}
        </div>
      </div>
      <div className="tm-line"></div>
      <div className="tm-error">
        {t("TOURNAMENT: This ticket does not contribute points to the tournament")}{showErrorDetails ? ":" : "."}
      </div>
      {showErrorDetails && <div className="tm-errors">
        {eligibility_errors.map((err, index) => {
          return <div className="tm-error-item" key={index}>
            <img src={Error} alt="" />
            {convertArgs(err["args"], t)}
          </div>;
        })}
      </div>}
      <div className="tm-footer" onClick={toggleShowErrorDetails}>
        {showErrorDetails && t("Show less")}
        {!showErrorDetails && t("Find out why?")}
      </div>
    </div>;
  }

  if (state === INFO) {
    return (
      <React.Fragment>
        <div className="tournament-missions-components-sport-betslip-component info">
          <div className="tm-header">
            <div className="tm-type">
              <img src={CheckOn} alt="" />
              {t("tournament")}
            </div>
            <div className="tm-name">
              {name}
            </div>
          </div>
          <div className="tm-line"></div>

          <RankingInfo player={player} top={top} nextPrize={next_prize} totalPrizes={total_prizes} defaultAvatarImage={avatar_image} />

          {boosterInfo !== null && <React.Fragment>
            <div className="tm-booster">
              <BoosterInfo info={boosterInfo} />
            </div>
            <div className="tm-line"></div>
          </React.Fragment>}

          {descriptions.length > 0 && <div className="info-panel" onClick={showRules}>
            <div className="star-up"><img src={StarUp} alt="" /></div>
            <div className="txt">
              <TextTransition
                text={descriptions[textIndex % descriptions.length]}
                springConfig={presets.wobbly}
                isHtml={true}
              />
            </div>
            <div className="qm">?</div>
          </div>}

          {descriptions.length === 0 && max_level_text !== "" && <div className="info-panel" onClick={showRules}>
            <div className="txt rich-text ml" dangerouslySetInnerHTML={{ __html: max_level_text }} />
            <div className="qm">?</div>
          </div>}

          <div className="scores" onClick={showRules}>
            <div className="score">

              {starsData.map((star, index) => {
                let leftIndicator = -5;
                let opacity = 1;
                if (star.stars > 0) {
                  leftIndicator = -5 + (19 * (star.stars));

                  if (star.stars === star.starsTotal) {
                    opacity = 0;
                  }
                }

                let shouldBlink = 0;
                let firstEmpty = true;
                if (descriptions.length &&
                  typeof descriptionsIndex[textIndex % descriptions.length] !== "undefined" &&
                  descriptionsIndex[textIndex % descriptions.length] === index) {
                  shouldBlink = true;
                }

                return <div className={`${star.type} ${star.stars > 0 ? 'filled' : ''} stars-${star.starsTotal}`} key={index}>
                  <div className="tm-container">
                    <div className={`value ${shouldBlink ? 'should-blink' : ''}`}>
                      {star.stars === star.starsTotal ? 'MAX' : star.value}
                    </div>
                    <div className="tm-indicator">
                      <img src={Indicator} alt="" style={{ left: leftIndicator, opacity: opacity }} />
                    </div>
                    <div className="stars">
                      {Array.from(Array(star.starsTotal).keys()).map((i) => {
                        let starImg = StarFilled;
                        let blink = false;
                        if (!(i < star.stars)) {
                          starImg = StarEmpty;
                          if (firstEmpty && shouldBlink) {
                            firstEmpty = false;
                            blink = true;
                            starImg = StarFilled;
                          }
                        }

                        return <img key={i} src={starImg} className={blink ? 'should-blink' : ''} alt="" />;
                      })}
                    </div>
                    <div className="title">{star.text}</div>
                  </div>

                </div>
              })}
            </div>

            <div className="points">
              <div className="title">{t("Tournament points")}:</div>
              <div className="points-txt"><ScalableText text={`${isNaN(boostScore) ? '-' : Math.floor(boostScore)}`} /></div>
              {extraScore >= 1 && <div className="star-points">
                <img src={StarFilledBig} alt="" />
                +{Math.floor(extraScore)} extra
              </div>}
            </div>
          </div>
        </div>
        {rulesOpen && <RulesPopup open={rulesOpen} onClose={hideRules} rules={rules} />}
      </React.Fragment>
    )
  }

  return (
    <div className="tournament-missions-components-sport-betslip-component not-enrolled">
      <div className="tm-header">
        <div className="tm-type">
          <img src={CheckOn} alt="" />
          {t("tournament")}
        </div>
        <div className="tm-name">
          {name}
        </div>
      </div>
      <div className="tm-line"></div>
      <div className="tm-info">
        {isStarted && <React.Fragment>
          <div className="tm-ranking">
            <div className="tm-sub-text">TOP</div>
            <div className="tm-high-text">{total_prizes}</div>
          </div>
          <div className="tm-text">
            {t("This ticket contributes to the tournament.")}
          </div>
          <div className="tm-btn-wrapper">
            {!enroll_blocked && <div className="tm-btn" onClick={handleJoinPlay}>{t("Join")}</div>}
            {enroll_blocked && <div className="tm-locked">{t("LOCKED")}</div>}
          </div>
        </React.Fragment>}
        {!isStarted && <React.Fragment>
          <div></div>
          <Timer date={tournament.start_date} key={`t-${tournament.start_date}`}>
            {({ days, hours, mins, secs }) => {
              let muted = 0;
              if (!days || days.toString() === "0") muted += 1;
              if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
              if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
              if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

              return <div className="progress-wrapper">
                {<div className="progress-title">
                  {t("Begins in")}:
                </div>}
                <div className={`progress-text ${!isStarted ? "begins" : ""}`}>
                  {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                </div>
              </div>;
            }}</Timer>
          <div></div>
        </React.Fragment>}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    authentication: state.authentication,
    groups: state.tournamentsMissions ? state.tournamentsMissions.groups.list : [],
    groupsLoaded: state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
    eventNo: ct.live.selected.length + ct.prematch.selected.length,
    stake: ct.amount,
    odd: ct.totalOdds,
    tournamentEvaluateInProgress: ct.tournamentEvaluateInProgress,
    tournamentEvaluate: ct.tournamentEvaluate,
    ranking: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : {},
  }
};

const mapActions = {
  tournamentActivate,
  tournamentEnroll,
  doLogin,
  loadTournamentsGroups,
  betsBonusRequestEvaluation,
  loadPlayerLeaderBoard
};

export default withTranslation()(connect(mapStateToProps, mapActions)(withRouter(SportBetslipComponent)));