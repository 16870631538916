import { combineReducers } from "redux";

import gamesReducer from "./games";

export const lobbyRootReducer = combineReducers({
  games: gamesReducer,
});

const rootReducer = combineReducers({
  lobby: lobbyRootReducer
});

export default rootReducer;
