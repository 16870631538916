import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpPromise =  FingerprintJS.load();

const fpRand = Math.random() + 'fp';

class Fingerprint {

    static generate() {
        return  window[fpRand];
    }
}


export default Fingerprint;

(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()
    window[fpRand]=result.visitorId;
})()