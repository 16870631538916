import React from "react";
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ClearIcon from '@material-ui/icons/Clear';

import "./landscape-top-gallery.scss";

import { tournamentEnroll } from "../store/actions/tournament";
import { loadPlayerLeaderBoard } from "../store/actions/leader_board";
import { closeGameLauncher } from "../../store/actions/games";

import Watch from "../assets/watch.svg";
import Timer from "./timer";
import Random from "../assets/random.svg";
import DefaultAvatar from "../assets/img-avatar-male.png";
import { formatNumber } from "../utils/functions";


import PosBg from "../assets/pos-bg.svg";
import DialogObjectives from "../dialogs/dialog-rules";
import DialogSwitchTournament from "../dialogs/dialog-switch-tournament";
import { getData } from "../../lobby/utils/functions";

const differentArray = (left, right) => {
  if (left.length !== right.length) return true;

  for (let i = 0; i < left.length; i++) {
    const keys = Object.keys(left[i]);
    for (let j = 0; j < keys.length; j++) {
      if (left[i][keys[j]] !== right[i][keys[j]]) {
        return true;
      }
    }
  }

  return false;
};

const TopGallery = (props) => {
  const { tournament, t, tournamentEnroll, rank, loadPlayerLeaderBoard, authentication, closeGameLauncher, history } = props;

  React.useEffect(() => {
    if (tournament && rank && !rank[tournament.id]) {
      loadPlayerLeaderBoard({ tournamentId: tournament.id, inGame: true });
    }
  }, [tournament, rank, loadPlayerLeaderBoard]);

  const [openObjectivesDialog, setOpenObjectivesDialog] = React.useState(false);
  const handleCloseObjectivesDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenObjectivesDialog(false);
  };
  const showRules = (e) => {
    if (e) e.stopPropagation();
    setOpenObjectivesDialog(true);
  };

  const [openTournamentsDialog, setOpenTournamentsDialog] = React.useState(false);
  const handleCloseTournamentsDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(false);
  };
  const showMenu = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(true);
  };
  const closeGame = () => {
    closeGameLauncher();
    if (window._CanGoBack) {
      history.goBack();
      return;
    }
    history.push("/cazino/jocuri");
  };

  const [players, setPlayers] = React.useState([]);

  React.useEffect(() => {
    loadPlayerLeaderBoard({ tournamentId: tournament.id, inGame: true });
  }, []); // eslint-disable-line

  React.useEffect(() => {
    let isAuthenticated = false;
    if (authentication && ["user", "token"].indexOf(authentication.auth_type) > -1) {
      isAuthenticated = true;
    }

    if (isAuthenticated && tournament && rank && rank[tournament.id] && rank[tournament.id].top && differentArray(rank[tournament.id].top, players)) {
      setPlayers(JSON.parse(JSON.stringify(rank[tournament.id].top)));
    }
  }, [tournament, rank, players, authentication]);

  if (!tournament) return null;

  const handleClick = (e) => {
    e.stopPropagation();
    if (!tournament.enrolled) {
      tournamentEnroll({ tournamentId: tournament.id, activate: true });
    }
  };

  const avatar_image = getData(tournament, "meta.ui.avatar_image.url", DefaultAvatar);

  return (
    <div className="landscape-top-gallery">
      <div className="header-wrapper">
        <div className="menus">
          <div className="menu close" onClick={closeGame}>
            <ClearIcon />
          </div>
          <div className="menu" onClick={showMenu}>
            <MoreIcon />
          </div>
        </div>
        <div className="header">
          <div className="tmr">
            <img src={Watch} alt="" />
            <Timer date={tournament.end_date}>
              {({ days, hours, mins, secs }) => {
                return `${!!days ? (days > 1 ? `${days} ${t("days")} ` : `${days} ${t("day")} `) : ""}${hours}:${mins}:${secs}`;
              }}</Timer>
          </div>
        </div>
        <div className="hr"></div>
      </div>
      <div className="gallery-wrapper" onClick={showRules}>
        <div className="gallery  real">
          {
            [...Array(3).keys()].map((_, i) => {
              if (i >= players.length) return <div key={i} className={`item no-${i + 1}`}>
                <div className="avatar">
                  <img src={avatar_image} alt="" />
                </div>
                <div className="pos">
                  <img src={PosBg} alt="" />
                  <div className="no">-</div>
                </div>
                <div className="score"></div>
              </div>;

              const p = players[i];

              return <div key={i} className={`item no-${i + 1}`}>
                <div className="avatar">
                  <img src={p.avatar ? p.avatar : avatar_image} alt="" />
                </div>
                <div className="pos">
                  <img src={PosBg} alt="" />
                  <div className="no">#{p.pos}</div>
                </div>
                <div className="score">{formatNumber(p.score, props.i18n.language)}</div>
              </div>
            })
          }
          <div className="tm-btn-wrapper">
            <div className="tm-btn" onClick={handleClick}>
              <div className="inner">
                <img src={Random} alt="" />
                {!tournament.enrolled ? t("Join") : t("Play")}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openObjectivesDialog && <DialogObjectives open={openObjectivesDialog} onClose={handleCloseObjectivesDialog} tournament={tournament} groupUrl={props.groupUrl} />}
      {openTournamentsDialog && <DialogSwitchTournament open={openTournamentsDialog} onClose={handleCloseTournamentsDialog} />}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication,
    rank: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : {},
  }
};
const actions = {
  loadPlayerLeaderBoard,
  tournamentEnroll,
  closeGameLauncher
}

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(TopGallery)));