import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Section from "../layout/Section";
import { getBetsState, getWinnerAdvMatches } from "../store/selectors/betData";

import { buildMatchGroups } from "../utils/betUtils";
import { renderMatchGroupWithSport } from "../utils/render";
import InPageMD from "./InPageMD";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#EDF0F0",
    padding: "16px 14px"
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  }
});

function PreMatchAvantaj(props) {
  const { bst, matches, t } = props;
  const classes = useStyles();

  const ms = Object.values(matches);
  //sortArrayByKey(ms, "matchDateTime");
  const groups = buildMatchGroups(ms, "matchDateTime", { winnerAdv: true });

  return (
    <Section doRender={[matches]}>
      <InPageMD>
        <div className={classes.root}>
          {groups.length > 0 ? (
            groups.map(g => renderMatchGroupWithSport(g, bst))
          ) : (
              <div className={classes.empty}>{t("There are no Winner Advantage matches")}</div>
            )}
        </div>
      </InPageMD>
    </Section>
  );
}

PreMatchAvantaj.propTypes = {
  idSport: PropTypes.string
};

PreMatchAvantaj.defaultProps = {
  idSport: "0"
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    bst: bst,
    idSport: bst.navSports.preMatch,
    matches: getWinnerAdvMatches(state)
  };
};

export default withTranslation()(connect(mapStateToProps)(PreMatchAvantaj));
