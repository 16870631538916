import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import "./TicketSelector.scss";
import { getBetsState } from "../../store/selectors/betData";
import { betsSlipSetCurrentTicket, betsSlipClearCopyStatus } from "../../store/actions/betsSlip";

let tid = 0;

const TicketSelector = (props) => {

  const { currentTicket, hasBets, copyStatus, setCurrentTicket, clearCopyStatus } = props;

  const changeTicket = (i) => () => {
    setCurrentTicket(i);
  };

  React.useEffect(() => {
    if (copyStatus.status) {
      clearTimeout(tid);
      tid = window.setTimeout(() => {
        clearCopyStatus();
      }, 2000)
    }
  }, [copyStatus.status, clearCopyStatus]);

  return (<div className="bets-ticket-selector">
    <div>
      <div className="button-wrapper">
        {[1, 2, 3, 4, 5].map((v, i) => (
          <div key={i} className={`ticket-wrapper ${hasBets[i].no ? "ticket-" + hasBets[i].type : ''} ${currentTicket === i ? 'active' : ''} ${copyStatus.status && copyStatus.to - 1 === i ? 'animate' : ''}`}>
            <div
              onClick={changeTicket(i)}
              className={`ticket-no`}>
              {v}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>);
}

const makeMapStateToProps = state => {
  const bst = getBetsState(state);

  const hasBets = [];
  bst.betsSlip.tickets.forEach(t => {
    hasBets.push({
      no: t.live.selected.length + t.prematch.selected.length,
      type: t.live.selected.length > 0 ? 'live' : 'prematch'
    });
  });

  return {
    currentTicket: bst.betsSlip.currentTicket,
    hasBets: hasBets,
    copyStatus: bst.betsSlip.copyStatus,
  };
};

const actionCreators = {
  setCurrentTicket: betsSlipSetCurrentTicket,
  clearCopyStatus: betsSlipClearCopyStatus,
};

export default withTranslation()(connect(makeMapStateToProps, actionCreators)(TicketSelector));