import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import moment from "moment";
import "./cta-promotions.scss";
import SimpleTimer from "./simple_timer";

import { ReactComponent as PromoIcon } from "../assets/cta-promotions-icons.svg";
import { loadWheelStatus } from "../store/actions/wheel";
import Rules from './rules';
import KeysRulesDialog from "./keys-rules";
import EvBusMain from "../../utils/evbus";
import { imgPath } from "../utils/functions";

const ctaBg = {
  backgroundImage: `url(${imgPath('wheel-cta-big.png')})`,
};

const CTAPromotions = (props) => {
  const { wheelStatus, t, showHeader, authentication } = props;

  const [open, setStateOpen] = React.useState(false);
  const closeDialog = () => {
    setStateOpen(false);
  };

  const [openKeysRules, setOpenKeysRules] = React.useState({
    open: false,
    type: "",
  });

  const openDialog = (e) => {
    e.stopPropagation();

    let wagerNeeded = 0;
    if (wheelStatus && wheelStatus.free_spin && wheelStatus.free_spin.wager_needed) {
      wagerNeeded = wheelStatus.free_spin.wager_needed;
    }
    if (wagerNeeded) {
      setOpenKeysRules({
        open: true,
        type: "free",
      });
      return;
    }

    setStateOpen(true);
  };
  const handleCloseRules = () => {
    setOpenKeysRules({
      open: false,
      type: "",
    });
  };


  React.useEffect(() => {
    props.loadWheelStatus();
  }, []); // eslint-disable-line

  if (!wheelStatus) return null;
  if (wheelStatus && wheelStatus.status === "not_eligible") {
    if (wheelStatus.reason && wheelStatus.reason.conflicts && typeof wheelStatus.reason.conflicts[1002] !== "undefined") {
      return null;
    }
  }

  const isNextSpin = () => {
    if (wheelStatus && wheelStatus.free_spin && wheelStatus.free_spin.next_spin_after) {
      return true;
    }
    return false;
  }
  const reloadStatus = () => {
    props.loadWheelStatus();
  };
  const goToWheel = () => {
    if (window.config.platformType === 1) {
      EvBusMain.emit("OPEN-WHEEL-DIALOG");
      return;
    }
    props.history.push("/wheel");
  }

  if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
    return null;
  }

  let hasWheelAvailable = wheelStatus && ((wheelStatus.free_spin && wheelStatus.free_spin.status === "available") || (wheelStatus.keys_spin && wheelStatus.keys_spin.status === "available"));
  let hasWheelConflicts = wheelStatus.status === "not_eligible" && wheelStatus.reason && wheelStatus.reason.conflicts;

  return (
    <div className="wheel-cta-promotions-wrapper">
      {showHeader && <div className="wheel-cta-promo-header">
        <h1>
          <PromoIcon />
          {t("Winner Wheel")}
        </h1>
      </div>}
      <div className={`wheel-cta-promotions ${showHeader ? 'mb10' : ''}`} onClick={!isNextSpin() && !hasWheelAvailable ? openDialog : goToWheel} style={ctaBg}>
        <div className="wheel-footer" >
          {isNextSpin() && <div className="wheel-count-down">
            <div className="txt">{t("Available at")}</div>
            <div className="count"><SimpleTimer date={moment(wheelStatus.free_spin.next_spin_after, "x")} onLive={reloadStatus} time={true} /></div>
          </div>}
          {!isNextSpin() && hasWheelAvailable && <div className="wheel-spin-button">{t("SPIN NOW")}</div>}
          {!isNextSpin() && !hasWheelAvailable && <div className="wheel-spin-button-denied">{t("Not eligible? Find out why")}</div>}
        </div>
      </div>
      {open && <Rules open={open} status={wheelStatus} onClose={closeDialog} />}
      {openKeysRules.open && <KeysRulesDialog open={openKeysRules.open} status={wheelStatus} type={openKeysRules.type} onClose={handleCloseRules} />}
    </div>
  );
}

const mapStateToProps = state => ({
  wheelStatus: state.wheel && state.wheel.wheel && state.wheel.wheel.status ? state.wheel.wheel.status : null,
  authentication: state.authentication
});
const mapActions = {
  loadWheelStatus
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapActions)(CTAPromotions)));