import React, { useEffect, useState, Fragment } from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {NavLink, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../store/actions";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "./Okto.scss";
import Utils from "../../../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Translate from "../../../utils/Translate";
import Icons from "../../../assets/Icons/Icons";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const Okto = (props) => {
    const { t } = useTranslation();
    const [checks, setChecks] = useState({
        terms: { checked: false, message: '' },
        privacy: { checked: false, message: '' },
    });

    const [amount, setAmount] = useState(props.amount);
    const [maximumLimit, setMaximumLimit] = useState();
    const [showResult, setShowResult] = useState(false);
    const [buttons, setButtons] = useState([]);
    const [isDefaultValue, setIsDefaultValue] = useState(false);


    const { responsePayload,step,type, profile, loading,  bonusId, createPaymentCode,oktoGetCustomer, oktoReset , onRequestTaxService,limitMessage, minimumLimit,dailyLimit,bonusMax,bonusMin, tax } = props;
    const {paymentCode,qrPayload,customer} = responsePayload;

    const {okto_terms_accepted,amount_can_deposit} = customer;

    const isFirstTime = okto_terms_accepted === null ? true : !okto_terms_accepted;

    const values = [20, 50, 100, 150, 250,500, 1000, 1500, 2500, 3000, 4500];

    const buttonsAreaType = type === 'deposit' ? 2 : 1; // 1 - all buttons, 2 - only min and max buttons


    const initButtons = (localAmount) => {

        if (buttonsAreaType === 1) {
            setButtons(values);
            let amnt = minimumLimit <= maximumLimit ? minimumLimit : maximumLimit;
            if (isNaN(amnt)) {
                amnt = 0;
            }
            Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, amnt, 1),100);
            setAmount(amnt*100);
            return false;
        }

        // find if amount is in values
        let current = localAmount ? localAmount/100 : amount/100;
        // prevent selecting bigger value than maximumLimit
        if (maximumLimit !== false && maximumLimit < current) {
            current = maximumLimit;
        }
        let preselected = current;
        let preselectedIndex = 0;
        let tempButtons = [];
        let def = false;
        values.every((val,index) => {

            if (current === val) {
                // if is default value stop and do nothing else;
                def=true;
                preselectedIndex = index;
                return false;
            } else if (current > val && current < values[index+1]) {
                // if is between two values, select 6 buttons from current value
                tempButtons = values.slice(index,index+6);
                // preselect button with the next value, if exists;
                if (typeof values[index+1] === "undefined") {
                    preselectedIndex = index;
                } else {
                    preselectedIndex = index+1;
                }
                // if there are not enough buttons, select 6 buttons from previous value
                if (tempButtons.length < 6) {
                    tempButtons = values.slice(index-(6 - tempButtons.length),index+(tempButtons.length));
                }
                return false;
            } else if (current < val) {
                preselectedIndex = index;
                tempButtons = values.slice(index,index+6);

                return false;
            }

            return true;
        } );

        if (typeof tempButtons[0] === "undefined" && !def) {
            tempButtons = values.slice(values.length-6,values.length);
            preselectedIndex = values.length-1;
        }

        while(values[preselectedIndex] > maximumLimit && preselectedIndex > 0) {
            preselectedIndex--;
        }

        preselected = values[preselectedIndex];

        setButtons(tempButtons);
        Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, preselected, 1),100);
        setAmount(preselected*100);
        setIsDefaultValue(def);
    }

    useEffect(() => {
        initButtons();
        // if (amount_can_deposit === null) {
        oktoGetCustomer();
        // } else {
        //     if (!isFirstTime) {
        //         createPaymentCode(amount,bonusId);
        //     }
        // }
        return () => {
            oktoReset();
        }
    }, []);

    useEffect(() => {
        setAmount(props.amount);
        initButtons(props.amount);
    }, [props.amount]);

    useEffect(() => {

        if (step === 7) {
            Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, amount/100, 1),100);

            if (!isFirstTime) {
                if (isDefaultValue) {
                    if (amount > 0) {
                        onConfirmHandler();
                    }
                }
            }
        }
    },[step]);

    useEffect(() => {
        if (!loading && paymentCode) {
            responseReceived();
        }
    },[paymentCode]);

    useEffect(() => {
        setMaximumLimit(amount_can_deposit);
    }, [amount_can_deposit]);

    const responseReceived = () => {
        setShowResult(true);
    }

    const toggleCheckbox = (e) => {
        let state = { ...checks };
        state[e.target.value] = {
            checked: e.target.checked,
        };
        setChecks(state);
    }


    const onConfirmHandler = () => {
        createPaymentCode(amount,bonusId > 0 ? bonusId: null);
    }

    const onChangeHandler = (e) => {
        let field;
        let time = 1500;
        if (typeof e.target === "undefined") {
            field = e;
            time = 100;
        } else {
            field = e.target.value;
        }

        field = parseInt(field);

        if (isNaN(field)) {
            field = 0;
        }

        if (maximumLimit !== false && maximumLimit < field) {
            field = maximumLimit;
        }

        if(field > 0) {
            Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, field, 1),time);
        }

        setAmount(field*100);
    }
    if (window?.config?.okto_enabled !== '1' ) {
        return (<div className={"content register-disabled"} >
            {props.t("Deposit is temporarily unavailable")}
        </div>)
    } else if (loading) {
        return (<div className={"content "} >
            <CircularProgress  className={'Loading'} />
        </div>)
    }
    let buttonDisabled = true;
    if((amount === 0)) {
        buttonDisabled = true;
    } else if (isFirstTime) {
        if (checks.terms.checked && checks.privacy.checked) {
            buttonDisabled = false;
        }
    } else {
        buttonDisabled = false;
    }

    let termsUrl = 'https://oktocash.eu/service-terms-of-use/';
    let privacyUrl = 'https://oktocash.eu/privacy-policy/';
    if (props.language === "ro") {
        termsUrl = 'https://oktocash.eu/ro/termenii-de-utilizare-a-serviciului/';
        privacyUrl = 'https://oktocash.eu/ro/politica-de-confidentialitate/';
    }

    console.log(maximumLimit,minimumLimit,amount,buttonsAreaType,buttons);
    return (
        <div className={"Okto"}>

            {showResult &&
                <Grid item xs={12} className={"result"} key={'okto-deposit-result'}>
                    <Grid container>

                        <Grid item xs={12} className={'result'}>
                            <div className="icon">
                                {Icons.get('pwSuccess','pwSuccess')}
                            </div>
                            <div className={'title'}>{t("Ready to deposit")}</div>
                            <div className={'subtitle'}>{t("Visit any OKTO.CASH payment station and use the code below to deposit with cash.")}</div>
                            <div className={"description"}>
                                {t('At the OKTO.CASH point, please select Winner from the menu, then introduce the payment code below. The funds will be delivered to your Winner account shortly after.')}
                            </div>
                            <div className={'code'}>{paymentCode}</div>
                        </Grid>
                        <Grid item xs={12} className={'user-details'}>
                            <Grid container>
                                <Grid item xs={6} className={'left'}>
                                    <div className={'value'}>{profile.first_name} {profile.last_name}</div>
                                </Grid>
                                <Grid item xs={6} className={'right'}>
                                    <div className={'value'}>{profile.nin}</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={'nearest-locations'}>

                            <a target={'_blank'} href={'https://www.oktopayments.com/store-locator/oktocash-romania/'}>
                                {t("Find here the closest OKTO.CASH payment station")}
                                {Icons.get('newTab','newTab')}

                            </a>

                        </Grid>
                        <Grid item xs={12} className={'actions'}>
                            <NavLink to={"/"} className={'DepositButton'}>
                                <Button fullWidth variant="contained"  size="large"  className={'active gradient btn-danger w-100 nextButton'}>
                                    {t("I have saved the code")}
                                </Button>
                            </NavLink>

                        </Grid>

                    </Grid>
                </Grid>
            }

            {!showResult && (buttonsAreaType === 2 || buttonsAreaType === 1) && <Grid container
                                                           alignItems="center"
                                                           justifyContent="center"
                                                           className={'buttons  small-buttons'}
                                                           spacing={2}
                                                           key={"okto-deposit-buttons"}>
                <Grid item xs={12} className={'DepositAmount'}>
                    <FormControl className="PlayerDataInput text-center">
                        <InputLabel htmlFor="amount">{t('Deposited amount')}</InputLabel>
                        <Input
                            id="amount"
                            type='text'
                            disabled={true}
                            value={(amount/100) + ' Lei'}
                        />
                    </FormControl>

                </Grid>

                <Grid item xs={12} className={'accepted-values'} key={"okto-deposit-title"}>
                    {t("Amounts available via OKTO.CASH:")}
                </Grid>
                <Grid container className={'sAmountButtons'}>
                    {buttons && buttons.map(val => (
                        <Grid item xs={4}  key={"deposit_buttons_okto" + val}>

                            <div className="align-self-center text-center w-100 buttonContainer">
                                <button disabled={ minimumLimit > val || amount_can_deposit < val} className={ 'w-100 btn btn-outline-success ' + (parseInt(amount/100,10) === parseInt(val,10) ? "active-" : "") }  onClick={() => onChangeHandler(val)}
                                >
                                    { val }
                                    {/*{amount === this.props.minBonus && (<span className={"bonus-button-label"}>Min Bonus</span> )}*/}
                                    {val === bonusMax  && bonusMin  !== bonusMax && (<span className={"bonus-button-label"}>Max Bonus</span> )}
                                </button>
                            </div>
                        </Grid>))}
                </Grid>
                <Grid container  className="Taxes">
                    <Grid item={true} xs={6} >
                        <div className="left">
                            <p>
                                {tax < 1 ? '' : '-'}{Utils.formatAmount(tax,0,'RON')}
                            </p>
                            <span>{t("CDA 2% (GEO 114)")}</span>
                        </div>
                    </Grid>
                    <Grid item={true} xs={6} >
                        <div className="right">
                            <p>{Utils.formatAmount(amount/100 - tax,0,'RON')}</p>
                            <span>{t("Net deposit")}</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>}
            {!showResult && isFirstTime && <>

                <Grid container className={'actions'} key={'okto-actions-button'}>
                    <Grid container>
                        <FormControl className={'field terms'}>
                            <FormControlLabel
                                control={<Checkbox checked={checks.terms.checked} onChange={(e) => toggleCheckbox(e)} value="terms" />}
                                label={<div>
                                    <div className="checkbox-title">
                                        {t("Terms & Conditions")}

                                    </div>
                                    <div className="checkbox-subtitle">
                                        <Translate text={"I have read, understood and accept the OKTO.CASH Service ${startUrl}Terms of Use${endUrl} and I agree to the transfer of my data to Oktopay Ltd and Sureswipe EMI PLC."}
                                            placeholder={
                                                {
                                                    startUrl: `<a target="_blank" href="${termsUrl}">`,
                                                    endUrl: '</a>'
                                                }
                                            }
                                        />
                                    </div>
                                </div>}
                                className={checks.terms.checked ? "no-error" : "error"}
                            />
                            {(!checks.terms.checked && checks.terms.message !== '') && <FormHelperText error className={'CheckBoxError'}>{checks.terms.message}</FormHelperText>}
                        </FormControl>
                        <FormControl className={'field privacy'}>
                            <FormControlLabel
                                control={<Checkbox checked={checks.terms.privacy} onChange={(e) => toggleCheckbox(e)} value="privacy" />}
                                label={<div>
                                    <div className="checkbox-title">
                                        {t("Privacy policy")}
                                    </div>
                                    <div className="checkbox-subtitle">
                                        <Translate text={"I have read, understood, and accept the ${startUrl}OKTO Privacy Policy${endUrl}"}
                                            placeholder={
                                                {
                                                    startUrl: `<a target="_blank" href="${privacyUrl}">`,
                                                    endUrl: '</a>'
                                                }
                                            }
                                        />
                                    </div>
                                </div>}
                                className={checks.privacy.checked ? "no-error" : "error"}
                            />
                            {(!checks.privacy.checked && checks.privacy.message !== '') && <FormHelperText error className={'CheckBoxError'}>{checks.privacy.message}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>

            </>}
            {!showResult && <Grid item xs={12} className={'actions'} >
                <Grid container>
                    <Button fullWidth variant="contained" disabled={buttonDisabled} size="large"  className={'active gradient btn-danger w-100'} onClick={() => onConfirmHandler()}>
                        Confirm
                    </Button>
                </Grid>
            </Grid>}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        responsePayload: state.deposit.okto,
        loading: state.deposit.loading,
        tax: state.withdrawals.taxes,
        language: state.application.language,
        profile: state.profile.player,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createPaymentCode: (amount,bonusId) => dispatch(storeActions.oktoCreatePaymentCode(amount,bonusId)),
        oktoGetCustomer: () => dispatch(storeActions.oktoGetCustomer()),
        oktoReset: () => dispatch(storeActions.oktoReset()),
        onRequestTaxService: (taxType, amount, id) => dispatch(storeActions.requestTaxService(taxType, amount, id)),

    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Okto)));