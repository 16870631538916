import { appConstants } from "../constants";
import { produce } from "immer";

const initialState = {
	initialized: false
};

const appReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case appConstants.INITIALIZED:
				draft.initialized = true;
				break;
			default:
				break;
		}
	});

export default appReducer;
