import { takeEvery, put } from "redux-saga/effects";
import axios from "axios";

import * as actionTypes from "../../../store/actions/actionTypes";
import { loadedWinterStatus } from "../actions/winter";
import { winterConstants } from "../actions/constants";
import getStore from "..";

let apiUrl = process.env.CFW.betsApiUrl + "/promo/winter";

function* loadWinterStatusSaga() {
  const { authentication } = getStore().getState();

  if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const headers = {
    "Authorization": "Bearer " + authentication.access_token,
  };

  try {
    const resp = yield axios.get(apiUrl + "/status", { headers });
    yield put(loadedWinterStatus(resp.data));

  } catch (err) {
    console.log("loadWinterStatusSaga[ERR]:", err)
  }
}

export default function* watchWinterSaga() {
  yield takeEvery(actionTypes.profile.RECEIVED_ACCOUNT, loadWinterStatusSaga);
  yield takeEvery(winterConstants.LOAD, loadWinterStatusSaga);
}
