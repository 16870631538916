import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withCommonStyles } from "../../styles";
import clsx from "clsx";
import moment from "moment";
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import localization from "moment/locale/ro.js";

import LiveBadge from "../../elements/LiveBadge";
import Match from "../../components/Match";
import BetSelectSingle from "../../components/BetSelectSingle";

import { groupMatchesByDay } from "../../utils/betUtils";

const useStyles = makeStyles({
	root: {},
	days: {
		display: "flex",
		backgroundColor: "#CCD8DE",
		padding: "16px 14px"
	},
	day: {
		"&:not(:first-child)": {
			marginLeft: "8px"
		},
		backgroundColor: "#FFFFFF",
		color: "#17214D",
		padding: "7px 16px",
		borderRadius: 15,
		fontSize: 11,
		fontWeight: 600,
		whiteSpace: "nowrap",
		textTransform: "capitalize",
		cursor: "pointer"
	},
	daySelected: {
		backgroundColor: "#070D29",
		color: "#979DAB"
	},
	betsWrapper: {
		marginTop: "20px",
		paddingLeft: "14px",
		paddingRight: "14px"
	},
	bets: {
		marginTop: "8px"
	},
	dayDate: {
		backgroundColor: "#979DAB",
		color: "#FFFFFF",
		fontSize: 9,
		fontWeight: 600,
		padding: "4px 7px",
		borderRadius: 4,
		width: "fit-content"
	},
	clearBoth: {
		clear: "both"
	}
});

const visibleStyle = {
	maxHeight: "0px",
	overflow: "visible"
};

const Offer = props => {
	const classes = withCommonStyles(useStyles);

	const { data, mType, index, t, i18n } = props;
	const isDesktop = useMediaQuery("(min-width:1281px)");

	//console.log("data", data);

	const [selDate, setSelDate] = useState(0);

	if (!data) return null;

	const matches = Object.values(data).filter(m => m.active);
	const ml = groupMatchesByDay(data);
	const dates = Object.keys(ml).sort();

	const handleDateChange = d => {
		setSelDate(d);
	};

	let style = null;
	if (index !== 0) {
		style = visibleStyle;
	}

	const parseDate = d => {
		let dt;
		if (i18n.language === "ro") {
			dt = moment(parseInt(d, 10)).locale("ro", localization);
		} else {
			dt = moment(parseInt(d, 10)).locale("en");
		}
		return dt.format("D MMMM");
	};

	let matchGroup = "";
	if (dates.length) {
		matchGroup =
			"ld_mg_" +
			matches[0].mType +
			"_" +
			matches[0].idSport +
			"_" +
			matches[0].idCategory +
			"_" +
			matches[0].idTournament;
	}

	//console.log("ml", ml, "matchgroup", matchGroup, matches);

	return (
		<div style={style}>
			<div className={clsx(classes.scrollable, classes.days)}>
				<div
					className={clsx(classes.scrollableItem, classes.day, {
						[classes.daySelected]: selDate === 0
					})}
					onClick={() => handleDateChange(0)}
				>
					{t("All")}
				</div>
				{dates.length > 1 &&
					dates.map(d => {
						return (
							<div
								key={d}
								className={clsx(classes.scrollableItem, classes.day, {
									[classes.daySelected]: selDate === d
								})}
								onClick={() => handleDateChange(d)}
							>
								{parseDate(d)}
							</div>
						);
					})}
			</div>
			{selDate === 0 ? (
				dates.map((d, i) => {
					return (
						<div key={"d" + d} className={classes.betsWrapper}>
							{mType !== "live" && (
								<React.Fragment>
									{!isDesktop && (
										<div className={classes.dayDate}>{parseDate(d)}</div>
									)}
								</React.Fragment>
							)}
							<div className={classes.bets}>
								<BetSelectSingle
									matches={matches}
									matchGroup={matchGroup}
									time={parseInt(d, 10)}
									second={i > 0 ? true : false}
								/>
								{ml[d].map(m => {
									return (
										<Match
											key={m.idMatch}
											idMatch={m.idMatch}
											mType={m.mType}
											refDate={d}
											margin="0 0 6px 0"
											matchGroup={matchGroup}
										/>
									);
								})}
							</div>
						</div>
					);
				})
			) : (
				<div className={classes.betsWrapper}>
					<div className={classes.bets}>
						<BetSelectSingle
							matches={matches}
							matchGroup={matchGroup}
							time={parseInt(selDate, 10)}
						/>
						{ml[selDate].map(m => {
							return (
								<Match
									key={m.idMatch}
									idMatch={m.idMatch}
									mType={m.mType}
									refDate={selDate}
									margin="0 0 6px 0"
									matchGroup={matchGroup}
								/>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default withTranslation()(Offer);
