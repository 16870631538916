import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getLottoState } from "../../store/selectors/lottoData";
import TicketReserved from "../../components/Ticket/TicketReserved";
import TicketReservedNsoft from "../../components/TicketNsoft/TicketReserved";
import { ticketReservedListRequest } from "../../store/actions/tickets";

const useStyles = makeStyles({
  root: {},
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  },
  more: {
    fontSize: "10px",
    fontWeight: "600",
    color: "#586682",
    margin: "16px 0",
    textAlign: "center",
    textDecoration: "underline",
    cursor: "pointer",
  },
});

const Reserved = ({ reserved, inProgress, ticketReservedListRequest, theme, t }) => {
  const classes = useStyles();

  const currentTheme = theme && theme === "nsoft" ? "nsoft" : "bets";

  let reservedTickets = null;
  if (currentTheme === "nsoft") {
    if (reserved.length > 0) {
      reservedTickets = reserved.map((ticket, i) => <TicketReservedNsoft key={ticket.id} ticket={ticket} />)
    }
  } else {
    if (reserved.length > 0) {
      reservedTickets = reserved.map((ticket, i) => <TicketReserved key={ticket.id} ticket={ticket} />)
    }
  }

  React.useEffect(() => {
    ticketReservedListRequest();
  }, []); // eslint-disable-line

  return (
    <div>
      {inProgress && <div className={classes.empty}>
        <CircularProgress />
      </div>}
      {!inProgress && reserved.length === 0 && <div className={classes.empty}>{t("There are no reserved tickets")}</div>}
      {reservedTickets}
    </div>
  );
};

const mapStateToProps = state => {
  const lst = getLottoState(state);
  return {
    reserved: lst.tickets.ticketsReserved,
    inProgress: lst.tickets.requestInProgress.reserved
  };
};

const actionCreators = {
  ticketReservedListRequest,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(Reserved));
