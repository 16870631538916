import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import ModalHeader from "../ModalHeader";
import Expanded from "./Expanded";

const useStyles = makeStyles({
  root: {}
});

const MyTicket = props => {
  const classes = useStyles();

  const { ticket, winnerFun, cashout, cashoutState, onGoBack, t } = props;

  const goBack = () => {
    onGoBack()
  };
  const onAction = () => { };

  return (
    <div className={classes.root}>
      <ModalHeader
        title={t("Ticket Details")}
        active={false}
        type={"none"}
        goBack={goBack}
        onAction={onAction}
      />
      <div>
        <Expanded ticket={ticket} cashout={cashout} cashoutState={cashoutState} winnerFun={winnerFun} goBack={goBack} />
      </div>
    </div>
  );
};

export default withTranslation()(MyTicket);
