import React from 'react';

import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import "./rules-popup.scss";
import HeaderIcon from "./assets/dialog-icon.svg";
import { getText } from "../../../lobby/utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TournamentMissionsSportDialog = props => {
  const {
    open,
    onClose,
    rules,
    i18n,
    t
  } = props;

  const handleClose = () => {
    typeof onClose === "function" && onClose(false);
  };

  const lang = i18n.language;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`tournament-missions-sport-rules`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{t("Tournament Sport")}</div>
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>
          <div className="rich-text" dangerouslySetInnerHTML={{ __html: getText(rules, `text.${lang}`, "") }}></div>

          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={handleClose}>
              {t("I understand")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

const mapStateToProps = (state, props) => {

  let freeBetRules = [];
  if (props.type === "freeBet" && props.eventName) {
    if (state.freeBets && state.freeBets.freeBetsFilters && typeof state.freeBets.freeBetsFilters[props.eventName] !== "undefined" && typeof state.freeBets.freeBetsFilters[props.eventName].textRules !== "undefined") {
      freeBetRules = state.freeBets.freeBetsFilters[props.eventName].textRules;
    }
  }

  return {
    freeBetRules
  };
};

export default connect(mapStateToProps)(withTranslation()(TournamentMissionsSportDialog));



