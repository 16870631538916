import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ButtonBase from "@material-ui/core/ButtonBase";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import isArray from "lodash/fp/isArray";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import Section from "../layout/Section";
import CalendarFilter from "../components/Calendar/CalendarFilter";
//import CalendarSort from "../components/Calendar/CalendarSort";
import BetSelectSingle from "../components/BetSelectSingle";

import Match from "../components/Match";
import Collapsible from "../components/Collapsible";

import { saveCalendarSelection } from "../store/actions/calendar";
import { prematchFetchSportByDay } from "../store/actions/prematch";
import { getBetsState, getCalendarMatches } from "../store/selectors/betData";
import { testValues, makeSort2Keys, sortArrayByKey } from "../utils";
import { getSelected } from "../store/selectors/preMatchFilter";
import { filterMatchData } from "../store/actions/preMatchFilter";
import { navSportsUpdate } from "../store/actions/navSports";
import Breadcrumbs from "../components/PrematchBreadcrumbs";
//import FilterLeague from "./FilterLeague";
import InPageMD from "./InPageMD";
import { empty } from "../utils/empty";
import NewtonLoader from "../../newton-loader";
import { nSoftPrematch } from "../api/config/nsoft";
import { debug } from "../utils";

import { HappyHours } from "../../common/hh-exports";
import WinnerFunFront from "../components/WinnerFunWelcome/WinnerFunFront";

const RENDER_BATCH = 5;
const DEBUG = false;

const useStyles = makeStyles({
  root: {
    backgroundColor: "#EDF0F0",
    padding: "16px 14px"
  },
  centered: {
    width: "fit-content",
    margin: "0 auto",
    paddingBottom: "8px"
  },
  showMore: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#586682",
    padding: "8px 13px"
  },
  wrapper: {
    backgroundColor: "#CCD8DE",
    minHeight: "47px",
    padding: "0 19px",
    fontFamily: "OpenSans"
  },
  selected: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "5px",
    fontSize: "12px",
    color: "#17214D",
    padding: "0 10px",
    minHeight: "27px",
    display: "flex",
    alignItems: "center",
    fontWeight: "600",
    margin: "5px 0",
    textAlign: "left"
  },
  probe: {
    width: "1px",
    height: "1px"
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  },
  hhMargin: {
    marginTop: "12px!important"
  }
});

const observerOptions = { threshold: [0], root: null, rootMargin: "0px 0px 400px 0px" };

function PreMatch(props) {
  const {
    idSport,
    matches,
    allMatches,
    allData,
    categories,
    tournaments,
    tournamentGroups,
    location,
    history,
    filterBy,
    setFilterBy,
    fetchMatches,
    loadingState,
    fullStateLoaded,
    eventsDayCache,
    baseUrlPath,
    match,
    t
  } = props; // eslint-disable-line
  const classes = useStyles();

  const locationSearch = location.search;

  //debug("*** FILTERBY", filterBy);

  //console.log("pre-match props", props);
  const [showN, setShowN] = React.useState(10);

  const iOS = navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const query = new URLSearchParams(location.search);
  const leagueId = query.get("f");
  const leagueName = query.get("l");
  const selectedDate = query.get("sd");
  const [leagueSelection, setLeagueSelection] = React.useState({
    leagueName: leagueName ? leagueName : "",
    leagueId: leagueId ? leagueId : "",
    selectedDate: selectedDate ? selectedDate === "0" ? 0 : selectedDate : "",
  });

  const [toRender, setToRender] = useState(RENDER_BATCH);
  const [chss, setChss] = useState([]);
  //const [triggerChange, setTriggerChange] = useState(0);

  const ds = [0];
  for (let i = 0; i < 7; i++) {
    const ts =
      moment()
        .add(i, "days")
        .startOf("day")
        .valueOf() + "";
    ds.push(ts);
  }

  const [dates, setDates] = useState(ds);

  //const [filterBy, setFilterBy] = React.useState(calendarSelection); // eslint-disable-line
  const handleFilterBy = React.useCallback(
    value => {
      if (filterBy !== value) {
        setFilterBy(value);
        //saveCalendarSelection(value);
        setToRender(RENDER_BATCH);

        const query = new URLSearchParams(location.search);
        query.set("sd", value);

        history.push(`${baseUrlPath}/pre-match/calendar?${query.toString()}`);
      }
    },
    [filterBy, location.search, baseUrlPath] // eslint-disable-line
  );

  // on mount, load expanded bets preferences
  //useEffect(() => {
  //  setToRender(RENDER_BATCH);
  //}, []);

  React.useEffect(() => {
    if (idSport === "0") {
      let sid = nSoftPrematch.defaultSportId;
      props.updateSportsIndex("prematch", sid);
    }
  }, [idSport]); // eslint-disable-line

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const leagueId = query.get("f") ? query.get("f") : "";
    const leagueName = query.get("l") ? query.get("l") : "";
    const selectedDate = query.get("sd") ? query.get("sd") : "";

    //if (leagueSelection.leagueId === leagueId) {
    //  return;
    //}

    if (leagueSelection.leagueId === leagueId && leagueId !== "") {
      const lidp = leagueId.split("_");
      if (lidp[0] !== idSport && fullStateLoaded) {
        props.updateSportsIndex("prematch", lidp[0]);
      }
      return;
    } else if (leagueSelection.leagueId === leagueId) {
      return;
    }

    setChss(empty);

    const tid = setTimeout(() => {
      if (leagueId !== null && leagueId !== "") {
        const lidp = leagueId.split("_");
        if (lidp[0] !== idSport) {
          props.updateSportsIndex("prematch", lidp[0]);
        }
      }

      setLeagueSelection(v => {
        if (v.leagueId !== leagueId || v.leagueName === leagueName) {
          return {
            leagueId,
            leagueName,
            selectedDate
          };
        }
        return v;
      });
    }, 0);

    return () => {
      clearTimeout(tid);
    }
  }, [location, fullStateLoaded, filterBy]); // eslint-disable-line

  const didStateLoaded = React.useRef(false);
  React.useEffect(() => {
    if (fullStateLoaded && !didStateLoaded.current) {
      didStateLoaded.current = true;

      debug("*** Check URL sd param at fullStateLoaded");
      const query = new URLSearchParams(location.search);
      const sd = query.get("sd");
      const id = query.get("f");
      const [sID] = id ? id.split("_") : [""];

      if (sd) {
        let startDate, days;

        if (sd === "0") {
          startDate = moment();
          days = 31;
        } else {
          startDate = moment(parseInt(sd, 10));
          days = 1;
        }

        startDate = startDate.startOf("day").valueOf();

        let eventsLoaded = true;

        for (let i = 0; i < days; i++) {
          if (
            eventsDayCache[
            `${idSport}_${startDate + i * 24 * 3600 * 1000}`
            ] !== 1
          ) {
            eventsLoaded = false;
            break;
          }
        }

        if (sID && sID !== idSport) {
          props.updateSportsIndex("prematch", sID);
        } else if (!eventsLoaded) {
          fetchMatches({ day: sd === "0" ? 0 : sd, idSport: sID ? sID : idSport, noDays: 1 });
        }
      }
    }
  }, [fullStateLoaded, idSport, eventsDayCache]); // eslint-disable-line

  const didMount = React.useRef(false);
  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    if (leagueSelection.leagueId) {
      //console.log("make filterBy null")
      //setFilterBy(null);
      setSortBy("select");
    } else {
      //console.log("make filterBy null")
      //setFilterBy(null);
      setSortBy("league");
    }
  }, [leagueSelection]); // eslint-disable-line

  let expanded = 0;
  const buildMatches = (idSport, c, t, msl, groupName, ftb, gID, index) => {
    let mCurrDate = msl && msl.length ? moment(msl[0].matchDateTime) : null;
    let currDate = msl && msl.length ? msl[0].matchDateTime : null;

    if (typeof categories[c] !== "undefined" && categories[c].categoryActive && typeof tournaments[t] !== "undefined" && tournaments[t].tournamentActive) {
      const matchGroup =
        "mg_prematch_" + idSport + "_" + c + "_" + t + (groupName ? "_" + groupName : "_0");

      const displayMatches = [];

      msl = msl.filter(m => m.active);

      displayMatches.push(
        <BetSelectSingle
          key={displayMatches.length}
          matches={msl}
          match={msl.length ? msl[0] : null}
          mType="prematch"
          time={currDate}
          matchGroup={matchGroup}
        />
      );

      msl.forEach((m, i) => {
        if (mCurrDate) {
          const mDate = moment(m.matchDateTime);
          if (mCurrDate.format("YYYY-MM-DD") !== mDate.format("YYYY-MM-DD")) {
            mCurrDate = mDate;

            displayMatches.push(
              <BetSelectSingle
                key={displayMatches.length}
                matches={msl}
                match={msl.length ? msl[0] : null}
                mType="prematch"
                matchGroup={matchGroup}
                second={true}
                time={m.matchDateTime}
              />
            );
          }
        }

        displayMatches.push((
          <Match
            key={displayMatches.length}
            idMatch={m.idMatch}
            mType={m.mType}
            refDate={ftb}
            inGroup={true}
            matchGroup={matchGroup}
          />
        ));
      });

      const ch = (
        <Collapsible
          type="preMatch"
          idSport={idSport}
          region={categories[c].categoryIsoName}
          categoryDLId={categories[c].categoryDLId}
          league={`${tournaments[t].tournamentName}${groupName ? ` - ${groupName}` : ""}`}
          description={tournaments[t].description}
          leagueDetails={`#league-details-prematch-${idSport}-${c}-${t}`}
          key={index}
          opened={expanded < 5}
          events={msl ? msl.length : 0}
        >
          {displayMatches}
        </Collapsible>
      );
      expanded++;
      return ch;
    }
    return null;
  };

  React.useEffect(() => {
    let rid = 0;
    if (iOS) return;
    const chsLength = chss.length;
    if (toRender < chsLength) {
      let d = chsLength - toRender;
      if (d > RENDER_BATCH) {
        d = RENDER_BATCH;
      }
      rid = setTimeout(() => {
        setToRender(tr => {
          DEBUG && console.log(`render from ${toRender} to ${toRender + d}`);
          return tr + d;
        });
      }, 100);
    }

    return () => {
      if (rid) clearTimeout(rid);
    };
  }, [toRender, chss, iOS]);

  const fireEvent = React.useCallback(
    inView => {
      DEBUG && console.log("in view", inView, "chsLength", chss.length);
      if (inView) {
        const chsLength = chss.length;
        if (chsLength) {
          setToRender(tr => {
            let d = chsLength - tr;
            if (d > RENDER_BATCH) {
              d = RENDER_BATCH;
            }
            if (d <= 0) return tr;
            DEBUG && console.log(`render from ${tr} to ${tr + d}`);
            return tr + d;
          });
        }
      }
    },
    [chss]
  );

  const [probe, probeInView, probeEntry] = useInView(observerOptions);

  React.useEffect(() => {
    if (!probeEntry) {
      return;
    }

    const targetInfo = probeEntry.boundingClientRect;
    const rootBoundsInfo = probeEntry.rootBounds;

    if (targetInfo && rootBoundsInfo) {
      if (targetInfo.bottom < rootBoundsInfo.bottom) {
        fireEvent(true);
      }

      if (targetInfo.bottom >= rootBoundsInfo.bottom) {
        fireEvent(false);
      }
    }
  }, [probeEntry, fireEvent]);

  /*
  const probe = React.createRef();
  const refObserver = React.useRef();

  React.useEffect(() => {
    let mounted = true;
    if (!iOS) return;
    if (mounted) {
      console.log("mount obs", probe, refObserver);
      if (probe && probe.current) {
        console.log("create refObserver");
        refObserver.current = new IntersectionObserver(
          (records, observer) => {
            for (const record of records) {
              const targetInfo = record.boundingClientRect;
              const rootBoundsInfo = record.rootBounds;

              if (targetInfo && rootBoundsInfo) {
                if (targetInfo.bottom < rootBoundsInfo.bottom) {
                  fireEvent(true);
                }

                if (targetInfo.bottom >= rootBoundsInfo.bottom) {
                  fireEvent(false);
                }
              }
            }
          },
          { threshold: [0], root: null, rootMargin: "0px 0px 400px 0px" }
        );
        refObserver.current.observe(probe.current); // attach observer to our probe
      }
    }

    return () => {
      mounted = false;
      console.log("unmount obs", probe, refObserver);
      if (probe.current && refObserver.current) {
        console.log("unboserve");
        refObserver.current.unobserve(probe.current);
        refObserver.current = null;
        probe.current = null;
      }
    };
  }, [probe, iOS]); //eslint-disable-line
  //console.log("probe", probe);
  */

  const handleUnfilter = () => {
    history.push(`${baseUrlPath}/pre-match/calendar?sd=${filterBy}`);
  };

  const [sortBy, setSortBy] = React.useState(leagueId && leagueName ? "select" : "league"); // eslint-disable-line

  React.useEffect(() => {
    if (!fullStateLoaded) {
      return;
    }

    if (sortBy === "select") {
      //console.log(idSport, allData[idSport]);
      props.setMatchData(allData[idSport], "prematch", idSport);
    }
  }, [fullStateLoaded, sortBy, idSport, allData]); // eslint-disable-line

  React.useEffect(() => {
    if (idSport !== "0" && filterBy !== null) {
      fetchMatches({ day: filterBy, idSport, noDays: 1 });
    }
  }, [idSport, filterBy, fetchMatches]);

  React.useEffect(() => {
    DEBUG && console.log("mounted");
    return () => {
      DEBUG && console.log("unmounted");
      document.body.classList.remove("pre-match-hide-extra");
    };
  }, []); // eslint-disable-line

  const showMore = React.useCallback(() => {
    let ns = showN + 5;

    const ftb = parseInt(filterBy, 10);
    let ms;

    if (ftb === 0) {
      ms = Object.values(allMatches).filter(m => m.idSport === idSport && m.active);
    } else {
      ms = typeof matches[filterBy] !== "undefined" ? matches[filterBy]["_all"].filter(m => m.active) : [];
    }

    if (ns > ms.length) {
      ns = ms.length;
    }

    setShowN(ns);
  }, [allMatches, matches]);

  DEBUG && console.time("prematch matches");

  //console.log("dates", dates, "show", show, "filterBy", filterBy);
  React.useEffect(() => {
    if (filterBy === null) {
      let urlFilterBy = dates[1];

      if (leagueSelection.selectedDate) {
        const exists = dates.find(d => {
          if (d.toString() === leagueSelection.selectedDate.toString()) {
            return true;
          }
          return false;
        });
        if (typeof exists !== "undefined") {
          urlFilterBy = exists;
        } else { // we should allow start dates outside of our 7 days limit
          let selectedDate = moment(parseInt(leagueSelection.selectedDate, 10));

          const now = moment().startOf("day").valueOf();
          if (selectedDate.valueOf() > now) {
            urlFilterBy = selectedDate.valueOf().toString();
          }
        }
      } else if (leagueSelection.selectedDate === 0) {
        urlFilterBy = 0;
      }

      debug("dates", dates, "urlFilterBy", urlFilterBy);
      setFilterBy(urlFilterBy);
    }

    DEBUG && console.log("filterBy", filterBy, "sortBy", sortBy, "showN", showN);

    let chs = [];

    const partsLength = leagueSelection.leagueId.split("_").length;

    if (filterBy !== null) {
      if (sortBy === "select" && partsLength === 3) {
        const ftb = parseInt(filterBy, 10);

        let ms;

        if (ftb === 0) {
          ms = Object.values(allMatches).filter(m => m.idSport === idSport && m.active);
        } else {
          ms = typeof matches[filterBy] !== "undefined" ? matches[filterBy]["_all"].filter(m => m.active) : [];
        }

        //debugger;
        ms = ms.filter(m => {
          if (
            partsLength === 3 &&
            m.idSport + "_" + m.idCategory + "_" + m.idTournament === leagueSelection.leagueId
          ) {
            return true;
          }
          return false;
        });

        sortArrayByKey(ms, "matchDateTime");

        const groups = {};
        let hasGroups = false;
        ms.forEach(m => {
          if (!m.idTournamentGroup) return;
          if (typeof groups[m.idTournamentGroup] === "undefined") {
            groups[m.idTournamentGroup] = [];
          }

          groups[m.idTournamentGroup].push(m);
          if (m.idTournamentGroup !== "0") hasGroups = true;
        });

        if (hasGroups) {
          const parts = [];
          const keys = Object.keys(groups);

          keys.sort((a, b) => {
            const posA =
              a !== "0" && tournamentGroups[a] ? tournamentGroups[a].tournamentGroupPosition : null;
            const posB =
              b !== "0" && tournamentGroups[b] ? tournamentGroups[b].tournamentGroupPosition : null;
            return testValues(posA, posB);
          });

          keys.forEach((gID, index) => {
            let gName = "";
            if (gID !== "0") {
              if (typeof tournamentGroups[gID] !== "undefined") {
                gName = tournamentGroups[gID].tournamentGroupName
                  ? tournamentGroups[gID].tournamentGroupName
                  : "";
              }
            }
            const ch = buildMatches(
              idSport,
              groups[gID][0].idCategory,
              groups[gID][0].idTournament,
              groups[gID],
              gName,
              ftb,
              gID,
              index
            );
            if (ch) {
              parts.push(ch);
            }
          });

          setChss(parts);
          return;
        }

        const matchGroup = "mg_prematch__" + leagueSelection.leagueId;

        let mCurrDate = ms && ms.length ? moment(ms[0].matchDateTime) : null;
        let currDate = ms && ms.length ? ms[0].matchDateTime : null;

        chs = ms.map((m, i) => {
          const matchContent = (
            <Match
              key={i}
              idMatch={m.idMatch}
              mType={m.mType}
              refDate={ftb}
              inGroup={true}
              showFav={true}
              matchGroup={matchGroup}
            />
          );

          const mDate = moment(m.matchDateTime);
          if (mCurrDate.format("YYYY-MM-DD") !== mDate.format("YYYY-MM-DD")) {
            mCurrDate = mDate;

            return (
              <React.Fragment key={i}>
                <BetSelectSingle
                  matches={ms}
                  match={ms.length ? ms[0] : null}
                  mType="prematch"
                  matchGroup={matchGroup}
                  second={true}
                  time={m.matchDateTime}
                />
                {matchContent}
              </React.Fragment>
            );
          }
          return matchContent;
        });

        if (chs.length)
          chs.unshift(
            <BetSelectSingle
              matches={ms}
              match={ms.length ? ms[0] : null}
              mType="prematch"
              key="bet_select_single"
              matchGroup={matchGroup}
              time={currDate}
            />
          );
      } else if (sortBy === "league" || (sortBy === "select" && partsLength === 2)) {
        const ftb = parseInt(filterBy, 10);

        let ms;

        if (ftb === 0) {
          ms = allData[idSport] || [];
        } else {
          ms = matches[ftb] || [];
        }

        let keys = [];
        if (sortBy === "league") {
          keys = Object.keys(ms).filter(c => c !== "_all");
        } else if (sortBy === "select" && partsLength === 2) {
          keys = Object.keys(ms).filter(c => idSport + "_" + c === leagueSelection.leagueId);
        }

        let keys2 = {};
        if (sortBy === "league") {
          Object.keys(ms).forEach(c => {
            if (c !== "_all") {
              Object.keys(ms[c]).forEach(t => (keys2[t] = true));
            }
          });
        } else if (sortBy === "select" && partsLength === 2) {
          Object.keys(ms).forEach(c => {
            if (idSport + "_" + c === leagueSelection.leagueId) {
              Object.keys(ms[c]).forEach(t => (keys2[t] = true));
            }
          });
        }

        const tournamentKeys = Object.keys(keys2).sort(
          makeSort2Keys(tournaments, "tournamentPosition", "tournamentName")
        );

        chs = tournamentKeys.map((t, j) => {
          if (typeof tournaments[t] === "undefined") {
            return null;
          }

          const c = tournaments[t].idCategory;

          //chs = keys.sort(makeSort(categories, "categoryPosition"))
          //  .map(c => {
          //console.log("category", c);

          //    return Object.keys(ms[c]).sort(makeSort(tournaments, "tournamentPosition")).map(t => {

          let msl = ms[c][t];

          if (!isArray(msl)) {
            msl = Object.values(msl);
          }

          msl = msl.filter(m => m.active);
          sortArrayByKey(msl, "matchDateTime");

          const groups = {};
          let hasGroups = false;
          msl.forEach(m => {
            if (!m.idTournamentGroup) return;
            if (typeof groups[m.idTournamentGroup] === "undefined") {
              groups[m.idTournamentGroup] = [];
            }

            groups[m.idTournamentGroup].push(m);
            if (m.idTournamentGroup !== "0") hasGroups = true;
          });

          if (hasGroups) {
            const parts = [];
            const keys = Object.keys(groups);

            keys.sort((a, b) => {
              const posA =
                a !== "0" && tournamentGroups[a]
                  ? tournamentGroups[a].tournamentGroupPosition
                  : null;
              const posB =
                b !== "0" && tournamentGroups[b]
                  ? tournamentGroups[b].tournamentGroupPosition
                  : null;
              return testValues(posA, posB);
            });

            keys.forEach((gID, index) => {
              let gName = "";
              if (gID !== "0") {
                if (typeof tournamentGroups[gID] !== "undefined") {
                  gName = tournamentGroups[gID].tournamentGroupName
                    ? tournamentGroups[gID].tournamentGroupName
                    : "";
                }
              }
              const ch = buildMatches(idSport, c, t, groups[gID], gName, ftb, gID, index);
              if (ch) {
                parts.push(ch);
              }
            });

            if (parts.length === 0) return null;

            return <React.Fragment key={j}>{parts}</React.Fragment>;
          }

          return buildMatches(idSport, c, t, msl, null, ftb, null, j);
        });
        //});
      } else {
        const ftb = parseInt(filterBy, 10);

        let ms;

        if (ftb === 0) {
          ms = Object.values(allMatches).filter(m => m.idSport === idSport && m.active);
        } else {
          ms = typeof matches[filterBy] !== "undefined" ? matches[filterBy]["_all"].filter(m => m.active) : [];
        }

        sortArrayByKey(ms, "matchDateTime");

        chs = ms
          .slice(0, showN)
          .map((m, i) => (
            <Match
              key={i}
              idMatch={m.idMatch}
              mType={m.mType}
              refDate={ftb}
              showFav={true}
              matchGroup={"mg_prematch_" + idSport}
            />
          ));

        if (chs.length)
          chs.unshift(
            <BetSelectSingle
              matches={ms}
              match={ms.length ? ms[0] : null}
              mType="prematch"
              key="bet_select_single"
              matchGroup={"mg_prematch_" + idSport}
            />
          );

        if (ms.length > showN) {
          chs.push(
            <div key="show_more_all" className={classes.centered}>
              <div key="show_more_text" className={classes.showMore} onClick={showMore}>
                {t("Show more")} ({ms.length - showN})
              </div>
            </div>
          );
        }
      }
    } else {
      chs = [];
    }

    setChss(chs);
  }, [
    allData,
    allMatches,
    categories,
    filterBy,
    idSport,
    leagueSelection,
    matches,
    showMore,
    showN,
    sortBy,
    tournaments
  ]);

  /*
  const handleLeagueSelection = (id, name) => {
    document.body.classList.remove("pre-match-hide-extra");
    if (id === "") {
      setLeagueSelection({
        leagueName: "",
        leagueId: ""
      });
      setSortBy("league");
    } else {
      setLeagueSelection({
        leagueName: name,
        leagueId: id
      });
    }
  };
  */

  DEBUG && console.timeEnd("prematch matches");
  //console.log("filterBy", filterBy);

  const display = chss.slice(0, toRender);
  let style = {};
  if (iOS && loadingState === null) {
    style = {
      minHeight: `${chss.length * 75}px`
    };
  }

  DEBUG && console.log("display.length", display.length, display, "loadingState", loadingState);

  return (
    <React.Fragment>
      <Section
        doRender={[chss, toRender, leagueSelection, location, idSport, filterBy, probe, match, locationSearch]}
        key="prematch_page"
      >
        <Breadcrumbs />
        <CalendarFilter
          className="pre-match-calendar-filter"
          active={filterBy ? parseInt(filterBy, 10) : 0}
          days={dates}
          onChange={handleFilterBy}
        />
        <InPageMD key={"prematch"}>
          {leagueSelection.leagueName && (
            <div className={`${classes.wrapper} d-flex flex-row flex-nowrap align-items-center`}>
              <ButtonBase className={`${classes.selected}`} onClick={handleUnfilter}>
                <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between w100">
                  <div className="flex-grow-1">{leagueSelection.leagueName}</div>
                  <CloseIcon className={classes.closeIcon} />
                </div>
              </ButtonBase>
            </div>
          )}
          <HappyHours event_type={`${baseUrlPath === "/pariuri" ? "" : "winner-fun-"}bets-prematch`} className={classes.hhMargin} />
          <WinnerFunFront />
          <div key="prematch_content" className={classes.root} style={style}>
            {display.length > 0 && loadingState === null && display}
            {display.length === 0 && loadingState === null && (
              <div className={classes.empty}>
                {idSport && props.sports && props.sports[idSport] && (
                  <span>
                    {t("There are no matches for ")} {props.sports[idSport].sportName}
                  </span>
                )}
              </div>
            )}

            {loadingState !== null && (
              <div>
                <NewtonLoader />
              </div>
            )}

            <div className={classes.probe} ref={probe}></div>
          </div>
        </InPageMD>
      </Section>
    </React.Fragment>
  );
}

PreMatch.whyDidYouRender = true;

PreMatch.propTypes = {
  idSport: PropTypes.string
};

PreMatch.defaultProps = {
  idSport: "0"
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    matches: getCalendarMatches(state),
    allData: bst.prematch.data, // matchData
    loadingState: bst.prematch.loadingState,
    allMatches: bst.prematch.matches,
    categories: bst.prematch.categories,
    tournaments: bst.prematch.tournaments,
    sports: bst.prematch.sports,
    tournamentGroups: bst.prematch.tournamentGroups,
    idSport: bst.navSports.prematch,
    filterBy: bst.calendar.filterBy,
    fullStateLoaded: bst.prematch.fullStateLoaded,
    eventsDayCache: bst.prematch.eventsDayCache,
    selected: getSelected(state, {
      filterType: "prematch",
      idSport: bst.navSports.prematch
    }),
    baseUrlPath: bst.app.basePath,
  };
};

/*
const mapDispatchToProps = dispatch => {
  return {
    setMatchData: (md, fType, idSport) => dispatch(filterMatchData(md, fType, idSport)),
    updateSportsIndex: (nav, idSport) => dispatch(navSportsUpdate(nav, idSport)),
    setFilterBy: filterBy => dispatch(saveCalendarSelection(filterBy)),
    fetchMatches: data => dispatch(prematchFetchSportByDay(data))
  };
};
*/

const mapDispatchToProps = ({
  setMatchData: filterMatchData,
  updateSportsIndex: navSportsUpdate,
  setFilterBy: saveCalendarSelection,
  fetchMatches: prematchFetchSportByDay
});

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PreMatch))
);
