import moment from "moment";
import isArray from "lodash/fp/isArray";
import { sortArrayByKey2 } from "./";

const emptyArray = [];

export const betUtilsConstants = {
	MATCH_NULL: -1,
	MATCH_BLOCKED: -2,
	BET_NULL: -3,
	BET_BLOCKED: -4,
	ODD_NULL: -5,
	ODD_BLOCKED: -6,
	ODD_CHANGED: -7
};

export const betUtilsErrors = {
	"-1": "Evenimentul nu mai face parte din oferta",
	"-2": "Evenimentul este blocat",
	"-3": "Pariul nu mai face parte din oferta",
	"-4": "Pariul este blocat",
	"-5": "Cota nu mai face parte din oferta",
	"-6": "Cota este blocata",
	"-7": "Cota s-a schimbat"
};

export const getOutcomeValue = (m, idMb, idMbo) => {
	if (!m) {
		return betUtilsConstants.MATCH_NULL;
	}

	if (!m.bettingStatus) {
		return betUtilsConstants.MATCH_BLOCKED;
	}

	const bet = m.matchBets ? m.matchBets.find(mb => mb.idMb === idMb) : null;

	if (!bet) {
		return betUtilsConstants.BET_NULL;
	}

	if (!bet.mbActive) {
		return betUtilsConstants.BET_BLOCKED;
	}

	const bo = bet.mbOutcomes ? bet.mbOutcomes.find(mbo => mbo.idMbo === idMbo) : null;

	if (!bo) {
		return betUtilsConstants.ODD_NULL;
	}

	if (!bo.mboActive) {
		return betUtilsConstants.ODD_BLOCKED;
	}

	return bo.mboOddValue;
};

export const checkOutcome = (cm, bm, idMb, idMbo) => {
	const cv = getOutcomeValue(cm, idMb, idMbo);
	if (cv < 0) {
		return cv;
	}

	const bv = getOutcomeValue(bm, idMb, idMbo);
	if (bv < 0) {
		return bv;
	}

	if (cv !== bv) {
		return betUtilsConstants.ODD_CHANGED;
	}

	return cv;
};

export const showLeague = m => () => {
	window.location.hash = `#league-details-${m.mType}-${m.idSport}-${m.idCategory}-${m.idTournament}`;
};

export const showMatch = m => () => {
	window.location.hash = `#match-details-${m.mType}-${m.idMatch}`;
};

export const groupMatchesByDay = matches => {
	let ml = Object.values(matches);

	return ml.reduce((acc, m) => {
		const d = moment(m.matchDateTime)
			.startOf("day")
			.valueOf();

		d in acc || (acc[d] = []);
		acc[d].push(m);

		return acc;
	}, {});
};

const MARKETS_THRESHOLD = 1;

export const getCommonMarkets2 = (matches, bets) => {
	if (typeof matches === "undefined" || matches === null || matches.length === 0) {
		return emptyArray;
	}

	let markets = {};

	for (let i = 0; i < matches.length; i++) {
		const m = matches[i];

		for (let j = 0; j < m.matchBets.length; j++) {
			const added = {};

			//let midBet = buildBetId(m.matchBets[j]);
			let midBet = m.matchBets[j].idBet;

			if (added[midBet]) {
				continue;
			}

			added[midBet] = true;

			if (midBet in markets) {
				markets[midBet].count++;
			} else {
				markets[midBet] = {
					count: 1,
					mType: m.mType,
					idSport: m.idSport,
					bet: m.matchBets[j],
					name: m.team1Name + " : " + m.team2Name
				};
			}
		}
	}

	let me = Object.entries(markets);

	// filter markets on threshold
	if (MARKETS_THRESHOLD > 0) {
		me = me.filter(([midBet, m]) => m.count >= MARKETS_THRESHOLD);
	}

	// sort by apparitions number
	me.sort((a, b) => {
		if (
			bets !== null &&
			bets[a[1].idSport] &&
			bets[a[1].idSport][a[1].bet.idBet] &&
			bets[b[1].idSport] &&
			bets[b[1].idSport][b[1].bet.idBet]
		) {
			const ba = bets[a[1].idSport][a[1].bet.idBet];
			const bb = bets[b[1].idSport][b[1].bet.idBet];

			//console.log("b1", b1, "b2", b2);

			const diff = ba.betPosition - bb.betPosition;

			if (diff !== 0) {
				return diff;
			}
		}

		if (a[1].count !== b[1].count) {
			return b[1].count - a[1].count;
		}

		const ap = a[1].bet.mbPosition !== null ? a[1].bet.mbPosition : 9999;
		const bp = b[1].bet.mbPosition !== null ? b[1].bet.mbPosition : 9999;
		return ap - bp;
	});

	// put winner adv and winner plus in front
	let idx;
	if ((idx = me.findIndex(m => m[1].bet.winnerPlus || m[1].bet.winnerAdv)) !== -1) {
		const m = me.splice(idx, 1);
		me.unshift(m[0]);
	}

	return me;
};

export const buildBetId = mb => {
	let midBet = mb.idBet;

	let sp = "";

	if (isArray(mb.mbSpecialValue)) {
		if (mb.mbSpecialValue.length > 0) {
			sp = mb.mbSpecialValue.join("/");
		}
	} else if (mb.mbSpecialValue !== "" && mb.mbSpecialValue !== "*") {
		sp = mb.mbSpecialValue;
	}

	if (sp !== "") {
		midBet += "/" + sp;
	}

	return midBet;
};

export const buildMatchGroups = (matches, sortKey = "position") => {
	// requirements
	if (!isArray(matches)) {
		console.error("Expecting matches to be an array");
		return emptyArray;
	}

	if (matches.length === 0) {
		return emptyArray;
	}

	// sort matches by sport
	sortArrayByKey2(matches, "idSport", sortKey);

	// get the type of the first match
	const mType = matches[0].mType;

	// check valid
	if (!(mType === "live" || mType === "prematch")) {
		console.error("invalid match type", matches[0]);
		return emptyArray;
	}

	// make sure all the matches have the same type
	for (let i = 1; i < matches.length; i++) {
		if (matches[i].mType !== mType) {
			console.error(`match ${i} doesn't have type ${mType}`, matches[i]);
			return emptyArray;
		}
	}

	// results
	const hmg = [];

	// start with an empty group
	let cgn = "";
	let cgm = [];

	for (let i = 0; i < matches.length; i++) {
		// group by sport/group/category
		//let mg = "mg_" + mType + "_" + matches[i].idSport + "_" + matches[i].idCategory;

		// group by sport only
		let mg = "mg_" + mType + "_" + matches[i].idSport;

		if (matches[i].winnerPlus) mg = mg + "_wp";
		if (matches[i].winnerAdv) mg = mg + "_wa";

		if (cgn != mg) {
			// push whatever accumulated (if anything)
			if (cgm.length > 0) {
				hmg.push({
					group: cgn,
					matches: [...cgm]
				});
			}

			// set new group
			cgn = mg;

			// reset matches
			cgm = [];
		}

		// push match to group
		cgm.push(matches[i]);
	}

	// push last group if anything in it
	if (cgm.length > 0) {
		hmg.push({
			group: cgn,
			matches: [...cgm]
		});
	}

	return hmg;
};
