import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Utils from './../../../../../../utils/Utils';
import Icons from "../../../../../../assets/Icons/Icons";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import WalletBoxItem from './WalletBoxItem/WalletBoxItem';
import './WalletPreview.scss';
import { animateScroll as scroll } from "react-scroll";

import WheelKeysStatus from "../../../../../../wheel/components/keys_status";

const SPORTSBOOK = 'walletSportsbook';
const CASINO = 'walletCasino';
const CASINO_LIVE = 'walletCasinoLive';
const VIRTUALS = 'walletVirtuals';
const LOTTO = 'walletLotto';
// const PENDING_WITHDRAWALS = 'withdrawCoin';
// const PENDING_DEPOSITS = 'depositCoin';
const WINNER_FUN = 'walletWinnerFun2';

const CASINO_ID = 1;
const SPORTSBOOK_ID = 2;
const CASINO_LIVE_ID = 4;
const VIRTUALS_ID = 999; //To be defined
const LOTTO_ID = 3;
const WINNER_FUN_ID = 6;

const betsEnabled = window.config && window.config.betsEnabled === "1";
const casinoLiveEnabled = window.config && window.config.liveCasinoEnabled === "1";
const virtualsEnabled = window.config && window.config.virtualsEnabled === "1";
const lottoEnabled = window.config && window.config.lottoEnabled === "1";
const nsoftEnabled = window.config && window.config.nsoftEnabled === "1";
const winnerFunEnabled = window.config && window.config.winnerFunEnabled === "1";

const WalletPreview = (props) => {
    const { history, wallet, withdrawals, isVisible, setIsVisible, t } = props;

    const [popupIsOpen, setPopupIsOpen] = useState(false);

    useEffect(() => {
        if (isVisible) {
            setPopupIsOpen(true);
            document.documentElement.style.overflow = "hidden";
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        }
    }, [isVisible]);

    const goToDeposit = () => {
        closePopup()
        history.push('/deposit');
    };

    const goToWallet = () => {
        closePopup()
        history.push('/profile/wallet');
    };

    const closePopup = () => {
        setPopupIsOpen(false);
        document.documentElement.style.overflow = 'auto';
        document.body.style.overflow = "auto";
        setIsVisible();
    }

    const getCurrentWallet = (id) => {
        if (id === WINNER_FUN_ID) {
            return props.winnerFunWallet ? props.winnerFunWallet.total : 0;
        }

        if (!wallet.loadingWallet) {
            if (wallet.walletManagement[id] && wallet.walletManagement[id].productAmount) {
                return wallet.walletManagement[id].productAmount;
            } else {
                return wallet.main;
            }
        }
        return 0;
    }

    const getCurrentWalletBonus = (id) => {
        if (id === WINNER_FUN_ID) {
            return props.winnerFunWallet ? props.winnerFunWallet.secondary : 0;
        }
        if (!wallet.loadingWallet) {
            if (wallet.walletManagement[id] && wallet.walletManagement[id].bonusAmount) {
                return wallet.walletManagement[id].bonusAmount;
            } else {
                return false;
            }
        }
        return false;
    }

    let mainContent = (
        <div className={"WP-Container " + (popupIsOpen ? 'OpenPopup' : 'ClosedPopup')}>
            <div className="WP-Header">
                <h2 className="Title">{t('Product Balance')}</h2>
                <i onClick={() => closePopup()} className={"CloseButton"}>{Icons.get('closeIcon', 'CloseIcon')}</i>
            </div>
            <div className="WP-Primary">
                <div className="AmountContainer">
                    <div className="WalletBox TotalWallet" onClick={() => goToWallet()}>
                        <h3 className="BoxTitle">{t('Account Balance')}</h3>
                        <h2 className="BoxAmount">
                            {Icons.get("walletOutlined", "WalletIcon")}
                            {Utils.formatSmallDecimals(wallet.total, null)}
                            <span className="Currency">{Utils.formatCurrency(wallet.currency)}</span>
                        </h2>
                    </div>
                    <div className="WalletBox AvailableWallet">
                        <h4 className="BoxTitle">{t('Available')}</h4>
                        <p className="BoxAmount">{Utils.formatSmallDecimals(wallet.main, null)}</p>
                    </div>
                    <div className="WalletBox ReservedWallet">
                        <h4 className="BoxTitle">{t('Reserved')}</h4>
                        <p className="BoxAmount">{Utils.formatSmallDecimals(wallet.ringFence, null)}</p>
                    </div>
                    <div className="WalletBox BonusWallet">
                        <h4 className="BoxTitle">{t('Bonus')}</h4>
                        <p className="BoxAmount">{Utils.formatSmallDecimals(wallet.bonus, null)}</p>
                    </div>
                </div>
                <button className={'DepositButton'} onClick={() => goToDeposit()}>{t('Deposit')}</button>
            </div>
            <div className="WP-Secondary">
                <div className="ProductsWrapper">
                    <div className="ProductsSection BalancePerProduct">
                        <h3 className="SectionTitle">{t('Balance per Product')}:</h3>
                        <div className="ProductsContainer">
                            <WalletBoxItem title={'Sportsbook'} type={SPORTSBOOK} amount={getCurrentWallet(SPORTSBOOK_ID)} currency={wallet.currency} bonus={getCurrentWalletBonus(SPORTSBOOK_ID)} enabled={betsEnabled || nsoftEnabled} />
                            <WalletBoxItem title={'Casino'} type={CASINO} amount={getCurrentWallet(CASINO_ID)} currency={wallet.currency} bonus={getCurrentWalletBonus(CASINO_ID)} enabled={true} />
                            <WalletBoxItem title={'Casino LIVE'} type={CASINO_LIVE} amount={getCurrentWallet(CASINO_LIVE_ID)} currency={wallet.currency} bonus={getCurrentWalletBonus(CASINO_LIVE_ID)} enabled={casinoLiveEnabled} />
                            <WalletBoxItem title={'Virtuals'} type={VIRTUALS} amount={getCurrentWallet(VIRTUALS_ID)} currency={wallet.currency} bonus={getCurrentWalletBonus(VIRTUALS_ID)} enabled={virtualsEnabled} />
                            <WalletBoxItem title={'Lotto'} type={LOTTO} amount={getCurrentWallet(LOTTO_ID)} currency={wallet.currency} bonus={getCurrentWalletBonus(LOTTO_ID)} enabled={lottoEnabled} />
                            {window.config && window.config.winnerFunEnabled === "1" && props.currentProduct === 'walletWinnerFun' && <WalletBoxItem title={'Winner Fun'} type={WINNER_FUN} amount={getCurrentWallet(WINNER_FUN_ID)} currency={"WINCOIN"} bonus={getCurrentWalletBonus(WINNER_FUN_ID)} enabled={winnerFunEnabled} noIcon={true} />}
                        </div>
                    </div>
                    {/*<div className="ProductsSection PendingTransactions">*/}
                    {/*    <h3 className="SectionTitle">{t('Pending Transactions')}:</h3>*/}
                    {/*    <div className="ProductsContainer">*/}
                    {/*        <WalletBoxItem title={'Withdrawls'} type={PENDING_WITHDRAWALS} amount={withdrawals.blockedAmount} currency={wallet.currency} bonus={false} enabled={true} />*/}
                    {/*        /!* <WalletBoxItem title={'Deposits'} type={PENDING_DEPOSITS} amount={withdrawals.blockedAmount} currency={wallet.currency} bonus={false} enabled={true}/> *!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <WheelKeysStatus />
                </div>
                <div className="ExpandLessButton" onClick={() => closePopup()}><ExpandLessIcon /></div>
            </div>
        </div>
    );
    return (
        <div className={"WalletPreviewScreen"} style={isVisible ? { display: 'flex' } : { display: 'none' }}>
            {mainContent}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        wallet: state.wallet,
        withdrawals: state.withdrawals,
        winnerFunWallet: state.winnerFun && state.winnerFun.wallet ? state.winnerFun.wallet : null,
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, null)(WalletPreview)));