import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./labels.scss";

import { getText, styleToObj, openItem, checkItemAvailability, getIndexById } from "../../utils/functions";

const empty = {};

const Labels = (props) => {
  const { data, i18n, source } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, source]);

  if (items.length === 0) return null;

  const filteredItems = items.filter(item => (checkItemAvailability(item)));
  if (filteredItems.length === 0) return null;

  return (
    <div className={`component-labels component`} style={styleToObj(data.style)}>

      <div className="cgl-list" style={styleToObj(data.data_source_style)}>

        {filteredItems.map((item, i) => {
          const realIndex = getIndexById(items, item.id);
          let itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;
          itemStyle = Object.assign({ minWidth: data.card_width }, itemStyle);

          return <div
            key={i}
            className={`cgl-item ${item.isEmpty ? "empty" : ""}`}
            style={itemStyle}
            data-type={"navigate"}
            data-item={JSON.stringify({ url: item && item.data && item.data.url ? item.data.url : "" })}
            onClick={openItem}
          >
            {!!item.data.thumbnail && <img src={item.data.thumbnail} alt="" />}
            {getText(item, `data.title.text.${lang}`, "")}
          </div>;
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
  }
};

export default withTranslation()(connect(mapStateToProps)(Labels));