import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Icons from "../../../../../assets/Icons/Icons";
import EmptyList from "./EmptyList";
import Moment from "react-moment";
import Utils from "../../../../../utils/Utils";

const visibleStyle = {
    maxHeight: "0px",
    overflow: "visible"
};
class BonusesList extends Component {
    constructor(props) {
        super(props);

        this.selfType = 3;

        this.icons = {
            "download": Icons.get("historyCsvDownload", "historyCsvDownload"),
            "loading": Icons.get("historyCsvGenerate", "historyCsvGenerate"),
        };

        this.status = {
            "1": { "name": "active", "color": "success" },
            "2": { "name": "inactive", "color": "failed" },
            "3": { "name": "expired", "color": "failed" },
            "4": { "name": "forfeited", "color": "failed" },
            "5": { "name": "collected", "color": "success" }, // wagered
        };
    }

    getTime(timestamp){
        let date = new Date(timestamp * 1000);
        let hours = date.getUTCHours();
        let minutes = "0" + date.getMinutes();
        return hours + ':' + minutes.substr(-2);
    }

    createList() {
        let list = [];
        // console.log(this.props.transactions.bonuses);
        if (!(this.props.transactions.bonuses.length > 0)) {
            return <EmptyList />;
        }

        this.props.transactions.bonuses.forEach((bonus, index) => {

            let formattedAmount = Utils.formatAmount(bonus.amount, true, this.props.wallet.currency);

            let status = "unknown";
            let statusColor = "";
            if (this.status.hasOwnProperty(bonus.status)) {
                if (Utils.isBonusActive(bonus)) {
                    status = this.status[1].name;
                    statusColor = this.status[1].color;
                } else {
                    if (bonus.status === 1) {
                        status = this.status[3].name;
                        statusColor = this.status[3].color;
                    } else {
                        status = this.status[bonus.status].name;
                        statusColor = this.status[bonus.status].color;
                    }
                }


            }

            let seconSpacer = null;
            let amountGrantedHtml = null;
            if (bonus.wager > 0) {
                let formattedAmountGranted = Utils.formatAmount(bonus.wager, true, this.props.wallet.currency);
                seconSpacer = (<span className={"spacer"}>|</span>);
                amountGrantedHtml = (
                    <div className={`amount`}>{formattedAmountGranted}</div>
                );
            }

            let component = (
                <li key={`history_elem_${index}`} className={"container"}>
                    <div className={"historyElementR1 row"}>
                        <div className={"col-9 trsDesc"}>
                            <div className={"bName"}>{ bonus.name }</div>
                            <span className={"spacer"}>|</span>
                            <div className={`specialAmount`}>{formattedAmount}</div>
                            { seconSpacer }
                            { amountGrantedHtml }
                        </div>
                        <div className={"col-3 trsTimestamp text-right"}>
                            <Moment format={"DD/MM/YYYY"} unix>{ bonus.startDate }</Moment>
                        </div>
                    </div>
                    <div className={"historyElementR2 row"}>
                        <div className={"col-9 text-left trsDesc"}>
                            <div className={`text-left transactionStatus ${statusColor}`}>{ this.props.t(status) }</div>
                        </div>
                        <div className={"col-3 text-right trsTimestamp"}>
                            <Moment format={"HH:mm"} unix>{bonus.startDate}</Moment>
                        </div>
                    </div>
                </li>
            );

            list.push(component);
        });

        if (!(list.length > 0)) {
            return <EmptyList />
        }

        return (
            <ul className={"bonusList"}>{list}</ul>
        );
    }

    downloadCsv(event) {
        event.nativeEvent.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        if (this.props.transactions.generatingCsv) {
            return false;
        }

        if (this.props.transactions.emptyCsv) {
            return false;
        }

        this.props.downloadCsv(this.selfType, event);

        return false;
    }

    render() {
        if (!this.props.transactions.bonuses) {
            return undefined;
        }

        let dIcon = this.icons.download;
        let dIconClass = ["dIcon"];
        let dText = "Download history for last 90 days";
        if (this.props.transactions.generatingCsv) {
            dIcon = this.icons.loading;
            dText = "Please wait! Generating CSV file...";
            dIconClass.push("loading");
        }

        if (this.props.transactions.emptyCsv) {
            dText = "The 90 days report is empty. Please try again later!";
            dIcon = null;
        }

        let style = null;
        if (this.props.tabIndex !== 0) {
            style = visibleStyle;
        }

        return (
            <div className={"transactionsList"} style={style}>
                {this.createList()}
                <div className={"downloadCsv"}>
                    <a href={"/csv/download"} onClick={this.downloadCsv.bind(this)}>
                        <span className={dIconClass.join(" ")}>{dIcon}</span>
                        <span className={"dText"}>{this.props.t(dText)}</span>
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        transactions: state.transactions,
        wallet: state.wallet,
    }
};

export default withTranslation()(connect(mapStateToProps)(BonusesList));
