import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Slide from "@material-ui/core/Slide";

import { createHowl } from "../../../../common/howler";

import WinImg from "../../assets/win.png";
import { ReactComponent as GiftIcon } from "../../assets/gift.svg";
import { happyHourClearPrizeWon } from "../../../../store/actions/happy_hour";
import { ReactComponent as UpArrowIcon } from "../../assets/up-arrow.svg";
import WinSpecialPrize from "./win-panel-special-prize";

const tenantId = window.config && window.config.clientId ? parseInt(window.config.clientId, 10) : 0;

let selfWinSound = createHowl("self-win.mp3");
let anotherWinSound = createHowl("another-win.mp3");
let tID = 0;

const WinMobile = props => {
  const { data, happyHourClearPrizeWon, prizeWon, client_player_id, sound_enabled, t } = props;

  const [open, setOpen] = React.useState({
    modal: false,
    play_sound: false,
    winner_id: 0,
    winner_tenant_id: 0,
    ts: 0
  });

  const clearOpenState = () => {
    setOpen({
      modal: false,
      play_sound: false,
      winner_id: 0,
      winner_tenant_id: 0,
      ts: 0
    });
  };

  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  React.useEffect(() => {
    if (!(prizeWon && !prizeWon.amount && typeof prizeWon.custom !== "undefined")) {
      if (prizeWon.event_type === "online-slots") {

        clearTimeout(tID);
        tID = setTimeout(() => {
          clearOpenState();
          setTimeout(() => {
            happyHourClearPrizeWon("online-slots");
          }, 300);
        }, 10000);

        if (prizeWon) {
          setOpen({
            modal: true,
            play_sound: false,
            winner_id: prizeWon.winner_id,
            winner_tenant_id: prizeWon.winner_tenant_id,
            ts: +new Date()
          });
        }
      }
    }
  }, [prizeWon, happyHourClearPrizeWon]);

  const isWinner = React.useCallback(
    prize => {
      let res = parseInt(client_player_id, 10) === parseInt(prize.winner_id, 10);
      if (!res) return false;

      if (tenantId !== 0) {
        res = res && tenantId === parseInt(prize.winner_tenant_id, 10);
      }

      return res;
    },
    [client_player_id]
  );

  React.useEffect(() => {
    if (open.modal && !open.play_sound) {
      if (isWinner(open)) {
        if (sound_enabled && selfWinSound) selfWinSound.play();
      } else {
        if (sound_enabled && anotherWinSound) anotherWinSound.play();
      }
      setOpen({
        ...open,
        play_sound: true
      });
    }
  }, [open, isWinner, sound_enabled]); //eslint-disable-line

  const clearWinner = () => {
    const now = +new Date();

    // can be dismissed after 2 seconds
    if (now - open.ts < 2000) return;

    clearOpenState();
    clearTimeout(tID);
    setTimeout(() => {
      happyHourClearPrizeWon("online-slots");
    }, 300);
  };

  const clearWinnerForce = () => {
    clearOpenState();
    clearTimeout(tID);
    setTimeout(() => {
      happyHourClearPrizeWon("online-slots");
    }, 300);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientY);
  }

  const handleTouchEnd = () => {
    console.log({ touchStart, touchEnd }, touchStart - touchEnd > 10);
    if (touchStart - touchEnd > 10) {
      clearWinnerForce();
    }
  };

  if (!prizeWon) return null;
  if (prizeWon.event_type !== "online-slots") return null;

  if (!prizeWon.amount && typeof prizeWon.custom !== "undefined") {
    return <WinSpecialPrize />;
  }

  const winCurrentUser = isWinner(prizeWon);
  const element = (
    <div className={`hh-win-container ${winCurrentUser ? "win" : ""}`}>
      <div className="hh-fi">
        <UpArrowIcon />
      </div>
      <div className="hh-win-left">
        <div className="hh-win-top">
          {data.name}
          {!data.name && <span>&nbsp;</span>}
        </div>
        <div className={`hh-win-bottom ${winCurrentUser ? "winner" : ""}`}>
          {!winCurrentUser && <img src={WinImg} alt="" />}
          {/*winCurrentUser && <img className="winner" src={WinSelfImg} alt="" />*/}
        </div>
      </div>
      <div className="hh-win-right">
        <div className="hh-win-top">
          <span>
            {winCurrentUser
              ? t("You won prize") + ` #${prizeWon.prize_index + 1}`
              : `${prizeWon.player_initials ? prizeWon.player_initials : t("A player")} ` +
              t("has won prize") +
              ` #${prizeWon.prize_index + 1}`}
          </span>
        </div>
        <div className="hh-win-bottom">
          <div className="hh-img">
            <GiftIcon />
          </div>
          <div className="hh-txt">{prizeWon.amount ? prizeWon.amount / 100 : 0} Lei</div>
        </div>
      </div>
    </div>
  );

  if (winCurrentUser) {
    return (
      <div
        className={`hh-win-portrait ${winCurrentUser ? "win" : ""}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={() => {
          clearWinner();
        }}
      >
        <Slide direction="down" in={open.modal}>
          <div>{element}</div>
        </Slide>
      </div>
    );
  }

  return (
    <Slide direction="down" in={open.modal}>
      <div
        className={`hh-win-portrait ${winCurrentUser ? "win" : ""}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={() => {
          clearWinner();
        }}
      >
        {element}
      </div>
    </Slide>
  );
};

const mapStateToProps = state => ({
  sound_enabled: state.sounds && state.sounds.config ? state.sounds.config.enabled : false,
  client_player_id:
    state.profile.pageData && state.profile.pageData.client_player_id
      ? state.profile.pageData.client_player_id
      : state.profile.client_player_id
});

const actions = {
  happyHourClearPrizeWon
};

export default withTranslation()(connect(mapStateToProps, actions)(WinMobile));
