function normalizeLiveTicket(t, bst, pct, multiplier) {
  const ticket = {
    id: t.serial,
    idHash: t.serial,
    product: "Live",
    format: "live",
    type: t.ticketType === "combo" ? "SIMPLU" : "SISTEM",
    status: "WON",
    winning: t.maxPossibleWin,
    cashouted: t.cashouted ? t.cashouted : false,
    minWinning: 0,
    totalOdd: t.totalOdds,
    stake: t.stake,
    createdAt: t.created_at * 1000,
    systemType: t.ticketType === "combo" ? "SIMPLU" : "SISTEM",
    hasBonus: t.hasBonus ? true : false,
    minBonus: t.minBonus ? t.minBonus : 0,
    maxBonus: t.maxBonus ? t.maxBonus : 0,
    minBonusPercentage: t.minBonusPercentage ? t.minBonusPercentage : 0,
    maxBonusPercentage: t.maxBonusPercentage ? t.maxBonusPercentage : 0,
    bonusWonAmount: t.bonusWonAmount ? t.bonusWonAmount : 0,
    paidOutBonus: t.paidOutBonus ? t.paidOutBonus : 0,
    requestUuid: t.requestUuid,
    payout: pct,
    eval_res: {
      event_balance_multiplier: multiplier ? multiplier : 0
    }
  };

  const ts = [];
  let won = 0;
  let lost = 0;
  let bankers = 0;

  ticket.bets = t.bets.map(b => {
    const bet = {
      banker: b.ticketBetBank === "1" && ticket.type === "SISTEM",
      status: "WON",
      startAt: ticket.createdAt,
      idMatch: b.idMatch,
      idSport: b.idSport,
      idCategory: b.idCategory,
      idTournament: b.idTournament,
      idBet: b.idBet,
      idMatchBet: b.idMb,
      idBetOutcome: b.idBo,
      idMatchBetOutcome: b.idMbo,
      betDisplayName: b.betName,
      outcomeDisplayName: b.outcomeName,
      oddValue: b.value,
      specialValue: b.specialValue || "",
      teams: [
        { teamDisplayName: b.team1Name },
        { teamDisplayName: b.team2Name },
      ],
      results: []
    };

    // handle %player% issue
    /*
    betOutcome:
    betOutcomeDisplayName: "%player%"
    betOutcomeShortName: "%player%"
    idBetOutcome: "8/146/1/player home 5"
    idMatchBetOutcome: "22660061/8/146//Alba, Jordi!35199"
    providerBetOutcomeId: "Alba, Jordi"
    */
    if (bet.outcomeDisplayName === "%player%") {
      // try to get it from state / match data
      if (
        bst &&
        bst.live &&
        bst.live.matches &&
        typeof bst.live.matches[bet.idMatch] !== "undefined"
      ) {
        const m = bst.live.matches[bet.idMatch];

        const fmb = m.matchBets.find(mb => mb.idMb === bet.idMatchBet);

        if (fmb) {
          const fbo = fmb.mbOutcomes.find(bo => bo.idMbo === bet.idMatchBetOutcome);

          if (fbo && fbo.mboDisplayName) {
            bet.outcomeDisplayName = fbo.mboDisplayName
          }
        }
      }

      // get it from providerBetOutcomeId
      if (bet.outcomeDisplayName === "%player%" && b.providerBetOutcomeId) {
        bet.outcomeDisplayName = b.providerBetOutcomeId;
      }
    }

    if (bet.banker) bankers += 1;

    ts.push(bet.startAt);

    if (bet.status === "LOST") {
      lost += 1;
    }
    if (bet.status === "WON") {
      won += 1;
    }

    return bet;
  });

  if (!ticket.systemType && ticket.type === "SISTEM") {
    const parlays = [];
    let events = 0;
    t.ticketCombinationGroups.forEach(c => {
      parlays.push(c.parlays);
      events = c.events;
    });

    ticket.systemType = parlays.join(",") + "/" + events;
    if (bankers) ticket.systemType = bankers + "B+" + parlays.join(",") + "/" + events;
  }

  ticket.betsRes = {
    lost,
    won
  };

  ticket.maxTime = Math.max(...ts);
  ticket.minTime = Math.max(...ts);

  return ticket;
}

function normalizePreMatchTicket(t, bst, pct, multiplier) {
  const ticket = {
    id: t.serial,
    idHash: t.serial,
    product: "PreMatch",
    format: "prematch",
    type: t.ticketType === "combo" ? "SIMPLU" : "SISTEM",
    status: "WON",
    winning: t.maxWinAmount,
    minWinning: t.minWinAmount ? t.minWinAmount : 0,
    totalOdd: t.totalOddsMax,
    stake: t.stake,
    cashouted: t.cashouted ? t.cashouted : false,
    createdAt: t.created_at * 1000,
    hasBonus: t.hasBonus ? true : false,
    minBonus: t.minBonus ? t.minBonus : 0,
    maxBonus: t.maxBonus ? t.maxBonus : 0,
    minBonusPercentage: t.minBonusPercentage ? t.minBonusPercentage : 0,
    maxBonusPercentage: t.maxBonusPercentage ? t.maxBonusPercentage : 0,
    bonusWonAmount: t.bonusWonAmount ? t.bonusWonAmount : 0,
    paidOutBonus: t.paidOutBonus ? t.paidOutBonus : 0,
    bonusEligibles: [],
    cashBack: t.cashBack,
    payout: pct,
    eval_res: {
      event_balance_multiplier: multiplier ? multiplier : 0
    }
  };

  let systems = t.systems;
  systems.sort();
  ticket.systemType = systems.join(",") + "/" + t.bets.length;

  const ts = [];
  let won = 0;
  let lost = 0;

  ticket.bets = t.bets.map((b, i) => {
    const bet = {
      banker: b.banker === 1 && ticket.type === "SISTEM",
      status: "WON",
      startAt: ticket.createdAt,
      idMatch: b.idMatch,
      idSport: b.idSport,
      idCategory: b.idCategory,
      idTournament: b.idTournament,
      idBet: b.idBet,
      idMatchBet: b.idMb,
      idBetOutcome: b.idBo,
      idMatchBetOutcome: b.idMbo,
      betDisplayName: b.betName,
      outcomeDisplayName: b.outcomeName,
      oddValue: b.value,
      specialValue: b.specialValues && b.specialValues.length > 0 ? b.specialValues[0] : "",
      results: [],
      competitors: [],
    };

    bet.teams = [
      { teamDisplayName: b.team1Name },
      { teamDisplayName: b.team2Name },
    ];

    ts.push(bet.startAt);

    if (bet.status === "LOST") {
      lost += 1;
    }
    if (bet.status === "WON") {
      won += 1;
    }

    return bet;
  });

  ticket.systemType = systems.join(",") + "/" + t.bets.length;

  ticket.betsRes = {
    lost,
    won
  };

  ticket.maxTime = Math.max(...ts);
  ticket.minTime = Math.max(...ts);

  return ticket;
}

export {
  normalizeLiveTicket,
  normalizePreMatchTicket,
};
