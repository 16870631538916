import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  iframe: {
    width: "100%",
    height: props => props.height
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  }
});

const Statistics = props => {
  const {
    brId,
    index,
    idSport,
    provider,
    head2HeadId,
    mType,
    t,
    i18n
  } = props;
  const classes = useStyles(props);

  let style = {};
  if (index !== 1) {
    style = {
      maxHeight: "0px",
      overflow: "visible"
    };
  }

  if (provider === "digitain") {
    if (head2HeadId && !isNaN(Math.abs(head2HeadId))) {
      if (mType === "live") {
        const url = `https://livescore.winner.ro/matchview/${idSport}/g/${Math.abs(head2HeadId)}/?lg=${i18n.language}`;
        return (
          <div className={classes.root} style={style}>
            <iframe
              src={url}
              title={t("Match Statistics")}
              className={classes.iframe}
              frameBorder="0"
            >
              {t("Browser not compatible.")}
            </iframe>
          </div>
        );
      } else {
        const url = `https://stats.winner.ro/matchview/${idSport}/g/${Math.abs(head2HeadId)}/?lg=${i18n.language}`;
        return (
          <div className={classes.root} style={style}>
            <iframe
              src={url}
              title={t("Match Statistics")}
              className={classes.iframe}
              frameBorder="0"
            >
              {t("Browser not compatible.")}
            </iframe>
          </div>
        );
      }
    } else {
      return (
        <div className={classes.root} style={style}>
          <div className={classes.empty}>{t("Statistics not available.")}</div>
        </div>
      );
    }
  }

  //console.log("match brId", brId);

  if (!brId) {
    return (
      <div className={classes.root} style={style}>
        <div className={classes.empty}>{t("Statistics not available.")}</div>
      </div>
    );
  }

  let url = `https://s5.sir.sportradar.com/blingbet/ro/match/${brId}`;

  return (
    <div className={classes.root} style={style}>
      <iframe
        src={url}
        title={t("Match Statistics")}
        className={classes.iframe}
        frameBorder="0"
      >
        {t("Browser not compatible.")}
      </iframe>
    </div>
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    return {};
  };

  return mapStateToProps;
};

export default withTranslation()(connect(makeMapStateToProps)(Statistics));
