import React, { useEffect } from 'react';
import { withTranslation} from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import * as stateActions from "../../../../store/actions";
import Translate from '../../../../utils/Translate';
import './NotFound.scss';

const NotFound = (props) => {

    /**
     * help poedit to scan and find the text
     */
    const t = (string) => {
        return string;
    }

    useEffect(() => {
		window.scrollTo(0, 0);
		if (props.contentLoader.show) {
			props.contentLoaded();
		}
	}, []);

    const homePageHandler = () => {
        document.location.href = "/";
    }

    return (
        <div className={"NotFoundScreen"}>
            <h3><Translate text={t("Ooops!")} /></h3>
            <h1><Translate text={t("404")} /></h1>
            <p><Translate text={t("Page not found.")} /></p>
            <NavLink to={'/'} onClick={() => homePageHandler()}>
                <Translate text={t('Back to home page')} />
            </NavLink>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        application: state.application,
        contentLoader: state.contentLoader,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
        showCookiesInfo: () => dispatch(stateActions.toggleCookiesInfo()),
        contentLoaded: () => dispatch(stateActions.hideContentLoader()),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(NotFound)));