import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { geolocated } from 'react-geolocated';
import "./Locations.scss";
import * as stateActions from "../../store/actions";
import Icons from "../../assets/Icons/Icons";
import MapContainer from "./../PlayerProfile/Modals/Withdraw/Sections/MapContainer/MapContainer";
import LocationsList from "./Sections/LocationsList";
import * as GeoStates from "./GeoStates";

class Locations extends Component {
    constructor(props) {
        super(props);

        this.icons = {
            back: Icons.get('modal-back', 'backToAmount'),
            titleInfo: Icons.get('questionmarkCircle', 'title-info'),
            pin: Icons.get('PinAddressIcon', 'PinAddressIcon'),
        };

        this.timer = 0;

        this.google = null;

        this.geolocation = {
            latitude: this.props.profile.latitude,
            longitude: this.props.profile.longitude,
        };

        this.state = {
            location: null,
            tmp: false,
            geoStatus: GeoStates.UNKNOWN,
            geolocation: this.geolocation,
        };

        this.coordsReady = false;

        this.markers = {};

        this.clickMarker = null;

        this.elemHight = 0;
    }

    componentDidMount() {
        this.selfReset();

        this.props.loadLocations();

        if (this.props.contentLoader.show) {
            this.props.contentLoaded();
        }

        let pageHeight = window.innerHeight;
        this.elemHight = pageHeight - 78;

        this.setState({
            ...this.state,
            geoStatus: this.getGeoStatus(),
        });
    }

    getGeoStatus() {
        if (this.props.geolocation.coords) {
            return GeoStates.AVAILABLE;
        }

        // check geolocation:
        let geoStatus = GeoStates.UNKNOWN;
        if (!this.props.isGeolocationAvailable) {
            geoStatus = GeoStates.NOT_AVAILABLE;
            return geoStatus;
        }

        if (this.props.isGeolocationEnabled) {
            geoStatus = GeoStates.AVAILABLE;
            if (!this.props.coords) {
                geoStatus = GeoStates.UNKNOWN;
            }

            if (geoStatus === GeoStates.AVAILABLE) {
                this.resetOnce();
            }
            return geoStatus;
        }

        let positionError = this.props.positionError;
        if (!positionError) {
            return geoStatus;
        }

        if (!positionError.hasOwnProperty('code')) {
            return geoStatus;
        }

        if (1 === positionError.code) {
            geoStatus = GeoStates.DENIED;
            return geoStatus;
        }
    }

    componentDidUpdate() {
        let update = false;

        let geoState = this.getGeoStatus();
        if (!(this.state.geoStatus === geoState)) {
            update = true;
        }

        let geolocation = {};
        if (!this.coordsReady) {
            if (this.props.coords) {
                update = true;

                geolocation.longitude = this.props.coords.longitude;
                geolocation.latitude = this.props.coords.latitude;

                this.coordsReady = true;

                this.savePermission();

                this.props.resetActualPosition();

                this.props.setCoords(this.props.coords);
            } else if (this.props.geolocation.coords) {
                this.coordsReady = true;

                update = true;

                geolocation.longitude = this.props.geolocation.coords.longitude;
                geolocation.latitude = this.props.geolocation.coords.latitude;

                this.savePermission();

                this.props.resetActualPosition();

            }
        }

        if (!update) {
            return;
        }

        this.setState({
            ...this.state,
            geoStatus: geoState,
            geolocation: geolocation,
        });
    }

    selfReset() {
        this.timer = 0;

        this.google = null;
    }

    setMarkers(refMarkers) {
        this.markers = refMarkers;
    }

    setClickMarker(markerFunction) {
        this.clickMarker = markerFunction;
    }

    setLocation(location) {
        this.props.setLocation(location);
    }

    setGoogle(google) {
        this.google = google;
    }

    noneMethode() {
        //
    }

    savePermission() {
        if (this.hasPermission()) {
            return;
        }

        localStorage.setItem('location_permission', true);
    }

    hasPermission() {
        if (!localStorage.getItem('location_permission')) {
            return false;
        }

        return true;
    }

    render() {
        if (!(this.props.withdrawals.locations.length > 0) || window?.config?.hide_agencies === '1' || window?.config?.okto_enabled === '1') {
            return (
                <Fragment>
                    <div className={"emptyCasinoPage"}></div>
                </Fragment>
            );
        }

        let style = {
            height: `${this.elemHight}px`,
            maxHeight: `${this.elemHight}px`,
        };

        return (
            <div className={"Locations"} style={style}>
                <div className={"locationsLeft"}>
                    <div className={"leftTitle"}>
                        <div className={"titleBack"}>
                        </div>
                        <div className={"titleName"}>{this.props.t('agencies')}</div>
                    </div>
                    <LocationsList pageHeight={this.elemHight} geoStatus={this.state.geoStatus} />
                </div>
                <div className={"locationsMap"}>
                    <MapContainer
                        locations={this.props.withdrawals.locations}
                        setLocation={this.setLocation.bind(this)}
                        googleHandler={this.setGoogle.bind(this)}
                        lat={this.state.geolocation.latitude}
                        long={this.state.geolocation.longitude}
                        selectedLocation={this.props.withdrawals.location}
                        setMarkers={this.setMarkers.bind(this)}
                        clickMarker={this.setClickMarker.bind(this)}
                        withdrawHandler={this.noneMethode.bind(this)}
                        notWithdraw={true} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        profile: state.profile,
        contentLoader: state.contentLoader,
        geolocation: state.geolocation,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadLocations: () => dispatch(stateActions.requestLocations()),
        setLocation: (location) => dispatch(stateActions.selectedLocation(location)),
        setGeolocation: (latitude, longitude) => dispatch(stateActions.setGeolocation(latitude, longitude)),
        contentLoaded: () => dispatch(stateActions.hideContentLoader()),
        resetActualPosition: () => dispatch(stateActions.resetActualPosition()),
        setCoords: (coords) => dispatch(stateActions.setCoords(coords)),
    };
};

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
    suppressLocationOnMount: true,
    watchPosition: true
})(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Locations)));
