import { searchConstants } from './constants';

export const searchUpdate = (query) => ({
  type: searchConstants.SAVE,
  query
});

export const searchUpdateLeague = (query) => ({
  type: searchConstants.SAVE_LEAGUE,
  query
});
