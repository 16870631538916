import { takeEvery, takeLatest, put } from "redux-saga/effects";

import { freeSpinsLoaded, freeSpinsFetch, freeSpinsLoadedHistory } from "../actions/free_spins";
import { resetOneRequest } from "../actions/requestManager";
import { setBonusAvailable } from "../actions/application"
import { authentication, freeSpins, application } from "../actions/actionTypes";
import BackendClient from "../../BackendClient";

export const fetchFreeSpinsDumpHistory = () => {
    const axios = BackendClient.getInstance();
    const store = BackendClient.getStore();
    const state = store.getState();

    if (
        ["user", "token"].indexOf(state.authentication.auth_type) === -1 ||
        state.authentication.access_token === null
    ) {
        return new Promise((resolve, reject) => {
            reject(new Error("Not Authenticated"));
        });
    }

    return new Promise((resolve) => {
        axios({
            url: `/api/free-spins/dump-history`,
            method: 'get'
        }).then(response => {
            resolve(response && response.result && response.result.history ? response.result.history : []);
        }).catch((err) => {
            console.log("fetchFreeSpinsDumpHistory: err", err);
            resolve([]);
        });
    });
};

function* fetchFreeSpinsSaga() {
    const axios = BackendClient.getInstance();
    const store = BackendClient.getStore();
    const state = store.getState();

    if (
        ["user", "token"].indexOf(state.authentication.auth_type) === -1 ||
        state.authentication.access_token === null
    ) {
        yield put(freeSpinsLoaded([]));
        return;
    }

    try {
        const response = yield axios({
            url: `/api/free-spins/get-list`,
            method: 'get'
        });

        yield put(freeSpinsLoaded(response && response.result ? response.result : []));
    } catch (e) {
        console.log("fetchFreeSpinsSaga err", e);
    }
}

function* fetchFreeSpinsHistorySaga(action) {
    const axios = BackendClient.getInstance();
    const store = BackendClient.getStore();
    const state = store.getState();
    const page = action.page;

    if (
        ["user", "token"].indexOf(state.authentication.auth_type) === -1 ||
        state.authentication.access_token === null
    ) {
        yield put(freeSpinsLoadedHistory([]));
        return;
    }

    try {
        const response = yield axios({
            url: `/api/free-spins/get-history`,
            method: 'post',
            data: {
                page: page,
                items_per_page: 10,
            }
        });

        yield put(freeSpinsLoadedHistory(response && response.result ? { items: response.result} : { items: [] }));
    } catch (e) {
        console.log("fetchFreeSpinsSaga err", e);
    }
}


function* removeFreeSpinSaga(action) {
    const axios = BackendClient.getInstance();
    const store = BackendClient.getStore();
    const state = store.getState();
    const code = action.code;

    if (!code) return;

    if (
        ["user", "token"].indexOf(state.authentication.auth_type) === -1 ||
        state.authentication.access_token === null
    ) {
        return;
    }

    try {
        yield axios({
            url: `/api/free-spins/delete`,
            method: 'post',
            data: {
                code: code
            }
        });

        yield put(freeSpinsFetch());
        yield put(resetOneRequest('get-bonus-available'));
        yield put(setBonusAvailable([]));
    } catch (e) {
        console.log("fetchFreeSpinsSaga err", e);
    }
}

export default function* watchFreeSpinsSaga() {
    yield takeEvery(freeSpins.FREE_SPINS_FETCH, fetchFreeSpinsSaga);
    yield takeEvery(freeSpins.FREE_SPIN_REMOVE, removeFreeSpinSaga);
    yield takeEvery(freeSpins.FREE_SPINS_FETCH_HISTORY, fetchFreeSpinsHistorySaga);
    yield takeLatest(authentication.AUTHENTICATE, fetchFreeSpinsSaga);
    yield takeEvery(application.REINITIALIZE, fetchFreeSpinsSaga);
}
