import React, { Component } from 'react';
// import { connect } from 'react-redux';
import {DIGITS, LENGTH, LOWER_CASE, SPECIAL_CHAR, UPPER_CASE} from "../../../utils/PasswordValidator";
import Icons from "../../../assets/Icons/Icons";
import "./../../Register/Form/Password/Password.scss";
import { VelocityTransitionGroup } from 'velocity-react';
import './PasswordGraph.scss';
import PasswordValidator from "../../../utils/PasswordValidator";
// import * as stateActions from "./../../../store/actions";

class PasswordGraph extends Component {
    constructor(props) {
        super(props);

        this.defaultGroupClassName = "input-group";

        this.mState = {
            groupClassName: `${this.defaultGroupClassName} `,
            groupClassNameConfirm: `${this.defaultGroupClassName} notValid`,
            message: [],
            messageConfirm: "",
            ok: false,
            okConfirm: false,
            confirmInput: "",
            showPasswordStrength: false,
        };

        this.onScreen = false;
    }

    componentDidUpdate() {
        let fieldInput = this.props.profile.changePasswordForm.newPassword;
        if (null === fieldInput || fieldInput.length <= 0) {
            this.mState.showPasswordStrength = false;
            return;
        }

        this.onScreen = true;

        let groupClassName = undefined;
        let ok = this.mState.ok;
        let valid = PasswordValidator.validate(fieldInput);

        if (valid['success']) {
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        } else {
            groupClassName = `${this.defaultGroupClassName} `;
            if (ok) {
                ok = false;
            }
        }


        this.mState = {
            ...this.mState,
            groupClassName: groupClassName,
            message: valid,
            ok: ok,
            showPasswordStrength: true,
        };

        this.props.validationAction(valid['success']);
    }

    render() {

        let strength = this.mState.showPasswordStrength ? ' ' : 'hide';

        let notValid = "";
        let progress = 0;
        let style = [{width: 0,},{ width: 0,},{width: 0,}];
        let checkmark = Icons.get('checkmark','checkmark');
        if (this.props.profile.changePasswordForm.newPassword !== "" && this.mState.showPasswordStrength) {

            notValid = "";
            if(this.mState.message[LENGTH] === "success") {
                progress +=20;
            }
            if(this.mState.message[LOWER_CASE]  === "success") {
                progress +=20;
            }
            if(this.mState.message[UPPER_CASE]  === "success") {
                progress +=20;
            }
            if (progress === 40 || true) {
                if (this.mState.message[DIGITS]  === "success") {
                    progress += 20;
                }
                if (this.mState.message[SPECIAL_CHAR]  === "success") {
                    progress += 20;
                }
            }
            switch (progress) {

                case 60:
                    strength = 'Low';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'red';
                    break;
                case 80:
                    strength = 'Medium';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'orange';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'orange';
                    break;
                case 100:
                    strength = 'Maximum';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'green';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'green';
                    style[2].width = '100%';
                    style[2].backgroundColor = 'green';
                    break;
                case 0:
                case 20:
                case 40:
                default:
                    strength = 'Very Low';
                    strength = 'Low';
                    style[0].width = (progress * 1.65) + '%';
                    style[0].backgroundColor = 'red';
                    break;


            }
        }

        let htmlContent = (
            <div className={(strength === 'hide' ? strength : "") + " password-strength" + (this.mState.groupClassName.indexOf('valid') !== -1 ? ' valid' : '')}>
                <div className={strength + " progress-0 header"}>

                    Security Level:
                    <span className="right">{('hide' === strength) ? '' : strength}</span>
                    <div className="clearfix"> </div>
                    <div className="lines">
                        <div className="line">
                            <div className="fill" style={style[0]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[1]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[2]}> </div>
                        </div>
                    </div>
                </div>
                <ul className="list">
                    <li className={"title " + this.mState.groupClassName}>Password Requirements:</li>
                    <li className={(this.mState.message[UPPER_CASE] || this.mState.message[LOWER_CASE] ? "success" : notValid )}>
                        {checkmark}
                        Include at least 1 letter
                    </li>
                    <li className={this.mState.message[LENGTH] ? this.mState.message[LENGTH] : notValid}>
                        {checkmark}
                        Have at least 6 characters in length
                    </li>
                    <li className={this.mState.message[DIGITS] ? this.mState.message[DIGITS] : notValid}>
                        {checkmark}
                        Include at least 1 number
                    </li>
                    <div className="space"> </div>
                </ul>
                <div className="clearfix"> </div>
            </div>
        );

        return (
            <div className="PasswordGraphContent ColumnMessage col text-left" >
                <VelocityTransitionGroup enter={{animation: "slideDown"}} leave={{animation: "slideUp"}} duration={200}>
                    {(!this.onScreen) ? undefined : htmlContent}
                </VelocityTransitionGroup>
            </div>
        );
    }
}

export default PasswordGraph;