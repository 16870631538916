import React from 'react';
import moment from "moment";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ButtonBase from '@material-ui/core/ButtonBase';

import "./full_news.scss";
import { getText, styleToObj } from "../../utils/functions";
import { ReactComponent as BackIcon } from "./assets/arrow.svg";
import GTM from "../../../utils/GTM";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullNews = (props) => {
  const { data, open, onClose } = props;
  const { t, i18n } = useTranslation();

  const isDesktop = useMediaQuery("(min-width:1281px)");

  React.useEffect(() => {
    if (data) {
      try {
        GTM.pushGAEvent('NEWS', 'READ-ARTICLE', getText(data, `data.title.text.${i18n.language}`, ""));
      } catch (err) { }
    }

  }, []); // eslint-disable-line

  if (!data) return null;

  const article_title = getText(data, `data.title.text.${i18n.language}`, "")


  const backHandler = () => {
    GTM.pushGAEvent('NEWS', 'EXIT-ARTICLE-BACK', article_title);
    if (typeof onClose === "function") onClose();
  };

  const hasLink = data && data.url ? true : false;
  const navigateCard = () => {
    GTM.pushGAEvent('NEWS', 'EXIT-ARTICLE-LINK', article_title);
    if (data.url) {
      const url = data.url;
      if (url.toLowerCase().indexOf("http") > -1) {
        if (window.config.cordova) {
          window.cordova.InAppBrowser.open(url, '_system');
          return;
        }

        let win = window.open(url, '_blank');
        if (win !== null) {
          win.focus();
        }
        return;
      } else {
        props.history.push(url);
      }
    }
  };

  const fullScreen = isDesktop ? false : true;

  return (
    <Dialog
      fullScreen={fullScreen}
      disableEnforceFocus
      open={open}
      TransitionComponent={Transition}
      maxWidth={!fullScreen ? "sm" : false}
      className={`news-popup ${isDesktop ? "minHeight" : `h100vh`} mobile-league betsModal`}
    >
      <div className="news-full-page">
        <div className={`title-bar d-flex flex-row flex-nowrap align-items-center justify-content-between match-prematch-header match-modal-top`}>
          <div>
            <ButtonBase className="back" onClick={onClose}>
              <BackIcon className="backSvg" />
            </ButtonBase>
          </div>
          <div className="title">{t("News")}</div>
          <div >&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div className="news-content">
          <div className="news-image">
            <img src={data.data.image.desktop} alt="" />
          </div>
          <div className="news-flex">
            <div className="news-tag" style={styleToObj(data.data.tag.style)}>{getText(data, `data.tag.text.${i18n.language}`, "")}</div>
            <div className="news-date">{moment(data.data.startTime * 1000).format("D MMMM YYYY")}</div>
          </div>

          <div className="news-title" style={styleToObj(data.data.title.style)}>{article_title}</div>
          <div className="news-text" style={styleToObj(data.data.content.style)}>
            <div dangerouslySetInnerHTML={{ __html: getText(data, `data.content.text.${i18n.language}`, "") }} />
          </div>
          {hasLink && <div className="news-button-wrapper">
            <Button variant="contained" color="primary" className="see-more-button" onClick={navigateCard}>{t("See more details")}</Button>
          </div>}
          <div>&nbsp;</div>
        </div>
      </div>
    </Dialog>
  );
}


export default withRouter(FullNews);