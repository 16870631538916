import React from 'react';
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { getBaseRouterPath } from "../utils/config";

//import ElementHeader from "./elements/header";
//import ElementFooter from "./elements/footer";

import LiveHome from "../views/live/Home";
import LiveEvent from "../views/live/Event";
import LiveSport from "../views/live/Sport";
import LeftNav from "../components/left-nav-live";
import BetSlipFull from "../components/bet-slip/BetsFull";
import BetSlipFooter from "../components/betslip-footer";
import History from "../components/history";

const EntryPoint = (props) => {
  const basePath = getBaseRouterPath();

  return (
    <div className="n-page live">
      <History />
      {/*<ElementHeader />*/}
      <div className="content">
        <div className="frame">
          <LeftNav />

          <Route>
            <Switch>
              <Route exact path={`${basePath}/live`} component={LiveHome} />
              <Route exact path={`${basePath}/live/sport/:idSport`} component={LiveSport} />
              <Route exact path={`${basePath}/live/event/:idSport/:idCategory/:idTournament/:idMatch`} component={LiveEvent} />
            </Switch>
          </Route>

          <div className="sidebar" id="betslip-slider">
            <BetSlipFull desktop={true} />
          </div>
        </div>
      </div>
      <BetSlipFooter />
    </div>
  );
}

export default withTranslation()(EntryPoint);