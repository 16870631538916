import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { Animated } from "react-animated-css";

import * as stateActions from "../../store/actions";
import JackpotsHeader from "./JackpotsHeader";
import BackgroundImage from "./assets/img_jackpot_egt_web_bgjpdetails.jpg";

//import * as stateActions from './../../store/actions';
import "./Jackpot.scss";
import PromotedGames from "./JackpotGames/PromotedGames";
import RecentGames from "./JackpotGames/RecentGames";
import PopularGames from "./JackpotGames/PopularGames";
import AllGames from "./JackpotGames/AllGames";
import TickerWinners from "./TickerWinners";
import WinHistoryExtended from "./WinHistoryExtended";
import WinHistorySticky from "./WinHistorySticky";
import JackpotsHeaderV2 from "./HeaderV2"

const bgImage = {
    backgroundImage: `url(${BackgroundImage})`
};


class Jackpot extends PureComponent {
    getEmpty() {
        let eStyle = {
            minHeight: this.props.application.pageHeight
        };
        return (
            <div style={eStyle}></div>
        );
    }

    render() {
        if (!this.props.egt) {
            return this.getEmpty();
        }

        if (!this.props.egt.dataReceived) {
            return this.getEmpty();
        }

        return (
            <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={200}>
                <div className={"jackpotsPage"}>
                    <div className={"jackpotSpacer"}></div>
                    <JackpotsHeaderV2
                        integration={"desktop"}
                        name={"EGT"}
                        value={this.props.egtParsed.maxValue}
                        jackpot={this.props.egtParsed}
                        currency={this.props.egtParsed.currency}
                    />
                    <div className={"jackpotSpacer2"}></div>
                    <PromotedGames />
                    <TickerWinners />
                    <RecentGames />
                    <PopularGames />
                    <AllGames />
                </div>
            </Animated>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        egt: state.jackpots.egt,
        egtParsed: state.jackpots.egtParsed,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        reloadJackpots: () => { dispatch(stateActions.requestJackpots()) },
        reloadLatestJackpotWinners: () => { dispatch(stateActions.requestLatestWinners(1)) },
        reloadLatestWinners: () => { dispatch(stateActions.requestLatestWinners(0)) },
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Jackpot));