import * as actionTypes from './../actions/actionTypes';

const initialState = {
    loading: false,
    response: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.application.REDEEEM_CODE_RESET:
            return {
                ...state,
                loading: false,
                response: null
            };
        case actionTypes.application.REDEEEM_CODE:
            return {
                ...state,
                loading: true,
                response: null
            };
        case actionTypes.application.REDEEEM_CODE_RESPONSE:

            return {
                ...state,
                loading: false,
                response: action.response
            };
        default:
            return state;
    }
};

export default reducer;
