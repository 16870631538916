const callbacks = [];
let isRunning = false;

const execute = () => {
  if (callbacks.length && !isRunning) {
    isRunning = true;
    const f = callbacks.shift();
    if (f && typeof f == "function") {
      setTimeout(() => {
        f();
        isRunning = false;
        execute();
      }, 10);
    }
  } else {
    isRunning = false;
  }
};

const registerExecution = (func) => {
  callbacks.push(func);
  execute();
};

export { registerExecution };

