import React from 'react';
import { withTranslation } from "react-i18next";
import ClearIcon from '@material-ui/icons/Clear';
import { connect } from "react-redux";
import isArray from "lodash/fp/isArray";

import {
  getBetsState,
  makeGetBets,
} from "../../../bets/store/selectors/betData";
import {
  makeGetSelectedBet,
} from "../../../bets/store/selectors/betsSlip";
import {
  betsSlipRemove,
  betsSlipAcceptOddChange,
  betsSlipToggleFixed
} from "../../../bets/store/actions/betsSlip";
import { formatOddValue, formatBetTitle } from "../../utils/formatters";
import moment from 'moment';

const Bet = (props) => {
  const {
    t,
    mType,
    match,
    oddSelected,
    bets,
    //betAbbr,
    removeSelected,
    toggleFixed,
    selectedBet,
    showFixed,
    error,
    oddValueChanged,
    acceptOddChange,
    evaluateMessages,
    evaluateValid,
    bv
  } = props;
  //console.log("Bet", props);

  if (!match) return null;

  const title = `${match.team1Name} : ${match.team2Name}`;
  let bet = null;
  let outcome = null;
  const date = moment(match.matchDateTime).format("DD.MM HH:mm");

  match.matchBets.forEach(b => {
    b.mbOutcomes.forEach(o => {
      if (o.idMbo === oddSelected) {
        bet = b;
        outcome = o;

        let ostate = null;
        try {
          ostate = bets[match.idSport][b.idBet].betOutcomes[o.idBo];
        } catch (err) { }
        if (ostate && !outcome.mboDisplayName) {
          outcome = {
            ...outcome,
            mboDisplayName: ostate.betOutcomeName
          };
        }
      }
    })
  });

  let outcomeType = "";
  let outcomeValue = "";
  let betTitle = "";

  if (outcome) {
    outcomeType = outcome.mboDisplayName ? outcome.mboDisplayName : outcome.mboType;
    outcomeValue = formatOddValue(outcome.mboOddValue);
  }

  if (bet) {
    betTitle = formatBetTitle(bet, match, bets);
  }

  const handleDelete = () => {
    removeSelected(mType, oddSelected);
  };
  const handleOddChange = () => {
    acceptOddChange(mType, oddSelected);
  };
  const handleFixToggle = () => {
    toggleFixed(mType, oddSelected);
  };

  return (
    <div className={`bet-prematch ${error !== '' ? 'unavailable-bg' : ''} ${!error && oddValueChanged ? 'odd-changed-bg' : ''}  ${evaluateMessages && evaluateMessages.length !== 0 ? evaluateValid ? "evaluate-border-warning" : "evaluate-border-error" : ""}`}>
      <div className="flex-row clearfix">
        <div className="first-column">
          <div className="flex-row justify-between">
            <div className="teams">
              {title}
            </div>
            <div className="date">
              {date}
            </div>
          </div>
          <div className="flex-row justify-between second-row">
            <div className="odd-text">
              {showFixed && <span className={`fix-button ${selectedBet.fixed ? 'active' : ''}`} onClick={handleFixToggle}>FIX</span>}
              {betTitle} | {outcomeType}
            </div>
            <div className="odd-value">
              {bv > 0 && outcome.mboOddValue !== bv && <span className="old-odd-value">{bv}</span>}
              {outcomeValue}
            </div>
          </div>
        </div>
        <div className="second-column" onClick={handleDelete}>
          <div className="item-remove">
            <ClearIcon />
          </div>
        </div>
      </div>
      {evaluateMessages && evaluateMessages.length !== 0 && <div className="evaluate-message">
        {
          evaluateMessages.map((m, i) => (<div className={evaluateValid ? "evaluate-message-warning" : "evaluate-message-error"} key={i}>{m}</div>))
        }
      </div>}
      {error !== '' &&
        <div className="error clearfix">
          <div className="item-message">{error}</div>
          <div className="item-button" onClick={handleDelete}>
            {t("Unavailable event - Remove")}
          </div>
        </div>
      }
      {!error && oddValueChanged &&
        <div className="odd-changed clearfix">
          <div className="item-button" onClick={handleOddChange}>
            {t("Odds changed - Click to accept the changes")}
          </div>
        </div>
      }
    </div>
  );
}

const makeMapStateToProps = () => {
  const getBets = makeGetBets();
  const getSelectedBet = makeGetSelectedBet();

  return (state, props) => {
    const bst = getBetsState(state);

    let init = {};

    if (props.match) {
      init = {
        mType: props.match.mType,
        idMatch: props.match.idMatch,
        idSport: props.match.idSport,
        idCategory: props.match.idCategory,
        idTournament: props.match.idTournament,
      };
    }

    return {
      betAbbr: bst.config.betAbbr,
      bets: getBets(state, init),
      selectedBet: getSelectedBet(state, props)
    };
  };
};

const actionCreators = {
  removeSelected: betsSlipRemove,
  acceptOddChange: betsSlipAcceptOddChange,
  toggleFixed: betsSlipToggleFixed
};

export default withTranslation()(connect(makeMapStateToProps, actionCreators)(Bet));