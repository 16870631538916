import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import "./views.scss";
import { makeGetTableById } from "../store/selectors/liveCasinoData";
import config from "../api/config";
import * as stateActions from '../../store/actions';
import { doLogin } from "../../store/actions/authentication";
import Icons from '../../assets/Icons/Icons';
import { checkIfBundleExits, downloadBundle, cancelBundleDownload } from "../../cordova/cdv-download-evolution-bundle";

const noop = () => { };

const Table = (props) => {
  const { table, access_token, hideLoader, auth, t } = props;

  let imgSrc = "";
  if (table && table.videoSnapshot && table.videoSnapshot.thumbnails) {
    if (table.videoSnapshot.thumbnails.XL) {
      imgSrc = `${table.videoSnapshot.thumbnails.XL[0] === "/" ? config.evoHost : ""
        }${table.videoSnapshot.thumbnails.XL}`
    }
  }

  const [gameUrl, setGameUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [odrState, setOdrState] = React.useState({
    type: null,
    progress: 0,
    message: ""
  });

  let loggedIn = false;
  if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const launchGame = () => {
    if (!table) return;
    if (!loggedIn) {
      handleLogin();
      return;
    }

    if (!gameUrl) {
      setLoading(true);
      axios.get(
        config.launchGame,
        {
          params: {
            "gameCategory": table.gameTypeUnified,
            "gameTableId": table.id,
            "platformType": window.config.platformType,
            "urls": {
              "cashier": `https://${window.location.hostname}/cazino_live`,
              "sessionTimeout": `https://${window.location.hostname}/cazino_live`,
            }
          },
          headers: {
            Authorization: "Bearer " + access_token,
          }
        }
      ).then(resp => {
        window.scrollTo(0, 0);

        if (resp && resp.data && typeof resp.data !== "string" && resp.data.link) {

          let entryEmbedded = resp.data.link;
          if (window.config.cordova && window.config.native_platform === "ios") {
            entryEmbedded = `http://127.0.0.1:9007/evolution-cdn/index.html?origin=${encodeURIComponent(resp.data.link)}`;
          }

          window.EvolutionGaming.init({
            iframeId: "evolution-game",
            url: entryEmbedded,
            topBar: 48,
            topBarLandscape: 48,
            topBarPortrait: 48,
            allowFullscreen: true,
          });
          setGameUrl(entryEmbedded);
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      })
    }
  };

  const checkAndDownloadBundle = () => {
    checkIfBundleExits((res) => {
      if (res) {
        launchGame();
      } else {
        downloadBundle((res) => {
          if (res.type === "progress-download") {
            console.log(res);
            setOdrState({
              type: "progress-download",
              progress: res.progress,
            });
          } else if (res.type === "progress-zip") {
            setOdrState({
              type: "progress-zip",
              progress: res.progress,
            });
          } else if (res.type === "error") {
            setOdrState({
              type: "error",
              message: res.message,
            });
          } else if (res.type === "done") {
            setOdrState({
              type: null,
              progress: 0,
              message: ""
            });
            launchGame();
          }
        });
      }
    });
  };

  const handleLogin = () => {
    localStorage.setItem("redirect_after_login", props.location.pathname);

    if (window.config.platformType === 2) {
      // mobile - do a redirect
      props.history.push("/connect");
    } else {
      // web - show modal
      props.doLogin();
    }
  };

  const goBack = () => {
    let path = props.history.location.pathname ? props.history.location.pathname.split('/') : [];
    if (path && path.length > 2 && path.includes('cazino_live')) {
      props.history.push("/cazino_live");
    } else {
      props.history.goBack();
    }
  };

  const launchIOSGame = () => {
    checkAndDownloadBundle();
  }

  React.useEffect(() => {
    hideLoader();
    if (window.config.cordova && window.config.native_platform === "ios") {
      launchIOSGame();
    } else {
      launchGame();
    }

    document.body.classList.add("table-full-screen");

    return () => {
      document.body.classList.remove("table-full-screen");
      try {
        window.EvolutionGaming.removeEventListeners();
        cancelBundleDownload();
      } catch (err) { }
    }
  }, []); // eslint-disable-line

  let playIcon = Icons.get("gamePlayIcon", "table-play-icon-svg");

  return (
    <div className="live-casino-wrapper-table">
      {table && <div className="table">
        <div className={`table-header`} onClick={goBack}>
          <IconButton aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <div
          className={`table-game ${loading ? 'table-loading' : ''}`}
          onClick={
            (odrState.type === null || odrState.type !== "error")
              ? window.config.cordova && window.config.native_platform === "ios" ? launchIOSGame : launchGame
              : noop
          }
        >
          {gameUrl === "" && <React.Fragment>
            <img src={imgSrc} className={"table-image"} alt="" />
            {!loading && odrState.type === null && <div className="table-play-icon">
              {playIcon}
            </div>}
            {loading && odrState.type === null && <div className="table-loader">
              <CircularProgress />
            </div>}
            {odrState.type !== null && <div className="table-resource-info">
              {odrState.type === "progress-download" === "error" &&
                <div>
                  Error: {odrState.message}
                </div>
              }
              {odrState.type === "progress-download" &&
                <div>
                  {t("Downloading game files")}... {odrState.progress}%
                </div>
              }
              {odrState.type === "progress-zip" &&
                <div>
                  {t("Preparing game files")}... {odrState.progress}%
                </div>
              }
            </div>}
          </React.Fragment>}

          <iframe src={gameUrl} alt="" className={`evolution-iframe ${gameUrl !== "" ? '' : 'd-none'}`} title="game" id="evolution-game" scrolling="no" allowFullScreen={true} webkitallowfullscreen="true" />
        </div>
      </div>}
    </div>
  );
}

const makeMapStateToProps = () => {
  const getTable = makeGetTableById();

  const mapStateToProps = (state, props) => {

    const customProps = { idCategory: "all" };

    if (props.match && props.match.params && props.match.params.category) {
      customProps.idCategory = props.match.params.category;
    }

    if (props.match && props.match.params && props.match.params.table) {
      customProps.idTable = props.match.params.table;
    }

    return {
      table: getTable(state, customProps),
      access_token: state.authentication.access_token,
      auth: state.authentication,
      topMenuHeight: state.application.topMenuHeight,
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => {
  return {
    hideLoader: () => { dispatch(stateActions.hideContentLoader()) },
    doLogin: () => { dispatch(doLogin()) }
  };
};

export default withTranslation()(withRouter(connect(makeMapStateToProps, mapDispatchToProps)(Table)));