import axios from 'axios';
// import * as actionTypes from './store/actions/actionTypes';
import * as actions from './store/actions/';
import * as paths from './paths';
import * as actionTypes from "./store/actions/actionTypes";

const ACCOUNT_SUSPENDED = 21;
const ACCOUNT_CLOSED = 22;
const ACCOUNT_SELF_EXCLUDED = 23;

class BackendClient {
    constructor() {
        // console.log('BackendClient constructor CALLED !!!');
        this.called = false;
        this.instance = undefined;
        this.store = undefined;

        this.appBaseUrl = window.config.front_url;

        this.cancelRetry = false;
        this.retryTimer = 0;

        this.retryTimerByUrl = [];
    }

    setStore(store) {
        this.store = store;

        this.init();
    }

    init() {
        if (undefined === this.store) {
            return;
        }

        if (this.called) {
            return;
        }
        this.called = true;

        let self = this;
        this.instance = axios.create({
            baseURL: self.appBaseUrl,
            transformRequest: [
                (data, headers) => {
                    headers.common["Access-Control-Allow-Origin"] = "*";
                    headers.common["X-Requested-With"] = "XMLHttpRequest";
                    headers.common["Platform-Type"] = window.config.platformType ? window.config.platformType : process.env.CFW.platformType;

                    return data;
                },
                ...axios.defaults.transformRequest
            ]
        });

        /*
        this.instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        this.instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        this.instance.defaults.headers.common['Platform-Type'] = window.config.platformType;
        */

        this.instance.interceptors.request.use(request => {
            // let data = request.data;

            if (!self.allowRequest(request)) {
                console.log(`Request rejected!`);
                // console.log(request);
                self.retryIn1(request);
                return Promise.reject({});
            }



            return request;
        }, error => {
            console.info(`[ERROR] Server request error => ${error}`);
            console.log(`[NETWORK] Error, network disconnected!`);

            return Promise.reject(error);
        });

        this.instance.interceptors.response.use(response => {
            // console.info('Response from server:');
            // console.info(response);

            // check if responseType is blob
            if (response.hasOwnProperty('config') && response.config.hasOwnProperty('responseType') && "blob" === response.config.responseType) {
                if ("text/csv" === response.data.type) {
                    if (!(response.data.size > 3)) {
                        return self.store.dispatch(actions.emptyCsvReport());
                    }
                    return self.store.dispatch(actions.downloadHistoryCsv(response));
                }

            }

            if (!response.hasOwnProperty('data')) {
                return;
            }
            let responseData = response.data;
            if (responseData.hasOwnProperty('coins')) {
                return self.store.dispatch(actions.setWinCoins(responseData));
            }
            if (responseData.hasOwnProperty('rows')) {

                if (responseData.hasOwnProperty('success')) {
                    if (responseData.success) {
                        return self.store.dispatch(actions.setPreEuroTickets(responseData));
                    }
                }
            }

            if (!responseData.hasOwnProperty("type")) {
                console.info('Response object has no TYPE!!!');
                return self.executeGeneralError();
            }

            if (responseData.hasOwnProperty('result')) {
                // check for response message 21, 22 and 23
                // const ACCOUNT_SUSPENDED = 21;
                // const ACCOUNT_CLOSED = 22;
                // const ACCOUNT_SELF_EXCLUDED = 23;
                if (responseData.result.hasOwnProperty('ResponseCode') && [ACCOUNT_SUSPENDED, ACCOUNT_CLOSED, ACCOUNT_SELF_EXCLUDED].includes(responseData.result.ResponseCode)) {
                    let responseMessage = null;
                    if (responseData.result.hasOwnProperty('ResponseMessage')) {
                        responseMessage = responseData.result.ResponseMessage;
                    }
                    self.store.dispatch(actions.logout());
                    self.store.dispatch(actions.openAccountProblemModal(responseData.result.ResponseCode, responseMessage));
                }
            }

            switch (responseData['type']) {
                case "menu":
                    self.store.dispatch(actions.menuLoaded(responseData['content']));
                    break;
                case "authorization":
                    // console.info('Server response is a AUTHORIZATION object!');
                    if ("OK" === responseData['status']) {
                        self.store.dispatch(actions.authSuccess(responseData['result']));
                        self.store.dispatch(actions.receivedCheckPassword(true));
                    }
                    break;
                case "refresh_token":
                    if ("OK" === responseData['status']) {
                        // store new access data
                        // update access data
                        // can't fix missing refresh token because token can be used only one time!
                        self.store.dispatch(actions.authSuccess(responseData['result']));
                    } else {
                        // logout
                        self.store.dispatch(actions.authenticationExpired());
                    }
                    break;
                case "games":
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    self.store.dispatch(actions.loadGames(responseData['result']));
                    break;
                case "table-games":
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    self.store.dispatch(actions.loadGames(responseData['result']));
                    break;
                case "page":
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    break;
                case "game":
                    self.store.dispatch(actions.gameLoaded(responseData['result']));
                    break;
                case "phone-validation":
                    self.store.dispatch(actions.responseReceived(responseData, 'phone'));
                    break;
                case "verify-phone":
                    self.store.dispatch(actions.receivedRegisterPhoneValidation(responseData));
                    break;
                case "mail-validation":
                    self.store.dispatch(actions.responseReceived(responseData, 'email'));
                    break;
                case "cnp-validation":
                    self.store.dispatch(actions.responseReceived(responseData, 'cnp'));
                    break;
                case "countries":
                    self.store.dispatch(actions.countriesReceived(responseData));
                    break;
                case "default-country":
                    self.store.dispatch(actions.defaultCountryReceived(responseData));
                    break;
                case "signup":
                    self.store.dispatch(actions.signUpResponseReceived(responseData));
                    break;
                case "profile":
                case 'responsible-gaming':
                case 'self-exclusion':
                case 'customer-support':
                case 'take-a-break':
                case 'self-exclude':
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    self.store.dispatch(actions.stopProfilePageLoading());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.receivedProfilePageData(responseData.result));
                    if (responseData['type'] === 'profile') {
                        self.store.dispatch(actions.setMarketingAgreements({
                            ...responseData.result.marketing_agreement,
                            sendToServer: false
                        }));
                    }
                    break;
                case "password":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.store.dispatch(actions.step2Message('error', "Server error! Please try again later."));
                    }
                    self.store.dispatch(actions.changePasswordResponse(responseData.result));
                    break;
                case "phone-number-validation":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.store.dispatch(actions.step1MessagePhone('error', "Server error! Please try again later."));
                    }
                    self.store.dispatch(actions.receivedPhoneValidation(responseData.result));
                    break;
                case "sms-code-validation":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.store.dispatch(actions.step2MessagePhone('error', "Server error! Please try again later."));
                    }
                    self.store.dispatch(actions.receivedCodeValidation(responseData.result));
                    break;
                case "update-profile-account":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.receivedProfilePageData(responseData.result));
                    self.store.dispatch(actions.receivedPlayerInfo(responseData.result))
                    break;
                case "email-sent":
                case "email_sent":
                    self.store.dispatch(actions.emailSent());
                    break;
                case "change-address":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.store.dispatch(actions.changeAddressMessage('error', "Server error! Please try again later."));
                    }
                    self.store.dispatch(actions.receivedAddressChange(responseData.result));
                    break;
                case "token-email-validation":
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    self.store.dispatch(actions.stopProfilePageLoading());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.tokenEmailValidation(responseData.result));
                    break;
                case "forfeit":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.forfeitResponseReceived(responseData.result));
                    break;
                case "sms-code":
                    if (!responseData.hasOwnProperty('result')) {
                        self.store.dispatch(actions.toggleModalVisibility(false));
                        return self.store.dispatch(actions.changeAddressMessage('error', "Server error! Please try again later."));
                    }
                    self.store.dispatch(actions.receivedSmsLoginError(responseData.result));
                    break;
                case "documents-approved-files":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.responseDocumentsApproval(responseData.result));
                    break;
                case "documents":
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    self.store.dispatch(actions.stopProfilePageLoading());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.receivedDocumentsPageData(responseData.result));
                    self.store.dispatch(actions.receivedDocumentsPreview(responseData.result));
                    break;
                case "documents-uploaded":
                    self.store.dispatch(actions.receivedUploadResponse());
                    self.store.dispatch(actions.closeUploadModal());
                    break;
                case "get-chat-info":

                    self.store.dispatch(actions.receivedUserInfo(responseData.result));
                    break;
                case "wallet-preview":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.receivedWalletPreview(responseData.result));
                    self.store.dispatch(actions.walletReceived(responseData));
                    break;
                case "promotions":
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }

                    // let bonusValue = 0;
                    // Object.keys(responseData.result).forEach((key) => {
                    //     responseData.result[key].type = 1;
                    //     if (responseData.result[key].title === "Bonus de Bun Venit") {
                    //         bonusValue = responseData.result[key].value;
                    //     }
                    // })
                    self.store.dispatch(actions.setPromotions(responseData.result));
                    // self.store.dispatch(actions.setMaxBonus(bonusValue));
                    break;
                case "pirate-treasure":
                case "live-games":
                case "live-today":
                case "betting":
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    break;
                case 'reset_password_email_sent':
                    self.store.dispatch(actions.resetPasswordEmailSent(responseData.result));
                    break;
                case 'locations':
                    self.store.dispatch(actions.loadLocalLocations(responseData.result));
                    break;
                case "deposit":
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    return self.store.dispatch(actions.locationsReceived(responseData.result));
                case 'responsible-gaming-set-limit':
                    return self.store.dispatch(actions.setLimitResponseReceived(responseData.result));
                case 'error':
                    self.store.dispatch(actions.showUploadModalButtons());
                    self.executeGeneralError();
                    break;
                case 'withdraw-request':
                    self.store.dispatch(actions.receivedWithdrawalRequest(responseData.result));
                    break;
                case 'withdraw-safecharge':
                    self.store.dispatch(actions.receivedWithdrawSafeCharge(responseData.result));
                    break;
                case 'pending-withdrawals':
                case 'pending-viva-withdrawals':
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    self.store.dispatch(actions.stopProfilePageLoading());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.withdrawalRequestsReceived(responseData));
                    break;
                case 'cancel-withdraw':
                case 'cancel-viva-withdraw':
                    self.store.dispatch(actions.receiveCancelWithdrawal(responseData));
                    break;
                case "withdraw-code":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.receivedVerificationCode(responseData.result));
                    break;
                case "documents-preview":
                    if (!responseData.hasOwnProperty('result')) {
                        return;
                    }
                    self.store.dispatch(actions.receivedDocumentsPreview(responseData.result));
                    break;
                case 'reset-password-with-token':
                    self.store.dispatch(actions.resetPasswordResponseReceived(responseData.result));
                    break;
                case 'game-rules':
                    if (!responseData.hasOwnProperty('groups')) {
                        return;
                    }
                    self.store.dispatch(actions.receivedGameRules(responseData.items, responseData.groups));
                    break;
                case 'transactions-history':
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    self.store.dispatch(actions.stopProfilePageLoading());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.transactionsHistoryReceived(responseData));
                    break;
                case 'tax':
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.receivedTaxService(responseData.result));
                    break;
                case 'jackpots':
                    self.store.dispatch(actions.hideContentLoader());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }

                    self.store.dispatch(actions.jackpotsReceived(responseData.result));
                    //console.log(responseData.result);
                    if (responseData.result && responseData.result.egt) {
                        self.store.dispatch(actions.receivedJackpotListDetails("1", responseData.result.egt));
                    }
                    if (responseData.result && responseData.result.egt_egypt_quest) {
                        self.store.dispatch(actions.receivedJackpotListDetails("3", responseData.result.egt_egypt_quest));
                    }
                    if (responseData.result && responseData.result.ct) {
                        self.store.dispatch(actions.receivedJackpotListDetails("4", responseData.result.ct));
                    }

                    break;
                case 'latest-winners-jackpot':
                    self.store.dispatch(actions.hideContentLoader());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }

                    self.store.dispatch(actions.latestJackpotWinnersReceived(responseData.result));
                    break;
                case 'latest-winners-slots':
                    self.store.dispatch(actions.hideContentLoader());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }

                    self.store.dispatch(actions.latestWinnersReceived(responseData.result));
                    break;
                case 'jackpot':
                    if (!responseData.hasOwnProperty('jackpotType')) {
                        console.log(`[JACKPOT] Error on JackpotType -- UNKNOWN`);
                        break;
                    }
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    // console.log(responseData);
                    self.store.dispatch(actions.jackpotProviderReceived(responseData.jackpotType, responseData.result));
                    break;
                case 'post-complaint':
                    if (!((typeof responseData.data === 'object' || typeof responseData.data === 'Array') && responseData.data.length === 0)) {
                        return self.executeGeneralError();
                    }

                    self.store.dispatch({
                        type: actionTypes.notifications.COMPLAINT_RESPONSE,
                        data: responseData.data
                    });
                    break;
                case 'check-password':
                    // if ()
                    break;
                case 'max-upload-size':
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.setMaxFileSize(responseData.result));
                    break;
                case 'signup':
                    self.store.dispatch(actions.signUpResponseReceived(responseData));
                    break;
                case 'pay-checkout':
                    self.store.dispatch(actions.safeChargeReceived(responseData));
                    break;
                case 'game-collections':
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.receivedGameCollections(responseData.result));
                    break;
                case 'eligible-bonuses':
                    self.store.dispatch(actions.hideContentLoader());
                    self.store.dispatch(actions.contentLoaded());
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.setEligibleBonuses(responseData.result));
                    break;
                case 'preeuro':
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.setPreEuroTickets(responseData.result));
                    break;
                case "bonus-available":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.setBonusAvailable(responseData.result));
                    break;
                case "bonuses":
                    if (!responseData.hasOwnProperty('result')) {
                        return self.executeGeneralError();
                    }
                    self.store.dispatch(actions.setBonuses(responseData.result));
                    break;
                case "claim-bonus":
                    if (!responseData.status) {
                        throw new Error(`[ERROR] GET_AVAILABLE_BONUSES missing status!`);
                    }

                    if (!("OK" === responseData.status)) {
                        throw new Error(`[ERROR] GET_AVAILABLE_BONUSES message not sent!`);
                    }

                    self.store.dispatch(actions.bonusClaimed(responseData.result));
                    break;
                case 'free-bet-get-list':
                case "free-bet-get-history":
                case "free-bet-dump-history":
                case "viva-auth":
                case "viva-card-token":
                case "viva-charge-token":
                case "viva-withdraw":
                case "redeem-code":
                case "free-spin-get-list":
                case "free-spin-get-history":
                case "free-spin-dump-history":
                case "scan-document":
                case "marketing-offer":
                case "get-partial-account":
                case "send-sms":
                case "validate-phone":
                case "register-v2":
                case "set-partial-account":

                case "okto-get-customer":
                case "okto-create-payment-code":
                    return responseData;
                default:
                    break;
            }

            // return response;
        }, error => {
            if (!error.hasOwnProperty('response')) {
                return;
            }

            console.info(`[ERROR] Server response error => ${error}`);
            console.info(error.response);

            let responseData = error.response.data;

            if (responseData.hasOwnProperty('type')) {
                switch (responseData['type']) {
                    case 'authorization':
                        if (responseData['result'] && !responseData['result']['allowed'] && !responseData['result']['http']) {
                            self.cancelRetry = true;
                            return self.store.dispatch(actions.appNotAllowed());
                        }
                        self.store.dispatch(actions.receivedCheckPassword(false));
                        self.store.dispatch(actions.authFail(error));
                        break;
                    case 'refresh_token':
                        self.store.dispatch(actions.authenticationExpired());
                        break;
                    case 'phone-number-validation':
                        self.store.dispatch(actions.stopPhoneValidationProgress());
                        break;
                    case 'sms-code-validation':
                        self.store.dispatch(actions.stopCodeValidationProgress());
                        break;
                    case 'password':
                        self.store.dispatch(actions.stopPasswordRequest());
                        break;
                    case "signup":
                        self.store.dispatch(actions.signUpResponseReceived(responseData));
                        break;
                    case "email_sent":
                        self.store.dispatch(actions.signUpOK());
                        break;
                    case "get-chat-info":
                        return self.store.dispatch(actions.receivedUserInfo(responseData.result));
                    case "wallet-preview":
                        break;
                    case "phone-validation":
                        self.store.dispatch(actions.responseReceived(responseData, 'phone'));
                        break;
                    case 'error':
                        self.store.dispatch(actions.showUploadModalButtons());
                        self.executeGeneralError();
                        break;

                    case 'game':
                        self.store.dispatch(actions.gameError());
                        break;
                    case "scan-document":
                    case "marketing-offer":
                    case "get-partial-account":
                    case "send-sms":
                    case "validate-phone":
                    case "register-v2":
                    case "set-partial-account":
                    case "okto-create-payment-code":
                    case "okto-get-customer":
                            return responseData;
                        break;
                    default:
                        break;
                }


            } else {
                self.executeGeneralError();
            }

            return Promise.reject(error);
        });
    }

    getInstance() {
        return this.instance;
    }

    dispatchCloseLoader() {
        this.store.dispatch(actions.hideContentLoader());
    }

    getStore() {
        return this.store;
    }

    getAuthState() {
        let state = this.store.getState();
        return state.authentication;
    }

    isAuthenticated(request) {
        let authentication = this.getAuthState();

        if (!this.instance.defaults.headers.common.hasOwnProperty('Authorization')) {
            return false;
        }

        if (!request.headers.common.hasOwnProperty('Authorization')) {
            request.headers.common['Authorization'] = this.instance.defaults.headers.common['Authorization'];
        }

        if (null !== authentication.auth_type) {
            return true;
        }

        return false;
    }

    allowRequest(request) {
        if (!request.hasOwnProperty('url')) {
            return false;
        }

        if (this.isAuthenticated(request)) {
            return true;
        }

        let url = request.url;
        switch (url) {
            case paths.menu.ALL:
            case paths.authentication.APP:
            case paths.authentication.AUTH:
                return true;

            default:
                return false;
        }
    }

    retryIn1(request) {
        if (this.cancelRetry) {
            clearTimeout(this.retryTimer);
            return;
        }

        if (typeof this.retryTimerByUrl[request.url] === "undefined") {
            this.retryTimerByUrl[request.url] = 1;
        } else {
            if (this.retryTimerByUrl[request.url] > 30) {
                clearTimeout(this.retryTimer);
                return;
            }
            this.retryTimerByUrl[request.url] = this.retryTimerByUrl[request.url] + (this.retryTimerByUrl[request.url] + 1);
        }

        // console.log(request);
        this.retryTimer = setTimeout(() => {
            this.instance(request).catch(e => {
                // console.log('[REQUEST] Request set to retry:');
                // console.log(request);
                this.store.dispatch(actions.contentError(e));
            });
        }, this.retryTimerByUrl[request.url] * 1000);
    }

    executeGeneralError() {
        let state = this.store.getState();

        if (state.authModal.visible) {
            this.store.dispatch(actions.changeAuthButton());
            return;
        }

        let e = {
            toString() {
                return "Request cannot be processed!";
            }
        };
        this.store.dispatch(actions.contentError(e));
    }
}

export default new BackendClient();
