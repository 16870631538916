import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import StepWizard from "react-step-wizard";
import './Register.scss';
import Steps from "./Steps/Steps";
import * as actionTypes from '../../store/actions/actionTypes';
import * as storeActions from "../../store/actions";
import StepsHeader from "./Steps/StepsHeader";
import { VelocityComponent } from "velocity-react";
import ClaimBonus from "./Steps/ClaimBonus";
import img from "react-image";
import Utils from "../../utils/Utils";
import {productID} from "../../constants/Products";
import RegisterV2 from "./RegisterV2/RegisterV2";
// import YahooPixel from "../../utils/YahooPixel";


const FINAL_STEP_ANIMATION_TIME = 2000;

class Register extends Component {

    constructor(props) {
        super(props);

        // YahooPixel.init();

        this.baseUrl = window.config.front_url;

        this.stepsId = 'registerWizard';

        this.sounds = {
            registerComplete: new Audio(this.baseUrl + "/sounds/register_complete.wav")
        };

        this.sounds.registerComplete.preload = "auto"; // preload audio in browser

        this.image = {
          left: this.baseUrl + '/img/bg_leftside.png',
          right: this.baseUrl + '/img/bg_rightside.png'
        };

    }



    state= {
        style: {
            opacity: 1,
        },
        background: {
            style: {
                opacity: 0,
            },
            duration: 0,
            delay: 0,
        },
    };

    fixOverflow() {
        let obj = document.getElementById(this.stepsId);
        if (obj) {
            if (obj.style) {
                obj.style.height = 0;
                obj.style.overflow = "hidden";
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.signedUp !== prevProps.signedUp) {


            Utils.delayedFunction("fixRegisterWizardOverflow",this.fixOverflow.bind(this),1000);

            Utils.playSound(this.sounds.registerComplete);

            this.setState({
                ...this.state,
                style: {
                    ...this.state.style,
                    width: 0,
                    opacity: 0,
                    left: -1920
                },
                background: {
                    style: {
                        opacity: 1
                    },
                    duration: 3000,
                    delay: 5000,
                }
            })
        }
    }

    componentWillMount() {
        this.props.hideLoader();
        this.props.hideMenu();
        this.props.hideLeftMenu();
        try {
            let el = document.getElementsByClassName('Footer')[0];
            el.className += ' hide';
        } catch (e){}
    }
    componentDidMount() {
        const email = Utils.getQueryParam('emailYahoo');

        // autocomplete email from parameters
        if (email) {
            this.props.onChange({ field: 'email', value: email, validated: true })
        }

    }
    componentWillUnmount() {
        this.props.showMenu();
        this.props.showLeftMenu();
        // document.addEventListener("click",this.toggleBackgroundNoise);
        this.props.resetForm();
        try {
            let el = document.getElementsByClassName('Footer')[0];
            el.className = el.className.replace('hide','');
        } catch (e){}
    }
    isOldRegister() {
        return Utils.getQueryParam('winner_fun') || this.props.productId === productID.WINNER_FUN || window.config.force_old_register === '1'
    }
    render() {
        if (this.isOldRegister()) {
            return (
                <VelocityComponent animation={{opacity: 1}} duration={1000} runOnMount={true}>
                    <div className="registerWizard">
                        <div className={"container"}>
                            <VelocityComponent animation={this.state.background.style}
                                               duration={this.state.background.duration}
                                               delay={this.state.background.delay} runOnMount={true}>
                                <div className="background-image">
                                    <img src={this.image.left} alt="casino-promo-banner-left"/>
                                </div>
                            </VelocityComponent>
                            <StepsHeader previousStep={this.props.previousStep} goToStep={this.props.goToStep}
                                         currentStep={this.props.currentStep}/>
                            <div className="form" id={"register"}>
                                <div id={this.stepsId}>
                                    <VelocityComponent animation={this.state.style} duration={FINAL_STEP_ANIMATION_TIME}
                                                       runOnMount={true}>
                                        <StepWizard>
                                            <Steps form="CreateNewAccount"/>
                                            <Steps form="PersonalDetails"/>
                                            <Steps form="Verification"/>
                                            {/*<Steps form="ClaimBonus"/>*/}
                                        </StepWizard>
                                    </VelocityComponent>
                                </div>
                                {(!(window.config && window.config.register_disabled === '1')) && <ClaimBonus/>}
                            </div>
                            <div className="clearfix"></div>
                            {(!(window.config && window.config.register_disabled === '1')) &&
                            <VelocityComponent animation={this.state.background.style}
                                               duration={this.state.background.duration}
                                               delay={this.state.background.delay} runOnMount={true}>
                                <div className="background-image right">
                                    <img src={this.image.right} alt="casino-promo-banner-right"/>
                                </div>
                            </VelocityComponent>}
                        </div>
                    </div>
                </VelocityComponent>
            );
        }

        return <VelocityComponent animation={{opacity: 1}} duration={1000} runOnMount={true}>
            <RegisterV2/>
        </VelocityComponent>
    }
}
const mapDispatchToProps = dispatch => {
    return {
        hideLoader: () => dispatch({type: actionTypes.contentLoader.HIDE}),
        hideMenu: () => dispatch({type: actionTypes.menu.HIDE}),
        showMenu: () => dispatch({type: actionTypes.menu.SHOW}),
        hideLeftMenu: () => dispatch({type: actionTypes.menu.HIDE_LEFT_MENU}),
        showLeftMenu: () => dispatch({type: actionTypes.menu.SHOW_LEFT_MENU}),
        resetForm: () => dispatch({type: actionTypes.registerForm.RESET_FORM}),
        onChange: (fieldInput) => dispatch(storeActions.fieldEntered(fieldInput))
    };
};

const mapStateToProps = state => {

    return {
        signedUp: state.registerWizard.signedUp,
        signedUpErrors: state.registerWizard.signedUpErrors,
        productId: state.application.lastProductId
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
