import React from 'react';
import connect from "react-redux/es/connect/connect";
import { withTranslation } from 'react-i18next';
import Moment from "react-moment";
import moment from "moment";

// import "./FreeSpin.scss";
import Icons from "../../../../../assets/Icons/Icons";
import { freeSpinsFetchHistory } from "../../../../../store/actions/free_spins";
import { fetchFreeSpinsDumpHistory } from "../../../../../store/sagas/free_spins";

const visibleStyle = {
    overflow: "visible"
};

const exportToCsv = (filename, rows) => {
    const processRow = row => {
        var finalVal = "";
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? "" : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
            if (j > 0) finalVal += ",";
            finalVal += result;
        }
        return finalVal + "\n";
    };

    let csvFile = "";
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

const formatCurrency = (value, small) => {
    if (small === 1) value = value / 100;

    return (
        value
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            .replace(',00', '')
    )
};

const processDump = (list, t) => {
    const listData = [];

    const bonusStatus = {
        0: t("other"),
        1: t("active"),
        2: t("inactive"),
        3: t("expired"),
        4: t("canceled"),
        6: t("collected"),
    };

    const handleList = (bonus) => {
        let name = bonus.bonus_name;

        let title = `${bonus.remaining_fs}/${bonus.total_fs} ${t("Spins")}`;
        let amount = ``;

        listData.push([
            moment.unix(bonus.insert_date).format("DD/MM/YYYY HH:mm:ss"),
            t(name),
            title,
            amount,
            t(bonusStatus[bonus.bonus_status])
        ]);
    };

    list.forEach(handleList);

    listData.sort((ta, tb) => {
        if (ta[0] < tb[0]) {
            return -1;
        }
        if (ta[0] > tb[0]) {
            return 1;
        }
        return 0;
    });

    listData.unshift([t("Event Date"), t("Name"), t("Tokens"), t("Result"), t("Status")]);

    exportToCsv("free_spins_history.csv", listData);
};

const FreeSpin = (props) => {
    const { items, hasMore, freeSpinsFetchHistory, tabIndex, t } = props;

    const bonusStatus = {
        0: t("other"),
        1: t("active"),
        2: t("inactive"),
        3: t("expired"),
        4: t("canceled"),
        6: t("collected"),
    };
    const statusColors = {
        "1": { "name": "active", "color": "sucess" },
        "2": { "name": "inactive", "color": "failed" },
        "3": { "name": "expired", "color": "failed" },
        "4": { "name": "forfeited", "color": "failed" },
        "6": { "name": "collected", "color": "success" },
    };
    const historic = [];
    items.forEach((bonus, i) => {

        let name = bonus.bonus_name;
        historic.push(<li key={i} className={"container"}>
            <div className={"historyElementR1 row"}>
                <div className={"col-9 trsDesc"}>
                    <div className={"bName"}>{t(name)}</div>
                    <span className={"spacer"}>|</span>
                    {bonus.total_fs} {props.t("Spins")}
                </div>
                <div className={"col-3 trsTimestamp text-right"}>
                    <Moment format={"DD/MM/YYYY"} unix>{bonus.insert_date}</Moment>
                </div>
            </div>
            <div className={"historyElementR2 row"}>
                <div className={"col-9 text-left trsDesc"}>
                    <div className={`text-left transactionStatus ${statusColors[bonus.bonus_status_id].color}`}>{t(bonusStatus[bonus.bonus_status_id])}</div>
                </div>
                <div className={"col-3 text-right trsTimestamp"}>
                    <Moment format={"HH:mm"} unix>{bonus.insert_date}</Moment>
                </div>
            </div>
        </li>)
    });

    const [currentPage, setCurrentPage] = React.useState(0);

    React.useEffect(() => {
        freeSpinsFetchHistory(currentPage)
    }, [currentPage, freeSpinsFetchHistory]);

    const downloadCsv = () => {
        const historyDumpReq = fetchFreeSpinsDumpHistory();

        historyDumpReq.then(list => {
            console.log("list", list);
            processDump(list, t);
        })
            .catch(e => {
                //console.log("failed to get tickets", e);
            });
    };

    let dIcon = Icons.get("historyCsvDownload", "historyCsvDownload");
    let dIconClass = ["dIcon"];
    let dText = "Download history for last 90 days";

    let style = null;
    if (tabIndex !== 1) {
        style = visibleStyle;
    }

    return (
        <div className={"transactionsList"} style={style}>
            {historic.length !== 0 && <ul className={"bonusList"}>
                {historic}
            </ul>}
            {historic.length === 0 && <div className={"transactionsList emptyList"}>
                <p>{t("no results found")}</p>
            </div>}
            <div className={"downloadCsv"}>
                <div onClick={downloadCsv}>
                    <span className={dIconClass.join(" ")}>{dIcon}</span>
                    <span className={"dText"}>{t(dText)}</span>
                </div>
            </div>
        </div>
    );
}

const mapPropsToState = () => {
    return (state, props) => {
        return {
            items: state.freeSpins.freeSpinsHistory,
            hasMore: state.freeSpins.freeSpinsHistoryHasMore,
        };
    }
}

const mapActions = {
    freeSpinsFetchHistory
};


export default connect(mapPropsToState, mapActions)(withTranslation()(FreeSpin));
