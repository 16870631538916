import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import moment from "moment";

import Section from "../layout/Section";
import FilterBy from "../components/Filter/FilterBy";
import { getSelected } from "../store/selectors/preMatchFilter";
import { filterUnselect } from "../store/actions/preMatchFilter";
import { getBetsState } from "../store/selectors/betData";
import BetSelectSingle from "../components/BetSelectSingle";

import Match from "../components/Match";
import Collapsible from "../components/Collapsible";
import InPageMD from "./InPageMD";

import { sortArrayByKey } from "../utils";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#EDF0F0",
    padding: "16px 14px"
  }
});

function PreMatchFilter(props) {
  const {
    //allMatches,
    matchData,
    categories,
    tournaments,
    idSport,
    selected,
    remove
  } = props;
  const classes = useStyles();

  const md = matchData[idSport];

  if (!md) {
    return null;
  }

  let expanded = 0;

  const chs = Object.keys(md).map(c => {
    return Object.keys(md[c]).reduce((acc, t) => {
      if (!(idSport + "_" + c + "_" + t in selected)) {
        return acc;
      }

      //console.log("md[c][t]", md[c][t]);

      if (!(c in md && t in md[c])) {
        return acc;
      }

      let ms = Object.values(md[c][t]);

      if (!ms || !ms.length) return null;

      ms = ms.filter(m => m.active);
      sortArrayByKey(ms, "matchDateTime");

      const matchGroup = "mg_prematch_" + idSport + "_" + c + "_" + t;

      const displayMatches = [];
      let mCurrDate = ms && ms.length ? moment(ms[0].matchDateTime) : null;
      let currDate = ms && ms.length ? ms[0].matchDateTime : null;

      displayMatches.push(<BetSelectSingle
        key={displayMatches.length}
        matches={ms}
        match={ms.length ? ms[0] : null}
        mType="prematch"
        matchGroup={matchGroup}
        time={currDate}
      />);

      ms.forEach((m, i) => {
        if (mCurrDate) {
          const mDate = moment(m.matchDateTime);
          if (mCurrDate.format("YYYY-MM-DD") !== mDate.format("YYYY-MM-DD")) {
            mCurrDate = mDate;

            displayMatches.push(<BetSelectSingle
              key={displayMatches.length}
              matches={ms}
              match={ms.length ? ms[0] : null}
              mType="prematch"
              matchGroup={matchGroup}
              second={true}
              time={m.matchDateTime}
            />);
          }
        }
        displayMatches.push(<Match
          key={displayMatches.length}
          mType={m.mType}
          idMatch={m.idMatch}
          inGroup={true}
          matchGroup={matchGroup}
        />);
      });

      acc = acc.concat(
        <Collapsible
          type="preMatch"
          idSport={idSport}
          region={categories[c].categoryIsoName}
          categoryDLId={categories[c].categoryDLId}
          league={tournaments[t].tournamentName}
          description={tournaments[t].description}
          leagueDetails={`#league-details-prematch-${idSport}-${c}-${t}`}
          key={acc.length}
          opened={expanded < 5}
          events={ms ? ms.length : 0}
        >
          {displayMatches}
        </Collapsible>
      );

      expanded++;

      return acc;
    }, []);
  });

  const doRemove = id => {
    remove("prematch", idSport, id);
  };

  return (
    <Section doRender={[idSport, selected]}>
      <InPageMD>
        <FilterBy
          matchData={md}
          filterType="prematch"
          idSport={idSport}
          items={selected}
          onDelete={doRemove}
        />
        <div className={classes.root}>{chs}</div>
      </InPageMD>
    </Section>
  );
}

PreMatchFilter.propTypes = {
  idSport: PropTypes.string,
  selected: PropTypes.object,
  remove: PropTypes.func
};

PreMatchFilter.defaultProps = {
  idSport: "0",
  selected: {},
  remove: () => { }
};

const mapStateToProps = () => {
  return function realMapStateToProps(state, props) {
    const bst = getBetsState(state);

    return {
      //allMatches: bst.prematch.matches,
      matchData: bst.prematch.data,
      categories: bst.prematch.categories,
      tournaments: bst.prematch.tournaments,
      idSport: bst.navSports.prematch,
      selected: getSelected(state, {
        filterType: "prematch",
        idSport: bst.navSports.prematch
      })
    };
  };
};
const actionCreators = {
  remove: filterUnselect
};

export default connect(mapStateToProps, actionCreators)(PreMatchFilter);
