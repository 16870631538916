import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import InView from "react-intersection-observer";

import { debug } from "../../utils";
import { statScoreSave } from "../../store/actions/statScore";
import { getBetsState } from "../../store/selectors/betData";
import { statScoreLiveURL } from "../../api/widgets";

const useStyles = makeStyles({
  root: {
    width: "100%",
    background: "#0a1752",
    padding: "2px 0",
    overflow: "visible",
    position: "relative",
    zIndex: "3",
    minHeight: "26px",

    "& .STATSCOREWidgetContainer>.STATSCOREWidget": {
      padding: "2px",
    },
    "& .STATSCOREWidget--liveBar__incidentTeamColor": {
      width: "auto!important"
    }
  },
  gradient: {
    position: "absolute",
    top: "-15px",
    left: "0",
    right: "0",
    height: "15px",
    background: "linear-gradient(0deg, rgba(10,23,82,1) 0%, rgba(10,23,82,0) 100%)",
  },
  dNone: {
    display: "none"
  },
  noInfo: {
    background: "rgba(46,45,59,0.03)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    minHeight: "26px",

    "& .stat-score-timeline-gradient": {
      background: "linear-gradient(0deg, rgba(46,45,59,1) 0%, rgba(46,45,59,0) 100%)",
    }
  },
  noInfoText: {
    textAlign: "left",
    padding: "0 10px",
    fontSize: "12px",
    fontWeight: "normal",
    color: "#FFEDF4",
    paddingBottom: "2px",
  },
  probeWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  probe: {
    width: 0,
    height: 0,
    opacity: 0,
  }
});

const footballId = "1";

const StatsScoreTimeline = (props) => {
  const { ssId, idMatch, idSport, statsScoreStatus, statScoreSave, topLiveModel, noStatScore, i18n, t } = props;
  const classes = useStyles();

  const [showWidget, setShowWidget] = React.useState(false);
  const [componentInView, setComponentInView] = React.useState(true);
  const unmounted = React.useRef(false);

  const handleWidgetError = (e) => {
    if (unmounted.current) return;
    setShowWidget(false);
  };

  const handleWidgetMounted = (e) => {
    if (unmounted.current) return;
    setShowWidget(true);
  };

  const domID = `widget_${ssId}_${idMatch}_${topLiveModel}`;
  const widget = React.useRef(null);
  const initedForId = React.useRef("");

  React.useEffect(() => {
    if (unmounted.current) return;

    if (ssId && idSport === footballId && initedForId.current !== ssId) {
      if (initedForId.current && widget.current && typeof widget.current.destroy === "function") {
        debug("destroy live score widget");
        widget.current.off("error", handleWidgetError);
        widget.current.off("mount", handleWidgetMounted);
        widget.current.destroy();
        widget.current = null;
      }
      let element = document.getElementById(domID);

      // Configuration that you should receive from STATSCORE
      const configurationId = '5f8f274aaa0b6199c00a08a0';

      // Input data for widget type you want to embded
      const inputData = { eventId: ssId, language: "en" }; // i18n.language

      // Optional object with options. 
      // You can check available options further in the docs.
      const options = {};

      try {
        widget.current = new window.STATSCOREWidgets.Widget(element, configurationId, inputData, options);
        console.log("widget", widget.current);
        initedForId.current = ssId;
      } catch (err) { }

      if (widget.current) {
        widget.current.on("mount", handleWidgetMounted);
        widget.current.on("error", handleWidgetError);
      }

      element = null;
    } else {
      handleWidgetMounted();
    }
  }, [ssId, idSport, domID, i18n.language]);

  React.useEffect(() => {
    // idSport === footballId online live sport matches have timeline
    if (idMatch && idSport === footballId && statsScoreStatus && typeof statsScoreStatus[idMatch] === "undefined" && componentInView) {
      const getStatsScoreStatus = () => {
        axios
          .get(statScoreLiveURL(idMatch))
          .then(r => {
            if (unmounted.current) return;
            if (r.data && r.data.api && r.data.api.method && r.data.api.method.total_items && r.data.api.data.booked_events) {
              const sId = r.data.api.data.booked_events.length && r.data.api.data.booked_events[0].id ? r.data.api.data.booked_events[0].id : "";
              statScoreSave(idMatch, true, sId);
            } else {
              statScoreSave(idMatch, false, "");
            }
          })
          .catch(err => {
            debug("statsScore err", err);
          });
      };

      if (!noStatScore) {
        getStatsScoreStatus();
      }
    }
  }, [idMatch, idSport, statsScoreStatus, statScoreSave, noStatScore, componentInView]);

  React.useEffect(() => {
    return () => {
      unmounted.current = true;

      if (widget.current && typeof widget.current.destroy === "function") {
        debug("destroy live score widget");
        widget.current.off("error", handleWidgetError);
        widget.current.off("mount", handleWidgetMounted);
        widget.current.destroy();
        widget.current = null;
      }
    }
  }, []); // eslint-disable-line

  const inViewportChange = (inView) => {
    setComponentInView(inView);
  };

  return (
    <div className={`${classes.root} ${!showWidget && topLiveModel ? classes.noInfo : !showWidget ? classes.dNone : ''} stat-score-timeline`}>
      <InView onChange={inViewportChange} className={classes.probeWrapper}>
        <div className={classes.probe}></div>
      </InView>
      <div className={`${classes.gradient} stat-score-timeline-gradient`}></div>
      <div id={domID} className={`${!showWidget ? classes.dNone : ''} ${!componentInView ? classes.dNone : ""}`}></div>
      {/*!showWidget && topLiveModel && <div className={classes.noInfoText}>{t("Live text temporar indisponibil")}</div>*/}
    </div>
  );
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const bst = getBetsState(state);

    const ret = {
      statsScoreStatus: bst.statScore.exists,
    };

    if (!props.ssId) {
      if (props.idMatch && bst.statScore.exists && typeof bst.statScore.exists[props.idMatch] !== "undefined") {
        if (bst.statScore.exists[props.idMatch].status) {
          ret["ssId"] = bst.statScore.exists[props.idMatch].ssId;
        }
      }
    }
    return ret;
  };

  return mapStateToProps;
};

const actionCreators = {
  statScoreSave
};

export default connect(makeMapStateToProps, actionCreators)(withTranslation()(StatsScoreTimeline));