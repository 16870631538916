import React from 'react';

import { ReactComponent as Missing } from '../../assets/sports/missing.svg';

// Digitain
import { ReactComponent as Id1football } from "../../assets/sports-digitain/1_football.svg";
import { ReactComponent as Id2waterPolo } from "../../assets/sports-digitain/2_water_polo.svg";
import { ReactComponent as Id3tennis } from "../../assets/sports-digitain/3_tennis.svg";
import { ReactComponent as Id4basketball } from "../../assets/sports-digitain/4_basketball.svg";
import { ReactComponent as Id5baseball } from "../../assets/sports-digitain/5_baseball.svg";
import { ReactComponent as Id6americanFootball } from "../../assets/sports-digitain/6_american_football.svg";
import { ReactComponent as Id7boxing } from "../../assets/sports-digitain/7_boxing.svg";
import { ReactComponent as Id8golf } from "../../assets/sports-digitain/8_golf.svg";
import { ReactComponent as Id9chess } from "../../assets/sports-digitain/9_chess.svg";
import { ReactComponent as Id10iceHockey } from "../../assets/sports-digitain/10_ice_hockey.svg";
import { ReactComponent as Id11bandy } from "../../assets/sports-digitain/11_bandy.svg";
import { ReactComponent as Id12volleyball } from "../../assets/sports-digitain/12_volleyball.svg";
import { ReactComponent as Id13handball } from "../../assets/sports-digitain/13_handball.svg";
import { ReactComponent as Id14rugbyUnion } from "../../assets/sports-digitain/14_rugby_union.svg";
import { ReactComponent as Id15biatlon } from "../../assets/sports-digitain/15_biatlon.svg";
import { ReactComponent as Id16whatWhereWhen } from "../../assets/sports-digitain/16_what_where_when.svg";
import { ReactComponent as Id17futsal } from "../../assets/sports-digitain/17_futsal.svg";
import { ReactComponent as Id18formula1 } from "../../assets/sports-digitain/18_formula_1.svg";
import { ReactComponent as Id19snooker } from "../../assets/sports-digitain/19_snooker.svg";
import { ReactComponent as Id20darts } from "../../assets/sports-digitain/20_darts.svg";
import { ReactComponent as Id21fieldHockey } from "../../assets/sports-digitain/21_field_hockey.svg";
import { ReactComponent as Id22beachFootball } from "../../assets/sports-digitain/22_beach_football.svg";
import { ReactComponent as Id23curling } from "../../assets/sports-digitain/23_curling.svg";
import { ReactComponent as Id24other } from "../../assets/sports-digitain/24_other.svg";
import { ReactComponent as Id25tableTennis } from "../../assets/sports-digitain/25_table_tennis.svg";
import { ReactComponent as Id26beachVolleyball } from "../../assets/sports-digitain/26_beach_volleyball.svg";
import { ReactComponent as Id27rollerHockey } from "../../assets/sports-digitain/27_roller_hockey.svg";
import { ReactComponent as Id28eurovision } from "../../assets/sports-digitain/28_eurovision.svg";
import { ReactComponent as Id29badminton } from "../../assets/sports-digitain/29_badminton.svg";
import { ReactComponent as Id30rugbyLeague } from "../../assets/sports-digitain/30_rugby_league.svg";
import { ReactComponent as Id31bowls } from "../../assets/sports-digitain/31_bowls.svg";
import { ReactComponent as Id32squash } from "../../assets/sports-digitain/32_squash.svg";
import { ReactComponent as Id33politics } from "../../assets/sports-digitain/33_politics.svg";
import { ReactComponent as Id34olimpicGames } from "../../assets/sports-digitain/34_olimpic_games.svg";
import { ReactComponent as Id35specials } from "../../assets/sports-digitain/35_specials.svg";
import { ReactComponent as Id36cricket } from "../../assets/sports-digitain/36_cricket.svg";
import { ReactComponent as Id37florball } from "../../assets/sports-digitain/37_florball.svg";
import { ReactComponent as Id38speadway } from "../../assets/sports-digitain/38_speadway.svg";
import { ReactComponent as Id39aussieRules } from "../../assets/sports-digitain/39_aussie_rules.svg";
import { ReactComponent as Id40padelTennis } from "../../assets/sports-digitain/40_padel_tennis.svg";
import { ReactComponent as Id41cycling } from "../../assets/sports-digitain/41_cycling.svg";
import { ReactComponent as Id42skiing } from "../../assets/sports-digitain/42_skiing.svg";
import { ReactComponent as Id43skyJumping } from "../../assets/sports-digitain/43_sky_jumping.svg";
import { ReactComponent as Id44yachting } from "../../assets/sports-digitain/44_yachting.svg";
import { ReactComponent as Id45miniFootball } from "../../assets/sports-digitain/45_mini_football.svg";
import { ReactComponent as Id46ufc } from "../../assets/sports-digitain/46_ufc.svg";
import { ReactComponent as Id47netball } from "../../assets/sports-digitain/47_netball.svg";
import { ReactComponent as Id48athletics } from "../../assets/sports-digitain/48_athletics.svg";
import { ReactComponent as Id49judo } from "../../assets/sports-digitain/49_judo.svg";
import { ReactComponent as Id50equestrian } from "../../assets/sports-digitain/50_equestrian.svg";
import { ReactComponent as Id51softball } from "../../assets/sports-digitain/51_softball.svg";
import { ReactComponent as Id52gaelicFootball } from "../../assets/sports-digitain/52_gaelic_football.svg";
import { ReactComponent as Id53esports } from "../../assets/sports-digitain/53_esports.svg";
import { ReactComponent as Id54weightlifting } from "../../assets/sports-digitain/54_weightlifting.svg";
import { ReactComponent as Id55poker } from "../../assets/sports-digitain/55_poker.svg";
import { ReactComponent as Id56sumo } from "../../assets/sports-digitain/56_sumo.svg";
import { ReactComponent as Id57gymnastics } from "../../assets/sports-digitain/57_gymnastics.svg";
import { ReactComponent as Id58taekwondo } from "../../assets/sports-digitain/58_taekwondo.svg";
import { ReactComponent as Id59pool } from "../../assets/sports-digitain/59_pool.svg";
import { ReactComponent as Id60shooting } from "../../assets/sports-digitain/60_shooting.svg";
import { ReactComponent as Id61archery } from "../../assets/sports-digitain/61_archery.svg";
import { ReactComponent as Id62waterSports } from "../../assets/sports-digitain/62_water_sports.svg";
import { ReactComponent as Id63lacrosse } from "../../assets/sports-digitain/63_lacrosse.svg";
import { ReactComponent as Id64motorcycling } from "../../assets/sports-digitain/64_motorcycling.svg";
import { ReactComponent as Id65surfing } from "../../assets/sports-digitain/65_surfing.svg";
import { ReactComponent as Id66rally } from "../../assets/sports-digitain/66_rally.svg";
import { ReactComponent as Id67nascar } from "../../assets/sports-digitain/67_nascar.svg";
import { ReactComponent as Id68canoeing } from "../../assets/sports-digitain/68_canoeing.svg";
import { ReactComponent as Id69autoRacing } from "../../assets/sports-digitain/69_auto_racing.svg";
import { ReactComponent as Id70lottery } from "../../assets/sports-digitain/70_lottery.svg";
import { ReactComponent as Id71skyRacing } from "../../assets/sports-digitain/71_sky_racing.svg";
import { ReactComponent as Id72greyhound } from "../../assets/sports-digitain/72_greyhound.svg";
import { ReactComponent as Id73horseRacing } from "../../assets/sports-digitain/73_horse_racing.svg";
import { ReactComponent as Id74sailing } from "../../assets/sports-digitain/74_sailing.svg";
import { ReactComponent as Id75billiard } from "../../assets/sports-digitain/75_billiard.svg";
import { ReactComponent as Id76fencing } from "../../assets/sports-digitain/76_fencing.svg";
import { ReactComponent as Id77proWrestling } from "../../assets/sports-digitain/77_pro_wrestling.svg";
import { ReactComponent as Id78oscar } from "../../assets/sports-digitain/78_oscar.svg";
import { ReactComponent as Id79grammy } from "../../assets/sports-digitain/79_grammy.svg";
import { ReactComponent as Id80basketball3x3 } from "../../assets/sports-digitain/80_basketball3x3.svg";
import { ReactComponent as Id81tableFootball } from "../../assets/sports-digitain/81_table_football.svg";
import { ReactComponent as Id82default } from "../../assets/sports-digitain/82_default.svg";
import { ReactComponent as Id83modernPentathlon } from "../../assets/sports-digitain/83_modern_pentathlon.svg";
import { ReactComponent as Id84triathlon } from "../../assets/sports-digitain/84_triathlon.svg";
import { ReactComponent as Id85wrestling } from "../../assets/sports-digitain/85_wrestling.svg";
import { ReactComponent as Id86beachHandball } from "../../assets/sports-digitain/86_beach_handball.svg";
import { ReactComponent as Id87hurling } from "../../assets/sports-digitain/87_hurling.svg";
import { ReactComponent as Id88armyGames } from "../../assets/sports-digitain/88_army_games.svg";
import { ReactComponent as Id89bareKnuckleBoxing } from "../../assets/sports-digitain/89_bare_knuckle_boxing.svg";
import { ReactComponent as Id90kabaddi } from "../../assets/sports-digitain/90_kabaddi.svg";
import { ReactComponent as Id91indycar } from "../../assets/sports-digitain/91_indycar.svg";
import { ReactComponent as Id92kickboxing } from "../../assets/sports-digitain/92_kickboxing.svg";
import { ReactComponent as Id93sepakTakraw } from "../../assets/sports-digitain/93_sepak_takraw.svg";
import { ReactComponent as Id94efootball } from "../../assets/sports-digitain/94_e_football.svg";
import { ReactComponent as Id95ebasketball } from "../../assets/sports-digitain/95_e_basketball.svg";
import { ReactComponent as Id96eicehockey } from "../../assets/sports-digitain/96_e_icehockey.svg";
import { ReactComponent as Id97etennis } from "../../assets/sports-digitain/97_e_tennis.svg";
import { ReactComponent as Id98enascar } from "../../assets/sports-digitain/98_e_nascar.svg";
import { ReactComponent as Id99evolleyball } from "../../assets/sports-digitain/99_e_volleyball.svg";
import { ReactComponent as Id100oddsbooster } from "../../assets/sports-digitain/100_odds_booster.svg";
import { ReactComponent as Id101klask } from "../../assets/sports-digitain/101_klask.svg";
import { ReactComponent as Id102airhockey } from "../../assets/sports-digitain/102_air_hockey.svg";
import { ReactComponent as Id103pesapallo } from "../../assets/sports-digitain/103_pesapallo.svg";
import { ReactComponent as Id104karate } from "../../assets/sports-digitain/104_karate.svg";
import { ReactComponent as Id105figureSkating } from "../../assets/sports-digitain/105_figure_skating.svg";
import { ReactComponent as Id106skateboarding } from "../../assets/sports-digitain/106_skateboarding.svg";
import { ReactComponent as Id107climbing } from "../../assets/sports-digitain/107_climbing.svg";
import { ReactComponent as Id108speedSkating } from "../../assets/sports-digitain/108_speed_skating.svg";
import { ReactComponent as Id109shortTrack } from "../../assets/sports-digitain/109_short_track.svg";
import { ReactComponent as Id110freestyleSkiing } from "../../assets/sports-digitain/110_freestyle_skiing.svg";
import { ReactComponent as Id111bobsleigh } from "../../assets/sports-digitain/111_bobsleigh.svg";
import { ReactComponent as Id112nordicCombined } from "../../assets/sports-digitain/112_nordic_combined.svg";
import { ReactComponent as Id113luge } from "../../assets/sports-digitain/113_luge.svg";
import { ReactComponent as Id114skeleton } from "../../assets/sports-digitain/114_skeleton.svg";
import { ReactComponent as Id115snowboard } from "../../assets/sports-digitain/115_snowboard.svg";
import { ReactComponent as Id118virtualSport } from "../../assets/sports-digitain/118_virtual_sport.svg";

const assoc = {
  '1': '1_football',
  '2': '2_water_polo',
  '3': '3_tennis',
  '4': '4_basketball',
  '5': '5_baseball',
  '6': '6_american_football',
  '7': '7_boxing',
  '8': '8_golf',
  '9': '9_chess',
  '10': '10_ice_hockey',
  '11': '11_bandy',
  '12': '12_volleyball',
  '13': '13_handball',
  '14': '14_rugby_union',
  '15': '15_biatlon',
  '16': '16_what_where_when',
  '17': '17_futsal',
  '18': '18_formula_1',
  '19': '19_snooker',
  '20': '20_darts',
  '21': '21_field_hockey',
  '22': '22_beach_football',
  '23': '23_curling',
  '24': '24_other',
  '25': '25_table_tennis',
  '26': '26_beach_volleyball',
  '27': '27_roller_hockey',
  '28': '28_eurovision',
  '29': '29_badminton',
  '30': '30_rugby_league',
  '31': '31_bowls',
  '32': '32_squash',
  '33': '33_politics',
  '34': '34_olimpic_games',
  '35': '35_specials',
  '36': '36_cricket',
  '37': '37_florball',
  '38': '38_speadway',
  '39': '39_aussie_rules',
  '40': '40_padel_tennis',
  '41': '41_cycling',
  '42': '42_skiing',
  '43': '43_sky_jumping',
  '44': '44_yachting',
  '45': '45_mini_football',
  '46': '46_ufc',
  '47': '47_netball',
  '48': '48_athletics',
  '49': '49_judo',
  '50': '50_equestrian',
  '51': '51_softball',
  '52': '52_gaelic_football',
  '53': '53_esports',
  '54': '54_weightlifting',
  '55': '55_poker',
  '56': '56_sumo',
  '57': '57_gymnastics',
  '58': '58_taekwondo',
  '59': '59_pool',
  '60': '60_shooting',
  '61': '61_archery',
  '62': '62_water_sports',
  '63': '63_lacrosse',
  '64': '64_motorcycling',
  '65': '65_surfing',
  '66': '66_rally',
  '67': '67_nascar',
  '68': '68_canoeing',
  '69': '69_auto_racing',
  '70': '70_lottery',
  '71': '71_sky_racing',
  '72': '72_greyhound',
  '73': '73_horse_racing',
  '74': '74_sailing',
  '75': '75_billiard',
  '76': '76_fencing',
  '77': '77_pro_wrestling',
  '78': '78_oscar',
  '79': '79_grammy',
  '80': '80_basketball3x3',
  '81': '81_table_football',
  '82': '82_default',
  '83': '83_modern_pentathlon',
  '84': '84_triathlon',
  '85': '85_wrestling',
  '86': '86_beach_handball',
  '87': '87_hurling',
  '88': '88_army_games',
  '89': '89_bare_knuckle_boxing',
  '90': '90_kabaddi',
  '91': '91_indycar',
  '92': '92_kickboxing',
  '93': '93_sepak_takraw',
  '94': '94_e_football',
  '95': '95_e_basketball',
  '96': '96_e_icehockey',
  '97': '97_e_tennis',
  '98': '98_e_nascar',
  '99': '99_e_volleyball',
  '100': '100_odds_booster',
  '101': '101_klask',
  '102': '102_air_hockey',
  '103': '103_pesapallo',
  '104': '104_karate',
  '105': '105_figure_skating',
  '106': '106_skateboarding',
  '107': '107_climbing',
  '108': '108_speed_skating',
  '109': '109_short_track',
  '110': '110_freestyle_skiing',
  '111': '111_bobsleigh',
  '112': '112_nordic_combined',
  '113': '113_luge',
  '114': '114_skeleton',
  '115': '115_snowboard',
  '118': '118_virtual_sport',
}

class Icons {
  default = (elementClass = 'default') => {
    return (
      <Missing className={elementClass} />
    );
  };
  elements = {
    '1_football': (elementClass = 'default') => {
      return (
        <Id1football className={elementClass} />
      );
    },
    '2_water_polo': (elementClass = 'default') => {
      return (
        <Id2waterPolo className={elementClass} />
      );
    },
    '3_tennis': (elementClass = 'default') => {
      return (
        <Id3tennis className={elementClass} />
      );
    },
    '4_basketball': (elementClass = 'default') => {
      return (
        <Id4basketball className={elementClass} />
      );
    },
    '5_baseball': (elementClass = 'default') => {
      return (
        <Id5baseball className={elementClass} />
      );
    },
    '6_american_football': (elementClass = 'default') => {
      return (
        <Id6americanFootball className={elementClass} />
      );
    },
    '7_boxing': (elementClass = 'default') => {
      return (
        <Id7boxing className={elementClass} />
      );
    },
    '8_golf': (elementClass = 'default') => {
      return (
        <Id8golf className={elementClass} />
      );
    },
    '9_chess': (elementClass = 'default') => {
      return (
        <Id9chess className={elementClass} />
      );
    },
    '10_ice_hockey': (elementClass = 'default') => {
      return (
        <Id10iceHockey className={elementClass} />
      );
    },
    '11_bandy': (elementClass = 'default') => {
      return (
        <Id11bandy className={elementClass} />
      );
    },
    '12_volleyball': (elementClass = 'default') => {
      return (
        <Id12volleyball className={elementClass} />
      );
    },
    '13_handball': (elementClass = 'default') => {
      return (
        <Id13handball className={elementClass} />
      );
    },
    '14_rugby_union': (elementClass = 'default') => {
      return (
        <Id14rugbyUnion className={elementClass} />
      );
    },
    '15_biatlon': (elementClass = 'default') => {
      return (
        <Id15biatlon className={elementClass} />
      );
    },
    '16_what_where_when': (elementClass = 'default') => {
      return (
        <Id16whatWhereWhen className={elementClass} />
      );
    },
    '17_futsal': (elementClass = 'default') => {
      return (
        <Id17futsal className={elementClass} />
      );
    },
    '18_formula_1': (elementClass = 'default') => {
      return (
        <Id18formula1 className={elementClass} />
      );
    },
    '19_snooker': (elementClass = 'default') => {
      return (
        <Id19snooker className={elementClass} />
      );
    },
    '20_darts': (elementClass = 'default') => {
      return (
        <Id20darts className={elementClass} />
      );
    },
    '21_field_hockey': (elementClass = 'default') => {
      return (
        <Id21fieldHockey className={elementClass} />
      );
    },
    '22_beach_football': (elementClass = 'default') => {
      return (
        <Id22beachFootball className={elementClass} />
      );
    },
    '23_curling': (elementClass = 'default') => {
      return (
        <Id23curling className={elementClass} />
      );
    },
    '24_other': (elementClass = 'default') => {
      return (
        <Id24other className={elementClass} />
      );
    },
    '25_table_tennis': (elementClass = 'default') => {
      return (
        <Id25tableTennis className={elementClass} />
      );
    },
    '26_beach_volleyball': (elementClass = 'default') => {
      return (
        <Id26beachVolleyball className={elementClass} />
      );
    },
    '27_roller_hockey': (elementClass = 'default') => {
      return (
        <Id27rollerHockey className={elementClass} />
      );
    },
    '28_eurovision': (elementClass = 'default') => {
      return (
        <Id28eurovision className={elementClass} />
      );
    },
    '29_badminton': (elementClass = 'default') => {
      return (
        <Id29badminton className={elementClass} />
      );
    },
    '30_rugby_league': (elementClass = 'default') => {
      return (
        <Id30rugbyLeague className={elementClass} />
      );
    },
    '31_bowls': (elementClass = 'default') => {
      return (
        <Id31bowls className={elementClass} />
      );
    },
    '32_squash': (elementClass = 'default') => {
      return (
        <Id32squash className={elementClass} />
      );
    },
    '33_politics': (elementClass = 'default') => {
      return (
        <Id33politics className={elementClass} />
      );
    },
    '34_olimpic_games': (elementClass = 'default') => {
      return (
        <Id34olimpicGames className={elementClass} />
      );
    },
    '35_specials': (elementClass = 'default') => {
      return (
        <Id35specials className={elementClass} />
      );
    },
    '36_cricket': (elementClass = 'default') => {
      return (
        <Id36cricket className={elementClass} />
      );
    },
    '37_florball': (elementClass = 'default') => {
      return (
        <Id37florball className={elementClass} />
      );
    },
    '38_speadway': (elementClass = 'default') => {
      return (
        <Id38speadway className={elementClass} />
      );
    },
    '39_aussie_rules': (elementClass = 'default') => {
      return (
        <Id39aussieRules className={elementClass} />
      );
    },
    '40_padel_tennis': (elementClass = 'default') => {
      return (
        <Id40padelTennis className={elementClass} />
      );
    },
    '41_cycling': (elementClass = 'default') => {
      return (
        <Id41cycling className={elementClass} />
      );
    },
    '42_skiing': (elementClass = 'default') => {
      return (
        <Id42skiing className={elementClass} />
      );
    },
    '43_sky_jumping': (elementClass = 'default') => {
      return (
        <Id43skyJumping className={elementClass} />
      );
    },
    '44_yachting': (elementClass = 'default') => {
      return (
        <Id44yachting className={elementClass} />
      );
    },
    '45_mini_football': (elementClass = 'default') => {
      return (
        <Id45miniFootball className={elementClass} />
      );
    },
    '46_ufc': (elementClass = 'default') => {
      return (
        <Id46ufc className={elementClass} />
      );
    },
    '47_netball': (elementClass = 'default') => {
      return (
        <Id47netball className={elementClass} />
      );
    },
    '48_athletics': (elementClass = 'default') => {
      return (
        <Id48athletics className={elementClass} />
      );
    },
    '49_judo': (elementClass = 'default') => {
      return (
        <Id49judo className={elementClass} />
      );
    },
    '50_equestrian': (elementClass = 'default') => {
      return (
        <Id50equestrian className={elementClass} />
      );
    },
    '51_softball': (elementClass = 'default') => {
      return (
        <Id51softball className={elementClass} />
      );
    },
    '52_gaelic_football': (elementClass = 'default') => {
      return (
        <Id52gaelicFootball className={elementClass} />
      );
    },
    '53_esports': (elementClass = 'default') => {
      return (
        <Id53esports className={elementClass} />
      );
    },
    '54_weightlifting': (elementClass = 'default') => {
      return (
        <Id54weightlifting className={elementClass} />
      );
    },
    '55_poker': (elementClass = 'default') => {
      return (
        <Id55poker className={elementClass} />
      );
    },
    '56_sumo': (elementClass = 'default') => {
      return (
        <Id56sumo className={elementClass} />
      );
    },
    '57_gymnastics': (elementClass = 'default') => {
      return (
        <Id57gymnastics className={elementClass} />
      );
    },
    '58_taekwondo': (elementClass = 'default') => {
      return (
        <Id58taekwondo className={elementClass} />
      );
    },
    '59_pool': (elementClass = 'default') => {
      return (
        <Id59pool className={elementClass} />
      );
    },
    '60_shooting': (elementClass = 'default') => {
      return (
        <Id60shooting className={elementClass} />
      );
    },
    '61_archery': (elementClass = 'default') => {
      return (
        <Id61archery className={elementClass} />
      );
    },
    '62_water_sports': (elementClass = 'default') => {
      return (
        <Id62waterSports className={elementClass} />
      );
    },
    '63_lacrosse': (elementClass = 'default') => {
      return (
        <Id63lacrosse className={elementClass} />
      );
    },
    '64_motorcycling': (elementClass = 'default') => {
      return (
        <Id64motorcycling className={elementClass} />
      );
    },
    '65_surfing': (elementClass = 'default') => {
      return (
        <Id65surfing className={elementClass} />
      );
    },
    '66_rally': (elementClass = 'default') => {
      return (
        <Id66rally className={elementClass} />
      );
    },
    '67_nascar': (elementClass = 'default') => {
      return (
        <Id67nascar className={elementClass} />
      );
    },
    '68_canoeing': (elementClass = 'default') => {
      return (
        <Id68canoeing className={elementClass} />
      );
    },
    '69_auto_racing': (elementClass = 'default') => {
      return (
        <Id69autoRacing className={elementClass} />
      );
    },
    '70_lottery': (elementClass = 'default') => {
      return (
        <Id70lottery className={elementClass} />
      );
    },
    '71_sky_racing': (elementClass = 'default') => {
      return (
        <Id71skyRacing className={elementClass} />
      );
    },
    '72_greyhound': (elementClass = 'default') => {
      return (
        <Id72greyhound className={elementClass} />
      );
    },
    '73_horse_racing': (elementClass = 'default') => {
      return (
        <Id73horseRacing className={elementClass} />
      );
    },
    '74_sailing': (elementClass = 'default') => {
      return (
        <Id74sailing className={elementClass} />
      );
    },
    '75_billiard': (elementClass = 'default') => {
      return (
        <Id75billiard className={elementClass} />
      );
    },
    '76_fencing': (elementClass = 'default') => {
      return (
        <Id76fencing className={elementClass} />
      );
    },
    '77_pro_wrestling': (elementClass = 'default') => {
      return (
        <Id77proWrestling className={elementClass} />
      );
    },
    '78_oscar': (elementClass = 'default') => {
      return (
        <Id78oscar className={elementClass} />
      );
    },
    '79_grammy': (elementClass = 'default') => {
      return (
        <Id79grammy className={elementClass} />
      );
    },
    '80_basketball3x3': (elementClass = 'default') => {
      return (
        <Id80basketball3x3 className={elementClass} />
      );
    },
    '81_table_football': (elementClass = 'default') => {
      return (
        <Id81tableFootball className={elementClass} />
      );
    },
    '82_default': (elementClass = 'default') => {
      return (
        <Id82default className={elementClass} />
      );
    },
    '83_modern_pentathlon': (elementClass = 'default') => {
      return (
        <Id83modernPentathlon className={elementClass} />
      );
    },
    '84_triathlon': (elementClass = 'default') => {
      return (
        <Id84triathlon className={elementClass} />
      );
    },
    '85_wrestling': (elementClass = 'default') => {
      return (
        <Id85wrestling className={elementClass} />
      );
    },
    '86_beach_handball': (elementClass = 'default') => {
      return (
        <Id86beachHandball className={elementClass} />
      );
    },
    '87_hurling': (elementClass = 'default') => {
      return (
        <Id87hurling className={elementClass} />
      );
    },
    '88_army_games': (elementClass = 'default') => {
      return (
        <Id88armyGames className={elementClass} />
      );
    },
    '89_bare_knuckle_boxing': (elementClass = 'default') => {
      return (
        <Id89bareKnuckleBoxing className={elementClass} />
      );
    },
    '90_kabaddi': (elementClass = 'default') => {
      return (
        <Id90kabaddi className={elementClass} />
      );
    },
    '91_indycar': (elementClass = 'default') => {
      return (
        <Id91indycar className={elementClass} />
      );
    },
    '92_kickboxing': (elementClass = 'default') => {
      return (
        <Id92kickboxing className={elementClass} />
      );
    },
    '93_sepak_takraw': (elementClass = 'default') => {
      return (
        <Id93sepakTakraw className={elementClass} />
      );
    },
    '94_e_football': (elementClass = 'default') => {
      return (
        <Id94efootball className={elementClass} />
      );
    },
    '95_e_basketball': (elementClass = 'default') => {
      return (
        <Id95ebasketball className={elementClass} />
      );
    },
    '96_e_icehockey': (elementClass = 'default') => {
      return (
        <Id96eicehockey className={elementClass} />
      );
    },
    '97_e_tennis': (elementClass = 'default') => {
      return (
        <Id97etennis className={elementClass} />
      );
    },
    '98_e_nascar': (elementClass = 'default') => {
      return (
        <Id98enascar className={elementClass} />
      );
    },
    '99_e_volleyball': (elementClass = 'default') => {
      return (
        <Id99evolleyball className={elementClass} />
      );
    },
    '100_odds_booster': (elementClass = 'default') => {
      return (
        <Id100oddsbooster className={elementClass} />
      );
    },
    '101_klask': (elementClass = 'default') => {
      return (
        <Id101klask className={elementClass} />
      );
    },
    '102_air_hockey': (elementClass = 'default') => {
      return (
        <Id102airhockey className={elementClass} />
      );
    },
    '103_pesapallo': (elementClass = 'default') => {
      return (
        <Id103pesapallo className={elementClass} />
      );
    },
    '104_karate': (elementClass = 'default') => {
      return (
        <Id104karate className={elementClass} />
      );
    },
    '105_figure_skating': (elementClass = 'default') => {
      return (
        <Id105figureSkating className={elementClass} />
      );
    },
    '106_skateboarding': (elementClass = 'default') => {
      return (
        <Id106skateboarding className={elementClass} />
      );
    },
    '107_climbing': (elementClass = 'default') => {
      return (
        <Id107climbing className={elementClass} />
      );
    },
    '108_speed_skating': (elementClass = 'default') => {
      return (
        <Id108speedSkating className={elementClass} />
      );
    },
    '109_short_track': (elementClass = 'default') => {
      return (
        <Id109shortTrack className={elementClass} />
      );
    },
    '110_freestyle_skiing': (elementClass = 'default') => {
      return (
        <Id110freestyleSkiing className={elementClass} />
      );
    },
    '111_bobsleigh': (elementClass = 'default') => {
      return (
        <Id111bobsleigh className={elementClass} />
      );
    },
    '112_nordic_combined': (elementClass = 'default') => {
      return (
        <Id112nordicCombined className={elementClass} />
      );
    },
    '113_luge': (elementClass = 'default') => {
      return (
        <Id113luge className={elementClass} />
      );
    },
    '114_skeleton': (elementClass = 'default') => {
      return (
        <Id114skeleton className={elementClass} />
      );
    },
    '115_snowboard': (elementClass = 'default') => {
      return (
        <Id115snowboard className={elementClass} />
      );
    },
    '118_virtual_sport': (elementClass = 'default') => {
      return (
        <Id118virtualSport className={elementClass} />
      );
    },
  };

  live = assoc;
  preMatch = assoc;
  custom = assoc;

  get(name = 'default', elementClassName = 'default', type) {
    if (!type) {
      return this.default(elementClassName);
    } else if (!this[type].hasOwnProperty(name)) {
      //console.log("no name", name, elementClassName, type);
      return this.default(elementClassName);
    }
    return this.elements[this[type][name]](elementClassName);
  }
}

export default new Icons();
