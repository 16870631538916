import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

class EmptyList extends Component {
    render() {
        return (
            <div className={"transactionsList emptyList"}>
                <p>{this.props.t("no results found")}</p>
            </div>
        );
    }
}

export default withTranslation()(EmptyList);