import React from "react";

import WinSpecialPrize from "./win-panel-special-prize";
import WinRegularPrize from "./win-panel-regular-prize";

const WinPanel = props => {
  const { data, prizeWon } = props;

  if (!prizeWon) return null;
  if (!data) return null;

  if (prizeWon.event_type !== "online-slots") return null;
  if (data.event_type !== "online-slots") return null;

  if (!prizeWon.amount && typeof prizeWon.custom !== "undefined") {
    return <WinSpecialPrize data={data} prizeWon={prizeWon} />;
  }

  return <WinRegularPrize data={data} prizeWon={prizeWon} />;
};

export default WinPanel;
