import React, { useEffect } from "react";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import detectIt from "detect-it";

const eventListenerOptions = detectIt.passiveEvents ? { passive: true } : false;

const SwipeProvider = props => {
	const {
		onSwipe,
		children,
		disabled,
		threshold,
		restraint,
		allowedTime,
		dirs,
		swipes,
		phases
	} = props;

	let targetNode = null;
	let prevTargetNode = null;

	let dir, startX, startY, distX, distY, startTime;

	let mouseState = {
		clicked: false,
		started: false,
		clientX: 0,
	};

	const handleEvent = ev => {
		if (dirs != null && dirs.length && !dirs.find(d => d === ev.dir)) {
			return;
		}

		if (phases != null && phases.length && !phases.find(p => p === ev.phase)) {
			return;
		}

		if (swipes != null && swipes.length && !swipes.find(s => s === ev.swipe)) {
			return;
		}

		onSwipe(ev);
	};

	const handleNode = target => {
		if (children !== null && target !== null) {
			/**
			 * Forward hijacked ref to user.
			 */
			const nodeRef = children.ref;

			if (nodeRef) {
				if (typeof nodeRef === "function") {
					nodeRef(target);
				} else if (typeof nodeRef === "object") {
					nodeRef.current = target;
				}
			}
		}

		targetNode = target && findDOMNode(target);
	};

	// event handlers
	const touchstart = e => {
		e.target.addEventListener("touchmove", touchmove, eventListenerOptions);
		e.target.addEventListener("touchend", touchend, eventListenerOptions);

		let touchobj = e.changedTouches[0];
		dir = "none";
		startX = touchobj.pageX;
		startY = touchobj.pageY;
		distX = 0;
		distY = 0;
		startTime = new Date().getTime(); // record time when finger first makes contact with surface
		handleEvent({
			element: e,
			dir: "none",
			phase: "start",
			swipe: "",
			startX: startX,
			startY: startY,
			distX,
			distY
		});
	};

	const touchmove = e => {
		let touchobj = e.changedTouches[0];
		distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
		distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
		if (Math.abs(distX) > Math.abs(distY)) {
			// if distance traveled horizontally is greater than vertically, consider this a horizontal movement
			dir = distX < 0 ? "left" : "right";
			handleEvent({
				element: e,
				dir,
				phase: "move",
				swipe: "",
				startX,
				startY,
				distX,
				distY
			});
		} else {
			// else consider this a vertical movement
			dir = distY < 0 ? "up" : "down";
			handleEvent({
				element: e,
				dir,
				phase: "move",
				swipe: "",
				startX,
				startY,
				distX,
				distY
			});
		}
	};

	const touchend = e => {
		e.target.removeEventListener("touchstart", touchstart, eventListenerOptions);
		e.target.removeEventListener("touchmove", touchmove, eventListenerOptions);
		e.target.removeEventListener("touchend", touchend, eventListenerOptions);

		let swipe = "";
		const elapsedTime = new Date().getTime() - startTime; // get time elapsed
		if (elapsedTime <= allowedTime) {
			// first condition for awipe met
			if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
				// 2nd condition for horizontal swipe met
				swipe = dir; // set swipe to either "left" or "right"
			} else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) {
				// 2nd condition for vertical swipe met
				swipe = dir; // set swipe to either "top" or "down"
			}
		}
		handleEvent({
			element: e,
			dir,
			phase: "end",
			swipe,
			startX,
			startY,
			distX,
			distY
		});
	};

	const mousedown = e => {
		mouseState = {
			clicked: true,
			started: false,
			clientX: 0,
		};
		e.preventDefault();
		e.stopPropagation();

		window.addEventListener("mousemove", mousemove);
		window.addEventListener("mouseup", mouseup);
	};

	const mousemove = e => {
		if (mouseState.clicked) {
			if (!mouseState.started) {
				if (Math.abs(e.clientX - mouseState.clientX) > 10) {
					mouseState.started = true;
					mouseState.clientX = e.clientX;
				}
			} else {
				//console.log("mousemove", mouseState, e.clientX - mouseState.clientX);
				handleEvent({
					element: e,
					dir: "none",
					phase: "drag",
					swipe: "",
					startX: e.clientX,
					startY: 0,
					distX: e.clientX - mouseState.clientX,
					distY: 0
				});
				mouseState.clientX = e.clientX;
			}
		}
	};

	const mouseup = e => {
		if (mouseState.clicked) {
			mouseState = {
				clicked: false,
				started: false,
				clientX: 0,
			};
			handleEvent({
				element: e,
				dir: "none",
				phase: "endDrag",
				swipe: "",
				startX: 0,
				startY: 0,
				distX: 0,
				distY: 0
			});
		}
		window.removeEventListener("mousemove", mousemove);
		window.removeEventListener("mouseup", mouseup);
	};

	const install = () => {
		if (children == null || disabled) {
			return false;
		}
		if (!targetNode) {
			throw new Error(
				"SwipeProvider: Can't find DOM node in the provided children. Make sure to render at least one DOM node in the tree."
			);
		}

		targetNode.addEventListener("touchstart", touchstart, eventListenerOptions);
		targetNode.addEventListener("mousedown", mousedown, { passive: false });
		return true;
	};

	const uninstall = target => {
		target.removeEventListener("touchstart", touchstart, eventListenerOptions);
		target.removeEventListener("mousedown", mousedown);
	};

	useEffect(() => {
		if (targetNode !== null) {
			install();
		}

		return () => {
			// check we have a previous node to uninstall
			if (prevTargetNode !== null && prevTargetNode !== targetNode) {
				uninstall(prevTargetNode);
			}
		};
	}, []); // eslint-disable-line

	useEffect(() => {
		return () => {
			if (prevTargetNode !== null) {
				uninstall(prevTargetNode);
			}
		};
	}, []); // eslint-disable-line

	return children !== null
		? React.cloneElement(React.Children.only(children), {
			ref: handleNode
		})
		: null;
};

SwipeProvider.propTypes = {
	// need a target element
	children: PropTypes.element.isRequired,

	// received events or not
	disabled: PropTypes.bool,

	// need a function that will be invoked whenever a selected touch event is fired
	onSwipe: PropTypes.func.isRequired,

	//required min distance traveled to be considered swipe
	threshold: PropTypes.number,

	// maximum distance allowed at the same time in perpendicular direction
	restraint: PropTypes.number,

	// maximum time allowed to travel that distance
	allowedTime: PropTypes.number,

	// movement directions to report
	dirs: PropTypes.array,

	// swipes to report
	swipes: PropTypes.array,

	// phases to report
	phases: PropTypes.array
};

SwipeProvider.defaultProps = {
	disabled: false,
	threshold: 30,
	restraint: 100,
	allowedTime: 1000,
	dirs: ["none", "left", "right"],
	swipes: null,
	phases: ["start", "move", "end", "drag", "endDrag"],
	onSwipe: () => { }
};

export default SwipeProvider;
