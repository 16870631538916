import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
    position: "relative",
    left:"5px",
    transform: "translate3d(0,0,0)",
  },
  text: {
    fontWeight: "bold",
    fontSize: "12px",
    fontFamily: "OpenSans",
    fill:"#FB1A6E"
  }
});

const LiveIcon = props => {
	const classes = useStyles();
  const { number } = props;

  return (
    <svg className={classes.root} key={`live-icon-${number}`} x="0px" y="0px" width="44.812px" height="29.872px" viewBox="-4.812 0 44.812 29.872">
      <g>
      <path fill="#16214C" d="M14.534,29.861c-0.877,0-1.754,0-2.633,0c-2.687-0.002-10.222-0.006-12.909-0.006
		c-1.731,0-3.222-1.138-3.666-2.797c-0.088-0.332-0.133-0.668-0.133-1.012c0-3.503-0.013-9.88,0.006-13.382
		c0.008-1.499,0.702-2.633,2.027-3.367C-2.232,9-1.639,8.876-1.02,8.876c7.148,0,19.145,0,26.295,0c0.884,0,1.595,0.615,1.695,1.481
		c0.022,0.214-0.013,0.438-0.041,0.656c-0.121,0.988,0.506,1.859,1.404,2.048c0.23,0.048,0.46,0.048,0.689,0.001
		c1.066-0.219,2.037,0.563,2.037,1.643c0,2.824,0.022,8.525-0.009,11.35c-0.019,1.613-0.807,2.783-2.274,3.502
		c-0.488,0.237-1.006,0.266-1.527,0.279c-0.545,0.014-1.09,0.021-1.637,0.023c-1.752,0.006-3.506,0.009-5.257,0.013
		c-0.702,0.001-1.405-0.004-2.106-0.004c-1.239-0.001-2.477-0.001-3.716-0.001C14.534,29.866,14.534,29.863,14.534,29.861z
		 M24.98,10.814c-0.047,0-0.086,0-0.127,0c-1.373,0-2.746,0-4.119,0c-5.628,0-16.105,0-21.734,0c-0.253,0-0.501,0.032-0.733,0.139
		c-0.734,0.34-1.113,0.927-1.116,1.721C-2.858,16.156-2.853,22.518-2.851,26c0,0.141,0.012,0.279,0.037,0.416
		c0.149,0.868,0.912,1.501,1.798,1.502c2.373,0.002,9.596,0.003,11.97,0.005c1.636,0.002,3.273,0.005,4.909,0.006
		c1.339,0.003,2.678,0.007,4.017,0.007c1.946-0.002,3.894-0.008,5.841-0.014c0.536-0.002,1.071-0.011,1.607-0.025
		c0.213-0.009,0.427-0.006,0.632-0.104c0.74-0.354,1.138-0.939,1.142-1.742c0.015-2.686,0.004-8.246,0.004-10.931
		c0-0.034,0-0.065,0-0.104c-1.209,0.104-2.246-0.25-3.078-1.108C25.195,13.051,24.877,12.008,24.98,10.814z"/>
        <path className="live-icon-big" fill="#FB1A6E" d="M29.688,2.319c-2.226,0.041-4.136,0.819-5.709,2.389c-0.145,0.143-0.273,0.301-0.41,0.449
		c-0.316,0.342-0.705,0.472-1.156,0.348c-0.452-0.125-0.735-0.432-0.818-0.893c-0.059-0.333,0.02-0.644,0.245-0.906
		c1.502-1.751,3.386-2.883,5.652-3.388c0.632-0.141,1.274-0.219,1.924-0.23c2.812-0.044,5.253,0.877,7.303,2.78
		c1.568,1.455,2.552,3.249,2.991,5.332c0.125,0.585,0.191,1.176,0.201,1.773c0.042,2.775-0.896,5.177-2.795,7.212
		c-0.198,0.213-0.411,0.412-0.627,0.605c-0.342,0.307-0.737,0.389-1.168,0.229c-0.43-0.158-0.674-0.481-0.728-0.927
		c-0.046-0.382,0.091-0.71,0.382-0.967c0.727-0.637,1.313-1.381,1.768-2.23c0.41-0.776,0.684-1.596,0.82-2.462
		c0.1-0.623,0.125-1.249,0.068-1.875c-0.171-1.837-0.884-3.439-2.166-4.781c-1.122-1.174-2.488-1.927-4.078-2.276
		C30.828,2.379,30.264,2.316,29.688,2.319z"/>
        <path className="live-icon-medium" fill="#FB1A6E" d="M35.37,10.052c-0.015,1.382-0.366,2.485-1.08,3.47c-0.276,0.383-0.746,0.556-1.192,0.446
		c-0.455-0.111-0.796-0.475-0.856-0.941c-0.039-0.288,0.024-0.555,0.2-0.794c0.32-0.439,0.533-0.926,0.617-1.463
		c0.142-0.926-0.063-1.779-0.621-2.536c-0.554-0.75-1.304-1.207-2.229-1.354c-0.903-0.144-1.742,0.058-2.496,0.574
		c-0.688,0.473-1.624,0.101-1.771-0.716c-0.078-0.439,0.063-0.818,0.429-1.084c0.688-0.501,1.456-0.817,2.294-0.97
		c0.496-0.09,0.995-0.12,1.498-0.074c1.302,0.121,2.441,0.61,3.4,1.497c0.905,0.838,1.475,1.866,1.703,3.07
		C35.332,9.521,35.352,9.873,35.37,10.052z"/>
        <path className="live-icon-small" fill="#FB1A6E" d="M30.805,10.229c-0.002,0.625-0.506,1.125-1.138,1.119c-0.662-0.004-1.135-0.553-1.122-1.144
		c0.012-0.529,0.426-1.092,1.133-1.098C30.307,9.103,30.805,9.609,30.805,10.229z"/>
      </g>
      <g transform="translate(-164 -437)">
        <text transform="matrix(1 0 0 1 176.1885 460.7979)" textAnchor="middle" className={classes.text}>{number}</text>
      </g>
    </svg>
	);
};

LiveIcon.propTypes = {
	number: PropTypes.number,
};

LiveIcon.defaultProps = {
	number: 0
};

export default LiveIcon;
