import React, { Component } from 'react';
import RewardBox from "../Form/Reward/RewardBox";

class ClaimBonus extends Component
{

    render() {
        return (

                <RewardBox/>
        );
    }
}



export default (ClaimBonus);