import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Icons from "./../../../../../../assets/Icons/Icons";
import * as stateActions from "../../../../../../store/actions";
import "./Transactions.scss";
import { NO_SMALL_FORMAT } from "../../../Wallet/Parts/ValueCurrency";
import ValueCurrency from "../../../Wallet/Parts/ValueCurrency";
import Moment from 'react-moment';
import Menus from "./../../../../../Utils/Menus/Menus";
Moment.globalFormat = 'D MMM';

const HISTORY_LIMIT = 5;

class Transactions extends Component {
    getStatusText(status) {
        let text = "";

        switch (status) {
            case stateActions.withdrawalStates.CANCELLED:
                text = "cancelled";
                break;
            case stateActions.withdrawalStates.PROCESSED:
                text = "success";
                break;
            case stateActions.withdrawalStates.REJECTED:
                text = "failed";
                break;
        }

        return text;
    }

    getList() {
        if (!this.props.withdrawals.historyRequests || !(this.props.withdrawals.historyRequests.length > 0)) {
            return (
                <li>
                    <div className={"notAvailable"}>
                        <p>{this.props.t("List is empty for the moment.")}</p>
                    </div>
                </li>
            );
        }

        let list = [];
        let locations = this.props.withdrawals.locations;

        this.props.withdrawals.historyRequests.some((withdraw, index) => {
            let location = locations.find((loc) => {
                if (!loc) {
                    return false;
                }
                return loc.id === withdraw.location_id;
            });

            if (!location) {
                return false;
            }

            let statusText = this.getStatusText(withdraw.status_id);
            let amount = withdraw.amount;
            if (!(amount > 0)) {
                return false;
            }

            let item = (
                <li key={`${index}-history-${location.id}`}>
                    <div className={`historyElement type_${statusText}`}>
                        <div className={"elemState"}>{this.props.t(statusText)}</div>
                        <div className={"elemDetails"}>
                            <div className={"row1"}>
                                {location.formattedAddress} | <ValueCurrency value={amount} currency={this.props.wallet.currency} type={NO_SMALL_FORMAT} />
                            </div>
                            <div className={"row2"}>
                                <Moment unix>{withdraw.date}</Moment><span>|</span><Menus parent={"simple"} pageType={"profile"} pageCode={"transactions"} buttonText={"View"} />
                            </div>
                        </div>
                    </div>
                </li>
            );

            list.push(item);

            if (list.length >= HISTORY_LIMIT) {
                return true;
            }

            return false;
        });

        return list;
    }

    render() {
        let transactionsIcon = Icons.get("pwTransactions", "gray-icon-common");

        return (
            <li className={"transactions-support"}>
                <div className={"transactions-section wsection"}>
                    <div className={"head-area"}>
                        <div className={"icon"}>{transactionsIcon}</div>
                        <div className={"texts-support"}>
                            <div className={"title"}>{this.props.t("Recent transactions")}</div>
                            <div className={"subtitle"}>{this.props.t("Withdrawals history")}</div>
                        </div>
                    </div>
                    <div className={"list-area"}>
                        <ul className={"wlist history"}>
                            {this.getList()}
                        </ul>
                    </div>
                    <div className={"history-link"}>
                        <Menus parent={"simple"} pageType={"profile"} pageCode={"transactions"} buttonText={"See all transactions"} />
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // cancelAmount: (id, amount) => dispatch(stateActions.requestCancelWithdrawal(id, amount)),
        openModal: (element) => dispatch(stateActions.cancelWithdrawalModal(true, element)),
        openPartialModal: (element) => dispatch(stateActions.partialWithdrawalModal(true, element)),
    };
};


export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Transactions)));