import React from 'react';

import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import HeaderIcon from "../../assets/dialog-icon.svg";
import "./lucky-number-dialog.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LuckyNumberDialog = props => {
  const {
    open,
    onClose,
    bonusInfo,
    t
  } = props;

  const handleClose = () => {
    typeof onClose === "function" && onClose(false);
  }

  const translation = {
    FREE_BET_NAME: bonusInfo && bonusInfo["free_bet_title"] ? bonusInfo["free_bet_title"] : "",
    FREE_BET_COUNT: bonusInfo && bonusInfo["free_bet_title"] ? bonusInfo["free_bet_token_count"] : "",
    FREE_BET_VALUE: bonusInfo && bonusInfo["free_bet_title"] ? bonusInfo["free_bet_token_value"] / 100 : "",
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`terms-dialog`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{t("Lucky Number")}</div>
          <div className="dh-text muted">{t(`How it works?`)}</div>
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>

          <div className="dg-row">
            <div className="icon"></div>
            <div className="txt">
              {t("Any of the numbers played can be Lucky Number. Choose your lucky number.")}
            </div>
          </div>
          <div className="dg-row">
            <div className="icon"></div>
            <div className="txt">{t("If you lose the ticket, but your lucky number is extra, you win a {{FREE_BET_NAME}} of {{FREE_BET_COUNT}} x {{FREE_BET_VALUE}} Leu value.", translation)}</div>
          </div>
          <div className="dg-row">
            <div className="icon"></div>
            <div className="txt">{t("Good luck!")}</div>
          </div>

          <a className="db-link" href={`/cazino/promotii`} target="_blank" rel="noopener noreferrer">{t("TERMS AND CONDITIONS")}</a>
          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={handleClose}>
              {t("I understand")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

export default withTranslation()(LuckyNumberDialog);



