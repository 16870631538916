import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import * as actionTypes from './../../../store/actions/actionTypes';
import * as stateActions from './../../../store/actions/';
import './Username.css';
// import Icons from './../../../assets/Icons/Icons';
// import * as EmailValidator from 'email-validator';
// import PhoneNumberValidator from './../../../utils/PhoneNumberValidator'; 
import ColumnMessage from './../ColumnMessage/ColumnMessage';
import ButtonNext from './../ButtonNext/ButtonNext';
import InputField from './../InputField/InputField';
import * as MethodTypes from './../MethodTypes';
import { withTranslation } from "react-i18next";

class Username extends Component {
    constructor(props) {
        super(props);

        this.defaultGroupClassName = "input-group";
    }

    state = {
        groupClassName: `${this.defaultGroupClassName} notValid`,
        username: "",
        password: "",
        message: {
            type: "",
            text: ""
        },
    };

    componentDidUpdate() {
        if ("password" === this.props.authenticationModal.step) {
            this.props.goToStep(2);
            return;
        }
    }

    usernameHandler(event) {
        let username;
        if (null === event) {
            username = this.props.authenticationModal.username;
        } else {
            username = event.target.value;
        }

        let groupClassName = undefined;
        let valid = stateActions.validateUsername(username);

        let authUsername = null;
        let authMethod = null;

        switch (valid) {
            case MethodTypes.IS_NOT:
                groupClassName = `${this.defaultGroupClassName} error`;
                break;

            case MethodTypes.IS_EMAIL:
                groupClassName = `${this.defaultGroupClassName} valid`;
                authUsername = username;
                authMethod = 'EMAIL_ADDRESS';
                break;

            case MethodTypes.IS_PHONE:
                groupClassName = `${this.defaultGroupClassName} valid`;
                authUsername = username;
                authMethod = 'PHONE_NUMBER';
                break;

            case MethodTypes.IS_UNDEFINED:
            default:
                groupClassName = `${this.defaultGroupClassName} notValid`;
                break;
        }

        this.props.onUsername(authUsername, authMethod);

        this.setState({
            ...this.state,
            groupClassName: groupClassName,
            username: username,
            message: {
                type: "",
                text: ""
            }
        });
    }

    reset() {
        this.setState({
            groupClassName: `${this.defaultGroupClassName} notValid`,
            username: "",
            password: "",
            message: {
                type: "",
                text: ""
            }
        });
        this.props.onReset();
    }

    componentWillReceiveProps() {
        if (true === this.props.authenticationModal.reset) {
            this.reset();
            this.props.firstStep();
            return;
        }
    }

    keyPressedHandler(event) {
        if (13 === event.charCode) {
            // this.usernameHandler(null);
            this.usernameHandler(event);
            this.props.onEnterKey('username');
        }
    }

    registerHandler() {
        this.props.onOpenRegister();
    }

    render() {
        let inputValue = this.state.username;
        let groupClassName = this.state.groupClassName;
        if ("" === inputValue && null !== this.props.authenticationModal.username) {
            inputValue = this.props.authenticationModal.username;
            groupClassName = `${this.defaultGroupClassName} valid`;
        }

        let messageObject = this.state.message;
        if ("" !== this.props.authenticationModal.errorMessage) {
            messageObject.type = "error";
            messageObject.text = this.props.authenticationModal.errorMessage;
        } else {
            messageObject.type = "";
            messageObject.text = "";
        }
        return (
            <div className="UsernameField">
                <div className="space-top"> </div>
                <div className="input-centered">
                    <InputField iconName="id-check"
                        iconClassName="login-check"
                        groupClassName={groupClassName}
                        inputType="text"
                        inputIdName="lf_username"
                        inputValue={inputValue}
                        inputLabel={this.props.t("E-mail or Phone")}
                        changeHandler={this.usernameHandler.bind(this)}
                        keyPressedHandler={this.keyPressedHandler.bind(this)} />
                </div>
                <div className="messages row">
                    <ColumnMessage msg={messageObject} />
                    <div className="col text-right">
                        <Link to="/register" className="login_register_link" onClick={this.registerHandler.bind(this)}>
                            <span>{this.props.t("Create Account")}</span>
                        </Link>
                    </div>
                </div>
                <ButtonNext parentType="username" />
                <div className="space-bottom"> </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUsername: (username = null, method = null) => dispatch(stateActions.changeAuthUsername(username, method)),
        onReset: () => dispatch(stateActions.reseted()),
        onEnterKey: (parentType) => dispatch(stateActions.authButtonPressed(parentType)),
        onOpenRegister: () => {
            dispatch(stateActions.resetModal());
            dispatch(stateActions.changePage('register'));
        },
    };
};

const mapStateToProps = state => {
    return {
        authenticationModal: state.authModal
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Username));