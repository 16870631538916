import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import * as stateActions from "../../../../store/actions";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import TotalWallet from "./Parts/TotalWallet";
import MoneyWidget from "./Parts/MoneyWidget";
import Bonus from "./Parts/Bonus";
import FreeBet from "./Parts/FreeBet/FreeBet";
import Icons from '../../../../assets/Icons/Icons';
import "./Wallet.scss";
import RedeemCode from "../../RedeemCode/RedeemCode";
import FreeSpin from "./Parts/FreeSpin/FreeSpin";
import Prize from "./Parts/Prize/Prize";
import {loadPrizes} from "../../../../tournaments-missions/store/actions/history";

class Wallet extends Component {

    componentDidMount() {
        this.props.fetchFreeSpins();
        this.props.fetchPrize();
    }

    depositHandler(event) {
        event.preventDefault();
        event.stopPropagation()

        if (!this.props.application.menu.hasOwnProperty("deposit")) {
            return false;
        }

        let depositPageRow = this.props.application.menu["deposit"];
        let pageUrl = `/${depositPageRow.code}`;
        this.props.history.push(pageUrl);

        this.props.onPageChanged(depositPageRow);

        return false;
    }

    withdrawHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        // open withdrawals popup
        this.props.openWithdrawalModal();

        return false;
    }

    render() {
        let withdrawDangerIcon = (!this.props.profile.accountVerified) ? Icons.get("exWarning", "withdraw-alert-icon") : null;
        return (
            <div>
                <div id="wallet">
                    <TotalWallet />
                    <div className="MainWallet">
                        <MoneyWidget type={"real"} value={this.props.main + (this.props.ringFence ? this.props.ringFence : 0)} currency={this.props.currency} key={"real"}/>
                        <MoneyWidget type={"bonus"} value={this.props.bonus} currency={this.props.currency} key={"bonus"}/>
                    </div>
                    <div className="WalletButtons">
                        <a href="/deposit" className="btn" onClick={this.withdrawHandler.bind(this)}>{withdrawDangerIcon}{this.props.t("Withdraw")}</a>
                        <a href={"/deposit"} className="btn outer" onClick={this.depositHandler.bind(this)}>{this.props.t("Deposit")}</a>
                    </div>
                    <div className="clearfix"> </div>
                </div>
                <RedeemCode/>
                <Bonus/>
                <Prize/>
                <FreeBet />
                <FreeSpin/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
        openWithdrawalModal: () => dispatch(stateActions.openWithdrawalModal()),
        fetchFreeSpins: () => dispatch(stateActions.freeSpinsFetch()),
        fetchPrize: () => dispatch(loadPrizes(()=> {}))
    };
};

const mapStateToProps = state => {

    return {
        main: state.wallet.main,
        ringFence: state.wallet.ringFence,
        bonus: state.wallet.bonus,
        currency: state.wallet.currency,
        profile: state.profile,
        application: state.application
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Wallet)));