import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../Promotions.scss';
import './Promotion.scss';
import { connect } from "react-redux";
import DinamicImage from "../../../../Utils/DinamicImage/DinamicImage";
import { withRouter } from "react-router";
import DOMPurify from 'dompurify';
import BackendClient from "../../../../../BackendClient";
import Utils from "../../../../../utils/Utils";
import PlayerPromoTickets from "../Promotion/Details/PlayerPromoTickets";
import { doLogin } from "../../../../../store/actions/authentication";
import EvBusMain from "../../../../../utils/evbus";
class Promotion extends Component {

	state = {
		promotion: false,
		id: false
	};

	constructor(props) {
		super(props);
		const pathname = window.location.pathname.split('/');
		this.state.id = typeof pathname[3] !== "undefined" ? pathname[3] : false;
	}

	decodeHTML(encodedString) {
		try {
			let textArea = document.createElement('textarea');
			textArea.innerHTML = encodedString;
			return DOMPurify.sanitize(textArea.value);
		} catch (e) {
			console.warn(e);
		}
		return " ";
	}

	setPromotion(promo) {
		if (typeof promo !== "undefined") {
			this.setState({
				...this.state,
				promotion: promo
			});
		}
	}

	getPromotions() {
		const axios = BackendClient.getInstance();
		axios({
			url: '/api/casino/promotions',
		}).catch(e => {
			console.log(`[CATCHED ERROR] => ${e}`);
		});
	}

	componentDidMount() {

		window.scrollTo({ top: 0, behavior: 'smooth' });
		if (typeof this.props.dummy === "undefined") {
			if (this.props.promotion) {
				this.setPromotion(this.props.promotion);
			} else if (!this.props.promotions.length && this.state.id) {
				Utils.delayedFunction("getPromotions", this.getPromotions.bind(this), 750);
			} else {
				let promotionFound = this.props.promotions.find(item => item.id == this.state.id);
				this.setPromotion(promotionFound);
			}
		} else {
			if (!this.props.promotions.length) {
				Utils.delayedFunction("getPromotions", this.getPromotions.bind(this), 750);
			}
		}
		this.props.history.listen((location) => {
			const pathname = location.pathname.split('/');
			let id = typeof pathname[3] !== "undefined" ? pathname[3] : false;
			this.setState({
				...this.state,
				id: id
			});
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.promotions !== this.props.promotions && this.state.id) {
			let promotionFound = this.props.promotions.find(item => item.id == this.state.id);
			this.setPromotion(promotionFound);
		}
	}

	promoUrl() {
		let permalink = this.state.promotion.permalink ? this.state.promotion.permalink : encodeURI(this.state.promotion.title.replace(/ /g, '-'));

		let url = `/cazino/promotii/${this.state.promotion.id}/${permalink}`;
		if (window.config.lobbyEnabled === "1") {
			if (window.config.rootPaths && window.config.rootPaths.casino) {
				url = `/${window.config.rootPaths.casino}/promotii/${this.state.promotion.id}/${permalink}`;
			}
		}

		return url;
	}

	handleGetBonus() {
		if (["user", "token"].indexOf(this.props.authentication.auth_type) === -1 || this.props.authentication.access_token === null) {
			if (window.config.platformType !== 1) {
				if (this.state.promotion.call_to_action_url) {
					if (this.state.promotion.call_to_action_url[0] === '/') {
						localStorage.setItem("redirect_after_login", this.state.promotion.call_to_action_url);
					}
				}
				this.props.history.push("/connect");
			} else {
				// desktop - show modal
				this.props.doLogin();
			}
			return;
		}

		if (this.state.promotion.call_to_action_url && this.state.promotion.call_to_action_url === "/wheel") {
			EvBusMain.emit("OPEN-WHEEL-DIALOG");
			return;
		}

		if (this.state.promotion.call_to_action_url) {
			if (this.state.promotion.call_to_action_url[0] === '/') {
				this.props.history.push(this.state.promotion.call_to_action_url);
			} else {
				window.open(this.state.promotion.call_to_action_url, "_blank");
			}
		} else {

			this.props.history.push("/deposit");
		}
	}

	render() {

		let content = <div className={`wrapper dummy ${this.props.className ? this.props.className : ""}`}
			key={'dummy' + Math.random()}>
			<div className="wrapper-cell">
				<div className="image"> </div>
				<div className={"text-line"}> </div>
			</div>
		</div>;

		if (this.state.promotion) {
			content = <article className={`${!this.state.id ? 'promotion-card' : "promotion-detail"} ${this.props.featured ? this.props.featured : ""}`}
				key={"promotion" + this.state.promotion.id}>
				<div className="content">
					<div className="top" onClick={() => this.props.history.push(this.promoUrl())}>
						<DinamicImage imgLink={!this.state.id ? this.state.promotion.path_to_thumbnail : this.state.promotion.path_to_banner} title={this.decodeHTML(this.state.promotion.title)} placeholder />
					</div>
					{/*{this.state.promotion.availability && <Countdown date={this.state.promotion.availability} type={TYPE_COMPACT_LAST_CHANCE} expiredMessage={this.props.t("Unavailable")}/>}*/}
					<div className="bottom">
						{!this.state.id && <div className="label">{this.decodeHTML(this.state.promotion.title_category)} </div>}
						<h2 onClick={() => this.props.history.push(this.promoUrl())}>
							{this.decodeHTML(this.state.promotion.title)}
						</h2>

						{this.props.featured && <div className={"short-description"}
							dangerouslySetInnerHTML={{ __html: this.decodeHTML(this.state.promotion.short_description) }}></div>}
						{this.state.id && <div className={"description"}
							dangerouslySetInnerHTML={{ __html: this.decodeHTML(this.state.promotion.long_description) }}></div>}
						<div className={"get-bonus"} onClick={this.handleGetBonus.bind(this)}>{this.state.promotion.call_to_action_text ? this.state.promotion.call_to_action_text : this.props.t('GET BONUS')}</div>
					</div>
				</div>
			</article>;
		}
		if (!this.state.id) {
			return content;
		}

		return (
			<div className={'PromotionsScreenContent'}>
				{content}
			</div>
		);
	}
};
const mapStateToProps = state => {
	return {
		language: state.application.language,
		promotions: state.application.promotions,
		player: state.profile.client_player_id,
		authentication: state.authentication,
	}
};

const mapDispatchToProps = {
	doLogin
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Promotion)));
