class GoogleAds {

    static isActive() {
        return !!window.config.google_ads_id && typeof window.gtag === 'function';
    }

    static conversion_callback (url) {
        // if (typeof(url) != 'undefined') {
        //     window.location = url;
        // }
    }

    static pushConversion(data) {
        if (!this.isActive()) {
            return false;
        }

        // if (typeof data.event_callback === 'undefined') {
        //     data.event_callback = this.conversion_callback;
        // }
        console.log('GoogleAds event triggered');
        window.gtag('event','conversion',data);
    }
}


export default GoogleAds;
