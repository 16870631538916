import { Howl } from "howler";

const cache = {};

const cdnAudioUrl = `https://${window.config.cdn_address}/${window.config.brand_name_short.toLowerCase()}/audio`;

export const createHowl = (sound) => {

  const soundUri = `${cdnAudioUrl}/${sound}`;

  if (typeof cache[soundUri] !== "undefined") return cache[soundUri];

  cache[soundUri] = new Howl({
    src: [soundUri],
    html5: true,
    preload: true
  });

  return cache[soundUri]
};