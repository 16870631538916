import { combineReducers } from "redux";
import appReducer from "./app";
import tablesReducer from "./tables";

export const rootReducer = combineReducers({
  app: appReducer,
  tables: tablesReducer,
});

export default rootReducer;
