import "./dev.js";

import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { Router } from "react-router-dom";

import { I18nextProvider } from "react-i18next";
import i18n from "./utils/i18n";

import applicationReducer from "./store/reducers/application";
import authModalReducer from "./store/reducers/authModal";
import authenticationReducer from "./store/reducers/authentication";
import layoutMessageReducer from "./store/reducers/layoutMessage";
import authButtonReducer from "./store/reducers/authButton";
import contentLoaderReducer from "./store/reducers/contentLoader";
import gamesReducer from "./store/reducers/games";
import mainBannerReducer from "./store/reducers/mainBanner";
import registerWizardReducer from "./store/reducers/registerWizard";
import profileReducer from "./store/reducers/profile";
import documentsReducer from "./store/reducers/documents";
import leftMenuReducer from "./store/reducers/leftMenu";
import rightMenuReducer from "./store/reducers/rightMenu";
import walletReducer from "./store/reducers/wallet";
import resetPasswordReducer from "./store/reducers/resetPassword";
import promotionsReducer from "./store/reducers/promotions";
import withdrawalsReducer from "./store/reducers/withdrawals";
import depositReducer from "./store/reducers/deposit";
import spentTimeReducer from "./store/reducers/spentTime";
import customerSupportReducer from "./store/reducers/customerSupport";
import transactionsReducer from "./store/reducers/transactions";
import geoLocationReducer from "./store/reducers/geolocation";
import jackpotsReducer from "./store/reducers/jackpots";
import notificationReducer from "./store/reducers/notifications";
import happyHourReducer from "./store/reducers/happy_hour";
import configReducer from "./store/reducers/config";
import freeBetsReducer from "./store/reducers/free_bets";
import paymentCheckoutReducer from './store/reducers/paymentCheckout';
import redeemCodeReducer from "./store/reducers/redeemCode";
import requestManagerReducer from "./store/reducers/requestManager";
import freeSpinsReducer from "./store/reducers/free_spins";
import marketingOfferReducer from "./store/reducers/marketing_offer";
import druidReducer from "./store/reducers/druid";

import BackendClient from "./BackendClient";
import VivaApi from "./VivaApi";
import "sanitize.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import "./index.css";
import "./colors.css";

import { rootSaga } from "./store/sagas";

import { setStore as betsSetStore } from "./bets/store";
import { betsRootReducer } from "./bets/store/reducers";

import { setStore as virtualsSetStore } from "./virtuals/store";
import { virtualsRootReducer } from "./virtuals/store/reducers";

import { setStore as lottoSetStore } from "./lotto/store";
import { lottoRootReducer } from "./lotto/store/reducers";

import { setStore as winnerFunSetStore } from "./winner-fun/store";
import { winnerFunRootReducer } from "./winner-fun/store/reducers";

import { setStore as liveCasinoSetStore } from "./live-casino/store";
import { rootReducer as liveCasinoReducer } from "./live-casino/store/reducers";

import alertsReducer from "./store/reducers/alerts";

import { soundsRootReducer } from "./sounds-toggle/store/reducers";
import App from "./App";

import { setStore as lobbySetStore } from "./lobby/store";
import { lobbyRootReducer } from "./lobby/store/reducers";

import MaintenanceGateway from "./maintenance/MaintenanceGateway";
import MaintenanceModal from "./maintenance/MaintenanceModal";
import { setStore as maintenanceSetStore } from "./maintenance/store"
import { maintenanceRootReducer } from "./maintenance/store/reducers";

import history from "./history.js";

import jackpotListReducer from "./store/reducers/jackpotList";

import { setStore as wheelSetStore } from "./wheel/store";
import { wheelRootReducer } from "./wheel/store/reducers";

import { setStore as promoSetStore } from "./promo/store"
import { promoRootReducer } from "./promo/store/reducers";

import { setStore as tournamentsGroupsSetStore } from "./tournaments-missions/store"
import { tournamentsMissionsRootReducer } from "./tournaments-missions/store/reducers";

import { setStore as depositTicketSetStore } from "./deposit-ticket/store";
import { depositTicketRootReducer } from "./deposit-ticket/store/reducers";

if (
    process.env.NODE_ENV === "development" ||
    (window.config.environment && window.config.environment !== "production")
) {
    console.log("!!!! THIS IS A STAGING ENVIRONMENT !!!!");
}

const rootReducer = combineReducers({
    application: applicationReducer,
    authModal: authModalReducer,
    authentication: authenticationReducer,
    layoutMessage: layoutMessageReducer,
    authButton: authButtonReducer,
    contentLoader: contentLoaderReducer,
    games: gamesReducer,
    mainBanner: mainBannerReducer,
    registerWizard: registerWizardReducer,
    profile: profileReducer,
    documents: documentsReducer,
    wallet: walletReducer,
    leftMenu: leftMenuReducer,
    rightMenu: rightMenuReducer,
    promotions: promotionsReducer,
    withdrawals: withdrawalsReducer,
    resetPassword: resetPasswordReducer,
    deposit: depositReducer,
    spentTime: spentTimeReducer,
    customerSupport: customerSupportReducer,
    transactions: transactionsReducer,
    geolocation: geoLocationReducer,
    jackpots: jackpotsReducer,
    notification: notificationReducer,
    bets: betsRootReducer,
    virtuals: virtualsRootReducer,
    lotto: lottoRootReducer,
    happyHour: happyHourReducer,
    config: configReducer,
    liveCasino: liveCasinoReducer,
    sounds: soundsRootReducer,
    freeBets: freeBetsReducer,
    redeemCode: redeemCodeReducer,
    freeSpins: freeSpinsReducer,
    winnerFun: winnerFunRootReducer,
    paymentCheckout: paymentCheckoutReducer,
    requestManager: requestManagerReducer,
    lobby: lobbyRootReducer,
    maintenance: maintenanceRootReducer,
    jackpotList: jackpotListReducer,

    wheel: wheelRootReducer,
    alerts: alertsReducer,
    promo: promoRootReducer,

    marketingOffer: marketingOfferReducer,
    druid: druidReducer,
    register: registerWizardReducer,

    tournamentsMissions: tournamentsMissionsRootReducer,

    depositTicket: depositTicketRootReducer,
});

const asyncDispatchMiddleware = store => next => action => {
    let syncActivityFinished = false;
    let actionQueue = [];

    function flushQueue() {
        actionQueue.forEach(a => store.dispatch(a)); // flush queue
        actionQueue = [];
    }

    function asyncDispatch(asyncAction) {
        actionQueue = actionQueue.concat([asyncAction]);

        if (syncActivityFinished) {
            flushQueue();
        }
    }

    const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });

    const res = next(actionWithAsyncDispatch);
    syncActivityFinished = true;
    flushQueue();

    return res;
};

const sagaMiddleware = createSagaMiddleware();
let storeVariable;
if (process.env.NODE_ENV === "development" ||
    (window.config.environment && window.config.environment !== "production")) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    storeVariable = createStore(
        rootReducer, composeEnhancers(applyMiddleware(thunk, asyncDispatchMiddleware, sagaMiddleware)),
    );
} else {
    storeVariable = createStore(
        rootReducer,
        applyMiddleware(thunk, asyncDispatchMiddleware, sagaMiddleware)
    );
}
export const store = storeVariable;

BackendClient.setStore(store);
VivaApi.setStore(store);

if (window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")) {
    betsSetStore(store);
}

if (window.config && window.config.virtualsEnabled === "1") {
    virtualsSetStore(store);
}

if (window.config && window.config.lottoEnabled === "1") {
    lottoSetStore(store);
}

if (window.config && window.config.liveCasinoEnabled === "1") {
    liveCasinoSetStore(store);
}

if (window.config && window.config.maintenanceModuleEnabled === "1") {
    maintenanceSetStore(store);
}

if (window.config && window.config.winnerFunEnabled === "1") {
    winnerFunSetStore(store);
}

if (window.config && window.config.lobbyEnabled === "1") {
    lobbySetStore(store);
}

if (window.config && window.config.maintenanceModuleEnabled === "1") {
    maintenanceSetStore(store);
}

if (window.config && window.config.wheelEnabled === "1") {
    wheelSetStore(store);
}

if (window.config && window.config.promoEnabled === "1") {
    promoSetStore(store);
}

if (window.config && window.config.tournamentsMissionsEnabled === "1") {
    tournamentsGroupsSetStore(store);
}

if (window.config && window.config.depositTicketEnabled === "1") {
    depositTicketSetStore(store);
}

sagaMiddleware.run(rootSaga);

let mainMenuDataElement = document.getElementById("menu-data");
let menuData = JSON.parse(mainMenuDataElement.firstElementChild.innerHTML);

/** START -- Fix for Chrome problems on scroll **/
const EVENTS_TO_MODIFY = ["touchstart", "touchmove", "touchend", "touchcancel", "wheel"];

const originalAddEventListener = document.addEventListener.bind();
document.addEventListener = (type, listener, options, wantsUntrusted) => {
    let modOptions = options;
    if (EVENTS_TO_MODIFY.includes(type)) {
        if (typeof options === "boolean") {
            modOptions = {
                capture: options,
                passive: false
            };
        } else if (typeof options === "object") {
            modOptions = {
                ...options,
                passive: false
            };
        }
    }

    return originalAddEventListener(type, listener, modOptions, wantsUntrusted);
};

const originalRemoveEventListener = document.removeEventListener.bind();
document.removeEventListener = (type, listener, options) => {
    let modOptions = options;
    if (EVENTS_TO_MODIFY.includes(type)) {
        if (typeof options === "boolean") {
            modOptions = {
                capture: options,
                passive: false
            };
        } else if (typeof options === "object") {
            modOptions = {
                ...options,
                passive: false
            };
        }
    }
    return originalRemoveEventListener(type, listener, modOptions);
};
/** END -- Fix for Chrome problems on scroll **/
window._store = store;
ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <Router history={history}>
                <MaintenanceGateway>
                    <App menu={menuData} />
                    <MaintenanceModal />
                </MaintenanceGateway>
            </Router>
        </Provider>
    </I18nextProvider>,
    document.getElementById("root")
);
