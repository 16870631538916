//import PlaceholderImage from "./assets/placeholder.png";
import LuckySixImage from "../assets/img-thumbnail-luckysix@2x.png";
import LuckyXImage from "../assets/img-thumbnail-luckyx@2x.png";
import NextSixImage from "../assets/img-thumbnail-nextsix@2x.png";
import GreyhoundImage from "../assets/img-thumbnail-grayoundraces@2x.png";
import VGreyhoundImage from "../assets/img-thumbnail-virtualgrayhounds@2x.png";
import VHorseImage from "../assets/img-thumbnail-virtualhorseracing@2x.png";
import VMSImage from "../assets/img-thumbnail-virtualmoto@2x.png";

let isStaging = false;

if (
  process.env.NODE_ENV === "development" ||
  (window.config.environment && window.config.environment !== "production")
) {
  isStaging = true;
}

export default [
  {
    id: "luckysix",
    name: "Lucky Six",
    img: LuckySixImage,
    iframeURL: isStaging ? "https://staging-winner.web.7platform.net/luckysix" : "https://winner.web.7platform.net/luckysix"
  },
  {
    id: "greyhound",
    name: "Greyhound Races",
    img: GreyhoundImage,
    iframeURL: isStaging ? "https://staging-winner.web.7platform.net/greyhound" : "https://winner.web.7platform.net/greyhound"
  },
  {
    id: "nextsix",
    name: "Next Six",
    img: NextSixImage,
    iframeURL: isStaging ? "https://staging-winner.web.7platform.net/nextsix" : "https://winner.web.7platform.net/nextsix"
  },
  {
    id: "vhorse",
    name: "Virtual Horse Races",
    img: VHorseImage,
    iframeURL: isStaging ? "https://staging-winner.web.7platform.net/vhorse" : "https://winner.web.7platform.net/vhorse"
  },
  {
    id: "vms",
    name: "Virtual Motorcycle Speedway",
    img: VMSImage,
    iframeURL: isStaging ? "https://staging-winner.web.7platform.net/vms" : "https://winner.web.7platform.net/vms"
  },
  {
    id: "luckyx",
    name: "Lucky X",
    img: LuckyXImage,
    iframeURL: isStaging ? "https://staging-winner.web.7platform.net/luckyx" : "https://winner.web.7platform.net/luckyx"
  },
  {
    id: "vgreyhound",
    name: "Virtual Greyhound Races",
    img: VGreyhoundImage,
    iframeURL: isStaging ? "https://staging-winner.web.7platform.net/vgreyhound" : "https://winner.web.7platform.net/vgreyhound"
  }
];
