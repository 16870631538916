import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/ro.js";

import {
  getBetsState,
  getPrematchTournamentDataRetails,
  getPrematchSportInfoRetail,
  makeGetCategories,
  makeGetTournaments,
  makeGetTournamentGroups
} from "../../../bets/store/selectors/betData";
import { prematchFetchSportByDay } from "../../../bets/store/actions/prematch";
import { nSoftChangeDayIndex } from "../../../bets/store/actions/nsoft";

import Search from "../../components/search";
import TournamentHeader from "../../components/tournament-header";
import DateHeader from "../../components/date-header";
import MatchRowPreMatch from "../../components/match-row-prematch";
import { testValues } from "../../../bets/utils";
import NewtonLoader from "../../../newton-loader";

const Tournament = props => {
  const {
    match,
    sport,
    matchData,
    categories,
    tournaments,
    tournamentGroups,
    t,
    i18n,
    prematchFetchSportByDay,
    loadingState,
    fullStateLoaded,
    dayIndex,
    nSoftChangeDayIndex
  } = props;

  const calendarMatches = {};
  for (let i = 0; i < 7; i++) {
    const ts = moment().add(i, 'days').startOf("day").valueOf();
    calendarMatches[ts] = true;
  }

  let allMatches = [];
  Object.keys(matchData).forEach(c => {
    Object.keys(matchData[c]).forEach(t => {
      const matches = Object.values(matchData[c][t]);
      allMatches = [...allMatches, ...matches];
    });
  });

  const dates = [
    { t: 0, label: "All" }, // t("All")
    { t: 1, label: "3 days" }, // t("3 days")
    { t: 2, label: "Today" }, // t("Today")
    { t: 3, label: "Soon (+3h)" }, // t("Soon")
  ];
  const filterFormat = "YYYY.MM.DD"; //DD/MM/YYYY
  const dayFormat = "DD/MM/YYYY"; //DD/MM/YYYY

  Object.keys(calendarMatches).sort().forEach(t => {
    if (moment(parseInt(t, 10)).format(filterFormat) === moment().format(filterFormat)) return;
    dates.push({
      t: t
    });
  });

  const dateList = dates;
  const [state, setState] = React.useState({
    currentIndex: dayIndex,
    sort: 0,
    filter: "",
    collapsed: {},
    collapsedDay: {},
  });
  const [fetch, setFetch] = React.useState(0);

  const toggleCollapsed = (id) => () => {
    const collapsed = { ...state.collapsed };
    if (typeof collapsed[id] === "undefined") {
      collapsed[id] = true;
    } else {
      collapsed[id] = !collapsed[id];
    }

    setState(v => ({ ...v, collapsed }));
  };
  const toggleCollapsedDay = (day) => () => {
    const collapsedDay = { ...state.collapsedDay };
    if (typeof collapsedDay[day] === "undefined") {
      collapsedDay[day] = true;
    } else {
      collapsedDay[day] = !collapsedDay[day];
    }

    setState(v => ({ ...v, collapsedDay }));
  };

  const [idSport, setIDSport] = React.useState(match.params.idSport);
  const [idCategory, setIDCategory] = React.useState(match.params.idCategory);
  const [idTournament, setIDTournament] = React.useState(match.params.idTournament);

  React.useEffect(() => {
    if (
      match.params.idSport !== idSport ||
      match.params.idCategory !== idCategory ||
      match.params.idTournament !== idTournament
    ) {
      setState(s => ({
        ...s,
        sort: 0,
        filter: "",
        collapsed: {},
        collapsedDay: {},
      }));
      setIDSport(v => {
        if (v !== match.params.idSport) {
          setTimeout(() => {
            setFetch(v => v + 1);
          }, 0);
          return match.params.idSport;
        }
        return v
      });
      setIDCategory(v => {
        if (v !== match.params.idCategory) {
          return match.params.idCategory;
        }
        return v
      });
      setIDTournament(v => {
        if (v !== match.params.idTournament) {
          return match.params.idTournament;
        }
        return v
      });
    }
  }, [
    match.params.idSport,
    match.params.idCategory,
    match.params.idTournament,
    idSport,
    idCategory,
    idTournament
  ]);

  const handleSearchChange = (type, val) => {
    setState(v => {
      const tmp = { ...v };
      if (type === "tab") {
        tmp.currentIndex = val;
      } else if (type === "filter") {
        tmp.filter = val;
      } else if (type === "sort") {
        tmp.sort = val;
      }
      return tmp;
    });
    if (type === "tab") {
      setFetch(v => v + 1);
      nSoftChangeDayIndex(val);
    }
  };

  const oldFetch = React.useRef(0);
  React.useEffect(() => {
    if (fullStateLoaded && oldFetch.current !== fetch && loadingState === null && dateList.length) {
      let day;
      let noDays = 1;
      if (state.currentIndex === 0) {
        day = 0;
      } else if (state.currentIndex === 1) {
        day = moment().startOf("day").valueOf() + "";
        noDays = 3;
      } else if (state.currentIndex === 2 || state.currentIndex === 3) {
        day = moment().startOf("day").valueOf() + "";
      } else {
        day = moment(parseInt(dateList[state.currentIndex].t, 10)).startOf("day").valueOf() + "";
      }
      //console.log("prematchFetchSportByDay", { day, noDays, idSport });
      prematchFetchSportByDay({ day, noDays, idSport });
      oldFetch.current = fetch;
    }
  }, [fetch, fullStateLoaded, dateList, state.currentIndex, idSport, loadingState, prematchFetchSportByDay]);

  const filterByDate = (m, currentDate) => {
    if (state.currentIndex === 0) {
      // "All" matches
      return true;
    } else if (state.currentIndex === 1) {
      // "Three days" matches
      return moment(parseInt(m.matchDateTime, 10)).format(filterFormat) < moment().startOf("day").add(3, 'days').startOf("day").format(filterFormat);
    } else if (state.currentIndex === 2) {
      // "Today" matches
      return moment(parseInt(m.matchDateTime, 10)).format(filterFormat) === moment().format(filterFormat);
    } else if (state.currentIndex === 3) {
      // "Soon" matches
      return m.matchDateTime <= moment().add(3, 'hours').valueOf();
    }

    // "[DAY_OF_THE_WEEK]" matches
    return moment(parseInt(m.matchDateTime, 10)).format(filterFormat) === moment(currentDate).format(filterFormat);
  };

  const buildMatches = (matches, mCurrentDate, category, tournament, tournamentGroupName) => {
    const t = tournament.idTournament;
    const c = category.idCategory;

    let nowDate = state.currentIndex <= 3 ? moment(parseInt(matches[0].matchDateTime, 10)).format(dayFormat) : mCurrentDate;

    const mg = `mg_sort_leagues_category_${c}_${t}${tournamentGroupName ? `_${tournamentGroupName}` : ''}`;

    let hasMatches = false;
    let displayMatches = [];

    displayMatches.push(<DateHeader key={`dateHeader_${c}_${t}_${nowDate}`} date={nowDate} matchGroup={mg} matches={matches} />);

    matches.forEach((m, i) => {
      if (
        state.filter &&
        `${m.team1Name} - ${m.team2Name}`.toLowerCase().indexOf(state.filter.toLowerCase()) === -1
      )
        return null;
      hasMatches = true;

      const newDate = moment(parseInt(m.matchDateTime, 10)).format(dayFormat);
      if (newDate !== nowDate) {
        nowDate = newDate;
        displayMatches.push(<DateHeader key={`dateHeader_${c}_${t}_${nowDate}`} date={nowDate} matchGroup={mg} matches={matches} />)
      }

      displayMatches.push(
        <MatchRowPreMatch
          key={"pm_" + m.idMatch}
          idMatch={m.idMatch}
          matchGroup={mg}
        />
      );
    });

    if (!hasMatches) return null;

    const collapsedKey = `${tournament.idTournament}${tournamentGroupName ? tournamentGroupName : ''}`;

    if (state.collapsed[collapsedKey]) displayMatches = [];

    return (
      <div className="tournaments" key={`tournament_${tournament.idTournament}${tournamentGroupName ? `_${tournamentGroupName}` : ''}`}>
        <TournamentHeader
          idTournament={tournament.idTournament}
          brId={tournament.brId}
          name={`${category.categoryName} / ${tournament.tournamentName}${tournamentGroupName ? ` / ${tournamentGroupName}` : ''}`}
          onClick={toggleCollapsed(collapsedKey)}
          collapsed={typeof state.collapsed[collapsedKey] !== "undefined" ? state.collapsed[collapsedKey] : false}
          matchGroup={mg}
          matches={matches}
        />
        {displayMatches}
      </div>
    );
  };

  const list = [];
  if (state && matchData && dates[state.currentIndex]) {
    const currentDate = parseInt(dates[state.currentIndex].t, 10);
    const mCurrentDate = state.currentIndex > 3 ? moment(currentDate).format(dayFormat) : moment().format(dayFormat);
    const filter = state.filter;

    if (state.sort === 0) {
      const categoryKeys = Object.keys(matchData);
      categoryKeys.sort((a, b) => {
        const posA = a !== "0" && categories[a] ? categories[a].categoryPosition : null
        const posB = b !== "0" && categories[b] ? categories[b].categoryPosition : null
        return testValues(posA, posB);
      });

      categoryKeys.forEach(c => {
        const category = categories[c] ? categories[c] : null;

        if (!category) return;

        const tournamentKeys = Object.keys(matchData[c]);
        tournamentKeys.sort((a, b) => {
          const posA = a !== "0" && tournaments[a] ? tournaments[a].tournamentPosition : null
          const posB = b !== "0" && tournaments[b] ? tournaments[b].tournamentPosition : null
          return testValues(posA, posB);
        });

        tournamentKeys.forEach(t => {
          const matches = Object.values(matchData[c][t]).filter(
            m => filterByDate(m, currentDate)
          );
          if (matches.length) {
            const tournament = tournaments[t] ? tournaments[t] : null;

            if (!tournament) return;
            if (!category) return;

            matches.sort((a, b) => a.matchDateTime - b.matchDateTime);

            const tmpGroups = {};
            let hasGroups = false;
            matches.forEach(m => {
              if (typeof tmpGroups[m.idTournamentGroup] === "undefined") {
                tmpGroups[m.idTournamentGroup] = [];
              }

              tmpGroups[m.idTournamentGroup].push(m);
              if (m.idTournamentGroup !== "0") hasGroups = true;
            });

            const groups = [];

            if (hasGroups) {
              const keys = Object.keys(tmpGroups);

              keys.sort((a, b) => {
                const posA = a !== "0" && tournamentGroups[a] ? tournamentGroups[a].tournamentGroupPosition : null
                const posB = b !== "0" && tournamentGroups[b] ? tournamentGroups[b].tournamentGroupPosition : null
                return testValues(posA, posB);
              });

              keys.forEach(gID => {
                if (tournamentGroups[gID]) {
                  groups.push({ id: gID, groupName: tournamentGroups[gID].tournamentGroupName, m: tmpGroups[gID] });
                } else {
                  groups.push({ id: gID, groupName: "", m: tmpGroups[gID] });
                }
              });

              groups.forEach(g => {
                const res = buildMatches(g.m, mCurrentDate, category, tournament, g.groupName);

                if (res) list.push(res);
              });

            } else {
              const res = buildMatches(matches, mCurrentDate, category, tournament);
              if (res) list.push(res);
            }
          }
        });
      });
    } else {

      // sort by grouped
      const matches = allMatches.filter(m => {
        if (
          filter &&
          `${m.team1Name} - ${m.team2Name}`.toLowerCase().indexOf(filter.toLowerCase()) === -1
        )
          return false;
        return filterByDate(m, currentDate);
      });
      matches.sort((a, b) => a.matchDateTime - b.matchDateTime);

      let nowDate = matches.length ? parseInt(matches[0].matchDateTime, 10) : moment().valueOf();
      let cDate = moment(nowDate).format(dayFormat);
      let d;
      if (i18n.language === "ro") {
        d = moment(nowDate).locale("ro", localization);
      } else {
        d = moment(nowDate).locale("en");
      }
      let mCurrentDay = d.format("dddd");
      if (d.format("YYYYMMDD") === moment().format("YYYYMMDD")) {
        mCurrentDay = t("Today");
      } else if (d.format("YYYYMMDD") === moment().add(1, "day").format("YYYYMMDD")) {
        mCurrentDay = t("Tomorrow");
      }

      let mg = `mg_sort_days_tournament_${cDate}_${mCurrentDay}`;
      if (matches.length) {
        let tmpMatches = [];

        matches.forEach((m, i) => {
          let newDate = moment(parseInt(m.matchDateTime, 10)).format(dayFormat);

          if (cDate !== newDate) {
            if (tmpMatches.length) {
              let cnt = null;
              if (!state.collapsedDay[cDate]) {
                cnt = <React.Fragment>
                  <DateHeader date={cDate} matchGroup={mg} matches={matches} />
                  {tmpMatches}
                </React.Fragment>;
              }

              list.push(
                <div className="tournaments" key={`day_${cDate}_${mCurrentDay}`}>
                  <TournamentHeader
                    name={mCurrentDay}
                    onClick={toggleCollapsedDay(cDate)}
                    collapsed={typeof state.collapsedDay[cDate] !== "undefined" ? state.collapsedDay[cDate] : false}
                    matchGroup={mg}
                    matches={matches}
                  />
                  {cnt}
                </div>
              );
            }

            cDate = newDate;

            let d;
            if (i18n.language === "ro") {
              d = moment(parseInt(m.matchDateTime, 10)).locale("ro", localization);
            } else {
              d = moment(parseInt(m.matchDateTime, 10)).locale("en");
            }
            mCurrentDay = d.format("dddd");
            if (d.format("YYYYMMDD") === moment().format("YYYYMMDD")) {
              mCurrentDay = t("Today");
            } else if (d.format("YYYYMMDD") === moment().add(1, "day").format("YYYYMMDD")) {
              mCurrentDay = t("Tomorrow");
            }
            tmpMatches = [];
          }

          let newFilterDate = moment(parseInt(m.matchDateTime, 10)).format(filterFormat);
          let futureDay = moment().add(8, "days").format(filterFormat);
          if (newFilterDate >= futureDay) {// allow only for maxim 7 days
            return;
          }

          tmpMatches.push(<MatchRowPreMatch
            key={"pm_" + m.idMatch}
            idMatch={m.idMatch}
            matchGroup={mg}
            extended={true}
          />);
        });

        if (tmpMatches.length) {
          let cnt = null;
          if (!state.collapsedDay[cDate]) {
            cnt = <React.Fragment>
              <DateHeader date={cDate} matchGroup={mg} matches={matches} />
              {tmpMatches}
            </React.Fragment>;
          }

          list.push(
            <div className="tournaments" key={`day_${cDate}_${mCurrentDay}`}>
              <TournamentHeader
                name={mCurrentDay}
                onClick={toggleCollapsedDay(cDate)}
                collapsed={typeof state.collapsedDay[cDate] !== "undefined" ? state.collapsedDay[cDate] : false}
                matchGroup={mg}
                matches={matches}
              />
              {cnt}
            </div>
          );
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Search
        className="prematch"
        dateList={dateList}
        currentIndex={state.currentIndex}
        sort={state.sort}
        filter={state.filter}
        onChange={handleSearchChange}
      />

      <div className="elements">
        <div className={`sport-header sport-color-${idSport}`}>{sport.sportName}</div>
        {loadingState === null && list}
        {list.length !== 0 && state.currentIndex !== 0 && <div className="search-warning">
          {t("To perform a complete search please select the \"All\" option from the menu.")}
        </div>}
        {list.length === 0 && loadingState === null && (
          <div className="empty">
            {t("Currently there are no matches for selected parameters!")}
          </div>
        )}
        {loadingState !== null && (
          <div className="txt-center">
            <NewtonLoader />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  const getCategories = makeGetCategories();
  const getTournaments = makeGetTournaments();
  const getTournamentGroups = makeGetTournamentGroups();

  return (state, props) => {
    const bst = getBetsState(state);

    const init = {
      mType: "prematch",
      idSport: props.match.params.idSport,
      idCategory: props.match.params.idCategory,
      idTournament: props.match.params.idTournament
    };

    return {
      loadingState: bst.prematch.loadingState,
      fullStateLoaded: bst.prematch.fullStateLoaded,
      categories: getCategories(state, init),
      tournaments: getTournaments(state, init),
      tournamentGroups: getTournamentGroups(state, init),
      sport: getPrematchSportInfoRetail(state, init),
      matchData: getPrematchTournamentDataRetails(state, init),
      dayIndex: bst.nsoft.dayIndex
    };
  };
};

const actionCreators = {
  prematchFetchSportByDay,
  nSoftChangeDayIndex
};

export default connect(mapStateToProps, actionCreators)(withTranslation()(Tournament));
