import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import StepWizard from 'react-step-wizard';
import * as stateActions from "../../../../store/actions/index";
import CurrentPassword from './CurrentPassword/CurrentPassword';
import NewPassword from './NewPassword/NewPassword';
import './PasswordModal.scss';
import { withTranslation } from "react-i18next";

class PasswordModal extends Component {
    close() {
        if (true === this.props.profile.changePasswordForm.requested) {
            return;
        }

        this.props.closeModal(true);
    }

    render() {
        return (
            <Modal isOpen={this.props.profile.passwordModal} className="PasswordChangeModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader toggle={this.close.bind(this)} className="PasswordChangeModalHeader">{this.props.t("change password")}</ModalHeader>
                <ModalBody className="PasswordChangeModalBody">
                    <StepWizard>
                        <CurrentPassword />
                        <NewPassword />
                    </StepWizard>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => dispatch(stateActions.openPasswordModal()),
        closeModal: () => dispatch(stateActions.closePasswordModal()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PasswordModal));