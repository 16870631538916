import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VelocityComponent } from 'velocity-react';
import './ContentError.css';
import ComingSoon from "../../CustomerSupport/PagesContent/ComingSoon";

class ContentError extends Component {
    render() {
        let title = "";
        let message = "";
        if (null !== this.props.contentError) {
            title = this.props.contentError.title;
            message = this.props.contentError.message;
        }
        if (process.env.NODE_ENV !== 'development') {
            return <ComingSoon type={2}/>
        } else {

            return (
                <VelocityComponent animation={{ opacity: 1, top: 0 }} duration={1000} runOnMount={true}>
                    <div className="ContentError">
                        <div className="alert alert-danger topMargin">
                            <h2>{title}</h2>
                            <p>{message}</p>
                        </div>
                    </div>
                </VelocityComponent>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        contentError: state.application.error,
    }
};

export default connect(mapStateToProps)(ContentError);