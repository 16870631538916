import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { withRouter } from "react-router-dom";

import Section from "../layout/Section";
import Match from "../components/Match";
import Collapsible from "../components/Collapsible";
import BetSelectSingle from "../components/BetSelectSingle";

import InPageMD from "./InPageMD";
import { getBetsState, getLiveSportsList } from "../store/selectors/betData";
import { navSportsUpdate } from "../store/actions/navSports";
import { debug, testValues, makeSort2Keys } from "../utils";

const RENDER_BATCH = 3;
const DEBUG = false;

const useStyles = makeStyles({
  root: {
    backgroundColor: "#1B1E2C",
    padding: "16px 7px",
    color: "#fff",
    height: "100%",
    position: "relative"
  },
  header: {
    fontSize: "22px",
    fontWeight: "600",
    borderLeft: "2px solid #F8991C",
    paddingLeft: "16px",
    margin: "24px 7px",
    color: "#fff"
  },
  sticky: {
    position: "sticky",
    top: "0",
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.16)"
  }
});

function LiveMatch(props) {
  const { idSport, liveData, categories, tournaments, highlights, location, navSportsUpdate, t } = props; // eslint-disable-line
  const classes = useStyles();

  //console.log("live match props", props);

  const [show, setShow] = React.useState(false);
  const [toRender, setToRender] = useState(0);
  const [content, setContent] = useState({ chs: [], h2c: [] });

  const query = new URLSearchParams(location.search);
  const leagueId = query.get("f");
  const [leagueSelection, setLeagueSelection] = React.useState({
    leagueId: leagueId ? leagueId : ""
  });
  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    const leagueId = query.get("f") ? query.get("f") : "";

    if (leagueSelection.leagueId === leagueId) {
      return;
    }

    if (leagueId !== "") {
      const lidp = leagueId.split("_");
      if (lidp[0] !== idSport) {
        navSportsUpdate("live", lidp[0]);
      }

      setLeagueSelection(v => {
        if (v.leagueId !== leagueId) {
          return {
            leagueId,
          };
        }
        return v;
      })
    } else {
      setLeagueSelection({ leagueId: "" });
    }

  }, [location]); // eslint-disable-line

  React.useEffect(() => {
    setShow(true);
    setToRender(RENDER_BATCH);
  }, []); // eslint-disable-line

  //console.log("liveData[idSport]", liveData[idSport]);

  React.useLayoutEffect(() => {
    let rid = 0;
    const chsLength = content.chs.length;
    if (toRender < chsLength) {
      let d = chsLength - toRender;
      if (d > RENDER_BATCH) {
        d = RENDER_BATCH;
      }
      rid = setTimeout(() => {
        setToRender(tr => {
          DEBUG && console.log(`render from ${toRender} to ${toRender + d}`);
          return tr + d;
        });
      }, 0);
    }

    return () => {
      if (rid) clearTimeout(rid);
    };
  }, [toRender, content.chs]);

  React.useEffect(() => {

    const query = new URLSearchParams(location.search);
    const leagueId = query.get("f") ? query.get("f") : "";
    const [fIdSport, fIdCategory, fIdTournament] = leagueId.split("_");

    if (fIdSport && fIdSport !== idSport) {
      navSportsUpdate("live", fIdSport);
      return;
    }

    const currentDate = moment().format("YYYY-MM-DD");
    let cHighlights = [];
    if (
      highlights &&
      typeof highlights[currentDate] !== "undefined" &&
      typeof highlights[currentDate][idSport] !== "undefined"
    ) {
      cHighlights = highlights[currentDate][idSport].rows;
    }

    const md = liveData[idSport] || {};
    //console.log("idSport", idSport, `liveData[${idSport}]`, liveData[idSport]);

    let expanded = 0;
    let chs = [];

    const matches = {};
    const leagues = {};


    if (Object.keys(md).length) {
      Object.keys(md).forEach(c => {
        if (!categories[c].categoryActive) return;

        Object.keys(md[c]).forEach(t => {
          if (!tournaments[t].tournamentActive) return;

          leagues[t] = {
            c,
            t
          };
          const ms = Object.keys(md[c][t]);
          ms.forEach(m => {
            if (!md[c][t][m].active) return;

            matches[m] = {
              c,
              t,
              m
            };
          });
        });
      });
    }

    const leaguesId = Object.keys(leagues);

    let h2c = [];
    let hasHighlights = false;
    if (cHighlights.length) {
      let highlightsMatchesGroup = "";
      const mts = [];

      cHighlights.forEach((item, index) => {
        if (item.type === "match") {
          if (typeof matches[item.idMatch] !== "undefined") {
            highlightsMatchesGroup = "mg_highlights_matches_live_" + item.idSport;
            const m = md[matches[item.idMatch].c][matches[item.idMatch].t][matches[item.idMatch].m];
            hasHighlights = true;
            mts.push(m);
            h2c.push(<Match key={index} idMatch={m.idMatch} mType={m.mType} dark={true} />);
          }
        }
      });

      if (h2c.length && highlightsMatchesGroup && mts.length) {
        h2c.unshift(
          <BetSelectSingle
            key={"what_other_matches_play"}
            matches={mts}
            match={mts[0]}
            mType="live"
            dark={true}
            matchGroup={highlightsMatchesGroup}
          />
        );
      }

      cHighlights.map((item, index) => {
        if (item.type !== "match") {
          // league
          if (typeof leagues[item.idTournament] !== "undefined") {
            const c = leagues[item.idTournament].c;
            const t = leagues[item.idTournament].t;
            const ms = Object.values(md[c][t]);
            const matchGroup = "mg_highlights_leagues_live_" + idSport + "_" + c + "_" + t;

            const displayMatches = [];
            let currDate = ms && ms.length ? ms[0].matchDateTime : null;

            displayMatches.push(<BetSelectSingle
              key={displayMatches.length}
              matches={ms}
              match={ms.length ? ms[0] : null}
              mType="prematch"
              matchGroup={matchGroup}
              time={currDate}
              dark={true}
            />);

            ms.forEach((m, i) => {
              displayMatches.push(<Match
                key={displayMatches.length}
                idMatch={m.idMatch}
                mType={m.mType}
                inGroup={true}
                matchGroup={matchGroup}
                dark={true}
              />);
            });

            const ch = (
              <Collapsible
                type="live"
                idSport={idSport}
                region={categories[c].categoryIsoName}
                categoryDLId={categories[c].categoryDLId}
                league={tournaments[t].tournamentName}
                description={tournaments[t].description}
                leagueDetails={`#league-details-live-${idSport}-${c}-${t}`}
                key={index}
                opened={expanded < 5}
                dark={true}
                events={ms ? ms.length : 0}
              >
                {displayMatches}
              </Collapsible>
            );

            expanded++;
            hasHighlights = true;
            h2c.push(ch);
          } else {
            //console.log(`tournament ${item.idTournament} missing`);
          }
        }
        return null;
      });
      expanded = 0;
    }

    //console.log("matches", matches);
    //console.log("leagues", leagues);
    //console.log("cHighlights", cHighlights);
    //console.log("h2c", h2c);

    if (Object.keys(md).length) {

      const tournamentKeys = leaguesId.sort(
        makeSort2Keys(tournaments, "tournamentPosition", "tournamentName", true)
      );
      const filteredElements = Object.values(leagues)
        .sort((l1, l2) => testValues(tournaments[l1.t].tournamentPosition ? tournaments[l1.t].tournamentPosition : null, tournaments[l2.t].tournamentPosition ? tournaments[l2.t].tournamentPosition : null, true))
        .filter(l => {
          if (fIdCategory) {
            if (l.c === fIdCategory) return true;
            return false;
          }
          return true;
        })
        .filter(l => {
          if (fIdTournament) {
            if (l.t === fIdTournament) return true;
            return false;
          }
          return true;
        });


      chs = tournamentKeys
        .map((leagueId, index) => {

          const league = filteredElements.find(el => leagueId === el.t);

          if (!league) return null;

          const c = league.c;
          const t = league.t;

          //console.log(expanded, "tournament", tournaments[t]);

          const ms = Object.values(md[c][t]);

          ms.sort((m1, m2) => testValues(m1.position, m2.position));

          const matchGroup = "mg_live_" + idSport + "_" + c + "_" + t;

          //debug("live render", "matchGroup", matchGroup, "ms", ms);

          const displayMatches = [];
          let currDate = ms && ms.length ? ms[0].matchDateTime : null;

          displayMatches.push(<BetSelectSingle
            key={displayMatches.length}
            matches={ms}
            match={ms.length ? ms[0] : null}
            mType="prematch"
            matchGroup={matchGroup}
            time={currDate}
            dark={true}
          />);

          ms.forEach((m, i) => {
            displayMatches.push(<Match
              key={displayMatches.length}
              idMatch={m.idMatch}
              mType={m.mType}
              inGroup={true}
              matchGroup={matchGroup}
              dark={true}
            />);
          });

          const ch = (
            <Collapsible
              type="live"
              idSport={idSport}
              region={categories[c].categoryIsoName}
              categoryDLId={categories[c].categoryDLId}
              league={tournaments[t].tournamentName}
              description={tournaments[t].description}
              leagueDetails={`#league-details-live-${idSport}-${c}-${t}`}
              key={index}
              opened={expanded < 5}
              dark={true}
              events={ms ? ms.length : 0}
            >
              {displayMatches}
            </Collapsible>
          );

          expanded++;

          return ch;
        });
    } else {
      const bgStyle = {
        backgroundColor: "#2b3046",
        margin: "12px 0 6px"
      };
      const cardStyle = {
        backgroundColor: "#101320",
        padding: "9px 12px 5px 18px",
        marginBottom: "5px"
      };
      const elemStyle = {
        backgroundColor: "#2b3046",
        marginBottom: "7px"
      };
      chs = [
        <div key="live-match-highlights-placeholder">
          <Skeleton variant="rect" width={361} height={45} style={bgStyle} />
          <div style={cardStyle}>
            <Skeleton variant="text" width={331} height={15} style={elemStyle} />
            <Skeleton variant="rect" width={150} height={48} style={elemStyle} />
            <Skeleton variant="rect" width={331} height={3} style={elemStyle} />
            <Skeleton variant="text" width={30} height={13} style={elemStyle} />
            <Skeleton variant="rect" width={331} height={35} style={elemStyle} />
          </div>
          <div style={cardStyle}>
            <Skeleton variant="text" width={331} height={15} style={elemStyle} />
            <Skeleton variant="rect" width={150} height={48} style={elemStyle} />
            <Skeleton variant="rect" width={331} height={3} style={elemStyle} />
            <Skeleton variant="text" width={30} height={13} style={elemStyle} />
            <Skeleton variant="rect" width={331} height={35} style={elemStyle} />
          </div>
        </div>
      ];
    }

    setContent({ chs, h2c: hasHighlights ? h2c : [] });
  }, [categories, highlights, idSport, liveData, tournaments, leagueSelection]);

  const pageContent = (
    <React.Fragment>
      {content.h2c.length > 0 && (
        <React.Fragment>
          <div className={classes.header}>{t("What others play")}</div>
          {content.h2c}
        </React.Fragment>
      )}
      {/*<div className={classes.header}>A-Z</div>*/}
      {content.chs.slice(0, toRender)}
    </React.Fragment>
  );

  return (
    <Section doRender={[idSport, content, toRender]}>
      <InPageMD>
        <div className={classes.root}>{pageContent}</div>
      </InPageMD>
    </Section>
  );
}

LiveMatch.whyDidYouRender = true;

LiveMatch.propTypes = {
  idSport: PropTypes.string
};

LiveMatch.defaultProps = {
  idSport: "0"
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    sports: getLiveSportsList(state),
    liveData: bst.live.data,
    idSport: bst.navSports.live,
    categories: bst.live.categories,
    tournaments: bst.live.tournaments,
    highlights: bst.config.highlights
  };
};

const actionCreators = {
  navSportsUpdate
};

export default withRouter(withTranslation()(connect(mapStateToProps, actionCreators)(LiveMatch)));
