import React from 'react';
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { styleToObj, checkComponentAvailability } from "../../utils/functions";

import "./search_dialog.scss";

import GameList from "./../../components/game_list/game_list";
import GameListPromoted from "./../../components/game_list_promoted/game_list_promoted";
import GameCollection from './../../components/game_collection/game_collection';
import JackpotCollection from './../../components/jackpot_collection/jackpot_collection';
import ContentSlider from './../../components/content_slider/content_slider';
import Labels from './../../components/labels/labels';
import Providers from './../../components/providers/providers';
import Breadcrumbs from "./../../components/breadcrumbs/breadcrumbs";
import ItemsLister from "./../../components/items-lister/items_lister";
import News from "./../../components/news/news";
import StartingSoon from "./../../components/starting_soon/starting_soon";
import TopLive from "./../../components/top_live/top_live";
import CompetitionPromotion from "./../../components/competition_promotion/competition_promotion";
import EventPromotion from "./../../components/event_promotion/event_promotion";
import UserPromotion from "./../../components/user_promotions/user_promotions";
import SimplePromotion from './../../components/simple_promotion/simple_promotion';
import BannerComponent from "./../../components/banner_component/banner_component";
import EgtJackpot from "./../../components/egt_jackpot/egt_jackpot";
import EgtJackpotDetails from "./../../components/egt_jackpot_details/egt_jackpot_details";
import HappyHours from './../../components/happy_hours/happy_hours';
import ClaimBonus from './../../components/claim_bonus/claim_bonus';
import UseFreeSpins from './../../components/use_free_spins/use_free_spins';
import WheelStatus from "./../../../wheel/components/cta-lobby";

import Search from "./search";



const empty = {};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SearchDialog = (props) => {
  const { open, lobbyPages, i18n, t, onClose } = props;

  const isDesktop = useMediaQuery("(min-width:1281px)");

  let rootPath = "/lobby";
  if (window.config.rootPaths && window.config.rootPaths.casino) {
    rootPath = "/" + window.config.rootPaths.casino;
  }

  const path = rootPath + "/search";

  const components = [];
  let pageStyle = empty;

  const backHandler = () => {
    if (typeof onClose === "function") onClose();
  };


  if (lobbyPages && typeof lobbyPages[path] !== "undefined" && typeof lobbyPages[path].meta !== "undefined") {
    const lang = i18n.language;

    lobbyPages[path].meta.forEach((data, index) => {
      if (!checkComponentAvailability(data)) return;

      if (data.type === "game_list") {
        components.push(<GameList key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "game_list_promoted") {
        components.push(<GameListPromoted key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "game_collection") {
        components.push(<GameCollection key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "nav_bar") {
        components.push(<Search key={"search"} onBack={backHandler} popup={true} />);
      } else if (data.type === "jackpot_collection") {
        components.push(<JackpotCollection key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "content_slider") {
        components.push(<ContentSlider key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "labels") {
        components.push(<Labels key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "providers") {
        components.push(<Providers key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "breadcrumbs") {
        components.push(<Breadcrumbs key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "items_lister") {
        components.push(<ItemsLister key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "news_list") {
        components.push(<News key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "starting_soon_list") {
        components.push(<StartingSoon key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "top_live_list") {
        components.push(<TopLive key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "competition_promotion_list") {
        components.push(<CompetitionPromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "event_promotion_list") {
        components.push(<EventPromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "user_promotions_list") {
        components.push(<UserPromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "simple_promotion_list") {
        components.push(<SimplePromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "banner_component") {
        components.push(<BannerComponent key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "egt_jackpot") {
        components.push(<EgtJackpot key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "egt_jackpot_details") {
        components.push(<EgtJackpotDetails key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "happy_hours") {
        components.push(<HappyHours key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "claim_bonus_list") {
        components.push(<ClaimBonus key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "use_free_spins_list") {
        components.push(<UseFreeSpins key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "wheel_status") {
        components.push(<WheelStatus key={index} index={index} data={data} lang={lang} />);
      }
    });

    pageStyle = styleToObj(lobbyPages[path].settings && lobbyPages[path].settings.style ? lobbyPages[path].settings.style : empty);
  }

  const fullScreen = isDesktop ? false : true;

  return (
    <Dialog
      fullScreen={fullScreen}
      disableEnforceFocus
      open={open}
      TransitionComponent={Transition}
      maxWidth={!fullScreen ? "sm" : false}
      className={`lobby-search-popup ${isDesktop ? "minHeight" : `h100vh`} mobile-league betsModal`}
    >
      <div className="lobby-root">
        <div className="lobby-component-wrapper" style={pageStyle}>
          <div className="search-full-page" style={pageStyle}>
            {components}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    lobbyPages: state.config.lobbyPages,
    gameCollections: state.games.gameCollections
  }
};


export default connect(mapStateToProps)(withTranslation()(withRouter(SearchDialog)));
