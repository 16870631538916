/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 11/12/2018
 * Time: 13:08
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextInput from "../../Register/Form/TextInput/TextInput";
import { withTranslation } from "react-i18next";
import "./Identity.scss";
import * as EmailValidator from "email-validator";
import Utils from "../../../utils/Utils";
import BackendClient from "../../../BackendClient";
import InputLoader from "../../Register/Form/InputLoader/InputLoader";
import * as actionTypes from "../../../store/actions/actionTypes";

class Identity extends Component {

    constructor(props) {
        super(props);

        this.timerId = "resetPasswordEmailAddress";
        this.infoMessage = {
            type: "info",
            text: this.props.t("Type in the field the email address associated with the account")
        };
        this.setToken();
    }

    setToken(token) {
        if (this.props.token) return;
        if (token) {
            this.token = token;
        } else {
            if (typeof this.props.match !== "undefined") {
                // let parameters = this.parseQueryString(window.location.search.replace("?",""));
                let parameters = this.props.match.params;
                if (typeof parameters.token !== "undefined") {
                    this.token = parameters.token;
                } else {
                    this.token = false;
                }
            }
        }

        this.props.setToken(this.token);
    }

    parseQueryString(query) {
        let vars = query.split("&");
        let query_string = {};
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            let key = decodeURIComponent(pair[0]);
            let value = decodeURIComponent(pair[1]);
            // If first entry with this name
            if (typeof query_string[key] === "undefined") {
                query_string[key] = decodeURIComponent(value);
                // If second entry with this name
            } else if (typeof query_string[key] === "string") {
                let arr = [query_string[key], decodeURIComponent(value)];
                query_string[key] = arr;
                // If third or later entry with this name
            } else {
                query_string[key].push(decodeURIComponent(value));
            }
        }
        return query_string;
    }

    state = {
        message: {},
        ok: false,
        nextButton: {
            cssClass: 'disabled',
            loadingClass: "hide",
        }
    };

    componentDidUpdate(prevProps, prevState) {

        if (prevState.ok !== this.state.ok) {
            this.setState({
                ...this.state,
                nextButton: {
                    ...this.state.nextButton,
                    cssClass: this.state.ok ? "" : "disabled"
                }
            })
        }

        if (prevProps.emailSent !== this.props.emailSent) {
            this.setState({
                ...this.state,
                nextButton: {
                    ...this.state.nextButton,
                    cssClass: "",
                    loadingClass: 'hide'
                }
            })
        }

        if (prevProps.error !== this.props.error && this.props.error !== false) {

            this.setState({
                ...this.state,
                message: {
                    type: 'error',
                    text: this.props.error
                },
                nextButton: {
                    ...this.state.nextButton,
                    cssClass: "",
                    loadingClass: "hide",
                }
            });
        }
    }

    componentWillMount() {
        if (this.token) {
            this.props.setStep(2);
            this.props.goToStep(2);
        }
        
        if (typeof this.props.step !== "undefined" && typeof this.props.step.goToStep !== "undefined") {
            this.props.goToStep(this.props.step);
        }
        
        this.setState({
            ...this.state,
            message: this.infoMessage
        })
    }

    triggerHandler() {

        let groupClassName = undefined;
        let message = "";
        let valid = EmailValidator.validate(this.state.fieldInput);
        let ok = this.state.ok;

        if (valid) {
            // this.serverSideValidation(this.state.fieldInput); TODO: serverSideValidation
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        } else {
            groupClassName = `${this.defaultGroupClassName} error`;
            message = this.props.t("Email invalid");
            if (ok) {
                ok = false;
            }
        }

        this.setState({
            ...this.state,
            groupClassName: groupClassName,
            message: {
                ...this.state.message,
                type: (message !== "" ? "error" : ""),
                text: message,
            },
            ok: ok,
        });
    }

    blurHandler() {
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this), 0);
    }

    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.firstName;
        } else {
            fieldInput = event.target.value.trim().split(' ').join('');
            console.log(fieldInput);
        }

        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this));

        this.setState({
            ...this.state,
            fieldInput: fieldInput,
        });
    }

    onFocusHandler() {

        this.setState({
            ...this.state,
            message: this.infoMessage,
            groupClassName: `${this.defaultGroupClassName} empty`,
        })
    }

    onClickHandler() {
        let axios = BackendClient.getInstance();
        let email = this.state.fieldInput;
        axios({
            url: 'api/register/resetPassword',
            method: 'post',
            data: {
                mail: email,
            },
        }).catch(e => {
            // console.log(`[CATCHED **NOTIFICATION RESET PASSWORD** ERROR] => ${e}`);
        });

        this.loadingButton();
        this.props.resetErrorMessage();
    }

    loadingButton() {
        this.setState({
            ...this.state,
            nextButton: {
                ...this.state.nextButton,
                cssClass: this.state.loadingClass === "" ? "" : "disabled",
                loadingClass: this.state.loadingClass === "" ? "hide" : "",
            },
            message: {},
        })
    }

    getEmailSent() {
		let message = '';
		if(this.props.emailSent){
			message = (
				<div className="SuccessMessage">
					<h2>{this.props.t('We sent you an email with the link to change password')}</h2>
				</div>
			);
		}
		return message;
    };
    
    handleKeyDown(e) {
		if (e.keyCode === 32) {
			e.preventDefault();
		}
	};

    render() {
        return (
            <div id="Identity">
                <TextInput inputType="text"
                    inputIdName={this.props.t("Email")}
                    inputValue={this.state.fieldInput}
                    inputLabel={this.props.t("Email")}
                    onKeyDown={this.handleKeyDown}
                    changeHandler={this.changeHandler.bind(this)}
                    blurHandler={this.blurHandler.bind(this)}
                    onFocus={this.onFocusHandler.bind(this)}
                    message={this.state.message} />
                {this.getEmailSent()}
                <p className="description">
                    {this.props.t("You will receive a link to the email associated with the account. You can reset your password from it.")}
                </p>
                <div className="actions">
                    <div className={this.state.nextButton.cssClass + " btn"} onClick={this.onClickHandler.bind(this)}>
                        {this.props.t("Send email")}

                        <InputLoader className={this.state.nextButton.loadingClass} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setToken: (token) => dispatch({ type: actionTypes.resetPassword.TOKEN_RECEIVED, token: token }),
        setStep: (step) => dispatch({ type: actionTypes.resetPassword.GO_TO_STEP, step: step }),
        resetErrorMessage: () => dispatch({ type: actionTypes.resetPassword.ERROR_TOKEN, error: false })
    };
};

const mapStateToProps = state => {
    return {
        emailSent: state.resetPassword.emailSent,
        step: state.resetPassword.step,
        error: state.resetPassword.error,
        token: state.resetPassword.token
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Identity));
