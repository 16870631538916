import { getBetsState, makeGetCategories, makeGetTournaments } from './betData';

import { createSelector } from 'reselect';


//const getMatchData = state => state.preMatchFilter.matchData;
const getMatchData = state => getBetsState(state).preMatchFilter.matchData;
const getQuery = state => getBetsState(state).search.queryLeague;

const emptySelection = {};

export const getSelected = (state, props) => {
  const psm = getBetsState(state).preMatchFilter;

  let ft;
  if ('filterType' in props) {
    ft = props.filterType;
  } else {
    ft = psm.filterType;
  }

  let ids;
  if ('idSport' in props) {
    ids = props.idSport;
  } else {
    ids = psm.idSport;
  }

  //console.log("psm", psm, "props", props);

  return psm.selected[ft][ids] || emptySelection;
};

export const makeGetRecommended = () =>
  createSelector([getMatchData, getQuery], (matchData, query) => {
    return [];
  });

export const makeQueryLeagues = () =>
  createSelector(
    [getMatchData, getQuery, makeGetCategories(), makeGetTournaments()],
    (matchData, query, categories, tournaments) => {
      if (query === '') {
        return matchData;
      }

      const q = query.toLowerCase();

      return Object.keys(matchData).reduce((acc, idCategory) => {
        if (typeof matchData[idCategory] === 'undefined') return acc;
        const cData = matchData[idCategory];

        const tids = Object.keys(cData).reduce((cacc, idTournament) => {
          if (typeof tournaments[idTournament] === 'undefined') return cacc;
          const tName = tournaments[idTournament].tournamentName;

          if (tName && tName.toLowerCase().indexOf(q) !== -1) {
            if (typeof matchData[idCategory] === 'undefined' || typeof matchData[idCategory][idTournament] === 'undefined') return cacc;
            cacc[idTournament] = matchData[idCategory][idTournament];
          }

          return cacc;
        }, {});

        if (Object.keys(tids).length > 0) {
          acc[idCategory] = tids;
        }

        return acc;
      }, {});
    }
  );
