const DEFAULT_EXPIRE_TIME = 172800;

class ExpiringLocalStorage {
    static set(key,value, ttl = false) {
        if (!ttl) {
            ttl = DEFAULT_EXPIRE_TIME;
        }
        localStorage.setItem(key,JSON.stringify({
            value: value,
            expire: new Date().getTime() + (parseInt(ttl,10) * 1000)
        }));
    }

    static get(key) {
        let item = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!item) {
            return null
        }

        item = JSON.parse(item);

        if (new Date().getTime() > item.expire) {
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    }
}

export default ExpiringLocalStorage