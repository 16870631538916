import moment from "moment";
import localization from "moment/locale/ro.js";

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export const styleToObj = (style) => {
  const ret = {};

  if (!style) return ret;
  if (typeof style !== "string") return ret;

  const parts = style.split(";");

  parts.forEach(p => {
    const line = p.trim();

    if (line && line.indexOf(":") > -1) {
      let [key, value] = line.split(":", 2);
      key = toCamel(key.trim());
      value = value.trim();
      ret[key] = value;
    }
  });

  return ret;
};

const months = {
  "1": "Ian",
  "2": "Feb",
  "3": "Mart",
  "4": "Apr",
  "5": "Mai",
  "6": "Iun",
  "7": "Iul",
  "8": "Aug",
  "9": "Sept",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec"
};

export const formatDateWithTime = (date, lang) => {
  let refDate = moment();
  let tomorrow = moment().add(1, "days");
  let dt = moment(date);
  if (lang === "ro") {
    dt = moment(date).locale("ro", localization);
  }

  if (refDate.isSame(dt, "day")) {
    return `${lang === "ro" ? "Azi" : "Today"} ${dt.format("HH:mm")}`;
  }
  if (tomorrow.isSame(dt, "day")) {
    return `${lang === "ro" ? "Maine" : "Tomorrow"} ${dt.format("HH:mm")}`;
  }

  if (lang === "ro") {
    return `${dt.format("ddd, D ")}${months[dt.format("M")]
      .toUpperCase()
      .replace(".", "")} ${dt.format("HH:mm")}`;
  }
  return `${dt.format("ddd, ")}${dt
    .format("MMM")
    .toUpperCase()
    .replace(".", "")}${dt.format(" D")} ${dt.format("HH:mm")}`;
};

export const chooseTextLanguage = (txt, lang, def) => {
  if (typeof txt === "string") {
    return txt;
  };
  try {
    // check if we have translation for the selected language and return it
    if (typeof txt[lang] !== "undefined" && txt[lang]) {
      return txt[lang];
    }

    // choose first available language and return it
    const values = Object.values(txt);
    values.sort((a, b) => (b.localeCompare(a)))
    return values.length ? values[0] : def ? def : '';
  } catch (err) {
    return def ? def : '';
  }
};

let host = process.env.CFW.betsBannersImagesUrl;
if (host.substr(-1) !== "/") host += "/";

export const choseImage = (image, type) => {
  if (image && typeof image[type] !== "undefined") {
    return host + image[type];
  }
  return "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
}

export const backendImage = (image, defaultImage) => {
  if (image) {
    return host + image;
  }
  if (defaultImage) return defaultImage;

  return "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
}

/*
cb - callback with progress value between 0 and 1
duration - milliseconds
*/
export const animateValue = (cb, duration) => {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);

    const shouldCancel = cb(progress); //cb(Math.floor(progress * (end - start) + start));

    if (shouldCancel) {
      return;
    }

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

export const copyInputText = (input) => {
  var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

  if (isiOSDevice) {
    let editable = false;
    if (typeof input.contentEditable !== "undefined") {
      editable = input.contentEditable;
    }
    let readOnly = input.readOnly;

    if (typeof input.contentEditable !== "undefined") {
      input.contentEditable = true;
    }
    input.readOnly = false;

    var range = document.createRange();
    range.selectNodeContents(input);

    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    input.setSelectionRange(0, 999999);
    if (typeof input.contentEditable !== "undefined") {
      input.contentEditable = editable;
    }
    input.readOnly = readOnly;

  } else {
    input.select();
  }

  document.execCommand('copy');
}