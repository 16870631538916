import { combineReducers } from "redux";

import templateReducer from "./template";
import configReducer from "./config";
import playerReducer from "./player";
import winCoinsSystemsReducer from "./wincoins";
import scoreReducer from "./score";
import walletReducer from "./wallet";
import historyReducer from "./history";
import teamsReducer from "./teams";

export const winnerFunRootReducer = combineReducers({
  template: templateReducer,
  config: configReducer,
  player: playerReducer,
  winCoinsSystems: winCoinsSystemsReducer,
  score: scoreReducer,
  wallet: walletReducer,
  history: historyReducer,
  teams: teamsReducer,
});

export const rootReducer = combineReducers({
  winnerFun: winnerFunRootReducer
});

export default rootReducer;
