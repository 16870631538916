import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

import { getLottoState } from "../../store/selectors/lottoData";
import { ticketOpenedListRequest } from "../../store/actions/tickets";

import Ticket from "../../components/Ticket/Ticket";
import TicketNsoft from "../../components/TicketNsoft/Ticket";

const useStyles = makeStyles({
  root: {
    padding: "15px 7px 0"
  },
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  }
});

const OpenedModule = ({ opened, inProgress, index, ticketOpenedListRequest, theme, t }) => {
  const classes = useStyles();

  const currentTheme = theme && theme === "nsoft" ? "nsoft" : "bets";

  const sorted = opened ? [...opened] : [];
  sorted.sort((a, b) => {
    return a.event_time - b.event_time;
  })

  let openedTickets = null;
  if (currentTheme === "nsoft") {
    if (sorted.length > 0) {
      openedTickets = sorted.map((ticket, i) => <TicketNsoft key={ticket.id} ticket={ticket} />)
    }
  } else {
    if (sorted.length > 0) {
      openedTickets = sorted.map((ticket, i) => <Ticket key={ticket.id} ticket={ticket} />)
    }
  }

  React.useEffect(() => {
    ticketOpenedListRequest();
  }, []); // eslint-disable-line

  return (
    <div>
      {inProgress && (
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      )}
      {!inProgress && sorted.length === 0 && (
        <div className={classes.empty}>{t("There are no opened tickets")}</div>
      )}
      {openedTickets}
    </div>
  );
};

const mapStateToProps = state => {
  const lst = getLottoState(state);

  return {
    opened: lst.tickets.ticketsOpened,
    inProgress: lst.tickets.requestInProgress.opened
  };
};

const actionCreators = {
  ticketOpenedListRequest
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(OpenedModule));
