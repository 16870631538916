import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import './empty.scss';
const bgStyle = {
  backgroundColor: '#D1E1E9',
  margin: '0px 0px 6px',
  borderRadius: '5px'
};
const cardStyle = {
  backgroundColor: '#fff',
  padding: '10px 12px 5px 18px',
  margin: '5px 0 5px',
  borderRadius: '7px'
};
const elemStyle = {
  backgroundColor: '#ccc',
  marginBottom: '7px'
};

const empty = [
  <div key="pre-match-highlights-placeholder">
    <Skeleton variant="rect" height={45} style={bgStyle} />
    <div style={cardStyle}>
      <Skeleton variant="text" width={301} height={15} style={elemStyle} />
      <Skeleton variant="rect" width={210} height={24} style={elemStyle} />
      <Skeleton variant="text" width={30} height={13} style={elemStyle} />
      <Skeleton variant="rect" width={301} height={35} style={elemStyle} />
    </div>
    <div style={cardStyle}>
      <Skeleton variant="text" width={301} height={15} style={elemStyle} />
      <Skeleton variant="rect" width={210} height={24} style={elemStyle} />
      <Skeleton variant="text" width={30} height={13} style={elemStyle} />
      <Skeleton variant="rect" width={301} height={35} style={elemStyle} />
    </div>
  </div>
];

const eds = {
  el1: { width: '30px', height: '10px' },
  el2: { width: '40px', height: '15px' },
  el3: { width: '120px', height: '20px' },
  el4: { width: '90px', height: '20px' },
  el5: { width: '40px', height: '15px' },
  el6: { width: '95%', height: '48px' },
  el7: { width: '15px', height: '15px' },
  el8: { width: '15px', height: '15px' },
};

const emptyDesktop = (
  <div className="emptyDesktop">
    <div className="first">
      <div className="text pulse" style={eds.el1} />
      <div className="text pulse" style={eds.el2} />
    </div>
    <div className="second">
      <div className="text pulse" style={eds.el3} />
      <div className="text pulse" style={eds.el4} />
    </div>
    <div className="third">
      <div className="text pulse" style={eds.el5} />
      <div className="bets">
        <div className="rect bts pulse" style={eds.el6} />
        <div className="rect bts pulse" style={eds.el6} />
        <div className="rect bts pulse" style={eds.el6} />
        <div className="rect bts pulse" style={eds.el6} />
      </div>
    </div>
    <div className="fourth">
      <div className="text pulse" style={eds.el7} />
      <div className="text pulse" style={eds.el8} />
    </div>
  </div>
);

const ems = {
  el1: { width: '30px', height: '10px' },
  el2: { width: '80px', height: '20px' },
  el3: { width: '60px', height: '20px' },
  el4: { width: '30px', height: '15px' },
  el5: { height: '60px' },
};

const emptyMobile = (
  <div className="emptyMobile">
    <div className="first">
      <div className="text pulse" style={ems.el1} />
      <div className="text pulse" style={ems.el2} />
      <div className="text pulse" style={ems.el3} />
    </div>
    <div className="second">
      <div className="text pulse" style={ems.el4} />
      <div className="bets">
        <div className="text bts pulse" style={ems.el5} />
        <div className="text bts pulse" style={ems.el5} />
        <div className="text bts pulse" style={ems.el5} />
      </div>
    </div>
  </div>
);

export default empty;
export { empty, emptyDesktop, emptyMobile };