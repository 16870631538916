import { depositTicketConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  serial: null,
  pin: null,
  type: null,
  amount: 0,
  limits: null
};

const wheelReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case depositTicketConstants.SET:
        draft.serial = action.data.serial;
        draft.pin = action.data.pin;
        draft.type = action.data.type;
        draft.amount = action.data.amount;
        draft.limits = action.data.limits;
        break;
      default:
      /* noop */
    }
  });


export default wheelReducer;