import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withTranslation } from "react-i18next";

import { ReactComponent as Calendar } from "./assets/calendar.svg";
import { formatValue, formatTotalOddsValue } from "../../utils/formatters";
import MultiplierIcon from "./assets/multiplier-icon.png";

const useStyles = makeStyles({
	ticketDetails: {
		//margin: "7px 11px",
		//flex: "1 0 auto",
		padding: "10px 10px",
		backgroundColor: "#B7CDD8",
		borderRadius: 12,
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-between",
		maxWidth: "180px",

	},
	max3: {
		maxWidth: "250px"
	},
	ticketDetailsItem: {
		minWidth: "70px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		alignContent: "center",
		whiteSpace: "nowrap",
	},
	ticketDetailsTitle: {
		fontSize: 9,
		fontWeight: 600,
		margin: "0 auto",
		color: "#586682",
		whiteSpace: "nowrap",
	},
	ticketDetailsContent: {
		position: "relative",
		fontSize: 11,
		fontWeight: 700,
		margin: "0 auto",
		color: "#17214D",
		display: "flex",
		alignItems: "center",
		flexWrap: "nowrap"
	},
	root: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-between"
	},
	calendar: {
		padding: "11px 22px"
	},
	calendarIcon: {
		display: "inline-block",
		width: "18px",
		height: "18px",
		verticalAlign: "middle",
		marginRight: "7px"
	},
	muted: {
		opacity: .5,
		fontWeight: "normal"
	},
	small: {
		fontWeight: "normal",
		fontSize: "10px",
		display: "inline-block",
		paddingRight: "3px",
	},
	icon: {
		position: "relative",
		display: "inline-block",
		height: "15px",
		top: "4px",
	},
	py: {
		padding: "11px 5px",
		maxWidth: "250px"
	},
	minW: {
		minWidth: "90px"
	},
	freeBetName: {
		display: "inline-block",
		maxWidth: "80px",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	dinb: {
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center"
	}
});

const months = {
	"1": "Ian",
	"2": "Feb",
	"3": "Mart",
	"4": "Apr",
	"5": "Mai",
	"6": "Iun",
	"7": "Iul",
	"8": "Aug",
	"9": "Sept",
	"10": "Oct",
	"11": "Nov",
	"12": "Dec"
};

const DetailsVariant = ({ ticket, winnerFun, t }) => {
	const classes = useStyles();

	const minDt = moment(ticket.minTime);
	const maxDt = moment(ticket.maxTime);

	let sd = minDt.format("D") + " " + months[minDt.format("M")];
	let ed = "";

	if (minDt.format("YYYY-MM-DD") !== maxDt.format("YYYY-MM-DD")) {
		ed = ` - ${maxDt.format("D")} ${months[maxDt.format("M")]}`;
	}

	let detailsContent = null;
	if (ticket.bonusEligibles && ticket.bonusEligibles.length !== 0) {
		detailsContent = (<div className={classes.ticketDetailsContent}>
			<div className={classes.muted}>{t("Bonus")}:</div>
			<div>{ticket.bonusEligibles.join(" + ")}</div>
		</div>)
	} else {
		detailsContent = (<div className={classes.ticketDetailsContent}>
			<Calendar className={classes.calendarIcon} /> {sd}
			{ed}
		</div>);
	}

	//console.log("ticket.type", ticket.type);
	let hasBoost = false;
	let boostValue = 0;
	if (winnerFun && ticket.eval_res && ticket.eval_res.event_balance_multiplier > 1 && (ticket.status === "OPEN" || ticket.status === "WON")) {
		hasBoost = true;
		boostValue = ticket.winning - ticket.originalWinning;
	}

	let stake_is_free_bet = false;
	let free_bet_name = "";

	if (ticket && ticket.clientVal && typeof ticket.clientVal.free_bet_code !== "undefined") {
		stake_is_free_bet = true;
		//free_bet_name = ticket.clientVal.free_bet_name ? ticket.clientVal.free_bet_name : "FREE BET";
		free_bet_name = "FREE BET";
	}

	return (
		<div className={`${classes.root}`}>
			<div className={classes.calendar}>
				<div>
					{detailsContent}
				</div>
			</div>
			<div className={`${classes.ticketDetails} ${(ticket.hasBonus && ticket.maxBonusPercentage !== 0) || stake_is_free_bet ? classes.max3 : ''} ${hasBoost ? classes.py : ''}`}>
				{ticket.type === "SIMPLU" && <div className={classes.ticketDetailsItem}>
					<div className={classes.ticketDetailsTitle}>{t("Total odds")}</div>
					<div className={classes.ticketDetailsContent}>
						{formatTotalOddsValue(ticket.totalOdd)}
					</div>
				</div>}
				<div className={classes.ticketDetailsItem}>
					<div className={classes.ticketDetailsTitle}>{stake_is_free_bet && free_bet_name ? free_bet_name : t("Stake")}</div>
					<div className={classes.ticketDetailsContent}>{formatValue(ticket.stake, true)} {winnerFun ? "W" : "Lei"}</div>
				</div>
				{ticket.hasBonus && ticket.maxBonusPercentage !== 0 && <div className={classes.ticketDetailsItem}>
					<div className={classes.ticketDetailsTitle}>{t("Bonus")} {ticket.maxBonusPercentage}%</div>
					<div className={classes.ticketDetailsContent}>+{formatValue(ticket.maxBonus)} {winnerFun ? "W" : "Lei"}</div>
				</div>}
				{hasBoost && <div className={`${classes.ticketDetailsItem} ${classes.minW}`}>
					<div className={classes.ticketDetailsTitle}>{t("BOOST")}</div>
					<div className={classes.ticketDetailsContent}>
						<img src={MultiplierIcon} alt="" className={classes.icon} />  +{formatValue(boostValue)} {winnerFun ? "W" : "Lei"}
					</div>
				</div>}
			</div>
		</div>
	);
};

export default withTranslation()(DetailsVariant);
