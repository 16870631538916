import { put, takeLatest } from 'redux-saga/effects';
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";
import * as actions from "../actions";
import {paymentCheckout} from "../actions/actionTypes";
import RequestManager from "../../utils/RequestManager";

export function* requestOktoCreatePaymentCodeSaga(action) {
    const axios = yield RequestManager.getAxiosWithToken();
    try {

        let data = {
            amount: action.amount,

            currencyId: 1,
        };
        if (action.bonusId) {
            data.bonusId = action.bonusId;
        }
        let pac = PlayerAbuseChecker.getInfo();
        data = {
            ...pac,
            ...data
        };
        const platformType = window.config.cordova ? window.config.native_platform === "ios" ? 3 : 4 : window.config.platformType;

        data.platformType = platformType;

        const response = yield axios({
            url: '/api/pay-checkout/okto-create-payment-code',
            method: 'post',
            data: data
        });
        if (!response) {
            throw new Error(`[ERROR] Request Okto Create Payment Code response is empty!`);
        }
        if (!response.hasOwnProperty('result')) {

            throw new Error(`[ERROR] Request Okto Create Payment Code response has no 'result' property`);
        }
        console.table(response.result)

        yield put(actions.oktoReceivedPaymentCode(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* oktoGetCustomerSaga(action) {
    const axios = yield RequestManager.getAxiosWithToken();
    try {

        const response = yield axios({
            url: '/api/pay-checkout/okto-get-customer',
            method: 'GET',
        });

        if (!response) {
            throw new Error(`[ERROR] Request Okto GET Customer response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request Okto GET CUSTOMER response has no 'result' property`);
        }

        yield put(actions.oktoReceivedCustomer(response.result));
    } catch (error) {
        console.log(error);
        yield put(actions.oktoReceivedCustomer({
            "active_auto_exclusion": null,
            "amount_can_deposit": 4500,
            "account_verified": 1,
            "okto_terms_accepted": 1,
            "okto_terms_modified": 1697447934,
            "okto_customer_date": 1697447934
        }));
        return;
    }
}
export default function* watchPayments() {
    yield takeLatest(paymentCheckout.OKTO_CREATE_PAYMENT_CODE,requestOktoCreatePaymentCodeSaga);
    yield takeLatest(paymentCheckout.OKTO_GET_CUSTOMER,oktoGetCustomerSaga);
}