import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import "./../SpentTime/SpentTime.scss";
import * as stateActions from "../../../store/actions";
import ResponseErrorMessages from "./../../../store/errors/ResponseErrorMessages";
import Moment from "react-moment";
import {withRouter} from "react-router-dom";

class AccountProblem extends Component {
    close() {
        this.props.closeModal();
    }

    supportHandler(event) {
        this.props.closeModal();
        this.props.history.push('/customer-support/about-us')
    }

    getMessageAdditions(code) {
        let htmlMessage = null;

        if (23 === code) {
            if (this.props.authentication.accountProblemMessage) {
                let exclusionInterval = this.props.authentication.accountProblemMessage.split("-");
                htmlMessage = (
                    <Fragment>
                        <p>{this.props.t("Self exclusion period")} {this.props.t("from")} <Moment format={"DD/MM/YYYY"} unix>{exclusionInterval[0]}</Moment> {this.props.t("to")} <Moment format={"DD/MM/YYYY"} unix>{exclusionInterval[1]}</Moment>.</p>
                        <p>{this.props.t("If you want to cancel this action, please contact support.")}</p>
                    </Fragment>
                );
            }
        }

        return htmlMessage;
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.props.authentication.accountProblemModal} className="SpentTimeModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                    <ModalBody className={"SpentTimeModalBody"}>
                        <div className={"messageSupport"}>
                            <p>{ResponseErrorMessages.get(this.props.authentication.accountProblemCode)}</p>
                            {this.getMessageAdditions(this.props.authentication.accountProblemCode)}
                        </div>
                        <div className={"buttonSupport text-center"}>
                            <Button color="success" className={"verifyButton"} onClick={this.supportHandler.bind(this)}>{this.props.t("Open support")}</Button>
                            <Button color="danger" onClick={this.close.bind(this)}>{this.props.t("Close")}</Button>
                        </div>
                    </ModalBody>
                </Modal>
                {/*<a href={"#"} data-reamaze-lightbox="default" ref={a => this.aElement = a}></a>*/}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(stateActions.closeAccountProblemModal()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountProblem)));