import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { withCommonStyles } from "../../styles";
import eventStyles from "./styles";
import { formatDateWithTime } from "../../utils/formatters";
import Favorite from "../../elements/Favorite";
import { getBetsState, makeGetStateMatch } from "../../store/selectors/betData";
import { chooseTextLanguage } from "../../utils";
import { activeWinnerFunCampaigns } from "../../utils/winnerFunUtils";
import { evaluateMatch } from "../../utils/winnerFunEvaluation";
import MultiplierIcon from "../Tickets/assets/multiplier-icon.png";

const useStyles = makeStyles({
  root: {
    margin: "4px 0 6px",
    cursor: "pointer",
    height: "20px",
    "&>div": {
      height: "100%"
    }
  },
  newTime: {
    fontSize: "9px",
    color: "#7C88A0",
  },
  fixed: {
    display: "inline-block",
    textTransform: "uppercase",
    fontSize: "9px",
    fontWeight: "600",
    marginLeft: "5px",
    backgroundColor: "#B7CDD8",
    padding: "2px 8px",
    color: "#fff",
    borderRadius: "5px"
  },
  winFunIcon: {
    height: "18px",
    display: "inline-block",
    width: "auto",
    marginRight: "3px",
    position: "relative",
    top: "1px"
  }
});

const Prematch = props => {
  const classes = withCommonStyles(useStyles, eventStyles);

  const {
    match,
    fixedOdd,
    i18n,
    dark,
    isWinnerFun,
    winnerFunCampaigns,
    t
  } = props;

  if (!match) return null;

  let winnerFunTitle = "";
  let winnerFunEvent = false;
  if (isWinnerFun) {
    const winnerFunData = activeWinnerFunCampaigns(winnerFunCampaigns);
    if (winnerFunData && winnerFunData.event) {
      if (winnerFunData.event.filters && winnerFunData.event.filters.prematch) {
        const fs = winnerFunData.event.filters.prematch;
        winnerFunTitle = chooseTextLanguage(winnerFunData.event.name.text, i18n.language);
        winnerFunEvent = evaluateMatch(fs, match);
      }
    }
  }

  const lang = i18n.language;

  return (
    <div className={classes.root}>
      <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
        <div className="d-flex flex-row flex-nowrap align-items-center">
          {winnerFunEvent && <img src={MultiplierIcon} alt="" className={classes.winFunIcon} title={winnerFunTitle} />}
          {!!match.matchDateTime === true && (
            <div className={classes.newTime}>
              {formatDateWithTime(parseInt(match.matchDateTime, 10), lang)}
            </div>
          )}
        </div>
        <Favorite
          justSpecial={true}
          mType={match.mType}
          dark={dark}
          idMatch={match.idMatch}
          winnerPlus={match.winnerPlus}
          winnerAdv={match.winnerAdv}
          winnerSpecial={match.winnerSpecial}
        />
        {fixedOdd && fixedOdd.banker && <div className={classes.fixed}>{t("fixed")}</div>}
      </div>
    </div>
  );
};

Prematch.propTypes = {
  refDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Prematch.defaultProps = {
  refDate: moment().valueOf()
};

const getStateMatch = makeGetStateMatch();

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    match: getStateMatch(state, props),
    isWinnerFun: bst.app.isWinnerFun,
    winnerFunCampaigns: bst.config.winnerFunCampaigns
  };
};


export default withTranslation()(connect(mapStateToProps)(Prematch));
