import * as actionTypes from '../actions/actionTypes';
import { setLanguage } from "../../bets/utils/nsoft";

const initialState = {
    menu: {},
    hideMenu: false,
    hideLeftMenu: false,
    page: {
        code: 'home'
    },
    subpage: {
        code: ''
    },
    error: null,
    loading: false,
    loadStarted: false,
    pageHeight: 600,
    scrollToTop: true,
    loadedContent: false,
    splash: true,
    splashClosed: false,
    player: {
        id: null,
        email: null,
        name: null,
    },
    language: false,
    lastPageType: "",
    showCookiesInfo: false,
    notAllowed: false,
    promotions: [],
    eligibleBonuses: [],
    availableBonuses: [],
    retailBonuses: [],
    preEuroTickets: 0,
    wincoins: 0,
    lastProductId: false,
    bonusClaimError: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.application.PAGE:
            let item = action.item;
            let subPage = {};
            let submenus = [];
            if (item.hasOwnProperty('submenus')) {
                submenus = item.submenus;
            }
            if (submenus.length > 0) {
                subPage = submenus[0];
            }

            return {
                ...state,
                page: action.item,
                subpage: subPage,
                error: null,
                loading: true,
            };

        case actionTypes.application.SUBPAGE:
            return {
                ...state,
                subpage: action.item,
                error: null,
            };

        case actionTypes.application.MENU:
            // console.log('MENU ACTION received in REDUCER!!!!');

            if (window.config && window.config.virtualsEnabled === "1" && action.items.virtuale) {
                action.items.virtuale.visible = "1";
                action.items.virtuale.available = "1";
                action.items.virtuale.requestPath = null;
            }

            const items = {};
            action.items && Object.keys(action.items).forEach(key => {
                if (key !== "cazino") {
                    items[key] = action.items[key];
                } else {
                    const value = action.items[key];
                    let newKey = "cazino";

                    if (window.config.lobbyEnabled === "1") {
                        if (window.config.rootPaths && window.config.rootPaths.casino) {
                            newKey = window.config.rootPaths.casino;
                            value.code = newKey;
                            value.requestPath = "";
                        }
                        //value.requestPath = "";
                    }

                    items[newKey] = value;
                }
            });

            return {
                ...state,
                menu: items,
                error: null,
            };
        case actionTypes.menu.HIDE:
            return {
                ...state,
                hideMenu: true,
            };
        case actionTypes.menu.SHOW:
            return {
                ...state,
                hideMenu: false,
            };
        case actionTypes.menu.HIDE_LEFT_MENU:
            return {
                ...state,
                hideLeftMenu: true
            };
        case actionTypes.menu.SHOW_LEFT_MENU:
            return {
                ...state,
                hideLeftMenu: false
            };
        case actionTypes.application.CONTENT_ERROR:
            return {
                ...state,
                error: {
                    title: action.title,
                    message: action.message,
                },
                loadStarted: false,
                loading: false,
                loadedContent: true,
            };

        case actionTypes.application.START_LOADING:
            return {
                ...state,
                loadStarted: true,
                loadedContent: false,
            };

        case actionTypes.application.CONTENT_LOADED:
            return {
                ...state,
                loadStarted: false,
                loading: false,
                loadedContent: true,
            };

        case actionTypes.application.NEW_PAGE_HEIGHT:
            return {
                ...state,
                pageHeight: action.height,
                scrollToTop: true,
            };

        case actionTypes.application.CLOSE_SPLASHSCREEN:
            return {
                ...state,
                splash: false,
            };

        case actionTypes.application.SPLASH_CLOSED:
            return {
                ...state,
                splashClosed: true,
            };
        case actionTypes.application.BONUS_CLAIMED:
            return {
                ...state,
                bonusClaimError: action.data.detail ? action.data.detail : (action.data.ResponseCode === 6 ? { canRetry: false } : !!action.data.ResponseCode)
            };
        case actionTypes.application.USER_INFO_RECEIVED:
            let player = { ...state.player };

            if (typeof action.user !== "undefined" && action.user.name) {
                player = { ...state.player, ...action.user }
            } else {
                player.id = false;
                if (typeof action.user !== "undefined" && typeof action.user.chat !== "undefined") {
                    player.chat = action.user.chat;
                }
            }

            return {
                ...state,
                player: player
            };
        case actionTypes.application.CHANGE_LANGUAGE:
            setLanguage(action.language);

            return {
                ...state,
                language: action.language
            };
        case actionTypes.application.REQUESTED_PAGE_TYPE:
            return {
                ...state,
                lastPageType: action.pageType,
            };
        case actionTypes.application.CLEAR_PAGE:
            return {
                ...state,
                page: { code: '' },
                subpage: { code: '' },
            };
        case actionTypes.application.TOGGLE_COOKIES_INFO:
            return {
                ...state,
                showCookiesInfo: !state.showCookiesInfo,
            };
        case actionTypes.application.NOT_ALLOWED:
            return {
                ...state,
                notAllowed: true,
                hideLeftMenu: true,
                hideMenu: true,
            };
        case actionTypes.application.SET_PROMOTIONS:

            return {
                ...state,
                promotions: action.promotions ? action.promotions : []
            };
        case actionTypes.application.SET_ELIGIBLE_BONUSES:

            return {
                ...state,
                eligibleBonuses: action.eligibleBonuses ? action.eligibleBonuses : []
            };
        case actionTypes.application.SET_AVAILABLE_BONUSES:

            if (typeof action.bonuses.ResponseCode !== 'undefined') {
                if (state.availableBonuses === []) {
                    return state;
                } else {
                    return {
                        ...state,
                        availableBonuses: []
                    }
                }
            }

            return {
                ...state,
                availableBonuses: action.bonuses ? action.bonuses : []
            };
        case actionTypes.bonuses.SET:

            if (typeof action.bonuses.ResponseCode !== 'undefined') {
                if (state.retailBonuses === []) {
                    return state;
                } else {
                    return {
                        ...state,
                        retailBonuses: []
                    }
                }
            }

            return {
                ...state,
                retailBonuses: action.bonuses ? action.bonuses : []
            };
        case actionTypes.application.RESET_BONUS_CLAIM_STATUS:
            return {
                ...state,
                bonusClaimError: null
            };
        case actionTypes.application.SET_PREEURO_TICKETS:
            return {
                ...state,
                preEuroTickets: action.preEuroTickets
            }
        case actionTypes.application.SET_WINCOINS:
            return {
                ...state,
                wincoins: action.coins
            };
        case actionTypes.application.SET_LAST_PRODUCT_ID:
            return {
                ...state,
                lastProductId: action.productId
            };
        default:
            return state;
    }
};

export default reducer;
