import React from 'react';
import { connect } from "react-redux";
import { makeGetEventByType, makeGetShowInfoByType } from "../selectors/happy-hour";

import InfoOnlineSlots from "../branding/online-slots/info";
import InfoBetsPrematch from "../branding/bets-prematch/info";
import InfoBetsLive from "../branding/bets-live/info";

const Info = (props) => {
  const { event_type, data, showInfo } = props;

  if (!showInfo || !data) return null;
  const happyHourBetsEnabled =
    window.config
      && typeof window.config.happyHourBetsEnabled !== "undefined"
      && window.config.happyHourBetsEnabled === "1" ? true : false;


  if (event_type === "bets-prematch") {
    if (!happyHourBetsEnabled) return null;
    return <InfoBetsPrematch data={data} showInfo={showInfo} />;
  }

  if (event_type === "bets-live") {
    if (!happyHourBetsEnabled) return null;
    return <InfoBetsLive data={data} showInfo={showInfo} />;
  }

  return <InfoOnlineSlots data={data} showInfo={showInfo} />;
}

const mapStateToProps = () => {
  const getEventByType = makeGetEventByType();
  const getShowInfoByType = makeGetShowInfoByType();

  return (state, props) => ({
    data: getEventByType(state, props),
    showInfo: getShowInfoByType(state, props),
  });
};

export default connect(mapStateToProps)(Info);
