import * as actionTypes from './actionTypes';

export const freeSpinsLoaded = (freeSpins) => ({
    type: actionTypes.freeSpins.FREE_SPINS_LOADED,
    freeSpins: freeSpins
});
export const freeSpinsFetch = () => ({
    type: actionTypes.freeSpins.FREE_SPINS_FETCH
});

export const freeSpinsRemove = (bonusCode) => ({
    type: actionTypes.freeSpins.FREE_SPIN_REMOVE,
    code: bonusCode
});

export const freeSpinsFetchHistory = (page) => ({
    type: actionTypes.freeSpins.FREE_SPINS_FETCH_HISTORY,
    page: page
});

export const freeSpinsLoadedHistory = (data) => ({
    type: actionTypes.freeSpins.FREE_SPINS_SET_HISTORY,
    items: data.items,
    page: data.page,
});