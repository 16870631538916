import { getLiveMatches, getPrematchMatches } from "../../../bets/store/selectors/betData";
import { createSelector } from "reselect";

const selectorsCache = {};

const getMatches = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  const res = [];
  source.forEach(m => res.push(m.idMatch));
  return res;
};

selectorsCache['makeGetLiveMatchState'] = createSelector(
  [
    getLiveMatches,
    getMatches,
  ],
  (liveMatches, matches) => {
    const status = {};

    matches.forEach(m => {
      if (typeof liveMatches[m] !== "undefined") {
        status[m] = true;
      }
    });

    return status;
  }
);
export const makeGetLiveMatchState = () => selectorsCache['makeGetLiveMatchState'];

selectorsCache['makeGetPrematchMatchState'] = createSelector(
  [
    getPrematchMatches,
    getMatches,
  ],
  (prematchMatches, matches) => {
    const status = {};

    matches.forEach(m => {
      if (typeof prematchMatches[m] !== "undefined") {
        status[m] = true;
      }
    });

    return status;
  }
);
export const makeGetPrematchMatchState = () => selectorsCache['makeGetPrematchMatchState'];
