import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";

import { expiredLottoEvents } from "../../store/actions/lotto-events";

const useStyles = makeStyles({
  root: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#192640",
    borderRadius: "11px",
    backgroundColor: "#DFE7EF",
    padding: "6px 12px",
    marginBottom: "5px",
    textTransform: "uppercase",
    marginRight: "2px",
    "&:last-child": {
      marginRight: "0"
    },
    height: "31px",
    cursor: "pointer",
  },
  selectedTime: {
    backgroundColor: "#06D2BD",
    color: "#fff",
    boxShadow: "0px 2px 4px rgba(0,0,0,.31)"
  },
  noInteraction: {
    pointerEvents: "none",
    opacity: ".6",
    fontSize: "10px",
    padding: "8px 7px 0",
  }
});

const EventSelector = (props) => {
  const classes = useStyles();
  const { id, date, onClick, isSelected, expiredEvents, t } = props;

  const dt = moment(date);
  const timeout = dt.diff(moment());

  const [expired, setExpired] = React.useState(timeout < 0 ? true : false);

  React.useEffect(() => {
    const dt = moment(date);
    const timeout = dt.diff(moment());

    let timeoutID = 0;

    if (timeout >= 0) {
      timeoutID = setTimeout(() => {
        setExpired(true);
        expiredEvents();
        onClick(id, false, true);
      }, timeout);
    }

    return () => {
      clearTimeout(timeoutID);
    }
  }, [date, onClick, id, expiredEvents]);

  const toggleSelect = () => {
    onClick(id, !isSelected);
  };

  let cls = classes.root;
  if (isSelected && !expired) {
    cls += ` ${classes.selectedTime}`;
  }
  if (expired) {
    cls += ` ${classes.noInteraction}`;
  }

  return (
    <div className={cls} onClick={toggleSelect}>{expired ? t("BLOCKED") : dt.format("HH:mm")}</div>
  );
}

const actions = {
  expiredEvents: expiredLottoEvents
};

export default connect(null, actions)(withTranslation()(EventSelector));