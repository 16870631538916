import React, { Component } from 'react';
import './ColumnMessage.css';

class ColumnMessage extends Component {
    render() {
        let messages = [];
        // check if we have an array and push all the errors in messages
        if (typeof this.props.msg[0] === "object") {
            for(let i = 0; i < this.props.msg.length; i++) {
                messages.push(
                    <p className={this.props.msg[i].type} key={`${this.props.msg.text}_${i}`}>{this.props.msg[i].text}</p>
                )
            }
        } else {
            // check if we have a text message and push it to messages
            // class will be undefined, if type is not present
            if (typeof this.props.msg.text !== "undefined") {
                messages.push(
                    <p className={this.props.msg.type} key={`${this.props.msg.text}_0`}>{this.props.msg.text}</p>
                );
            }
        }

        return (
            <div className="ColumnMessage col text-left">
                {messages}
            </div>
        );
    }
}

export default ColumnMessage;