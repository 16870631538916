import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    loadingAccessToken: false,
    loadingCardToken: false,
    access_token: null,
    amount: null,
    iframeLink: null,
    bonusError: false
};

const paymentCheckoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.paymentCheckout.REQUEST_PAYMENT_LINK:
            return {
                ...state,
                amount: action.amount,
                loading: true,

                bonusError: false
            };
        case actionTypes.paymentCheckout.RECEIVED_PAYMENT_LINK:
            return {
                ...state,
                iframeLink: action.response.link,
                loading: false,
            };
        case actionTypes.paymentCheckout.REQUEST_VIVA_TOKEN:
            return {
                ...state,
                loadingAccessToken: true,
                bonusError: false
            };
        case actionTypes.paymentCheckout.RECEIVED_VIVA_TOKEN:
            return {
                ...state,
                loadingAccessToken: false,
                access_token: action.data,
            };
        case actionTypes.paymentCheckout.REQUEST_VIVA_CARD_TOKEN:
            return {
                ...state,
                loadingCardToken: true,
                bonusError: false
            };
        case actionTypes.paymentCheckout.RECEIVED_VIVA_CARD_TOKEN:
            let cardData = null;
            if(action.data && action.data.result){
                cardData = action.data.result;
            }
            return {
                ...state,
                loadingCardToken: false,
                cardData: cardData
            };
        case actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.paymentCheckout.SEND_VIVA_CHARGE_TOKEN:
            let iframeLink = null;
            if(action.data && action.data.redirectToACSForm){
                iframeLink = action.data.redirectToACSForm;
            }
            return {
                ...state,
                loading: true,
                iframeLink: iframeLink
            };
        case actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN:
            let serverResponse = null;
            if(action.data.result && action.data.result.status){
                serverResponse = action.data.result.status;
            }

            if(action.data.result && action.data.result.http){
                serverResponse = Object.keys(action.data.result.http)[0] + ": " + action.data.result.http[Object.keys(action.data.result.http)];
            }

            if (action.data.message){
                serverResponse = action.data.message;
            }

            if (action.data.response && action.data.response.data && action.data.response.data.message){
                serverResponse = action.data.response.data.message;
                if(action.data.response.status){
                    serverResponse = action.data.response.status + ": " + serverResponse;
                }
            }

            return {
                ...state,
                loading: false,
                serverResponse: serverResponse,
                bonusError: action.data.result.bonusError ? action.data.result.bonusError : false
            };
        case actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN_WITH_CARD:
            let serverResponseWC = null;
            if(action.data.result && action.data.result.status){
                serverResponseWC = action.data.result.status;
            }

            if (action.data.response && action.data.response.data && action.data.response.data.message){
                serverResponseWC = action.data.response.data.message;
                if(action.data.response.status){
                    serverResponseWC = action.data.response.status + ": " + serverResponseWC;
                }
            }
            return {
                ...state,
                loading: false,
                serverResponse: serverResponseWC,
                bonusError: action.data.result.bonusError ? action.data.result.bonusError : false
            };
        default:
            return state;
    }
};

export default paymentCheckoutReducer;