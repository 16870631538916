import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import TicketCheckModal from "../ticket-check-modal";
import { ticketCheckCode } from "../../../bets/store/actions/tickets";

const VerifyTicket = (props) => {
  const { t, checkCode, className } = props;

  const [search, setSearch] = React.useState("");
  const [ticketCheck, setTicketCheck] = React.useState("");

  const handleSearchTicket = (ticketCode) => {
    //console.log("ticketCode", ticketCode);
    setTicketCheck(ticketCode)
  };
  const handleSearchValueChange = event => {
    setSearch(event.target.value);
  };
  const doTicketSearch = () => {
    checkCode(search);
    handleSearchTicket(search);
  };
  const handleKeyDown = (event) => {
    if (event && event.key === 'Enter') {
      doTicketSearch();
    }
  };
  const handleCheckModalClose = () => {
    setTicketCheck("");
    setSearch("");
  };

  return (<div className={`verify-ticket ${className ? className : ''}`}>
    <div className="input">
      <input
        type="text"
        className="textbox"
        placeholder={t("Ticket verification")}
        onChange={handleSearchValueChange}
        onKeyDown={handleKeyDown}
        value={search}
      />
    </div>
    <div className="button" onClick={doTicketSearch}>
      {t("Verify")}
    </div>
    {ticketCheck && <TicketCheckModal openTicketCheck={true} onModalClose={handleCheckModalClose} />}
  </div >);
}

const actionCreators = {
  checkCode: ticketCheckCode
};

export default withTranslation()(connect(null, actionCreators)(VerifyTicket));