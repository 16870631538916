import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import StarIcon from "@material-ui/icons/Star";

//import StarBorderIcon from "@material-ui/icons/StarBorder";
import BarChartIcon from "@material-ui/icons/BarChart";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

import {
  getBetsState,
  makeGetBets,
  getPrematchTournaments,
  getPrematchCategories,
  makeGetSelectedBet
} from "../../store/selectors/betData";
import { makeMatchDetailsOpened } from "../../../bets/store/selectors/betData";
import { appSetSelectedBet } from "../../../bets/store/actions/app";
import { buildBetId, getHeaderMarkets } from "../../../bets/utils/betUtils";
import { getCommonMarkets2 } from "../../utils/betUtils";
import { formatBetTitle } from "../../../bets/utils/formatters";
import BetSearch from "../bet-search";
import { favoriteTogglePrematchLeague } from "../../../bets/store/actions/favorite";

import isEqual from "lodash/fp/isEqual";

const TournamentHeader = props => {
  const {
    collapsedKey,
    tournaments,
    idTournament,
    name,
    brId,
    onClick,
    collapsed,
    selectedBets,
    matchGroup,
    matches,
    setSelectedBet,
    stateBets,
    categoryIsoName,
    matchDetailOpened,
    favoriteTogglePrematchLeague,
    favorite,
    t
  } = props;

  const statsURL = `https://s5.sir.sportradar.com/blingbet/ro/uniquetournament/${brId}`;

  const preventPropagation = e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
  };

  const [show, setShow] = React.useState(false);
  const toggleShow = e => {
    preventPropagation(e);
    setShow(v => !v);
  };

  const toggleFavorite = (e) => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
    favoriteTogglePrematchLeague(idTournament);
  };

  let description = "";
  if (tournaments && tournaments[idTournament] && tournaments[idTournament].description) {
    description = tournaments[idTournament].description;
  }

  const [betSelectedData, setBetSelectedData] = React.useState({
    selected0: null,
    selected1: null,
    selected2: null,
    selectedBet0: null,
    selectedBet1: null,
    selectedBet2: null,
    markets: null
  });

  const [searchBet, setSearchBet] = React.useState({
    openMenu: false,
    selectedBet: null,
    match: null,
    bets: [],
    index: -1
  });

  React.useEffect(() => {
    if (typeof matches === "undefined" || matches === null || matches.length === 0) {
      return;
    }

    const markets = getCommonMarkets2(matches, stateBets);

    if (typeof window.config.nsoftTheme !== "undefined" && window.config.nsoftTheme && window.config.nsoftTheme === "Excel") {
      // place "Final" market first

      let idx = markets.findIndex((m) => {
        if (m && m[1] && m[1].bet) {
          const title = formatBetTitle(m[1].bet, matches[0], stateBets);
          if (title && title.toString().toLowerCase() === "final") {
            return true;
          }
        }
        return false;
      });

      if (idx !== -1) {
        const m = markets.splice(idx, 1);
        markets.unshift(m[0]);
      }
    }

    const res = {
      markets
    };

    ["0", "1", "2"].forEach(index => {
      const mg = matchGroup + "_" + index;
      const headerMarkets = getHeaderMarkets(markets, mg);

      const groupSelectedBet = selectedBets && selectedBets[mg] ? selectedBets[mg] : null;

      let betSelected = null;

      if (groupSelectedBet !== null) {
        const bs = markets.find(([midBet, m]) => midBet === groupSelectedBet.midBet);
        if (bs) {
          betSelected = bs[1].bet;
        }
      }

      if (betSelected === null) {
        if (headerMarkets.length > 0) {
          if (typeof headerMarkets[parseInt(index, 10)] !== "undefined") {
            betSelected = headerMarkets[parseInt(index, 10)][1].bet;

            setSelectedBet(
              mg,
              formatBetTitle(betSelected, matches[0], stateBets),
              headerMarkets[parseInt(index, 10)][0]
            );

          }
        }
      }

      //console.log(mg, betSelected);

      if (
        betSelected === null &&
        (groupSelectedBet === null || groupSelectedBet.midBet !== "none")
      ) {
        setSelectedBet(mg, " ", "none");
      }

      res["selected" + index] = { name: formatBetTitle(betSelected, matches[0], stateBets) };
      res["selectedBet" + index] = betSelected;
    });
    setBetSelectedData(res);
  }, [matches, matchGroup, selectedBets, setSelectedBet, stateBets]);

  const handleSelect = index => {
    setSearchBet(v => ({ ...v, openMenu: false }));
    if (index === -1) {
      return;
    }

    setSelectedBet(
      matchGroup + "_" + searchBet.index,
      formatBetTitle(betSelectedData.markets[index][1].bet, matches[0], stateBets),
      betSelectedData.markets[index][0]
    );
  };

  const handleSearch = id => e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
    setSearchBet({
      openMenu: true,
      selectedBet:
        id === 0
          ? betSelectedData.selectedBet0
          : id === 1
            ? betSelectedData.selectedBet1
            : betSelectedData.selectedBet2,
      match: matches[0],
      bets: stateBets,
      index: id
    });
  };

  const formatName = (t) => {
    if (t === "Winner Avantaj") {
      t = t.replace("Winner", window.config.nsoftTheme)
    } else if (t === "Winner Plus") {
      t = t.replace("Winner", window.config.nsoftTheme)
    } else if (t === "Winner la 2 goluri") {
      t = t.replace("Winner", "Castigator")
    } else if (t.indexOf("Winner Lucky Bet") === 0) {
      t = t.replace("Winner", window.config.nsoftTheme)
    }
    return t;
  };

  //console.log("favorite", favorite);

  return (
    <React.Fragment>
      <div className="tournament-header" data-id={collapsedKey} onClick={onClick}>
        <div className={`info-wrap tournament ${matchDetailOpened ? "w100" : ""}`}>
          <div className="tournament-name">
            {categoryIsoName && (
              <span
                className={`flag flag-${categoryIsoName ? categoryIsoName.toLowerCase() : "undef"}`}
              ></span>
            )}
            {name}
          </div>
          {/*idTournament && (
            <span className="pin">
              <StarBorderIcon />
            </span>
          )*/}
          <div className="stats bet-selector" onClick={handleSearch(0)}>
            <MoreVertIcon />
          </div>
          {idTournament && brId && (
            <a
              href={statsURL}
              target="_blank"
              rel="noopener noreferrer"
              className="stats"
              onClick={preventPropagation}
            >
              <Tooltip title={t("League Statistics")} arrow>
                <BarChartIcon />
              </Tooltip>
            </a>
          )}
          {idTournament && description && (
            <div className="stats nopointer" onClick={toggleShow}>
              <Tooltip title={description} arrow>
                <InfoIcon />
              </Tooltip>
            </div>
          )}
        </div>
        {!matchDetailOpened && (
          <React.Fragment>
            <div className="bets-wrap bets-3">
              <div className="bet-name font-alt" onClick={handleSearch(0)}>
                {betSelectedData.selected0 ? formatName(betSelectedData.selected0.name) : "-"}{" "}
                <ExpandMoreIcon />
              </div>
              <div className="bet-name font-alt" onClick={handleSearch(1)}>
                {betSelectedData.selected1 ? formatName(betSelectedData.selected1.name) : "-"}{" "}
                <ExpandMoreIcon />
              </div>
              <div className="bet-name font-alt" onClick={handleSearch(2)}>
                {betSelectedData.selected2 ? formatName(betSelectedData.selected2.name) : "-"}{" "}
                <ExpandMoreIcon />
              </div>
            </div>
            <div className="stats-wrap">

              <span className="brd-left">

                {idTournament && <StarIcon
                  onClick={toggleFavorite}
                  className={`favorite-icon ${favorite.favorite_prematch_leagues.indexOf(idTournament) > -1 ? "active" : ""
                    }`}
                />}
                {typeof collapsed !== "undefined" && collapsed && <ExpandMoreIcon />}
                {typeof collapsed !== "undefined" && !collapsed && <ExpandLessIcon />}
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
      {show && <div className="tournament-description">{description}</div>}
      {
        searchBet.openMenu && (
          <BetSearch
            open={searchBet.openMenu}
            markets={betSelectedData.markets}
            match={searchBet.match}
            selectedBetId={searchBet.selectedBet ? buildBetId(searchBet.selectedBet) : null}
            bets={searchBet.bets}
            handleSelect={handleSelect}
          />
        )
      }
    </React.Fragment >
  );
};

const getBets = makeGetBets();
const getMatchDetailsOpened = makeMatchDetailsOpened();
const getSelectedBet = makeGetSelectedBet();
const emptyArray = [];

const makeMapStateToProps = () => {
  return (state, props) => {
    const bst = getBetsState(state);

    const bets = props.matches && props.matches.length ? getBets(state, { match: props.matches[0] }) : emptyArray;

    let categoryIsoName = null;
    const tournaments = getPrematchTournaments(state);
    const categories = getPrematchCategories(state);
    if (props.idTournament) {
      if (
        tournaments &&
        tournaments[props.idTournament] &&
        tournaments[props.idTournament].idCategory &&
        categories &&
        categories[tournaments[props.idTournament].idCategory] &&
        categories[tournaments[props.idTournament].idCategory].categoryIsoName
      ) {
        categoryIsoName = categories[tournaments[props.idTournament].idCategory].categoryIsoName;
      }
    }

    return {
      matchDetailOpened: getMatchDetailsOpened(state),
      stateBets: bets,
      selectedBets: bst.app.selectedBets,
      categoryIsoName,
      favorite: bst.favorite,
      tournaments
    };
  };
};
const actionCreators = {
  setSelectedBet: appSetSelectedBet,
  favoriteTogglePrematchLeague: favoriteTogglePrematchLeague
};

export default connect(
  makeMapStateToProps,
  actionCreators
)(withTranslation()(React.memo(TournamentHeader, (pp, np) => {
  for (let k in pp) {
    if (!isEqual(pp[k], np[k])) {
      //console.log(`TournamentHeader: ${k} is not equal`, pp[k], np[k], np.idTournament);
      return false;
    }
  }
  return true;
})));
