import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import * as stateActions from "../../../../../store/actions";
import "./LocationTabs.scss";

class LocationTabs extends Component {
    constructor(props) {
        super(props);

        this.selectedClass = "selected";
        this.tabs = {
            all: ["all", this.selectedClass],
            recommended: ["recommended"],
        };

        this.state = {
            tabs: this.tabs,
        };
    }

    clickHandler(elemClassName, event) {
        let tabs = {};
        Object.keys(this.tabs).forEach((elem) => {
            tabs[elem] = this.tabs[elem].filter(name => name !== this.selectedClass);
        });

        tabs[elemClassName].push(this.selectedClass);

        this.setState({
            ...this.state,
            tabs: tabs,
        });
    }

    render() {
        return (
            <div className={"LocationTabs"}>
                <button className={this.state.tabs.all.join(" ")} onClick={this.clickHandler.bind(this, "all")}>
                    <span>{this.props.t("all")}</span>
                </button>
                <button className={this.state.tabs.recommended.join(" ")} onClick={this.clickHandler.bind(this, "recommended")}>
                    <span>{this.props.t("recommended")}</span>
                </button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
    }
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LocationTabs));