let trans = {};
const configTranslate = process.env.TRANSLATE;
for (let i = 0; i < configTranslate.languages.length; i++) {
    try {
        trans[configTranslate.languages[i]] = { translations: require("./" + configTranslate.languages[i] + ".js")[configTranslate.languages[i]] }
    } catch (e) {
        // console.error("A aparut o eroare la incarcarea fisierului de traduceri pentru limba:", configTranslate.languages[i]);
    }
}
export const translations = trans;


function t() {
    return true;
}

t('slots'); t('table games'); t('cazino live'); t('pariuri'); t('promotii'); t('jocuri'); t('about us'); t('locations'); t('Only for players over 18 years old'); t('Wallet'); t('Real money'); t("BONUS money (requires rolling)"); t("Active Bonuses"); t('account balance'); t('BONUS Money'); t('profile settings'); t('player profile transactions'); t("deposits & withdrawals"); t('game'); t("unknown"); t("pending approval"); t("ready for cashing"); t("failed"); t("collected"); t("cancelled");
t('player profile responsibly'); t('First deposit bonus'); t("Authentication successful!"); t('live casino'); t('games'); t('promotions');
t("take a break page"); t("documents"); t("meet the legal requirements");
t("player self exclusion"); t("complaint form"); t("Limite"); t("Agencies"); t("here");

// About Us Translations
t("PlayOnline is operated by ${companyName}, registered in Malta with number ${registerNumber} at the address listed below. In Romania, Play Online Solutions LTD operates under license number ${onjnLicenseNumber}, issued by the Romanian Gambling Office (ONJN), valid from ${date} until ${licenseExpirationNumber}.");
t("Gambling may become addictive and impact your financials. Read about responsible gaming ${begin_url}here${end_url}.");

// Promotions Translations
t("Welcome to Elite! We know that a high stake generates high earnings. For you to enjoy your betting without any restraints, ${boldStart}we give you a BONUS, for each deposit, up to a total limit of 1,000 Lei.${boldEnd} Make a deposit now, we give you a prize and you can start playing, right away!");
t("First deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}500 lei BONUS.${boldEnd}");
t("Second deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}200 Lei BONUS.${boldEnd}");
t("Third deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}300 Lei BONUS.${boldEnd}");
// Footer
t('This website is operated by ${companyName}, registered in Malta under number ${companyRegNumber}, at ${companyAddress}. In Romania, ${companyName} owns license number ${license_number}, issued on ${license_date}, by the National Gambling Office (${openTagOnjn}http://onjn.gov.ro/${closeTagOnjn}), valid until ${license_expiration_date}. Please be informed that gambling might be prohibited in your area. It is your responsibility to obey the law. Gambling may be addictive and impact your financials. Please play responsibly!')
t('available');t('consumed');
t("Withdrawals in pending");t("Your requests");t("This account is suspended. Please contact support.");t("Recent transactions");t("Withdrawals history");t("Fast deposit");


t("See details");t("Next ball color"); t("Next ball even or odd");t("Even");t("My tickets");t("Sports");
t("virtual games");t("Placing the ticket in progress...");t("Match details");t("Waiting for events...");
t("Search bet");t("Placing failed");t("An error occurred");t("If the problem persists, please contact the Customer Support departement");
t("Live tickets can only be played online");t("There are no top leagues");t("Most played");t("By Kick-off");t("By league");
t("Home");t("My Filtru");t("Event bets locked");t("All Championships");t("By Hour");t("You haven't selected any bets");
t("Balance");t("Choose Stake");t("Min. Winning");t("Place ticket online");t("Automatically accept odds changed");
t("Online ticket");t("Ticket in shop");t("Enter now");t("The tournament begins");t("Event bets locked");t("High Odds matches");
t("Pull and come into play");t("Cazino");t("Bets view"); t("UK layout"); t("Default layout");