import { debug } from './';
import { getBetsState } from '../store/selectors/betData';
import isArray from 'lodash/fp/isArray';
import getStore from '../store';
import capitalize from 'lodash/fp/capitalize';
import moment from 'moment';

const liveTeamSort = (t1, t2) => parseInt(t1.teamType, 10) - parseInt(t2.teamType, 10);
const prematchTeamSort = (t1, t2) => t1.type - t2.type;

function normalizeLiveTicket(t) {
  const bst = getBetsState(getStore().getState());

  const ticket = {
    id: t.id,
    idHash: t.id,
    product: 'Live',
    format: 'live',
    type: t.ticketType.id === 1 ? 'SIMPLU' : 'SISTEM',
    status: t.status.value.toUpperCase(),
    winning: t.maxPossibleWin,
    payout: t.displayPayout ? t.displayPayout : (t.payout ? t.payout : 0),
    cashouted: t.cashouted ? t.cashouted : false,
    minWinning: 0,
    totalOdd: t.totalOdd,
    stake: t.payin,
    createdAt: t.ticketDateTimeUTC,
    systemType: t.systemType,
    hasBonus: t.hasBonus ? true : false,
    minBonus: t.minBonus ? t.minBonus : 0,
    maxBonus: t.maxBonus ? t.maxBonus : 0,
    minBonusPercentage: t.minBonusPercentage ? t.minBonusPercentage : 0,
    maxBonusPercentage: t.maxBonusPercentage ? t.maxBonusPercentage : 0,
    bonusWonAmount: t.bonusWonAmount ? t.bonusWonAmount : 0,
    paidOutBonus: t.paidOutBonus ? t.paidOutBonus : 0,
    requestUuid: t.requestUuid,
    //wf_event_balance_multiplier: t.wf_event_balance_multiplier ? t.wf_event_balance_multiplier : 0,
    clientVal: {}
  };

  if (t.clientVal) {
    try {
      ticket.clientVal = JSON.parse(t.clientVal);
    } catch (err) { }
  }

  if (ticket.status === 'PAYEDOUT') {
    ticket.status = 'WON';
    ticket.origStatus = 'PAID OUT';
  }

  if (ticket.status === 'PENDING') {
    ticket.status = 'OPEN';
    ticket.origStatus = 'PENDING';
  }

  const ts = [];
  let won = 0;
  let lost = 0;
  let bankers = 0;

  ticket.bets = t.bets.map(b => {

    let teams = [...b.teams];

    const bet = {
      banker: b.ticketBetBank === '1' && ticket.type === 'SISTEM',
      status: b.statusInfo.value,
      startAt: b.matchDateTimeUTC,
      idMatch: b.matchId,
      idSport: b.idSport,
      idCategory: b.idCategory,
      idTournament: b.idTournament,
      idBet: b.bet.idBet,
      idMatchBet: b.bet.idMatchBet,
      idBetOutcome: b.betOutcome.idBetOutcome,
      idMatchBetOutcome: b.betOutcome.idMatchBetOutcome,
      betDisplayName: b.bet.betDisplayName,
      outcomeDisplayName: b.betOutcome.betOutcomeDisplayName,
      oddValue: b.oddValue,
      specialValue: b.specialValue || '',
      teams: teams.sort(liveTeamSort).map(team => {
        return {
          teamDisplayName: team.teamDisplayName
        };
      }),
      results: b.results.map(r => {
        return {
          halfName: r.providerResultId,
          halfResult: r.matchResultValue.split(':')
        };
      })
    };

    // handle %player% issue
    /*
    betOutcome:
    betOutcomeDisplayName: "%player%"
    betOutcomeShortName: "%player%"
    idBetOutcome: "8/146/1/player home 5"
    idMatchBetOutcome: "22660061/8/146//Alba, Jordi!35199"
    providerBetOutcomeId: "Alba, Jordi"
    */
    if (bet.outcomeDisplayName === '%player%') {
      // try to get it from state / match data
      if (
        bst &&
        bst.live &&
        bst.live.matches &&
        typeof bst.live.matches[bet.idMatch] !== 'undefined'
      ) {
        const m = bst.live.matches[bet.idMatch];

        const fmb = m.matchBets.find(mb => mb.idMb === bet.idMatchBet);

        if (fmb) {
          const fbo = fmb.mbOutcomes.find(bo => bo.idMbo === bet.idMatchBetOutcome);

          if (fbo && fbo.mboDisplayName) {
            bet.outcomeDisplayName = fbo.mboDisplayName;
          }
        }
      }

      // get it from providerBetOutcomeId
      if (bet.outcomeDisplayName === '%player%' && b.providerBetOutcomeId) {
        bet.outcomeDisplayName = b.providerBetOutcomeId;
      }
    }

    if (bet.banker) bankers += 1;

    ts.push(b.matchDateTimeUTC);

    if (b.statusInfo.value === 'LOST') {
      lost += 1;
    }
    if (b.statusInfo.value === 'WON') {
      won += 1;
    }

    return bet;
  });

  if (!ticket.systemType && ticket.type === 'SISTEM') {
    const parlays = [];
    let events = 0;
    t.ticketCombinationGroups.forEach(c => {
      parlays.push(c.parlays);
      events = c.events;
    });

    ticket.systemType = parlays.join(',') + '/' + events;
    if (bankers) ticket.systemType = bankers + 'B+' + parlays.join(',') + '/' + events;
  }

  let minWinning = Number.MAX_VALUE;
  t.ticketCombinationGroups.forEach(c => {
    if (minWinning > c.minGain) {
      minWinning = c.minGain;
    }
  });
  if (minWinning !== Number.MAX_VALUE && minWinning !== ticket.winning) {
    ticket.minWinning = minWinning;
  }

  ticket.betsRes = {
    lost,
    won
  };

  ticket.maxTime = Math.max(...ts);
  ticket.minTime = Math.max(...ts);

  //debug("normalize live", ticket, t);

  return ticket;
}
function normalizeLiveTickets(tickets) {
  if (!tickets || tickets.length === 0) return [];
  return tickets
    .map(t => normalizeLiveTicket(t))
    .filter(t => t.status === 'OPEN' || t.status === 'LOST' || t.status === 'WON');
}

function normalizePrematchResults(results) {
  const res = [];
  if (results && (results.ft || results.ht)) {
    ['ht', 'ft'].forEach(halfName => {
      if (results[halfName]) {
        res.push({
          halfName: halfName.toUpperCase(),
          halfResult: results[halfName].split(':')
        });
      }
    });
  }
  return res;
}
function normalizePrematchBonusEligibles(t) {
  const res = {};
  if (t.ticketBonusEligibles && t.ticketBonusEligibles.length) {
    t.ticketBonusEligibles.forEach(b => {
      if (b.eligible && b.bonus && b.bonus.name) res[b.bonus.name] = 1;
    });
  }
  return Object.keys(res).sort();
}

function normalizePreMatchTicket(t, bst) {
  const ticket = {
    id: t.ticketId.toString(),
    idHash: t.normalTicketHash,
    product: 'PreMatch',
    format: 'prematch',
    type: t.type === 'COMBO' ? 'SIMPLU' : 'SISTEM',
    status: t.status.toUpperCase(),
    winning: t.maxWinnings,
    minWinning: t.minWinnings ? t.minWinnings : 0,
    totalOdd: t.oddMax,
    stake: t.payment,
    cashouted: t.cashouted ? t.cashouted : false,
    createdAt: new Date(t.createdAt).getTime(),
    hasBonus: t.hasBonus ? true : false,
    minBonus: t.minBonus ? t.minBonus : 0,
    maxBonus: t.maxBonus ? t.maxBonus : 0,
    minBonusPercentage: t.minBonusPercentage ? t.minBonusPercentage : 0,
    maxBonusPercentage: t.maxBonusPercentage ? t.maxBonusPercentage : 0,
    bonusWonAmount: t.bonusWonAmount ? t.bonusWonAmount : 0,
    paidOutBonus: t.paidOutBonus ? t.paidOutBonus : 0,
    bonusEligibles: normalizePrematchBonusEligibles(t),
    cashBack: t.cashBack,
    payout: t.payout,
    //wf_event_balance_multiplier: t.wf_event_balance_multiplier ? t.wf_event_balance_multiplier : 0,
    clientVal: {}
  };

  if (t.clientVal) {
    try {
      ticket.clientVal = JSON.parse(t.clientVal);
    } catch (err) { }
  }

  if (ticket.status === 'CASHOUTED') {
    ticket.status = 'WON';
    ticket.origStatus = 'CASHOUTED';
  }

  if (ticket.status === 'PAIDOUT') {
    ticket.status = 'WON';
    ticket.origStatus = 'PAID OUT';
  }

  if (ticket.status === 'PENDING') {
    ticket.status = 'OPEN';
    ticket.origStatus = 'PENDING';
  }

  let systems = [];
  t.units.forEach(unit => {
    systems = systems.concat(unit.selectedSystems);
  });
  systems.sort();
  ticket.systemType = systems.join(',') + '/' + t.bets.length;

  const ts = [];
  let won = 0;
  let lost = 0;
  let bankers = 0;

  ticket.bets = t.bets.map((b, i) => {
    const bet = {
      banker: b.banker === 1 && ticket.type === 'SISTEM',
      status: b.resolutionStatus.toUpperCase(),
      startAt: new Date(b.startAt).getTime(),
      idMatch: b.eventId,
      idSport: '',
      idCategory: '',
      idTournament: '',
      idBet: b.marketId,
      idMatchBet: b.eventMarketId,
      idBetOutcome: b.marketOutcomeId,
      idMatchBetOutcome: b.eventMarketOutcomeId,
      betDisplayName: b.marketName,
      outcomeDisplayName: b.marketOutcomeName,
      oddValue: b.odd,
      specialValue: b.specialValues && b.specialValues.length > 0 ? b.specialValues[0] : '',
      results: normalizePrematchResults(b.results)
    };

    const competitors =
      b.eventCompetitors && b.eventCompetitors.length ? [...b.eventCompetitors] : [];
    competitors.sort(prematchTeamSort);
    const teams = competitors.map(team => {
      return {
        teamDisplayName: team.teamName
      };
    });
    bet.teams = teams;

    const marketCompetitors =
      b.eventMarketCompetitors &&
        isArray(b.eventMarketCompetitors) &&
        b.eventMarketCompetitors.length
        ? b.eventMarketCompetitors
        : [];
    bet.competitors = marketCompetitors.map(mc => {
      let name = '';

      if (mc && mc.player && mc.player.displayName) {
        name = mc.player.displayName;
      } else if (mc && mc.player) {
        name = mc.player.name + ' ' + mc.player.surname;
      }

      return {
        id: mc.id,
        name
      };
    });

    if (bet.competitors.length > 0) {
      bet.betDisplayName += ' - ' + bet.competitors[0].name;
    }

    if (!bet.banker) {
      if (
        t.units &&
        t.units.length &&
        t.units[0].ticketUnitBets &&
        t.units[0].ticketUnitBets.length &&
        t.units[0].ticketUnitBets[i] &&
        t.units[0].ticketUnitBets[i].banker
      ) {
        bet.banker = true;
      }
    }

    if (bet.banker) bankers += 1;

    // for prematch tickets we need to set the BR ID inside the ticket bets
    // so we can detect when we need to recheck the ticket after a live match
    // has completed
    bet.brId = null;

    if (
      bst &&
      bst.prematch &&
      bst.prematch.matches &&
      typeof bst.prematch.matches[bet.idMatch] !== 'undefined'
    ) {
      bet.idSport = bst.prematch.matches[bet.idMatch].idSport;
      bet.idCategory = bst.prematch.matches[bet.idMatch].idCategory;
      bet.idTournament = bst.prematch.matches[bet.idMatch].idTournament;

      if (!bst.prematch.matches[bet.idMatch].brId) {
        debug(`prematch event ${bet.idMatch} has no BR ID`);
      } else {
        bet.brId = bst.prematch.matches[bet.idMatch].brId;
      }
    }

    ts.push(bet.startAt);

    if (bet.status === 'LOST') {
      lost += 1;
    }
    if (bet.status === 'WON') {
      won += 1;
    }

    return bet;
  });

  ticket.systemType = systems.join(',') + '/' + t.bets.length;
  if (bankers)
    ticket.systemType = bankers + 'B+' + systems.join(',') + '/' + (t.bets.length - bankers);

  ticket.betsRes = {
    lost,
    won
  };

  ticket.maxTime = Math.max(...ts);
  ticket.minTime = Math.max(...ts);

  //debug("normalize prematch", ticket, t);

  return ticket;
}
function normalizePreMatchTickets(tickets) {
  if (!tickets || tickets.length === 0) return [];
  return tickets
    .map(t => normalizePreMatchTicket(t))
    .filter(t => t.status === 'OPEN' || t.status === 'LOST' || t.status === 'WON');
}

function normalizeLiveReservedTickets() { }
function normalizePreMatchReservedTickets(tickets) {
  const bst = getBetsState(getStore().getState());

  return tickets.map(t => {
    const ticket = {
      id: t.id.toString(),
      idHash: t.id,
      product: 'PreMatch (R)',
      format: 'prematch-reserved',
      type: t.type === 'Combo' ? 'SIMPLU' : 'SISTEM',
      status: t.status.name.toUpperCase(),
      winning: t.winningAmountMax,
      totalOdd: t.oddMax,
      stake: t.paymentAmount,
      createdAt: t.createdAtMs
    };

    let systems = [];
    t.ticketUnits.forEach(unit => {
      systems = systems.concat(unit.selectedSystems);
    });
    systems.sort();
    ticket.systemType = systems.join(',') + '/' + t.ticketBets.length;

    const ts = [];
    let won = 0;
    let lost = 0;

    ticket.bets = t.ticketBets.map(b => {
      const bet = {
        banker: b.banker === 1 && ticket.type === 'SISTEM',
        status: 'OPEN',
        startAt: b.event.startsAt,
        idMatch: b.event.id,
        idSport: '',
        idCategory: '',
        idTournament: '',
        idBet: b.market.id,
        idMatchBet: b.eventMarketId,
        idBetOutcome: b.marketOutcomeId,
        idMatchBetOutcome: b.eventMarketOutcomeId,
        betDisplayName: b.market.name,
        outcomeDisplayName: b.marketOutcome.name,
        oddValue: b.odd,
        teams: b.eventCompetitors.sort(prematchTeamSort).map(team => {
          return {
            teamDisplayName: team.teamName
          };
        }),
        results: []
      };

      if (
        bst &&
        bst.prematch &&
        bst.prematch.matches &&
        typeof bst.prematch.matches[bet.idMatch] !== 'undefined'
      ) {
        bet.idSport = bst.prematch.matches[bet.idMatch].idSport;
        bet.idCategory = bst.prematch.matches[bet.idMatch].idCategory;
        bet.idTournament = bst.prematch.matches[bet.idMatch].idTournament;
      }

      ts.push(bet.startAt);
      return bet;
    });

    ticket.betsRes = {
      lost,
      won
    };

    ticket.maxTime = Math.max(...ts);
    ticket.minTime = Math.max(...ts);

    ticket.ticketHashes = t.ticketHashes;

    return ticket;
  });
}

const computeWinnerFunWinnings = (v, ev) => {
  if (!ev.secondary_amount) return v;

  // compute main won amount accoring to participation from main balance 
  const main_won_amount = (ev.main_amount * v) / ev.ticket_amount;

  // compute secondary won amount according to participation from secondary balance and computed multiplier 
  const secondary_won_amount = (ev.secondary_amount * v * ev.event_balance_multiplier) / ev.ticket_amount;

  return parseFloat((main_won_amount + secondary_won_amount).toFixed(2));
};

const adjustWinnerFunWinnings = (t, eval_res) => {
  const keys = ['winning', 'payout', 'minWinning'];

  keys.forEach(k => {
    if (!(k in t)) {
      return;
    }

    const ok = 'original' + capitalize(k);

    t[ok] = t[k];
    t[k] = computeWinnerFunWinnings(t[k], eval_res);
  });

  t.eval_res = eval_res;
};

const EVENT_REFRESH_INTERVAL = 5 * 60 * 1000;

const getPrematchTicketsEventIds = (tickets, bst) => {
  const hEventIds = {};

  const now = moment().utc().valueOf();

  tickets.forEach(t => {
    // only prematch
    if (t.format !== 'prematch') {
      return;
    }

    t.bets.forEach(b => {
      // ignore bets which already have a Betradar ID set
      if (b.brId) {
        return;
      }

      //debug("bet without Betradar ID", b);

      // check if match is in state
      if (
        bst &&
        bst.prematch &&
        bst.prematch.matches &&
        typeof bst.prematch.matches[b.idMatch] !== 'undefined'
      ) {
        const m = bst.prematch.matches[b.idMatch];

        // check if it has Betradar ID set
        if (m.brId) {
          //debug("Betradar ID found in state", m.brId);

          // set prematch Betradar ID and we're done
          b.brId = m.brId;
          return;
        }

        debug('checking fetch condition', m.lastFetched, now, now - m.lastFetched);

        // match doesn't have Betradar ID set. If we didn't fetch it in a while
        // do it again now
        if (!m.lastFetched || (now - m.lastFetched > EVENT_REFRESH_INTERVAL)) {
          hEventIds[b.idMatch] = 1;
        }
      } else {
        //debug(`match ${b.idMatch} not found in state`);

        // match is not in state. fetch it now
        hEventIds[b.idMatch] = 1;
      }
    });
  });

  const eventIds = Object.keys(hEventIds);
  eventIds.sort();

  return eventIds;
};

function normalizePrematchCheckResults(results) {
  const res = [];
  if (results && results.length) {
    results.forEach(result => {
      if (result.value && result.resultType && result.resultType.constName && ['HF', 'FT'].indexOf(result.resultType.constName) > -1) {
        res.push({
          halfName:
            result.resultType && result.resultType.constName ? result.resultType.constName.toUpperCase() : '',
          halfResult: result.value.split(':')
        });
      }
    });
  }
  return res;
}

function normalizePreMatchCheckTicket(t, bst) {

  if (t.product !== 'SportsbookSM') {
    if (t.product === 'LiveBetting') {
      return normalizeLiveTicket(t);
    }
    if (t.product === 'LuckySix') {
      return normalizeLuckySixTicket(t);
    }
    if (t.product === 'GreyhoundRaces') {
      return normalizeGrayhoundTicket(t);
    }
    return {};
  }

  const ticket = {
    id: t.id,
    idHash: t.id,
    product: 'PreMatch',
    format: 'prematch',
    type: t.type === 'COMBO' ? 'SIMPLU' : 'SISTEM',
    status: t.status && t.status.value ? t.status.value.toUpperCase() : '',
    // normalize from here
    winning: t.winningAmountMax, // t.maxWinnings,
    minWinning: t.winningAmountMin ? t.winningAmountMin : 0, // t.minWinnings ? t.minWinnings : 0,
    totalOdd: t.oddMax,
    stake: t.stakeAmount, // t.payment,
    cashouted: t.cashouted ? t.cashouted : false,
    createdAt: new Date(t.createdAt).getTime(),
    hasBonus: false,
    minBonus: 0,
    maxBonus: 0,
    minBonusPercentage: 0,
    maxBonusPercentage: 0,
    bonusWonAmount: 0,
    paidOutBonus: 0,
    bonusEligibles: normalizePrematchBonusEligibles(t),
    cashBack: 0,
    cashout: t.cashout,
    online: t.deliveryPlatformId && t.deliveryPlatformId === 'Web',
    retail: t.deliveryPlatformId && t.deliveryPlatformId === 'Retail',
    payout: t.displayPayoutAmount ? t.displayPayoutAmount : (t.paidOutAmount ? t.paidOutAmount : 0), // t.payout,
    //wf_event_balance_multiplier: t.wf_event_balance_multiplier ? t.wf_event_balance_multiplier : 0,
    clientVal: {}
  };

  if (t.ticketAppliedBonuses && t.ticketAppliedBonuses.length) {
    t.ticketAppliedBonuses.forEach((b) => {
      if (b.type === 1) {// progressive bonus
        ticket.hasBonus = true;
        ticket.minBonus = b.amountMin;
        ticket.maxBonus = b.amountMax;
        ticket.minBonusPercentage = b.minPercentage;
        ticket.maxBonusPercentage = b.maxPercentage;
        ticket.bonusWonAmount = b.amountMax;

        if (ticket.status === 'PAIDOUT') {
          ticket.paidOutBonus = b.amountMax;
        }
      } else if (b.type === 3) {
        ticket.hasBonus = true;
        ticket.minBonus = b.amountMin;
        ticket.maxBonus = b.amountMax;
        ticket.minBonusPercentage = b.minPercentage;
        ticket.maxBonusPercentage = b.maxPercentage;
        ticket.cashBack = 1;

        ticket.bonusWonAmount = b.amountMax;

        if (ticket.status === 'PAIDOUT') {
          ticket.paidOutBonus = b.amountMax;
        }
      }
    });
  }

  if (t.clientVal) {
    try {
      ticket.clientVal = JSON.parse(t.clientVal);
    } catch (err) { }
  }

  if (ticket.status === 'PAIDOUT') {
    ticket.status = 'WON';
    ticket.origStatus = 'PAID OUT';
  }

  if (ticket.status === 'PENDING') {
    ticket.status = 'OPEN';
    ticket.origStatus = 'PENDING';
  }

  let systems = [];
  t.ticketUnits && t.ticketUnits.forEach(unit => {
    systems = systems.concat(unit.selectedSystems);
  });
  systems.sort();
  ticket.systemType = systems.join(',') + '/' + t.ticketBets.length;

  const ts = [];
  let won = 0;
  let lost = 0;
  let bankers = 0;

  ticket.bets = t.ticketBets.map((b, i) => {
    let banker = false;

    t.ticketUnits && t.ticketUnits.forEach((unit) => {
      unit && unit.ticketUnitBets && unit.ticketUnitBets.forEach(ub => {
        if (ub && ub.betId === b.id && ub.banker) {
          banker = true;
        }
      });
    });

    const bet = {
      banker: banker && ticket.type === 'SISTEM',
      status: b.combineResolutionStatus && b.combineResolutionStatus.name ? b.combineResolutionStatus.name.toUpperCase() : '',
      startAt: b.event.startsAt,
      idMatch: b.event.id,
      idSport: b.sport && b.sport.id ? b.sport.id : '',
      idCategory: b.category && b.category.id ? b.category.id : '',
      idTournament: b.tournament && b.tournament.id ? b.tournament.id : '',
      idBet: b.market.id,
      idMatchBet: b.eventMarketId,
      idBetOutcome: b.marketOutcome.id,
      idMatchBetOutcome: b.eventMarketOutcomeId,
      betDisplayName: b.market.name,
      outcomeDisplayName: b.marketOutcome.name,
      oddValue: b.odd,
      specialValue: b.specialValues && b.specialValues.length > 0 ? b.specialValues[0] : '',
      results: normalizePrematchCheckResults(b.results)
    };

    const competitors =
      b.eventCompetitors && b.eventCompetitors.length ? [...b.eventCompetitors] : [];
    competitors.sort(prematchTeamSort);
    const teams = competitors.map(team => {
      return {
        teamDisplayName: team.teamName
      };
    });
    bet.teams = teams;

    const marketCompetitors =
      b.eventMarketCompetitors &&
        isArray(b.eventMarketCompetitors) &&
        b.eventMarketCompetitors.length
        ? b.eventMarketCompetitors
        : [];
    bet.competitors = marketCompetitors.map(mc => {
      let name = '';

      if (mc && mc.player && mc.player.displayName) {
        name = mc.player.displayName;
      } else if (mc && mc.player) {
        name = mc.player.name + ' ' + mc.player.surname;
      }

      return {
        id: mc.id,
        name
      };
    });

    if (bet.competitors.length > 0) {
      bet.betDisplayName += ' - ' + bet.competitors[0].name;
    }

    if (banker) bankers += 1;

    // for prematch tickets we need to set the BR ID inside the ticket bets
    // so we can detect when we need to recheck the ticket after a live match
    // has completed
    bet.brId = null;

    if (
      bst &&
      bst.prematch &&
      bst.prematch.matches &&
      typeof bst.prematch.matches[bet.idMatch] !== 'undefined'
    ) {
      bet.idSport = bst.prematch.matches[bet.idMatch].idSport;
      bet.idCategory = bst.prematch.matches[bet.idMatch].idCategory;
      bet.idTournament = bst.prematch.matches[bet.idMatch].idTournament;

      if (!bst.prematch.matches[bet.idMatch].brId) {
        debug(`prematch event ${bet.idMatch} has no BR ID`);
      } else {
        bet.brId = bst.prematch.matches[bet.idMatch].brId;
      }
    }

    ts.push(bet.startAt);

    if (bet.status === 'LOST') {
      lost += 1;
    }
    if (bet.status === 'WON') {
      won += 1;
    }

    return bet;
  });

  ticket.systemType = systems.join(',') + '/' + t.ticketBets.length;
  if (bankers)
    ticket.systemType = bankers + 'B+' + systems.join(',') + '/' + (t.ticketBets.length - bankers);

  ticket.betsRes = {
    lost,
    won
  };

  ticket.maxTime = Math.max(...ts);
  ticket.minTime = Math.max(...ts);

  //debug("normalize prematch", ticket, t);

  return ticket;
}

const normalizeLuckySixTicket = t => {
  debug('normalize lucky six', t);

  const ticket = {
    id: t.id,
    idHash: t.id,
    product: 'LuckySix',
    format: 'luckysix',
    type: 'SIMPLU',
    status: t.status && t.status.value ? t.status.value.toUpperCase() : '',
    // normalize from here
    winning: t.winnings, // t.maxWinnings,
    minWinning: t.winningAmountMin ? t.winningAmountMin : 0, // t.minWinnings ? t.minWinnings : 0,
    totalOdd: 0,
    stake: t.payin, // t.payment,
    cashouted: false,
    createdAt: new Date(t.ticketDateTime).getTime(),
    hasBonus: false,
    minBonus: 0,
    maxBonus: 0,
    minBonusPercentage: 0,
    maxBonusPercentage: 0,
    bonusWonAmount: 0,
    paidOutBonus: 0,
    bonusEligibles: [],
    cashBack: 0,
    cashout: false,
    online: t.betshopId ? false : true,
    retail: t.betshopId ? 'Retail' : 'Web',
    payout: t.possiblePayout ? t.possiblePayout : 0, // t.payout,
    //wf_event_balance_multiplier: t.wf_event_balance_multiplier ? t.wf_event_balance_multiplier : 0,
  };

  if (ticket.status === 'PAYEDOUT') {
    ticket.status = 'WON';
    ticket.origStatus = 'PAID OUT';
  }

  if (ticket.status === 'PENDING') {
    ticket.status = 'OPEN';
    ticket.origStatus = 'PENDING';
  }

  let won = 0;
  let lost = 0;

  ticket.bets = t.bets.map((b, i) => {
    const bet = {
      status: b.status && b.status.value ? b.status.value.toUpperCase() : '',
      startAt: b.eventDateTime,
      idEvent: b.eventId,
      name: b.typeValue ? b.typeValue : (b.bet && b.bet.title ? b.bet.title : ''),
      oddValue: b.odd,
      value: b.value && typeof b.value === 'string' ? b.value : '',
      amount: b.amount,
      possibleWin: b.winnings,
    };

    if (b.combinations && Array.isArray(b.combinations)) {
      b.combinations.find(c => {
        if (c.status && c.status.value && c.status.value.toUpperCase() === 'WON') {
          bet.value = c.value;
          bet.amount = c.amount.toFixed(2);
          bet.oddValue = c.odd;
          bet.possibleWin = c.possibleWin;

          return true;
        }

        return false;
      });
    }

    if (bet.status === 'LOST') {
      lost += 1;
    }
    if (bet.status === 'WON') {
      won += 1;
    }

    return bet;
  });

  ticket.betsRes = {
    lost,
    won
  };

  return ticket;
};

const normalizeGrayhoundTicket = t => {
  const ticket = normalizeLuckySixTicket(t);

  ticket.product = 'GreyhoundRaces';
  ticket.format = 'grayhound';

  return ticket;
};

export {
  normalizeLiveTicket,
  normalizeLiveTickets,
  normalizePreMatchTicket,
  normalizePreMatchTickets,
  normalizeLiveReservedTickets,
  normalizePreMatchReservedTickets,
  normalizePreMatchCheckTicket,
  adjustWinnerFunWinnings,
  getPrematchTicketsEventIds
};
