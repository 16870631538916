import Phaser from "phaser";

class LoadingBar extends Phaser.GameObjects.Arc
{
    constructor(scene, radius) {
        super(scene, 0, 0, radius, 0);
        this.scene = scene;

        this.progress = 0;

        this.setStrokeStyle(2, 0x008e35);
        this.setClosePath(false);

        // add me to the scene
        this.scene.add.existing(this);
    }

    updateProgress(value) {
        let coef = 100/(value * 100);
        this.progress = Number.parseInt(360/coef);
        // console.log(this.progress);
        // console.log(value);
        this.setEndAngle(this.progress);
    }
}

export default LoadingBar;