import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import * as storeActions from "../../../store/actions";
import Icons from "../../../assets/Icons/Icons";
import {withTranslation} from "react-i18next";
import './RedeemCode.scss';
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {CircularProgress} from "@material-ui/core";

const RedeemCode = (props) => {
    const INPUT_TEXT = props.t("VOUCHER CODE")
    const [popupOpen, setPopupOpen] = useState(false);
    const [code,setCode] = useState(INPUT_TEXT);
    const [loading, setLoading] = useState(false);
    const [errorCode, setErrorCode] = useState(false);
    const [showSuccess,setShowSuccess] = useState(false);
    const openPopup = () => {
        props.resetReducer();
        setErrorCode(false);
        setPopupOpen(true);
        setShowSuccess(false);
    }
    const handleClose = () => {
        setPopupOpen(false)
    }

    const changeHandler = (event) => {
        setCode(event.target.value.trim());
    }

    const confirm = () => {

        props.sendVoucherCode(code);
        setLoading(true)
    }

    const focusHandler = () => {
        if (code === INPUT_TEXT) {
            setCode('');
        }
        setErrorCode('')
    }

    const blurHandler = () => {
        if (code === '') {
            setCode(INPUT_TEXT);
        }
    }

    useEffect(() => {
        props.resetReducer();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!props.redeemCode.loading && props.redeemCode.response !== null) {
            if (props.redeemCode.response === false) {
                setErrorCode(1);
                setCode('');
            } else if (typeof props.redeemCode.response.ResponseCode !== "undefined") {
                setErrorCode(props.redeemCode.response.ResponseCode);
                setCode('');
            } else {
                if (loading) {
                    setShowSuccess(true);
                } else {
                    setErrorCode(false);
                }
            }
        }
    }, [props.redeemCode]); // eslint-disable-line

    let errorMessage = false;
    switch (errorCode) {
        case 1:
            errorMessage = props.t('An error has occurred. Please try again later.');
            break;
        case 37:
            errorMessage = props.t('Invalid code. Please verify the value');
            break;
        case 38:
            errorMessage = props.t('This code has reached its maximum number of activations');
            break;
        case 39:
            errorMessage = props.t("You have already participated in this voucher campaign.");
            break;
        default:
            break;
    }
    let disabled = code === '' || code === 'VOUCHER CODE';
    return (
        <div className={"voucher-code"}>
            <section className={'actions'}>
                <i>
                    {Icons.get('diamond', `DiamondIcon`)}
                </i>
                <div className="">
                    <p>{props.t("Got a voucher code?")}</p>
                    <button className={"WithdrawButton"} onClick={openPopup}>
                        <span>{props.t('Enter code')}</span>
                    </button>
                </div>
            </section>
            {!showSuccess && <Dialog
                open={popupOpen}
                fullWidth={true}
                maxWidth={"sm"}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={`voucher-code-popup betsModal betsModalDialog`}
            >
                <DialogContent className="wf-dialog-content">

                    <div className="wf-title">{props.t("Enter the code to claim your prize")}</div>

                    <div className="wf-input-body">
                        <div className="wf-input">
                            <input type={'text'}
                                   id={'vocuher'}
                                   onChange={(e) => changeHandler(e)}
                                   value={code}
                                   required
                                   onKeyPress={() => {}}
                                   disabled={false}
                                   onBlur={() => {blurHandler()}}
                                   onFocus={() => {focusHandler()}}
                            />
                            {errorMessage && <div className="error">{errorMessage}</div>}
                        </div>
                    </div>

                    {!props.redeemCode.loading && <div className="wf-button-wrapper">
                        <div>
                            <ButtonBase disabled={disabled} className="wf-button" onClick={confirm}>
                                {props.t("Confirm")}
                            </ButtonBase>
                        </div>
                        <div>
                            <ButtonBase  onClick={handleClose}>
                                {props.t("Close")}
                            </ButtonBase>
                        </div>
                    </div>}
                    {props.redeemCode.loading  && <CircularProgress className={'Loading'}/>}
                </DialogContent>
            </Dialog>}
            {showSuccess && <Dialog
                open={popupOpen}
                fullWidth={true}
                maxWidth={"sm"}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={`voucher-code-popup betsModal betsModalDialog`}
            >
                <DialogContent className="wf-dialog-content">

                    <div className="wf-title">
                        {props.t("Success")}
                    </div>

                    <div className="wf-input-body">
                        <div className="wf-text">
                            {props.t("The claimed prize is available in 'My Bonuses'")}
                        </div>
                    </div>
                    <div className="wf-button-wrapper">
                        <div>
                            <ButtonBase className="wf-button" onClick={()=>props.history.push('/')}>
                                {props.t("Confirm")}
                            </ButtonBase>
                        </div>
                        <div>
                            <ButtonBase  onClick={handleClose}>
                                {props.t("Close")}
                            </ButtonBase>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>}
        </div>

    );
};

const mapStateToProps = state => {
    return {
        redeemCode: state.redeemCode
    }
};

const mapDispatchToProps = dispatch => {
    return {
        sendVoucherCode: (code) => dispatch(storeActions.redeemCode(code)),
        resetReducer: () => dispatch(storeActions.redeemCodeReset())
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)((RedeemCode))));
