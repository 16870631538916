import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { ReactComponent as RightIcon } from "./assets/arrowRight.svg";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import EventTimer from "./Timer";
import OddsTable from "../OddsTable";
import evBus from "../../utils/evbus";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: "0",
    margin: "12px 0 0",
    padding: "0",
  },
  eventName: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "10px",
    lineHeight: "10px",
    color: "#9EA1AC",
    position: "relative",
    top: "-2px"
  },
  header: {
    marginTop: "3px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between"
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  title: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
  },
  description: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#17214D",
  },
  ticketData: {
    flex: "1 1 0",
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "right",
    color: "#000",
    padding: "3px 0 4px",
    display: "inline-block",
    whiteSpace: "nowrap"
  },
  won: {
    borderRight: "3px solid #06D2BD",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block",
    "& svg": {
      display: "inline-block"
    }
  },
  lost: {
    borderRight: "3px solid #FF196E",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block"
  },
  possibleWin: {
    display: "inline-block",
    marginRight: "10px"
  },
  rightSvg: {
    height: "14px",
    width: "auto",
    fill: "#7f7f7f",
    display: "inline-block",
    verticalAlign: "middle",
    float: "right",
    position: "relative",
    top: "2px"
  },
  hr: {
    borderTop: "1px solid #B7CDD8",
    height: "1px",
    width: "100%",
    margin: "3px auto 10px"
  },
  balls: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto"
  },
  ball: {
    width: "29px",
    minWidth: "29px",
    maxWidth: "29px",
    height: "29px",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#121934",
    lineHeight: "27px",
    margin: "3px 0",
    border: "1.5px solid #3D4D8E",
    background: "#fff",
  },
  ballsFlexStart: {
    justifyContent: "flex-start",
    "&>div": {
      margin: "3px 2px",
    }
  },
  ballWinner: {
    color: "#fff",
    border: "1.5px solid #00C7B2",
    background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)",
  },
  ballWinnerNow: {
    color: "#fff",
    border: "1.5px solid #fff",
    background: "linear-gradient(180deg, #F20732 0%, #F2CA00 100%)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.57)"
  },
  ballEmpty: {
    color: "#E9E9E9",
    border: "1.5px solid #E9E9E9",
    background: "#fff",
  },
  ballLost: {
    border: "1.5px solid #FF196E",
    background: "#FF196E",
    color: "#fff",
  },
  infoPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "13px",
  },
  timer: {

  },
  timerTitle: {
    fontSize: "11px",
    fontWeight: "300",
    color: "#5A6798",
  },
  stake: {
    backgroundColor: "#E6EEF1",
    borderRadius: "12px",
    padding: "12px 34px"
  },
  stakeHeader: {
    textAlign: "center",
    fontSize: "9px",
    fontWeight: "600",
    color: "#586682",
  },
  stakeAmount: {
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bold",
    color: "#17214D",
  },
  results: {
    margin: "15px -5px",
    borderRadius: "7px",
    padding: "2px 5px",
    backgroundColor: "#E6EEF1"
  },
  link: {
    color: "#17214D",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    margin: "10px 0"
  },
  headerTicket: {
    height: "34px",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "uppercase",
    display: "flex",
    width: "100%",
    "&>.first": {
      minWidth: "0",
      width: "100%",
      height: "34px",
      lineHeight: "34px",
      padding: "0 20px"
    },
    "&>.second": {
      cursor: "pointer",
      minWidth: "45px",
      maxWidth: "45px",
      width: "45px",
      height: "34px",
      lineHeight: "34px",
      textAlign: "center",
      background: "rgba(255,255,255,.2)",
      "& svg": {
        fontSize: "2em"
      }
    }
  },
  headerOPEN: {
    backgroundColor: "#7E7D80",
    color: "#E8E8E8",
  },
  headerLOST: {
    backgroundColor: "#845858",
    color: "#E8E8E8",
  },
  headerWON: {
    backgroundColor: "#008A41",
    color: "#E8E8E8",
  },
  ticketWrapper: {
    margin: "13px",
    paddingBottom: "13px",
  }
});

const noop = () => { };

const Ticket = (props) => {
  const classes = useStyles();
  const { ticket, className, onLive, t } = props;

  const [showDetails, setShowDetails] = React.useState(false);

  const handleClick = (e) => {
    if (e) e.stopPropagation();
    setShowDetails(v => !v);
  };

  let rootCls = classes.root;
  if (className) {
    rootCls += ` ${className}`;
  }

  let description = '';
  if (ticket.systems && ticket.systems.length) {
    if (ticket.systems.length === 1 && ticket.numbers >= ticket.systems[0]) {
      description = `| ${t("SIMPLE")}`;
    } else {
      description = `| ${t("SYSTEM")} ${ticket.systems.join(",")}/${ticket.event_M}`;
    }
  }

  const status = ticket.status;
  let amount = "0.00";
  if (ticket.status === "OPEN") {
    amount = ticket.max_winning;
  } else if (ticket.status === "WON") {
    amount = ticket.gross_winning_amount;
  } else if (ticket.status === "LOST") {
    amount = "0.00";
  }
  const event_date = moment(ticket.event_time / 1e+6).format("YYYY-MM-DD HH:mm:ss");

  const results = ticket.event_results ? ticket.event_results : [];

  const numbers = [];
  const winner_numbers = {};
  ticket.numbers.forEach(no => {
    numbers.push({ n: no, s: 0 });
    winner_numbers[no] = true;
  });

  let displayResults = null;
  if (results && results.length) {
    const line1 = [...Array(ticket.event_R > 10 ? 10 : ticket.event_R).keys()].map(i => {
      if (typeof results[i] !== "undefined") {
        let no = results[i];
        const rootCls = `${classes.ball} ${winner_numbers[no] ? classes.ballWinner : classes.ballLost}`;
        return <div key={i} className={rootCls}>{no}</div>;
      }
      return <div key={i} className={classes.ballEmpty}>-</div>;
    });
    let line2 = null;
    if (ticket.event_R > 10) {
      line2 = [...Array(ticket.event_R - 10).keys()].map(ix => {
        const i = ix + 10;
        if (typeof results[i] !== "undefined") {
          let no = results[i];
          const rootCls = `${classes.ball} ${winner_numbers[no] ? classes.ballWinner : classes.ballLost}`;
          return <div key={i} className={rootCls}>{no}</div>;
        }
        return <div key={i} className={classes.ballEmpty}>-</div>;
      });
    }
    if (showDetails) {
      displayResults = (
        <React.Fragment>
          <div className={classes.results}>
            <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>{line1}</div>
            {!!line2 && <div className={`${classes.balls} ${ticket.event_R - 10 < 10 ? classes.ballsFlexStart : ''}`}>{line2}</div>}
          </div>
          <div>
            <OddsTable ticket={ticket} />
          </div>
          <div className={classes.link} onClick={handleClick}>
            {t("Hide details")}
          </div>
        </React.Fragment>
      );
    } else {
      displayResults = (<div className={classes.link} onClick={handleClick}>
        {t("Details")}
      </div>)
    }
  }

  const onClick = () => {
    evBus.emit("OPEN_TICKET_DETAILS", { event_name: ticket.event_name });
  };

  return (
    <div className={rootCls} onClick={status === "OPEN" ? onClick : noop}>
      <div className={`${classes.headerTicket} ${classes[`header${ticket.status}`]}`} onClick={results && results.length ? handleClick : () => { }}>
        <div className="first">{t("Ticket Details")}</div>
        {results && results.length !== 0 && <div className="second">
          <div>
            {showDetails && <ExpandLessIcon />}
            {!showDetails && <ExpandMoreIcon />}
          </div>
        </div>}
      </div>
      <div className={classes.ticketWrapper}>
        <div className={classes.eventName}>
          <div>{ticket.event_name} #{ticket.event_code}</div>
          <div>{ticket.serial}</div>
        </div>
        <div className={classes.header}>
          <div className={classes.titleWrapper}>
            <div className={classes.title}>{t("LOTTO")}</div>
            <div className={classes.description}>&nbsp;{description}</div>
          </div>
          <div className={classes.ticketData}>
            {status === "WON" && (
              <div className={classes.won}>
                <CheckIcon />
              </div>
            )}
            {status === "LOST" && <div className={classes.lost}>X</div>}
            <div className={classes.possibleWin}>{amount} Lei</div>
            {status === "OPEN" && <RightIcon className={classes.rightSvg} />}
          </div>
        </div>
        <div className={classes.hr}></div>
        <div className={classes.infoPanel}>
          <div className={classes.timer}>
            <EventTimer date={event_date} onLive={onLive} status={status} />
          </div>
          <div className={classes.stake}>
            <div className={classes.stakeHeader}>{t("Stake")}</div>
            <div className={classes.stakeAmount}>{ticket.amount} Lei</div>
          </div>
        </div>
        <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>
          {[...Array(ticket.event_R > 10 ? 10 : ticket.event_R).keys()].map(i => {
            if (typeof numbers[i] !== "undefined") {
              const no = numbers[i];
              const winner = results && results.indexOf(no.n) > -1 ? true : false;
              const rootCls = `${classes.ball} ${winner ? classes.ballWinner : ''}`;
              return <div key={i} className={rootCls}>{no.n}</div>;
            }
            return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
          })}
        </div>
        {ticket.event_R > 10 && <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>
          {[...Array(ticket.event_R - 10).keys()].map(i => {
            const ix = i + 10;
            if (typeof numbers[ix] !== "undefined") {
              const no = numbers[ix];
              const winner = results && results.indexOf(no.n) > -1 ? true : false;
              const rootCls = `${classes.ball} ${winner ? classes.ballWinner : ''}`;
              return <div key={i} className={rootCls}>{no.n}</div>;
            }
            return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
          })}
        </div>}
        {displayResults}
      </div>
    </div>
  );
}

Ticket.propTypes = {
  ticket: PropTypes.object,
  className: PropTypes.string,
  onLive: PropTypes.func
};

Ticket.defaultProps = {
  ticket: {},
  className: "",
  onLive: () => { }
};

export default withTranslation()(Ticket);