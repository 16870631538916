import * as actionTypes from './actionTypes';

export const locationsReceived = (result) => {
    return {
        type: actionTypes.deposit.LOCATIONS_RECEIVED,
        locations: result.locations
    };
};

export const openDepositModal = () => {
    return {
        type: actionTypes.deposit.OPEN_MODAL,
    };
};

export const closeDepositModal = () => {
    return {
        type: actionTypes.deposit.CLOSE_MODAL,
    };
};

export const setQueryParams = (queryParams) => {
    return dispatch => {
        dispatch({
            type: actionTypes.deposit.DIR_QUERY_PARAMS,
            queryParams: queryParams,
        });
    };
};

export const updateDepositAmount = (amount) => {
    return dispatch => {
        dispatch({
            type: actionTypes.deposit.UPDATE_AMOUNT,
            amount: amount
        })
    }
};

export const safeChargeReceived = (response) => {
    return dispatch => {
        dispatch({
            type: actionTypes.deposit.SAFECHARGE_RECEIVED,
            link: response.result.link
        })
    }
}


export const oktoCreatePaymentCode = (amount, bonusId) => {
    return {
        type: actionTypes.paymentCheckout.OKTO_CREATE_PAYMENT_CODE,
        amount: amount,
        bonusId: bonusId
    }
}

export const oktoReceivedPaymentCode = (data) => {
    return {
        type: actionTypes.paymentCheckout.OKTO_RECEIVED_PAYMENT_CODE,
        data: data
    }
}


export const oktoGetCustomer = () => {
    return {
        type: actionTypes.paymentCheckout.OKTO_GET_CUSTOMER
    }
}

export const oktoReceivedCustomer = (data) => {
    return {
        type: actionTypes.paymentCheckout.OKTO_RECEIVED_CUSTOMER,
        data: data
    }
}

export const oktoReset = () => {
    return {
        type: actionTypes.paymentCheckout.OKTO_RESET
    }
}