import React from "react";
import ModalHeader from "../../components/ModalHeader";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core/styles";
import TabsMenu from "../../components/TabsMenu";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Offer from "./Offer";
import Statistics from "./Statistics";

import {
  makeGetSports,
  makeGetCategories,
  makeGetTournaments,
  makeGetData
} from "../../store/selectors/betData";

const useStyles = makeStyles({
  root: {},
  hScroll: {
    height: "calc(100% - 104px)",
    overflowY: "auto"
  },
  headerHeight: {
    height: "56px"
  },
  empty: {
    padding: "20px 0",
    textAlign: "center",
    fontWeight: "600"
  },
});

function LeagueDetails(props) {
  const classes = useStyles();

  const {
    mType,
    data,
    idSport,
    idTournament,
    tournaments,
    t
  } = props;

  //console.log("league props", props);

  const goBack = () => {
    window.location.hash = "";
    //history.goBack();
  };
  const onAction = () => { };

  const categories = [
    { sportName: props.t("Offer") },
    //{ sportName: props.t("Statistics") },
  ];

  const [category, setCategory] = React.useState(0); // eslint-disable-line
  const handleCategoryChange = index => {
    setCategory(index);
  };

  if (data === null) {
    return (
      <React.Fragment>
        <ModalHeader type={'none'} className={classes.headerHeight} title={t("The league doesn't exists")} goBack={goBack} />
        <div className={classes.empty}>{t("Information is not available right now")}</div>
      </React.Fragment>
    );
  }

  const tTtitle = tournaments && idTournament && tournaments[idTournament] && tournaments[idTournament].tournamentName
    ? tournaments[idTournament].tournamentName : "";
  const brId = tournaments && idTournament && tournaments[idTournament] && tournaments[idTournament].tournamentName
    ? tournaments[idTournament].brId : null;

  return (
    <React.Fragment>
      <ModalHeader
        title={tTtitle}
        active={false}
        mType={mType}
        idTournament={idTournament}
        goBack={goBack}
        onAction={onAction}
        className={classes.headerHeight}
        id="_id_league_"
      />
      <TabsMenu value={category} tabs={categories} onChange={handleCategoryChange} centered />
      <SwipeableViews className={classes.hScroll} index={category} onChangeIndex={handleCategoryChange} disabled={true}>
        <div>
          <Offer mType={mType} data={data} index={category} />
        </div>
        {/*
        <div>
          <Statistics brId={brId} height={"calc(100vh - 175px)"} index={category} />
        </div>
        */}
      </SwipeableViews>
    </React.Fragment>
  );
}

const getSports = makeGetSports();
const getCategories = makeGetCategories();
const getTournaments = makeGetTournaments();
const getData = makeGetData();

const mapStateToProps = (state, props) => {
  const data = getData(state, props);

  let lData = null;

  if (data !== null) {
    const { idSport, idCategory, idTournament } = props;

    if (
      idSport in data &&
      idCategory in data[idSport] &&
      idTournament in data[idSport][idCategory]
    ) {
      lData = data[idSport][idCategory][idTournament];
    }
  }

  return {
    sports: getSports(state, props),
    categories: getCategories(state, props),
    tournaments: getTournaments(state, props),
    data: lData
  };
};

export default withTranslation()(connect(mapStateToProps)(LeagueDetails));
