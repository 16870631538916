import React, { Component } from 'react';
import { connect } from 'react-redux';
import './VerificationInput.scss';
import * as stateActions from "../../../../store/actions";
import ColumnMessage from "../../../AuthenticationModal/ColumnMessage/ColumnMessage";
import BackendClient from "../../../../BackendClient";
import Reward from "../Reward/Reward";
import ResponseErrorMessages from "../../../../store/errors/ResponseErrorMessages";
import { withTranslation } from "react-i18next";
import GTM from "../../../../utils/GTM";
import Utils from "../../../../utils/Utils";
import {TextField} from "@material-ui/core";



const CODE_LENGTH = 5;

class VerificationInput extends Component {

    constructor(props) {
        super(props);

        for (let i = 0; i < CODE_LENGTH; i++) {
            this.state.code[i] = "-";
        }

    }

    state = {
        code: [],
        message: {
            type: "",
            text: "",
        },
        ok: false,
        pendingRequest: false
    };

    showErrors() {
        if (this.props.type === 'simple' && !this.props.oldRegister) {
            this.setState({
                ...this.state,
                message: [],
                pendingRequest: false,
            })
            return false;
        }
        let errors = [];
        // if errors are not for validation code
        // this case shouldn't appear, but... just to be sure we catch this errors and show them in the UI
        if (typeof this.props.signedUpErrors !== 'string' && typeof this.props.signedUpErrors !== "undefined" && this.props.signedUpErrors) {
            let origErrors = this.props.signedUpErrors;
            // add each error in queue
            Object.keys(this.props.signedUpErrors).forEach(function (key) {
                if (origErrors !== null && origErrors !== undefined) {

                    // it's possible to receive an object or a string
                    // verify and push each error in the array
                    if (typeof origErrors[key] === 'string') {
                        errors.push({
                            type: "error",
                            text: origErrors[key]
                        })
                    } else {
                        if (origErrors[key]) {

                            Object.keys(origErrors[key]).forEach(function (nKey) {
                                if (typeof origErrors[key][nKey] === 'string') {
                                    errors.push({
                                        type: "error",
                                        text: origErrors[key][nKey]
                                    });
                                } else {
                                    errors['undefined'] = { type: "error", text: ResponseErrorMessages.get(0) };
                                }
                            });
                        }

                    }
                }

            });
        } else {
            errors.push({
                type: "error",
                text: this.props.signedUpErrors === "unknown" ? this.props.t("Something went wrong") : this.props.signedUpErrors,
            })
        }

        if (typeof errors[0] !== "undefined") {
            GTM.pushGAEvent("Register_" + this.props.lang,"Field - Error", "Verification Code - " + errors[0].text);
        }

        this.setState({
            ...this.state,
            message: errors,
            pendingRequest: false,
        })
    }

    componentDidUpdate(prevProps) {

        if (this.props.signedUpErrors !== prevProps.signedUpErrors) {
            this.showErrors();
        }
        if (this.props.signedUp !== prevProps.signedUp) {
            GTM.pushGAEvent("Register_" + this.props.lang,"Field - OK", "Verification Code")

            this.setState({
                ...this.state,
                pendingRequest: false,
            })
        }
    }

    refreshCode(code, index) {
        let arrCode = this.state.code;
        arrCode[index] = code;
        let finalCode = "";
        for (let i = 0; i < CODE_LENGTH; i++) {
            if (!isNaN(arrCode[i])) {
                finalCode += arrCode[i];
            } else {
                finalCode += '-';
            }
        }

        this.setState({
            ...this.state,
            code: arrCode,
        });

        return finalCode;
    }

    serverSideValidation(fieldInput) {

        this.props.signUp();

        this.setState({
            ...this.state,
            pendingRequest: true,
        })
    }

    validate(code) {
        let length = CODE_LENGTH;

        if (this.props.verificationType !== 1) {
            length++;
        }
        if (this.props.type === 'simple') {
            return code.length === length;
        }
        for (let i = 0; i < length; i++) {
            if (isNaN(code[i])) {
                return false;
            }
        }

        return true;
    }

    keyPressedHandler(event) {
        // not number
        if ((event.charCode < 48 || event.charCode > 57) &&  this.props.verificationType === 1) {
            event.preventDefault();
        }
    }

    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.code;

        } else {
            if (this.props.type === 'simple') {
                fieldInput = event.target.value;
            } else {
                let code = event.target.value;

                let targetInput = event.target.getAttribute('data-target');
                if (targetInput !== CODE_LENGTH) {
                    let elem = document.getElementById("target_" + targetInput);
                    if (elem !== null) {
                        elem.focus();
                        elem.value = "";
                    } else {
                        elem = document.getElementById("target_" + (parseInt(targetInput, 10) - 1));
                        if (elem !== null) {
                            elem.blur()
                        }
                    }
                }

                fieldInput = this.refreshCode(code, parseInt(targetInput, 10) - 1);
            }
        }

        let valid = this.validate(fieldInput);
        let ok = this.state.ok;
        let message = "";
        if (valid) {

            if (!ok) {
                ok = true;
            }
        } else {
            // errors are catched in componentDidUpdate, and they come from api request
            // theoretically, in this version, the user will not enter on this section
            if (ok) {
                ok = false;
            }
        }

        this.setState({
            ...this.state,
            message: {
                ...this.state.message,
                type: (message !== "" ? "error" : ""),
                text: message,
            },
            ok: ok,
        });

        this.props.onChange({ code: fieldInput, validated: ok });

        if (ok) {
            if (this.props.type ==='simple') {

            } else {
                this.serverSideValidation(fieldInput);
            }
        }
    }

    focusHandler(event) {
        if (!(this.props.type === 'simple')) {
            let index = event.target.getAttribute('data-target') - 1;

            let code = this.state.code;
            let length = CODE_LENGTH;

            if (this.props.verificationType !== 1) {
                length++;
            }
            for (let i = index; i < length; i++) {
                document.getElementById("target_" + i).value = "";
                code[i] = "-";
            }
            this.setState({
                ...this.state,
                code: code
            })
        }
    }

    render() {

        let HTMLInputs = [];
        if (this.props.type === 'simple') {
            HTMLInputs.push(<TextField
                {...this.props}
                id={"verification-code"}
                type={this.props.verificationType !== 1 ? "text" : "tel"}
                placeholder="*****"
                className={'simple'}
                autocomplete="one-time-code"
                onKeyPress={this.keyPressedHandler.bind(this)}
                onChange={this.changeHandler.bind(this)}
                onFocus={this.focusHandler.bind(this)}
                disabled={this.state.pendingRequest}
                key={"key-simple-verification-code"}
                value={this.props.code}
            />)
        } else {
            for (let i = 0; i < CODE_LENGTH; i++) {
                HTMLInputs.push(
                    <div className="input-group" key={"key_" + i}>
                        <input type="text" id={"target_" + i} required placeholder="&#9679;" data-target={i + 1}
                               onFocus={this.focusHandler.bind(this)} onKeyPress={this.keyPressedHandler.bind(this)}
                               onChange={this.changeHandler.bind(this)} disabled={this.state.pendingRequest}/>
                    </div>
                );
            }
        }

        return (
            <div>
                <div className="VerificationField" >
                    {HTMLInputs}

                    {typeof this.props.showReward === "undefined" || this.props.showReward && <Reward animate={this.props.reward} />}
                    <div className="clearfix"> </div>
                    <ColumnMessage msg={this.state.message} />
                </div>
                <div className="clearfix"> </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => {
            dispatch(stateActions.fieldEntered({
                field: "code",
                value: fieldInput.code,
                validated: fieldInput.validated
            }));
            dispatch(stateActions.fieldEntered({
                field: "phoneValidationCode",
                value: fieldInput.code,
                validated: fieldInput.validated
            }));
        },
        signUp:() => dispatch(stateActions.signUp(true))
    };
};

const mapStateToProps = state => {
    return {
        code: state.registerWizard.fields.code,
        fields: state.registerWizard.fields,
        signedUpErrors: state.registerWizard.signedUpErrors,
        reward: state.registerWizard.rewards.phoneValidationCode,
        lastProductId: state.application.lastProductId,
        verificationType: state.registerWizard.verificationType,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VerificationInput));
