import * as actionTypes from '../actions/actionTypes';

const initialState = {
    complaintResponse: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.notifications.COMPLAINT_RESPONSE:
            return {
                ...state,
                complaintResponse: action.response,
            };

        default:
            return state;
    }
};

export default reducer;
