import { prematchConstants } from './constants';

export const prematchSetMiniState = data => ({
	type: prematchConstants.SET_MINI_STATE,
	data
});

export const prematchSetState = (dayInfo, meta, topOffer, events, shortProps) => ({
	type: prematchConstants.SET_STATE,
	dayInfo,
	meta,
	topOffer,
	events,
	shortProps
});

export const prematchCopyState = (dayInfo, data) => ({
	type: prematchConstants.COPY_STATE,
	dayInfo,
	data
});

export const prematchMergeState = (dayInfo, data) => ({
	type: prematchConstants.MERGE_STATE,
	dayInfo,
	data
});

export const prematchShowTopOffer = show => ({
	type: prematchConstants.SHOW_TOP_OFFER,
	show
});

export const prematchInitialize = () => ({
	type: prematchConstants.INITIALIZE
});

export const prematchCreateTicketRequest = ({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable }) => ({
	type: prematchConstants.CREATE_TICKET_REQUEST,
	free_bet_code,
	free_bet_name,
	free_bet_pid,
	free_bet_redeemable
});

export const prematchAddMatches = data => ({
	type: prematchConstants.ADD_MATCHES,
	data
});

export const prematchClearExpired = () => ({
	type: prematchConstants.CLEAR_EXPIRED
});

export const prematchConnectTicketSocket = punterUuid => ({
	type: prematchConstants.CONNECT_TICKET_SOCKET,
	punterUuid
});

export const prematchAuthTicketSocket = punterUuid => ({
	type: prematchConstants.AUTH_TICKET_SOCKET,
	punterUuid
});

export const prematchDisconnectTicketSocket = () => ({
	type: prematchConstants.DISCONNECT_TICKET_SOCKET
});

export const prematchMergeMatches = data => ({
	type: prematchConstants.MERGE_MATCHES,
	data
});

export const prematchSetMatch = data => ({
	type: prematchConstants.SET_MATCH,
	data
});

export const prematchSetMatches = (data, idRequest) => ({
	type: prematchConstants.SET_MATCHES,
	data,
	idRequest
});

export const prematchFetchMatch = idMatch => ({
	type: prematchConstants.FETCH_MATCH,
	idMatch
});

export const prematchFetchMatches = (idMatch, idRequest) => ({
	type: prematchConstants.FETCH_MATCHES,
	idMatch,
	idRequest,
});

export const prematchFetchSportByDay = data => ({
	type: prematchConstants.FETCH_SPORT_BY_DAY,
	data
});

export const prematchSetLoadingState = data => ({
	type: prematchConstants.SET_LOADING_STATE,
	data
});

export const prematchFetchTournamentMatches = tournamentId => ({
	type: prematchConstants.FETCH_TOURNAMENT_MATCHES,
	tournamentId
});
