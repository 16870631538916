import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { withRouter } from 'react-router-dom';
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ClearIcon from "@material-ui/icons/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from '@material-ui/core/CircularProgress';

import TicketInProgress from "./assets/ticket-in-progress.svg";
import TicketSuccess from "./assets/ticket-success.svg";
import TicketFailed from "./assets/ticket-failed.svg";
import TicketStart from "./assets/photo-camera.svg";
import { ticketCheckCodeClose, ticketCheckCode } from "../../store/actions/tickets";

import { depositTicketSet } from "../../../deposit-ticket/store/actions/deposit-ticket";

import { doLogin } from "../../../store/actions/authentication";

import { getLottoState } from "../../store/selectors/lottoData";
import "./TicketCheckModal.scss";
import Mask from "./assets/bgMask-old.svg";

import Scanner from "./Scanner2";
import { shallowEqual } from "../../utils";

import EvBusMain from "../../../utils/evbus";
import { getAccountLimits } from "../../../deposit-ticket/store/sagas/deposit-ticket";
import { getText } from "../../../lobby/utils/functions";
import i18next from "i18next";
import getSelectedBonusValue from "../../../utils/GetSelectedBonusValue";
import { getBonuses } from "../../../store/actions/application";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    zIndex: "1301!important",
    "& .MuiPaper-rounded": {
      borderRadius: "13px",
      width: "calc(100% - 16px)",
      margin: "0px"
    }
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px",
    position: "relative"
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center",
    position: "relative",
    padding: "8px 12px"
  },
  dialogHeaderInProgress: {
    "& .dh-logo": {
      width: "38px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E",
      position: "relative"
    },
    "& .dh-text:after": {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "bottom",
      animation: "dhEllipsis steps(4,end) 900ms infinite",
      content: '"\\2026"',
      width: "0px",
      position: "absolute"
    }
  },
  mt: {
    marginTop: "1em"
  },
  mt2: {
    marginTop: "3em"
  },
  dialogHeaderSuccess: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#558707"
    }
  },
  dialogHeaderReoffered: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#958707"
    }
  },
  dialogHeaderFailed: {
    "& .dh-logo": {
      width: "46px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#FF196E"
    }
  },
  dialogHeaderStart: {
    "& .dh-logo": {
      width: "56px",
      height: "auto"
    },
    "& .dh-text": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#285C98"
    }
  },
  dialogBodyInProgress: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "600",
      margin: "0 0 2em"
    }
  },
  dialogBodySuccess: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogBodyReoffered: {
    "& .db-text": {
      color: "#47214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-bold": {
      fontSize: "15px",
      fontWeight: "bold"
    }
  },
  dialogBodyFailed: {
    "& .db-text": {
      color: "#17214D",
      fontSize: "12px",
      fontWeight: "normal"
    },
    "& .db-text.db-link": {
      color: "#939393",
      fontSize: "13px",
      textDecoration: "underline",
      fontWeight: "bold"
    }
  },
  dialogOK: {
    width: "100%",
    padding: "13px 0",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
    margin: ".5em 0 1em",
    background: "linear-gradient(90deg, #295A91 0%, #2174E0 100%)",
    flex: "1 1 auto",
    minWidth: 0,
  },
  dialogCashIt: {
    width: "100%",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    margin: ".5em 0 1em",
    background: "linear-gradient(to right, #FE1C6B 0%, #F8961D 100%)",
    flex: "1 1 auto",
    minWidth: 0,
    textTransform: "uppercase",

    "&.disabled": {
      filter: "grayscale(1)"
    },

    "& .MuiCircularProgress-root": {
      marginLeft: "10px",
    },
    "& .MuiCircularProgress-circle": {
      stroke: "#fff"
    }
  },
  ticketData: {
    textAlign: "left"
  },
  muted: {
    opacity: 0.8
  },
  won: {
    color: "#06D2BD"
  },
  lost: {
    color: "#FF196E"
  },
  fRight: {
    float: "right"
  },
  title: {
    marginBottom: "5px",
    fontSize: "13px"
  },
  subtitle: {
    marginBottom: "5px"
  },
  status: {
    margin: "5px 0",
    fontSize: "13px"
  },
  result: {
    display: "inline-block",
    marginRight: "5px",
    background: "#777",
    padding: "2px 3px",
    borderRadius: "2px",
    color: "#fff",
    fontWeight: "normal"
  },
  win: {
    fontSize: "15px",
    margin: "10px 0"
  },
  info: {
    height: "15px",
    width: "15px",
    verticalAlign: "middle",
    position: "relative",
    top: "-1px"
  },
  smaller: {
    fontSize: "11px",
    opacity: 0.7,
    margin: "0 0 5px"
  },
  input: {
    background: "#E6EEF1",
    color: "#17214D",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "8px 20px",
    borderRadius: "10px",
    border: 0
  },
  clear: {
    position: "absolute",
    //padding: "5px",
    top: "16px",
    right: "13px",
    cursor: "pointer",
    borderRadius: "50%",
    background: "#F4F4F4",
    color: "#AA0808",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: "16px",
      position: "relative",
      //left: "-1px"
    }
  },
  dialogOKOutline: {
    color: "#718FB1",
    background: "#fff",
    border: "2px solid #91B3DA",
    marginRight: "5px",
    height: "45px",
    width: "95px",
    minWidth: "95px",
    padding: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      verticalAlign: "middle",
      height: "20px",
      marginRight: "5px"
    }
  },
  scanningText: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#003D6A",
    textAlign: "middle",
    marginTop: "-1em",
    marginBottom: "1em"
  },
  results: {
    margin: "15px -7px",
    borderRadius: "7px",
    padding: "6px 7px",
    background: "#e6eef1",
  },
  balls: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto"
  },
  ball: {
    width: "29px",
    minWidth: "29px",
    maxWidth: "29px",
    height: "29px",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#121934",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "3px 0",
    border: "1.5px solid #3D4D8E",
    background: "#fff",
  },
  ballsFlexStart: {
    justifyContent: "flex-start",
    "&>div": {
      margin: "3px 2px",
    }
  },
  ballWinner: {
    color: "#fff",
    border: "1.5px solid #00C7B2",
    background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)",
  },
  ballEmpty: {
    color: "#E9E9E9",
    border: "1.5px solid #E9E9E9",
    background: "#fff",
  },
  ballLost: {
    border: "1.5px solid #FF196E",
    background: "#FF196E",
    color: "#fff",
  },
  bold: {
    color: "#17214D",
    fontSize: 12,
    fontWeight: 700,
    "&.upper": {
      textTransform: "uppercase",
    },
    "&.muted": {
      opacity: .7,
    },
    "& .slim": {
      fontWeight: 600,
    },
    "& .regular": {
      fontWeight: 400,
    }
  },
  status_won: {
    color: "#558707",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  status_lost: {
    color: "#FF196E",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  stakeHolder: {
    backgroundColor: "rgba(183,205,216,.35)",
    borderRadius: "12px",
    padding: "0 0 0 10px",
    marginTop: "30px",
    height: "55px"
  },
  stakeSubtitle: {
    fontSize: "11px",
    fontWeight: "600",
    color: "#586682",
  },
  stakeAmount: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#162A57"
  },
  currency: {
    fontSize: "11px",
    fontWeight: "300"
  },
  textCenter: {
    textAlign: "left"
  },
  mask: {
    maskImage: `url(${Mask})`,
    maskRepeat: "no-repeat",
    maskPosition: "0 0",
    maskSize: "100% 55px",
    height: "55px",
    width: "133px",
    background:
      "linear-gradient(90deg, #7B8F97 0px, #6E88A6 26.6px, #586192  133px, #149AD4 133px, #1878E5 159.6px, #022CFF 266px, #14A5D4 266px, #1878E5 292.6px, #A902FF 399px)",
    backgroundSize: "399px, 55px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px, 0px",
    margin: "0",
    borderBottomRightRadius: "12px",
    borderTopRightRadius: "12px",
    position: "relative",
    animation: "smallAnim .33s ease",
    "& .subtitle": {
      color: "#C8DAFF",
      marginLeft: "25px"
    },
    "& .title": {
      color: "#FFFFFF",
      fontSize: "20px",
      marginLeft: "25px"
    },
    "& .currency": {
      color: "#FFFFFF"
    },
    "&.medium": {
      backgroundPosition: "-133px, 0px",
      animation: "mediumAnim .33s ease"
    },
    "&.large": {
      backgroundPosition: "-266px, 0px",
      animation: "largeAnim .33s ease"
    }
  },
  checkWrapper: {
    margin: "15px 0 10px",
    width: "100%",
    "& .MuiFormControlLabel-root": {
      //width: "100%"
    },
    "& .MuiButtonBase-root": {
      padding: "2px 3px 2px 2px"
    }
  },
  checkLabel: {
    textAlign: "left",
    fontSize: "12px"
  }
});

const TicketModal = props => {
  const {
    openTicketCheck,
    ticketCheckCodeStatus,
    ticketCodeData,
    ticketCode,
    ticketCheckCodeClose,
    ticketCheckCode,
    onModalClose,
    depositTicketSet,
    doLogin,
    auth,
    t
  } = props;
  const classes = useStyles();

  const [agree, setAgree] = React.useState(false);
  const [scanning, setScanning] = React.useState(false);
  const [bonus, setBonus] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [checkingLimits, setCheckingLimits] = React.useState({
    loading: false,
    open: false,
    data: null,
  });

  React.useEffect(() => {
    if (props.retailTicketsSettings && props.retailTicketsSettings.bonusPresetId && props.retailBonuses) {
      Object.keys(props.retailBonuses).forEach((key) => {
        if (key.toString() === props.retailTicketsSettings.bonusPresetId.toString()) {
          setBonus(props.retailBonuses[key]);
        }
      })
    }
  }, [props.retailBonuses, props.retailTicketsSettings]);

  const toggleAgree = (e) => {
    setAgree(v => !v);
  };

  React.useEffect(() => {
    if (openTicketCheck) {
      setAgree(false);
      props.getBonuses({
        trigger: 4
      })
    }
  }, [openTicketCheck]);

  const handleSearchValueChange = e => {
    setSearch(e.target.value);
  };

  const doSearch = (rs) => {
    let s = "";

    if (typeof rs === "string") {
      s = rs;
    } else {
      s = search;
    }

    if (s) {
      ticketCheckCode(s);
    }
  };

  const doScan = () => {
    setScanning(true);
  };

  const stopScan = () => {
    setScanning(false);
  };

  const handleScanResult = result => {
    stopScan();

    if (result) result = result.trim().toUpperCase();

    const rres = result.split("-");
    let appearsValid = rres.length === 2 && rres[0] && rres[0].length === 8 && rres[1] && rres[1].length === 5;

    try {
      setSearch(result ? result : "");
      if (appearsValid) doSearch(result);
    } catch (err) {/*noop*/ }
  };

  const handleKeyDown = event => {
    if (event && event.key === "Enter") {
      doSearch();
    }
  };

  if (!openTicketCheck) return null;

  let nt = {}; // hold normalized ticket data
  if (ticketCheckCodeStatus === "done") {
    nt = { ...ticketCodeData };
  }

  const handleModalClose = () => {
    setSearch("");
    ticketCheckCodeClose();
    onModalClose();
    setScanning(false);
  };

  const getBonusValue = () => {

    if (!props.retailTicketsSettings) return false;

    const ticket = ticketCodeData && ticketCodeData.data ? ticketCodeData.data : null;
    let amount = ticket && ticket.win_net_amount ? ticket.win_net_amount : 0;
    return getSelectedBonusValue(bonus, amount, props.retailTicketsSettings);
  }

  const handleCashItOnline = async () => {
    setSearch("");
    setScanning(false);

    setCheckingLimits(v => ({ ...v, loading: true }));

    let limits = null;
    try {
      const response = await getAccountLimits();
      if (response.status === 'OK' && response.result) {
        if (response.result['1'].id || response.result['2'].id || response.result['3'].id) {
          limits = response.result;
        }
      }
    } catch (err) { }

    const parts = ticketCode.split("-");
    if (parts.length === 2) {
      const ticket = ticketCodeData && ticketCodeData.data ? ticketCodeData.data : null;
      depositTicketSet({
        serial: parts[0],
        pin: parts[1],
        type: "lotto",
        amount: parseFloat(ticket.win_net_amount),
        limits: limits
      });
    }

    ticketCheckCodeClose();
    onModalClose();
    if (parts.length === 2) {
      if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
        if (window.config.platformType.toString() === "1") {
          EvBusMain.emit("OPEN-DEPOSIT-TICKET-DIALOG");
          return;
        }
        props.history.push("/deposit-ticket");
      } else {
        if (window.config.platformType.toString() === "2") {
          // mobile - do a redirect
          if (window.config.cordova) {
            EvBusMain.emit("TRY-FACE-UNLOCK-LOGIN");
          } else {
            props.history.push("/connect");
          }
        } else {
          // web - show modal
          doLogin();
        }
      }
    }
  };

  let content = null;
  if (ticketCheckCodeStatus === "") {
    content = (
      <DialogContent className={classes.dialogContent}>
        {!scanning ? (
          <div className={classes.dialogBodyInProgress}>
            <div className="db-text">{t("Type in bellow your ticket code")}</div>
            <div className="db-text text-center">
              <input
                onChange={handleSearchValueChange}
                onKeyDown={handleKeyDown}
                value={search}
                type="text"
                placeholder={t("ticket code")}
                className={classes.input}
              />
            </div>
            <div className="d-flex flex-nowrap align-items-center">
              <ButtonBase className={`${classes.dialogOK} ${classes.dialogOKOutline}`} onClick={doScan}>
                <img src={TicketStart} className="dh-logo" alt="" />
                {t("Scan")}
              </ButtonBase>
              <ButtonBase className={classes.dialogOK} onClick={doSearch}>
                {t("Search Ticket")}
              </ButtonBase>

            </div>
          </div>
        ) : (<React.Fragment>
          <div className={classes.scanningText}>
            {t("Position the barcode from the ticket in order to read it")}
          </div>
          <div style={{ width: "100%", maxWidth: "100%", height: "100%", maxHeight: "100%" }}>
            <Scanner
              onDetected={handleScanResult}
              onCancel={stopScan}
            />
          </div>
        </React.Fragment>)}
      </DialogContent>
    );
  } else {
    const ticket = ticketCodeData && ticketCodeData.data ? ticketCodeData.data : null;
    let displayResults = null;
    const results = ticket ? ticket.event_results : [];

    const winner_numbers = {};
    ticket && ticket.numbers.forEach(no => {
      winner_numbers[no] = true;
    });

    if (results && results.length && ticket) {
      const line1 = [...Array(ticket.event_r > 10 ? 10 : ticket.event_r).keys()].map(i => {
        if (typeof results[i] !== "undefined") {
          let no = results[i];
          const rootCls = `${classes.ball} ${winner_numbers[no] ? classes.ballWinner : classes.ballLost}`;
          return <div key={i} className={rootCls}>{no}</div>;
        }
        return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
      });
      let line2 = null;
      if (ticket.event_r > 10) {
        line2 = [...Array(ticket.event_r - 10).keys()].map(ix => {
          const i = ix + 10;
          if (typeof results[i] !== "undefined") {
            let no = results[i];
            const rootCls = `${classes.ball} ${winner_numbers[no] ? classes.ballWinner : classes.ballLost}`;
            return <div key={i} className={rootCls}>{no}</div>;
          }
          return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
        });
      }
      displayResults = (
        <React.Fragment>
          <div className={classes.results}>
            <div className={`${classes.balls} ${ticket.event_r < 10 ? classes.ballsFlexStart : ''}`}>{line1}</div>
            {!!line2 && <div className={`${classes.balls} ${ticket.event_r - 10 < 10 ? classes.ballsFlexStart : ''}`}>{line2}</div>}
          </div>
        </React.Fragment>
      );
    } else if (ticket) {
      const line1 = [...Array(ticket.event_r > 10 ? 10 : ticket.event_r).keys()].map(i => {
        if (typeof ticket.numbers[i] !== "undefined") {
          let no = ticket.numbers[i];
          const rootCls = `${classes.ball}`;
          return <div key={i} className={rootCls}>{no}</div>;
        }
        return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
      });
      let line2 = null;
      if (ticket.event_r > 10) {
        line2 = [...Array(ticket.event_r - 10).keys()].map(ix => {
          const i = ix + 10;
          if (typeof ticket.numbers[i] !== "undefined") {
            let no = ticket.numbers[i];
            const rootCls = `${classes.ball}`;
            return <div key={i} className={rootCls}>{no}</div>;
          }
          return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
        });
      }
      displayResults = (
        <React.Fragment>
          <div className={classes.results}>
            <div className={`${classes.balls} ${ticket.event_r < 10 ? classes.ballsFlexStart : ''}`}>{line1}</div>
            {!!line2 && <div className={`${classes.balls} ${ticket.event_r - 10 < 10 ? classes.ballsFlexStart : ''}`}>{line2}</div>}
          </div>
        </React.Fragment>
      );
    }

    let description = '';
    let ticket_time = moment();
    let winning = 0;
    if (ticket) {
      if (ticket.systems && ticket.systems.length) {
        if (ticket.systems.length === 1 && ticket.numbers.length === ticket.systems[0]) {
          description = `${t("SIMPLE")} ${ticket.systems[0]}/${ticket.numbers.length}`;
        } else {
          description = `${t("SYSTEM")} ${ticket.systems.join(",")}/${ticket.numbers.length}`;
        }
      }

      ticket_time = moment.unix(Math.floor(ticket.event_time / 1e+9));
      winning = !!ticket.win_net_amount ? ticket.win_net_amount : 0;
    }

    let canBeDeposit = false;
    if (window.config.depositTicketEnabled === "1" && ticketCheckCodeStatus === "done" && ticket.status === "won" && ticketCode.indexOf("-") > -1) {
      canBeDeposit = true;
    }

    content = (
      <DialogContent className={classes.dialogContent}>
        {ticketCheckCodeStatus === "pending" && (
          <div className={classes.dialogBodyInProgress}>
            <div className="db-text">{t("We're checking the ticket status. Please wait.")}</div>
          </div>
        )}
        {ticketCheckCodeStatus === "done" && (
          <div className={classes.dialogBodySuccess}>
            {ticket && <React.Fragment>
              <div className="db-text w100 d-flex align-items-center justify-content-between">
                <div className={`${classes.bold} muted `}><span className="regular">{ticket.event_name}</span></div>
                <div className={classes.bold}><span className="slim">{t("Ticket Status")}:</span> <span className={(ticket.status === "won" || ticket.status === "paid") ? classes.status_won : classes.status_lost}>{t(ticket.status)}</span></div>{/* t("lost") t("won") t("paid") t("expired") t("unknown") */}
              </div>
              <div className="db-text w100 d-flex align-items-center justify-content-between">
                <div className={classes.bold}>{t("Lotto")} | <span className="slim">{description}</span></div>
                <div className={`${classes.bold} upper`}><span className="slim">{ticket_time.format("DD MMMM YYYY")}, {ticket_time.format("HH:mm")}</span></div>
              </div>
              {displayResults}
              <div
                className={`${classes.stakeHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center scip`}
              >
                <div className={`${classes.textCenter} scip-first`}>
                  <div className={classes.stakeSubtitle}>{t("Stake")}</div>
                  <div className={classes.stakeAmount}>
                    {ticket.amount} <span className={`${classes.currency}`}>Lei</span>
                  </div>
                </div>
                <div className={`${classes.textCenter} ${classes.mask} ${winning > 100 ? "large" : winning > 50 ? "medium" : ""} scip-third d-flex flex-column justify-content-center`}>
                  <div className={`${classes.stakeSubtitle} subtitle`}>{t("Winning")}</div>
                  <div className={`${classes.stakeAmount} title`}>
                    {winning} <span className={`${classes.currency}`}>Lei</span>
                  </div>
                </div>

              </div>
            </React.Fragment>}
            {canBeDeposit && !!getBonusValue() && <div className={'retail-ticket-bonus-lotto'}>
              <div className="text" dangerouslySetInnerHTML={{ __html: getText(props.retailTicketsSettings, `uiText.${i18next.language}`, '') }}>
              </div>
              <div className="value">
                +{getBonusValue()}
              </div>
            </div>}
            <div>
              {!canBeDeposit && <ButtonBase className={classes.dialogOK} onClick={handleModalClose}>
                {t("OK")}
              </ButtonBase>}
              {canBeDeposit && <div>
                <div className={classes.checkWrapper}>
                  <FormControlLabel
                    control={<Checkbox checked={agree} onChange={toggleAgree} />}
                    label={
                      <span className={classes.checkLabel}>
                        <Trans i18nKey="agreeRetailTicketsTerms" t={t} >
                          I agree to the <a href="https://storage.googleapis.com/winner-production/documents/termsAndConditions/ro/Declaratie%20client%20DREAM%20BETTING%20plata%20bilet%20retail%20in%20online%20.pdf" target="_blank" rel="noreferrer noopener">terms</a> of payment of the ticket
                        </Trans>
                      </span>
                    }
                  />
                </div>
                <ButtonBase className={`${classes.dialogCashIt} ${!agree || checkingLimits.loading ? 'disabled' : ''}`} onClick={handleCashItOnline} disabled={!agree || checkingLimits.loading}>
                  {t("Cash it online")} {checkingLimits.loading && <CircularProgress size={20} thickness={3} />}
                </ButtonBase>
              </div>}
            </div>
          </div>
        )}
        {ticketCheckCodeStatus === "error" && (
          <div className={classes.dialogBodyFailed}>
            <div className="db-text">
              {t(ticketCodeData.toString())}
            </div>
            <div className={`${classes.mt} db-text`}>
              {t("If the problem persists, please contact the Customer Support department.")}
            </div>
            <div>
              <ButtonBase className={classes.dialogOK} onClick={handleModalClose}>
                {t("OK")}
              </ButtonBase>
            </div>
          </div>
        )}
      </DialogContent>
    );
  }

  return (
    <Dialog
      open={openTicketCheck}
      fullWidth={true}
      maxWidth={"xs"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`${classes.root} betsModal betsModalDialog`}
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">
        <div className={classes.clear} onClick={handleModalClose}>
          <ClearIcon />
        </div>
        {ticketCheckCodeStatus === "pending" && (
          <div className={classes.dialogHeaderInProgress}>
            <div>
              <img src={TicketInProgress} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Ticket verification in progress")}</div>
          </div>
        )}
        {ticketCheckCodeStatus === "done" && (
          <div
            className={
              nt.status === "LOST" ? classes.dialogHeaderFailed : classes.dialogHeaderSuccess
            }
          >
            <div>
              <img
                src={nt.status === "LOST" ? TicketFailed : TicketSuccess}
                className="dh-logo"
                alt=""
              />
            </div>
            <div className="dh-text">{t("Ticket Info")}</div>
          </div>
        )}
        {ticketCheckCodeStatus === "error" && (
          <div className={classes.dialogHeaderFailed}>
            <div>
              <img src={TicketFailed} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Verification failed")}</div>
          </div>
        )}
        {ticketCheckCodeStatus === "" && (
          <div className={classes.dialogHeaderStart}>
            <div>
              <img src={TicketStart} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t("Ticket Verification")}</div>
          </div>
        )}
      </DialogTitle>
      {content}
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  const bst = getLottoState(state);
  return {
    bst: bst,
    ticketCheckCodeStatus: bst.tickets.ticketCheckCodeStatus,
    ticketCodeData: bst.tickets.ticketCodeData,
    ticketCode: bst.tickets.ticketCode,
    auth: state.authentication,
    retailTicketsSettings: state.config.retailTicketsSettings,
    retailBonuses: state.application.retailBonuses
  };
};

const actionCreators = {
  ticketCheckCodeClose,
  ticketCheckCode,
  depositTicketSet,
  doLogin,
  getBonuses
};

export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(React.memo(TicketModal, (pp, np) => {
  //debug("pp", pp, "np", np);

  if (!shallowEqual(pp, np)) {
    return false;
  }

  return true;
}))));
