import * as actionTypes from './actionTypes';

export const promotionsReceived = (promotions) => {
    return dispatch => {
        dispatch({
            type: actionTypes.promotions.RECEIVED,
            promotions: promotions,
        });
    };
};

export const getPromotions = () => { };