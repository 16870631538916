import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import clsx from "clsx";

import { mergeStyles } from "../../styles";
import eventStyles from "./styles";
import LiveBadge from "../../elements/LiveBadge";
import { stdLiveScore } from "../../utils/matchUtils";
import { makeGetMatchStatus, makeGetStateMatch } from "../../store/selectors/betData";
import Favorite from "../../elements/Favorite";
import StatScoreAvailable from "../StatScoreAvailable";
import { getBetsState } from "../../store/selectors/betData";
import { chooseTextLanguage } from "../../utils";
import { activeWinnerFunCampaigns } from "../../utils/winnerFunUtils";
import { evaluateMatch } from "../../utils/winnerFunEvaluation";
import MultiplierIcon from "../Tickets/assets/multiplier-icon.png";

const useStyles = makeStyles({
  root: {
    margin: "4px 0 6px",
    cursor: "pointer"
  },
  startTime: {
    fontSize: 9,
    fontWeight: 600,
    backgroundColor: "#313F80",
    borderRadius: 5,
    color: "#fff",
    padding: "4px 8px 4px",
    textTransform: "capitalize"
  },
  liveWrapper: {
    padding: "7px 4px 0px 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    alignItems: "center",
    alignContent: "center"
  },
  liveEventInterval: {
    minWidth: 24,
    fontSize: 12,
    fontWeight: 600,
    color: "#06D2BD"
  },
  liveEventTime: {
    //marginTop: "7px",
    //minWidth: 24,
    fontSize: 11,
    fontWeight: 600,
    color: "#009ED9",
    marginLeft: "10px"
  },
  emptyEventTime: {
    "&:after": {
      content: "'\\200b'"
    }
  },
  teamLine: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "baseline"
  },
  teamScore: {
    fontSize: 12,
    fontWeight: 600,
    marginLeft: "8px"
  },
  teamScoreTotal: {
    color: "#06D2BD"
  },
  eventTitle: {
    textTransform: "uppercase",
    fontSize: 9,
    fontWeight: 700,
    padding: "1px 0",
    borderRadius: 4,
    color: "#979DAB",
    cursor: "pointer",
  },
  dark: {
    color: "#fff"
  },
  muted: {
    opacity: 0.6
  },
  w100: {
    width: "100%"
  },
  liveBadge: {
    position: "relative",
    left: "-5px"
  },
  totals: {
    fontSize: "11px",
    color: "rgb(151, 157, 171)",
    marginLeft: "10px"
  },
  fixed: {
    display: "inline-block",
    textTransform: "uppercase",
    fontSize: "9px",
    fontWeight: "600",
    marginLeft: "5px",
    backgroundColor: "#B7CDD8",
    padding: "2px 8px",
    color: "#fff",
    borderRadius: "5px"
  },
  pr: {
    position: "relative",
    width: "100%",
    "&>div:last-child": {
      marginRight: "0!important",
    }
  },
  winFunIcon: {
    height: "18px",
    display: "inline-block",
    width: "auto",
    margin: "0 5px 0 0",
    position: "relative",
    top: "1px"
  },
  cashout: {
    borderRadius: "5px",
    background: "#FA7036",
    fontSize: "7px",
    textTransform: "uppercase",
    fontWeight: "600",
    color: "#fff",
    padding: "2px 5px",
    marginRight: "5px",
    whiteSpace: "nowrap",
  },
  pr0: {
    paddingRight: "0!important"
  }
});

// Soccer, Baseball, Basketball, Tennis, Badminton, Beach Volley, Squash
const cashoutAvailable = ["1", "3", "2", "5", "31", "34", "37"];

const Live = props => {
  const classes = mergeStyles(useStyles, eventStyles);

  const {
    match,
    matchStatus,
    fixedOdd,
    dark,
    isWinnerFun,
    winnerFunCampaigns,
    i18n,
    t
  } = props;

  if (!match) return null;

  let winnerFunTitle = "";
  let winnerFunEvent = false;
  if (isWinnerFun) {
    const winnerFunData = activeWinnerFunCampaigns(winnerFunCampaigns);
    if (winnerFunData && winnerFunData.event) {
      if (winnerFunData.event.filters && winnerFunData.event.filters.prematch) {
        const fs = winnerFunData.event.filters.prematch;
        winnerFunTitle = chooseTextLanguage(winnerFunData.event.name.text, i18n.language);
        winnerFunEvent = evaluateMatch(fs, match);
      }
    }
  }

  const scoreData = stdLiveScore(match, matchStatus);

  const canCashout = cashoutAvailable.findIndex(elem => match.idSport === elem) > -1;

  return (
    <div className={classes.root}>
      <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
        <div className={`d-flex flex-row flex-nowrap align-items-center ${classes.pr}`}>
          <div className={classes.liveBadge}>
            <LiveBadge />
          </div>

          {winnerFunEvent && <img src={MultiplierIcon} alt="" className={classes.winFunIcon} title={winnerFunTitle} />}
          {scoreData.intervalTime !== "" && <div
            className={clsx(classes.liveEventTime, {
              [classes.emptyEventTime]: scoreData.intervalTime === ""
            })}
          >
            <div>{scoreData.intervalName} {scoreData.intervalTime}</div>
          </div>}
          {scoreData.intervalTime === "" && <div className={classes.liveEventTime}>
            {scoreData.intervalName}
          </div>}
          <div className="flex-grow-1"></div>
          {canCashout && <div className={classes.cashout}>{t("CASHOUT")}</div>}
          <StatScoreAvailable
            idMatch={match.mType === "live" ? match.idMatch : match.brId}
            mType={match.mType}
            type={"classic"}
            provider={match.provider}
            hasScout={match.hasScout}
            hasLiveTV={match.hasLiveTV}
            className={classes.pr0}
            idSport={match.idSport}
          />
        </div>
        <Favorite
          justSpecial={true}
          mType={match.mType}
          dark={dark}
          idMatch={match.idMatch}
          winnerPlus={match.winnerPlus}
          winnerAdv={match.winnerAdv}
          winnerSpecial={match.winnerSpecial}
        />
        {fixedOdd && fixedOdd.banker && <div className={classes.fixed}>{t("fixed")}</div>}
      </div>
    </div>
  );
};

const getMatchStatus = makeGetMatchStatus();
const getStateMatch = makeGetStateMatch();

const makeMapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  return {
    match: getStateMatch(state, props),
    matchStatus: getMatchStatus(state, props),
    isWinnerFun: bst.app.isWinnerFun,
    winnerFunCampaigns: bst.config.winnerFunCampaigns
  };
};

export default withTranslation()(connect(makeMapStateToProps)(Live));
