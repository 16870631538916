/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 21/03/2019
 * Time: 17:45
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Game from './../Game/Game';
import Icons from '../../../assets/Icons/Icons';
import { withTranslation } from "react-i18next";
import "./TableGames.scss";

class TableGames extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let tableGamesIcon = Icons.get('table_game', 'table-games-icon');

        let stateTableGames = this.props.games.tableGames;
        let tableGames = [];
        stateTableGames.every((element, index) => {
            tableGames.push(<Game key={"table-game_" + element.id} gameItem={element} section="tableGames" />);
            return true;
        });
        if (tableGames.length) {

            return (
                <div className="TableGames">
                    <h1>
                        <span className="icon">{tableGamesIcon}</span>
                        <div className="texts">
                            <span className="text">{this.props.t("Table Games")}</span>
                        </div>
                        <div style={{
                            clear: "both",
                        }}></div>
                    </h1>
                    <div className="GamesCards">{tableGames}</div>
                    <div style={{
                        clear: "both",
                    }}></div>
                </div>
            );
        } else {
            return (<Fragment> </Fragment>);
        }
    }

}

const mapStateToProps = state => {
    return {
        games: state.games,
    }
};

export default withTranslation()(connect(mapStateToProps)(TableGames));
