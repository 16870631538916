import moment from "moment";
import { createSelector } from "reselect";
import isArray from "lodash/fp/isArray";
import cloneDeep from "lodash/fp/cloneDeep";

const DEBUG = false;

const emptyObject = {};
const emptyArray = [];
const nullValue = null;

export const getEvents = state => Object.values(state.happyHour.status);
const getEventsObj = state => state.happyHour.status;
const getRunning = state => state.happyHour.running;
const getPrizeWon = state => state.happyHour.prizeWon;
const getStatusCompleted = state => state.happyHour.statusCompleted;
const getBetInfo = state => state.happyHour.betInfo;
const getShowInfo = state => state.happyHour.showInfo;

const getEventType = (state, props) => {
    if (props.event_type) {
        return props.event_type
    }

    return "online-slots";
}

export const makeGetEventByType = () => createSelector(
    [getEventsObj, getEventType],
    (evs, et) => {
        DEBUG && console.log("events", evs, "et", et);
        return evs[et];
    }
);

export const makeGetRunningByType = () => createSelector(
    [getRunning, getEventType],
    (rn, et) => {
        DEBUG && console.log("running", rn, "et", et);
        return rn[et];
    }
);

export const makeGetShowInfoByType = () => createSelector(
    [getShowInfo, getEventType],
    (si, et) => {
        DEBUG && console.log("showInfo", si, "et", et);
        return si[et];
    }
);

export const makeGetPrizeWonByType = () => createSelector(
    [getPrizeWon, getEventType],
    (pw, et) => {
        DEBUG && console.log("prizeWon", pw, "et", et);
        return pw[et];
    }
);

export const makeGetStatusCompletedByType = () => createSelector(
    [getStatusCompleted, getEventType],
    (sc, et) => {
        DEBUG && console.log("statusCompleted", sc, "et", et);
        return sc[et];
    }
);

export const makeGetBetInfoByType = () => createSelector(
    [getBetInfo, getEventType],
    (bi, et) => {
        DEBUG && console.log("betInfo", bi, "et", et);
        return bi[et];
    }
);
