import { Filter } from "@pixi/core";
import { hex2rgb, rgb2hex } from "@pixi/utils";

const fragment = `
precision mediump float;
varying vec2 vTextureCoord;
uniform sampler2D uSampler;
uniform vec3 color;
uniform float uAlpha;

vec3 hsl2rgb( in vec3 c )
{
  vec3 rgb = abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),6.0)-3.0)-1.0;
  return c.z + c.y * (rgb-0.5)*(1.0-abs(2.0*c.z-1.0));
}

vec3 rgb2hsl( in vec3 c ){
  float h = 0.0;
  float s = 0.0;
  float l = 0.0;
  float r = c.r;
  float g = c.g;
  float b = c.b;
  float cMin = min( r, min( g, b ) );
  float cMax = max( r, max( g, b ) );

  l = ( cMax + cMin ) / 2.0;
  if ( cMax > cMin ) {
    float cDelta = cMax - cMin;
    s = l < .0 ? cDelta / ( cMax + cMin ) : cDelta / ( 2.0 - ( cMax + cMin ) );
    if ( r == cMax ) {
      h = ( g - b ) / cDelta;
    } else if ( g == cMax ) {
      h = 2.0 + ( b - r ) / cDelta;
    } else {
      h = 4.0 + ( r - g ) / cDelta;
    }
    if ( h < 0.0) {
      h += 6.0;
    }
    h = h / 6.0;
  }
  return vec3( h, s, l );
}

void main(void) {
  vec4 currentColor = texture2D(uSampler, vTextureCoord);

  if (uAlpha == 0.0) {
    gl_FragColor = currentColor;
    return;
  }

  vec3 cc3 = vec3(currentColor.r, currentColor.g, currentColor.b);
  vec3 ccsl = rgb2hsl(cc3);
  vec3 csl = rgb2hsl(color);
  vec3 cr = hsl2rgb(vec3(csl.r, csl.g, ccsl.b));

  vec3 cr2 = mix(cc3, cr, uAlpha);

  cr2 *= currentColor.a;

  gl_FragColor = vec4(cr2, currentColor.a);
}`;

class ColorOverlayFilter extends Filter {
  constructor(color = 0x000000) {
    const colorArr = new Float32Array(3);
    hex2rgb(color, colorArr);
    const uniforms = {
      color: colorArr,
      uAlpha: 1.0
    };
    super(null, fragment, uniforms);

    this.color = colorArr;
    this.alpha = 1.0;
  }

  set color(value) {
    let arr = this.uniforms.color;
    if (typeof value === "number") {
      hex2rgb(value, arr);
      this._color = value;
    } else {
      arr[0] = value[0];
      arr[1] = value[1];
      arr[2] = value[2];
      this._color = rgb2hex(arr);
    }
  }
  get color() {
    return this._color;
  }

  set alpha(value) {
    this.uniforms.uAlpha = value;
  }
  get alpha() {
    return this.uniforms.uAlpha;
  }
}

export { ColorOverlayFilter };
