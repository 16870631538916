import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import moment from "moment";

import "./dialog-rules.scss";

import { getText, openItem, itemThumbnail, getData, emptyImage, launchSlotGame, toJSON } from "../../lobby/utils/functions";
import { loadedGames } from "../../lobby/store/actions/games";
import * as storeActions from "../../store/actions";
import { closeGameLauncher } from "../../store/actions/games";

import { tournamentActivate, tournamentEnroll } from "../store/actions/tournament";
import Timer from "../components/timer";
import ChilliRed from "../assets/chili-red.svg";
import ChilliGrey from "../assets/chili-grey.svg";
import EvBusMain from "../../utils/evbus";

const apiUrl = process.env.CFW.betsApiUrl + "/casinoapi/config/games";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogRules = (props) => {
  const { games, history, gamesLoaded, loadedGames, open, onClose, t, tournament, i18n, tournamentActivate, tournamentEnroll, closeGameLauncher } = props;

  React.useEffect(() => {
    if (!gamesLoaded) {
      axios.get(apiUrl + "/" + window.config.clientId + "?platformType=" + window.config.platformType)
        .then(response => {
          if (response && response.data) {
            loadedGames(response.data);
          }
        });
    }
  }, [gamesLoaded, loadedGames]);

  const lang = i18n.language;

  if (!open) return null;

  let rules = getText(tournament, `meta.ui.rules.text.${lang}`, "");
  let levels = getData(tournament, "meta.levels", 0);
  let objectives_image = getData(tournament, "meta.ui.objectives_image.url", emptyImage);
  let top_image = getData(tournament, "meta.ui.top_image.url", emptyImage);

  let tournament_games = games.filter(g => {
    if (tournament.meta && tournament.meta.games && tournament.meta.games.indexOf(g.game_id) > -1) return true;
    return false;
  });
  let tournament_recommended = tournament_games.filter(g => {
    if (tournament.meta && tournament.meta.recommended && tournament.meta.recommended.indexOf(g.game_id) > -1) return true;
    return false;
  });

  const adjustGameScreen = () => {
    if (window.config.platformType === 1) {
      const gameScreen = document.querySelector(".GameScreen");
      if (gameScreen) {
        gameScreen.style.top = "0px";
        gameScreen.style.left = "0px";
        gameScreen.style.width = "calc(0px + 100%)";
        gameScreen.style.height = "calc(0px + 100%)";
      }
    }
  };

  const handleJoinPlayAndGame = (event) => {
    event.stopPropagation();

    const target = event.currentTarget;

    if (target) {
      const item = toJSON(target.dataset.item);

      if (!tournament.enrolled) {
        tournamentEnroll({
          tournamentId: tournament.id,
          activate: true,
          cb: () => {
            closeGameLauncher();
            launchSlotGame(item, { clientX: 0, clientY: 0 }, storeActions);
            setTimeout(() => { adjustGameScreen() }, 300);
          }
        });
      } else if (!tournament.activated) {
        tournamentActivate({
          tournamentId: tournament.player_mission_id,
          cb: () => {
            closeGameLauncher();
            launchSlotGame(item, { clientX: 0, clientY: 0 }, storeActions);
            setTimeout(() => { adjustGameScreen() }, 300);
          }
        });
      } else {
        closeGameLauncher();
        launchSlotGame(item, { clientX: 0, clientY: 0 }, storeActions);
        setTimeout(() => { adjustGameScreen() }, 300);
      }
    }
  };

  const goToTournamentPage = (e) => {
    e.stopPropagation();

    onClose();

    if (window.config.platformType === 1) {
      EvBusMain.emit("OPEN-TOURNAMENTS-GROUPS-DIALOG", { path: props.groupUrl });
      return;
    }

    closeGameLauncher();
    history.push(`/tournaments${props.groupUrl}`);
  };

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      scroll="body"
      maxWidth="xs"
      className="tournaments-missions-dialog-rules">
      <div className="rules-body">
        <div className="rules-header">
          <div className="rules-mission">
            <div className="img">
              <img src={top_image} alt="" />
              <div className="txt">
                <div className="subtitle">TOP</div>
                <div className="title">{levels}</div>
              </div>
            </div>

            <Timer date={tournament.end_date}>
              {({ days, hours, mins, secs }) => {
                const now = moment().valueOf();
                const total = tournament.end_date - tournament.start_date;
                const progress = now - tournament.start_date;

                let muted = 0;
                if (!days || days.toString() === "0") muted += 1;
                if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                return <React.Fragment>
                  <div className="timer">
                    {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                  </div>
                  <div className="progress-wrapper">
                    <div className="progress-br" style={{ width: `${(progress * 100) / total}%` }} />
                  </div>
                </React.Fragment>;
              }}</Timer>

          </div>
          <div className="rules-close" onClick={onClose}>
            <div className="rules-close-wrapper">
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="rules-title">{t("Tournament rules")}</div>
        <div className="rules-subtitle">{t("scoring system")}</div>
        <div className="rules-content">
          {rules}
        </div>
        <div className="rules-icons">
          <img src={objectives_image} alt="" />
        </div>
        {tournament_recommended.length !== 0 && <div className="rules-header-line">
          {t("Recommended games for this tournament")}
        </div>}
        {tournament_recommended.length !== 0 && <div className="rules-recommended-line">
          {tournament_recommended.map((g, i) => {

            const image = itemThumbnail({
              type: "slot_game",
              providerId: g.provider,
              name: g.name
            });

            let hasFreeSpins = false;
            let freeSpinsCount = 0;

            props.freeSpins && props.freeSpins.forEach(fs => {
              fs && fs.games && fs.games.forEach(gId => {
                if (parseInt(g.game_id, 10) === parseInt(gId, 10)) {
                  hasFreeSpins = true;
                  freeSpinsCount = fs.remaining_free_spins;
                }
              })
            });

            return <div className="recommended" key={i}
              data-type="slot_game"
              data-item={JSON.stringify({ id: g.id, providerId: g.provider, name: g.name, gameId: g.game_id })}
              onClick={handleJoinPlayAndGame}
            >
              <div className="img">
                <img src={image} alt="" />
                {hasFreeSpins && <div className="free-bet-sticker">{freeSpinsCount} Free Spins</div>}
              </div>
              <div className="rank">
                <img src={g.v > 33 ? ChilliRed : ChilliGrey} alt="" />
                <img src={g.v > 66 ? ChilliRed : ChilliGrey} alt="" />
                <img src={g.v >= 100 ? ChilliRed : ChilliGrey} alt="" />
              </div>
            </div>;
          })}
        </div>}
        <div className="rules-footer" onClick={goToTournamentPage}>
          {tournament_games.length} {t("available games")}
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state, props) => {
  return {
    games: state.lobby.games.items,
    gamesLoaded: state.lobby.games.items.length !== 0,
    freeSpins: state.freeSpins && state.freeSpins.freeSpins ? state.freeSpins.freeSpins : [],
  };
};

const actions = {
  loadedGames, tournamentActivate, tournamentEnroll, closeGameLauncher
};

export default withRouter(withTranslation()(connect(mapStateToProps, actions)(DialogRules)));

