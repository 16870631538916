import React from "react";
import MoreIcon from '@material-ui/icons/MoreHoriz';
import ClearIcon from '@material-ui/icons/Clear';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./landscape-player-gallery.scss";

import { loadPlayerLeaderBoard } from "../store/actions/leader_board";
import { closeGameLauncher } from "../../store/actions/games";

import { formatNumber } from "../utils/functions";
import Watch from "../assets/watch.svg";
import DefaultAvatar from "../assets/img-avatar-male.png";
import Timer from "./timer";

import PosBg from "../assets/pos-bg.svg";
import DialogObjectives from "../dialogs/dialog-rules";
import DialogSwitchTournament from "../dialogs/dialog-switch-tournament";
import AwardIcon from "../assets/award.svg";
import { getData } from "../../lobby/utils/functions";

const differentArray = (left, right) => {
  if (left.length !== right.length) return true;

  for (let i = 0; i < left.length; i++) {
    const keys = Object.keys(left[i]);
    for (let j = 0; j < keys.length; j++) {
      if (left[i][keys[j]] !== right[i][keys[j]]) {
        return true;
      }
    }
  }

  return false;
};

const PlayerGallery = (props) => {
  const { tournament, t, rank, loadPlayerLeaderBoard, authentication, closeGameLauncher, history } = props;

  const [openObjectivesDialog, setOpenObjectivesDialog] = React.useState(false);
  const handleCloseObjectivesDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenObjectivesDialog(false);
  };
  const showRules = (e) => {
    if (e) e.stopPropagation();
    setOpenObjectivesDialog(true);
  };

  const [openTournamentsDialog, setOpenTournamentsDialog] = React.useState(false);
  const handleCloseTournamentsDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(false);
  };
  const showMenu = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(true);
  };
  const closeGame = () => {
    closeGameLauncher();
    if (window._CanGoBack) {
      history.goBack();
      return;
    }
    history.push("/cazino/jocuri");
  };

  const [players, setPlayers] = React.useState([]);

  React.useEffect(() => {
    let isAuthenticated = false;
    if (authentication && ["user", "token"].indexOf(authentication.auth_type) > -1) {
      isAuthenticated = true;
    }

    if (isAuthenticated && tournament && rank && rank[tournament.id] && rank[tournament.id].player && differentArray(rank[tournament.id].player, players)) {
      setPlayers(JSON.parse(JSON.stringify(rank[tournament.id].player)));
    }
  }, [tournament, rank, players, authentication]);

  React.useEffect(() => {
    if (tournament && rank && !rank[tournament.id]) {
      loadPlayerLeaderBoard({ tournamentId: tournament.id, inGame: true });
    }
  }, [tournament, rank, loadPlayerLeaderBoard]);

  if (!tournament) return;

  let currentPlayer = null;
  let currentPlayerPos = -1;
  players && players.length && players.forEach((p, i) => {
    if (p.me) {
      currentPlayer = p;
      currentPlayerPos = i;
    }
  });

  const avatar_image = getData(tournament, "meta.ui.avatar_image.url", DefaultAvatar);

  return (
    <div className="landscape-player-gallery" key={`id-${tournament.id}`}>

      <div className="header-wrapper">
        <div className="menus">
          <div className="menu close" onClick={closeGame}>
            <ClearIcon />
          </div>
          <div className="menu" onClick={showMenu}>
            <MoreIcon />
          </div>
        </div>
        <div className="header">
          <div className="tmr">
            <img src={Watch} alt="" />
            <Timer date={tournament.end_date}>
              {({ days, hours, mins, secs }) => {
                return `${!!days ? (days > 1 ? `${days} ${t("days")} ` : `${days} ${t("day")} `) : ""}${hours}:${mins}:${secs}`;
              }}</Timer>
          </div>
        </div>
        <div className="hr"></div>
      </div>
      {players.length === 0 && <div className="gallery-wrapper" onClick={showRules}>
        <div className="empty">{t("The tournament rankings will appear soon")}</div>
      </div>
      }
      {players.length !== 0 && <div className="gallery-wrapper" onClick={showRules}>
        <div className="gallery  real">
          <div className="container before"></div>

          {
            players.map((p, i) => {
              return <div key={i} className={`item no-${i + 1} ${i === currentPlayerPos ? 'current' : ''}`}>
                <div className="avatar">
                  <img src={p.avatar ? p.avatar : avatar_image} alt="" />
                </div>
                <div className="pos">
                  <img src={PosBg} alt="" />
                  <div className="no">#{p.pos}</div>

                </div>
                <div className="score">{formatNumber(p.score, props.i18n.language)}</div>
              </div>
            })
          }

          <div className="container after"></div>
        </div>
        {currentPlayer &&
          <div className="gallery float">
            {
              players.map((p, i) => {

                if (currentPlayerPos !== i) return <div key={i} className={`item`}>
                  <div className="avatar off">
                  </div>
                  <div className="pos"></div>
                  <div className="score">&nbsp;</div>
                </div>;

                return <div className={`item big`} key={'current-player-float'}>
                  <div className="avatar">
                    <img src={currentPlayer.avatar ? currentPlayer.avatar : avatar_image} alt="" />
                  </div>
                  <div className="pos">
                    <img src={PosBg} alt="" />
                    <div className="no">#{currentPlayer.pos}</div>
                    <div className="award-icon">
                      <img src={AwardIcon} alt="" />
                    </div>
                  </div>
                  <div className="score">{formatNumber(currentPlayer.score, props.i18n.language)}</div>
                </div>;
              })
            }
          </div>
        }
      </div>}

      {openObjectivesDialog && <DialogObjectives open={openObjectivesDialog} onClose={handleCloseObjectivesDialog} tournament={tournament} groupUrl={props.groupUrl} />}
      {openTournamentsDialog && <DialogSwitchTournament open={openTournamentsDialog} onClose={handleCloseTournamentsDialog} />}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication,
    rank: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : {},
  }
};

const actions = {
  loadPlayerLeaderBoard,
  closeGameLauncher
};

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(PlayerGallery)));