import React from "react";
import PropTypes from 'prop-types'; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import { withTranslation } from "react-i18next";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterListIcon from '@material-ui/icons/Search';

import SystemSelectDialog from "../SystemSelectDialog";

const useStyles = makeStyles({
  root: {
  },
  wrapperSelect: {
    width: "100%"
  },
  select: {
    backgroundColor: "#DBFFFB",
    borderRadius: "10px",
    fontSize: "14px",
    color: "#17214D",
    marginRight: "9px",
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    border: "1px solid #06D2BD",
    height: "34px",
    textAlign: "center",
  },
  selectHome: {
    fontSize: "22px",
    fontWeight: "600",
    borderLeft: "3px solid #F8991C",
    borderRadius: "0",
    backgroundColor: "transparent",
    padding: "0 15px",
    margin: "22px 0",
    border: "0",
    height: "auto",
    textAlign: "left",
  },
  selected: {
    width: "100%",
    backgroundColor: "#DBFFFB",
    borderRadius: "10px",
    fontSize: "14px",
    color: "#17214D",
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    margin: "5px 0",
    cursor: "pointer",
    border: "1px solid #06D2BD",
    height: "34px",
    textAlign: "center",
    paddingLeft: "16px",
  },
  selectedHome: {
    fontSize: "22px",
    fontWeight: "600",
    borderLeft: "3px solid #F8991C",
    borderRadius: "0",
    backgroundColor: "transparent",
    padding: "0 15px",
    margin: "22px 0",
    border: "0",
    height: "auto",
    textAlign: "left",
  },
  icon: {
    marginLeft: "5px",
    height: "24px",
    verticalAlign: "middle!important"
  },
  closeIcon: {
    width: "16px"
  },
  closeIconHome: {
    width: "24px"
  },
  disabled: {
    opacity: ".5",
    pointerEvents: "none"
  }
});

const SystemSelectEntry = props => {
  const classes = useStyles();
  const { className, systemName, onChange, home, direct, active, t } = props;

  const [openFilter, setOpenFilter] = React.useState(false);
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleCloseFilter = (val) => {
    setOpenFilter(false);
    onChange(val ? val : "");
  }
  const resetFilter = () => {
    onChange("");
  };

  return (
    <div className={`${classes.root} ${className ? className : ''} d-flex flex-row flex-nowrap align-items-center`}>
      {systemName !== "all" && <React.Fragment>
        <ButtonBase className={`${classes.selected} ${home ? classes.selectedHome : ''}`} onClick={direct ? handleOpenFilter : resetFilter}>
          <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between w100">
            <div className="flex-grow-1">{systemName}</div>
            {!direct && <CloseIcon className={`${classes.closeIcon} ${home ? classes.closeIconHome : ''}`} />}
            {direct && <ArrowDropDownIcon className={`${classes.closeIcon}`} />}
          </div>
        </ButtonBase>
      </React.Fragment>}
      {systemName === "all" &&
        <div className={classes.wrapperSelect}>
          <ButtonBase className={`${classes.select} ${home ? classes.selectHome : ''}`} onClick={handleOpenFilter}>
            <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between w100">
              <div>{t("All Lotteries")}</div>
              <div>
                <FilterListIcon className={classes.icon} />
                {/*<Select className={classes.icon} />*/}
              </div>
            </div>
          </ButtonBase>
        </div>
      }
      {openFilter && <SystemSelectDialog selected={systemName !== "all" ? systemName : ""} open={openFilter} onClose={handleCloseFilter} direct={direct} active={active} />}
    </div >
  );
};

SystemSelectEntry.propTypes = {
  onChange: PropTypes.func,
};

SystemSelectEntry.defaultProps = {
  onChange: () => { }
};

export default withTranslation()(SystemSelectEntry);

