import React from 'react';
import isEqual from "lodash/fp/isEqual";
import { withTranslation } from "react-i18next";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const BetFilter = (props) => {

  const { marketGroups, onChange, t, className } = props;

  let isMobile = useMediaQuery("(max-width: 847px)");
  const is1400 = useMediaQuery("(max-width: 1400px)");
  const is1100 = useMediaQuery("(max-width: 1100px)");
  const isLive = className === "live" ? true : false;
  isMobile = true;

  const [state, setState] = React.useState({
    marketGroups: [],
    checked: 0,
  });

  var checked = { ...state.checked };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  React.useEffect(() => {
    setState(v => {
      let isModified = false;
      let tmp = { ...v };
      if (!isEqual(v.marketGroups, marketGroups)) {
        tmp.marketGroups = marketGroups;
        isModified = true;
        tmp.checked = 0;
      }
      if (isModified) return tmp;
      return v;
    });
  }, [marketGroups]);

  const handleChange = (i) => (event) => {
    checked = ({ ...checked, [event.target.name]: event.target.checked });
    setState({
      marketGroups: state.marketGroups,
      checked: i,
    });
    if (anchorEl !== null) {
      setAnchorEl(null);
    }
    onChange(i)
  };

  const buildMarketGroupFilterList = () => {
    const list = [];

    state.marketGroups.forEach((market, i) => {
      list.push((
        <div key={`bet_group_${i}`} className={`bet-group ${state.checked === i ? 'active' : ''}`} onClick={handleChange(i)}>
          <span>{market.isAll ? t("All") : market.isOthers ? t("Others") : market.name}</span>
        </div>
      ));
    });

    return (
      <div className="bet-filter-wrapper">
        <div className={`bet-filter ${className ? className : ''}`}>
          {list}
        </div>
      </div>
    );
  };

  const buildMarketGroupFilterListDesktop = () => {
    const list = [];

    let howMany = 4;
    if (isLive) {
      if (is1100) {
        howMany = 2;
      } else if (is1400) {
        howMany = 3;
      } else {
        howMany = 6;
      }
    }

    // console.log("marketGroups", state.marketGroups);

    state.marketGroups.forEach((market, i) => {
      if (i <= howMany) {
        list.push((
          <div key={`bet_group_${i}`} className={`bet-group ${state.checked === i ? 'active' : ''}`} onClick={handleChange(i)} title={market.isAll ? t("All") : market.isOthers ? t("Others") : market.name}>
            <span>{market.isAll ? t("All") : market.isOthers ? t("Others") : market.name}</span>
          </div>
        ));
      }
    });

    if (state.marketGroups.length - 1 > howMany) {
      const menu = (
        <React.Fragment key="bet_group_menu">
          <div className={`bet-group ${state.checked > howMany ? 'active' : ''}`} onClick={handleClick} title={state.checked > howMany ? (state.marketGroups[state.checked].isAll ? t("All") : state.marketGroups[state.checked].isOthers ? t("Others") : state.marketGroups[state.checked].name) : t("More")}>
            <span>{state.checked > howMany ? (state.marketGroups[state.checked].isAll ? t("All") : state.marketGroups[state.checked].isOthers ? t("Others") : state.marketGroups[state.checked].name) : t("More")}</span>
            <ExpandMoreIcon className="more-svg" />
          </div>
          <Menu
            id="simple-bets-group-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {state.marketGroups.map((market, i) => {
              if (i <= howMany) {
                return null;
              }
              return <MenuItem key={`bet_group_${i + howMany}`} onClick={handleChange(i)}>{market.isAll ? t("All") : market.isOthers ? t("Others") : market.name}</MenuItem>
            })}
          </Menu>
        </React.Fragment>
      );

      list.push(menu);
    }

    return (
      <div className="bet-filter-wrapper">
        <div className={`bet-filter ${className ? className : ''}`}>
          {list}
        </div>
      </div>
    );
  };
  if (isMobile)
    return buildMarketGroupFilterList();

  return buildMarketGroupFilterListDesktop();
}


export default withTranslation()(BetFilter);