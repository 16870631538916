import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loadingProgress: false,
    pageData: null,
    idWarning: false,
    passwordModal: false,
    changePasswordForm: {
        currentPassword: null,
        newPassword: null,
        repeatPassword: null,
        validNewPassword: false,
        step1Message: null,
        step2Message: null,
        step: 1,
        requested: false,
        success: false,
    },
    phoneModal: false,
    changePhoneForm: {
        newPhoneNumber: null,
        validationCode: null,
        coderErrorsCount: 0,
        maxErrorsCount: 3,
        step: 1,
        step1Message: null,
        step2Message: null,
        phoneValidationProgress: false,
        codeValidationProgress: false,
        success: false,
    },
    addressModal: false,
    addressForm: {
        addressValues: null,
        message: null,
        requested: false,
        success: false,
    },
    limits: {
        error: false,
        limitApplied: false
    },
    latitude: 44.437551,
    longitude: 26.118162,
    accountVerified: false,
    accountStatus: 0,
    accountSubStatus: 0,
    accountCreated: false,
    takeABreakModal: false,
    selfExclusionModal: false,
    selfExclusionForm: {
        passwordIsValid: null,
        loadingCheckPassword: false,
        checkPassword: null,
        checkEmailCode: null,
        checkSMSCode: null,
    },
    global_player_id: null,
    client_player_id: null,
    emailVerified: false,
    player: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.profilePage.START_LOADING:
            return {
                ...state,
                loadingProgress: true,
            };
        
        case actionTypes.profilePage.STOP_LOADING:
            return {
                ...state,
                loadingProgress: false,
            };

        case actionTypes.profilePage.PAGE_DATA:
            let playerData = {};
            if (action.data.hasOwnProperty("global_player_id")) {
                let clVerified = false;
                let aStatus = 0;
                let aSubStatus = 0;
                let dateCreated = false;
                let playerEmail = null;
                let emailVerified = false;

                if (action.data.hasOwnProperty("verified") && 1 === Number.parseInt(action.data.verified)) {
                    clVerified = true;
                }
                if (action.data.hasOwnProperty("email_verified") && 1 === Number.parseInt(action.data.email_verified)) {
                    emailVerified = true;
                }
                if (action.data.hasOwnProperty("account_status") && action.data.account_status > 0) {
                    aStatus = action.data.account_status;
                }
                if (action.data.hasOwnProperty("account_sub_status") && action.data.account_sub_status) {
                    aSubStatus = action.data.account_sub_status;
                }
                if (action.data.hasOwnProperty("date_created") && action.data.date_created) {
                    dateCreated = action.data.date_created;
                }
                if (action.data.hasOwnProperty("email") && action.data.email) {
                    playerEmail = action.data.email;
                }

                playerData.global_player_id = action.data.global_player_id;
                playerData.client_player_id = action.data.client_player_id;
                playerData.playerEmail = playerEmail;
                playerData.emailVerified = emailVerified;
                playerData.accountVerified = clVerified;
                playerData.accountStatus = aStatus;
                playerData.accountSubStatus = aSubStatus;
                playerData.accountCreated = dateCreated;
            }
            if (action.data.hasOwnProperty("limitApplied")) {
                playerData.selfExclusionForm = {
                    ...state.selfExclusionForm,
                    loadingCheckPassword: false
                }
            }
            return {
                ...state,
                pageData: action.data,
                addressForm: {
                    ...state.addressForm,
                    addressValues: action.data.address,
                },
                ...playerData,
            };
        case actionTypes.authentication.LOGOUT:
            return {...initialState}
        case actionTypes.profilePage.PLAYER_INFO:


            return {
                ...state,
                player: action.data
            };

        case actionTypes.profilePage.ID_CARD_WARNING:
            return {
                ...state,
                idWarning: action.show,
            };

        case actionTypes.profilePage.PASSWORD_MODAL:
            return {
                ...state,
                passwordModal: action.open,
            };

        case actionTypes.profilePage.CURRENT_PASSWORD:
            return {
                ...state,
                changePasswordForm: {
                    ...state.changePasswordForm,
                    currentPassword: action.value,
                }
            };

        case actionTypes.profilePage.NEW_PASSWORD:
            return {
                ...state,
                changePasswordForm: {
                    ...state.changePasswordForm,
                    newPassword: action.value,
                }
            };

        case actionTypes.profilePage.REPEAT_PASSWORD:
            return {
                ...state,
                changePasswordForm: {
                    ...state.changePasswordForm,
                    repeatPassword: action.value,
                }
            };

        case actionTypes.profilePage.CLEAR_PASSWORD_FORM:
            return {
                ...state,
                changePasswordForm: {
                    currentPassword: null,
                    newPassword: null,
                    repeatPassword: null,
                    validNewPassword: false,
                    step1Message: null,
                    step2Message: null,
                    step: 1,
                    requested: false,
                    success: false,
                }
            };

        case actionTypes.profilePage.VALID_NEW_PASSWORD:
            return {
                ...state,
                changePasswordForm: {
                    ...state.changePasswordForm,
                    validNewPassword: action.value,
                }
            };

        case actionTypes.profilePage.PASSWORD_STEP_2_MESSAGE:
            let step2Message = null;
            if (action.msgType !== null && action.msgText !== null) {
                step2Message = {};
                step2Message.type = action.msgType;
                step2Message.message = action.msgText;
            }

            return {
                ...state,
                changePasswordForm: {
                    ...state.changePasswordForm,
                    step2Message: step2Message,
                    requested: false,
                }
            };

        case actionTypes.profilePage.PASSWORD_STEP_1_MESSAGE:
            let step1Message = null;
            if (action.msgType !== null && action.msgText !== null) {
                step1Message = {};
                step1Message.type = action.msgType;
                step1Message.message = action.msgText;
            }

            return {
                ...state,
                changePasswordForm: {
                    ...state.changePasswordForm,
                    step1Message: step1Message,
                }
            };

        case actionTypes.profilePage.PROFILE_PASSWORD_CHANGE_STEP:
            return {
                ...state,
                changePasswordForm: {
                    ...state.changePasswordForm,
                    step: action.newStep,
                }
            };

        case actionTypes.profilePage.CHANGE_PASSWORD_REQUEST_STATUS:
            return {
                ...state,
                changePasswordForm: {
                    ...state.changePasswordForm,
                    requested: action.requestStatus,
                }
            };

        case actionTypes.profilePage.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordForm: {
                    ...state.changePasswordForm,
                    success: true,
                }
            };

        case actionTypes.profilePage.TAKE_A_BREAK_MODAL:
            return {
                ...state,
                takeABreakModal: action.open,
            };
        case actionTypes.profilePage.SELF_EXCLUSION_MODAL:
            return {
                ...state,
                selfExclusionModal: action.open,
            };

        case actionTypes.profilePage.REQUEST_CHECK_PASSWORD:
            return {
                ...state,
                selfExclusionForm: {
                    ...state.selfExclusionForm,
                    loadingCheckPassword: true,
                }
            };
        
        case actionTypes.profilePage.CLEAR_SELF_EXCLUSION_FORM:
            return {
                ...state,
                selfExclusionForm: {
                    passwordIsValid: null,
                    loadingCheckPassword: false,
                    checkPassword: null,
                    checkEmailCode: null,
                    checkSMSCode: null,
                }
            };

        case actionTypes.profilePage.UPDATE_CHECK_PASSWORD:
            return {
                ...state,
                selfExclusionForm: {
                    ...state.selfExclusionForm,
                    checkPassword: action.value,
                }
            };
        case actionTypes.profilePage.RECEIVED_CHECK_PASSWORD:
            return {
                ...state,
                selfExclusionForm: {
                    ...state.selfExclusionForm,
                    passwordIsValid: action.value,
                    loadingCheckPassword: false,
                    checkPassword: null,
                }
            };
        case actionTypes.profilePage.UPDATE_CHECK_EMAIL_CODE:
            return {
                ...state,
                selfExclusionForm: {
                    ...state.selfExclusionForm,
                    checkEmailCode: action.value,
                }
            };
        case actionTypes.profilePage.UPDATE_CHECK_SMS_CODE:
            return {
                ...state,
                selfExclusionForm: {
                    ...state.selfExclusionForm,
                    checkSMSCode: action.value,
                }
            };
        case actionTypes.profilePage.PHONE_MODAL:
            return {
                ...state,
                phoneModal: action.open,
            };

        case actionTypes.profilePage.PHONE_CHANGE_STEP:
            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    step: action.newStep,
                    coderErrorsCount: 0,
                    maxErrorsCount: 3,
                    step1Message: null,
                    step2Message: null,
                    phoneValidationProgress: false,
                    codeValidationProgress: false,
                    success: false
                }
            };

        case actionTypes.profilePage.NEW_PHONE_NUMBER:
            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    newPhoneNumber: action.number,
                }
            };

        case actionTypes.profilePage.PHONE_STEP_1_MESSAGE:
            let step1MessagePhone = null;
            if (action.msgType !== null && action.msgText !== null) {
                step1MessagePhone = {};
                step1MessagePhone.type = action.msgType;
                step1MessagePhone.message = action.msgText;
            }

            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    step1Message: step1MessagePhone,
                }
            };

        case actionTypes.profilePage.PHONE_STEP_2_MESSAGE:
            let step2MessagePhone = null;
            if (action.msgType !== null && action.msgText !== null) {
                step2MessagePhone = {};
                step2MessagePhone.type = action.msgType;
                step2MessagePhone.message = action.msgText;
            }

            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    step2Message: step2MessagePhone,
                }
            };

        case actionTypes.profilePage.CLEAR_PHONE_MODAL:
            return {
                ...state,
                changePhoneForm: {
                    newPhoneNumber: null,
                    validationCode: null,
                    coderErrorsCount: 0,
                    maxErrorsCount: 3,
                    step: 1,
                    step1Message: null,
                    step2Message: null,
                    phoneValidationProgress: false,
                    codeValidationProgress: false,
                    success: false
                }
            };

        case actionTypes.profilePage.NUMBER_VALIDATION_PROGRESS:
            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    phoneValidationProgress: action.value,
                }
            };

        case actionTypes.profilePage.NUMBER_VALIDATION_SUCCESS:
            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    step: 2,
                    validationCode: null,
                    step1Message: null,
                    step2Message: null,
                    phoneValidationProgress: false,
                    codeValidationProgress: false,
                    coderErrorsCount: 0,
                }
            };

        case actionTypes.profilePage.NEW_SMS_CODE:
            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    validationCode: action.code,
                }
            };

        case actionTypes.profilePage.CODE_VALIDATION_PROGRESS:
            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    codeValidationProgress: action.value,
                }
            };

        case actionTypes.profilePage.CODE_VALIDATION_SUCCESS:
            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    codeValidationProgress: false,
                    success: true,
                }
            };

        case actionTypes.profilePage.ADD_CODE_ERROR:
            let actualErrorsCount = state.changePhoneForm.coderErrorsCount;
            return {
                ...state,
                changePhoneForm: {
                    ...state.changePhoneForm,
                    coderErrorsCount: actualErrorsCount+1,
                }
            };

        case actionTypes.profilePage.ADDRESS_MODAL:
            return {
                ...state,
                addressModal: action.open,
            };

        case actionTypes.profilePage.NEW_ADDRESS:
            return {
                ...state,
                addressForm: {
                    ...state.addressForm,
                    addressValues: action.values,
                }
            };

        case actionTypes.profilePage.ADDRESS_CHANGE_REQUEST:
            return {
                ...state,
                addressForm: {
                    ...state.addressForm,
                    requested: action.requestValue,
                }
            };

        case actionTypes.profilePage.ADDRESS_CHANGE_MESSAGE:
            let adcMessage = null;
            if (action.msgType !== null && action.msgText !== null) {
                adcMessage = {};
                adcMessage.type = action.msgType;
                adcMessage.message = action.msgText;
            }

            return {
                ...state,
                addressForm: {
                    ...state.addressForm,
                    message: adcMessage,
                }
            };

        case actionTypes.profilePage.ADDRESS_CHANGE_SUCCESS:
            return {
                ...state,
                addressForm: {
                    ...state.addressForm,
                    success: true,
                }
            };

        case actionTypes.profilePage.CLEAR_ADDRESS_MODAL:
            let addressVal = state.addressForm.addressValues;

            if (typeof addressVal === "object") {
                addressVal = state.addressForm.addressValues.formated_adress;
            }

            return {
                ...state,
                addressForm: {
                    ...state.addressForm,
                    addressValues: addressVal,
                    message: null,
                    requested: false,
                    success: false,
                }
            };
        case actionTypes.profilePage.SET_LIMIT_APPLIED:
            let limits = {...state.limits.limitApplied};
            if (action.value) {
                try {
                    if (typeof limits[action.value.limit_type] === "undefined") limits[action.value.limit_type] = {};
                    limits[action.value.limit_type][action.value.limit_type_period] = action.value.limit_value;
                } catch (e) {
                    console.warn("Can't find info about the new limit that was applied");
                    return state;
                }
            } else {
                return state;
            }

            return {
                ...state,
                limits: {
                    ...state.limits,
                    limitApplied: limits
                }

            };
        default:
            return state;
    }
};

export default reducer;
