import React from 'react';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import "./ExpandButton.scss";

const ExpandButton = (props) => {
  const { expanded, dark, handleExpanded } = props;

  const cls = expanded ? `ebb-iconWrapper ebb-expanded` : `ebb-iconWrapper`;

  return (
    <div
      onClick={handleExpanded}
      className={cls}
    >
      <div className="d-flex flex-column">
        <ExpandLessIcon
          className={`ebb-icon icon` + (dark ? " dark" : "")}
        />
        <ExpandMoreIcon
          className={`ebb-icon icon` + (dark ? " dark" : "")}
        />
      </div>
    </div>
  );
}

export default React.memo(ExpandButton);