import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';

import "./BonusWidget.scss";
import * as storeActions from "../../store/actions";
import Utils from "../../utils/Utils";
import Icons from "../../assets/Icons/Icons";

import i18next from "i18next";

export const FREEBET_LOTTO = 5;
export const FREEBET_SPORT = 9;
export const FREE_SPINS = 8;
export const BONUS_SCALAR = 1;
export const BONUS_PERCENTAGE = 2;

class BonusWidget extends Component {

  clickBonus(bonus) {
    if (typeof bonus.bonus_conflicts !== "undefined") {
      if (Object.keys(bonus.bonus_conflicts).length > 0) {
        return;
      }
    }
    this.props.onClickedBonus(bonus.bonus_available_id, bonus)
  }

  render() {
    let bonusList = [];

    if (this.props.bonuses) {
      Object.keys(this.props.bonuses).forEach((key) => {

        let bonus = this.props.bonuses[key]
        let products = [];
        if (bonus) {
          if (bonus.eligible_products) {
            bonus.eligible_products.forEach((key) => {
              switch (key) {
                case 2:
                case "2":
                  products.push(this.props.t("Sportsbook"));
                  break;
                case 1:
                case "1":
                  products.push(this.props.t("Casino"));
                  break;
                default:
                  break;

              }
            })
          }

          if (bonus.bonus_conflicts && Object.keys(bonus.bonus_conflicts).length) {
            return;
          }

          let bonusReceived;
          if (!this.props.grayscale) {
            bonusReceived = getSelectedBonusValue(bonus, this.props.depositValue);
            if (this.props.type === "compact") {
              bonusReceived = `+${bonusReceived} Bonus`;
            }
          }

          let bonusHTML = (<div
            className={"BonusWidget" + (this.props.type === "compact" ? " compact" : "") /*+ (this.props.grayscale ? " grayscale" : "")*/} key={'bonus-widget-' + bonus.bonus_available_id}>
            <div className="details">
              <div className="img">
                <img
                  src={this.props.type === "compact" ? bonus.deposit_wizard_image : bonus.icon_image}
                  alt="" />
              </div>
              <div className="other-details"
                style={this.props.type === "compact" ? { background: "linear-gradient(90deg, transparent 30%, #f5f8fa 60%)" } : bonus.deposit_wizard_gradient ? { background: bonus.deposit_wizard_gradient } : {}}>
                <h2 className="title">
                  {bonus.bonus_available_name}
                  {this.props.type === "compact" && this.props.changeBonusClick && (
                    <div className={'change'} onClick={this.props.changeBonusClick.bind(this)}>{this.props.t("Change bonus")}</div>
                  )}
                </h2>
                {this.props.type === "compact" && (
                  <div className={'deposit-details'}>
                    {/*{this.props.grayscale && (*/}
                    {/*  <div className={'alert'}>*/}
                    {/*    <div className="atitle">{this.props.t("Bonus requirements:")}</div>*/}
                    {/*    <div className="list">{this.props.t(this.props.t("Minimum deposit:"))} <strong>{Utils.formatAmount(bonus.bonus_available_min * 100 / bonus.bonus_available_amount, false, "Lei")}</strong></div>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                    {/*{!this.props.grayscale && (*/}
                    {/*  <div>*/}
                    {/*    <span className={'bonus-received'}>*/}
                    {/*      {bonusReceived}*/}
                    {/*    </span>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </div>
                )}
                {this.props.type !== "compact" && (<div className="bonus">
                  <div className="name">
                    {bonus.bonus_available_name}
                    <div className="type">
                      {products.join(', ')}
                    </div>
                  </div>
                  {this.props.type !== "compact" && (<div className="value">
                    {bonusReceived}
                  </div>)}
                </div>)}
              </div>
            </div>
            {this.props.type !== "compact" && (<div className={"cta"}
              onClick={() => this.clickBonus(bonus)}>
              <div className="timer">
                <div className={"time"} >{this.props.t("Time left:")}</div> {this.props.t("Unlimited")}
              </div>
              <a className={'button'}>{this.props.cta_text ? this.props.cta_text : this.props.t("GET BONUS")}</a>
            </div>)}
          </div>);
          bonusList.push(bonusHTML);
        }
      });


      bonusList.push(<div className={"BonusWidget empty"} key={'bonus-widget-empty'}>
        <div className="details">
          <div className="img">
            {Icons.get('diamond')}
          </div>
          <div className="other-details">
            <h2 className="title">{this.props.t("No bonus")}</h2>
          </div>
        </div>
        <div className="cta" onClick={this.props.onClickedBonus.bind(this, -2)}>
          <span></span>
          <a className={'button'}>{this.props.t("Deposit without bonus")}</a>
        </div>
      </div>);
    }

    if (this.props.bonuses !== null && !bonusList.length) {
      bonusList = (<div className={"BonusWidget loading"}>
        <div className="details  no-bonus">
          <div className="img" style={{ backgroundImage: "url(https://" + window.config.cdn_address + "/public/bonus/img-bonuscard-nobonus-luminosity@3x.png)" }} />
          <span></span>
          <div className="message">
            Nu poti obtine alte BONUS-uri chiar acum.
            Incearca din nou mai tarziu.
          </div>
        </div>
      </div>)
    }

    if (this.props.bonuses === null) {
      bonusList = (<div className={"BonusWidget loading"}>
        <div className="details">
          <div className="img" style={{ backgroundImage: "url(https://" + window.config.cdn_address + "/public/bonus/img-bonuscard-nobonus-luminosity@3x.png)" }}>

          </div>
          <span></span>
          <div className="message">
            Se verifica bonusurile....
          </div>
        </div>
      </div>)
    }

    return (<div>{bonusList}</div>)
  }

};

const mapStateToProps = state => {
  return {
    bonuses: state.application.eligibleBonuses,
    profile: state.profile,
    transactions: state.transactions,
    wallet: state.wallet,
    authenticated: state.authentication.auth_type === "user",
    freeBets: state.freeBets.freeBets,
    freeSpins: state.freeSpins.freeSpins,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getPromotions: () => dispatch(storeActions.getEligibleBonuses()),
    getFreeBets: () => dispatch(storeActions.freeBetsFetch()),
    getFreeSpins: () => dispatch(storeActions.freeSpinsFetch())
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)((BonusWidget))));

export const getSelectedBonusValue = (bonus, depositValue) => {
  if (!bonus) {
    return false;
  }
  let bonusReceived;
  const t = i18next.getFixedT(i18next.language);

  switch (bonus.bonus_available_type) {
    case FREEBET_SPORT:
    case FREEBET_LOTTO:
      if (typeof bonus.bonus_type_specific !== 'undefined' && typeof bonus.bonus_type_specific.tokenCount !== 'undefined' && typeof bonus.bonus_type_specific.tokenValue !== 'undefined') {
        bonusReceived = `${bonus.bonus_type_specific.tokenCount} x ${Utils.formatAmount(bonus.bonus_type_specific.tokenValue / 100, false, 'ron')}`;
      }
      break;
    case FREE_SPINS:
      if (typeof bonus.bonus_type_specific !== 'undefined' && typeof bonus.bonus_type_specific.fs_count !== 'undefined') {
        bonusReceived = `${bonus.bonus_type_specific.fs_count} ${t("Spins")}`;
      }
      break;
    case BONUS_SCALAR:
    case BONUS_PERCENTAGE:
      if (depositValue * (bonus.bonus_available_amount / 100) < bonus.bonus_available_max) {
        bonusReceived = Utils.formatAmount(depositValue * (bonus.bonus_available_amount / 100), true, "lei")
      } else {
        bonusReceived = Utils.formatAmount(bonus.bonus_available_max, true, 'lei')
      }

      break;
    default:
      break;
  }

  return bonusReceived;
}