import { teamsConstants } from "./constants";

export const teamsLoaded = teams => ({
  type: teamsConstants.LOADED,
  teams
});

export const teamsLoad = () => ({
  type: teamsConstants.LOAD
});
