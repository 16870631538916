import React from 'react';
import { withTranslation } from "react-i18next";

import "./LockedBets.scss";
import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";

const LockedBets = ({ dark, t }) => {
  return (
    <div className="match-locked-bets">
      <div className={`mbl-lockIcon ${dark ? 'dark' : ''}`}>
        <LockOutlinedIcon />
      </div>
      <div className={"mbl-lockText"}>{t("EVENT BETS LOCKED")}</div>
    </div>
  );
}

export default withTranslation()(React.memo(LockedBets));