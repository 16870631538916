import React from 'react';
import { Link } from 'react-router-dom';

import Page from '../layout/Page';

const textCentered = {
  textAlign: "center",
  padding: "1rem"
};

export default function Error404() {
  return (
    <Page>
      <h1 style={textCentered}>Page not found</h1>
      <div style={textCentered}>
        <Link to="/pariuri">Go Home</Link>
      </div>
    </Page>
  );
}