const mergeCfg = (cfg1, cfg2) => {
    for (const k in cfg2) {
        if (k in cfg1) {
            continue;
        }
        cfg1[k] = cfg2[k];
    }
    return cfg1;
};

const digitainDevConfig = () => ({
    // wsUrl: "https://micros1-ro.play-online.com",
    // wsPath: "/digitain-fetcher/live/ws",
    // apiURL: "https://micros1-ro.play-online.com/digitain-fetcher/public",
    // defaultSportId: "1",

    // wsUrl: "http://front.localhost:8667",
    // wsPath: "/digitain-fetcher/live/ws",
    // apiURL: "http://front.localhost:8667/digitain-fetcher/public",
    // defaultSportId: "1",
    // ticketsWsUrl: "http://front.localhost:8668",
    // ticketsWsPath: "/digitain-tickets/ws",
    // ticketsUrl: "http://front.localhost:8668/digitain-tickets/player",

    wsUrl: 'https://test2-micros-1.gambling-solutions.ro',
    wsPath: '/digitain-fetcher/live/ws',
    apiURL: 'https://test2-micros-1.gambling-solutions.ro/digitain-fetcher/public',
    defaultSportId: '1',
    ticketsWsUrl: 'https://test2-micros-1.gambling-solutions.ro',
    ticketsWsPath: '/digitain-tickets/ws',
    ticketsUrl: 'https://test2-micros-1.gambling-solutions.ro/digitain-tickets/player',
    widgetUrl: 'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl: 'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl: 'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',
    marketCountNsoft: 3,
});

const digitainMicrosConfig = () => ({
    wsUrl: 'https://micros1-ro.play-online.com',
    wsPath: '/digitain-fetcher/live/ws',
    ticketsWsUrl: 'https://micros1-ro.play-online.com',
    ticketsWsPath: '/digitain-tickets/ws',
    ticketsUrl: 'https://micros1-ro.play-online.com/digitain-tickets/player',
    apiURL: 'https://micros1-ro.play-online.com/digitain-fetcher/public',
    widgetUrl: 'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl: 'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl: 'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',
    defaultSportId: '1',
    marketCountNsoft: 3,
});

const digitainWinnerFunDevConfig = () => ({
    wsUrl: 'https://test2-micros-1.gambling-solutions.ro',
    wsPath: '/digitain-fetcher/live/ws',
    ticketsWsUrl: 'https://test2-micros-1.gambling-solutions.ro',
    ticketsWsPath: '/digitain-tickets-wf/ws',
    ticketsUrl: 'https://test2-micros-1.gambling-solutions.ro/digitain-tickets-wf/player',
    apiURL: 'https://test2-micros-1.gambling-solutions.ro/digitain-fetcher/public',
    widgetUrl: 'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl: 'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl: 'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',
    defaultSportId: '1',
    marketCountNsoft: 3,
});

const digitainWinnerFunMicrosConfig = () => ({
    wsUrl: 'https://micros1-ro.play-online.com',
    wsPath: '/digitain-fetcher/live/ws',
    ticketsWsUrl: 'https://micros1-ro.play-online.com',
    ticketsWsPath: '/digitain-tickets-wf/ws',
    ticketsUrl: 'https://micros1-ro.play-online.com/digitain-tickets-wf/player',
    apiURL: 'https://micros1-ro.play-online.com/digitain-fetcher/public',
    widgetUrl: 'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl: 'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl: 'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',
    defaultSportId: '1',
    marketCountNsoft: 3,
});

let digitainConfig = () => {
    if (
        (process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && window.config && window.config.environment !== 'production') ||
        (typeof window !== 'undefined' && window.config && window.config.environment !== 'production')
    ) {
        return mergeCfg(digitainDevConfig(), digitainMicrosConfig());
    }
    return digitainMicrosConfig();
};

let digitainWinnerFunConfig = () => {
    if (
        (process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && window.config && window.config.environment !== 'production') ||
        (typeof window !== 'undefined' && window.config && window.config.environment !== 'production')
    ) {
        return mergeCfg(digitainWinnerFunDevConfig(), digitainWinnerFunMicrosConfig());
    }
    return digitainWinnerFunMicrosConfig();
};

export { digitainConfig, digitainWinnerFunConfig };
