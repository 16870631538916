import React, { Component } from 'react';

import connect from "react-redux/es/connect/connect";
import PopupBonusDialog from "../../../PopupBonus/PopupBonusDialog";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import './Step5.scss'
import * as storeActions from "../../../../store/actions";

class Step5 extends Component
{
    state = {
        show: false,
        marketingOffer: false
    }

    componentDidMount() {
        this.setMarketingOffer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.signedUp !== this.props.signedUp) {

            if (this.props.signedUp) {

                if (this.props.marketingOffer.bonus_preset_id) {
                    this.setState({
                        ...this.state,
                        show: true
                    })
                    this.props.nextStep();
                } else {
                    this.handleClose();
                }
            }
        }
        if (this.props.marketingOffer !== prevProps.marketingOffer) {
            this.setMarketingOffer();
        }
    }
    setMarketingOffer() {
        if (this.props.marketingOffer) {
            let uiElements = {
                cta: false,
            };
            if (typeof this.props.marketingOffer.ui_elements !== "undefined" && this.props.marketingOffer.ui_elements) {
                try {

                    let elements = JSON.parse(this.props.marketingOffer.ui_elements);
                    if (elements) {
                        uiElements.cta = {};
                        if (typeof elements.cta_text) {
                            uiElements.cta.text  = JSON.stringify({text: elements.cta_text});
                        }
                        if (typeof elements.cta_url) {
                            uiElements.cta.link = elements.cta_url;
                        }
                    }
                } catch (e) {
                    //silence
                }
                this.setState({
                    ...this.state,
                    marketingOffer: {
                        ...this.props.marketingOffer,
                        ui_elements: uiElements
                    }
                })
            }

        }
    }
    handleClose() {
        this.props.history.push('/');
        this.props.resetRegister();

    }
    render() {
        if (!this.state.marketingOffer) {
            return null;
        }
        return (
            <div className="step-5">
                <PopupBonusDialog open={this.state.show} onClose={this.handleClose.bind(this)} data={{
                    art: this.state.marketingOffer.ftd_image !== null ? this.state.marketingOffer.ftd_image : this.state.marketingOffer.register_image,
                    cta_text_label: typeof this.state.marketingOffer.ui_elements.cta.text !== "undefined" ? this.state.marketingOffer.ui_elements.cta.text : "",
                    later_text_label: '{"text":{"ro":"MAI TARZIU","en":"LATER"},"style":""}',
                    cta_link: this.state.marketingOffer.ui_elements.cta.link !== "undefined" ? this.state.marketingOffer.ui_elements.cta.link : "",
                    bonus_preset_id: this.state.marketingOffer.bonus_preset_id
                }} />
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetRegister: () => dispatch(storeActions.resetRegister()),
        nextStep: () => dispatch(storeActions.setCurrentStep(5))
    };
};

const mapStateToProps = state => {

    return {
        signedUp: state.register.signedUp,
        marketingOffer: state.marketingOffer.marketingOffer,
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)((Step5))));
