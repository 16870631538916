import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { connect } from "react-redux";
import { getBetsState } from "../../store/selectors/betData";
import { withTranslation } from "react-i18next";
import moment from "moment";

import Details from "./Details";
import Match from "./MatchV2";

import { withCommonStyles } from "../../styles";
import { debug } from "../../utils";
import { showMatchPopupWithDataSet } from "../../utils/betUtils";

import { betsSlipAdd, betsSlipToggle, betsSlipClear } from "../../store/actions/betsSlip";
import { formatValue } from "../../utils/formatters";
import CashoutModal from "./CashoutModal";
import { pushUnique } from "../../utils";
import { prematchFetchMatches } from "../../store/actions/prematch";

const noop = () => { };

const useStyles = makeStyles({
	ticketRoot: {
		margin: "7px 7px 0px 7px",
		padding: 7,
		backgroundColor: "#FFFFFF",
		borderRadius: 7
	},
	flex: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	pp: {
		margin: "5px 7px 0px 7px"
	},
	ticketData: {
		padding: "4px 4px 6px 4px",
		fontSize: 12,
		fontWeight: 600,
		color: "#17214D"
	},
	bigButton: {
		alignSelf: "stretch",
		width: "45%",
		padding: "6px 8px",
		borderRadius: 11,
		fontSize: 14,
		color: "#FFFFFF",
		height: "42px"
	},
	cashOutButton: {
		background: "linear-gradient(90deg, #F8991C 0%, #FF196E 100%)",
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "space-around",
		alignItems: "center",
		alignContent: "flex-end",
		"&.Mui-disabled": {
			background: "linear-gradient(90deg, #A7A7A7 0%, #DBDBDB 100%)"
		}
	},
	cashOutSum: {
		fontSize: 9
	},
	recoverButton: {
		background: "linear-gradient(270deg, #1F83E6 0%, #06D2BD 100%)"
	},
	cashoutButton: {
		background: "linear-gradient(to right, #F8991C 0%, #FF5619 100%)",
		width: "100%",
		whiteSpace: "nowrap",
		//maxWidth: "100px",
		"&.Mui-disabled": {
			background: "linear-gradient(90deg, #A7A7A7 0%, #DBDBDB 100%)"
		}
	},
	cashoutText: {
		fontSize: 14,
		color: "#FFFFFF",
		fontWeight: "600",
		lineHeight: "14px",
		whiteSpace: "nowrap"
	},
	cashoutSubText: {
		color: "#fff",
		fontSize: "9px",
		lineHeight: "9px",
		fontWeight: "bold",
		whiteSpace: "nowrap"
	},
	mt2: {
		marginTop: "16px"
	},
	winner: {
		backgroundColor: "#06D2BD",
		borderRadius: "7px",
		fontSize: "10px",
		fontWeight: "600",
		color: "#fff",
		padding: "3px 11px 4px"
	},
	lost: {
		backgroundColor: "#FF196E",
		borderRadius: "7px",
		fontSize: "10px",
		fontWeight: "600",
		color: "#fff",
		padding: "3px 11px 4px"
	},
	opened: {
		backgroundColor: "#1F83E6",
		borderRadius: "7px",
		fontSize: "10px",
		fontWeight: "600",
		color: "#fff",
		padding: "3px 11px 4px"
	},
	rejected: {
		backgroundColor: "#FF7700",
		borderRadius: "7px",
		fontSize: "10px",
		fontWeight: "600",
		color: "#fff",
		padding: "3px 11px 4px"
	},
	subText: {
		fontSize: "9px",
		fontWeight: "600",
		color: "#979DAB",
		maxWidth: "150px",
		marginTop: "2px"
	},
	w42: {
		width: "42%"
	},
	lostColor: {
		color: "#FF196E"
	},
	wonColor: {
		color: "#06D2BD"
	},
	subTitle: {
		color: "#17214D",
		fontSize: "10px",
		lineHeight: "5px",
		opacity: ".6",
		paddingLeft: "4px"
	},
	cp: {
		textTransform: "capitalize"
	}
});

const Expanded = ({ ticket, winnerFun, matches, liveMatches, cashout, cashoutState, addBet, toggleBet, clearBets, prematchFetchMatches, goBack, t }) => {
	const classes = withCommonStyles(useStyles);

	const [showCashout, setShowCashout] = React.useState(false);

	const type = ticket.type;
	const lost = ticket.betsRes.lost;
	const won = ticket.betsRes.won;
	const txt = type === "SIMPLU" ? ticket.bets.length : ticket.systemType;

	let typeText = (
		<React.Fragment>
			{txt}
			{won !== 0 && ticket.status === "OPEN" && (
				<React.Fragment>
					&nbsp;
					<span>
						(<span className={classes.wonColor}>{won}</span>)
					</span>
				</React.Fragment>
			)}
			{lost !== 0 && (
				<React.Fragment>
					&nbsp;
					<span>
						(<span className={classes.lostColor}>{lost}</span>)
					</span>
				</React.Fragment>
			)}
			{ticket.cashBack === 1 && (
				<span>
					&nbsp;|&nbsp;
					<span>
						{t("Cashback")}: {formatValue(ticket.payout)}
					</span>
				</span>
			)}
		</React.Fragment>
	);
	if (typeText && typeText.props && typeText.props.children) {
		let hasContent = false;
		typeText.props.children.forEach(c => {
			if (c) hasContent = true;
		});
		if (!hasContent) {
			typeText = null;
		}
	}

	//console.log(`lost: *${lost}*; won: *${won}*; txt: *${txt}*`, typeText);

	let statusClass = "";
	let statusText = "";

	switch (ticket.status) {
		case "WON": {
			statusClass = classes.winner;
			statusText = "Bilet Castigator";
			break;
		}
		case "LOST": {
			statusClass = classes.lost;
			statusText = "Bilet Pierdut";
			break;
		}
		case "OPEN": {
			statusClass = classes.opened;
			statusText = "In Curs";
			break;
		}
		default: {
			statusClass = "";
			statusText = "";
		}
	}

	let canRecover = false;
	let hasLostBets = false;
	let hasOpenBets = false;

	ticket.bets.forEach(tb => {
		if (tb.status === "LOST") {
			hasLostBets = true;
			return;
		}

		if (tb.status !== "OPEN") {
			return;
		}

		if (!(tb.idMatch in matches)) {
			return;
		}

		hasOpenBets = true;
	});

	canRecover = hasLostBets && hasOpenBets;

	const recoverTicket = () => {
		debug("recover", ticket);

		let slipCleared = false;

		const ids = [];
		ticket.bets.forEach(tb => {
			if (tb.status !== "OPEN") {
				//console.log("bet is no longer open", tb);
				return;
			}

			if (!(tb.idMatch in matches)) {
				debug("match is no longer in state", tb.idMatch, matches);
				pushUnique(ids, tb.idMatch);
				return;
			}

			if (!matches[tb.idMatch]._loaded)
				pushUnique(ids, tb.idMatch);
		});
		if (ids.length) {
			prematchFetchMatches(ids, "favorites");
		}

		ticket.bets.forEach(tb => {
			if (tb.status !== "OPEN") {
				//console.log("bet is no longer open", tb);
				return;
			}

			if (!(tb.idMatch in matches)) {
				debug("match is no longer in state", tb.idMatch, matches);
				return;
			}

			debug(
				"recover bet",
				ticket.format,
				tb.idSport + "",
				tb.idMatch + "",
				tb.idBet + "",
				tb.idMatchBet + "",
				tb.idBetOutcome + "",
				tb.idMatchBetOutcome + ""
			);

			if (!slipCleared) {
				slipCleared = true;
				clearBets();
			}

			toggleBet(
				ticket.format,
				tb.idSport + "",
				tb.idMatch + "",
				tb.idBet + "",
				tb.idMatchBet + "",
				tb.idBetOutcome + "",
				tb.idMatchBetOutcome + ""
			);

			window.location.hash = "";
			if (typeof goBack === "function") goBack();
		});
	};

	let canCashout = false;
	if (cashout && cashout.ticketHash === ticket.idHash && !ticket.cashouted) {
		if (cashout.available) canCashout = true;
	}

	if (ticket && ticket.clientVal && typeof ticket.clientVal.free_bet_code !== "undefined") {
		canCashout = false;
	}

	if (canCashout) canRecover = false;

	const cashoutTicket = () => {
		setShowCashout(true);
	};
	const handleCashoutClose = () => {
		setShowCashout(false);
	};

	const handleMatchDetailsOpen = (e) => {
		window.location.hash = "";
		if (typeof goBack === "function") goBack();
		showMatchPopupWithDataSet(e);
	}

	const pBets = ticket.bets.filter(b => b.mType === "prematch").length;
	const lBets = ticket.bets.filter(b => b.mType === "live").length;

	let product = "Combo";
	if (pBets === 0) {
		product = "Live";
	} else if (lBets === 0) {
		product = "Prematch";
	}

	return (
		<div>
			<div className={`${classes.ticketRoot}`}>
				<div className={`${classes.flex}`}>
					<div className={classes.ticketData}>
						{product} {type} {typeText !== null ? "|" : ""} {typeText}
					</div>
					<div className={statusClass}>{statusText}</div>
				</div>
				<div className="d-flex flex-nowrap align-items-center justify-content-between">
					<div className={classes.subTitle}>
						{ticket.idHash} -{" "}
						<span className={classes.cp}>
							{moment(ticket.createdAt).format("D MMM, HH:mm")}
						</span>
					</div>
					{ticket.minWinning !== 0 && ticket.minWinning !== ticket.winning && ticket.status === "OPEN" &&
						<div className={`${classes.subTitle} ${classes.pr18}`}>
							{t("Min. Winning")}: {formatValue(ticket.minWinning)} {winnerFun ? "W" : "Lei"}
						</div>
					}
				</div>
			</div>
			{ticket.bets && ticket.bets.length > 2 && <div className={`${classes.ticketRoot} ${classes.pp}`}>
				<Details ticket={ticket} winnerFun={winnerFun} />
				{(canRecover || canCashout) && (
					<div
						className={`${classes.mt2} d-flex flex-row justify-content-between align-items-center`}
					>
						{canCashout && (<Button
							onClick={cashoutTicket}
							disabled={!(cashout.available && cashout.cashoutEnabled)}
							className={clsx(classes.bigButton, classes.cashoutButton)}
						>
							<div className="d-flex flex-column align-items-center justify-content-center">
								<div className={classes.cashoutText}>{t("CASH OUT")}</div>
								{cashout.available && cashout.cashoutEnabled && <div className={classes.cashoutSubText}>{formatValue(cashout.cashoutAmountAfterTax)} {winnerFun ? "W" : "Lei"}</div>}
							</div>
						</Button>)}
						{canRecover && (<Button
							onClick={recoverTicket}
							className={clsx(classes.bigButton, classes.recoverButton)}
						>
							{t("Recover")}
						</Button>)}
						{!canRecover && <div>&nbsp;</div>}
					</div>
				)}
				{canRecover && (
					<div className={`d-flex flex-row justify-content-between align-items-center`}>
						<div className={classes.w42}>&nbsp;</div>
						<div className={classes.w42}>
							<div className={classes.subText}>
								*{t("Place the same ticket without losing matches")}
							</div>
						</div>
					</div>
				)}
			</div>}
			<div>
				{ticket.bets.map((bet, i) => {
					let clickHandler = handleMatchDetailsOpen;
					let idMatch = null;
					if (ticket.format === "prematch") {
						if (bet.brId && bet.brId in liveMatches) {
							idMatch = bet.brId;
						}
					} else {
						idMatch = bet.idMatch;
					}

					if (!idMatch) clickHandler = noop;

					return <div key={i} onClick={clickHandler} data-mtype={bet.mType} data-idmatch={idMatch}>
						<Match bet={bet} ticket={ticket} />
					</div>
				})}
			</div>
			<div className={`${classes.ticketRoot} ${classes.pp}`}>
				{ticket.bets && ticket.bets.length < 3 && <Details ticket={ticket} winnerFun={winnerFun} />}
				{(canRecover || canCashout) && (
					<div
						className={`${ticket.bets && ticket.bets.length < 3 ? classes.mt2 : ""} d-flex flex-row justify-content-between align-items-center`}
					>
						{canCashout && (<Button
							onClick={cashoutTicket}
							disabled={!(cashout.available && cashout.cashoutEnabled)}
							className={clsx(classes.bigButton, classes.cashoutButton)}
						>
							<div className="d-flex flex-column align-items-center justify-content-center">
								<div className={classes.cashoutText}>{t("CASH OUT")}</div>
								{cashout.available && cashout.cashoutEnabled && <div className={classes.cashoutSubText}>{formatValue(cashout.cashoutAmountAfterTax)} {winnerFun ? "W" : "Lei"}</div>}
							</div>
						</Button>)}
						{canRecover && (<Button
							onClick={recoverTicket}
							className={clsx(classes.bigButton, classes.recoverButton)}
						>
							{t("Recover")}
						</Button>)}
						{!canRecover && <div>&nbsp;</div>}
					</div>
				)}
				{canRecover && (
					<div className={`d-flex flex-row justify-content-between align-items-center`}>
						<div className={classes.w42}>&nbsp;</div>
						<div className={classes.w42}>
							<div className={classes.subText}>
								*{t("Place the same ticket without losing matches")}
							</div>
						</div>
					</div>
				)}
			</div>
			{showCashout && <CashoutModal open={showCashout} handleClose={handleCashoutClose} provider={ticket.provider} cashout={cashout} cashoutState={cashoutState} isWinnerFun={winnerFun} ticketType={ticket.format} />}
		</div>
	);
};

const mapStateToProps = (state, props) => {
	const bst = getBetsState(state);

	let matches = [];
	if (
		props.ticket &&
		(props.ticket.format === "prematch" || props.ticket.format === "live") &&
		bst[props.ticket.format] &&
		bst[props.ticket.format].matches) {
		matches = bst[props.ticket.format].matches;
	}

	return {
		matches,
		liveMatches: bst.live && bst.live.matches ? bst.live.matches : {}
	};
};

const actionCreators = {
	addBet: betsSlipAdd,
	toggleBet: betsSlipToggle,
	clearBets: betsSlipClear,
	prematchFetchMatches: prematchFetchMatches
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(Expanded));
