import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from "moment";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import "./rules.scss";
import HeaderIcon from "../assets/dialog-icon.svg";
import evBusMain from "../../utils/evbus";
import { getText, styleToObj } from "../utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const empty = {};

const Rules = props => {
  const {
    open,
    onClose,
    winterStatus,
    defDay,
    i18n,
    t,
  } = props;

  const lang = i18n.language;

  const [day, setDay] = React.useState(parseInt(moment().format("D")));

  React.useEffect(() => {
    setDay(defDay);
  }, [defDay]);

  if (!open) return null;
  if (!winterStatus) return null;

  const handleClose = () => {
    if (typeof onClose === "function") onClose(false);
  }

  let dayPrize = null;
  if (winterStatus && winterStatus.winter_prizes) {
    Object.keys(winterStatus.winter_prizes).forEach(id => {
      if (winterStatus.winter_prizes[id].day === day.toString()) {
        if (winterStatus.winter_prizes[id].meta && winterStatus.winter_prizes[id].meta) {
          dayPrize = winterStatus.winter_prizes[id].meta;
        }
      }
    });
  }

  const resolve = () => {
    evBusMain.emit("CLOSE-WINTER-TIME-DIALOG");
    if (dayPrize && dayPrize.url) {
      props.history.push(dayPrize.url);
      return;
    }
    props.history.push("/deposit");
  };

  let rules = [];
  if (winterStatus && winterStatus.campaign_settings && winterStatus.campaign_settings.winter && winterStatus.campaign_settings.winter.rules) {
    rules = winterStatus.campaign_settings.winter.rules;
  }

  let buttonText = t("Deposit Now");
  let buttonStyle = null;

  if (dayPrize && dayPrize.button && dayPrize.button.text) {
    buttonText = getText(dayPrize, `button.text.${lang}`, t("Deposit Now"));
    if (dayPrize && dayPrize.button && dayPrize.button.style) {
      buttonStyle = styleToObj(dayPrize.button.style);
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winter-time-rules`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{t("Unlocked prize")}</div>
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>

          {rules.map((r, i) => {
            const txt = getText(r, lang, "");
            if (!txt) return null;
            return <div className={`dg-row`} key={i}>
              <div className="icon"></div>
              <div className="txt" dangerouslySetInnerHTML={{ __html: txt }} />
            </div>
          })}

          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={resolve} style={buttonStyle ? buttonStyle : empty}>
              {buttonText}
            </ButtonBase>
          </div>
          <div className="db-link" onClick={handleClose}>{t("Close")}</div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    winterStatus: state && state.promo && state.promo.winter && state.promo.winter.status ? state.promo.winter.status : null,
  }
};
const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Rules)));



