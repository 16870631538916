import React from 'react';
import { withTranslation } from "react-i18next";
import moment from "moment";
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./CalendarSummary.scss";
import { getWinnerFunState } from "../../store/selectors/winnerFunData";
import { chooseTextLanguage, choseImage } from "../../utils/functions";

import HeaderIconSVG from "../../assets/calendar-icon.svg";
import PeriodTimer from "../PeriodTimer/PeriodTimer";

let host = process.env.CFW.betsBannersImagesUrl;
if (host.substr(-1) !== "/") host += "/";

const CalendarSummary = (props) => {
  const { t, seasons, className } = props;
  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const imageType = isDesktop1024 ? "desktop" : "mobile";

  const goToDetails = () => {
    props.onTabChange(null, 3);
  }

  const buildEvent = (event, i) => {
    return <PeriodTimer
      startDate={moment(event.startDate, 'x').format("YYYY-MM-DD HH:mm:ss")}
      endDate={moment(event.endDate, 'x').format("YYYY-MM-DD HH:mm:ss")}
      key={`event-${i}`}
      render={(avail) => {
        let text = t("Finished");
        let isSoon = false;
        if (avail.remaining || avail.soon) {
          if (avail.diff.indexOf(":") > -1) {
            text = avail.diff;
          } else {
            text = avail.diff + " " + t("wf.days");
          }

          if (avail.soon) isSoon = true;
        }

        let progress = avail.progress ? avail.progress : 0;

        const style = {
          backgroundImage: `url("${choseImage(event.bgSummary, imageType)}")`,
        };

        return <div className="wf-event" style={style}>
          <div className="wf-icon">
            <img src={choseImage(event.icon, imageType)} alt="event icon" />
          </div>
          <div className="wf-info">
            <div className="wf-summary">
              <div className="d-flex align-items-start justify-content-between">
                <div className="d-flex flex-column">
                  <div className="wf-title">{chooseTextLanguage(event.name.text, props.i18n.language)}</div>
                  <div className="wf-subtitle">{event.type === "season" ? t("SEASON") : t("COMPETITION")}</div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="wf-days d-flex flex-column justify-content-center align-items-center">
                    <div className="wf-text">{isSoon ? t("Available in") : t("Time remaining")}</div>
                    <div className="wf-days-text">{text}</div>
                  </div>
                </div>
              </div>
              <div className={`wf-progress ${progress > 0 ? 'wf-started' : 'wf-ended'}`}>
                <LinearProgress variant="determinate" value={progress} />
              </div>
              <div className="d-flex align-items-center justify-content-end" onClick={goToDetails}>
                <div className="wf-details">{t("DETAILS")}</div>
              </div>
            </div>
          </div>
        </div>
      }} />
  };
  const buildSeason = (season, i) => {
    return <div key={`season_${i}`}>
      {buildEvent(season, "s" + i)}
      {season.events.map((event, j) => buildEvent(event, "e" + j))}
    </div>
  }

  return (
    <div className={`wf-calendar-summary ${className ? className : ""}`}>
      <div className="wf-header">
        <div className="wf-icon">
          <img src={HeaderIconSVG} alt="header icon" />
        </div>
        <div className="wf-title">{t("Calendar")}</div>
      </div>
      <div className="wf-body">
        {seasons.map((season, i) => {
          const now = moment().valueOf();

          if (parseInt(season.endDate) < now) return null;

          return buildSeason(season, i);
        })}
        <div className="wf-button" onClick={goToDetails}>
          {t("See calendar")}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);
  return {
    seasons: wfs.config && wfs.config.seasons ? wfs.config.seasons : [],
  };
};

export default withTranslation()(connect(mapStateToProps)(CalendarSummary));