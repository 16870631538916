import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Alert } from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import * as stateActions from "../../../../../store/actions";
import MaterialInputField from './../../../../MaterialInputField/MaterialInputField';
import PasswordGraph from './../../../PasswordGraph/PasswordGraph';
import { VelocityTransitionGroup } from "velocity-react";
import Icons from "../../../../../assets/Icons/Icons";
import './NewPassword.scss';
import { withTranslation } from "react-i18next";

class NewPassword extends Component {
    constructor(props) {
        super(props);

        this.screenStep = 2;
    }

    state = {
        inputType: 'password',
        inputGroupClassName: 'notValid',
    };

    toggleShowPassword() {
        if ('password' === this.state.inputType) {
            this.setState({
                ...this.state,
                inputType: 'text',
                inputGroupClassName: 'valid',
            });
            return;
        }

        this.setState({
            ...this.state,
            inputType: 'password',
            inputGroupClassName: 'notValid'
        });
    }

    updateNewHandler(event) {
        let value = event.target.value;
        this.props.onNewUpdate(value);
    }

    updateRepeatHandler(event) {
        let value = event.target.value;
        this.props.onRepeatUpdate(value);
    }

    changePasswordHandler() {
        if (true === this.props.profile.changePasswordForm.requested) {
            return;
        }

        if (false === this.props.profile.changePasswordForm.validNewPassword) {
            this.props.onStepError('error', this.props.t('New password is invalid!'));
            return;
        }

        if (!(this.props.profile.changePasswordForm.newPassword === this.props.profile.changePasswordForm.repeatPassword)) {
            this.props.onStepError('error', this.props.t("Passwords don't match"));
            return;
        }

        this.props.onSendRequest(
            this.props.profile.changePasswordForm.currentPassword,
            this.props.profile.changePasswordForm.newPassword
        );
    }

    cancelHandler() {
        if (true === this.props.profile.changePasswordForm.requested) {
            return;
        }

        this.props.onCloseModal(true);
    }

    onPasswordValidation(result) {
        if (null === this.props.profile.changePasswordForm) {
            return;
        }

        if (this.props.profile.changePasswordForm.validNewPassword === result) {
            return;
        }

        this.props.onValidation(result);
    }

    getMessage() {
        if (null === this.props.profile.changePasswordForm.step2Message) {
            return undefined;
        }

        let color = 'info';
        if ('error' === this.props.profile.changePasswordForm.step2Message.type) {
            color = 'danger';
        }

        if ('success' === this.props.profile.changePasswordForm.step2Message.type) {
            color = 'success';
        }

        return (
            <Alert color={color}>
                {this.props.profile.changePasswordForm.step2Message.message}
            </Alert>
        );
    }

    genFieldIcon() {
        let icon = Icons.get('password-hide', 'login-check');
        let iconElement = <button className="showPassword" onClick={this.toggleShowPassword.bind(this)}>{icon}</button>;
        return iconElement;
    }

    componentDidUpdate() {
        if (this.screenStep !== this.props.currentStep) {
            return;
        }

        if (1 === this.props.profile.changePasswordForm.step) {
            this.props.previousStep();
        }
    }

    render() {
        let activeField = (null !== this.props.profile.changePasswordForm && this.screenStep === this.props.profile.changePasswordForm.step) ? true : false;

        let buttonsSupportClassName = [
            'align-self-center',
            'text-center',
            'w-100',
            'buttonsSupport',
        ];

        let loaderSupportClassName = [
            'pm-loader',
            'w-100',
            'text-center',
        ];

        if (true === this.props.profile.changePasswordForm.requested || true === this.props.profile.changePasswordForm.success) {
            buttonsSupportClassName.push('hide');
        }

        if (false === this.props.profile.changePasswordForm.requested || true === this.props.profile.changePasswordForm.success) {
            loaderSupportClassName.push('dontShow');
        }

        return (
            <div className="d-flex justify-content-center NewPassword">
                <div className="step-support w-100">
                    <div className="step-header">
                        <h2>
                            {this.props.t("Step")} {this.props.currentStep} {this.props.t("of")} {this.props.totalSteps}
                        </h2>
                    </div>
                    <div className="step-content d-flex">
                        <div className="align-self-center text-center w-100">
                            <MaterialInputField inputLabel={this.props.t("New password")}
                                inputType={this.state.inputType}
                                inputIdName="chp_new_password"
                                inputValue={this.props.profile.changePasswordForm.newPassword}
                                changeHandler={this.updateNewHandler.bind(this)}
                                onlyOneFocus={true} elemIcon={this.genFieldIcon()}
                                groupClassName={this.state.inputGroupClassName}
                                activeField={activeField} />
                            <PasswordGraph validationAction={this.onPasswordValidation.bind(this)} profile={this.props.profile} />
                            <MaterialInputField inputLabel={this.props.t("Repeat password")}
                                inputType="password"
                                inputIdName="chp_repeat_password"
                                inputValue={this.props.profile.changePasswordForm.repeatPassword}
                                changeHandler={this.updateRepeatHandler.bind(this)}
                                noFocus={true}
                                activeField={activeField} />
                        </div>
                    </div>
                    <div className="step-message d-flex">
                        <div className="align-self-center text-center w-100">
                            { /* here the error */}
                            <VelocityTransitionGroup enter={{ animation: "fadeIn" }} leave={{ animation: "fadeOut" }} duration={500}>
                                {this.getMessage()}
                            </VelocityTransitionGroup>
                        </div>
                    </div>
                    <div className="step-footer d-flex">
                        <div className={loaderSupportClassName.join(" ")}>
                            <PulseLoader />
                        </div>
                        <div className={buttonsSupportClassName.join(" ")}>
                            <Button color="warning" onClick={this.changePasswordHandler.bind(this)} className="w-100 requestPasswordChange">
                                <span className="text text-left">{this.props.t("Update password")}</span>
                            </Button>
                            <Button outline color="danger" onClick={this.cancelHandler.bind(this)} className="w-100 cancelButton">
                                <span className="text text-left">{this.props.t("cancel")}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNewUpdate: (value) => dispatch(stateActions.updateNewPassword(value)),
        onRepeatUpdate: (value) => dispatch(stateActions.updateRepeatPassword(value)),
        onCloseModal: (clear) => dispatch(stateActions.closePasswordModal(clear)),
        onValidation: (status) => dispatch(stateActions.validationProcess(status)),
        onStepError: (type, message) => dispatch(stateActions.step2Message(type, message)),
        onSendRequest: (currentPassword, newPassword) => dispatch(stateActions.changePlayerPassword(currentPassword, newPassword)),
    };
};

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NewPassword));