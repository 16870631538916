import React, { Component } from 'react';
import './Menu.css';
import { connect } from 'react-redux';
import * as storeActions from './../../store/actions';
import BackendClient from './../../BackendClient';
import MainMenu from './MainMenu/MainMenu';
import SubMenu from './SubMenu/SubMenu';
import * as paths from './../../paths';


class Menu extends Component {
    constructor(props) {
        super(props);

        this.menuUrl = paths.menu.ALL;
        this.allowRequest = true;
        let specs = props.data;
        if (!(Object.keys(specs).length > 1)) {
            this.getMenu();
        } else {
            this.create(specs);
        }
    }

    getMenu() {
        if (!this.allowRequest) {
            return;
        }

        this.allowRequest = false;

        let self = this;
        let axios = BackendClient.getInstance();
        axios({
            url: self.menuUrl,
            method: 'get'
        });
    }

    create(specs) {
        this.props.setMenu(specs);
    }

    render() {
        return (
            <div className="menus container-fluid">
                <MainMenu specs={this.props.menu} />
                {window.config.lobbyEnabled !== "1" && <SubMenu submenus={this.props.page.submenus} page={this.props.page} />}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        page: state.application.page,
        menu: state.application.menu,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setMenu: (items) => dispatch(storeActions.menuLoaded(items)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);