import React from 'react';
import { withTranslation } from 'react-i18next';

import "./ranking-info.scss";
import Debugger from "../../../utils/logger";
import DefaultAvatar from "../../assets/img-avatar-default.png";
import WhiteArrow from "./assets/white-arrow.svg";
import GreenArrow from "./assets/green-arrow-up.svg";
import { formatNumber } from "../../utils/functions";

const logger = Debugger(Debugger.DEBUG, "tm-sb-ranking-info");

const RankingInfo = (props) => {
  const { player, top, nextPrize, totalPrizes, t, i18n } = props;

  logger.debug({ player, top, nextPrize });

  if (window.config.sportTournamentBetslipShowPlayerRank !== "1") return null;

  if (!player) return null;
  if (!top) return null;

  let cp = null;
  for (let i = 0; i < player.length; i++) {
    if (player[i] && player[i].me) {
      cp = player[i];
    }
  }

  /* cp = {
      award: "Premiul 2"
      me: true
      nickname: "Vony"
      pos: 2
      score: 538
    }
  */

  if (!cp) return null;

  let first = false;
  let outside = false;

  if (top && top.length > 0 && top[0].me) {
    first = true;
  }
  if (cp.pos > totalPrizes) {
    outside = true;
  }

  var mePos = outside ? `${totalPrizes}+` : cp.pos;

  let rightSideName = <div className='tm-column tm-nickname tm-text-right'>{cp.nickname}</div>;
  let rightSide = <div className='tm-columns tm-column tm-max-width-45'>
    <div className="tm-column">
      <div className={`tm-prize ${first ? 'tm-first-place' : ''}`}>{cp.award ? cp.award : '0 Lei'}</div>
      <div className='tm-points'>{cp.score ? formatNumber(cp.score, i18n.language) : 0} {t('pts')}</div>
    </div>
    <div className="tm-column">
      <div className="tm-avatar-wrapper tm-ml">
        <img src={cp.avatar ? cp.avatar : props.defaultAvatarImage} alt="" />
        <div className={`tm-position ${first ? 'tm-first-place' : ''}`}>
          #{mePos}
        </div>
      </div>
    </div>
  </div>;

  let abovePlayer = null;
  for (let i = 0; i < player.length; i++) {
    if (player[i] && player[i].me) {
      if (i > 0) {
        abovePlayer = player[i - 1];
        break;
      }
    }
  }

  const leftSideNickNames = [];
  const leftSide = [];
  let keyNickname = 0;
  let key = 0;

  if (abovePlayer && abovePlayer.pos !== 1) {

    leftSideNickNames.push(<div key={keyNickname++} className='tm-column tm-nickname tm-text-left'>{abovePlayer.nickname}</div>);

    let scoreOffset = abovePlayer.score - cp.score;

    leftSide.push(
      <div key={key++} className='tm-columns'>
        <div className="tm-column tm-centered">
          <div className={`tm-position2 tm-first-place`}>
            #{abovePlayer.pos > totalPrizes ? `${totalPrizes}+` : abovePlayer.pos}
            <img className='tm-position-up' src={GreenArrow} alt='' />
          </div>
        </div>
        <div className="">
          <div className="tm-avatar-wrapper tm-mr tm-alternative">
            <img src={abovePlayer.avatar ? abovePlayer.avatar : props.defaultAvatarImage} alt="" />
          </div>
        </div>
        <div className="tm-column">
          <div className={`tm-prize tm-regular-color`}>{abovePlayer.award ? abovePlayer.award : '0 Lei'}</div>
          <div className='tm-points tm-color-up'>+{scoreOffset ? formatNumber(scoreOffset, i18n.language) : 0} {t('pts')}</div>
        </div>
      </div>
    );
  }

  if (nextPrize) {
    leftSideNickNames.push(<div key={keyNickname++} className='tm-column tm-nickname tm-text-left'>{nextPrize.nickname}</div>);

    let scoreOffset = nextPrize.score - cp.score;

    leftSide.push(
      <div key={key++} className='tm-columns'>
        <div className="tm-column tm-centered">
          <div className={`tm-position2`}>
            #{nextPrize.pos > totalPrizes ? `${totalPrizes}+` : nextPrize.pos}
          </div>
        </div>
        <div className="">
          <div className="tm-avatar-wrapper tm-mr tm-alternative">
            <img src={nextPrize.avatar ? nextPrize.avatar : DefaultAvatar} alt="" />
          </div>
        </div>
        <div className="tm-column">
          <div className={`tm-prize tm-first-place`}><img className='tm-score-up' src={GreenArrow} alt='' /> {nextPrize.award ? nextPrize.award : '0 Lei'}</div>
          <div className='tm-points tm-color-up'>+{scoreOffset ? formatNumber(scoreOffset, i18n.language) : 0} {t('pts')}</div>
        </div>
      </div>
    );
  }

  if (first && top.length > 1) {

    const belowPlayer = top[1];

    leftSideNickNames.push(<div key={keyNickname++} className='tm-column tm-nickname tm-text-left'>{belowPlayer.nickname}</div>);

    let scoreOffset = cp.score - belowPlayer.score;

    leftSide.push(
      <div key={key++} className='tm-columns'>
        <div className="tm-column tm-centered">
          <div className={`tm-position2`}>
            #{belowPlayer.pos > totalPrizes ? `${totalPrizes}+` : belowPlayer.pos}
          </div>
        </div>
        <div className="">
          <div className="tm-avatar-wrapper tm-mr tm-alternative">
            <img src={belowPlayer.avatar ? belowPlayer.avatar : DefaultAvatar} alt="" />
          </div>
        </div>
        <div className="tm-column">
          <div className={`tm-prize tm-regular-color`}>{belowPlayer.award ? belowPlayer.award : '0 Lei'}</div>
          <div className='tm-points tm-color-down'>-{scoreOffset ? formatNumber(scoreOffset, i18n.language) : 0} {t('pts')}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="tm-sport-ranking-info">
      <div className='tm-columns tm-nicknames space-between'>
        <div className="tm-column tm-nom">
          <div className="tm-left-wrapper-outer-nicknames">
            <div className={`tm-left-wrapper-nicknames ${leftSide.length > 1 ? 'with-animation' : ''}`}>{leftSideNickNames}</div>
          </div>
        </div>
        {rightSideName}
      </div>

      <div className='tm-columns tm-informations'>
        <div className="tm-column tm-max-width-55">
          <div className="tm-left-wrapper-outer">
            <div className={`tm-left-wrapper ${leftSide.length > 1 ? 'with-animation' : ''}`}>{leftSide}</div>
          </div>
        </div>
        <div className="tm-separator">
          <div className="tm-line-863468712318723"></div>
          <img src={WhiteArrow} alt="" />
        </div>
        {rightSide}
      </div>
    </div>
  );
}

export default withTranslation()(RankingInfo);