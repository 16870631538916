import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import StepWizard from 'react-step-wizard';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import "../../../PlayerProfile/Modals/Withdraw/WithdrawModal.scss";
import Amount, {MINIMUM_DEPOSIT_VALUE} from "../../../Deposit/Screens/Amount";
import Utils from "../../../../utils/Utils";
import BackendClient from "../../../../BackendClient";
import Viva from "../../../Viva/Viva";
import * as stateActions from "../../../../store/actions";
import { getSelectedBonusValue } from "../../../ClaimBonus/BonusAvailableWidget/BonusAvailableWidget";
import RequestManager from "../../../../utils/RequestManager";
import Okto from "../../../Deposit/Okto/Okto";

class DepositModal extends Component {


    constructor(props) {
        super(props);

        // Utils.delayedFunction("getBonusesFromServer",this.getBonuses.bind(this),1000)
        this.preselectedPaymentMethod = props.paymentMethod ?? null;

        this.sitePath = window.config.front_url;
        this.loaderPath = this.sitePath + '/img/withdraw.gif';
        this.loaderStyle = {
            // backgroundImage: `url('${this.loaderPath}')`,
        };
        // this.props.getPromotions();
    }

    getBonuses() {
        this.props.getEligibleBonuses();
    }

    state = {
        opened: false,
        tempPlayerData: [],
        bonusGroupId: -1,
        vivaOnly: true,
        minBonus: 0,
        step: 1,
    }

    openModal() {
        this.setState({
            ...this.state,
            opened: true,
        })
    }

    componentDidMount() {
        Utils.delayedFunction('openDepositModalPayment', this.openModal.bind(this), 1000);
        if (!(window.config && window.config.vivaDesktopEnabled === '1' && window.config && window.config.vivaNewAccountTimestamp !== '0' && typeof window.config.vivaNewAccountTimestamp !== 'undefined')) {
            this.setState({
                ...this.state,
                vivaOnly: false
            });
        } else {
            this.props.getTransactionsHistory();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.transactions !== this.props.transactions) {
            if (this.props.transactions) {
                let lastMonth = new Date().getTime() / 1000 - 2592000;
                let _self = this;
                Object.keys(this.props.transactions).some(function (key) {
                    if (_self.props.transactions[key].type === 24) {
                        if (_self.props.transactions[key].insert_date < parseInt(window.config.vivaNewAccountTimestamp, 10)
                            && _self.props.transactions[key].insert_date > lastMonth) {
                            _self.setState({
                                ..._self.state,
                                vivaOnly: false
                            });
                            return true;
                        }

                        return true;
                    }
                })
            } else {
                this.setState({
                    ...this.state,
                    vivaOnly: true
                });
            }
        }

    }

    close() {
        this.setState({
            ...this.state,
            opened: false
        })
        this.props.history.push('/');
    }

    tempPlayerDataHandler(playerData) {
        if (playerData.bonusId) {
            let obj = {
                bonusId: playerData.bonusId,
                minBonus: playerData.minBonus,
                bonusObj: playerData.bonusObj,
            };
            this.setState({
                ...this.state,
                ...obj
            });

        } else if (playerData.limitMessage) {
            let obj = {};
            obj.limitMessage = playerData.limitMessage;
            if (playerData.maximumLimit) {
                obj.maximumLimit = playerData.maximumLimit
            }

            this.setState({
                ...this.state,
                ...obj
            })
        }
    }
    onStepChangeHandler(step,a,b,c) {

        this.setState({
            ...this.state,
            step: step.activeStep
        })
    }
    getSelectedBonus() {
        let selectedBonus = this.state.bonusId;

        if (!this.props.bonuses || selectedBonus === false || selectedBonus === -2) {
            return false;
        }
        let amountShown = this.props.amount;
        let bonusValue = 0;
        if (selectedBonus) {
            Object.keys(this.props.bonuses).forEach(key => {
                let bonus = this.props.bonuses[key];
                if (bonus.bonus_available_id === selectedBonus) {
                    if (amountShown * (bonus.bonus_available_amount / 100) < bonus.bonus_available_max) {
                        bonusValue = amountShown * (bonus.bonus_available_amount / 100);
                    } else {
                        bonusValue = bonus.bonus_available_max;
                    }
                }
            })
        }

        return bonusValue < this.state.minBonus ? false : bonusValue;
    }
    getModalContent(custom) {

        if (window.config && window.config.deposit_disabled === '1') {
            return (
                <p className={'deposit-disabled'}>
                    {this.props.t("Deposit is temporarily unavailable")}
                </p>
            );
        }
        return (
            <StepWizard transitions={custom} onStepChange={this.onStepChangeHandler.bind(this)}>

                <Amount step={this.state.step} stage={0} preselectedPaymentMethod={this.preselectedPaymentMethod}tempPlayerDataHandler={this.tempPlayerDataHandler.bind(this)} vivaOnly={this.state.vivaOnly} />
                <Amount stage={1} preselectedPaymentMethod={this.preselectedPaymentMethod}tempPlayerDataHandler={this.tempPlayerDataHandler.bind(this)} vivaOnly={this.state.vivaOnly} />
                <Amount stage={2} preselectedPaymentMethod={this.preselectedPaymentMethod}tempPlayerDataHandler={this.tempPlayerDataHandler.bind(this)} vivaOnly={this.state.vivaOnly} />
                <Viva
                    // tempPlayerDataHandler={(playerData) => this.tempPlayerDataHandler(playerData)}
                    // tempPlayerData={{
                    //     amount: this.props.amount,
                    //     bonusGroupId: this.props.bonusGroupId
                    // }}
                    amount={this.props.amount * 100}
                    bonusAmount={this.state.minBonus <= this.props.amount ? getSelectedBonusValue(this.state.bonusObj, this.props.amount) : this.props.t('No bonus')}
                    bonusId={(this.state.bonusId !== false && this.state.bonusId !== -2) ? this.state.bonusId : null}
                    vivaOnly={this.state.vivaOnly}
                // backHandler={() => backPageHandler()}
                />
                <Amount stage={3}  preselectedPaymentMethod={this.preselectedPaymentMethod} tempPlayerDataHandler={this.tempPlayerDataHandler.bind(this)} vivaOnly={this.state.vivaOnly}/>
                <Amount stage={4} preselectedPaymentMethod={this.preselectedPaymentMethod}  tempPlayerDataHandler={this.tempPlayerDataHandler.bind(this)} vivaOnly={this.state.vivaOnly}/>
                <div className="WithdrawModal">
                    <div className="modal-content">
                        <Okto amount={this.props.amount * 100}
                              bonusId={(this.state.bonusId !== false && this.state.bonusId !== -2) ? this.state.bonusId : null}
                              bonusMin={this.state.minBonus}
                              bonusMax={this.state.maxBonus}
                              maximumLimit={this.state.maximumLimit}
                              minimumLimit={MINIMUM_DEPOSIT_VALUE}
                            // dailyLimit={dailyLimit}
                              type={this.preselectedPaymentMethod === 4 ? "deposit-okto" : "deposit"}
                              step={this.state.step}
                            // onRequestTaxService={props.onRequestTaxService.bind(this)}
                              limitMessage={this.state.limitMessage}
                              taxes={this.state.depositTax}
                        />
                    </div>
                </div>
            </StepWizard>
        );
    }


    render() {


        let modalClassName = [
            "WithdrawModal", "h-100", "d-flex", "flex-column", "justify-content-center", "my-0"
        ];

        let custom = {
            enterRight: 'fadeIn',
            enterLeft: 'fadeIn',
        };



        return (
            <Fragment>
                <Modal isOpen={this.state.opened} className={modalClassName.join(" ")} backdrop={'static'} modalTransition={{ timeout: 300 }} zIndex={9999}>
                    <ModalHeader toggle={this.close.bind(this)} className="WithdrawModalHeader">{this.props.t("Deposit")}</ModalHeader>
                    <ModalBody className="WithdrawModalBody" style={this.loaderStyle}>

                        {this.getModalContent(custom)}

                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        // wallet: state.wallet,
        amount: state.deposit.payment.amount,
        bonuses: state.application.eligibleBonuses,
        transactions: state.transactions.depositsWithdrawals
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // getPromotions: () => dispatch(stateActions.bo()),
        getEligibleBonuses: () => dispatch(stateActions.getEligibleBonuses()),
        getTransactionsHistory: () => dispatch(stateActions.requestChangeHistoryList({ requestPath: '/profile/transactions' }))
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DepositModal)));
