import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import Fuse from 'fuse.js';

import "./search.scss";

import { loadedGames, loadingGames } from "../../store/actions/games";
import { openItem, itemThumbnail } from "../../utils/functions";
import { iosGames } from "../../../common/functions";

const apiUrl = process.env.CFW.betsApiUrl + "/casinoapi/config/games";

const fuseOptions = {
  includeScore: true,
  keys: ['name']
};

const Search = (props) => {
  const { games, onBack, popup, loadedGames, loadingGames, t } = props

  const [filtered, setFiltered] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [fuseObject, setFuseObject] = React.useState(new Fuse([], fuseOptions));

  const [dropShadow, setDropShadow] = React.useState(false);
  const probe = React.createRef();

  React.useEffect(() => {
    let observer = null;

    const fireEvent = (stuck) => {
      setDropShadow(oldVal => {
        if (stuck !== oldVal) {
          return stuck;
        }
        return oldVal;
      });
    };


    let probeRef = probe && probe.current ? probe.current : null;

    if (probeRef) {
      observer = new IntersectionObserver(
        (records, observer) => {
          for (const record of records) {
            const targetInfo = record.boundingClientRect;
            const rootBoundsInfo = record.rootBounds;

            if (targetInfo && rootBoundsInfo) {

              // Started sticking.
              if (targetInfo.bottom < rootBoundsInfo.top) {
                fireEvent(true);
              }

              // Stopped sticking.
              if (
                targetInfo.bottom >= rootBoundsInfo.top &&
                targetInfo.bottom < rootBoundsInfo.bottom
              ) {
                fireEvent(false);
              }
            }
          }
        },
        { threshold: [0] }
      );
      observer.observe(probeRef); // attach observer to our probe
    }

    return () => {
      if (observer) observer.disconnect();
    }
  }, [probe]); //eslint-disable-line

  React.useEffect(() => {
    if (games && games.length === 0) {
      loadingGames();
      axios.get(apiUrl + "/" + window.config.clientId + "?platformType=" + window.config.platformType)
        .then(response => {
          if (response && response.data) {
            loadedGames(response.data);
          }
        });
    }
    /*
    const inp = document.querySelector(".queryInput");
    window.scroll(0, 0);
    if (inp) inp.focus();
    */
  }, []); // eslint-disable-line

  React.useEffect(() => {
    setFuseObject(new Fuse(games, fuseOptions));
  }, [games]);

  React.useEffect(() => {
    if (query && query.length >= 2) {
      const res = fuseObject.search(query);
      setFiltered(res);
    } else {
      setFiltered([]);
    }
  }, [query, fuseObject]);

  const handleOnChange = (event) => {
    setQuery(event.target.value);
  };

  const mostMatched = filtered.slice(0, window.config.platformType.toString() === "1" ? 14 : 16);

  let searchLength = 0;
  mostMatched.forEach((g) => {
    if (window.config.platformType.toString() !== "1" && g.item && typeof g.item.desktopOnly !== "undefined" && g.item.desktopOnly) {
      return null;
    }

    if (window.config && window.config.cordova && window.config.native_platform === "ios") {
      if (typeof iosGames[g.item.game_id] === "undefined") {
        return null;
      }
    }
    searchLength += 1;
  });

  return (
    <div className={`lobby-special-component-search ${popup ? "popup" : ""}`}>
      <div className="search-probe" ref={probe}></div>
      <div className={`search-in ${dropShadow ? 'drop-shadow' : ''}`}>
        <input name="fakeUsername" type="text" className="d-none" />
        <input name="fakePassword" type="password" className="d-none" />
        <input type="text" value={query} onChange={handleOnChange} placeholder={t("Search in Casino")} name="query" className="queryInput" />
        <div className={`cancel-text ${popup ? "popup" : ""}`} onClick={onBack}>{t("Cancel")}</div>
      </div>
      {searchLength !== 0 && <div className="cgl-header"><span className="number">{searchLength}</span> {t(`results`)}</div>}
      {searchLength === 0 && query.length >= 2 && <div className="no-results">
        <div>{t(`We could not find any results for "{{QUERY}}".`, { QUERY: query })}</div>
        <div>{t("Try another word.")}</div>
      </div>}
      <div className={`cgl-list`}>
        <div className={`cgl-grid`}>
          {mostMatched.map((g, i) => {
            if (window.config.platformType.toString() !== "1" && g.item && typeof g.item.desktopOnly !== "undefined" && g.item.desktopOnly) {
              return null;
            }

            if (window.config && window.config.cordova && window.config.native_platform === "ios") {
              if (typeof iosGames[g.item.game_id] === "undefined") {
                return null;
              }
            }

            return <div
              className="cgl-wrapper"
              key={i}
              data-type={"slot_game"}
              data-item={JSON.stringify({ id: g.item.id, providerId: g.item.provider, name: g.item.name, gameId: g.item.game_id })}
              onClick={openItem}
            >
              <div className={`cgl-item`} >
                <div className="outer">
                  <div className="inner">
                    <img src={itemThumbnail({ data: {}, type: "slot_game", name: g.item.name, providerId: g.item.provider })} alt="" />
                  </div>
                </div>
              </div>
              <div className="gtxt">
                {g.item.name}
              </div>
            </div>;
          })}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    games: state.lobby.games.items,
    loading: state.lobby.games.loading
  }
};
const actionCreators = {
  loadedGames, loadingGames
}

export default withTranslation()(connect(mapStateToProps, actionCreators)(Search));