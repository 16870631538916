import { appConstants } from './constants';

export const appInitialize = () => ({
	type: appConstants.INITIALIZE
});

export const appSubscribeLiveText = idMatch => ({
	type: appConstants.SUBSCRIBE_LIVE_TEXT,
	idMatch
});

export const appUnsubscribeLiveText = idMatch => ({
	type: appConstants.UNSUBSCRIBE_LIVE_TEXT,
	idMatch
});

export const appLiveTextReceived = data => ({
	type: appConstants.LIVE_TEXT_RECEIVED,
	data
});

export const appLiveTextClear = () => ({
	type: appConstants.LIVE_TEXT_CLEAR
});

export const appSetSelectedBet = (group, name, midBet) => ({
	type: appConstants.SET_SELECTED_BET,
	group,
	name,
	midBet
});

export const appSaveReservedTicket = (tType, data) => ({
	type: appConstants.SAVE_RESERVED_TICKET,
	tType,
	data
});

export const appPlayerInitialize = token => ({
	type: appConstants.PLAYER_INITIALIZE,
	token
});

export const appSaveErrorLog = (req, error, details) => ({
	type: appConstants.SAVE_ERROR_LOG,
	req,
	error,
	details
});

export const appGetTicketMeta = (prematch, live) => ({
	type: appConstants.GET_TICKET_META,
	prematch,
	live
});

export const appEvaluateBonusRequest = data => ({
	type: appConstants.EVALUATE_BONUS_REQUEST,
	data
});

export const appBasePath = basePath => ({
	type: appConstants.SET_URL_BASE_PATH,
	basePath
});

export const appEvaluateWinnerFunRequest = data => ({
	type: appConstants.EVALUATE_WINNER_FUN_REQUEST,
	data
});

export const appEvaluateFreeBetRequest = data => ({
	type: appConstants.EVALUATE_FREE_BET_REQUEST,
	data
});

export const appEvaluateTournamentRequest = data => ({
	type: appConstants.EVALUATE_TOURNAMENT_REQUEST,
	data
});

export const appPlayerTicketsReceived = data => ({
	type: appConstants.PLAYER_TICKETS_RECEIVED,
	data
});

export const appWinnerFunStoreEval = tickets => ({
	type: appConstants.WINNER_FUN_STORE_EVAL,
	tickets
});
