import * as actionTypes from "../actions/actionTypes";

const DEFAULT_CURRENCY = "lei";

const initialState = {
	list: {},
};

const parseJackpotHistoryData = data => {
	const currency = data["currency"] || DEFAULT_CURRENCY;

	const thresholdLevels = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
	const jdKeys = [
		"current",
		"wins",
		"largestWin",
		"largestWinDate",
		"largestWinUser",
		"lastWin",
		"lastWinDate",
		"lastWinUser",
		"topMonthlyWinners",
		"topYearlyWinners"
	];

	let levels = [];
	let levelsValues = [];

	for (const tlevel of thresholdLevels) {
		// format key name as keyLevelX
		let kfn = kn => kn + "Level" + tlevel;

		// get value for formatted key
		let kfv = kn => data[kfn(kn)];

		const key = kfn(jdKeys[0]);

		// if we don't have data for the current level, stop
		if (key && !(key in data)) {
			break;
		}

		// new jackpot level
		let level = {};

		// get level data from received history
		jdKeys.forEach(key => {
			level[key] = kfv(key);
		});

		// store level
		levels.push(level);
	}

	// compute max value for all levels
	let maxValue = 0;
	let minValue = Number.MAX_SAFE_INTEGER;

	levels.forEach(level => {
		if (level.current > maxValue) {
			maxValue = level.current;
		}
		if (level.current < minValue) {
			minValue = level.current;
		}
		levelsValues.push(level.current);
	});

	levelsValues.sort(function (a, b) {
		return b - a;
	});

	if (minValue === Number.MAX_SAFE_INTEGER) {
		minValue = 0;
	}

	return {
		maxValue,
		minValue,
		currency,
		levels,
		levelsValues
	};
};

export const jackpotList = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.jackpotList.RECEIVED_DETAILS:
			const parsedData = parseJackpotHistoryData(action.data);
			const id = action.id;

			const updatedList = { ...state.list };

			updatedList[id] = {
				currency: parsedData.currency ? parsedData.currency : DEFAULT_CURRENCY,
				maxValue: parsedData.maxValue,
				minValue: parsedData.minValue,
				levels: parsedData.levels,
				levelsValues: parsedData.levelsValues,
				lastFetchTime: new Date().getTime()
			}

			return {
				...state,
				list: updatedList
			}
		default:
			return state
	}
};

export default jackpotList;
