import { makeStyles } from "@material-ui/core/styles";

const eventStyles = makeStyles({
	contentSlice: {
		alignSelf: "stretch",
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "space-between",
		alignItems: "center",
		alignContent: "flex-end"
	},
	teamBadge: {
		width: 11,
		height: 11,
		marginRight: 8
	},
	teamName: {
		fontSize: 12,
		fontWeight: 500,
		color: "#17214D"
	},
	dark: {
		color: "#fff"
	}
});

export default eventStyles;
