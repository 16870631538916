import { matchDetailsConstants } from './constants';

export const getMatchDetails = (data) => ({
  type: matchDetailsConstants.GET_MATCH_DETAILS,
  data
});

export const receivedInfo = data => ({
  type: matchDetailsConstants.RECEIVED_INFO,
  data
});

export const requestingData = data => ({
  type: matchDetailsConstants.REQUESTING_DATA,
  data
});

export const setOpenState = data => ({
  type: matchDetailsConstants.SET_OPEN_STATE,
  data
});
