import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { rerenderTournamentsGroups } from "../store/actions/tournaments_groups";

class Timer extends React.Component {
  state = {
    days: null,
    hours: null,
    mins: null,
    secs: null,
    formatted: "",
    expired: false,
  };
  intervalID = 0;

  calcTime = () => {
    const dt = moment(this.props.date);
    const dtNow = moment();

    const duration = moment.duration(dt.diff(dtNow, 'milliseconds'), 'milliseconds');

    let days = Math.floor(duration.asDays());
    let hours = duration.hours();
    const mins = duration.minutes();
    const secs = duration.seconds();

    if (days < 0 || hours < 0 || mins < 0 || secs < 0) {
      this.setState({
        days: 0,
        hours: '00',
        mins: '00',
        secs: '00',
        formatted: dt.format("DD MMM, HH:mm"),
        expired: true,
      });
      clearInterval(this.intervalID);
      const self = this;
      setTimeout(() => {
        self.props.rerenderTournamentsGroups();
      }, 1000);
      return;
    }

    this.setState({
      days: days,
      hours: ("0" + hours).slice(-2),
      mins: ("0" + mins).slice(-2),
      secs: ("0" + secs).slice(-2),
      formatted: dt.format("DD MMM, HH:mm"),
      expired: false,
    });
  }

  componentDidMount() {
    clearInterval(this.intervalID);
    this.intervalID = setInterval(() => {
      this.calcTime();
    }, 1000);
    this.calcTime();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    return this.props.children({
      ...this.props,
      ...this.state,
    });
  }
}

const actions = {
  rerenderTournamentsGroups
}

export default connect(null, actions)(Timer);