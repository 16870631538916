import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import connect from "react-redux/es/connect/connect";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Icons from "../../../assets/Icons/Icons";
import CircularProgress from "@material-ui/core/CircularProgress";

import Opened from "../Ticket/OpenedModule";
import Settled from "../Ticket/SettledModule";
import tickets from "../Ticket/virtuale.tickets.json";

import { ticketHistoryRequest } from "../../store/sagas/tickets";

const useStyles = makeStyles({
  root: {},
  exportWrapper: {
    padding: "10px 30px"
  },
  export: {
    "& svg": {
      height: "1.2rem",
      marginRight: "1em"
    }
  },
  header: {
    fontWeight: "bold",
    padding: "1em 1em 0",
    fontSize: "18px",
    textAlign: "left"
  }
});

const exportToCsv = (filename, rows) => {
  const processRow = row => {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\r\n";
  };

  let csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const processTickets = (tickets, t) => {
  let vm = [];

  vm = vm.concat(
    tickets.filter(
      t =>
        t.status === "Open" || t.status === "Lost" || t.status === "Won" || t.status === "Payedout"
    )
  );

  const ticketsData = [];

  const handleList = ticket => {
    let events = [];
    ticket.bets.forEach(bet => {
      let value = "";
      if (bet.value) {
        value = ` - ${Array.isArray(bet.value) ? bet.value.join(",") : bet.value}`;
      }
      const event = `${bet.type}${value}`;
      events.push(event);
    });

    ticketsData.push([
      ticket.barcode,
      moment(ticket.paymentDate).format("YYYY-MM-DD HH:mm:ss"),
      ticket.product,
      ticket.status === "Payedout" ? t("Payed Out") : t(ticket.status),
      events.join("; "),
      ticket.payment,
      ticket.payoutAmount ? ticket.payoutAmount : "0.00"
    ]);
  };

  vm.forEach(handleList);

  ticketsData.sort((ta, tb) => {
    if (ta[1] < tb[1]) {
      return -1;
    }
    if (ta[1] > tb[1]) {
      return 1;
    }
    return 0;
  });

  ticketsData.unshift([
    t("ID"),
    t("Created At"),
    t("Product"),
    t("Status"),
    t("Bets"),
    t("Stake"),
    t("Payed Out")
  ]);

  exportToCsv("tickets_history.csv", ticketsData);
};

const Virtual = ({ t, opened, settled, exportDisabled }) => {
  const classes = useStyles();

  //console.log("opened", opened, "settled", settled);

  const [inProgress, setInProgress] = React.useState(false);

  const downloadCsv = () => {
    const past90days = moment()
      .subtract(90, "day")
      .startOf("day")
      .format("YYYY-MM-DD hh:mm:ss");

    setInProgress(true);

    ticketHistoryRequest(past90days)
      .then(res => {
        setInProgress(false);
        processTickets(res.data, t);
      })
      .catch(e => {
        setInProgress(false);
        alert(t("There was an error generating transaction history. Please try again later."));
      });
  };

  return (
    <div className={`${classes.root} transactionsList`}>
      <div className={classes.header}>{t("Opened")}</div>
      <Opened />
      <div className={classes.header}>{t("Settled")}</div>
      <Settled />
      <div className={classes.exportWrapper}>
        {inProgress ? (
          <CircularProgress />
        ) : (
          <Button className={`${classes.export} downloadCsv`} onClick={downloadCsv} disabled={exportDisabled}>
            <div>
              {Icons.get("historyCsvDownload", "historyCsvDownload")}{" "}
              {t("Download History for last 90 days")}
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Virtual)));
