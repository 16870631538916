import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import "./tournaments-slider.scss";
import { getText, styleToObj, openItem, getData, emptyImage, launchSlotGame } from "../../utils/functions";

import StatsGlowing from "./assets/stats-glowing.svg";
import Timer from "../../../tournaments-missions/components/timer";
import ExtendedBackground from "./assets/extended-background@3x.png";
import Next from "./assets/next.svg";

import { loadTournamentsGroups } from "../../../tournaments-missions/store/actions/tournaments_groups";
import { loadedGames } from "../../store/actions/games";
import { tournamentActivate, tournamentEnroll } from "../../../tournaments-missions/store/actions/tournament";
import * as storeActions from "../../../store/actions";
import { loadLeaderBoard } from "../../../tournaments-missions/store/actions/leader_board";
import { doLogin } from "../../../store/actions/authentication";
import Random from "../../../tournaments-missions/assets/random.svg";


import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";
import Debugger from "../../../utils/logger";
import EvBusMain from "../../../utils/evbus";
import TournamentInfo from "../tournament-info/tournament-info";
import DialogUnblock from "../../../tournaments-missions/dialogs/dialog-unblock";

const logger = Debugger(Debugger.DEBUG, "tournaments-slider");
const apiUrl = process.env.CFW.betsApiUrl + "/casinoapi/config/games";

const emptyHash = {};

const TournamentsSlider = (props) => {
  const { data, i18n, groups, groupsLoaded, loadTournamentsGroups, t, history, ranking,
    games, gamesLoaded, loadedGames, tournamentActivate, tournamentEnroll, loadLeaderBoard,
    authentication, doLogin
  } = props;

  const [dialogUnblock, setDialogUnblock] = React.useState(false);
  const isDesktop = useMediaQuery("(min-width:1528px)");

  const [group, setGroup] = React.useState(null);
  const [activeTournaments, setActiveTournaments] = React.useState([]);

  React.useEffect(() => {
    if (!groupsLoaded) {
      loadTournamentsGroups();
    }
  }, [groupsLoaded]); // eslint-disable-line

  React.useEffect(() => {
    if (!gamesLoaded) {
      axios.get(apiUrl + "/" + window.config.clientId + "?platformType=" + window.config.platformType)
        .then(response => {
          if (response && response.data) {
            loadedGames(response.data);
          }
        });
    }
  }, [gamesLoaded, loadedGames]);

  React.useEffect(() => {
    const tgid = data && data.tournament_group && data.tournament_group.id ? data.tournament_group.id : 0;
    if (!tgid) return null;

    let group = groups.find(g => g.id === tgid);
    if (!group) return;
    setGroup(group);

    const tournaments = [...group.tournaments];

    tournaments.sort((a, b) => {
      if (a && a.start_date && b && b.end_date) {
        return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
      }
      return 0;
    });
    const now = moment().valueOf();

    const activeTournaments = tournaments.filter(t => {
      if (t && parseInt(t.end_date, 10) > now) return true;
      return false;
    });
    if (!activeTournaments.length) return;
    setActiveTournaments(activeTournaments);

    if (activeTournaments && activeTournaments.length &&
      activeTournaments[0].start_date <= now && now < activeTournaments[0].end_date &&
      typeof ranking[activeTournaments[0].id] === "undefined"
    ) {
      loadLeaderBoard({ tournamentId: activeTournaments[0].id });
    }
  }, [groups, data, loadLeaderBoard, ranking]);

  if (!groups || !groups.length) return null;
  if (!group) return null;
  if (!activeTournaments.length) return null;

  const now = moment().valueOf();
  logger.debug({ data, group, activeTournaments });

  let isCasinoTournament = group.type === 2;

  const lang = i18n.language;
  let extendedLayout = data && data.layout && data.layout === "extended";

  if (extendedLayout && isDesktop) {
    return <TournamentInfo data={data} />;
  }

  const redirectToTournament = (e) => {
    if (e) e.stopPropagation();
    if (window.config.platformType === 1) {
      EvBusMain.emit("OPEN-TOURNAMENTS-GROUPS-DIALOG", { path: group.url });
      return;
    }
    history.push(`/tournaments${group.url}`);
  };

  const playGame = (tournament, id) => {
    if (!isCasinoTournament) return redirectToTournament();

    let gameId = id;
    if (!gameId && tournament.meta && tournament.meta.games) {
      gameId = tournament.meta.games[Math.floor(Math.random() * tournament.meta.games.length)];
    }
    const found = games.filter(g => {
      if (g.game_id.toString() === gameId.toString()) return true;
      return false;
    });

    if (found && found.length) {
      const g = found[0];

      const gameData = {
        id: g.id,
        providerId: g.provider,
        name: g.name,
        gameId: g.game_id
      };

      launchSlotGame(gameData, { clientX: 10, clientY: 10 }, storeActions);
    }
  }

  const checkIfLogin = () => {
    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      if (window.config.platformType !== 1) {
        localStorage.setItem("redirect_after_login", window.location.pathname);
        history.push("/connect");
        return true;
      } else {
        doLogin();
        return true;
      }
    }
    return false;
  };

  const handleJoinPlay = (e) => {
    e.stopPropagation();

    if (checkIfLogin()) return;

    const tournament = activeTournaments[0];
    if (!tournament.enrolled) {
      tournamentEnroll({
        tournamentId: tournament.id,
        activate: true,
        cb: () => {
          playGame(tournament);
        }
      });
    } else if (!tournament.activated) {
      tournamentActivate({
        tournamentId: tournament.player_mission_id,
        cb: () => {
          playGame(tournament);
        }
      });
    } else {
      playGame(tournament);
    }
  };

  const handleUnblock = (e) => {
    e.stopPropagation();

    if (checkIfLogin()) return;

    setDialogUnblock(true);
  };
  const handleDialogUnblockClose = (e) => {
    e.stopPropagation();
    setDialogUnblock(false);
  }

  let list = <div className="cgl-list" onClick={!extendedLayout ? redirectToTournament : () => { }}>
    <div className="cgl-grid">
      {activeTournaments.slice(0, 8).map((tr, i) => {
        const isCurrent = tr.start_date <= now && now <= tr.end_date;

        const days = (tr.end_date - tr.start_date) / (24 * 60 * 60 * 1000);
        let dateString = '';
        if (days >= 1) {
          dateString = `${moment(tr.start_date).format("D MMM")} - ${moment(tr.end_date).format("D MMM")}`;
        } else {
          dateString = `${moment(tr.start_date).format("HH:mm")} - ${moment(tr.end_date).format("HH:mm")}`;
        }

        let logo = getData(tr, "meta.ui.corner_image.url", emptyImage);
        let prize1 = getText(tr, `meta.ui.prize_1.text.${lang}`, "");
        let prize2 = getText(tr, `meta.ui.prize_2.text.${lang}`, "");
        let name = tr.name;
        let games_image = getData(tr, "meta.ui.games_image.url", emptyImage);
        let gamesTotal = tr.meta && tr.meta.games && tr.meta.games.length ? tr.meta.games.length : 0;
        let top_image = getData(tr, "meta.ui.top_image.url", emptyImage);
        let levels = getData(tr, "meta.levels", 0);
        let showSticker = !tr.enrolled;
        let block_enroll = typeof group.block_enroll !== "undefined" && group.block_enroll;

        if (isCurrent) {
          return <div key={i} className={`cgl-item double ${extendedLayout ? 'extended' : ''}`} onClick={redirectToTournament}>
            {extendedLayout && <div className="header-tag-wrapper"><div className="header-tag">{t("ACTIVE NOW")}</div></div>}
            <div className="header">{dateString}</div>
            <div className="body-wrapper">
              <div className="body">
                <div className="body-header">
                  <div className="logo">
                    <img src={logo} alt="" />
                  </div>
                  <div className="prizes">
                    <div className="icon">
                      <img src={StatsGlowing} alt="" />
                    </div>
                    <div className="txt">
                      <div className="title">{prize1}</div>
                      <div className="subtitle">{prize2}</div>
                    </div>
                  </div>
                </div>

                <div className="body-title">
                  <div className="txt">
                    {name}
                  </div>
                </div>

                <div className={`body-images ${!showSticker ? 'mt10px' : ''}`}>
                  <div className="img-simple">
                    <img src={games_image} alt="" />
                    {isCasinoTournament && <div className="txt">
                      +{gamesTotal} {t("games")}
                    </div>}
                  </div>
                  <div className="img-award">
                    <img src={top_image} alt="" />
                    <div className="txt">
                      <div className="subtitle">TOP</div>
                      <div className="title">{levels}</div>
                    </div>
                    <div className="timer">
                      <Timer date={tr.end_date}>
                        {({ days, hours, mins, secs }) => {
                          let muted = 0;
                          if (!days || days.toString() === "0") muted += 1;
                          if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                          if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                          if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                          return <React.Fragment>
                            {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                          </React.Fragment>;
                        }}</Timer>
                    </div>
                  </div>
                </div>

                {showSticker && !block_enroll && <div className="sticker">
                  {t("You are not enrolled yet")}
                </div>}

                {block_enroll && <div className="sticker blocked">
                  {t("The tournament is blocked for you")}
                </div>}

                {!block_enroll && showSticker && <div className={`button`} onClick={handleJoinPlay}>
                  {t("Join")}
                </div>}

                {isCasinoTournament && !block_enroll && !showSticker &&
                  <div className="group-buttons">
                    <div className={`button regular random`} onClick={handleJoinPlay}>
                      <img src={Random} alt="" />
                    </div>
                    <div className={`button regular`} onClick={redirectToTournament}>
                      {t("Play")}
                    </div>
                  </div>}
                {!isCasinoTournament && !block_enroll && !showSticker &&
                  <div className="group-buttons">
                    <div className={`button regular`} onClick={redirectToTournament}>
                      {t("See Details")}
                    </div>
                  </div>}
                {block_enroll && <div className={`button blocked`} onClick={handleUnblock}>
                  {t("Unblock")}
                </div>}
              </div>
            </div>
          </div>;
        }

        return <div key={i} className={`cgl-item ${extendedLayout ? 'extended' : ''}`}>
          {extendedLayout && <div className="header-tag-wrapper"><div className="header-tag">{t("FUTURE")}</div></div>}
          <div className="header">{dateString}</div>
          <div className="body-wrapper">
            <div className="body">
              <div className="body-header">
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
                <div className="prizes">
                  <div className="txt">
                    <div className="title">{prize1}</div>
                    <div className="subtitle">{prize2}</div>
                  </div>
                </div>
              </div>

              <div className="body-title">
                <div className="txt">
                  {name}
                </div>
              </div>

              <div className={`body-images`}>
                <div className="img-simple">
                  <img src={games_image} alt="" />
                  <div className="txt">
                    +{gamesTotal} {t("games")}
                  </div>
                </div>
                <div className="img-award desktop">
                  <img src={top_image} alt="" />
                  <div className="txt">
                    <div className="subtitle">TOP</div>
                    <div className="title">{levels}</div>
                  </div>
                </div>
              </div>

              <div className="timers-wrapper">
                <div className="header">
                  {t("Begins in")}:
                </div>
                <Timer date={tr.start_date}>
                  {({ days, hours, mins, secs }) => {
                    let muted = 0;
                    if (!days || days.toString() === "0") muted += 1;
                    if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                    if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                    if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                    return <React.Fragment>
                      <div className="timers">
                        <div className={`timer ${muted >= 1 ? 'muted' : ''}`}>{days}</div>
                        <div className={`timer ${muted >= 2 ? 'muted' : ''}`}>{hours}</div>
                        <div className={`timer ${muted >= 3 ? 'muted' : ''}`}>{mins}</div>
                        <div className={`timer ${muted >= 4 ? 'muted' : ''}`}>{secs}</div>
                      </div>
                      <div className="timers-labels">
                        <div className={`timer ${muted >= 1 ? 'muted' : ''}`}>{t("DAY")}</div>
                        <div className={`timer ${muted >= 2 ? 'muted' : ''}`}>{t("HOUR")}</div>
                        <div className={`timer ${muted >= 3 ? 'muted' : ''}`}>{t("MIN")}</div>
                        <div className={`timer ${muted >= 4 ? 'muted' : ''}`}>{t("SEC")}</div>
                      </div>
                    </React.Fragment>;
                  }}</Timer>
              </div>
            </div>
          </div>
        </div>;
      })}
    </div>
  </div>;

  if (extendedLayout) {
    const style_bg = data.background ? styleToObj(data.background.style_bg) : emptyHash;
    const image = data.background && data.background.image ? data.background.image : ExtendedBackground;
    const style_img = data.background ? styleToObj(data.background.style_img) : emptyHash;

    style_img["backgroundImage"] = `url('${image}')`;

    const style_overlay = data.background ? styleToObj(data.background.style_overlay) : emptyHash;
    const style_list = data.list ? styleToObj(data.list.style) : emptyHash;

    list = <div className="extended-layout" onClick={redirectToTournament}>
      <div className="bg-image">
        <div className="wrapper" style={style_bg} />
        <div className="img" style={style_img} />
        <div className="gradient-filter" style={style_overlay} />
      </div>
      <div className="list-wrapper" style={style_list}>
        {list}
      </div>
      <div className="footer-line" onClick={redirectToTournament}>{t("TOURNAMENT DETAILS")} <img src={Next} alt="" /></div>
    </div>
  }

  const category = getText(data, `category.text.${lang}`, "");
  const title = getText(data, `title.text.${lang}`, "");
  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`tournaments-slider component`}
      style={styleToObj(data.style)}
    >
      {category !== "" && <div className="cgl-super-header">
        <div
          className="category"
          style={styleToObj(data.category.style)}
        >
          {category}
        </div>
      </div>}

      <div className="cgl-header">
        {title !== "" && <div
          className="title"
          style={styleToObj(data.title.style)}
        >
          {title}
        </div>}
        {hasNavigate && <div
          className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          <LobbyArrow />
        </div>}
      </div>

      {list}

      {
        dialogUnblock && <DialogUnblock open={dialogUnblock} onClose={handleDialogUnblockClose} tournament={activeTournaments[0]} group={group} />
      }
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication,
    games: state.lobby.games.items,
    gamesLoaded: state.lobby.games.items.length !== 0,
    groups: state.tournamentsMissions ? state.tournamentsMissions.groups.list : [],
    rerenderGroup: state.tournamentsMissions ? state.tournamentsMissions.groups.rerender : 0,
    ranking: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.top : {},
    groupsLoaded: state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
  }
};

const mapActions = {
  loadTournamentsGroups,
  loadedGames,
  tournamentActivate,
  tournamentEnroll,
  loadLeaderBoard,
  doLogin
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapActions)(TournamentsSlider)));
