import React from 'react';
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";
import Slide from '@material-ui/core/Slide';

import "./happy-hours.scss";
import GiftIcon from "../../assets/gift@2x.png";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { happyHourHideInfo } from "../../../../store/actions/happy_hour";

const Info = (props) => {
  const { t, data, showInfo, clearInfo } = props;

  const isPortrait = useMediaQuery("(orientation: portrait)");
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (showInfo) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [showInfo]);

  const onClearInfo = () => {
    setOpen(false);
    window.setTimeout(() => {
      clearInfo();
    }, 300)
  };

  if (!showInfo || !data) return null;

  return (
    <div className={`hh-ended ${isPortrait ? 'hh-portrait' : 'hh-landscape'}`} onClick={onClearInfo}>
      <Slide direction="down" in={open}>
        <div>
          <div className="hh-wrapper">
            <div className="hh-innerWrapper">
              <div className="hh-title">{data.name}</div>
              <div className="hh-prizes">
                <div className="hh-prize-won" style={{ marginLeft: 0 }}>
                  <div className="hh-title">{t("Total prizes")}:</div>
                  <div className="hh-gift"><img src={GiftIcon} alt="" /></div>
                  <div className="hh-amount">{data.total_prizes_amount} Lei</div>
                </div>
              </div>
              <div className="hh-fi">
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </div>
  );
}

const mapStateToProps = state => ({
  //data: state.happyHour.status,
  //showInfo: state.happyHour.showInfo
});

const actions = {
  clearInfo: happyHourHideInfo
}

export default withTranslation()(connect(mapStateToProps, actions)(Info));