import moment from "moment";
import localization from "moment/locale/ro.js";

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export const styleToObj = (style) => {
  const ret = {};

  if (!style) return ret;
  if (typeof style !== "string") return ret;

  const parts = style.split(";");

  parts.forEach(p => {
    const line = p.trim();

    if (line && line.indexOf(":") > -1) {
      let [key, value] = line.split(":", 2);
      key = toCamel(key.trim());
      value = value.trim();
      ret[key] = value;
    }
  });

  return ret;
};

const months = {
  "1": "Ian",
  "2": "Feb",
  "3": "Mart",
  "4": "Apr",
  "5": "Mai",
  "6": "Iun",
  "7": "Iul",
  "8": "Aug",
  "9": "Sept",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec"
};

export const formatDateWithTime = (date, lang) => {
  let refDate = moment();
  let tomorrow = moment().add(1, "days");
  let dt = moment(date);
  if (lang === "ro") {
    dt = moment(date).locale("ro", localization);
  }

  if (refDate.isSame(dt, "day")) {
    return `${lang === "ro" ? "Azi" : "Today"} ${dt.format("HH:mm")}`;
  }
  if (tomorrow.isSame(dt, "day")) {
    return `${lang === "ro" ? "Maine" : "Tomorrow"} ${dt.format("HH:mm")}`;
  }

  if (lang === "ro") {
    return `${dt.format("ddd, D ")}${months[dt.format("M")]
      .toUpperCase()
      .replace(".", "")} ${dt.format("HH:mm")}`;
  }
  return `${dt.format("ddd, ")}${dt
    .format("MMM")
    .toUpperCase()
    .replace(".", "")}${dt.format(" D")} ${dt.format("HH:mm")}`;
};

export const chooseTextLanguage = (txt, lang) => {
  if (typeof txt === "string") {
    return txt;
  };
  try {
    // check if we have translation for the selected language and return it
    if (typeof txt[lang] !== "undefined" && txt[lang]) {
      return txt[lang];
    }

    // choose first available language and return it
    const values = Object.values(txt);
    return values.length ? values[0] : '';
  } catch (err) {
    return "";
  }
};