import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import * as stateActions from "../../../store/actions";
import "./DepositModal.scss";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";

class DepositModal extends Component {
    constructor(props) {
        super(props);

        this.sitePath = window.config.front_url;
        this.smileImg = '/img/smile.png';
        this.subImg = '/img/deposit-sub-msg.png';
    }

    close() {
        this.props.closeModal();
    }


    goToDeposit() {
        this.close();
        this.props.history.push('/deposit');
    }

    mapsHandler() {
        if (!this.props.deposit.queryParams) {
            return;
        }

        window.open(`https://www.google.com/maps/dir/?api=1${this.props.deposit.queryParams}`, '_blank');
        this.close();
    }

    render() {
        let modalClassName = [
            "DepositModal", "h-100", "d-flex", "flex-column", "justify-content-center", "my-0"
        ];

        return (
            <Modal isOpen={this.props.deposit.modal} className={modalClassName.join(" ")} backdrop={'static'} modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader toggle={this.close.bind(this)} className="WithdrawModalHeader">{this.props.t("depunere agentie")}</ModalHeader>
                <ModalBody className="WithdrawModalBody">

                    <Animated animationIn="zoomIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={200}>
                        <div className={"depositMessage"}>
                            <div className={"top"}>
                                <h1>{this.props.t("Doar cu buletinul")}</h1>
                            </div>
                            <div className={"middle"}>
                                <div className={"headLine"}>
                                    <h2>{this.props.t("anywhere, any shop")}</h2>
                                </div>
                                <Animated animationIn="bounceIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={1000}>
                                    <div className={"successChar"}>
                                        <img src={this.sitePath + this.smileImg} />
                                    </div>
                                </Animated>
                                <p className={"message"}>
                                    {this.props.t("Mergi in orice agentie Eldorado si poti depune cat vrei doar cu buletinul")}.&nbsp;
                                    {this.props.t("Fara batai de cap, fara carduri")}.
                                </p>
                            </div>
                            <div className={"sub-msg"}>
                                <img src={this.sitePath + this.subImg} />
                            </div>
                            <div className={"bottom"}>
                                <Button color={"success"} onClick={this.goToDeposit.bind(this)}>{this.props.t("Online deposit")}</Button>
                                <br />
                                <br />
                                <Button color={"info"} onClick={this.mapsHandler.bind(this)}>{this.props.t("Harta cu Agentii")}</Button>
                            </div>
                        </div>
                    </Animated>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        deposit: state.deposit,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => dispatch(stateActions.openDepositModal()),
        closeModal: () => dispatch(stateActions.closeDepositModal()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DepositModal)));
