import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import "./main-view.scss";

import WinterTree from "../components/tree";
import CloseIcon from "../assets/close.svg";
import { ReactComponent as CalendarIcon } from "../assets/calendar.svg";
import { ReactComponent as UnlockCalendarIcon } from "../assets/unlock-calendar.svg";
import Decorations from "../assets/decorations.svg";
import Rules from "../components/rules";
import Error from "../components/error";
import { loadWinterStatus, loadedWinterStatus } from "../store/actions/winter";
import NewtonLoader from "../../newton-loader/index";
import { getText } from "../utils/functions";
import { doLogin } from "../../store/actions/authentication";
import evBusMain from "../../utils/evbus";

const highlightPositions = [
  [86.5, 247],
  [96.5, 281],
  [130.5, 297],
  [153.5, 329],
  [111.5, 349],
  [71.5, 358],
  [28.5, 373],
  [7.5, 411],
  [58.5, 423],
  [109.5, 429],
  [156.5, 429],
  [198.5, 441],
  [219.5, 470],
  [181.5, 478],
  [134.5, 490],
  [79.5, 490],
  [26.5, 493],
  [-12.5, 467],
  [150, 620],
  [20, 605],
  [91, 575],
  [180, 590],
  [235, 570],
  [-25, 570],
  [63, 620],
];

const renderStates = (winterStatus, day) => {
  if (!winterStatus) return;
  if (!winterStatus.winter_prizes) return;

  const globes = Array.from({ length: 18 }, (_, i) => i + 1);
  const giftbox = [19, 20, 21, 22, 23, 24, 25];

  let dayIndex = 0;
  const prizes = {};
  const prizesArray = [];

  Object.keys(winterStatus.winter_prizes).forEach(id => {
    prizesArray.push(winterStatus.winter_prizes[id]);
  });

  try {
    prizesArray.sort((a, b) => a.ts - b.ts);
  } catch (err) {/*noop*/ }
  prizesArray.forEach((p, index) => {
    prizes[index + 1] = false;
    if (winterStatus && winterStatus.winter_status && winterStatus.winter_status.prizes) {
      if (winterStatus.winter_status.prizes.indexOf(p.id) > -1) {
        prizes[index + 1] = true;
      }
    }
    if (p.day === day.toString()) {
      dayIndex = index + 1;
    }
  });

  globes.forEach(g => {
    const el = document.getElementById(`globe-${g}`);
    const elTxt = document.getElementById(`globe-${g}-txt`);
    if (el && elTxt) {
      el.style.display = "";
      el.setAttribute("fill", "url(#pattern-9)");
      elTxt.style.display = "";

      if (typeof prizes[g] !== "undefined") {
        if (g <= dayIndex) {
          elTxt.style.display = "block";
          if (prizes[g]) {
            el.setAttribute("fill", "url(#pattern-10)");
          } else {
            el.setAttribute("fill", "url(#pattern-11)");
          }
        }
      } else {
        el.style.display = "none";
      }
    }
  });

  giftbox.forEach(g => {
    const el = document.getElementById(`giftbox-${g}`);
    if (el) {
      el.style.display = "";
      el.style.filter = "";

      if (typeof prizes[g] !== "undefined") {
        if (g <= dayIndex) {
          if (prizes[g]) {
            el.style.display = "none";
          } else {
            el.style.filter = "grayscale(1)";
          }
        }
      } else {
        el.style.display = "none";
      }
    }
  });

  const hightLight = document.getElementById(`current_step`);
  const hightLightCircle = document.getElementById(`current_step_circle`);
  if (hightLightCircle) hightLightCircle.style.display = "none";

  if (dayIndex && dayIndex < 26 && typeof prizes[day.toString()] !== "undefined" && !prizes[day.toString()]) {
    hightLight.setAttribute("transform", `translate(${highlightPositions[dayIndex - 1][0]} ${highlightPositions[dayIndex - 1][1]})`);
    hightLight.style.display = "block";
  } else if (dayIndex) {
    if (dayIndex < 19) {
      hightLight.setAttribute("transform", `translate(${highlightPositions[dayIndex - 1][0]} ${highlightPositions[dayIndex - 1][1]})`);
      hightLight.style.display = "block";

    } else {
      hightLight.style.display = "";
    }
  }
};

let apiUrl = process.env.CFW.betsApiUrl + "/promo/winter";

const MainView = (props) => {
  const { t, onClose, winterStatus, winterLoading, loadWinterStatus, authentication, loadedWinterStatus, doLogin, history, i18n } = props;

  const [showOverlay, setShowOverlay] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const [openRules, setOpenRules] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [day, setDay] = React.useState(parseInt(moment().format("D")));
  const [loading, setLoading] = React.useState(false);

  const handleDayChange = (event) => {
    setDay(parseInt(event.target.value));
  };

  let dayIndex = 0;
  const prizesArray = [];

  Object.keys(winterStatus.winter_prizes).forEach(id => {
    prizesArray.push(winterStatus.winter_prizes[id]);
  });

  try {
    prizesArray.sort((a, b) => a.ts - b.ts);
  } catch (err) {/*noop*/ }
  prizesArray.forEach((p, index) => {
    if (p.day === day.toString()) {
      dayIndex = index + 1;
    }
  });

  React.useEffect(() => {
    document.documentElement.classList.add('promo-winter-html');
    document.body.classList.add('promo-winter');

    const meta = document.createElement('meta');
    meta.id = "theme-meta";
    meta.name = "theme-color";
    meta.content = "7a3312";
    document.getElementsByTagName('head')[0].appendChild(meta);

    return () => {
      document.documentElement.classList.remove('promo-winter-html');
      document.body.classList.remove('promo-winter');
      const el = document.getElementById("theme-meta");
      if (el) el.parentNode.removeChild(el);
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      if (window.config.platformType === 2) {
        localStorage.setItem("redirect_after_login", "/winter-time");
        // mobile - do a redirect
        if (window.config.cordova) {
          evBusMain.emit("TRY-FACE-UNLOCK-LOGIN");
        } else {
          history.push("/connect");
        }
      } else {
        // web - show modal
        evBusMain.emit("CLOSE-WINTER-TIME-DIALOG");
        doLogin();
      }
      return;
    }
    if (!winterLoading && !winterStatus && loadWinterStatus) {
      loadWinterStatus();
      return;
    }
    if (!winterLoading && winterStatus) {
      if (winterStatus && winterStatus.campaign_settings && winterStatus.campaign_settings.winter) {
        if (winterStatus.campaign_settings.winter.start_date && winterStatus.campaign_settings.winter.end_date) {
          const now = moment().valueOf();
          const start_start = winterStatus.campaign_settings.winter.start_date;
          const end_date = winterStatus.campaign_settings.winter.end_date;

          if (!(start_start <= now && now <= end_date)) {
            if (window.config.platformType === 1) {
              evBusMain.emit("CLOSE-WINTER-TIME-DIALOG");
            }
            history.push("/");
            return;
          }
        }
      }
      renderStates(winterStatus, day);
    }
  }, [winterStatus, winterLoading, day, authentication, doLogin, history, loadWinterStatus]);

  React.useEffect(() => {
    if (mounted) {
      const month = moment().format("MMM");

      let el = document.querySelector(".promo-winter-wrapper .winter-calendar-day");
      if (el) {
        el.innerHTML = day;
      }
      el = document.getElementById("calendar-day-unlock");
      if (el) {
        el.innerHTML = day;
      }
      el = document.querySelector(".promo-winter-wrapper .winter-calendar-month");
      if (el) {
        el.innerHTML = month.toLocaleUpperCase();
      }
      el = document.getElementById("calendar-month-unlock");
      if (el) {
        el.innerHTML = month.toLocaleUpperCase();
      }

    }
  }, [mounted, day]);

  const nodeRef = React.useCallback(node => {
    if (node !== null) {
      setMounted(true);
    }
  }, []);

  const handleClose = () => {
    if (typeof onClose === "function") return onClose();

    props.history.push("/");
  };

  const revealPrize = () => {
    setShowOverlay(true);
  };

  const handleOverlayClose = () => {
    setShowOverlay(false);
  };

  const handleUnlock = () => {
    if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
      return;
    }

    const headers = {
      "Authorization": "Bearer " + authentication.access_token,
    };

    axios.post(apiUrl + "/unlock", { day }, { headers })
      .then(resp => {
        console.log(resp);
        if (resp && resp.data && resp.data.success) {
          //setOpenRules(true);
          setOpenRules(true);
          loadedWinterStatus(resp.data);
        } else {
          setOpenError(true);
          loadedWinterStatus(resp.data);
        }
      })
      .catch(err => {
        console.log(err);
        setOpenError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleErrorClose = () => {
    setOpenError(false);
  };

  const handleRulesClose = () => {
    setShowOverlay(false);
    setOpenRules(false);
  };

  const getDayText = () => {
    let content = null;
    if (winterStatus && winterStatus.winter_prizes) {
      Object.keys(winterStatus.winter_prizes).forEach(id => {
        if (winterStatus.winter_prizes[id].day === day.toString()) {
          if (winterStatus.winter_prizes[id].meta && winterStatus.winter_prizes[id].meta.content && winterStatus.winter_prizes[id].meta.content.text) {
            content = winterStatus.winter_prizes[id].meta.content;
          }
        }
      });
    }

    return getText(content, `text.${i18n.language}`, "");
  };

  const checkUnlockState = () => {
    let unlock = true;
    if (winterStatus) {

      const prizes = {};

      if (winterStatus && winterStatus.winter_prizes) {
        Object.keys(winterStatus.winter_prizes).forEach(id => {
          prizes[winterStatus.winter_prizes[id].day] = false;
        });
      }

      if (winterStatus && winterStatus.winter_status && winterStatus.winter_status.prizes) {
        winterStatus.winter_status.prizes.forEach(id => {
          if (winterStatus && winterStatus.winter_prizes && typeof winterStatus.winter_prizes[id] !== "undefined") {
            if (winterStatus.winter_prizes[id].day) {
              prizes[winterStatus.winter_prizes[id].day] = true; // prize claimed on this day
            }
          }
        });
      }

      if (prizes[day.toString()]) { // already unlocked
        unlock = false;
      }
    }

    return unlock;
  };
  const canUnlock = checkUnlockState();

  const handleReveal = () => {
    revealPrize();
  };

  console.log({ day, dayIndex });

  return (
    <div ref={nodeRef} className={`promo-winter-main-view ${showOverlay ? 'show-overlay' : ''}`}>

      <div className="promo-winter-wrapper">
        <div className="tree-wrapper">
          <WinterTree onReveal={handleReveal} day={dayIndex} />
        </div>
        <div className="calendar">
          <CalendarIcon />
        </div>
        <div className="button-wrapper front">
          {dayIndex < 26 && <div className="pw-button" onClick={revealPrize}>
            <div className="pw-button-2">
              <div className="pw-button-3">
                {t("Discover today's prize")}
              </div>
            </div>
          </div>}

          {(window.config && window.config.promoDebug === "1") &&
            <FormControl className="days">
              <InputLabel id="day-label">Day</InputLabel>
              <Select
                labelId="day-label"
                value={day}
                onChange={handleDayChange}
              >
                {prizesArray.map((p, index) => {
                  return <MenuItem key={index} value={p.day}>{p.day}</MenuItem>
                })}
              </Select>
            </FormControl>
          }
        </div>
      </div>

      <div className="overlay">
        <div className="overlay-wrapper">
          <div className="header">
            {t("Today's prize")}
            <div className="calendar">
              <UnlockCalendarIcon />
            </div>
          </div>
          <div className="content-wrapper">
            <div className="prize-wrapper">
              <div className="prize-border">
                <div className="prize-content">
                  <div dangerouslySetInnerHTML={{ __html: getDayText() }} />
                </div>
              </div>
            </div>
            <img src={Decorations} alt="" className="decorations" />
          </div>
          {!canUnlock && <div className="unlocked-text">
            -&nbsp;{t("Unlocked")}&nbsp;-
          </div>}
          <div className="button-wrapper static">
            {canUnlock && <div className="pw-button" onClick={handleUnlock}>
              <div className="pw-button-2">
                <div className="pw-button-3 pw-button-4">
                  {t("Unlock")}
                </div>
              </div>
            </div>}
            {!canUnlock && <div className="pw-button" onClick={handleOverlayClose}>
              <div className="pw-button-2">
                <div className="pw-button-3 pw-button-4">
                  {t("Close")}
                </div>
              </div>
            </div>}
          </div>
        </div>
        <div className="close-overlay" onClick={handleOverlayClose}>
          <div className="close-icon">
            <img src={CloseIcon} alt="" />
          </div>
        </div>
      </div>

      {(winterLoading || loading) && <div className="loader-wrapper"><NewtonLoader /></div>}
      <div className="close-header" onClick={handleClose}>
        <div className="close-icon">
          <img src={CloseIcon} alt="" />
        </div>
      </div>

      {openRules && <Rules open={openRules} onClose={handleRulesClose} defDay={day} />}
      {openError && <Error open={openError} onClose={handleErrorClose} />}
    </div>
  );
}

const mapState = (state) => {
  return {
    authentication: state.authentication,
    winterStatus: state && state.promo && state.promo.winter && state.promo.winter.status ? state.promo.winter.status : null,
    winterLoading: state && state.promo && state.promo.winter && state.promo.winter.loading ? state.promo.winter.loading : null,
  };
};

const mapActions = {
  loadWinterStatus,
  loadedWinterStatus,
  doLogin
};


export default withRouter(connect(mapState, mapActions)(withTranslation()(MainView)));