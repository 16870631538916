import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import moment from "moment";

import { getFlagISO2 } from "../../utils/countries";
import TimerExtended from "./TimerExtended";
import Mask from "./assets/bgMask.svg";
import { ReactComponent as PlaceTicketGreen } from "./assets/placeTicketGreen.svg";
import { ReactComponent as PlaceTicketGrey } from "./assets/placeTicketGrey.svg";
import { getLottoState } from "../../store/selectors/lottoData";
import FavNumbersDialog from "../FavNumbers";
import TicketsBanner from "../LotteryEvent/TicketsBanner";
import {
  betsSlipAddLottoTicket
} from "../../store/actions/betsSlip";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    padding: "0",
    cursor: "pointer",
  },
  details: {
    marginBottom: "0px",
  },
  header: {
    background: "#fff",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    padding: "4px 0 4px 74px",
  },
  subheader: {
    position: "relative"
  },
  mb0: {
    marginBottom: "0px"
  },
  title: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#22262D",
    textTransform: "uppercase",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  subtitle: {
    fontSize: "11px",
    color: "#161A23"
  },
  circles: {
    display: "flex",
    marginLeft: "12px",
    paddingRight: "15px"
  },
  circle1: {
    width: "20px",
    height: "20px",
    lineHeight: "17px",
    border: "1px solid #726FC9",
    backgroundColor: "#fff",
    color: "#22262D",
    fontSize: "10px",
    fontWeight: "600",
    textAlign: "center",
    borderRadius: "50%",
    marginRight: "5px",
  },
  circle2: {
    width: "20px",
    height: "20px",
    lineHeight: "17px",
    border: "1px solid #726FC9",
    backgroundColor: "#726FC9",
    color: "#fff",
    fontSize: "10px",
    fontWeight: "600",
    textAlign: "center",
    borderRadius: "50%",
  },
  flag: {
    width: "51px",
    height: "51px",
    borderRadius: "50%",
    overflow: "hidden",
    //border: "5px solid #fefefe",
    boxShadow: "0px 0px 15px 1px rgba(0,0,0,0.10)",
    position: "relative",
    top: "-15px",
    left: "14px",
    "&>div": {
      width: "0",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
    },
    "& img": {
      flex: "1 0 auto",
      width: "auto",
      height: "51px",
      display: "block",
      margin: "auto",
    },

  },
  top: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px"
  },
  topTitle: {
    fontSize: "18px",
    fontWeight: "600",
    margin: "0 4px",
    textAlign: "center",
  },
  topSubtitle: {
    fontSize: "9px",
    margin: "0 4px",
    textAlign: "center",
  },
  bottom: {
    backgroundColor: "#C31822",
    borderBottomRightRadius: "20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    textAlign: "center",
    height: "27px",
    lineHeight: "25px"
  },
  mask: {
    maskImage: `url(${Mask})`,
    maskRepeat: "no-repeat",
    maskPosition: "0 0",
    maskSize: "100% 100%",
    height: "53px",
    width: "163px",
    background:
      "linear-gradient(90deg, #06D2BD 0px, #1F83E6 100%)",
    backgroundSize: "100%, 53px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px, 0px",
    borderBottomRightRadius: "7px",
    position: "relative",
    animation: "smallAnim .33s ease",
    "& .title": {
      color: "#FFFFFF",
      fontSize: "14px",
      fontWeight: "600",
      paddingTop: "18px",
      textAlign: "center"
    },
  },
  info: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between"
  },
  placeTicket: {
    borderTop: "1px solid #03A688",
    flex: "1 1 auto",
    height: "53px",
    marginLeft: "7px",
    marginRight: "-12px",
    paddingRight: "14px",
    fontSize: "10px",
    color: "#03A688",
    textAlign: "center",
    "&>div:first-child": {
      paddingTop: "8px"
    }
  },
  placeTicketGrey: {
    color: "#9E9E9E"
  },
  noBottomBrd: {
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
  },
  mt: {
    marginTop: "3px"
  }
});


const EventExtended = (props) => {
  const classes = useStyles();
  const { event, openedTickets, onClick, className, hasFavNumbers, updateTicket, t } = props;

  const [openFavDialog, setOpenFavDialog] = React.useState(false);

  const now = Date.now();
  const tickets = openedTickets.filter(t => t.event_name === event.event_name && (Math.floor(t.event_time / 1e+6) > (now - 5 * 60 * 1000)));
  const rootCls = `${classes.root} ${tickets.length ? classes.noBottomBrd : ''}`;
  const maskCls = `${classes.mask} ${tickets.length ? classes.noBottomBrd : ''}`;

  const doFastBetting = (ev) => {
    if (ev) ev.stopPropagation();
    setOpenFavDialog(true);
  };
  const handleFavDialogClose = (ev) => {
    setOpenFavDialog(false);
  };
  const handleFavNumbersSelect = (numbers) => {
    setOpenFavDialog(false);

    let maxSystems = event.m;
    if (numbers.length < event.m) {
      maxSystems = numbers.length;
    }

    updateTicket({
      event: { ...event },
      numbers: numbers,
      systems: [maxSystems],
      selectedEvents: [event.event_id],
      additionalEvents: 0,
      allSelectedEvents: [event.event_id],
      allSelectedEventsFull: [event.event_date],
      fastBetting: true,
    });
  };

  const handleClick = (ev) => {
    onClick(ev);
  }

  const hasOpenedTickets = tickets.length ? true : false;
  let bannerData = {};
  if (hasOpenedTickets) {
    const dates = [];
    tickets.forEach(t => {
      dates.push(t.event_time);
    });
    bannerData.event_date = moment(Math.min(...dates) / 1000000).format("YYYY-MM-DD HH:mm:ss");
    bannerData.event_name = event.event_name;
    bannerData.tickets_count = tickets.length;
  }

  const imgSrc = getFlagISO2(event.iso2);

  return (
    <div className={className ? className : ''}>
      <div className={rootCls} onClick={handleClick}>
        <div className={classes.details}>
          <div className={`${classes.header} d-flex flex-row align-items-center justify-content-between`}>
            <div className={classes.title}>{event.event_name}</div>
            <div className={classes.circles}>
              <div className={classes.circle1}>{event.r}</div>
              <div className={classes.circle2}>{event.n}</div>
            </div>
          </div>
          <div className={`${classes.subheader} d-flex flex-row align-items-center justify-content-between`}>
            <div className={classes.flag}>
              <div>
                <img src={imgSrc} alt={event.iso2} />
              </div>
            </div>
            <TimerExtended className={classes.top} date={event.event_date} home={true} />
          </div>
        </div>
        <div className={`${classes.details} ${classes.mb0}`}>
          <div className={classes.info}>
            {hasFavNumbers && <div className={`${classes.placeTicket} txt-place-ticket`} onClick={doFastBetting}>
              <div><PlaceTicketGreen /></div>
              <div>{t("FAST BETTING")}</div>
            </div>}
            {!hasFavNumbers && <div className={`${classes.placeTicket} ${classes.placeTicketGrey} txt-place-ticket`}>
              <div><PlaceTicketGrey /></div>
              <div>{t("WITHOUT FAVORITE NUMBERS")}</div>
            </div>}
            <div className={maskCls}>
              <div className={"title"}>{t("PLAY NOW")}</div>
            </div>
          </div>
        </div>
      </div>
      {hasOpenedTickets && <TicketsBanner className={classes.mt} data={bannerData} />}
      <FavNumbersDialog open={openFavDialog} eventName={event.event_name} onClose={handleFavDialogClose} onSelect={handleFavNumbersSelect} />
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const lst = getLottoState(state);

  let tmp = [];
  if (props.event && typeof props.event.event_name !== "undefined") {
    const favNumbers = lst.favNumbers.items;
    const eventName = props.event.event_name;

    if (favNumbers && typeof favNumbers[eventName] != "undefined") {
      tmp = favNumbers[eventName];
    }
  }

  return {
    openedTickets: lst.tickets.ticketsOpened,
    hasFavNumbers: tmp.length > 0,
  };
};

const actionCreators = {
  updateTicket: betsSlipAddLottoTicket,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(EventExtended));