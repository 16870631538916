import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import * as stateActions from "../../../store/actions";
import "./PermissionModal.scss";

class PermissionModal extends Component {
    constructor(props) {
        super(props);

        this.sitePath = window.config.front_url;
    }

    close() {
        this.props.closeModal();
    }

    requestPermission() {
        this.props.request();
        this.close();
    }

    render() {
        let modalClassName = [
            "PermissionModal", "h-100", "d-flex", "flex-column", "justify-content-center", "my-0"
        ];

        return (
            <Modal isOpen={this.props.geolocation.modal} className={modalClassName.join(" ")} backdrop={'static'} modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalHeader toggle={this.close.bind(this)} className="WithdrawModalHeader">{this.props.t("Activare locatie")}</ModalHeader>
                <ModalBody className="WithdrawModalBody">
                    <div className={"modalMessage"}>
                        <p>{this.props.t("Accepta cererea de acces locatie!")}</p>
                    </div>
                    <div className={"modalButtons"}>
                        <Button color={"success"} onClick={this.requestPermission.bind(this)}>{this.props.t("deschide cererea")}</Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        geolocation: state.geolocation,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => dispatch(stateActions.openLocationModal()),
        closeModal: () => dispatch(stateActions.closeLocationModal()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PermissionModal));