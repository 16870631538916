import React from 'react';
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from '@material-ui/core/Slide';

import "./final.scss";
import GiftIcon from "../../assets/gift@2x.png";
import GiftGreyIcon from "../../assets/gift-grey@2x.png";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { happyHourClearStatusCompleted } from "../../../../store/actions/happy_hour";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Final = (props) => {
  const { t, data, clearEvent } = props;

  const isPortrait = useMediaQuery("(orientation: portrait)");
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (data) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [data]);

  const onClearEvent = () => {
    setOpen(false);
    window.setTimeout(() => {
      clearEvent("bets-prematch");
    }, 300)
  }

  if (!data) return null;

  const hhWrapper = (
    <div className="ended-wrapper">
      <div className="ended-innerWrapper">
        <div className="ended-title">{data.event_name} {t("finalized")}</div>
        <div className="ended-prizes">
          <div className="ended-prize-miss">
            <div className="ended-title">{t("Missed prizes")}:</div>
            <div className="ended-gift"><img src={GiftGreyIcon} alt="" /></div>
            <div className="ended-amount">{data.total_missed} Lei</div>
          </div>
          <div className="ended-prize-won">
            <div className="ended-title">{t("Prizes won")}:</div>
            <div className="ended-gift"><img src={GiftIcon} alt="" /></div>
            <div className="ended-amount">{data.total_awarded} Lei</div>
          </div>
        </div>
        <div className="ended-fi">
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"xs"}
      TransitionComponent={Transition}
      keepMounted
      className={`bets-prematch-final-win`}
    >
      <DialogContent className={'dialog-content'}>
        <div className={`bets-prematch-ended ${isPortrait ? 'ended-portrait' : 'ended-landscape'}`} onClick={onClearEvent}>
          {hhWrapper}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const actions = {
  clearEvent: happyHourClearStatusCompleted
}

export default withTranslation()(connect(null, actions)(Final));