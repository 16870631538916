import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types"; // eslint-disable-line
import { createHowl } from "../../../common/howler";
import { getBetsState } from "../../store/selectors/betData";


let addSound = createHowl("click.mp3");
let ticketSound = createHowl("ticket.mp3");

const SoundPLayer = ({ bets, ticketCreateStatus, sounds_enabled }) => {
  const [oldCount, setOldCount] = React.useState(0);

  React.useEffect(() => {
    setOldCount(bets);
  }, [bets]);

  if (bets !== oldCount) {
    if (sounds_enabled && addSound) addSound.play();
  }
  if (ticketCreateStatus === "done") {
    if (sounds_enabled && ticketSound) ticketSound.play();
  }

  return null;
};

SoundPLayer.propTypes = {};

SoundPLayer.defaultProps = {};

const mapStateToProps = state => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    sounds_enabled: state.sounds && state.sounds.config ? state.sounds.config.enabled : false,
    ticketCreateStatus: ct.ticketCreateStatus,
    bets: ct.live.selected.length + ct.prematch.selected.length
  };
};

export default connect(mapStateToProps)(SoundPLayer);
