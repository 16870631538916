import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import { ReactComponent as BackIcon } from "./assets/arrow.svg";
import Favorite from "../../elements/Favorite";
import MatchLiveHeaderSimple from "./MatchLiveHeaderSimple";

import { digitainWidgetLiveUrl } from "../../api/widgets";

const useStyles = makeStyles({
  root: {
    position: "relative"
  },
  topper: {
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",
    fontFamily: "OpenSans",
    height: "56px"
    //padding: "0 23px"
  },
  back: {
    padding: "24px 23px",
    fill: "#fff"
  },
  backSvg: {
    height: "17px",
    width: "auto"
  },
  title: {
    fontSize: 13
  },
  inPageButton: {
    position: "relative",
    top: -5,
    padding: "10px",
    color: "#ddd",
    "&:hover": {
      color: "#fff"
    }
  },
  top: {
    background: "#0e5bb3",
    minHeight: "50px",
    "& iframe": {
      display: "block"
    }
  },
  dn: {
    display: "none"
  },
  error: {
    minHeight: "50px",
    textAlign: "center",
    color: "#fff",
    background: "#0e5bb3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  iframe: {
    overflow: "hidden"
  }
});

const MatchLiveHeader = props => {
  const classes = useStyles();
  const { match, goBack, mType, idMatch, inPage, className, t, i18n, noHeader, hasScout, hasLiveTV } = props; // eslint-disable-line

  const [hasError, setHasError] = React.useState(false);
  const [height, setHeight] = React.useState(!hasScout && hasLiveTV ? 320 : 100);

  const url = digitainWidgetLiveUrl(idMatch, i18n.language, hasScout, hasLiveTV);

  // listen for resize message from digitain iframe
  const handleMessage = (message) => {
    //console.log("message", message);
    if (message.origin === "https://widgets.feeddigi.com" && message.data) {
      if ('liveInfoHeight' === message.data.type) {
        setHeight(message.data.value);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("message", handleMessage);

    if (!hasScout && hasLiveTV) {
      const iframeContainer = document.querySelector(".iframe-container");
      if (iframeContainer) {
        // get the width of the container
        const width = iframeContainer.offsetWidth;
        setHeight(width * 0.562);
      }
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    }
  }, []); // eslint-disable-line

  if (!match) return null;

  return (
    <div className={`${classes.root} match-live-header ${className ? className : ''}`}>
      {!noHeader && <div
        className={`${classes.topper} bar-top match-modal-top d-flex flex-nowrap align-items-center justify-content-between`}
      >
        <div>
          {!inPage && <ButtonBase className={classes.back} onClick={goBack}>
            <BackIcon className={classes.backSvg} />
          </ButtonBase>}
          {inPage && <ButtonBase className={classes.inPageButton} onClick={goBack}>
            <HighlightOffOutlinedIcon />
          </ButtonBase>}
        </div>
        <div className={classes.title}>{t("Match details")}</div>
        <div>
          <Favorite mType={mType} idMatch={idMatch} type="header" />
        </div>
      </div>}
      <div className={`${classes.top} iframe-container`}>
        <iframe src={url} width="100%" height={height} title="Live Event" scrolling="no" className={classes.iframe} />
      </div>
      <MatchLiveHeaderSimple match={match} />
      {hasError && <div className={classes.error}>
        {t("No live event data")}
      </div>}
    </div >
  );
};

MatchLiveHeader.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  goBack: PropTypes.func,
  onAction: PropTypes.func,
  children: PropTypes.node,
  liveText: PropTypes.array
};

MatchLiveHeader.defaultProps = {
  title: "Title",
  id: "_id_",
  active: false,
  type: "fav",
  goBack: () => { },
  onAction: () => { },
  children: null,
  liveText: []
};

export default withTranslation()(MatchLiveHeader);
