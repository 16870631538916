import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import ConfirmButton from "../elements/ConfirmButton";
import VerificationInput from "../../Form/VerificationInput/VerificationInput";
import * as stateActions from "../../../../store/actions";
import './Step4.scss'
import Utils from "../../../../utils/Utils";
import {withTranslation} from "react-i18next";
import {CircularProgress} from "@material-ui/core";
import NewSMS from "../../Form/NewSMS/NewSMS";
import GTM from "../../../../utils/GTM";

class Step4 extends Component
{
    state = {
        verifyStarted: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.fields.phoneValidationCode !== prevProps.fields.phoneValidationCode) {
            if (this.props.fields.phoneValidationCode === '' && this.state.verifyStarted) {
                this.setState({
                    ...this.state,
                    verifyStarted: false,
                    error: true
                })
            }
        } else if (prevProps.validated !== this.props.validated && this.props.validated && this.state.verifyStarted) {
            this.setState({
                ...this.state,
                verifyStarted: false,
                error: false
            })
            this.props.goToStep(this.props.currentStep+1);
        }
        // if (prevProps.signedUp !== this.props.signedUp) {
        //     this.setState({
        //         ...this.state,
        //         verifyStarted: false
        //     })
        // }
    }

    // getTimer() {
    //     if (this.props.timer === false) return [];
    //     if (this.props.timer > 0) {
    //         if (!this.state.error) {
    //             Utils.delayedFunction("setTimer", this.setTimer.bind(this),1000);
    //             return (<p className={"timer"}>
    //                 {this.props.t("You can resend a SMS Code in") + " " + this.props.timer + " " + this.props.t("seconds") }
    //             </p>)
    //         }
    //     }
    //
    //     return (<p className={"timer"} onClick={this.sendSmsHandler.bind(this)}>
    //         {this.state.error && <div className={'error'}>{this.props.t('Something went wrong')}</div>}
    //         <div className={'underlined'}>
    //             {this.props.t("Click here to resend SMS Code")}
    //         </div>
    //     </p>);
    //
    // }
    // setTimer() {
    //     let time = this.props.timer - 1;
    //     this.props.setTimer(time);
    // }
    // sendSmsHandler(){
    //     this.setState({
    //         ...this.state,
    //         error: false
    //     })
    //     this.props.sendSMS();
    // }
    verify() {
        // check number to the core
        this.props.validatePhone();
        this.setState({
            ...this.state,
            verifyStarted:true,
            error: false
        })
    }
    getErrors() {
        return this.state.error ? this.props.t('Something went wrong') : "";
    }
    render() {
        return (
            <div className={'fields step-4'}>
                <div className="errors">
                    {this.getErrors()}
                </div>
                <div className="title">{this.props.t("Securizare cont")}</div>
                <VerificationInput type={'simple'} showReward={false}/>
                {this.state.verifyStarted &&
                    <div className={'loading-screen'} >
                        <CircularProgress/>
                    </div>}
                <NewSMS/>
                {!this.state.verifyStarted && <ConfirmButton {...this.props} onClickFunction={this.verify.bind(this)} preventNext={true}/>}
                {/*{this.getTimer()}*/}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendSMS: () => dispatch(stateActions.sendSMS()),
        // setTimer: (time) => dispatch(stateActions.setTimer(time)),
        validatePhone: () => dispatch(stateActions.validatePhone()),
        goToStep: (step) => dispatch(stateActions.setCurrentStep(step)),
    };
};

const mapStateToProps = state => {
    let error = !(state.register.validated.phoneValidationCode);

    if(typeof error === 'undefined') {
        error = true;
    }

    return {
        error: error,
        timer: state.register.SMSTimer,
        username: state.register.fields.email,
        password: state.register.fields.password,
        validated: state.register.validated.phoneValidationCode,
        currentStep: state.register.currentStep,
        phone: state.register.fields.phone,
        fields: state.register.fields,
        signedUpErrors: state.register.signedUpErrors
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)((Step4)));
