import CreateNewAccount from "./CreateNewAccount";
import PersonalDetails from "./PersonalDetails";
import StepsFooter from "./StepsFooter";
import React, {Component, Fragment} from "react";
import connect from "react-redux/es/connect/connect";
import Verification from "./Verification";
import * as stateActions from "../../../store/actions";
import {VelocityComponent} from "velocity-react";
import {withTranslation} from "react-i18next";

class Steps extends Component {

    componentDidUpdate() {
        this.goToStep();
    }
    goToStep() {
        if (this.props.step !== false) {
            this.props.preventGoToStep();
            this.props.goToStep(this.props.step);
        }
    }

    render() {

        const AllComponents = {
          CreateNewAccount: CreateNewAccount,
          PersonalDetails: PersonalDetails,
            Verification: Verification,
        };
        const Type = AllComponents[this.props.form];
        if (!Type) {
            return ;
        }
        if (window.config && window.config.register_disabled === '1') {
            if (this.props.form !== 'CreateNewAccount') {
                return <Fragment></Fragment>;
            }
            return (
                <VelocityComponent animation={{opacity: 1}} duration={1000} runOnMount={true}>
                    <div className="registerWizard">
                        <div className={"container"} >
                            {this.props.t("Register is temporarily unavailable")}
                        </div>
                    </div>
                </VelocityComponent>
            );
        }
        return (
            <div>
                <Type />
                <StepsFooter nextStep={this.props.nextStep} currentStep={this.props.currentStep}/>
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        preventGoToStep: () => dispatch(stateActions.goToStep(false))
    };
};
const mapStateToProps = state => {

    return {
        step: state.registerWizard.goToStep,
    };
};

export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(Steps));
