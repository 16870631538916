import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./jackpot_collection.scss";

import { getText, styleToObj, openItem, checkItemAvailability, formatNumber, getIndexById } from "../../utils/functions";
import Counter from "../common/counter";
import { JackpotCounter } from "../../../common/jackpot";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const JackpotCollection = (props) => {
  const { data, i18n, source, jackpotList, t } = props;

  const isDesktop = useMediaQuery("(min-width:1528px)");

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  if (items.length === 0) return null;

  const filteredItems = items.filter(item => (checkItemAvailability(item)));
  if (filteredItems.length === 0) return null;

  if (!jackpotList || Object.keys(jackpotList).length === 0) return null;

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  const counterColorsSimple = {
    disabled_color: "rgba(0, 0, 0, .3)"
  };

  return (
    <div className={`component-jackpot-collection component`} style={styleToObj(data.style)}>
      <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          {filteredItems !== 0 && <span>({filteredItems})</span>}
          <LobbyArrow />
        </div>}
      </div>

      <div className="cgl-list" style={styleToObj(data.data_source_style)}>
        <div className={`cgl-grid ${data.thumbnail_size === "large" ? "large" : ""}`}>
          {filteredItems.slice(0, 6).map((item, i) => {
            let jackpot_id = "";
            if (item.data && item.data.jackpot_id && typeof jackpotList[item.data.jackpot_id] !== "undefined") {
              jackpot_id = item.data.jackpot_id;
            }
            if (!jackpot_id) return null;

            const jackpot = jackpotList[jackpot_id];

            const clonedLevels = JSON.parse(JSON.stringify(jackpot.levels));
            clonedLevels.sort((a, b) => {
              if (a.current < b.current) {
                return 1;
              }
              if (a.current > b.current) {
                return -1;
              }
              return 0;
            });
            clonedLevels.forEach(l => {
              if (
                typeof l.largestWin === "undefined" ||
                l.largestWin === null ||
                l.largestWin === 0
              ) {
                l.largestWinUser = "";
                l.largestWinDate = "N/A";
              }

              if (typeof l.lastWin === "undefined" || l.lastWin === null || l.lastWin === 0) {
                l.lastWinUser = "";
                l.lastWinDate = "N/A";
              }
            });

            const realIndex = getIndexById(items, item.id);
            const itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;

            return <div
              className={`cgl-item ${item.isEmpty ? "empty" : ""}`}
              key={i}
              data-type={"navigate"}
              data-item={JSON.stringify({ url: item.data && item.data.url ? item.data.url : "" })}
              onClick={openItem}
            >
              <div className="outer">
                <div className="inner">
                  {!!item.data.thumbnail && <img src={item.data.thumbnail} alt="" />}
                  <div className="info-wrapper" style={itemStyle}>
                    {clonedLevels.slice(0, 3).map((l, i) => {
                      return <div className="txt" key={i}>
                        <div className="number">
                          <JackpotCounter
                            theme="small-counter"
                            digits={5}
                            value={l.current / 100}
                            storageKey={`EGT_${jackpot_id}_${i}`}
                            colors={counterColorsSimple}
                          />
                        </div>
                        <div className="txt">{t("Last win")}: {l.lastWin ? formatNumber(l.lastWin / 100) : 0} Lei</div>
                      </div>;
                    })}
                  </div>
                </div>
              </div>
            </div>;
          })}
          {isDesktop && filteredItems.length < 6 && [...Array(6 - filteredItems.length).keys()].map(i => {
            return <div className={`cgl-item soon`} key={i + items.length}>
              <div className="outer">
                <div className="inner">
                  <div>
                    {props.t("COMING SOON")}
                  </div>
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
    jackpotList: state.jackpotList.list,
  }
};

export default withTranslation()(withTranslation()(connect(mapStateToProps)(JackpotCollection)));