import React from 'react';
import { connect } from "react-redux";

import "./use_free_spins.scss";

import { getText, getStyle, styleToObj, openItem, checkItemAvailability } from "../../utils/functions";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const UseFreeSpins = (props) => {
  const { data, lang, source, freeSpins } = props;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  const title = getText(data, `title.text.${lang}`, "");
  const navigate = getText(data, `navigate.text.${lang}`, "");

  const indexAssoc = {};
  items.forEach((b, i) => {
    if (b && b.data && b.data.bonus_available_id) {
      indexAssoc[b.data.bonus_available_id.toString()] = i;
    }
  });

  const filteredItems = items.filter(item => {
    const isAvailable = checkItemAvailability(item);
    return isAvailable && item.data && item.data.tenant_id && item.data.tenant_id.toString() === window.config.clientId.toString();
  });
  const freeSpinsConfigAssoc = {};
  filteredItems.forEach(b => {
    if (b && b.data && b.data.bonus_available_id) {
      freeSpinsConfigAssoc[b.data.bonus_available_id.toString()] = b;
    }
  });

  if (!freeSpins || freeSpins.length === 0) return null;

  let count = 0;
  let hasValidFreeSpins = false;
  let freeSpinsContent = freeSpins.map((freeSpin, i) => {
    let freeSpinId = freeSpin.bonus_available_id;
    let item = null;
    if (freeSpinId && typeof freeSpinsConfigAssoc[freeSpinId.toString()] !== "undefined") {
      item = freeSpinsConfigAssoc[freeSpinId.toString()]
    }

    if (!item) return null;

    let gameId = freeSpin && freeSpin.games && freeSpin.games.length ? freeSpin.games[0] : 0;
    if (!gameId) return null;

    const realIndex = indexAssoc[freeSpinId];

    let itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;
    itemStyle = Object.assign({ minWidth: data.card_width }, itemStyle);
    const outerStyle = { paddingTop: `${data.card_height}%` };

    let dataType = "";
    let dataItem = null;

    props.games && props.games.forEach(game => {
      if (game && game.game_id && game.game_id.toString() === gameId.toString()) {
        dataType = "slot_game";
        dataItem = JSON.stringify({ id: game.id, providerId: game.provider, name: game.name, gameId: game.game_id });
      }
    });

    if (!dataType) return null;

    hasValidFreeSpins = true;
    count += 1;

    return <div className={`cgl-item`}
      key={i}
      style={itemStyle}
      data-type={dataType}
      data-item={dataItem}
      onClick={openItem}
    >
      <div className="outer" style={outerStyle}>
        <div className="inner">
          {item.data.thumbnail && <img src={item.data.thumbnail} alt="" />}
          <div className="cgl-text-wrapper">
            <div className="cgl-footer-wrapper">
              <div>
                <div className="cgl-btn" style={styleToObj(getStyle(data, `data_source_items[${realIndex}].button.style`, empty))}>
                  {getText(item, `data.button.text.${lang}`, "")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  });

  if (!hasValidFreeSpins) return null;

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`component-simple_promotion component`} style={styleToObj(data.style)}>
      <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {title}
        </div>
        {hasNavigate && <div
          className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {navigate}
          {count !== 0 && <span>({count})</span>}
          <LobbyArrow />
        </div>}
      </div>

      <div className="cgl-list with-scroll-style" style={styleToObj(data.data_source_style)}>
        <div className={`cgl-grid ${items.length === 1 ? "centered" : ""}`}>
          {freeSpinsContent}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    games: state.lobby.games.items,
    source: source,
    freeSpins: state.freeSpins && state.freeSpins.freeSpins ? state.freeSpins.freeSpins : [],
  }
};

export default connect(mapStateToProps)(UseFreeSpins);