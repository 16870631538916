import "typeface-open-sans";
import "../index.scss";
import "../styles/animate.min.css";
//import "../styles/flexUtils.css";

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import { connect } from "react-redux";

import Home from "../views/HomeWeb";
import PreMatchLayout from "../layout/PreMatchLayoutWeb";
import LiveMatchLayout from "../layout/LiveMatchLayout";
import Error404 from "../views/Error404";
import Modals from "../layout/Modals";
import SoundPLayer from "../components/SoundPlayer";

import { prematchInitialize } from "../store/actions/prematch";
import { betsSlipClear } from "../store/actions/betsSlip";
import { liveInitialize } from "../store/actions/live";
import { appInitialize, appBasePath } from "../store/actions/app";
import { requestWallet } from "../../winner-fun/store/actions/wallet";

import { HappyHoursWin, HappyHoursFinal } from "../../common/hh-exports";

const toggledStyle = {
  right: 10
};

function DefaultLayout(props) {
  const { prematchInitialize, liveInitialize, basePath, appBasePath, betsSlipClear, requestWallet } = props;

  useEffect(() => {
    document.body.classList.add("bets");
    document.documentElement.style.overflow = "";

    prematchInitialize();

    return () => {
      document.body.classList.remove("bets");
      //appBasePath("");
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    appBasePath(basePath);
    if (basePath === "/pariuri") {
      document.body.classList.remove("winner_fun");
    } else if (basePath === "/winner-fun") {
      document.body.classList.add("winner_fun");
      requestWallet();
    }
    liveInitialize();
  }, [basePath, appBasePath, betsSlipClear, liveInitialize, requestWallet]);

  let contentLayout = (
    <React.Fragment>
      <div className={`page-wrapper bets-entry`}>
        <Switch>
          <Route exact path={basePath} component={Home}></Route>
          <Route path={`${basePath}/lobby/:path+`} component={Home} />
          <Route path={`${basePath}/pre-match`} component={PreMatchLayout} />
          <Route path={`${basePath}/live-match`} component={LiveMatchLayout} />
          <Route component={Error404} />
        </Switch>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <HappyHoursWin event_type={`${basePath === "/pariuri" ? "" : "winner-fun-"}bets-prematch`} />
      <HappyHoursWin event_type={`${basePath === "/pariuri" ? "" : "winner-fun-"}bets-live`} />
      {contentLayout}
      <Modals />
      <ScrollUpButton ToggledStyle={toggledStyle} />
      <SoundPLayer />
      <HappyHoursFinal event_type={`${basePath === "/pariuri" ? "" : "winner-fun-"}bets-live`} />
      <HappyHoursFinal event_type={`${basePath === "/pariuri" ? "" : "winner-fun-"}bets-prematch`} />
    </React.Fragment >
  );
}

const mapStateToProps = state => ({
  auth: state.auth
});

const actionCreators = {
  appInitialize,
  prematchInitialize,
  liveInitialize,
  betsSlipClear,
  appBasePath,
  requestWallet
};

export default connect(mapStateToProps, actionCreators)(DefaultLayout);
