class SocialSecurityNumberValidator
{
    static validate(string = undefined) {

        if (typeof string === "undefined" || string.length < 4) {
            return {
                type: 'error',
                text: 10
            }
        }


        return {
            type: "success"
        }
    }
}

export default SocialSecurityNumberValidator;