import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {MetaTags} from 'react-meta-tags';

class Head extends Component {

    state = {
      pageId: false
    };

    componentDidMount() {
        this.newPage(this.props.history.location);
        this.props.history.listen((location) => {
            this.newPage(location);
        })
    }

    newPage(location) {

        let newId = location.pathname !== '/' ? location.pathname.substr(1).replace(/\//g, '-') : 'home';
        

        this.setState({
            ...this.state,
            pageId: newId
        });
    }

    getMetaTags() {
        if (!this.state.pageId) {
            return null;
        }
        window.config['meta-canonical_' + this.state.pageId] = window.config['meta-thumbnail_' + this.state.pageId] ?? window.location.origin+window.location.pathname;

        if (window.config) {
            return (
                    <MetaTags>
                        <title>{window.config['meta-title_' + this.state.pageId] && window.config['meta-title_' + this.state.pageId] + ' - ' }  {window.config.brand_name_full}</title>
                        {window.config['meta-title_' + this.state.pageId] && <meta name="title" content={window.config['meta-title_' + this.state.pageId]} />}
                        {window.config['meta-description_' + this.state.pageId] && <meta name="description" content={window.config['meta-description_' + this.state.pageId]} />}
                        {window.config['meta-keywords_' + this.state.pageId] && <meta property="keywords" content={window.config['meta-keywords_' + this.state.pageId]} /> }
                        {window.config['meta-thumbnail_' + this.state.pageId] && <meta property="thumbnail" content={window.config['meta-thumbnail_' + this.state.pageId]} /> }
                        {window.config['meta-canonical_' + this.state.pageId] && <link rel={'canonical'} href={window.config['meta-canonical_' + this.state.pageId]}/>}
                        <meta property="og:type" content="website"/>
                        <meta property="og:url" content={window.location.href} />
                        {window.config['meta-og-description_' + this.state.pageId] && <meta name="og:description" content={window.config['meta-description_' + this.state.pageId]} />}
                        {window.config['meta-og-title_' + this.state.pageId] && <meta property="og:title" content={window.config['meta-title_' + this.state.pageId]} /> }
                        {window.config['meta-og-image_' + this.state.pageId] && <meta property="og:image" content={window.config['meta-og-image_' + this.state.pageId]} /> }

                        <meta property="twitter:url" content={window.location.href}/>
                        {window.config['meta-twitter-card_' + this.state.pageId] && <meta property="twitter:card" content={window.config['meta-twitter-card_' + this.state.pageId]}/>}
                        {window.config['meta-twitter-title_' + this.state.pageId] && <meta property="twitter:title" content={window.config['meta-twitter-title_' + this.state.pageId]}/>}
                        {window.config['meta-twitter-description_' + this.state.pageId] && <meta property="twitter:description" content={window.config['meta-twitter-description_' + this.state.pageId]}/>}
                        {window.config['meta-twitter-image_' + this.state.pageId] && <meta property="twitter:image" content={window.config['meta-twitter-image_' + this.state.pageId]}/>}
                    </MetaTags>
            )
        }
    }

    render () {
        return (
            <Fragment>
                {this.getMetaTags()}
            </Fragment>
        );
    }
};

export default withRouter(Head);
