import React, { Component } from "react";
import { connect } from "react-redux";
import phaserEntryPoint from "./../../spine/phaserEntryPoint";
import * as stateActions from "./../../store/actions";
import "./MainBanner.scss";
import { VelocityComponent } from "velocity-react";
import BannerMessage from "./sections/BannerMessage";
import Phaser from "phaser";
import Icons from "./../../assets/Icons/Icons";
import { Button } from "reactstrap";
import { withTranslation } from "react-i18next";

//import NewBanner from './../Jackpots/Banner';
import PixiCarousel from "./../Jackpots/PixiCarousel";

import SlotBanner from "./../Jackpots/Banner/SlotBanner3";
import NewGamesBanner from "../Banners/NewGamesBanner";
//import KYCBanner from "./../Jackpots/Banner/KYCBanner";

import WebBannerWelcome from "./../Jackpots/Banner/assets/banner-welcomebonus-web.jpg";
import MobileBannerWelcome from "./../Jackpots/Banner/assets/banner-welcomebonus-mobile.jpg";
import WebBannerNewGames from "../../assets/Banners/banner-newgames-web.jpg";
import MobileBannerNewGames from "../../assets/Banners/banner-newgames-mobile.jpg"
//import WebBannerKYC from "./../Jackpots/Banner/assets/banner-kyc-web.jpg";
//import MobileBannerKYC from "./../Jackpots/Banner/assets/banner-kyc-mobile.jpg";

import Slider from "../Slider";

class MainBanner extends Component {
  constructor(props) {
    super(props);

    this.baseUrl = window.config.front_url;
    this.gameRootElem = null;
    this.activateTimer = 0;
    this.imgPath = this.props.mainBanner.default.imgPath;

    this.emitter = new Phaser.Events.EventEmitter();

    this.icons = {
      csLogo: Icons.get("customerSupportLogo", "customerSupportLogo"),
      liveChat: Icons.get("liveChatButton", "liveChatButton")
    };

    this.spinePageCodes = ["cazino", "table-games"];
    this.spineSubpageCodes = ["jocuri", "games"];
  }

  componentDidMount() {
    this.props.hideBanner();
    this.activateTimer = setTimeout(this.activateBanner.bind(this), 2000);

    this.emitter.on("car", action => {
      if ("done" !== action) {
        return;
      }

      // console.log("[PHASER] received the car event");
      // console.log(action);
      this.props.showMessage();
      // display animation with crossfade (alpha from 0 to 1)
    });
  }

  componentDidUpdate() {
    if (document.getElementById("MainBanner")) {
      if (document.getElementById("MainBanner").clientHeight !== this.props.mainBanner.height) {
        this.props.resizeMainBanner();
      }
    }
    this.emitter.emit("screen", "reload");

    if (!this.props.application.page) {
      return;
    }

    let page = this.props.application.page;

    if (page.code && "customer-support" === page.code) {
      if (true === this.props.mainBanner.hide) {
        return this.props.showBanner("support");
      }

      if ("spine" === this.props.mainBanner.bannerType) {
        return this.props.changeMainBannerType("support");
      }

      return;
    }

    let subpage = this.props.application.subpage;
    if (page.code && this.spinePageCodes.includes(page.code)) {
      if (!subpage || !subpage.hasOwnProperty("code")) {
        if (true === this.props.mainBanner.hide) {
          return this.props.showBanner("spine");
        }

        if ("spine" !== this.props.mainBanner.bannerType) {
          return this.props.changeMainBannerType("spine");
        }

        return;
      }

      if (this.spineSubpageCodes.includes(subpage.code)) {
        if (true === this.props.mainBanner.hide) {
          if ("spine" !== this.props.mainBanner.bannerType) {
            this.props.changeMainBannerType("spine");
          }

          return this.props.showBanner("spine");
        }

        if ("spine" !== this.props.mainBanner.bannerType) {
          return this.props.changeMainBannerType("spine");
        }

        return;
      }
    }

    if (false === this.props.mainBanner.hide) {
      return this.props.hideBanner();
    }
  }

  activateBanner() {
    /*
        clearTimeout(this.activateTimer);
        if (!this.gameRootElem) {
            this.activateTimer = setTimeout(this.activateBanner.bind(this), 1000);
            return;
        }

        if (!(this.gameRootElem.clientWidth > 10 && this.gameRootElem.clientHeight > 10)) {
            this.activateTimer = setTimeout(this.activateBanner.bind(this), 1000);
            return;
        }

        phaserEntryPoint(this.gameRootElem, this.emitter);
        */

    return this.props.hideBanner();
  }

  voidHandler(event) {
    event.preventDefault();

    return false;
  }

  render() {
    let style = {
      opacity: 0,
      marginTop: -this.props.mainBanner.height
    };

    if (!this.props.mainBanner.hide) {
      style = this.props.application.splashClosed
        ? { opacity: 1, top: 0, marginTop: 0 }
        : { opacity: 0, top: -this.props.mainBanner.height, marginTop: 0 };
    }

    let bannerStatus = ["show", "hide"];
    if ("support" === this.props.mainBanner.bannerType) {
      bannerStatus = ["hide", "show"];
    }

    return (
      <VelocityComponent animation={style} duration={500} runOnMount={false}>
        <div className={"MainBanner"} id="MainBanner">
          <div className={`Banner ${bannerStatus[0]}`} id="SpineBannerSupport">
            {/*
                        <div className="bannerCanvasSupport" ref={ element => this.gameRootElem = element } id={"bannerCanvasSupport"} />
                        <BannerMessage />
                        */}
            {/*<NewBanner />*/}
            {/*
            <PixiCarousel>
              <NewGamesBanner
                webImage={WebBannerNewGames}
                mobileImage={MobileBannerNewGames}
                blendColor={0x613029}
                eventName="newGamesBanner"
              />
              <SlotBanner
                webImage={WebBannerWelcome}
                mobileImage={MobileBannerWelcome}
                blendColor={0x613029}
                eventName="slotBanner"
              />
              <KYCBanner
                webImage={WebBannerKYC}
                mobileImage={MobileBannerKYC}
                blendColor={0x8c066d}
                eventName="kycBanner"
              />
            </PixiCarousel>
            */}
            <Slider eventPrefix="home" config={this.props.superBanner} fullWidth={true} />
          </div>
          <div className={`Banner ${bannerStatus[1]}`} id="CustomerBannerSupport">
            <div className={"htmlBanner"}>
              <div className={"csLogo"}>{this.icons.csLogo}</div>
              <div className={"csButtons"}>
                <div className={"btns"}>
                  <div className={"btSupport"}>
                    {/*<Button color={"warning"} className={"liveChat"}>*/}
                    {/*<span className={"icon"}>{ this.icons.liveChat }</span>*/}
                    {/*<span className={"text"}>{ this.props.t("live chat") }</span>*/}
                    {/*</Button>*/}
                    <a
                      href={"/cs"}
                      data-reamaze-lightbox="default"
                      onClick={this.voidHandler.bind(this)}
                      className={"liveChat btn-warning"}
                    >
                      <span className={"icon"}>{this.icons.liveChat}</span>
                      <span className={"text"}>{this.props.t("live chat")}</span>
                    </a>
                    <p>{this.props.t("Timp mediu de asteptare: 30 sec")}</p>
                  </div>
                  <div className={"btSupport"}>
                    <Button color={"secondary"}>{this.props.t("E-Mail")}</Button>
                    <p>{this.props.t("Serviciu temporar indisponibil")}</p>
                  </div>
                  <div className={"btSupport"}>
                    <Button color={"warning"}>{this.props.t("Apel Telefonic Invers")}</Button>
                    <p>{this.props.t("Asteptare medie: 1-3 minute")}</p>
                    <p>{this.props.t("Nu te costa nimic.")}</p>
                    <p>{this.props.t("Te sunam noi, nu astepti la telefon.")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VelocityComponent>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSpineAnimation: () => dispatch(stateActions.spineAnimation(true)),
    hideSpineAnimation: () => dispatch(stateActions.spineAnimation(false)),
    showBanner: (bannerType = null) => dispatch(stateActions.showMainBanner(bannerType)),
    hideBanner: () => dispatch(stateActions.hideMainBanner()),
    showMessage: () => dispatch(stateActions.showBannerMessage()),
    resizeMainBanner: () => dispatch(stateActions.resizeMainBanner()),
    changeMainBannerType: newType => dispatch(stateActions.changeMainBannerType(newType))
  };
};

const emptyArray = [];

const mapStateToProps = state => {
  return {
    mainBanner: state.mainBanner,
    application: state.application,
    superBanner: state.config.superBannerCasino || emptyArray
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MainBanner));
