import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import BarChartIcon from "@material-ui/icons/BarChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import StarIcon from "@material-ui/icons/Star";

//import { getBaseRouterPath } from "../../utils/config";
import {
  makeGetBets,
  getSelectedBets,
  getBetsState,
  makeGetPrematchMatch,
  makeGetMatchBets,
  makeMatchDetailsOpened
} from "../../../bets/store/selectors/betData";
import { formatOddValue, formatOddName } from "../../utils/formatters";
import { betsSlipToggle } from "../../../bets/store/actions/betsSlip";

import { ReactComponent as Avantaj } from "../../assets/icon-avantaj.svg";
import { ReactComponent as Winner } from "../../assets/icon-winner-wo.svg";
import { ReactComponent as Excel } from "../../assets/icon-exel-plus.svg";
import { ReactComponent as WinnerSpecial } from "../../assets/icon-winner-special.svg";
import evBus from "../../utils/evbus";
import { favoriteTogglePrematchMatch } from "../../../bets/store/actions/favorite";
import { ReactComponent as InfoOff } from "../../assets/info-off.svg";
import { sortArrayByKey2 } from "../../../bets/utils";

const MatchRowPreMatch = props => {
  const {
    categoryIsoName,
    match,
    selected,
    toggleBet,
    favorite,
    favoriteTogglePrematchMatch,
    matchGroup,
    markets,
    selectedBets,
    bets,
    matchDetailOpened,
    t
  } = props;

  const is768px = useMediaQuery("(max-width: 767px)");

  const [showBets, setShowBets] = React.useState([]);
  const [selectedBet, setSelectedBet] = React.useState({});

  React.useEffect(() => {
    if (!match) return;

    const res = [];
    let sBet = {};

    ["0", "1", "2"].forEach(index => {
      const selectedBet = selectedBets[matchGroup + "_" + index]
        ? selectedBets[matchGroup + "_" + index]
        : null;

      let s = null;

      if (selectedBet) {
        const bs = markets.find(b => b.idBet === selectedBet.midBet);
        if (bs) {
          s = bs;
        }
      }

      res.push(s);

      if (index === "0" && selectedBet) {
        sBet = selectedBet;
      }
    });

    setSelectedBet(sBet);
    setShowBets(res);
  }, [markets, match, matchGroup, selectedBets]);

  if (match === null) return null;

  //const basePath = getBaseRouterPath();
  const handleNavigation = props => {
    //console.log(`${basePath}/prematch/match/${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`)
    evBus.emit("SHOW-MATCH-DETAILS", {
      idSport: match.idSport,
      idCategory: match.idCategory,
      idTournament: match.idTournament,
      idMatch: match.idMatch
    });
    return;
  };

  //console.log("selectedBet", selectedBet, "showBets", showBets, "match", match);

  const handleBetClick = (e) => {
    e.stopPropagation();

    const target = e.currentTarget;

    if (target) {
      const mboActive = target.dataset.mboactive;

      if (mboActive === "1" || mboActive === "true") {
        const idBet = target.dataset.idbet;
        const idMb = target.dataset.idmb;
        const idBo = target.dataset.idbo;
        const idMbo = target.dataset.idmbo;

        toggleBet(match.mType, match.idSport, match.idMatch, idBet, idMb, idBo, idMbo, {
          ...match
        });
      }
    }
  };

  const handleToggleFavoriteMatch = idMatch => e => {
    if (e && typeof e.stopPropagation) e.stopPropagation();
    favoriteTogglePrematchMatch(idMatch);
  };

  const isWinnerPlus = match.winnerPlus;
  let wpIcon = <Winner className="winPlus" />;
  if (typeof window.config.nsoftTheme !== "undefined" && window.config.nsoftTheme) {
    wpIcon = <Excel className="winPlus" />;
  }
  const isWinnerAdv = match.winnerAdv;
  const isWinnerSpecial = match.winnerSpecial;
  const brId = match.brId ? match.brId : "";
  const statsURL = `https://s5.sir.sportradar.com/blingbet/ro/match/${brId}`;

  const handleStatsNavigation = e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
  };

  const description = match.description ? match.description : null;

  const getFlag = (category) => {
    if (category.categoryDLId) {
      return `digflag2 f${category.categoryDLId}`;
    }
    return `flag flag-${category.categoryIsoName ? category.categoryIsoName.toLowerCase() : "undef"}`;
  };

  return (
    <React.Fragment>
      <div className="match-row" onClick={handleNavigation}>
        <div className={`info-wrap ${matchDetailOpened ? "w100" : ""}`}>
          <div className="match-time">
            {is768px
              ? moment(match.matchDateTime).format("DD:MM HH:mm")
              : moment(match.matchDateTime).format("HH:mm")}
          </div>
          {categoryIsoName && (
            <div className="match-category-iso">
              <span
                className={`flag flag-${categoryIsoName ? categoryIsoName.toLowerCase() : "undef"}`}
              ></span>
              <span>{categoryIsoName}</span>
            </div>
          )}
          <div
            className={`match-display-name ${categoryIsoName ? "match-display-name-timeline" : ""}`}
          >
            <span className="team">{match.team1Name}</span>
            <span className="team-separator">&nbsp;-&nbsp;</span>
            <span className="team">{match.team2Name}</span>
            {(isWinnerPlus || isWinnerAdv || isWinnerSpecial || (match && match.displayId)) && (
              <div className="match-special">
                {isWinnerSpecial && <WinnerSpecial className="winAdv" />}
                {isWinnerAdv && <Avantaj className="winAdv" />}
                {isWinnerPlus && wpIcon}
                {(!window.config.nsoftHidePrematchID || window.config.nsoftHidePrematchID === "0") && match && match.displayId && <span className="display-id">{match.displayId}</span>}
              </div>
            )}
          </div>
          {brId !== "" && (
            <a
              href={statsURL}
              target="_blank"
              rel="noopener noreferrer"
              className="match-stats"
              onClick={handleStatsNavigation}
              title={t("Statistics")}
            >
              <BarChartIcon />
            </a>
          )}
        </div>
        {!matchDetailOpened && (
          <React.Fragment>
            <div className="bets-wrap bets-3">
              {showBets &&
                showBets.map((b, i) => {
                  if (is768px && i > 0) return null;

                  if (!b) {
                    return (
                      <React.Fragment key={"fnb_" + i}>
                        <div className={`odd-container odds-1`} key={"nb_" + i}>
                          <div className="odd" key={`placeholder_0`}>
                            -
                          </div>
                        </div>
                        {is768px && (
                          <div className="bet-name">
                            {selectedBet && selectedBet.name ? selectedBet.name : "n/a"}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }

                  // outcomes for this bet
                  const os = [...b.mbOutcomes];
                  sortArrayByKey2(os, "mboPosition", "mbSpecialValue");

                  // find match bet
                  const sb = b;

                  // bet found - fill odds
                  return (
                    <React.Fragment key={"fb_" + i}>
                      <div
                        key={"b_" + i}
                        className={`odd-container odds-${os.length > 3 ? 3 : os.length}`}
                      >
                        {os.map((o, j) => {
                          if (j > 2) return null;
                          let mboActive = o.mboActive;

                          const outcomeName = formatOddName(selectedBet.midBet, o, match, bets)
                          const value = formatOddValue(o.mboOddValue);
                          let active = false;
                          if (selected.find(e => e.idMbo === o.idMbo)) {
                            active = true;
                          }

                          return (
                            <div
                              key={o.idMbo}
                              className={clsx("odd", active && "active")}
                              data-idbet={sb.idBet}
                              data-idmb={sb.idMb}
                              data-idbo={o.idBo}
                              data-idmbo={o.idMbo}
                              data-mboactive={mboActive}
                              onClick={handleBetClick}
                            >
                              {is768px && (
                                <React.Fragment>
                                  <span className="odd-outcome-name">{outcomeName}</span>
                                  <br />
                                </React.Fragment>
                              )}
                              {value}
                            </div>
                          );
                        })}
                      </div>
                      {is768px && (
                        <div className="bet-name">
                          {selectedBet && selectedBet.name ? selectedBet.name : "n/a"}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
            <div className="stats-wrap">
              <span className="match-total-bets">
                +{match.matchBets.length}{" "}
                <StarIcon
                  onClick={handleToggleFavoriteMatch(match.idMatch)}
                  className={`favorite-icon ${favorite.favorite_prematch_matches.indexOf(match.idMatch) > -1 ? "active" : ""
                    }`}
                />
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
      {!!description && (
        <div className="match-description">
          <div className="icon">
            <InfoOff />
          </div>
          <div className="desc">{description}</div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = () => {
  const getBets = makeGetBets();
  const getPrematchMatch = makeGetPrematchMatch();
  const getMatchBets = makeGetMatchBets();
  const getMatchDetailsOpened = makeMatchDetailsOpened();

  return (state, props) => {
    const bst = getBetsState(state);

    let match = getPrematchMatch(state, props);
    let markets = null;
    let bets = null;
    let categoryIsoName = null;

    if (match) {
      markets = getMatchBets(state, {
        mType: match.mType,
        idMatch: match.idMatch,
        idSport: match.idSport,
        idCategory: match.idCategory,
        idTournament: match.idTournament
      });
      bets = getBets(state, {
        mType: match.mType,
        idMatch: match.idMatch,
        idSport: match.idSport,
        idCategory: match.idCategory,
        idTournament: match.idTournament
      });

      if (
        props.extended &&
        bst.prematch &&
        bst.prematch.categories &&
        bst.prematch.categories[match.idCategory] &&
        bst.prematch.categories[match.idCategory].categoryIsoName
      ) {
        categoryIsoName = bst.prematch.categories[match.idCategory].categoryIsoName;
      }
    }

    return {
      matchDetailOpened: getMatchDetailsOpened(state),
      categoryIsoName,
      bets: bets,
      markets: markets,
      favorite: bst.favorite,
      match: match,
      selected: bst.betsSlip.tickets[bst.betsSlip.currentTicket].prematch.selected,
      selectedBets: getSelectedBets(state, props)
    };
  };
};

const actionCreators = {
  toggleBet: betsSlipToggle,
  favoriteTogglePrematchMatch
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, actionCreators)(MatchRowPreMatch))
);
