import React from 'react';
import { withRouter } from 'react-router-dom';

import ButtonBase from "@material-ui/core/ButtonBase";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import "./keys-rules.scss";
import HeaderIcon from "../assets/dialog-icon.svg";
import KeysStatus from "./keys_status_2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Rules = props => {
  let {
    open,
    onClose,
    type,
    authentication,
    status,
    t,
  } = props;

  const handleClose = () => {
    if (typeof onClose === "function") onClose(false);
  }

  if (!open) return null;

  if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) return null;

  let wagerAmount = 0;
  if (status && status.free_spin && status.free_spin.wager_needed) {
    wagerAmount = status.free_spin.wager_needed;
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`wheel-keys-rules`}
    >
      <DialogTitle className={"dialogTitle"} id="alert-dialog-slide-title">
        <div className={"dialogHeader"}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          {type === "keys" && <div className="dh-text">{t("{{brand}} Keys", { brand: window.config.brand_name_short })}</div>}
          {type === "multiplier" && <div className="dh-text">{t("Spin with multiplier")}</div>}
          {type === "free" && <div className="dh-text">{t("Simple spin")}</div>}
          {type !== "free" && <div className="dh-text muted">{t("How it works?")}</div>}
          {type === "free" && <div className="dh-text muted">{t("Eligibility conditions")}</div>}
        </div>
      </DialogTitle>

      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>

          {type !== "free" && <KeysStatus />}

          {type === "keys" && <React.Fragment>
            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt black">
                <Trans
                  i18nKey="wheelKeysStatus1"
                  values={{ brand: window.config.brand_name_short }}
                  components={[<strong />]}
                > <strong>Play</strong> your preferred product.</Trans>
              </div>
            </div>

            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt">
                <Trans i18nKey="wheelKeysStatus2"> Earn <strong>EXTRA PROGRESS</strong> for playing Sportsbook tickets.</Trans>
              </div>
            </div>

            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt black">
                <Trans i18nKey="wheelKeysStatus3"> <strong>Only real money are counted</strong> in as they fit the usual bonus requirements (no roulette games, min odds 1.5 on sport bets, etc).</Trans>
              </div>
            </div>

            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt ">
                <Trans
                  i18nKey="wheelKeysStatus4"
                  values={{ brand: window.config.brand_name_short }}
                  components={[<strong />]}
                > <strong>Unlock the win multiplier</strong> by spending a few keys every Wheel spin.</Trans>
              </div>
            </div>
          </React.Fragment>}

          {type === "multiplier" && <React.Fragment>
            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt black">
                <Trans
                  i18nKey="wheelKeysStatus1"
                  values={{ brand: window.config.brand_name_short }}
                  components={[<strong />]}
                > <strong>Play</strong> your preferred product.</Trans>
              </div>
            </div>

            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt">
                <Trans i18nKey="wheelKeysStatus6"> <strong>Spend keys</strong> to activate the Multiplier Wheel.</Trans>
              </div>
            </div>

            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt black">
                <Trans i18nKey="wheelKeysStatus7"> <strong>Every three spins with multipliers</strong> activated, unlock one of the puzzle special prizes.</Trans>
              </div>
            </div>

            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt">
                <Trans i18nKey="wheelKeysStatus8"> <strong>The puzzle prize is one of</strong>: increased multiplier values, spin and win twice or using a wildcard to try again for another prize. The puzzle prize is applied automatically to the spin that triggered it.</Trans>
              </div>
            </div>
          </React.Fragment>}

          {type === "free" && <React.Fragment>

            {wagerAmount !== 0 && <div className={`dg-row warning`}>
              <div className="icon"></div>
              <div className="txt">
                {t("Play activity required: {{AMOUNT}} left", { AMOUNT: wagerAmount })}
              </div>
            </div>}

            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt black">
                <Trans
                  i18nKey="wheelKeysStatus1"
                  values={{ brand: window.config.brand_name_short }}
                  components={[<strong />]}
                > <strong>Play</strong> your preferred product.</Trans>
              </div>
            </div>

            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt">
                <Trans i18nKey="wheelKeysStatus2"> Earn <strong>EXTRA PROGRESS</strong> for playing Sportsbook tickets.</Trans>
              </div>
            </div>

            <div className={`dg-row`}>
              <div className="icon"></div>
              <div className="txt black">
                <Trans i18nKey="wheelKeysStatus3"> <strong>Only real money play</strong> is considered within the usual rules (no roulette, min odds 1.5, etc).</Trans>
              </div>
            </div>
          </React.Fragment>}

          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center mt`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={handleClose}>
              {t("I understand")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

export const noAction = () => {
  return {
    type: "NO-ACTION",
  };
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
  }
};
const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Rules)));



