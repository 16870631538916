import React, {Component} from 'react';
import { VelocityComponent } from 'velocity-react';
import { connect } from 'react-redux';
import './LayoutMessages.css';
import {withRouter} from "react-router";

class LayoutMessages extends Component
{
    constructor(props) {
        super(props);

        this.show = false; // not used
    }

    componentDidMount() {

        if (window.config && window.config.landing_page) {
            let storage = window.config.landing_page_session_redirect === '1' ? window.sessionStorage : window.localStorage;
            let landingPage = storage.getItem("landing_page");
            if (landingPage !== window.config.landing_page) {
                if (window.location.pathname === "/") {
                    this.props.history.push(window.config.landing_page)
                }
                storage.setItem("landing_page",window.config.landing_page);
            }
        }
    }

    getMessage(text) {
        if (typeof text === 'string') {
            return (<p dangerouslySetInnerHTML={{__html: text}}></p>);
        } else {
            return (<p>{text}</p>);
        }
    }

    render() {
        let msgState = false;
        let msgText = "";
        if (undefined !== this.props.msg) {
            msgState = this.props.msg.show;
            msgText = this.getMessage(this.props.msg.text);
        }



        return (
            <VelocityComponent animation={ msgState ? { opacity: 1, height: 44 } : { opacity: 0, height: 0 }} duration={200}>
                <div className="LayoutMessages">
                    {msgText}
                </div>
            </VelocityComponent>
        );
    }
}

const mapStateToProps = state => {
    return {
        msg: state.layoutMessage
    }
};

export default withRouter(connect(mapStateToProps)(LayoutMessages));
