import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import './OldSiteLayoutMessages.scss';
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import Icons from "../../assets/Icons/Icons";
import i18next from "i18next";

class OldSiteLayoutMessages extends Component
{
    state = {
        show: true
    };

    componentDidMount() {
        if (window.localStorage.getItem("layoutMessageShown") === "true") {
            this.setState({
                ...this.state,
                show: false
            })
        }
    }

    close() {
        window.localStorage.setItem('layoutMessageShown', "true");
        this.setState({
            ...this.state,
            show: false
        })
    }
    getMessage(text) {

        if (typeof text === 'string') {
            return (<p dangerouslySetInnerHTML={{__html: text}}>
                        <span className={"close"} onClick={this.close.bind(this)}>
                            {Icons.get("closeIcon")}
                        </span>
                    </p>);
        } else {
            return (<p>
                        {text}
                        <span className={"close"} onClick={this.close.bind(this)}>
                            {Icons.get("closeIcon")}
                        </span>
                    </p>);
        }
    }

    render() {
        let msgText = "";

        if (!msgText) {
            if (window.config.old_site && window.config.old_site !== "" && window.config.old_site !== "false") {
                let lang = i18next.language === 'en' ? 'en' : 'ro';
                let text = window.config["old_site_text_" + lang] ? window.config["old_site_text_" + lang] : this.props.t("Use old site");
                let el = (<a href={window.config.old_site}>{text}</a>)
                msgText = this.getMessage(el);
            }
        }

        if (msgText) {
            return (
                <div className={"OldSiteLayoutMessages" + (!this.state.show ? " hide" : "")}>
                    {msgText}
                </div>
            );
        }

        return (<Fragment/>);
    }
}

export default withTranslation()(withRouter(OldSiteLayoutMessages));
