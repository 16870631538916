import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./items_lister.scss";

import { styleToObj, openItem, checkItemAvailability, itemThumbnail, getIndexById } from "../../utils/functions";

const empty = {};

const ItemsLister = (props) => {
  const { data, i18n, source } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  if (items.length === 0) return null;

  const filteredItems = items.filter(item => (checkItemAvailability(item)));
  if (filteredItems.length === 0) return null;

  return (
    <div className={`component-items-lister component`} style={styleToObj(data.style)}>
      <div className="cgl-list with-scroll-style" style={styleToObj(data.data_source_style)}>
        <div className={`cgl-grid ${data.thumbnail_size === "large" ? "large" : ""}`}>
          {filteredItems.map((item, i) => {
            const realIndex = getIndexById(items, item.id);
            const itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;
            const className = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? data.data_source_items[realIndex].className : "";

            let hasFreeSpins = false;
            let freeSpinsCount = 0;
            if (item.type === "slot_game") {
              props.freeSpins && props.freeSpins.forEach(fs => {
                fs && fs.games && fs.games.forEach(gId => {
                  if (parseInt(item.gameId, 10) === parseInt(gId, 10)) {
                    hasFreeSpins = true;
                    freeSpinsCount = fs.remaining_free_spins;
                  }
                })
              });
            }

            return <div
              className={`cgl-item ${item.isEmpty ? "empty" : ""} ${className}`}
              key={i}
              style={itemStyle}
              data-type={item.type}
              data-item={JSON.stringify({ id: item.internalId, providerId: item.providerId, name: item.name ? item.name : item.label, gameId: item.gameId })}
              onClick={openItem}>
              <div className="outer">
                <div className="inner">
                  <div>
                    <img src={itemThumbnail(item)} alt="" />
                    {hasFreeSpins && freeSpinsCount !== 0 && <div className="free-spin-ticker">{freeSpinsCount} Free Spins</div>}
                  </div>
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
    freeSpins: state.freeSpins && state.freeSpins.freeSpins ? state.freeSpins.freeSpins : [],
  }
};

export default withTranslation()(connect(mapStateToProps)(ItemsLister));