import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import Game from './../../../Games/Game/Game';
import Icons from './../../../../assets/Icons/Icons';
import "./RecentGames.scss";

class RecentGames extends Component {
    constructor(props) {
        super(props);
        this.limit = 6;
    }

    render() {
        let promotedIcon = Icons.get('recommendedIcon', 'recommendedIcon');

        let stateRecentGames = this.props.games.items;
        let recentGames = [];
        stateRecentGames.every((element, index) => {
            if (index + 1 > this.limit) {
                return false;
            }

            recentGames.push(<Game key={"jpRec_" + element.id} gameItem={element} section="jackpotRecentGames" />);
            return true;
        });
        if (recentGames.length) {
            return (
                <div className="RecentGames">
                    <h1>
                        <span className="icon">{promotedIcon}</span>
                        <div className="texts">
                            <span className="text">{this.props.t("Recently played in EGT Jackpot")}</span>
                        </div>
                        <div style={{
                            clear: "both",
                        }}></div>
                    </h1>
                    <div className="GamesCards">{recentGames}</div>
                </div>
            );
        } else {
            return (<Fragment> </Fragment>);
        }
    }

}

const mapStateToProps = state => {
    return {
        games: state.games,
    }
};

export default withTranslation()(connect(mapStateToProps)(RecentGames));
