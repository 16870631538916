import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IframeResizer from "iframe-resizer-react";

import "./GamePageWeb.scss";
import Games from "./games";
import Icons from "../../assets/Icons/Icons";
import * as stateActions from "../../store/actions";
import { getSportsbookToken } from "../../store/actions/authentication";

class GamePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sbToken: null,
      showClose: false
    };
    this.iframeRef = null;
  }

  doAuth = () => {
    const auth = this.props.authentication;

    if (
      auth.access_token === null ||
      !(auth && ["user", "token"].indexOf(auth.auth_type) > -1)
    ) {
      this.setState(s => ({
        ...s,
        sbToken: {
          tempToken: null,
          id: null,
        }
      }));

      return;
    }

    if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
      getSportsbookToken(auth, data =>
        this.setState(s => ({
          ...s,
          sbToken: data
        }))
      );
    }
  };

  componentDidMount() {
    this.doAuth();
  }

  componentDidUpdate(prevProps) {
    //console.log("props changed", prevProps, this.props);

    if (
      this.props.authentication.auth_type !== prevProps.authentication.auth_type ||
      this.props.application.language !== prevProps.application.language
    ) {
      this.doAuth();
    }
  }

  hideLoader = () => {
    setTimeout(() => {
      this.props.contentLoaded();
    }, 2000);
  };

  onResize = data => {
    //console.log("onResize", data);
  };

  onMessage = data => {
    //console.log("onMessage", data);
    if (data.message && data.message.type) {
      switch (data.message.type) {
        case "contentLoaded": {
          this.hideLoader();
          this.setState(s => ({ ...s, showClose: true }));
          break;
        }
        case "loginRequired": {
          this.props.onModalStateChanged(true);
        }
      }
    }
  };

  closeHandler = event => {
    let menuItems = Object.values(this.props.application.menu);
    let menuItem = menuItems.find(item => {
      return "virtuale" === item.code;
    });

    if (!menuItem) return false;

    event.preventDefault();
    event.stopPropagation();

    this.props.history.push(`/${menuItem.code}`);

    this.props.onPageChanged(menuItem);

    return false;
  };

  render() {
    const { showClose, sbToken } = this.state;

    if (sbToken === null) return null;

    const { match } = this.props;
    const { id } = match.params;

    const closeIcon = Icons.get("close-button", "closeScreenGame");

    //console.log("sbToken", sbToken);

    const game = Games.find(game => game.id === id);

    let iframeURL = `${game.iframeURL}?auth=b2b&logout=true&lang=${this.props.application.language}`;

    if (sbToken.tempToken !== null) {
      iframeURL = `${game.iframeURL}?auth=b2b&token=${sbToken.tempToken}&id=${sbToken.id}&authStrategy=tempToken&lang=${this.props.application.language}`;
    }

    return (
      <div className="virtuals-game-cs">
        {/*
        <iframe
          className={`-iframe -id-${id}`}
          src={iframeURL}
          onLoad={this.hideLoader}
          frameBorder="0"
          title={game.name}
        ></iframe>
        */}

        {showClose && (
          <div className="virtual-screen-title">
            {/*<h5>{game.name}</h5>*/}
            <button type="button" className="close" onClick={this.closeHandler}>
              <span aria-hidden="true">{closeIcon}</span>
            </button>
          </div>
        )}

        <IframeResizer
          checkOrigin={false}
          forwardRef={this.iframeRef}
          heightCalculationMethod="lowestElement"
          //inPageLinks
          //log
          onMessage={this.onMessage}
          onResized={this.onResize}
          src={iframeURL}
          className={`-iframe -id-${id}`}
          frameBorder="0"
          title={game.name}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    application: state.application
  };
};

const mapDispatchToProps = dispatch => {
  return {
    contentLoaded: () => dispatch(stateActions.hideContentLoader()),
    onPageChanged: page => dispatch(stateActions.changePage(page)),
    onModalStateChanged: (visible = false, modalType = "login") =>
      dispatch(stateActions.toggleModalVisibility(visible, modalType))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GamePage));
