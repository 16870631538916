import { tablesConstants } from "../constants";

export const tablesStateIni = (data) => ({
  type: tablesConstants.STATE_INI,
  data: data,
});

export const tablesStateUpdate = (data) => ({
  type: tablesConstants.STATE_UPDATE,
  data: data,
});
