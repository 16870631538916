import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";

import { debug } from "../../utils";
import { statScoreSave } from "../../store/actions/statScore";
import { getBetsState } from "../../store/selectors/betData";
import { statScoreLiveURL, statScorePreMatchURL } from "../../api/widgets";
import TabsMenu from "../TabsMenu";

const useStyles = makeStyles({
  root: {
    width: "100%",
    color: "#fff",
    textAlign: "center"
  },
});

const StatScoreWidget = (props) => {
  const { ssId, idMatch, idSport, brId, mType, statsScoreStatus, configId, statScoreSave, subType, i18n, t, index } = props;
  const classes = useStyles();

  const [showWidget, setShowWidget] = React.useState(false);

  let [standingsState, setStandingsState] = React.useState({
    index: 0,
    standingType: "League",
    show: subType === "standings" ? true : false,
  });
  const unmounted = React.useRef(false);

  const handleWidgetError = (e) => {
    debug("Widget", ssId, idMatch, idSport, brId, mType, "error", e);
    if (unmounted.current) return;
    setShowWidget(false);
  };

  const handleWidgetMounted = (e) => {
    debug("Widget", ssId, idMatch, idSport, brId, mType, "mounted", e);
    if (unmounted.current) return;
    setShowWidget(true);
  };

  const domID = `widget_${ssId}_${idMatch}_${configId}`;
  const widget = React.useRef(null);
  const initedForId = React.useRef("");

  React.useEffect(() => {
    if (subType === "standings") {
      setStandingsState(v => ({ index: 0, standingType: "League", show: true }));
    } else {
      setStandingsState(v => ({ index: 0, standingType: "League", show: false }));
    }
  }, [subType]);

  React.useEffect(() => {
    if (unmounted.current) return;

    if (ssId && initedForId.current !== ssId) {
      if (initedForId.current && widget.current && typeof widget.current.destroy === "function") {
        debug("destroy live score widget");
        widget.current.off("error", handleWidgetError);
        widget.current.off("mount", handleWidgetMounted);
        widget.current.destroy();
        widget.current = null;
      }
      const element = document.getElementById(domID);

      // Input data for widget type you want to embed
      const inputData = { eventId: ssId, language: i18n.language };

      if (standingsState.show) {
        inputData.standingType = standingsState.standingType;
      }

      // Optional object with options. 
      // You can check available options further in the docs.
      const options = { loader: { enabled: true } };

      try {
        widget.current = new window.STATSCOREWidgets.Widget(element, configId, inputData, options);
        initedForId.current = ssId;
      } catch (err) { }

      if (widget.current) {
        widget.current.on("mount", handleWidgetMounted);
        widget.current.on("error", handleWidgetError);
      }
    } else {
      handleWidgetMounted();
    }
  }, [ssId, idSport, domID, configId, i18n.language, standingsState]); // eslint-disable-line

  React.useEffect(() => {
    // idSport === footballId online live sport matches have timeline
    if (idMatch && statsScoreStatus && ((mType === "live" && typeof statsScoreStatus[idMatch] === "undefined") || (mType === "prematch" && typeof statsScoreStatus[brId] === "undefined"))) {
      const getStatsScoreStatus = () => {
        if (mType === "live" && idMatch) {
          axios
            .get(statScoreLiveURL(idMatch))
            .then(r => {
              if (unmounted.current) return;
              if (r.data && r.data.api && r.data.api.method && r.data.api.method.total_items && r.data.api.data.booked_events) {
                const sId = r.data.api.data.booked_events.length && r.data.api.data.booked_events[0].id ? r.data.api.data.booked_events[0].id : "";
                statScoreSave(idMatch, true, sId);
              } else {
                statScoreSave(idMatch, false, "");
              }
            })
            .catch(err => {
              debug("statsScore err", err);
            });
        } else if (mType === "prematch" && brId) {
          axios
            .get(statScorePreMatchURL(brId))
            .then(r => {
              if (unmounted.current) return;
              if (r.data && r.data.api && r.data.api.method && r.data.api.method.total_items && r.data.api.data.booked_events) {
                const sId = r.data.api.data.booked_events.length && r.data.api.data.booked_events[0].id ? r.data.api.data.booked_events[0].id : "";
                statScoreSave(brId, true, sId);
              } else {
                statScoreSave(brId, false, "");
              }
            })
            .catch(err => {
              debug("statsScore err", err);
            });
        }
      };

      getStatsScoreStatus();
    }
  }, [idMatch, brId, idSport, statsScoreStatus, mType, statScoreSave]);

  React.useEffect(() => {
    return () => {
      unmounted.current = true;

      if (widget.current && typeof widget.current.destroy === "function") {
        widget.current.off("error", handleWidgetError);
        widget.current.off("mount", handleWidgetMounted);
        widget.current.destroy();
        widget.current = null;
      }
    }
  }, []); // eslint-disable-line

  const tabs = [
    { sportName: t("League"), type: "League" },
    { sportName: t("Home"), type: "Home" },
    { sportName: t("Away"), type: "Away" },
    { sportName: t("Top Scorers"), type: "TopScorers" },
    { sportName: t("Form"), type: "Form" },
    { sportName: t("Assists"), type: "Assists" },
    { sportName: t("Cards"), type: "Cards" },
    { sportName: t("Halftime"), type: "Halftime" },
    //{ sportName: t("ATP Ranking"), type: "AtpRanking" },
    //{ sportName: t("WTA Ranking"), type: "WtaRanking" },
    //{ sportName: t("ATP Doubles"), type: "AtpDoubles" },
    //{ sportName: t("WTA Doubles"), type: "WtaDoubles" },
  ];

  const handleMenuChange = (index) => {
    initedForId.current = "0";
    setStandingsState(v => ({ ...v, index: index, standingType: tabs[index].type }));
  };

  return (
    <div className={`${classes.root}`}>
      {standingsState.show && index === 4 && <TabsMenu
        className={"stat-score-widget"}
        value={standingsState.index}
        tabs={tabs}
        onChange={handleMenuChange}
      />}
      <div id={domID}></div>
    </div>
  );
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const bst = getBetsState(state);

    const ret = {
      statsScoreStatus: bst.statScore.exists,
    };

    if (!props.ssId) {
      if (props.mType === "live") {
        if (props.idMatch && bst.statScore.exists && typeof bst.statScore.exists[props.idMatch] !== "undefined") {
          if (bst.statScore.exists[props.idMatch].status) {
            ret["ssId"] = bst.statScore.exists[props.idMatch].ssId;
          }
        }
      } else if (props.mType === "prematch") {
        if (props.brId && bst.statScore.exists && typeof bst.statScore.exists[props.brId] !== "undefined") {
          if (bst.statScore.exists[props.brId].status) {
            ret["ssId"] = bst.statScore.exists[props.brId].ssId;
          }
        }
      }
    }
    return ret;
  };

  return mapStateToProps;
};

const actionCreators = {
  statScoreSave
};

export default connect(makeMapStateToProps, actionCreators)(withTranslation()(StatScoreWidget));