/* eslint-disable */
import { takeEvery, put } from "redux-saga/effects";
import axios from "axios";

import * as actionTypes from "../../../store/actions/actionTypes";
import { playerConstants } from "../actions/constants";
import { playerLoaded } from "../actions/player";
import getStore from "../../store";

let apiUrl = process.env.CFW.betsApiUrl + "/winner-fun";

if (process.env.NODE_ENV === "development") {
  //console.error("TODO: REMOVE NEXT LINE");
  //apiUrl = "http://localhost:6001/winner-fun";
}

function* playerDataSaga(action) {
  const store = getStore();
  const state = store.getState();

  if (["user", "token"].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }

  try {
    let { data } = yield axios.get(apiUrl + "/player/data", {
      headers: {
        Authorization: "Bearer " + state.authentication.access_token
      }
    });
    if (data) {
      yield put(playerLoaded(data));
    }
  } catch (e) {
    console.log("[WF:playerDataSaga] err", e);
  }
}

function* savePlayerNicknameSaga(action) {
  const store = getStore();
  const state = store.getState();

  const newNickname = action.data.nickname;
  const cb = action.data.cb ? action.data.cb : () => { };

  if (!newNickname) return;

  if (["user", "token"].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }

  let res = {
    success: false
  };

  try {
    let { data } = yield axios.post(
      apiUrl + "/player/data",
      {
        nickname: newNickname
      },
      {
        headers: {
          Authorization: "Bearer " + state.authentication.access_token
        }
      });
    if (data) {
      yield playerDataSaga();
      res = data;
    }
  } catch (e) {
    console.log("[WF:playerDataSaga] err", e);
  }
  yield cb(res);
}

export default function* playerInit() {
  //console.log("[saga] playerInit");
  yield takeEvery(actionTypes.profile.RECEIVED_ACCOUNT, playerDataSaga);
  yield takeEvery(playerConstants.LOAD, playerDataSaga);
  yield takeEvery(playerConstants.SAVE, savePlayerNicknameSaga);
}
