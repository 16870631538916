import * as actionTypes from "./actionTypes";
import JackpotsModel from "../../models/JackpotsModel";
import BackendClient from "../../BackendClient";

export const jackpotEgtReceived = data => {
    return dispatch => {
        const model = new JackpotsModel(data);
        model.dataReceived = true;

        dispatch({
            type: actionTypes.jackpots.DATA_RECEIVED_EGT,
            model: model
        });
    };
};

export const jackpotAmaticReceived = data => {
    return dispatch => {
        const model = new JackpotsModel(data);
        model.dataReceived = true;

        dispatch({
            type: actionTypes.jackpots.DATA_RECEIVED_AMATIC,
            model: model
        });
    };
};

export const jackpotsReceived = data => {
    return dispatch => {
        dispatch({
            type: actionTypes.jackpots.ALL_JACKPOTS_RECEIVED,
            data: data
        });
    };
};

export const latestJackpotWinnersReceived = data => {
    return dispatch => {
        dispatch({
            type: actionTypes.jackpots.LATEST_JACKPOT_WINNERS_RECEIVED,
            data: data
        });
    };
};

export const latestWinnersReceived = data => {
    return dispatch => {
        dispatch({
            type: actionTypes.jackpots.LATEST_WINNERS_RECEIVED,
            data: data
        });
    };
};

export const hideJackpotApp = (action = false) => {
    return dispatch => {
        dispatch({
            type: actionTypes.jackpots.HIDE_APP,
            action: action
        });
    };
};

export const jackpotProviderReceived = (provider, data) => {
    return dispatch => {
        if ("egt" === provider) {
            return dispatch(jackpotEgtReceived(data));
        }

        if ("amatic" === provider) {
            return dispatch(jackpotAmaticReceived(data));
        }
    };
};

const JACKPOT_VALUES_REQUEST_INTERVAL = 30 * 1000; // 3 seconds
let jackpotValuesLastRequestTime = 0;

export const requestJackpotValues = (jpType = 1, simulationAmount = null) => {
    return dispatch => {
        const now = new Date().getTime();

        if (now - jackpotValuesLastRequestTime < JACKPOT_VALUES_REQUEST_INTERVAL) {
            return;
        }

        jackpotValuesLastRequestTime = now;

        let params = {
            type: jpType
        };

        if (simulationAmount) {
            params.amount = simulationAmount;
        }

        let axios = BackendClient.getInstance();

        axios({
            url: "/api/casino/jackpot",
            method: "get",
            params: params
        }).catch(e => {
            console.log(`[CATCHED **casino jackpot** ERROR] =? ${e}`);
        });
    };
};

const JACKPOTS_REQUEST_INTERVAL = 30 * 1000; // 30 seconds
let jackpotsLastRequestTime = 0;

export const requestJackpots = () => {
    return dispatch => {
        const now = new Date().getTime();

        if (now - jackpotsLastRequestTime < JACKPOTS_REQUEST_INTERVAL) {
            return;
        }

        jackpotsLastRequestTime = now;

        let params = {
            platformType: window.config.platformType
        };
        let axios = BackendClient.getInstance();

        axios({
            url: "/api/casino/jackpots",
            method: "get",
            data: params
        }).catch(e => {
            console.log(`[CATCHED **casino jackpot** ERROR] =? ${e}`);
        });
    };
};

const LATEST_WINNERS_REQUEST_INTERVAL = 3600 * 1000; // one hour
let latestWinnersLastRequestTime = {
    jackpot: 0,
    slots: 0
};

export const requestLatestWinners = (jackpotEnabled = 1) => {
    return dispatch => {
        let params = {
            type: jackpotEnabled ? "jackpot" : "slots"
        };

        const now = new Date().getTime();

        if (now - latestWinnersLastRequestTime[params["type"]] < LATEST_WINNERS_REQUEST_INTERVAL) {
            return;
        }

        latestWinnersLastRequestTime[params["type"]] = now;

        let axios = BackendClient.getInstance();

        axios({
            url: `/api/casino/latest-winners`,
            method: "post",
            data: params
        }).catch(e => {
            console.log(`[CATCHED **casino jackpot** ERROR] =? ${e}`);
        });
    };
};
