import React from 'react';

import "./PanelSwitcher.scss";
import Gratuities from "./Gratuities";
import Score from "./Score";
import Raffle from "./Raffle";
import Calendar from "./Calendar";
import Missions from "./Missions";
import Teams from "./Teams";

const PanelSwitcher = (props) => {

  const { index, subsection, subsectionType, goToHistoric } = props;

  let a = 0;

  return (
    <React.Fragment>
      <Gratuities className={`${index !== a++ ? 'wf-display-none' : ''}`} subsection={subsection} subsectionType={subsectionType} opened={index === 0 ? true : false} onTabChange={props.onTabChange} />
      <Score className={`${index !== a++ ? 'wf-display-none' : ''}`} goToHistoric={goToHistoric} subsection={subsection} subsectionType={subsectionType} opened={index === 1 ? true : false} onTabChange={props.onTabChange} />
      <Calendar className={`${index !== a++ ? 'wf-display-none' : ''}`} subsection={subsection} subsectionType={subsectionType} opened={index === 2 ? true : false} onTabChange={props.onTabChange} />
      <Teams className={`${index !== a++ ? 'wf-display-none' : ''}`} subsection={subsection} subsectionType={subsectionType} opened={index === 3 ? true : false} onTabChange={props.onTabChange} />
      <Raffle className={`${index !== a++ ? 'wf-display-none' : ''}`} subsection={subsection} subsectionType={subsectionType} opened={index === 4 ? true : false} onTabChange={props.onTabChange} />
      <Missions className={`${index !== a++ ? 'wf-display-none' : ''}`} subsection={subsection} subsectionType={subsectionType} opened={index === 5 ? true : false} onTabChange={props.onTabChange} />
    </React.Fragment>
  );
}

export default PanelSwitcher;