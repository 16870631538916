import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { gsap, TimelineMax, TweenMax, Power3, Power2, Linear, Draggable } from "gsap/all";


import { getBetsState, getSuperBannerMatches } from "../../../bets/store/selectors/betData";
import BetSelect from "../../../bets/components/BetSelect";

import evbus from "../../../utils/evbus";
import { getBaseRouterPath } from "../../utils/config";
import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear);

const useStyles = makeStyles({
  root: {
    //width: "100%",
    padding: "0 20px",
    position: "relative",
    bottom: "-145px",
    //zIndex: 1,
    boxSizing: "border-box",
    fontFamily: "OpenSans, 'Open Sans'"
  },
  rootShown: {
    bottom: 0
  },
  full: {
    paddingLeft: "calc(9% + 10px)",
    paddingRight: "calc(9% + 10px)",
    paddingBottom: "10px",
    paddingTop: "20px"
  },
  betWrapper: {
    display: "block",
    background: "#000000",
    padding: "6px 14px 8px 14px",
    minHeight: "64px",
    boxSizing: "border-box",
    boxShadow: "0px 3px 6px rgba(0,0,0,.5)",
    pointerEvents: "all",
    "& .myBetElement": {
      borderRadius: "0!important"
    }
  },
  betWrapperSm: {
    padding: "6px 14px 8px 14px"
  },
  lightPass: {
    background: "linear-gradient(90deg, #FF1D96, #247DFF, #FF1D96, #247DFF)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "400% 400%",
    animation: "buttonAnim 10s ease infinite"
  },
  betWrapperText: {
    fontSize: 14,
    fontWeight: 600,
    padding: "6px",
    lineHeight: "24px",
    borderRadius: "5px",
    transform: "translate3d(0,0,0)"
  },
  betWrapperTextFull: {
    fontSize: "calc(14px + (25 - 14) * ((100vw - 960px) / (2560 - 960)))",
    padding: "20px 50px 20px"
  },
  question: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "18px",
    textShadow: "0px 2px 1px #000000D1"
  },
  questionFull: {
    fontSize: "calc(18px + (30 - 18) * ((100vw - 960px) / (2560 - 960)))"
  },
  subQuestion: {
    color: "#D5D5D5",
    fontWeight: 500,
    fontSize: "12px",
    textShadow: "0px 2px 2px #000000C7"
  },
  subQuestionFull: {
    fontSize: "calc(12px + (20 - 12) * ((100vw - 960px) / (2560 - 960)))"
  },
  text: {
    textAlign: "left",
    paddingLeft: "24px",
    paddingBottom: "8px"
    //transition: "transform 300ms ease-in-out",
    //transform: "translate3d(0, 135px, 0)",
    //willChange: "transform"
  },
  data: {
    //transition: "transform 300ms ease-in-out",
    //transform: "translate3d(0, 135px, 0)",
    //willChange: "transform"
  },
  delayedTransition: {
    transition: "transform 300ms ease-in-out 300ms"
  },
  enter: {
    transform: "translate3d(0, 0, 0)"
  },
  bg: {
    opacity: 0,
    pointerEvents: "none",
    position: "absolute",
    bottom: "22px",
    left: "0",
    right: "0",
    top: "0",
    background:
      "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.4) 15%, rgba(0,0,0,.6) 50%, rgba(15, 52, 90, 0.8) 100%)",
    transition: "opacity 0.5s ease-in-out"
  },
  bgFull: {
    bottom: "0"
  },
  teams: {
    padding: "12px 0 8px"
  },
  matchVs: {
    //fontSize: "10px",
    fontSize: "calc(10px + (14 - 10) * ((100vw - 960px) / (2560 - 960)))",
    padding: "0 5px",
    position: "relative",
    color: "#fff",
    top: "2px",
    textShadow: "0px 0px 15px #000000",
    opacity: ".7"
  },
  matchName: {
    fontWeight: "normal",
    //fontSize: "16px",
    fontSize: "calc(16px + (20 - 16) * ((100vw - 960px) / (2560 - 960)))",
    color: "#fff",
    textShadow: "0px 0px 15px #000000"
  },
  matchFlag: {
    position: "relative",
    top: "2px",
    height: "16px",
    margin: "0 5px"
  },
  header: {
    textAlign: "center",
    margin: "0 20px",
    background: "rgba(4,8,25,.6)"
  },
  title: {
    fontSize: "9px",
    color: "#fff",
    lineHeight: "13px",
    marginBottom: "2px"
  },
  date: {
    display: "inline-block",
    color: "#192640",
    backgroundColor: "#FFC400",
    padding: "2px 6px",
    //fontSize: "7px",
    fontSize: "calc(11px + (12 - 11) * ((100vw - 960px) / (2560 - 960)))",
    fontWeight: "bold",
    borderRadius: "3px",
    textTransform: "uppercase",
  },
  betsLocked: {
    height: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  lockIcon: {
    color: "#fff",
    "& svg": {
      fill: "#fff"
    }
  },
  lockText: {
    color: "#fff",
    fontSize: "12px"
  },
  txt: {
    padding: "7px 16px 0px",
    color: "#FFC400",
    fontSize: "14px",
    fontWeight: "600"
  },
  uc: {
    textTransform: "uppercase"
  },
  pr: {
    position: "relative"
  },
  white: {
    color: "#fff",
    textTransform: "capitalize"
  },
  time: {
    background: "#313F80",
    padding: "2px 8px",
    color: "#B7CDD8",
    fontSize: "12px",
    fontWeight: "600",
    position: "absolute",
    right: "16px",
    top: "33px",
    borderRadius: "5px"
  }
});

const FootballBanner = ({ pMatch, eventName, title, width, t, i18n }) => {
  const classes = useStyles();

  /*
  const root = useRef();
  const bgRef = useRef();
  */

  const lang = i18n.language;

  const root = React.createRef();
  const bgRef = React.createRef();

  const [visible, setVisible] = useState(false);
  const [match, setMatch] = useState(null);

  useEffect(() => {
    //console.log("pMatches", pMatches);
    /*
    if (pMatches.length > 0) {
      setMatch(pMatches[0]);
    } else {
      setMatch(null);
    }
    */
    setMatch(pMatch);
  }, [pMatch]);

  const onEnter = useCallback(
    tl => {
      //console.log("enter", tl, root);

      if (root.current === null) {
        //console.log("null root");
        return;
      }

      let pos = "-=1";
      if (tl === null) {
        tl = gsap;
        pos = "";
      }

      tl.to(
        root.current,
        {
          duration: 1,
          y: -145
        },
        pos
      );

      setVisible(() => true);

      tl.eventCallback("onReverseComplete", () => {
        //console.log("fotball banner hidden");
        setVisible(() => false);
      });

      if (bgRef && bgRef.current) {
        bgRef.current.style.opacity = "1";
      }
    },
    [root]
  );

  const onExit = useCallback(
    tl => {
      //console.log("exit", tl, root);

      if (root.current === null) {
        //console.log("null root");
        return;
      }

      tl.to(
        root.current,
        {
          duration: 1,
          y: 145
        },
        "-=1"
      );

      tl.eventCallback("onComplete", () => {
        //console.log("football banner hidden");
        setVisible(() => false);
      });

      if (bgRef && bgRef.current) {
        bgRef.current.style.opacity = "0";
      }
    },
    [root]
  );

  React.useEffect(() => {
    //console.log("mount eventName", eventName);

    evbus.on(eventName + "_enter", onEnter);
    evbus.on(eventName + "_exit", onExit);

    return () => {
      //console.log("unmount eventName", eventName);

      evbus.remove(eventName + "_enter");
      evbus.remove(eventName + "_exit");
    };
  }, [eventName, onExit, onEnter]);

  let clsText = classes.text;
  let clsData = `${classes.data} ${
    width !== "md" && width !== "sm" && width !== "xs" ? classes.delayedTransition : ""
    }`;

  let classRoot =
    width !== "md" && width !== "sm" && width !== "xs"
      ? `${classes.root} ${classes.full}`
      : classes.root;
  let classBg =
    width !== "md" && width !== "sm" && width !== "xs"
      ? `${classes.bg} ${classes.bgFull}`
      : classes.bg;
  let classQuestion =
    width !== "md" && width !== "sm" && width !== "xs"
      ? `${classes.question} ${classes.questionFull}`
      : classes.question;
  let classSubQuestion =
    width !== "md" && width !== "sm" && width !== "xs"
      ? `${classes.subQuestion} ${classes.subQuestionFull}`
      : classes.subQuestion;
  let classBetWrapper =
    width !== "md" && width !== "sm" && width !== "xs"
      ? `${classes.betWrapper}`
      : `${classes.betWrapper} ${classes.betWrapperSm}`;
  /*
  let classBetWrapperText =
    width!== "md" && width !== "sm" && width !== "xs"
      ? `${classes.betWrapperText} ${classes.betWrapperTextFull}`
      : classes.betWrapperText;
      */

  /*
  if (!match) {
    return null;
  }
  */

  let teams = "";

  if (match !== null) {
    teams = (
      <React.Fragment>
        <div>
          <span className={classes.matchName}>{match.team1Name}</span>
        </div>
        <div className={classes.matchVs}>:</div>
        <div>
          <span className={classes.matchName}>{match.team2Name}</span>
        </div>
      </React.Fragment>
    );
  }




  const history = useHistory();
  const handleClick = () => {
    const basePath = getBaseRouterPath();
    history.push(`${basePath}/${match.mType === "live" ? "live" : "prematch"}/match/${match.idSport}/${match.idCategory}/${match.idTournament}/${match.idMatch}`);
  };

  //let isDesktop = useMediaQuery("(min-width:1920px)");
  const lgCols = 12;
  const isDesktop1024 = useMediaQuery("(min-width:1025px)");

  return (
    <div>
      <div ref={bgRef} className={classBg}></div>
      <div ref={root} className={classRoot}>
        {visible && (
          <React.Fragment>
            <Grid container alignItems="center">
              {/*width !== "md" && width !== "sm" && width !== "xs" && isDesktop && (
                <Grid item md={8} className={clsText}>
                  <div className={classQuestion}>
                    {match !== null && (
                      <span>
                        {match.team1Name} {t("or")} {match.team2Name}?
                      </span>
                    )}
                  </div>
                  <div className={classSubQuestion}>
                    {t("Decide who goes home with the trophy")}
                  </div>
                </Grid>
                    )*/}
              <Grid item xs={12} lg={lgCols} className={clsData}>
                <div className={classes.header} onClick={handleClick}>
                  {match && (
                    <div
                      className={`d-flex flex-row flex-nowrap align-items-center justify-content-between ${classes.pr}`}
                    >
                      <div className={`${classes.txt}`}>({t("Code")}: {match.displayId})</div>
                      <div className={`${classes.txt} ${classes.uc}`}>{title}</div>
                      <div className={`${classes.txt} ${classes.white}`}>
                        {moment(parseInt(match.matchDateTime, 10)).format("dddd, DD MMM")}
                      </div>
                      <div className={`${classes.time}`}>
                        {moment(parseInt(match.matchDateTime, 10)).format("HH:mm")}
                      </div>
                    </div>
                  )}
                  <div
                    className={`d-flex flex-row flex-nowrap align-items-center justify-content-center ${classes.teams}`}
                  >
                    {teams}
                  </div>
                </div>
                <div className={classBetWrapper}>
                  {match && match.bettingStatus && (
                    <BetSelect
                      match={match}
                      titleColor="#FFF"
                      banner={true}
                      lockedBet
                      maxOutcomes={isDesktop1024 ? 6 : 3}
                    />
                  )}
                  {match && !match.bettingStatus && (
                    <div className={classes.betsLocked}>
                      <div className={classes.lockIcon}>
                        <LockOutlinedIcon />
                      </div>
                      <div className={classes.lockText}>{t("EVENT BETS LOCKED")}</div>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

/*
FootballBanner.propTypes = {
  match: PropTypes.object
};

FootballBanner.defaultProps = {
  match: null
};
*/

const mapStateToProps = () => {

  return (state, props) => {
    const bst = getBetsState(state);
    const pMatches = getSuperBannerMatches(state);
    const pMatch = pMatches.length > 0 ? pMatches[0] : null;

    let title = "";
    if (pMatch) {
      const parts = [];

      if (bst.prematch.sport && bst.prematch.sport[pMatch.idSport]) parts.push(bst.prematch.sport[pMatch.idSport].sportName);
      if (bst.prematch.categories && bst.prematch.categories[pMatch.idCategory]) parts.push(bst.prematch.categories[pMatch.idCategory].categoryName);
      if (bst.prematch.tournaments && bst.prematch.tournaments[pMatch.idTournament]) parts.push(bst.prematch.tournaments[pMatch.idTournament].tournamentName);

      title = parts.length ? parts.join(" / ") : '';
    }

    return {
      pMatch: pMatches.length > 0 ? pMatches[0] : null,
      title
    };
  };
};

export default withTranslation()(withWidth()(connect(mapStateToProps)(FootballBanner)));
