import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../AuthenticationModal/Username/Username.css';
import TextInput from "../TextInput/TextInput";
import * as stateActions from "../../../../store/actions";
import Utils from "../../../../utils/Utils";
import { withTranslation } from "react-i18next";
import GTM from "../../../../utils/GTM";


const ERR_LENGTH = 0;
const ERR_CHAR = -1;
const IS_UNDEFINED = undefined;
const VALID = 1;

class LastName extends Component {
    constructor(props) {
        super(props);
        this.defaultGroupClassName = "input-group";
        this.timerId = 'LastNameValidation';

    }
    componentDidMount() {
        this.infoMessage = {
            type: 'info',
            text: this.props.t('Last Name'),
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.fieldInput !== this.props.lastName && prevProps.lastName !== this.props.lastName && this.props.lastName.length > 1) {
            this.setState({
                ...this.state,
                fieldInput: this.props.lastName
            })
            this.blurHandler();
        }
    }
    state = {
        groupClassName: `${this.defaultGroupClassName} notValid`,
        message: {
            type: "",
            text: "",
        },
        ok: false,
        fieldInput: '',
    };

    validate(lastName) {
        if (typeof lastName === 'undefined') return IS_UNDEFINED;

        if (lastName.length < 2) return ERR_LENGTH;

        if (lastName.match(/^[A-z\s-]+$/) == null) return ERR_CHAR; // accept letters, spaces and "-"

        return VALID;
    }

    sendGAEvent() {
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "Last Name" + (this.state.ok ? "" : " - " + this.state.message.text));
    }

    blurHandler() {

        Utils.delayedFunction('sendGAEvent',this.sendGAEvent.bind(this),50);
        Utils.delayedFunction(this.timerId, this.triggerChangeHandler.bind(this), 0);
    }

    triggerChangeHandler() {
        let groupClassName = undefined;
        let message = "";
        let valid = this.validate(this.state.fieldInput);
        let ok = this.state.ok;

        switch (valid) {
            case ERR_CHAR:
                groupClassName = `${this.defaultGroupClassName} error`;
                message = this.props.t("This field contains invalid characters");
                if (ok) {
                    ok = false;
                }
                break;
            case ERR_LENGTH:
                groupClassName = `${this.defaultGroupClassName} error`;
                message = this.props.t("This field must have minimum 2 characters");
                if (ok) {
                    ok = false;
                }
                break;
            case VALID:
                groupClassName = `${this.defaultGroupClassName} valid`;
                if (!ok) {
                    ok = true;
                }
                break;
            default:
                groupClassName = `${this.defaultGroupClassName} notValid`;
                message = this.props.t("Inserted field is invalid");
                if (ok) {
                    ok = false;
                }
                break;
        }

        this.setState({
            ...this.state,
            groupClassName: groupClassName,
            message: {
                ...this.state.message,
                type: (message !== "" ? "error" : ""),
                text: message,
            },
            ok: ok,

        });
        this.props.onChange({ lastName: this.state.fieldInput, validated: ok });
    }

    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.lastName;
        } else {
            fieldInput = event.target.value;
        }
        fieldInput = fieldInput.trim();

        if (fieldInput !== this.state.fieldInput && this.state.fieldInput !== '') {
            this.props.resetOCR();
        }

        Utils.delayedFunction(this.timerId, this.triggerChangeHandler.bind(this));

        this.setState({
            ...this.state,
            fieldInput: fieldInput
        });

        this.props.onChange({ lastName: fieldInput, validated: false });

    }

    onFocusHandler() {
        this.setState({
            ...this.state,
            message: this.infoMessage,
            groupClassName: `${this.defaultGroupClassName} empty`
        });
    }

    keyPressedHandler(e) {
        // accept just letters, space and "-"
        if (!((e.charCode < 123 && e.charCode > 64) || e.charCode === 32 || e.charCode === 45)) {
            e.preventDefault();
        }
    }

    render() {

        return (

            <TextInput reward={(typeof this.props.showReward !== "undefined" ? this.props.showReward : true)}

                       animateReward={this.props.reward ? true : false}
                iconName="id-check"
                iconClassName="login-check"
                groupClassName={this.state.groupClassName}
                inputType="text"
                inputIdName={this.props.t("Last Name")}
                inputValue={this.props.lastName}
                inputLabel={this.props.t("Last Name")}
                onFocus={this.onFocusHandler.bind(this)}
                blurHandler={this.blurHandler.bind(this)}
                changeHandler={this.changeHandler.bind(this)}
                keyPressedHandler={this.keyPressedHandler.bind(this)}
                message={this.state.message} />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(stateActions.fieldEntered({ field: "lastName", value: fieldInput.lastName, validated: fieldInput.validated })),
        resetOCR: () => dispatch(stateActions.fieldEntered({field: "ocr_scan", value: "", validated: true}))
    };
};

const mapStateToProps = state => {
    return {
        lastName: state.registerWizard.fields.lastName,
        reward: state.registerWizard.rewards.lastName,
        lang: state.application.language
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LastName));