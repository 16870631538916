import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import * as stateActions from "../../../../../store/actions";
import {MINIMUM_DEPOSIT_VALUE} from "../../../../Deposit/Screens/Amount";

class AmountButtons extends Component {


    constructor(props) {
        super(props);

        let steps = typeof window.config !== "undefined" && typeof window.config.DEPOSIT_PRESETS !== "undefined" ? window.config.DEPOSIT_PRESETS.split('/') : "";
        if (steps.length !== 6) {
            steps = [60,120,150,250,350,450];
        }

        let bonusMin = props.minBonus < MINIMUM_DEPOSIT_VALUE ? MINIMUM_DEPOSIT_VALUE : props.minBonus;
        let bonusMax = props.maxBonus;
        let minUsed = bonusMin === 0 || bonusMin === false;
        let maxUsed = bonusMax === 0 || bonusMax === false;

        if (bonusMin || bonusMax) {

            steps.forEach((val,key) => {

                if(!minUsed && val >= bonusMin ) {
                    val = bonusMin;
                    minUsed = true;
                }

                if (!maxUsed && val >= bonusMax && minUsed) {
                    val = bonusMax;
                    maxUsed = true
                }

                if (key === 5) {
                if (!minUsed) {
                    if (bonusMin > 0) {
                        val = bonusMin;
                    }
                    } else {
                        if (!maxUsed) {
                            if (bonusMax > 0 ) {
                            val = bonusMax;
                        }
                        }
                    }

                }
                steps[key]= val;
            });
        }
        this.amounts = steps;
    }

    pushHandler(amount, event) {
        amount = Number.parseInt(amount, 10);
        this.props.updateAmount(amount);
    }

    getButton(id) {
        let amount = Number.parseInt(this.amounts[id], 10);

        if (!(amount > 0)) {
            return null;
        }
        // console.log(amount,this.amounts)
        // if (!this.amounts.includes(amount)) {
        //     return null;
        // }

        let inputAmount = Number.parseInt(this.props.withdrawals.amount, 10);

        let buttonClass = ["w-100"];
        if (inputAmount === amount) {
            buttonClass.push("selected");
        }

        return (
            <div className="align-self-center text-center w-100 buttonContainer">
                <Button className={ buttonClass.join(" ") } color="success" outline onClick={ this.pushHandler.bind(this, amount) }>
                    { amount }
                    {/*{amount === this.props.minBonus && (<span className={"bonus-button-label"}>Min Bonus</span> )}*/}
                    {amount === this.props.maxBonus  && this.props.minBonus  !== this.props.maxBonus && (<span className={"bonus-button-label"}>Max Bonus</span> )}
                </Button>
            </div>
        );
    }

    render() {



        return (
            <div className={"buttonContainer"}>
                <div className="d-flex">
                    { this.getButton(0) }
                    { this.getButton(1) }
                    { this.getButton(2) }
                </div>
                <div className="d-flex">
                    { this.getButton(3) }
                    { this.getButton(4) }
                    { this.getButton(5) }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setAmount: (amount) => dispatch(stateActions.newWithdrawalAmount(amount)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AmountButtons);