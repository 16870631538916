export const lottoTokenUrl = process.env.CFW.appApiUrl;

const lottoStaging = {
	wsUrl: "https://stagingbt.winner.ro",
	wsPath: "/api/v1/tpws",
	wsProtocol: "sio1",
	auth: "https://stagingbt.winner.ro/api/v1/auth/b2b",

	createTicket: "https://stagingbt.winner.ro/api/v1/loto-online-ticket",
	listTickets: "https://stagingbt.winner.ro/api/v1/loto-online-tickets",
	getTicket: "https://stagingbt.winner.ro/api/v1/loto-online-ticket/",
	reserveTicket: "https://stagingbt.winner.ro/api/v1/loto-ticket-reserve",

	publicOffer: "https://stagingbt.winner.ro/api/v1/report/public-offer",
	publicSystems: "https://stagingbt.winner.ro/api/v1/report/public-systems",
	publicResults: "https://stagingbt.winner.ro/api/v1/report/public-results",

	checkTicket: "https://stagingbt.winner.ro/api/v1/loto-ticket-check",

	lottoApi: process.env.CFW.betsApiUrl + "/lottoapi",
	betsApi: process.env.CFW.betsApiUrl + "/betsapi",
	lottoApiWS: process.env.CFW.betsApiUrl,
	tenantId: window.config.lottoTenantId,
};

const lottoProduction = {
	wsUrl: "https://lotobt.winner.ro",
	wsPath: "/api/v1/tpws",
	wsProtocol: "sio1",
	auth: "https://lotobt.winner.ro/api/v1/auth/b2b",

	createTicket: "https://lotobt.winner.ro/api/v1/loto-online-ticket",
	listTickets: "https://lotobt.winner.ro/api/v1/loto-online-tickets",
	getTicket: "https://lotobt.winner.ro/api/v1/loto-online-ticket/",
	reserveTicket: "https://lotobt.winner.ro/api/v1/loto-ticket-reserve",

	publicOffer: "https://lotobt.winner.ro/api/v1/report/public-offer",
	publicSystems: "https://lotobt.winner.ro/api/v1/report/public-systems",
	publicResults: "https://lotobt.winner.ro/api/v1/report/public-results",

	checkTicket: "https://lotobt.winner.ro/api/v1/loto-ticket-check",

	lottoApi: process.env.CFW.betsApiUrl + "/lottoapi",
	betsApi: process.env.CFW.betsApiUrl + "/betsapi",
	lottoApiWS: process.env.CFW.betsApiUrl,
	tenantId: window.config.lottoTenantId,
};

let config = null;

if (window.config.environment === "staging") {
	config = lottoStaging;
} else {
	config = lottoProduction;
}

export const lottoConfig = config; // lottoProduction

