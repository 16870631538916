import React, { useEffect, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import * as PIXI from "pixi.js";
import { gsap, TimelineMax, TweenMax, Power3, Power2, Linear, Draggable } from "gsap/all";
import { PixiPlugin } from "gsap/PixiPlugin";

import LeftArrow from "../../assets/banners/leftArrow.svg";
import RightArrow from "../../assets/banners/rightArrow.svg";

import { arrayProxy, compareObjsByKeys, debug, shallowEqual } from "../../utils";
import Carousel from "./pixiObj";
//import Carousel from "../../components/PixiCarousel/pixiObj";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear, PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

const useStyles = makeStyles({
	root: {
		width: "100%",
		fontFamily: "OpenSans",
		backgroundColor: "#fff",
		userSelect: "none"
	},
	pb12: {
		paddingBottom: "14px"
	},
	sliderWrapper: {
		position: "relative",
		overflow: "visible"
	},
	pb20: {
		paddingBottom: "20px"
	},
	betBanner: {
		padding: "0",
		position: "absolute",
		bottom: "0",
		left: "0",
		right: "0",
		overflow: "hidden",
		pointerEvents: "none"
	},
	betBannerSm: {
		left: "calc(9% + 10px)",
		right: "calc(9% + 10px)",
		bottom: "10px"
	},
	betBannerPosition: {
		bottom: "-24px",
		paddingBottom: "10px",
		left: "0",
		right: "0"
	}
});

const noop = () => {};

const PixiCarousel = props => {
	const classes = useStyles(props);

	const { width, children, onClick } = props;

	//console.log("begin render", "width", width);

	const rootCreated = useCallback(node => {
		//console.log("root created", node);

		Carousel.setRootElement(node);
	}, []);

	useEffect(() => {
		// on unmount remove the banner completly
		return () => {
			Carousel.destroyApp(true);
		};
	}, []);

	useEffect(() => {
		//console.log("width", width, "children", children);

		let somethingToLoad = false;

		// build banner items
		const items = arrayProxy(
			React.Children.map(children, (c, i) => {
				return {
					webImage: c.props.webImage,
					mobileImage: c.props.mobileImage,
					blendColor: c.props.blendColor,
					eventName: c.props.eventName,
					timeout: c.props.timeout,
					index: i
				};
			})
		);

		// check if banner has the same children (images)
		let sameChildren = false;

		if (Carousel.items.length === items.length) {
			let sc = true;

			for (let i = 0; i < items.length; i++) {
				if (!shallowEqual(Carousel.items[i], items[i])) {
					sc = false;
					break;
				}
			}

			sameChildren = sc;
		}

		//console.log("sameChildren", sameChildren, "somethingToLoad", somethingToLoad);

		// cancel initialization after resource loading
		let cancelInit = false;

		// only refresh if something changed or something needs to load
		if (!sameChildren || somethingToLoad) {
			Carousel.destroyApp(false);

			items.forEach(i => {
				let r = false;
				
				if (window.config.platformType === 2) {
					debug("load mobile img", i.mobileImage);
					r = Carousel.loadResource("mobileimg_" + i.mobileImage, i.mobileImage);
					somethingToLoad = somethingToLoad || r;
				} else {
					debug("load desktop img", i.webImage);
					r = Carousel.loadResource("webimg_" + i.webImage, i.webImage);
					somethingToLoad = somethingToLoad || r;
				}
			});

			let r = Carousel.loadResource("right", RightArrow);
			somethingToLoad = somethingToLoad || r;
			r = Carousel.loadResource("left", LeftArrow);
			somethingToLoad = somethingToLoad || r;

			if (somethingToLoad) {
				Carousel.loader.load((loader, resources) => {
					if (!cancelInit) {
						Carousel.init(items, resources, width, onClick);
					} else {
						Carousel.setResources(items, resources);
					}
				});
			} else {
				Carousel.init(items, null, width, onClick);
			}
		}

		return () => {
			cancelInit = true;
		};
	}, [children]); // eslint-disable-line

	useEffect(() => {
		Carousel.resize(width);
	}, [width]);

	let clsRoot = classes.root;
	let clsSliderWrapper = classes.sliderWrapper;
	let clsBetBanner = `${classes.betBanner}`;
	if (width === "xs" || width === "sm") {
		//clsRoot = `${classes.root} ${classes.pb12}`;
		clsRoot = `${classes.root}`;
		clsBetBanner = `${classes.betBanner} ${classes.betBannerSm} ${classes.betBannerPosition}`;
	}

	// onClick={onClick ? () => onClick(Carousel.currentIndex) : () => noop}
	return (
		<div className={clsRoot}>
			<div className={clsSliderWrapper}>
				<div ref={rootCreated} className="banner-pixi">
					<div className="slider-pixi">
						<div className="slider-wrapper"></div>
						{/*<LeftArrow onClick={prevSlide} />
						<RightArrow onClick={nextSlide} />*/}
					</div>
				</div>
				{React.Children.map(children, (c, i) => {
					return (
						<div key={i} className={clsBetBanner}>
							{c}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default withWidth()(PixiCarousel);
