import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

import "./bets-lobby.scss";

import { styleToObj, checkComponentAvailability } from "../../../lobby/utils/functions";

import Meta from "../../../lobby/components/meta/meta";
import GameList from "../../../lobby/components/game_list/game_list";
import GameListPromoted from "../../../lobby/components/game_list_promoted/game_list_promoted";
import GameCollection from '../../../lobby/components/game_collection/game_collection';
import NavBar from '../../../lobby/components/nav-bar/nav-bar';
import JackpotCollection from '../../../lobby/components/jackpot_collection/jackpot_collection';
import ContentSlider from '../../../lobby/components/content_slider/content_slider';
import Labels from '../../../lobby/components/labels/labels';
import Providers from '../../../lobby/components/providers/providers';
import Breadcrumbs from "../../../lobby/components/breadcrumbs/breadcrumbs";
import ItemsLister from "../../../lobby/components/items-lister/items_lister";
import News from "../../../lobby/components/news/news";
import StartingSoon from "../../../lobby/components/starting_soon/starting_soon";
import TopLive from "../../../lobby/components/top_live/top_live";
import CompetitionPromotion from "../../../lobby/components/competition_promotion/competition_promotion";
import CompetitionPromotion2 from "../../../lobby/components/competition_promotion_2/competition_promotion_2";
import EventPromotion from "../../../lobby/components/event_promotion/event_promotion";
import UserPromotion from "../../../lobby/components/user_promotions/user_promotions";
import SimplePromotion from '../../../lobby/components/simple_promotion/simple_promotion';
import BannerComponent from "../../../lobby/components/banner_component/banner_component";
import EgtJackpot from "../../../lobby/components/egt_jackpot/egt_jackpot";
import EgtJackpotDetails from "../../../lobby/components/egt_jackpot_details/egt_jackpot_details";
import HappyHours from '../../../lobby/components/happy_hours/happy_hours';
import ClaimBonus from '../../../lobby/components/claim_bonus/claim_bonus';
import UseFreeSpins from '../../../lobby/components/use_free_spins/use_free_spins';
import WheelStatus from "../../../wheel/components/cta-lobby";
import TextComponent from "../../../lobby/components/text_component/text_component";
import TournamentsSlider from "../../../lobby/components/tournaments-slider/tournaments-slider";
import TournamentInfo from "../../../lobby/components/tournament-info/tournament-info";

const empty = {};

const Lobby = (props) => {
  let { url, lobbyPages, i18n, match, location, baseUrlPath } = props;

  if (!url) {

    url = location.pathname;
    if (url.indexOf(`${baseUrlPath}/pre-match/lobby/`) === 0) {
      url = url.replace(`${baseUrlPath}/pre-match/lobby/`, `${baseUrlPath}/lobby/`);
    } else if (url.indexOf(`${baseUrlPath}/live-match/lobby/`) === 0) {
      url = url.replace(`${baseUrlPath}/live-match/lobby/`, `${baseUrlPath}/lobby/`);
    }
  }

  console.log({ url, match, location });

  React.useEffect(() => {
    document.body.classList.add("in-lobby");

    return () => {
      document.body.classList.remove("in-lobby");
    }
  }, []); // eslint-disable-line

  let path = url;

  const components = [];
  let pageStyle = empty;

  React.useEffect(() => {
    if (lobbyPages && typeof lobbyPages[path] !== "undefined") {
      if (lobbyPages[path].type === "details") {
        window.scrollTo(0, 0);
      }
    }
  }, [lobbyPages, path]);

  if (lobbyPages && typeof lobbyPages[path] !== "undefined" && typeof lobbyPages[path].meta !== "undefined") {
    const lang = i18n.language;

    const settings = lobbyPages[path].settings ? lobbyPages[path].settings : null;
    if (settings && settings.meta && Object.keys(settings.meta).length > 0) {
      components.push(<Meta key="meta" meta={settings.meta} />);
    }

    lobbyPages[path].meta.forEach((data, index) => {
      if (!checkComponentAvailability(data)) return;

      if (data.type === "game_list") {
        components.push(<GameList key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "game_list_promoted") {
        components.push(<GameListPromoted key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "game_collection") {
        components.push(<GameCollection key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "nav_bar") {
        components.push(<NavBar key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "jackpot_collection") {
        components.push(<JackpotCollection key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "content_slider") {
        components.push(<ContentSlider key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "labels") {
        components.push(<Labels key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "providers") {
        components.push(<Providers key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "breadcrumbs") {
        components.push(<Breadcrumbs key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "items_lister") {
        components.push(<ItemsLister key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "news_list") {
        components.push(<News key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "starting_soon_list") {
        components.push(<StartingSoon key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "top_live_list") {
        components.push(<TopLive key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "competition_promotion_list") {
        components.push(<CompetitionPromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "competition_promotion_list_2") {
        components.push(<CompetitionPromotion2 key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "event_promotion_list") {
        components.push(<EventPromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "user_promotions_list") {
        components.push(<UserPromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "simple_promotion_list") {
        components.push(<SimplePromotion key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "banner_component") {
        components.push(<BannerComponent key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "egt_jackpot") {
        components.push(<EgtJackpot key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "egt_jackpot_details") {
        components.push(<EgtJackpotDetails key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "happy_hours") {
        components.push(<HappyHours key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "claim_bonus_list") {
        components.push(<ClaimBonus key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "use_free_spins_list") {
        components.push(<UseFreeSpins key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "wheel_status") {
        components.push(<WheelStatus key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "text_component") {
        components.push(<TextComponent key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "tournaments_slider") {
        components.push(<TournamentsSlider key={index} index={index} data={data} lang={lang} />);
      } else if (data.type === "tournament_info") {
        components.push(<TournamentInfo key={index} index={index} data={data} lang={lang} />);
      }
    });

    pageStyle = styleToObj(lobbyPages[path].settings && lobbyPages[path].settings.style ? lobbyPages[path].settings.style : empty);
  }

  return (
    <div className="bets-lobby ">
      <div className="lobby-component-wrapper" style={pageStyle}>
        {components}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    gamesLoaded: state.lobby.games.items.length !== 0,
    lobbyPages: state.config.lobbyPages,
    gameCollections: state.games.gameCollections,
    baseUrlPath: state.bets.app.basePath,
  }
};

const hideLoader = () => {
  return {
    type: "HIDE"
  };
}

const actionCreators = {
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(Lobby)));