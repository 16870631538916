import Phaser from "phaser";

class BgCover extends Phaser.GameObjects.Graphics
{
    constructor(scene) {
        super(scene, 0, 0);

        this.fillStyle(0x000000, 1);
        this.fillRect(0, 0, scene.cameras.main.width, scene.cameras.main.height);

        scene.add.existing(this);

        this.setAlpha(1);
        this.setDepth(5);
    }

    hide(callback = null) {
        this.scene.time.delayedCall(500, this.hideHandler.bind(this, callback), [], this);
    }

    hideHandler(callback) {
        // trigger an event to let scene know this image is ready
        this.scene.tweens.add({
            callbackScope: this,
            targets: this,
            duration: 1000,
            alpha: 0,
            ease: 'Cubic.easeIn',
            onComplete: () => {
                if (!callback) {
                    return;
                }

                callback.call();
            }
        });
    }
}

export default BgCover;