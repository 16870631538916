import * as actionTypes from '../actions/actionTypes';

let initialOktoState = {
    paymentCode: null,
    qrPayload: null,

    customer: {
        active_auto_exclusion: null,
        amount_can_deposit: null,
        account_verified: null,
        okto_terms_accepted: null,
        okto_terms_modified: null,
        okto_customer_date: null,

    },
}

const initialState = {
    locations: [],
    modal: false,
    queryParams: null,
    payment: {
        amount: 0,
        iframe: false
    },
    okto: {...initialOktoState}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.deposit.LOCATIONS_RECEIVED:
            return {
                ...state,
                locations: action.locations,
            };

        case actionTypes.deposit.OPEN_MODAL:
            return {
                ...state,
                modal: true,
            };

        case actionTypes.deposit.CLOSE_MODAL:
            return {
                ...state,
                modal: false,
                queryParams: null,
            };

        case actionTypes.deposit.DIR_QUERY_PARAMS:
            return {
                ...state,
                queryParams: action.queryParams,
            };
        case actionTypes.deposit.UPDATE_AMOUNT:
            return {
                ...state,
                payment: {
                    ...state.payment,
                    amount: action.amount
                }
            };
        case actionTypes.deposit.SAFECHARGE_RECEIVED:
            return {
                ...state,
                payment: {
                    ...state.payment,
                    iframe: action.link,
                }
            }
        case actionTypes.paymentCheckout.OKTO_CREATE_PAYMENT_CODE:
            return {
                ...state,
                loading: true,
                okto: {...initialOktoState}
            }
        case actionTypes.paymentCheckout.OKTO_RECEIVED_PAYMENT_CODE:
            return {
                ...state,
                loading: false,
                okto: {
                    ...initialOktoState,
                    ...action.data
                }
            }
        case actionTypes.paymentCheckout.OKTO_RECEIVED_CUSTOMER:

            return {
                ...state,
                loading: false,
                okto: {
                    ...initialOktoState,
                    customer: {
                        ...initialOktoState.customer,
                        ...action.data
                    }
                },
            }
        case actionTypes.paymentCheckout.OKTO_GET_CUSTOMER:
            return {
                ...state,
                loading: true,
                okto: {
                    ...initialOktoState,
                },
            }
        case actionTypes.paymentCheckout.OKTO_RESET:
            return {
                ...state,
                okto: {
                    ...initialOktoState,
                }
            }
        default:
            return state;
    }
};

export default reducer;
