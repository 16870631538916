const noop = () => { };

function Debugger(level, name) {
  let levelNo = 0;
  switch (level) {
    case "debug":
      levelNo = 5;
      break;
    case "info":
      levelNo = 4;
      break;
    case "warn":
      levelNo = 3;
      break;
    case "error":
      levelNo = 2;
      break;
    case "fatal":
      levelNo = 1;
      break;
    default:
      levelNo = 0;
  }

  if (process.env.NODE_ENV !== "development") {
    levelNo = 2;
  }

  let tag = name ? `${name}|` : '';

  const obj = {
    debug: levelNo >= 5 ? console.log.bind(console.log, `[${tag}debug]`) : noop,
    info: levelNo >= 4 ? console.log.bind(console.log, `[${tag}info]`) : noop,
    warn: levelNo >= 3 ? console.log.bind(console.log, `[${tag}warn]`) : noop,
    error: levelNo >= 2 ? console.error.bind(console.error, `[${tag}error]`) : noop,
    fatal: levelNo >= 1 ? console.error.bind(console.error, `[${tag}fatal]`) : noop,
  };
  return obj;
}

Debugger.DEBUG = "debug";
Debugger.INFO = "info";
Debugger.WARN = "warn";
Debugger.ERROR = "error";
Debugger.FATAL = "fatal";

export default Debugger;