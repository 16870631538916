import {delay, put, takeEvery} from "redux-saga/effects";
import {paymentCheckout} from "../actions/actionTypes";
import RequestManager from "../../utils/RequestManager";
import * as actions from "../../../src/store/actions";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";

function* callback_payCheckout(response,id) {
    try {
        if (!response.status) {
            throw new Error(`[ERROR] PAY_CHECKOUT missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] PAY_CHECKOUT message not sent!`);
        }
        delay(1000);
        yield put(actions.resetOneRequest(id));

    } catch (error) {
        yield put(actions.requestFailed(id))
    }

}

export function* payCheckoutSaga(action) {
    let pac = PlayerAbuseChecker.getInfo();
    let data = {
        ...pac,
        ...action.data
    }
    yield RequestManager.request('pay-checkout', {
        url: '/api/pay-checkout',
        method: 'post',
        data: data
    },callback_payCheckout);
}

export default function* watchVivaSaga() {
    yield takeEvery(paymentCheckout.REQUEST_VIVA_PAY_CHECKOUT, payCheckoutSaga);
}