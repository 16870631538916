import { takeEvery, put } from 'redux-saga/effects';
import axios from "axios";

import { teamsConstants } from "./../actions/constants";
import { teamsLoaded, teamsLoad } from './../actions/teams';
import { playerLoad } from './../actions/player';
import getStore from "../../store";

let winnerFunWalletAPIUrl = process.env.CFW.betsApiUrl;

if (process.env.NODE_ENV === "development") {
  //console.error("TODO: REMOVE NEXT LINE");
  //winnerFunWalletAPIUrl = "http://localhost:6001";
}

export async function requestEnroll(action) {
  const store = getStore();
  const state = store.getState();

  const headers = {};
  const cb = action.cb ? action.cb : () => { };
  const team_id = action.team_id ? action.team_id : "";

  if (!team_id) return cb(null);

  if (["user", "token"].indexOf(state.authentication.auth_type) !== -1 && state.authentication.access_token) {
    headers.Authorization = "Bearer " + state.authentication.access_token;
  }

  try {
    let { data } = await axios.get(winnerFunWalletAPIUrl + "/winner-fun/player/team/enroll/" + window.config.clientId + "/" + team_id, {
      headers: headers
    });
    if (data) {
      store.dispatch(playerLoad());
      store.dispatch(teamsLoad());
      return cb(data);
    }
  } catch (e) {
    console.log("[WF:requestTicketsSaga] err", e);
  }
  cb(null);
}

export async function requestLeave(action) {
  const store = getStore();
  const state = store.getState();

  const headers = {};
  const cb = action.cb ? action.cb : () => { };
  const team_id = action.team_id ? action.team_id : "";

  if (!team_id) return cb(null);

  if (["user", "token"].indexOf(state.authentication.auth_type) !== -1 && state.authentication.access_token) {
    headers.Authorization = "Bearer " + state.authentication.access_token;
  }

  try {
    let { data } = await axios.get(winnerFunWalletAPIUrl + "/winner-fun/player/team/leave/" + window.config.clientId + "/" + team_id, {
      headers: headers
    });
    if (data) {
      store.dispatch(playerLoad());
      store.dispatch(teamsLoad());
      return cb(data);
    }
  } catch (e) {
    console.log("[WF:requestTicketsSaga] err", e);
  }
  cb(null);
}

export async function requestCanLeave(action) {
  const store = getStore();
  const state = store.getState();

  const headers = {};
  const cb = action.cb ? action.cb : () => { };
  const team_id = action.team_id ? action.team_id : "";

  if (!team_id) return cb(null);

  if (["user", "token"].indexOf(state.authentication.auth_type) !== -1 && state.authentication.access_token) {
    headers.Authorization = "Bearer " + state.authentication.access_token;
  }

  try {
    let { data } = await axios.get(winnerFunWalletAPIUrl + "/winner-fun/player/team/can-leave/" + window.config.clientId + "/" + team_id, {
      headers: headers
    });
    if (data) {
      return cb(data);
    }
  } catch (e) {
    console.log("[WF:requestTicketsSaga] err", e);
  }
  cb(null);
}

export async function requestTeamInfo(action) {
  const store = getStore();
  const state = store.getState();

  const headers = {};
  const cb = action.cb ? action.cb : () => { };
  const team_id = action.team_id ? action.team_id : "";

  if (!team_id) return cb(null);

  if (["user", "token"].indexOf(state.authentication.auth_type) !== -1 && state.authentication.access_token) {
    headers.Authorization = "Bearer " + state.authentication.access_token;
  }

  try {
    let { data } = await axios.get(winnerFunWalletAPIUrl + "/winner-fun/stats/teams/" + window.config.clientId + "/" + team_id, {
      headers: headers
    });
    if (data) {
      return cb(data);
    }
  } catch (e) {
    console.log("[WF:requestTicketsSaga] err", e);
  }
  cb(null);
}

export function* requestTeamsSaga() {
  const store = getStore();
  const state = store.getState();

  const headers = {};
  try {

    if (["user", "token"].indexOf(state.authentication.auth_type) !== -1 && state.authentication.access_token) {
      headers.Authorization = "Bearer " + state.authentication.access_token;
    }

    const response = yield axios.get(winnerFunWalletAPIUrl + "/winner-fun/stats/teams/" + window.config.clientId, {
      headers: headers
    });

    if (!response || !response.data || !response.data.teams) {
      throw new Error(`[ERROR] Wallet response is empty!`);
    }

    yield put(teamsLoaded(response.data.teams));
  } catch (error) {
    console.log(error);
    yield put(teamsLoaded([]));
    return;
  }
}

export default function* templateInit() {
  yield takeEvery(teamsConstants.LOAD, requestTeamsSaga);
}
