import BetsTicketsOpenedModule from "../bets/views/MyTickets/OpenedModule";
import BetsTicketsSettledModule from "../bets/views/MyTickets/SettledModule";
import BetsTicketsReservedModule from "../bets/views/MyTickets/ReservedModule";

//import VirtualTicketsOpenedModule from "../components/VirtualGames/components/Ticket/OpenedModule";
//import VirtualTicketsSettledModule from "../components/VirtualGames/components/Ticket/SettledModule";

import VirtualsTicketsOpenedModule from "../virtuals/components/Ticket/OpenedModule";
import VirtualsTicketsSettledModule from "../virtuals/components/Ticket/SettledModule";

import LottoTicketsOpenedModule from "../lotto/views/MyTickets/OpenedModule";
import LottoTicketsSettledModule from "../lotto/views/MyTickets/SettledModule";
import LottoTicketsReservedModule from "../lotto/views/MyTickets/ReservedModule";

import WinnerFunTicketsOpenedModule from "../bets/views/MyWinnerFunTickets/OpenedModule";
import WinnerFunTicketsSettledModule from "../bets/views/MyWinnerFunTickets/SettledModule";
import WinnerFunTicketsReservedModule from "../bets/views/MyWinnerFunTickets/ReservedModule";

let modules = {
  opened: [],
  settled: [],
  reserved: [],
  options: {
    theme:
      window.config && window.config.nsoftEnabled === "1" && window.config.betsEnabled !== "1"
        ? "nsoft"
        : "bets"
  }
};

function sortByPriority(a, b) {
  if (typeof a["priority"] !== "undefined" && typeof b["priority"] !== "undefined") {
    return b["priority"] - a["priority"];
  }

  return 0;
}

const registerTicketsModule = newModule => {
  if (typeof newModule["opened"] !== "undefined") {
    modules.opened.push(newModule["opened"]);
    modules.opened.sort(sortByPriority);
  }

  if (typeof newModule["settled"] !== "undefined") {
    modules.settled.push(newModule["settled"]);
    modules.settled.sort(sortByPriority);
  }

  if (typeof newModule["reserved"] !== "undefined") {
    modules.reserved.push(newModule["reserved"]);
    modules.reserved.sort(sortByPriority);
  }
};

const getTicketsModules = () => modules;

if (window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")) {
  registerTicketsModule({
    opened: {
      product: "Sports",
      icon: "some_icon.png",
      priority: 100,
      component: BetsTicketsOpenedModule
    },
    settled: {
      product: "Sports",
      icon: "some_icon.png",
      priority: 100,
      component: BetsTicketsSettledModule
    },
    reserved: {
      product: "Sports",
      icon: "some_icon.png",
      priority: 100,
      component: BetsTicketsReservedModule
    }
  });
}

if (window.config && (window.config.winnerFunEnabled === "1" || window.config.winnerFunEnabled === "1")) {
  registerTicketsModule({
    opened: {
      product: "Winner Fun",
      icon: "some_icon.png",
      priority: 100,
      component: WinnerFunTicketsOpenedModule
    },
    settled: {
      product: "Winner Fun",
      icon: "some_icon.png",
      priority: 100,
      component: WinnerFunTicketsSettledModule
    },
    reserved: {
      product: "Winner Fun",
      icon: "some_icon.png",
      priority: 100,
      component: WinnerFunTicketsReservedModule
    }
  });
}

if (window.config && window.config.lottoEnabled === "1") {
  registerTicketsModule({
    opened: {
      product: "Lotto",
      icon: "some_icon.png",
      priority: 100,
      component: LottoTicketsOpenedModule
    },
    settled: {
      product: "Lotto",
      icon: "some_icon.png",
      priority: 100,
      component: LottoTicketsSettledModule
    },
    reserved: {
      product: "Lotto",
      icon: "some_icon.png",
      priority: 100,
      component: LottoTicketsReservedModule
    }
  });
}

if (window.config && window.config.virtualsEnabled === "1") {
  registerTicketsModule({
    opened: {
      product: "Virtuals",
      icon: "some_icon.png",
      priority: 100,
      component: VirtualsTicketsOpenedModule
    },
    settled: {
      product: "Virtuals",
      icon: "some_icon.png",
      priority: 100,
      component: VirtualsTicketsSettledModule
    }
  });
}

export { registerTicketsModule, getTicketsModules };

/*
t("Sports")
t("Lotto")
t("Virtuals")
*/
