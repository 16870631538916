import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Icons from "../../../../../assets/Icons/Icons";
import EmptyList from "./EmptyList";
import SingleTransaction from './SingleTransaction/SingleTransaction';

const STATUS_PENDING_APPROVAL = 1;
const TYPE_DEPOSIT_CASH = 1;
const TYPE_DEPOSIT_SAFECHARGE = 24;
const TYPE_DEPOSIT_VIVA = 53;

class DepositsAndWithdrawals extends Component {
    constructor(props) {
        super(props);

        this.transactionStatus = {
            0: { "label": "unknown", "color": "unknown" },
            1: { "label": "pending approval", "color": "pending" },
            2: { "label": "ready for cash-in", "color": "success" },
            3: { "label": "failed", "color": "failed" },
            4: { "label": "collected", "color": "success" },
            5: { "label": "cancelled", "color": "failed" },
        };

        this.icons = {
            "withdrawals": Icons.get("withdrawCoin", "withdrawCoin"),
            "deposits": Icons.get("depositCoin", "depositCoin"),
            "download": Icons.get("historyCsvDownload", "historyCsvDownload"),
            "loading": Icons.get("historyCsvGenerate", "historyCsvGenerate"),
        };

        this.transactionTypes = {
            0: "unknown",
            1: "deposit",
            2: "withdraw",
            10: "withdraw",
            11: "withdraw",
            12: "withdraw",
            24: "deposit"
        };

        this.selfType = 2;
    }

    createList() {
        let list = [];
        let pendingTransactionList = [];
        let allTransactionsList = [];

        if (!(this.props.transactions.depositsWithdrawals.length > 0)) {
            return <EmptyList />;
        }

        if (this.props.transactions.depositsWithdrawals !== null && this.props.transactions.depositsWithdrawals.length > 0) {

            pendingTransactionList = this.props.transactions.depositsWithdrawals.map((elem, index) => {
                if (elem.status === STATUS_PENDING_APPROVAL && (elem.type === TYPE_DEPOSIT_CASH || elem.type === TYPE_DEPOSIT_SAFECHARGE || elem.type === TYPE_DEPOSIT_VIVA)) {
                    return <SingleTransaction elem={elem} index={index} key={elem.id} />;
                } else {
                    return null;
                }
            });
            allTransactionsList = this.props.transactions.depositsWithdrawals.map((elem, index) => {
                if (elem.status !== STATUS_PENDING_APPROVAL) {
                    return <SingleTransaction elem={elem} index={index} key={elem.id} />;
                }
            });

            pendingTransactionList = pendingTransactionList.filter(el => (el !== null));
            allTransactionsList = allTransactionsList.filter(el => (el !== null));

            if (Array.isArray(pendingTransactionList) && pendingTransactionList.length > 0) {
                pendingTransactionList = (
                    <Fragment>
                        <h3 className="TransactionTitle container">{this.props.t("Pending")}</h3>
                        {pendingTransactionList}
                    </Fragment>
                );
            } else {
                pendingTransactionList = '';
            }
            if (Array.isArray(allTransactionsList) && allTransactionsList.length > 0) {
                allTransactionsList = (
                    <Fragment>
                        <h3 className="TransactionTitle container">{this.props.t("All")}</h3>
                        {allTransactionsList}
                    </Fragment>
                );
            } else {
                allTransactionsList = '';
            }

            list = <Fragment>{pendingTransactionList}{allTransactionsList}</Fragment>;
        } else if (Array.isArray(list) && list.length === 0) {
            return <EmptyList />
        }

        return (
            <ul className={"historyList"}>{list}</ul>
        );
    }

    downloadCsv(event) {
        event.nativeEvent.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        if (this.props.transactions.generatingCsv) {
            return false;
        }

        if (this.props.transactions.emptyCsv) {
            return false;
        }

        this.props.downloadCsv(this.selfType, event);

        return false;
    }

    render() {
        if (!this.props.transactions.depositsWithdrawals) {
            return undefined;
        }

        let dIcon = this.icons.download;
        let dIconClass = ["dIcon"];
        let dText = "Download history for last 90 days";
        if (this.props.transactions.generatingCsv) {
            dIcon = this.icons.loading;
            dText = "Please wait! Generating CSV file...";
            dIconClass.push("loading");
        }

        if (this.props.transactions.emptyCsv) {
            dText = "The 90 days report is empty. Please try again later!";
            dIcon = null;
        }

        return (
            <div className={"transactionsList"}>
                {this.createList()}
                <div className={"downloadCsv"}>
                    <a href={"/csv/download"} onClick={this.downloadCsv.bind(this)}>
                        <span className={dIconClass.join(" ")}>{dIcon}</span>
                        <span className={"dText"}>{this.props.t(dText)}</span>
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        transactions: state.transactions,
        wallet: state.wallet,
    }
};

export default withTranslation()(connect(mapStateToProps)(DepositsAndWithdrawals));