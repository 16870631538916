import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { ReactComponent as FlashImage } from "../../assets/flash.svg";
import {
  getBetsState,
  makeGetBets,
  makeGetSelectedBet,
  makeGetStateMatches
} from "../../store/selectors/betData";
import { appSetSelectedBet } from "../../store/actions/app";
import {
  sortArrayByKey,
  compareArraysByKeys,
  //sortArrayByKeyUKMarkets 
} from "../../utils";
import { buildBetId, getCommonMarkets, getHeaderMarkets } from "../../utils/betUtils";
import { formatBetTitle, formatOddName } from "../../utils/formatters";
import BetSearch from "../BetSearch";

import { debug, shallowEqual } from "../../utils";

const useStyles = makeStyles({
  selectButton: {
    borderRadius: "7px",
    background: "linear-gradient(90deg, #1F83E6 0%, #06D2BD 100%)",
    padding: "0 24px 0 8px",
    height: "26px",
    color: "#fff",
    lineHeight: "11px",
    fontSize: "11px",
    textAlign: "left",
    minWidth: "110px",
    position: "relative",
    marginRight: "10px",
    overflow: "hidden"
  },
  flashImage: {
    height: 16,
    width: 16,
    fill: "#fff",
    objectFit: "cover"
  },
  expandMore: {
    position: "absolute",
    top: "5px",
    right: "5px",
    color: "#fff",
    "&>svg": {
      height: 16,
      width: 16,
      verticalAlign: "middle"
    }
  },
  wp: {
    overflow: "hidden"
  },
  outcome: {
    flex: "1 0 auto",
    border: "1px solid transparent",
    position: "relative",
    maxWidth: "200px",
    borderRadius: "7px",
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "600",
    color: "#808EAB",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    minWidth: "0",
    "&:not(:first-child)": {
      marginLeft: "3px"
    }
  },
  big: {
    minWidth: "70px"
  },
  small: {
    minWidth: "48px"
  },
  w17: {
    width: "17px"
  },
  desktopWidth: {
    width: "calc(33.3333% - 2px)",
    fontSize: "13px",
    maxWidth: "none"
  }
});

const DEBUG_MEMO = false;

const BetsSelector = props => {
  const classes = useStyles(props);

  const {
    matchGroup,
    bets,
    groupSelectedBet,
    setSelectedBet,
    second,
    isSticky,
    columnIndex,
    stateMatches
  } = props;

  const isDesktop1280 = useMediaQuery("(min-width:1281px)");

  const [oddsGroup, setOddsGroup] = React.useState(null);

  const slideRoot = React.createRef();
  const [openMenu, setOpenMenu] = React.useState(false);

  const [betData, setBetData] = React.useState({
    markets: [],
    headerMarkets: [],
    matchBets: [],
    outcome: "",
    outcomeSelectedIndex: -1,
    betSelected: null,
    betShow: null,
    outcomes: [],
    outcomesTotal: 0,
    more: 0,
    itemClass: ""
  });

  React.useEffect(() => {
    if (typeof stateMatches === "undefined" || stateMatches === null || stateMatches.length === 0) {
      return;
    }

    const markets = getCommonMarkets(stateMatches);
    //sortArrayByKeyUKMarkets(markets, "mbPosition", "mbSpecialValue");
    const headerMarkets = getHeaderMarkets(markets, matchGroup);

    DEBUG_MEMO && debug("markets", markets, "headerMarkets", headerMarkets);

    let oddSelectedIndex = -1;
    let outcome = "";

    let betSelected = null;

    DEBUG_MEMO && debug("groupSelectedBet", groupSelectedBet, matchGroup, bets);

    if (groupSelectedBet !== null) {
      const bs = markets.find(([midBet, m]) => midBet === groupSelectedBet.midBet);
      if (bs) {
        betSelected = bs[1].bet;
        setOddsGroup(bs[1].bet);
      } else {
        DEBUG_MEMO && debug("Bet not found", groupSelectedBet, markets, stateMatches);
      }
    }

    if (betSelected === null) {
      if (headerMarkets.length > 0) {
        if (typeof headerMarkets[columnIndex] !== "undefined") {
          betSelected = headerMarkets[columnIndex][1].bet;
          setOddsGroup(headerMarkets[columnIndex][1].bet);
          setSelectedBet(
            matchGroup,
            formatBetTitle(betSelected, stateMatches[0], bets),
            headerMarkets[columnIndex][0]
          );
          DEBUG_MEMO &&
            debug(
              "set new group bet to",
              formatBetTitle(betSelected, stateMatches[0], bets),
              headerMarkets[columnIndex][0]
            );
        } else {
          DEBUG_MEMO && debug(`headmarket[${columnIndex}] not available`);
        }
      } else {
        DEBUG_MEMO && debug("no headmarket available");
      }
    }

    if (betSelected === null && (groupSelectedBet === null || groupSelectedBet.midBet !== "none")) {
      DEBUG_MEMO && debug(`setting group to none`);
      setSelectedBet(matchGroup, " ", "none");
    }

    const ns = {
      markets,
      headerMarkets,
      oddSelectedIndex,
      betSelected,
      outcome
    };

    setBetData(bd => ({ ...bd, ...ns }));
  }, [stateMatches, bets, groupSelectedBet, columnIndex]); // eslint-disable-line

  React.useEffect(() => {
    let outcomes = [];
    let outcomesTotal = 0;

    if (typeof oddsGroup !== "undefined" && oddsGroup !== null) {
      outcomes = [...oddsGroup.mbOutcomes];
      sortArrayByKey(outcomes, "mboPosition");
      outcomesTotal = outcomes.length;
    } else {
      //console.log(`oddsGroup is undefined`);
    }
    const more = outcomesTotal > 3 ? outcomesTotal - 3 : 0;

    const ns = {
      outcomes,
      outcomesTotal,
      itemClass: outcomes.length <= 2 ? classes.big : classes.small,
      more
    };

    setBetData(bd => ({ ...bd, ...ns }));
  }, [oddsGroup]); // eslint-disable-line

  /*
  React.useEffect(() => {
    if (slideRoot && slideRoot.current !== null) {
      const el = slideRoot.current;
      const parentContainerLeft = el.offsetLeft;
      const parentContainerWidth = el.clientWidth;
      if (parentContainerWidth) {
        const boxes = el.querySelectorAll(".myBetElement");
        if (boxes.length) {
          let containedBoxesCount = 0;
          boxes.forEach(box => {
            if (
              box.offsetLeft - parentContainerLeft + (box.clientWidth - 20) <=
              parentContainerWidth
            ) {
              containedBoxesCount += 1;
            }
          });
          
          if (betData.more !== betData.outcomes.length - containedBoxesCount) {
            const ns = {
              more: betData.outcomes.length - containedBoxesCount
            };
            setBetData(bd => ({ ...bd, ...ns }));
          }
        }
      }
    }
  }, [betData, slideRoot]);
  */

  if (typeof stateMatches === "undefined" || stateMatches === null || stateMatches.length === 0) {
    return null;
  }

  if (oddsGroup === null) {
    return null;
  }

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleSelect = index => {
    setOpenMenu(false);
    if (index === -1) {
      return;
    }
    setOddsGroup(betData.markets[index][1].bet);

    setSelectedBet(
      matchGroup,
      formatBetTitle(betData.markets[index][1].bet, stateMatches[0], bets),
      betData.markets[index][0]
    );
  };

  if (betData.markets.length === 0) {
    return null;
  }

  let content = null;
  //console.log("isSticky", isSticky, "second", second);
  if (isSticky || !second) {
    content = (
      <React.Fragment>
        <ButtonBase onClick={handleOpenMenu} className={classes.selectButton}>
          <FlashImage className={classes.flashImage} />{" "}
          {formatBetTitle(oddsGroup, stateMatches[0], bets)}
          <div className={classes.expandMore}>
            <ExpandMoreIcon />
          </div>
        </ButtonBase>
        {openMenu && (
          <BetSearch
            open={openMenu}
            markets={betData.markets}
            match={stateMatches[0]}
            selectedBetId={oddsGroup ? buildBetId(oddsGroup) : null}
            bets={bets}
            handleSelect={handleSelect}
          />
        )}
      </React.Fragment>
    );
  } else {
    content = (
      <div className="d-flex flex-row flex-nowrap justify-content-between">
        <div className={`w100 ${classes.wp}`} ref={slideRoot}>
          <div className="d-flex flex-row flex-nowrap align-items-center">
            {betData.outcomes.map((outcome, i) => (
              <div
                className={`${classes.outcome} ${betData.itemClass} ${isDesktop1280 ? classes.desktopWidth : ""
                  } myBetElement`}
                key={i}
              >
                {formatOddName(oddsGroup.idBet, outcome, stateMatches[0], bets)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return content;
};

BetsSelector.propType = {
  matchGroup: PropTypes.string.isRequired
};

const getBets = makeGetBets();
const getSelectedBet = makeGetSelectedBet();
const getStateMatches = makeGetStateMatches();

const mapStateToProps = (state, props) => {
  if (
    typeof props.matches === "undefined" ||
    props.matches === null ||
    props.matches.length === 0
  ) {
    return;
  }

  const bst = getBetsState(state);
  const bets = getBets(state, { match: props.matches[0] });

  return {
    betAbbr: bst.config.betAbbr,
    bets: bets,
    groupSelectedBet: getSelectedBet(state, props),
    stateMatches: getStateMatches(state, props)
  };
};

const actionCreators = {
  setSelectedBet: appSetSelectedBet
};

export default connect(
  mapStateToProps,
  actionCreators
)(
  React.memo(BetsSelector, (pp, np) => {
    if (pp["isSticky"] !== np["isSticky"]) {
      DEBUG_MEMO && debug(pp.matchGroup, "re-render isStickey", pp["isSticky"], np["isSticky"]);
      return false;
    }

    if (!shallowEqual(pp["groupSelectedBet"], np["groupSelectedBet"])) {
      DEBUG_MEMO &&
        debug(
          pp.matchGroup,
          "re-render groupSelectedBet",
          pp["groupSelectedBet"],
          np["groupSelectedBet"]
        );
      return false;
    }

    if (!shallowEqual(pp["betAbbr"], np["betAbbr"])) {
      DEBUG_MEMO && debug(pp.matchGroup, "re-render betAbbr", pp["betAbbr"], np["betAbbr"]);
      return false;
    }

    if (!shallowEqual(pp["bets"], np["bets"])) {
      DEBUG_MEMO && debug(pp.matchGroup, "re-render bets", pp["bets"], np["bets"]);
      return false;
    }

    /*
    if (!shallowEqual(pp["stateMatches"], np["stateMatches"])) {
      //debug(pp.matchGroup, "re-render stateMatches", pp["stateMatches"], np["stateMatches"]);
      return false;
    }
    */

    if (!compareArraysByKeys(pp["stateMatches"], np["stateMatches"], ["matchBets"])) {
      DEBUG_MEMO &&
        debug(
          pp.matchGroup,
          "re-render stateMatches (array)",
          pp["stateMatches"],
          np["stateMatches"]
        );
      return false;
    }

    return true;
  })
);
