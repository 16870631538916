import { setBaseRouterPath as setNSoftBaseRouterPath } from "./nsoft/utils/config";

// window.config.okto_enabled = '1';
// window.config.platformType = '1';
// window.config.vivaDesktopEnabled = '1';

let configFromServer = false;
if (process.env.NODE_ENV === "development" && !window.config) {
    console.log("Setting window.config manually!!!");

    window.config = {
        confirm_register_url: "https://winner-casino-test.play-online.com/token_email_validation",
        front_url: "https://test1-front-winner.play-online.com/",
        // okto_enabled: "1",
        vivaDesktopEnabled: "1",
        depositTicketEnabled: "1",
        brand_name_short: "Winner",
        NOG_license_number: "L1193408W001140",
        NOG_license_date_start: "01.12.2019",
        brand_name_full: "Winner",
        NOG_license_date_end: "30.11.2029",
        NOG_license_phone_number: "0759646088",
        NOG_license_email: "support@play-online.ro",
        brand_web_full: "winner-casino-test.play-online.com",
        brand_cs_email_address: "suport@play-online.ro ",
        chestionar_link: "NO_URL",
        support_phone_number: "TBD",
        brand_web_short: "winner-casino-test.play-online.com",
        company_cui: "Winn, CUI TBD",
        company_address: "TBD",
        reset_password_confirmation_url:
            "https://winner-casino-test.play-online.com/reset-password",
        MOBILE_MAIN_BAR_BG_COLOR: "#001d8a",
        MOBILE_MAIN_BAR_TEXT_NORMAL_COLOR: "white",
        MOBILE_MAIN_BAR_TEXT_SELECTED_COLOR: "#ffb400",
        MOBILE_SECONDARY_BAR_BG_COLOR: "#17214d",
        MOBILE_SECONDARY_BAR_TEXT_NORMAL_COLOR: "white",
        MOBILE_SECONDARY_BAR_TEXT_SELECTED_COLOR: "#FFB400",
        MOBILE_DELIMITER_ACCENT_COLOR: "#c31822",
        MOBILE_BRAND_LOGO: "/public/logos/MOBILE_BRAND_LOGO/logo.1588862145.png",
        MOBILE_SIDEMENU_GRADIENT: "linear-gradient(to top, #FFFFFF, #FFFFFF)",
        MOBILE_SIDEMENU_TEXT_MAIN_COLOR: "#182153",
        MOBILE_SIDEMENU_TEXT_SECODARY_COLOR: "#6f777f",
        MOBILE_SIDEMENU_LINEDELIMITER_COLOR: "#e8e9ea",
        MOBILE_HOMEPAGE_PROMO_BG_GRADIENT:
            "linear-gradient(344deg, #7f52db 0%, #3d62c1 NaN%, #262fcc NaN%, #296be8 NaN%, #4aaaff NaN%, white NaN%)",
        MOBILE_HOMEPAGE_PROMO_CONTAINER_COLOR: "#4965DA",
        DESKTOP_MAIN_BAR_BG_COLOR: "#001d8a",
        DESKTOP_MAIN_BAR_TEXT_NORMAL_COLOR: "white",
        DESKTOP_MAIN_BAR_TEXT_SELECTED_COLOR: "#ffb400",
        DESKTOP_SECONDARY_BAR_BG_COLOR: "white",
        DESKTOP_SECONDARY_BAR_TEXT_NORMAL_COLOR: "#17214d",
        DESKTOP_SECONDARY_BAR_TEXT_SELECTED_COLOR: "#303d75",
        DESKTOP_DELIMITER_ACCENT_COLOR: "#c31822",
        DESKTOP_BRAND_LOGO: "/public/logos/DESKTOP_BRAND_LOGO/logo.1588862672.png",
        DESKTOP_SIDEMENU_GRADIENT: "linear-gradient(to top, #FFFFFF, #FFFFFF)",
        DESKTOP_SIDEMENU_TEXT_MAIN_COLOR: "#182153",
        DESKTOP_SIDEMENU_TEXT_SECODARY_COLOR: "#6f777f",
        DESKTOP_SIDEMENU_LINEDELIMITER_COLOR: "#e8e9ea",
        DESKTOP_HOMEPAGE_PROMO_BG_GRADIENT:
            "linear-gradient(to top, #7f52db, #3d62c1, #262fcc, #296be8, #4aaaff)",
        DESKTOP_HOMEPAGE_PROMO_CONTAINER_COLOR: "#17214d",
        MOBILE_BUTTON_LOGIN_GRADIENT: "linear-gradient(79deg, #0430d5 0%, #0430d5 100%)",
        manifest_theme_icon: "#000000",
        manifest_background_color: "#FFFFFF",
        favicon: "/favicon.ico",
        DESKTOP_BUTTON_LOGIN_GRADIENT: "linear-gradient(79deg,#5015C7 ,#660FFF)",
        MOBILE_BUTTON_REGISTER_GRADIENT: "linear-gradient(79deg,#ffaa00,#ffc400)",
        DESKTOP_BUTTON_REGISTER_GRADIENT: "linear-gradient(79deg,#ffaa00,#ffc400)",
        email_header_bar_logo_img:
            "http://a8e5k4h8.stackpathcdn.com/public/img_logo_eliteslots@3x.png",
        email_header_bar_logo_h_align: "center",
        email_header_bar_bg_color: "#151D26",
        email_subject_bar_bg_color_accent: "#350989",
        email_subject_bar_text_color_accent: "#FFFFFF",
        email_subject_bar_bg_color_faded: "#151D26",
        email_subject_bar_text_color_faded: "#FFFFFF",
        email_subject_bar_bg_color_bonus: "#F20732",
        email_subject_bar_text_color_bonus: "#FFFFFF",
        email_subject_bar_bg_color_beneficial: "#0D6D09",
        email_subject_bar_text_color_beneficial: "#FFFFFF",
        email_subject_bar_bg_color_administrative: "#075DF2",
        email_subject_bar_text_color_administrative: "#FFFFFF",
        email_footer_bg_color: "#16202B",
        email_footer_text_color_contrast: "#DFDFDF",
        email_footer_text_color_faded: "#7B818A",
        email_body_text_color_title: "#350989",
        email_body_text_color_body_red: "#F20732",
        email_body_text_color_hyperlink: "#075DF2",
        email_body_text_color_body_gray_bold: "#767E8A",
        email_body_text_color_body_gray_normal: "#444E5D",
        email_body_text_color_body_black_normal: "#000000",
        email_img_banner_account_closed:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-account-closed@3x.png",
        email_img_banner_card:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-card@3x.png",
        email_img_banner_deposit:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-deposit@3x.png",
        email_img_banner_products:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-products@3x.jpg",
        email_img_banner_reset_password:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-reset-password@3x.png",
        email_img_banner_reset_password_confirmed:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-reset-password-confirmed@3x.png",
        email_img_banner_top_bonus_crown:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-top-bonus-crown@3x.jpg",
        email_img_banner_top_welcome:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-top-welcome@3x.jpg",
        email_img_banner_verify_identity_email:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-verify-identity-email@3x.png",
        email_img_banner_withdrawal:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-banner-withdrawal@3x.png",
        email_img_category_item_casino:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-category-item-casino@3x.png",
        email_img_category_item_casinolive:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-category-item-casinolive@3x.png",
        email_img_category_item_email:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-category-item-email@3x.png",
        email_img_category_item_idcard:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-category-item-idcard@3x.png",
        email_img_category_item_promo:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-category-item-promo@3x.png",
        email_img_category_item_sportsbook:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-category-item-sportsbook@3x.png",
        email_img_category_item_virtualsloto:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-category-item-virtualsloto@3x.png",
        email_img_icon_lock:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-icon-lock@3x.png",
        email_img_verify_email:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-verify-email@3x.png",
        email_img_verify_identity:
            "http://h2p9v4i4.stackpathcdn.com/public/email/standard/2020/05/05/img-verify-identity@3x.png",
        email_bg_color: "#151D26",
        email_body_bg_color: "#FFFFFF",
        email_button_text_color: "#FFFFFF",
        cdn_address: "y5i5c8x5.stackpathcdn.com",
        google_maps_api_key: "AIzaSyB24YGE7amzk9Zjt5SVEHiwcAv0sN9NFAA",
        cdn_games_path: "games",
        bets_enabled: "1",
        virtuals_enabled: "1",
        google_tag_manager: "GTM-NGXGNF6",
        account_preclosure_date: "7",
        page_contact: "customer-support/complaint-form",
        brand_URL: "https://winner-casino-test.play-online.com",
        page_product_sportsbook: "pariuri",
        page_promotions: "cazino/promotii",
        page_product_casino: "cazino",
        bets_licence_en:
            "ONJN decision no. 815 of 29.03.2019 regarding the LICENSING OF BETS IN A FIXED QUOTA",
        bets_licence_ro:
            "Decizia ONJN nr. 815 din 29.03.2019 privind LICENTIEREA PARIURILOR IN COTA FIXA",
        old_site: "/nsoft/prematch",
        is404: 0,
        config_url: "/js/brand.desktop.json",
        platformType: 1,
        betsEnabled: "1",
        virtualsEnabled: "1",
        environment: "staging",
        lottoEnabled: "1",
        clientId: 1,
    };
}

if (!window.config) {
    window.config = {};
}

window.config.statScoreEnabled = "1";
window.config.happyHourBetsEnabled = "1";
window.config.liveCasinoEnabled = "1";
window.config.useBonusEvaluation = "1";
window.config.cashoutEnabled = "1";
window.config.maintenanceModuleEnabled = "1";
window.config.winnerFunEnabled = "1";
window.config.lottoEnabled = "1";
window.config.lottoTenantId = "rYMCnTqSVlKmh83BoeLtIw";
window.config.vivaEnabled = "1";
window.config.vivaDesktopEnabled = "1";
window.config.lobbyEnabled = "1";

// if bets (Winner modern) is not enabled and nsoft (Winner classic) is enabled
// change nsoft route to "/pariuri"
const betsEnabled = window.config && window.config.betsEnabled === "1";
const nsoftEnabled = window.config && window.config.nsoftEnabled === "1";
if (nsoftEnabled && !betsEnabled) {
    setNSoftBaseRouterPath("/pariuri");
}

if (window.config.rootPaths && window.config.rootPaths && typeof window.config.rootPaths === "string") {// this is JSON; needs to be parsed
    try {
        window.config.rootPaths = window.config.rootPaths.replace(/&quot;/g, '"');
        window.config.rootPaths = JSON.parse(window.config.rootPaths)
    } catch (err) {/* */ }
}

