import { takeEvery } from "redux-saga/effects";
import openSocket from "socket.io-client";
import getStore from "../index";

import { appConstants } from "../constants";
import { getLiveCasino } from "../selectors/liveCasinoData";
import { appInitialized } from "../actions/app";
import { tablesStateIni, tablesStateUpdate } from "../actions/tables";
import config from "../../api/config";

let socket = null;

export const connect = () => {
  if (socket !== null) {
    return;
  }

  const query = {
    protocol: "sio1"
  };

  if (window.config && window.config.clientId) {
    query["tenantId"] = window.config.clientId;
  }

  socket = openSocket(config.wsUrl, {
    path: "/evolution/ws",
    forceNew: true,
    transports: ["websocket"],
    query
  });

  //console.log("app socket", socket);

  socket.on("connect", () => {
    //console.log("Evolution App socket connected");
  });

  socket.on("event", function (data) {
    //console.log("Evolution Socket event data", data);
  });

  socket.on("error", error => {
    console.log("Evolution Socket error", error);
  });

  socket.on("connect_error", error => {
    console.log("Evolution Socket connect error", error);
  });

  socket.on("state", data => {
    getStore().dispatch(tablesStateIni(data));
  });
  socket.on("state-update", data => {
    getStore().dispatch(tablesStateUpdate(data));
  });
};

export const appDisconnect = () => {
  if (socket !== null) {
    socket.disconnect();
    socket = null;
  }
};

function* appInitializeSaga() {
  const evo = getLiveCasino(getStore().getState());

  if (!evo.app.initialized) {
    yield connect();
    yield getStore().dispatch(appInitialized());
  }
}

function* appSubscribeSaga() {
  //console.log("subscribe to evolution events");
  if (socket !== null) {
    try {
      yield socket.emit("subscribe", "evolution");
    } catch (err) {
      //console.log("appSubscribeSaga", err, socket);
    }
  }
}

function* appUnsubscribeSaga() {
  //console.log("unsubscribe from evolution events");
  if (socket !== null) {
    try {
      yield socket.emit("unsubscribe", "evolution");
    } catch (err) {
      //console.log("appUnsubscribeSaga", err, socket);
    }
  }
}

export default function* watchAppSaga() {
  //console.log("watching evolution app");
  yield takeEvery(appConstants.INITIALIZE, appInitializeSaga);
  yield takeEvery(appConstants.SUBSCRIBE, appSubscribeSaga);
  yield takeEvery(appConstants.UNSUBSCRIBE, appUnsubscribeSaga);
}
