/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 17/01/2019
 * Time: 15:41
 */
import React, {Component} from 'react';
import  './ValueCurrency.scss';
import Utils from '../../../../../utils/Utils';

export const MAIN_BANKROLL = 1;
export const MAIN_BANKROLL_NC = 2;
export const NO_SMALL_FORMAT = 102;

const HIDE_DECIMALS = true;

class ValueCurrency extends Component {

    formatCurrency(number, currency/*, decimal*/) {
        // number = 100.69;
        // number = 100;
        let type;
        let locales = 'ro-RO';
        // let locales = 'ja-JP';
        if(isNaN(number)) number = 0;

        let minimumFractionDigits = 0;
        if (number < 100) {
            minimumFractionDigits = 2;
        }

        if (MAIN_BANKROLL_NC === this.props.type && number >= 100) {
            currency = undefined;
        }

        if (typeof currency !== "undefined") {
            type = {
                style: "currency",
                currency: Utils.formatCurrency(currency),
                minimumFractionDigits: minimumFractionDigits,
                maximumFractionDigits: 2,
            };
        }
        /*
            if (typeof decimal !== "undefined") {
                type.maximumFractionDigits = decimal;
            }
        */

        number = Number(number).toFixed(2);
        if (HIDE_DECIMALS && parseInt(number, 10) >= 100) {
            number = Math.floor(number);
        }

        let value = new Intl.NumberFormat(locales, type).format(number);

        if ( Utils.isInt(number) ) {

            // [WEB-323] Currency should be Lei (not LEI or RON)
            if (typeof currency !== "undefined") {
                return [value.replace(type.currency.toUpperCase(), type.currency)];
            } else {
                return [value];
            }
        } else {
            return this.injectStyle(value);
        }
    }

    injectStyle(formattedValue) {
        let comp = [];
        let currencyIndex = -1;
        let separatorLastPosition = -1;

        for (let i = 0; i < formattedValue.length; i++) {
            // check if it's not a number
            if (isNaN(formattedValue[i])) {
                // skip if it's a space;
                if (formattedValue[i] === ' ') {
                    continue;
                }

                // if it's not a separator, it means that we found the position of the currency;
                if (formattedValue[i] !== '.' && formattedValue[i] !== ',') {
                    // skip if we already have a currency index
                    if (currencyIndex !== -1)  continue;

                    currencyIndex = i;
                } else {
                    // we found a separator
                    separatorLastPosition = i;
                    // we don't really know what separator it is, so... put it in memory
                    // separator = formattedValue[i];
                }
            }
        }

        let decimals;
        let number;

        // let old = formattedValue;
        if (currencyIndex < separatorLastPosition) {
            decimals = formattedValue.substring(separatorLastPosition);
            number = formattedValue.substring(0,separatorLastPosition);
            if (this.props.type === MAIN_BANKROLL && number > 100) {
                comp.push(
                    <div className={"formatted"} key={Utils.generateNumericRandomId()}>
                        {number}
                    </div>
                );
            } else {
                if (this.props.type === MAIN_BANKROLL_NC && number >= 100) {
                    decimals = null;
                }

                comp.push(
                    <div className={"formatted"} key={Utils.generateNumericRandomId()}>
                        {number}<small>{decimals}</small>
                    </div>
                );
            }
        } else {
            decimals = formattedValue.substring(separatorLastPosition, currencyIndex - 1);
            number = formattedValue.substring(0,separatorLastPosition);
            let currency = formattedValue.substring(currencyIndex);
            if (this.props.type === MAIN_BANKROLL && number > 100) {
                comp.push(
                    <div className={"formatted"} key={Utils.generateNumericRandomId()}>
                        {number} {Utils.formatCurrency(currency)}
                    </div>
                );
            } else if (this.props.type === MAIN_BANKROLL_NC) {
                if (number >= 100) {
                    decimals = null;
                }

                comp.push(
                    <div className={"formatted"} key={Utils.generateNumericRandomId()}>
                        {number}<small>{decimals}</small>
                    </div>
                );
            } else {
                comp.push(
                    <div className={"formatted"} key={Utils.generateNumericRandomId()}>
                        {number}
                        <small>{decimals}</small>
                        &nbsp;{Utils.formatCurrency(currency)}
                    </div>
                );
            }
        }

        /** START REMOVE **/
        if (this.props.type === NO_SMALL_FORMAT) {
            decimals = formattedValue.substring(separatorLastPosition, currencyIndex - 1);
            number = formattedValue.substring(0,separatorLastPosition);
            if (0 === parseInt(number)) {
                decimals = null;
            }

            let currency = formattedValue.substring(currencyIndex);
            comp = [];
            comp.push(
                <div className={"formatted"} key={Utils.generateNumericRandomId()}>
                    {number}{decimals} {Utils.formatCurrency(currency)}
                </div>
            );
        }
        /** END REMOVE **/

        return comp;
    }

    getClassName() {
        let className=['currency-value'];

        // if (this.props.value < 100) {
        //     className.push('fractional-part-size-normal');
        // }

        if (typeof this.props.className !== "undefined") {
            className.push(this.props.className);
        }

        return className.join(' ');
    }

    render() {
        let comp = this.formatCurrency(this.props.value, this.props.currency);


        return (
            <div className={this.getClassName()}>
                {comp}
            </div>
        );
    }
}

export default (ValueCurrency);