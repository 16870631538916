import * as actionTypes from './actionTypes';
import BackendClient from "../../BackendClient";
import * as appActions from "./application";
import * as profileActions from "./profile";
import * as paths from './../../paths';

export const openUploadModal = (request) => {
    return dispatch => {
        dispatch({
            type: actionTypes.documents.OPEN_UPLOAD_MODAL,
            data: request,
        });

        dispatch(requestMaxFileSize());
    }
};

export const closeUploadModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.documents.CLOSE_UPLOAD_MODAL,
        });
    }
};

const formatFiles = (files) => {
    let formattedFiles = [];

    files.forEach((fileElement, index) => {
        // let fileElement = files[attribute];
        let file = {
            index: index,
            name: fileElement.name,
            size: fileElement.size,
            type: fileElement.type,
        };

        formattedFiles.push(file);
    });

    return formattedFiles;
};

export const requestDocumentsApproval = (files, type = 0) => {
    return dispatch => {
        dispatch({
            type: actionTypes.documents.REQUEST_FILES_APPROVAL,
            files: files,
        });

        let formattedFiles = formatFiles(files);

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/documents/check-files',
            method: 'post',
            data: {
                files: formattedFiles,
                docType: type
            }
        }).catch(e => {
            // console.log(`[CATCHED ERROT] => ${e}`);
        });
    }
};

export const responseDocumentsApproval = (jsonResponse) => {
    return dispatch => {
        if (!jsonResponse.hasOwnProperty("files")) {
            return;
        }

        let fileErrors = [];
        if (jsonResponse.hasOwnProperty("errors")) {
            fileErrors = jsonResponse["errors"];
        }

        let approvedFiles = null;
        if (jsonResponse["files"] && jsonResponse["files"].length > 0) {
            approvedFiles = jsonResponse["files"];
        }

        dispatch({
            type: actionTypes.documents.APPROVED_FILES,
            files: approvedFiles,
            errors: fileErrors,
        });
    }
};

export const documentReadyForUpload = (attributeName) => {
    return dispatch => {
        dispatch({
            type: actionTypes.documents.FILE_READY,
            attributeName: attributeName,
        })
    }
};

export const removeFileFromUpload = (attributeName) => {
    return dispatch => {
        dispatch({
            type: actionTypes.documents.FILE_REMOVE,
            attributeName: attributeName,
        });
    }
};

export const receivedDocumentsPageData = (jsonData) => {
    return dispatch => {
        dispatch({
            type: actionTypes.documents.DOCUMENTS_REQUEST_RECEIVED,
            docs: jsonData,
        });
    };
};

export const requestDocumentsUpload = () => {
    return dispatch => {
        let state = BackendClient.getStore().getState();
        let filesObject = state.documents.files;
        let modalData = state.documents.modalData;

        if (!(Object.keys(filesObject).length > 0)) {
            dispatch(closeUploadModal());
            let e = {
                toString() {
                    return "Error: No files for upload!";
                }
            };
            dispatch(appActions.contentError(e));
            return;
        }

        dispatch({
            type: actionTypes.documents.UPLOAD_PROGRESS,
            status: true,
        });

        let uploadForm = new FormData();
        uploadForm.append("type", modalData.type);
        uploadForm.append("id_row", modalData.id);
        Object.keys(filesObject).forEach((attrName) => {
            uploadForm.append("documents[]", filesObject[attrName]);
        });
        
        // POST the upload form
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/documents/upload',
            method: 'post',
            data: uploadForm,
        }).catch(e => {
            // console.log(`[CATCHED ERROT] => ${e}`);
        });
    }
};

export const receivedUploadResponse = () => {
    return dispatch => {
        let state = BackendClient.getStore().getState();
        let subMenus = state.application.page.submenus;

        dispatch({
            type: actionTypes.documents.UPLOAD_PROGRESS,
            status: false,
        });

        let documentsPage = subMenus.find((page) => {
            return page.code === "documents";
        });

        dispatch(profileActions.startProfilePageLoading());
        dispatch(appActions.loadSubpage(documentsPage, true));
    }
};

export const showUploadModalButtons = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.documents.SHOW_BUTTONS,
        });
    }
};

export const requestMaxFileSize = () => {
    return dispatch => {
        // POST the upload form
        let axios = BackendClient.getInstance();
        axios({
            url: paths.documents.MAX_SIZE,
            method: 'get'
        }).catch(e => {
            console.log(`[CATCHED ERROT] => ${e}`);
        });
    }
};

export const setMaxFileSize = (data) => {
    return dispatch => {
        let maxFileSize = '12Mb';
        if (data.hasOwnProperty('maxSize')) {
            maxFileSize = data['maxSize'];
        }

        dispatch({
            type: actionTypes.documents.MAX_FILE_SIZE,
            maxSize: maxFileSize,
        });
    }
};