import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from "../../../../store/actions";
import { withTranslation } from "react-i18next";
import Translate from "../../../../utils/Translate";
import { Animated } from "react-animated-css";

class PirateTreasure extends Component {
    constructor(props) {
        super(props);

        this.urlPath = window.config.front_url;
        this.bgDark = this.urlPath + '/img/coming-soon/comingsoon_background_dark.png';
        this.bgLight = this.urlPath + '/img/coming-soon/comingsoon_background_light.png';
        this.bgShadow = this.urlPath + '/img/coming-soon/comingsoon_dropshadow.png';
        this.bannerImage = null;
        this.bgTopBanner = this.urlPath + '/img/coming-soon/comingsoon_line_gold_large.png';
        this.bgBottomBanner = this.urlPath + '/img/coming-soon/comingsoon_line_gold_bottom.png';
        this.shadowTopBanner = this.urlPath + '/img/coming-soon/shadow-banner-top.png';
        this.shadowBottomBanner = this.urlPath + '/img/coming-soon/shadow-banner-bottom.png';
        this.bgComingSoon = this.urlPath + '/img/coming-soon/comingsoon_label.png';
        this.compass = this.urlPath + '/img/coming-soon/comingsoon_piratetreasure_compass.png';
        this.smallGoldenLine = this.urlPath + '/img/coming-soon/comingsoon_line_small.png';

        this.bgStyle = {
            backgroundImage: `url('${this.bgDark}')`,
        };

        this.headerStyle = {
            backgroundImage: `url('${this.bgLight}')`,
        };

        this.bodyStyle = {
            backgroundImage: `url('${this.bgShadow}')`,
        };

        this.topBannerStyle = {
            backgroundImage: `url('${this.bgTopBanner}')`,
        };

        this.bottomBannerStyle = {
            backgroundImage: `url('${this.bgBottomBanner}')`,
        };

        this.shadowTopBannerTopStyle = {
            backgroundImage: `url('${this.shadowTopBanner}')`,
        };

        this.shadowBottomBannerStyle = {
            backgroundImage: `url('${this.shadowBottomBanner}')`,
        };

        this.comingSoonStyle = {
            backgroundImage: `url('${this.bgComingSoon}')`,
        };

        this.titleTextStyle = {
            backgroundImage: `url('${this.smallGoldenLine}')`,
        };

        let imageName = 'piratetreasure.jpg';
        if (this.props.bannerImage) {
            imageName = this.props.bannerImage;
        }
        this.bannerImage = `${this.urlPath}/img/coming-soon/${imageName}`;
        this.bannerStyle = {
            backgroundImage: `url('${this.bannerImage}')`,
        };

        this.titleString = <Translate text={"Aici vei intra într-o aventură"} />;
        if (this.props.titleString) {
            this.titleString = this.props.titleString;
        }

        this.titleStringSecond = <Translate text={"pirate treasure"} />;
        if (this.props.titleStringSecond) {
            this.titleStringSecond = this.props.titleStringSecond;
        }

        this.showCompass = true;
        if (this.props.showCompass) {
            if ("off" === this.props.showCompass) {
                this.showCompass = false;
            }

            if ("empty" === this.props.showCompass) {
                this.showCompass = "empty"
            }
        }
    }

    getCompass() {
        if (!this.showCompass) {
            return null;
        }

        if ("empty" === this.showCompass) {
            return (
                <div className={"compass"}>&nbsp;</div>
            );
        }

        return (
            <div className={"compass"}>
                <img src={this.compass} />
            </div>
        );
    }

    render() {
        return (
            <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={200}>
                <div className={"specialComingSoon"} style={this.bgStyle}>
                    <div className={"header"} style={this.headerStyle}>
                        <div className={"text-message"} style={this.topBannerStyle}>
                            <p>{this.titleString}</p>
                        </div>
                        <div className={"banner"} style={this.bannerStyle}>
                            <div className={"baner-shadow-top"} style={this.shadowTopBannerTopStyle}>
                                <div className={"banner-shadow-bottom"} style={this.shadowBottomBannerStyle}>
                                    <div className={"coming-soon-label"}>
                                        <p style={this.comingSoonStyle}>{this.props.t("coming soon")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"bottom-space"} style={this.bottomBannerStyle}></div>
                    </div>
                    <div className={"body"} style={this.bodyStyle}>
                        <div className={"title-name"} style={this.titleTextStyle}>
                            <h1>{this.titleStringSecond}</h1>
                        </div>
                        <div className={"coming-soon-label"}>
                            <p style={this.comingSoonStyle}>{this.props.t("coming soon")}</p>
                        </div>
                        {this.getCompass()}
                    </div>
                </div>
            </Animated>
        );
    }
}

const mapStateToProps = state => {
    return {
        mainBanner: state.mainBanner,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        hideMainBanner: () => dispatch(stateActions.hideMainBanner()),
        showMainBanner: () => dispatch(stateActions.showMainBanner()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PirateTreasure));