import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./game_collection.scss";

import { getText, styleToObj, openItem, checkItemAvailability, itemThumbnail, getIndexById } from "../../utils/functions";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const GamesCollection = (props) => {
  const { data, i18n, source } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  if (items.length === 0) return null;

  const filteredItems = items.filter(item => (checkItemAvailability(item)));
  if (filteredItems.length === 0) return null;

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`component-games-collection component ${data.hide_margin_shadows ? 'hide-margins' : ''}`} style={styleToObj(data.style)}>
      <div className="before" style={data.before ? styleToObj(data.before.style) : empty}></div>

      <div className="cgl-super-header">
        <div className="category"
          style={styleToObj(data.category.style)}
        >
          {getText(data, `category.text.${lang}`, "")}
        </div>
      </div>

      <div className="cgl-header">
        <div className="title"
          style={styleToObj(data.title.style)}
        >
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          {filteredItems.length !== 0 && <span>({filteredItems.length})</span>}
          <LobbyArrow />
        </div>}
      </div>

      <div className="cgl-list" style={styleToObj(data.data_source_style)}>
        <div className={`cgl-grid`}>
          {filteredItems.slice(0, 6).map((item, i) => {
            let label = getText(item, `data.title.text.${lang}`, item.label ? item.label : "");

            const realIndex = getIndexById(items, item.id);
            const itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;

            return <div
              key={i}
              className={`cgl-item ${item.isEmpty ? "empty" : ""}`}
              style={itemStyle}
              data-type={item.type}
              data-item={JSON.stringify({ id: item.internalId, providerId: item.providerId, name: item.name ? item.name : item.label, gameId: item.gameId })}
              onClick={openItem}>
              <div className="outer">
                <div className="inner">
                  <div className="cgl-wrapper">
                    <div className="cgl-icon">
                      <img src={itemThumbnail(item)} alt="" />
                    </div>
                    <div className="cgl-name">
                      {label}
                    </div>
                  </div>
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>

      <div className="after" style={data.after ? styleToObj(data.after.style) : empty}></div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
  }
};

export default withTranslation()(connect(mapStateToProps)(GamesCollection));