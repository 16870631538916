import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { getWinnerAdvMatches, getWinnerPlusMatches } from "../../../../bets/store/selectors/betData";
import { getBaseRouterPath } from "../../../utils/config";

import { ReactComponent as WinnerAdv } from "../../../assets/icon-avantaj.svg";
import { ReactComponent as WinnerPlus } from "../../../assets/icon-winner-wo.svg";
import { ReactComponent as ExcelPlus } from "../../../assets/icon-exel-plus.svg";

const SpecialOffer = props => {
  const { matchesAdvantage, matchesPlus, history, onNavigate, t } = props;
  const basePath = getBaseRouterPath();

  const handleNavigate = (type) => () => {
    if (typeof onNavigate === "function") onNavigate();
    if (type === "advantage") {
      history.push(`${basePath}/prematch/winner-advantage`);
    } else if (type === "plus") {
      history.push(`${basePath}/prematch/winner-plus`);
    }
  };

  const buildList = () => {
    const list = [];

    const totalAdvantage = matchesAdvantage ? Object.keys(matchesAdvantage).length : 0;
    const totalPlus = matchesPlus ? Object.keys(matchesPlus).length : 0;

    if (totalAdvantage) {
      let title = "Winner Avantaj";

      if (typeof window.config.nsoftTheme !== "undefined" && window.config.nsoftTheme) {
        title = window.config.nsoftTheme + " Avantaj";
      }

      list.push(
        <div
          className={`navigation-item`}
          key={`to_tournament_advantage`}
          onClick={handleNavigate("advantage")}
        >
          <div className="icon special"><WinnerAdv /></div>
          <div className="label">{title}</div>
          <div className="match-count no-right">{totalAdvantage}</div>
        </div>
      );
    }

    if (totalPlus) {
      let title = "Winner Plus";
      let icon = <WinnerPlus />;
      if (typeof window.config.nsoftTheme !== "undefined" && window.config.nsoftTheme) {
        title = window.config.nsoftTheme + " Plus";
        icon = <ExcelPlus />;
      }

      list.push(
        <div
          className={`navigation-item`}
          key={`to_tournament_plus`}
          onClick={handleNavigate("plus")}
        >
          <div className="icon special">{icon}</div>
          <div className="label">{title}</div>
          <div className="match-count no-right">{totalPlus}</div>
        </div>
      );
    }

    return list;
  };

  let total = 0;
  total += matchesAdvantage ? Object.keys(matchesAdvantage).length : 0;
  total += matchesPlus ? Object.keys(matchesPlus).length : 0;

  if (total === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="navigation-group navigation-group-3">{t("Bonuses of the Day")}</div>
      {buildList()}
    </React.Fragment>)
};

const mapStateToProps = state => {
  return {
    matchesAdvantage: getWinnerAdvMatches(state),
    matchesPlus: getWinnerPlusMatches(state),
  };
};

const actionCreators = {};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(SpecialOffer)));
