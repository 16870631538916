import * as actionTypes from '../actions/actionTypes';

const initialState = {
    opened: false,
    wallet: null,
    alerts: {
        documents: [],
        withdrawals: [],
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.rightMenu.RIGHT_MENU_STATUS:
            return {
                ...state,
                opened: action.opened,
            };
        case actionTypes.rightMenu.RIGHT_MENU_WALLET_VALUES:
            return {
                ...state,
                wallet: action.wallet,
            };
        case actionTypes.rightMenu.RIGHT_MENU_DOCS_ALERTS:
            return {
                ...state,
                alerts: {
                    ...state.alerts,
                    documents: action.documents,
                }
            };
        case actionTypes.rightMenu.RESET:
            return {
                ...initialState,
            };
        case actionTypes.wallet.CORE_SET_BALANCE:
            //console.log("right menu wallet update", action.data, state.wallet);

            // set balance for given currency if found
            if (state.wallet && action.data.currency in state.wallet) {
                const w = state.wallet[action.data.currency];

                if ("main" in w) {
                    w["main"] = action.data.balance_amount / 100;

                    // core sends maybe the bonus balance
                    if (action.data.bonus_amount !== null) {
                        // TODO
                    }

                    return {
                        ...state,
                        wallet: {
                            ...state.wallet,
                            [action.data.currency]: w,
                        }
                    }
                }
            }

            return state;
        default:
            return state;
    }
};

export default reducer;
