import React from 'react';
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./index-mobile.scss";
import Timer from "../../components/timer";
import GiftImage from "../../assets/gift@2x.png";
import HappyHourMobileSpecial from "./index-mobile-special-prize";
import TermsDialog from "../../components/terms-dialog";
import HappyHoursDesktop from "./index-desktop";

import { getBetsState } from "../../../../bets/store/selectors/betData";

const HappyHours = (props) => {
  const { t, amount, liveBets, className } = props;

  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const data = Object.assign({}, props.data);
  data.date = moment(data.start_time * 1000).add(data.duration, "seconds");
  data.enabled = props.running;

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  }

  if (!data.enabled) return null;
  if (data.event_type !== "bets-live") return null;

  if (isDesktop) return <HappyHoursDesktop {...props} />;

  const genPrize = (className, prize) => {
    const isSpecialPrize = prize && !prize.amount && typeof prize.custom !== "undefined" ? true : false;
    if (!isSpecialPrize) {
      return (
        <div className={`${className} ${!prize ? 'hidden' : ''}`}>{prize ? prize.amount : 0} Lei</div>
      )
    }

    return (
      <div className={`hh-special-prize-front ${!prize ? 'hidden' : ''}`}>
        <img className="hh-sp-img" src={prize.custom.thumbnail} alt="" />
        <div className="hh-sp-title-front">{prize.custom.title}</div>
      </div>
    )
  };

  const hasSpecialPrize = typeof data.next_special_prize !== "undefined" && data.next_special_prize ? true : false;
  const isSpecialPrizeCurrent = !data.prize_current.amount && typeof data.prize_current.custom !== "undefined" ? true : false;
  if (isSpecialPrizeCurrent) {
    return <HappyHourMobileSpecial data={data} />;
  }

  let warning = 0;
  if (liveBets.length > 0) {
    if (data.prize_current.min_bet && amount < data.prize_current.min_bet) {
      warning = 1;
    }
    if (data.prize_current.max_bet && amount > data.prize_current.max_bet) {
      warning = 2;
    }
  }

  return (
    <div className={`bets-live-regular-wrapper ${warning ? 'warning' : ''}`}>
      <div className={`bets-live-regular ${className ? className : ''}`}>
        <div className="hh-header">
          <div className="header-tag-wrapper">
            Pariuri LIVE
        </div>
          <div className="title">
            <div>{data.name}</div>
            {hasSpecialPrize && <div className="q-mark " onClick={openDialog}>?</div>}
          </div>
        </div>
        <div className={`hh-prizes`}>
          <Timer date={data.date} />
          <div className="hh-prize-current">
            <div className="hh-flx">
              <div className="hh-img">
                <img src={GiftImage} alt="" />
              </div>
              <div className="hh-txt">
                {data.prize_current ? data.prize_current.amount : 0} Lei
            </div>
            </div>
            <div className="hh-bet">{t("Minimum Bet")}: {data.prize_current ? data.prize_current.min_bet : 0}</div>
          </div>
          {genPrize("hh-prize-after", data.prize_after)}
        </div>

      </div>
      {warning !== 0 && <div className="hh-warning">
        {warning === 1 && <div className="hh-ft">{t("Minimum bet for")} {data.name}: {data.prize_current.min_bet} Lei</div>}
        {warning === 2 && <div className="hh-ft">{t("Maximum bet for")} {data.name}: {data.prize_current.max_bet} Lei</div>}
      </div>}
      {hasSpecialPrize && <TermsDialog open={open} prize={data.next_special_prize.custom} name={data.name} onClose={closeDialog} />}
    </div>

  );
}

const mapStateToProps = state => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    amount: ct.amount,
    liveBets: ct.live.selected,
  };
};

export default withTranslation()(connect(mapStateToProps)(HappyHours));