/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import axios from "axios";

import { scoreConstants } from "../actions/constants";
import { scoreLoaded } from "../actions/score";

import getStore from "../../store";

let apiUrl = process.env.CFW.betsApiUrl + "/winner-fun";

if (process.env.NODE_ENV === "development") {
  //console.error("TODO: REMOVE NEXT LINE");
  //apiUrl = "http://localhost:6001" + "/winner-fun";;
}

function* scoreDataSaga(action) {
  const store = getStore();
  const state = store.getState();

  const headers = {};

  if (["user", "token"].indexOf(state.authentication.auth_type) !== -1 && state.authentication.access_token) {
    headers["Authorization"] = "Bearer " + state.authentication.access_token;
  }

  try {
    let { data } = yield axios.get(apiUrl + "/stats", {
      headers: headers
    });
    if (data) {
      yield put(scoreLoaded(data));
    }
  } catch (e) {
    console.log("[WF:playerDataSaga] err", e);
  }
}

export default function* scoreInit() {
  //console.log("[saga] scoreInit");
  yield takeEvery(scoreConstants.LOAD, scoreDataSaga);
}
