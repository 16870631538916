/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 28/11/2018
 * Time: 17:25
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import Icons from "../../../assets/Icons/Icons";
import { NavLink } from "react-router-dom";
import * as stateActions from "../../../store/actions/index";
import './SideMenu.scss';
import { withTranslation } from "react-i18next";
import Menus from "./../Menus/Menus";

class SideMenu extends Component {

    clickHandler(subpageRow) {

        this.props.onSubpageChanged(subpageRow);

        return true;
    }

    handleTermsAndConditions = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        let win = window.open(url, '_blank');
        if(win !== null){
            win.focus();
        }
    }
    handleBonusRules = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/bonusRules/Regulament%20General%20pentru%20Bonusuri.pdf';
        let win = window.open(url, '_blank');
        if (win !== null) {
            win.focus();
        }
    }
    getNavItems() {
        let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');
        // let menuPages = this.props.application.page.submenus.slice();
        //
        // let injectItems     = typeof this.props.injectItems === "undefined"? {} : this.props.injectItems;
        // Object.keys(injectItems).forEach((index) => {
        //         menuPages.splice(parseInt(index, 10),0,injectItems[index]);
        // });
        //
        // let comp = [];
        //
        // if (menuPages.length) {
        //     let menuArrow = Icons.get('profileMenuArrow', 'profile-menu-arrow');
        //     let baseUrl = `/${this.props.application.page.code}/`;
        //
        //     for (let i = 0; i < menuPages.length; i++ ) {
        //         let itemRow = menuPages[i];
        //
        //         comp.push(
        //             <li key={`menu-${itemRow.code}-${itemRow.name}`}>
        //                 <NavLink to={ baseUrl + itemRow.code } activeClassName="selected" onClick={ this.clickHandler.bind(this, itemRow) } className="row">
        //                     <span className="col-3 icon"> </span>
        //                     <span className="col-7 ptext">{ this.props.t(itemRow.name) }</span>
        //                     <span className="col-2 arrow">{menuArrow}</span>
        //                 </NavLink>
        //             </li>
        //         );
        //     }
        // }

        return (
            <ul className="">
                <li key={'1_v1'}>
                    <Menus pageType={"cs"} pageCode={"faq"} />
                </li>
                <li key={'1_v5'}>
                    <a className="row tconditions" onClick={() => this.handleBonusRules()}>
                        <span className="icon col-3"> </span>
                        <span className="ptext col-7">{this.props.t("Bonus rules")}</span>
                        <span className="col-2 arrow">{menuArrow}</span>
                    </a>
                </li>
                <li key={'1_v2'}>
                    <Menus pageType={"cs"} pageCode={"game-rules"} />
                </li>
                <li key={'1_v3'}>
                    <Menus pageType={"reset-password"} />
                </li>
                {/*<li key={'1_v4'}>*/}
                {/*    <Menus pageType={"cs"} pageCode={"complaint-form"} />*/}
                {/*</li>*/}
                <li key={'1_v5'}>
                    <a class="row tconditions" onClick={() => this.handleTermsAndConditions()}>
                        <span class="icon col-3"> </span>
                        <span class="ptext col-7">{this.props.t("terms & conditions")}</span>
                        <span className="col-2 arrow">{menuArrow}</span>
                    </a>
                </li>
                <li key={'1_v6'}>
                    <Menus pageType={"cs"} pageCode={"politicadeconfidentialitate"} />
                </li>
                <li key={'1_v7'}>
                    <Menus pageType={"cs"} pageCode={"responsible-gaming"} />
                </li>
                <li key={'1_v8'}>
                    <Menus pageType={"cs"} pageCode={"payment-methods"} />
                </li>
                <li key={'1_v9'}>
                    <Menus pageType={"cs"} pageCode={"cookies"} />
                </li>
                <li key={'1_v10'}>
                    <Menus pageType={"cs"} pageCode={"about-us"} />
                </li>
            </ul>
        );
    }


    render() {
        if (!this.props.application.page.hasOwnProperty("submenus")) {
            return (<Fragment />);
        }

        let comp = this.getNavItems();



        return (
            <div className="ProfileMenu row csSideMenu">
                <div className="pmHeader row">
                    <div className="col-2"> </div>
                    <div className="col-8">
                        <h1>{this.props.t(this.props.application.page.name)}</h1>
                    </div>
                    <div className="col-2"> </div>
                </div>
                {comp}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SideMenu));