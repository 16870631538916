import { authConstants } from './constants';

export const authInitialize = () => ({
	type: authConstants.INITIALIZE
});

export const authSuccess = details => ({
	type: authConstants.SUCCESS,
	details
});

export const liveAuthSuccess = details => ({
	type: authConstants.LIVE_SUCCESS,
	details
});

export const prematchAuthSuccess = details => ({
	type: authConstants.PREMATCH_SUCCESS,
	details
});

export const authClear = () => ({
	type: authConstants.CLEAR
});

export const authEndCycle = () => ({
	type: authConstants.END_CYCLE
});

export const authWinnerFunSuccess = details => ({
	type: authConstants.WINNER_FUN_SUCCESS,
	details
});
