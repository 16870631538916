import React, { Component } from 'react';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { ButtonBase } from '@material-ui/core';
import './Button.scss';

class Button extends Component {
    render () {

        return (
            <Auxiliary>
                <ButtonBase 
                    type={this.props.type} 
                    className={'default ' + (this.props.className ? this.props.className : '') + (this.props.full ? ' full' : '') }
                    onClick={this.props.click}
                    disabled={this.props.disabled}
                >
                    {this.props.leftIcon && <i className={'LeftIcon'}>{this.props.leftIcon}</i>}
                    {this.props.value}
                    {this.props.subtitle && <span>{this.props.subtitle}</span>}
                    {this.props.rightIcon && <i className={'RightIcon'}>{this.props.rightIcon}</i>}
                    
                </ButtonBase>
            </Auxiliary>
        );
    };
};

export default Button;