import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { ReactComponent as WinnerPlus } from "./assets/winnerPlus.svg";
import { ReactComponent as ExcelPlus } from "./assets/icon-exel-plus.svg";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    border: "1px solid #f50057",
    height: "16px",
    display: "flex",
    alignItems: "center",
    color: "#f50057",
    fontSize: "11px",
    fontWeight: "600",
    boxShadow: "0px 1px 3px rgba(0,0,0,.16)",
    borderRadius: "8px",
    paddingLeft: "6px",
    paddingRight: "8px",
    "& svg": {
      "max-height": 14,
      "max-width": 20,
    },
  },
  success: {
    border: "1px solid #338908",
    backgroundColor: "#f1ffea",
    color: "#338908"
  },
  icon: {
    marginRight: "5px"
  },
  text: {
    position: "relative",
    top: "-1px"
  }
});

const WinnerWarning = ({ number, total }) => {
  const classes = useStyles();

  let wpIcon = <WinnerPlus className={classes.icon} />;
  if (typeof window.config.nsoftTheme !== "undefined" && window.config.nsoftTheme) {
    wpIcon = <ExcelPlus className={classes.icon} />;
  }

  return (
    <div className={`${classes.root} ${number >= total ? classes.success : ''}`}>
      {wpIcon} <span className={classes.text}>{number}/{total}</span>
    </div>
  );
};



export default WinnerWarning;
