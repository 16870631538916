import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import * as stateActions from './../../../store/actions';
import './MainMenu.scss';
import MenuItem from './MenuItem/MenuItem';
import LeftMenuTrigger from "../../LeftMenu/LeftMenuTrigger/LeftMenuTrigger";
import AuthenticationMenu from './AuthenticationMenu/AuthenticationMenu';
import { VelocityComponent } from "velocity-react";
import Icons from "../../../assets/Icons/Icons";
import { withTranslation } from "react-i18next";
import Utils from "../../../utils/Utils";

const LOGO_WIDTH = 265;
const LARGE_LOCATION_WIDTH = 295;
const SMALL_LOCATION_WIDTH = 180;
const ACCOUNT_BUTTONS_WIDTH = 305;
const BIG_MENU = "big";
const MEDIUM_MENU = "medium";
const SMALL_MENU = "small";
const NO_MENU = "hide";
const BIG_ITEM_WIDTH = 114;
const SMALL_ITEM_WIDTH = 100;


class MainMenu extends Component {



    constructor(props) {
        super(props);

        this.allowRequest = true;

        this.urlPath = window.config.front_url;
        this.logoPath = window.location.protocol + "//" + window.config.cdn_address + window.config.DESKTOP_BRAND_LOGO;

        this.mainPageRow = null;
    }

    state = {
        type: NO_MENU,
        items: 0
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeMenu.bind(this));
        Utils.delayedFunction("loadMenu", this.resizeMenu.bind(this), 500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.specs !== this.props.specs) {
            this.resizeMenu();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeMenu);
    }

    resizeMenu() {

        if (typeof this.state === "undefined") {
            return Utils.delayedFunction("loadMenu", this.resizeMenu.bind(this), 500);
        }

        let freeSpace = window.innerWidth - LOGO_WIDTH - ACCOUNT_BUTTONS_WIDTH;
        let type = NO_MENU;

        if (freeSpace > 0) {
            const element = document.getElementById("primary-menu");
            if (element) {
                let items = (element.childNodes.length - (this.state.type !== NO_MENU ? 1 : 0));
                let largeSpaceNeeded = items * BIG_ITEM_WIDTH + LARGE_LOCATION_WIDTH;
                let mediumSpaceNeeded = items * BIG_ITEM_WIDTH + SMALL_LOCATION_WIDTH;
                let smallSpaceNeeded = items * SMALL_ITEM_WIDTH;


                if (freeSpace > largeSpaceNeeded) {
                    type = BIG_MENU;
                } else {
                    if (freeSpace > mediumSpaceNeeded) {
                        type = MEDIUM_MENU;
                    } else {
                        if (freeSpace > smallSpaceNeeded) {
                            type = SMALL_MENU;
                        }
                    }
                }
            } else {
                return;
            }

        }


        if (this.state.type !== type) {
            this.setState({
                ...this.state,
                type: type,
            })
        }


    }

    clickHandler() {
        if (null === this.mainPageRow) {
            return false;
        }

        // if (this.props.page.code === this.mainPageRow.code) {
        //     return false;
        // }

        this.props.onPageChanged(this.mainPageRow);

        return true;
    }

    getLocationPageItem() {
        let menuItems = Object.values(this.props.application.menu);
        let menuItem = menuItems.find((item) => {
            return "locations" === item.code;
        });

        return menuItem;
    }

    locationsHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        let depositItem = this.getLocationPageItem();

        if (!depositItem) {
            return false;
        }

        this.props.history.push(`/${depositItem.code}`);

        this.props.onPageChanged(depositItem);

        return false;
    }

    clearPageCode() {
        this.props.onPageChanged({});
    }

    render() {
        const items = [];

        let specs = {};
        if (undefined !== this.props.specs && null !== this.props.specs) {
            specs = this.props.specs;
        }

        if (Object.keys(specs).length > 1) {

            //specs["lotto"] = {
            //    code: "lotto"
            //};

            if (window.config && window.config.winnerFunEnabled === "1") {
                items.push(
                    <MenuItem row={{
                        available: "1",
                        code: "winner-fun",
                        description: "winner fun item",
                        icon: "winner-fun",
                        id: "500",
                        key: "winner-fun",
                        menuId: 0,
                        name: "Winner Fun",
                        requestPath: "",
                        special: 0,
                        submenus: [],
                        visible: "1"
                    }} key={"winner-fun"} />
                );
            }

            Object.keys(specs).forEach((key) => {
                let item = specs[key];

                if (item.code === 'virtuale') {
                    if (window.config && window.config.lottoEnabled === "1") {
                        items.push(
                            <MenuItem row={{
                                available: "1",
                                code: "lotto/home",
                                description: "lotto item",
                                icon: "lotto",
                                id: "3",
                                key: "lotto4",
                                menuId: 0,
                                name: "lotto",
                                requestPath: "",
                                special: 0,
                                submenus: [],
                                visible: "1"
                            }} key={"lotto4"} />
                        );
                    }
                }
                
                if (item.hasOwnProperty('special')) {
                    // console.log(item);
                    if ("1" === item.special) {
                        return;
                    }
                }

                if (item.hasOwnProperty('visible')) {
                    if ("1" !== item.visible) {
                        return;
                    }
                }

                item.key = key + item.id;
                if ("locations" === item.code) {


                    items.push(<div className={"divider"} key={`div_${key}`} />);
                    if (window.config && window.config.old_site && window.config.old_site !== "" && window.config.old_site !== "false") {
                        items.push(<div className="MenuItem" key="old_site">
                            <a href={window.config.old_site} title={this.props.t("Old site").toUpperCase()} target={"_blank"}>
                                {Icons.get("world", "world")}
                                <span className="align-top item-text">{this.props.t("Old site")}</span>
                            </a>
                        </div>)
                    }
                }

                if (item.code === 'pariuri') {
                    //console.log("keeey",item)
                    if (window.config && window.config.betsEnabled === "1" && window.config.brand_name_short === "Winner") {
                        items.push(
                            <MenuItem row={{
                                available: "1",
                                code: "pariuri",
                                description: "pariuri item",
                                icon: "betting",
                                id: "3",
                                key: "pariuri3",
                                menuId: 0,
                                name: "Sports",
                                requestPath: "",
                                special: 0,
                                submenus: [],
                                visible: "1"
                            }} key={"pariuri4"} />
                        );
                    } else if (window.config && window.config.nsoftEnabled === "1") {
                        items.push(
                            <MenuItem row={{
                                available: "1",
                                code: "pariuri/prematch",
                                description: "pariuri item",
                                icon: "betting",
                                id: "3",
                                key: "pariuri3",
                                menuId: 0,
                                name: "sport",
                                requestPath: "",
                                special: 0,
                                submenus: [],
                                visible: "1"
                            }} key={"pariuri4"} />
                        );
                        items.push(
                            <MenuItem row={{
                                available: "1",
                                code: "pariuri/live",
                                description: "pariuri item",
                                icon: "live",
                                id: "3",
                                key: "pariuri4",
                                menuId: 0,
                                name: "live betting",
                                requestPath: "",
                                special: 0,
                                submenus: [],
                                visible: "1"
                            }} key={"pariuri5"} />
                        );
                    } else {
                        items.push(
                            <MenuItem row={item} key={item.key} />
                        );
                    }
                } else if (item.code === 'virtuale') {
                    items.push(
                        <MenuItem row={item} key={item.key} />
                    );
                } else if (item.code === 'lotto' || item.code === 'loto') {
                    // noop
                } else {
                    const data = { ...item };
                    if (item.code === "cazino_live" && window.config.liveCasinoEnabled === "1") {
                        data.available = 1;
                    }

                    if(item.code === 'locations') {

                        if (window?.config?.okto_enabled === '1') {
                            item.name = 'Cash Deposit';
                            item.code = 'deposit-oktocash';
                            item.requestPath = null;
                            items.push(
                                <MenuItem row={data} key={item.key} />
                            );
                        }
                    } else {
                        items.push(
                            <MenuItem row={data} key={item.key} />
                        );
                    }


                }

                if ("cazino" === item.code) {
                    this.mainPageRow = item;
                }
            });
        }

        let casinoLogo = (<img src={window.location.protocol + "//" + window.config.cdn_address + window.config.DESKTOP_BRAND_LOGO} />);


        let url = "/cazino";
        if (window.config.lobbyEnabled === "1" && window.config.rootPaths && window.config.rootPaths.casino) {
            url = "/" + window.config.rootPaths.casino;
        }
        let style = {
            overflow: "initial"
        }
        let animatedStyle = { height: 86 }
        if (this.props.hideMenu) {
            style.overflow = 'hidden';
            animatedStyle.height = 0;
        }
        return (
            <VelocityComponent animation={animatedStyle} duration={1000} delay={0} runOnMount={true}>
                <div className="MainMenu row" style={style}>
                    <LeftMenuTrigger />
                    <div className="left-menu-space"> </div>
                    <div className="logoSupport">
                        <Link to={url} onClick={this.clickHandler.bind(this)} className="logo-link">
                            {casinoLogo}
                        </Link>
                    </div>
                    <div className={"topMenu " + this.state.type} id={"primary-menu"}>
                        {items}
                        {/*{locationsMenu}*/}
                    </div>
                    <AuthenticationMenu />
                </div>
            </VelocityComponent>
        );
    }
}

// export default MainMenu;
const mapStateToProps = state => {
    return {
        page: state.application.page,
        loading: state.application.loading,
        hideMenu: state.application.hideMenu,
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainMenu)));
