import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';

import { getBetsState, makeGetMatchesForBets } from "../../../bets/store/selectors/betData";
import TicketSystem from "./TicketSystem";
import SoldCard from "./SoldCard";
import {
  betsSlipClear,
  betsSlipComputeWin,
  betsSlipAcceptOddChange,
  betsSlipRemove,
  betsSlipSetTicketType,
  betsSlipSetCurrentTicket,
  betsSlipCopyCurrentTicket,
  betsSlipClearCopyStatus,
} from "../../../bets/store/actions/betsSlip";
import { betUtilsErrors, betUtilsConstants, getOutcomeValue } from "../../../bets/utils/betUtils";
import { comb, debug, formatMessage } from "../../../bets/utils";
import { formatTotalOddsValue } from "../../../bets/utils/formatters";
import WarningModal from "./WarningModal";
import WinnerWarning from "../../../bets/elements/WinnerWarning";

import BetLive from "../bet/live";
import BetPrematch from "../bet/prematch";
import VerifyTicket from "../verify-ticket";

import isEqual from "lodash/fp/isEqual";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles({
  root: {
    position: "relative",
    height: "100%"
  },
  tabs: {
    margin: "0 60px",
    "& .MuiTabs-indicator": {
      background: "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)"
    },
    borderBottom: "1px solid rgba(183, 205, 216, .35)",
    minHeight: "initial",
    "& .Mui-selected": {
      color: "#17214D"
    }
  },
  tab: {
    fontFamily: "OpenSans, 'Open Sans'",
    color: "#17214D",
    fontWeight: "bold",
    fontSize: "14px",
    paddingTop: "6px",
    paddingBottom: "6px",
    minHeight: "initial"
  },
  badge: {
    display: "inline-block",
    backgroundColor: "#17214D",
    color: "#fff",
    borderRadius: "50%",
    minWidth: "20px",
    fontWeight: "600",
    fontSize: "11px"
  },
  pb: {
    paddingBottom: "10px"
  },
  downButton: {
    position: "absolute",
    top: "2px",
    left: "20px",
    height: "29px",
    width: "29px",
    zIndex: 1,
    backgroundColor: "#FFF",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      width: "15px",
      height: "18px"
    }
  },
  deleteButton: {
    position: "absolute",
    top: "2px",
    right: "20px",
    height: "29px",
    width: "29px",
    zIndex: 1,
    backgroundColor: "#FF1A6F",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      width: "15px",
      height: "18px"
    }
  },
  topMenu: {
    backgroundColor: "#EEF0F1",
    padding: "0",
    position: "relative",
    "&.sticky": {
      position: "sticky",
      top: 0,
      zIndex: 1,
    }
  },
  sticky: {
    position: "sticky",
    top: 0,
  },
  betsWrapper: {
    backgroundColor: "#D2DBE7",
    padding: "14px 10px 0 10px",
    height: props => props.desktop ? "auto" : "calc(100% - 30px)",
    overflowY: "auto",
    "-webkit-overflow-scrolling": "touch"
  },
  anotherFuckingWrapper: {
    backgroundColor: "#D2DBE7",
    padding: 0,
    margin: 0
  },
  panelMargin: {
    margin: "0 4px 30px 0"
  },
  oddsWrapper: {
    marginBottom: "19px"
  },
  oddWrapper: {
    "&:not(:first-child)": {
      marginTop: "5px"
    }
  },
  unavailable: {
    //borderTopLeftRadius: 7,
    //borderTopRightRadius: 7,
    borderRadius: 7
  },
  unavailableTitle: {
    backgroundColor: "#FF7700",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    color: "#FFFFFF",
    fontSize: 9,
    fontWeight: 600,
    padding: "3px 15px"
  },
  unavailableContent: {
    borderLeft: "1px solid #FF7700",
    borderRight: "1px solid #FF7700",
    borderBottom: "1px solid #FF7700",
    backgroundColor: "#FFEAD8",
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    paddingBottom: "9px"
  },
  button: {
    width: "100%",
    padding: "9px",
    color: "#fff",
    borderRadius: 9,
    fontSize: 9,
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: "center",
    overflow: "hidden",
  },
  remove: {
    background: "linear-gradient(90deg, #F8991C 0%, #FF196E 100%)"
  },
  oddsChanged: {
    backgroundColor: "#FFF",
    borderRadius: 7,
    paddingBottom: "9px"
  },
  update: {
    background: "linear-gradient(90deg, #009ED9, #14259B, #009ED9, #14259B)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "400% 400%",
    //animation: "buttonAnim 10s ease infinite",
    width: "400%",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0"
  },
  textContent: {
    transform: "translate3d(0, 0, 0)",
  },
  centered: {
    width: "100%",
    padding: "0 20px"
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px",
    "& img": {
      width: "27px",
      height: "34px"
    }
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogActions: {
    padding: "60px 16px 40px",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dialogNoAction: {
    backgroundColor: "#16214c",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px"
  },
  dialogYesAction: {
    backgroundColor: "#fb1a6e",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px"
  },
  wrapperToggle: {
    margin: "0",
    padding: "0",
    position: "relative"
  },
  toggleError: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  toggleFull: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  toggleFullPreMatch: {
    backgroundColor: "#1F84E6"
  },
  toggleFullLive: {
    backgroundColor: "#ff196e"
  },
  toggleFullNone: {
    backgroundColor: "#000",
  },
  toggleText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "11px",
    paddingLeft: "10px",
    display: "inline-block",
    height: "36px",
    lineHeight: "36px",
    position: "relative",
    paddingRight: "10px",
    whiteSpace: "nowrap",
    minWidth: "120px",
    textAlign: "center"
  },
  togglePreMatch: {
    backgroundColor: "#1F84E6"
  },
  toggleLive: {
    backgroundColor: "#ff196e"
  },
  toggleNone: {
    backgroundColor: "#000",
  },
  toggleBorderSvg: {
    position: "absolute",
    height: "24px",
    width: "auto",
    right: "-65px"
  },
  errorSelection: {
    color: "#ff196e",
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "center",
    padding: "6px 0 4px"
  },
  errorSelectionLive: {
    backgroundColor: "#111"
  },
  errorSelectionLiveB: {
    borderBottom: "1px solid #4e333f"
  },
  errorSelectionPreMatch: {
    backgroundColor: "#111"
  },
  errorSelectionPreMatchB: {
    borderBottom: "1px solid #174e82"
  },
  winnerWrapper: {
    position: "absolute",
    top: "4px",
    right: "30px"
  },
  bgWhite: {
    backgroundColor: "#fff"
  },
  empty: {
    backgroundColor: "#fff",
    color: "#020203",
    fontSize: "13px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "9px 18px",
    borderRadius: "7px",
    marginBottom: "10px",
  }
});

let tid = 0;

const BetsFull = props => {
  const classes = useStyles(props);

  const {
    liveBets,
    liveMatches,
    prematchBets,
    prematchMatches,
    onToggle,
    computeWin,
    setTicketType,
    desktop,
    selectError,
    errorMessage,
    winnerPlus,
    winnerPlusNeededEvents,
    setCurrentTicket,
    currentTicket,
    copyCurrentTicket,
    clearCopyStatus,
    //hasBets,
    tickets,
    copyStatus,
    liveSystems,
    prematchSystems,
    bonusEvaluate,
    walletBonuses,
    t
  } = props;

  const hasBets = [];
  tickets.forEach(t => {
    hasBets.push({
      no: t.live.selected.length + t.prematch.selected.length,
      type: t.live.selected.length > 0 ? 'live' : 'prematch'
    });
  });

  //console.log("betsSlip props", props);

  const isDesktop = useMediaQuery("(min-width:848px)");
  const walletBonusesHash = {};
  if (walletBonuses) {
    walletBonuses.forEach(wb => {
      walletBonusesHash[wb.id] = wb.name
    });
  }

  const [value, setValue] = React.useState(0);
  const [openWarning, setOpenWarning] = React.useState({
    state: false,
    lastError: 0,
  });

  useEffect(() => {
    if (selectError > 2 && selectError !== openWarning.lastError) {
      setOpenWarning({
        state: true,
        lastError: selectError,
      });
    }
  }, [selectError, openWarning.lastError]);

  const handleWarningClose = () => {
    setOpenWarning(v => ({ ...v, state: false }));
  };

  useEffect(() => {
    computeWin();
  }, [liveMatches, prematchMatches]); // eslint-disable-line

  const clearStatus = () => {
    clearCopyStatus();
  }
  /*
    useEffect(() => {
      if (copyStatus.status) {
        clearTimeout(tid);
        tid = window.setTimeout(() => {
          clearCopyStatus();
        }, 5000)
      }
    }, [copyStatus.status, clearCopyStatus]);
  */
  const changeTicket = (i) => () => {
    setCurrentTicket(i);
  };
  const handleCopyTicket = () => {
    copyCurrentTicket();
  };

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setTicketType("single");
    } else {
      setTicketType("system");
    }
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  const buildBets = (mType, showFixed) => {
    let bets, matches;

    if (mType === "live") {
      bets = liveBets;
      matches = liveMatches;
    } else {
      bets = prematchBets;
      matches = prematchMatches;
    }

    let first = true;
    const rb = bets.map((b, i) => {
      let error = "";

      const cm = matches[b.idMatch];
      const bm = b.match;
      let bv;
      let rv = null;

      let rm = cm;
      if (!rm) {
        rm = bm;
        error = betUtilsErrors[betUtilsConstants.MATCH_NULL];
      } else {
        if (b.reoffered) {
          rv = b.reofferedOdd;
        }

        const cv = getOutcomeValue(cm, b.idMb, b.idMbo);
        if (cv < 0) {
          error = betUtilsErrors[cv];
        } else {
          bv = getOutcomeValue(bm, b.idMb, b.idMbo);
          if (bv < 0) {
            error = betUtilsErrors[bv];
          }
        }
      }

      const evaluateMessages = [];
      let evaluateValid = true;
      //if (bonusEvaluate && bonusEvaluate.success && bonusEvaluate.data && !bonusEvaluate.data.valid) {

      debug("bonusEvaluate", bonusEvaluate, "b", b);

      if (bonusEvaluate && bonusEvaluate.success && bonusEvaluate.data) {
        evaluateValid = bonusEvaluate.data.valid;
        bonusEvaluate.data.details && bonusEvaluate.data.details.forEach(ev => {
          if (ev.error && ev.error.type === "outcome") {
            if (b.idMbo === ev.error.idMbo) {
              const args = [...ev.error.args];
              const noWager = evaluateValid ? (walletBonusesHash[ev.id] ? " - " + t("Fara Rulaj") + ": " : t("Fara Rulaj") + ": ") : ": ";
              args[0] = (walletBonusesHash[ev.id] ? walletBonusesHash[ev.id] + noWager : noWager) + t(args[0]); // see if we have a translation for that message
              evaluateMessages.push(formatMessage(...args));
            }
          }
        });
      }

      debug("evaluateMessages", evaluateMessages);

      let card = null;
      if (mType === "live") {
        card = <BetLive
          key={`nSoft-${b.idMbo}`}
          showFixed={showFixed}
          mType={rm.mType}
          idMatch={b.idMatch}
          match={matches[b.idMatch]}
          oddSelected={b.idMbo}
          bv={bv}
          evaluateMessages={evaluateMessages}
          evaluateValid={evaluateValid}
          error={error}
          oddValueChanged={b.oddValueChanged}
        />;
      } else {
        card = <BetPrematch
          key={`nSoft-${b.idMbo}`}
          showFixed={showFixed}
          mType={rm.mType}
          idMatch={b.idMatch}
          match={matches[b.idMatch]}
          oddSelected={b.idMbo}
          bv={bv}
          evaluateMessages={evaluateMessages}
          evaluateValid={evaluateValid}
          error={error}
          oddValueChanged={b.oddValueChanged}
        />;
      }
      return card;
    });

    return (
      <React.Fragment>
        <div className="items clearfix">{rb}</div>
      </React.Fragment>);
  };

  const fact = n => {
    if (n === 0 || n === 1) {
      return 1;
    }

    for (let i = n - 1; i >= 1; i--) {
      n *= i;
    }

    return n;
  };

  const comb = (n, r) => {
    return fact(n) / (fact(r) * fact(n - r));
  };

  const showSystems = (bets, provider) => {
    const mType = "none";
    const rb = [];

    const systems = buildSystems(bets, provider);

    if (systems.length > 0) {
      rb.push(
        <div className="systems clearfix">
          <div className="systems-wrap ">
            {systems}
          </div>
        </div>
      );
    }
    return rb;
  };

  const buildSystems = (bets, provider) => {
    let numSystems = bets.length;
    let numFixedBets = bets.reduce((acc, b) => acc + (b.fixed ? 1 : 0), 0);
    let numAvailableSystems = numSystems - numFixedBets;

    let rs = [];

    for (let i = 2; i < numAvailableSystems; i++) {
      rs.push(
        <TicketSystem
          key={i}
          system={i}
          systemName={
            (numFixedBets > 0 ? numFixedBets + "F " : "") +
            i +
            "/" +
            numAvailableSystems +
            " (" +
            formatTotalOddsValue(comb(numAvailableSystems, i), true) +
            ")"
          }
          mType={"none"}
          provider={provider}
        />
      );
    }

    return rs;
  };

  let systems = null;
  if (liveBets.length + prematchBets.length > 0) {
    systems = showSystems([...liveBets, ...prematchBets], "digitain");
  }

  const handleBetSlipHeader = () => {
    const betSlipSidebar = document.getElementById("betslip-slider");
    if (betSlipSidebar) {
      betSlipSidebar.classList.toggle("expanded");
      document.body.classList.toggle("betslip-expanded");
    }
  }

  let header = null;
  if (desktop) {
    header = (
      <React.Fragment>
        {!isDesktop && <div className="bet-slip-header" onClick={handleBetSlipHeader}>
          <div><ExpandMoreIcon /></div>
        </div>}
        {window.config.hideTicketSearch !== "1" && <VerifyTicket className={"live"} />}
        <div className={classes.wrapperToggle}>
          <div
            className={`${classes.toggleFull} ${classes.toggleFullNone}`}
          ></div>
          <div className="top-wrapper">
            <div className="button-wrapper">
              <div
                className={`${classes.toggleText} ${liveBets.length > 0 ? classes.toggleLive : prematchBets.length > 0 ? classes.togglePreMatch : classes.toggleNone
                  }`}
              >
                <span>{liveBets.length > 0 ? t("LIVE BetSlip") : prematchBets.length > 0 ? t("Pre-match BetSlip") : t("BetSlip")}</span>
              </div>
              <div className="button-wrapper">
                {[1, 2, 3, 4, 5].map((v, i) => (
                  <div
                    key={i}
                    onClick={changeTicket(i)}
                    className={`ticket-no ${currentTicket === i ? 'active' : ''}  ${hasBets[i].no && currentTicket !== i && copyStatus.to - 1 !== i ? 'dot ' + hasBets[i].type : ''} ${copyStatus.status && copyStatus.to - 1 === i ? 'notif' : ''}`}>
                    {v}
                  </div>
                ))}
              </div>
            </div>
            <div className="ticket-copy" onClick={handleCopyTicket}>
              <FilterNoneIcon />
            </div>
          </div>
          {winnerPlus && <div className={classes.winnerWrapper}>
            <WinnerWarning number={prematchBets.length - 1} total={prematchBets.length + winnerPlusNeededEvents - 1} />
          </div>}
        </div>
        {selectError !== 0 && (
          <div className={`${classes.wrapperToggle} ${classes.mt0}`}>
            <div
              className={`${classes.errorSelection} ${liveBets.length > 0
                ? `${classes.errorSelectionLive} ${classes.errorSelectionLiveB}`
                : `${classes.errorSelectionPreMatch} ${classes.errorSelectionPreMatchB}`
                }`}
            >
              {liveBets.length > 0 && <span>{t("LIVE and Pre-match events cannot be mixed on the same betslip.")}</span>}
              {prematchBets.length > 0 && <span>{t("Pre-match and LIVE events cannot be mixed on the same betslip.")}</span>}
            </div>
          </div>
        )}
        {errorMessage !== "" && (
          <div className={`${classes.wrapperToggle} ${classes.mt0}`}>
            <div
              className={`${classes.errorSelection} ${liveBets.length > 0
                ? `${classes.errorSelectionLive} ${classes.errorSelectionLiveB}`
                : `${classes.errorSelectionPreMatch} ${classes.errorSelectionPreMatchB}`
                }`}
            >
              {errorMessage}
            </div>
          </div>
        )}
        <WarningModal open={openWarning.state} handleClose={handleWarningClose} type={liveBets.length > 0 ? 1 : 2} />
      </React.Fragment>
    );
  }

  return (
    <div className="betslip-full">
      {header}
      <div className="top-menu">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          className="tabs"
        >
          <Tab className="tab" label={
            <div>
              {t("Simple")}{" "}
              <span className="badge">{liveBets.length + prematchBets.length}</span>
            </div>
          } {...a11yProps(0)} />
          <Tab
            className="tab"
            label={
              <div>
                {t("System")}{" "}
                <span className="badge">{liveBets.length + prematchBets.length}</span>
              </div>
            }
            {...a11yProps(1)}
          />
        </Tabs>
        {copyStatus.status && <div className="copy-message" onClick={clearStatus}>
          {t("Ticket")} #{copyStatus.from} {t("was copied to")} #{copyStatus.to}
          <div className="clear-status-icon"><ClearIcon /></div>
        </div>}
      </div>
      <div className='bets-wrapper'>
        <div>
          <SwipeableViews index={value} onChangeIndex={handleChangeIndex} className={"nsoft-betslip-swipe"}>
            <div className="panel-margin simple">
              {liveBets.length > 0 && buildBets("live", false)}
              {prematchBets.length > 0 && buildBets("prematch", false)}
              {liveBets.length === 0 && prematchBets.length === 0 && <div className="items-empty">
                <div className="wrap">
                  <div className="items-empty-line"></div>
                  <div className="items-empty-text">
                    {t("The ticket is empty. Click on odds in order to bet")}
                  </div>
                  {/*<div className="items-empty-icon"><ArrowBackIosIcon /></div>*/}
                </div>
              </div>}
              <SoldCard exitFull={onToggle} />
            </div>

            <div className="panel-margin system">
              {liveBets.length > 0 && buildBets("live", false)}
              {prematchBets.length > 0 && buildBets("prematch", false)}
              {systems && systems.length > 0 && (<div>{systems}</div>)}
              {liveBets.length === 0 && prematchBets.length === 0 && <div className="items-empty">
                <div className="wrap">
                  <div className="items-empty-line"></div>
                  <div className="items-empty-text">
                    {t("The ticket is empty. Click on odds in order to bet")}
                  </div>
                  {/*<div className="items-empty-icon"><ArrowBackIosIcon /></div>*/}
                </div>
              </div>}
              <SoldCard exitFull={onToggle} />
            </div>
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
};

BetsFull.propTypes = {
  bets: PropTypes.array
};

BetsFull.defaultProps = {
  bets: []
};

const emptyArray = [];
const makeMapStateToProps = state => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  const getLiveMatches = makeGetMatchesForBets("live", ct.live.selected);
  const getPrematchMatches = makeGetMatchesForBets("prematch", ct.prematch.selected);

  return {
    //hasBets: hasBets,
    liveBets: ct.live.selected,
    liveMatches: getLiveMatches(state),
    prematchBets: ct.prematch.selected,
    prematchMatches: getPrematchMatches(state),
    selectError: bst.betsSlip.selectError,
    currentTicket: bst.betsSlip.currentTicket,
    liveSystems: ct.live.systems,
    prematchSystems: ct.prematch.systems,
    errorMessage: ct.errorMessage,
    winnerPlus: ct.winnerPlus,
    winnerPlusNeededEvents: ct.winnerPlusNeededEvents,
    bonusEvaluate: ct.bonusEvaluate,
    walletBonuses: window.config.useBonusEvaluation === "1" && state.wallet && state.wallet.bonuses ? state.wallet.bonuses : emptyArray,
    copyStatus: bst.betsSlip.copyStatus,
    tickets: bst.betsSlip.tickets,
  };
};

const actionCreators = {
  clearSlip: betsSlipClear,
  computeWin: betsSlipComputeWin,
  acceptOddChange: betsSlipAcceptOddChange,
  removeSelected: betsSlipRemove,
  setTicketType: betsSlipSetTicketType,
  setCurrentTicket: betsSlipSetCurrentTicket,
  copyCurrentTicket: betsSlipCopyCurrentTicket,
  clearCopyStatus: betsSlipClearCopyStatus,
};

//export default withTranslation()(connect(makeMapStateToProps, actionCreators)(BetsFull));

export default withTranslation()(connect(makeMapStateToProps, actionCreators)(React.memo(BetsFull, (pp, np) => {
  for (let k in pp) {
    if (!isEqual(pp[k], np[k])) {
      //console.log(`${k} is not equal`, typeof pp[k], pp[k], np[k]);
      return false;
    }
  }
  return true;
}))); 