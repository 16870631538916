import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { connect } from "react-redux";

import { getBetsState } from "../../store/selectors/betData";
import Time from "../NavList/Time";
import LiveBadge from "../../elements/LiveBadge";
import { getIso2 } from "../../utils";
import { showLeague } from "../../utils/betUtils";

const useStyles = makeStyles({
  item: {
    backgroundColor: "#fff",
    height: "45px",
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    alignContent: "stretch",
    padding: "0 12px 0 18px",
    marginBottom: "5px"
  },
  time: {
    marginRight: "5px"
  },
  icon: {
    marginRight: "5px",
    "& img": {
      width: "25px",
      display: "block"
    }
  },
  name: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
    marginRight: "5px",
    lineHeight: "12px"
  },
  open: {
    color: "#979DAB",
    "& svg": {
      position: "relative",
      top: "4px"
    }
  }
});

const LeagueList = props => {
  const {
    leagues,
    liveSports,
    liveCategories,
    liveTournaments,
    prematchSports,
    prematchCategories,
    prematchTournaments
  } = props;
  const classes = useStyles();

  //window.location.hash = `#league-details-${m.mType}-${m.idSport}-${m.idCategory}-${m.idTournament}`;

  let list = "";
  if (leagues && leagues.length) {
    list = leagues.map((l, i) => {
      const mType = l.mType;

      let title;
      let region;
      let regionURL = "";

      if (l.tournamentName) {
        title = l.tournamentName;
      } else if (mType === "live") {
        title =
          liveSports[l.idSport].sportName +
          " / " +
          liveCategories[l.idCategory].categoryName +
          " / " +
          liveTournaments[l.idTournament].tournamentName;
        region = liveCategories[l.idCategory].categoryIsoName;
      } else {
        title =
          prematchSports[l.idSport].sportName +
          " / " +
          prematchCategories[l.idCategory].categoryName +
          " / " +
          prematchTournaments[l.idTournament].tournamentName;
        region = prematchCategories[l.idCategory].categoryIsoName;
      }

      regionURL = "";
      const iso2 = getIso2(region);

      if (iso2 !== "unk") {
        regionURL = process.env.PUBLIC_URL + "/assets/countries/" + iso2 + ".svg";
      }

      const sLeague = showLeague(l);
      const showDetails = e => {
        sLeague(e);
      };

      return (
        <div key={i} className={classes.item} onClick={showDetails}>
          <div className={classes.time}>
            {mType === "live" ? <LiveBadge /> : <Time date={l.matchDateTime} />}
          </div>
          {iso2 !== "unk" && (
            <div className={classes.icon}>
              <img src={regionURL} alt={region} />
            </div>
          )}
          <div className={`${classes.name} flex-grow-1`}>{title}</div>
          <div className={classes.open}>
            <NavigateNextIcon />
          </div>
        </div>
      );
    });
  } else {
    return null;
  }

  return <div>{list}</div>;
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  return {
    liveSports: bst.live.sports,
    liveCategories: bst.live.categories,
    liveTournaments: bst.live.tournaments,
    prematchSports: bst.prematch.sports,
    prematchCategories: bst.prematch.categories,
    prematchTournaments: bst.prematch.tournaments
  };
};

export default connect(mapStateToProps)(LeagueList);
