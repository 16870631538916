import React from 'react';
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import "./happy-hours-front.scss";
import Timer from "../../components/timer";
import GiftImage from "../../assets/gift@2x.png";

const FrontMobile = (props) => {
  const { data, t } = props;
  const history = useHistory();

  const navigateToSlots = () => {
    history.push("/cazino/jocuri");
  };

  const hasSpecialPrize = typeof data.next_special_prize !== "undefined" && data.next_special_prize ? true : false;

  const genPrize = (className, prize) => {
    const isSpecialPrize = prize && !prize.amount && typeof prize.custom !== "undefined" ? true : false;
    if (!isSpecialPrize) {
      return (
        <div className={`${className} ${!prize ? 'hidden' : ''}`}>{prize ? prize.amount : 0} Lei</div>
      )
    }

    return (
      <div className={`hh-special-prize-front ${!prize ? 'hidden' : ''}`}>
        <img className="hh-sp-img" src={prize.custom.thumbnail} alt="" />
        <div className="hh-sp-title-front">{prize.custom.title}</div>
      </div>
    )
  };

  return (
    <div className="hh-front-root" onClick={navigateToSlots}>
      <div className="hh-header nomrg">
        {hasSpecialPrize && <div className="hh-sp-left">
          <div className="hh-name">
            {data.name}
          </div>
          <Timer date={data.date} />
        </div>}
        {!hasSpecialPrize && <div className="hh-name">
          {data.name}
        </div>}

        <div className="hh-center">
          <div className="hh-pills">
            <div className="hh-pill op-26"></div>
            <div className="hh-pill op-50 ml-5"></div>
            <div className="hh-pill active m-3"></div>
            <div className="hh-pill op-50 mr-5"></div>
            <div className="hh-pill op-26"></div>
          </div>
          <div className="hh-pills-text">
            <span className="hh-current-prize">{data.current_prize}</span>
            <span className="hh-all-prizes">&nbsp;/ {data.total_prizes}</span>
          </div>
        </div>

        {hasSpecialPrize && <div className="hh-sp-right">
          <div className="hh-sp-title-front">
            {t("SPECIAL PRIZE")}
          </div>
          <div className="hh-sp-name">
            {data.next_special_prize.custom.title}
          </div>
        </div>}
        {!hasSpecialPrize && <Timer date={data.date} />}
      </div>

      <div className="hh-prizes">
        {genPrize("hh-prize-before", data.prize_before)}
        <div className="hh-prize-current">
          <div className="hh-flx">
            <div className="hh-img">
              {/*<GiftIcon />*/}
              <img src={GiftImage} alt="" />
            </div>
            <div className="hh-txt">
              {data.prize_current ? data.prize_current.amount : 0} Lei
            </div>
          </div>
          <div className="hh-bet">{t("Minimum Bet")}: {data.prize_current ? data.prize_current.min_bet : 0}</div>
        </div>
        {genPrize("hh-prize-after", data.prize_after)}
      </div>
    </div>
  );
}

export default withTranslation()(FrontMobile);