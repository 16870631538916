import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScrollBooster from './components/ScrollBoster';

const useScrollBoost = (options) => {
  const scrollBooster = useRef(null);
  const [scrollBoosterState, setScrollBoosterState] = useState(scrollBooster.current);

  // options shouldn't change within the hook but can be changed on the scrollBooster instance
  const optionsRef = useRef(options);
  const viewport = useCallback((node) => {
    if (node) {
      const { content, ...rest } = optionsRef.current;
      const sbOptions = { viewport: node, ...rest };

      if (content && content.current) {
        sbOptions.content = content.current;
      }

      // create the scrollbooster instance
      scrollBooster.current = new ScrollBooster(sbOptions);
      setScrollBoosterState(scrollBooster.current);
    }
  }, []);

  useEffect(() => {
    // clear the scrollbooster eventlisteners
    return () => scrollBooster && scrollBooster.current.destroy();
  }, []);

  return [viewport, scrollBoosterState];
};

function ScrollBoost({ children, ...options }) {
  const [viewport, scrollbooster] = useScrollBoost(options);
  return <React.Fragment>{children({ viewport, scrollbooster })}</React.Fragment>;
}

export { useScrollBoost, ScrollBoost };