import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../AuthenticationModal/Username/Username.css';
import SelectInput from "../SelectInput/SelectInput";
import * as stateActions from "../../../../store/actions";
import BackendClient from "../../../../BackendClient";
import { withTranslation } from "react-i18next";
import Utils from "../../../../utils/Utils";
import GTM from "../../../../utils/GTM";

class Citizenship extends Component {
    constructor(props) {
        super(props);
        Utils.delayedFunction("initCitizenship", this.init.bind(this), 1000);

    }
    init() {
        this.getCountries();
        // this.getDefaultCountry();
    }
    getDefaultCountry() {
        let axios = BackendClient.getInstance();
        axios({
            url: 'api/countries/default',
            method: 'get',
        }).catch(e => {
            console.log(`[CATCHED **GET DEFAULT COUNTRY** ERROR] => ${e}`);
        });
    }
    getCountries() {
        let axios = BackendClient.getInstance();
        axios({
            url: 'api/countries',
            method: 'get',
        }).catch(e => {
            // console.log(`[CATCHED **GET COUNTRIES** ERROR] => ${e}`);
        });
    }

    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.citizenship;
        } else {
            fieldInput = event.target.value;
        }
        if (fieldInput === "") {
            fieldInput = this.props.defaultCountry;
        }
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - OK', "Citizenship");
        this.props.onChange(fieldInput);
    }

    render() {
        return (
            <div>
                <SelectInput options={[["RO", "Romania"]]/*this.props.countries*/}
                    default={"RO"/*this.props.defaultCountry*/}
                    onChange={this.changeHandler.bind(this)}
                    id={this.props.t("Citizenship")}
                    name={this.props.t("Citizenship")}
                    label={this.props.t("Citizenship")} />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(stateActions.fieldEntered({ value: fieldInput, field: "citizenship", validated: true })),
    };
};

const mapStateToProps = state => {
    return {
        citizenship: state.registerWizard.fields.citizenship,
        countries: state.registerWizard.countries,
        defaultCountry: state.registerWizard.defaultCountry,
        lang: state.application.language
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Citizenship));
