import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./news.scss";

import { getText, styleToObj, checkItemAvailability, getIndexById } from "../../utils/functions";
import FullNews from "./full_news";

import moment from 'moment';

const empty = {};

const News = (props) => {
  const { data, i18n, source } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  const [fullNews, setFullNews] = React.useState({
    open: false,
    data: null,
  });

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  if (items.length === 0) return null;
  const filteredItems = items.filter(item => (checkItemAvailability(item)));
  if (filteredItems.length === 0) return null;

  const openFullNews = (event) => {
    event.stopPropagation();

    const target = event.currentTarget;

    if (target) {
      const index = target.dataset.index;

      setFullNews({
        open: true,
        data: filteredItems[index],
      });
    }
  };
  const closeModal = () => {
    setFullNews({
      open: false,
      data: null,
    });
  }

  return (
    <div className={`component-news component`} style={styleToObj(data.style)}>

      <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
      </div>

      <div className="cgl-header-tags">
        <div className="tags">
          <div className="tag" style={styleToObj(data.tag.style)}>TAG</div>
        </div>
      </div>

      <div className="cgl-list with-scroll-style"
        style={styleToObj(data.data_source_style)}
      >
        <div className={`cgl-grid`}>
          {filteredItems.map((item, i) => {
            const realIndex = getIndexById(items, item.id);
            const itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;
            const tagStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" && typeof data.data_source_items[realIndex.toString()].tag !== "undefined"
              ? styleToObj(data.data_source_items[realIndex.toString()].tag.style) : empty;
            const textStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" && typeof data.data_source_items[realIndex.toString()].text !== "undefined"
              ? styleToObj(data.data_source_items[realIndex.toString()].text.style) : empty;
            const dateStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" && typeof data.data_source_items[realIndex.toString()].date !== "undefined"
              ? styleToObj(data.data_source_items[realIndex.toString()].date.style) : empty;

            return <div className={`cgl-item ${item.isEmpty ? "empty" : ""}`} key={i} data-index={i} style={itemStyle} onClick={openFullNews}>
              <div className="outer">
                <div className="inner">
                  {item.data.image && item.data.image.mobile && <img src={item.data.image.mobile} alt="" />}
                  <div className="cgl-tag-wrapper">
                    <div className="cgl-tag" style={tagStyle}>
                      {getText(item, `data.tag.text.${lang}`, "")}
                    </div>
                  </div>
                  <div className="cgl-txt" style={textStyle}>
                    {getText(item, `data.title.text.${lang}`, "")}
                  </div>
                  {item.data.timeEnabled && !!item.data.startTime && <div className="cgl-date" style={dateStyle}>
                    {moment(item.data.startTime * 1000).format("D MMMM YYYY")}
                  </div>}
                </div>
              </div>
            </div>;
          })}
        </div>
      </div>
      {fullNews.open && <FullNews open={fullNews.open} data={fullNews.data} onClose={closeModal} />}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
  }
};

export default withTranslation()(connect(mapStateToProps)(News));