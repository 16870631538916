import { debug } from './';
import getStore from '../store';
import { getBetsState } from '../store/selectors/betData';
import { normalizeBetsForEvaluation } from './formatters';

const evaluateFreeBet = (freeBetFilter, ticket) => {
  // check there are some filters; if no filters then is eligible
  if (!freeBetFilter || !('live' in freeBetFilter && 'prematch' in freeBetFilter)) return true;

  // check ticket type in filters; if no filters then is eligible
  if (!(ticket.betType in freeBetFilter)) return true;

  const fs = freeBetFilter[ticket.betType];

  // no filters then is eligible
  if (fs.length === 0) return true;

  debug('fbeval filters', fs);

  let eligible = true;

  const markets = fs.filter((b) => typeof b.idBet !== 'undefined');
  const events = fs.filter((b) => typeof b.idBet === 'undefined');

  for (let bet of ticket.bets) {
    if (fs) {
      let idxMarket = markets.findIndex(f => {
        if ('idBet' in f) return bet.idBet === f.idBet;
        if ('idMatch' in f) return bet.idMatch === f.idMatch;
        if ('idTournament' in f) return bet.idTournament === f.idTournament;
        if ('idCategory' in f) return bet.idCategory === f.idCategory;
        if ('idSport' in f) return bet.idSport === f.idSport;
        return false;
      });

      let idxEvent = events.findIndex(f => {
        if ('idBet' in f) return bet.idBet === f.idBet;
        if ('idMatch' in f) return bet.idMatch === f.idMatch;
        if ('idTournament' in f) return bet.idTournament === f.idTournament;
        if ('idCategory' in f) return bet.idCategory === f.idCategory;
        if ('idSport' in f) return bet.idSport === f.idSport;
        return false;
      });

      let matchesBet = false;

      if (!markets.length) {
        matchesBet = true;
      } else {
        if (idxMarket !== -1) matchesBet = true;
      }

      let matchesEvent = false;
      if (!events.length) {
        matchesEvent = true;
      } else {
        if (idxEvent !== -1) matchesEvent = true;
      }


      if (!(matchesBet && matchesEvent)) {
        // at least one bet doesn't match the filters - set eligible false
        eligible = false;
      }
    }
  }

  debug('fbeval', { eligible });

  // all bets found in ticket filters - ticket eligible
  return eligible;
};

const evaluateFreeBets = rt => {
  const ticket = rt.ticket;

  const tBets = normalizeBetsForEvaluation(ticket.betType, ticket.bets);
  ticket.bets = tBets;

  debug('evaluateFreeBet', rt);

  const state = getStore().getState();
  const bst = getBetsState(state);

  const freeBets = state.freeBets.freeBets;
  const freeBetsFilters = state.freeBets.freeBetsFilters;
  const rules = bst.config.bonusRules;

  const response = {
    success: true,
    data: {}
  };

  freeBets.forEach((freeBet) => {
    if (freeBet && freeBet && freeBet.fbid && (freeBet.product === 'sport' || freeBet.product === 'sport.prematch' || freeBet.product === 'sport.live')) {

      let resFilters = true;
      if (typeof freeBetsFilters[freeBet.fbid] !== 'undefined') {
        resFilters = evaluateFreeBet(freeBetsFilters[freeBet.fbid], ticket);
      }

      let resRule = true;
      if (
        typeof rules['bets'] !== 'undefined' &&
        typeof freeBetsFilters[freeBet.fbid] !== 'undefined' &&
        freeBetsFilters[freeBet.fbid].rulesetId in rules['bets']
      ) {
        rules['bets'][freeBetsFilters[freeBet.fbid].rulesetId].forEach(r => {
          debug('evaluating free bet rule', r.id);
          debug('rule lists', r.lists);

          let evalRes = null;

          const lists = rt.product === 'SportsbookSM' ? r.lists.prematch : r.lists.live;

          try {
            evalRes = r.script(ticket, freeBet, lists);
          } catch (e) {
            console.error('Error evaluating free bet rule', e);
            evalRes = { eligible: false };
          }

          debug('evalRes', evalRes);

          if (!evalRes.eligible) {
            resRule = false;
          }
        });
      }

      response.data[freeBet.code] = resFilters && resRule;
    }
  });
  return response;
};

export { evaluateFreeBets };