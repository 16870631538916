import React from "react";
import PropTypes from 'prop-types'; // eslint-disable-line
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import * as stateActions from "../../../store/actions";
import JackpotHeader from './../JackpotHeader';
import SubHeader from './../SubHeader';
import EgtLogo from './../assets/img-jackpotlogo-egt@3x.png';
import "./index.scss";

const JackpotsHeader = ({ t, jackpots, onClick, reloadJackpots, type }) => {
  const handleClick = (index) => {
    onClick(index ? index : 0)
  };

  return (
    <div className={`JackpotsHeader-${type}`}>
      <JackpotHeader
        name={"EGT Spades"}
        logo={EgtLogo}
        value={jackpots.egtParsed.maxValue}
        currency={jackpots.egtParsed.currency}
        type={type} onClick={handleClick} />
      <SubHeader name="EGT" jackpot={jackpots.egtParsed} type={type} onClick={handleClick} />
    </div>
  );
};

JackpotsHeader.propTypes = {
  jackpots: PropTypes.object,
  onClick: PropTypes.func,
  reloadJackpots: PropTypes.func,
};

JackpotsHeader.defaultProps = {
  jackpots: { egtParsed: {} },
  onClick: () => { }
};

const mapStateToProps = state => {
  return {
    jackpots: state.jackpots,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    reloadJackpots: () => { dispatch(stateActions.requestJackpots()) },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(JackpotsHeader)); 