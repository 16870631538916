import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as stateActions from "../../../../store/actions";
import Promotion from './Promotion/Promotion';
import './Promotions.scss';
import BonusAvailableWidget from "../../../ClaimBonus/BonusAvailableWidget/BonusAvailableWidget";
import BackendClient from "../../../../BackendClient";
import Icons from "../../../../assets/Icons/Icons";

import WheelCTAPromotions from "../../../../wheel/components/cta-promotions";
import WinterCTAPromotions from "../../../../promo/components/cta-promotions-desktop";

class Promotions extends Component {

	constructor(props) {
		super(props);
		const pathname = window.location.pathname.split('/');
		this.state.id = typeof pathname[3] !== "undefined" ? pathname[3] : false;
	}
	state = {
		id: false,
		bonusSelected: false
	};
	componentDidMount() {
		this.props.history.listen((location) => {
			const pathname = location.pathname.split('/');
			let id = typeof pathname[3] !== "undefined" ? pathname[3] : false;
			this.setState({
				...this.state,
				id: id
			});
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.history.match !== this.props.history.match) {
			console.log('PROMO2', this.props.history.match)
		}
	}

	// componentDidMount() {
	// 	if (!this.props.promotions.length) {
	// 		this.props.getPromotions();
	// 	}
	// }

	onClickedBonus(bonusId, bonus) {
		this.claimBonus(bonusId);
		this.setState({
			...this.state,
			bonusSelected: bonus
		})
	}
	claimBonus(bonusId, force) {
		const axios = BackendClient.getInstance();
		let data = {
			bonusAvailableId: bonusId
		}
		if (force) {
			data.force = 1;
		}

		axios({
			url: "/api/casino/claimBonus",
			method: 'post',
			data: data
		}).catch(e => {
			console.log(`[CATCHED **casino jackpot** ERROR] =? ${e}`);
		});
	}
	render() {
		if (this.state.id) {
			return <Promotion />;
		}
		let pageContent = [];
		let otherPromotions = [];
		let firstPromotions = [];

		if (this.props.promotions.length) {
			for (let i = 0; i < this.props.promotions.length; i++) {
				let featured = false;
				if (i > 3) {
					if ((i % 5 === 0)) {
						featured = 'medium ';
					} else if (i % 5 === 4) {
						featured = 'medium';
					}

				}
				if (i === 0) {
					featured = 'big';
					firstPromotions.push(<Promotion promotion={this.props.promotions[i]} featured={featured} key={"promotions_" + i} />)
				} else {
					otherPromotions.push(<Promotion promotion={this.props.promotions[i]} featured={featured} key={"promotions_" + i} />)
				}
			}
			pageContent.push(<div key={'promotions'}>
				<div className="featuredPromotion">
					{firstPromotions}
				</div>
				<div className="otherPromotions">
					{otherPromotions}
				</div>
			</div>)
		} else {
			pageContent = (
				<Fragment>
					<Promotion dummy className={'big'} />
					<Promotion dummy className={'small'} />
					<Promotion dummy className={'small'} />
					<Promotion dummy className={'small'} />
					<Promotion dummy className={'medium'} />
					<Promotion dummy className={'medium'} />
				</Fragment>

			);
		}

		// let menuItems = [
		// 	{
		// 		"to": '/cazino/promotii',
		// 		"name": "test",
		// 		"icon": "cherries",
		// 		"color": "#ff0000"
		// 	},
		// ];
		const wheelEnabled = window.config && window.config.promoEnabled === "1";
		const promoEnabled = window.config && window.config.wheelEnabled === "1";

		return (
			<div className={'PromotionsScreen'}>
				{/*<HorizontalScrollingMenu items={menuItems}/>*/}
				<div className='PromotionsScreenContent'>
					{(wheelEnabled || promoEnabled) && <div className="special-promos">
						<h1>{Icons.get('hotGameIcon')} {this.props.t('Special Promotions')}</h1>
						<div className="flex-promos">
							{promoEnabled && <WheelCTAPromotions />}
							{wheelEnabled && <WinterCTAPromotions />}
						</div>
					</div>}
					{this.props.loggedIn && <div className="claim-now">
						<BonusAvailableWidget selected={this.state.bonusSelected} type={'promo-page'} onClickedBonus={this.onClickedBonus.bind(this)} cta_text={this.props.t("CLAIM NOW")} />
					</div>}
					<h1>{Icons.get('hotGameIcon')} {this.props.t('Promotions')}</h1>
					{pageContent}
				</div>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		contentLoader: state.contentLoader,
		promotions: state.application.promotions,
		loggedIn: state.authentication.auth_type === 'user',
	}
};

const mapDispatchToProps = dispatch => {
	return {
		contentLoaded: () => dispatch(stateActions.hideContentLoader()),
		showLoader: () => dispatch(stateActions.showContentLoader())
	};
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Promotions)));
