import { winterConstants } from "./constants";

export const loadWinterStatus = () => ({
  type: winterConstants.LOAD
});

export const loadingWinterStatus = () => ({
  type: winterConstants.LOADING
});

export const loadedWinterStatus = (status, skip) => {
  return {
    type: winterConstants.LOADED,
    status
  };
};