import { gsap, TimelineMax, TweenMax, TweenLite, Power1, Linear, CustomEase } from "gsap/all";

gsap.registerPlugin(TweenMax, TimelineMax, TweenLite, Power1, Power1, Linear, CustomEase);

// use for when there is the double multiplier award activated
const multiAngleOffset = 32;
const multiAngle = 180;

const randomFromInterval = (min, max, sliceMargins) => { // min and max included
  let total = max - min;
  let margin = (sliceMargins * total) / 100;
  let rMin = min + margin;
  let rMax = max - margin;
  return Math.random() * (rMax - rMin + 1) + rMin;
};

export const getIdByRotation = (currentRotation, config, sa, configKey, specialPrize) => {
  const slots = config.slots;
  const slot_count = slots.length;
  let commonAngle = config.commonAngle;

  let startAngle = 0;
  let offsetAngle = multiAngle;
  if (specialPrize && specialPrize.type === 1) {
    offsetAngle += multiAngleOffset;
  }
  let compareWithAngle = filterDegree(filterDegree((360 + (configKey === "multipliers" ? offsetAngle : 0)) - currentRotation - sa));

  for (let i = 0; i < slot_count; i++) {
    const slot = slots[i];
    let endAngle = startAngle + commonAngle;

    if (parseFloat(slot.angle)) {
      endAngle = startAngle + parseFloat(slot.angle);
    }

    if (startAngle <= compareWithAngle && compareWithAngle <= endAngle) {
      const newSlot = {
        ...slot
      };

      if (specialPrize && specialPrize.type === 1) {
        // this is a double multiplier so look up the second multiplier
        newSlot.second = i - 2 >= 0 ? { ...slots[i - 2] } : { ...slots[slot_count - 2] };
      }

      return newSlot;
    } else {
    }
    startAngle = endAngle;
  }
  return null;
}

const getParsedValue = (val, def) => {
  const parsedValue = parseFloat(val);
  if (!isNaN(parsedValue)) return parsedValue;
  return def;
};

export const filterDegree = (d) => {
  while (d < 0) {
    d += 360;
  }
  return (d % 360);
};

export const calculateCommonAngle = (config) => {
  let slot_count = config.slots.length;
  let commonAngle = 360 / slot_count;
  let fixedAngles = 0;
  let fixedSlots = 0;
  let i = 0;

  for (i = 0; i < slot_count; i++) {
    const slot = config.slots[i];
    if (slot.angle) {
      fixedAngles += parseFloat(slot.angle);
      fixedSlots += 1;
    }
  }

  if (fixedSlots) {
    commonAngle = (360 - fixedAngles) / (slot_count - fixedSlots);
  }

  config.commonAngle = commonAngle;
}

const rotate = (config) => {
  gsap.ticker.lagSmoothing(false);

  const tl = gsap.timeline({
    paused: true,
    onComplete: () => {
      if (tl) tl.kill();
      if (typeof config.callBack === "function") config.callBack();
      gsap.ticker.lagSmoothing(500, 33);
    },
    onUpdate: config.onUpdate ? config.onUpdate : () => { }
  });

  const keys = ["products", "values", "multipliers"];

  keys.forEach((configKey) => {
    if (config[configKey]) {
      const slots = config[configKey].slots;
      const slot_count = slots.length;
      let commonAngle = config[configKey].commonAngle;

      const winId = config[configKey].winId;
      let startWinAngle = 0;
      let endWinAngle = 0;

      let startSpinAngle = getParsedValue(config[configKey].spin, 0);
      let startAngle = startSpinAngle;
      for (let i = 0; i < slot_count; i++) {
        const slot = slots[i];
        let endAngle = startAngle + commonAngle;

        if (parseFloat(slot.angle)) {
          endAngle = startAngle + parseFloat(slot.angle);
        }

        if (slot.id === winId) {
          startWinAngle = startAngle;
          endWinAngle = endAngle;
          break;
        }

        startAngle = endAngle;
      }

      const rotations = config[configKey].rotations;
      const time = config[configKey].time;
      const ease = config[configKey].ease;

      let endAngle = (rotations * 360) + (360 - randomFromInterval(startWinAngle, endWinAngle, config[configKey].sliceMargins));

      if (configKey === "multipliers") {
        endAngle += multiAngle;
        if (config.specialPrize && config.specialPrize.type === 1) {
          endAngle += multiAngleOffset;
        }
      }

      startAngle = 0;

      tl.fromTo(config[configKey],
        { spin: startAngle },
        {
          spin: startAngle + config[configKey].preSpin.angle,
          duration: config[configKey].preSpin.time,
          ease: config[configKey].preSpin.ease && config[configKey].preSpin.ease.indexOf("M0,") === 0 ? CustomEase.create("custom", config[configKey].preSpin.ease) : config[configKey].preSpin.ease,
        }, 0);

      tl.to(config[configKey], {
        spin: endAngle,
        duration: time,
        stagger: config[configKey].stagger,
        ease: ease && ease.indexOf("M0,") === 0 ? CustomEase.create("custom", ease) : ease,
      }, config[configKey].preSpin.time);

      if (configKey === "multipliers") {
        tl.to(config[configKey], {
          duration: time + 3,
        }, config[configKey].preSpin.time);
      }
    }
  });

  return tl;
}

export default rotate;