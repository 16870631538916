import React from "react";
import MoreIcon from '@material-ui/icons/MoreHoriz';
import ClearIcon from '@material-ui/icons/Clear';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { gsap, TimelineMax, TweenMax, TweenLite, Power3, Power2, Linear, Draggable } from "gsap/all";
import isEqual from "lodash/fp/isEqual";

import "./portrait-player-gallery.scss";

import { getText } from "../../lobby/utils/functions";
import { loadPlayerLeaderBoard, loadedPlayerLeaderBoard } from "../store/actions/leader_board";
import { closeGameLauncher } from "../../store/actions/games";

import Watch from "../assets/watch.svg";
import Timer from "./timer";
import DefaultAvatar from "../assets/img-avatar-male.png";

import PosBg from "../assets/pos-bg.svg";
import DialogObjectives from "../dialogs/dialog-rules";
import DialogSwitchTournament from "../dialogs/dialog-switch-tournament";
import AwardIcon from "../assets/award.svg";
import { animationOnePosition, animationTwoPosition } from "./animation";
import { formatNumber } from "../utils/functions";
import TextTransition from "./text-transition";
import { config as presets } from "react-spring";
import Debugger from "../../utils/logger";
import { getData } from "../../lobby/utils/functions";

const logger = Debugger(Debugger.DEBUG, "portrait-player-gallery");

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, TweenLite, Power2, Power3, Linear);

const differentArray = (left, right) => {
  if (left.length !== right.length) return true;

  for (let i = 0; i < left.length; i++) {
    const keys = Object.keys(left[i]);
    for (let j = 0; j < keys.length; j++) {
      if (left[i][keys[j]] !== right[i][keys[j]]) {
        return true;
      }
    }
  }

  return false;
};

const newPlayersLeft = [
  {
    "pos": 5,
    "nickname": "winner_100570",
    "score": 99700,
    "award": "0 Lei"
  },
  {
    "pos": 6,
    "nickname": "winner_100740",
    "score": 99600,
    "award": "0 Lei"
  },
  {
    "pos": 7,
    "nickname": "Vony",
    "score": 99500,
    "award": "0 Lei",
    "me": true
  },
  {
    "pos": 8,
    "nickname": "Adina",
    "score": 80404,
    "award": "0 Lei"
  },
  {
    "pos": 9,
    "nickname": "winner_100210",
    "score": 61304,
    "award": "0 Lei"
  },
];

const newPlayersRight = [
  {
    "pos": 8,
    "nickname": "Adina",
    "score": 80404,
    "award": "0 Lei"
  },
  {
    "pos": 9,
    "nickname": "winner_100210",
    "score": 61304,
    "award": "0 Lei"
  },
  {
    "pos": 10,
    "nickname": "Vony",
    "score": 495,
    "award": "0 Lei",
    "me": true
  },
  {
    "pos": 11,
    "nickname": "winner_100570",
    "score": 488,
    "award": "0 Lei"
  },
  {
    "pos": 12,
    "nickname": "winner_100740",
    "score": 468,
    "award": "0 Lei"
  }
];

const newPlayersLeftOne = [
  {
    "pos": 7,
    "nickname": "winner_100740",
    "score": 90404,
    "award": "0 Lei"
  },
  {
    "pos": 8,
    "nickname": "Adina",
    "score": 80404,
    "award": "0 Lei"
  },
  {
    "pos": 9,
    "nickname": "Vony",
    "score": 62304,
    "award": "0 Lei",
    "me": true
  },
  {
    "pos": 10,
    "nickname": "winner_100210",
    "score": 61304,
    "award": "0 Lei"
  },
  {
    "pos": 11,
    "nickname": "winner_100570",
    "score": 488,
    "award": "0 Lei"
  },

];

const newPlayersRightOne = [
  {
    "pos": 8,
    "nickname": "Adina",
    "score": 80404,
    "award": "0 Lei"
  },
  {
    "pos": 9,
    "nickname": "winner_100210",
    "score": 61304,
    "award": "0 Lei"
  },
  {
    "pos": 10,
    "nickname": "Vony",
    "score": 495,
    "award": "0 Lei",
    "me": true
  },
  {
    "pos": 11,
    "nickname": "winner_100570",
    "score": 488,
    "award": "0 Lei"
  },
  {
    "pos": 12,
    "nickname": "winner_100740",
    "score": 468,
    "award": "0 Lei"
  }
];

const PlayerGallery = (props) => {
  const { tournament, i18n, t, rank, loadPlayerLeaderBoard, loadedPlayerLeaderBoard, authentication, closeGameLauncher, history } = props;

  const [updating, setUpdating] = React.useState(false);

  const [players, setPlayers] = React.useState([]);

  const [texts, setTexts] = React.useState([]);

  const [newPlayers, setNewPlayers] = React.useState({
    players: [],
    direction: 0,
  });

  React.useEffect(() => {
    if (tournament && rank && !rank[tournament.id]) {
      loadPlayerLeaderBoard({ tournamentId: tournament.id, inGame: true });
    }

    if (tournament) {
      let prize1 = getText(tournament, `meta.ui.prize_1.text.${i18n.language}`, "");
      let prize2 = getText(tournament, `meta.ui.prize_2.text.${i18n.language}`, "");
      let name = tournament.name;
      let texts = [
        name,
        `${prize1} ${prize2}`
      ];

      if (rank && rank[tournament.id] && rank[tournament.id].top) {
        for (let i = 0; i < 3; i++) {
          if (rank[tournament.id].top.length > i && rank[tournament.id].top[i] && rank[tournament.id].top[i].award) {
            texts.push(`${t("Position")} #${rank[tournament.id].top[i].pos}: ${rank[tournament.id].top[i].award}`);
          }
        }
      }

      setTexts(v => {
        if (!isEqual(v, texts)) return texts;
        return v;
      });
    }
  }, [tournament, rank, loadPlayerLeaderBoard, i18n.language, t]);

  const avatar_image = getData(tournament, "meta.ui.avatar_image.url", DefaultAvatar);

  const [openObjectivesDialog, setOpenObjectivesDialog] = React.useState(false);
  const handleCloseObjectivesDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenObjectivesDialog(false);
  };
  const showRules = (e) => {
    if (e) e.stopPropagation();
    setOpenObjectivesDialog(true);
  };

  const [openTournamentsDialog, setOpenTournamentsDialog] = React.useState(false);
  const handleCloseTournamentsDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(false);
  };
  const showMenu = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(true);
  };

  const closeGame = () => {
    closeGameLauncher();
    if (window._CanGoBack) {
      history.goBack();
      return;
    }
    history.push("/cazino/jocuri");
  };

  React.useEffect(() => {
    let isAuthenticated = false;
    if (authentication && ["user", "token"].indexOf(authentication.auth_type) > -1) {
      isAuthenticated = true;
    }

    if (isAuthenticated && tournament && rank && rank[tournament.id] && rank[tournament.id].player && differentArray(rank[tournament.id].player, players)) {
      const newPlayer = JSON.parse(JSON.stringify(rank[tournament.id].player));

      if (players.length === 0 || newPlayer.length !== players.length || players.length !== 5) { // we don't animate for players less then five
        setPlayers(newPlayer);
      } else {
        let currentPlayerPos = -1;
        let currentIndex = -1;
        players && players.length && players.forEach((p, i) => {
          if (p.me) {
            currentPlayerPos = p.pos;
            currentIndex = i;
          }
        });

        let newPlayerPos = -1;
        let newIndex = -1;
        newPlayer && newPlayer.length && newPlayer.forEach((p, i) => {
          if (p.me) {
            newPlayerPos = p.pos;
            newIndex = i;
          }
        });

        if (currentIndex !== 2 || newIndex !== 2) { // we don't animate if not in center
          setPlayers(newPlayer);
          return;
        }

        if (currentPlayerPos !== newPlayerPos) {
          setNewPlayers({
            players: newPlayer,
            direction: currentPlayerPos - newPlayerPos, // TODO decide the new direction
          })
        } else {
          setPlayers(newPlayer);
        }
      }
    }
  }, [tournament, rank, players, authentication]);

  const animateLeft = () => {
    loadedPlayerLeaderBoard({
      tournamentId: tournament.id,
      data: {
        player: JSON.parse(JSON.stringify(newPlayersLeft)),
      }
    });
  };
  const animateRight = () => {
    loadedPlayerLeaderBoard({
      tournamentId: tournament.id,
      data: {
        player: JSON.parse(JSON.stringify(newPlayersRight)),
      }
    });
  };

  const animateLeftOne = () => {
    loadedPlayerLeaderBoard({
      tournamentId: tournament.id,
      data: {
        player: JSON.parse(JSON.stringify(newPlayersLeftOne)),
      }
    });
  };
  const animateRightOne = () => {
    loadedPlayerLeaderBoard({
      tournamentId: tournament.id,
      data: {
        player: JSON.parse(JSON.stringify(newPlayersRightOne)),
      }
    });
  };

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      6000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  React.useEffect(() => {
    let tl;

    if (newPlayers && newPlayers.players.length && differentArray(newPlayers.players, players)) {
      const direction = newPlayers.direction;
      const before = document.querySelector(".before");
      const after = document.querySelector(".after");
      const gallery = document.querySelector(".gallery.real");

      if (direction < 0) {
        let player0 = Math.abs(direction) > 1 ? newPlayers.players[3] : newPlayers.players[4];
        let player1 = Math.abs(direction) > 1 ? newPlayers.players[4] : newPlayers.players[0];

        let cnt = `<div class='item no-6'>
            <div class="avatar">
              <img src=${player0.avatar ? player0.avatar : avatar_image} alt="" />
            </div>
            <div class="pos">
              <img src='${PosBg}' alt="" />
              <div class="no">#${player0.pos}</div>
            </div>
            <div class="score">${formatNumber(player0.score, props.i18n.language)}</div>
          </div>`;

        cnt += Math.abs(direction) > 1 ?
          `<div class='item'>
            <div class="avatar">
              <img src=${player1.avatar ? player1.avatar : avatar_image} alt="" />
            </div>
            <div class="pos">
              <img src='${PosBg}' alt="" />
              <div class="no">#${player1.pos}</div>
            </div>
            <div class="score">${formatNumber(player1.score, props.i18n.language)}</div>
          </div>` : `<div class='item'>
          <div class="avatar off"></div>
          <div class="pos"></div>
          <div class="score">&nbsp;</div>
        </div>`;

        after.innerHTML = cnt;

      } else {

        let player0 = Math.abs(direction) > 1 ? newPlayers.players[0] : newPlayers.players[4];
        let player1 = Math.abs(direction) > 1 ? newPlayers.players[1] : newPlayers.players[0];

        let cnt = Math.abs(direction) > 1 ?
          `<div class='item'>
          <div class="avatar">
            <img src=${player0.avatar ? player0.avatar : avatar_image} alt="" />
          </div>
          <div class="pos">
            <img src='${PosBg}' alt="" />
            <div class="no">#${player0.pos}</div>
          </div>
          <div class="score">${formatNumber(player0.score, props.i18n.language)}</div>
        </div>` : `<div class='item'>
        <div class="avatar off"></div>
        <div class="pos"></div>
        <div class="score">&nbsp;</div>
      </div>`;

        cnt += `<div class='item no--1'>
          <div class="avatar">
            <img src=${player1.avatar ? player1.avatar : avatar_image} alt="" />
          </div>
          <div class="pos">
            <img src='${PosBg}' alt="" />
            <div class="no">#${player1.pos}</div>
          </div>
          <div class="score">${formatNumber(player1.score, props.i18n.language)}</div>
        </div>
      `;

        before.innerHTML = cnt;
      }

      let totalDuration = 0.25;
      if (Math.abs(direction) > 1) {
        totalDuration *= 1;
      }

      tl = gsap.timeline({
        onComplete: () => {
          setTimeout(() => {
            const pl = JSON.parse(JSON.stringify(newPlayers.players));
            setNewPlayers({
              players: [],
              direction: 0,
            });

            setUpdating(true);

            setTimeout(() => {
              setPlayers(pl);


              gallery.style.transform = '';
              if (direction < 0) {
                after.innerHTML = "";
              } else {
                before.innerHTML = "";
              }

              setTimeout(() => {
                setUpdating(false);
              }, 0)
              return;

            });
          }, 200);
        }
      });

      const scale = 0.6;
      const offset = 0; //duration / 4;
      const translateY = -10;

      if (Math.abs(direction) > 1) {
        animationTwoPosition({
          players: newPlayers.players, tl, direction, duration: totalDuration, offset, translateY, scale
        });
      } else {
        animationOnePosition({
          players: newPlayers.players, tl, direction, duration: totalDuration, offset, translateY, scale
        });
      }
    }

    return () => {
      if (tl) {
        try {
          tl.kill();
        } catch (err) { }
      }
    }
  }, [newPlayers, players, props.i18n.language]);

  if (!tournament) return null;

  let currentPlayer = null;
  let currentPlayerPos = -1;
  players && players.length && players.forEach((p, i) => {
    if (p.me) {
      currentPlayer = p;
      currentPlayerPos = i;
    }
  });

  logger.debug({ tournament, rank });

  return (
    <div className="portrait-player-gallery">
      <div className="header-wrapper">
        <div className="header">
          <div className="tmr">
            <img src={Watch} alt="" />
            <Timer date={tournament.end_date}>
              {({ days, hours, mins, secs }) => {
                return `${!!days ? (days > 1 ? `${days} ${t("days")} ` : `${days} ${t("day")} `) : ""}${hours}:${mins}:${secs}`;
              }}</Timer>
          </div>
          <div className="title">
            <TextTransition
              text={texts[index % texts.length]}
              springConfig={presets.wobbly}
            />
          </div>
        </div>
        <div className="hr"></div>
      </div>
      {players.length === 0 && <div className="gallery-wrapper" onClick={showRules}>
        <div className="empty">{t("The tournament rankings will appear soon")}</div>
      </div>
      }
      {players.length !== 0 && <div className="gallery-wrapper" onClick={showRules}>
        <div className="gallery real">
          <div className="container before"></div>
          {
            !updating && players.map((p, i) => {
              if (i === currentPlayerPos) {
                return <div key={i} className={`item no-${i + 1}`}>
                  <div className="avatar off"></div>
                  <div className="pos">
                  </div>
                  <div className="score">&nbsp;</div>
                </div>;
              }

              return <div key={i} className={`item no-${i + 1} ${i === currentPlayerPos ? 'current' : ''}`}>
                <div className="avatar">
                  <img src={p.avatar ? p.avatar : avatar_image} alt="" />
                </div>
                <div className="pos">
                  <img src={PosBg} alt="" />
                  <div className="no">#{p.pos}</div>
                </div>
                <div className="score">{formatNumber(p.score, props.i18n.language)}</div>
              </div>
            })
          }

          <div className="container after"></div>
        </div>
        {currentPlayer &&
          <div className="gallery float">
            {
              !updating && players.map((_, i) => {

                if (currentPlayerPos !== i) return <div key={i} className={`item`}>&nbsp;</div>;

                return <div className={`item big crn`} key={'current-player-float'}>
                  <div className="avatar">
                    <img src={currentPlayer.avatar ? currentPlayer.avatar : avatar_image} alt="" />
                  </div>
                  <div className="pos">
                    <img src={PosBg} alt="" />
                    <div className="no">#{currentPlayer.pos}</div>
                    <div className="award-icon">
                      <img src={AwardIcon} alt="" />
                    </div>
                  </div>
                  <div className="score">{formatNumber(currentPlayer.score, props.i18n.language)}</div>
                </div>;
              })
            }
          </div>
        }
      </div>}
      <div className="menu close" onClick={closeGame}>
        <ClearIcon />
      </div>
      <div className="menu" onClick={showMenu}>
        <MoreIcon />
      </div>
      {openObjectivesDialog && <DialogObjectives open={openObjectivesDialog} onClose={handleCloseObjectivesDialog} tournament={tournament} groupUrl={props.groupUrl} />}
      {openTournamentsDialog && <DialogSwitchTournament open={openTournamentsDialog} onClose={handleCloseTournamentsDialog} />}
    </div>
  );

  /*
  <div onClick={animateLeft}>Left</div>
    <div onClick={animateRight}>Right</div>
    <div onClick={animateLeftOne}>Left One</div>
    <div onClick={animateRightOne}>Right One</div>
    */
}

const mapStateToProps = (state, props) => {
  return {
    authentication: state.authentication,
    rank: state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : {},
  }
};

const actions = {
  loadPlayerLeaderBoard,
  loadedPlayerLeaderBoard,
  closeGameLauncher
};

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(PlayerGallery)));