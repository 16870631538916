import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import JackpotCounter from "../NewCounter";
import BackgroundImage from "./../assets/img-bg-jackpot-header-front@3x.png";
import WinHistoryBG from "./../assets/winhistorybg.svg";
import "./WinHistoryExtended.scss";
import { formatDate, formatNumber, ucFirst } from "./../utils/formatters";

const styleBg = {
    backgroundImage: `url(${WinHistoryBG})`
};

class WinHistory extends Component {
    render() {
        const names = {
            "0": "Spades",
            "1": "Hearts",
            "2": "Diamonds",
            "3": "Clubs"
        };

        const onClick = this.props.onClick;
        const currency = ucFirst(this.props.currency);

        const clonedLevels = JSON.parse(JSON.stringify(this.props.levels));
        clonedLevels.sort((a, b) => {
            if (a.current < b.current) {
                return 1;
            }
            if (a.current > b.current) {
                return -1;
            }
            return 0;
        });
        clonedLevels.forEach(l => {
            if (
                typeof l.largestWin === "undefined" ||
                l.largestWin === null ||
                l.largestWin === 0
            ) {
                l.largestWinUser = "";
                l.largestWinDate = "N/A";
            }

            if (typeof l.lastWin === "undefined" || l.lastWin === null || l.lastWin === 0) {
                l.lastWinUser = "";
                l.lastWinDate = "N/A";
            }
        });

        return (
            <div className="winHistory" onClick={onClick}>
                {clonedLevels.map((level, index) => (
                    <div className="wrapperAll" key={index}>
                        <div
                            className="wrapper"
                            style={{ backgroundImage: `url(${BackgroundImage})` }}
                            key={index}
                        >
                            <div className="title" style={styleBg}>
                                {this.props.name} {names[index]}
                            </div>
                            <div className="counter">
                                {/*
                                <JackpotCounter
                                    value={level.current / 100}
                                    storageKey={`EGT_${index}`}
                                    format="( ddd).DD"
                                    theme="simplejackpot"
                                    minIntegerLen={1}
                                />
                                */}
                                <JackpotCounter
                                    value={level.current / 100}
                                    storageKey={`EGT_${index}`}
                                    theme="simple-counter"
                                    digits={5}
                                />
                                <div className="currency">{currency}</div>
                            </div>
                        </div>
                        <div className="wrapperText">
                            <div className="biggestWin">
                                <div className="text-left">
                                    <div className="muted-text">{this.props.t("Highest win")}</div>
                                    <div className="amount">
                                        {formatNumber(level.largestWin / 100)}{" "}
                                        <span className="cap">{currency}</span>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className="muted-text">{level.largestWinUser}</div>
                                    <div className="date">
                                        {formatDate(level.largestWinDate, this.props.i18n)}
                                    </div>
                                </div>
                            </div>
                            <div className="delimiter"></div>
                            <div className="latestWin">
                                <div className="text-left">
                                    <div className="muted-text">
                                        {this.props.t("Most recent win")}
                                    </div>
                                    <div className="amount">
                                        {formatNumber(level.lastWin / 100)}{" "}
                                        <span className="cap">{currency}</span>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className="muted-text">{level.lastWinUser}</div>
                                    <div className="date">
                                        {formatDate(level.lastWinDate, this.props.i18n)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

WinHistory.propTypes = {
    name: PropTypes.string,
    levels: PropTypes.array,
    onClick: PropTypes.func
};

WinHistory.defaultProps = {
    name: "",
    levels: [],
    onClick: () => {}
};

const mapStateToProps = state => {
    return {
        levels: state.jackpots.egtParsed.levels,
        currency: state.jackpots.egtParsed.currency
    };
};

export default withTranslation("translations")(connect(mapStateToProps)(WinHistory));
