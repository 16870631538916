import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Location from "./Location";
import * as GeoStates from "./../GeoStates";
import Icons from '../../../assets/Icons/Icons';

class LocationsList extends Component {

    handleSearch() {
        let input, filter, ul, li, item, i, txtValue;
        input = document.getElementById("SearchLocations");
        filter = input.value.toUpperCase();
        ul = document.getElementById("LocationsContainer");
        li = ul.getElementsByTagName("li");

        for (i = 0; i < li.length; i++) {
            item = li[i];
            txtValue = item.textContent || item.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }

    getLocations() {
        if (!(this.props.withdrawals.locations.length > 0)) {
            return null;
        }

        let storeLocations = this.props.withdrawals.locations;
        storeLocations.sort(function (a, b) {
            return a.locationName < b.locationName ? -1 : 1;
        })
        let locations = [];
        storeLocations.forEach((location, index) => {
            let locationComponent = (
                <li key={`side_location_${index}`}>
                    <Location elemId={location.id} />
                </li>
            );

            locations.push(locationComponent);
        });

        return locations;
    }

    getGeoCard() {

        if (GeoStates.NOT_AVAILABLE === this.props.geoStatus) {
            // geolocation is not available in this browser
            return null;
        }

        if (GeoStates.DENIED === this.props.geoStatus) {
            // client blocked geolocation request
            return null;
        }

        if (GeoStates.AVAILABLE === this.props.geoStatus) {
            // already available
            return null;
        }

        if (GeoStates.UNKNOWN === this.props.geoStatus) {
            return [];
        }
    }

    render() {
        let height = this.props.pageHeight - 74;
        let style = {
            height: `${height}px`,
            maxHeight: `${height}px`,
        };
        let placeholder = this.props.t("Search");

        return (
            <Fragment>
                <div className="SearchContainer">
                    <input type="text" id="SearchLocations" onKeyUp={this.handleSearch} placeholder={placeholder} autoComplete="off" />
                    {Icons.get("search", "SearchIcon")}
                </div>
                <div className={"LocationsList"} style={style}>
                    <ul id={'LocationsContainer'}>{this.getLocations()}</ul>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
    }
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LocationsList));
