/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 22/01/2019
 * Time: 15:31
 */
import React, {Component} from 'react';
import LoremContent from "../../LoremContent/LoremContent";


class DataProtection extends Component {
    render() {
        return (
            <LoremContent title={""} icon={false}/>
        );
    }
}

export default (DataProtection);