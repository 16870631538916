import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Menus from "./../../Utils/Menus/Menus";
class ResponsibleGaming extends Component {
    render() {
        let addictionNumber = "0800 800 099";
        return (
            <div className={"responsibleGaming"}>
                <p>{this.props.t("We are committed to deliver the best time you can have in our casino. Placing stakes and winning is a lot of fun. But losing is also part of the gambling thrill. Many people believe that gamble gaming is high-reward activity with low risk; it cannot be further from the truth. While Jackpots do hit and huge winnings occur, remember that mathematically the odds favor the house. People come up with strategies and lucky totems that guarantee the wins, some games involve some element of skill, but slots, blackjack, roulette and sports betting are all games of chance and no one can influence the outcome. ")}</p>
                <p>{
                    /* xgettext:no-javascript-format */
                    this.props.t("We strongly encourage that you play only when you feel 100% confident in your decisions and only the budget that you can afford to lose. Never play money that you need for a healthy life, for your bills and your loving ones. If you ever feel the urge to keep playing to recoup your loss, then STOP! If you can’t tell or feel guilty to tell other people how much money you have gambled, please consider you have some gambling addiction.")}</p>
                <p>{this.props.t("To find out to which degree you are addicted to gambling, please take the anonymous test at")} <a href={"https://jocresponsabil.ro/autotestare/"} target={"_blank"}>https://jocresponsabil.ro/autotestare/</a> </p>
                <p>{this.props.t("If you think you have a gambling addiction or you think a family member or a friend has a gambling problem, consider getting qualified help from")} <a href={"https://jocresponsabil.ro/"} target={"_blank"}>https://jocresponsabil.ro/</a> </p>
                <p>{this.props.t("Looking for immediate help? Call the free number")} {addictionNumber}, {this.props.t("Monday to Friday")} {this.props.t("from")} 10:00 {this.props.t("to")} 18:00 {this.props.t("and a specialist will answer and help you!")}</p>
                <p>{this.props.t("Also, have a look at the following tips that may help you have a healthy, pleasing gaming.")}</p>
                <p><b>{this.props.t("Play for fun, not to make money.")}</b> {this.props.t("Gaming is never a solution to any financial problems. Remember that the odds favor the house.")}</p>
                <p><b>{this.props.t("Stick to a budget.")}</b> {this.props.t("Set a budget, stick to it and never play for more than you can safely afford. You should never play more than your disposable income; that is the remaining money after you deduct from your income the daily-living expenses, rent, food, your loving ones’ needs, bills, etc. Also, never play with other people’s money and never burrow money to gamble.")}</p>
                <p><b>{this.props.t("Use limits.")}</b> {this.props.t("Don’t get carried away in the heat of play and deposit more than you planned initially. Use the built-in tools to set deposit limits to maximize the fun and minimize your frustration. If you are connected, you can set up")} <Menus parent={"simple"} pageType={"profile"} pageCode={"responsibly"} buttonText={this.props.t("Deposit Limits from here.")} /></p>
                <p><b>{
                    /* xgettext:no-javascript-format */
                    this.props.t("Play when you are 100% clear headed.")}</b> {this.props.t("Never play when you had alcohol or drugs because they will affect your decision making. Never play when you are sad or tired because you will not be able to think clearly.")}</p>
                <p><b>{this.props.t("Don’t raise the stakes to artificially create excitement.")}</b> {this.props.t("If you get bored of gaming, raising stakes to artificially inflate the thrill is never a good idea; it will only lead to losing your funds faster. It’s much better to stand up and have a walk or meet some friends. The excitement will return when you place the original stake after a break.")}</p>
                <p><b>{this.props.t("Spend your time wisely!")}</b> {this.props.t("Remember to get out in the real world and spend time and money with your friends and your loving ones as well. The key to happiness is a balanced life. This way you can enjoy all the activities more.")}</p>
                <p><b>{this.props.t("It is illegal for anyone under the age of 18 to gamble in our casino.")}</b></p>
            </div>
        );
    }
}

export default withTranslation()(ResponsibleGaming);