import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import SearchTemplate from "../SearchTemplate";
import LottoEvents from "./LottoEvents";

const useStyles = makeStyles({
  root: {
    position: "relative",
    minHeight: "calc(100vh - 85px)"
  },
  dropShadow: {
    boxShadow: "0px 3px 6px rgba(0,0,0,.16)",
    position: "absolute",
    top: 0,
    right: "8px",
    left: "8px",
    bottom: "8px",
    borderRadius: "14px 14px 0 0"
  }
});

function debounce(func, wait = 1000) {
  let timeout;
  function debounced(...args) {
    // eslint-disable-next-line consistent-this
    const that = this;
    const later = () => {
      func.apply(that, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

const LeftNav = props => {
  const classes = useStyles();

  const isMobile = useMediaQuery("(max-width:991px)");
  const { t } = props;

  const [searchValue, setSearchValue] = React.useState("");
  const [search, setSearch] = React.useState("");

  if (isMobile) {
    return null;
  }

  const handleSearchValueChange = (value, type) => {
    if (type === "clear") {
      setSearch("");
    }
    setSearchValue(value);
    if (value.length) {
      //debounce(() => {
      setSearch(value);
      //})();
    } else if (value === "") {
      setSearch("");
    }
  };
  const handleSearch = () => {
    setSearch(searchValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.dropShadow}>
        <SearchTemplate
          search={searchValue}
          onChange={handleSearchValueChange}
          onSearch={handleSearch}
          placeholder={t("Search events etc.")}
        />
        <LottoEvents search={search} />
      </div>
    </div>
  );
};

export default withTranslation()(LeftNav);
