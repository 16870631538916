import React, { useEffect, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import * as PIXI from "pixi.js";
import { gsap, TimelineMax, TweenMax, Power3, Power2, Linear, Draggable } from "gsap/all";
import { PixiPlugin } from "gsap/PixiPlugin";

import LeftArrow from "./../assets/leftArrow.svg";
import RightArrow from "./../assets/rightArrow.svg";

import { arrayProxy } from "./utils";
import Carousel from "./pixiObj";
import "./index.scss";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear, PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

const useStyles = makeStyles({
	root: {
		width: "100%",
		fontFamily: "Open Sans",
		backgroundColor: "#162151",
		userSelect: "none",
		paddingBottom: "0"
	},
	sliderWrapper: {
		position: "relative",
		overflow: "visible"
	},
	pb20: {
		paddingBottom: "20px"
	},
	betBanner: {
		padding: "0",
		position: "absolute",
		bottom: "0",
		left: "0",
		right: "0",
		overflow: "hidden",
		pointerEvents: "none",
	},
	betBannerSm: {
		left: "calc(9% + 10px)",
		right: "calc(9% + 10px)",
		bottom: "10px"
	},
	betBannerPosition: {
		bottom: "-24px",
		paddingBottom: "10px",
		left: "0",
		right: "0"
	}
});

const PixiCarousel = props => {
	const classes = useStyles(props);

	const { width, children } = props;

	//console.log("begin render", "width", width);

	const rootCreated = useCallback(node => {
		//console.log("root created", node);

		Carousel.setRootElement(node);
	}, []);

	useEffect(() => {
		//console.log("root", root, "width", width);

		//Carousel.setRootElement(root);
		//Carousel.resize(width);

		//const loader = PIXI.Loader.shared;

		const items = arrayProxy(
			React.Children.map(children, (c, i) => {
				//if (width === "sm" || width === "xs") {
				Carousel.loadResource("mobileimg" + i, c.props.mobileImage);
				//} else {
				Carousel.loadResource("webimg" + i, c.props.webImage);
				//}

				return {
					webImage: c.props.webImage,
					mobileImage: c.props.mobileImage,
					blendColor: c.props.blendColor,
					eventName: c.props.eventName
				};
			})
		);

		Carousel.loadResource("right", RightArrow);
		Carousel.loadResource("left", LeftArrow);

		Carousel.loader.load((loader, resources) => {
			Carousel.init(items, resources, width);
		});

		return () => {
			Carousel.destroyApp();
		};
	}, []); // eslint-disable-line

	useEffect(() => {
		Carousel.resize(width);
	}, [width]);

	let clsSliderWrapper = classes.sliderWrapper;
	let clsBetBanner = `${classes.betBanner}`;
	if (width === "xs" || width === "sm") {
		clsBetBanner = `${classes.betBanner} ${classes.betBannerSm} ${classes.betBannerPosition}`;
	}

	return (
		<div className={classes.root}>
			<div className={clsSliderWrapper}>
				<div ref={rootCreated} className="banner-pixi">
					<div className="slider-pixi">
						<div className="slider-wrapper"></div>
						{/*<LeftArrow onClick={prevSlide} />
						<RightArrow onClick={nextSlide} />*/}
					</div>
				</div>
				{React.Children.map(children, (c, i) => {
					return (
						<div key={i} className={clsBetBanner}>
							{c}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default withWidth()(PixiCarousel);
