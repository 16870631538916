import React, { useEffect, useState, Fragment } from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button} from 'reactstrap';
import CardList from './CardList/CardList';
import './Viva.scss';
import Utils from "../../utils/Utils";
import VivaAPI from "../../VivaApi";
import BackendClient from "../../BackendClient";
import Icons from "../../assets/Icons/Icons";
import Translate from "../../utils/Translate";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";
import ReactPixel, {AddPaymentInfo} from "react-facebook-pixel";
import ExpiringLocalStorage from "../../utils/ExpiringLocalStorage";
import paysafe from '../../assets/Images/Deposit/paysafe.png';
import okto from '../../assets/Images/Deposit/oktocash.png';

const Viva = (props) => {
    const { t } = useTranslation();

    const [selectedCard, setSelectedCard] = useState(null);
    const [showCVV, setShowCVV] = useState(false);
    const [checked, setChecked] = useState(true);
    const [cardData, setCardData] = useState(false);
    const [serverResponse, setServerResponse] = useState(false);
    const [loading,setLoading] = useState(true);
    const [playerData, setPlayerData] = useState({
        amount: props.amount,
        cvc: '',
        number: '',
        holderName: "",
        monthYear: "",
        month: "",
        year: "",
        bonusId: props.bonusId
        // cvc: 123,
        // number: '5188 3400 0000 0060',
        // holderName: "Jhon Doe",
        // monthYear: "12/25",
        // month: "12",
        // year: "25",
        // bonusGroupId: props.bonusGroupId
    });



    useEffect(() => {
        const axios = BackendClient.getInstance();
        axios({
            url: '/api/pay-checkout/viva-auth',
            method: 'get',
        }).then(function (response) {

            if (!response) {
                throw new Error(`[ERROR] Request Viva Token response is empty!`);
            }

            if (!response.hasOwnProperty('result')) {
                throw new Error(`[ERROR] Request Viva Token response has no 'result' property`);
            }

            if(response.result && response.result.access_token){
                VivaAPI.setToken(response.result.access_token);
                axios({
                    url: '/api/pay-checkout/card-token',
                    method: 'get',
                }).then(function (response) {
                    if (!response) {
                        throw new Error(`[ERROR] Request Viva Token response is empty!`);
                    }

                    if (!response.hasOwnProperty('result')) {
                        throw new Error(`[ERROR] Request Viva Token response has no 'result' property`);
                    }

                    setCardData(response.result);
                    if (typeof response.result[0] !== 'undefined') {
                        setSelectedCard(response.result[0].card_token);
                    } else {
                        Utils.injectScript(process.env.CFW.vivaUrl + '/web/checkout/v2/js','viva-script',vivaScriptInserted,{async: true});
                    }
                    setLoading(false);
                })


            }
        });

            setPlayerData({
                ...playerData,
                amount: props.amount,
                bonusId: props.bonusId
            });
        Utils.injectScript('https://code.jquery.com/jquery-1.12.4.min.js','viva-jquery',jqueryInserted,{
            integrity: "sha256-ZosEbRLbNQzLpnKIkEdrPv7lOy9C27hHQ+Xp8a4MxAQ=",
            crossorigin: 'anonymous'
        });

    }, []);

    useEffect(() => {
        // props.tempPlayerDataHandler(playerData); TODO:// fix this?
    }, [playerData]);

    useEffect(() => {
        if (loading) {
            ExpiringLocalStorage.set('loading_vctok','1',30)
        } else {
            window.localStorage.removeItem('loading_vctok')
        }
    },[loading])

    const jqueryInserted = () => {
        //Utils.injectScript(process.env.CFW.vivaUrl + paymentCheckout.JS_VIVA_PAYMENT,'viva-script',vivaScriptInserted,{async: true});
    }

    const vivaScriptInserted = () => {

        setTimeout(function () {
            let setupParams = {
                authToken: VivaAPI.getToken(),
                baseUrl: process.env.CFW.vivaApiUrl,
                cardHolderAuthOptions: {
                    cardHolderAuthPlaceholderId: 'validation3ds',
                    cardHolderAuthInitiated: function() {
                        document.getElementById('validation3ds').setAttribute('class','show');
                    },
                    cardHolderAuthFinished: function() {
                        document.getElementById('validation3ds').setAttribute('class','');
                    }
                }
            };
            window.VivaPayments.cards.setup(setupParams)
            window.VivaPayments.setBaseURL(process.env.CFW.vivaApiUrl);
        },500)
    }

    const handleChange = prop => event => {
        let value = event.target.value;
        setServerResponse(false);

        switch (prop) {
            case 'holderName':

                if (value.length <= 50) {
                    if(value.match(/^[a-zA-Z\s]*$/)){
                        setPlayerData({
                            ...playerData,
                            [prop]: value,
                        });
                    }
                }

                break;
            case 'number':
                if(value.match(/^[0-9\s]*$/) && value.length <= 19){
                    setPlayerData({
                        ...playerData,
                        [prop]: value.replace(/\D/g, "")
                                     .replace(/(\d{4})(\d)/, "$1 $2")
                                     .replace(/(\d{4})(\d)/, "$1 $2")
                                     .replace(/(\d{4})(\d)/, "$1 $2"),
                    });
                }
                break;
            case 'monthYear':
                value = value.replace(/\D/g,'');
                let month = '';
                let year = '';
                switch (value.length) {
                    case 1:
                        if (value.length === 1 && parseInt(value) > 1) {
                            value = '0' + value;
                        }
                        month = value;
                        break;
                    case 2:
                        if (parseInt(value[0]) === 1 && parseInt(value[1]) > 2) {
                            month = '0' + value[0];
                            year = value[1];
                            value = '0'+ value[0] + '/' + value[1];
                        } else {
                            month = value;
                        }
                        break;
                    case 3:
                        month = value[0] + value[1];
                        year = value[2];
                        value = value[0] + value[1] + '/' + value[2] ;
                        break;
                    case 4:
                        month = value[0] + value[1];
                        year = value[2] + value[3];
                        value = value[0] + value[1] + '/' + value[2] + value[3];
                        break;
                    default:
                        break;
                }
                setPlayerData({
                    ...playerData,
                    [prop]: value,
                    month: month,
                    year: year
                });
                break;
            case 'cvc':
                if(value.match(/^[0-9]*$/) && value.length <= 4){
                    setPlayerData({
                        ...playerData,
                        [prop]: value,
                    });
                }
                break;
            default:
                return;
        }
        return;
    };

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClickShowCVV = () => {
        setShowCVV(!showCVV);
    };

    const handleMouseDownCVV = (event) => {
        event.preventDefault();
    };

    const sendVivaChargeToken = (chargeToken, sendHolderName = true) => {
        const axios = BackendClient.getInstance();
        let data = {
            "chargeToken": chargeToken,
            "amount": props.amount,
            "bonusId": props.bonusId ? props.bonusId : -1
        };
        if(window.config && window.config.pixel_id) {
            async function digestMessage(message) {
                // encode as UTF-8
                const msgBuffer = new TextEncoder().encode(message);
                // hash the message
                const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
                // convert ArrayBuffer to Array
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                // convert bytes to hex string
                const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                return hashHex;
            }

            digestMessage(props.email)
                .then(encodedEmail => ReactPixel.track('AddPaymentMethod', {
                    user_data: {
                        email: encodedEmail
                    }
                }));
        }
        if (sendHolderName) {
            data.holderName = playerData.holderName;
        }
        let pac = PlayerAbuseChecker.getInfo();
        data = {
            ...pac,
            ...data
        }
        axios({
            url: '/api/pay-checkout/charge-token',
            method: 'post',
            data: data
        }).then(response => {
            if (!response) {
                throw new Error(`[ERROR] Viva Charge Token response is empty!`);
            }
            if (!response.hasOwnProperty('result')) {
                throw new Error(`[ERROR] Viva Charge Tokenresponse has no 'data' property`);
            }
            if (response.result.hasOwnProperty('ResponseCode')) {
                setServerResponse(`${response.result.ResponseMessage} (${response.result.ResponseCode})`);
            } else {
                if (response.status === 'OK') {
                    props.history.push('/payment/success?currency=RON&totalAmount=' + (props.amount/100))
                } else {
                    setServerResponse(props.t(`Something went wrong`));
                }
            }
            setLoading(false);
        })
    }

    const reqInProgress = () => {
        return ExpiringLocalStorage.get('loading_vctok') === '1' || loading;
    }

    const onRequestVivaChargeToken = () => {

        if (reqInProgress()) {
            return;
        }

        setLoading(true);
        window.VivaPayments.cards.requestToken({
            amount: props.amount
        }).done(function (data) {
            sendVivaChargeToken(data.chargeToken)
        }).fail( function () {
            let message = props.t('Something went wrong. Please verify the card data.');
            setServerResponse(message)
            setLoading(false);
        })
    }

    const selectCard = (card) => {
        if (!card) {
            Utils.injectScript(process.env.CFW.vivaUrl + '/web/checkout/v2/js','viva-script',vivaScriptInserted,{async: true});
        }
        setSelectedCard(card);
        setServerResponse(false);
    }
    const showSafecard = () => {
        return window.config && window.config.vivaDesktopEnabled === '1' && window.config.disable_safecharge !== '1';
    }

    const showOkto = () => {
        return window?.config?.okto_enabled === '1';
    }

    const onRequestVivaChargeTokenWithCard = () => {
        if (reqInProgress()) {
            return;
        }

        setLoading(true);
        const vivaAxios = VivaAPI.getInstance();
        let cardToken = selectedCard;

        vivaAxios({
            url: '/nativecheckout/v2/chargetokens',
            method: 'POST',
            data: {
                "token": cardToken,
                "amount": props.amount,
                "sessionRedirectUrl": window.location.protocol + '//' + window.location.hostname + (window.location.port ? ":" + window.location.port : '') + '/deposit-final',
            }
        }).then(function(response) {
            if (!response) {
                throw new Error(`[ERROR] Viva Charge Token With Card response is empty!`);
            }

            if (!response.hasOwnProperty('data')) {
                throw new Error(`[ERROR] Viva Charge Token With Card response has no 'chargeToken' property`);
            }
            if (response.data.hasOwnProperty('redirectToACSForm') && response.data.redirectToACSForm) {


                window.localStorage.setItem('deposit',JSON.stringify({
                    data: {
                        chargeToken: response.data.chargeToken
                    },
                    amount: props.amount,
                    bonusId: props.bonusId
                }));

                let wrapper = document.getElementById('validation3ds');
                wrapper.className = 'show';
                let range = document.createRange();
                let documentFragment = range.createContextualFragment(response.data.redirectToACSForm);
                wrapper.appendChild(documentFragment);

            } else {
                sendVivaChargeToken(response.data.chargeToken,false)
            }
        });
    }

    const clickHandler = () => {
        props.goToStep(6);
    }

    const oktoHandler = () => {
        props.goToStep(7);
    }

    let mainContent = <CircularProgress className={'Loading'} />;

    if(!props.paymentCheckout.loadingCardToken && !props.paymentCheckout.loadingAccessToken){
        let mainForm = null;
        if(selectedCard === null){
            mainForm = (
                <Fragment>
                    <p className="PageDescription">{t('Proceeding will imply the acceptance of secure storing of your card data.')}</p>
                    <div className={'PageForm'}>
                        <h2 className="FormTitle">{t('CARD DETAILS')}</h2>
                        <FormControl className="PlayerDataInput">
                            <InputLabel htmlFor="holderName">{t('Name')}</InputLabel>
                            <Input
                                id="holderName"
                                type='text'
                                autoComplete='off'
                                value={playerData.holderName}
                                onChange={handleChange('holderName')}
                            />
                            <input
                                id="holderNameHidden"
                                type='hidden'
                                value={playerData.holderName}
                                data-vp={'cardholder'}
                            />
                        </FormControl>
                        <FormControl className="PlayerDataInput">
                            <InputLabel htmlFor="number">{t('Card number')}</InputLabel>
                            <Input
                                id="number"
                                type='text'
                                autoComplete='off'
                                inputProps={{ maxLength: 19 }}
                                value={playerData.number}
                                onChange={handleChange('number')}
                            />
                            <input
                                id="numberhidden"
                                type='hidden'
                                value={playerData.number}
                                data-vp={'cardnumber'}
                            />
                        </FormControl>
                        <div className="SingleRow">
                            <FormControl className="PlayerDataInput">
                                <InputLabel htmlFor="monthYear">{t('MM/YY')}</InputLabel>
                                <Input
                                    id="monthYear"
                                    type='text'
                                    autoComplete='off'
                                    inputProps={{ maxLength: 5 }}
                                    value={playerData.monthYear}
                                    onChange={handleChange('monthYear')}
                                />
                                <input
                                    id="year"
                                    type='hidden'
                                    value={playerData.year}
                                    data-vp={'year'}
                                />
                                <input
                                    id="month"
                                    type='hidden'
                                    value={playerData.month}
                                    data-vp={'month'}
                                />
                            </FormControl>
                            <FormControl className="PlayerDataInput">
                                <InputLabel htmlFor="cvc">{t('CVV')}</InputLabel>
                                <Input
                                    id="cvc"
                                    type={showCVV ? 'text' : 'password'}
                                    autoComplete='off'
                                    inputProps={{ maxLength: 3 }}
                                    value={playerData.cvc}
                                    onChange={handleChange('cvc')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleClickShowCVV} onMouseDown={handleMouseDownCVV} >
                                                {showCVV ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <input
                                    id="cvchidden"
                                    type={'hidden'}
                                    value={playerData.cvc}
                                    data-vp={'cvv'}
                                />
                            </FormControl>
                        </div>

                    </div>
                </Fragment>
            );
        }else{
            mainForm = (
                <Fragment>
                    <p className="PageDescription">{t('Simple and convenient. Complete your transaction just with the amount.')}</p>
                    <div className={'PageForm'}>
                        <h2 className="FormTitle">{t('AMOUNT CONFIRM')}</h2>
                    </div>
                </Fragment>
            );
        }

        mainContent = (
            <div className="VivaScreenContainer">
                <CardList
                    cardData={cardData}
                    setCard={(card) => selectCard(card)}
                    selectedCard={selectedCard}
                />
                {mainForm}
                <div className="DepositAmount">
                    <FormControl className="PlayerDataInput">
                        <InputLabel htmlFor="amount">{t('Deposited amount')}</InputLabel>
                        <Input
                            id="amount"
                            type='text'
                            disabled={true}
                            value={(props.amount/100) + ' Lei'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => props.goToStep(1)}>
                                        <EditIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <div className="BonusAmount">
                        <span className="BonusTitle">{t('Deposit Bonus')}</span>
                        <p className="BonusValue">{props.bonusAmount}</p>
                    </div>
                </div>
                {serverResponse
                    ? (<div className="ReturnMessage"><p>{serverResponse}</p></div>)
                    : null
                }
                <div className="DepositButton">
                    <Button
                        className={'active gradient btn-danger w-100'}
                        disabled={loading}
                        onClick={ selectedCard === null
                            ? () => onRequestVivaChargeToken()
                            : () => onRequestVivaChargeTokenWithCard()
                        }
                    >{loading ? <CircularProgress className={'Loading'} /> : t('DEPOSIT')}</Button>
                </div>
            </div>
        );
    }
    return (
        <div className={"VivaScreen"}>
            {mainContent}
            {(showSafecard() || showOkto()) && <div  className={'actions-fixed'}>
                <p style={{"color":"#008ac9"}}>
                    <Translate text={"or make a deposit with"} />
                </p>
                {showOkto() && <div>
                    <img src={okto} onClick={oktoHandler}/>
                </div>}
                {showSafecard() && <div>
                    <img src={paysafe} alt={'paysafe card'} onClick={clickHandler} />

                    <div className={'description'}>
                        {Icons.get('newTab', 'newTab')}
                        <Translate text={"Pagina pentru finalizarea depunerii cu ${boldStart}PaysafeCard se va deschide intr-un tab nou${boldEnd}."} placeholder={{ boldStart: '<strong>', boldEnd: '</strong>' }} />
                    </div>

                </div>}
            </div>}
            <div id={'validation3ds'}></div>
        </div>
    );
};

const mapStateToProps = state => {
    let email = false;

    if (state.profile.player) {
        if (typeof state.profile.player.email !== "undefined") {
            email = state.profile.player.email;
        }
    }

    return {
        paymentCheckout: state.paymentCheckout,
        email: email
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // onRequestVivaToken: () => dispatch(storeActions.requestVivaToken()),
        // onRequestVivaCardToken: () => dispatch(storeActions.requestVivaCardToken()),
        // onRequestVivaChargeToken: (playerData) => dispatch(storeActions.requestVivaChargeToken(playerData)),
        // onRequestVivaChargeTokenWithCard: (cardToken, playerData) => dispatch(storeActions.requestVivaChargeTokenWithCard(cardToken, playerData)),
        // sendDeposit: (amount,data,bonusGroupId) => dispatch(storeActions.sendVivaChargeToken(data,amount,bonusGroupId))
    };
};

export default  withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Viva)));