import * as actionTypes from './actionTypes';
import BackendClient from "../../BackendClient";
import * as actions from "./index";

export const transactionsHistoryReceived = (response) => {
    return dispatch => {
        if (!response.hasOwnProperty("requestType")) {
            // console.error(`[ERROR] Received transactions history has no "RequestType"`);
            return; // dispatch an in page error and exit
        }

        let results = response.result;
        if (3 === response.requestType) {
            results = [];
            let state = BackendClient.getStore().getState();
            let currency = state.wallet.currency;
            if (response.result.hasOwnProperty(currency) && response.result[currency].hasOwnProperty('bonus')) {
                results = response.result[currency]['bonus'];
            }
        }

        dispatch({
            type: actionTypes.transactions.RECEIVED_HISTORY_TRANSACTIONS,
            responseType: response.requestType,
            pageData: results,
        });
    };
};

export const requestChangeHistoryList = (subpage, requestType=2, exportCsv=false) => {
    return dispatch => {
        if (!subpage.hasOwnProperty("requestPath")) {
            return;
        }

        let state = BackendClient.getStore().getState();
        if (state.transactions.generatingCsv) {
            return;
        }

        let requestPath = subpage.requestPath;

        let axios = BackendClient.getInstance();
        let data = {
            platformType: window.config.platformType,
            type: requestType,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        if (exportCsv) {
            data['export'] = true;
            // dispatch start loading on csv downloading
            return dispatch(requestHistoryCsv(dispatch, requestPath, axios, data));

        }

        if (!exportCsv) {
            dispatch({
                type: actionTypes.transactions.REQUEST_HISTORY_LIST,
                responseType: requestType,
            });
        }

        axios({
            url: '/api' + requestPath,
            method: 'post',
            data: data
        }).catch(e => {
            // console.log(`[CATCHED **SUBPAGE** ERROR] => ${e}`);
            dispatch(actions.contentError(e));
        });
    };
};

const requestHistoryCsv = (dispatch, requestPath, axios, data) => {
    return dispatch => {
        dispatch({
            type: actionTypes.transactions.GENERATING_CSV_DOWNLOAD,
            generating: true,
        });

        axios({
            url: '/api' + requestPath,
            method: 'post',
            data: data,
            responseType: 'blob',
        }).catch(e => {
            // console.log(`[CATCHED **DOWNLOAD PAGE** ERROR] => ${e}`);
            dispatch(actions.contentError(e));
            dispatch({
                type: actionTypes.transactions.GENERATING_CSV_DOWNLOAD,
                generating: false,
            });
        });
    };
};

export const downloadHistoryCsv = (response) => {
    return dispatch => {
        let fileName = `history_`;
        let reportIntType = 2;

        if (response.config.hasOwnProperty('data')) {
            let configData = JSON.parse(response.config.data);
            if (!(reportIntType === configData.type)) {
                reportIntType = configData.type;
            }
        }

        switch (reportIntType) {
            case 1:
                fileName += `game_spins`;
                break;
            case 2:
                fileName += `deposits_and_withdrawals`;
                break;
            case 3:
                fileName += `bonuses`;
                break;
            default:
                fileName += `unknown`;
                break;
        }

        let now = Date.now();
        fileName += `_${now.toString()}.csv`;

        // force download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        dispatch({
            type: actionTypes.transactions.GENERATING_CSV_DOWNLOAD,
            generating: false,
        });
    };
};

export const emptyCsvReport = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.transactions.EXPORT_IS_EMPTY,
        });
    }
};