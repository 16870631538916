import * as actionTypes from './actionTypes';

export const resizeMainBanner = () => {
    return dispatch => {
        let width = 1024;
        let height = 768;

        let bannerObj = document.getElementById('MainBanner');
        if (bannerObj) {
            width = bannerObj.clientWidth;
            height = bannerObj.clientHeight;
        }
        dispatch({
            type: actionTypes.mainBanner.RESIZE,
            width: width,
            height: height
        });
    };
};

// let animTimer = 0;
export const spineAnimation = (status) => {
    return dispatch => {
        // clearTimeout(animTimer);
        // animTimer = setTimeout(() => {
        //     dispatch({
        //         type: actionTypes.mainBanner.SPINE_ANIMATION,
        //         status: status,
        //     });
        // }, 100);
        dispatch({
            type: actionTypes.mainBanner.SPINE_ANIMATION,
            status: status,
        });
    };
};

let toggleTimer = 0;
export const showMainBanner = (mbType = null) => {
    return dispatch => {
        clearTimeout(toggleTimer);
        toggleTimer = setTimeout(() => {
            dispatch({
                type: actionTypes.mainBanner.SHOW,
                bType: mbType,
            });
        }, 1000);
    }
};

export const hideMainBanner = () => {
    return dispatch => {
        clearTimeout(toggleTimer);
        dispatch({
            type: actionTypes.mainBanner.HIDE,
        });
    }
};

export const changeMainBanner = (overwrite) => {
    return {
        type: actionTypes.mainBanner.CHANGE,
        overwrite: overwrite
    }
};

export const showBannerMessage = () => {
    return {
        type: actionTypes.mainBanner.SHOW_MESSAGE,
    }
};

export const changeMainBannerType = (mbType = 'spine') => {
    return {
        type: actionTypes.mainBanner.CHANGE_BANNER_TYPE,
        bannerType: mbType,
    };
};