import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./happy-hours.scss";
import Timer from "../../components/timer";
import GiftImage from "../../assets/gift@2x.png";
import { ReactComponent as UpArrowIcon } from "../../assets/up-arrow.svg";
import { ReactComponent as UpDownIcon } from "../../assets/down-arrow.svg";
import { happyHourShowInfo } from "../../../../store/actions/happy_hour";

import { closeGameLauncher } from "../../../../store/actions/games";
import DialogSwitchTournament from "../../../../tournaments-missions/dialogs/dialog-switch-tournament";

import IconDots from "../../assets/icon-dots.svg";
import IconClose from "../../assets/icon-close.svg";

const PortraitMode = ({ collapsed, onCollapsed, data, betInfo, happyHourShowInfo, t, closeGameLauncher, history }) => {

  const [state, setState] = React.useState(data);
  React.useEffect(() => {
    setState(data);
  }, [data]);

  const [bet, setBet] = React.useState(betInfo);
  React.useEffect(() => {
    setBet(betInfo);
  }, [betInfo]);

  const [openTournamentsDialog, setOpenTournamentsDialog] = React.useState(false);
  const handleCloseTournamentsDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(false);
  };
  const showMenu = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(true);
  };

  if (!state.enabled) return null;

  const toggleExpanded = (e) => {
    onCollapsed();
    e.stopPropagation();
  };

  const showInfo = (e) => {
    happyHourShowInfo();
  };

  const closeGame = () => {
    closeGameLauncher();
    if (window._CanGoBack) {
      history.goBack();
      return;
    }
    history.push("/cazino/jocuri");
  };

  const hasSpecialPrize = typeof data.next_special_prize !== "undefined" && data.next_special_prize ? true : false;

  const genPrize = (className, prize) => {
    const isSpecialPrize = prize && !prize.amount && typeof prize.custom !== "undefined" ? true : false;
    if (!isSpecialPrize) {
      return (
        <div className={`${className} ${!prize ? 'hidden' : ''}`}>{prize ? prize.amount : 0} Lei</div>
      )
    }

    return (
      <div className={`hh-special-prize ${!prize ? 'hidden' : ''}`}>
        <img className="hh-sp-img" src={prize.custom.thumbnail} alt="" />
        <div className="hh-sp-title">{prize.custom.title}</div>
      </div>
    )
  };

  const showWarning = bet && !bet.eligible;

  if (!collapsed) {
    return (
      <div className={`hh-parent ${showWarning ? 'warning' : ''}`} onClick={showInfo}>
        <div className={`hh-root hh-portrait ${showWarning ? 'warning' : ''}`}>
          <div className="hh-header">
            {hasSpecialPrize && <div className="hh-sp-left">
              <div className="hh-name ">{state.name}</div>
              <Timer date={state.date} className="" />
            </div>}
            {!hasSpecialPrize && <div className="hh-name w100">
              {state.name}
            </div>}

            <div className="hh-pills">
              <div className="hh-pill op-21"></div>
              <div className="hh-pill op-49"></div>
              <div className="hh-pill active">
                <span className="hh-current-prize">{state.current_prize}</span>
                <span className="hh-all-prizes">&nbsp;/ {state.total_prizes}</span>
              </div>
              <div className="hh-pill op-49"></div>
              <div className="hh-pill op-21"></div>
            </div>

            {hasSpecialPrize && <div className="hh-sp-right">
              <div className="hh-sp-title">
                {t("SPECIAL PRIZE")}
              </div>
              <div className="hh-sp-name">
                {data.next_special_prize.custom.title}
              </div>
            </div>}
            {!hasSpecialPrize && <Timer date={state.date} className="" />}
          </div>

          <div className="hh-prizes">
            {genPrize("hh-prize-before", state.prize_before)}
            <div className="hh-prize-current">
              <div className="hh-flx">
                <div className="hh-img">
                  {/*<GiftIcon />*/}
                  <img src={GiftImage} alt="" />
                </div>
                <div className="hh-txt">
                  {state.prize_current ? state.prize_current.amount : 0} Lei
                </div>
              </div>
              <div className="hh-bet">{t("Minimum Bet")}: {state.prize_current ? state.prize_current.min_bet : 0}</div>
            </div>
            {genPrize("hh-prize-after", state.prize_after)}
          </div>
          <div className="hh-sfooter-txt">
            <div className="hh-fi" onClick={closeGame}>
              <img src={IconClose} alt="" />
            </div>
            <div className="hh-fi" onClick={showMenu}>
              <img src={IconDots} alt="" />
            </div>
          </div>
        </div>
        <div className="hh-footer-txt">
          <div className="hh-ft">{t("Minimum bet for")} {state.name}: {bet && bet.min_bet ? bet.min_bet : 0} Lei</div>
          <div className="hh-fi" onClick={toggleExpanded}>
            <UpArrowIcon />
          </div>
        </div>

        {openTournamentsDialog && <DialogSwitchTournament open={openTournamentsDialog} onClose={handleCloseTournamentsDialog} />}
      </div>
    );
  }

  return <div className={`hh-parent-simple ${showWarning ? 'warning' : ''}`} onClick={showInfo}>
    <div className="hh-portrait-collapsed" >
      <div className="hh-name">
        {state.name}
      </div>
      <div className="hh-content">
        <div className="hh-pills-text">
          <span className="hh-current-prize">{state.current_prize}</span>
          <span className="hh-all-prizes">&nbsp;/ {state.total_prizes}</span>
        </div>
        <div className="hh-flx">
          <div className="hh-img">
            {/*<GiftIcon />*/}
            <img src={GiftImage} alt="" />
          </div>
          <div className="hh-txt">
            {state.prize_current ? state.prize_current.amount : 0} Lei
          </div>
        </div>
        <Timer date={state.date} type="simple" />
      </div>
      <div className="hh-fi" onClick={toggleExpanded}>
        <UpDownIcon />
      </div>
    </div>
    <div className="hh-footer-txt hh-full-height">
      <div className="hh-ft">{t("Minimum bet for")} {state.name}: {bet && bet.min_bet ? bet.min_bet : 0} Lei</div>
      <div className="hh-fi" onClick={toggleExpanded}>
        <UpDownIcon />
      </div>
    </div>
  </div>
};

const actions = {
  happyHourShowInfo,
  closeGameLauncher
};

export default withRouter(connect(null, actions)(withTranslation()(PortraitMode)));
