import { tablesConstants } from "../constants";
import { produce } from "immer";

// reduce the currency info in order to keep in memory only the data we need
const simplifyBetsLimits = (betLimits) => {
  const currencies = ['RON', 'EUR'];

  const res = {};

  Object.keys(betLimits).forEach(currency => {
    if (currencies.indexOf(currency) > -1) {
      res[currency] = betLimits[currency];
    }
  });

  return res;
};

const initialState = {
  state: {
    "players": 0,
    "tables": {},
  },
  filter: ""
};

const tablesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case tablesConstants.STATE_INI: {
        draft.state = action.data;
        break;
      }
      case tablesConstants.STATE_UPDATE: {
        const message = action.data;

        if (message.type !== "TableAssigned" && typeof draft.state.tables[message.tableId] === "undefined") {
          console.log("Can't update evolution table. It doesn't exists", JSON.stringify(message));
          return;
        }

        switch (message.type) {
          case "TableAssigned":
            draft.state.tables[message.tableId] = message.table;
            break;
          case "TableUnassigned":
            delete draft.state.tables[message.tableId];
            break;
          case "TableOpened":
            draft.state.tables[message.tableId].open = true;
            break;
          case "TableClosed":
            draft.state.tables[message.tableId].open = false;
            break;
          case "TableUpdated":
            if (message.table && message.table.betLimits) {
              message.table.betLimits = simplifyBetsLimits(message.table.betLimits);
            }
            draft.state.tables[message.tableId] = Object.assign(draft.state.tables[message.tableId], message.table);
            break;
          case "DealerChanged":
            draft.state.tables[message.tableId].dealer = message.dealer ? message.dealer : [];
            break;
          case "DealerLeft":
            draft.state.tables[message.tableId].dealer.left = true;
            break;
          case "SeatsUpdated":
            draft.state.tables[message.tableId].seatsTaken = message.seatsTaken;
            break;
          case "DealerHandUpdated":
            draft.state.tables[message.tableId].dealerHand = message.dealerHand;
            break;
          case "RouletteNumbersUpdated":
            draft.state.tables[message.tableId].results = message.results ? message.results : [];
            if (typeof message.luckyNumbers !== "undefined") {
              draft.state.tables[message.tableId].luckyNumbers = message.luckyNumbers ? message.luckyNumbers : [];
            }
            break;
          case "DiceResultsUpdated":
            draft.state.tables[message.tableId].results = message.results ? message.results : [];
            break;
          case "BaccaratRoadUpdated":
            draft.state.tables[message.tableId].road = message.road ? message.road : [];
            draft.state.tables[message.tableId].history = message.history ? message.history : [];
            break;
          case "DragonTigerRoadUpdated":
            draft.state.tables[message.tableId].road = message.road ? message.road : [];
            draft.state.tables[message.tableId].history = message.history ? message.history : [];
            break;
          case "TopCardHistoryUpdated":
            draft.state.tables[message.tableId].history = message.history ? message.history : [];
            break;
          case "MoneyWheelNumbersUpdated":
            draft.state.tables[message.tableId].results = message.results ? message.results : [];
            break;
          case "MonopolyResultsUpdated":
            draft.state.tables[message.tableId].results = message.results ? message.results : [];
            break;
          case "CrazyTimeResultsUpdated":
            draft.state.tables[message.tableId].results = message.results ? message.results : [];
            break;
          case "MegaBallResultsUpdated":
            draft.state.tables[message.tableId].results = message.results ? message.results : [];
            break;
          case "PlayersUpdated":
            draft.state.tables[message.tableId].players = message.players ? message.players : 0;
            break;
          case "OperationHoursUpdated":
            draft.state.tables[message.tableId].operationHours = message.operationHours ? message.operationHours : {};
            break;
          default:
            //noop
            break;
        }

        break;
      }
      default:
        //noop
        break;
    }
  });

export default tablesReducer;