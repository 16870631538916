/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 21/11/2018
 * Time: 15:27
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ResponsibleGaming.scss';
import ChangeLimit from "./Parts/ChangeLimit/ChangeLimit";
import { withTranslation } from "react-i18next";
import { sprintf } from 'sprintf-js';
import ValueCurrency from '../Wallet/Parts/ValueCurrency';
import * as stateActions from '../../../../store/actions';

const PERIOD_TYPE_DAILY = 1;
const PERIOD_TYPE_WEEKLY = 2;
const PERIOD_TYPE_MONTHLY = 3;
const TYPE_DEPOSIT = 1; //DEPOZIT
const TYPE_WAGE = 2; // rulaj
const TYPE_LOST = 3; // pierderi


class ResponsibleGaming extends Component {

    state = {
        limitType: this.getType(TYPE_DEPOSIT),
        instantiated: false,
        limits: {},
    };

    createLimitVariable() {
        let limits = {};
        let propLimits = this.props.limits;
        // for each type
        Object.keys(propLimits).forEach((key) => {

            if (propLimits[key] && typeof propLimits[key].type !== "undefined") {

                let type = this.getType(propLimits[key].type);
                let subtype = this.getSubType(propLimits[key].period_type);
                if (typeof limits[type] === "undefined") {
                    limits[type] = {};
                }
                limits[type][subtype] = {
                    targetAmmount: propLimits[key].limit_amount,
                    ammount: propLimits[key].accumulated_amount,
                    id: propLimits[key].period_type,
                    currency: this.props.currency,
                    end: propLimits[key].limit_end,
                    /**
                     * null         - new limit not found
                     * -1           - current limit will be removed
                     * 0-MAX_INT    - value of the new limit the will be applied at propLimits[key].limit_end
                     */
                    new_limit_ammount: propLimits[key].new_limit_amount,
                }
            }
        });

        this.setState({
            ...this.state,
            limits: limits
        })
    }
    getSubType(subtype) {
        switch (parseInt(subtype, 10)) {
            case PERIOD_TYPE_DAILY:
                return this.props.t("daily");
            case PERIOD_TYPE_WEEKLY:
                return this.props.t("weekly");
            case PERIOD_TYPE_MONTHLY:
                return this.props.t("monthly");
            default:
                return this.props.t("others");
        }
    }
    getType(type) {
        switch (parseInt(type, 10)) {
            case TYPE_DEPOSIT:
                return this.props.t("Deposit");
            case TYPE_WAGE:
                return this.props.t("Rolling");
            case TYPE_LOST:
                return this.props.t("Losses");
            default:
                return this.props.t("Others");
        }
    }
    componentDidMount() {
        // if (typeof this.props.limits[this.state.limitType] === "undefined") {
        //     let fixLimitType = false;
        //     Object.keys(this.props.limits).forEach((key) => {
        //         if (!fixLimitType) {
        //             fixLimitType = key;
        //         }
        //
        //     });
        this.setState({
            ...this.state,
            // limitType: fixLimitType,
            instantiated: true
        })
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props.limits !== prevProps.limits) {
            this.createLimitVariable();
        }
        if (this.props.lang !== prevProps.lang) {
            this.createLimitVariable();
        }
    }

    showType(e) {
        this.setState({
            ...this.state,
            limitType: e.target.getAttribute('data-type'),
        });
    }

    getComponent(type, opt) {
        let comp = [];

        if (!this.state.instantiated) return comp;

        switch (type) {
            case 'entirePage':
                let topComp = this.getComponent('pageTop');


                let limitsComp = this.getComponent(this.state.limitType);
                comp.push(
                    <div id={"limit-page"} key={"limit-page"}>
                        {topComp}
                        {limitsComp}
                    </div>
                );
                break;
            case 'alert':
                if (typeof opt.limit !== 'undefined') {
                    if (opt.limit.end !== null) {
                        if (opt.limit.new_limit_ammount !== null) {
                            let appliedLimits = this.props.limitApplied;
                            let appliedLimit = false;
                            if (appliedLimits) {
                                let _self = this;
                                Object.keys(appliedLimits).forEach(function (type, value) {
                                    if (_self.getType(type) === opt.type) {

                                        Object.keys(appliedLimits[type]).forEach(function (subtype, value) {
                                            if (_self.getSubType(subtype) === opt.subtype) {
                                                appliedLimit = appliedLimits[type][subtype];
                                            }
                                        })

                                    }
                                });
                            } else {
                                appliedLimit = false;
                            }

                            if(opt.limit.new_limit_ammount >= opt.limit.targetAmmount){
                                comp.push(<div className="alert danger text-center font-weight-bold" key={"alert-" + opt.limit.id}>
                                    {sprintf(this.props.t("The new limit of %s will be activated in 48 hours"),appliedLimit ? appliedLimit : opt.limit.new_limit_ammount)}
                                </div>);
                            }else{
                                this.props.startLoading();
                                window.location.reload(true);
                            }
                        } else {
                            comp.push(<div className="alert danger text-center font-weight-bold" key={"alert-" + opt.limit.id}>
                                {this.props.t("This limit will be changed to unlimited in 48 hours")}
                            </div>);
                        }
                    }
                }
                break;
            case 'pageTop':
                let headerItemsComp = [];
                let infoShowed = this.state.limitType;
                Object.keys(this.state.limits).forEach((key) => {
                    let className = key === infoShowed ? " active" : "";
                    headerItemsComp.push(
                        <div className={"limit-item" + className} key={"limit-item-" + key} data-type={key} onClick={this.showType.bind(this)}>
                            {key}
                        </div>
                    );
                });

                // TODO: remove coming soon items
                let key = this.props.t("Rolling");
                headerItemsComp.push(
                    <div className={"limit-item coming-soon"} key={"limit-item-" + key} data-type={key}>
                        {key}
                        <div className={"coming-soon"}>
                            <span>{this.props.t("coming soon")}</span>
                        </div>
                    </div>
                );
                key = this.props.t("loss");
                headerItemsComp.push(
                    <div className={"limit-item coming-soon"} key={"limit-item-" + key} data-type={key}>
                        {key}
                        <div className={"coming-soon"}>
                            <span>{this.props.t("coming soon")}</span>
                        </div>
                    </div>
                );
                comp.push(
                    <div className="header-limits" key={"header-limits"}>
                        {headerItemsComp}
                    </div>
                );
                break;
            case 'limit':
                let limit = this.state.limits[opt.type][opt.subtype];

                if (limit.targetAmmount !== null) {
                    let percent = parseInt((limit.ammount / limit.targetAmmount) * 100, 10);
                    if (isNaN(percent)) percent = 0;
                    let available = limit.targetAmmount - limit.ammount;
                    if (available < 0) available = 0;
                    if (percent > 100) percent = 100;
                    comp.push(
                        <div id={`limit-${opt.type}-${opt.subtype}`} className={"limit-widget"} key={`limit-${opt.type}-${opt.subtype}`}>
                            <div className="details">
                                <div className="limit-type">{opt.subtype}</div>
                                <div className="clearfix"> </div>
                                <div className="value">
                                    <ValueCurrency value={limit.targetAmmount} currency={limit.currency} />
                                    <span className="remained">
                                        <ValueCurrency value={available} currency={limit.currency} /> {this.props.t("available")}
                                    </span>
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: percent + '%' }}> </div>
                                    </div>
                                    <div className="consumed">
                                        <div className="percent">
                                            {percent}% <span className="description">{this.props.t("consumed")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.getComponent('alert', { limit: limit, type: opt.type, subtype: opt.subtype })}
                            <ChangeLimit limitId={limit.id} initialValue={limit.targetAmmount} currency={this.props.currency} />
                            <div className="line"> </div>
                        </div>
                    );
                } else {
                    comp.push(
                        <div id={`limit-${opt.type}-${opt.subtype}`} className={"limit-widget"} key={`limit-${opt.type}-${opt.subtype}`}>
                            <div className="details">
                                <div className="limit-type">{opt.subtype}</div>
                                <div className="clearfix"> </div>
                                <div className="value">{this.props.t("Unlimited")}</div>
                            </div>
                            <ChangeLimit limitId={limit.id} initialValue={limit.targetAmmount} />
                            <div className="line"> </div>
                        </div>
                    );
                }
                break;
            default:
                if (typeof this.state.limits[type] !== "undefined") {
                    Object.keys(this.state.limits[type]).forEach((key) => {
                        comp.push(this.getComponent('limit', { type: type, subtype: key }));
                    });
                }

                break;
        }

        return comp;
    }


    render() {

        let comp = this.getComponent("entirePage");

        return (
            <div>
                {comp}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch(stateActions.showContentLoader()),
    };
};

const mapStateToProps = state => {
    return {
        limits: state.profile.pageData,
        currency: state.wallet.currency,
        lang: state.application.language,
        limitApplied: state.profile.limits.limitApplied
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ResponsibleGaming));
