import {put, takeEvery, takeLatest} from "redux-saga/effects";
import {registerForm} from "../actions/actionTypes";
import * as actions from "../../../src/store/actions";
import BackendClient  from "../../BackendClient";
import ExpiringLocalStorage from "../../utils/ExpiringLocalStorage";
import Utils from "../../utils/Utils";
import * as MethodTypes from "../../elements/AuthenticationModal/MethodTypes";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";
import ReactPixel from "react-facebook-pixel";
import GoogleAds from "../../../src/utils/GoogleAds";

const REGISTER_MAP = {
    attributes: {
        cnp: "nin",
        phone: "phone",
        firstName: "first_name",
        lastName: "last_name",
        ocr_scan: "ocr_scan",
        idExpired: "id_expired",
        marketing: "marketing",
        marketing_sms: "marketing_sms",
        marketing_phone: "marketing_phone",
        marketing_email: "marketing_email",
        moid: "moid",
        refferenceCode: "aff_id",
        lpid: "lpid",
        address: "address"
    },
};

export function* setPartialAccountSaga(action) {

    let fields = BackendClient.getStore().getState().register.fields;
    const axios = BackendClient.getInstance();

    fields.paid = yield ExpiringLocalStorage.get('paid');
    if (!fields.paid) {
        fields.paid = yield generateUUID();
        ExpiringLocalStorage.set('paid',fields.paid);
    }
    fields.aff_id = yield Utils.getCookie('aff_id');
    fields.sub_id = yield Utils.getCookie('sub_id');
    fields.gclid = yield Utils.getCookie('gclid');
    fields.lpid = yield ExpiringLocalStorage.get('lpid');
    fields.moid = yield ExpiringLocalStorage.get('moid');

    let lastProductId = yield BackendClient.getStore().getState().application.lastProductId;
    if (lastProductId) {
        fields.product_id = yield lastProductId;
    }

    try {

        Object.keys(REGISTER_MAP.attributes).forEach(attr => {
            if (fields[attr]) {
                fields[REGISTER_MAP.attributes[attr]] =  fields[attr];
            }
        })

        if(action.phoneValidation) {
            fields.phoneValidationCode = yield fields.phoneValidationCode;
        } else {
            fields.phoneValidationCode = null;
        }

        const response = yield axios({
            url: '/api/register/setPartial',
            method: 'post',
            data: fields,
        });

        if (!response.status) {

            throw new Error(`[ERROR] REGISTER missing status!`);
        }

        if (!("OK" === response.status)) {

            throw new Error(`[ERROR] REGISTER Validation not triggered!`);
        }

        if (action.phoneValidation) {
            yield put(actions.fieldEntered({ field: 'paid', value: response.result.paid, validated: true}));
            ExpiringLocalStorage.set('paid',response.result.paid);

            const currentStep = BackendClient.getStore().getState().register.currentStep;
            ExpiringLocalStorage.set('phoneValidation',JSON.stringify({step: currentStep, phone: fields.phone}),1800);
            yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: fields.phoneValidationCode, validated: true}));
            yield put(actions.saveRegisterFields(true));
            yield put(actions.setCurrentStep(3));

        }
    } catch (error) {
        console.log('-----------------');
        console.log(error);
        console.log('-----------------');
        window.localStorage.removeItem('phoneValidationCode');
        yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: '', validated: false}));
    }
}
function* generateUUID() {
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return yield 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export function* saveRegisterFieldsSaga(action) {
    let fields = BackendClient.getStore().getState().register.fields;
    let validated = BackendClient.getStore().getState().register.validated;
    let toSave = {};

    Object.keys(fields).forEach((key)  => {
        if (validated[key] && key !== 'password' && key !== 'confirmPassword' && key !== 'phoneValidationCode') {
            toSave[key] = fields[key];
        }
    })

    // // regenerate paid for fraud reasons.
    let oldRegisterFields = ExpiringLocalStorage.get('registerFields');
    if (oldRegisterFields) {
        oldRegisterFields = JSON.parse(oldRegisterFields);
        if ((oldRegisterFields.nin && oldRegisterFields.nin !== toSave.nin) || (oldRegisterFields.phone && oldRegisterFields.phone !== toSave.phone)) {
            const uuid = yield generateUUID();
            yield ExpiringLocalStorage.set('paid',uuid);
        }
    }

    yield ExpiringLocalStorage.set('registerFields',JSON.stringify(toSave));
    if (!action.onlyLocal) {
        yield put(actions.setPartialAccount())
    }
}
export function* signUpSaga(action) {

    let fields = BackendClient.getStore().getState().register.fields;
    let lastProductId = BackendClient.getStore().getState().application.lastProductId;
    const axios = BackendClient.getInstance();

    if (typeof fields.cnp !== "undefined") {
        fields.nin = fields.cnp;
    }


    if (window.config && window.config.cordova) {
        const apps_flyer_af_sub1 = localStorage.getItem("apps_flyer_af_sub1");
        if (apps_flyer_af_sub1) {
            fields.refferenceCode = apps_flyer_af_sub1;
        }
    }

    fields.refferenceCode = Utils.getCookie('aff_id');
    fields.sub_id = yield Utils.getCookie('sub_id');
    fields.gclid = Utils.getCookie('gclid');
    fields.marketingAgreement = fields.marketing ? 1 : 0;
    fields.marketing_sms = fields.marketing_sms ? 1 : 0;
    fields.marketing_email = fields.marketing_email ? 1 : 0;
    fields.marketing_phone = fields.marketing_phone ? 1 : 0;
    fields.marketing_partners = fields.marketing_partners ? 1 : 0;
    fields.lpid = ExpiringLocalStorage.get('lpid');
    fields.moid = ExpiringLocalStorage.get('moid');
    if (action.oldVersion) {
        fields.paid = null;
    } else {
        fields.paid = ExpiringLocalStorage.get('paid');
        fields.responseType = 'register-v2';
    }

    let winnerFun = Utils.getQueryParam('winner_fun');
    if (winnerFun === 'true') {
        fields.bonusCoins = 1;
        let winner_referral_id = Utils.getQueryParam('winner_referral_id');
        if (winner_referral_id) {
            fields.winner_referral_id = winner_referral_id;
        }
    }

    if (lastProductId) {
        fields.product_id = lastProductId
    }
    let pac = PlayerAbuseChecker.getInfo();
    fields = {
        ...pac,
        ...fields
    }
    // yield put(actions.startSignUpProcess());
    try {

        const response = yield axios({
            url: '/api/register',
            method: 'post',
            data: fields,
        });

        if (!response.status) {
            yield put(actions.signUpResponseReceived(response));
            throw new Error(`[ERROR] REGISTER missing status!`);
        }

        if (!("OK" === response.status)) {
            yield put(actions.signUpResponseReceived(response));
            throw new Error(`[ERROR] REGISTER Validation not triggered!`);
        }
        //
        yield put(actions.signUpResponseReceived(response));
        //
        // //login
        yield put(actions.setModalMethod(MethodTypes.METHODS.email));
        yield put(actions.auth(fields.email, fields.password));

        if(window.config && window.config.pixel_id) {
            ReactPixel.track('CompleteRegistration');
        }

        if (window.config && window.config.google_ads_id && window.config.google_ads_register_label) {
            GoogleAds.pushConversion({
                'send_to': `${window.config.google_ads_id}/${window.config.google_ads_register_label}`
            })
        }

        try {
            if (window.config && window.config.cordova && window.plugins && window.plugins.appsFlyer && window.plugins.appsFlyer.logEvent) {
                window.plugins.appsFlyer.logEvent("af_complete_registration", { "af_registration_method": "Email" }, () => { }, () => { });
            }
        } catch (err) { }

    } catch (error) {
        yield put(actions.signUpResponseReceived({
            result: {
                ResponseCode: 500
            }
        }));
    }
}

export function* sendSMSSaga(action) {
    const storeState = BackendClient.getStore().getState();

    const axios = BackendClient.getInstance();

    try {
        yield put(actions.setTimer(window.config.register_sms_timer ? window.config.register_sms_timer : 14));
        yield put(actions.fieldEntered({
            field: "code",
            value: '',
            validated: false
        }));
        yield put(actions.fieldEntered({
            field: "phoneValidationCode",
            value: "",
            validated: false
        }))

        const response = yield axios({
            url: 'api/register/verifyPhone',
            method: 'post',
            data: {
                phone: storeState.register.fields.phone,
                responseType: 'send-sms'
            }
        });

        if (!response.status) {
            throw new Error(`[ERROR] SEND SMS missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] SEND SMS Validation not triggered!`);
        }
        if (typeof response.result !== 'undefined' && typeof response.result.type !== "undefined") {
            yield put(actions.codeSent())
        }
    } catch (error) {
        console.log(error);
    }
}

export function* validatePhoneSaga(action) {

    // const axios = BackendClient.getInstance();

    try {
        // const phone = BackendClient.getStore().getState().register.fields.phone;
        // const currentStep = BackendClient.getStore().getState().register.currentStep;
        // const phoneValidationCode = BackendClient.getStore().getState().register.fields.phoneValidationCode;
        // yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: phoneValidationCode, validated: false}));
        //
        // const response = yield axios({
        //     url: 'api/register/verifyPhone',
        //     method: 'post',
        //     data: {
        //         phone: phone,
        //         code: phoneValidationCode,
        //         responseType: 'validate-phone'
        //     }
        // });
        //
        // if (!response.status) {
        //     throw new Error(`[ERROR] SEND SMS missing status!`);
        // }
        //
        // if (!("OK" === response.status)) {
        //     throw new Error(`[ERROR] SEND SMS Validation not triggered!`);
        // }
        // ExpiringLocalStorage.set('phoneValidation',JSON.stringify({step: currentStep, phone: phone}),1800);
        // yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: phoneValidationCode, validated: true}));
        yield put(actions.setPartialAccount(true));
    } catch (error) {
        // window.localStorage.removeItem('phoneValidationCode');
        // yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: '', validated: false}));
        //
        // console.log(error);
    }
}

export default function* watchRegisterSaga() {
    yield takeEvery(registerForm.SET_PARTIAL_ACCOUNT, setPartialAccountSaga);
    yield takeLatest(registerForm.SAVE_REGISTER_FIELDS, saveRegisterFieldsSaga);
    yield takeEvery(registerForm.SIGN_UP, signUpSaga);
    yield takeEvery(registerForm.SEND_SMS, sendSMSSaga);
    yield takeEvery(registerForm.VALIDATE_PHONE, validatePhoneSaga)
}