import { combineReducers } from 'redux';

import authReducer from './auth';

import betsReducer from './bets';
// import liveReducer from "./live";
import liveReducer from './live_digitain';
import navSportsReducer from './navSports';
import searchReducer from './search';
import preMatchFilterReducer from './preMatchFilter';
import betsSlipReducer from './betsSlip';
import prematchReducer from './prematch';
import matchDetailsReducer from './matchDetails.js';
//import leagueDetailsReducer from "./leagueDetails.js.skip";
import ticketsReducer from './tickets';
import ticketsWinnerFunReducer from './tickets_wf';
import appReducer from './app';
import favoriteReducer from './favorite';
import configReducer from './config';
import nSoftReducer from './nsoft';
import layoutReducer from './layout';
import calendarReducer from './calendar';
import statScoreReducer from './statScore';
import cashoutReducer from './cashout';

export const betsRootReducer = combineReducers({
    auth: authReducer,
    bets: betsReducer,
    live: liveReducer,
    navSports: navSportsReducer,
    search: searchReducer,
    preMatchFilter: preMatchFilterReducer,
    betsSlip: betsSlipReducer,
    prematch: prematchReducer,
    matchDetails: matchDetailsReducer,
    //leagueDetails: leagueDetailsReducer,
    tickets: ticketsReducer,
    ticketsWinnerFun: ticketsWinnerFunReducer,
    app: appReducer,
    favorite: favoriteReducer,
    config: configReducer,
    nsoft: nSoftReducer,
    layout: layoutReducer,
    calendar: calendarReducer,
    statScore: statScoreReducer,
    cashout: cashoutReducer,
});

const rootReducer = combineReducers({
    bets: betsRootReducer,
});

export default rootReducer;
