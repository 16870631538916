import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withRouter } from 'react-router-dom';
import moment from "moment";

import "./cta-lobby.scss";

import { styleToObj } from "../../lobby/utils/functions";
import SimpleTimer from "./simple_timer";
import { loadWheelStatus } from "../store/actions/wheel";

import QuestionLogo from "../assets/question.svg";
import Rules from './rules';
import EvBusMain from "../../utils/evbus";
import { imgPath } from "../utils/functions";
import KeysRulesDialog from "./keys-rules";

const ctaBg = {
  backgroundImage: `url(${imgPath('wheel-cta-active.png')})`,
};
const ctaBgDisabled = {
  backgroundImage: `url(${imgPath('wheel-cta-disabled.png')})`,
};

const WheelCTA = (props) => {
  const { data, wheelStatus, t, authentication } = props;

  const [open, setStateOpen] = React.useState(false);
  const openDialog = () => {
    let wagerNeeded = 0;
    if (wheelStatus && wheelStatus.free_spin && wheelStatus.free_spin.wager_needed) {
      wagerNeeded = wheelStatus.free_spin.wager_needed;
    }
    if (wagerNeeded) {
      setOpenKeysRules({
        open: true,
        type: "free",
      });
      return;
    }

    setStateOpen(true);
  };
  const closeDialog = () => {
    setStateOpen(false);
  };

  const [openKeysRules, setOpenKeysRules] = React.useState({
    open: false,
    type: "",
  });
  const handleCloseRules = () => {
    setOpenKeysRules({
      open: false,
      type: "",
    });
  };

  React.useEffect(() => {
    props.loadWheelStatus();
  }, []); // eslint-disable-line

  if (!wheelStatus) return null;
  if (wheelStatus && wheelStatus.status === "not_eligible") {
    if (wheelStatus.reason && wheelStatus.reason.conflicts && typeof wheelStatus.reason.conflicts[1002] !== "undefined") {
      return null;
    }
  }

  const isNextSpin = () => {
    if (wheelStatus && wheelStatus.free_spin && wheelStatus.free_spin.next_spin_after) {
      return true;
    }
    return false;
  }
  const reloadStatus = () => {
    props.loadWheelStatus();
  };
  const goToWheel = () => {
    if (window.config.platformType === 1) {
      EvBusMain.emit("OPEN-WHEEL-DIALOG");
      return;
    }
    props.history.push("/wheel");
  }

  if (["user", "token"].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
    return null;
  }

  let hasWheelAvailable = wheelStatus && ((wheelStatus.free_spin && wheelStatus.free_spin.status === "available") || (wheelStatus.keys_spin && wheelStatus.keys_spin.status === "available"));

  return (
    <div className={`component-wheel_status component`} style={styleToObj(data.style)} >
      <div className={`cgl-cta ${hasWheelAvailable ? '' : 'disabled'}`} style={hasWheelAvailable ? ctaBg : ctaBgDisabled} onClick={!isNextSpin() && !hasWheelAvailable ? openDialog : goToWheel}>
        <div className="wheel-logo">
          <img src={imgPath('wheel-icon-big.png')} alt="" />
        </div>
        {isNextSpin() &&
          <div className="wheel-spin-next">
            <div className="wheel-count-down">
              <div className="txt">{t("Available at")}</div>
              <div className="count"><SimpleTimer date={moment(wheelStatus.free_spin.next_spin_after, "x")} onLive={reloadStatus} time={true} /></div>
            </div>
            <div className="wheel-arrow">
              <NavigateNextIcon />
            </div>
          </div>
        }
        {!isNextSpin() && hasWheelAvailable &&
          <div className="wheel-spin-now">
            <div className="wheel-spin-button">{t("SPIN NOW")}</div>
            <div className="wheel-arrow">
              <NavigateNextIcon />
            </div>
          </div>
        }
        {!isNextSpin() && !hasWheelAvailable &&
          <div className="wheel-cant-spin">
            <div className="wheel-spin-button-denied">{t("Not eligible? Find out why")}</div>
            <div className="wheel-why">
              <img src={QuestionLogo} alt="" />
            </div>
          </div>
        }

      </div>
      {open && <Rules open={open} status={wheelStatus} onClose={closeDialog} />}
      {openKeysRules.open && <KeysRulesDialog open={openKeysRules.open} status={wheelStatus} type={openKeysRules.type} onClose={handleCloseRules} />}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    wheelStatus: state.wheel && state.wheel.wheel && state.wheel.wheel.status ? state.wheel.wheel.status : null,
    authentication: state.authentication
  }
};
const mapActions = {
  loadWheelStatus
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapActions)(WheelCTA)));