import * as actionTypes from '../actions/actionTypes';

const initialState = {
    visible: false,
    type: 'login',
    username: null,
    password: null,
    token: '',
    method: undefined,
    errorMessage: '',
    step: 'username',
    oldStep: null,
    showPassword: false,
    loading: false,
    reset: false,
    redirect: false,
};

const reducer = (state = initialState, action) => {
    let password = state.password;
    switch (action.type) {
        case actionTypes.authModal.STATE:
            return {
                ...state,
                visible: action.visible,
                type: (action.modalType) ? action.modalType : 'login',
                oldStep: (!action.visible) ? null : state.oldStep,
                redirect: (!action.redirect) ? false : action.redirect,
            };

        case actionTypes.authModal.STEP:
            if ('PHONE_NUMBER' === state.method) {
                password = null;
            }
            return {
                ...state,
                oldStep: state.step,
                step: action.step,
                password: password,
                errorMessage: '',
            };
        
        case actionTypes.authModal.USERNAME:
            return {
                ...state,
                username: action.username,
                method: action.method,
                errorMessage: "",
            };
        
        case actionTypes.authModal.PASSWORD:
            return {
                ...state,
                password: action.password,
            };

        case actionTypes.authModal.SHOWPASSWORD:
            return {
                ...state,
                showPassword: !state.showPassword,
            };

        case actionTypes.authModal.START_LOADING:
            // console.log('START LOADING received in REDUCER!');
            // console.log(action);
            return {
                ...state,
                loading: true,
            };

        case actionTypes.authModal.STOP_LOADING:
            return {
                ...state,
                loading: false,
            };
            
        case actionTypes.authModal.ERROR:
            return {
                ...state,
                loading: false,
                oldStep: state.step,
                step: 'username',
                errorMessage: action.error,
            };
        
        case actionTypes.authModal.RESET:
            return {
                ...initialState,
                username: state.username,
                reset: true,
            }
            
        case actionTypes.authModal.RESETED:
            // console.log('******MODAL reseded!******');
            return {
                ...state,
                reset: false,
                username: null,
                password: null,
                step: 'username',
            };
        
        case actionTypes.authModal.METHOD:
            return {
                ...state,
                method: action.method
            }

        case actionTypes.authModal.RESET_OLD_STEP:
            return {
                ...state,
                oldStep: null,
            };

        case actionTypes.authModal.CONFIRM_REDIRECT:
            return {
                ...state,
                redirect: false,
            };
        default:
            return state;
    }
};

export default reducer;