import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from "../../../../store/actions";
import { withTranslation } from "react-i18next";
import TextInput from "../TextInput/TextInput";
import Utils from "../../../../utils/Utils";

class Address extends Component {

    state = {
        groupClassName: `${this.defaultGroupClassName}`,
        fieldInput: '',
        message: {
            type: "",
            text: "",
        },
        ok: false
    };

    componentDidMount() {
        this.timerId = 'addressValidationTimer';
        this.defaultGroupClassName = "input-group";
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.fieldInput !== this.props.address && prevProps.address !== this.props.address && this.props.address.length > 1) {
            this.setState({
                ...this.state,
                fieldInput: this.props.address
            });
            this.blurHandler();
        }
    }

    onFocusHandler() {
        this.setState({
            ...this.state,
            groupClassName: `${this.defaultGroupClassName} empty`,
        });
    }
    triggerHandler() {

        let groupClassName = undefined;
        let message = "";
        let valid = this.state.fieldInput.length > 9;
        let ok = this.state.ok;

        if (valid) {
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        } else {
            groupClassName = `${this.defaultGroupClassName} error`;
            message = this.props.t("Invalid address");
            if (ok) {
                ok = false;
            }
        }

        this.setState({
            ...this.state,
            groupClassName: groupClassName,
            message: {
                ...this.state.message,
                type: (message !== "" ? "error" : ""),
                text: message,
            },
            ok: ok,
        });
        this.props.onChange({ field: "address", value: this.state.fieldInput, validated: ok});
    }
    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.address;
        } else {
            fieldInput = event.target.value;
        }
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this));
        this.setState({
            ...this.state,
            fieldInput: fieldInput
        });
        this.props.onChange({ field: "address", value: fieldInput, validated: false});
    }

    blurHandler() {
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this),0);
    }

    render() {
        let errorMessage = this.state.message;
        let className = this.state.groupClassName;
        if (errorMessage.type === 'error') {
            className = this.defaultGroupClassName + ' error'; // overwrite className
        }

        let startAnimation = (errorMessage.type !== "error" ? true : false);


        return (
            <TextInput reward={(typeof this.props.showReward !== "undefined" ? this.props.showReward : true)}

                       animateReward={startAnimation}
                       groupClassName={className}
                       inputType="text"
                       inputIdName={"address"}
                       inputValue={this.props.address}
                       inputLabel={this.props.t("Address")}
                       onFocus={this.onFocusHandler.bind(this)}
                       changeHandler={this.changeHandler.bind(this)}
                       blurHandler={this.blurHandler.bind(this)}
                       message={errorMessage}
            />
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(stateActions.fieldEntered(fieldInput)),
    };
};

const mapStateToProps = state => {
    return {
        address: state.registerWizard.fields.address,
        addressInput: state.registerWizard.addressInput
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Address));
