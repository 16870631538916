import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//import BetSelectSingle from "../components/BetSelectSingle";
import { navSportsUpdate } from "../store/actions/navSports";
import { ticketCheckCode } from "../store/actions/tickets";
import {
  getBetsState,
  getHomeMatches3,
  getHomeMatches4,
  //getLiveSportsList,
  getWinnerPlusMatches,
  getWinnerAdvMatches,
  getFavoriteMatches,
  getAllSports2,
  getSuperBannerMatches,
  getHomeLiveMatchesBySport,
  getHomePrematchMatchesBySport,
  makeGetLayoutShow
} from "../store/selectors/betData";

import Page from "../layout/Page";
//import TabsMenu from "../components/TabsMenu";
import LeftNav from "../components/LeftNav";
import webStyle from "../utils/webStyle";

import PageToolbar from "../components/PageToolbar";
import PageToolbarSection from "../components/PageToolbar/PageToolbarSection";
import PageToolbarButton from "../components/PageToolbar/PageToolbarButton";
import BetsFull from "../components/BetSlip/BetsFull";

//import homeIcon from "../assets/icons/homeIcon.svg";
import homeIcon from "../assets/icons/icon-home-button-new.svg";

import LiveIcon from "../elements/LiveIcon";

import { ReactComponent as HomeIcon } from "../assets/icons/footballIcon.svg";
import { ReactComponent as LiveTVIcon } from "../assets/icons/live-tv.svg";

import { ReactComponent as AvantajIcon } from "../assets/icons/icon-avantaj-link.svg";
import { ReactComponent as WinnerIcon } from "../assets/icons/icon-winner-link.svg";
import { ReactComponent as FavoriteIcon } from "../assets/icons/home-favorite.svg";
import { ReactComponent as PreMatchIcon } from "../assets/icons/preMatchIcon.svg";
import { ReactComponent as AgentiiIcon } from "../assets/icons/icon-agentii.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/searchIcon.svg";
import { ReactComponent as SearchTicketIcon } from "../assets/icons/searchTicketIcon.svg";
//import { ReactComponent as OlympicsIcon } from "../assets/icons/icon-olympics.svg";

import CasinoIcon from "../assets/icons/icon-casino.svg";

import PromotionCard from "../components/Promotion/PromotionCard";
import PromotionCards from "../components/Promotion/PromotionCards";
import PromotionsWinner from "../components/Promotion/PromotionsWinner";

import LeagueList from "../components/LeagueList";
import NewsTicker from "../components/NewsTicker";

import { shallowEqual, compareObjsByKeys, compareArraysByKeys } from "../utils";
import { buildMatchGroups } from "../utils/betUtils";
import {
  renderMatchGroupWithSport
} from "../utils/render";
import InPageMD from "./InPageMD";

import SearchTemplate from "../components/SearchTemplate";
import TicketCheckModal from "../components/BetSlip/TicketCheckModal";
import { sortArrayByKey } from "../utils";
import isArray from "lodash/fp/isArray";
import LayoutSwitcherHighlighter from "../components/LayoutSwitcherHighlighter";
import OldSiteLayoutMessages from "../../elements/OldSiteLayoutMessages/OldSiteLayoutMessages";

import Slider from "../../elements/Slider";

import TopLive from "../../elements/HomeV2/PartsV2/13-TopLive/TopLive";

import WinnerFunWelcome from "../components/WinnerFunWelcome/WinnerFunWelcome";
import WinnerFunFront from "../components/WinnerFunWelcome/WinnerFunFront";

import { saveCalendarSelection } from "../store/actions/calendar";

import { getText } from "../../lobby/utils/functions";

import BetsLobby from "../components/bets-lobby/bets-lobby";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#EDF0F0",
    padding: "0px 14px 0 14px"
  },
  homeImage: {
    width: "27px",
    height: "27px",
    position: "relative",
    //top: "-2px"
  },
  pl: {
    paddingLeft: "10px"
  },
  emptyFav: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  },
  spacer: {
    height: "15px",
    width: "100%",
    background: "#fff"
  },
});

const routesTo = {
  home: {
    pathname: "/",
    state: { prev: false }
  },
  preMatch: {
    pathname: "pre-match/calendar",
    state: { prev: true }
  },
  liveMatch: {
    pathname: "live-match/highlights",
    state: { prev: true }
  }
};

const Home = props => {
  const {
    bst,
    homeMatches,
    homeGroups,
    wpMatches,
    waMatches,
    favMatches,
    sports,
    idSport,
    updateSportsIndex,
    liveMatchesNum,
    homeLeagues,
    homeNews,
    liveMatchesBySport,
    prematchMatchesBySport,
    checkCode,
    layoutShow,
    superBanner,
    prematchFirst,
    baseUrlPath,
    navElements,
    isWinnerFun,
    match,
    t
  } = props;
  const classes = useStyles();
  const classesDesktop = webStyle();

  const isDesktop1024 = useMediaQuery("(min-width:1025px)");
  const isDesktop1280 = useMediaQuery("(min-width:1281px)");

  const [lobbyUrl, setLobbyUrl] = React.useState("");

  const [show, setShow] = React.useState(true);
  const [matchesType, setMatchesType] = React.useState(0);
  const handleChangeMatchesType = type => () => {
    setMatchesType(type);
  };

  React.useEffect(() => {
    setShow(true);
    updateSportsIndex("prematch", "0");
    if (match.path === "/pariuri/lobby/:path+") {
      setLobbyUrl(match.url);
      setMatchesType(4)
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (layoutShow && matchesType !== 0) {
      setMatchesType(0);
    }
  }, [layoutShow, matchesType]); // eslint-disable-line

  let sportIndex = sports.findIndex(s => s.idSport === idSport);
  if (sportIndex === -1) sportIndex = 0;

  const handleOnSearch = () => {
    window.location.hash = `#filter`;
  };

  const [ticketCode, setTicketCode] = React.useState("");
  const [showSearch, setShowSearch] = React.useState(false);
  const handleOnCheckTicket = () => {
    setShowSearch(v => !v);
  };
  const handleTicketCodeChange = (value, type) => {
    if (type === "clear") {
      setTicketCode("");
    } else {
      setTicketCode(value);
    }
  };

  const [openCheckModal, setOpenCheckModal] = React.useState(false);
  const handleCheckModalClose = () => {
    setOpenCheckModal(false);
    setTicketCode("");
  };

  const handleTicketCodeCheck = () => {
    checkCode(ticketCode);
    setOpenCheckModal(true);
  };

  const filteredNavElements = navElements.filter(el => {
    return el.type && el.type === 'promoted';
  });
  const handleNavElementsLink = (i) => (e) => {
    if (filteredNavElements && filteredNavElements[i] && filteredNavElements[i].url) {
      const url = filteredNavElements[i].url;
      if (url && url.indexOf("http") > -1) {
        if (window.config && window.config.cordova) {
          window.cordova.InAppBrowser.open(url, '_system');
          return;
        }
        window.location.href = url;
      } else {
        if (url.indexOf("sd=0") > - 1) {
          props.saveCalendarSelection(0);
        }
        if (url.indexOf("/pariuri/lobby/") > -1) {
          // special lobby content displayed on this bet promoted page
          setMatchesType(4);
          setLobbyUrl(url);
          return;
        }
        props.history.push(url);
      }
    }
  };

  const lang = props.i18n.language;

  let content = <div></div>;
  const disableSearch = true;
  let highlightMatch = null;

  if (show) {
    let banner = <Slider eventPrefix="bets" config={superBanner} />;
    const pageToolbar = (
      <PageToolbar type="prematch" className="home-match-toolbar">
        <PageToolbarSection>
          <Link to={routesTo.home} className="nav__link">
            <PageToolbarButton
              image={homeIcon}
              imageCls={classes.homeImage}
              text={props.t("Start")}
            />
          </Link>
        </PageToolbarSection>
        <PageToolbarSection title={props.t("bets")} titleBg="#C31822">
          <div
            onClick={handleChangeMatchesType(0)}
            className={`nav__link ${matchesType === 0 ? "active" : ""}`}
          >
            <PageToolbarButton text={props.t("Promoted")}>
              <HomeIcon />
            </PageToolbarButton>
          </div>
          {/*
          <div onClick={handleOlympics} className="nav__link">
            <PageToolbarButton text={props.t("Olympics")}>
              <OlympicsIcon className="olympics-icon" />
            </PageToolbarButton>
          </div>
          */}
          <Link to={`${baseUrlPath}/${routesTo.preMatch.pathname}`} className="nav__link">
            <PageToolbarButton text={props.t("Pre-Match")}>
              <PreMatchIcon />
            </PageToolbarButton>
          </Link>
          <Link to={`${baseUrlPath}/${routesTo.liveMatch.pathname}`} className="nav__link">
            <PageToolbarButton text={props.t("Live")}>
              <LiveIcon number={liveMatchesNum} />
            </PageToolbarButton>
          </Link>
          {!isDesktop1280 && (
            <div onClick={handleOnSearch} className={`nav__link`}>
              <PageToolbarButton text={props.t("Search")}>
                <SearchIcon />
              </PageToolbarButton>
            </div>
          )}
          {!disableSearch && !isDesktop1024 && (
            <div
              onClick={handleOnCheckTicket}
              className={`nav__link ${showSearch ? "active" : ""}`}
            >
              <PageToolbarButton text={props.t("Check Ticket")}>
                <SearchTicketIcon />
              </PageToolbarButton>
            </div>
          )}
          {favMatches.length > 0 && (
            <div
              onClick={handleChangeMatchesType(3)}
              className={`${classes.pl} nav__link ${matchesType === 3 ? "active" : ""}`}
            >
              <PageToolbarButton text={props.t("Favorites")}>
                <FavoriteIcon />
              </PageToolbarButton>
            </div>
          )}
        </PageToolbarSection>
        {(Object.keys(waMatches).length > 0 || Object.keys(wpMatches).length > 0) && (
          <React.Fragment>
            <PageToolbarSection title={props.t("in bets")} titleBg="#f88e04">
              {filteredNavElements.map((item, i) => {
                return <div onClick={handleNavElementsLink(i)} className={`nav__link custom-nav-element ${matchesType === 4 ? "active" : ""}`} key={`custom_link_${i}`}>
                  <PageToolbarButton image={item.icon} text={getText(item, `title.text.${lang}`, "")} />
                </div>
              })}
              {Object.keys(waMatches).length > 0 && (
                <div
                  onClick={handleChangeMatchesType(1)}
                  className={`nav__link ${matchesType === 1 ? "active" : ""}`}
                >
                  <PageToolbarButton text={props.t("Avantaj")}>
                    <AvantajIcon />
                  </PageToolbarButton>
                </div>
              )}
              {Object.keys(wpMatches).length > 0 && (
                <div
                  onClick={handleChangeMatchesType(2)}
                  className={`nav__link ${matchesType === 2 ? "active" : ""}`}
                >
                  <PageToolbarButton text={props.t("Winner+")}>
                    <WinnerIcon />
                  </PageToolbarButton>
                </div>
              )}
            </PageToolbarSection>
          </React.Fragment>
        )}
        {baseUrlPath === "/pariuri" && <PageToolbarSection title={props.t("navigare")} titleBg="#162151">
          <Link to={"/cazino/jocuri"} className="nav__link">
            <PageToolbarButton image={CasinoIcon} text={props.t("Casino")} />
          </Link>
          {/*
          <Link to={"/locations"} className="nav__link">
            <PageToolbarButton text={props.t("Locations")}>
              <AgentiiIcon />
            </PageToolbarButton>
          </Link>
          */}
        </PageToolbarSection>}
      </PageToolbar>
    );

    let leaguesNews = (
      <React.Fragment>
        <LeagueList leagues={homeLeagues} />
        <NewsTicker news={homeNews} />
      </React.Fragment>
    );
    if (isDesktop1280) {
      if ((homeLeagues && homeLeagues.length) && (homeNews && homeNews.length)) {
        leaguesNews = (
          <div className="d-flex flex-row flex-nowrap align-items-center">
            <div className={classesDesktop.w50}>
              <LeagueList leagues={homeLeagues} />
            </div>
            <div className={classesDesktop.w50}>
              <NewsTicker news={homeNews} />
            </div>
          </div>
        );
      } else if (homeLeagues && homeLeagues.length) {
        leaguesNews = <LeagueList leagues={homeLeagues} />;
      } else if (homeNews.length) {
        leaguesNews = <NewsTicker news={homeNews} />;
      } else {
        leaguesNews = null;
      }
    }

    const waMatchesList = Object.values(waMatches);
    const waMatchesGroups = buildMatchGroups(waMatchesList, "matchDateTime", { winnerAdv: true });
    const wpMatchesList = Object.values(wpMatches);
    const wpMatchesGroups = buildMatchGroups(wpMatchesList, "matchDateTime", { winnerPlus: true });

    const buildFavoriteMatches = () => {
      const liveMatchesBySport = {};
      const prematchMatchesBySport = {};

      Object.values(favMatches).forEach(m => {
        if (m.mType === "live") {
          if (typeof liveMatchesBySport[m.idSport] === "undefined") {
            liveMatchesBySport[m.idSport] = [];
          }
          liveMatchesBySport[m.idSport].push(m);
        } else {
          if (typeof prematchMatchesBySport[m.idSport] === "undefined") {
            prematchMatchesBySport[m.idSport] = [];
          }
          prematchMatchesBySport[m.idSport].push(m);
        }
      });

      const retVal = [];

      Object.keys(liveMatchesBySport).forEach(k => {
        sortArrayByKey(liveMatchesBySport[k], "matchDateTime");
        retVal.push(
          renderMatchGroupWithSport(
            {
              group: "mg_live_fav_" + k,
              idSport: k,
              mType: "live",
              matches: liveMatchesBySport[k]
            },
            bst
          )
        );
      });

      Object.keys(prematchMatchesBySport).forEach(k => {
        sortArrayByKey(prematchMatchesBySport[k], "matchDateTime");
        retVal.push(
          renderMatchGroupWithSport(
            {
              group: "mg_prematch_fav_" + k,
              idSport: k,
              mType: "prematch",
              matches: prematchMatchesBySport[k]
            },
            bst
          )
        );
      });

      return retVal;
    };

    if (homeGroups["promoted"] !== null) {
      highlightMatch = {
        idMatch: homeGroups["promoted"].idMatch,
        mType: homeGroups["promoted"].mType
      };
    } else if (homeGroups["winner_avantaj"] && homeGroups["winner_avantaj"].length) {
      highlightMatch = {
        idMatch: homeGroups["winner_avantaj"][0].idMatch,
        mType: homeGroups["winner_avantaj"][0].mType
      };
    } else if (homeGroups["winner_selection"] && homeGroups["winner_selection"].length) {
      highlightMatch = {
        idMatch: homeGroups["winner_selection"][0].idMatch,
        mType: homeGroups["winner_selection"][0].mType
      };
    } else if (homeGroups["live"] && homeGroups["live"].length) {
      highlightMatch = {
        idMatch: homeGroups["live"][0].idMatch,
        mType: homeGroups["live"][0].mType
      };
    }

    const genPrematch = () => {
      return <React.Fragment>
        <div className="bets-home">
          <div className="bets-icon"><HomeIcon /></div>
          <div className="bets-text">{t("Sport")}</div>
        </div>
        {homeGroups["promoted"] !== null
          ? buildMatchGroups([homeGroups["promoted"]], "position", {
            suffix: "hg_promoted"
          }).map(g => renderMatchGroupWithSport(g, bst))
          : null}

        {homeGroups["match_carousel"].length > 0 && (
          <PromotionsWinner matches={homeGroups["match_carousel"]} />
        )}

        {!isDesktop1024 && leaguesNews}

        {homeGroups["winner_avantaj"].length > 0
          ? buildMatchGroups(homeGroups["winner_avantaj"], "position", {
            makeWinnerAdvIfAll: true,
            suffix: "hg_winner_avantaj"
          }).map(g => renderMatchGroupWithSport(g, bst))
          : null}

        {!isDesktop1024 && <PromotionCard />}

        {homeGroups["winner_selection"].length > 0
          ? buildMatchGroups(homeGroups["winner_selection"], "position", {
            suffix: "hg_winner_selection"
          }).map(g => renderMatchGroupWithSport(g, bst))
          : null}

        {!isDesktop1024 && homeGroups["surprise_match"] !== null && (
          <PromotionCards match={homeGroups["surprise_match"]} />
        )}
      </React.Fragment>;
    };
    const genLive = () => {
      return <React.Fragment>
        {homeGroups["live"].length > 0 &&
          <div className="bets-home mfix">
            <div className="bets-icon"><LiveTVIcon /></div>
            <div className="bets-text">{t("LIVE")}</div>
          </div>
        }

        {homeGroups["live"].length > 0
          ? buildMatchGroups(homeGroups["live"], "position", {
            suffix: "hg_live"
          }).map(g => renderMatchGroupWithSport(g, bst))
          : null}
      </React.Fragment>;
    };

    const matchesCnt = (
      <React.Fragment>
        <WinnerFunWelcome />
        <WinnerFunFront />
        {matchesType === 0 && (
          <React.Fragment>
            <TopLive componentKey="top_live" className="bets-top-live" />
            {idSport === "0" ? (
              <div className={`${classes.root}  bets-promoted-root`}>
                {prematchFirst && <React.Fragment>
                  {genPrematch()}
                  <div className="space-fix"></div>
                  {genLive()}
                </React.Fragment>}

                {!prematchFirst && <React.Fragment>
                  {genLive()}
                  <div className="space-fix"></div>
                  {genPrematch()}
                </React.Fragment>}
              </div>
            ) : liveMatchesBySport.length > 0 || prematchMatchesBySport.length > 0 ? (
              <div className={`${classes.root}  bets-promoted-root`}>
                {renderMatchGroupWithSport(
                  {
                    group: "mg_live_" + sports[idSport].idLive,
                    idSport: idSport,
                    mType: "live",
                    matches: liveMatchesBySport
                  },
                  bst
                )}
                {renderMatchGroupWithSport(
                  {
                    group: "mg_prematch_" + sports[idSport].idPreMatch,
                    idSport: idSport,
                    mType: "prematch",
                    matches: prematchMatchesBySport
                  },
                  bst
                )}
              </div>
            ) : (
              <div className={classes.emptyFav}>
                {props.t("There are no matches for this sport")}
              </div>
            )}
          </React.Fragment>
        )}
        {matchesType === 1 && (
          <div className={`${classes.root}  bets-promoted-root`}>
            {waMatchesGroups.length > 0 ? (
              waMatchesGroups.map(g => renderMatchGroupWithSport(g, bst))
            ) : (
              <div className={classes.emptyFav}>
                {props.t("There are no Winner Advantage matches")}
              </div>
            )}
          </div>
        )}
        {matchesType === 2 && (
          <div className={`${classes.root}  bets-promoted-root`}>
            {wpMatchesGroups.length > 0 ? (
              wpMatchesGroups.map(g => renderMatchGroupWithSport(g, bst))
            ) : (
              <div className={classes.emptyFav}>{props.t("There are no Winner Plus matches")}</div>
            )}
          </div>
        )}
        {matchesType === 3 && (
          <div className={`${classes.root}  bets-promoted-root`}>
            {favMatches.length > 0 ? (
              buildFavoriteMatches()
            ) : (
              <div className={classes.emptyFav}>{props.t("There are no favorite matches")}</div>
            )}
          </div>
        )}
        {matchesType === 4 && (
          <div className={`${classes.root}  bets-promoted-root`}>
            <BetsLobby url={lobbyUrl} />
          </div>
        )}
      </React.Fragment>
    );
    if (isDesktop1280) {
      const tabsMenu = <LeftNav />;

      content = (
        <div>
          <div className={`${classesDesktop.wrapper} d-flex flex-row flex-norwap`}>
            <div className={classesDesktop.tabsDesktop}>{tabsMenu}</div>
            <div className={`${classesDesktop.cnt} ${classesDesktop.px}`}>
              {banner}
              <div className={classesDesktop.over}>{pageToolbar}</div>
              <InPageMD>{matchesCnt}</InPageMD>
            </div>
            <div className={classesDesktop.betSlip}>
              <div>
                <BetsFull desktop={true} />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (isDesktop1024) {
      content = (
        <div>
          <div className={`${classesDesktop.wrapper} d-flex flex-row flex-norwap`}>
            <div className={`${classesDesktop.cnt} ${classesDesktop.px}`}>
              {banner}
              <div className={classesDesktop.over}>{pageToolbar}</div>
              {matchesCnt}
            </div>
            <div className={classesDesktop.betSlip}>
              <div>
                <BetsFull desktop={true} />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = (
        <div>
          {banner}
          {pageToolbar}
          {showSearch && !isDesktop1024 && (
            <React.Fragment>
              <SearchTemplate
                search={ticketCode}
                onChange={handleTicketCodeChange}
                onSearch={handleTicketCodeCheck}
                placeholder={t("Check Ticket Code")}
                noRadius={true}
              />
              <TicketCheckModal
                openTicketCheck={openCheckModal}
                onModalClose={handleCheckModalClose}
              />
            </React.Fragment>
          )}
          {matchesCnt}
        </div>
      );
    }
  }

  return (
    <Page
      doRender={[
        idSport,
        sports,
        homeMatches,
        homeGroups,
        favMatches,
        wpMatches,
        waMatches,
        matchesType,
        isDesktop1024,
        isDesktop1280,
        showSearch,
        ticketCode,
        openCheckModal,
        layoutShow,
        superBanner,
        baseUrlPath,
        navElements,
        isWinnerFun
      ]}
    >
      {!isWinnerFun && <OldSiteLayoutMessages />}
      {content}
      <div className="spacer">&nbsp;</div>
      {layoutShow && (
        <LayoutSwitcherHighlighter highlightMatch={highlightMatch} showDialog={layoutShow} />
      )}
    </Page>
  );
};

Home.whyDidYouRender = true;

Home.propTypes = {
  idSport: PropTypes.string,
  sports: PropTypes.array,
  updateSportsIndex: PropTypes.func,
  matches: PropTypes.array
};

Home.defaultProps = {
  idSport: "0",
  sports: [],
  matches: [],
  updateSportsIndex: () => { }
};

const emptyArray = [];

const getLayoutShow = makeGetLayoutShow();
const mapStateToProps = state => {
  const bst = getBetsState(state);

  let prematchFirst = true;
  if (bst
    && bst.config
    && bst.config.settings
    && typeof bst.config.settings.prematchFirst !== "undefined"
    && !bst.config.settings.prematchFirst) {
    prematchFirst = false;
  }

  let superBanner = bst.config.homepageGroupsImages.super_banner || emptyArray;
  if (bst.app.basePath === "/winner-fun") {
    superBanner = state.winnerFun && state.winnerFun.config && state.winnerFun.config.super_banner ? state.winnerFun.config.super_banner : [];
  }

  return {
    bst: bst,
    prematchFirst: prematchFirst,
    liveMatchesBySport: getHomeLiveMatchesBySport(state, {
      idSport: bst.navSports.home
    }),
    prematchMatchesBySport: getHomePrematchMatchesBySport(state, {
      idSport: bst.navSports.home
    }),
    homeMatches: getHomeMatches3(state),
    homeGroups: getHomeMatches4(state),
    favMatches: getFavoriteMatches(state),
    homeLeagues: bst.config.homepageLeagues,
    homeNews: bst.config.homepageNews,
    wpMatches: getWinnerPlusMatches(state),
    waMatches: getWinnerAdvMatches(state),
    idSport: bst.navSports.home,
    sports: getAllSports2(state),
    liveMatchesNum: Object.keys(bst.live.matches).length,
    superBannerMatches: getSuperBannerMatches(state),
    layoutShow: getLayoutShow(state),
    superBanner: superBanner,
    baseUrlPath: bst.app.basePath,
    navElements: bst.config.navElements,
    isWinnerFun: bst.app.isWinnerFun,
  };
};

const actionCreators = {
  updateSportsIndex: navSportsUpdate,
  checkCode: ticketCheckCode,
  saveCalendarSelection: saveCalendarSelection,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    actionCreators
  )(
    React.memo(Home, (pp, np) => {
      const toCompareKeys = {
        //homeMatches: ["group"],
        sports: ["sportName"],
        liveMatchesBySport: ["idMatch"],
        prematchMatchesBySport: ["idMatch"]
      };

      //debug("homeGroups", np.homeGroups);

      const pps = Object.keys(toCompareKeys);

      for (let i = 0; i < pps.length; i++) {
        if (!compareObjsByKeys(pp[pps[i]], np[pps[i]], toCompareKeys[pps[i]])) {
          /*
          debug(
            new Date().getTime() / 1000,
            new Date().getTime() % 1000,
            "home not the same (keys)",
            pps[i],
            pp[pps[i]],
            np[pps[i]]
          );
          */
          return false;
        }

        if (pps[i] === "homeMatches" && "homeMatches" in pp) {
          for (let i = 0; i < pp.homeMatches.length; i++) {
            if (
              !compareObjsByKeys(pp.homeMatches[i].matches, np.homeMatches[i].matches, ["idMatch"])
            ) {
              /*
              console.log(
                new Date().getTime() / 1000,
                new Date().getTime() % 1000,
                "home not the same (homematches matches)",
                pps[i],
                pp[pps[i]],
                np[pps[i]]
              );
              */
              return false;
            }
          }
        }
      }

      // handle homegroups
      if (
        !(
          "homeGroups" in pp &&
          "homeGroups" in np &&
          typeof pp.homeGroups !== "undefined" &&
          typeof np.homeGroups !== "undefined"
        )
      ) {
        return false;
      }

      const hgs = [
        "promoted",
        "match_carousel",
        "winner_avantaj",
        "winner_selection",
        "surprise_match",
        "live"
      ];
      for (let i = 0; i < hgs.length; i++) {
        if (isArray(pp.homeGroups[hgs[i]])) {
          if (
            !compareArraysByKeys(pp.homeGroups[hgs[i]], np.homeGroups[hgs[i]], [
              "idMatch",
              "matchBets"
            ])
          ) {
            /*
            debug(
              `homegroup array ${hgs[i]} not the same`,
              pp.homeGroups[hgs[i]],
              np.homeGroups[hgs[i]]
            );
            */
            return false;
          }
        } else {
          if (
            !compareObjsByKeys(pp.homeGroups[hgs[i]], np.homeGroups[hgs[i]], [
              "idMatch",
              "matchBets"
            ])
          ) {
            /*
            debug(
              `homegroup obj ${hgs[i]} not the same`,
              pp.homeGroups[hgs[i]],
              np.homeGroups[hgs[i]]
            );
            */
            return false;
          }
        }
      }

      const toCompare = [
        "favMatches",
        "homeLeagues",
        "homeNews",
        "wpMatches",
        "waMatches",
        "idSport",
        "liveMatchesNum",
        "superBannerMatches",
        "prematchFirst"
      ];

      for (let i = 0; i < toCompare.length; i++) {
        if (!shallowEqual(pp[toCompare[i]], np[toCompare[i]])) {
          /*
          debug(
            new Date().getTime() / 1000,
            new Date().getTime() % 1000,
            "home not the same",
            toCompare[i],
            pp[toCompare[i]],
            np[toCompare[i]]
          );
          */
          return false;
        }
      }

      if (pp.layoutShow !== np.layoutShow) {
        return false;
      }

      if (pp.baseUrlPath !== np.baseUrlPath) {
        return false;
      }

      if (pp.navElements !== np.navElements) {
        return false;
      }

      if (pp.isWinnerFun !== np.isWinnerFun) {
        return false;
      }

      //console.log(new Date().getTime() / 1000, new Date().getTime() % 1000, "home the same");
      return true;
    })
  )
);
