import { uuidv4 } from '../../utils';

export const sportsbookTokenUrl = process.env.CFW.appApiUrl;

const nSoftAuthStaging = {
	serviceUrl: 'https://services-staging.7platform.com',
	companyUuid: '28ec1e93-68f0-49fd-9ab5-639d88169625'
};

const nSoftAuthProduction = {
	serviceUrl: 'https://services-ro2.7platform.com',
	companyUuid: '04301c5a-6b6c-4694-aaf5-f81bf665498c'
};

const nSoftAuthWinnerFunProduction = {
	serviceUrl: 'https://services-ro2.7platform.com',
	companyUuid: 'eb5cbbf1-4576-4e2c-b641-8c49b0865e0f'
};

/*
Name: Winner FUN
UUID: eb5cbbf1-4576-4e2c-b641-8c49b0865e0f
Product (Live MTS) UUID: 0f9edd10-b9cd-4974-94bd-11636f89d332
*/

const nSoftLiveStaging = {
	wsUrl: 'https://sportsbook-public-api-staging.nsoft.com',
	product: 'live',
	protocol: 'sio1',
	ticketsUrl: 'https://services-staging.7platform.com',
	cpvUuid: '3657d0f3-f54d-4dc8-9077-578940875c8d',
	productName: 'LiveBetting',
	companyUuid: '28ec1e93-68f0-49fd-9ab5-639d88169625',
	productInstanceId: 1017294,
	productInstanceUuid: '8a3f73e2-0a45-42d5-9488-9b6ec164923a'
};

const nSoftLiveProduction = {
	wsUrl: 'https://sportsbook-public-api.nsoft.com',
	product: 'live',
	protocol: 'sio1',
	ticketsUrl: 'https://services-ro2.7platform.com',
	cpvUuid: 'd8d00130-5509-4e9c-b2b9-94af43812fc8',
	productName: 'LiveBetting',
	companyUuid: '04301c5a-6b6c-4694-aaf5-f81bf665498c',
	productInstanceId: 1017294,
	productInstanceUuid: '8a3f73e2-0a45-42d5-9488-9b6ec164923a'
};

const nSoftLiveWinnerFunProduction = {
	wsUrl: 'https://sportsbook-public-api.nsoft.com',
	product: 'live',
	protocol: 'sio1',
	ticketsUrl: 'https://services-ro2.7platform.com',
	cpvUuid: '0f9edd10-b9cd-4974-94bd-11636f89d332',
	productName: 'LiveBetting',
	companyUuid: 'eb5cbbf1-4576-4e2c-b641-8c49b0865e0f',
	productInstanceId: 1017294,
	productInstanceUuid: '8a3f73e2-0a45-42d5-9488-9b6ec164923a'
};

const nSoftPrematchStaging = {
	wsURL: 'https://staging-sportsbook-sm-distribution-socket.nsoft.com',
	apiURL: 'https://staging-sportsbook-sm-distribution-api.nsoft.com',
	ticketsUrl: 'https://services-staging.7platform.com',
	ticketWsURL: 'wss://staging-sportsbook-sm-ticket-socket.nsoft.com',
	gatewayUrl: 'https://staging-sb-sm-gateway.nsoft.com',
	cpvUuid: '3657d0f3-f54d-4dc8-9077-578940875c8d',
	productName: 'SportsbookSM',
	companyUuid: '28ec1e93-68f0-49fd-9ab5-639d88169625',
	productInstanceId: 1017294,
	productInstanceUuid: '8a3f73e2-0a45-42d5-9488-9b6ec164923a',
	ticketCalculatorUrl: 'wss://staging-sportsbook-sm-ticket-calculator.nsoft.com',
	defaultSportId: '1', //"6", // changed because of digitain
	footballId: '1', //"6", // changed because of digitain
	shortProps: 0,
	marketCount: 1,
	ticketCheckUrl: 'https://staging-gravity-service.7platform.com/v1/web/tickets/',
};

if (process.env.NATIVE_PLATFORM && (process.env.NATIVE_PLATFORM === 'ios' || process.env.NATIVE_PLATFORM === 'android')) {
	nSoftPrematchStaging.marketCount = 1;
}

const nSoftPrematchProduction = {
	wsURL: 'https://sportsbook-sm-distribution-socket.nsoft.com',
	apiURL: 'https://sportsbook-sm-distribution-api.nsoft.com',
	ticketsUrl: 'https://services-ro2.7platform.com',
	ticketWsURL: 'wss://sportsbook-sm-ticket-socket.nsoft.com',
	gatewayUrl: 'https://sportsbook-sm-gateway.nsoft.com',
	cpvUuid: '6442b1ed-e06b-45df-810c-cff5a89900f8',
	productName: 'SportsbookSM',
	companyUuid: '04301c5a-6b6c-4694-aaf5-f81bf665498c',
	productInstanceId: 1017294,
	productInstanceUuid: '8a3f73e2-0a45-42d5-9488-9b6ec164923a',
	ticketCalculatorUrl: 'wss://sportsbook-sm-ticket-calculator.nsoft.com',
	defaultSportId: '1', //"18", // changed because of digitain
	footballId: '1', //"18", // changed because of digitain
	shortProps: 1,
	marketCount: 1,
	ticketCheckUrl: 'https://gravity-service.7platform.com/v1/web/tickets/',
};

if (process.env.NATIVE_PLATFORM && (process.env.NATIVE_PLATFORM === 'ios' || process.env.NATIVE_PLATFORM === 'android')) {
	nSoftPrematchProduction.marketCount = 1;
}

const nSoftCommonStaging = {
	apiURL: 'https://staging-sportbook-public-api.neosoft.ba'
};

const nSoftCommonProduction = {
	apiURL: 'https://sportbook-public-api.neosoft.ba'
};

export const nSoftBonusUrl =
	'https://sportbook-public-api.nsoft.com/bonus.php/sevenPlayerSportBonus';

const nSoftReportStaging = {
	apiURL: 'https://staging-sportsbook-sm-reporting-api.nsoft.com'
};

const nSoftReportProduction = {
	apiURL: 'https://sportsbook-sm-reporting-api.nsoft.com'
};

const nSoftTicketStaging = {
	apiURL: 'https://staging-sportsbook-sm-ticket.nsoft.com'
};

const nSoftTicketProduction = {
	apiURL: 'https://sportsbook-sm-ticket.nsoft.com'
};

const nSoftRiskStaging = {
	apiURL: 'https://staging-sb-sm-gateway.nsoft.com'
};

const nSoftRiskProduction = {
	apiURL: 'https://sportsbook-sm-gateway.nsoft.com'
};

/*
DEV: https://dev-sports-sm-cashout.nsoft.com/client/distribution/sio2
STAGING: https://staging-sports-sm-cashout.nsoft.com/client/distribution/sio2
PRODUCTION: https://sports-sm-cashout.nsoft.com/client/distribution/sio2
*/

const nSoftCashoutPrematchStaging = {
	wsURL: 'https://staging-sports-sm-cashout.nsoft.com',
	path: '/client/distribution/sio2',
	apiURL: 'https://services-staging.7platform.com/web/tickets/cashout.json',
	companyUuid: '28ec1e93-68f0-49fd-9ab5-639d88169625',
	product: 'SportsbookSM',
};

const nSoftCashoutPrematchProduction = {
	wsURL: 'https://sports-sm-cashout.nsoft.com',
	path: '/client/distribution/sio2',
	apiURL: 'https://services-ro2.7platform.com/web/tickets/cashout.json',
	companyUuid: '04301c5a-6b6c-4694-aaf5-f81bf665498c',
	product: 'SportsbookSM',
};

/*
DEV: https://sports-mts-cashout-dev.nsoft.com/client/distribution/protocol/sio2
STAGING: https://sports-mts-cashout-staging.nsoft.com/client/distribution/protocol/sio2
PRODUCTION: https://sports-mts-cashout.nsoft.com/client/distribution/protocol/sio2
*/

const nSoftCashoutStaging = {
	wsURL: 'https://sports-mts-cashout-staging.nsoft.com',
	path: '/client/distribution/protocol/sio2',
	apiURL: 'https://sports-mts-cashout-staging.nsoft.com/v1/cashout',
	companyUuid: '28ec1e93-68f0-49fd-9ab5-639d88169625',
};

const nSoftCashoutProduction = {
	wsURL: 'https://sports-mts-cashout.nsoft.com',
	path: '/client/distribution/protocol/sio2',
	apiURL: 'https://sports-mts-cashout.nsoft.com/v1/cashout',
	companyUuid: '04301c5a-6b6c-4694-aaf5-f81bf665498c',
};

const nSoftCashoutWinnerFunProduction = {
	wsURL: 'https://sports-mts-cashout.nsoft.com',
	path: '/client/distribution/protocol/sio2',
	apiURL: 'https://sports-mts-cashout.nsoft.com/v1/cashout',
	companyUuid: 'eb5cbbf1-4576-4e2c-b641-8c49b0865e0f',
};

export const routingKey = uuidv4();

let nSoftAuth;
let nSoftAuthWinnerFun;
let nSoftLive;
let nSoftLiveWinnerFun;
let nSoftPrematch;
let nSoftCommon;
let nSoftReport;
let nSoftTicket;
let nSoftRisk;
let nSoftCashoutPrematch;
let nSoftCashout;
let nSoftCashoutWinnerFun;

if (
	(process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && window.config && window.config.environment !== 'production') ||
	(typeof window !== 'undefined' && window.config && window.config.environment !== 'production')
) {
	console.log('using staging endpoints');

	nSoftAuth = nSoftAuthStaging;
	nSoftAuthWinnerFun = nSoftAuthStaging;
	nSoftLive = nSoftLiveStaging;
	nSoftLiveWinnerFun = nSoftLiveStaging;
	nSoftPrematch = nSoftPrematchStaging;
	nSoftCommon = nSoftCommonStaging;
	nSoftReport = nSoftReportStaging;
	nSoftTicket = nSoftTicketStaging;
	nSoftRisk = nSoftRiskStaging;
	nSoftCashoutPrematch = nSoftCashoutPrematchStaging;
	nSoftCashout = nSoftCashoutStaging;
	nSoftCashoutWinnerFun = nSoftCashoutWinnerFunProduction;
} else {
	//console.log("using production endpoints");

	nSoftAuth = nSoftAuthProduction;
	nSoftAuthWinnerFun = nSoftAuthWinnerFunProduction;
	nSoftLive = nSoftLiveProduction;
	nSoftLiveWinnerFun = nSoftLiveWinnerFunProduction;
	nSoftPrematch = nSoftPrematchProduction;
	nSoftCommon = nSoftCommonProduction;
	nSoftReport = nSoftReportProduction;
	nSoftTicket = nSoftTicketProduction;
	nSoftRisk = nSoftRiskProduction;
	nSoftCashoutPrematch = nSoftCashoutPrematchProduction;
	nSoftCashout = nSoftCashoutProduction;
	nSoftCashoutWinnerFun = nSoftCashoutWinnerFunProduction;
}

export { nSoftAuth, nSoftAuthWinnerFun, nSoftLive, nSoftLiveWinnerFun, nSoftPrematch, nSoftCommon, nSoftReport, nSoftTicket, nSoftRisk, nSoftCashoutPrematch, nSoftCashout, nSoftCashoutWinnerFun };
