import React from 'react';
import { withRouter } from 'react-router-dom';
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import "./scanner_dialog.scss";
import HeaderIcon from "../assets/success-icon.svg";
import Scanner from "../../bets/components/BetSlip/Scanner2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Rules = props => {
  const {
    open,
    onClose,
    onResult,
    t,
  } = props;

  if (!open) return null;

  const handleClose = () => {
    if (typeof onClose === "function") onClose();
  }

  const handleDone = () => {
    props.history.push("/");
  };

  const handleScanResult = result => {
    const text = result.split(".");
    let code = text[0] ? text[0].trim() : "";

    if (typeof onResult === "function") onResult(code);
    if (typeof onClose === "function") onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winter-time-rules`}
    >
      <DialogContent className={"dialogContent"}>
        <div className={"dialogBody"}>
          <Scanner
            onDetected={handleScanResult}
            onCancel={handleDone}
          />

          <div className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
            <ButtonBase className={`dialogOK dialogOKOutline`} onClick={handleClose}>
              {t("Cancel Scan")}
            </ButtonBase>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
  }
};
const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Rules)));



