import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './../../../store/actions';
import './ButtonNext.css';
import Icons from './../../../assets/Icons/Icons';

class ButtonNext extends Component {
    state = {
        iconName: 'login-next',
        text: 'next',
        cssClass: 'none',
    };

    clickHandler() {
        this.props.onButtonPressed(this.props.parentType);
    }
    
    render() {
        let iconName = this.state.iconName;
        let cssClass = this.state.cssClass;
        let buttonText = this.state.text;

        if ('password' === this.props.parentType) {
            iconName = this.props.authButton.iconName;
            cssClass = this.props.authButton.cssClass;
            buttonText = this.props.authButton.text;
        }

        let icon = Icons.get(iconName);

        return (
            <div className="ButtonNext text-right">
                <button className="btn btn-warning" onClick={this.clickHandler.bind(this)}>
                    <span className={"icon text-center " + cssClass}>{icon}</span>
                    <span className="text text-left">{buttonText}</span>
                </button>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onStepChanged: (step) => dispatch(actions.changeModalStep(step)),
        onAuthStart: (username, password) => dispatch(actions.auth(username, password)),
        onButtonPressed: (parentType) => dispatch(actions.authButtonPressed(parentType)),
    };
};

const mapStateToProps = state => {
    return {
        authenticationModal: state.authModal,
        authButton: state.authButton
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonNext);