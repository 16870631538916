import React, {Component} from 'react';
import Icons from "../../assets/Icons/Icons";

class LoremContent extends Component {
    render() {
        let icon = this.props.icon === false ? "" : Icons.get('party', 'casino-live-games');

        let title = typeof this.props.title === "undefined" ? "Casino Live Page" : this.props.title;

        return (
            <div>
                <h1>
                    <span className="icon">{icon}</span>
                    <span className="text">{title}</span>
                    <div style={{
                        clear: "both",
                    }}> </div>
                </h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis, arcu ut maximus pretium, tortor augue tincidunt leo, sed hendrerit sem leo vel mauris. Pellentesque hendrerit sollicitudin velit ac accumsan. Curabitur suscipit malesuada blandit. Aliquam vestibulum egestas libero tincidunt lacinia. Cras nunc lacus, varius id est eget, hendrerit rhoncus augue. Etiam eu posuere metus. In euismod rhoncus imperdiet. Sed eleifend lectus in dapibus dignissim. Donec volutpat at leo id rutrum.</p>
                <p>Praesent eget iaculis sapien. Nullam eget orci a justo eleifend vulputate. Quisque fermentum eget ligula at rhoncus. In semper, velit et feugiat auctor, leo mi sollicitudin mauris, ut porttitor lorem purus eget nisi. Integer elit enim, sodales non ipsum sit amet, bibendum pharetra orci. In iaculis ut urna id aliquam. Nulla efficitur metus tortor, eget commodo erat commodo vel.</p>
                <p>Morbi pretium ligula nibh, a ornare eros elementum vitae. Donec a luctus sapien. Nulla facilisi. Maecenas sem augue, viverra a maximus quis, luctus non ante. Suspendisse semper, neque ut pellentesque ullamcorper, magna orci porttitor eros, nec porttitor eros sem eget lectus. Aliquam erat volutpat. Integer in nisi in quam luctus luctus. Nam placerat pretium enim eget tempus. In efficitur, justo nec ornare sagittis, purus nunc sagittis enim, vitae feugiat mauris mi a urna. Fusce ornare quis nulla vitae varius. Praesent arcu nibh, euismod id nibh id, vehicula fringilla ex. Suspendisse venenatis finibus cursus. Nulla at mi nec mauris blandit pulvinar nec et dolor. Nunc vehicula tempus dapibus. Praesent blandit dignissim mattis.</p>
            </div>
        );
    }
}

export default LoremContent;