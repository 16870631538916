import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { mergeStyles } from "../../styles";
import eventStyles from "./styles";

import Favorite from "../../elements/Favorite";
import LiveBadge from "../../elements/LiveBadge";

import { formatDate } from "../../utils/formatters";

import { makeGetStateMatch } from "../../store/selectors/betData";
import { showLeagueWithDataSet, noop } from "../../utils/betUtils";
import StatScoreAvailable from "../StatScoreAvailable";
import { getBetsState } from "../../store/selectors/betData";
import { chooseTextLanguage } from "../../utils";
import { activeWinnerFunCampaigns } from "../../utils/winnerFunUtils";
import { evaluateMatch2 } from "../../utils/winnerFunEvaluation";
import MultiplierIcon from "../Tickets/assets/multiplier-icon.png";

const useStyles = makeStyles({
  root: {
    margin: "4px 0 0"
  },
  startTime: {
    fontSize: 9,
    fontWeight: 600,
    color: "#fff",
    padding: "4px 8px 4px 0",
    textTransform: "capitalize"
  },
  liveWrapper: {
    padding: "7px 4px 0px 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    alignItems: "center",
    alignContent: "center"
  },
  liveEventInterval: {
    minWidth: 24,
    fontSize: 12,
    fontWeight: 600,
    color: "#06D2BD"
  },
  liveEventTime: {
    marginTop: "7px",
    minWidth: 24,
    fontSize: 12,
    fontWeight: 600,
    color: "#009ED9"
  },
  emptyEventTime: {
    "&:after": {
      content: "'\\200b'"
    }
  },
  teamLine: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "baseline"
  },
  teamScore: {
    fontSize: 12,
    fontWeight: 600,
    marginLeft: "8px"
  },
  teamScoreTotal: {
    color: "#06D2BD"
  },
  eventTitle: {
    textTransform: "uppercase",
    fontSize: 9,
    fontWeight: 700,
    padding: "1px 0",
    borderRadius: 4,
    color: "#979DAB",
    cursor: "pointer",
    textAlign: "left"
  },
  dark: {
    color: "#fff"
  },
  muted: {
    opacity: 0.6
  },
  w100: {
    width: "100%"
  },
  liveBadge: {
    position: "relative",
    left: "-5px"
  },
  flx: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  winFunIcon: {
    height: "18px",
    display: "inline-block",
    width: "auto",
    margin: "0 5px 0 0",
    position: "relative",
    top: "1px"
  },
  cashout: {
    borderRadius: "5px",
    background: "#FA7036",
    fontSize: "7px",
    textTransform: "uppercase",
    fontWeight: "600",
    color: "#fff",
    padding: "2px 5px",
    marginRight: "5px",
    whiteSpace: "nowrap",
  }
});

// Soccer, Baseball, Basketball, Tennis, Badminton, Beach Volley, Squash
const cashoutAvailable = ["1", "3", "2", "5", "31", "34", "37"];

const Live = props => {
  const classes = mergeStyles(useStyles, eventStyles);

  const {
    match,
    idMatch,
    idTournament,
    idCategory,
    idSport,
    matchDateTime,
    mType,
    brId,
    winnerPlus,
    winnerAdv,
    winnerSpecial,
    isWinnerFun,
    winnerFunCampaigns,
    title, dark, disableDetails, inGroup, showFav, t, i18n, noStatScore
  } = props;

  if (!idMatch) return null;

  const lang = i18n.language;

  let winnerFunTitle = "";
  let winnerFunEvent = false;
  if (isWinnerFun) {
    const winnerFunData = activeWinnerFunCampaigns(winnerFunCampaigns);
    if (winnerFunData && winnerFunData.event) {
      if (winnerFunData.event.filters && winnerFunData.event.filters.live) {
        const fs = winnerFunData.event.filters.live;
        winnerFunTitle = chooseTextLanguage(winnerFunData.event.name.text, i18n.language);
        winnerFunEvent = evaluateMatch2(fs, idMatch, idTournament, idCategory, idSport);
      }
    }
  }

  // const canCashout = cashoutAvailable.findIndex(elem => idSport === elem) > -1;
  let canCashout = false;
  if (match) {
    for (const mb of match.matchBets) {
      for (const mbo of mb.mbOutcomes) {
        if (mbo.isCashoutEnabled) {
          canCashout = true;
          break;
        }
      }

      if (canCashout) {
        break;
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
        <div className="d-flex flex-row flex-nowrap align-items-center">
          {!inGroup && (
            <div className={classes.liveBadge}>
              <LiveBadge />
            </div>
          )}
          {winnerFunEvent && <img src={MultiplierIcon} alt="" className={classes.winFunIcon} title={winnerFunTitle} />}
          {!!matchDateTime === true && inGroup && (
            <div className={classes.startTime}>
              {formatDate(parseInt(matchDateTime, 10), lang)}
            </div>
          )}
          {!!title === true && !inGroup && (
            <div
              className={`${classes.eventTitle} event-card-title`}
              onClick={!disableDetails ? showLeagueWithDataSet : noop}
              data-mtype={mType}
              data-idsport={idSport}
              data-idcategory={idCategory}
              data-idtournament={idTournament}
            >
              {title}
            </div>
          )}
        </div>


        <div className={`${classes.flx} text-right align-items-center`}>
          {canCashout && <div className={classes.cashout}>{t("CASHOUT")}</div>}
          {!noStatScore && <StatScoreAvailable idMatch={mType === "live" ? idMatch : brId} mType={mType} type="classic"
            provider={match.provider}
            hasScout={match.hasScout}
            hasLiveTV={match.hasLiveTV}
            idSport={match.idSport}
          />}
          {(inGroup || showFav) && (<Favorite
            mType="live"
            dark={dark}
            idMatch={idMatch}
            winnerPlus={winnerPlus}
            winnerAdv={winnerAdv}
            winnerSpecial={winnerSpecial}
          />)}
        </div>

      </div>
    </div>
  );
};

const getStateMatch = makeGetStateMatch();
const makeMapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  const match = getStateMatch(state, props);

  if (!match) {
    return {
      idMatch: null,
    }
  }

  const data = {
    match,
    idMatch: match.idMatch,
    idTournament: match.idTournament,
    idCategory: match.idCategory,
    idSport: match.idSport,
    mType: match.mType,
    brId: match.brId,
    matchDateTime: match.matchDateTime,
    winnerPlus: match.winnerPlus,
    winnerAdv: match.winnerAdv,
    winnerSpecial: match.winnerSpecial,
    isWinnerFun: bst.app.isWinnerFun,
    winnerFunCampaigns: bst.config.winnerFunCampaigns
  };

  return data;
};

export default withTranslation()(connect(makeMapStateToProps)(React.memo(Live)));
