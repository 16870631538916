import { configConstants } from './constants';

export const configLoaded = data => ({
	type: configConstants.LOADED,
	data
});

export const configReload = () => ({
	type: configConstants.RELOAD
});

export const configUpdate = data => ({
	type: configConstants.UPDATE,
	data
});
