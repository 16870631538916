import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { gsap, TimelineMax, TweenMax, Power3, Power2, Linear, Draggable } from "gsap/all";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear);

const useStyles = makeStyles({
  button: {
    width: "100%",
    padding: "9px",
    color: "#fff",
    borderRadius: 9,
    fontSize: 9,
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: "center",
    overflow: "hidden",
    "-webkit-mask-image": "-webkit-radial-gradient(white, black)",
    ">span": {
      borderRadius: 9,
    }
  },
  update: {
    background: "linear-gradient(90deg, #009ED9, #14259B, #009ED9, #14259B)",
    backgroundRepeat: "no-repeat",
    //backgroundSize: "400% 400%",
    //animation: "buttonAnim 10s ease infinite",
    width: "400%",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    borderRadius: 9,
  },
  textContent: {
    transform: "translate3d(0, 0, 0)"
  }
});
const AnimatedButton = ({ title, onClick }) => {
  const classes = useStyles();

  const tlMotion = React.useRef();
  const buttonBgRef = React.useRef();

  React.useEffect(() => {
    if (buttonBgRef && buttonBgRef.current) {
      if (tlMotion && tlMotion.current) {
        tlMotion.current.kill();
        tlMotion.current = null;
      }

      tlMotion.current = gsap.timeline({ repeat: -1, repeatDelay: 4 });
      tlMotion.current
        .to(buttonBgRef.current, { x: "-75%", duration: 5 })
        .to(buttonBgRef.current, { duration: 2 })
        .to(buttonBgRef.current, { x: "0%", duration: 5 });
    }
  }, [buttonBgRef]);
  React.useEffect(() => {
    return () => {
      if (tlMotion && tlMotion.current) {
        tlMotion.current.kill();
        tlMotion.current = null;
      }
    };
  }, []);

  return (
    <Button component="div" className={classes.button} onClick={onClick}>
      <div className={classes.update} ref={buttonBgRef}>
        &nbsp;
      </div>
      <div className={classes.textContent}>{title}</div>
    </Button>
  );
};

export default AnimatedButton;
