import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './ClaimBonus.scss';
import {connect} from "react-redux";
import * as storeActions from "../../store/actions";
import {CircularProgress} from "@material-ui/core";
import Utils from "../../utils/Utils";
import ClaimPopup from "./ClaimPopup/ClaimPopup";
import BackendClient from "../../BackendClient";
import BonusAvailableWidget from "./BonusAvailableWidget/BonusAvailableWidget";
import { Modal, ModalHeader, ModalBody} from "reactstrap";
import {bonusAvailable} from "../../store/actions/actionTypes";

const ClaimBonus = (props) => {

    const { t } = useTranslation();

    const [bonusCounter, setBonusCounter] = useState(0);
    const [bonus,setBonus] = useState(false);
    const [loadingPage,setLoadingPage] = useState(false);
    const [selectedBonus,setSelectedBonus] = useState(false);
    const [bonusId, setBonusId] = useState(false);

    useEffect(() => {
        props.getBonusAvailable();
        if (props.availableBonuses) {
            setBonusCounter(Object.keys(props.availableBonuses).length)

            // props.bonusClaimed([]);
            // props.bonusClaimed({ResponseCode: 1,detail: null})
            // props.bonusClaimed({detail: {canRetry: false,amount: 200}});
            // props.bonusClaimed({details: {canRetry: true,amount: 200}});
        } else {
            if (!bonus) {
                // props.history.push('/');
            }
        }
    },[props.availableBonuses]);

    useEffect(() => {
        setLoadingPage(false);
    },[props.claimError]);

    useEffect(() => {

        if (props.claimError !== null) {
            props.resetClaimError();
        }
        const {bonus} = props.match.params;
        setBonus(bonus);
        if(bonus) {
            // setBonusByHash(bonus);
        }

    },[])

    const setBonusByHash = (hash) => {
        let info = Utils.decodeString(hash);
        console.log(info,'test123')
    }

    const getHashByBonus = (id,name) => {
        let hash = Utils.encodeString(id + ';' + name);

    }

    const onClickedBonus = (bonusId,bonus) => {
        if(bonusId > 0) {
                setBonusId(bonusId);
                claimBonus(bonusId,false);
                setLoadingPage(true);
                setSelectedBonus(bonus);
            // props.history.push(Utils.encodeString(bonusId + ';' + bonus.name));
        }
    }

    const claimBonus = (bonusId, force) => {
        const axios = BackendClient.getInstance();
        let data = {
            bonusAvailableId: bonusId
        }
        if(force) {
            data.force =  1;
        }

        const response = axios({
            url: "/api/casino/claimBonus",
            method: 'post',
            data: data
        }).catch(e => {
            console.log(`[CATCHED **casino jackpot** ERROR] =? ${e}`);
        });
    }

    const forceBonus = (bonusId) => {
        if(bonusId > 0) {
            setBonusId(bonusId);
            claimBonus(bonusId,true);
        }
    }

    const getContent = () => {
         if (props.claimError !== null) {
            return (<ClaimPopup selectedBonus={selectedBonus} getBonus={() => forceBonus(bonusId)}/>)
        } else if(loadingPage || bonusCounter === 0) {
             return (<div className={'centerLoading'}><CircularProgress className={"Loading"}/></div> )
         } else if (bonus) {

        } else if (bonusCounter > 0) {
            return (<BonusAvailableWidget type={'list'} onClickedBonus={onClickedBonus.bind(this)} cta_text={props.t("CLAIM NOW")}/>)
        } else {
            return ( <BonusAvailableWidget type={'compact'} bonusAvailableId={props.availableBonuses[Object.keys(props.availableBonuses)[0]].bonus_group_id} onClickedBonus={onClickedBonus.bind(this)} />)
        }
    }
    let modalClassName = [
        "DepositModal", "h-100", "d-flex", "flex-column", "justify-content-center", "my-0"
    ];
    return (
        <Modal isOpen={true} className={modalClassName.join(' ')} backdrop={'static'} modalTransition={{ timeout: 300 }} zIndex={9999}>
            <ModalHeader toggle={() => props.history.goBack()} className="WithdrawModalHeader">{props.t("Bonus for you")}</ModalHeader>
            <ModalBody >
                {/*<CustomScroll  allowOuterScroll={false}>*/}
                    <div id={"ClaimBonus"} >
                        {getContent()}
                    </div>
                {/*</CustomScroll>*/}
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        availableBonuses: state.application.availableBonuses,
        claimError: state.application.bonusClaimError
    }
};

const mapDispatchToProps = dispatch => {
    return {
        bonusClaimed: (details) => dispatch(storeActions.bonusClaimed(details)),
        resetClaimError: () => dispatch(storeActions.resetBonusClaimStatus()),
        getBonusAvailable: () => dispatch({type:bonusAvailable.GET}),

    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ClaimBonus)));
