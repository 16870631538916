/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 07/11/2018
 * Time: 13:55
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Icons from "../../../../../assets/Icons/Icons";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ValueCurrency from './ValueCurrency';
import Utils from "../../../../../utils/Utils";
import { VelocityComponent } from "velocity-react";
import * as stateActions from "../../../../../store/actions";

class MoneyWidget extends Component {
    constructor(props) {
        super(props);

        if (this.props.type === 'real') {
            this.title = "Money";
            this.icon = Icons.get('piggy-bank', 'real-money-icon');// TODO: icon
            this.icon2 = "";
            this.iconWidth = 56;
            this.iconHeight = 64;
        } else {
            this.title = "Bonus";
            this.icon = Icons.get('claim-bonus', 'bonus-money-icon');
            this.icon2 = Icons.get('green-info', 'green-info');
            this.iconWidth = 40;
            this.iconHeight = 48;
        }

        this.fontSize = 33;

        this.globalId = Utils.generateNumericRandomId();
        this.popoverId = "money-widget-popover_" + this.globalId;
        this.containerId = 'money-widget-container_' + this.globalId;
        this.iconId = 'money-widget-icon_' + this.globalId;
        this.valueId = 'money-widget-value_' + this.globalId;
        this.valueItemId = 'money-widget-value-item_' + this.globalId;

    }

    state = {
        showPopOver: false,
        popoverTries: 0,
        popover: {
            style: {
                opacity: 1,
            }
        },
        icon: {
            style: {
                opacity: 1,
            },
        },
        value: {
            style: {
                opacity: 1,
            },
            item: {
                style: {
                    opacity: 1,
                }
            }
        },
        lastContainerWidth: -1,
        readyToBeSynced: false,
    };

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.fontSize !== this.props.fontSize) {
            // let _self = this;
            // setTimeout(function () {
            //     _self.changePosition(true);
            // }, 500);
            this.changePosition();
        }
    }

    getCurrentFontSize() {
        let fontSize = parseInt(document.getElementById(this.valueItemId).style.fontSize, 10);
        if (!fontSize) {
            fontSize = parseInt(window.getComputedStyle(document.getElementById(this.valueItemId)).fontSize, 10);
        }

        return fontSize;
    }

    getNewIconDimensions(newFontSize) {

        let fontSize = this.getCurrentFontSize();
        // we need to resize the icon too;
        // get icon dimensions
        let iconHeight = parseInt(document.getElementById(this.iconId).style.height, 10);
        let iconWidth = parseInt(document.getElementById(this.iconId).style.width, 10);

        if (!iconHeight) {
            iconHeight = this.iconHeight;//parseInt(window.getComputedStyle(document.getElementById(this.iconId)).height);
        }
        if (!iconWidth) {
            iconWidth = this.iconWidth;//parseInt(window.getComputedStyle(document.getElementById(this.iconId)).width);
        }

        // keep aspect ratio for icon, based on fontSize
        let newIconHeight = (iconHeight * newFontSize) / fontSize;
        let newIconWidth = (iconWidth * newFontSize) / fontSize;

        return {
            height: newIconHeight > this.iconHeight ? this.iconHeight : (newIconHeight < 0.5 * this.iconHeight ? 0.5 * this.iconHeight : newIconHeight),
            width: newIconWidth > this.iconWidth ? this.iconWidth : (newIconWidth < 0.5 * this.iconHeight ? 0.5 * this.iconHeight : newIconWidth),
        }
    }

    getSyncedFontSize() {
        return this.props.fontSize;
    }

    changePosition() {

        if (!document.getElementById(this.containerId)) {
            return Utils.delayedFunction('changeMoneyWidgetFontSize', this.props.setGlobalMoneyWidgetFontSize.bind(this), 1);
        }

        // calculate container width, this helps us to reset sizes and positions for widget elements
        let containerWidth = 0;
        if (typeof document.getElementById(this.containerId).getClientRects()[0].style !== "undefined") {
            containerWidth = parseInt(document.getElementById(this.containerId).getClientRects()[0].style.width, 10);
        } else {
            containerWidth = parseInt(window.getComputedStyle(document.getElementById(this.containerId)).width, 10);
        }

        // if container width has increased, reset the widget, and rerun this function
        if (this.state.lastContainerWidth < containerWidth) {
            this.setState({
                ...this.state,
                lastContainerWidth: containerWidth,
                popover: {
                    ...this.state.popover,
                    // popover need to be repositioned
                    style: {
                        marginTop: -this.fontSize - 2,
                    }
                },
                icon: {
                    style: {
                        width: this.iconWidth,
                        height: this.iconHeight,
                    }
                },
                value: {
                    ...this.state.value,
                    style: {
                        opacity: 1,
                    },
                    item: {
                        ...this.state.value.item,
                        style: {
                            opacity: 1,
                            fontSize: this.fontSize,
                        }
                    }
                },
            });

            let _self = this;
            // recalculate size
            return Utils.delayedFunction('changeMoneyWidgetFontSize', function () {
                _self.props.setGlobalMoneyWidgetFontSize(_self.fontSize);
            }, 100);
        }
        //
        // // get details for values
        // let valueDetails        = document.getElementById(this.valueId).getClientRects()[0];
        // let valueItemsDetails = document.getElementById(this.valueItemId).getClientRects()[0];
        //
        // get synced fontSize
        let fontSize = this.getSyncedFontSize();
        let currentFontSize = this.getCurrentFontSize();

        if (fontSize === currentFontSize && this.state.lastContainerWidth !== -1) {
            // return true;
        }

        // get icon dimensions based on the fontSize;
        let iconDimensions = this.getNewIconDimensions(fontSize);

        this.setState({
            ...this.state,
            popover: {
                ...this.state.popover,
                // popover need to be repositioned
                style: {
                    marginTop: -fontSize - 2,
                }
            },
            icon: {
                style: {
                    width: iconDimensions.width,
                    height: iconDimensions.height,
                    // marginLeft: iconWidth * 0.4
                }
            },
            value: {
                ...this.state.value,
                style: {
                    opacity: 1,
                    // width: valueWidth,
                    // maxWidth: valueWidth,
                    // paddingRight: 20,
                    // marginLeft: 0
                },
                item: {
                    ...this.state.value.item,
                    style: {
                        opacity: 1,
                        fontSize: fontSize,
                    }
                }
            },
            lastContainerWidth: containerWidth,
        });

        // if we are here, it means that this dimensions are the optimum one
        Utils.delayedFunction('changeMoneyWidgetFontSize', this.props.setGlobalMoneyWidgetFontSize.bind(this), 1);

    }

    componentDidMount() {
        Utils.delayedFunction('changeMoneyWidgetFontSize' + this.popoverId, this.changePosition.bind(this), 200);

        this.attachPopoverEvents();

        window.addEventListener('resize', this.changePosition.bind(this));

        this.props.moneyWidgetLoaded(this.globalId);

        this.setState({
            ...this.state,
            icon: {
                ...this.state.icon,
                style: {
                    ...this.state.icon,
                    width: this.iconWidth,
                    height: this.iconHeight,
                }
            }
        });

        this.changePosition();

        if (this.props.withdrawals.requested) {
            this.props.requestedState(false);
            return;
        }
    }

    attachPopoverEvents() {
        let popover = document.getElementById(this.popoverId);
        if (popover) {
            popover.addEventListener("mouseenter", this.showPopover.bind(this));
            popover.addEventListener("mouseleave", this.hidePopover.bind(this));
        } else {
            // try 3 times to attach the events
            if (this.state.popoverTries < 3) {
                this.setState({
                    ...this.state,
                    popoverTries: this.state.popoverTries + 1,
                });
                Utils.delayedFunction('attachPopoveEventsMoneyWidget', this.attachPopoverEvents.bind(this), 1000);
            }
        }
    }

    componentWillUnmount() {
        let popover = document.getElementById(this.popoverId);
        window.removeEventListener('resize', this.changePosition);
        if (popover) {
            popover.removeEventListener("mouseenter", this.showPopover);
            popover.removeEventListener("mouseleave", this.hidePopover);
        }
    }

    showPopover() {
        if (this.state.showPopOver !== true) {
            this.setState({
                ...this.state,
                showPopOver: true
            })
        }
    }

    hidePopover() {
        if (this.state.showPopOver !== false) {

            this.setState({
                ...this.state,
                showPopOver: false
            })
        }
    }

    getComponent(type) {
        let comp = [];
        let pendingContent = '';
        let approved = 0;
        let pending = 0;

        if (this.props.withdrawals.approvedRequests.length > 0 || this.props.withdrawals.pendingRequests.length > 0) {
            if (this.props.withdrawals.approvedRequests.length > 0) {
                this.props.withdrawals.approvedRequests.map(valueApproved => approved += valueApproved.amount);
            }

            if (this.props.withdrawals.pendingRequests.length > 0) {
                this.props.withdrawals.pendingRequests.map(valuePending => pending += valuePending.amount);
            }

        }
        if (approved > 0 || pending > 0) {
            pendingContent = (
                <Fragment>
                    <div className="ProductItem">

                        <p>+<ValueCurrency value={approved + pending} currency={this.props.currency} /></p>
                    </div>
                    <div className="ProductItem Icons">
                        {approved > 0 && Icons.get('pwSuccess', `ApprovedIcon`)}
                        {pending > 0 && Icons.get('wPending', `PendingIcon`)}
                    </div>
                    <div className="ProductItem">
                        <span className="LinkStyle" onClick={this.pendingWithdrawalsHandler.bind(this)}>{this.props.t('Pending withdrawals')}</span>
                    </div>
                </Fragment>
            );
        } else {
            pendingContent = (
                <div className="ProductItem">
                    <span className="LinkStyle" onClick={this.pendingWithdrawalsHandler.bind(this)}>{this.props.t('There are no pending witdrawals')}</span>
                </div>
            );
        }

        switch (type) {
            case 'buttons':
                if (this.props.type === 'real') {
                    let withdrawDangerIcon = (!this.props.profile.accountVerified) ? Icons.get("exWarning", "withdraw-alert-icon") : null;

                    comp.push(
                        <div className="buttons" key={"buttons_money_widget"}>
                            <div className="PendingContent">
                                <div className="PendingProducts">
                                    {pendingContent}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    comp.push(
                        <div className="buttons" key={"buttons_money_widget"}>
                            <div className="BonusProducts">
                                <div className="ProductItem">
                                    <p>{this.props.t('Casino')}</p>
                                    <p>{Utils.formatSmallDecimals(this.props.wallet && this.props.wallet[1] ? this.props.wallet[1].bonusAmount : 0 , this.props.currency)}</p>
                                </div>
                                <div className="ProductItem">
                                    <p>{this.props.t('Sportsbook')}</p>
                                    <p>{Utils.formatSmallDecimals(this.props.wallet && this.props.wallet[2] ? this.props.wallet[2].bonusAmount : 0 , this.props.currency)}</p>
                                </div>
                                {/*<div className="ProductItem">*/}
                                {/*    <p>{this.props.t('LIVE')}</p>*/}
                                {/*    <p>{Utils.formatSmallDecimals(0, this.props.currency)}</p>*/}
                                {/*</div>*/}
                            </div>
                        </div>)
                }
                break;
            case 'popover':
                if (this.icon2 === "") break;

                comp.push(
                    <VelocityComponent animation={this.state.popover.style} duration={2} runOnMount={true} key={this.popoverId}>
                        <div id={this.popoverId} className="right popover-money-widget">
                            {this.icon2}
                        </div>
                    </VelocityComponent>
                );
                /*<PopoverHeader>Popover Title</PopoverHeader>*/
                comp.push(<Popover placement="right" isOpen={this.state.showPopOver} target={this.popoverId} key={this.popoverId + "_1"}>

                    <PopoverBody>{this.props.t("BONUS funds have wager conditions before they can be withdrawn.")}</PopoverBody>
                </Popover>);
                break;
            default:

                break;
        }

        return comp;
    }

    depositHandler(event) {
        event.preventDefault();
        event.stopPropagation()

        if (!this.props.application.menu.hasOwnProperty("deposit")) {
            return false;
        }

        let depositPageRow = this.props.application.menu["deposit"];
        let pageUrl = `/${depositPageRow.code}`;
        this.props.history.push(pageUrl);

        this.props.onPageChanged(depositPageRow);

        return false;
    }

    pendingWithdrawalsHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.props.application.menu.hasOwnProperty("profile")) {
            return false;
        }

        let profilePageRow = this.props.application.menu["profile"];
        let subPages = profilePageRow.submenus;
        let pendingWithdrawalsItem = subPages.find((elem) => {
            return "pending-withdrawals" === elem.code;
        });

        if (!pendingWithdrawalsItem) {
            return false;
        }

        let stringUrl = `/${profilePageRow.code}/${pendingWithdrawalsItem.code}`;
        this.props.history.push(stringUrl);

        this.props.onSubpageChanged(pendingWithdrawalsItem);

        return false;
    }

    promotionsHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.props.application.menu.hasOwnProperty("cazino")) {
            return false;
        }

        let cazinoPageRow = this.props.application.menu["cazino"];
        let subPages = cazinoPageRow.submenus;
        let promotionsItem = subPages.find((elem) => {
            return "promotii" === elem.code;
        });

        if (!promotionsItem) {
            return false;
        }

        let stringUrl = `/${cazinoPageRow.code}/${promotionsItem.code}`;
        this.props.history.push(stringUrl);

        this.props.onSubpageChanged(promotionsItem);

        return false;
    }

    withdrawHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        // open withdrawals popup
        this.props.openWithdrawalModal();

        return false;
    }

    render() {

        let buttons = this.getComponent('buttons');
        let popoverComp = this.getComponent('popover');

        return (

            <div className={`money-widget ${this.props.type}`} id={this.containerId}>

                <div className="col-3" >
                    <VelocityComponent animation={this.state.icon.style} duration={2} runOnMount={true}>
                        <div id={this.iconId}>
                            {this.icon}
                        </div>
                    </VelocityComponent>
                </div>

                <VelocityComponent animation={this.state.value.style} duration={2} runOnMount={true}>
                    <div className="col-9" id={this.valueId}>
                        <div className="title">{this.props.t(this.title)}</div>
                        <VelocityComponent animation={this.state.value.item.style} duration={2} runOnMount={true}>
                            <div className="value" id={this.valueItemId}>
                                {Utils.formatSmallDecimals(this.props.value, this.props.currency)}
                                {popoverComp}
                            </div>
                        </VelocityComponent>
                    </div>
                </VelocityComponent>
                <div className="clearfix">&nbsp;</div>
                <div className="col-3">
                    &nbsp;
                </div>
                <div className="col-9">
                    {buttons}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setGlobalMoneyWidgetFontSize: (number) => dispatch(stateActions.setGlobalMoneyWidgetFontSize(number)),
        moneyWidgetLoaded: (id) => dispatch(stateActions.moneyWidgetLoaded(id)),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
        openWithdrawalModal: () => dispatch(stateActions.openWithdrawalModal()),
        requestedState: (value) => dispatch(stateActions.changeRequestedState(value)),
    };
};

const mapStateToProps = state => {
    return {
        fontSize: state.wallet.moneyWidget.fontSize,
        application: state.application,
        profile: state.profile,
        withdrawals: state.withdrawals,
        wallet: state.wallet.walletManagement
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MoneyWidget)));
