export const ro = {
	"404": "404",
	"Self exclusion period": "Perioada de auto-excludere",
	from: "de la",
	to: "până la",
	"If you want to cancel this action, please contact support.":
		"Contactați serviciul de Suport Jucători pentru mai multe informații.",
	"Open support": "Contactează Suport Jucători",
	Cancel: "Anulare",
	"60 minutes spent on website from last notification":
		"Ai petrecut 60 minute pe site de la ultima notificare",
	OK: "OK",
	"We use cookies for an improved experience. Continuing to use our services, you must agree with our": "Folosim cookie-uri pentru a-ți oferi cea mai bună experiență. Continuând să folosești serviciile noastre, iți exprimi acordul privind termenii",
	"Privacy Policy": "Politica de confidențialitate",
	Accept: "Accept",
	"About Cookies": "Cookies",
	"Necessary Cookies": "Cookie-uri necesare",
	"These cookies are essential to provide you with a fully functional website, features and access to the secure areas. For example, we use them to keep your account safe by encrypting your session. If you delete the cookies or instruct your web-browser to not allow cookies, we might not be able to provide you all the functionality you came here for.":
		"Aceste cookie-uri sunt esențiale pentru a vă oferi un site web complet funcțional, funcții și acces la zonele sigure. De exemplu, le folosim pentru a vă păstra contul în siguranță, criptând sesiunea. Dacă ștergeți cookie-urile sau vă instruiți browserul web să nu permită cookie-urile, este posibil să nu vă putem oferi toate funcționalitățile pentru care ați venit aici.",
	"You may want to find out more about cookies: ${beforeCookiesURL}http://www.allaboutcookies.org${afterCookiesURL}":
		"Poate doriți să aflați mai multe despre cookie-uri: ${beforeCookiesURL}http://www.allaboutcookies.org${afterCookiesURL}",
	"Advertising Cookies": "Cookie-uri de publicitate",
	"We do not use any advertising cookies at this moment. There is nothing to worry about pesky advertising.":
		"Nu folosim nicio publicitate de cookie-uri în acest moment. Nu este nimic de care să vă faceți griji cu privire la publicitatea neplăcută.",
	"We value your privacy concerns and adhere to GDPR rules. If you want to find out more, please read our ${beforeCookiesURL}Privacy Policy${afterCookiesURL}.":
		"Evaluăm problemele dvs. de confidențialitate și respectăm regulile GDPR. Dacă doriți să aflați mai multe, vă rugăm să citiți linkul nostru ${beforeCookiesURL}GDPR si confidentialitate${afterCookiesURL}.",
	"Keep your account secure and verify %s": "Păstrați-vă contul în siguranță și verificați %s",
	"Prevent losing access to your acount in %s days. Verify now %s":
		"Împiedicați pierderea accesului la suma dvs. în %s zile. Verificați acum %s",
	connect: "Conectare",
	register: "Înregistrare",
	"without any passwords needed!": "Conectare doar cu telefonul!",
	"For more informations": "Află mai multe",
	"If you have not yet received the SMS Code, you may request a new one in":
		"Daca nu ai primit încă un Cod SMS, poți cere altul în",
	seconds: "secunde",
	"Send a new SMS code for login.": "Vreau un nou cod SMS de conectare.",
	"Password or SMS Code": "Parola sau codul primit prin SMS",
	"Forgot password?": "Ai uitat parola?",
	"E-mail or Phone": "E-mail sau Număr de Telefon",
	"Create Account": "Cont nou",
	"PlayOnline is operated by ${companyName}, registered in Malta with number ${registerNumber} at the address listed below. In Romania, Play Online Solutions LTD operates under license number ${onjnLicenseNumber}, issued by the Romanian Gambling Office (ONJN), valid from ${date} until ${licenseExpirationNumber}.":
		"Play Online este operat de ${companyName}, înregistrat în Malta cu numărul ${registerNumber} la adresa menționată mai jos. În România, ${companyName} operează sub numărul de licență ${onjnLicenseNumber}, valabil de la data de ${date} de către Oficiul Național de Jocuri de Noroc (ONJN), cu valabilitate până la data de ${licenseExpirationNumber}.",
	"Gambling is forbidden to persons under 18 years old. Gambling may be prohibited in the area where you are located. It is the player's responsibility to comply with the legal regulations.":
		"Jocurile de noroc sunt interzise persoanelor cu vârsta sub 18 ani. Jocurile de noroc pot fi interzise în zona în care sunteți localizat. Este responsabilitatea jucătorului să respecte reglementările legale.",
	"Gambling may become addictive and impact your financials. Read about responsible gaming ${begin_url}here${end_url}.":
		"Jocurile de noroc pot provoca dependență și pot avea impact asupra finanțelor tale. Citiți despre joc responsabil ${begin_url}aici${end_url}.",
	"Address:": "Adresa",
	"${operator_Malta_address}": "${operator_Malta_address}",
	"Phone number:": "Număr de telefon:",
	"${operator_contact_phone_number}": "${operator_contact_phone_number}",
	"Email address:": " Adresa email:",
	"${operator_contact_email_address}": "${operator_contact_email_address}",
	"If you want to send a complaint, please contact support ":
		"Dacă doriți să postați o plangere, vă rugăm să faceți click pentru mai multe informatii ",
	here: "aici",
	"If we are unable to solve your complaint within 14 days, you will have the right to contact the National Gambling Office (ONJN).":
		"Dacă nu putem rezolva reclamația dvs. în termen de 14 zile, veți avea dreptul să contactați Oficiul Național pentru Jocuri de Noroc (ONJN).",
	'What is "Deposits & Withdrawals in Location"?':
		"Ce înseamnă “Depuneri și Retrageri în Agenție”?",
	"We made it simpler so you can play as you want. If you want to stay away from Credit Cards and other digital payment systems, you are free to deposit and withdraw in any casino location using only your ID card.":
		"Este sistemul nostru inovativ pentru cei care nu vor să folosească card-uri bancare sau alte metode de plată electronice. Poți efectua operațiuni din orice agenție doar cu buletinul.",
	"Can I cash-in in any Location?": "Pot retrage în orice Agenție?",
	"You can cash-in up to 200 Lei per day in any location. Withdrawal amounts greater than 200 Lei can only be cashed-in in the selected location.":
		"Poți retrage din orice agenție în limita a 200 Lei zilnic. Retragerile individuale mai mari de 200 Lei pot fi retrase doar din agenția aleasă pentru ridicare.",
	"How do I Deposit in Location?": "Cum depun în Agenție?",
	"Visit any casino location with the money and your ID card. Your account will receive the funds instantly and you will receive confirmation by SMS.":
		"Vino cu banii și cu buletinul de identitate la ghișeul oricărei agenții. Contul tău va fi alimentat imediat și vei primi confirmarea prin SMS.",
	"How do I initiate a Withdrawal?": "Cum solicit o retragere?",
	"Visit the section Account - Wallet & Bonus - Withdraw or directly from the “Locations” section. Your request will be processed as soon as possible.":
		"Intra în contul tău și poți iniția o retragere din secțiunea “Portofel” sau din secțiunea “Agenții” selectând de unde vei ridica banii. Cererea ta va fi procesată în cel mai scurt timp.",
	"Visit the section Account - Wallet & Bonus - Withdraw. Your request will be processed as soon as possible.":
		"Intra în contul tău și poți iniția o retragere din secțiunea “Portofel”. Cererea ta va fi procesată în cel mai scurt timp.",

	"How do I cash-in my withdrawn money in location?": "Cum ridic banii din agenție?",
	"You will receive a SMS Code once your withdrawn has been approved. Go in the chosen location":
		"După aprobarea retragerii vei primi un cod prin SMS. Mergi cu codul și cu buletinul în agenția aleasă pentru a ridica banii.",
	"I want to cancel a withdrawal and return the funds to my play account.":
		"Cum anulez o cerere de retragere și returnez fondurile în contul meu de jucător?",
	"Visit the section Account - Withdrawals. For every withdrawal there is an option to cancel funds partially or completely. This will not affect the approval time.":
		"Intră în Contul tău, în secțiunea Retrageri în așteptare. Pentru fiecare retragere ai opțiunea de a returna complet sau parțial fonduri în contul tău de jucător. În cazul anulărilor parțiale, această acțiune nu va întârzia procesul de aprobare.",
	"I'm trying to initiate a withdrawal without success.":
		"De ce nu pot înregistra o cerere de retragere?",
	"In most of the cases there will be an error message explaining the reason. The main causes are (1) you have not completed your Identity Verification process or (2) you have active BONUSES that have wager requirements in progress.":
		"În majoritatea cazurilor vei primi un mesaj de eroare care explică motivele. Cauzele principale sunt (1) nu ai completat procesul de Verificare a Identității sau (2) ai BONUS-uri active care nu au îndeplinit încă cerințele de pariere.",
	"What kind of currency can be used to deposit and withdraw?": "Ce valute pot utiliza?",
	"Right now, the following currency can be used": "În acest moment poți folosi doar",
	"Lei (RON)": "Lei (RON)",
	"What taxes apply on withdrawals?": "Ce este impozitul cu reținere la sursă?",
	"Starting from 1st of August 2022, according to the Government Ordinance no. 16/2022, amending and supplementing the Law no. 227/2015 on the Fiscal Code, gambling income obtained by individuals as a result of participating in online gambling, is taxed according to the following thresholds":
		"Începând cu 1 august 2022, conform Ordonanței Guvernului nr. 16/2022, pentru modificarea şi completarea Legii nr. 227/2015 privind Codul fiscal, veniturile din jocuri de noroc, obținute de persoane fizice ca urmare a participării la jocuri de noroc online, se impozitează conform următoarei grile",
	From: "De la",
	inclusive: "inclusiv",
	"applied to the amount over": "pentru ceea ce depășește suma de",
	Over: "Peste",
	"I have cashed-in less money than the amount requested in the withdrawal.":
		"De ce am primit mai puțini bani decât am retras?",
	"We do not tax you in any way; the difference comes from the state taxes accordingly to the law.":
		"Noi nu reținem nici un comision, diferența fiind dată de impozitul la sursă conform legilor în vigoare.",
	"My wallet received less money than the deposit value.":
		"De ce am primit mai puțini bani decât am depus?",
	"Based on the Government’s Emergency Decree 114/2018, every deposit is taxed 2% of its value, transferred to the State Budget.":
		"Urmare a Ordonanței de Urgență a Guvernului 114/2018, fiecare depunere în contul de jucător este taxată cu 2% din valoare, acești bani fiind virați la Bugetul de Stat.",
	Introduction: "Introducere",
	"As an on-line gambling Organizer, PLAY ONLINE SOLUTIONS LTD (“We”) will process the personal data of the participants by registering the game account on the website and is committed to protecting your personal information and processing it fairly and transparently in accordance with the provisions of EU Regulation 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (“GDPR/the Regulation”).":
		"Ca organizator de jocuri de noroc online, PLAY ONLINE SOLUTIONS LTD („Noi”) va prelucra datele personale ale participanților prin înregistrarea contului de joc pe site-ul web și se angajează să vă protejeze informațiile personale și să le prelucreze în mod corect și transparent, în conformitate cu prevederile Regulamentului UE 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și libera circulație a acestor date, prin care se abrogă Directiva 95/46 / CE („GDPR / Regulamentul”).",
	"Above all, we are faithful to the following key data processing principles:":
		"În primul rând, respectăm următoarele principii cheie de prelucrare a datelor:",
	"Lawfulness, fairness and transparency": "Legalitate, echitate și transparență",
	"we process personal data on legal grounds, fairly and in a transparent manner;":
		"prelucrăm datele cu caracter personal în baza unui temei legal, în mod echitabil și transparent;",
	"Purpose limitation": "Limitări legate de scop",
	"we collect personal data for specified, explicit and legitimate purposes;":
		"colectăm date cu caracter personal în scopuri specifice, explicite și legitime;",
	"Data minimization": "Reducerea la minimum a datelor",
	"we only collect and keep personal data that is adequate, relevant and limited to what is necessary in relation to the purposes for which it is processed and according to the legal provisions in force;":
		"colectăm și păstrăm numai datele cu caracter personal care sunt adecvate, relevante și limitate la ceea ce este necesar în raport cu scopurile în care sunt prelucrate;",
	Accuracy: "Exactitate",
	"we ensure that the personal data we keep is accurate, kept up to date or otherwise erased or rectified;":
		"ne asigurăm că  datele  cu  caracter  personal  pe  care  le păstrăm  sunt  exacte,  actualizate  sau șterse, respectiv rectificate;",
	"Storage limitation": "Limitări legate de stocare",
	"we ensure that personal data is stored only for the period of time that is strictly necessary for the fulfilment of our purposes or is otherwise erased or anonymized;":
		"ne asigurăm că stocăm datele cu caracter personal pe o perioadă care nu depășește perioada  strict  necesară  îndeplinirii  scopurilor  în  care  sunt  prelucrate  datele;  altfel,  datele  sunt  șterse  sau anonimizate;",
	"Integrity and confidentiality": "Integritate și confidențialitate",
	"we ensure appropriate security by implementing organizational measures and adequate technical solutions which are harmoniously combined as to guard personal data against unauthorized or unlawful processing and against accidental loss, destruction or damage;":
		"asigurăm securitatea adecvată a datelor cu caracter personal prin luarea unor măsuri tehnice sau organizatorice corespunzătoare, combinate armonios în vederea protejării datelor cu caracter personal  împotriva prelucrării  neautorizate  sau  ilegale și  împotriva  pierderii,  a  distrugerii  sau  a deteriorării accidentale;",
	Accountability: "Responsabilitate",
	"we recognize our responsibility for ensuring the lawful processing of personal data.":
		"recunoaștem că  suntem  responsabili  pentru  asigurarea prelucrării  în  legalitate  a  datelor  cu caracter personal.",
	"Your personal information belongs to you and we respect this. It is your right to be fully informed about the processing operations we perform with the personal data you provide us or we collect about you. In order to make available to you all this information in a way that is as accessible and concise as possible, we have drafted this privacy policy (“Privacy Policy”) applicable to online personal data processing operations.":
		"Informațiile  cu  caracter  personal  sunt  proprietatea  dumneavoastră și respectăm  acest  lucru.  Aveți  dreptul  la  o informare detaliată cu privire la operațiunile de prelucrare la care sunt supuse datele cu caracter personal pe care ni le puneți  la  dispoziție  sau  pe  care  le  colectăm.  Am  redactat  prezenta  politică  de  confidențialitate  („Politica  de confidențialitate”), aplicabilă operațiunilor  de  prelucrare  online  a  datelor  cu  caracter  personal,  pentru  a vă  pune  la dispoziție toate aceste informații, într-o formă cât mai accesibilă și cât mai succintă.",
	"Thus, this Privacy Policy gives you detailed information on the personal data we process, how we collect it, the purposes for which we use personal data, and how we keep it safe. This Privacy Policy also describes what your rights as data subject are, so please review it alongside the Terms and Conditions section.":
		"Prin urmare, prin prezenta Politică de confidențialitate vi se oferă informații detaliate cu privire la datele cu caracter personal  pe  care  le prelucrăm,  modul  în  care  le colectăm,  scopurile  în  care  le utilizăm și  modul  în  care asigurăm securitatea  lor.  În  prezenta Politică  de confidențialitate  sunt  descrise și  drepturile dumneavoastră  în  calitate  de persoană vizată,așadar vă rugăm să o parcurgeți, împreună cu secțiunea „Termeni și condiții”.",
	"To facilitate your understanding of this Privacy Policy, please find below definitions and explanations of the specific notions used:":
		"În cele ce urmează puteți regăsi definițiile și explicațiile termenilor specifici utilizați, în vederea facilitării înțelegerii prezentei Politici de confidențialitate:",
	"Personal data": "Datele mele personale GDPR ",
	"any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.":
		"orice  informații  privind  o  persoană fizică identificată  sau  identificabilă  („persoana vizată”); o persoană fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, în special prin referire la un element de identificare, cum ar fi un nume, un număr de identificare, date de localizare, un identificator online, sau la unul sau mai multe elemente   specifice,   proprii  identității   sale   fizice,   fiziologice,   genetice,   psihice, economice, culturale sau sociale.",
	"Data subject": "Persoana vizată",
	"an identified or identifiable natural person whose personal data is processed.":
		"o persoană fizică identificată  sau identificabilă,  ale cărei  date  cu  caracter  personal  se prelucrează.",
	Processing: "Timp de procesare",
	"means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.":
		"înseamnă  orice operațiune  sau  set  de operațiuni  efectuate  asupra  datelor  cu  caracter personal  sau  asupra  seturilor  de  date  cu  caracter  personal,  cu  sau fără  utilizarea  de mijloace  automatizate,  cum  ar  fi  colectarea,  înregistrarea,  organizarea,  structurarea, stocarea,  adaptarea  sau  modificarea,  extragerea,  consultarea,  utilizarea,  divulgarea  prin transmitere,  diseminarea  sau  punerea  la  dispoziție  în  orice  alt  mod,  alinierea  sau combinarea, restricționarea,ștergerea sau distrugerea;",
	Controller: "Operator",
	"the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data.":
		"persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care, singur sau împreună cu altele, stabilește scopurile și mijloacele de prelucrare a datelor cu caracter personal.",
	Processor: "Persoana împuternicită de operator",
	"a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.":
		"persoana fizică sau juridică, autoritatea publică,agenția sau alt organism care prelucreazădatele cu caracter personal în numele operatorului.",
	Recipient: "Destinatar",
	"a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not.":
		"persoana fizică sau juridică, autoritatea publică, agenția sau alt organism căreia (căruia) îi sunt divulgate datele cu caracter personal, indiferent dacă este sau nu o parte terță.",
	Consent: "Consimțământ",
	"Freely given, specific, informed and unambiguous indication of the data subject’s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.":
		"Orice  manifestare  de  voință liberă, specifică, informată și lipsită  de  ambiguitate  a persoanei  vizate  prin  care  aceasta acceptă,  printr-o declarație  sau  printr-o acțiune fără echivoc, ca datele cu caracter personal care o privesc să fie prelucrate.",
	"Online identifiers": "Identificatori online",
	"internet protocol addresses, cookie identifiers or other identifiers such as radio frequency identification tags provided by data subject devices, applications, tools and protocols. These may leave traces which, in particular when combined with unique identifiers and other information received by the servers, may be used to create profiles of natural persons and identify them.":
		"adresele IP, identificatorii cookie sau alți identificatori precum etichetele de identificare prin frecvențe radio pe care persoanele fizice îi furnizează, prin dispozitivele, aplicațiile, instrumentele și protocoalele lor. Aceștia pot lăsa urme care, în special atunci când sunt combinate  cu  identificatori  unici și  alte informații  primite  de  servere,  pot  fi  utilizate pentru crearea de profiluri ale persoanelor fizice și pentru identificarea lor.",
	"Who we are?": "Cine suntem noi?",
	"Your personal data are being processed by PLAY ONLINE SOLUTIONS LTD, acting as controller of your personal data:":
		"Datele dumneavoastră cu caracter personal sunt prelucrate de PLAY ONLINE SOLUTIONS LTD, care acționează în calitate de operator al datelor dumneavoastră cu caracter personal:",
	"PLAY ONLINE SOLUTIONS LTD": "PLAY ONLINE SOLUTIONS LTD",
	"is a Maltese limited liability company, registered in Malta under number C59636, having its registered office in Elite Business Centre, Trejqa Ta’ Box Box, Msida MSD 1840, Malta":
		"este o companie malteză cu răspundere limitată,înregistrată în Malta cu numărul C59636, având sediul social în Elite Business Centre, Trejqa Ta’ Box Box, Msida MSD 1840, Malta",
	"How can you contact us?": "Cum ne puteți contacta?",
	"In order to ask us questions about this Privacy Policy or to submit us request for the exercise of your rights as data subject, please write to us or call us using the following contact details":
		"Pentru a ne adresa întrebări cu privire la prezenta Politică de confidențialitate sau pentru a face o solicitare cu privire la  exercitarea  drepturilor dumneavoastră  în  calitate  de persoană vizată,  ne puteți  contacta  în  scris, utilizând următoarele date de contact:",
	"E-mail address": " Adresa email",
	"Headquarters address": "Adresa sediului:",
	"4 Șoseaua Pipera| 1st District | Bucharest| Romania":
		"Șoseaua Pipera nr 4| Sector 1| București| Romania",
	"Phone number": "Număr de telefon",
	"Contact person": "Persoana de contact:",
	"Mrs. Irina Crăciun, appointed Data Protection Officer":
		"Irina Crăciun, Responsabil cu protecția datelor desemnat",
	"What personal data do we process, when and for what purposes?":
		"Ce date cu caracter personal prelucrăm, când prelucrăm și în ce scop?",
	"PERSONAL DATA PROCESSED WHEN YOU CARRY OUT YOUR ACTIVITY ON OUR WEBSITE":
		"Date cu caracter personal pe care le prelucrăm când ne accesați website-ul",
	"We collect your personal information when you register your game account by using online forms and when you provide your details and documents by email. We also collect information about the transactions you make, excluding the details of the cards used to make the payments:":
		"Colectăm informațiile  dvs.  personale  atunci  când vă înregistrați  contul  de  joc  folosind  formulare  online și  când vă furnizați  datele și  documentele  prin  e-mail.  De  asemenea, colectăm informații  despre tranzacțiile  pe  care  le faceți, excluzând detaliile cardurilor utilizate pentru efectuarea plăților:",
	"The data included in your ID card,": "Datele incluse în cartea de identitate,",
	"Copy of your ID card,": "Verifică-ți cartea de identitate!",
	"Copy of your Utility Bill,": "Copie după factura de utilități,",
	"When registering on our website, your browser could automatically send us information about:":
		"Când accesați website-ul nostru, este posibil ca browser-ul dumneavoastră să ne trimită automat informații cu privire la:",
	"IP address of your device,": "adresa IP a dispozitivului dumneavoastră,",
	"Date and time of access,": "data și ora accesării,",
	"used browser,": "browser-ul pe care l-ați utilizat,",
	"the operating system of your device,": "sistemul de operare al dispozitivului dumneavoastră,",
	"information in relation to your Internet service provider,":
		"informații cu privire la furnizorul dumneavoastră de servicii de internet,",
	"status and amount of data transferred during the visit of our websites.":
		"status-ul și cantitatea de date transferate în timpul accesării website-ului nostru.",
	"We process the mentioned data for the following purposes:":
		"Prelucrăm datele menționate în următoarele scopuri:",
	"to comply with legal requirements applicable to online gambling,":
		"în vederea respectării cerințelor legale aplicabile jocurilor de noroc online,",
	"to set up and manage your account and data (including processing data on deposits and withdrawals),":
		"în  vederea configurării și gestionării  contului și  datelor  dvs.  (inclusiv  procesarea  datelor  privind depozitele și retragerile),",
	"receive and answer to communications and requests from you or Legal Authorities,":
		"în vederea primirii și formulării răspunsului la comunicările și cererile de la dvs. sau de la autoritățile legale,",
	"to notify you of any updates about the Software and / or Services,":
		"în vederea notificării cu privire la orice actualizări despre software și / sau servicii,",
	"to ensure, including through periodic reviews of the information you provide us, that the activity on the gaming platform is conducted in accordance with mandatory legal provisions,":
		"în  vederea asigurării,  inclusiv  prin  analize  periodice  a informațiilor  pe  care  ni  le furnizați, că activitatea de pe platforma de jocuri se desfășoară în conformitate cu dispozițiile legale obligatorii,",
	"investigate and assist the competent authorities in analyzing any activity that is suspected of being illegal, fraudulent or inappropriate in relation to the Services,":
		"în vederea investigării și oferirea suportului necesar autorităților competente în analizarea oricărei activități suspectate de a fi ilegale, frauduloase sau necorespunzătoare în legătură cu Serviciile;",
	"to conduct promotional campaigns and market studies,":
		"pentru desfășurarea campaniilor promoționale și studiilor de piață,",
	"to draft statistics on your use of the Services by you and other clients,":
		"în vederea elaborării statisticilor privind utilizarea serviciilor dvs. de către dvs. și alți clienți,",
	"to periodically notify you on information on the available Services and Campaigns, change of terms applicable to Casino activity, technical updates and changes to Terms & Conditions,":
		"pentru  a vă anunța  periodic  cu  privire  la informațiile  despre  Serviciile și  Campaniile  disponibile, modificarea  condițiilor  aplicabile  activității  cazinoului,  actualizări  tehnice  și modificări  ale Termenilor și condițiilor,",
	"to ensure the fulfillment of our contractual obligations towards the participants,":
		"în vederea asigurării îndeplinirii obligațiilor noastre contractuale față de participanți,",
	"to ensure a smooth connection to our website and proper use of our website,":
		"pentru a asigura o conexiune optimă la site-ul nostru web și utilizarea corectă a site-ului nostru web,",
	"for evaluating system security and stability,":
		"pentru evaluarea securității și stabilității sistemului,",
	"for further administrative purposes.": "în alte scopuri administrative.",
	"The grounds of processing such data are the performance of the contract for providing you our services and our legitimate interest to ensure that our website functions adequately.":
		"Temeiul  legal  pentru  prelucrarea  acestor  date  este  reprezentat  de  executarea  contractului  prin  care vă  punem  la dispoziție website-ul nostru, precum și de interesul nostru legitim de a asigura funcționarea corespunzătoare a website-ului).",
	"Also, when visiting our website, we install cookies and other tracking technologies on your device and use analysis services. For further details, please refer to section 10 hereto representing our Cookie Policy.":
		"În plus, la accesarea website-ului nostru, instalăm module „cookie” și alte tehnologii de monitorizare pe dispozitivul dumneavoastră și utilizăm servicii de analiză. Pentru informații suplimentare, vă rugăm să consultați secțiunea 10 din prezentul document, care prezintă Politica noastră cu privire la Modulele „Cookie”.",
	"PERSONAL DATA PROCESSED WHEN REGISTERING AND LEAVING COMMENTS ON OUR CHAT":
		" DATE PERSONALE PROCESATE CÂND VĂ ÎNREGISTRAȚI ȘI LASAȚI COMENTARII PE CHAT-ul NOSTRU",
	"When registering or leaving comments on our online chat your browser will provide us with the following information:":
		"Când înregistrați sau lăsați comentarii pe chat-ul nostru online, browser-ul nostru ne va oferi următoarele informații:",
	username: "Nume de utilizator",
	"name,": "numele,",
	"e-mail address,": " adresa de e-mail,",
	date: "data",
	time: "timp",
	location: "Locul de amplasare",
	"comment,": "comentariu,",
	"your site URL.": "adresa URL a site-ului dvs.",
	"IP address": "Adresa IP",
	"We process such data exclusively for operating the chat section of our website and for granting you the possibility to interact with our support team, based on our legitimate interests to obtain feedback and to help you in your online activity, by interacting with our staff.":
		"Procesăm  astfel  de  date  exclusiv  pentru  operarea  secțiunii  de  chat  a  site-ului  nostru  web  și  pentru  a  vă  oferi posibilitatea  de  a interacționa  cu  echipa noastră  de asistență,  pe  baza  intereselor  noastre  legitime  pentru  a obține feedback și pentru a vă ajuta în activitatea dvs. online, interacționând cu personalul nostru.",
	"We draw your attention to the fact the comments are a public section, therefore you should avoid disclosing personal information in your comments.":
		"Vă atragem atenția asupra faptului că comentariile sunt o secțiune publică, prin urmare, ar trebui să evitați divulgarea informațiilor personale în comentariile dvs.",
	"We shall keep the personal data above up to a period of 1 year.":
		"Vom păstra datele personale mai sus până la o perioadă de 1 an.",
	"PROCESSING OF SENSITIVE DATA OR MINORS’ DATA":
		"Prelucrarea datelor sensibile sau ale datelor cu privire la minori ",
	"We shall not collect through our website sensitive information, unless legally required for specific purposes.":
		"Nu vom colecta date sensibile prin intermediul website-ului nostru, cu excepția situației în care legislația impune acest lucru în mod specific.",
	"Our website and its content is not intended for or addressed to minors. Thus, we shall not deliberately collect or maintain personal data about minors.":
		"Website-ul  nostru și conținutul  aferent  nu  se adresează  minorilor.  Prin  urmare,  nu  vom  colecta  sau păstra,  în  mod intenționat, date cu caracter personal despre minori.",
	"On what grounds do we process personal data?":
		"În baza cărui temei prelucrăm date cu caracter personal?",
	"When processing your personal data, we rely on the following legal grounds:":
		"Prelucrăm datele dumneavoastră cu caracter personal în baza următoarelor temeiuri legale:",
	"Consent, as per article 6 (1) a) GDPR":
		"Acordarea consimțământului,  conform  Articolului  6  alin.  (1),  lit.  a)  din  RGPD ",
	"– we may (but usually do not) need your consent to use your personal information. You can exercise your right of consent withdrawal by contacting us as described below.":
		"-  este  posibil  (dar  de obicei  nu  este  cazul)  să  avem  nevoie  de  consimțământul dumneavoastră  pentru  a  utiliza  informațiile dumneavoastră cu caracter personal. Vă puteți exercita dreptul de retragere a consimțământului, contactându-ne în modul descris în cele ce urmează.",
	"Performance of a contract ": "Executarea  unui  contract ",
	"or to take steps at your request prior to entering into a contract with us, as per article 6 (1) b) GDPR – we may need to collect and use your personal information in order to take steps for the conclusion of a contract, to conclude a contract with you (Terms and Conditions), to perform our obligations under a contract with you or otherwise execute the contract. The Contract referred to herein is the Terms and Conditions on our website.":
		"sau  luarea  unor măsuri  solicitate  de dumneavoastră  înainte  de  semnarea unui  contract  cu  noi,  conform  Articolului  6  alin.  (1),  lit.  b)  din  RGPD  -  este  posibil să  fie  necesar să colectăm și să utilizăm informațiile dumneavoastră cu caracter personal în vederea efectuării demersurilor pentru  încheierea  unui  contract,  pentru  încheierea  unui  contract  cu dumneavoastră  (Termeni și Condiții), pentru îndeplinirea obligațiilor noastre contractuale față de dumneavoastră sau pentru derularea în alt mod a contractului. Contractul la care se face referire în prezentul document este Termeni și condiții de pe site-ul nostru web.",
	"Compliance with law or regulation, as per article 6 (1) c) GDPR":
		"Conformitatea cu legislația sau regulamentul, conform Articolului 6 alin. (1), lit. c) din RGPD",
	"– we may use your personal data in order to comply with an applicable law or regulation.":
		"- este posibil să utilizăm  datele dumneavoastră  cu  caracter  personal  în  vederea respectării  normelor  legale  sau reglementărilor aplicabile.",
	"Legitimate interest, as per article 6 (1) f) GDPR":
		"Interesul legitim, conform Articolului 6 alin. (1), lit. f) din RGPD",
	"– we may use your personal information for our legitimate interests, some examples of which are given above.":
		"- este posibil să utilizăm informațiile dumneavoastră  cu  caracter  personal  în  scopul  îndeplinirii  intereselor  noastre  legitime,  printre  care și  cele enumerate anterior.",
	"To whom do we disclose or transfer personal data?":
		"Cui divulgăm sau transferăm date cu caracter personal?",
	"Your personal information will be mainly disclosed to our employees from specific departments and to the companies that are providers of PLAY ONLINE SOLUTIONS LTD, as it will prove to be necessary.":
		"În  principal,  informațiile dumneavoastră  cu  caracter  personal  vor  fi  divulgate  angajaților noștri  din  anumite departamente și societăților care sunt furnizori ai PLAY ONLINE SOLUTIONS LTD, în funcție de necesități.",
	"When justified and/or necessary, we may also share your personal information outside our company. This may include:":
		"În situațiile în care acest lucru este justificat și/sau necesar, este posibil să distribuim informațiile dumneavoastră cu caracter personal și în afara companiei. Printre posibilii destinatari se numără următoarele categorii:",
	"Third party agents/suppliers or contractors bound by obligations of confidentiality. This may include, without limitation, IT and communications service providers.":
		"Agenți terți/furnizori  sau contractanți, aflați  sub incidența obligației păstrării confidențialității .Această categorie poate include, fără a se limita la, furnizorii de servicii IT și de comunicare.",
	"Third parties relevant to the services that we provide. This may include, without limitation, counterparties to online gaming, public authorities and institutions, as empowered by the law to request information on personal data collected during PLAY ONLINE SOLUTIONS LTD’s specific activity.":
		"Terțe părți  relevante  pentru  serviciile  pe  care  le prestăm. Această  categorie  poate  include, fără  limitare, contrapartide la jocurile online, autoritățile și instituțiile publice, astfel cum este împuternicită prin lege să solicite informații  cu  privire  la  datele  cu  caracter  personal  colectate  în  timpul activității  specifice  PLAY ONLINE SOLUTIONS LTD.",
	"To the extent required by law, search warrant or court order, for example, if we are under a duty to disclose your personal information in order to comply with any legal obligation.":
		"În măsura impusă prin lege, sub incidența unui mandat de percheziție sau a unei hotărâri judecătorești, spre exemplu, dacă  suntem obligați să divulgăm informațiile dumneavoastră  cu  caracter  personal  în  vederea respectării oricărei obligații legale.",
	"In case it will be necessary to transfer your personal information outside the European Economic Area, we will ensure that it is protected and transferred in a manner consistent with legal requirements, including the following:":
		"În  cazul  în  care  va  fi  necesar să transferăm informațiile dumneavoastră  cu  caracter  personal  în  afara Spațiului Economic  European,  ne  vom  asigura că  acestea  sunt  protejate și  transferate  într-un  mod  care să  respecte cerințele legale, inclusiv după cum urmează:",
	"the European Commission has issued a decision recognizing the adequate character of data protection in the envisaged third country or where the recipient is located in the US, it may be a certified member of the EU-US Privacy Shield scheme;":
		"Comisia Europeană a emis o decizie prin care recunoaște caracterul adecvat al nivelului de protecție al datelor din statul terț preconizat pentru transfer sau în cazul în care destinatarul este localizat în SUA, acesta poate fi un membru certificat prin planul Scutului de confidențialitate UE-SUA;",
	"the recipient has signed a contract based on “standard contractual clauses” approved by the European Commission, obliging them to protect your personal information, or":
		"Destinatarul a semnat un contract pe bază de „clauze contractuale standard”, aprobat de Comisia Europeană, prin care se obligă să protejeze informațiile dumneavoastră cu caracter personal, sau",
	"we have obtained your prior explicit consent.":
		"Am obținut consimțământul dumneavoastră explicit în prealabil.",
	"In all cases, however, any transfer of your personal information will be compliant with applicable data protection law.":
		"Cu  toate  acestea,  în  toate situațiile,  orice  transfer  al informațiilor dumneavoastră  cu  caracter  personal  va  respecta legislația aplicabilă în materie de protecție a datelor.",
	"You can obtain more details of the protection given to your personal information in case of transfer outside the European Economic Area (including a sample copy of the standard contractual clauses) by contacting us using the details set in section 3 above.":
		"Puteți primi mai multe detalii despre protecția acordată informațiilor dumneavoastră cu caracter personal, în caz de transfer  în  afara  Spațiului  Economic  European  (inclusiv  o  mostră  din  clauzele  contractuale  standard),  dacă  ne contactați utilizând informațiile din Secțiunea 3 de mai sus.",
	"Where and for how long do we store personal data?":
		"Unde și pentru cât timp stocăm datele cu caracter personal?",
	"Your personal data is stored by PLAY ONLINE SOLUTIONS LTD mainly on servers located within the European Economic Area.":
		"Datele dumneavoastră  cu  caracter  personal  sunt  stocate,  preponderent,  pe  servere  localizate  în Spațiul  Economic European.",
	"We process and retain personal data only for as long as is necessary to fulfil our purposes, contractual obligations and other legal obligations of storage / archiving, as the case may be.":
		"Prelucrăm și reținem datele dumneavoastră cu caracter personal numai atât timp cât ne este necesar pentru îndeplinirea scopurilor, a obligațiilor contractuale și a altor obligații legale în materie de stocare/arhivare, după caz.",
	"We shall retain the data only for as long as is necessary and / or prescribed by law for that purpose. For example:":
		"Vom reține datele numai atât timp cât este necesar și/sau stipulat în legislație pentru scopul respectiv. De exemplu:",
	"Data processed for anti-money laundering purposes and supporting documents will be kept for a period of 5 years, as the case may be, according to the Anti-money LaunderingLaw no. 589/18.07.2019;":
		"Datele prelucrate în vederea facturării și documentele contabile justificative se vor păstra pe o perioadă de 5 ani, după caz, conform Legii pentru prevenirea spălării banilor 589/18.07.2019;",
	"Data processed under your consent will be processed during the validity period of your consent or until you choose to withdraw your consent, or the data is no longer necessary;":
		"Datele   prelucrate   cu  consimțământul dumneavoastră   se   vor   prelucra   în   perioada   de   valabilitate   a consimțământului dumneavoastră sau până când alegeți să vă retrageți consimțământul sau până când datele nu mai sunt necesare;",
	"Data processed under our legitimate interest will be processed for a maximum period of 1 year, after which it will be anonymized and processed for statistical purposes.":
		"Datele prelucrate în interesul nostru legitim se vor prelucra pe o perioadă de maximum 1 an, după care vor fi anonimizate și prelucrate în scopuri statistice.",
	"What are your rights as data subject?":
		"Care sunt drepturile dumneavoastră în calitate de persoană vizată?",
	"RIGHT OF ACCESS": "Dreptul de acces",
	"Upon your request, we will confirm that we process your personal data and, if so, we will provide you with a copy of your personal data that is subject to our processing and the following information:":
		"La solicitarea dumneavoastră, vă vom confirma că vă prelucrăm datele cu caracter personal și, în acest caz, vă vom pune  la dispoziție  o  copie  a  datelor dumneavoastră  cu  caracter  personal  care  sunt  supuse prelucrării,  precum și următoarele informații:",
	"the purposes of the processing;": "scopurile prelucrării;",
	"the categories of personal data concerned;":
		"categoriile de date cu caracter personal avute în vedere;",
	"the recipients or categories of recipients to whom personal data has been or is to be disclosed, in particular recipients from third countries or international organizations;":
		"destinatarii sau categoriile de destinatari cărora le-au fost sau le vor fi divulgate date cu caracter personal, în special destinatarii din state sau organizații internaționale terțe;",
	"where possible, the period for which personal data are to be stored or, if that is not possible, the criteria used to determine that period;":
		"dacă  este  posibil,  perioada  de  stocare  a  datelor  cu  caracter  personal,  iar dacă  nu  este  posibil,  criteriile  de stabilire a respectivei perioade;",
	"the existence of the right to require the operator to rectify or erase personal data or to restrict the processing of personal data relating to the data subject or the right to object to processing;":
		"existența  dreptului  de  a  solicita  operatorului să  rectifice  sau să șteargă  datele  cu  caracter  personal  sau să restricționeze prelucrarea datelor cu caracter personal, cu privire la persoana vizată, sau existența dreptului de a se opune prelucrării;",
	"the right to lodge a complaint with a supervisory authority;":
		"dreptul de a depune plângere la autoritatea de supraveghere;",
	"where personal data are not collected from the data subject, any available information on their source;":
		"în cazul în care datele cu caracter personal nu sunt colectate de la persoana vizată, orice informații disponibile despre proveniența acestora;",
	"the existence of an automated decision-making process including the creation of profiles and, in those cases, relevant information on the logic used and the significance and expected consequences of such a processing for the data subject.":
		"existența  unui  proces  decizional  automatizat  care  include  crearea  de  profiluri  și,  cel  puțin  în  cazurile respective, a unor informații pertinente privind logica utilizată, dar și importanța și consecințele preconizate ale unei astfel de prelucrări pentru persoana vizată.",
	"If we transfer your data outside of the European Economic Area or to an international organization you have the right to be informed of the appropriate safeguards applied.":
		"Dacă transferăm datele dumneavoastră în afara Spațiului Economic European sau către o organizație internațională, aveți dreptul să fiți informat(ă) cu privire la garanțiile adecvate aplicate.",
	"The first copy of your personal data is provided free of charge. For additional specimens, we may charge a reasonable additional charge, taking into account the related administrative costs.":
		"Prima copie a datelor dumneavoastră cu caracter personal vă este pusă la dispoziție gratuit. Pentru copii suplimentare, este posibil să percepem o taxă suplimentară rezonabilă, în funcție de costurile administrative aferente.",
	"RIGHT TO RECTIFICATION AND/OR COMPLETION OF PERSONAL DATA":
		"Dreptul la rectificarea și/sau completarea datelor cu caracter personal",
	"If the data we hold about you is inaccurate or incomplete, you are entitled to correct it or to complete. In order to do so, you can submit a request using the contact details provided above. Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your rectification or completion of your data. Upon your request, we will inform you of those recipients.":
		"În cazul în care datele pe care le avem despre dumneavoastră sunt inexacte sau incomplete, aveți dreptul să solicitați corectarea  sau  completarea  acestora.  În  acest  sens, puteți  face  o  solicitare,  utilizând  datele  de  contact menționate anterior.  Cu excepția situației  în  care  acest  lucru  se dovedește  imposibil  sau  impune  eforturi  mult  prea  mari,  vom înștiința fiecare destinatar căruia i-au fost divulgate datele dumneavoastră cu privire la rectificarea sau completarea acestora. La cerere, vă vom informa care sunt respectivii destinatari.",
	"In order to keep personal data accurate, we may request you to reconfirm/renew your personal data from time to time.":
		"În  vederea actualizării și  a păstrării exactității  datelor,  este  posibil să vă solicităm  periodic să reconfirmați/ să reactualizați datele dumneavoastră cu caracter personal.",
	"RIGHT TO ERASURE OF PERSONAL DATA (“RIGHT TO BE FORGOTTEN”)":
		"Dreptul la ștergerea datelor („dreptul de a fi uitat”)",
	"You may ask us to delete your personal information and we will respond to your request without undue delay, if one of the following situation applies:":
		"Este posibil să ne solicitați să ștergem informațiile dumneavoastră cu caracter personal și vom răspunde neîntârziat unei astfel de solicitări, în oricare dintre următoarele situații:",
	"Data are no longer required for the purposes for which they were collected or processed;":
		"Datele nu mai sunt necesare în scopul pentru care au fost colectate sau prelucrate;",
	"You withdraw consent to the processing of your data when your data processing is based on your consent and there is no other legal basis on which to process your personal data;":
		"Vă retrageți consimțământul pentru prelucrare a datelor, în situația în care prelucrarea datelor dumneavoastră se întemeiază pe consimțământ și nu există niciun alt temei legal pentru prelucrarea datelor dumneavoastră cu caracter personal;",
	"You oppose the processing of your data on our legitimate interest, including the creation of profiles based on this ground, or you oppose data processing for direct marketing purposes, including the creation of profiles for direct marketing purposes;":
		"Vă opuneți prelucrării datelor dumneavoastră în interesul nostru legitim, inclusiv creării de profiluri în baza acestui temei, sau vă opuneți prelucrării datelor în scopuri de marketing direct, inclusiv creării de profiluri care să aibă legătură cu scopurile de marketing direct;",
	"your data has been processed unlawfully;": "Datele dumneavoastră au fost prelucrate ilegal;",
	"Personal data should be deleted to comply with a legal obligation under Union law or national law;":
		"Datele  cu  caracter  personal  ar  trebui șterse,  în  vederea respectării obligațiilor  legale  impuse  prin  dreptul Uniunii sau prin dreptul intern;",
	"Personal data have been collected in connection with the provision of information services to children and the basis of processing is consent.":
		"Datele cu caracter personal au fost colectate în legătură cu furnizarea de servicii informative către copii, iar temeiul prelucrării îl constituie consimțământul.",
	"Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your deletion of your data. Upon your request, we will inform you of those recipients.":
		"Cu excepția situației  în  care  acest  lucru  se dovedește  imposibil  sau  impune  eforturi  mult  prea  mari,  vom înștiința fiecare destinatar căruia i-au fost divulgate datele dumneavoastră cu privire la ștergerea acestora. La cerere, vă vom informa care sunt respectivii destinatari.",
	"We reserve the right to refuse deletion your data when processing is required for:":
		"Ne rezervăm dreptul de a refuza ștergerea datelor, în situațiile în care prelucrarea este necesară pentru:",
	"For the exercise of the right to free expression and information;":
		"exercitarea dreptului la liberă exprimare și informare;",
	"In order to comply with a legal obligation that applies to us as a personal data operator;":
		"respectarea unei obligații legale aplicabile nouă, în calitate de operator de date cu caracter personal;",
	"for purposes of archiving in the public interest, scientific or historical research or for statistical purposes, insofar as the deletion of the data is likely to render impossible or seriously impair the achievement of the objectives of the processing;":
		"arhivarea în interes public, științific sau în scopuri statistice sau care țin de cercetare istorică, atât timp cât există probabilitatea ca ștergerea datelor să facă imposibilă sau afecteze în mod grav realizarea obiectivelor prelucrării;",
	"To establish, exercise or defend a right in court.":
		"constatarea, exercitarea sau apărarea unui drept în instanță.",
	"RIGHT TO RESTRICTION OF PROCESSING": " Dreptul la restricționarea prelucrării",
	"You may ask us to block and restrict the processing of your personal information if one of the situations below applies:":
		"Ne puteți solicita să blocăm sau să restricționăm prelucrarea informațiilor dumneavoastră cu caracter personal, în cazul în care se aplică una dintre următoarele situații:",
	"Contest the accuracy of the data – in this case, at your request, we will restrict the processing for the period we perform the necessary checks on the accuracy of your data;":
		"Contestați  exactitatea  datelor  -  în  acest  caz,  la  solicitarea dumneavoastră,  vom restricționa  prelucrarea  pe perioada în care efectuăm demersurile necesare verificării exactității datelor dumneavoastră;",
	"data processing is illegal, and you do not want to delete your data;":
		"Prelucrarea datelor este ilegală și nu doriți să ștergeți datele dumneavoastră;",
	"We no longer need your data for processing, but processed data about you is necessary to establish, exercise or defend a right in court;":
		"Nu mai avem nevoie de datele dumneavoastră pentru prelucrare, însă datele despre dumneavoastră prelucrate sunt necesare în vederea constatării, exercitării sau apărării unui drept în instanță;",
	"You opposed your processing of your data under our legitimate interest, including the creation of profiles based on this basis – in this case, at your request, we will restrict the processing for the period in which we verify that our legitimate rights do not prevail over your rights.":
		"-ați  opus prelucrării  datelor dumneavoastră  sub incidența  interesului  nostru  legitim,  inclusiv creării  de profiluri pe această bază - în acest caz, la solicitarea dumneavoastră, vom restricționa prelucrarea pe perioada cât verificăm dacă drepturile noastre legitime nu prevalează asupra drepturilor dumneavoastră.",
	"If your data processing has been restricted, we will only be able to store your data. Any other way of processing out of storage will be done only:":
		"Dacă s-a restricționat prelucrarea datelor dumneavoastră, noi vom putea numai să stocăm datele dumneavoastră. Orice alt tip de prelucrare, în afară de stocare, se va realiza numai:",
	"after obtaining your consent;": "ulterior obținerii consimțământului dumneavoastră;",
	"for finding, exercising or defending a right in court;":
		"în vederea constatării, exercitării sau apărării unui drept în instanță.",
	"to protect the rights of another natural or legal person;":
		"în vederea apărării drepturilor unei alte persoane fizice sau juridice;",
	"for reasons of public interest of the Union or of a Member State.":
		"din motive de interes public al Uniunii sau al unui Stat Membru.",
	"We will inform you before lifting any processing restriction as set out above.":
		"Vă vom informa înainte de ridicarea oricăror restricții de prelucrare stabilite în modurile enunțate anterior.",
	"Unless this proves impossible or involves disproportionate efforts, we will communicate to each recipient to whom your data has been disclosed restricting the processing of such data. At your request, we will inform you of those recipients.":
		"Cu excepția situației  în  care  acest  lucru  se dovedește  imposibil  sau  impune  eforturi  mult  prea  mari,  vom înștiința fiecare destinatar căruia i-au fost divulgate datele dumneavoastră cu privire la restricționarea prelucrării acestora. La cerere, vă vom informa care sunt respectivii destinatari.",
	"RIGHT TO DATA PORTABILITY": "Dreptul la portabilitatea datelor",
	"You have the right to receive the data that concerns you and that you have provided us with in order to transmit such data to another controller, in the following circumstances:":
		"Aveți dreptul de a primi datele cu caracter personal care vă privesc și pe care ni le-ați furnizat, în vederea transmiterii acestora către un alt operator, în următoarele împrejurări:",
	"Your data processing is based on your consent or on a contract between us and you;":
		"Prelucrarea  datelor dumneavoastră  are  la bază consimțământul dumneavoastră  sau  un  contract  încheiat  cu noi;",
	"Your data is processed by automatic means.":
		"Prelucrarea datelor dumneavoastră se efectuează prin mijloace automatizate.",
	"We will provide your personal data in a structured, commonly used and machine-readable format.":
		"Vă vom pune la dispoziție datele dumneavoastră cu caracter personal într-un format structurat, utilizat în mod curent și care poate fi citit automat.",
	"If technically feasible, you can request that your personal data be transmitted directly to the controller indicated by you.":
		"În cazul în care acest lucru este fezabil din punct de vedere tehnic, puteți solicita ca datele cu caracter personal să fie transmise direct operatorului indicat de dumneavoastră.",
	"RIGHT TO OBJECT AND AUTOMATED INDIVIDUAL DECISION-MAKING":
		"Dreptul la opoziție și procesul decizional individual automatizat",
	"You may request us not to further process your personal information for reasons relating to your particular circumstances and if the processing of your data is based on our legitimate interest. We will cease processing of your data unless we demonstrate that we have legitimate and compelling reasons that justify processing and those reasons prevail over your interests, rights and freedoms, or whether the purpose of the processing is to establish, exercise or defend a right in court.":
		"Aveți dreptul de a ne solicita să nu mai prelucrăm informațiile dumneavoastră cu caracter personal din motive legate de situația particulară în care vă aflați și dacă prelucrarea datelor dumneavoastră are la bază interesul nostru legitim. Nu  vom  mai  prelucra  datele dumneavoastră  cu  caracter  personal,  cu excepția  cazului  în  care demonstrăm că  avem motive legitime și imperioase, care justifică prelucrarea și care prevalează asupra intereselor, drepturilor și libertăților dumneavoastră, sau că scopul prelucrării este constatarea, exercitarea sau apărarea unui drept în instanță.",
	"In addition to the above, you may request that we no longer process your personal data for direct marketing purposes, including the creation of profiles related to that direct marketing.":
		"În plus față de cele menționate anterior, puteți să solicitați să nu vă mai prelucrăm datele cu caracter personal în scopuri de marketing direct, inclusiv crearea de profiluri care țin de marketing direct.",
	"RIGHTS IN RELATION TO AUTOMATED INDIVIDUAL DECISION-MAKING, INCLUDING PROFILING":
		" Drepturi care țin de procesul decizional individual automatizat, inclusiv crearea de profiluri",
	"You have the right not to be subject to a decision when it is based on automatic processing, including not being profiled, if the automatic decision or profiling has legal effects or significantly affects you, except in the following cases:":
		"Aveți dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată, inclusiv crearea de profiluri, care produce efecte juridice care vă afectează semnificativ, cu următoarele excepții:",
	"automatic decision is required to conclude or execute a contract between you and us;":
		"decizia bazată pe prelucrare automată este necesară pentru încheierea sau executarea unui contract cu noi;",
	"the automatic decision is authorized by European Union or national law applicable to PLAY ONLINE SOLUTIONS LTD and also provides for appropriate measures to protect the legitimate rights, freedoms and interests of the data subject;":
		"decizia bazată pe prelucrare automată este autorizată prin dreptul Uniunii Europene sau prin dreptul intern care  se aplică  PLAY  ONLINE  SOLUTIONS  LTD și  care  prevede,  de  asemenea, măsuri corespunzătoare pentru protejarea drepturilor, libertăților și intereselor legitime ale persoanei vizate;",
	"Automatic decision is based on your express consent.":
		"decizia bazată pe prelucrare automată are la bază consimțământul dumneavoastră explicit.",
	"If the cases indicated in (1) and (3) above apply, you may request that you exercise the following correlative rights:":
		"Dacă  se aplică situațiile  descrise  anterior  la  punctele  (1) și  (3), puteți  solicita  exercitarea următoarelor  drepturi corespunzătoare:",
	"the right to obtain human intervention on our part;":
		"dreptul de a obține intervenție umană din partea noastră;",
	"the right to express your point of view;": "dreptul de a vă exprima punctul de vedere;",
	"the right to challenge the automatic decision.":
		"dreptul de a contesta decizia bazată pe prelucrare automată.",
	"RIGHT TO WITHDRAW CONSENT": "Dreptul de a retrage consimțământul",
	"If we rely on your consent (or explicit consent) as the legal basis for processing your personal information, you have the right to withdraw your consent at any time and we will cease processing your personal data.":
		"Dacă  temeiul  legal  pe  care  ne  bazăm  pentru  a  prelucra  informațiile dumneavoastră  cu  caracter  personal  este consimțământul (sau consimțământul explicit), aveți dreptul de a vă retrage în orice moment consimțământul, iar noi nu vom mai prelucra datele dumneavoastră cu caracter personal.",
	"Withdrawal of consent does not affect the lawfulness of the processing of your personal data on the basis of your consent prior to its withdrawal.":
		"Retragerea consimțământului nu afectează legalitatea prelucrării datelor dumneavoastră cu caracter personal în baza consimțământului, efectuate anterior retragerii acestuia.",
	"RIGHT TO LODGE A COMPLAINT WITH NATIONAL SUPERVISORY AUTHORITY FOR PERSONAL DATA PROCESSING OF ROMANIA (“ANSPDCP”)":
		" Dreptul de a depune plângere la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP)",
	"You have the right to contact the ANSPDCP if you believe the processing of your data is not in compliance with the applicable law. More information about ANSPDCP can be obtained by visiting http://www.dataprotection.ro/.":
		"Aveți dreptul de a contacta ANSPDCP în situația în care considerați că prelucrarea datelor dumneavoastră nu respectă legislația aplicabilă. Pentru informații suplimentare despre ANSPDCP accesați:http://www.dataprotection.ro/.",
	"RIGHT TO SEEK JUDICIAL REMEDY": "Dreptul la o cale de atac judiciară",
	"Without prejudice to any other administrative or non-judicial remedy, you have the right to pursue an effective judicial remedy against a legally binding decision of ANSPDCP.":
		"Fără a aduce atingere vreunei căi de atac administrative sau nejudiciare disponibile, aveți dreptul de a exercita o cale de atac judiciară eficientă, în baza unei decizii opozabile a ANSPDCP.",
	"How can you exercise your rights?": "Cum vă puteți exercita drepturile?",
	"Submitting a request.": "Înaintarea unei solicitări.",
	"For the exercise of any rights above, please submit your request in writing or by phone, using the contact details indicated in section 3 above.":
		"În vederea exercitării oricăruia dintre drepturile menționate anterior, vă rugăm să înaintați solicitarea dumneavoastră în scris, utilizând datele de contact oferite în Secțiunea 3 de mai sus.",
	"Identification of the applicant.": "Anularea contului",
	"In order to be able to properly manage your request, we urge you to identify yourself as completely as possible. In case we have reasonable doubts as to the identity of the applicant, we will ask for further information to confirm your identity.":
		"Identificarea  solicitantului. În  vederea gestionării corespunzătoare  a solicitării dumneavoastră, vă rugăm  să vă identificați cât mai complet posibil. În situația în care avem rezerve rezonabile cu privire la identitatea solicitantului, vom cere informații suplimentare pentru a confirma identitatea dumneavoastră.",
	"Response time.": "Joc Responsabil.",
	"We will respond to your requests without undue delay, and in any case within one month from the receipt of the request. Insofar as your application is complex or we are in a position to process a large number of requests, we may reasonably postpone the delivery of your response for up to two months with your prior notice.":
		"Timpul de răspuns. Vom răspunde solicitărilor dumneavoastră neîntârziat, în orice caz, în termen de o lună de la data primirii solicitării. În eventualitatea în care solicitarea dumneavoastră este una complexă, iar noi ne găsim în situația de  a  prelucra  un număr  mare  de solicitări,  este  posibil să amânăm  rezonabil  acordarea  unui răspuns  cu  privire  la solicitarea dumneavoastră, cu până la maximum două luni și cu notificare prealabilă.",
	"Providing our answer.": "Oferirea unui răspuns.",
	"We will provide you with our response and any requested information in electronic format, unless you request them to be provided in another format.":
		"Vă vom oferi răspunsul nostru, precum și orice informații solicitate, în format electronic, cu excepția cazului în care le solicitați într-un alt format.",
	"Refusal.": "Refuzul.",
	"In so far as we refuse to meet your request, we will inform you of the reasons which led to such a decision and of the possibility to submit a complaint to ANSPDCP and to apply for a judicial remedy.":
		"În cazul în care refuzăm să dăm curs solicitării dumneavoastră, vă vom prezenta motivele care au generat această decizie, precum și posibilitatea de a depune plângere la ANSPDCP și de a iniția exercitarea unei căi de atac judiciare.",
	"Taxes. Exercising your rights as a data subject is free. However, to the extent that your claims are manifestly unfounded or excessive, especially in the light of their repetitiveness, we reserve the right to levy a fee or to refuse the fulfilment of the request.":
		"Taxele. Exercitarea drepturilor dumneavoastră în calitate de persoană vizată este gratuită. Cu toate acestea, în măsura în  care solicitările dumneavoastră  sunt vădit  nefondate și  excesive,  mai  ales  prin  prisma  caracterului  repetitiv,  ne rezervăm dreptul de a percepe o taxă sau de a refuza să dăm curs solicitării.",
	"Cookies Policy": "Politica privind Modulele „Cookie”",
	"WHAT ARE COOKIES?": " Ce sunt modulele „cookie”?",
	"Cookies are small files of letters and numbers that are stored on your computer, mobile terminal, or other equipment that you use to access the internet. There are two main types of cookies:":
		"Modulele  „cookie”  sunt fișiere  de  mici  dimensiuni,  care conțin  litere și  numere  stocate  pe  calculatorul,  terminalul mobil sau un alt echipament pe care îl utilizați pentru a vă conecta la internet. Există două tipuri de module „cookie”:",
	"Session cookies – temporary cookies which allow website operators to link the actions of a visitor during a browser session. They are activated when the browser window is opened. Once you close the browser, all session cookies are deleted.":
		"Module  „cookie”  temporare  –  modul  „cookie”  temporar  permite  operatorului  website-ului să  realizeze conexiuni  între acțiunile  unui  utilizator,  pe  durata  unei  sesiuni  de  navigare  prin  browser.  Se activează  la deschiderea ferestrei browser-ului. La închiderea ferestrei browser-ului, toate modulele „cookie” temporare se șterg.",
	"Persistent cookies – remain on a user’s device for a set period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.":
		"Module „cookie” permanente - rămân pe dispozitivul utilizatorului pe o durată determinată, specificată în modulul „cookie”. Se activează de fiecare dată când utilizatorul accesează website-ul care a creat respectivul modul „cookie”.",
	"Cookies are installed through the request of our web-server to your browser (eg Internet Explorer, Chrome) and do not contain software, viruses or spyware, and cannot access information from your hard drive.":
		"Modulele „cookie” se instalează prin solicitarea trimisă de serverul nostru web către browser-ul dumneavoastră (cum ar  fi  Internet  Explorer,  Chrome) și  nu conțin  software, viruși  sau  programe  spyware și  nu  pot  accesa informațiile stocate pe hard-disk-ul dumneavoastră.",
	"WHAT TYPES OF COOKIES DO WE USE AND FOR WHAT PURPOSES?":
		"Ce tipuri de module „cookie” utilizăm și în ce scopuri?",
	"Strictly necessary cookies – are essential to navigate around our website and to use its features. These cookies do not gather personal information about you.":
		"Module „cookie” strict necesare - sunt esențiale pentru navigarea pe website-ul nostru și pentru utilizarea caracteristicilor  acestuia.  Aceste  module  „cookie”  nu colectează informații  cu  caracter  personal  despre dumneavoastră.",
	"Performance cookies – collect data for statistical purposes on how visitors use our website. They do not contain personal information such as names and email addresses and are used to improve your experience on our website. Information supplied by performance cookies can help us understand how you use the website; for example, whether or not you have visited before, what you looked at or clicked on and how you found us. Such data will be used to further improve our services.":
		"Module  „cookie”  de performanță  - colectează  date  cu  privire  la  modul  în  care  utilizatorii accesează website-ul nostru, în scopuri statistice. Nu conțin informații cu caracter personal cum ar fi nume și adrese de e-mail și se utilizează pentru a îmbunătăți experiența dumneavoastră de utilizator al website-ului. Informațiile oferite de modulele „cookie” de performanță ne pot ajuta să înțelegem cum utilizați website-ul; de exemplu, dacă l-ați mai accesat în trecut, la ce v-ați uitat, pe ce ați dat click și cum ne-ați găsit.  Ne vom folosi de astfel de date pentru a continua îmbunătățirea serviciilor pe care le oferim.",
	"Analytics cookies – cookies generated by the Google Analytics software to account the activity of visitors, and the frequency of accessing the Site. We have taken care that the data transmitted by these cookies does not lead to your identification.":
		"Module  „cookie”  de  analiză  -  module  „cookie”  generate  de  software-ul  Google  Analytics  pentru  a monitoriza  activitatea  utilizatorilor,  precum și frecvența accesării  website-ului.  Ne-am  asigurat că  datele transmise de aceste module „cookie” nu atrag dezvăluirea identității dumneavoastră.",
	Name: "Nume",
	Purpose: "Scop",
	Expiry: "expirat",
	Analytics: "Denumire",
	months: "luni",
	month: "lună",
	years: "ani",
	"Functionality cookies – remember usernames, language preferences and regions, thus allowing user to customize how our website looks for them.":
		"Module „cookie” de funcționalitate- memorează numele de utilizator, preferințele lingvistice sau regiunile din care se face accesarea, permițând astfel utilizatorului să personalizeze interfața website-ului.",
	language: "Limbă",
	year: "an",
	"Advertising and targeting cookies – are used to deliver more relevant advertisements to you, but can also limit the number of times you see an advertisement, and be used to chart the effectiveness of an ad campaign by tracking users’ clicks.":
		"Module „cookie” în scopuri publicitare și de analiză a comportamentului utilizatorului - se utilizează în  vederea prezentării  unor  reclame  mai  relevante  pentru dumneavoastră, însă  pot  servi și  la  limitarea numărului  de rulări  ale  unui  spot  publicitar și  la  cuantificarea eficienței  unei  campanii  publicitare,  prin monitorizarea click-urilor utilizatorilor.",
	"HOW CAN YOU REFUSE OR DEACTIVATE COOKIES?": "Cum puteți refuza sau dezactiva module „cookie”?",
	"With the opening pop-up message, you can select which cookies you want to use, except for the strictly necessary cookies. Deactivating strictly necessary cookies will disable essential website services rendering it unusable.":
		"În  mesajul  tip  „pop-up”  care  apare  pe  ecran, aveți  posibilitatea  de  a  selecta  modulele  „cookie”  pe  care doriți să  le utilizați, cu excepția modulelor „cookie” strict necesare. Dezactivarea modulelor „cookie” strict necesare va dezafecta și servicii esențiale ale website-ului și va face utilizarea acestuia imposibilă.",
	"You can also disable cookies by changing your browser settings. For more information about this, please visit your browser’s settings page.":
		"Modulele  „cookie”  pot  fi  dezactivate și  prin  modificarea setărilor  browser-ului.  Pentru informații  suplimentare  pe acest subiect, vă rugăm să accesați pagina de setări a browser-ului.",
	"SOCIAL MEDIA TECHNOLOGIES": "Tehnologiile aferente platformelor de comunicare socială",
	"Facebook Like and Share plugins – allow visitors to Like the PLAY ONLINE SOLUTIONS LTD Facebook page or share PLAY ONLINE SOLUTIONS LTD websites with friends. If a person is connected to Facebook and visits the Site, the browser sends this information to Facebook in order for Facebook upload content on that page. Among the data that can be sent to Facebook are: the user ID, the site you visit, the date and time, and other browser-related information. If you are disconnected from your Facebook account less information shall be transferred to Facebook.":
		"Plug-in-urile Facebook Apreciere și Distribuire („Like and Share”) - permit utilizatorilor să aprecieze pagina de Facebook  a  PLAY  ONLINE  SOLUTIONS  LTD  sau  să  distribuie  prietenilor  website-urile  PLAY  ONLINE SOLUTIONS  LTD.  În  cazul  în  care  o persoană conectată  la  Facebook accesează  website-ul,  browser-ul  trimite informații către  Facebook,  pentru  ca  Facebook să  încarce conținut  pe  pagina respectivă.  Printre  datele  care  pot  fi trimise către  Facebook  se numără:  numele  de  utilizator,  website-ul  pe  care  îl accesați,  data și  ora accesării și  alte informații care țin de browser. În cazul în care nu sunteți conectat(ă) la contul de Facebook, informațiile transferate către Facebook vor fi mai puține.",
	"This personal information can be used wholly or in part by Facebook to improve Facebook products and services and to provide personalized ads. For more information you can consult Facebook privacy policy.":
		"Facebook poate utiliza integral sau parțial aceste informații cu caracter personal, în vederea îmbunătățirii produselor și  serviciilor  Facebook și  a prezentării  de  sporturi  publicitare  personalizate.  Pentru informații  suplimentare, puteți consulta Politica de confidențialitate a Facebook.",
	"Google+ plugin – when you visit a site that has a Google Plug-in, your browser sends a series of information to Google, such as the URL of the page you’re visiting and your IP address. Google can access and read the cookies stored in the visitor’s device. Apps that use Google’s advertising services also share information with Google, such as the app’s name and a unique identifier for advertising.":
		"Plug-in Google+ - în cazul în care accesați un website care are un plug-in Google, browser-ul dumneavoastră trimite o serie de informații către Google, cum ar fi URL-ul paginii pe care ați accesat-o și adresa dumneavoastră IP. Google poate  accesa și  citi  modulele  „cookie”  stocate  pe  dispozitivul  utilizatorului. Aplicațiile  care utilizează  serviciile  de publicitate ale Google partajează și ele informații cu Google, respectiv numele aplicației și identificatorul unic pentru publicitate.",
	"“Share with LinkedIn” plugin – when a person visits a site that has integrated such a plugin, LinkedIn receives the following visitor information: the URL of the aside from which it came and the one to which it is going. We also receive information about IP address, proxy server, operating system, web browser and add-ons, device ID and / or ISP and / or mobile phone identifier and features. If the site is accessed from a mobile device, the device will send us your location data according to the visitor’s phone settings.":
		"Plug-in „Distribuie pe LinkedIn” -  când cineva accesează un website care are integrat un astfel de plug-in, LinkedIn primește următoarele informații despre utilizator: URL-ul website-ului accesat anterior și cel al website-ului accesat imediat după de utilizator. Noi primim informații și despre adresa IP, serverul proxy, sistemul de operare, browser-ul web și extensiile, datele de identificare ale dispozitivului și/sau furnizorului de servicii de internet și/sau identificatorul și caracteristicile telefonului mobil. În cazul în care website-ul este accesat de pe un dispozitiv mobil, acesta ne va trimite datele de localizare conform setărilor telefonului utilizatorului.",
	"Tweet plugin – when a person visits a site that has integrated such a plugin, Tweeter can receive the following personal information about the visitor: Visited Web page, IP address, browser type, operating system, and cookie information. This information is collected to improve Tweeter products and services, including personalized customized ads and personalized ads.":
		"Plug-in  Twitter  -  când  cineva accesează  un  website  care  are  integrat  un  astfel  de  plug-in,  Twitter  poate  primi următoarele informații  cu  caracter  personal  despre  utilizator:  Pagina  web accesată,  adresa  IP,  tipul  de  browser, sistemul de operare, informațiile aferente modulelor „cookie”. Aceste informații se colectează în vederea îmbunătățirii produselor și serviciilor Twitter, inclusiv a spoturilor publicitare și a reclamelor personalizate.",
	"Ensuring personal data security": "Asigurarea securității datelor cu caracter personal",
	"For ensuring safety of this website we use the SSL (Secure Socket Layer) method in connection with the website visit, in conjunction with the highest encryption level supported by your browser. In general, this is a 256-bit encryption.  Whether a single page of our website is encrypted is indicated by the closed representation of the key or lock icon in the status bar of your browser.":
		"În vederea asigurării securității acestui website, utilizăm protocolul SSL („Secure Socket Layer”) în ceea ce privește accesarea  website-ului, împreună  cu  nivelul  maxim  de  criptare  pe  care  îl acceptă  browser-ul dumneavoastră. În general, este vorba despre criptare cu cheie de 256 biți. Faptul că o singură pagină din website-ul nostru este criptată este indicat de pictograma unui lacăt încuiat, prezentă în bara de status a browser-ului dumneavoastră.",
	"At organizational level, we have adopted and instructed our employees to follow internal procedures aimed at preventing loss or unauthorized access or disclosure. All persons, including our employees, processing your personal data under our authority have pledged to preserve the confidentiality and security of all such personal data.":
		"La nivel de organizație, am adoptat și ne-am instruit angajații să urmeze proceduri interne de prevenire a pierderii, a divulgării  datelor  cu  caracter  personal  sau  a  accesului  neautorizat  la  acestea.  Toate  persoanele,  inclusiv angajații, implicate în prelucrarea datelor dumneavoastră cu caracter personal sub îndrumarea noastră s-au angajat să păstreze confidențialitate și securitatea tuturor acestor date cu caracter personal.",
	"We have also implemented adequate security measures to protect your data against informatics treats and other potential data breaches. Such security measures shall be continuously improved in line with technological developments.":
		"În plus, am pus în aplicare măsuri de securitate corespunzătoare, în vederea protejării datelor dumneavoastră împotriva atacurilor cibernetice și a altor potențiale încălcări ale securității datelor. Vom îmbunătăți continuu astfel de măsuri de securitate, în pas cu dezvoltarea tehnologiei.",
	"Final provisions": "Dispoziții finale",
	"This Privacy Policy is applicable starting with 25th of May 2018.":
		"Prezenta Politică de confidențialitate intră în vigoare începând cu data de 25 mai 2018.",
	"PLAY ONLINE SOLUTIONS LTD reserves the right to modify or amend this Privacy Policy at any time by publishing an updated version here.":
		"PLAY ONLINE SOLUTIONS LTD își rezervă dreptul de a aduce modificări și amendamente prezentei Politici de confidențialitate, în orice moment, prin publicarea unei versiuni actualizate aici.",
	"coming soon": "în curând",
	method: "metoda",
	description: "Verificare",
	commission: "Comision",
	"process time": "timp de procesare",
	limits: "limite",
	"Mergi in orice agentie si depune usor si rapid la ghiseu doar actul de identitate.":
		"Mergi în orice agenție și depune ușor și rapid la ghișeu doar cu actul de identitate.",
	Free: "Gratis",
	Instant: "Instant",
	Min: "Min",
	Max: "Max",
	"Visa / MasterCard Credit Card": "Visa / MasterCard Credit Card",
	"Online payment through credit and debit cards.": "Plati online prin cardul de credit/debit.",
	"Mergi in orice agentie si retrage usor si rapid la ghiseu doar actul de identitate.":
		"Mergi in orice agentie si retrage usor si rapid la ghiseu doar cu actul de identitate.",
	"0-4 days after approval": "0-4 zile dupa aprobare.",
	"taxe si impozite": "taxe și impozite",
	"Depuneri si retrageri": "Depuneri si retrageri",
	"Conform ${sstrong} OUG 114/2018 art. 53 ${estrong} si a Ordinului 38 al ONJN (Oficiul National al Jocurilor de Noroc din Romania), ${sstrong}fiecare depunere este taxata 2% din valoare.${estrong}":
		"Conform ${sstrong} OUG 114/2018 art. 53 ${estrong} si a Ordinului 38 al ONJN (Oficiul National al Jocurilor de Noroc din Romania), ${sstrong}fiecare depunere este taxata 2% din valoare.${estrong}",
	exemplu: "Exemplu",
	"O depunere de 100 Lei va pune in contul de joc suma de 98 Lei, restul de 2 Lei mergand catre bugetul de stat.":
		"O depunere de 100 Lei va pune în contul de joc suma de 98 Lei, restul de 2 Lei mergând către bugetul de stat.",
	"Retragerile sunt impozitate conform pragurilor de mai jos:":
		"Retragerile sunt impozitate conform pragurilor de mai jos:",
	valoare: "valoare",
	impozit: "impozit",
	lei: "lei",
	"pana la 66.750, inclusiv": "până la 66.750, inclusiv",
	"1%": "1%",
	"peste 66.750 - 445.000, inclusiv": "peste 66.750 - 445.000, inclusiv",
	"667,5 + 16% pentru ceea ce depaseste suma de 66.750 lei":
		"667,5 + 16% pentru ceea ce depășește suma de 66.750 lei",
	"peste 445.000": "peste 445.000",
	"61.187 + 25% pentru ceea ce depaseste suma de 445.000 lei":
		"61.187 + 25% pentru ceea ce depășește suma de 445.000 lei",
	"Rotunjirea sumelor datorate ca taxe sau impozite se face prin ${sstrong}rotunjire la un leu pentru fractiunile de peste 50 de bani inclusiv si prin neglijarea fractiunilor de pana la 49 de bani inclusiv.${estrong}":
		"Rotunjirea sumelor datorate ca taxe sau impozite se face prin ${sstrong}rotunjire la un leu pentru fracțiunile de peste 50 de bani inclusiv și prin neglijarea fracțiunilor de până la 49 de bani inclusiv.${estrong}",
	"Astfel, o valoare de 1,58 Lei se rotunjeste la 2 Lei, iar 0.49 Lei se rotunjeste la 0 Lei.":
		"Astfel, o valoare de 1,58 Lei se rotunjeste la 2 Lei, iar 0.49 Lei se rotunjeste la 0 Lei.",
	"We are committed to deliver the best time you can have in our casino. Placing stakes and winning is a lot of fun. But losing is also part of the gambling thrill. Many people believe that gamble gaming is high-reward activity with low risk; it cannot be further from the truth. While Jackpots do hit and huge winnings occur, remember that mathematically the odds favor the house. People come up with strategies and lucky totems that guarantee the wins, some games involve some element of skill, but slots, blackjack, roulette and sports betting are all games of chance and no one can influence the outcome. ":
		"Ne-am angajat să oferim cel mai bun timp pe care îl puteți avea în cazinoul nostru. Plasarea mizelor și câștigarea este foarte distractivă. Dar pierderea face parte și din fiorul jocurilor de noroc. Mulți oameni consideră că jocurile de noroc sunt activități cu recompense mari, cu risc redus; nu poate fi mai departe de adevăr. În timp ce Jackpot-urile ating și câștiguri uriașe, nu uitați că matematic șansele favorizează casa. Oamenii vin cu strategii și totemuri norocoase care garantează câștigurile, unele jocuri implică un anumit element de îndemânare, dar sloturile, blackjack-ul, ruleta și pariurile sportive sunt jocuri de noroc și nimeni nu poate influența rezultatul.",
	"We strongly encourage that you play only when you feel 100% confident in your decisions and only the budget that you can afford to lose. Never play money that you need for a healthy life, for your bills and your loving ones. If you ever feel the urge to keep playing to recoup your loss, then STOP! If you can’t tell or feel guilty to tell other people how much money you have gambled, please consider you have some gambling addiction.":
		"Încurajăm cu tărie să joci doar atunci când te simți 100%  încrezător în deciziile tale și doar bugetul pe care îți poți permite să îl pierzi. Nu juca niciodată banii de care ai nevoie pentru o viață sănătoasă, pentru facturile tale și pentru cei dragi. Dacă simți vreodată nevoia de a continua să joci pentru a-ți recupera recuperarea, atunci STOP! Dacă nu puteți spune sau vă simțiți vinovat să spuneți altor oameni câți bani ați pariat, vă rugăm să considerați că aveți dependență de jocuri de noroc.",
	"To find out to which degree you are addicted to gambling, please take the anonymous test at":
		"Pentru a afla în ce măsură sunteți dependent de jocuri de noroc, vă rugăm să faceți testul anonim la",
	"If you think you have a gambling addiction or you think a family member or a friend has a gambling problem, consider getting qualified help from":
		"Dacă credeți că aveți o dependență de jocuri de noroc sau credeți că un membru al familiei sau un prieten are o problemă de jocuri de noroc, luați în considerare să primiți ajutor calificat de la",
	"Looking for immediate help? Call the free number":
		"Cauți ajutor imediat? Sunați la numărul gratuit",
	"Monday to Friday": "de Luni până Vineri",
	"and a specialist will answer and help you!":
		"iar un specialist vă va răspunde și vă va ajuta!",
	"Also, have a look at the following tips that may help you have a healthy, pleasing gaming.":
		"De asemenea, aruncați o privire la următoarele sfaturi care vă pot ajuta să aveți un joc sănătos și plăcut.",
	"Play for fun, not to make money.": "Joacă-te pentru distracție, nu pentru a câștiga bani.",
	"Gaming is never a solution to any financial problems. Remember that the odds favor the house.":
		"Jocurile de noroc nu sunt niciodată o soluție pentru probleme financiare. Amintiți-vă că șansele favorizează casa.",
	"Stick to a budget.": "Controlează-ți bugetul.",
	"Set a budget, stick to it and never play for more than you can safely afford. You should never play more than your disposable income; that is the remaining money after you deduct from your income the daily-living expenses, rent, food, your loving ones’ needs, bills, etc. Also, never play with other people’s money and never burrow money to gamble.":
		"Setați un buget, respectați-l și nu jucați niciodată mai mult decât vă puteți permite în siguranță. Niciodată nu ar trebui să joci mai mult decât venitul disponibil; aceștia sunt banii rămași după ce deduceți din venituri cheltuielile de viață zilnică, chiria, mâncarea, nevoile persoanelor iubitoare, facturile etc. De asemenea, nu vă jucați niciodată cu banii altor persoane și niciodată nu împrumutați bani pentru a paria.",
	"Use limits.": "Folosește limitele.",
	"Don’t get carried away in the heat of play and deposit more than you planned initially. Use the built-in tools to set deposit limits to maximize the fun and minimize your frustration. If you are connected, you can set up":
		"Nu vă lăsați purtat de lovitura jocului și nu depuneți mai mult decât ați planificat inițial. Utilizați instrumentele încorporate pentru a stabili limitele depozitelor pentru a maximiza distracția și a vă reduce frustrarea. Dacă sunteți conectat, puteți configura",
	"Deposit Limits from here.": "Limitele de depunere de aici.",
	"Play when you are 100% clear headed.": "Joacă când ai 100% mintea liberă.",
	"Never play when you had alcohol or drugs because they will affect your decision making. Never play when you are sad or tired because you will not be able to think clearly.":
		"Nu vă jucați niciodată când aveți alcool sau droguri, deoarece acestea vă vor afecta luarea deciziilor. Nu juca niciodată când ești trist sau obosit, deoarece nu vei putea gândi clar.",
	"Don’t raise the stakes to artificially create excitement.":
		"Nu ridicați miza pentru a crea artificial emoție.",
	"If you get bored of gaming, raising stakes to artificially inflate the thrill is never a good idea; it will only lead to losing your funds faster. It’s much better to stand up and have a walk or meet some friends. The excitement will return when you place the original stake after a break.":
		"Dacă vă plictisiți de jocuri, creșterea mizelor pentru a umfla artificial fiorul nu este niciodată o idee bună; aceasta va duce doar la pierderea mai rapidă a fondurilor. Este mult mai bine să te ridici în picioare și să te plimbi sau să întâlnești niște prieteni. Entuziasmul va reveni când poziționați miza originală după o pauză.",
	"Spend your time wisely!": "Petreceți-vă timpul cu înțelepciune!",
	"Remember to get out in the real world and spend time and money with your friends and your loving ones as well. The key to happiness is a balanced life. This way you can enjoy all the activities more.":
		"Nu uitați să ieșiți în lumea reală și să petreceți timp și bani cu prietenii și cu cei dragi. Cheia fericirii este o viață echilibrată felul în care vă puteți bucura mai mult de toate activitățile.",
	"It is illegal for anyone under the age of 18 to gamble in our casino.":
		"Este ilegal ca oricine sub 18 ani să joace în cazinoul nostru.",
	Confirm: "Confirmă",
	"Amount (Lei)": "Sumă (lei)",
	"Amount must be grater than 0": "Suma trebuie să fie mai mare decât 0",
	"You can deposit maximum ${value} RON": "Poti depune maxim ${value} RON",
	"What is the amount you wish to deposit?": "Care este suma pe care doriți să o depuneți?",
	"Withdrawal Successful!": "Operatiunea a avut succes!",
	"Deposit Successful!": "Operatiunea a avut succes!",
	"Everything is well! Your withdrawal request was registered. For the moment, Payment Team is checking it, and it will be approved soon.":
		"Totul este in regula! Cererea ta de retragere a fost inregistrata. Pentru moment, echipa de plati o verifica si va fi aprobata in curand.",
	"Everything is well! Your account has been credited with the amount of ": "Totul este in regula! Contul tau a fost creditat cu suma de ",
	"as 2% deposit tax": "taxa 2%",
	". Time to sit back, play and relax. Good luck!": ". Acum te poți intoarce relaxat la joc. Mult noroc!",
	"Pending withdrawals": "Retrageri în așteptare",
	"Wallet & Bonus": "Portofel & Bonus",
	Finish: "Finalizare",
	"Time to play": "Joaca acum",
	"Operation failed!": "Operatiunea nu a reusit!",
	"An error has occured": "A aparut o eroare",
	"Customer Service": "Suport Clienti",
	"I got it": "Am inteles",
	"Operation canceled!": "Operatiune intrerupta!",
	"security information": "informatie de securitate",
	"data protection": "Protecția datelor",
	"about us": "Contact",
	"game rules": "Regulament de Joc",
	"Responsible Game": "Joc Responsabil",
	locations: "Agenții",
	"Do you need help?": "Ai nevoie de ajutor?",
	"Payment Methods": "Metode de Plată",
	"Only for players over 18 years old": "Numai pentru jucătorii peste 18 ani",
	"Gambling can create addiction. Play Responsible!":
		"Jocurile de noroc pot provoca dependenta. Joaca responsabil!",
	"This website is operated by ${companyName}, registered in Malta under number ${companyRegNumber}, at ${companyAddress}. In Romania, ${companyName} owns license number ${license_number}, issued on ${license_date}, by the National Gambling Office (${openTagOnjn}http://onjn.gov.ro/${closeTagOnjn}), valid until ${license_expiration_date}. Please be informed that gambling might be prohibited in your area. It is your responsibility to obey the law. Gambling may be addictive and impact your financials. Please play responsibly!":
		"Acest website este operat de ${companyName}, înregistrată în Malta sub nr. ${companyRegNumber}, având sediul social în ${companyAddress}. În România, ${companyName} deține licența nr. ${license_number}, emisă la data de ${license_date} de Oficiul Național pentru Jocuri de Noroc (${openTagOnjn}http://onjn.gov.ro/${closeTagOnjn}), valabilă până la data de ${license_expiration_date}. Va informăm cu privire la posibilitatea ca activitatea de jocuri de noroc să fie interzisă în jurisdicția în care sunteti localizat. Este responsabilitatea dumneavoastră să respectați legea. Activitatea de jocuri de noroc poate cauza dependență și să aibă un impact asupra situației dumneavoastră financiare. Va rugăm să jucați responsabil!",
	"my favourites": "favoritele mele",
	"Add game to": "Adauga jocul la",
	favourites: "favorite",
	"Remove from": "Elimina din",
	"Load more games": "Load more games",
	"hot earnings": "hot ",
	"jakpot egt": "Jackpot EGT",
	recommended: "recomandate",
	"You may like": "Posibil sa iti placa",
	"Table Games": "Jocuri de Masă",
	"50 LEI FREE": "50 LEI GRATIS",
	"Friends with benefits": "Prieteni cu beneficii",
	"Money for free, once we know each other": "Bani gratis, după ce ne cunoaștem",
	"TAKE 1,000 LEI BONUS": "IA BONUS 1.000 LEI",
	"What's your number?": "Cat vrei sa iei cu tine?",
	"Mega Jackpots. Prizes. Tournaments.": "Mega Jackpots. Premii speciale. Concursuri.",
	"All EGT Jackpot Games": "Toate jocurile EGT Jackpot",
	"EGT Jackpot Popular Games": "Jocurile populare EGT Jackpot",
	"Recently played in EGT Jackpot": "Jucat recent în EGT Jackpot",
	"Jackpot EGT": "Jackpot EGT",
	"View All Games": "Vezi toate jocurile",
	"Last Winnings": "Ultimele câștiguri",
	wins: "a câştigat",
	at: "la",
	"JACKPOT Last Winnings": "Ultimele câștiguri JACKPOT ",
	"Highest win": "Cel mai mare câștig",
	"Most recent win": "Cel mai recent câștig",
	promotions: "Promotii",
	"Deposit & withdrawal in locations": "Depunere & retragere în agenții",
	"The name should have minimum %s characters": "Numele trebuie să aibă minim %s caractere.",
	"Email invalid": "Email invalid",
	"The subject should have minimum %s characters": "Subiectul trebuie sa aibă minim %s caractere",
	"The content should have minimum %s characters":
		"Conţinutul trebuie să aibă minim %s caractere",
	"Thank you for your feedback! Someone will look into your issue shortly":
		"Multumim pentru feedback-ul dvs! Cineva va examina problema dvs. în curând",
	Email: "Email",
	"Subject of complaint": "Subiectul plangerii",
	Body: "Conținut",
	Submit: "Trimite",
	agencies: "Agenții",
	"depunere agentie": "Depunere Agentie",
	"Doar cu buletinul": "Doar cu buletinul",
	"anywhere, any shop": "oriunde, oricand",
	"Mergi in orice agentie Eldorado si poti depune cat vrei doar cu buletinul":
		"Mergi în orice agenție și depune ușor și rapid la ghișeu doar cu actul de identitate",
	"Fara batai de cap, fara carduri": "Fără bătăi de cap, fără carduri",
	"Online deposit": "Depuneri Online",
	"Harta cu Agentii": "Harta cu Agenții",
	"Activare locatie": "BONUS-uri active",
	"Accepta cererea de acces locatie!": "Acceptă cererea de acces locație!",
	"deschide cererea": "deschide cererea",
	"Aproape de tine": "În apropiere",
	"Pentru activari mai precise trebuie sa activezi accesul la Locatia ta.":
		"Pentru activări mai precise trebuie să activezi accesul la Locația ta.",
	Activeaza: "Activeaza",
	Withdraw: "Retragere",
	Deposit: "Depunere",
	Maps: "Hărţi",
	"Bonus 1000 Lei": "Bonus 1000 Lei",
	"open account": "deschide cont",
	banner_message_1st_row: "Cât vrei",
	banner_message_2nd_row: "să iei cu tine?",
	"Better odds. Easy to bet.": "Cote mai bune. Mai ușor de pariat. ",
	"Get wins at a fast speed.": "Prinde câștiguri în viteză.",
	"VIP Experiece": "Experienţă VIP",
	"Live Casino. Exceed expectations.": "Cazino LIVE. Peste aşteptări.",
	"all games": "Toate Jocurile",
	"Not logged in, please log in to continue":
		"Nu ești autentificat. Te rog să te autentifici pentru a continua!",
	"SEE ALL RULES, T&C": "VEZI TOATE NORMELE, T&C",
	"Deposit with this bonus": "Depuneți cu acest bonus",
	Logout: "Deconectare",
	Locations: "Agenții",
	"change street address": "schimba adresa",
	"Change address": "Schimbă adresa",
	cancel: "Anulare",
	Step: "Pasul",
	of: "din",
	"Current password": "Parola actuală",
	Next: "Pasul următor",
	"New password is invalid!": "Noua parola este invalida!",
	"Passwords don't match": "Parolele nu corespund",
	"New password": "Noua parola",
	"Repeat password": "Repetă parola",
	"Update password": "Modifică parola",
	"change password": "modifica parola",
	"Invalid phone number!": "Număr de telefon invalid!",
	"New phone number": "Noul numar de telefon",
	Validate: "Validare",
	"Validate code": "Validare cod",
	"Loading...": "Incărcare...",
	"SMS Code": "Retrimite codul prin SMS",
	"check number and resend code": "verifica numarul si retrimite cod",
	"ATENTIE: BONUS-uri ACTIVE": "ATENTIE: BONUS-uri ACTIVE",
	"retragere blocata": "retragere blocată",
	"Bani reali": "Disponibili",
	"Bani BONUS": "BONUS",
	Conform: "Conform",
	", orice retragere presupune ${bOpen}anularea${bClose} fondurilor bonus ( ${bOpen}-${bonusAmount}${bClose} ) si a bonus-urilor active.":
		", orice retragere presupune ${bOpen}anularea${bClose} fondurilor bonus ( ${bOpen}-${bonusAmount}${bClose} ) si a bonus-urilor active.",
	"Renuntarea este permanenta.": "Renuntarea este permanenta.",
	"Bonus-urile nu pot fi reactivate, nici macar prin anularea ulterioara a retragerii.":
		"Bonus-urile nu pot fi reactivate, nici macar prin anularea ulterioara a retragerii.",
	"Retrag mai tarziu": "Retrag mai tarziu",
	"Vezi BONUS-uri active": "Vezi BONUS-uri active",
	"Amount withdrawn (Lei)": "Suma retrasă (lei)",
	"Chose location": "Alege locaţie",
	"Online Withdraw": "Retragere Online",
	"What is the amount you wish to withdraw?": "Care este suma pe care doriți să o retrageți?",
	"Requested amount is not available to withdraw!":
		"Care este suma pe care doriți să o retrageți?",
	"Please select an agency!": "Vă rugăm să selectați o agenție!",
	"In agentia": "În agenția:",
	change: "schimbă",
	"Vei primi": "Vei primi",
	"Ai ales sa retragi": "Ai ales să retragi:",
	"Taxes & Fees": "taxe și impozite",
	"Available funds": "Fonduri disponibile",
	Eroare: "Eroare",
	"Suma de": "Suma de",
	"nu a fost trimisa catre aprobare": "nu a fost trimisa catre aprobare",
	"Te rugam sa incerci mai tarziu": "Te rugăm sa încerci mai târziu",
	"Am inteles": "Am înțeles",
	"The amount of ${amount} was sent for approval.":
		"Suma de ${amount} a fost trimisă către aprobare.",
	"We will notify you when status changes occur for this request":
		"Vă vom anunța când apar modificări de stare pentru această solicitare",
	all: "toate",
	withdraw: "Retrage",
	"Accordingly to legal requirements, please verify your identity prior to withdrawing any money.":
		"În conformitate cu cerințele legale, vă rugăm să vă verificați identitatea înainte de a retrage bani.",
	Verify: "Verifică",
	"View status": "Vezi status",
	"Your identity documents you provided are under review.":
		"Documentele tale de identitate vor fi analizate în curând.",
	withdrawal: "Retragere",
	"Please select a location": "Please select a location",
	"taxes & fees": "taxe și impozite",
	"Amount withdrawn": "Valoare retragere",
	Yes: "Da",
	"cancel withdrawal": "anulare retragere",
	"Are you sure that you want to cancel this withdrawal request?":
		"Ești sigur că vrei să anulezi această retragere?",
	"Amount to cancel": "Valoare anulată",
	No: "Nu",
	"fast deposit": "Depunere rapidă",
	"Deposit from withdrawal request": "Depunere & retragere în agenții",
	"Available amount": "Valoare disponibila",
	Close: "Închide",
	"Verify your ID card!": "Verifică-ți identitatea!",
	"According to law regulations, please confirm your identication card details in order to be able to deposit funds into your account.":
		"Conform reglementarilor in vigoare, te rugam sa iti confirmi identitatea pentru a putea depune mai mult de 900 Lei in contul tau.",
	"Resolve now": "Rezolvă acum",
	"estimated time": "Timp de așteptare estimat",
	"ID Card": "Carte de Identitate",
	Verified: "Verificat, totul e în regulă.",
	"Requires verification!": "Necesita verificare!",
	"Email status:": "stare:",
	"Not Verified": "Neverificat",
	"Email sent": "Email trimis",
	"Verify now": "Verifică acum",
	"Email:": "Email:",
	Edit: "Modifică",
	Password: "Parola",
	"Mobile number": "Număr de telefon mobil",
	"Street address": "Adresa de domiciliu",
	Language: "Limba",
	English: "English",
	"Your documents are safe with us. Learn more!":
		"Documentele tale sunt în siguranță. Află mai multe!",
	"Upload document": "Incarcă document",
	"Upload a new photo": "Incarcă o nouă poză",
	"Please upload a photo of your Card Bank": "Te rugam sa incarci o poza cu Cardul Bancar",
	minutes: "minute",
	"This document is under review.": "Acest document va fi analizat în curând.",
	"Estimated time left": "Timp estimat de așteptare",
	"Documents's photo rejected!": "Documentul trimis a fost respins!",
	"Validity date has expired": "Data de valabilitate a expirat",
	"Details are not visible": "Detaliile nu sunt clare",
	"File cannot be opened": "Detaliile nu sunt clar vizibile",
	"Wrong document": "Nu corespunde documentului cerut",
	"Document cannot read": "Documentul este ilizibil",
	"Document wrong image": "Nu este documentul cerut",
	"Document should be fully visible in the photo":
		"Imaginea documentului nu trebuie să fie tăiată",
	"Card Bank": "Card Bancar",
	"Please upload a photo of your ID-card":
		"Te rugam sa incarci o poza cu Buletinul de Identitate",
	"Please upload a photo of your Passport": "Te rugam sa incarci o poza cu Pașaportul",
	Passport: "Paşaport",
	"Please upload a photo of your Utilities Bill":
		"Te rugăm să încarci o poză a Facturii de Utilități pe numele tău",
	"Utilities Bill": "Factură de utilități",
	"No pending withdrawals": "Nicio retragere în așteptare",
	"Total amount of blocked funds": "Suma totală de fonduri blocate",
	"List is empty for the moment.": "Lista este momentan goală",
	Unlimited: "Nelimitat",
	"Set the limit to": "Activează o limită de",
	"Remove limit": "Anulează limita",
	daily: "zilnică",
	weekly: "săptămânală",
	monthly: "lunară",
	others: "altele",
	Rolling: "Rulaj",
	Losses: "Pierderi",
	Others: "Altele",
	"The new limit of %s will be activated in 48 hours":
		"Noua limită de %s va fi activată în 48 de ore",
	"This limit will be changed to unlimited in 48 hours":
		"Această limită va fi modificată în 48 de ore",
	loss: "Pierderi",
	"%s day": "%s zi",
	"%s days": "%s zile",
	"%s month": "%s luna",
	"%s months": "%s luni",
	"%s year": "%s an",
	"%s years": "%s ani",
	"If you self-exclude, you will be unable to complete it before the deadline and, accordingly to the legal requirements, you account will be closed and the funds will be sent to the State Budget.":
		"Dacă vă excludeți singur, nu veți putea să îl finalizați înainte de termen și, în consecință, conform cerințelor legale, contul dvs. va fi închis, iar fondurile vor fi trimise la bugetul de stat.",
	"Your account and your funds are safe.": "Contul și fondurile tale sunt în siguranță.",
	"You can come back when the chosen period expires":
		"Vei putea reveni în cazinou după expirarea perioadei de auto-excludere.",
	"More options": "Mai multe opțiuni",
	"Lock the account for": "Restricționează accesul la cont pentru",
	Period: "Perioada",
	"no results found": "Nici un rezultat",
	"no win": "niciun câştig",
	"There is no bonus active yet. Visit ${beforePromotionsLink}Promotions${afterPromotionsLink} to get one.":
		"Momentan nu este niciun bonus activ.  Vizitează pagina de ${beforePromotionsLink}Promoţii${afterPromotionsLink} pentru a lua unul.",
	"Your BONUS": "BONUS-ul tau",
	"Current state": "Stare curentă",
	pending: "în așteptare",
	active: "activ",
	expired: "expirat",
	forfeited: "renunțat",
	"under investigation": "sub investigație",
	wagered: "rulaj",
	"Current bonus": "Bonus curent",
	"Rolling conditions": "Condiții de rulaj",
	"Esti sigur ca vrei sa renunti la acest bonus?":
		"Ești sigur ca vrei să renunți la acest bonus?",
	Casino: "Cazino",
	Sportsbook: "Pariuri Sportive",
	"Get more": "Obține mai mult",
	"BONUS funds have wager conditions before they can be withdrawn.":
		"Fondurile BONUS au conditii de rulaj inainte de a putea fi retrase.",
	"Total amount": "Valoare totală",
	"Verify Identity": "Verifică-ți identitatea",
	"My account": "Contul meu",
	"Self-exclusion": "Auto-excludere",
	"How long you want to self-exile:": "Cât timp vrei să te auto-excluzi?",
	"It is important for us to have fun when playing Eldorado online casino. If you're worried about playing more than you can: Take a break for a short period of time. After the self-exclusion period expires, you will be able to return to play.":
		"Este important pentru noi ca tu să te distrezi în cazino. Dacă ești îngrijorat că joci prea mult, gândește-te să iei o pauză pentru o perioadă scurtă de timp. După ce perioada de auto-excludere expiră, te poți întoarce să joci.",
	"I agree to be restricted to my online casino access for the period specified above.":
		"Sunt de acord să îmi fie restricționat accesul în contul meu pentru perioada de mai sus.",
	day: "zi",
	days: "zile",
	"I want a break": "Vreau o pauză",
	"How long do you want to take a break?": "Cât timp vrei să iei o pauză?",
	"It is important for us to have fun when playing Eldorado online casino. If you're worried about playing more than you can: take a break for a short period of time. After the self-exclusion period expires, you will be able to return to play.":
		"Este important pentru noi ca tu să te distrezi în cazino. Dacă ești îngrijorat ca joci prea mult, gandește-te să iei o pauză pentru o perioadă scurtă de timp. După ce perioada de auto-excludere expiră, te poți întoarce să joci.",
	"Address from Identity Card": "Adresa de reședință",
	Address: "Adresa",
	"CNP must be 13 chars long": "CNP-ul trebuie să aibă minim 13 caractere",
	"CNP must contain only numbers": "CNP-ul trebuie să conțină doar cifre",
	"Minimum age accepted for registration is 18 years":
		"Vârsta minimă acceptată pentru înregistrare este de 18 ani",
	"Social Security Number is too short": "Seria Pașaportului este prea scurtă",
	"Inserted value is invalid": "Valoarea introdusă este invalidă",
	CNP: "CNP",
	"Social Security Number": "Serie Pașaport",
	Citizenship: "Cetățenie",
	"Insert a valid email address": "Introdu o adresă de email validă",
	"First Name from Identity Card": "Numele mic din buletin",
	"This field contains invalid characters.": "Acest câmp conține caractere invalide",
	"This field must have minimum 3 characters": "Acest câmp trebuie să aibă minim 3 caractere",
	"Inserted value is not valid": "Valoarea introdusă nu este validă",
	"First Name": "Prenume",
	"Last Name": "Nume",
	"This field contains invalid characters": "Acest câmp conține caractere invalide",
	"This field must have minimum 2 characters": "Acest câmp trebuie să aibă minim 2 caractere",
	"Inserted field is invalid": "Valoarea introdusă este invalidă",
	"Change number?": "Schimbi număr?",
	Save: "Salvează",
	"Change Phone Number": "Schimbă Numărul de Telefon",
	"Resend SMS Code": "Retrimite codul prin SMS",
	"This phone number is already registered": "Acest număr de telefon este deja înregistrat",
	"You can request a new SMS code in %s seconds": "Poți cere un nou cod prin SMS in %s secunde",
	"The passwords doesn't match": "Parolele nu corespund",
	"The password doesn't respect the minimum criteria": "Parola nu respectă condițiile minime",
	"Password Requirements:": "Cerințe parolă:",
	"Include at least 1 letter": "Include minim o literă",
	"Have at least 6 characters in length": "Are cel puțin 6 caractere lungime",
	"Include at least 1 number": "Include minim un număr",
	"Security Level:": "Nivel Securitate:",
	"Confirm Password": "Confirmare parolă",
	"Insert your phone number": "Introdu numărul de telefon",
	"Phone number invalid": "Număr de telefon invalid",
	Phone: "Telefon",
	"Reference Code": "Cod de referință",
	"Your Deposit Bonus": "Bonusul Tău de Depozit",
	"You are moments away from collecting your bonus and immediately start playing at the":
		"Ești la cateva momente de a colecta bonusul tău și de a începe să joci la",
	and: "și",
	"LIVE Casino": "Cazino LIVE",
	"See Terms & Conditions": "Vezi Termeni & Condiții",
	PLAY: "Joacă",
	"SAVE Bonus for Later": "Salvează bonusul pentru mai târziu",
	"Your BONUS will remain available for 30 days":
		"BONUSUL tău va ramane disponibil pentru 30 zile",
	DEPOSIT: "Depune",
	"CLAIM Bonus Now": "SOLICITĂ Bonusul Acum",
	"Something went wrong": "A apărut o problemă",
	"Something went wrong. Please try again later.": "A aparut o eroare. Reincearca mai tarziu",
	"This field is mandatory": "Acest câmp  este obligatoriu",
	"I am older than 18 years": "Am peste 18 ani",
	"I want to receive bonuses and marketing materials that are relevant to me.":
		"Vreau sa fiu informat despre promotiile relevante pentru mine.",
	"I read and I agree with ${linkStartTC}T&C${linkEnd} and ${linkStartGDPR}Privacy Policy${linkEnd}.":
		"Am citit si sunt de acord cu ${linkStartTC}Termenii si Conditiile${linkEnd} de utilizare a serviciilor si cu ${linkStartGDPR}Politica de Confidentialitate${linkEnd} a cazinoului.",
	"Romanian IP only": "doar IP-ul românesc",
	"Register Account": "Înregistrare cont",
	"Start Playing in style": "Incepe să joci cu stil",
	Congratulations: "Felicitări",
	"Create New Account": "Creare cont nou",
	"Personal Details": "Detalii Personale",
	Verification: "Verificare",
	"Claim Bonus": "Solicită Bonus",
	"Secure Your Account": "Securizează contul",
	"A SMS has been sent to": "Un SMS a fost trimis către",
	"please enter the 5-digit code provided": "completează codul din 5 cifre primit",
	"Your email has been verified. Let’s play!":
		"Adresa ta de email a fost verificata cu succes. E timpul distractiei!",
	"Congratulations!": "Felicitări!",
	"Reset Password": "Resetează parola",
	"Your password was reseted. Now you can access your account with the new password.":
		"Parola a fost resetată. Poți intra în contul tău folosind noua parolă.",
	"Type in the field the email address associated with the account":
		"Introdu adresa de email asociată contului tau",
	"You will receive a link to the email associated with the account. You can reset your password from it.":
		"Vei primi un mail cu un link care iți permite schimbarea parolei. ",
	"We sent you an email with the link to change password":
		"Ti-am trimis un email cu link-ul pentru schimbarea parolei",
	"Send email": "Trimite email",
	PasswordConfirm: "Confirmare parolă",
	"The new password will be activated immediately and you will be able to access your account.":
		"Noua parolă se va activa automat și vei putea intra în contul tău",
	"Change Password": "Schimbă parola",
	"Type in the field the verification code sent to you through email":
		"Intro codul de resetare parolă primit prin email",
	"Verification Code": "Cod de resetare",
	"After identity verification you can choose a new password. Please, check your email.":
		"După verificarea identității, îți vei putea alege o nouă parolă. Te rugăm să îți verifici emailul. ",
	"Bonus in the Welcome Fund": "Fonduri BONUS în Premiul de Bun-Venit",
	"Pending funds": "Fonduri în așteptare",
	"Learn more": "Obține mai mult",
	"I want bonus": "Vreau bonus",
	Wallet: "Portofel",
	"Real money": "Disponibili",
	"BONUS money (requires rolling)": "Bani BONUS (necesită rulaj)",
	details: "detalii",
	Notifications: "Notificări",
	"Active Bonuses": "BONUS-uri active",
	Agencies: "Agenții",
	Limite: "Limite",
	"security of information": "securitatea informațiilor",
	FAQ: "FAQ",
	contact: "Contact",
	"reset-password": "Recuperare Parolă",
	slots: "slots",
	"table games": "Jocuri de Masă",
	"cazino live": "Cazinou LIVE",
	pariuri: "Pariuri",
	promotii: "Promotii",
	jocuri: "Jocuri",
	"account balance": "Soldul contului",
	"BONUS Money": "BONUS",
	"profile settings": "Setări profil",
	"player profile transactions": "Tranzacţiile jucătorului",
	"deposits & withdrawals": "Depuneri & retrageri",
	game: "Joc",
	unknown: "necunoscut",
	"pending approval": "Retrageri în așteptare",
	"ready for cashing": "gata pentru încasat",
	failed: "eşuat",
	collected: "colectat",
	cancelled: "Anulat",
	"player profile responsibly": "Responsibilitatea jucătorului",
	"First deposit bonus": "Primul bonus de depunere",
	"Authentication successful!": "Autentificare reușită!",
	"live casino": "LIVE Cazinou",
	games: "Joc",
	"take a break page": "Vreau o pauză",
	documents: "Documente",
	"meet the legal requirements": "îndeplinesc cerințele legale",
	"player self exclusion": "Excluderea de sine a jucătorului",
	"complaint form": "Formular plângere",
	"Welcome to Elite! We know that a high stake generates high earnings. For you to enjoy your betting without any restraints, ${boldStart}we give you a BONUS, for each deposit, up to a total limit of 1,000 Lei.${boldEnd} Make a deposit now, we give you a prize and you can start playing, right away!":
		"Bine ai venit la Elite! Știm ca o miza mare aduce câștiguri mari. Ca sa te bucuri fără rețineri, ${boldStart}primești BONUS la fiecare depunere in limita totala a 1.000 Lei.${boldEnd} Depune acum, te premiem si începi sa joci imediat!",
	"First deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}500 lei BONUS.${boldEnd}":
		"Prima depunere: ${boldStart}100%${boldEnd} din suma depusa in limita maxima de ${boldStart}500 lei BONUS.${boldEnd}",
	"Second deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}200 Lei BONUS.${boldEnd}":
		"A doua depunere: ${boldStart}100%${boldEnd} din suma depusa in limita maxima de ${boldStart}200 Lei BONUS.${boldEnd}",
	"Third deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}300 Lei BONUS.${boldEnd}":
		"A treia depunere: ${boldStart}100%${boldEnd} din suma depusa in limita maxima de ${boldStart}300 Lei BONUS.${boldEnd}",
	available: "disponibili",
	consumed: "consumați",
	"Withdrawals in pending": "Retrageri în așteptare",
	"Your requests": "Cererile tale",
	"This account is suspended. Please contact support.":
		"Acest cont este suspendat. Contactați serviciul de Suport Jucători pentru mai multe informații.",
	"Recent transactions": "Tranzacții recente",
	"Withdrawals history": "Istoric retrageri",
	"Fast deposit": "Depunere rapidă",
	"Logged out!": "Ai fost deconectat!",
	"The application has encountered an unknown error.": "A aparut o eroare necunoscuta.",
	"This player is already registered.": "Acest jucător este deja înregistrat",
	"Invalid phone number.": "Număr de telefon invalid!",
	"Invalid email address.": "Introdu o adresă de email validă",
	"The minimum age requirement is not met.": "Varsta minima obligatorie nu este atinsa.",
	"The cnp is not valid.": "Valoarea introdusă nu este validă",
	"Email is already registered": "Acest email este deja înregistrat",
	"Phone number is already registered.": "Acest număr de telefon este deja înregistrat",
	"The application has encountered a saving error.": "A aparut o eroare la salvare.",
	"Inserted code is invalid.": "Valoarea introdusă este invalidă",
	"Current password is invalid.": "Parola actuala este invalida!",
	"Authentication expired! Please login and try again.": "Incercati din nou dupa conectare.",
	"Invalid token.": "Token invalid.",
	"Phone number SMS limit was reached.":
		"Limita de SMS-uri pe acest numar de telefon a fost atinsa.",
	"Insufficient funds.": "Fonduri insuficiente.",
	"Invalid request.": "Cerere invalida",
	"Duplicate request.": "Duplicare cerere",
	"Negative amount.": "Suma negativa",
	"Cannot withdraw while having active bonuses.":
		"Nu se poate face o cerere de retragere cat timp sunt bonusuri active.",
	"This account is closed. Please contact support.":
		"Acest cont este inchis. Contactați serviciul de Suport Jucători pentru mai multe informații.",
	"This account is self excluded.": "Acest cont este auto-exclus.",
	"responsible-gaming": "Joc Responsabil",
	"payment methods": "Metode de Plată",
	cookies: "Cookies",
	deposits: "Depuneri",
	gdpr: "Politica de confidențialitate",
	"complaint-form": "Fă o Plângere",
	withdrawals: "Retrageri",
	betting: "pariuri",
	"GDPR consensus": "GDPR",
	"game-rules": "Regulament de Joc",
	"terms & conditions": "Termeni si Conditii",
	"pirate treasure": "comoara piratului",
	"about-us": "Contact",
	"transaction history": "istoric tranzacții",
	"self exclusion": "Auto-excludere",
	"take a break": "Vreau o pauză",
	"account details": "detalii cont",
	"wallet & bonuses": "portofel și bonusuri",
	"Partial cancellation": "Anulare parțială",
	"Download history for last 90 days": "Descarcă istoric pentru ultimele 90 de zile",
	"The 90 days report is empty. Please try again later!":
		"Raportul pe 90 de zile este gol. Te rugam sa încerci mai târziu!",
	"See all transactions": "Vezi toate tranzacțiile",
	View: "Vezi",
	"pending withdrawals": "Retrageri în așteptare",
	"current state": "Stare curentă",
	"responsible gaming": "Joc Responsabil",
	aici: "aici",
	limite: "Limite",
	"Termeni si Conditii": "Termeni & Condiții",
	or: "sau",
	"Decide who goes home with the trophy": "Decide who goes home with the trophy",
	"Not Available": "Indisponibili",
	"FREE BONUS 50 Lei": "Ia BONUS 1.000 LEI",
	"How much do you want to take with you?": "Cat vrei sa iei cu tine?",
	"Mega Jackpots. Special prizes. Competitions.": "Mega Jackpots. Premii speciale. Concursuri.",
	"TAKE BONUS 1.000 LEI": "Ia BONUS 1.000 LEI",
	'search "final" or "goluri"': "cauta “final” sau “goluri”",
	Recommended: "Recomandate",
	"Total Odds": "Cota finala",
	Stake: "Miza",
	Winning: "Castig",
	"Unavailable event - Remove": "Eveniment indisponibil - Sterge",
	"Odds changed - Click to accept the changes":
		"Cota schimbata – Click pentru a accepta schimbarea",
	"Your ticket will be placed in a few moments. Please wait.":
		" Biletul o sa fie plasat in cateva momente. Te rugam sa astepti.",
	'The ticket was placed successfully and you can find it in the "My Tickets" section.':
		"Biletul a fost plasat cu success si il poti gasi in sectiunea “Biletele mele”",
	"The ticket was placed successfully.": "Biletul a fost plasat cu succes",
	"ONLINE TICKET": "Bilet Online",
	"TICKET IN SHOP": "Bilet in agentie",
	"LIVE and Pre-match events cannot be mixed on the same betslip.":
		"Evenimentele Live si Pre-Meci nu se pot plasa pe acelasi bilet",
	Bets: "Pariuri",
	"Default Layout": "Default Layout",
	"UK Layout": "UK Layout",
	"All days": "Toate",
	"By League": "Dupa Liga",
	"By Hour": "Dupa Ora",
	"PLAY NOW": "JOACA ACUM",
	BLOCKED: "BLOCAT",
	"EVENT BETS LOCKED": "Pariuri Închise",
	"Show more": "Mai multe",
	Register: "Înregistrare",
	"Sign-in": "Conectare",
	Tickets: "Bilete",
	Scan: "Scaneaza",
	Account: "Cont",
	"Total odds": "Cota finala",
	"Potential Winning": "Castig Potential",
	"Ticket Details": "Detalii Bilet",
	Opened: "In Asteptare",
	Settled: "Decise",
	"Download History for last 90 days": "Descarcă istoric pentru ultimele 90 de zile",
	bets: "pariuri",
	Home: "Acasa",
	"Pre-Match": "Sport",
	Highlights: "Highlights",
	Favorite: "Favorite",
	"My Filter": "Filtrul Meu",
	"Top League": "Ligi de top",
	navigare: "Navigare",
	Live: "Live",
	"PRE - MATCH": "PRE - MATCH",
	Calendar: "Calendar",
	Avantaj: "Avantaj",
	"Winner+": "Winner+",
	All: "Toate",
	Goals: "Goluri",
	"Own Goals": "Autogol",
	"Goals by Head": "Goluri cu capul",
	"Goals by Penalty": "Goluri din penalty",
	"Yellow Cards": "Cartonase Galbene",
	"Red Cards": "Cartonase Rosii",
	"Yellow/Red Cards": "Cartonase Galbene/Rosii",
	"Shots On Target": "Suturi pe poarta",
	"Shots Off Target": "Suturi pe langa poarta",
	Assists: "Pase de gol",
	Points: "Puncte",
	"Top Scorers": "Top Marcatori",
	"Top Penalty Cards": "Top Cartonase",
	"Top Shots": "Top Suturi",
	"Top Other Details": "Alte Detalii",
	"Information is not available right now": "Nu sunt informatii disponibile in acest moment",
	"Matches day": "Meciul Zilei",
	Day: "Zi",
	Offer: "Oferta",
	Ranking: "Clasament",
	"Player Stats": "Statistici jucatori",
	Results: "Rezultate",
	"Important Players": "Jucatori Importanti",
	Odds: "Cote",
	H2H: "H2H",
	Teams: "Echipe",
	"The match doesn't exists": "Meciul nu exista",
	"[Today]": "[Today]",
	"[Tomorrow]": "[Tomorrow]",
	"[Yesterday]": "[Yesterday]",
	"There are no tickets": "Nu exista bilete",
	"There are no opened tickets": "Nu sunt bilete In Astepare",
	"There are no reserved tickets": "Nu exsita bilete rezervate",
	"There are no settled tickets": "Nu exista bilete decise",
	"Show More": "Mai multe",
	"There are no Winner Advantage matches": "Momentan nu sunt evenimente in Winner Avantaj",
	"There are no Winner Plus matches": "Nu sunt evenimente Winner Plus",
	"You have reached your deposit limit.": "Ti-ai atins limita de depunere.",
	"You can deposit minimum ${minVal} Lei and maximum ${maxVal} Lei":
		"Poti depune minim ${minVal} Lei si maxim ${maxVal} Lei",
	"You can deposit minimum ${minVal} Lei":
		"Poti depune minim ${minVal} Lei",
	"contact us": "Contact",
	Legal: "Legal",
	Products: "Produse",
	withdrew: "Retras",
	"Last Withdrawals": "Ultimele Retrageri",
	"Ooops!": "Ooops!",
	"Page not found.": "Pagina nu a fost gasita.",
	"Back to home page": "Inapoi Acasa",
	"Amount must be grater than ": "Suma trebuie să fie mai mare decât 0",
	"Requested amount is not available to withdraw! You can withdraw maximum ":
		"Fonduri insuficiente! Poti retrage maxim:",
	"Wrong password, try again!": "Parola greșită, încercați din nou!",
	"Wrong code, try again!": "Cod greșit, încercați din nou!",
	"Confirm exclusion": "Confirma excluderea",
	"To complete the process, ${customBoldStart}please confirm your identity below. The exclusion begins immediately after.${customBoldEnd}":
		"Pentru a continua procesul, ${customBoldStart} te rugam sa iti confirmi identitatea. Excluderea incepe imediat dupa confirmarea identitatii ${customBoldEnd}",
	"Exclusion ends:": "Excluderea se incheie: ",
	"Confirm password": "Confirma parola",
	"Confirm email code": "Confirma codul din email",
	"Confirm SMS Security Code": "Confirma codul SMS",
	"Resend SMS code": "Retrimite codul prin SMS",
	"I agree to be restricted to my online casino account for the period specified above.":
		"Sunt de acord să îmi fie restricționat accesul în contul meu pentru perioada de mai sus.",
	Loading: "Incărcare...",
	"Self-exclude now!": "Auto-excludere",
	"To complete your action, please confirm your password:":
		"Pentru a continua actiunea, confirma parola",
	"Take a short break instead": "Ia o pauza ",
	"We sent you an email with the next steps required to activate your self-exclusion.":
		"Ti-am trimis un email cu urmatorii pasi pentru activarea auto-excluderii.",
	"By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}${brandNameFull}${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.":
		"Continuand procesul, esti de acord cu termenii de mai jos in legatura cu excluderea permanenta din ${pinkBoldStart}${brandNameFull}${pinkBoldEnd} si pana la ${pinkBoldStart} In toata reteaua partenerilor ${pinkBoldEnd}.",
	"Inform yourself to full extent about this decision. You will not be able to revert it.":
		"Informeaza-te despre aceasta decizie. Aceasta actiune este ireversibila.",
	"! CANNOT BE REVERTED !": "! Nu poate fi inversata !",
	"! You will be excluded from all Play Online Network casinos !":
		"! Excluderea se aplica pe toata reteaua Play Online !",
	"! You will not regain access to your account, without exceptions !":
		"! Nu voi mai avea acces la contul tau !",
	"Identity Confirmation": "Identitate Confirmata",
	Pending: "In Asteptare",
	"There are no pending witdrawals": "Nu sunt retrageri in asteptare",
	Reserved: "Rezervate",
	"Ticket verification in progress": "Ticket verification in progress",
	"Ticket Info": "Ticket Info",
	"Verification failed": "Verificare esuata",
	"We're checking the ticket status. Please wait.":
		"We're checking the ticket status. Please wait.",
	SIMPLE: "SIMPLE",
	SYSTEM: "SYSTEM",
	Winnings: "Castiguri",
	Payout: "Payout",
	Cashbacked: "Cashbacked",
	"Ticket Code": "Ticket Code",
	Match: "Match",
	"Date/Hour": "Date/Hour",
	Bet: "Bet",
	Outcome: "Outcome",
	Odd: "Odd",
	"Event View": "Event View",
	"Search in Sports Betting": "Search in Sports Betting",
	Pariuri: "Pariuri",
	Cashback: "Cashback",
	Bonus: "Bonus",
	Start: "Start",
	Today: "Azi",
	Tomorrow: "Maine",
	Yesterday: "Ieri",
	"See details": "Vezi detalii",
	"Next ball color": "Culoarea urmatoarei bile",
	"Next ball even or odd": "Urmatoarea bila - PAR/IMPAR",
	Even: "PAR",
	"My tickets": "Biletele mele",
	Sports: "SPORT",
	"virtual games": "jocuri virtuale",
	"Placing the ticket in progress...": "Bilet in curs de plasare...",
	"Match details": "Detalii Eveniment",
	"Waiting for events...": "Se asteapta evenimente...",
	"Search bet": "Cauta pariul",
	"Placing failed": "Plasare eșuată",
	"An error occurred": "A aparut o eroare",
	"If the problem persists, please contact the Customer Support departement":
		"Daca problema persista, te rugam sa contactezi Departamentul de Suport",
	"Live tickets can only be played online":
		"Biletele cu evenimente LIVE se pot plasa doar ONLINE",
	"There are no top leagues": "Momentan nu sunt evenimente in aceasta sectiune",
	"Most played": "Cele mai pariate",
	"By Kick-off": "Dupa ora de start",
	"By league": "Dupa liga",
	"My Filtru": "Filtrul meu",
	"Event bets locked": "Pariuri inchise",
	"All Championships": "Toate ligile",
	"You haven't selected any bets": "Nu ai selectat niciun pariu",
	Balance: "Balanta",
	"Choose Stake": "Alege miza",
	"Min. Winning": "Castig minim",
	"Place ticket online": "PLASEAZA BILETUL ONLINE",
	"Automatically accept odds changed": "Accepta automat modificarile de cote",
	"Online ticket": "Bilet online",
	"Ticket in shop": "Bilet in agentie",
	"Enter now": "Pariaza acum",
	"The tournament begins": "Turneul incepe",
	"High Odds matches": "Meciuri cu cote marite",
	"Pull and come into play": "TRAGE si intra in joc",
	Cazino: "Cazinou",
	"Bets view": "Vizualizare pariuri",
	"UK layout": "Model UK",
	"Default layout": "Model Clasic",
	"Top offer": "Ligi de Top",
	Sports: "Sporturi",
	"Search teams, leagues, etc.": "Caută echipe, ligi, etc.",
	Promoted: "Promovate",
	"Check ticket code": "Verifică bilet",
	System: "Sistem",
	"You haven't selected any bets": "Nu ai selectat niciun pariu",
	Balance: "Balanța",
	"Place ticket online": "Plasează bilet online",
	"Place ticket in shop": "Plasează bilet în agenție",
	"Automatically accept odds changes": "Acceptă automat modificările de cote",
	"A ticket contain only one Winner Plus bet": "Poți paria un singur eveniment Winner Plus",
	"Odds changed - click to accept the changes":
		"Cotă schimbată – Click pentru a accepta schimbarea",
	"Unavailable event - remove": "Eveniment indisponibil - Șterge",
	"Live Betslip": "Bilet Live",
	"Pre-Match Betslip": "Bilet Pre-Meci",
	"You must accept the odd changes in order to continue":
		"Acceptă schimbările de cotă pentru a continua",
	"Live tickets can only played online": "Evenimentele live se pot paria doar ONLINE",
	"No valid odds found": "Cotă invalidă",
	"Live and Pre-match hevents cannot be mixed on the same betslip":
		"Evenimentele Live și Pre-Meci nu se pot plasa pe același bilet",
	"Default layout": "Model Clasic",
	"UK layout": "Model UK",
	"All leagues": "Ligi",
	"Search for Championships": "Caută Liga",
	"By Countries": "După Categorie",
	Leagues: "Ligi",
	"No Selection": "Nicio selecție",
	"You can follow up to 5 championships at the same time":
		"Poți urmări până la 5 ligi în același timp",
	"Your champioanships": "Ligile tale",
	"Search league": "Caută Liga",
	Confirm: "Confirmă",
	"NO BETS AVAILABLE": "Pariuri indisponibile",
	Statistics: "Statistici",
	"Event view": "Detalii Meci",
	"Most played": "Cele mai pariate",
	"By Kick-off": "După oră",
	"By league": "După Liga",
	Favorites: "Favorite",
	"Eligile Bonuses": "Bonusuri",
	"Progressive Bonus": "Bonus Progresiv",
	"No result": "Niciun rezultat",

	// Traduceri 23.05
	"Top Offer": "Ligi de Top",
	Sports: "Sporturi",
	"All Leagues": "Ligi",
	"Search teams, leagues, etc.": "Caută echipe, ligi, etc.",
	"Check ticket code": "Verifică bilet",
	SYSTEM: "SISTEM",
	"You haven't selected any bets": "Nu ai selectat niciun pariu",
	"Cota finala": "Cotă finală",
	Miza: "Miză",
	Castig: "Câştig",
	"ENTER YOUR ACCOUNT": "INTRA ÎN CONT",
	"You must select at least one system": "Trebuie să selectezi cel puţin un sistem",
	"Pre-match BetSlip": "Bilet Pre-Meci",
	"LIVE BetSlip": "Bilet LIVE",
	"Delete the bet?": "Ştergi Pariul?",
	"All the bets from the ticket will be deleted": "Toate pariurile de pe bilet vor fi şterse",
	"Are you sure?": "Eşti sigur(ă)",
	"NO": "NU",
	"YES": "DA",
	"Live tickets can only be played online.": "Biletele LIVE se plasează doar ONLINE",
	"A ticket can contain only one Winner Plus bet":
		"Un bilet poate conţine un singur eveniment Winner Plus",
	BALANCE: "BALANŢĂ",
	"You must add more bets in order to place this ticket":
		"Trebuie să mai adaugi evenimente pentru a putea plasa pariul",
	"Filtered championships": "Ligi găsite",
	Visible: "afişate",
	"You can follow up to 5 championships at the same time":
		"Poţi urmări până la 5 ligi în acelaşi timp",
	"PLACE TICKET IN SHOP": "PLASEAZĂ BILET ÎN AGENŢIE",
	"PLACE TICKET ONLINE": "PLASEAZĂ BILET ONLINE",
	"Show All": "Afişează tot",
	"No selection": "Nicio selecţie",
	"Your championships": "Ligile tale",
	"Maximum number of bets reached": "Ai atins numărul maxim de evenimente",
	SOCCER: "FOTBAL",
	"My Tickets": "Biletele Mele",
	"events...": "evenimente...",
	"See more details": "Vezi mai mult",
	FIXED: "FIX",
	Cazino: "Casino",
	SYSTEM: "SISTEM",
	"Pre-Meci": "Sport",
	//Sfarsit traduceri 23.05

	// Traduceri 24.05
	"Statistics not available.": "Statistici indisponibile.",
	"Search in Sports Betting": "Caută in pariuri sportive",
	"No events available": "Nu există evenimente",
	"Match statistics": "Statistica meciului",
	"Coming soon.": "În curând.",
	"You haven't selected any bets.": "Nu ai selectat niciun pariu.",
	bets: "pariuri",
	"in bets": "în pariuri",
	Tickets: "Bilete",
	"The bets are on": "Stare de pariuri",
	"Admin rejected ticket": "Biletul a fost respins",
	"Not enough money": "Fonduri insuficiente",

	// Traduceri 26.05
	"Top Offer": "Ligi de Top",
	Sports: "Sporturi",
	"Pre-match BetSlip": "Bilet Pre-Meci",
	"LIVE BetSlip": "Bilet LIVE",
	Visible: "afişate",
	"You can follow up to 5 championships at the same time.":
		"Poţi urmări până la 5 ligi în acelaşi timp",
	"events...": "evenimente...",
	FIXED: "FIX",
	Casino: "Casino",
	SYSTEM: "SISTEM",
	"Pre-Match": "Sport",
	"Placing the ticket in progress": "Bilet in curs de plasare",
	"MY TICKETS": "BILETELE MELE",
	"Eligible Bonuses": "Bonus",
	"Event View": "Detalii Meci",
	SIMPLE: "SIMPLU",
	OPEN: "DESCHIS",
	"Ticket Code": "Cod Bilet",
	"Ticket Info": "Informaţii Bilet",
	Match: "Eveniment",
	"Date/Hour": "Dată/Oră",
	Bet: "Pariu",
	Outcome: "Pronostic",
	Odd: "Cotă",
	Miza: "Miză",
	"Pre-match and LIVE cannot be mixed on the same betslip.":
		"Evenimentele LIVE si Pre-Meci nu se pot combina",
	"A aparut o eroare": "Mesaj:",
	"Plasare esuata": "Plasare eșuată",
	"No results": "Niciun rezultat",
	"Search Bets": "Caută Pariuri",
	"common to X events": "pentru X evenimente",
	"BET NOT AVAILABLE": "Pronostic Indisponibil",
	"There are no favorites matches": "Nu există evenimente Favorite",
	"events ongoing": "în desfășurare",
	"NO BETS AVAILABLE": "Pariuri Indisponibile",
	// Sfarsit traduceri 26.05

	// Traduceri 29.05
	"Cannot place ticket": "Biletul nu a putut fi plasat",
	Details: "Detalii",
	"Minim stake of": "Miza minimă este",
	AVAILABLE: "DISPONIBIL",
	"Insufficient Funds": "Fonduri insuficiente",
	Sports: "Sporturi",
	"Top Offer": "Top Offer",

	// Traduceri 10.06
	"Top Offer": "Ligi de Top",
	Sports: "Sporturi",
	"Pre-match BetSlip": "Bilet Pre-Meci",
	"LIVE BetSlip": "Bilet LIVE",
	Visible: "afişate",
	"You can follow up to 5 championships at the same time.":
		"Poţi urmări până la 5 ligi în acelaşi timp",
	"events...": "evenimente...",
	FIXED: "FIX",
	Casino: "Casino",
	SYSTEM: "SISTEM",
	"Pre-Match": "Sport",
	"Placing the ticket in progress": "Bilet în curs de plasare",
	"MY TICKETS": "BILETELE MELE",
	"Eligible Bonuses": "Bonus",
	"Event View": "Detalii Meci",
	SIMPLE: "SIMPLU",
	OPEN: "DESCHIS",
	"Ticket Code": "Cod Bilet",
	"Ticket Info": "Informaţii Bilet",
	Match: "Eveniment",
	"Date/Hour": "Dată/Oră",
	Bet: "Pariu",
	Outcome: "Pronostic",
	Odd: "Cotă",
	Miza: "Miză",
	"Pre-match and LIVE cannot be mixed on the same betslip.":
		"Evenimentele LIVE și Pre-Meci nu se pot combina",
	"A aparut o eroare": "Mesaj",
	"If the problem persists, please contact the Customer Support department.":
		"Pentru mai multe detalii, contacteaza Serviciul Clienti.",
	"Plasare esuata": "Plasare eșuată",
	"No results": "Niciun rezultat",
	"Search Bets": "Caută Pariuri",
	"common to X events": "pentru X evenimente",
	"BET NOT AVAILABLE": "Pronostic Indisponibil",
	"There are no favorites matches": "Nu există evenimente Favorite",
	"events ongoing": "în desfășurare",
	"NO BETS AVAILABLE": "Pariuri Indisponibile",
	"Ticket reoffered": "Bilet Modificat",
	"The ticket was reoffered. Please check the bet slip for changes":
		"Biletul tău a fost modificat. Te rugăm să îl verifici înainte de a-l plasa din nou.",
	"An error occurred": "Mesaj",
	"Placing failed": "Plasare eșuată",
	"Possible Winning": "Câștig Potențial",
	"Available Bonus": "Bonus Disponibil",
	LOST: "NECÂȘTIGĂTOR",
	"PAID OUT": "PLĂTIT",
	WON: "CĂȘTIGĂTOR",
	Cashbacked: "Câștig bonus",
	"Ticket not found": "Bilet inexistent",
	// Sfarsit trauceri 10.06

	//Traduceri 16/06
	RECOVER: "RECUPEREAZĂ",
	"Place the same ticket without losing matches":
		"Plasează acelaşi bilet fară evenimentele pierdute",
	WON: "CÂŞTIGĂTOR",
	"Verification failed": "Verificare eşuată",
	"An error occurred": "A apărut o eroare",
	REGISTER: "CONT NOU",
	"Ticket verification in progress": "Verificare bilet în curs",
	"We're checking the ticket status. Please wait.": "Se caută biletul. Te rugăm să aştepţi.",
	Winnings: "Câştig",
	Payout: "Sumă plătită",
	//Sfarsit traduceri 16/06

	// Traduceri 19.06 - bets classic / nsoft clone
	All: "Toate",
	Days: "Zile",
	Today: "Azi",
	Soon: "In curand",
	Sports: "Sport",
	"Ticket verification": "Verificare bilet",
	Verify: "Verifica",
	"Prematch BetSlip": "Bilet Pre-Meci",
	Simple: "Simplu",
	"TOTAL ODDS": "COTA FINALA",
	"Total stake": "Miză Totală",
	"POSSIBLE WINNING": "CASTIG POSIBIL",
	"PAYIN TAX": "T.A.",
	"Number of combinations": "Numar de combinatii",
	"Ticket Info": "Info Bilet",
	SIMPLE: "SIMPLU",
	"Ticket Code": "Serie Bilet",
	Match: "Meci",
	"Date/Hour": "Data/Ora",
	Bet: "Pariu",
	Outcome: "Pronostic",
	Odd: "Cota",
	Stake: "Suma pariata",
	"Possible Winning": "Castig Posibil",
	"PAID OUT": "PLATIT",
	Winnings: "Castig",
	Payout: "Suma Platita",
	"Search Bet": "Cauta Pariu",
	Ticket: "Biletul",
	"was copied to": "a fost copiat în",
	"Ticket Verification": "Verificare bilet",
	"Type in bellow your ticket code": "Introdu seria biletului",
	"ticket code": "Serie bilet",
	"Search Ticket": "Cauta Bilet",
	LOST: "PIERDUT",
	WON: "CASTIGĂTOR",
	SYSTEM: "SISTEM",
	Overview: "Lista evenimente",
	"Event view": "Detalii Meci",
	"LIVE BetSlip": "Bilet LIVE",
	"Odds changed-Click to accept the changes": "Cota modificata-Apasa pentru a accepta",
	"Unavailable event-Remove": "Eveniment indisponibil-Sterge",
	No: "Nu",
	Yes: "Da",
	"Currently there are no bets available for this match! Stay tuned":
		"Nu sunt pronosticuri disponibile in acest moment.",
	"Placing failed": "Plasare esuata",
	"An error occurred": "Mesajul",
	"Your ticket will be placed in a few moments. Please wait.":
		"Biletul tau o sa fie plasat in cateva momente. Te rugam sa astepti.",
	Success: "Succes",
	'The ticket was placed successfully and you can find it in the "My Tickets" section.':
		'Biletul a fost plasat cu succes si il poti regasi in sectiunea "Biletele Mele".',
	"The ticket was placed successfully.": "Bilet plasat cu succes.",
	BetSlip: "Bilet",
	Mo: "LU",
	Tu: "MA",
	We: "MI",
	Th: "JO",
	Fr: "VI",
	Sa: "SA",
	Su: "DU",
	"Soon (+3h)": "În curând (+3ore)",
	"Place automatically without unavailable events": "Plaseaza automat fara evenimentele indisponibile.",
	// Sfarsit Traduceri 19.06

	// Traduceri 26.06
	"The ticket is empty. Click on odds in order to bet": "Nu ai selectat niciun pariu.",
	"Payin Tax": "T.A.",
	"Ticket verification": "Verifică bilet",
	Search: "Caută",
	Soon: "În Curând",
	"3 days": "3 Zile",
	"Maximum number of bets reached": "Ai atins numărul maxim de evenimente",
	"Pre-match BetSlip": "Bilet Pre-meci",
	BeSlip: "Bilet",
	"LIVE BetSlip": "Bilet LIVE",
	"Total Stake": "Sumă",
	"POSSIBLE WINNING": "Câştig Posibil",
	"Sign in": "Conectare",
	"Go to new Winner website": "Accesează site-ul NOU",
	"Currently there are no bets available for this match! Stay tuned.":
		"Nu sunt pariuri disponibile în acest moment.",
	"See other bets.": "Vezi alte pariuri",
	"Search Bet": "Caută Pariu",
	More: "Mai mult",
	"Bonuses of the Day": "Bonusurile Zilei",
	Sports: "Sporturi",
	"Pre-match and LIVE events cannot be mixed on the same betslip.":
		"Evenimentele LIVE şi Pre-Meci nu se pot combina",
	"Maximum number of bets reached": "Numar maxim de evenimente atins",
	"Sort matches by time": "Sortare evenimente după Timp",
	"Sort matches by tournament": "Sorteaza evenimente după Liga",
	CODE: "COD",
	Tomorrow: "Mâine",
	// Sfarsit Traduceri 26.06

	"Invalid ticket code": "Cod bilet incorect",
	"Use old site": "Vechiul site este disponibil aici",
	"Keep Ticket": "Păstrează Bilet",
	"New Ticket": "Bilet Nou",
	"No camera present or no permission to use the camera. Please check your browser settings.":
		"Nu este prezentă nicio cameră sau nu sunt permisiuni pentru a folosi camera. Va rugăm sa verificați setările browserului.",
	"Old site": "Site vechi",
	"markets available": "pariuri disponibile",
	"Maximum number of lines exceeded (200,000)":
		"Numărul maxim de combinații permise depășit (200,000)",

	// Traduceri 13.07
	"This bet is not available for this match!": "Acest pariu nu mai este disponibil",

	"There are no tickets": "Nu există bilete",
	"There are no opened tickets": "Nu sunt bilete în aşteptare",
	"There are no reserved tickets": "Nu sunt bilete rezervate",
	"There are no settled tickets": "Nu sunt bilete decise",

	"virtual games": "jocuri virtuale",
	"Virtual Games": "Jocuri Virtuale",
	virtuals: "virtuale",
	Virtuals: "Virtuale",
	"top withdrawals": "TOP Retrageri",
	"withdrawn by": "retras de",

	"Maximum number of lines exceeded (200,000)": "Numărul maxim de combinații permise depășit (200,000)",
	"Attention: Withholding taxes for withdrawals": "Atentie: Impozit pe retragere",
	"State tax": "Impozit stat",
	"Taking into consideration the amendments to the Fiscal Code recently adopted, according to the Government Emergency Ordinance no. 18/2018, ": "Potrivit modificărilor Codului Fiscal, recent adoptate conform Ordonanței de Urgență a Guvernului nr. 18/2018, ",
	"regarding the implementation of fiscal-budgetary measures for amending and supplementing some normative acts, Play Online Solutions LTD is obligated by the law to return and transfer, on behalf of the participants in online gambling, the taxes related to the income made by individuals as a result of this activity, respectively ": "privind adoptarea unor măsuri fiscal-bugetare și pentru modificarea și completarea unor acte normative, POS are obligația de a reține și a vira, în numele participanților la jocurile de noroc on-line, impozitul aferent veniturilor realizate de persoanele fizice ca urmare a participării la aceste activități, respectiv ",
	"1% of each withdrawal up to 66,750 lei.": "1% din fiecare retragere având valoarea de până la 66.750 Lei.",
	"Tax and fees information": "Informatii taxe si impozite",
	"Withdraw later": "Retrag mai tarziu",
	"Pay the fee and continue": "Platesc taxa si continui",
	"withdrawn by": "retras de",
	"CDA 2% (GEO 114)": "CDA 2% (conform OUG 114)",
	"calculating...": "se calculeaza...",
	"Net deposit": "Depunere neta",

	"There are no matches for ": "Nu există meciuri pentru ",
	"There are no matches for this sport": "Nu există meciuri pentru acest sport",
	"Currently there are no matches for selected parameters!": "Nu au fost găsite meciuri pentru parametrii selectați",
	"This bet is not available for this match!": "Acest pariu nu mai este disponibil!",
	"Currently there are no bets available for this match! Stay tuned.": "În acest moment nu sunt pariuri disponibile",

	"RECEIVE 1500 LEI BONUS!": "IA 1500 LEI BONUS",
	"TAKE 1,500 LEI BONUS": "IA 1,500 LEI BONUS",

	"Winnings Volcano!": "Erupe cu premii!",
	"Volcano Wealth + 6 Reels on Burning Hot and 40 Burning Hot": "Volcano Wealth + 6 role la Burning Hot si 40 Burning Hot",
	"Have a spin at them": "Încearcă acum noile jocuri",
	"PLAY NOW": "JOACĂ ACUM",
	"See all": "Toate",
	"Collection Unavailable": "Colectie indisponibila",
	"The game collection you are looking for is temporarily unavailable. Please try again later.": "Colectia de jocuri pe care o cautati este temporar indisponibila. Va rugam sa incercati mai tarziu.",
	"BACK TO THE CASINO": "JOCURI DE CAZINOU",

	// Traducere modulul Happy Hour
	"NEXT_EVENT": "Următorul",
	"Missed prizes": "Premii ratate",
	"Prizes won": "Premii câştigate",
	"finalized": "finalizat",
	"You won": "Ai câştigat",
	"You won prize": "Ai câştigat premiul",
	"A player": "Un jucător",
	"has won prize": "a câştigat premiul",
	"Minimum Bet": "Pariu minim",
	"Minimum bet for": "Pariu minim pentru",
	"Deployment interval": "Interval desfăşurare",
	"Total prizes": "Premii totale",
	"Events in progress": "Evenimente în curs",
	"Enter to play": "Joacă acum!",
	"If you did not receive the SMS code, you can request another one in ": "Dacă nu ai primit codul SMS, poți solicita unul nou în ",
	"seconds.": "secunde.",
	"Product Balance": "Balanţă produse",
	"Account Balance": "Sold Cont",
	"Available": "Disponibil",
	"Reserved": "Rezervat",
	"Bonus": "Bonus",
	"Deposit": "Depunere",
	"Balance per Product": "Sold disponibil în fiecare zonă de joc",
	"Sportsbook": "Pariuri",
	"Casino LIVE": "Cazino LIVE",
	"Virtuals": "Virtuale",
	"Lotto": "Loto",
	"No bonus": "Fără bonus",
	"Coming Soon": "În curând",
	"Pending Transactions": "Tranzacţii în aşteptare",
	"Withdrawls": "Retrageri",
	"Deposits": "Depuneri",
	"Bonus requirements:": "Cerinţe Bonus:",
	"Minimum deposit:": "Depunere minimă:",
	"Show verification code": 'Arata cod de verificare',
	"Time left:": "Timp ramas:",
	"Deposit without bonus": "Depune fără bonus",
	"Change bonus": "Schimbă bonus",
	"Unlimited": 'Nelimitat',
	"SPECIAL PRIZE": "PREMIU SPECIAL",
	"Special prize": "Premiu special",
	"TERMS AND CONDITIONS": "TERMENI ŞI CONDIŢII",
	"I understand": "Am inţeles",
	"Rules for participation": "Reguli de participare",
	"No minimum stake for the prize": "Fără miza minimă pentru premiul",
	"Everyone participates.": "Toată lumea participa.",
	"When the prize is active, any spin can determine the winner. Watch out!": "Când premiul este activ, orice spin poate determina caştigătorul. Fii pe fază!",

	// lotto translation texts
	"Done": "Terminat",
	"Registering": "Înregistrare",
	"Manual Authorization": "Autorizare Manuală",
	"To late to place ticket for this event": "Prea târziu pentru a mai plasa un bilet pentru acest eveniment",
	"Registered successfully": "Înregistrat cu succes",
	"Failed to register": "Nu s-au înregistrat",
	"ticket": "bilet",
	"tickets": "bilete",
	"TICKETS": "BILETE",
	"Latest draws:": "Ultimile extrageri:",
	"See draws history": "Vezi istoric extrageri",
	"I want to play the next draws:": "Vreau sa joc la următoarele extrageri:",
	"Play more draws": "Joacă mai multe extrageri",
	"Favorites": "Favorite",
	"Size Table": "Marime tabla",
	"Random": "La noroc",
	"Lottery Draws": "Extrageri Loterie",
	"draws": "extrageri",
	"PLACE TICKETS": "PLASEAZĂ BILETE",
	"another": "incă",
	"Max. Winning per Ticket": "Câştig Max. per Bilet",
	"Event doesn't exist": "Nu există evenimentul",
	"There are no details about this event": "Nu există detalii despre acest eveniment",
	"WITHOUT FAVORITE NUMBERS": "FĂRĂ NUMERE FAVORITE",
	"HOURS": "ORE",
	"MIN": "MIN",
	"SEC": "SEC",
	"The numbers have been added to favorites": "Numerele au fost adaugate la favorite",
	"FAST BETTING": "PARIURI RAPIDE",
	"All Lotteries": "Toate Loteriile",
	"Next Draws": "Urmatoarele Extrageri",
	"Favorite Numbers": "Numere Favorite",
	"There are no favorite numbers for this lottery": "Nu există numere favorite pentru această extragere",
	"Next ticket in": "Următorul bilet în",
	"Search Lottery Systems": "Caută Sisteme Loterii",
	"search lottery systems": "caută sisteme loterii",
	"Registered": "Înregistrat",
	"Drawing begins in:": "Extragerea începe în",
	"Next draw at": "Următoarea extragere la",
	"in": "în",
	"Drawing": "Se extrage",
	"Draw": "Extragere",
	"Reserved Code": "Cod Rezervat",
	"lost": "necâştigător",
	"paid": "plătit",
	"won": "câştigător",
	"open": "deschis",
	"unknow": "necunoscut",
	"You haven't selected any lottery numbers": "Nu ați selectat niciun număr de loterie",
	"Ticket Status": "Status Bilet",
	"Hour": "Ora",
	"Lucky Number": "Bila Norocoasă",
	"Lotto Chance": "Loto Şansă",
	"Good luck!": "Mult noroc!",
	"How it works?": "Cum functioneaza?",
	"Any of the numbers played can be Lucky Number. Choose your lucky number.": "Oricare dintre numerele jucate poate fi Lucky Number. Alege numarul tau norocos.",
	"If you lose the ticket, but your lucky number is extra, you win a Lucky Bet of 1 Leu value.": "Daca pierzi biletul, dar numarul tau norocos este extras, ai castigat un Lucky Bet de 1 Leu.",
	"If you lose the ticket, but your lucky number is extra, you win a {{FREE_BET_NAME}} of {{FREE_BET_COUNT}} x {{FREE_BET_VALUE}} Leu value.": "Daca pierzi biletul, dar numarul tau norocos este extras, ai castigat un {{FREE_BET_NAME}} de {{FREE_BET_COUNT}} x {{FREE_BET_VALUE}} Leu.",
	"Next draw:": "Urmatoarea extragere:",
	"historic": "istoric",
	"Created At": "Create La",
	"Serial": "Serial",
	"Status": "Stare",
	"Event Name": "Nume Eveniment",
	"Event Time": "Data Extragere",
	"Selected Numbers": "Numere Selectate",
	"Gross Winning Amount": "Castig Brut",
	"Event Date": "Data Eveniment",
	"Tokens": "Token-uri",
	"Place the same ticket for the next available event": "Plasati acelasi bilet pentru urmatorul eveniment disponibil",
	"Place again": "Plaseaza din nou",
	"You guess and you win a {{FREE_BET_NAME}}": "Ghicesti si castigi {{FREE_BET_NAME}}",
	"Search events etc.": "Cauta evenimente etc.",
	"Check ticket code": "Verifica bilet",
	"Placing partially failed": "Plasare eşuată parţial",
	"Hide": "Ascunde",
	"Show": "Afişează",
	"draw": "extragere",
	"Delete Ticket?": "Ştergi Biletul?",
	"The ticket selection will be deleted.": "Toate pariurile de pe bilet vor fi şterse.",
	"Are you sure?": "Eşti sigur(ă)?",
	"YES": "DA",
	"NO": "NU",
	"There are no open tickets associated with this lottery event": "Nu există bilete asociate cu această extragere",

	// maintenance
	"Pause for improvements": "Pauza de îmbunătăţiri",
	"Do you want to know immediately what you can play?": "Vrei sa afli imediat ce poţi juca?",
	"We apologize for this situation and we are working to fix the problem as soon as possible.": "Ne cerem scuze pentru această situaţie şi lucrăm să remediem problema în cel mai scurt timp.",
	"I WANT to be notified": "VREAU sa fiu anunţat",
	"I DON'T WANT to be notified": "NU VREAU sa fiu anunţat",
	"The website is currently unavailable.": "Website-ul nu este disponibil momentant.",
	"Please upgrade to the latest version of the application.": "Vă rugăm să faceți upgrade la cea mai recentă versiune a aplicației.",
	"The section is currently unavailable.": "Secțiunea nu este disponibilă momentan.",
	'"LIVE Betting" events are currently unavailable.': 'Evenimentele de "Pariuri LIVE" sunt momentan indisponibile.',
	'"Prematch Betting" events are currently unavailable.': 'Evenimentele de "Pariuri Prematch" sunt momentan indisponibile.',
	'"Casino" games are currently unavailable.': 'Jocurile "Casino" nu sunt disponibile momentan.',
	'"Casino LIVE" games are currently unavailable.': 'Jocurile "Casino LIVE" nu sunt disponibile momentan.',
	'"Lotto" events are currently unavailable.': 'Evenimentele de "Loto" sunt momentan indisponibile',
	'"Virtual" games are currently unavailable.': 'Jocurile "Virtuale" nu sunt disponibile momentan.',
	"This game is currently unavailable.": "Acest joc nu este disponibil momentan.",
	'"{{PROVIDER}}" games are currently unavailable.': 'Jocurile "{{PROVIDER}}" nu sunt disponibile momentan.',
	'"{{NAME}}" is temporarily unavailable': '"{{NAME}}" este temporar indisponibil',
	'Game temporarily unavailable': 'Joc temporar indisponibil',
	"Do you want to know immediately when you can play?": "Vrei sa afli imediat cand poti juca?",
	"maintenanceModalContent": 'Fii <strong>primul care reintra</strong> in joc! Iti vom trimite o notificare imediat ce <strong>{{name}}</strong> devine disponibil.',
	"Your casino is getting better": "Cazinoul dvs. se îmbunătățește",
	"We'll be back online soon!": "Ne vom întoarce online în curând!",
	"THANK YOU FOR YOUR PATIENCE!	YOU WILL RETURN TO AN UPGRADED SITE.": "VĂ MULȚUMIM PENTRU RĂBDAREA DVS! VEȚI REVENI LA UN SITE ACTUALIZAT.",

	"GET BONUS": "OBTINE BONUS",
	"Change": "Schimba",

	// cashout
	"Confirm cashout for amount": "Confirma cashout pentru suma",
	"Confirm cashout for new amount": "Confirma cashout pentru noua suma",
	"There has been an error": "A aparut o eroare",
	"If the problem persists, please contact the Customer Service department.": "Daca problema persisita, va rugam sa va adresati departementului de Servicii Clienti.",
	"You have recovered {{AMOUNT}} {{CURRENCY}} by Cash-out. The amount has been added to your account.": "Ai recuperat {{AMOUNT}} {{CURRENCY}} prin Cash-out. Suma a fost adaugata in contul tau.",
	"ticket_not_found": "Nu exista biletul",
	"cashout_unavailable": "Nu exista cashout",
	"cashout_amount_changed": "S-a schimbat valoarea cashout",
	"ticket_cashout_rejected": "Cashout respins",
	"Cashout - amount modified": "Cashout - suma modificata",
	"Cashout available": "Cashout disponibil",
	"Cashout not available": "Cashout indisponibil momentan",

	"Event details": "Detalii eveniment",
	"The event you are looking for has expired or is no longer available": "Acest eveniment a expirat sau este indisponibil",

	"LIVE Betting": "Pariuri LIVE",

	// loto
	"luckyBetWarning1": "Minim 3 numere pentru a folosi <1>Lucky Bet/Free Bet</1>",
	"luckyBetWarning2": "Ai nevoie de un bilet simplu pentru <1>Lucky Bet/Free Bet</1>",
	"luckyNumberWarning1": "Minim 3 numere pentru a debloca <1>Bila Norocoasa</1>",
	"luckyNumberWarning2": "Ai nevoie de un bilet simplu pentru <1>Bila Norocoasa</1>",
	"Pick 3 numbers to unlock {{FREE_BET_NAME}}": "Minim 3 numere pentru {{FREE_BET_NAME}}",

	// Winner Fun
	"Winners Daily": "Winners Zilnic",
	"Winners at 3 hours": "Winners la 3 ore",
	"Enter and collect daily Winners for free. Three consecutive days rewards you extra.": "Intra si colecteaza zilnic Winners gratis. Trei zile consecutive te premiaza extra.",
	"wf.day": "Ziua",
	"wf.days": "zile",
	"NEXT IN": "URMATORUL IN",
	"Calendar": "Calendar",
	"See calendar": "Vezi calendar",
	"DETAILS": "DETALII",
	"Time remaining": "Timp ramas",
	"Available in": "Disponibil in",
	"Closed": "Inchis",
	"Freebies": "Gratuitati",
	"Score": "Clasament",
	"Raffle": "Tombola",
	"Missions": "Misiuni",
	"Calendar of Events": "Calendarul Evenimentelor",
	"RANKING": "CLASAMENT",
	"AWARDS": "PREMII",
	"REGULATIONS": "REGULAMENT",
	"POS": "LOC",
	"PLAYER": "JUCATOR",
	"AWARD": "PREMIU",
	"TOTAL WIN": "CATIG TOTAL",
	"A new season has started!": "A inceput un nou sezon!",
	"Play for free and win real money": "Joci gratis si castigi bani reali",
	"Season ended": "Sezon incheiat",
	"Your Raffle Tickets": "Biletele tale de Tombola",
	"In the ballot box": "In urna",
	"You've collected {{AMOUNT}} WinCoins": "Ai colectat {{AMOUNT}} WinCoins",
	"Choose a public name": "Alege-ti un nume public",
	"Anyone can see this name. It takes up to 48 hours until we approve it and it can become public.": "Oricine poate vedea acest nume. Dureaza pana la 48h pana il vom aproba si devine public.",
	"Nickname not approved yet.": "Numele public ales nu este inca aprobat.",
	"YOUR TEMPORARY NAME": "NUMELE TAU TEMPORAR",
	"Public name": "Nume public",
	"I'll change it later": "Il schimb mai tarziu",
	"Collect WinCoins daily for free.": "Colecteaza zilnic WinCoins gratis.",
	"POINTS": "PUNCTE",
	"COLLECT": "COLECTEAZA",
	"The bag is full!": "S-a umplut punga!",
	"Empty the bag in time to make room for other automatically gathering WinCoins.": "Goleste punga la timp pentru a face loc altor WinCoins care se strang automat.",
	"COMING SOON": "IN CURAND",
	"Collect": "Colecteaza",
	"MAIN BALANCE": "BALANTA PRINCIPALA",
	"ranking pts.": "pct. clasament",
	"Thank you": "Multumesc",
	"Your balance": "Balanta ta",
	"Season": "Sezon",
	"SEASON": "SEZON",
	"Ticket is not eligible for event, not enough wincoins in main balance": "Biletul nu este eligibil pentru eveniment. Nu sunt destui wincoins in balanta principala",
	"Insufficient balance": "Balanta insuficienta",
	"EVENT": "EVENIMENT",
	"Points": "Puncte",
	"win multiplier": "multiplicator castig",
	"BALANCE": "BALANȚĂ",
	"Tournament": "Turneu",
	"Competition": "Competitie",
	"COMPETITION": "COMPETITIE",
	"The public name already exists. You may choose: {{SUGGEST}}": "Numele ales exista deja. Poti alege: {{SUGGEST}}",
	"Invite a friend to join the game and receive WinCoins": "Invita un prieten sa intre in joc si primesti WinCoins",
	"CLOSE": "INCHIDE",
	"BEGIN": "INCEPE",
	"OPEN ": "DESCHIDE ",
	"Send this link to your friends": "Trimite acest link prietenilor tai",
	"Empty": "Liber",
	"Friends": "Prieteni in echipa",
	"See below when they made an account": "Vezi mai jos cand si-au facut cont",
	"Collect and Play": "Colecteaza si Joaca",
	"The active events have their own balance. Here you'll find all the winnings and all the WinCoins collected.": "Sezoanele si competitiile active au propria balanta. Aici se duc WinCoins colectate pe durata evenimentului și castigurile obtinute de pe urma utilizarii acestor fonduri.",
	"Nothing is lost! At the end of the competition, the WinCoins are moved to the main balance.": "Nu se pierde nimic! La finalul competitiei, WinCoins-urile cumulate se varsa in balanta principala.",
	"We do the calculations. You enjoy the game!": "Facem noi calculele. Tu bucura-te de joc!",
	"For events highlighted with the competition symbol, your winning is multiplied by the value displayed on the ticket.": "Pentru evenimentele evidentiate cu simbolul competitiei, castigul tau se multiplica cu valoarea afisata in bilet.",
	"Higher odds bring faster progress! Good luck!": "Cotele mai mari aduc progrese mai rapide! Succes!",
	"Invite a friend with the link below": "Invita un prieten cu link-ul de mai jos",
	"Copy and send this link on Whatsapp, Facebook, Instagram, Messenger or by mail": "Copiaza si trimite acest link pe Whatsapp, Facebook, Instagram, Messenger sau prin posta",
	"Good luck": "Mult noroc",
	"Invite": "Invita",
	"CURRENT": "CURENT",
	"HISTORIC": "ISTORIC",
	"MY AWARDS": "PREMIILE MELE",
	"A new tournament has started!": "A inceput un nou turneu!",
	"The top ranked players win real money.": "Primii jucatori clasati castiga bani reali.",
	"Tournament ended": "Turneul s-a terminat",
	"Congratulations and good luck in the future!": "Felicitari si mult noroc pe viitor!",
	"The ranking will be completed soon. See if you won any awards by navigating to": "Clasamentul se va finaliza in curand. Vezi daca ai castigat premii in",
	"History": "Istoric",
	"BOOST READY!": "BOOST reincarcat!",
	"Collect the boost in order to start recharging the next one.": "Colecteaza booster-ul pentru a porni reincarcarea urmatorului.",
	"Use Boost to place tickets to make room for the next ones.": "Foloseste Boost la plasarea biletelor pentru a face loc la urmatoarele.",
	"Win more": "Castigi mai mult",
	"Tickets that qualify for \"{{EVENT_NAME}}\" can benefit from increased Winner BOOST winnings!": "Biletele care se califica pentru \"{{EVENT_NAME}}\" pot beneficia de castiguri marite Winner BOOST!",
	"Collect Boosters to use when you see fit.": "Colecteaza Boostere pentru a le folosi cand crezi de cuviinta.",
	"The payout multiplier increases in proportion to the odds of the ticket. Higher odds bring even bigger gains!": "Multiplicatorul de castig creste proportional cu cota biletului. Cotele mai mari aduc castiguri si mai mari!",
	"Winner Boost: cannot be applied to some events on the ticket": "Winner Boost: nu se poate aplica unor evenimente de pe bilet",
	"Winner Boost: the minimum odds for the multiplier is {{MIN_ODD}}": "Winner Boost: cota minima pentru multiplicator este {{MIN_ODD}}",
	"BOOST Odds": "Cota BOOST",
	"win BOOST": "castig BOOST",
	"You've already used Boost on the event: {{EVENT_NAME}}": "Ai folosit deja Boost pe evenimentul: {{EVENT_NAME}}",
	"STARTING IN": "INCEPE IN",
	"Collect Winner BOOST and use them on your betslip to INCREASE the WIN. Begin collecting as soon as the next FUN event begins.": "Mareste-ti CASTIGUL de pe bilet cu Winner BOOST. Incepe sa colectezi imediat ce incepe urmatorul eveniment FUN din sezonul curent.",
	"SEE YOUR TEAM": "VEZI ECHIPA",

	// Evolution
	"All": "Toate",
	"Other": "Altele",

	// VIVA
	"Use the old method": "Depune prin metoda veche",
	"New card": "Card nou",
	"Payment Details": "Detalii de plată",
	"Proceeding will imply the acceptance of secure storing of your card data.": "Continuarea presupune acceptul stocării securizate a datelor cardului dumneavoastră.",
	"CARD DETAILS": "DETALII CARD",
	"Name as shown of the card": "Numele așa cum apare pe card",
	"Card number": "Număr card",
	"MM/YY": "LL/AA",
	"Deposited amount": "Suma depusă",
	"Deposit Bonus": "Bonus la depunere",
	"Simple and convenient. Complete your transaction just with the CVV.": "Simplu și convenabil. Finalizezi tranzacția doar cu CVV-ul.",
	"CVV CODE": "COD CVV",
	"The 3 or 4 digit code on the back of the card.": "Codul de 3 sau 4 cifre de pe spatele card-ului.",
	"Old Method": "Metoda Veche",
	"Old method": "Metoda veche",
	"AMOUNT CONFIRM": "CONFIRMARE",
	"Simple and convenient. Complete your transaction just with the amount.": "Simplu si convenabil. Finalizeaza tranzactia acum.",
	"Something went wrong. Please verify the card data.": "A aparut o eroare. Verificati daca datele cardului au fost introduse corect.",
	"Cash-in": "Retrage",
	"Payment method": "Metodă de plată",
	// statscore stats prematch
	"Wide": "Global",
	"League": "Competitie",
	"Away": "In deplasare",
	"Top Scorers": "Golgheteri",
	"Form": "Forma",
	"Overall": "Per total",
	"Assists": "Asistari",
	"Cards": "Cartonase",
	"Halftime": "Pauza",
	"ATP Ranking": "ATP",
	"WTA Ranking": "WTA",
	"ATP Doubles": "Dublu ATP",
	"WTA Doubles": "Dublu WTA",
	"Standings": "Clasamente",
	"Money": "Bani",

	// Excelbet
	"excelbet_betting_menu": "Pariuri",
	"excelbet_slots_menu": "Cazinou",
	"excelbet_live_casino": "Cazinou Live",
	"excelbet_locations": "Pariaza in agentii",

	"Olympics": "Olimpiada",
	"Choose Bonus": "Alege bonus",
	"The ticket number is not valid": "Numarul biletului nu este valid",

	//PO-4186
	"Minimum withdrawal:": "Retragere minima:",

	/* PO-4505: winner fun teams */
	"Place automatically without unavailable events": "Plaseaza automat fara evenimente indisponibile",

	/* winner fun teams */
	"Enter in your favorite team!": "Intra in echipa favorita!",
	"Support your favorite and help her win the title! Choose your team from the list below.": "Fa galerie preferatei tale si ajut-o sa castige titlul! Alege-ti echipa din lista de mai jos.",
	"Team ranking": "Clasament echipe",
	"Support your favorite team": "Sustine-ti echipa favorita",
	"Top {{TOP}} best players on this team": "Top {{TOP}} cei mai buni jucatori din aceasta echipa",
	"The team receives one star for each season won.": "Echipa primeste o steluta pentru fiecare sezon castigat. ",
	"PLACE": "LOCUL",
	"Find more about": "Afla mai multe despre",
	"You can change the team until the first ticket of a season is placed. After placing a ticket, it is no longer allowed to change the team until the end of the season.": "Poti schimba echipa pana la plasarea primului bilet dintr-un sezon. Dupa plasarea unui bilet, nu mai este permisa schimbarea echipei pana la finalizarea sezonului.",
	"LEAVE TEAM": "PARASESTE ECHIPA",
	"Join the group of team fans:": "Intri in grupul suporterilor echipei:",
	"No players": "Fara jucatori",
	"TEAM": "ECHIPA",
	"This email address is not registered": "Aceasta adresa de email nu este inregistrata",
	"Bet in location": "Pariati in locatii",
	"Resend verification email": "Retrimitere e-mail de verificare",
	"Not Verified": "Neverificat",
	"Verified": "Verificat",

	"To perform a complete search please select the \"All\" option from the menu.": "Pentru a efectua o căutare completă vă rugăm selectați opțiunea \"Toate\" din meniu.",

	"Lost": "Necastigator",
	"Paid": "Platit",
	"Won": "Castigator",
	"Open": "Deschis",

	"common to {{COUNT}} events": "comun la {{COUNT}} evenimente",

	// po4635
	'Bonus for you': 'Bonus pentru tine',
	//po4638
	"${bonusName} has been successfully been awarded to your account! GOOD LUCK!": "${bonusName} a fost adaugat cu succes in contul tau. MULT NOROC!",
	//po4647
	"Bonus conflict": "Bonus-uri in conflict",
	"The requested bonus cannot be active at the same time with the bonuses currently active in your account.": "Bonus-ul cerut nu poate fi activ in acelasi timp cu cele existente in contul tau.",

	"We apologize for this inconvenience. We have run into a problem and the bonus has not been added to your account. Please try again later. If the issue continues, please contact the Customer Support.":
		"Ne cerem scuze pentru neplacere. Bonusul nu a putut fi adaugat in contul de jucător. Vă rugăm să încercați mai tarziu. Dacă problema persistă, contactați serviciul de suport clienți.",
	"CLAIM NOW": "ACTIVEAZA",
	"LET'S PLAY": "HAI LA JOC",
	"Your bonus": "Bonusul tau",
	"Later": "Mai tarziu",

	"Place ticket with": "Plasare miza",

	"How and where you can use it": "Cum si unde il poti folosi",

	"Spins": "Rotiri",
	"Wager the active bonuses first": "Necesita rulajul bonusului din cont",
	"Requires email verification": "Necesita verificarea email-ului",
	"Requires identity verification": "Necesita verificarea identitatii",
	"Temporarily unavailable": "Motiv indisponibil temporar",
	"Refresh": "Reincarca",
	"Invalid code. Please verify the value": "Cod invalid. Verificati inca o data valoarea introdusa.",
	"This code has reached its maximum number of activations": "Acest cod nu mai permite activari",
	"An error has occurred. Please try again later.": "A aparut o eroare. Va rugam sa incercati mai tarziu.",
	"Got a voucher code?": "Ai un cod de voucher?",
	"Enter code": "Introdu cod",
	"Enter the code to claim your prize": "Introdu codul si vezi ce ai castigat",
	"The claimed prize is available in 'My Bonuses'": "Premiul revendicat este disponibil in 'Bonusurile mele'",
	"You have already participated in this voucher campaign.": "Ati participat deja in aceasta campanie promotionala.",
	"VOUCHER CODE": "COD PROMOTIONAL",

	"ONGOING": "IN CURS",
	"Group by ticket": "Grupeaza dupa bilet",
	"There are no matches in progres": "Nu exista meciuri in desfasurare",
	"Recently played": "Jucate recent",

	/* PO-4039 - Lobby UI V2 */
	"Results for: \"{{QUERY}}\"": "Rezultate pentru: \"{{QUERY}}\"",
	"results": "rezultate",
	"We could not find any results for \"{{QUERY}}\".": "Nu am gasit nici un rezultat pentru \"{{QUERY}}\".",
	"Try another word.": "Incearca alt cuvant.",
	"Search in CASINO and LIVE CASINO": "Cauta in Cazino si LIVE Cazino",

	"Your deposit has been successfully completed, but an error occured while trying to add the bonus to your account. Please contact the Customer Service to fix this.":
		"Depunerea a reusit, dar nu am reusit sa adaugam bonus-ul selectat in contul dumneavoastra. Va rugam sa contactati serviciul Relatii Clienti pentru remediere.",

	"Last win": "Ultimul castig",

	"cntLabel": "Cont",
	"Search in Casino": "Cauta in Cazino",
	"Promotions": "Promotii",
	"MORE_PROMOTIONS": "Bonus-uri activabile imediat",
	"Ready to CLAIM": "Bonus gata de activare",
	"WALLET & BONUS": "VEZI BONUS-URI ACTIVE",
	"CLAIM LATER": "OBTINE MAI TARZIU",
	"See event": "Vezi eveniment",
	"Deposit is temporarily unavailable": "Depunerea este temporar indisponibila",
	"Register is temporarily unavailable": "Inregistrarea este temporar indisponibila",
	"Set up your options": "Configurează-ți opțiunile",
	"Phone calls": "Apel telefonic",
	"Preferences": "Preferințe",

	/* Wheel */
	"MULTIPLIER": "MULTIPLICATOR",
	"DOUBLE": "DUBLU",
	"Win two multipliers. They MULTIPLY TOGETHER!!!": "Castigi doua casute pe roata de multiplicator. Rezultatele se INMULTESC!!!!",
	"All multipliers are increased by +1.": "Toate casutele de pe roata de multiplicator isi maresc valoarea cu +1.",
	"EXTRA CHANCE": "INCA O SANSA",
	"WILDCARD": "WILDCARD",
	"If you don't like what you won, you will be able to give up on that and try again.": "Daca nu iti place ce ai castigat, poti renunta la el si mai invarti o data.",
	"WIN TWICE": "CASTIGI DE DOUA ORI",
	"SPIN TWICE": "TWICE SPIN",
	"Spin twice today. Guaranteed win at two different products.": "Ai doua invartiri pe ziua de azi. Castigi GARANTAT la doua produse diferite.",
	"MAKE YOUR OWN LUCK": "FA-TI NOROCUL CU",
	"OPT OUT": "OPT OUT",
	"Opt out TWO PRODUCTS from the wheel. Guaranteed win one of the remaining ones.": "Elimini doua PRODUSE de pe roata. Castigi garantat din cele ramase.",
	"MEGA OPT OUT": "MEGA OPT OUT",
	"Opt out THREE PRODUCTS from the wheel. Guaranteed win one of the remaining ones.": "Elimini TREI PRODUSE de pe roata. Castigi garantat din cele ramase.",
	"Spin now!": "Roteste!",
	"SPIN NOW": "INVARTE ACUM",
	"Not eligible? Find out why": "Afla de ce nu esti eligibil",
	"Necessary conditions": "Conditii necesare",
	"Solve Now": "Rezolva Acum",
	"Activity Reward": "Rasplata Activitate",
	"Spin": "Rotire",
	"Spin with multiplier": "Rotire cu multiplicator",
	"Surprise prize at 3 spins with multiplier!": "Premiu surpriza la 3 rotiri cu multiplicator!",
	"I'll try again": "Mai incerc o data",
	"Activate prize": "Activeaza premiu",
	"Keys": "Chei",
	"How it works": "Cum functioneaza",
	"I chose, spin it!": "Am ales, roteste",
	"Award": "Castig",
	"I was unable to contact {{brand}} Wheel.": "Nu am putut contacta serviciul {{brand}} Wheel.",
	"Most likely this is a temporary problem. Please try again later.": "Cel mai probabil este o problema temporara. Va rugam sa incercati mai tarziu.",
	"Try again": "Incearca din nou",
	"The prize will be automatically applied in": "Premiul va fi aplicat automat in",
	"rotiri gratuite": "free spins",
	"One more spin left": "Mai ai o invartire",
	"Agree to receive marketing information from us": "Iti dai acordul sa afli imediat ce esti eligibil pentru bonus-uri si promotii.",
	"Play activity required: {{AMOUNT}} left": "Indeplineste activitatea minima necesara: {{AMOUNT}} Lei ramasi",
	"Verify your email address": "Ai adresa de email verificata",
	"Verify your identity": "Ai identitatea verificata",
	"There should not be any active bonuses in your account": "Nu trebuie sa ai bonus-uri active in cont",
	"To spin the wheel, you must:": "Ca sa invarti roata, trebuie sa:",
	"No limit": "Fara limita",

	"Discover today's prize": "Descopera premiul de azi",
	"Today's prize": "Premiul de azi",
	"Unlock": "Deblocheaza",
	"Unlocked prize": "Premiu deblocat",
	"Deposit Now": "Depune Acum",
	"Choose with which bonus you want to deposit: Free Bet or Free Spins.": "Alege cu ce bonus vrei sa depui: Free Bet sau Free Spins.",
	"You can choose one bonus, the other one will become unavailable after deposit.": "Poti alege un singur bonus, celalalt va deveni indisponibil dupa depunere.",
	"Activate the bonus until midnight before the offer expires.": "Activeaza bonus-ul pana la miezul noptii inainte sa expire oferta.",
	"Unlocked": "Deblocat",
	"An error occurred ": "A aparut o eroare",
	"Customer service": "Relatii cu clientii",
	"The operation may have been successful. If today's bonus is not available, please contact customer service.": "E posibil ca operatiunea sa fi reusit. Daca bonusul asociat acestei zile nu este disponibil, te rugam sa contactezi serviciul de relatii cu clientii.",
	"ACTIVATE AWARD": "ACTIVEAZA PREMIUL",
	"Next prize in": "Urmatorul premiu in",
	"Special Promotions": "Promotii Speciale",

	/* Raffle */
	"Surname": "Prenume",
	"Ticket Serial": "Serie Bilet",
	"I agree to be contacted": "Sunt de acord sa fiu contactat",
	"I want to be informed about promotions and bonuses.": "Vreau sa fiu informat despre promotii si bonusuri.",
	"Time left": "Timp ramas",
	"Add ticket": "Adauga bilet",
	"Check active tickets": "Verificare bilete active",
	"The SMS verification is required only first time to be eligible for prizes.": "Verificarea este necesara o singura data si este obligatorie pentru a fi considerat eligibil pentru premiere.",
	"Phone check": "Verificare telefon",
	"Enter the verification code received via SMS:": "Introdu codul de verificare primit prin SMS:",
	"Change number": "Shimba numarul",
	"ADD A NEW TICKET": "ADAUGA INCA UN BILET",
	"The ticket was registered for the raffle.": "Biletul a fost inregistrat pentru tombola.",
	"Add more tickets for more chances to win.": "Adauga mai multe bilete pentru mai multe sanse de castig.",
	"Invalid verification code": "Cod de verificare invalid",
	"Check tickets": "Verificare bilete",
	"Ownership check": "Verificare titular",
	"Raffle tickets": "Bilete in tombola",
	"Tickets list": "Lista bilete",
	"Start page": "Pagina de start",
	"Winner Raffle": "Tombola Winner",
	"Join the WINNER LEAGUE!": "Intra in LIGA WINNER!",
	"More tickets added will bring you closer to the big win. Keep the tickets so you can claim the prize.": "Inscrie cat mai multe serii si fii mai aproape de marele castig. Pastreaza biletele pentru a putea revendica premiul.",
	"Keep the ticket so you can claim the prize.": "Pastreaza biletul pentru a putea revendica premiul.",
	"Ticket serial already exists": "Seria a fost deja inscrisa",
	"Missing required field": "Lipseste camp obligatoriu",
	"Cancel Scan": "Anuleaza Scanarea",
	"SMS limit exceeded": "Limita SMS depasita",
	"You can check the ticket list maximum 3 times per day": "Poti verifica lista de maxim 3 ori pe zi",
	"See the game rules": "Vezi regulamentul",
	"You must wait one minute to receive the SMS verification code": "Trebuie sa astepti 1 minut pentru a primi SMS-ul de verificare",
	"By signing up for the campaign, you agree to the terms of the GDPR.": "Prin inscrierea in campanie, iti exprimi acordul privind termenii GDPR.",

	"Available at": "Disponibil la ora",
	"Your winnings if the spin was with the keys": "Castigul tau daca rotirea era cu chei",
	"{{brand}} Keys": "Cheile {{brand}}",
	"wheelKeysStatus1": " <0>Joci sau pariezi la {{brand}}</0> la produsul tau preferat.",
	"wheelKeysStatus2": " Primesti <1>EXTRA PROGRES</1> plasand pariuri pre-meci si live.",
	"wheelKeysStatus3": " <1>Se contorizeaza doar banii reali</1> plasati in conditiile obisnuite ale activitatilor promotionale (fara ruleta, cota minima 1.5 la sport, etc).",
	"wheelKeysStatus4": " <0>Deblochezi multiplicatorul de castig</0> folosind cateva chei la fiecare invartire {{brand}} Wheel.",
	"wheelKeysStatus5": " <0>Joci sau pariezi la {{brand}}</0> la produsul tău preferat si obții chei din rulaj.",
	"wheelKeysStatus6": " <1>Folosești chei</1> pentru a activa Roata de Multiplicatori.",
	"wheelKeysStatus7": " <1>La trei învârtiri cu multiplicator</1> deblochezi unul din premiile speciale puzzle.",
	"wheelKeysStatus8": " <1>Premiile speciale puzzle</1>: multiplicatori măriți, învârti si câștigi de doua ori la doua produse diferite sau mai încerci o data daca nu iți place ce ai câștigat. Premiul puzzle se aplica imediat la acea învârtire.",

	"Simple spin": "Invartire simpla",
	"Lucky Bet is available only for the real-money tickets.": "Lucky Bet este disponibil doar biletelor plasate cu bani reali.",
	"The Lucky Number will be considered a regular ball.": "Bila jucata va fi considerata bila normala.",
	"luckyBetBonusWarning": " <1>Lucky Bet</1> este disponibil doar biletelor plasate cu bani reali.",
	"How to activate multiplier?": "Cum obtin premiul cu multiplicator?",

	"Payment Method": "Metodă de plată",
	"Tranzactie securizata": "Tranzacție securizată",

	"Eligibility conditions": "Cerinte de eligibilitate",
	"Play activity for the next key:": "Activitate pentru urmatoarea cheie:",

	"Events": "Evenimente",

	"EVENT BETS ARE UPDATING": "PARIURILE SE ACTUALIZEAZA",

	"Cancel active bonus?": "Anuleaza bonus-urile existente?",
	"Total BONUS funds in your account:": "Ai fonduri BONUS in cont in valoare de:",
	"T&C agreement prevents activating two bonuses at the same time. Cancel ${totalValue} and claim this bonus?": "T&C previne activarea la doua bonusuri in acelasi timp. Renunta la ${totalValue} si activeaza acest bonus",
	"CLAIM BONUS": "ACTIVEAZA BONUSUL",

	"SEE OTHER OFFERS": "VEZI ALTE OFERTE",
	"Terms and conditions - in full": "Termeni si conditii - integral",

	"Bonus rules": "Regulament bonusuri",

	// po-5821
	'What is "PEP"?': 'Ce este "PEP"?',
	'“Publicly exposed person” (in the sense of the AML Law) means a natural person who is or who has been entrusted with prominent public functions. Prominent public functions mean:':
		"“Persoană expusă public” (în sensul Legii CSB) înseamnă o persoană fizică căreia i se încredințează sau i s-au încredințat funcții publice importante. Funcții publice proeminente înseamnă:",
	"head of State, heads of government, ministers and deputy ministers or state secretaries;":
		"șef de stat, șefi de guvern, miniștri și adjuncți sau secretari de stat;",
	"members of parliament or of similar central legislative bodies;": "membri ai parlamentului sau ai organelor legislative centrale similare;",
	"members of the governing bodies of political parties;": "membri ai organelor de conducere ale partidelor politice;",
	"members of supreme courts, of constitutional courts or of other high-level judicial bodies, the decisions of which are not subject to further appeal, except in exceptional circumstances;":
		"membri ai curților supreme, ai curților constituționale sau ai altor organe judiciare de nivel înalt, ale căror decizii nu sunt supuse recursului, cu excepția unor circumstanțe excepționale;",
	"members of governing bodies of courts of auditors or of the boards of central banks;":
		"membri ai organelor de conducere ale curților de conturi sau ai consiliilor băncilor centrale;",
	"ambassadors, chargés d'affaires and high-ranking officers in the armed forces;":
		"ambasadorii, oamenii de afaceri și ofițerii de rang înalt din forțele armate;",
	"members of the administrative, management or supervisory bodies of State-owned enterprises, regie autonomous or national companies;":
		"membri ai organelor administrative, de conducere sau de supraveghere ale întreprinderilor de stat, autonome sau naționale;",
	"directors, deputy directors and members of the board or equivalent function of an international organisation.":
		"directori, directori adjuncți și membri ai consiliului de administrație sau funcție echivalentă a unei organizații internaționale.",
	"No public function referred to in points (a) to (h) shall be understood as covering middle-ranking or more junior officials.":
		"Nicio funcție publică menționată la literele (a)-(h) nu poate fi înțeleasă ca acoperind funcționari de rang mediu sau mai mici.",
	"“family members” of a publicly exposed person are (in the sense of the AML Law):":
		"„Membrii de familie” ai unei persoane expuse public sunt (în sensul Legii CSB):",
	"the spouse of a publicly exposed person or the person considered to be equivalent to a spouse of a publicly exposed person;":
		"soțul unei persoane expuse public sau persoana considerată a fi echivalentă cu soțul unei persoane expuse public;",
	"the children and their spouses, or persons considered to be equivalent to a spouse, of a publicly exposed person;":
		"copiii și soții acestora, sau persoanele considerate a fi echivalente cu un soț, ale unei persoane expuse publicului;",
	"the parents of a publicly exposed person.": "părinții unei persoane expuse publicului.",
	"“persons known to be close associates” of a publicly exposed person are (in the sense of the AML Law):":
		"„Persoane despre care se știe că sunt apropiați” unei persoane expuse public sunt (în sensul Legii CSB):",
	"natural persons who are known to have joint beneficial ownership of legal entities or legal arrangements, or any other close business relations, with a publicly exposed person;":
		"persoane fizice despre care se știe că au drepturi de proprietate în comun de persoane juridice sau aranjamente juridice sau orice alte relații de afaceri apropiate cu o persoană expusă publicului;",
	"natural persons who have sole beneficial ownership of a legal entity or legal arrangement which is known to have been set up for the de facto benefit of a publicly exposed person.":
		"persoane fizice care dețin unicul proprietar efectiv al unei entități juridice sau al unui aranjament juridic despre care se știe că a fost înființat, de fapt, în beneficiul al unei persoane expuse publicului.",
	// register v2
	"I agree with the ${linkStartTC}Terms and Conditions${linkEnd} and I declare that I am over 18 years old and that I am not a publicly exposed person.":
		"Am citit și sunt de acord cu ${linkStartTC}T&C${linkEnd} și declar că am peste 18 ani și nu sunt persoană expusă public.",
	"NEW": "NOU",
	"Verified identity": "Identitate Verificată",
	"Quit registration?": "Renunți la înregistrare?",
	"Are you sure you want to give up on the progress made so far?": "Ești sigur că vrei să renunți la progresul realizat?",
	"Quit": "Renunț",
	"Continue": "Continuă",
	"100% SAFE": "100% SIGUR",
	"Take a picture of your ID card and have your account verified quickly": "Fa o poza cartii de identitate si ai contul verificat rapid",

	// PO-5858
	"Objectives": "Obiective",
	"Next begins in:": "Urmatorul incepe in:",
	" Temporarily unavailable": " Temporar indisponibil",
	"MAIN": "PRINCIPAL",
	"awards": "premii",
	"your place": "locul tau",
	"scores": "punctaj",
	"Join": "Participă",
	"Unblock": "Deblocheaza",
	"scoring system": "sistem de punctaj",
	"Recommended games for this tournament": "Jocuri recomandate pentru acest turneu",
	"Upcoming Tournaments": "Turnee Viitoare",
	"Deployment program": "Program de desfasurare",
	"premieres": "premiere",
	"Several tournaments will appear here as the start time approaches": "Mai multe turnee vor aparea aici pe masura ce se apropie ora inceperii",
	"Tournament history": "Istoric turnee",
	"The result of the completed tournaments": "Rezultatul turneelor finalizate",
	"Tournaments history completed more than 90 days ago is not available": "Istoricul turneelor finalizate cu mai mult de 90 de zile in urma nu este disponibil",
	"TOURNAMENT": "TURNEU",
	"TOURNAMENT DETAILS": "DETALII TURNEU",
	"ACTIVE NOW": "ACTIV ACUM",
	"FUTURE": "VIITOR",
	"You are not enrolled yet": "Nu esti inca inscris",
	"The tournament is blocked for you": "Turneu blocat pentru tine",
	"Begins in": "Incepe in",
	"Ends in": "Se termina in",
	"Starts in": "Incepe in",
	"Tournament rules": "Reguli turneu",
	"available games": "jocuri disponibile",
	"The tournament rankings will appear soon": "Clasamentul turneului va aparea in curand",
	"DAY": "ZILE",
	"HOUR": "ORE",
	"MIN": "MIN",
	"SEC": "SEC",
	"your position": "pozitia ta",
	"Play": "Joacă",
	"No players yet": "Niciun jucator",
	"games": "jocuri",
	"Position": "Locul",
	"Tournament History": "Istoric Turneu",
	"You have a prize waiting for you": "Ai un premiu in asteptare",
	"Claim": "Colecteaza",
	"The prize can only be claimed after the end of the active bonus.": "Premiul poate fi revendicat doar după finalizarea bonusului activ.",
	"The prize": "Bonusul",

	// PO-5976 - Retail Tickets
	"Cash it online": "Incaseaza online",
	"Ticket collection": "Incasare bilet",
	"Submission in progress": "Depunere in progres",
	"Successful deposit": "Incasare cu succes",
	"Unsuccessful deposit": "Incasare nereusita",
	"Everything is well! Your account has been credited with the amount of {{AMOUNT}} ({{TAX}} lei as {{PERCENTAGE}}% deposit tax). Time to sit back, play and relax. Good luck!":
		"Totul este bine! Contul dvs. a fost creditat cu suma de {{AMOUNT}} ({{TAX}} lei ca {{PERCENTAGE}}% taxa de depozit). E timpul sa stai pe spate, sa te joci si sa te relaxezi. Noroc!",
	"Try another ticket": "Incearca alt bilet",
	"Deposit in progress": "Incasare in curs",
	"Your money is safe. They will be added to your account in minutes.": "Banii tai sunt in siguranta. Vor fi adaugati in contul tau in cateva minute.",
	"The following error occurred:": "A aparut urmatoarea eroare:",
	"there was an internal error. Please try again later": "a aparut o eroare interna. Va rugam sa incercati mai tarziu",
	"missing brand setup. Please contact customer support": "configuratie invalida. Va rugam sa contactati suport clienti",
	"account kyc missing - not eligible. Please contact customer support": "identitatea contului nu este verificata. Va rugam sa contactati suport clienti",
	"missing ticket delivery platform. Please contact customer support": "bilet - platforma transmitere lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket delivery platform. Please contact customer support": "bilet - platforma transmitere invalida. Va rugam sa contactati suport clienti",
	"missing ticket product. Please contact customer support": "bilet - produs lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket product. Please contact customer support": "bilet - produs invalid. Va rugam sa contactati suport clienti",
	"missing ticket status. Please contact customer support": "bilet - stare lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket status. Please contact customer support": "bilet - stare invalida. Va rugam sa contactati suport clienti",
	"missing ticket amount. Please contact customer support": "bilet - valoare lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket amount. Please contact customer support": "bilet - valoare invalida. Va rugam sa contactati suport clienti",
	"missing ticket pin. Please contact customer support": "bilet - pin lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket pin. Please contact customer support": "bilet - pin invalid. Va rugam sa contactati suport clienti",
	"ticket was already claimed by a different player. Please contact customer support": "biletul a fost deja incasat de un alt jucator. Va rugam sa contactati suport clienti",
	"ticket was unsuccessfully claimed too many times. Please contact customer support": "incasarea biletului a esuat de prea multe ori. Va rugam sa contactati suport clienti",
	"agreeRetailTicketsTerms": "Sunt de acord cu <1>conditiile</1> de achitare a biletului",
	"Ticket payout": "Incasare castig",
	"According to art. 13, para. (2) of Law no. 129 of 18.07.2019, to prevent and combat money laundering and terrorist financing": "Conform art. 13, alin. (2) din Legea nr. 129 din 18.07.2019 pentru prevenirea şi combaterea spălării banilor şi finanţării terorismului",
	"I am the REAL BENEFICIARY of the amount won by participating in fixed odds betting games": "Sunt BENEFICIARUL REAL al sumei castigate prin participarea la jocurile de noroc de tip pariuri in cota fixa",
	"I am A PUBLIC EXPOSED PERSON": "Sunt PERSOANA EXPUSA PUBLIC",
	"I am a member of a family in which is a person that is publicly exposed or of a person known as an associate of a publicly exposed": "Detin  calitatea  de  membru  al  familiei  al  unei  persoane  expuse  public  ori  de  persoana  cunoscuta  ca  asociat  al unei persoane expuse public",
	"I declare that the amount collected represents a gain from participating in the fixed-odds betting game.": "Declar ca suma incasata reprezinta castig din participarea la jocul de noroc tip pariuri in cota fixa.",
	"I have read and agree that the above information constitutes a statement according to law no. 129 of 18.07.2019": "Am citit si sunt de acord ca informatiile de mai sus sa constituie o declaratie conform legii nr. 129 din 18.07.2019",
	"Deposit Ticket": "Depunere Bilet",
	"Deposit with bonus": "Depunere cu bonus",
	"You cannot get other BONUSES right now. Please try again later.": "Nu poti obtine alte BONUS-uri chiar acum. Incearca din nou mai tarziu.",
	"AML form missing or incomplete. Please try again": "Formularul AML lipseste sau este incomplet. Incearca din nou mai tarziu.",
	"verify your identity": "verifică-ţi identitatea",
	"Cashout in shop": "Cashout in agentie",
	"Cashout online": "Cashout online",

	"Verify your account and identity before you can self-exclude": "Verifică-ți identitatea înainte de auto-excludere",

	// PO-6252_Sport_Tournaments
	"See Details": "Vezi Detalii",
	"the following events qualify": "se califica urmatoarele evenimente",
	"tournament": "turneu",
	"This ticket contributes to the tournament.": "Acest bilet contribuie la turneu.",
	"This ticket does not contribute points to the tournament": "Acest bilet nu contribuie cu puncte la turneu",
	"Tournament points": "Puncte turneu",
	"TOURNAMENT: This ticket does not contribute points to the tournament": "TURNEU: Acest bilet nu contribuie cu puncte la turneu",
	"This type of bet ({{PARM0}}) is not accepted": "Acest tip de pariu ({{PARM0}}) nu este accceptat",
	"This event ({{PARM0}}) is not accepted": "Acest eveniment ({{PARM0}}) nu este accceptat",
	"This tournament ({{PARM0}}) is not accepted": "Acest turneu ({{PARM0}}) nu este accceptat",
	"This category ({{PARM0}}) is not accepted": "Aceasta categorie ({{PARM0}}) nu este accceptata",
	"This type of sport ({{PARM0}}) is not accepted": "Acest tip de sport ({{PARM0}}) nu este accceptat",
	"Minimum odd '{{PARM0}}'": "Cota minima '{{PARM0}}",
	"Show less": "Arata mai putin",
	"Find out why?": "Afla de ce?",
	"Tournament Sport": "Turneu Sport",
	"Bet already placed on event, different market": "Parierea in contra-partida nu va contribui la turneu",
	"LOCKED": "BLOCAT",
	"Current score": "Scor curent",
	"Your award": "Premiul tau",
	"pts": "pct",
	"This bet does not contribute points to the tournament": "Acest pariu nu contribuie cu puncte la turneu",

	// PO-6748
	"A ticket can contain only one Excel Plus bet":
		"Un bilet poate conţine un singur eveniment Excel Plus",
	"Retail ticket payment": "Încasare bilet agenție",

	// PO-6682
	"Deposit is not possible": "Incasarea nu este posibila",
	"Deposit limits exceeded": "Limite de depunere depasite",
	"Ticket value": "Valoare bilet",
	"Available limit": "Limita disponibila",
	"Limit type": "Tip de limita",
	"requires identity verification": "necesita verificarea identitatii",
	"requires the modification of the deposit limits set by you. The limit increase will be applied 48 hours after the change":
		"necesita modificarea limitelor de depunere setate de dumneavoastra. Marirea limitei se va aplica la 48 ore de la momentul schimbarii",
	"Solve now": "Rezolva acum",
	"dCancel": "Renunt",

	"The maximum file size is ${size}": "Dimensiunea maximă acceptată pentru fișier este ${size}",

	"PARTICIPATE IN THE WINNER RAFFLE!!": "Participă la TOMBOLA WINNER!!",
	"Enter as many eligible series as you can and your chances of winning the Grand Prize increase.": "Înscrie cât mai multe serii eligibile și șansele tale de a câștiga Marele Premiu cresc.",
	"Keep your tickets to claim your prize.": "Păstrează biletele pentru a-ți putea revendica premiul.",

	"This campaign is organized exclusively by DREAM BETTING S.R.L. All the personal data of the campaign participants, registered through the winner.ro platform, are processed and stored exclusively by DREAM BETTING S.R.L.":
		"Prezenta campanie este organizata exclusiv de catre DREAM BETTING S.R.L. Toate datele cu caracter personal ale participantilor la campanie, inregistrati prin platforma winner.ro, sunt prelucrate si stocate exclusiv de catre DREAM BETTING S.R.L.",

	"The document does not contain the requested details.": "Documentul nu conține detaliile solicitate.",
	"Average waiting time:": "Timp mediu de așteptare:",
	"Available from 10:00 to 22:00": "Serviciu disponibil în intervalul orar 10:00-22:00",
	"CALL US": "APELEAZĂ UN AGENT",
	"If you need any help, do not hesitate to get in touch with us using one of the methods below.":
		"Dacă aveți nevoie de ajutor, nu ezitați să luați legătura cu noi folosind una dintre metodele de mai jos.",
	"Processing...": "Se procesează...",
	"Average waiting time:": "Timp mediu de așteptare:",
	"Available from 10:00 to 22:00": "Serviciu disponibil în intervalul orar 10:00-22:00",
	"CALL US": "APELEAZĂ UN AGENT",
	"If you need any help, do not hesitate to get in touch with us using one of the methods below.":
		"Dacă aveți nevoie de ajutor, nu ezitați să luați legătura cu noi folosind una dintre metodele de mai jos.",

	"Ready for cash-in": "Gata pentru a ridica banii",
	"I have read, understood and accept the OKTO.CASH Service ${startUrl}Terms of Use${endUrl} and I agree to the transfer of my data to Oktopay Ltd and Sureswipe EMI PLC.":
		"Am citit, am înţeles şi accept ${startUrl}Termenii de utilizare${endUrl} a serviciului OKTO.CASH şi sunt de acord cu transferul datelor mele personale către Oktopay Ltd. şi Sureswipe EMI Plc.",
	"I have read, understood, and accept the ${startUrl}OKTO Privacy Policy${endUrl}":
		"Am citit, am înţelesşi accept ${startUrl}Politica de confidenţialitate${endUrl} a OKTO.",
	"Visit any OKTO.CASH payment station and use the code below to deposit with cash.": "Mergi la orice Statie de Plata OKTO.CASH si depune numerar cu ajutorul codului de mai jos.",
	"At the OKTO.CASH point, please select Winner from the menu, then introduce the payment code below. The funds will be delivered to your Winner account shortly after.": "Cand vizitezi Statia de Plata OKTO.CASH, selecteaza Winner din meniu si introdu codul de plata. Fondurile vor aparea in contul tau Winner la scurt timp dupa finalizarea platii.",
	"Find here the closest OKTO.CASH payment station": "Apasa aici si gaseste cea mai apropiata Statie de Plata OKTO.CASH",
	"Ready to deposit": "Actiune reusita",
	"I have saved the code": "Am salvat codul",
	"Amounts available via OKTO.CASH:": "Valori acceptate de OKTO.CASH:",
	"Top up your betting account with cash quickly and directly through OKTO.CASH, no bank card needed.":"Alimentează-ți contul de pariuri cu cash, direct și rapid, fără să ai nevoie de un card bancar, prin OKTO.CASH.",

	"What is OKTO.CASH?": "Ce este OKTO.CASH?",
	"OKTO.CASH is a payment method that allows you to fund your betting account with cash, without the need for a bank card.": "OKTO.CASH este o metodă de plată cu ajutorul căreia îți alimentezi contul de pariuri cu bani cash, fără să ai nevoie de un card bancar.",
	"How can I find an OKTO.CASH location?": "Cum pot identifica o locație OKTO.CASH?",
	"Access the ${startUrl}OKTO.CASH${endUrl} section from the homepage. Then, depending on your location, select the nearest terminal.": "Accesează din pagina principală secțiunea ${startUrl}OKTO.CASH${endUrl}. Apoi, în funcție de adresa ta, selectează cel mai apropiat terminal.",
	"How do I make a deposit through OKTO.CASH?" :"Cum efectuez o depunere prin OKTO.CASH?",
	"Open the Winner app.": "Deschide aplicația Winner.",
	"Go to the Deposit section.": "Accesează secțiunea Depunere.",
	"Choose the OKTO.CASH payment method.":"Selectează metoda de plată prin OKTO.CASH.",
	"Enter the amount you wish to deposit. You can only deposit fixed amounts, according to the following values: 20, 50, 100, 150, 250, 500, 1000, 1500, 2500, 3000, 4500 Lei.":"Introdu suma pe care dorești să o depui. Poți depune doar sume fixe, conform următoarelor valori: 20, 50, 100, 150, 250, 500, 1000, 1500, 2500, 3000, 4500 Lei.",
	"Find the nearest terminal where you can make the deposit." :"Identifică cel mai apropiat terminal unde poți depune suma respectivă.",
	"Save the payment code generated in your player account.":"Salvează codul de plată generat în contul de jucător.",
	"On the terminal, select the Gift Card & Games category - Betting - Winner.":"Selectează, pe terminal, categoria Gift Card & Games - Betting - Winner.",
	"Type the payment code.": "Introdu codul de plată.",
	"Your funds will instantly reach your player account." :"Banii vor ajunge instant în contul tău de jucător.",

	"Cash Deposit": "Depuneri Cash",

	"Processed": "Procesat",

	// cashout new
	"<div>Checking cashout</div><div>availability</div>": "<div>Verificare disponibilitate</div><div>CASHOUT</div>",
	"<div>Cashout temporarily</div><div>not available</div>": "<div>Cashout momentan</div><div>indisponibil</div>",

	// tickets placing errors
	"An error occurred: Error creating ticket": "Mesajul: Eroare la plasarea biletului",
	"Maximum stake accepted: {{LIMIT}} Lei": "Miza maxima acceptata: {{LIMIT}} Lei",


	"Access your account and you can initiate a withdrawal from the 'Wallet' section by selecting the amount you wish to withdraw. Your request will be processed as quickly as possible." :
		"Intra în contul tău și poți iniția o retragere din secțiunea “Portofel”, selectând suma pe care vrei să o retragi. Cererea ta va fi procesată în cel mai scurt timp.",
};
