import React, { useEffect } from 'react';
import connect from "react-redux/es/connect/connect";
import * as stateActions from './../../../store/actions';
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import { Button } from "reactstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import Game from '../../Games/Game/Game';
import './Collection.scss';

import JackpotsHeaderV2 from "../../Jackpots/HeaderV2";

const Collection = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.isRequestGameCollections();
        props.hideMainBanner();
        window.scrollTo(0, 0);

        if (props.egt && !props.egt.dataReceived) {
            props.reloadJackpots();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const backHandler = () => {
        props.history.push("/cazino/jocuri");
    };

    const getCollection = () => {
        let mainContent = <CircularProgress className={'Loading'} />;
        let gameContent = [];
        let errorContent = (
            <div className="CollectionContainer">
                <div className="ErrorContainer">
                    <h1 className={"ErrorTitle"}>Oops!</h1>
                    <p className={"ErrorContent"}>{t('The game collection you are looking for is temporarily unavailable. Please try again later.')}</p>
                    <Button color="success" className={"BackButton"} onClick={backHandler}>{t('BACK TO THE CASINO')}</Button>
                </div>
            </div>
        );
        let collection = '';

        if (!props.games.loadingGameCollections && props.games.gameCollections && props.games.gameCollections.length > 0) {
            let collectionID = window.location.pathname.replace("/cazino/collection/", "");
            if (collectionID !== "undefined" && collectionID !== '' && !isNaN(collectionID)) {
                let collections = [];
                Object.values(props.games.gameCollections).forEach((item, index) => {
                    collections[item.id] = item;
                });
                if (collections[collectionID]) {
                    collection = collections[collectionID];

                    let jackpot = null;
                    if (typeof collection.jackpot_id !== "undefined" && collection.jackpot_id) {
                        jackpot = (<JackpotsHeaderV2
                            integration={"desktop"}
                            jackpotId={collection.jackpot_id}
                            collectionId={collection.id}
                            front={false}
                            name={"EGT"}
                            value={props.egtParsed.maxValue}
                            jackpot={props.egtParsed}
                            currency={props.egtParsed.currency}
                        />);
                    }

                    if (collection.items) {
                        for (let i = 0; i < collection.items.length; i++) {
                            gameContent[i] = (
                                <div className={"GameItem" + (i === 0 ? ' FirstGame' : '')} key={i}>
                                    <Game gameItem={collection.items[i]} />
                                </div>
                            )
                        }
                        // gameContent.splice(3, 0, (
                        //     <div className={"GameItem JackpotWidget"} key={collection.items.length + 1}>
                        //         <RecentWinners winners={props.jackpot.latestWinners} style={{ marginBottom: 0 }} />
                        //     </div>
                        // ));
                    }
                    mainContent = (
                        <React.Fragment>
                            {jackpot}

                            <div className="CollectionContainer">
                                <div className="CollectionHeader">
                                    <h2 className={"CollectionTitle"}>
                                        {collection.title} <span>({collection.items.length})</span>
                                    </h2>
                                </div>
                                <div className={"GamesContainer"}>
                                    {gameContent}
                                </div>
                            </div>
                        </React.Fragment>
                    )
                } else {
                    mainContent = errorContent;
                }
            } else {
                mainContent = errorContent;
            }
        }

        return mainContent;
    }

    return (
        <div className={"CollectionScreen"}>
            <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={500} style={{ width: "100%" }}>
                {getCollection()}
            </Animated>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        games: state.games,
        egt: state.jackpots.egt,
        egtParsed: state.jackpots.egtParsed,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        isRequestGameCollections: () => dispatch(stateActions.requestGameCollections()),
        hideMainBanner: () => dispatch(stateActions.hideMainBanner()),
        reloadJackpots: () => { dispatch(stateActions.requestJackpots()) },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Collection));