import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Alert } from 'reactstrap';
import { VelocityTransitionGroup } from "velocity-react";
import MaterialInputField from './../../../../MaterialInputField/MaterialInputField';
import * as stateActions from "../../../../../store/actions";
import Icons from "../../../../../assets/Icons/Icons";
import "./ValidationCode.scss";
import { withTranslation } from "react-i18next";

class ValidationCode extends Component {
    constructor(props) {
        super(props);

        this.screenStep = 2;

        this.active = false;
        this.hideButtons = false;
    }

    componentDidUpdate() {
        if (!(this.screenStep === this.props.profile.changePhoneForm.step)) {
            if (!this.active) {
                return;
            }

            this.active = false;
            this.props.goToStep(this.props.profile.changePhoneForm.step);
            return;
        }

        this.active = true;
        this.hideButtons = false;
    }

    getMessage() {
        if (null === this.props.profile.changePhoneForm.step2Message) {
            return undefined;
        }

        let color = 'info';
        if ('error' === this.props.profile.changePhoneForm.step2Message.type) {
            color = 'danger';
        }

        if ('success' === this.props.profile.changePhoneForm.step2Message.type) {
            color = 'success';
            this.hideButtons = true;
        }

        return (
            <Alert color={color}>
                {this.props.profile.changePhoneForm.step2Message.message}
            </Alert>
        );
    }

    updateHandler(event) {
        if (true === this.props.profile.changePhoneForm.codeValidationProgress) {
            return;
        }

        let value = event.target.value;
        this.props.onUpdate(value);
    }

    validateCodeHandler() {
        if (true === this.props.profile.changePhoneForm.codeValidationProgress) {
            return;
        }

        this.props.onValidateCode(this.props.profile.changePhoneForm.newPhoneNumber, this.props.profile.changePhoneForm.validationCode);
    }

    goBack() {
        if (true === this.props.profile.changePhoneForm.codeValidationProgress) {
            return;
        }

        this.hideButtons = false;
        this.props.onChangeStep(1);
    }

    render() {
        let activeField = (null !== this.props.profile.changePhoneForm && this.screenStep === this.props.profile.changePhoneForm.step) ? true : false;

        let fieldIcon = "";

        let message = this.getMessage();

        let buttonsSupportClassName = [
            'align-self-center',
            'text-center',
            'w-100',
            'buttonsSupport',
        ];
        if (true === this.hideButtons || true === this.props.profile.changePhoneForm.success) {
            buttonsSupportClassName.push('hide');
        }

        let stepButton = {
            'icon': Icons.get('login-next', 'chp-next-step'),
            'text': this.props.t("Validate code"),
            'className': [
                "icon",
                "text-center"
            ]
        };
        if (true === this.props.profile.changePhoneForm.codeValidationProgress) {
            stepButton['icon'] = Icons.get('loading', 'chp-next-step');
            stepButton['className'].push('animate');
            stepButton['text'] = this.props.t("Loading...");
        }

        return (
            <div className="d-flex justify-content-center stepSmsCode">
                <div className="step-support w-100">
                    <div className="step-header">
                        <h2>
                            {this.props.t("Step")} {this.props.currentStep} {this.props.t("of")} {this.props.totalSteps}
                        </h2>
                    </div>
                    <div className="step-content d-flex">
                        <div className="align-self-center text-center w-100">
                            <MaterialInputField inputLabel={this.props.t("SMS Code")} inputType="text" inputIdName="chp_sms_code" inputValue={this.props.profile.changePhoneForm.validationCode} changeHandler={this.updateHandler.bind(this)} elemIcon={fieldIcon} activeField={activeField} />
                        </div>
                    </div>
                    <div className="step-message d-flex">
                        <div className="align-self-center text-center w-100">
                            { /* here the error */}
                            <VelocityTransitionGroup enter={{ animation: "fadeIn" }} leave={{ animation: "fadeOut" }} duration={500}>
                                {message}
                            </VelocityTransitionGroup>
                        </div>
                    </div>
                    <div className="step-footer d-flex">
                        <div className={buttonsSupportClassName.join(" ")}>
                            <Button color="warning" onClick={this.validateCodeHandler.bind(this)} className="w-100 requestPhoneChange">
                                <span className={stepButton['className'].join(" ")}>{stepButton['icon']}</span>
                                <span className="text text-left">{stepButton['text']}</span>
                            </Button>
                            <Button outline color="info" onClick={this.goBack.bind(this)} className="w-100 backButton">
                                <span className="text text-left">{this.props.t("check number and resend code")}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdate: (value) => dispatch(stateActions.updateSMScode(value)),
        onChangeStep: (value) => dispatch(stateActions.changePhoneModalStep(value)),
        onValidateCode: (phone, code) => dispatch(stateActions.requestCodeValidation(phone, code)),
    };
};

const mapStateToProps = state => {
    return {
        profile: state.profile,
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ValidationCode));