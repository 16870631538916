import { scoreConstants } from "./constants";

export const scoreLoad = (data) => ({
  type: scoreConstants.LOAD,
  data
});

export const scoreLoaded = (data) => ({
  type: scoreConstants.LOADED,
  data
});

export const scoreClear = (data) => ({
  type: scoreConstants.CLEAR,
  data
});