import React from 'react';
import { withTranslation } from "react-i18next";
import moment from "moment";

import "./simple_timer.scss";

const SimpleTimer = (props) => {
  const { date, onLive, className, t
    //  i18n 
  } = props;

  const [live, setLive] = React.useState(false);
  const [counter, setCounter] = React.useState(false);
  const [state, setState] = React.useState({
    mins: "--",
    secs: "--"
  });

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;

    if (mounted) {
      if (date) {
        const calcTime = () => {
          const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
          const dtNow = moment();

          const duration = moment.duration(dt.diff(dtNow, 'milliseconds'), 'milliseconds');

          let days = Math.floor(duration.asDays());
          let hours = duration.hours();
          const mins = duration.minutes();
          const secs = duration.seconds();

          if (days < 0 || hours < 0 || mins < 0 || secs < 0) {
            setCounter(false);
            setLive(true);
            clearInterval(intervalID);
            if (typeof onLive === "function") onLive();
            return;
          }

          setState({
            days: days,
            hours: ("0" + hours).slice(-2),
            mins: ("0" + mins).slice(-2),
            secs: ("0" + secs).slice(-2),
          });
          setLive(false);
          setCounter(true);
        };

        clearInterval(intervalID);
        intervalID = setInterval(() => {
          calcTime();
        }, 1000);
        calcTime();
      }
    }

    return () => {
      mounted = false;
      clearInterval(intervalID);
    }
  }, [date]); // eslint-disable-line

  if (!date) return null;



  return (
    <React.Fragment>
      <div className="txt">{t("Time left")}{!!state.days && <span>&nbsp;{state.days} {t("days")}</span>}</div>
      <div className="count">
        <div className={className ? className : ""}>
          {counter &&
            <span>
              {state.hours === "00" ? <span className="muted">{state.hours} :</span> : `${state.hours} :`} {state.hours === "00" && state.mins === "00" ? <span className="muted">{state.mins} :</span> : `${state.mins} :`} {state.secs}
            </span>
          }
          {!counter && <span><span className="muted">--</span> : <span className="muted">--</span> : <span className="muted">--</span></span>}
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(SimpleTimer);