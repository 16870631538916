import * as actionTypes from '../actions/actionTypes';

const initialState = {
    responseType: null,
    depositsWithdrawals: null,
    bonuses: null,
    spins: null,
    show: false,
    generatingCsv: false,
    emptyCsv: false,
    taxes: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.transactions.RECEIVED_HISTORY_TRANSACTIONS:
            let respObject = {
                ...state,
                show: true,
                responseType: action.responseType,
                emptyCsv: false,
                generatingCsv: false,
            };

            switch (action.responseType) {
                case 1:
                    respObject.spins = action.pageData;
                    respObject.depositsWithdrawals = null;
                    respObject.bonuses = null;
                    break;
                case 2:
                    respObject.depositsWithdrawals = action.pageData;
                    respObject.bonuses = null;
                    respObject.spins = null;
                    break;
                case 3:
                    respObject.depositsWithdrawals = null;
                    respObject.bonuses = action.pageData;
                    respObject.spins = null;
                    break;
                default:
                    respObject.depositsWithdrawals = null;
                    respObject.bonuses = null;
                    respObject.spins = null;
                    break;
            }

            return respObject;
        case actionTypes.transactions.REQUEST_HISTORY_LIST:
            return {
                ...state,
                show: false,
                responseType: null,
            };
        case actionTypes.transactions.GENERATING_CSV_DOWNLOAD:
            return {
                ...state,
                generatingCsv: action.generating,
            };
        case actionTypes.transactions.EXPORT_IS_EMPTY:
            return {
                ...state,
                emptyCsv: true,
                generatingCsv: false,
            };
        case actionTypes.withdrawals.REQUEST_TAX_AMOUNT:
            if (action.id) {
                let taxes = state.taxes;
                taxes[action.id] = false;
                return  {
                    ...state,
                    taxes: taxes
                }
            }
            return state;
        case actionTypes.withdrawals.UPDATE_TAX_AMOUNT:
            if (action.id) {
                let taxes = state.taxes;
                taxes[action.id] = action.taxAmount;
                return  {
                    ...state,
                    taxes: taxes
                }
            }
            return state;
        default:
            return state;
    }
};

export default reducer;
