import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./SkeletonLoader.scss";
import Match from "../../../../bets/components/Match";
import { makeGetStateMatch } from "../../../../bets/store/selectors/betData";

const SkeletonLoader = (props) => {
  const [loaded, setLoaded] = React.useState(props.preloaded ? true : false);

  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    let tid = setTimeout(() => {
      if (!isMounted.current) return;
      setLoaded(true);
    }, 100);
    return () => {
      isMounted.current = false;
      clearTimeout(tid);
    }
  }, []); // eslint-disable-line

  if (!loaded) {
    return <div className="skeleton-loader-live">
      <div className="sk-bar"></div>
      <div className="sk-header sk-loading"></div>
      <div className="sk-content-wrapper">
        <div className="sk-date sk-loading"></div>
        <div className="sk-content sk-loading"></div>
      </div>
      <div className="sk-progresses">
        <div className="sk-progress sk-loading"></div>
        <div className="sk-progress sk-loading"></div>
      </div>
      <div className="sk-bets sk-loading"></div>
      <div className="sk-buttons">
        <div className="sk-button sk-loading"></div>
        <div className="sk-button sk-loading"></div>
        <div className="sk-button sk-loading"></div>
      </div>
    </div>;
  }

  const navigateCard = () => {
    const url = props.row && props.row.link && props.row.link.url;
    if (url.toLowerCase().indexOf("http") > -1) {
      window.location = url;
    } else {
      props.history.push(url);
    }
  };

  if (!props.hasMatch) return null;

  return (
    <div className="grid-item-match drop-shadow">
    <Match
      idMatch={props.idMatch}
      mType={props.mType}
      showFav={true}
      matchGroup={"starting_soon_selection"}
      dark={false}
      topLiveModel={true}
      noDescription={true}
        noStatScore={true}
        showMatchDetails={props.row.link && props.row.link.url ? navigateCard(props.row.link.url) : null}
    />
    </div>
  );
}

const getStateMatch = makeGetStateMatch();
const mapStateToProps = (state, props) => {
  const stateMatch = getStateMatch(state, props);
  let hasMatch = false;
  if (stateMatch) hasMatch = true;

  return { hasMatch };
};

export default withRouter(connect(mapStateToProps)(SkeletonLoader));