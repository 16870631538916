import { betsSlipConstants } from './constants';
import { getBetsState } from '../selectors/betData';
//import { makeGetMatches } from "../selectors/betData";
import cloneDeep from 'lodash/fp/cloneDeep';

export const betsSlipLoad = () => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.LOAD,
		stateReady: bst.prematch.fullStateLoaded && bst.live.fullStateLoaded,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipAdd = (mType, idSport, idMatch, idBet, idMb, idBo, idMbo, match) => (
	dispatch,
	getState
) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.ADD,
		mType,
		idSport,
		idMatch,
		idBet,
		idMb,
		idBo,
		idMbo,
		match,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipRemove = (mType, idMbo) => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.REMOVE,
		mType,
		idMbo,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipToggle = (mType, idSport, idMatch, idBet, idMb, idBo, idMbo) => (
	dispatch,
	getState
) => {
	const bst = getBetsState(getState());

	let m = null;

	if (mType === 'live') {
		if (idMatch in bst.live.matches) {
			m = bst.live.matches[idMatch];
		}
	} else {
		if (idMatch in bst.prematch.matches) {
			m = bst.prematch.matches[idMatch];
		}
	}

	if (m === null) {
		return;
	}

	const cm = cloneDeep(m);

	//console.log("match", match, "m", m, "cm", cm);

	dispatch({
		type: betsSlipConstants.TOGGLE,
		mType,
		idSport,
		idMatch,
		idBet,
		idMb,
		idBo,
		idMbo,
		match: cm,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		},
		combiningAllowed: bst.nsoft.combiningAllowed
	});
};

export const betsSlipClear = () => ({
	type: betsSlipConstants.CLEAR
});

export const betsSlipStakeInc = stake => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.STAKE_INC,
		stake,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipStakeDec = stake => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.STAKE_DEC,
		stake,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipStakeSet = stake => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.STAKE_SET,
		stake,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipComputeWin = () => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.COMPUTE_WIN,
		dontResetError: true,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipToggleFixed = (mType, idMbo) => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.TOGGLE_FIXED,
		mType,
		idMbo,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipToggleSystem = (mType, system, provider) => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.TOGGLE_SYSTEM,
		mType,
		system,
		provider,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipAcceptOddChange = (mType, idMbo) => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.ACCEPT_ODD_CHANGE,
		mType,
		idMbo,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

const dispatchWithMatches = obj => {
	return (dispatch, getState) => {
		const bst = getBetsState(getState());

		obj.matches = {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		};

		dispatch(obj);
	};
};

export const betsSlipToggleAutoAcceptOddChange = () => dispatchWithMatches({
	type: betsSlipConstants.TOGGLE_AUTO_ACCEPT_ODD_CHANGE
});

export const betsSlipToggleAllowTicketPlaceWithInvalidEvents = () => dispatchWithMatches({
	type: betsSlipConstants.TOGGLE_ALLOW_TICKET_PLACE_WITH_INVALID_EVENTS,
});

export const betsSlipPrematchCreateTicketSuccess = code => ({
	type: betsSlipConstants.PREMATCH_CREATE_TICKET_SUCCESS,
	code
});

export const betsSlipPrematchCreateTicketReoffer = data => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.PREMATCH_CREATE_TICKET_REOFFER,
		data,
		mType: 'prematch',
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipPrematchCreateTicketError = (error, details) => ({
	type: betsSlipConstants.PREMATCH_CREATE_TICKET_ERROR,
	error,
	details
});

export const betsSlipLiveCreateTicketSuccess = code => ({
	type: betsSlipConstants.LIVE_CREATE_TICKET_SUCCESS,
	code
});

export const betsSlipLiveCreateTicketPending = (index, requestUuid) => ({
	type: betsSlipConstants.LIVE_CREATE_TICKET_PENDING,
	index,
	requestUuid
});

export const betsSlipLiveCreateTicketSettle = data => ({
	type: betsSlipConstants.LIVE_CREATE_TICKET_SETTLE,
	data
});

export const betsSlipLiveCreateTicketError = (error, details) => ({
	type: betsSlipConstants.LIVE_CREATE_TICKET_ERROR,
	error,
	details
});

export const betsSlipSetTicketType = ticketType => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.SET_TICKET_TYPE,
		ticketType,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipSetTicketOnline = online => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.SET_TICKET_ONLINE,
		online,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipAddLottoTicket = ticket => ({
	type: betsSlipConstants.LOTTO_ADD_TICKET,
	ticket
});

export const betsSlipClearLottoTicket = () => ({
	type: betsSlipConstants.LOTTO_CLEAR_TICKET
});

export const betsSlipClearCreateStatus = () => ({
	type: betsSlipConstants.CLEAR_CREATE_STATUS
});

export const betsSlipPrematchEvalTicket = index => ({
	type: betsSlipConstants.PREMATCH_EVAL_TICKET,
	index
});

export const betsSlipPrematchEvalTicketStr = (evalStr, index) => ({
	type: betsSlipConstants.PREMATCH_EVAL_TICKET_STR,
	evalStr,
	index
});

export const betsSlipPrematchEvalTicketData = (data, index) => ({
	type: betsSlipConstants.PREMATCH_EVAL_TICKET_DATA,
	data,
	index
});

export const betsSlipSetCurrentTicket = index => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.SET_CURRENT_TICKET,
		index,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsSlipCopyCurrentTicket = (switchToTab) => ({
	type: betsSlipConstants.COPY_CURRENT_TICKET,
	switchToTab
});

export const betsSlipClearCopyStatus = () => ({
	type: betsSlipConstants.CLEAR_COPY_STATUS
});

export const betsBonusEvaluateResponse = (bonusEvaluate) => ({
	type: betsSlipConstants.BONUS_EVALUATE_RESPONSE,
	bonusEvaluate
});

export const betsBonusRequestEvaluation = (force) => (dispatch, getState) => {
	const state = getState();

	dispatch({
		type: betsSlipConstants.REQUEST_BONUS_EVALUATION,
		wallet: state.wallet,
		profile: state.profile,
		authentication: state.authentication,
		force: force ? true : false
	});
};

export const betsWinnerFunEvaluateResponse = (winnerFunEvaluate) => ({
	type: betsSlipConstants.WINNER_FUN_EVALUATE_RESPONSE,
	winnerFunEvaluate
});

export const betsFreeBetsEvaluateResponse = (freeBetsEvaluate) => ({
	type: betsSlipConstants.FREE_BET_EVALUATE_RESPONSE,
	freeBetsEvaluate
});

export const betsTournamentEvaluateResponse = (tournamentEvaluate) => ({
	type: betsSlipConstants.TOURNAMENT_EVALUATE_REQUEST,
	tournamentEvaluate
});


export const betsSlipToggleWinnerFunBoost = force => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.TOGGLE_WINNER_FUN_BOOST,
		force,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betSlipSetFreeBetIndex = (index, freeBet, subIndex) => ({
	type: betsSlipConstants.FREE_BET_SELECTED_INDEX,
	index: index,
	freeBet: freeBet,
	subIndex: subIndex,
});

export const betSlipFreeBetClear = () => ({
	type: betsSlipConstants.FREE_BET_SELECTED_INDEX,
	index: -1,
	freeBet: null,
	subIndex: -1,
});

export const betsSlipPrematchMatchesLoaded = idRequest => (dispatch, getState) => {
	const bst = getBetsState(getState());

	dispatch({
		type: betsSlipConstants.PREMATCH_MATCHES_LOADED,
		idRequest,
		matches: {
			live: bst.live.matches,
			prematch: bst.prematch.matches
		}
	});
};

export const betsDigitainBonusEvaluateResponse = (bonusEvaluate) => ({
	type: betsSlipConstants.DIGITAIN_BONUS_EVALUATE_RESPONSE,
	bonusEvaluate
});
