import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/Add";
import ExpandLessIcon from "@material-ui/icons/Remove";
import isEqual from "lodash/fp/isEqual";
import LockIcon from "@material-ui/icons/Lock";
import StarIcon from "@material-ui/icons/Star";

import { getLiveSportsListRetail, getLiveMatches, getBetsState } from "../../store/selectors/betData";
import { favoriteToggleLiveMatch } from "../../../bets/store/actions/favorite";
import { queryMatches } from "../../store/selectors/search";

import Icons from "../Icons";

import { stdLiveScore } from "../../utils/matchUtils";
import { navSportsUpdate } from "../../store/actions/navSports";
import TabsMenu from "../TabsMenu";
import { makeSort2Keys } from "../../utils";

import "./leftnav.scss";
import "./icons-nsoft.scss";
import "./icons-digitain.scss";

const filterActive = allLiveMatches => {
  return Object.values(allLiveMatches).reduce((acc, m) => {
    if (!m.active) return acc;
    acc[m.idMatch] = m;
    return acc;
  }, {});
};

const NavSports = props => {
  const {
    search,
    navIdSport,
    liveSports,
    allLiveMatches,
    history,
    location,
    favorite,
    favoriteToggleLiveMatch,
    t,
    navSportsUpdate,
    baseUrlPath,
  } = props;

  //console.log("NavSports", props);

  const basePath = `${baseUrlPath}/live-match`;

  const [state, setState] = React.useState({
    sports: [],
    leagues: [],
    sportStates: {},
    categoryStates: {},
  });

  React.useEffect(() => {
    setState(v => {
      let isModified = false;
      let tmp = { ...v };
      if (!isEqual(v.sports, liveSports)) {
        tmp.sports = liveSports;
        isModified = true;
      }
      if (isModified) return tmp;
      return v;
    });
  }, [liveSports]);

  React.useEffect(() => {
    if (navIdSport !== "0") {
      setState(v => {
        if (!v.sportStates[navIdSport]) {
          let tmp = { ...v };
          tmp.sportStates[navIdSport] = true;
          return tmp;
        }
        return v;
      });
    }
  }, [navIdSport]);

  const [active, setActive] = React.useState({
    idSport: "",
    idCategory: "",
    idTournament: "",
    idMatch: "",
    path: "",
    extended: false,
  });

  const [index, setIndex] = React.useState(0); // eslint-disable-line
  const handleMenuChange = index => {
    setIndex(index);
    if (index === 0) {
      setActive(v => ({ ...v, extended: false }));
    } else {
      setActive(v => ({ ...v, extended: true }));
      const path = location.pathname;
      if (path.indexOf(`${basePath}/match/live`) === -1) {
        let idMatch = null;
        try {
          const matches = state.sports[0].categories[0].tournaments[0].matches;
          idMatch = Object.keys(matches)[0];
        } catch (err) {}
        if (idMatch) {
          history.push(`${basePath}/match/live/${idMatch}`);
        }
      }
    }
  };

  React.useEffect(() => {
    const path = location.pathname;
    const params = path.split("/").filter(v => !!v);

    const tmp = {
      idSport: "",
      idCategory: "",
      idTournament: "",
      path,
      extended: false,
    };

    if (path.indexOf(`${basePath}/match/live`) > -1) {
      tmp.idMatch = params[params.length - 1];
      tmp.extended = true;
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });
      setIndex(1);
    } else if (path.indexOf(`${basePath}`) > -1) {
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });
      setIndex(0);
    }
  }, [location, basePath]);

  const [liveMatches, setLiveMatches] = React.useState(filterActive(allLiveMatches));
  const totalMatches = liveMatches ? Object.keys(liveMatches).length : 0;
  const menuItems = [
    { sportName: t("All") + (totalMatches ? ` (${totalMatches})` : "") },
    { sportName: t("Event View") },
  ];

  React.useEffect(() => {
    const am = filterActive(allLiveMatches);

    if (search === null || search === "") {
      setLiveMatches(am);
      return;
    }

    const ms = queryMatches(am, search, true);
    setLiveMatches(ms);
  }, [search, allLiveMatches]);

  const toggleState = (idSport, idCategory) => e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();

    if (idSport) {
      setState(v => {
        const sportStates = { ...v.sportStates };
        if (typeof sportStates[idSport] === "undefined") {
          sportStates[idSport] = true;
        } else {
          sportStates[idSport] = !sportStates[idSport];
        }
        return {
          ...v,
          sportStates,
        };
      });
    }
    if (idCategory) {
      setState(v => {
        const categoryStates = { ...v.categoryStates };
        if (typeof categoryStates[idCategory] === "undefined") {
          categoryStates[idCategory] = true;
        } else {
          categoryStates[idCategory] = !categoryStates[idCategory];
        }
        return {
          ...v,
          categoryStates,
        };
      });
    }
  };

  const handleNavigate = (idSport, idCategory, idTournament) => () => {
    navSportsUpdate("live", idSport);

    if (idSport && idCategory && idTournament) {
      const leagueData = `${idSport}_${idCategory}_${idTournament}`;

      history.push(`${basePath}/highlights?f=${leagueData}`);
    } else if (idSport && idCategory) {
      const categoryData = `${idSport}_${idCategory}`;

      history.push(`${basePath}/highlights?f=${categoryData}`);
    } else if (idSport) {
      history.push(`${basePath}/highlights`);
    }
  };
  const navigateTo = link => () => {
    history.push(`${basePath}/match/live/${link}`);
  };
  const handleToggleFavoriteMatch = idMatch => e => {
    favoriteToggleLiveMatch(idMatch);
    e.stopPropagation();
  };

  const getFlag = category => {
    if (category.categoryDLId) {
      return `digflag f${category.categoryDLId}`;
    }
    return `flag flag-${category.categoryIsoName ? category.categoryIsoName.toLowerCase() : "undef"}`;
  };

  const buildNavList = () => {
    const list = [];

    const query = new URLSearchParams(location.search);
    const leagueId = query.get("f");

    let qactiveCategory = "";
    let qactiveTournament = "";
    if (leagueId !== null && leagueId !== "") {
      const lidp = leagueId.split("_");
      if (lidp.length === 3) {
        qactiveCategory = lidp[1];
        qactiveTournament = lidp[2];
      } else if (lidp.length === 2) {
        qactiveCategory = lidp[1];
      }
    }

    state.sports.forEach((sport, i) => {
      const idSport = sport.idSport;

      let isSportExpanded = state.sportStates[idSport] ? true : false;
      let activeSport = "";

      if (navIdSport === idSport) {
        activeSport = "active";
      }

      let expandIcon = <ExpandMoreIcon />;
      if (isSportExpanded) {
        expandIcon = <ExpandLessIcon />;
      }

      list.push(
        <div
          className={`navigation-item sport ${idSport === navIdSport ? "active" : ""} sport-border-color-${idSport}`}
          key={`sport_${idSport}`}
          title={sport.sportName}
          onClick={handleNavigate(idSport)}
        >
          <div className="icon">{Icons.get(idSport, "", "live")}</div>
          <div className="label">{sport.sportName}</div>
          <div className="match-count">{sport.matchCount}</div>
          <span className="navigation-item-arrow" onClick={toggleState(idSport)}>
            {expandIcon}
          </span>
        </div>
      );

      if (isSportExpanded) {
        sport.categories.forEach((category, k) => {
          const idCategory = category.idCategory;
          let isCategoryExpanded = state.categoryStates[idCategory] ? true : false;

          let activeCategory = "";

          if (qactiveCategory === idCategory) {
            activeCategory = "active";
          }

          let expandIcon = <ExpandMoreIcon />;
          if (isCategoryExpanded) {
            expandIcon = <ExpandLessIcon />;
          }

          list.push(
            <div
              className={`navigation-item category ${activeCategory}`}
              key={`category_${idSport + idCategory}`}
              title={category.categoryName}
              onClick={handleNavigate(idSport, idCategory)}
            >
              <span className="icon adjust">
                <div className={getFlag(category)}></div>
              </span>
              <div className="label">{category.categoryName}</div>
              <div className="match-count">{category.matchCount}</div>
              <span className="navigation-item-arrow" onClick={toggleState(null, idCategory)}>
                {expandIcon}
              </span>
            </div>
          );

          if (isCategoryExpanded) {
            const keys = {};
            category.tournaments.forEach(t => (keys[t.idTournament] = t));

            const tournamentKeys = Object.keys(keys);
            tournamentKeys.sort(makeSort2Keys(keys, "tournamentPosition", "tournamentName"));

            tournamentKeys.forEach((t, j) => {
              const tournament = keys[t];
              const idTournament = tournament.idTournament;

              let activeTournament = "";

              if (qactiveTournament === idTournament) {
                activeTournament = "active";
              }

              list.push(
                <div
                  className={`navigation-item tournament ${activeTournament}`}
                  key={`tournament_${idSport + idCategory + idTournament}`}
                  title={tournament.tournamentName}
                  onClick={handleNavigate(idSport, idCategory, idTournament)}
                >
                  <div className="label">{tournament.tournamentName}</div>
                  <div className="match-count">{tournament.matchCount}</div>
                </div>
              );
            });
          }
        });
      }
    });

    return (
      <div>
        <div className="navigation-group navigation-group-2">Sports</div>
        {list}
      </div>
    );
  };

  const buildExtendedNavList = () => {
    const listTable = [];

    if (favorite.favorite_live_matches.length) {
      const matchesList = [];
      favorite.favorite_live_matches.forEach((idMatch, i) => {
        const m = liveMatches[idMatch];
        if (!m) return;
        const matchStatus = m.currentStatus;
        const scoreData = stdLiveScore(m, matchStatus);

        // const score = scoreData.score.length ? scoreData.score[scoreData.score.length - 1] : ["-", "-"];
        const score = matchStatus.score ? matchStatus.score.split(":").map(s => parseInt(s, 10)) : ["-", "-"];
        const selected = active.idMatch === m.idMatch;

        matchesList.push(
          <div className={`table-row favorites-border-color`} key={`match_${i}`} onClick={navigateTo(`${m.idMatch}`)}>
            <div className={`match-item match-item-time ${selected ? "active" : ""}`}>
              <div className="match-time-interval">
                {/*scoreData.intervalLetter + scoreData.currentInterval*/}
                {scoreData.intervalName}
              </div>
              <div className="match-time">{scoreData.intervalTime ? scoreData.intervalTime : ""}</div>
              <div className="match-favorite active" onClick={handleToggleFavoriteMatch(m.idMatch)}>
                <StarIcon />
              </div>
              <div className="match-title">
                <div className="match-team">
                  <span title={m.team1Name}>{m.team1Name}</span>
                  <div className="match-result">{score[0]}</div>
                </div>
                <div className="match-team">
                  <span className="match-team-name" title={m.team2Name}>
                    <span>{m.team2Name}</span>
                  </span>
                  <div className="match-result">{score[1]}</div>
                </div>
              </div>
              {(!m.bettingStatus || (m.matchBets && m.matchBets.length === 0)) && (
                <div className="match-locked">
                  <LockIcon />
                </div>
              )}
            </div>
          </div>
        );
      });

      if (matchesList.length) {
        listTable.push(
          <div className="table" key={`nav_fav`}>
            <div className={`table-header favorites-header-color`}>
              <div className="table-title">{t("Favorites")}</div>
            </div>
            <div className="table-body">{matchesList}</div>
          </div>
        );
      }
    }

    //console.log("state", state);

    state.sports.forEach(s => {
      const matchesList = [];
      let matchCount = 0;

      //console.log("state.sportStates", state.sportStates);

      let isSportExpanded = state.sportStates[s.idSport] ? true : false;

      const matches = [];

      if (s.categories) {
        s.categories.forEach(c => {
          if (!c.tournaments) return;
          c.tournaments.forEach(t => {
            if (!t.matches) return;
            Object.keys(t.matches).forEach(k => {
              const m = liveMatches[k];
              if (!m) return;

              matchCount++;

              const favActive = favorite.favorite_live_matches.indexOf(m.idMatch) > -1 ? true : false;
              if (favActive) return;
              if (!isSportExpanded) return;

              const tmp = { ...m };
              const matchStatus = tmp.currentStatus;
              const scoreData = stdLiveScore(tmp, matchStatus);
              tmp.scoreData = scoreData;

              let ci = scoreData.currentInterval * 1000;
              let ct = !Number.isNaN(parseInt(scoreData.intervalTime)) ? parseInt(scoreData.intervalTime) : 0;

              if (scoreData && scoreData.intervalName && scoreData.intervalName === "P" && tmp.idSport === "1") {
                if (tmp.extraInfo && tmp.extraInfo.PeriodLength) {
                  ct = scoreData.currentInterval * parseInt(tmp.extraInfo.PeriodLength, 10);
                  tmp.scoreData.intervalTime = ct + "'";
                } else {
                  ci = (scoreData.currentInterval + 1) * 1000;
                  ct = 0;
                }
              }

              tmp.progress = ci + ct;
              matches.push(tmp);
            });
          });
        });
      }

      //if (s.idSport === "1") console.log("tmpProgress", matches);

      if (isSportExpanded) {
        matches.sort((a, b) => (a["progress"] > b["progress"] ? -1 : 1));
        matches.forEach((m, i) => {
          const favActive = favorite.favorite_live_matches.indexOf(m.idMatch) > -1 ? true : false;
          if (favActive) return;
          if (!isSportExpanded) return;

          const matchStatus = m.currentStatus;
          const scoreData = m.scoreData;

          // const score = scoreData.score.length ? scoreData.score[scoreData.score.length - 1] : ["-", "-"];
          const score = matchStatus.score ? matchStatus.score.split(":").map(s => parseInt(s, 10)) : ["-", "-"];
          const selected = active.idMatch === m.idMatch;

          matchesList.push(
            <div
              className={`table-row sport-border-color-${s.idSport}`}
              key={`match_${i}`}
              onClick={navigateTo(`${m.idMatch}`)}
            >
              <div className={`match-item match-item-time ${selected ? "active" : ""}`}>
                <div className="match-time-interval">
                  {/*scoreData.intervalLetter + scoreData.currentInterval*/}
                  {scoreData.intervalName}
                </div>
                <div className="match-time">{scoreData.intervalTime ? scoreData.intervalTime : ""}</div>
                <div className="match-favorite" onClick={handleToggleFavoriteMatch(m.idMatch)}>
                  <StarIcon />
                </div>
                <div className="match-title">
                  <div className="match-team">
                    <span title={m.team1Name}>{m.team1Name}</span>
                    <div className="match-result">{isNaN(score[0]) ? "-" : score[0].toString()}</div>
                  </div>
                  <div className="match-team">
                    <span className="match-team-name" title={m.team2Name}>
                      <span>{m.team2Name}</span>
                    </span>
                    <div className="match-result">{isNaN(score[1]) ? "-" : score[1].toString()}</div>
                  </div>
                </div>
                {(!m.bettingStatus || (m.matchBets && m.matchBets.length === 0)) && (
                  <div className="match-locked">
                    <LockIcon />
                  </div>
                )}
              </div>
            </div>
          );
        });
      }

      //console.log("matchesList", matchesList);

      if (matchCount > 0) {
        let expandIcon = <ExpandMoreIcon />;
        if (isSportExpanded) {
          expandIcon = <ExpandLessIcon />;
        }

        listTable.push(
          <div className="table" key={`nav_sport_${listTable.length}`}>
            <div className={`table-header sport-color-${s.idSport}`} onClick={toggleState(s.idSport)}>
              <div className="icon ">{Icons.get(s.idSport, "", "live")}</div>
              <div className="table-title">{s.sportName}</div>
              <div className="match-count">{matchCount}</div>
              <span className="navigation-item-arrow">{expandIcon}</span>
            </div>
            <div className="table-body">{matchesList}</div>
          </div>
        );
      }
    });

    return (
      <React.Fragment>
        {listTable.length > 0 && <div className="table-container">{listTable}</div>}
        {listTable.length === 0 && <div className="empty">{t("No Events Available")}</div>}
      </React.Fragment>
    );
  };

  let content = null;
  if (active.extended) {
    content = buildExtendedNavList();
  } else {
    if (search === null || search === "") {
      content = buildNavList();
    } else {
      content = buildExtendedNavList();
    }
  }

  return (
    <div>
      <TabsMenu
        className={`bets-left-nav-tabs-menu`}
        value={index}
        tabs={menuItems}
        onChange={handleMenuChange}
        centered
      />
      <div className={`bets-left-nav live`}>{content}</div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const init = {
    mType: "live",
  };
  return {
    navIdSport: bst.navSports.live,
    liveSports: getLiveSportsListRetail(state, init),
    allLiveMatches: getLiveMatches(state, init),
    favorite: bst.favorite,
    baseUrlPath: bst.app.basePath,
  };
};

const actionCreators = {
  favoriteToggleLiveMatch,
  navSportsUpdate,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(NavSports)));
