import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";

import EventTimer from "./Timer";
import evaluateTicket from "../../utils/evaluateTicket";


const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    margin: "15px 7px 0px 7px",
    padding: "9px 13px",
  },
  eventName: {
    fontSize: "10px",
    lineHeight: "10px",
    color: "#9EA1AC",
    position: "relative",
    top: "-2px"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between"
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  title: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
  },
  description: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#17214D",
  },
  ticketData: {
    flex: "1 1 0",
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "right",
    color: "#000",
    padding: "3px 0 4px",
    display: "inline-block",
    whiteSpace: "nowrap"
  },
  won: {
    borderRight: "3px solid #06D2BD",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block",
    "& svg": {
      display: "inline-block"
    }
  },
  lost: {
    borderRight: "3px solid #FF196E",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block"
  },
  possibleWin: {
    display: "inline-block",
    marginRight: "10px"
  },
  rightSvg: {
    height: "14px",
    width: "auto",
    fill: "#7f7f7f",
    display: "inline-block",
    verticalAlign: "middle",
    float: "right",
    position: "relative",
    top: "2px"
  },
  hr: {
    borderTop: "1px solid #B7CDD8",
    height: "1px",
    width: "100%",
    margin: "10px auto"
  },
  balls: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto"
  },
  ball: {
    width: "29px",
    minWidth: "29px",
    maxWidth: "29px",
    height: "29px",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#121934",
    lineHeight: "24px",
    margin: "3px 0",
    border: "1.5px solid #3D4D8E",
    background: "#fff",
  },
  ballsFlexStart: {
    justifyContent: "flex-start",
    "&>div": {
      margin: "3px 2px",
    }
  },
  ballWinner: {
    color: "#fff",
    border: "1.5px solid #00C7B2",
    background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)",
  },
  ballWinnerNow: {
    color: "#fff",
    border: "1.5px solid #fff",
    background: "linear-gradient(180deg, #F20732 0%, #F2CA00 100%)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.57)"
  },
  ballEmpty: {
    color: "#E9E9E9",
    border: "1.5px solid #E9E9E9",
    background: "#fff",
  },
  ballLost: {
    border: "1.5px solid #FF196E",
    background: "#FF196E",
    color: "#fff",
  },
  infoPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "13px",
  },
  timer: {

  },
  timerTitle: {
    fontSize: "11px",
    fontWeight: "300",
    color: "#5A6798",
  },
  stake: {
    backgroundColor: "#E6EEF1",
    borderRadius: "12px",
    padding: "12px 34px"
  },
  stakeHeader: {
    textAlign: "center",
    fontSize: "9px",
    fontWeight: "600",
    color: "#586682",
  },
  stakeAmount: {
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bold",
    color: "#17214D",
  },
  results: {
    margin: "15px -5px",
    borderRadius: "7px",
    padding: "2px 5px",
    backgroundColor: "#E6EEF1"
  },
  link: {
    color: "#17214D",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    margin: "10px 0"
  },
  code: {
    textAlign: "center",
    padding: "20px 0 10px",
    fontSize: "14px",
    fontWeight: "600",
  },
  codeBold: {
    fontWeight: "bold",
  }
});

const TicketReserved = (props) => {
  const classes = useStyles();
  const { ticket, className, t } = props;

  let rootCls = classes.root;
  if (className) {
    rootCls += ` ${className}`;
  }

  let description = '';
  if (ticket.systems && ticket.systems.length) {
    if (ticket.systems.length === 1 && ticket.numbers >= ticket.systems[0]) {
      description = `| ${t("SIMPLE")}`;
    } else {
      description = `| ${t("SYSTEM")} ${ticket.systems.join(",")}/${ticket.event_M}`;
    }
  }

  const res = evaluateTicket({
    ...ticket,
    stake_amount: ticket.amount - ticket.amount * 0.05,
    max_winning: 100000,
  });

  let amount = res.max_winning;
  const event_date = moment(ticket.event_time / 1e+6).format("YYYY-MM-DD HH:mm:ss");

  const numbers = [];
  const winner_numbers = {};
  ticket.numbers.forEach(no => {
    numbers.push({ n: no, s: 0 });
    winner_numbers[no] = true;
  });

  return (
    <div className={rootCls}>
      <div className={classes.eventName}>{ticket.event_name} #{ticket.event_code}</div>
      <div className={classes.header}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>{t("LOTTO")}</div>
          <div className={classes.description}>&nbsp;{description}</div>
        </div>
        <div className={classes.ticketData}>
          <div className={classes.possibleWin}>{amount} Lei</div>
        </div>
      </div>
      <div className={classes.hr}></div>
      <div className={classes.infoPanel}>
        <div className={classes.timer}>
          <EventTimer date={event_date} />
        </div>
        <div className={classes.stake}>
          <div className={classes.stakeHeader}>{t("Stake")}</div>
          <div className={classes.stakeAmount}>{ticket.amount} Lei</div>
        </div>
      </div>
      <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>
        {[...Array(ticket.event_R > 10 ? 10 : ticket.event_R).keys()].map(i => {
          if (typeof numbers[i] !== "undefined") {
            const no = numbers[i];
            const rootCls = `${classes.ball}`;
            return <div key={i} className={rootCls}>{no.n}</div>;
          }
          return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
        })}
      </div>
      {ticket.event_R > 10 && <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>
        {[...Array(ticket.event_R - 10).keys()].map(i => {
          const ix = i + 10;
          if (typeof numbers[ix] !== "undefined") {
            const no = numbers[ix];
            const rootCls = `${classes.ball}`;
            return <div key={i} className={rootCls}>{no.n}</div>;
          }
          return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
        })}
      </div>}
      <div className={classes.code}>Reserved Code: <span className={classes.codeBold}>{ticket.reserve_id}</span></div>
    </div>
  );
}

TicketReserved.propTypes = {
  ticket: PropTypes.object,
  className: PropTypes.string,
};

TicketReserved.defaultProps = {
  ticket: {},
  className: ""
};

export default withTranslation()(TicketReserved);