import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./top_live.scss";

import { getText, styleToObj, openItem, getIndexById } from "../../utils/functions";
import Match from "../../../bets/components/Match";
import { makeGetLiveMatchState } from "../../store/selector/lobbyData";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const TopLive = (props) => {
  const { data, i18n, source, liveMatches } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, data.thumbnail_size, source]);

  if (items.length === 0) return null;

  const filteredItems = items.filter(item => {
    if (item.mType && item.idMatch) {
      if (item.mType === "live" && typeof liveMatches[item.idMatch] !== "undefined") return true;
    }
    return false;
  });

  if (filteredItems.length === 0) return null;
  let sliceNo = 5;

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;
  const hasTitle = getText(data, `title.text.${lang}`, "") !== "";

  return (
    <div className={`component-top_live component`}
      style={styleToObj(data.style)}
    >

      {(hasTitle || hasNavigate) && <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          {filteredItems.length !== 0 && <span>({filteredItems.length})</span>}
          <LobbyArrow />
        </div>}
      </div>}

      <div className="cgl-list with-scroll-style" style={styleToObj(data.data_source_style)}>
        <div className={`cgl-grid`}>
          {filteredItems.slice(0, sliceNo).map((item, i) => {
            const realIndex = getIndexById(items, item.id);
            const itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;

            return <div className={`cgl-item`} key={i} style={itemStyle}>
              <Match
                idMatch={item.idMatch}
                mType={item.mType}
                showFav={true}
                matchGroup={"top_live_selection"}
                dark={false}
                topLiveModel={true}
                noDescription={true}
                noStatScore={true}
              />
            </div>;
          })}
        </div>
      </div>

    </div>
  );
}

const getLiveMatches = makeGetLiveMatchState();

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
    liveMatches: getLiveMatches(state, props),
  }
};

export default withTranslation()(connect(mapStateToProps)(TopLive));