import { gamesConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  items: [],
  loading: false,
};

const gamesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case gamesConstants.LOADED: {
        draft.items = action.games ? action.games : [];
        draft.loading = false;
        break;
      }
      case gamesConstants.LOADING: {
        draft.loading = true;
        break;
      }
      default:
      /* noop */
    }
  });

export default gamesReducer;
