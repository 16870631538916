import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from './../../../store/actions/';
import Icons from './../../../assets/Icons/Icons';
import './CodeCountdown.css';
import { withTranslation } from "react-i18next";

class CodeCountdown extends Component {
    constructor(props) {
        super(props);

        this.startSec = 20;
        this.endSec = 0;

        this.started = false;

        this.timer = 0;

        this.running = false;
    }

    state = {
        sec: 0
    };

    componentWillReceiveProps() {
        if ("username" === this.props.authenticationModal.step) {
            this.running = false;
        }
    }

    componentDidUpdate() {
        if ("password" === this.props.authenticationModal.step) {
            this.start();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.started = false;
    }

    start() {
        if (this.running) {
            return;
        }

        this.running = true;
        this.started = true;

        this.props.onCodeRequest(this.props.authenticationModal.username);

        this.setState({
            sec: this.startSec
        });

        this.timer = setTimeout(() => {
            this.update.bind(this)();
        }, 1000);
    }

    update() {
        clearTimeout(this.timer);
        if (!this.started) {
            return;
        }

        let actualCount = this.state.sec - 1;
        if (!(actualCount >= this.endSec)) {
            this.started = false;
            return;
        }

        this.setState({
            sec: actualCount
        });

        this.timer = setTimeout(() => {
            this.update.bind(this)();
        }, 1000);
    }

    onResendHandler() {
        this.running = false;
        this.start();
    }

    render() {
        let alertIcon = Icons.get('modal-blue-alert', 'code-blue-icon');

        let componentContent = "";
        if (this.state.sec > 0) {
            componentContent = <p>
                <span className="ccAlertIcon">{alertIcon}</span>
                {this.props.t("If you have not yet received the SMS Code, you may request a new one in")} {this.state.sec} {this.props.t("seconds")}.
            </p>;
        }

        if (this.state.sec === 0) {
            componentContent = <p className="text-center">
                <a href="#resend-sms-code" className="login_register_link" onClick={this.onResendHandler.bind(this)}>
                    <span>{this.props.t("Send a new SMS code for login.")}</span>
                </a>
            </p>;
        }

        return (
            <div className="CodeCountdown">
                {componentContent}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCodeRequest: (number) => dispatch(stateActions.requestSmsCode(number)),
    };
};

const mapStateToProps = state => {
    return {
        authenticationModal: state.authModal
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CodeCountdown));