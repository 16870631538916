import React from 'react';
import Button from "@material-ui/core/Button";
import connect from "react-redux/es/connect/connect";
import { withTranslation } from 'react-i18next';
import Moment from "react-moment";
import moment from "moment";

import Icons from "../../../../../assets/Icons/Icons";
import { freeBetsFetchHistory } from "../../../../../store/actions/free_bets";
import { fetchFreeBetsDumpHistory } from "../../../../../store/sagas/free_bets";

const visibleStyle = {
  maxHeight: "0px",
  overflow: "visible"
};

const exportToCsv = (filename, rows) => {
  const processRow = row => {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const formatCurrency = (value, small) => {
  if (small === 1) value = value / 100;

  return (
    value
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      .replace(',00', '')
  )
};

const processDump = (list, t) => {
  const listData = [];

  const bonusStatus = {
    4: t("canceled"),
    6: t("collected"),
  };

  const handleList = (bonus) => {
    let name = bonus.name;

    let title = null;
    let amount = null;
    if (bonus.status === 4) {
      title = `${bonus.count} x ${formatCurrency(bonus.amount, bonus.amount_small) + " RON"}`;
      amount = `-${formatCurrency(bonus.amount * bonus.count, bonus.amount_small)} RON`;
    } else if (bonus.status === 6) {
      title = `${bonus.count} x ${formatCurrency(bonus.amount, bonus.amount_small) + " RON"}`;
      amount = `${formatCurrency(bonus.collected, bonus.collected_small)} RON`;
    }

    listData.push([
      moment.unix(bonus.created).format("DD/MM/YYYY HH:mm:ss"),
      t(name),
      title,
      amount,
      t(bonusStatus[bonus.status])
    ]);
  };

  list.forEach(handleList);

  listData.sort((ta, tb) => {
    if (ta[0] < tb[0]) {
      return -1;
    }
    if (ta[0] > tb[0]) {
      return 1;
    }
    return 0;
  });

  listData.unshift([t("Event Date"), t("Name"), t("Tokens"), t("Result"), t("Status")]);

  exportToCsv("free_bet_history.csv", listData);
};

const FreeBet = (props) => {
  const { items, hasMore, freeBetsFetchHistory, tabIndex, t } = props;

  const bonusStatus = {
    4: t("canceled"),
    6: t("collected"),
  };

  const statusColors = {
    4: { "name": "forfeited", "color": "failed" },
    6: { "name": "collected", "color": "success" },
  };

  const historic = [];
  items.forEach((bonus, i) => {

    let name = bonus.name;

    let title = null;
    if (bonus.status === 4) {
      title = <React.Fragment>
        <div className={"specialAmount"}>{bonus.count} x {formatCurrency(bonus.amount, bonus.amount_small) + " RON"}</div>
        <span className={"spacer"}>|</span>
        <div className={"amount"}>- {formatCurrency(bonus.amount * bonus.count, bonus.amount_small) + " RON"}</div>
      </React.Fragment>;
    } else if (bonus.status === 6) {
      title = <React.Fragment>
        <div className={"specialAmount"}>{bonus.count} x {formatCurrency(bonus.amount, bonus.amount_small) + " RON"}</div>
        <span className={"spacer"}>|</span>
        <div className={"amount"}>{formatCurrency(bonus.collected, bonus.collected_small) + " RON"}</div>
      </React.Fragment>;
    }

    historic.push(<li key={i} className={"container"}>
      <div className={"historyElementR1 row"}>
        <div className={"col-9 trsDesc"}>
          <div className={"bName"}>{t(name)}</div>
          <span className={"spacer"}>|</span>
          {title}
        </div>
        <div className={"col-3 trsTimestamp text-right"}>
          <Moment format={"DD/MM/YYYY"} unix>{bonus.created}</Moment>
        </div>
      </div>
      <div className={"historyElementR2 row"}>
        <div className={"col-9 text-left trsDesc"}>
          <div className={`text-left transactionStatus ${statusColors[bonus.status].color}`}>{t(bonusStatus[bonus.status])}</div>
        </div>
        <div className={"col-3 text-right trsTimestamp"}>
          <Moment format={"HH:mm"} unix>{bonus.created}</Moment>
        </div>
      </div>
    </li>)
  });

  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(() => {
    freeBetsFetchHistory(currentPage)
  }, [currentPage, freeBetsFetchHistory]);

  const downloadCsv = (event) => {
    if (event) {
      try {
        event.preventDefault();
      } catch (err) { }
    }
    const historyDumpReq = fetchFreeBetsDumpHistory();

    historyDumpReq.then(list => {
      console.log("list", list);
      processDump(list, t);
    })
      .catch(e => {
        //console.log("failed to get tickets", e);
      });
  };

  const changePage = () => {
    setCurrentPage(v => {
      return v + 1;
    });
  }

  let dIcon = Icons.get("historyCsvDownload", "historyCsvDownload");
  let dIconClass = ["dIcon"];
  let dText = "Download history for last 90 days";

  let style = null;
  if (tabIndex !== 1) {
    style = visibleStyle;
  }

  return (
    <div className={"transactionsList"} style={style}>
      {historic.length !== 0 && <ul className={"bonusList"}>
        {historic}
      </ul>}
      {historic.length === 0 && <div className={"transactionsList emptyList"}>
        <p>{t("no results found")}</p>
      </div>}
      {hasMore && <div className={"fbh-show-more"} onClick={changePage}>
        {t("Show More")}
      </div>}
      <div className={"downloadCsv"}>
        <div onClick={downloadCsv}>
          <span className={dIconClass.join(" ")}>{dIcon}</span>
          <span className={"dText"}>{t(dText)}</span>
        </div>
      </div>
    </div>
  );
}

const mapPropsToState = () => {
  return (state, props) => {
    return {
      items: state.freeBets.freeBetsHistory,
      hasMore: state.freeBets.freeBetsHistoryHasMore,
    };
  }
}

const mapActions = {
  freeBetsFetchHistory
};

export default connect(mapPropsToState, mapActions)(withTranslation()(FreeBet));
