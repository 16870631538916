import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

import { getLottoState } from "../../store/selectors/lottoData";

import { ticketSettledListRequest } from "../../store/actions/tickets";

import Ticket from "../../components/Ticket/Ticket";
import TicketNsoft from "../../components/TicketNsoft/Ticket";

const useStyles = makeStyles({
  root: {},
  empty: {
    margin: "7px",
    textAlign: "center",
    borderRadius: "7px",
    padding: "7px",
    backgroundColor: "#fff",
    fontSize: "12px"
  },
  more: {
    fontSize: "10px",
    fontWeight: "600",
    color: "#586682",
    margin: "16px 0",
    textAlign: "center",
    textDecoration: "underline",
    cursor: "pointer",
  },
});

const Settled = ({ settled, inProgress, ticketSettledListRequest,theme, showMore, t }) => {
  const classes = useStyles();

  const currentTheme = theme && theme === "nsoft" ? "nsoft" : "bets";

  let settledTickets = null;
  if (currentTheme === "nsoft") {
    if (settled.length > 0) {
      settledTickets = settled.map((ticket, i) => <TicketNsoft key={ticket.id} ticket={ticket} />)
    }
  } else {
    if (settled.length > 0) {
      settledTickets = settled.map((ticket, i) => <Ticket key={ticket.id} ticket={ticket} />)
    }
  }

  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    ticketSettledListRequest({ page });
  }, [page]); // eslint-disable-line

  const handleChangePage = () => {
    setPage(page + 1);
  };

  return (
    <div>
      {!inProgress && settled.length === 0 && (
        <div className={classes.empty}>{t("There are no settled tickets")}</div>
      )}
      {settledTickets}
      {!inProgress && showMore && (
        <div className={classes.more} onClick={handleChangePage}>
          {t("Show More")}
        </div>
      )}
      {inProgress && (
        <div className={classes.empty}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const lst = getLottoState(state);
  return {
    settled: lst.tickets.ticketsSettled,
    inProgress: lst.tickets.requestInProgress.settled,
    showMore: lst.tickets.showMore
  };
};

const actionCreators = {
  ticketSettledListRequest
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(Settled));
