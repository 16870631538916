import moment from "moment";
import { createSelector } from "reselect";

import { sortArrayByKey } from "../../utils";

export const getBetsState = state => state.bets;
export const getConfig = state => state.bets.config;
export const getHomeSportId = state => getBetsState(state).navSports.home;
export const getPrematchSportId = state => getBetsState(state).navSports.prematch;
export const getLiveSports = state => getBetsState(state).live.sports;
export const getLiveCategories = state => getBetsState(state).live.categories;
export const getLiveTournaments = state => getBetsState(state).live.tournaments;
export const getLiveData = state => getBetsState(state).live.data;
export const getLiveMatches = state => getBetsState(state).live.matches;
export const getLiveBets = state => getBetsState(state).live.bets;
export const getLiveMatchesBySport = state => getBetsState(state).live.matchesBySport;

export const getPrematchSports = state => getBetsState(state).prematch.sports;
export const getPrematchCategories = state => getBetsState(state).prematch.categories;
export const getPrematchTournaments = state => getBetsState(state).prematch.tournaments;
export const getPrematchData = state => getBetsState(state).prematch.data;
export const getPrematchMatches = state => getBetsState(state).prematch.matches;
export const getPrematchBets = state => getBetsState(state).prematch.bets;
export const getPrematchMatchesBySport = state => getBetsState(state).prematch.matchesBySport;

export const getWinnerAdvMatches = state => getBetsState(state).prematch.waMatches;
export const getWinnerPlusMatches = state => getBetsState(state).prematch.wpMatches;

export const getFavoriteLiveMatches = state => getBetsState(state).favorite.favorite_live_matches;
export const getFavoritePrematchMatches = state =>
	getBetsState(state).favorite.favorite_prematch_matches;

export const getSelectedBets = state => getBetsState(state).app.selectedBets;

const getDataType = (state, props) => {
	//console.log("props", props);

	if ("mType" in props && props.mType !== null) {
		if (props.mType !== "") {
			return props.mType;
		}
	}

	if ("match" in props && props.match !== null) {
		return props.match.mType;
	}

	return "live";
};

const getIdMatch = (state, props) => {
	if ("idMatch" in props) {
		return props.idMatch;
	}

	if ("match" in props && props.match) {
		return props.match.idMatch;
	}

	return "";
};

const getIdsMatch = (state, props) => {
	const ids = [];
	if ("matches" in props) {
		props.matches.forEach(m => {
			ids.push(m.idMatch);
		});
	}

	return ids;
};

const getIdSport = (state, props) => {
	if ("idSport" in props) {
		return props.idSport;
	}

	if ("match" in props) {
		return props.match.idSport;
	}

	return 0;
};

const getSportOrder = state => {
	//console.log("state.config", state.config);
	return getBetsState(state).config.sportsOrder;
};

// NSOFT Retails
const getIdCategory = (state, props) => {
	if ("idCategory" in props) {
		return props.idCategory;
	}

	if ("match" in props) {
		return props.match.idCategory;
	}

	return 0;
};

const getIdTournament = (state, props) => {
	if ("idTournament" in props) {
		return props.idTournament;
	}

	if ("match" in props) {
		return props.match.idTournament;
	}

	return 0;
};

export const makeGetSports = () =>
	createSelector([getBetsState, getDataType], (state, dataType) => state[dataType].sports);

export const makeGetCategories = () =>
	createSelector([getBetsState, getDataType], (state, dataType) => state[dataType].categories);

export const makeGetTournaments = () =>
	createSelector([getBetsState, getDataType], (state, dataType) => state[dataType].tournaments);

export const makeGetMatches = () =>
	createSelector([getBetsState, getDataType], (state, dataType) => state[dataType].matches);

export const makeGetBets = () =>
	createSelector([getBetsState, getDataType], (state, dataType) => state[dataType].bets);

export const makeGetData = () =>
	createSelector([getBetsState, getDataType], (state, dataType) => state[dataType].data);

export const makeGetMatchBets = () =>
	createSelector([getBetsState, getDataType, getIdMatch], (state, dataType, idMatch) => {
		//console.log(state, dataType, idMatch);

		if (idMatch === "") {
			return [];
		}
		if (idMatch in state[dataType].matches) {
			return state[dataType].matches[idMatch].matchBets;
		}
		return [];
	});
export const makeGetMatchesBets = () =>
	createSelector([getBetsState, getDataType, getIdsMatch], (state, dataType, idsMatch) => {
		if (idsMatch.length === 0) {
			return [];
		}
		const bets = [];
		idsMatch.forEach(idMatch => {
			if (idMatch in state[dataType].matches) {
				bets.push(state[dataType].matches[idMatch].matchBets);
			}
		});
		return bets;
	});

export const makeGetMatchStatus = () =>
	createSelector([getLiveMatches, getIdMatch], (lm, idMatch) => {
		if (idMatch === "") {
			return {};
		}
		if (idMatch in lm) {
			return lm[idMatch].currentStatus;
		}
		return {};
	});

export const getPrematchSportDataRetails = createSelector(
	[getPrematchData, getIdSport],
	(matchData, idSport) => (matchData && matchData[idSport] ? matchData[idSport] : {})
);

export const getPrematchCategoryDataRetails = createSelector(
	[getPrematchData, getIdSport, getIdCategory],
	(matchData, idSport, idCategory) => {
		const ret = {};
		if (matchData && matchData[idSport] && matchData[idSport][idCategory]) {
			ret[idCategory] = {};
			ret[idCategory] = matchData[idSport][idCategory];
		}
		return ret;
	}
);

export const getPrematchTournamentDataRetails = createSelector(
	[getPrematchData, getIdSport, getIdCategory, getIdTournament],
	(matchData, idSport, idCategory, idTournament) => {
		const ret = {};
		if (
			matchData &&
			matchData[idSport] &&
			matchData[idSport][idCategory] &&
			matchData[idSport][idCategory][idTournament]
		) {
			ret[idCategory] = {};
			ret[idCategory][idTournament] = {};
			ret[idCategory][idTournament] = matchData[idSport][idCategory][idTournament];
		}
		return ret;
	}
);

export const getPrematchSportInfoRetail = createSelector(
	[getPrematchSports, getIdSport],
	(sports, idSport) => (sports[idSport] ? sports[idSport] : {})
);

export const getPrematchSportsListRetails = createSelector(
	[
		getPrematchData,
		getPrematchSports,
		getPrematchCategories,
		getPrematchTournaments,
		getSportOrder
	],
	(matchData, sports, categories, tournaments, so) => {
		const pso = so.prematch;

		const sl = Object.keys(matchData).reduce((acc, sportId) => {
			return acc.concat(sports[sportId]);
		}, []);

		sortArrayByKey(sl, "sportPosition");

		const ssl = [];
		const rsl = {};
		const final = [];

		pso.forEach(idSport => {
			const s = sl.find(s => {
				if (s) {
					if (typeof s.idSport === "undefined") {
						//console.log("!!! undef idSport", s);
						return false;
					}
				} else {
					//console.log("!!! undef s", matchData, sports);
					return false;
				}

				return parseInt(s.idSport, 10) === idSport;
			});

			if (typeof s !== "undefined") {
				ssl.push(s);
				rsl[idSport] = 1;
			} else {
				//console.log("sport", idSport, "not found");
			}
		});

		sl.forEach(s => {
			if (s) {
				if (typeof s.idSport === "undefined") {
					//console.log("!!! undef idSport", s);
					return;
				}
			} else {
				//console.log("!!! undef s", matchData, sports);
				return;
			}

			if (s.idSport in rsl) return;
			ssl.push(s);
		});

		ssl.forEach(s => {
			const sport = { ...s };
			sport.matchCount = 0;
			const cs = [];
			Object.keys(matchData[s.idSport]).forEach(c => {
				const category = { ...categories[c] };
				category.matchCount = 0;
				const ts = [];
				Object.keys(matchData[s.idSport][c]).forEach(t => {
					const tournament = { ...tournaments[t] };

					const len = Object.keys(matchData[s.idSport][c][t]).length;

					tournament.matchCount = len;
					sport.matchCount += len;
					category.matchCount += len;
					ts.push(tournament);
				});
				category.tournaments = ts;
				cs.push(category);
			});
			sport.categories = cs;
			final.push(sport);
		});

		return final;
	}
);

export const getPrematchMatchData = createSelector(
	[getPrematchMatches, getIdMatch],
	(matches, idMatch) => {
		if (matches && matches[idMatch]) {
			return matches[idMatch];
		}
		return null;
	}
);

export const getCalendarMatchesBySportId = createSelector(
	[getPrematchMatchesBySport, getIdSport],
	(matches, idSport) => {
		if (idSport === "0") {
			//console.log("Prematch matches for 0 sport", matches);
			return {};
		}

		if (typeof matches[idSport] === "undefined") {
			//console.log("No prematch sport", idSport);
			return {};
		}

		return matches[idSport].reduce((acc, m) => {
			const d = moment(m.matchDateTime)
				.startOf("day")
				.valueOf();

			//console.log("d", d);

			if (!(d in acc)) {
				acc[d] = {};
			}

			if (!(m.idCategory in acc[d])) {
				acc[d][m.idCategory] = {};
			}

			if (!(m.idTournament in acc[d][m.idCategory])) {
				acc[d][m.idCategory][m.idTournament] = [];
			}

			acc[d][m.idCategory][m.idTournament].push(m);

			if (!("_all" in acc[d])) {
				acc[d]["_all"] = [];
			}
			acc[d]["_all"].push(m);

			return acc;
		}, {});
	}
);

export const getLiveSportsListRetail = createSelector(
	[getLiveData, getLiveSports, getLiveCategories, getLiveTournaments, getSportOrder],
	(matchData, sports, categories, tournaments, soo) => {
		const so = soo.live;
		//console.log("so", so);
		const sl = Object.keys(matchData).reduce((acc, sportId) => {
			return acc.concat(sports[sportId]);
		}, []);

		sortArrayByKey(sl, "sportPosition");

		const ssl = [];
		const rsl = {};
		const final = [];

		so.forEach(idSport => {
			const s = sl.find(s => {
				if (s) {
					if (typeof s.idSport === "undefined") {
						//console.log("!!! undef idSport", s);
						return false;
					}
				} else {
					//console.log("!!! undef s", matchData, sports);
					return false;
				}

				return parseInt(s.idSport, 10) === idSport;
			});

			if (typeof s !== "undefined") {
				ssl.push(s);
				rsl[idSport] = 1;
			} else {
				//console.log("sport", idSport, "not found");
			}
		});

		sl.forEach(s => {
			if (s) {
				if (typeof s.idSport === "undefined") {
					//console.log("!!! undef idSport", s);
					return;
				}
			} else {
				//console.log("!!! undef s", matchData, sports);
				return;
			}

			if (s.idSport in rsl) return;
			ssl.push(s);
		});

		ssl.forEach(s => {
			const sport = { ...s };
			sport.matchCount = 0;
			const cs = [];
			Object.keys(matchData[s.idSport]).forEach(c => {
				const category = { ...categories[c] };
				category.matchCount = 0;
				const ts = [];
				Object.keys(matchData[s.idSport][c]).forEach(t => {
					const tournament = { ...tournaments[t] };

					const len = Object.keys(matchData[s.idSport][c][t]).length;

					tournament.matchCount = len;
					tournament.matches = matchData[s.idSport][c][t];
					sport.matchCount += len;
					category.matchCount += len;
					ts.push(tournament);
				});
				category.tournaments = ts;
				cs.push(category);
			});
			sport.categories = cs;
			final.push(sport);
		});

		return final;
	}
);

export const getFirstLiveMatchBySport = createSelector(
	[getLiveMatchesBySport, getLiveSports, getIdSport],
	(matches, sports, idSport) => {
		let id = idSport;
		if (id === "0" || id === 0) {
			const keys = Object.keys(sports);
			if (keys.length === 0) return {};
			id = keys[0];
		}

		if (typeof matches[id] === "undefined" || matches[id].length === 0) {
			//console.log("No live sport", id);
			return {};
		}
		return {
			idSport: matches[id][0].idSport,
			idCategory: matches[id][0].idCategory,
			idTournament: matches[id][0].idTournament,
			idMatch: matches[id][0].idMatch
		};
	}
);

export const getLiveDataRetails = createSelector([getLiveData], matchData =>
	matchData ? matchData : {}
);

export const getLiveSportDataRetails = createSelector(
	[getLiveData, getIdSport],
	(matchData, idSport) => (matchData && matchData[idSport] ? matchData[idSport] : {})
);

export const getLiveSportInfoRetail = createSelector(
	[getLiveSports, getIdSport],
	(sports, idSport) => (sports[idSport] ? sports[idSport] : {})
);

export const getLiveMatchData = createSelector(
	[getLiveMatches, getIdMatch],
	(lMatches, idMatch) => {
		if (lMatches && lMatches[idMatch]) {
			return lMatches[idMatch];
		}
		return null;
	}
);

const getMatchGroup = (state, props) => {
	if ("matchGroup" in props) {
		return props.matchGroup;
	}

	return "global";
};

export const makeGetSelectedBet = () =>
	createSelector([getSelectedBets, getMatchGroup], (sb, mg) => {
		if (mg in sb) {
			return sb[mg];
		}

		return null;
	});
