import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getBetsState } from "../../../store/selectors/betData";
import Image from "../../../assets/Footbal_Ball_Legs_Knee_highs_5_A2_Rectangle_49_pattern@2x.png";

const useStyles = makeStyles({
  root: {
    height: "160px",
    borderRadius: "8px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    boxShadow: "0px 3px 5px 0px rgba(0,0,0,0.5)",
    marginBottom: "10px"
  },
  wrapper: {
    position: "absolute",
    borderRadius: "8px",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage:
      "linear-gradient(180deg, rgba(8,12,20,0) 50%, rgba(23,33,77,.4) 60%, rgba(23,33,77,1) 100%)"
  },
  wrapperFlex: {
    padding: "0 11px 9px 22px",
    color: "#fff"
  },
  date: {
    fontSize: "6px",
    fontWeight: "bold",
    padding: "2px 6px",
    textTransform: "uppercase",
    backgroundColor: "#FFC400",
    borderRadius: "3px",
    color: "#192640"
  },
  title: {
    fontSize: "20px",
    fontWeight: "600"
  },
  button: {
    fontSize: "12px",
    fontWeight: "600",
    padding: "9px 18px",
    backgroundImage: "linear-gradient(270deg, #06D2BD 0%, #1F83E6 100%)",
    borderRadius: "8px",
    textTransform: "uppercase",
    whiteSpace: "nowrap"
  }
});

let host = process.env.CFW.betsBannersImagesUrl;
if (host.substr(-1) !== "/") host += "/";

const PromotionCard = ({ config, baseUrlPath, t }) => {
  const classes = useStyles();

  const history = useHistory();
  const isDesktop1024 = useMediaQuery("(min-width:1024px)");

  let bets_state = {
    image: {
      desktop: "",
      mobile: ""
    },
    hasMatch: false,
    subText: "Top Leagues", // t("Top Leagues")
    text: "The bets are on", // t("The bets are on")
    buttonText: "Play Now", // t("Play Now")
    buttonColor: "#fff",
    buttonBackgroundColor: "linear-gradient(270deg, #06D2BD 0%, #1F83E6 100%)",
    link: ""
  };
  if (config && typeof config["bets_state"] !== "undefined") {
    bets_state = config["bets_state"];
  }

  const bgStyle = {
    backgroundImage: `url(${Image})`
  };

  const bgButtonStyle = {
    color: bets_state.buttonColor,
    background: bets_state.buttonBackgroundColor
  };

  if (isDesktop1024) {
    if (bets_state.image.desktop) {
      bgStyle.backgroundImage = `url('${host}${bets_state.image.desktop}')`;
    }
  } else {
    if (bets_state.image.mobile) {
      bgStyle.backgroundImage = `url('${host}${bets_state.image.mobile}')`;
    }
  }

  const clickHandler = () => {
    if (bets_state.link) {
      if (bets_state.link && bets_state.link[0] === "/") {
        history.push(bets_state.link);
      } else {
        window.location = bets_state.link;
      }
    } else {
      history.push(`${baseUrlPath}/pre-match/top-league`);
    }
  };

  return (
    <div className={`${classes.root} boxWrapper`} style={bgStyle}>
      <div className={classes.wrapper} onClick={clickHandler}>
        <div
          className={`${classes.wrapperFlex} h100 d-flex flex-row flex-nowrap align-items-end justify-content-between`}
        >
          <div className="d-flex flex-column align-items-start">
            {bets_state.subText && <div className={classes.date}>{t(bets_state.subText)}</div>}
            {bets_state.text && <div className={classes.title}>{t(bets_state.text)}</div>}
          </div>
          {bets_state.buttonText && (
            <div className={classes.button} style={bgButtonStyle}>
              {t(bets_state.buttonText)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PromotionCard.propTypes = {};
PromotionCard.defaultProps = {};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const bst = getBetsState(state);

    return {
      config: bst.config.homepageGroupsImages,
      baseUrlPath: bst.app.basePath
    };
  };
  return mapStateToProps;
};

export default withTranslation()(connect(makeMapStateToProps)(PromotionCard));
