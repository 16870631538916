export const createLinearGradient = (
  gWidth,
  gHeight,
  width,
  height,
  stops,
  mapFn,
  options
) => {
  options = Object.assign(
    {},
    {
      padding: 0,
      paddingX: 0,
      paddingY: 0,
      border: 0,
      borderX: 0,
      borderY: 0,
      borderColor: "black"
    },
    options
  );

  if (options.padding !== 0) {
    options.paddingX = options.paddingY = options.padding;
  }

  if (options.border !== 0) {
    options.borderX = options.borderY = options.border;
  }

  mapFn =
    typeof mapFn == "function"
      ? mapFn
      : function (canvas) {
        return canvas;
      };

  var canvas = document.createElement("canvas");

  canvas.width = width;
  canvas.height = height;

  var ctx = canvas.getContext("2d");

  if (ctx) {

  if (options.borderX > 0) {
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderX;

    ctx.beginPath();

    ctx.moveTo(0, 0);
    ctx.lineTo(0, height);
    ctx.moveTo(width, 0);
    ctx.lineTo(width, height);

    ctx.stroke();
  }

  if (options.borderY > 0) {
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderY;

    ctx.beginPath();

    ctx.moveTo(0, 0);
    ctx.lineTo(width, 0);
    ctx.moveTo(0, height);
    ctx.lineTo(width, height);

    ctx.stroke();
  }

  var gradient = ctx.createLinearGradient(0, 0, gWidth, gHeight);
  var stopPoints = Object.keys(stops).sort();

  for (var i = 0, n = stopPoints.length; i < n; i += 1)
    gradient.addColorStop(parseFloat(stopPoints[i]), stops[stopPoints[i]]);

  ctx.fillStyle = gradient;
  ctx.fillRect(
    options.paddingX + options.borderX / 2,
    options.paddingY + options.borderY / 2,
    width - options.paddingX - options.borderX,
    height - options.paddingY - options.borderY
  );

    ctx = null;
  }

  return mapFn(canvas);
};
