import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";


import "./mobile-banner.scss";

import { ReactComponent as WinnerLogo } from "./assets/logo-winner.svg";
import { ReactComponent as CasinoLogo } from "./assets/logo-casino.svg";
import { ReactComponent as VirtualLogo } from "./assets/logo-virtuale.svg";

const MobileBanner = ({ t }) => {
  return (
    <div className="mobile-banner">
      <Link to="/pariuri" className="new-website">
        <div>
          <WinnerLogo />
          {t("New Winner website")}
        </div>
      </Link>
      <Link to="/cazino" className="casino">
        <div>
          <CasinoLogo />
          {t("Casino")}
        </div>
      </Link>
      <Link to="/virtuale" className="virtuale">
        <div>
          <VirtualLogo />
          {t("Virtuals")}
        </div>
      </Link>
    </div>
  );
}

export default withTranslation()(MobileBanner);