import React from 'react';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

import MatchDetails from "../MatchDetails";
import evBus from "../../utils/evbus";
import { topMargin } from "../../utils/webStyle";

const useStyles = makeStyles({
  root: {
    //paddingTop: "10px"
  },
  first: {
    paddingRight: "4px",
    width: "50%",
  },
  second: {
    paddingLeft: "4px",
    width: "50%",
    top: `${topMargin}px`,
    position: "sticky",
    height: `calc(100vh - ${topMargin + 10}px)`,
  }
});

const InPageMD = (props) => {
  const classes = useStyles();
  let isDesktop1600 = useMediaQuery("(min-width:1600px)");

  const [state, setState] = React.useState({
    mType: "",
    idMatch: "",
    show: false,
  })

  const handleShowMatchDetails = (mType, idMatch) => {
    setState({
      mType,
      idMatch,
      show: true,
    });
  };

  const handleGoBack = () => {
    setState({
      mType: "",
      idMatch: "",
      show: false,
    });
  }

  const handleEvent = (data, event) => {
    if (event === "SHOW_MATCH_DETAILS") {
      handleShowMatchDetails(data.mType, data.idMatch);
    }
  };

  React.useEffect(() => {
    evBus.on("SHOW_MATCH_DETAILS", handleEvent);
    return () => {
      evBus.remove("SHOW_MATCH_DETAILS", handleEvent);
    };
  }, []); // eslint-disable-line

  if (!isDesktop1600) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    );
  }

  return (
    <div className={`${classes.root} ${state.show ? 'd-flex flex-row flex-nowrap' : ''}`}>
      <div className={`flex-full ${state.show ? classes.first : ''}`}>
        {props.children}
      </div>
      {state.show && <div className={`flex-full ${classes.second}`}>
        <MatchDetails mType={state.mType} idMatch={state.idMatch} onGoBack={handleGoBack} inPage={true} />
      </div>}
    </div >
  );
}

export default InPageMD;