import React from "react";
import PropTypes from 'prop-types'; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#313F80",
    borderRadius: "5px",
    padding: "2px 5px",
    color: "#fff",
    fontSize: "9px",
    fontWeight: "600",
    textTransform: "capitalize",
    whiteSpace: "nowrap"
  },
});

const Time = ({ date }) => {
  const classes = useStyles();

  const dt = moment(date);
  let time = "";

  if (moment().isSame(dt, "day")) {
    time = dt.format("HH:mm");
  } else {
    time = dt.format("ddd MMM D");
  }

  return (
    <div className={classes.root}>
      {time}
    </div>
  );
};

Time.propTypes = {
  date: PropTypes.number
};

Time.defaultProps = {
  date: (new Date()).getTime()
};

export default Time;
