import React from 'react';
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from 'react-i18next';

import "./happy_hours.scss";

import { getText, styleToObj, openItem } from "../../utils/functions";
import { HappyHoursFront, getEvents } from "../../../common/hh-exports";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const HappyHours = (props) => {
  const { data, i18n, hhData, hhRunning } = props;

  const lang = i18n.language;

  const isDesktop = useMediaQuery("(min-width:1024px)");
  let isRunning = Object.keys(hhRunning).length > 0;

  if (!isRunning) {
    return null;
  }

  let happyHourBetsCasinoEnabled = data.casino_enabled;
  let happyHourBetsPrematchEnabled = data.bets_prematch_enabled;
  let happyHourBetsLiveEnabled = data.bets_live_enabled;

  let hhLength = hhData.length === 1;

  if (isDesktop) {
    happyHourBetsPrematchEnabled = false;
    happyHourBetsLiveEnabled = false;
    if (!happyHourBetsCasinoEnabled) return null;
    hhLength = true;
  }

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`component-happy_hours component`}
      style={styleToObj(data.style)}
    >

      <div className="cgl-super-header">
        <div className="category" style={styleToObj(data.category.style)}>
          {getText(data, `category.text.${lang}`, "")}
        </div>
      </div>

      <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div
          className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          {hhLength !== 0 && <span>({hhLength})</span>}
          <LobbyArrow />
        </div>}
      </div>

      <div className="carousel-grid-wrapper">
        <div>
          <div className={`grid grid-happy-hours ${hhLength ? 'one' : ''}`}>
            {
              hhData.map((e, i) => {
                if (e.event_type === "online-slots" && happyHourBetsCasinoEnabled) {
                  return <div className={`grid-item-happy-hour`} key={`hh_${i}`}><HappyHoursFront event_type={"online-slots"} /></div>
                } else if (e.event_type === "bets-live" && happyHourBetsLiveEnabled) {
                  return <div className={`grid-item-happy-hour`} key={`hh_${i}`}><HappyHoursFront event_type={"bets-live"} /></div>;
                } else if (e.event_type === "bets-prematch" && happyHourBetsPrematchEnabled) {
                  return <div className={`grid-item-happy-hour`} key={`hh_${i}`}><HappyHoursFront event_type={"bets-prematch"} /></div>;
                }
                return null;
              })
            }
          </div>
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    hhRunning: state.happyHour.running,
    hhData: getEvents(state),
  }
};

export default withTranslation()(connect(mapStateToProps)(HappyHours));