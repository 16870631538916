/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 28/11/2018
 * Time: 14:52
 */
import React, {Component} from 'react';
import "./SelfExclusionChoice.scss";

class SelfExclusionChoice extends Component {

    onClickHandler() {
        this.props.onClickHandler(this.props.value);
    }

    render() {
        return (
            <div className={"exclusion-choice" + (this.props.active === this.props.value ? " active" : "") + (this.props.disabled ? " disabled" : "")} onClick={this.onClickHandler.bind(this)}>
                {this.props.label}
            </div>
        );
    }
}

export default SelfExclusionChoice;