import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withTranslation } from "react-i18next";
import { geolocated } from 'react-geolocated';
import * as stateActions from "../../../../../store/actions";
import "./Location.scss";
import Icons from "../../../../../assets/Icons/Icons";
import LocationTabs from "../Sections/LocationTabs";
import MapContainer from "./../Sections/MapContainer/MapContainer";
import LocationsList from "../Sections/LocationsList";
import * as GeoStates from "../../../../Locations/GeoStates";

class Location extends Component {
    constructor(props) {
        super(props);

        this.icons = {
            back: Icons.get('modal-back', 'backToAmount'),
            titleInfo: Icons.get('questionmarkCircle', 'title-info'),
            pin: Icons.get('PinAddressIcon', 'PinAddressIcon'),
        };

        this.timer = 0;

        this.google = null;

        this.geolocation = {
            latitude: this.props.profile.latitude,
            longitude: this.props.profile.longitude,
        };

        this.state = {
            location: null,
            tmp: false,
            geoStatus: GeoStates.UNKNOWN,
            geolocation: this.geolocation,
        };

        this.coordsReady = false;

        this.markers = {};

        this.clickMarker = null;
    }

    componentDidMount() {
        this.selfReset();
        this.props.loadLocations();

        this.setState({
            ...this.state,
            geoStatus: this.getGeoStatus(),
        });
    }

    getGeoStatus() {
        if (this.props.geolocation.coords) {
            return GeoStates.AVAILABLE;
        }

        // check geolocation:
        let geoStatus = GeoStates.UNKNOWN;
        if (!this.props.isGeolocationAvailable) {
            geoStatus = GeoStates.NOT_AVAILABLE;
            return geoStatus;
        }

        if (this.props.isGeolocationEnabled) {
            geoStatus = GeoStates.AVAILABLE;
            if (!this.props.coords) {
                geoStatus = GeoStates.UNKNOWN;
            }

            if (geoStatus === GeoStates.AVAILABLE) {
                this.resetOnce();
            }
            return geoStatus;
        }

        let positionError = this.props.positionError;
        if (!positionError) {
            return geoStatus;
        }

        if (!positionError.hasOwnProperty('code')) {
            return geoStatus;
        }

        if (1 === positionError.code) {
            geoStatus = GeoStates.DENIED;
            return geoStatus;
        }
    }

    componentDidUpdate() {
        if (!this.props.withdrawals.modal) {
            return;
        }

        let update = false;

        let geoState = this.getGeoStatus();
        if (!(this.state.geoStatus === geoState)) {
            update = true;
        }

        let geolocation = {};
        if (!this.coordsReady) {
            if (this.props.coords) {
                update = true;

                geolocation.longitude = this.props.coords.longitude;
                geolocation.latitude = this.props.coords.latitude;

                this.coordsReady = true;

                this.savePermission();

                this.props.resetActualPosition();


                this.props.setCoords(this.props.coords);
            } else if (this.props.geolocation.coords) {
                this.coordsReady = true;

                update = true;

                geolocation.longitude = this.props.geolocation.coords.longitude;
                geolocation.latitude = this.props.geolocation.coords.latitude;

                this.savePermission();

                this.props.resetActualPosition();

            }
        }

        if (!update) {
            return;
        }

        this.setState({
            ...this.state,
            geoStatus: geoState,
            geolocation: geolocation,
        });
    }

    selfReset() {
        this.timer = 0;

        this.google = null;
    }

    setMarkers(refMarkers) {
        this.markers = refMarkers;
    }

    setClickMarker(markerFunction) {
        this.clickMarker = markerFunction;
    }

    setLocation(location) {
        this.props.setLocation(location);
    }

    setGoogle(google) {
        this.google = google;
    }

    savePermission() {
        if (this.hasPermission()) {
            return;
        }

        localStorage.setItem('location_permission', true);
    }

    hasPermission() {
        if (!localStorage.getItem('location_permission')) {
            return false;
        }

        return true;
    }

    nextStep(event) {
        if (!this.props.withdrawals.location) {
            return;
        }

        clearTimeout(this.timer);
        this.props.setBigModal(false);

        this.timer = setTimeout(() => {
            if (!this.props.withdrawals.modal) {
                clearTimeout(this.timer);
                return;
            }

            this.props.nextStep();
        }, 1000);
    }

    previousStep(event) {
        clearTimeout(this.timer);
        this.props.setBigModal(false);

        this.timer = setTimeout(() => {
            if (!this.props.withdrawals.modal) {
                clearTimeout(this.timer);
                return;
            }

            this.props.previousStep();
        }, 1000);
    }

    render() {
        if (!this.props.withdrawals.modal) {
            return null;
        }

        return (
            <div className={"LocationsScreen"}>
                <div className={"locationsLeft"}>
                    <div className={"leftTitle"}>
                        <div className={"titleBack"}>
                            <button className={"buttonBack titleButton"} onClick={this.previousStep.bind(this)}>{this.icons.back}</button>
                        </div>
                        <div className={"titleName"}>{this.props.t('agencies')}</div>
                        <div className={"titleInfo"}>
                            <button className={"buttonInfo titleButton"}>{this.icons.titleInfo}</button>
                        </div>
                    </div>
                    <LocationsList nextStep={this.nextStep.bind(this)} geoStatus={this.state.geoStatus} />
                </div>
                <div className={"locationsMap"}>
                    <MapContainer
                        locations={this.props.withdrawals.locations}
                        setLocation={this.setLocation.bind(this)}
                        googleHandler={this.setGoogle.bind(this)}
                        lat={this.state.geolocation.latitude}
                        long={this.state.geolocation.longitude}
                        selectedLocation={this.props.withdrawals.location}
                        setMarkers={this.setMarkers.bind(this)}
                        clickMarker={this.setClickMarker.bind(this)}
                        withdrawHandler={this.nextStep.bind(this)} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        profile: state.profile,
        wallet: state.wallet,
        geolocation: state.geolocation,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // loadLocations: () => dispatch(stateActions.loadLocalLocations()),
        loadLocations: () => dispatch(stateActions.requestLocations()),
        setLocation: (location) => dispatch(stateActions.selectedLocation(location)),
        setBigModal: (isBig) => dispatch(stateActions.setBigSizeModal(isBig)),
        setGeolocation: (latitude, longitude) => dispatch(stateActions.setGeolocation(latitude, longitude)),
        resetActualPosition: () => dispatch(stateActions.resetActualPosition()),
        setCoords: (coords) => dispatch(stateActions.setCoords(coords)),
    };
};

export default (withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Location)));