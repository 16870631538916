import React from 'react';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonBase from '@material-ui/core/ButtonBase';
import axios from "axios";
import moment from "moment";
import cloneDeep from "lodash/fp/cloneDeep";

import "./main-view.scss";
import SimpleTimer from "../components/simple_timer";
import Icons from "../../assets/Icons/Icons";
import { raffleConfig } from "../api/config";
import LogoImg from "../assets/img-banner-login-large.png";
import PersonalDetails from "../components/personal_details";
import SMSCode from "../components/sms_code";
import WaningIcon from "../assets/warning-icon.svg";
import { loadLocalData, saveLocalData } from "../utils/storage";
import DoneDialog from "../components/done_dialog";
import ScanDialog from "../components/scanner_dialog";
import LogoDesktop from "../assets/raffle-logo-desktop.png";
import ScanImage from "../assets/scan-image.png";

const MainView = (props) => {
  const { t } = props;

  const [state, setState] = React.useState({
    step: 0,
    loading: false,
    error: "",
    data: {
      agree: false,
      name: "",
      surname: "",
      phone: "",
      serial: "",
    },
    dataVerify: {
      code: "",
    },
    tickets: {
      phone: "",
      code: "",
    },
    rows: [],
    openedDetails: true,
    expired: 0,
  });
  const [openDone, setOpenDone] = React.useState(false);
  const handleDoneClose = () => {
    setOpenDone(false);
  };

  const [openScan, setOpenScan] = React.useState(false);
  const handleScanClose = () => {
    setOpenScan(false);
  };
  const handleOpenScan = () => {
    setOpenScan(true);
  };

  React.useEffect(() => {
    document.documentElement.classList.add('raffle-real-html');

    const data = loadLocalData();

    if (data && data.identity) {
      if (data.identity.agree && data.identity.name && data.identity.surname && data.identity.phone) {
        setState(v => {
          const newV = cloneDeep(v);
          newV.data.agree = data.identity.agree;
          newV.data.name = data.identity.name;
          newV.data.surname = data.identity.surname;
          newV.data.phone = data.identity.phone;
          newV.openedDetails = false;
          return newV;
        });
      }
    }

    return () => {
      document.documentElement.classList.remove('raffle-real-html');
    }
  }, []);

  const handleExit = () => {
    props.history.push("/");
  };

  const handleTextChange = (e) => {
    const newState = cloneDeep(state);
    if (e.target.name === "code") {
      newState.dataVerify[e.target.name] = e.target.value;
    } else {
      newState.data[e.target.name] = e.target.value;
    }
    setState(newState);
  };

  const onResult = (value) => {
    const newState = cloneDeep(state);
    newState.data.serial = value;
    setState(newState);
  };

  const handleSMSComplete = (data) => {
    const newState = cloneDeep(state);
    newState.dataVerify.code = data;
    setState(newState);
  };

  const handleTicketsSMSComplete = (data) => {
    const newState = cloneDeep(state);
    newState.tickets.code = data;
    setState(newState);
  };

  const handleTicketsChange = (e) => {
    const newState = cloneDeep(state);
    newState.tickets[e.target.name] = e.target.value;
    setState(newState);
  };

  const handleUpdate = (data) => {
    const newState = cloneDeep(state);
    newState.data = data;
    setState(newState);
  };

  const handleRules = () => {
    if (window.config.rafflePromoLink && window.config.rafflePromoLink[0] === "/") {
      props.history.push(window.config.rafflePromoLink ? window.config.rafflePromoLink : "/");
    }

    if (window.config.rafflePromoLink.indexOf("http") > -1) {
      let win = window.open(window.config.rafflePromoLink, '_blank');
      if (win !== null) {
        win.focus();
      }
    }
  };

  const handleAction = () => {
    let localData = loadLocalData();
    setState(v => ({ ...v, loading: true, error: "" }));

    if (state.step === 0) {
      localData.identity = {
        agree: state.data.agree,
        name: state.data.name,
        surname: state.data.surname,
        phone: state.data.phone,
      };

      if (state.data.agree && state.data.name && state.data.surname && state.data.phone) {
        saveLocalData(localData);
      }

      axios.post(raffleConfig.addClient, {
        agree: state.data.agree,
        name: state.data.name,
        surname: state.data.surname,
        phone: state.data.phone,
        serial: state.data.serial,
        hv: typeof localData.phones[state.data.phone] === "undefined" ? false : true
      }).then(resp => {
        if (typeof localData.phones[state.data.phone] === "undefined") {
          setState(v => ({ ...v, step: 1, openedDetails: false }));
        } else {
          setState(v => {
            const newV = cloneDeep(v);
            newV.data.serial = "";
            newV.openedDetails = false;
            return newV;
          });
          setOpenDone(true);
        }
      }).catch(err => {
        if (err && err.response && err.response.data && err.response.data.details) {
          const errData = err.response.data;
          if (errData.code === 1) {// input validation error
            if (errData.details.length && errData.details[0].indexOf("Missing required") === 0) {
              setState(v => ({ ...v, error: errData.details[0] }));
            }
          } else if (errData.code === 3) {
            if (errData.details.length && errData.details[0].indexOf("Ticket serial") === 0) {
              setState(v => ({ ...v, error: errData.details[0] }));
            } else if (errData.details.length && errData.details[0].indexOf("limit") > -1) {
              setState(v => ({ ...v, error: errData.details[0] }));
            }
          }
        }
      }).finally(() => {
        setState(v => ({ ...v, loading: false }));
      })

    } else if (state.step === 1) {
      axios.post(raffleConfig.verifyCode, {
        agree: state.data.agree,
        name: state.data.name,
        surname: state.data.surname,
        phone: state.data.phone,
        serial: state.data.serial,
        code: state.dataVerify.code
      }).then(resp => {
        if (resp && resp.data && resp.data.data && resp.data.data.verified) {
          if (localData && localData.phones) {
            localData.phones[state.data.phone.toString()] = true;
            saveLocalData(localData);
          }
          setState(v => {
            const newV = cloneDeep(v);
            newV.data.serial = "";
            newV.step = 0;
            return newV;
          });
          setOpenDone(true);
        } else {
          setState(v => ({ ...v, error: "Invalid verification code" }));
        }
      }).finally(() => {
        setState(v => ({ ...v, loading: false }));
      })
    } else if (state.step === 2) {
      axios.post(raffleConfig.checkPhone, {
        phone: state.tickets.phone,
      }).then(resp => {
        setState(v => ({ ...v, step: 3 }));
      }).finally(() => {
        setState(v => ({ ...v, loading: false }));
      })
    } else if (state.step === 3) {
      axios.post(raffleConfig.verifyPhone, {
        phone: state.tickets.phone,
        code: state.tickets.code
      }).then(resp => {
        if (resp && resp.data && resp.data.data && resp.data.data.verified) {
          setState(v => ({ ...v, rows: resp.data.data.rows ? resp.data.data.rows : [], step: 4 }));
        } else {
          setState(v => ({ ...v, error: "Invalid verification code" }));
        }
      }).catch(err => {
        if (err && err.response && err.response.data && err.response.data.details) {
          const errData = err.response.data;
          if (errData.code === 3) {
            if (errData.details.length && errData.details[0].indexOf("limit") > -1) {
              setState(v => ({ ...v, error: errData.details[0] }));
            }
          }
        }
      }).finally(() => {
        setState(v => ({ ...v, loading: false }));
      })
    }
  };
  const reloadStatus = () => {
    setState(v => ({
      ...v,
      expired: v.expired + 1,
    }));
  };

  const showTickets = () => {
    setState(v => ({
      ...v,
      step: 2
    }));
  };

  const addTicket = () => {
    const newState = cloneDeep(state);
    newState.step = 0;
    newState.data.serial = "";
    setState(newState);
  };

  const handleNumberChange = () => {
    const newState = cloneDeep(state);
    newState.step = 0;
    newState.openedDetails = true;
    setState(newState);
  };

  const now = moment();
  const firstEndDate = moment(window.config.raffleFirstEndDate ? parseInt(window.config.raffleFirstEndDate) : 1671400799000);
  const secondEndDate = moment(window.config.raffleSecondEndDate ? parseInt(window.config.raffleSecondEndDate) : 1674165599000);
  let endDate = null;

  if (now.isBefore(firstEndDate)) {
    endDate = firstEndDate;
  } else if (now.isBefore(secondEndDate)) {
    endDate = secondEndDate;
  }

  const isComplete = state.data.agree && state.data.phone && state.data.name && state.data.surname && state.data.serial;

  return (
    <div className="raffle-main-view">
      <div className={`header ${state.step !== 0 && state.step !== 2 ? "step-one" : ""}`}>
        <div className="title">
          {window.config.raffleTitle}
        </div>
        <div className="close-button">
          <ButtonBase
            onClick={handleExit}
            color="inherit"
            aria-label="Close">
            {Icons.get('closeIcon', 'CloseMenuIcon')}
          </ButtonBase>
        </div>
      </div>

      <form autoComplete="off">
        <div className="content-wrapper">
          <div className="left-side">
            <div className="logo">
              <img src={window.config.rafflePrizeImage ? window.config.rafflePrizeImage : LogoDesktop} alt="" />
            </div>
            <div className="title">
              Extragere bilete agenții
            </div>
            <div className="text">
              DREAM BETTING organizează o campanie în perioada 14.11-18.12. Daca doresti sa participi, completeaza formularul cu seriile
              eligibile ale biletelor necastigatoare. Astfel, participi la extragere.
            </div>
            <div className="text">
              Prezenta campanie este organizată de către DREAM BETTING S.R.L.
            </div>
            <div className="text">
              Datele cu caracter personal ale participanților la campanie, înregistrați prin platforma winner.ro sunt prelucrate
              de DREAM BETTING S.R.L. in calitate de operator de date cu caracter personal si Playonline Solutions Ltd in calitate
              de imputernicit.
            </div>
          </div>
          <div className="right-side">
            <div className="side-wrapper">

              {state.step === 0 && <div>
                <div className="logo-wrapper">
                  <img src={window.config.raffleHeaderImage ? window.config.raffleHeaderImage : LogoImg} alt="" />

                  <div className="footer">
                    <div className="count-down">
                      <SimpleTimer date={endDate.format("YYYY-MM-DD HH:mm:ss")} onLive={reloadStatus} />
                    </div>
                  </div>
                </div>

                <div className="fields">
                  {state.error && <div className="error">{t(state.error)}</div>}

                  <PersonalDetails data={state.data} onUpdate={handleUpdate} openedDetails={state.openedDetails} />

                  <div className={'raffle-field ticket-serial'}>
                    <TextField
                      value={state.data.serial}
                      name="serial"
                      label={t("Ticket Serial")}
                      type={"text"}
                      onChange={handleTextChange}
                    />
                    <div className="scan-image" onClick={handleOpenScan}>
                      <img src={ScanImage} alt="" />
                    </div>
                  </div>
                </div>

                <div className="button">
                  <Button
                    variant="contained"
                    disabled={state.loading || !isComplete}
                    size="large"
                    color="primary"
                    onClick={handleAction}>
                    {t("Add ticket")} {state.loading && <div className="loader"><CircularProgress color="primary" /></div>}
                  </Button>
                </div>

                {/*<div className="button-link" onClick={showTickets}>
                  {t('Check active tickets')}
              </div>*/}
                <div className="button-link" onClick={handleRules}>
                  {t('See the game rules')}
                </div>

              </div>}

              {state.step === 1 && <div>
                <div className="warning-text">
                  <div className="icon">
                    <img src={WaningIcon} alt="" />
                  </div>
                  <div className="txt">
                    {t("The SMS verification is required only first time to be eligible for prizes.")}
                  </div>
                </div>
                <div className="big-header">
                  {t("Phone check")}
                </div>
                <div className="small-header">
                  {t("Enter the verification code received via SMS:")}
                </div>
                {state.error && <div className="error">{t(state.error)}</div>}
                <div className="fields">
                  <div className="sms-wrapper">
                    <SMSCode type="text" fields={5} fieldWidth={43} fieldHeight={56} autoFocus={true} onComplete={handleSMSComplete} />
                  </div>
                </div>
                <div className="button">
                  <Button
                    variant="contained"
                    disabled={state.loading || !state.dataVerify.code}
                    size="large"
                    color="primary"
                    onClick={handleAction}>
                    {t("Verify code")} {state.loading && <div className="loader"><CircularProgress color="primary" /></div>}
                  </Button>
                </div>
                <div className="sms-footer">
                  <div className="phone-number">
                    {state.data.phone} |
                  </div>
                  <div className="change-phone-number" onClick={addTicket}>
                    {t("Change number")}
                  </div>
                </div>
              </div>}

              {state.step === 2 && <div>
                <div className="logo-wrapper">
                  <img src={LogoImg} alt="" />

                  <div className="footer">
                    <div className="count-down">
                      <SimpleTimer date={endDate.format("YYYY-MM-DD HH:mm:ss")} onLive={reloadStatus} />
                    </div>
                  </div>
                </div>

                <div className="fields">

                  <div className="tickets-enter-phone-text">
                    {t("Check tickets")}
                  </div>

                  {state.error && <div className="error">{t(state.error)}</div>}

                  <div className={'raffle-field ticket-serial'}>
                    <TextField
                      value={state.tickets.phone}
                      name="phone"
                      label={t("Phone")}
                      type={"text"}
                      onChange={handleTicketsChange}
                    />
                  </div>
                </div>

                <div className="button check-phone">
                  <Button
                    variant="contained"
                    disabled={state.loading || !state.tickets.phone}
                    size="large"
                    color="primary"
                    onClick={handleAction}>
                    {t("Next")} {state.loading && <div className="loader"><CircularProgress color="primary" /></div>}
                  </Button>
                </div>

                <div className="button-link" onClick={addTicket}>
                  {t('Add ticket')}
                </div>
              </div>}

              {state.step === 3 && <div>
                <div className="big-header">
                  {t("Ownership check")}
                </div>
                <div className="small-header">
                  {t("Enter the verification code received via SMS:")}
                </div>
                {state.error && <div className="error">{t(state.error)}</div>}
                <div className="fields">
                  <div className="sms-wrapper">
                    <SMSCode type="text" fields={5} fieldWidth={43} fieldHeight={56} autoFocus={true} onComplete={handleTicketsSMSComplete} />
                  </div>
                </div>
                <div className="button">
                  <Button
                    variant="contained"
                    disabled={state.loading || !state.tickets.code}
                    size="large"
                    color="primary"
                    onClick={handleAction}>
                    {t("Verify code")} {state.loading && <div className="loader"><CircularProgress color="primary" /></div>}
                  </Button>
                </div>
                <div className="sms-footer">
                  <div className="phone-number">
                    {state.tickets.phone} |
                  </div>
                  <div className="change-phone-number" onClick={handleNumberChange}>
                    {t("Change number")}
                  </div>
                </div>
              </div>}

              {state.step === 4 && <div className="tickets">
                <div className="tickets-header">
                  {t("Raffle tickets")}
                </div>
                <div className="tickets-total">
                  {!!state.rows && state.rows.length}
                </div>
                <div className="tickets-list">
                  {t("Tickets list")}
                </div>
                <div>
                  {!!state.rows && state.rows.map((r, i) => {
                    return <div className="ticket" key={i}>
                      <div className="date-time">{moment.unix(r.created / 1e9).format("DD.MM.YYYY")}</div>
                      <div className="serial">{r.serial}</div>
                    </div>
                  })}
                </div>

                <div className="button-link" onClick={addTicket}>
                  {t('Start page')}
                </div>
              </div>}
            </div>
          </div>
        </div>
      </form>

      {openDone && <DoneDialog open={openDone} onClose={handleDoneClose} />}
      {openScan && <ScanDialog open={openScan} onClose={handleScanClose} onResult={onResult} />}
    </div>
  );
}

export default withRouter(withTranslation()(MainView));