import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as stateActions from './../../store/actions';
// import RecentWinners from './../Jackpot/RecentWinners';
import Game from '../Games/Game/Game';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Collections.scss';
import HeaderV2 from "../Jackpots/HeaderV2";

const SINGLE_ROW = 1;
const DOUBLE_ROW = 2;
const DOUBLE_ROW_NO_PROMOTED = 3;

const Collections = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.isRequestGameCollections();
        window.scrollTo(0, 0);
        if (props.jackpot && !props.jackpot.egt.dataReceived) {
            props.reloadJackpots();
        }
    }, []);

    const goToCollection = (id) => {
        props.history.push('/cazino/collection/' + id);
    };

    const getGameCollections = () => {
        let mainContent = <CircularProgress className={'Loading'} />;
        if (!props.games.loadingGameCollections && props.games.gameCollections && props.games.gameCollections.length > 0) {
            let collections = props.games.gameCollections.filter(Boolean);
            mainContent = collections.map((collection, index) => {
                if(!collection.type) {
                    collection.type  = DOUBLE_ROW;
                }

                let gameContent = [];
                if (collection.items) {
                    if (collection.jackpot_id) {
                        collection.type = DOUBLE_ROW;
                        gameContent.push(
                            <div className={"GameItem FirstGame"} key={collection.items.length + 'Jackpot'}>
                                <HeaderV2
                                    integration={"desktop"}
                                    jackpotId={collection.jackpot_id}
                                    collectionId={collection.id}
                                    front={true}
                                    name={"EGT"}
                                    value={props.jackpot.egtParsed.maxValue}
                                    jackpot={props.jackpot.egtParsed}
                                    currency={props.jackpot.egtParsed.currency}
                                />
                            </div>
                        );
                    }

                    let gamesCount = 8;
                    if(collection.jackpot_id){ gamesCount = 7; }
                    if(collection.type === SINGLE_ROW){ gamesCount = 5; }
                    if(collection.type === DOUBLE_ROW_NO_PROMOTED){ gamesCount = 11; }

                    for (let i = 0; i < collection.items.length; i++) {
                        if (i < gamesCount) {
                            gameContent.push(
                                <div className={"GameItem" + (i === 0 && !collection.jackpot_id && collection.type === DOUBLE_ROW ? ' FirstGame' : '')} key={i}>
                                    <Game key={i} gameItem={collection.items[i]} section="allGames" />
                                </div>
                            )
                        } else {
                            break;
                        }
                    }
                    gameContent.push(
                        <div className={"GameItem"} onClick={() => goToCollection(collection.id)} key={collection.items.length + 1}>
                            <div className="ShowMore">
                                <p>...</p>
                            </div>
                        </div>
                    );
                    gameContent.push(<div className={"GameItem EmptyColumn"} key={collection.items.length + 2} />);
                }
                
                let collectionType = '';
                let collectionColumns = 0;
                switch (collection.type) {
                    case SINGLE_ROW:
                        collectionType = ' SingleRow';
                        collectionColumns = collection.items.length + 2;
                        if ((collection.items.length) > 5) {
                            collectionColumns = 7;
                        }
                        break;
                    case DOUBLE_ROW:
                        collectionType = ' DoubleRow';
                        collectionColumns = Math.floor((collection.items.length + 4) / 2) + 1;
                        if ((collection.items.length >= 7 && collection.jackpot_id) || (collection.items.length >= 8 && !collection.jackpot_id)) {
                            collectionColumns = 6;
                        }
                        break;
                    case DOUBLE_ROW_NO_PROMOTED:
                        collectionType = ' DoubleRowNoPromoted';
                        collectionColumns = Math.floor(collection.items.length / 2) + 1;
                        if (collection.items.length > 8) {
                            collectionColumns = 6;
                        }
                        break;
                }

                return (
                    <div className="GamesCollection" key={index}>
                        <div className="CollectionContainer">
                            <div className="CollectionHeader">
                                <h2 className={"CollectionTitle"} onClick={() => goToCollection(collection.id)}>
                                    {collection.title} <span>({collection.items.length})</span>
                                </h2>
                                <p className={"CollectionLink"} onClick={() => goToCollection(collection.id)}>{t("See all")}</p>
                            </div>
                            <div className={"GamesContainer Columns-" + collectionColumns + collectionType}>
                                {gameContent}
                            </div>
                        </div>
                    </div>
                )
            });
        }

        return mainContent;
    }

    return (
        <div className={"CollectionsScreen"}>
            {getGameCollections()}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        games: state.games,
        jackpot: state.jackpots,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        isRequestGameCollections: () => dispatch(stateActions.requestGameCollections()),
        reloadJackpots: () => { dispatch(stateActions.requestJackpots()) },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Collections));