import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import Slider from "react-slick";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';

import './TickerWinners.scss';
import './slick.scss';
import { formatNumber, ucFirst } from "../utils/formatters";
import DinamicImage from '../../Utils/DinamicImage/DinamicImage';

class TickerWinners extends PureComponent {
    render() {
        const { width, style, latestJackpotWinners, ids } = this.props;

        if (!latestJackpotWinners || !latestJackpotWinners.length) {
            return null;
        }

        const cdnGamesUrl = window.location.protocol + '//' + window.config.cdn_address + '/' + window.config.cdn_games_path + '/';
        const imageGameType = ".png";

        const firstWinners = latestJackpotWinners.slice(0, 10);
        const winnersRemote = firstWinners.map(winner => {
            winner.imgURL = cdnGamesUrl + ids[winner.game] + imageGameType;
            return winner;
        });

        let howMany = Math.trunc((width - 112) / 500);
        const slidesToShow = howMany > winnersRemote.length ? winnersRemote.length : howMany;

        var slick_settings = {
            dots: false,
            arrows: false,
            autoplay: true,
            easing: 'easeOutQuart',
            draggable: true,
            autoplaySpeed: this.props.delay,
            infinite: true,
            speed: this.props.speed,
            slidesToShow: slidesToShow,
            slidesToScroll: 1
        };

        return (
            <div className="ticker-winners" style={{ ...style }}>
                <div className="top">
                    <div className="title">{this.props.t("JACKPOT Last Winnings")}</div>
                </div>
                <div className="bottom">
                    <Slider {...slick_settings}>
                        {winnersRemote.map((element, idx) => (
                            <div className="element" key={idx}>
                                <DinamicImage
                                    folder={"games"}
                                    size={"small"}
                                    imgName={element.game !== null ? element.game.replace(/\s/g, "-").toLowerCase() + ".jpg" : "game-placeholder.jpg"}
                                    title={element.game !== null ? element.game + ' (' + element.game.replace(/\s/g, "-").toLowerCase() + ".jpg" + ')' : "slot games"}
                                    className={'card-img'}
                                />
                                <div>
                                    <div className="title">
                                        <span className="gold">{element.jackpot}</span> Jackpot Hit
                                    </div>
                                    <div className="subtitle">
                                        <span>{element.first_name} {element.last_name}</span> {this.props.t("wins")}&nbsp;
                                        <strong className="amount">{formatNumber(parseInt(element.amount, 10) / 100)}</strong> {ucFirst(element.currency)} {this.props.t("at")}&nbsp;
                                        <span className="game">{element.game}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        );
    }
}

TickerWinners.propTypes = {
    speed: PropTypes.number,
    delay: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
};

TickerWinners.defaultProps = {
    speed: 1000,
    delay: 5000,
    width: window.screen.width,
    height: window.screen.height,
};


const mapStateToProps = state => {
    return {
        latestJackpotWinners: state.jackpots.latestJackpotWinners,
        ids: state.games.ids
    }
};

export default withTranslation()(withResizeDetector(connect(mapStateToProps)(TickerWinners)));
