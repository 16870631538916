import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./Deposit.scss";
import DepositModal from "../PlayerProfile/Modals/Deposit/DepositModal";
import * as stateActions from "../../store/actions";

class Deposit extends Component {
    constructor(props) {
        super(props);
        window.localStorage.setItem('GAdsConversion',1);
    }

    render() {
        return (
            <div className={"DepositPage"}>
                <DepositModal paymentMethod={this.props.paymentMethod}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // withdrawals: state.withdrawals,
        // geolocation: state.geolocation,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        permissionRequested: () => dispatch(stateActions.permissionRequested()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);
