import * as actionTypes from '../actions/actionTypes';

const initialState = {
    modal: false,
    amount: 0,
    taxes: 0,
    loadingTaxes: false,
    requestInProgress: false,
    locations: [],
    location: null,
    requested: false,
    requests: [],
    pendingRequests: [],
    approvedRequests: [],
    historyRequests: [],
    blockedAmount: 0,
    showCancelModal: false,
    showPartialModal: false,
    withdrawElement: null,
    cancelRequested: false,
    // partialRequested: false,
    partialAmount: null,
    cancelRequestError: null,
    errorResponseMessage: null,
    amountResponseReceived: false,
    amountResponseCode: null,
    bigSizeModal: false,
    geolocation: {
        latitude: null,
        longitude: null,
    },
    link: false,
    loadingVerification: false,
    verificationData: null,
    verificationWithdrawID: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.withdrawals.OPEN_WITHDRAWALS_MODAL:
            return {
                ...state,
                modal: true,
                amountResponseReceived: false,
                errorResponseMessage: null,
                amountResponseCode: null,
                requested: false,
            };
        case actionTypes.withdrawals.CLOSE_WITHDRAWALS_MODAL:
            return {
                ...state,
                modal: false,
                amountResponseReceived: false,
                errorResponseMessage: null,
                amountResponseCode: null,
                requested: false,
                amount: 0,
                taxes: false,
                loadingTaxes: false,
                location: null,
                bigSizeModal: false,
            };
        case actionTypes.withdrawals.WITHDRAWAL_AMOUNT:
            return {
                ...state,
                amount: action.amount,
            };
        case actionTypes.withdrawals.SEND_REQUEST:
            return {
                ...state,
                requestInProgress: true,
                amountResponseReceived: false
            };
        case actionTypes.withdrawals.LOCATIONS_RECEIVED:
            if (action.locations.length === state.locations.length) {
                return state;
            }

            return {
                ...state,
                locations: action.locations,
            };
        case actionTypes.withdrawals.LOCATION_SELECTED:
            return {
                ...state,
                location: action.location,
            };

        case actionTypes.withdrawals.AMOUNT_REQUESTED:
            return {
                ...state,
                requested: (action.requested),
                requestInProgress: true,
                amountResponseReceived: false,
            };
        case actionTypes.withdrawals.AMOUNT_RESPONSE_RECEIVED:
            return {
                ...state,
                requested: true,
                requestInProgress: false,
                amountResponseReceived: true,
                amountResponseCode: action.responseCode,
                errorResponseMessage: action.errorMessage,
                link: typeof action.link !== "undefined" ? action.link : false,
            };
        case actionTypes.withdrawals.REQUESTS_RECEIVED:
            // console.log(action.requests);

            return {
                ...state,
                requests: action.requests,
                pendingRequests: action.pending,
                approvedRequests: action.approved,
                historyRequests: action.history,
                blockedAmount: action.amount,
            };
        case actionTypes.withdrawals.CANCEL_MODAL:
            return {
                ...state,
                showCancelModal: action.status,
                showPartialModal: false,
                withdrawElement: action.element,
                cancelRequestError: null,
                cancelRequested: false,
            };
        case actionTypes.withdrawals.CANCEL_REQUESTED:
            return {
                ...state,
                cancelRequested: action.status,
                cancelRequestError: null,
            };
        case actionTypes.withdrawals.PARTIAL_MODAL:
            return {
                ...state,
                showPartialModal: action.status,
                showCancelModal: false,
                withdrawElement: action.element,
                partialAmount: null,
                cancelRequestError: null,
                cancelRequested: false,
            };
        case actionTypes.withdrawals.PARTIAL_AMOUNT_UPDATE:
            return {
                ...state,
                partialAmount: action.amount,
                cancelRequestError: null,
            };
        case actionTypes.withdrawals.CANCEL_REQUEST_ERROR:
            return {
                ...state,
                cancelRequestError: action.message,
            };
        case actionTypes.withdrawals.RESET:
            return {
                ...initialState,
            };
        case actionTypes.withdrawals.REQUEST_ERROR_MESSAGE:
            return {
                ...state,
                errorResponseMessage: action.message,
            };
        case actionTypes.withdrawals.REQUEST_TAX_AMOUNT:
            return {
                ...state,
                taxes: false,
                loadingTaxes: true,
            };
        case actionTypes.withdrawals.UPDATE_TAX_AMOUNT:
            return {
                ...state,
                taxes: action.taxAmount,
                loadingTaxes: false,
            };
        case actionTypes.withdrawals.BIG_SIZE_MODAL:
            return {
                ...state,
                bigSizeModal: action.bigModal,
            };
        case actionTypes.withdrawals.GEOLOCATION_COORDS:
            return {
                ...state,
                geolocation: {
                    latitude: action.latitude,
                    longitude: action.longitude,
                }
            };
        case actionTypes.withdrawals.CLEAN_VERIFICATION_CODE:
            return {
                ...state,
                verificationData: null,
                loadingVerification: false,
                verificationWithdrawID: null,
            };
        case actionTypes.withdrawals.RECEIVED_VERIFICATION_CODE:
            let verificationData = null;
            if(action.data){
                let now = parseInt(new Date().getTime() / 1000, 10);
                let timestamp = action.data.validation_code_expire;
                let remaining = (timestamp - now) / 3600;

                let hours = parseInt(parseInt(remaining * 3600, 10) / 3600, 10);
                let minutes = parseInt((parseInt(remaining * 3600, 10) - (hours * 3600))/60, 10);
                let seconds = parseInt((parseInt(remaining * 3600, 10) - (minutes * 60) - hours * 3600), 10);

                
                verificationData = {
                    ...state.verificationData,
                    [state.verificationWithdrawID]: {
                        remainingMinutes: minutes,
                        remainingSeconds: seconds,
                        ...action.data
                    }
                };

                console.log(verificationData)
            }
            return {
                ...state,
                verificationData: verificationData,
                loadingVerification: false,
            };
        case actionTypes.withdrawals.REQUEST_VERIFICATION_CODE:
            return {
                ...state,
                loadingVerification: true,
                verificationWithdrawID: action.verificationWithdrawID,
            };
        default:
            return state;
    }
};

export default reducer;
