import { appConstants } from "./constants";

export const appInitialize = () => ({
	type: appConstants.INITIALIZE
});

export const appEvaluateBonusRequest = data => ({
	type: appConstants.EVALUATE_BONUS_REQUEST,
	data
});
