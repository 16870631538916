import React, { Component } from 'react';
import i18next from 'i18next';
import connect from "react-redux/es/connect/connect";
import Address from "../Form/Address/Address";
import CNP from "../Form/CNP/CNP";
import FirstName from "../Form/FirstName/FirstName";
import LastName from "../Form/LastName/LastName";
import Citizenship from "../Form/Citizenship/Citizenship";
import CheckboxInput from "../Form/CheckboxInput/CheckboxInput";
import * as stateActions from "../../../store/actions";
import { withTranslation } from "react-i18next";
import Translate from '../../../utils/Translate';

class PersonalDetails extends Component {
    constructor(props) {
        super(props);

        this.y18Id = "checkbox" + Math.floor((Math.random() * 9999) + 1);
        this.termsId = "checkbox" + Math.floor((Math.random() * 9999) + 1);
        this.marketingAgreementId = "checkbox" + Math.floor((Math.random() * 9999) + 1);
    }

    state = {
        y18: {
            message: {}
        },
        terms: {
            message: {}
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.checkboxError !== prevProps.checkboxError) {
            let y18 = document.getElementById(this.y18Id).checked;
            let terms = document.getElementById(this.termsId).checked;

            if (this.props.checkboxError) {

                if (!y18) {
                    y18 = {
                        type: "error",
                        text: this.props.t("This field is mandatory"),
                    };
                } else {
                    y18 = {};
                }
                if (!terms) {
                    terms = {
                        type: "error",
                        text: this.props.t("This field is mandatory"),
                    }
                } else {
                    terms = {};
                }
                this.setState({
                    ...this.state,
                    y18: {
                        ...this.state.y18,
                        message: {
                            ...this.state.y18.message,
                            ...y18,
                        }
                    },
                    terms: {
                        ...this.state.terms,
                        message: {
                            ...this.state.terms.message,
                            ...terms
                        }
                    },
                })
            }
        }
    }

    onClickHandler(id) {
        let validated = this.props.validated;

        let el = document.getElementById(id);
        if (el) {
            if (el.checked) {
                validated[id] = true;
            } else {
                validated[id] = false;
            }
            if (id === this.marketingAgreementId) {

                this.props.fieldEntered({ field: 'marketingAgreement', value: el.checked, validated: false });
                this.props.fieldEntered({ field: 'marketing', value: el.checked, validated: false });
                this.props.fieldEntered({ field: 'marketing_sms', value: el.checked, validated: false });
                this.props.fieldEntered({ field: 'marketing_email', value: el.checked, validated: false });
                this.props.fieldEntered({ field: 'marketing_phone', value: el.checked, validated: false });
            } else {

                this.props.onChange(validated);
            }
        }

    }

    // helps for poedit scan
    t(text) {
        return text;
    }

    render() {
        let tcLink = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        const y18 = (
            <div>{this.props.t("I am older than 18 years")}</div>
        );
        const marketingAgreement = (
            <div>
                <Translate text={this.t("I want to receive bonuses and marketing materials that are relevant to me.")} />
            </div>
        );
        const terms = (
            <div>
                <Translate
                    text={this.t("I read and I agree with ${linkStartTC}T&C${linkEnd} and ${linkStartGDPR}Privacy Policy${linkEnd}.")}
                    placeholder={
                        {
                            linkStartTC: '<a href="' + tcLink + '" target="_blank">',
                            linkStartGDPR: '<a href="/customer-support/politicadeconfidentialitate" target="_blank">',
                            linkEnd: '</a>',
                        }
                    }
                />
            </div>
        );

        return (
            <div>
                <Citizenship />
                <FirstName />
                <LastName />
                <Address />
                <CNP />
                {/*<RefferenceCode/>*/}
                <CheckboxInput inputLabel={y18} onClickHandler={this.onClickHandler.bind(this)} id={this.y18Id} message={this.state.y18.message} required={true} />
                <CheckboxInput inputLabel={marketingAgreement} onClickHandler={this.onClickHandler.bind(this,this.marketingAgreementId)} id={this.marketingAgreementId} required={false} />
                <CheckboxInput inputLabel={terms} onClickHandler={this.onClickHandler.bind(this)} id={this.termsId} message={this.state.terms.message} required={true} />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (validated) => dispatch(stateActions.changeValidation(validated)),
        fieldEntered: (field) => dispatch(stateActions.fieldEntered(field))
    };
};

const mapStateToProps = state => {
    return {
        validated: state.registerWizard.validated,
        checkboxError: state.registerWizard.errors.checkbox,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PersonalDetails));
