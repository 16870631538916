import React from 'react';
import createSvgIcon from './createSvgIcon';

/**
 * @ignore - internal component.
 */
export default createSvgIcon(
  <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />,
  'KeyboardArrowRight',
);
