import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from './../../../store/actions';
import Icons from './../../../assets/Icons/Icons';

class InputField extends Component {
    componentDidUpdate() {
        if ("lf_username" === this.props.inputIdName && 'username' === this.props.authenticationModal.step) {
            setTimeout(() => {
                this.startFocus();
            }, 500);
        }
    }

    startFocus() {
        if (this.nameInput) {
            this.nameInput.focus();
        }
    }

    keyPressedHandler(event) {
        if (13 === event.charCode) {
            // console.log(`[USERNAME] Enter pressed!`);
            // console.log(`[USERNAME] ${event.target.value}`);
            return this.props.keyPressedHandler(event);
        }

        this.props.keyPressedHandler(event);
    }

    render() {
        let elemIcon = Icons.get(this.props.iconName, this.props.iconClassName);
        if ("lf_password" === this.props.inputIdName) {
            elemIcon = <button className="showPassword" onClick={this.props.onShowPassword}>{elemIcon}</button>;
        }

        if ('password' === this.props.authenticationModal.step) {
            setTimeout(() => {
                this.startFocus();
            }, 500);
        }

        let inputValue = "";
        if (this.props.inputValue) {
            inputValue = this.props.inputValue;
        }

        return (
            <div className={"input-group " + this.props.groupClassName}>
                {elemIcon}
                <input 
                    type={this.props.inputType} 
                    id={this.props.inputIdName} 
                    onChange={this.props.changeHandler} 
                    value={inputValue} 
                    required
                    ref={(input) => { this.nameInput = input; }} 
                    // onKeyPress={this.props.keyPressedHandler}
                    onKeyPress={this.keyPressedHandler.bind(this)}
                />
                <label htmlFor={this.props.inputIdName}>{this.props.inputLabel}</label>
                <div className="input-bar"></div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowPassword: () => dispatch(stateActions.toggleShowPassword()),
    };
};

const mapStateToProps = state => {
    return {
        authenticationModal: state.authModal
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InputField);
