import { wheelConstants } from "./constants";
import getStore from "../../store";

export const loadWheelStatus = () => ({
  type: wheelConstants.LOAD
});

export const loadingWheelStatus = () => ({
  type: wheelConstants.LOADING
});

export const loadedWheelStatus = (status, skip) => {
  const { config } = getStore().getState();

  let randomTime = 600;

  if (status && status.wheel_setup && status.free_spin && status.free_spin.next_spin_after) {
    if (config && config.wheelSetups) {
      if (typeof config.wheelSetups[status.wheel_setup] !== "undefined" && config.wheelSetups[status.wheel_setup].parameters) {
        randomTime = config.wheelSetups[status.wheel_setup].parameters.randomTime;
      }
    }
  }

  return {
    type: wheelConstants.LOADED,
    randomTime: skip ? 0 : randomTime,
    status
  };
};