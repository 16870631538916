import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import VerificationInput from "../Form/VerificationInput/VerificationInput";
import NewSMS from "../Form/NewSMS/NewSMS";
import { withTranslation } from "react-i18next";

class Verification extends Component {

    render() {
        return (
            <div id={"verification"}>
                <h1>{this.props.t("Secure Your Account")}</h1>
                <p>
                    {this.props.t("A SMS has been sent to")} {this.props.phone},<br />{this.props.t("please enter the 5-digit code provided")}.
                        </p>
                <VerificationInput oldRegister={true}/>
                <NewSMS />
                {/*<p className="center">Esti la un pas distanta de a incepe uimitoarea aventura alaturi de PlayOnline Casino. Te asteapta cele mai populare jocuri de slots, LIVE Casino, pariuri sportive, turnee, misiuni si evenimente speciale: Arhipelagul Piratilor, Comoara Azteca si altele.</p>*/}
                {/*<div className="partnersLogo">*/}
                {/*<div className="egt"> </div>*/}
                {/*<div className="amatic"> </div>*/}
                {/*<div className="visa"> </div>*/}
                {/*<div className="verisign"> </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        // onChange: (fieldInput) => dispatch(stateActions.phoneEntered(fieldInput)),
    };
};

const mapStateToProps = state => {
    return {
        phone: state.registerWizard.fields.phone,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Verification));