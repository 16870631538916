import React from 'react';
import connect from "react-redux/es/connect/connect";
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import "./LandingPage.scss";
import Coins from "./assets/coins.png";
import WinnerCoinImg from "../assets/winner-fun-logo.svg";
import Logo from "./assets/logox.png";

import { doLogin } from "../../store/actions/authentication";
import YouCollectedModal from "../views/modals/YouCollected";

const LadingPage = (props) => {
  const { authentication, doLogin, history, t } = props;

  React.useEffect(() => {
    document.body.classList.add("wf-welcome");
    if (authentication && ["user", "token"].indexOf(authentication.auth_type) !== -1) {
      if (window.config.winnerFunEnabled === "1") {
        history.push("/winner-fun");
        return;
      }
      history.push("/");
    }
    return () => {
      document.body.classList.remove("wf-welcome");
    }
  }, []);

  const [state, setState] = React.useState({
    open: false,
    amount: 100,
  });
  const handleCollect = () => {
    setState({
      open: true,
      amount: 100,
    })
  };

  const handleCloseCollected = () => {
    setState({
      open: false,
      amount: 100,
    })
    let extra = "";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referral_id = urlParams.get('winner_referral_id');

    if (referral_id) {
      extra = "&winner_referral_id=" + referral_id;
    }

    window.location.href = "/register?winner_fun=true" + extra;
  }

  return (
    <div className="wf-landing-page">
      <div className="wf-body">
        <div className="wf-logo">
          <img src={Logo} alt="" />
        </div>
        <div className="wf-center">
          <img src={Coins} alt="" />
          <div className={`wf-wrapper`}>
            <div className="wf-inner-wrapper">
              <img src={WinnerCoinImg} alt="winner coin" />
              <div className={"wf-text"}>
                +100
            </div>
            </div>
          </div>
        </div>
        <div className="wf-button" onClick={handleCollect}>
          {t("Collect and Play")}
        </div>
      </div>
      {state.open && <YouCollectedModal open={state.open} handleClose={handleCloseCollected} amount={state.amount} walletTotal={0} />}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
  };
};

const actionCreators = {
  doLogin: doLogin,
};

export default connect(mapStateToProps, actionCreators)(withRouter(withTranslation()(LadingPage)));