import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import cloneDeep from "lodash/fp/cloneDeep";

import { betsSlipConstants } from "../../store/actions/constants";
import { getBetsState, makeGetBets, makeGetStateMatch } from "../../store/selectors/betData";
import { formatOddValue, formatOddName } from "../../utils/formatters";
import getStore from "../../store";

import { ReactComponent as GreenArrow } from "./assets/greenArrow.svg";
import { ReactComponent as RedArrow } from "./assets/redArrow.svg";
import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";

const noop = () => { };

const useStyles = makeStyles({
  button: {
    minWidth: props => {
      if (props.banner) {
        return props.total <= 2 ? "136px" : props.total === 6 ? "50px" : "88px";
      }
      if (props.bannerWP) {
        return props.total <= 2 ? "calc(50% - 10px)" : "calc(33% - 10px)";
      }
      if (props.details) {
        return "initial";
      }

      if (props.total <= 2) {
        if (props.screenWidth < 375) {
          return "120px";
        }
        return "140px";
      }

      if (props.screenWidth < 375) {
        return "85px";
      }

      return "90px";
    },
    maxWidth: props => {
      if (props.banner) {
        return "auto";
      }
      return "200px";
    },
    borderRadius: "7px",
    border: "1px solid transparent",
    position: "relative",
    flex: "1 0 auto",
    cursor: "pointer"
  },
  wrapper: {
    //width: "100%",
    padding: "4px 7px 6px 7px"
  },
  bgBanner: {
    backgroundColor: "#e8f7fb"
  },
  bgRegular: {
    backgroundColor: "#e7edf2"
  },
  bgDark: {
    backgroundColor: "#1E2751"
  },
  betType: {
    display: "inline-block",
    color: "#7F838A",
    fontSize: "14px",
    fontWeight: "600",
    marginRight: "5px",
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "&.details": {
      fontSize: "14px",
      fontWeight: "600"
    }
  },
  betTypeDesktop: {
    backgroundColor: "#d8e2ed",
    margin: "-4px -7px 2px",
    fontSize: "12px",
    borderRadius: "7px 7px 0 0"
  },
  betTypeDesktopDark: {
    backgroundColor: "#11162d",
    margin: "-4px -7px 2px",
    fontSize: "12px",
    borderRadius: "7px 7px 0 0"
  },
  betValue: {
    display: "inline-block",
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0 auto",
    "& svg": {
      color: "#666",
      height: "16px",
      position: "relative",
      top: "2px",
    },
    "&.details": {
      fontSize: "15px"
    }
  },
  dark: {
    "& .betType": {
      color: "#C0CEE5"
    },
    "& .betValue": {
      color: "#fff"
    }
  },
  active: {
    backgroundColor: "#06D2BD",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.31)",
    "& .betType": {
      color: "#fff"
    },
    "& .betValue": {
      color: "#fff"
    }
  },
  activeDesktop: {
    "& .betType": {
      backgroundColor: "#059486"
    }
  },
  highlighted: {
    border: "1px solid #9BBDEF"
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: "5px",
    transform: "translate3d(0,0,0)",
    "& svg": {
      width: "14px",
      height: "7px"
    }
  },
  colorGreen: {
    color: "#01AE3B!important",
    marginLeft: "3px"
  },
  colorRed: {
    color: "#DF2222!important",
    marginLeft: "3px"
  },
  lock: {
    position: "absolute",
    backgroundColor: "transparent",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: "7px",
    textAlign: "center",
    color: "#666",
    paddingTop: "2px",
    pointerEvents: "none"
  },
  bannerLock: {
    backgroundColor: "#a9d3fe"
  },
  lightLock: {
    backgroundColor: "#EDF2F9"
  },
  darkLock: {
    backgroundColor: "#171d38"
  },
  bannerLockColor: {
    fill: "#4867A4",
    color: "#4867A4!important"
  },
  lightLockColor: {
    fill: "#707070",
    color: "#707070!important"
  },
  darkLockColor: {
    fill: "#7D9AC7",
    color: "#7D9AC7!important"
  }
});

const onBetToggle = (e) => {
  e.stopPropagation();

  const target = e.currentTarget;

  if (target) {
    const mBoActive = target.dataset.mboactive;
    const mType = target.dataset.mtype;
    const idSport = target.dataset.idsport;
    const idMatch = target.dataset.idmatch;
    const idBet = target.dataset.idbet;
    const idMb = target.dataset.idmb;
    const idBo = target.dataset.idbo;
    const idMbo = target.dataset.idmbo;

    if (mBoActive === "1" || mBoActive === "true") {
      //console.log(mBoActive, mType, idSport, idMatch, idBet, idMb, idBo, idMbo);
      const store = getStore();
      const bst = getBetsState(store.getState());

      let m = null;

      if (mType === "live") {
        if (idMatch in bst.live.matches) {
          m = bst.live.matches[idMatch];
        }
      } else {
        if (idMatch in bst.prematch.matches) {
          m = bst.prematch.matches[idMatch];
        }
      }

      if (m === null) {
        return;
      }

      store.dispatch({
        type: betsSlipConstants.TOGGLE,
        mType,
        idSport,
        idMatch,
        idBet,
        idMb,
        idBo,
        idMbo,
        match: cloneDeep(m),
        matches: {
          live: bst.live.matches,
          prematch: bst.prematch.matches
        },
        combiningAllowed: bst.nsoft.combiningAllowed
      });
    }
  }
};

const Bet = props => {
  const classes = useStyles({
    total: props.total,
    banner: props.banner,
    bannerWP: props.bannerWP,
    details: props.details,
    screenWidth: window.screen.availWidth
  });

  const {
    id,
    idSport,
    idMatch,
    idBet,
    idMb,
    bet,
    banner,
    highlighted,
    dark,
    bets,
    selected,
    match,
    className,
    topLiveModel,
    details,
    bannerWP
  } = props;

  let isDesktop = useMediaQuery("(min-width:1821px)");

  if (banner || bannerWP || details || topLiveModel) isDesktop = false;

  const [changeDir, setChangeDir] = React.useState(
    typeof bet.changeDir !== "undefined" ? bet.changeDir : ""
  );
  React.useEffect(() => {
    setChangeDir(bet.changeDir);
  }, [bet.changeDir]);

  if (!match) return null;

  let bg = banner ? classes.bgBanner : dark ? classes.bgDark : classes.bgRegular;

  let status = "";
  let darkCls = "";
  let highlightedCls = "";

  if (selected.find(e => e.idMbo === bet.idMbo)) {
    status = `${classes.active} ${isDesktop ? classes.activeDesktop : ""}`;
  }

  if (dark) {
    darkCls = classes.dark;
  }

  if (highlighted) {
    highlightedCls = classes.highlighted;
  }

  let mboActive = bet.mboActive;

  //console.log("matchBets", match.matchBets);

  const matchBets = match.matchBets.filter(m => m.idMb === idMb);
  if (matchBets && matchBets.length) {
    if (!matchBets[0].mbActive) {
      // should we disable all bets?
      mboActive = false;
    }
  }

  let lockColor = "";
  if (mboActive === false) {
    bg = banner ? classes.bannerLock : dark ? classes.darkLock : classes.lightLock;
    lockColor = banner
      ? classes.bannerLockColor
      : dark
        ? classes.darkLockColor
        : classes.lightLockColor;
  }

  const buttonClass = `${classes.button} ${bg} ${status} ${darkCls} ${highlightedCls} myBetElement`;

  const handleAnimationEnd = event => {
    if (
      event.animationName === "animArrowGreenFrames" ||
      event.animationName === "animArrowRedFrames"
    ) {
      if (event.currentTarget) {
        setChangeDir("");
      }
    }
  };

  let arrow = null;
  let color = "";
  if (banner === false && bet && bet.mboActive) {
    if (changeDir === "up") {
      arrow = (
        <div
          className={`${classes.arrow} animArrowGreen`}
          onAnimationEnd={handleAnimationEnd}
        //key={`key_${bet.mboOddValue}`}
        >
          <GreenArrow />
        </div>
      );
      color = "colorGreen";
    } else if (changeDir === "down") {
      arrow = (
        <div
          className={`${classes.arrow} animArrowRed`}
          onAnimationEnd={handleAnimationEnd}
        //key={`key_${bet.mboOddValue}`}
        >
          <RedArrow />
        </div>
      );
      color = "colorRed";
    }
  }

  const oddName = formatOddName(idBet, bet, match, bets);

  const cnt = (
    <React.Fragment>
      <div
        className={`${classes.wrapper} d-flex ${isDesktop ? "flex-column" : "flex-row"
          } flex-nowrap justify-content-between bet-odd`}
      >
        <div
          className={`${classes.betType} ${isDesktop ? (dark ? classes.betTypeDesktopDark : classes.betTypeDesktop) : ""
            } betType ${lockColor} ${details ? "details" : ""}`}
        >
          {oddName}
        </div>
        <div
          className={`d-flex flex-row flex-nowrap align-items-center `}
        >
          {arrow}
          <div className={`${classes.betValue} betValue ${color} ${details ? "details" : ""}`}>
            {mboActive && formatOddValue(bet.mboOddValue)}
            {!mboActive && <LockOutlinedIcon className={lockColor} />}
          </div>
        </div>
      </div>
      {mboActive === false && <div className={classes.lock}></div>}
    </React.Fragment>
  );

  //if (isActualDesktop) {
  return (
    <div
      id={id}
      className={clsx(className, buttonClass)}
      onClick={bet.mboOddValue === 0 ? noop : onBetToggle}
      disabled={!mboActive}
      data-mboactive={mboActive}
      data-mtype={match.mType}
      data-idsport={idSport}
      data-idmatch={idMatch}
      data-idbet={idBet}
      data-idmb={idMb}
      data-idbo={bet.idBo}
      data-idmbo={bet.idMbo}
      title={oddName}
    >
      {cnt}
    </div>
  );
};

Bet.propTypes = {
  id: PropTypes.string,
  bet: PropTypes.object,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

Bet.defaultProps = {
  id: "",
  idSport: "0",
  idBet: "0",
  bet: {},
  banner: false,
  highlighted: false,
  active: false,
  dark: false,
  onClick: () => { }
};

const getBets = makeGetBets();
const getStateMatch = makeGetStateMatch();

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  return {
    bets: getBets(state, props),
    match: getStateMatch(state, props),
    selected:
      props.mType === "live"
        ? bst.betsSlip.tickets[bst.betsSlip.currentTicket].live.selected
        : bst.betsSlip.tickets[bst.betsSlip.currentTicket].prematch.selected,
    outcomeAbbr: bst.config.outcomeAbbr
  };
};


export default connect(mapStateToProps)(Bet);
