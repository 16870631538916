import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getBetsState } from "../../store/selectors/betData";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import { stdLiveScore } from "../../utils/matchUtils";
import { makeGetMatchStatus } from "../../store/selectors/betData";
import Intervals from "../Match/Intervals";
import { formatOddValue, formatTicketBetTitle, formatDateShort2 } from "../../utils/formatters";
import StatScoreAvailable from "../StatScoreAvailable";
import { debug } from "../../utils";
import crests from "../../utils/crests";

const useStyles = makeStyles({
  bet: {
    borderRadius: "7px",
    paddingLeft: "4px",
    margin: "5px 7px 0"
  },
  mm: {
    margin: "5px 7px 0"
  },
  bet_won: {
    backgroundColor: "#06D2BD"
  },
  bet_lost: {
    backgroundColor: "#FF196E"
  },
  bet_void: {
    backgroundColor: "#FFC400"
  },
  bet_open: {
    backgroundColor: "#fff"
  },
  betWrapper: {
    backgroundColor: "#fff",
    padding: "10px 15px",
    borderTopRightRadius: "7px",
    borderBottomRightRadius: "7px"
  },
  liveBox: {
    border: "1px solid #FF196E",
    color: "#FF196E",
    width: "11px",
    height: "9px",
    borderRadius: "2px",
    marginRight: "6px",
    display: "inline-flex",
    position: "relative",
    "&>div": {
      width: "3px",
      height: "3px",
      borderRadius: "50%",
      background: "#FF196E",
      margin: "auto"
    }
  },
  title: {
    color: "#7C88A0",
    fontSize: "9px",
    paddingRight: "5px",
    display: "flex",
    alignItems: "center",
  },
  intervalName: {
    fontSize: "11px",
    fontWeight: "300",
    color: "#A79199",
    fontStyle: "italic"
  },
  intervalTime: {
    paddingLeft: "5px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#FF196E",
    fontStyle: "italic"
  },
  won: {
    backgroundColor: "#06D2BD",
    borderRadius: "7px",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    padding: "3px 0 4px",
    width: "90px",
    display: "inline-block"
  },
  lost: {
    backgroundColor: "#FF196E",
    borderRadius: "7px",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    padding: "3px 0 4px",
    width: "90px",
    display: "inline-block"
  },
  void: {
    backgroundColor: "#FFC400",
    borderRadius: "7px",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    padding: "3px 0 4px",
    width: "90px",
    display: "inline-block"
  },
  open: {
    backgroundColor: "#1F83E6",
    borderRadius: "7px",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    padding: "3px 0 4px",
    width: "90px",
    display: "inline-block"
  },
  fixed: {
    display: "inline-block",
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: "600",
    marginRight: "5px",
    backgroundColor: "#B7CDD8",
    padding: "3px 8px 4px",
    color: "#fff",
    borderRadius: "5px"
  },
  table: {
    marginTop: "10px"
  },
  tableSettled: {
    marginTop: "3px"
  },
  row: {
    "& td": {
      padding: "3px 0"
    }
  },
  team: {
    width: "100%",
    fontSize: "12px",
    fontWeight: "600",
    color: "#17214D",
    position: "relative"
  },
  muted: {
    color: "#586682"
  },
  serving: {
    "&::before": {
      content: "' '",
      position: "absolute",
      width: "5px",
      height: "5px",
      background: "hsl(60, 91%, 54%)",
      left: "-9px",
      top: "9px",
      borderRadius: "100%"
    }
  },
  scoreHeader: {
    fontSize: "8px",
    fontWeight: "300",
    color: "#979DAB",
    paddingLeft: "5px!important",
    textAlign: "center",
    whiteSpace: "nowrap"
  },
  score: {
    whiteSpace: "nowrap",
    fontSize: "12px",
    fontWeight: "600",
    color: "#979DAB",
    textAlign: "center",
    paddingLeft: "5px!important",
    "&.colored": {
      color: "#01AE3B"
    },
    "&.muted": {
      opacity: 0.6,
      "&.colored": {
        opacity: 1,
        color: "#DF2222"
      }
    },
    "&.teamScoreTotal": {
      color: "#059e8f"
    }
  },
  final: {
    "&.losing": {
      color: "#FF196E"
    },
    "&.wining": {
      color: "#06D2BD"
    }
  },
  hr: {
    height: "1px",
    width: "100%",
    background: "#DFE7EF",
    margin: "4px 0 10px"
  },
  outcome: {
    paddingRight: "10px",
    fontSize: "13px",
    fontWeight: "bold",
    color: "#020203"
  },
  value: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#000000",
    whiteSpace: "nowrap"
  },
  teamBadge: {
    margin: "0 4px 0 0",
    width: "15px"
  },
  teamImg: {
    display: "block",
    width: "auto",
    height: "15px",
    margin: "0 auto"
  },
  st: {
    textDecoration: "line-through",
  },
  seeDetails: {
    whiteSpace: "nowrap",
    fontSize: "11px",
    color: "#17214D",
    fontWeight: "normal",
    textAlign: "left",
  },
  seeDetailsArrow: {
    color: "#9197B1",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "0px",
    position: "relative",
    top: "1px",
    marginLeft: "3px",
  },
  statScoreBorderRight: {
    borderRight: "1px solid rgba(23, 33, 77, .38)",
    paddingRight: "5px",
    marginRight: "5px"
  }
});

const TicketMatch = ({
  ticket,
  bet,
  live,
  prematch,
  liveData,
  preMatchData,
  matchStatus,
  t,
  i18n,
  ticketMatches,
  ticketMeta,
  inProgress, // show only in progress
  hideBet, // hide bet; show only the match details
}) => {
  //debug("bet", bet.idMatch, bet.brId);

  const classes = useStyles();

  if (bet.status === "WON" || bet.status === "LOST" || bet.status === "VOID") {
    if (inProgress) return null;
  }

  let ticketFormat = ticket.format;
  const isBanker = bet.banker;
  let match = null;
  let idMatch = null;

  if (!(bet.status === "WON" || bet.status === "LOST")) {
    if (ticketFormat === "prematch") {
      if (bet.brId && bet.brId in live.matches) {
        match = live.matches[bet.brId];
        ticketFormat = "live";
        idMatch = bet.brId;
      } else if (bet.idMatch in prematch.matches) {
        match = prematch.matches[bet.idMatch];
        idMatch = bet.idMatch;
      }
    } else {
      if (bet.idMatch in live.matches) {
        match = live.matches[bet.idMatch];
        idMatch = bet.idMatch;
      }
    }
  }

  let titleIdMatch = null;
  if (bet.idMatch) {
    titleIdMatch = bet.idMatch;
  }

  const status = bet.status.toLowerCase();
  let sportName = "";
  let tournamentName = ""; // eslint-disable-line
  let categoryName = "";
  let titleParts = [];

  if (ticketMatches[ticketFormat] && titleIdMatch in ticketMatches[ticketFormat]) {
    const md = ticketMatches[ticketFormat][titleIdMatch];

    if (md) {
      try {
        sportName = ticketMeta[ticketFormat].sports[md.idSport];
      } catch (err) {/**/ }
      try {
        tournamentName = ticketMeta[ticketFormat].tournaments[md.idTournament];
      } catch (err) {/**/ }
      try {
        categoryName = ticketMeta[ticketFormat].categories[md.idCategory];
      } catch (err) {/**/ }
    }
  }

  if (sportName) titleParts.push(sportName);
  if (categoryName) titleParts.push(categoryName);
  if (tournamentName) titleParts.push(tournamentName);

  if (sportName === "" && bet.idSport && bet.idCategory && bet.idTournament) {
    if (ticketFormat === "live") {
      if (live.sports && live.sports[bet.idSport]) sportName = live.sports[bet.idSport].sportName;
      if (live.tournaments && live.tournaments[bet.idTournament])
        tournamentName = live.tournaments[bet.idTournament].tournamentName;
    } else if (ticketFormat === "prematch") {
      if (prematch.sports[bet.idSport]) sportName = prematch.sports[bet.idSport].sportName;
      if (prematch.tournaments[bet.idTournament])
        tournamentName = prematch.tournaments[bet.idTournament].tournamentName;
    }
  }

  let title = titleParts.join(" / ");
  let intervalName = "";
  let intervalTime = "";
  let scores = [];
  let intervals = null;
  let losing = 0;
  let serving = 0;

  if (match && match.mType === "live") {
    const scoreData = stdLiveScore(match, matchStatus);
    scores = scoreData.scoreWithFormatData;

    losing = scoreData.teamLosing;

    intervalName = scoreData.intervalName;
    intervalTime = scoreData.intervalTime;
    serving = scoreData.teamServing;

    intervals = <Intervals idMatch={match.idMatch} mType={match.mType} dark={false} />;
  }

  let betDisplayName = formatTicketBetTitle(bet);
  let outcomeDisplayName = bet.outcomeDisplayName;
  let oddValue = formatOddValue(bet.oddValue);
  let eventTime = formatDateShort2(bet.startAt, i18n.language);

  let clsRoot = `${classes.bet} ${classes[`bet_${status}`]} bet-in-progress-card`;
  const statusCls = classes[status];
  const statusTxt =
    status === "won"
      ? "Pariu Castigat"
      : status === "lost"
        ? "Pariu Pierdut"
        : status === "void"
          ? "Pariu Anulat"
          : status === "n/a"
            ? "" : "Pariu In Curs";
  //debug("status", status, statusTxt);

  const ft = bet.results.length ? bet.results[bet.results.length - 1].halfResult : [];

  let header = null;
  if (status !== "won" && status !== "lost" && match) {
    // open
    header = <React.Fragment>
      {title && <div className="d-flex flex-nowrap align-items-center">
        <div className={`${classes.title} text-ellipsis mw0`}>
          {title}
        </div>
      </div>}
      <div className="d-flex flex-nowrap align-items-center justify-content-between">
        <div className={`${classes.title} text-ellipsis mw0`}>
          {match.mType === "live" && (
            <span className={classes.liveBox}>
              <div></div>
            </span>
          )}
          {match.mType !== "live" && <span>{eventTime}</span>}
          {isBanker && <div className={classes.fixed}>{t("FIXED")}</div>}
          {!!intervalName && <div className={classes.intervalName}>{intervalName}</div>}
          {!!intervalTime && <div className={classes.intervalTime}>{intervalTime}</div>}
        </div>
        <div className="d-flex flex-nowrap align-items-center">
          {match.mType === "live" && <StatScoreAvailable
            idMatch={match.idMatch} mType={match.mType}
            type="classic"
            provider={match.provider}
            hasScout={match.hasScout}
            hasLiveTV={match.hasLiveTV}
            idSport={match.idSport}
            className={classes.statScoreBorderRight} />}
          {match.mType === "live" && <div className={classes.seeDetails}>{t("See event")} <span className={classes.seeDetailsArrow}>&rsaquo;</span></div>}
        </div>
      </div>
    </React.Fragment>;
  } else {
    header = <React.Fragment>
      {title && <div className="d-flex flex-nowrap align-items-center">
        <div className={`${classes.title} text-ellipsis mw0`}>
          {title}
        </div>
      </div>}
      <div className="d-flex flex-nowrap align-items-center justify-content-between">
        <div className={`${classes.title} text-ellipsis mw0`}>
          <span>{eventTime}</span>
        </div>
        <div className="d-flex flex-nowrap align-items-center">
          {bet.banker && <div className={classes.fixed}>{t("FIXED")}</div>}
          <div className={statusCls}>{statusTxt}</div>
        </div>
      </div>
    </React.Fragment>;
  }

  let infoTable = null;
  const logoTeam1 =
    bet.idSport === "1" && bet.teams?.[0] ? crests(bet.teams[0].teamDisplayName) : null;
  const logoTeam2 =
    bet.idSport === "1" && bet.teams?.[1] ? crests(bet.teams[1].teamDisplayName) : null;
  if (status !== "won" && status !== "lost" && match) {
    const formatScore = s => {
      /*
      if (match.idSport === "5") {
        if (s === 50) {
          return "A";
        }
      }
      */

      return s;
    };

    infoTable = (
      <React.Fragment>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              {logoTeam1 && (
                <td>
                  <div className={classes.teamBadge}>
                    <img className={classes.teamImg} src={logoTeam1} alt="" />
                  </div>
                </td>
              )}
              <td
                className={`${classes.team} ${losing === 1 ? classes.muted : ""} ${serving === 1 ? classes.serving : ""
                  }`}
              >
                {bet.teams[0].teamDisplayName}
              </td>
              {scores.map((s, i) => (
                <td
                  key={"score" + i}
                  className={clsx(classes.score, {
                    muted: s[0] < s[1],
                    teamScoreTotal: s[2].isBig,
                    colored: s[2].isColored
                  })}
                >
                  {formatScore(s[0])}
                </td>
              ))}
            </tr>
            <tr className={classes.row}>
              {logoTeam2 && (
                <td>
                  <div className={classes.teamBadge}>
                    <img className={classes.teamImg} src={logoTeam2} alt="" />
                  </div>
                </td>
              )}
              <td
                className={`${classes.team} ${losing === 2 ? classes.muted : ""} ${serving === 2 ? classes.serving : ""
                  }`}
              >
                {Array.isArray(bet.teams) && bet.teams.length >= 2 && bet.teams[1].teamDisplayName}
              </td>
              {scores.map((s, i) => (
                <td
                  key={"score" + i}
                  className={clsx(classes.score, {
                    muted: s[0] > s[1],
                    teamScoreTotal: s[2].isBig,
                    colored: s[2].isColored
                  })}
                >
                  {formatScore(s[1])}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        {intervals}
      </React.Fragment>
    );
  } else {
    losing = ft && ft.length === 2 ? (ft[0] < ft[1] ? 1 : ft[0] > ft[1] ? 2 : 0) : 0;

    infoTable = (
      <table className={classes.tableSettled}>
        <tbody>
          <tr className={classes.row}>
            <td className={classes.team}></td>
            {bet.results.map((res, i) => (
              <td key={`col_header_${i}`} className={classes.scoreHeader}>
                {res.halfName}
              </td>
            ))}
          </tr>
          <tr className={classes.row}>
            {logoTeam1 && (
              <td>
                <div className={classes.teamBadge}>
                  <img className={classes.teamImg} src={logoTeam1} alt="" />
                </div>
              </td>
            )}
            <td className={`${classes.team} ${losing === 1 ? classes.muted : ""}`}>
              {bet.teams[0].teamDisplayName}
            </td>
            {bet.results.map((res, i) => (
              <td
                key={`col_team1_${i}`}
                className={`${classes.score} ${i === bet.results.length - 1
                  ? `${classes.final} ${losing === 1 ? "losing" : losing === 0 ? "" : "wining"}`
                  : ""
                  }`}
              >
                {res.halfResult[0]}
              </td>
            ))}
          </tr>
          <tr className={classes.row}>
            {logoTeam2 && (
              <td>
                <div className={classes.teamBadge}>
                  <img className={classes.teamImg} src={logoTeam2} alt="" />
                </div>
              </td>
            )}
            <td className={`${classes.team} ${losing === 2 ? classes.muted : ""}`}>
              {Array.isArray(bet.teams) && bet.teams.length >= 2 && bet.teams[1].teamDisplayName}
            </td>
            {bet.results.map((res, i) => (
              <td
                key={`col_team1_${i}`}
                className={`${classes.score} ${i === bet.results.length - 1
                  ? `${classes.final} ${losing === 2 ? "losing" : losing === 0 ? "" : "wining"}`
                  : ""
                  }`}
              >
                {res.halfResult[1]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  }

  let oddValueDisplay = oddValue;
  if (bet.status === "VOID") {
    oddValueDisplay = (<React.Fragment>
      <span className={classes.st}>{oddValue}</span> 1.00
    </React.Fragment>);
  }

  let betRow = null;
  if (!hideBet) {
    betRow = (
      <div
        className={`${classes.betRow} d-flex flex-nowrap align-items-center justify-content-between`}
      >
        <div className={classes.outcome}>
          {betDisplayName} | {outcomeDisplayName}
        </div>
        <div className={classes.value}>{oddValueDisplay}</div>
      </div>
    );
  }

  return (
    <div className={clsRoot}>
      <div className={classes.betWrapper}>
        {header}
        {infoTable}
        {!intervals && <div className={classes.hr}></div>}
        {betRow}
      </div>
    </div>
  );
};

const getMatchStatus = makeGetMatchStatus();
const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  let matchStatus = {};

  if (!(props.bet.status === "WON" || props.bet.status === "LOST")) {
    if (props.ticket.format === "live") {
      if (props.bet.idMatch in bst.live.matches) {
        matchStatus = getMatchStatus(state, { idMatch: props.bet.idMatch });
      }
    } else if (props.ticket.format === "prematch") {
      if (props.bet.brId in bst.live.matches) {
        matchStatus = getMatchStatus(state, { idMatch: props.bet.brId });
      }
    }
  }

  return {
    liveData: bst.live.data,
    live: bst.live,
    preMatchData: bst.prematch.data,
    prematch: bst.prematch,
    matchStatus: matchStatus,
    ticketMatches: bst.tickets.matches,
    ticketMeta: bst.tickets.meta
  };
};

export default withTranslation()(connect(mapStateToProps)(TicketMatch));
