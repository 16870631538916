import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import LockIcon from "@material-ui/icons/Lock";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import BarChartIcon from "@material-ui/icons/BarChart";

import {
  getBetsState,
  getPrematchSportInfoRetail,
  makeGetCategories,
  makeGetTournaments,
  makeGetBets,
  getPrematchMatchData
} from "../../store/selectors/betData";
import { betsSlipToggle } from "../../../bets/store/actions/betsSlip";
import BackHeader from "../../components/back-header";
import BetFilter from "../../components/bet-filter";
import { getBaseRouterPath } from "../../utils/config";
import { formatOddValue, formatOddName, formatBetTitle } from "../../utils/formatters";
import { getMatchMarketGroups } from "../../../bets/utils/matchUtils";
import { setOpenState } from "../../../bets/store/actions/matchDetails";
import { sortArrayByKey2 } from "../../../bets/utils";

import { prematchFetchMatch } from "../../../bets/store/actions/prematch";

const Match = props => {
  const {
    matchData,
    match,
    categories,
    tournaments,
    history,
    //betAbbr,
    betDesc,
    bets,
    selected,
    toggleBet,
    setOpenState,
    t,
    inPage,
    onClose,
    prematchFetchMatch,
  } = props;

  const [filter, setFilter] = React.useState("");
  const [matchBets, setMatchBets] = React.useState([]);
  const [allMatchBets, setAllMatchBets] = React.useState([]);
  const [favoriteBets, setFavoriteBets] = React.useState({});
  const [hasDescription, setHasDescription] = React.useState({});

  //console.log("matchBets", matchBets, "allMatchBets", allMatchBets, "favoriteBets", favoriteBets);
  const [params, setParams] = React.useState({
    idSport: !inPage && match.params.idSport ? match.params.idSport : props.idSport,
    idCategory: !inPage && match.params.idCategory ? match.params.idCategory : props.idCategory,
    idTournament:
      !inPage && match.params.idTournament ? match.params.idTournament : props.idTournament,
    idMatch: !inPage && match.params.idMatch ? match.params.idMatch : props.idMatch
  });

  const [marketGroups, setMarketGroups] = React.useState([]);
  const [selectedMarketGroup, setSelectedMarketGroup] = React.useState(null);
  const [loaded, setLoaded] = React.useState({});

  const handleMarketGroupsSelections = i => {
    setSelectedMarketGroup(marketGroups[i]);
    const elem = document.querySelector(".wrapper-of-bets");
    if (elem) elem.scrollTop = 0;
  };

  //console.log("matchData", matchData?.idMatch, props.idMatch);

  React.useEffect(() => {
    if (matchData && matchData.mType === "prematch" && props.idMatch && !loaded[props.idMatch]) {
      prematchFetchMatch([props.idMatch]);
      setLoaded({ ...loaded, [props.idMatch]: true });
    }
  }, [props.idMatch, matchData, matchData.mType, prematchFetchMatch, loaded]);

  React.useEffect(() => {
    if (!inPage) {
      setParams({
        idSport: match && match.params.idSport ? match.params.idSport : null,
        idCategory: match && match.params.idCategory ? match.params.idCategory : null,
        idTournament: match && match.params.idTournament ? match.params.idTournament : null,
        idMatch: match && match.params.idMatch ? match.params.idMatch : null
      });
    }
  }, [match, inPage]);

  React.useEffect(() => {
    if (inPage) setOpenState(true);
    return () => {
      if (inPage) setOpenState(false);
    };
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (inPage) {
      setParams({
        idSport: props.idSport,
        idCategory: props.idCategory,
        idTournament: props.idTournament,
        idMatch: props.idMatch
      });
    }
  }, [props.idSport, props.idCategory, props.idTournament, props.idMatch, inPage]);

  const handleBetClick = (e) => {
    e.stopPropagation();

    const target = e.currentTarget;

    if (target) {
      const mboActive = target.dataset.mboactive;

      if (mboActive === "1" || mboActive === "true") {
        const idBet = target.dataset.idbet;
        const idMb = target.dataset.idmb;
        const idBo = target.dataset.idbo;
        const idMbo = target.dataset.idmbo;

        toggleBet(matchData.mType, matchData.idSport, matchData.idMatch, idBet, idMb, idBo, idMbo, {
          ...matchData
        });
      }
    }
  };

  React.useEffect(() => {
    let mb = matchData && matchData.matchBets ? [...matchData.matchBets] : [];

    if (matchData) {
      const mkg = getMatchMarketGroups(matchData);
      setMarketGroups(mkg);

      if (mkg.length > 0) {
        if (selectedMarketGroup === null) {
          setSelectedMarketGroup(mkg[0]);
        } else {
          const gIdx = mkg.findIndex(g => g.id === selectedMarketGroup.id);
          if (gIdx === -1) {
            setSelectedMarketGroup(mkg[0]);
          } else {
            setSelectedMarketGroup(mkg[gIdx]);
          }
        }
      } else {
        setSelectedMarketGroup(null);
      }

      // load favorite bets
      const fb = localStorage.getItem("mdFavoriteBets_" + matchData.idSport);
      if (fb !== null) {
        const jfb = JSON.parse(fb);
        setFavoriteBets(jfb);
      }

      // sort bets by NSoft order
      sortArrayByKey2(mb, "mbPosition", "mbSpecialValue");
      //sortArrayByKey(mb, "mbPosition");

      // load bets order
      let bo = [];
      const jbo = localStorage.getItem("mdBetsOrder_" + matchData.mType + "_" + matchData.idSport);
      if (jbo !== null) {
        bo = JSON.parse(jbo);
      }

      // extract from matchData bets those we need to order (put first)
      let smb = [];

      bo.forEach(id => {
        let idx = mb.findIndex(b => b.idBet === id);
        if (idx !== -1) {
          smb.push(mb[idx]);
          mb.splice(idx, 1);
        }
      });

      // rebuild bets list
      mb = [...smb, ...mb];

      let idx = 0;

      // put winner avantaj or plus at the top
      if ((idx = mb.findIndex(b => b.winnerPlus || b.winnerAdv)) !== -1) {
        const b = mb.splice(idx, 1);
        mb.unshift(b[0]);
      }

      const hDescription = {};
      const descriptions = betDesc[matchData.mType];
      mb.forEach(m => {
        const title = formatBetTitle(m, matchData, bets);
        if (title && typeof descriptions[title.toLowerCase()] !== "undefined") {
          hDescription[m.idBet] = descriptions[title.toLowerCase()];
        }
      });

      setAllMatchBets(mb);
      setMatchBets(mb);
      setHasDescription(hDescription);
    }
  }, [matchData, betDesc]); // eslint-disable-line

  const handleHeaderChange = (type, value) => {
    if (type === "back") {
      if (inPage) {
        if (typeof onClose === "function") onClose();
        return;
      }
      if (document.referrer === "") {
        const basePath = getBaseRouterPath();
        history.push(`${basePath}/prematch`);
      } else {
        history.goBack();
      }
    } else if (type === "filter") {
      setFilter(value);
    }
  };

  if (!matchData)
    return (
      <div className="content-wrap has-left-nav has-sidebar sport-page">
        <BackHeader filter={filter} onChange={handleHeaderChange} noPortal={inPage} />
        <div className="elements">
          <div className="empty">{t("Currently there is no match for selected parameters!")}</div>
        </div>
      </div>
    );

  let title = [];
  if (typeof categories[params.idCategory] !== "undefined") {
    title.push(`${categories[params.idCategory].categoryName}`);
  }
  if (typeof tournaments[params.idTournament] !== "undefined") {
    title.push(`${tournaments[params.idTournament].tournamentName}`);
  }

  title = title.join(" : ");
  let filteredBets = matchBets;

  if (selectedMarketGroup) {
    if (!selectedMarketGroup.isAll) {
      filteredBets = matchBets.filter(mb => selectedMarketGroup.matchBets.indexOf(mb.idMb) > -1);
    }
  }

  const brId = matchData.brId ? matchData.brId : "";
  const statsURL = `https://s5.sir.sportradar.com/blingbet/ro/match/${brId}`;

  return (
    <React.Fragment>
      <div className="match-details-top-header">
        <BackHeader filter={filter} onChange={handleHeaderChange} noPortal={inPage} />
        <div className="elementsTopPadding">
          <div className={`match-header sport-color-${params.idSport}`}>
            <div className="name">
              {matchData.team1Name} : {matchData.team2Name}
            </div>
            <div className="time">
              {/*matchData.displayId && (
                <span className="info-code">
                  {t("CODE")}: {matchData.displayId}
                  <span className="dn">&nbsp;|&nbsp;</span>
                </span>
              )*/}
              <span className="info-title">
                {moment(matchData.matchDateTime).format("HH:mm DD/MM/YYYY")} - {title}
              </span>
            </div>

            {brId && (
              <a
                href={statsURL}
                target="_blank"
                rel="noopener noreferrer"
                className="header-stats"
                title={t("Statistics")}
              >
                <BarChartIcon />
              </a>
            )}
          </div>

          {matchData && matchData.bettingStatus && (
            <BetFilter marketGroups={marketGroups} onChange={handleMarketGroupsSelections} />
          )}
        </div>
      </div>

      <div className="elementsNoYPadding wrapper-of-bets">
        {matchData &&
          matchData.bettingStatus &&
          filteredBets.map((mb, listIndex) => {
            const outcomes = [...mb.mbOutcomes];
            sortArrayByKey2(outcomes, "mboPosition", "mbSpecialValue");
            const len = outcomes.length;
            const title = formatBetTitle(mb, matchData, bets);
            if (filter && title.toLowerCase().indexOf(filter.toLowerCase()) === -1) return null;

            // don't show inactive odss and/or markets
            let hasActive = false;
            outcomes.forEach((bo, i) => {
              if (bo.mboActive) {
                hasActive = true;
              }
            });
            if (!hasActive) return null;
            if (!mb.mbActive) return null;

            return (
              <div className="bets-list" key={listIndex}>
                <div className="bets-header">{title}</div>
                <div
                  className={`odds-container ${len > 2 && len !== 4 ? "bets-three" : ""} clearfix`}
                >
                  {outcomes.map((bet, i) => {
                    let mboActive = bet.mboActive;

                    if (!mboActive) return null;

                    const type = formatOddName(mb.idBet, bet, matchData, bets);
                    const value = formatOddValue(bet.mboOddValue);
                    let active = false;
                    if (selected.find(e => e.idMbo === bet.idMbo)) {
                      active = true;
                    }
                    const changeDir = bet.changeDir;

                    return (
                      <div
                        key={i}
                        disabled={!mboActive}
                        className={`button-wrapper ${active ? "active" : ""} outcome`}
                        data-idbet={mb.idBet}
                        data-idmb={mb.idMb}
                        data-idbo={bet.idBo}
                        data-idmbo={bet.idMbo}
                        data-mboactive={mboActive}
                        onClick={handleBetClick}
                      >
                        <div
                          key={`odd_${bet.idMbo}_${bet.mboType}_${bet.mboOddValue}_${bet.changeDir}`}
                          className={`button ${changeDir ? `change-${changeDir}` : ""}`}
                        >
                          <span className="type">{type}</span>
                          {mboActive && <span className="value">{value}</span>}
                          {!mboActive && (
                            <span className="value">
                              <LockIcon />
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        {matchBets.length === 0 && (
          <div className="bets-list-empty">
            <LockOutlinedIcon />
            {t("Bet market is updating")}
          </div>
        )}
        {matchData && !matchData.bettingStatus && (
          <div className="bets-list-empty">
            <LockOutlinedIcon />
            {t("Betting is locked")}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  const getBets = makeGetBets();
  const getCategories = makeGetCategories();
  const getTournaments = makeGetTournaments();

  return (state, props) => {
    const bst = getBetsState(state);
    const init = {
      mType: "prematch",
      idMatch:
        !props.inPage && props.match && props.match.params.idMatch
          ? props.match.params.idMatch
          : props.idMatch,
      idSport:
        !props.inPage && props.match && props.match.params.idSport
          ? props.match.params.idSport
          : props.idSport,
      idCategory:
        !props.inPage && props.match && props.match.params.idCategory
          ? props.match.params.idCategory
          : props.idCategory,
      idTournament:
        !props.inPage && props.match && props.match.params.idTournament
          ? props.match.params.idTournament
          : props.idTournament
    };

    return {
      betAbbr: bst.config.betAbbr,
      betDesc: bst.config.betDesc,
      bets: getBets(state, init),
      categories: getCategories(state, init),
      tournaments: getTournaments(state, init),
      sport: getPrematchSportInfoRetail(state, init),
      matchData: getPrematchMatchData(state, init),
      selected: bst.betsSlip.tickets[bst.betsSlip.currentTicket].prematch.selected
    };
  };
};

const actionCreators = {
  toggleBet: betsSlipToggle,
  setOpenState: setOpenState,
  prematchFetchMatch,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(Match));
