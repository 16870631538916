import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Moment from "react-moment";
import Icons from "../../../../../assets/Icons/Icons";
import Utils from "../../../../../utils/Utils";
import EmptyList from "./EmptyList";

class GamesSpins extends Component {
    constructor(props) {
        super(props);

        this.gameIcon = Icons.get("cherries", "game-spins");

        this.selfType = 1;

        this.icons = {
            "download": Icons.get("historyCsvDownload", "historyCsvDownload"),
            "loading": Icons.get("historyCsvGenerate", "historyCsvGenerate"),
        };
    }

    createList() {
        let list = [];

        if (!(this.props.transactions.spins.length > 0)) {
            return <EmptyList />;
        }

        this.props.transactions.spins.forEach((elem, index) => {
            let spinWin = this.props.t("no win");
            let winClass = ["spin-win"];
            if (parseFloat(elem.win).toFixed(2) > 0) {
                let formattedAmount = Utils.formatAmount(elem.win, true, this.props.wallet.currency);
                spinWin = `+${formattedAmount}`;
                winClass.push("positive");
            }

            let component = (
                <li key={`history_elem_${index}`}>
                    <div className={"historyElement gameSpin"}>
                        <div className={"icon"}>{this.gameIcon}</div>
                        <div className={"details"}>
                            <div className={"gsDetails"}>
                                <div className={"gameName"}>{elem.game}</div>
                                <div className={"stakeRow"}>
                                    <div className={""}>
                                        {elem.is_free === '1' ? "FREE SPIN" : this.props.t("Stake") + ":"} { elem.is_free === '1' ? "": Utils.formatAmount(elem.bet, true, this.props.wallet.currency)}
                                    </div>
                                    <span className={"spacer"}>|</span>
                                    <div className={winClass.join(" ")}>
                                        {this.props.t("Result")}: <strong>{spinWin}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className={"timestamp"}>
                                <p className={"hour"}>
                                    <Moment format={"HH:mm"} unix>{elem.spin_timestamp}</Moment>
                                </p>
                                <p className={"date"}>
                                    <Moment format={"DD/MM/YYYY"} unix>{elem.spin_timestamp}</Moment>
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            );

            list.push(component);
        });

        if (!(list.length > 0)) {
            return <EmptyList />
        }

        return (
            <ul className={"spinsList"}>{list}</ul>
        );
    }

    downloadCsv(event) {
        event.nativeEvent.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        if (this.props.transactions.generatingCsv) {
            return false;
        }

        if (this.props.transactions.emptyCsv) {
            return false;
        }

        this.props.downloadCsv(this.selfType, event);

        return false;
    }

    render() {
        if (!this.props.transactions.spins) {
            return undefined;
        }

        let dIcon = this.icons.download;
        let dIconClass = ["dIcon"];
        let dText = "Download history for last 90 days";
        if (this.props.transactions.generatingCsv) {
            dIcon = this.icons.loading;
            dText = "Please wait! Generating CSV file...";
            dIconClass.push("loading");
        }

        if (this.props.transactions.emptyCsv) {
            dText = "The 90 days report is empty. Please try again later!";
            dIcon = null;
        }

        return (
            <div className={"transactionsList"}>
                {this.createList()}
                <div className={"downloadCsv"}>
                    <a href={"/csv/download"} onClick={this.downloadCsv.bind(this)}>
                        <span className={dIconClass.join(" ")}>{dIcon}</span>
                        <span className={"dText"}>{this.props.t(dText)}</span>
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        transactions: state.transactions,
        wallet: state.wallet,
    }
};

export default withTranslation()(connect(mapStateToProps)(GamesSpins));
