import React from 'react';
import connect from "react-redux/es/connect/connect";
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import "./WinnerFunFront.scss";
import { getBetsState } from "../../store/selectors/betData";
import { getWinnerFunState } from "../../../winner-fun/store/selectors/winnerFunData";
import { historyLoad } from "../../../winner-fun/store/actions/history";
import { activeWinnerFunCampaign, lastActiveWinnerFunCampaign } from "../../utils/winnerFunUtils";
import WinnerFunLogo from "./assets/logo.png";
import WinnerFunDrawer from "../../../winner-fun/views/WinnerFunDrawer";
import SeasonStart from "../../../winner-fun/views/modals/SeasonStart";
import SeasonEnd from "../../../winner-fun/views/modals/SeasonEnd";

import { doLogin } from "../../../store/actions/authentication";

const WinnerFunFront = (props) => {
  const { isWinnerFun, dark, t, winnerFunCampaigns, oldWinnerFunCampaigns, historyLoad } = props;

  const [open, setOpen] = React.useState(false);
  const [showSeasonStart, setShowSeasonStart] = React.useState({
    open: false,
    campaign: null
  });
  const [showSeasonEnd, setShowSeasonEnd] = React.useState({
    open: false,
    campaign: null
  });

  React.useEffect(() => {
    historyLoad();
  }, []); // eslint-disable-line

  React.useEffect(() => {
    let seenData = localStorage.getItem("winner_fun_seen");
    let seen = {
      lastActiveCampaign: "",
      lastInactiveCampaign: "",
    };

    try {
      if (seenData && seenData !== "null") {
        seen = JSON.parse(seenData);
      }
    } catch (err) { /* noop */ }

    let skip = false;

    if (oldWinnerFunCampaigns && oldWinnerFunCampaigns.length !== 0) {
      const inactiveCampaign = lastActiveWinnerFunCampaign(oldWinnerFunCampaigns);
      if (inactiveCampaign) {
        if (seen.lastInactiveCampaign !== "" && seen.lastInactiveCampaign !== inactiveCampaign.id) {
          seen.lastInactiveCampaign = inactiveCampaign.id;
          setShowSeasonEnd({
            open: true,
            campaign: inactiveCampaign
          });
          skip = true;
        } else {
          seen.lastInactiveCampaign = inactiveCampaign.id;
        }
      }
    }

    if (!skip && winnerFunCampaigns && winnerFunCampaigns.length !== 0) {
      const activeCampaign = activeWinnerFunCampaign(winnerFunCampaigns);
      if (activeCampaign) {
        if (seen.lastActiveCampaign !== "" && seen.lastActiveCampaign !== activeCampaign.id) {
          seen.lastActiveCampaign = activeCampaign.id;
          setShowSeasonStart({
            open: true,
            campaign: activeCampaign
          });
        } else {
          seen.lastActiveCampaign = activeCampaign.id;
        }
      }
    }


    localStorage.setItem("winner_fun_seen", JSON.stringify(seen));
  }, [winnerFunCampaigns, oldWinnerFunCampaigns]); // eslint-disable-line

  const onShowDrawer = () => {
    /*
    if (false && props.authentication && ["user", "token"].indexOf(props.authentication.auth_type) === -1) {
      if (window.config.platformType === 2) {
        // mobile - do a redirect
        props.history.push("/connect");
      } else {
        // web - show modal
        props.doLogin();
      }
      return;
    }
    */

    setOpen(true);
  };
  const onCloseDrawer = () => {
    setOpen(false);
  };
  const onCloseSeasonStart = () => {
    setShowSeasonStart({
      open: false,
      campaign: null
    });
  };
  const onCloseSeasonEnd = () => {
    setShowSeasonEnd({
      open: false,
      campaign: null
    });
  };

  if (!isWinnerFun) return null;

  return (
    <div className={`bets-winner-fun-wrapper ${dark ? 'dark' : ''}`}>
      <div className="bets-winner-fun-front">
        <div className="wf-wrapper" onClick={onShowDrawer}>
          <div className="wf-logo"><img src={WinnerFunLogo} alt="wf logo" /></div>
          <div className="wf-text">{t("Tournament")} <div className="wf-no-break">Winner FUN</div></div>
          <div className="wf-go"><NavigateNextIcon /></div>
        </div>
      </div>
      {open && <WinnerFunDrawer open={open} onModalClose={onCloseDrawer} />}
      {showSeasonStart.open && <SeasonStart open={showSeasonStart.open} campaign={showSeasonStart.campaign} handleClose={onCloseSeasonStart} />}
      {showSeasonEnd.open && <SeasonEnd open={showSeasonEnd.open} campaign={showSeasonEnd.campaign} handleClose={onCloseSeasonEnd} />}
    </div>
  );
}

const mapStateToProps = state => {
  const bst = getBetsState(state);
  const wfst = getWinnerFunState(state);

  const isWinnerFun = bst.app.isWinnerFun ? true : false;

  return {
    isWinnerFun: isWinnerFun,
    authentication: state.authentication,
    winnerFunCampaigns: wfst.config && wfst.config.seasons ? wfst.config.seasons : [],
    oldWinnerFunCampaigns: wfst.history && wfst.history.seasons ? wfst.history.seasons : [],
  };
};

const actionCreators = {
  doLogin: doLogin,
  historyLoad: historyLoad,
};

export default connect(mapStateToProps, actionCreators)(withRouter(withTranslation()(WinnerFunFront)));