import React, { useEffect, useState, createRef } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import "intersection-observer"; // optional polyfill
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import BlockOutlinedIcon from "@material-ui/icons/BlockOutlined";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";
import Favorite from "../../elements/Favorite";
import SwipeProvider from "../../hoc/SwipeProvider";
import { debug, sortArrayByKey, sortArrayByKey2 } from "../../utils";
import Delete from "../BetSlip/assets/delete.svg";
import Bet from "../Bet";
import FixedBet from "../../elements/FixedBet";
import { ReactComponent as FlashImage } from "../../assets/flash.svg";
import { withCommonStyles } from "../../styles";
import { betsSlipRemove } from "../../store/actions/betsSlip";
import {
  getBetsState,
  makeGetBets,
  makeGetMatchBets,
  makeGetSelectedBet,
  makeGetMatchBettingStatus,
  makeGetStateMatch
} from "../../store/selectors/betData";

import { appSetSelectedBet } from "../../store/actions/app";
import { prematchFetchMatch, prematchFetchTournamentMatches } from "../../store/actions/prematch";
import { formatBetTitle, formatOddName, formatValue } from "../../utils/formatters";
import { buildBetId, getHeaderMarketsClassic, showMatch } from "../../utils/betUtils";

import { nSoftPrematch } from "../../api/config/nsoft";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  "@keyframes slideRight": {
    from: { width: 0 },
    to: { width: 16 }
  },
  "@keyframes slideLeft": {
    from: { width: 16 },
    to: { width: 0 }
  },
  flashWrapper: {
    animation: "$slideRight 0.2s"
  },
  flashWrapperHide: {
    animation: "$slideLeft 0.2s",
    animationFillMode: "forwards"
  },
  flashButton: {
    borderRadius: "7px",
    background: "linear-gradient(90deg, #1F83E6 0%, #06D2BD 100%)",
    padding: "6px 8px 8px 8px"
  },
  flashImage: {
    height: 16,
    width: 16,
    fill: "#fff",
    objectFit: "cover"
  },
  betWrapper: {
    display: "block",
    cursor: "pointer"
  },
  title: {
    fontWeight: "normal",
    textAlign: "left",
    fontSize: 10,
    color: props => (props.titleColor ? props.titleColor : "#979DAB"),
    lineHeight: "13px",
    marginBottom: "2px",
    textTransform: "uppercase"
  },
  moreWrapper: {
    marginRight: "-5px",
    textAlign: "left",
    minWidth: "30px",
  },
  more: {
    paddingLeft: "4px",
    fontSize: "11px",
    fontWeight: 500,
    color: props => (props.titleColor ? props.titleColor : "#979DAB"),
    whiteSpace: "nowrap"
  },
  oddItem: {
    "&:not(:first-child)": {
      marginLeft: "10px"
    }
  },
  oddItem2: {
    marginLeft: "4px"
  },
  oddSelected: {
    fontSize: 9,
    fontWeight: 700,
    textTransform: "uppercase",
    color: "#979DAB",
    position: "relative",
    top: "1px"
  },
  oddDetailsName: {
    fontSize: 11,
    fontWeight: 600,
    color: "#979DAB"
  },
  oddDetailsValue: {
    marginLeft: "8px",
    fontSize: 11,
    fontWeight: 600,
    color: "#17214D"
  },
  oddDivider: {
    marginLeft: "8px",
    fontSize: 9,
    color: "#17214D"
  },
  p0: {
    cursor: "pointer",
    margin: "-20px -15px",
    height: 15,
    padding: "20px 15px 20px 20px"
  },
  p0img: {
    //marginTop: "-3px",
    display: "inline-block",
    height: "16px"
  },
  betTitle: {
    color: "#020203",
    fontSize: "13px",
    fontWeight: "bold"
  },
  betTitle2: {
    color: "#020203",
    fontSize: "15px",
    fontWeight: "bold",
    marginRight: "17px",
    whiteSpace: "nowrap"
  },
  betTitle2Old: {
    color: "#979DAB",
    fontSize: "15px",
    marginRight: "17px",
    textDecoration: "line-through",
    whiteSpace: "nowrap"
  },
  betTitleColorGreen: {
    color: "#01AE3B"
  },
  betTitleColorRed: {
    color: "#DF2222"
  },
  dialogRoot: {
    zIndex: "1301!important",
    "& .MuiPaper-rounded": {
      borderRadius: "13px",
      backgroundColor: "transparent",
      padding: 0,
      margin: 0
    },
    "& .MuiDialogContent-root": {
      backgroundColor: "transparent",
      padding: 0,
      margin: 0
    }
  },
  dialogItem: {
    borderRadius: "13px",
    backgroundColor: "#fff",
    padding: "17px 26px",
    marginBottom: "10px",
    fontSize: "16px",
    color: "#FF196E",
    fontWeight: "bold",
    textTransform: "uppercase",
    "&:active": {
      backgroundColor: "#ccc",
      position: "relative",
      top: "3px"
    }
  },
  dialogItemActive: {
    backgroundColor: "#06D2BD",
    color: "#fff"
  },
  boxShadow: {
    boxShadow: "-3px 0 6px -6px #000",
    margin: "-8px 0 -4px",
    "&.dark": {
      boxShadow: "-3px 0 5px -6px #6375ca"
    }
  },
  scrollableDesktop: {
    overflow: "hidden",
    paddingBottom: "4px"
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  isDragging: {
    cursor: "grabbing",
    "&>*": {
      pointerEvents: "none"
    }
  },
  betsTotal: {
    display: "inline-block",
    marginLeft: "5px",
    color: "#687fae",
    fontSize: "10px",
    fontWeight: "600",
    paddingRight: "5px",
    //position: "relative",
    //top: "-1px",
  },
  mWidth: {
    minWidth: "0"
  },
  fix: {
    margin: "-10px 0 -10px -16px",
    padding: "10px 0 10px 16px"
  },
  scrollableExtra: {
    paddingBottom: "4px"
  },
  betsLocked: {
    height: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  lockIcon: {
    color: "#999",
    "& svg": {
      fill: "#999"
    }
  },
  lockText: {
    color: "#999",
    fontSize: "12px"
  },
});

const swipeDirs = ["none", "right"];

const BetSelect = props => {
  const classes = withCommonStyles(useStyles(props));

  const {
    match,
    markets,
    oddSelected,
    bets,
    dark,
    removeSelected,
    betSlip,
    inMyTickets,
    banner,
    bannerWP,
    showFixed,
    setSelectedBet,
    groupSelectedBet,
    bv,
    rv,
    lockedBet,
    maxOutcomes,
    matchBettingStatus,
    matchGroup,
    topLiveModel,
    prematchFetchMatch,
    prematchFetchTournamentMatches,
    t
  } = props;

  const reallyShowFixed = showFixed && match.provider !== "digitain";

  //const isMobile = useMediaQuery("(hover: none) and (pointer: coarse)");
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  if (topLiveModel) {
    isMobile = true;
  }
  const isDesktop = useMediaQuery('(min-width:1400px)');
  const history = useHistory();

  const [betData, setBetData] = useState({
    matchBets: [],
    outcome: "",
    outcomeSelectedIndex: -1,
    betSelected: null,
    betShow: null,
    outcomes: [],
    outcomesTotal: 0,
    more: 0
  });
  const [isDragging, setIsDragging] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [oddsGroup, setOddsGroup] = useState(null);
  const [matchId, setMatchId] = useState("");
  const [showFlash, setShowFlash] = useState({
    lastDir: "",
    lastScrollStart: -1,
    show: false
  });
  //const observerRoot = createRef(); // eslint-disable-line
  const slideRoot = createRef();
  const flashEl = React.useRef();

  //console.log("oddsGroup", oddsGroup);

  useEffect(() => {
    if (typeof match === "undefined" || match === null) {
      return;
    }

    let matchBets = [...markets];
    // matchBets = matchBets.filter(mb => mb.mbOutcomes.length > 0);
    for (let i = 0; i < matchBets.length; i++) {
      if (matchBets[i].mbOutcomes.length === 0) {
        matchBets.splice(i, 1);
        i--;
        continue;
      }
      let oddActive = false;
      for (let j = 0; j < matchBets[i].mbOutcomes.length; j++) {
        if (matchBets[i].mbOutcomes[j].mboActive === true) {
          oddActive = true;
          break;
        }
      }
      if (!oddActive) {
        matchBets.splice(i, 1);
        i--;
      }
    }
    sortArrayByKey2(matchBets, "mbPosition", "mbSpecialValue");
    matchBets = getHeaderMarketsClassic(matchBets, matchGroup);

    //console.log("oddSelected", oddSelected);

    let newOg = typeof oddsGroup === "undefined" || oddsGroup === null;

    // check if the current odds group is still available
    if (typeof oddsGroup !== "undefined" && oddsGroup !== null) {
      const og = matchBets.find(mb => mb.idMb === oddsGroup.idMb);
      if (!og) {
        newOg = true;
      }
    }

    if (matchId !== match.idMatch || newOg) {
      //console.log("computing match data", oddsGroup, selectedBet);
      /*
      let sb = selectedBet;
      if (sb > matchBets.length) {
        sb = 0;
      }
      */
      setMatchId(match.idMatch);

      // look for the first market that can be a header bet
      let mb = matchBets.find(b => {
        if (matchGroup && (matchGroup.indexOf("_wa") !== -1 || matchGroup.indexOf("_wp") !== -1)) {
          return true;
        }
        if (bets && bets[match.idSport] && b.idBet in bets[match.idSport]) {
          return bets[match.idSport][b.idBet].headerBet;
        }
        return false;
      });

      if (!mb) mb = matchBets[0];

      setOddsGroup(mb);
    }

    let oddSelectedIndex = -1;
    let outcome = "";

    let betSelected = null;
    if (oddSelected !== "") {
      for (let i = 0; i < matchBets.length; i++) {
        const bs = matchBets[i].mbOutcomes.filter(bo => bo.idMbo === oddSelected);
        if (bs.length > 0) {
          oddSelectedIndex = i;
          betSelected = bs[0];
          break;
        }
      }

      const og = matchBets[oddSelectedIndex > -1 ? oddSelectedIndex : 0] || null;

      //console.log("og", og);
      setOddsGroup(og);

      if (oddSelectedIndex !== -1) {
        outcome = formatOddName(og.idBet, betSelected, match, bets);
      }

      //console.log("outcome", outcome);
    } else {
      if (groupSelectedBet !== null) {
        const bs = matchBets.find(mb => buildBetId(mb) === groupSelectedBet.midBet);
        if (bs) {
          betSelected = bs;
          setOddsGroup(bs);
        }
      }
    }

    const ns = {
      matchBets,
      oddSelectedIndex,
      betSelected,
      outcome
    };

    setBetData(bd => ({ ...bd, ...ns }));
  }, [match, markets, bets, oddSelected, groupSelectedBet]); // eslint-disable-line

  useEffect(() => {
    let outcomes = [];
    let outcomesTotal = 0;

    if (typeof oddsGroup !== "undefined" && oddsGroup !== null) {
      const market = markets.find(mk => mk.idMb === oddsGroup.idMb);

      if (market) {
        outcomes = [...market.mbOutcomes]; // oddsGroup.mbOutcomes.filter(mbo => mbo.mboActive);
        outcomes = outcomes.filter(mbo => mbo.mboActive);
        sortArrayByKey(outcomes, "mboPosition");

        if (maxOutcomes !== 0) {
          outcomes = outcomes.slice(0, maxOutcomes);
        }

        outcomesTotal = outcomes.length;
      } else {
        //console.log(`market ${oddsGroup.idMb} not found`);
      }
    }
    //let more = 0;
    let more = outcomesTotal > 3 ? outcomesTotal - 3 : 0;

    // console.log("topLiveModel", topLiveModel, "outcomesTotal", outcomesTotal, "more", more, "outcomes", outcomes);

    if (isMobile) {
      // outcomes = outcomes.splice(0, 3);
      // Dragos request - if we have more than 3 outcomes - show the first 2 without scrolling the selector
      if (outcomes.length > 3) {
        outcomes = outcomes.slice(0, 2);
        outcomesTotal = 2;
        more = false;
      }
    }

    const ns = {
      outcomes,
      outcomesTotal,
      more
    };

    setBetData(bd => ({ ...bd, ...ns }));
  }, [oddsGroup, markets, maxOutcomes, topLiveModel]);

  useEffect(() => {
    if (slideRoot.current !== null) {
      const el = slideRoot.current;
      const parentContainerLeft = el.offsetLeft;
      const parentContainerWidth = el.clientWidth;
      if (parentContainerWidth) {
        const boxes = el.querySelectorAll(".myBetElement");
        if (boxes.length) {
          let containedBoxesCount = 0;
          boxes.forEach(box => {
            if (
              box.offsetLeft - parentContainerLeft + (box.clientWidth - 20) <=
              parentContainerWidth
            ) {
              containedBoxesCount += 1;
            }
          });
          if (betData.more !== betData.outcomesTotal - containedBoxesCount) {
            const ns = {
              more: betData.outcomesTotal - containedBoxesCount
            };
            setBetData(bd => ({ ...bd, ...ns }));
          }
        }
      }
    }
  }, [betData]);

  useEffect(() => {
    if (showFlash.show) {
      //console.log("show");
      slideRoot.current.scrollLeft = 0;

      if (typeof match !== "undefined" && match !== "null" && match.mType === "prematch" && nSoftPrematch.marketCount && !match["_loaded"]) {
        prematchFetchMatch([match.idMatch]);
      }
    }
  }, [showFlash, match]);

  if (typeof match === "undefined" || match === null) {
    return null;
  }

  const handleDialog = e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
    setTimeout(() => {
      setOpenDialog(true);
    }, 0);
  }
  const handleFlash = e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
    const target = e.currentTarget;
    setTimeout(() => {
      setAnchorEl(target);
      if (!isMobile && typeof match !== "undefined" && match !== "null" && match.mType === "prematch" && nSoftPrematch.marketCount && !match["_loaded"]) {
        prematchFetchMatch([match.idMatch]);
      }
    }, 0);
  }
  const handleClose = (e) => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
    if (!isMobile) {
      setAnchorEl(null);
    } else {
      setShowFlash({ show: false });
      setOpenDialog(false);
    }
  };
  const handleSelect = (index, e) => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();

    const newBetId = buildBetId(betData.matchBets[index]);

    debug("groupSelectedBet", groupSelectedBet, "newBetId", newBetId);

    if (newBetId !== groupSelectedBet) {
      debug(`new bet selected ${newBetId}`);

      setOddsGroup(betData.matchBets[index]);
      console.log("matchGroup", matchGroup);
      setSelectedBet(
        matchGroup, //"global",
        formatBetTitle(betData.matchBets[index], match, bets),
        newBetId
      );

      // trigger market load for matched in the same tournament
      prematchFetchTournamentMatches(match.idTournament);
    }

    if (!isMobile) {
      setAnchorEl(null);
    } else {
      setShowFlash({ show: false });
      setOpenDialog(false);
    }
  };

  const handleShowFlash = ev => {
    if (ev.phase === "drag") {
      if (!isDragging) setIsDragging(true);
      if (slideRoot.current !== null) {
        slideRoot.current.scrollLeft -= ev.distX;
      }
      return;
    }

    if (ev.phase === "endDrag") {
      setIsDragging(false);
      return;
    }

    if (topLiveModel) return;

    setShowFlash(sf => {
      let sfn = { ...sf };
      if (ev.phase === "start") {
        sfn.lastDir = ev.dir;
        if (slideRoot.current !== null) {
          sfn.lastScrollStart = slideRoot.current.scrollLeft;
        }
        return sfn;
      }
      if (ev.phase === "end") {
        if (ev.swipe === "right" && sf.lastScrollStart === 0) {
          if (sf.show) {
            return sf;
          }

          sfn.show = true;
        } else {
          sfn.lastScrollStart = -1;
        }
        return sfn;
      }

      return sf;
    });
  };

  const deleteSeletedOdd = e => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();
    removeSelected(match.mType, oddSelected);
  };

  const sMatch = showMatch(match, history);
  const handleStop = e => {
    if (betSlip) return;
    sMatch();
  };

  let betsTotal = match.activeBets;
  /*
  const exists = {};
  if (match && match.matchBets && match.matchBets.length) {
    match.matchBets.forEach(b => {
      if (typeof exists[b.idBet] === "undefined") {
        exists[b.idBet] = true;
        betsTotal += 1;
      }
    });
  }
  */

  let marketName = "";
  if (oddsGroup !== null) {
    marketName = formatBetTitle(oddsGroup, match, bets);
    if (match.mType === "prematch" && (marketName === "Final" || marketName === "Result") && !isDesktop && !bannerWP && !banner && !topLiveModel) {
      marketName = "";
    }
  }

  if (oddsGroup !== null && !lockedBet && matchBettingStatus && betData.outcomes.length === 0) {
    return <div className={classes.betsLocked}>
      <div key={'lock-item'} className={classes.lockIcon}>
        <LockOutlinedIcon />
      </div>
      <div key={'lock-text'} className={classes.lockText}>{t("EVENT BETS ARE UPDATING")}</div>
    </div>
  }

  return oddsGroup !== null ? (
    <div className={classes.betWrapper} onClick={handleStop}>
      {betData.oddSelectedIndex > -1 ? (
        <React.Fragment>
          {!!betSlip === false && (
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {reallyShowFixed && <FixedBet mType={match.mType} oddSelected={oddSelected} className={classes.fix} />}
                <div className={classes.oddSelected}>{formatBetTitle(oddsGroup, match, bets)}</div>
              </div>
              <div
                className={`${classes.oddSelectedDetails} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
              >
                <div className={classes.oddDetailsValue}>{betData.outcome}</div>
                <div className={classes.oddDetailsValue}>{formatValue(betData.betSelected.mboOddValue)}</div>
                {!inMyTickets && (
                  <div className={classes.oddDelete}>
                    <div
                      className={`${classes.p0} d-flex align-items-center`}
                      onClick={deleteSeletedOdd}
                    >
                      <img className={classes.p0img} src={Delete} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!!betSlip === true && (
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {reallyShowFixed && <FixedBet mType={match.mType} oddSelected={oddSelected} className={classes.fix} />}
                <div className={classes.betTitle}>
                  {formatBetTitle(oddsGroup, match, bets)} |{" "}
                  <span className={classes.noWrap}>{betData.outcome}</span>
                </div>
              </div>
              <div
                className={`${classes.oddSelectedDetails} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
              >
                {rv === null ? (
                  <React.Fragment>
                    {bv > 0 && bv !== betData.betSelected.mboOddValue && (
                      <div className={classes.betTitle2Old}>{formatValue(bv)}</div>
                    )}
                    <div
                      className={`${classes.betTitle2} ${bv > 0 && bv !== betData.betSelected.mboOddValue
                        ? bv < betData.betSelected.mboOddValue
                          ? classes.betTitleColorGreen
                          : classes.betTitleColorRed
                        : ""
                        }`}
                    >
                      {formatValue(betData.betSelected.mboOddValue)}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {rv !== betData.betSelected.mboOddValue && (
                      <div className={classes.betTitle2Old}>{formatValue(betData.betSelected.mboOddValue)}</div>
                    )}
                    <div
                      className={`${classes.betTitle2} ${rv !== betData.betSelected.mboOddValue
                        ? rv > betData.betSelected.mboOddValue
                          ? classes.betTitleColorGreen
                          : classes.betTitleColorRed
                        : ""
                        }`}
                    >
                      {formatValue(rv)}
                    </div>
                  </React.Fragment>
                )}
                {!inMyTickets && (
                  <div className={classes.oddDelete}>
                    <div
                      className={`${classes.p0} d-flex align-items-center`}
                      onClick={deleteSeletedOdd}
                    >
                      <img className={classes.p0img} src={Delete} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={`d-flex flex-row flex-nowrap align-items-center ${classes.mWidth}`}>
            <div className={`${classes.title} ${classes.mWidth}`}>
              <span>{marketName}</span>
            </div>
            {false && betsTotal > 0 && betData.outcomes.length > 0 && matchBettingStatus && !banner && !bannerWP &&
              <div className={classes.betsTotal}>+{betsTotal}</div>}
          </div>
          <div className={`d-flex flex-row flex-nowrap justify-content-between align-items-end`}>
            {!window.config.cordova && <SwipeProvider onSwipe={handleShowFlash} dirs={swipeDirs}>
              <div
                className={`${isMobile ? `${classes.scrollable} ${classes.scrollableExtra}` : classes.scrollableDesktop} w100 ${isDragging ? classes.isDragging : ""
                  }`}
                ref={slideRoot}
              >
                <div className={`d-flex flex-row flex-nowrap align-items-center`}>
                  {!lockedBet && betData.outcomes.length > 0 && matchBettingStatus && !isMobile ? (
                    <div className={clsx(classes.flashWrapper, classes.scrollableItem)}>
                      {!banner && !topLiveModel && (
                        <IconButton onClick={handleFlash} className={classes.flashButton}>
                          <FlashImage className={classes.flashImage} />
                        </IconButton>
                      )}
                      {anchorEl !== null && (
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          {betData.matchBets.map((mb, i) => (
                            <MenuItem key={i} onClick={(e) => handleSelect(i, e)}>
                              {formatBetTitle(mb, match, bets)}
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    </div>
                  ) : (
                    !lockedBet && betData.outcomes.length > 0 && matchBettingStatus &&
                    showFlash.show && (
                      <div
                        ref={flashEl}
                        className={clsx(classes.flashWrapper, classes.scrollableItem)}
                      >
                        <IconButton onClick={handleDialog} className={classes.flashButton}>
                          <FlashImage className={classes.flashImage} />
                        </IconButton>
                      </div>
                    )
                  )}
                  {betData.outcomes.map((bo, i) => (
                    <Bet
                      key={i}
                      id={bo.idMbo}
                      className={clsx(classes.scrollableItem, classes.oddItem)}
                      idSport={match.idSport}
                      idMatch={match.idMatch}
                      mType={match.mType}
                      idBet={oddsGroup.idBet}
                      idMb={oddsGroup.idMb}
                      total={betData.outcomesTotal}
                      bet={bo}
                      dark={dark}
                      banner={banner}
                      bannerWP={bannerWP}
                      topLiveModel={topLiveModel}
                    />
                  ))}
                  {false && betsTotal > 0 && (
                    <div className={clsx(classes.scrollableItem, classes.oddItem2)}>&nbsp;</div>
                  )}
                </div>
              </div>
            </SwipeProvider>}
            {window.config.cordova && <div
              className={`${isMobile ? `${classes.scrollable} ${classes.scrollableExtra}` : classes.scrollableDesktop} w100 bts-scrollable ${isDragging ? classes.isDragging : ""
                }`}
              ref={slideRoot}
            >
              <div className={`d-flex flex-row flex-nowrap align-items-center`}>
                {betData.outcomes.slice(0, 3).map((bo, i) => (
                  <Bet
                    key={i}
                    id={bo.idMbo}
                    className={clsx(classes.scrollableItem, classes.oddItem)}
                    idSport={match.idSport}
                    idMatch={match.idMatch}
                    mType={match.mType}
                    idBet={oddsGroup.idBet}
                    idMb={oddsGroup.idMb}
                    total={betData.outcomesTotal}
                    bet={bo}
                    dark={dark}
                    banner={banner}
                    bannerWP={bannerWP}
                    topLiveModel={topLiveModel}
                  />
                ))}
                {false && betsTotal > 0 && (
                  <div className={clsx(classes.scrollableItem, classes.oddItem2)}>&nbsp;</div>
                )}
              </div>
            </div>}
            {betsTotal > 0 && betData.outcomes.length > 0 && matchBettingStatus && !banner && !bannerWP && (
              <div
                className={`${classes.moreWrapper} ${dark ? "dark" : ""
                  } d-flex justify-content-start align-items-center moreBets`}
              >
                <div className={`${classes.more} bet-select-more`}>+{betsTotal}</div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      {showFlash.show && openDialog && (
        <Dialog
          open={openDialog}
          fullWidth={true}
          maxWidth={"md"}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className={`${classes.dialogRoot} betsModal`}
        >
          <DialogContent className={classes.dialogContent}>
            {betData.matchBets.map((mb, i) => (
              <div
                key={i}
                className={`${classes.dialogItem} ${groupSelectedBet !== null && groupSelectedBet.midBet === buildBetId(mb)
                  ? classes.dialogItemActive
                  : ""
                  }`}
                onClick={(e) => handleSelect(i, e)}
              >
                {formatBetTitle(mb, match, bets)}
              </div>
            ))}
          </DialogContent>
        </Dialog>
      )}
    </div>
  ) : (
    <div className={`${classes.betWrapper} layoutUK`} onClick={handleStop}>
      <div
        className={`d-flex flex-row flex-nowrap align-items-center justify-content-between ${classes.titleArrange}`}
      >
        <div>&nbsp;</div>
        <div>
          <Favorite
            mType={match.mType}
            dark={dark}
            idMatch={match.idMatch}
            winnerPlus={match.winnerPlus}
            winnerAdv={match.winnerAdv}
            winnerSpecial={match.winnerSpecial}
          />
        </div>
      </div>
      <div className={classes.betsLocked}>
        <div className={classes.lockIcon}>
          <BlockOutlinedIcon />
        </div>
        <div className={classes.lockText}>{t("BET NOT AVAILABLE")}</div>
      </div>
    </div>
  );
};

BetSelect.propType = {
  oddSelected: PropTypes.string,
  selectedBet: PropTypes.number,
  bets: PropTypes.object,
  lockedBet: PropTypes.bool,
  maxOutcomes: PropTypes.number
};

BetSelect.defaultProps = {
  oddSelected: "",
  selectedBet: 0,
  bets: {},
  lockedBet: false,
  maxOutcomes: 0
};

const getBets = makeGetBets();
const getMatchBettingStatus = makeGetMatchBettingStatus();
const getMatchBets = makeGetMatchBets();
const getSelectedBet = makeGetSelectedBet();
const getStateMatch = makeGetStateMatch();
const emptyArray = [];

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);
  const match = getStateMatch(state, props);

  if (match === null) {
    return {
      markets: emptyArray
    };
  }

  return {
    match: match,
    betAbbr: bst.config.betAbbr,
    markets: getMatchBets(state, props),
    bets: getBets(state, props),
    groupSelectedBet: getSelectedBet(state, props),
    matchBettingStatus: getMatchBettingStatus(state, props)
  };
};

const actionCreators = {
  removeSelected: betsSlipRemove,
  setSelectedBet: appSetSelectedBet,
  prematchFetchMatch,
  prematchFetchTournamentMatches
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(BetSelect));
