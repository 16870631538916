import { lottoEventsConstants } from "../actions/constants";
import { produce } from "immer";
import moment from "moment";

const initialState = {
  items: [],
  expiredItems: [],
  systems: [],
  defaultSystem: "all",
  systemsMap: {},
  loading: false,
  initialized: false,
};

const lottoEventsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case lottoEventsConstants.LOADED:
        const events = [];
        let now = moment().format("YYYY-MM-DD HH:mm:ss");
        action.rows.forEach(e => {
          const elem = { ...e };
          if (typeof state.systemsMap[elem.event_name] !== "undefined") {
            elem.iso2 = state.systemsMap[elem.event_name].iso2;
            elem.iso3 = state.systemsMap[elem.event_name].iso3;
          } else {
            elem.iso2 = "NOTAVAILABLE";
            elem.iso3 = "NOTAVAILABLE";
          }
          //console.log(elem);
          if (elem.event_date >= now)
            events.push(elem);
        });
        draft.items = events;
        draft.initialized = true;
        break;
      case lottoEventsConstants.LOADED_SYSTEMS:
        draft.systems = action.rows;
        const systemsMap = {};
        action.rows.forEach(elem => {
          if (elem.event_name === "Euro Millions") {
            elem.iso2 = "EU";
            elem.iso3 = "EUR";
          } else if (elem.event_name === "Irlanda Loto") {
            elem.iso2 = "IE";
            elem.iso3 = "IRL";
          }
          systemsMap[elem.system_name] = elem;
        });
        draft.systemsMap = systemsMap;
        break;
      case lottoEventsConstants.LOADING:
        draft.loading = action.data;
        break;
      case lottoEventsConstants.EXPIRED:
        const expiredItems = [];
        const items = draft.items.filter(e => {
          const dt = moment(e.event_date);
          const expires = dt.diff(moment());
          if (expires > 0) {
            return true;
          }
          expiredItems.push(e);
          return false;
        });
        draft.items = items;
        draft.expiredItems = expiredItems;
        draft.initialized = true;

        break;
      case lottoEventsConstants.SELECT_SYSTEM:
        draft.defaultSystem = action.name;
        break;
      case lottoEventsConstants.CLEAR_SYSTEM:
        draft.defaultSystem = "all";
        break;
      default:
      /* noop */
    }
  });


export default lottoEventsReducer;