import { takeEvery, takeLatest, put } from "redux-saga/effects";
import axios from "axios";
import * as actionTypes from "./../actions/actionTypes";
import { happyHourStatus } from "../actions/happy_hour";

let apiUrl;

if (0 && process.env.NODE_ENV === "development") {
	apiUrl = "http://localhost:8666";
} else {
	apiUrl = process.env.CFW.jackpotApiUrl;
}

function* appInitializeSaga() {
	//console.log("loading happy hour status", window.config.clientId);

	if (!(window.config && window.config.clientId)) {
		return;
	}

	try {
		const res = yield axios.get(apiUrl + "/events/" + window.config.clientId);

		yield put(happyHourStatus(res.data ? res.data.data : null));
	} catch (e) {
		console.log("failed to retrieve HH status", e);
	}
}

export default function* watchHappyHourSaga() {
	//console.log("watching happy hour");
	yield takeEvery(actionTypes.application.INITIALIZE, appInitializeSaga);
	yield takeEvery(actionTypes.application.REINITIALIZE, appInitializeSaga);
}
