import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from "../../../store/actions";
import Icons from "../../../assets/Icons/Icons";
import "./LeftMenuTrigger.scss";
import {VelocityComponent} from "velocity-react";

class LeftMenuTrigger extends Component {

    toggleHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.props.leftMenu.opened) {
            return this.props.show();
        }

        return this.props.hide();
    }

    render() {
        let leftMenuIcon = Icons.get("hamburgerSimple", "leftMenuTrigger");

        let className = ["left-trigger-menu-support"];
        if (true === this.props.leftMenu.opened) {
            className.push("opened");
        }

        if (true === this.props.application.hideLeftMenu) {
            className.push("hidden");
        }

        return (
            <VelocityComponent animation={this.props.application.hideLeftMenu ?  {height: 0} : {height: 78}} duration={1000} delay={0} runOnMount={true}>
                <div className={ className.join(" ") } style={this.props.application.hideLeftMenu ? {overflow: "hidden"} : {overflow: "none"}}>
                    <button className="left-menu-trigger" onClick={ this.toggleHandler.bind(this) }>{ leftMenuIcon }</button>
                </div>
            </VelocityComponent>
        );
    }
}

const mapStateToProps = state => {
    return {
        leftMenu: state.leftMenu,
        application: state.application
    }
};

const mapDispatchToProps = dispatch => {
    return {
        show: () => dispatch(stateActions.openLeftMenu()),
        hide: () => dispatch(stateActions.closeLeftMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuTrigger);