import * as actionTypes from './actionTypes';

let clTimer = 0;

export const showContentLoader = () => {
    clearTimeout(clTimer);
    return {
        type: actionTypes.contentLoader.SHOW,
    }
}

export const hideContentLoader = () => {
    clearTimeout(clTimer);
    return dispatch => {
        clTimer = setTimeout(() => {
            dispatch({
                type: actionTypes.contentLoader.HIDE,
            });
        }, 1000);
    }
}