import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { ReactComponent as RightIcon } from "./assets/arrowRight.svg";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import evBus from "../../utils/evbus";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    marginBottom: "7px",
    padding: "9px 13px",
  },
  eventName: {
    fontSize: "10px",
    lineHeight: "10px",
    color: "#9EA1AC",
    position: "relative",
    top: "-2px"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between"
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  live: {
    backgroundColor: "#FF196E",
    borderRadius: "6px",
    height: "20px",
    width: "34px",
    textAlign: "center",
    paddingTop: "4px",
    fontStyle: "italic",
    fontSize: "8px",
    color: "#fff",
  },
  title: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
    marginLeft: "6px",
  },
  description: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#17214D",
  },
  ticketData: {
    flex: "1 1 0",
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "right",
    color: "#000",
    padding: "3px 0 4px",
    display: "inline-block",
    whiteSpace: "nowrap"
  },
  won: {
    borderRight: "3px solid #06D2BD",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block",
    "& svg": {
      display: "inline-block"
    }
  },
  lost: {
    borderRight: "3px solid #FF196E",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block"
  },
  possibleWin: {
    display: "inline-block",
    marginRight: "10px"
  },
  rightSvg: {
    height: "14px",
    width: "auto",
    fill: "#7f7f7f",
    display: "inline-block",
    verticalAlign: "middle",
    float: "right",
    position: "relative",
    top: "2px"
  },
  hr: {
    borderTop: "1px solid #B7CDD8",
    height: "1px",
    width: "100%",
    margin: "10px auto"
  },
  balls: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto"
  },
  ball: {
    width: "29px",
    height: "29px",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#121934",
    lineHeight: "24px",
    margin: "3px 0",
    border: "1.5px solid #3D4D8E",
    background: "#fff",
  },
  ballsFlexStart: {
    justifyContent: "flex-start",
    "&>div": {
      margin: "3px 2px",
    }
  },
  ballContainer: {
    width: "29px",
    minWidth: "29px",
    maxWidth: "29px",
    position: "relative",
  },
  ballWinner: {
    color: "#fff",
    border: "1.5px solid #00C7B2",
    background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)",
  },
  ballLuckyNumber: {
    color: "#906821",
    border: "1.5px solid #A67C18",
    background: "#FFDF78",
  },
  ballLuckyNumberWon: {
    color: "#906821",
    border: "1.5px solid #00C7B2",
    background: "#FFDF78",
  },
  ballWinnerNow: {
    color: "#fff",
    border: "1.5px solid #fff",
    background: "linear-gradient(180deg, #F20732 0%, #F2CA00 100%)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.57)"
  },
  ballEmpty: {
    border: "1.5px solid #E9E9E9",
    background: "#fff",
    color: "#E9E9E9"
  },
  mr0: {
    marginRight: "0"
  }
});

const TicketLive = (props) => {
  const classes = useStyles();
  const { ticket, className, noArrow, t } = props;

  let rootCls = classes.root;
  if (className) {
    rootCls += ` ${className}`;
  }

  const defNumbers = [];
  ticket.numbers.forEach(no => {
    defNumbers.push({ n: no, s: 0 });
  });

  const [numbers, setNumbers] = React.useState(defNumbers);

  React.useEffect(() => {
    let timeoutID = 0;
    const handleEvent = (data, event) => {
      if (event === "LOTTO_DRAW") {
        if (data && data.event_id === ticket.event_id) {
          const tmp = [...numbers];

          tmp.forEach(n => {
            n.s = 0;
            if (data.drawn && data.drawn.length && data.drawn.indexOf(n.n) > -1) {
              n.s = 1;
            }
            if (data.current) {
              if (n.n === data.current) n.s = 2;
            }
          });

          timeoutID = setTimeout(() => {
            setNumbers(tmp);
          }, 2000);
        }
      }
    };
    evBus.on("LOTTO_DRAW", handleEvent);
    return () => {
      evBus.remove("LOTTO_DRAW", handleEvent);
      clearTimeout(timeoutID)
    };
  }, []); // eslint-disable-line

  let description = '';
  if (ticket.systems && ticket.systems.length) {
    if (ticket.systems.length === 1 && ticket.numbers.length === ticket.systems[0]) {
      description = `| ${t("SIMPLE")} ${ticket.systems[0]}/${ticket.numbers.length}`;
    } else {
      description = `| ${t("SYSTEM")} ${ticket.systems.join(",")}/${ticket.numbers.length}`;
    }
  }

  let lucky_number = 0;
  let lucky_number_won = false;

  if (ticket && ticket.bonus_data) {
    let bonus_data = [];
    try {
      bonus_data = JSON.parse(ticket.bonus_data);
    } catch (err) { }

    bonus_data.forEach(b => {
      if (b && b.type === "lotto_lucky_number") {
        if (b.data) {
          let data = {};
          try {
            data = JSON.parse(b.data);
          } catch (err) { }

          if (data && typeof data.number !== "undefined" && ticket.numbers.indexOf(data.number) > -1) {
            lucky_number = data.number;
          }
        }
      }
    });
  }

  const status = ticket.status;
  const amount = ticket.max_winning;
  const event_date = moment(ticket.event_time / 1e+6).format("HH:mm");

  return (
    <div className={rootCls}>
      <div className={classes.eventName}>{ticket.event_name} #{ticket.event_code} | {event_date}</div>
      <div className={classes.header}>
        <div className={classes.titleWrapper}>
          <div className={classes.live}>LIVE</div>
          <div className={classes.title}>{t("LOTTO")}</div>
          <div className={classes.description}>&nbsp;{description}</div>
        </div>
        <div className={classes.ticketData}>
          {status === "WON" && (
            <div className={classes.won}>
              <CheckIcon />
            </div>
          )}
          {status === "LOST" && <div className={classes.lost}>X</div>}
          <div className={`${classes.possibleWin} ${noArrow ? classes.mr0 : ''}`}>{amount} Lei</div>
          {!noArrow && <RightIcon className={classes.rightSvg} />}
        </div>
      </div>
      <div className={classes.hr}></div>
      <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>
        {[...Array(ticket.event_R > 10 ? 10 : ticket.event_R).keys()].map(i => {
          if (typeof numbers[i] !== "undefined") {
            let no = numbers[i];
            const isLuckyNumber = ticket.status !== "WON" && no.n === lucky_number ? true : false;
            const isLuckyNumberWon = ticket.status !== "WON" && no.n === lucky_number && lucky_number_won ? true : false;
            const rootCls = `${classes.ball} ${no.s === 1 ? classes.ballWinner : ''} ${no.s === 2 ? `${classes.ballWinnerNow} bounce-animation` : ''} ${isLuckyNumber ? classes.ballLuckyNumber : ''} ${isLuckyNumberWon ? classes.ballLuckyNumberWon : ''}`;
            return <div key={i} className={rootCls}>{no.n}</div>;
          }
          return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
        })}
      </div>
      {ticket.event_R > 10 &&
        <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>
          {[...Array(ticket.event_R - 10).keys()].map(i => {
            const ix = i + 10;
            if (typeof numbers[ix] !== "undefined") {
              let no = numbers[ix];
              const isLuckyNumber = ticket.status !== "WON" && no.n === lucky_number ? true : false;
              const isLuckyNumberWon = ticket.status !== "WON" && no.n === lucky_number && lucky_number_won ? true : false;

              const rootCls = `${classes.ball} ${no.s === 1 ? classes.ballWinner : ''} ${no.s === 2 ? `${classes.ballWinnerNow} bounce-animation` : ''} ${isLuckyNumber ? classes.ballLuckyNumber : ''} ${isLuckyNumberWon ? classes.ballLuckyNumberWon : ''}`;
              return <div key={i} className={rootCls}>{no.n}</div>;
            }
            return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
          })}
        </div>
      }
    </div>
  );
}

TicketLive.propTypes = {
  ticket: PropTypes.object
};

TicketLive.defaultProps = {
  ticket: {
    status: "", // OPEN,WON,LOST
    type: "SYSTEM", // "SYSTEM"
    systemType: "1,2,3/5", // 1,2,3/5
    event_name: "Slovakia Eklub Keno",
    event_date: "2020-04-07 15:55:00",
    event_id: "123456",
    amount: "95.54",
    numbers: [
      { n: 1, s: 0 },
      { n: 5, s: 0 },
      { n: 51, s: 0 },
      { n: 32, s: 0 },
      { n: 37, s: 0 },
      { n: 22, s: 0 },
      { n: 13, s: 0 },
      { n: 17, s: 0 },
      { n: 66, s: 0 },
      { n: 5, s: 0 },
      { n: 18, s: 0 },
      { n: 12, s: 0 },
      { n: 7, s: 0 },
      { n: 9, s: 0 },
      { n: 14, s: 0 },
    ]
  }
};

export default withTranslation()(TicketLive);