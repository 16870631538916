import { walletConstants } from "./../actions/constants";
import * as actionTypes from "../../../store/actions/actionTypes";

const initialState = {
  main_data: {},
  main: 0,
  secondary_data: {},
  secondary: 0,
  total: 0,
  currency: "WINCOIN",
  loadingWallet: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case walletConstants.RESET_WALLET:
      return {
        main_data: {},
        main: 0,
        secondary_data: {},
        secondary: 0,
        total: 0,
        currency: "WINCOIN",
        loadingWallet: false,
      };
    case walletConstants.REQUEST_WALLET: {
      return {
        ...state,
        loadingWallet: true,
      }
    }
    case actionTypes.authentication.AUTHENTICATE: {
      return {
        ...state,
        loadingWallet: true,
      }
    }
    case walletConstants.RECEIVED_WALLET:
      let rawWallet = action.wallet;
      if (!rawWallet) return state;
      if (!rawWallet.hasOwnProperty(state.currency)) {
        //console.log(`[ERROR] Winner FUN Wallet is missing currency: ${state.currency}`)
        return state;
      }

      let wallet = rawWallet[state.currency];

      let main_data = {};
      let main = 0.00;
      let secondary_data = {};
      let secondary = 0.00;
      let total = main;

      if (wallet.hasOwnProperty('main_amount')) {
        main += parseFloat(wallet.main_amount.amount) / 100;
        total += main;
        main_data = wallet.main_amount;
      }

      if (wallet.hasOwnProperty('secondary_amount')) {
        secondary += parseFloat(wallet.secondary_amount.amount) / 100;
        total += secondary;
        secondary_data = wallet.secondary_amount;
      }

      return {
        ...state,
        main_data: main_data,
        main: main,
        secondary_data: secondary_data,
        secondary: secondary,
        total: total,
        loadingWallet: false,
      };
    default:
      return state;
  }
};

export default reducer;
