import * as actionTypes from './actionTypes';
import BackendClient from '../../BackendClient';
import * as actions from '../actions';

import { recordPlaySession } from "../../lobby/special-components/favorites/favorite_store";
import { freeBetsFetch, freeSpinsFetch, getBonusAvailable, getEligibleBonuses, resetOneRequest } from "../actions";

let gTimer = 0;
let lTimer = 0;
let hTimer = 0;

export const loadGames = (games) => {
    return dispatch => {
        dispatch({
            type: actionTypes.games.RECEIVED,
            games: games,
        });
    };
};

export const openGame = (gameItem, x, y) => {
    clearTimeout(gTimer);
    clearTimeout(lTimer);
    return dispatch => {
        if (!gameItem.id) {
            return;
        }

        if (!(gameItem.id > 0)) {
            return dispatch(gameError());
        }

        recordPlaySession(gameItem.id);

        dispatch({
            type: actionTypes.games.OPEN,
            game: gameItem,
            x: x,
            y: y,
        });

        dispatch(requestGameUrl(typeof gameItem.game_id !== "undefined" ? gameItem.game_id : gameItem.id));
    };
};

export const requestGameUrl = (gameId) => {
    // console.log(`[GAME] Get URL for game ${gameId}`);
    return dispatch => {
        if (!(gameId > 0)) {
            return dispatch(gameError());
        }

        // call backend game launch
        let axios = BackendClient.getInstance();
        axios({
            url: '/api/game/launch',
            method: 'post',
            data: {
                platformType: window.config.platformType,
                gameId: gameId,
            }
        }).catch(e => {
            // console.log(`[CATCHED ERROT] => ${e}`);
            dispatch(gameError());
        });
    };
};

export const closeGame = (openLogin = false) => {
    clearTimeout(gTimer);
    return dispatch => {
        dispatch({
            type: actionTypes.games.CLOSE,
        });

        dispatch(resetOneRequest('get-bonus-available'));
        dispatch(getBonusAvailable());
        dispatch(resetOneRequest('get-eligible-bonuses'));
        dispatch(getEligibleBonuses());

        dispatch(freeSpinsFetch());
        //dispatch(freeBetsFetch());

        gTimer = setTimeout(() => {
            dispatch({
                type: actionTypes.games.CLOSED,
            });
            if (true === openLogin) {
                dispatch(actions.toggleModalVisibility(
                    true,
                    'login',
                ));
            }
        }, 600);
    };
};

export const gameLoaded = (result) => {
    if (!result.hasOwnProperty('link')) {
        return {
            type: actionTypes.games.ERRORS,
        }
    }
    // console.log(`[GAME-LINK] Game Link is: ${result.link}`);
    return {
        type: actionTypes.games.LOADED,
        link: result.link,
    };
};

export const gameClosed = () => {
    clearTimeout(lTimer);
    return {
        type: actionTypes.games.CLOSED,
    }
};

export const hideGameLoader = () => {
    return dispatch => {
        lTimer = setTimeout(() => {
            dispatch({
                type: actionTypes.games.HIDE_LOADING,
            });
        }, 1000);
    };
};

export const gameError = () => {
    return {
        type: actionTypes.games.ERRORS,
    }
}

export const showMoreGames = () => {
    return {
        type: actionTypes.games.SHOW_MORE,
    }
};

export const resetShownGames = () => {
    return {
        type: actionTypes.games.RESET_SHOWN,
    }
};

export const addToFavourites = (game) => {
    return {
        type: actionTypes.games.ADD_FAVOURITE,
        item: game,
    };
};

export const removeFromFavourites = (game) => {
    return {
        type: actionTypes.games.REMOVE_FAVOURITE,
        item: game,
    };
};

export const gameHovered = (sectionAndId) => {
    return dispatch => {
        clearTimeout(hTimer);

        if (null === sectionAndId) {
            hTimer = setTimeout(() => {
                dispatch({
                    type: actionTypes.games.HOVER_GAME,
                    gameHover: sectionAndId,
                });
            }, 10);
            return
        }

        hTimer = setTimeout(() => {
            dispatch({
                type: actionTypes.games.HOVER_GAME,
                gameHover: sectionAndId,
            });
        }, 10);
    }
};

export const resetGameHoverd = () => {
    // console.log('[TOOLTIP] Reset hover game!');
    return dispatch => {
        clearTimeout(hTimer);
    }
};

export const requestGameCollections = () => {
    return dispatch => {

        let loading = BackendClient.getStore().getState().games.loadingGameCollections;
        let collections = BackendClient.getStore().getState().games.gameCollections;
        //console.log('loading123', loading)
        if (loading || collections) return;
        dispatch({
            type: actionTypes.games.REQUEST_GAME_COLLECTIONS,
        });

        let axios = BackendClient.getInstance();
        axios({
            url: '/api/casino/get-collections',
            method: 'post'
        }).catch(e => {
            console.log(`[CATCHED ERROR] => ${e}`);
        });
    };
};

export const receivedGameCollections = (data) => {
    return dispatch => {
        dispatch({
            type: actionTypes.games.RECEIVED_GAME_COLLECTIONS,
            data: data,
        });
    };
};

export const closeGameLauncher = (openLogin = false) => {
    clearTimeout(gTimer);
    return dispatch => {
        dispatch({
            type: actionTypes.games.CLOSE,
        });

        gTimer = setTimeout(() => {
            dispatch({
                type: actionTypes.games.CLOSED,
            });
            if (true === openLogin) {
                dispatch(actions.toggleModalVisibility(
                    true,
                    'login',
                ));
            }
        }, 600);
    };
};