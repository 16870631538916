import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";

import "./dialog-switch-tournament.scss";

import GreenCircle from "../assets/green-circle@3x.png";


import { getText, getData, emptyImage } from "../../lobby/utils/functions";
import { tournamentActivate, tournamentEnroll } from "../store/actions/tournament";
import { toggleHappyHoursSelected } from "../store/actions/tournaments_groups";
import HappyHourImage from "../assets/happy-hour-image.png";
import Timer from "../components/timer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSwitchTournament = (props) => {
  const { open, onClose, t, groups, i18n, tournamentActivate, tournamentEnroll, hhRunning, hhStatuses, hhSelected, toggleHappyHoursSelected } = props;

  if (!open) return null;

  const now = moment().valueOf();
  const tournaments = [];
  const names = {};
  const hhIsRunning = hhRunning && hhRunning["online-slots"] ? hhRunning["online-slots"] : false;
  const hhStatus = hhIsRunning && hhStatuses && hhStatuses["online-slots"] ? hhStatuses["online-slots"] : null;

  let parts = window.location.pathname.split("/");
  let gameId = parseInt(parts[parts.length - 1], 10);

  groups.forEach(g => {
    if (g.type !== 2) return;

    g.tournaments.forEach(t => {
      if (t.start_date <= now && now < t.end_date) {
        if (t.meta && t.meta.games && t.meta.games.indexOf(gameId) > -1) {
          if (typeof g.block_enroll === "undefined" || !g.block_enroll) {
            tournaments.push(t);
            names[t.id] = g.name;
          }
        }
      }
    });
  });
  if (tournaments.length === 0 && !hhStatus) return null;

  const handleJoinActivate = (e) => {
    e.stopPropagation();

    toggleHappyHoursSelected(false);

    const target = e.currentTarget;

    if (target) {
      const id = target.dataset.id;
      const type = target.dataset.type;
      if (type === "enroll") {
        tournamentEnroll({
          tournamentId: parseInt(id, 10),
          activate: true,
          cb: () => {
            onClose();
          }
        });
      } else {
        tournamentActivate({
          tournamentId: parseInt(id, 10),
          cb: () => {
            onClose();
          }
        });
      }
    }
  };

  const handleHappyHoursSelect = (e) => {
    e.stopPropagation();
    toggleHappyHoursSelected();
  }

  const preventClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      scroll="body"
      maxWidth="xs"
      className="tournaments-missions-dialog-switch-tournament ">
      <div className="dg-body" onClick={preventClick}>
        <div className="dg-header">
          <div className="dg-close" onClick={onClose}>
            <div className="dg-close-wrapper">
              <CloseIcon />
            </div>
          </div>
        </div>

        <div className="tm-tournaments">
          {tournaments.map((tr, i) => {

            const name = names[tr.id];
            let prize1 = getText(tr, `meta.ui.prize_1.text.${i18n.language}`, "");
            let prize2 = getText(tr, `meta.ui.prize_2.text.${i18n.language}`, "");
            let top_image = getData(tr, "meta.ui.top_image.url", emptyImage);
            let scores_image = getData(tr, "meta.ui.scores_image.url", emptyImage);
            let levels = getData(tr, "meta.levels", 0);

            let clickHandler = handleJoinActivate;
            if (hhSelected && tr.activated) {
              clickHandler = handleHappyHoursSelect;
            }

            return <div className="tm-tournament" key={i}>
              <div className="tm-wrapper">
                <div className="tm-wrapper-inner">
                  <div className="tm-top">
                    <img src={top_image} alt="" />
                    <div className="tm-txt-award">
                      <div className="tm-subtitle">TOP</div>
                      <div className="tm-title">{levels}</div>
                    </div>
                  </div>
                  <div className="tm-wrapper-right">
                    <div className="tm-hd">
                      <div className="tm-left">
                        <img src={scores_image} alt="" />
                        <div className="tm-name">{name}</div>
                      </div>
                      <div className="tm-timer">
                        <Timer date={tr.end_date}>
                          {({ days, hours, mins, secs }) => {
                            let muted = 0;
                            if (!days || days.toString() === "0") muted += 1;
                            if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                            if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                            if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                            return <React.Fragment>
                              {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                            </React.Fragment>;
                          }}</Timer>
                      </div>
                    </div>
                    <div className="tm-foot">
                      <div className="tm-prizes">
                        <div className="tm-prize">{prize1}</div>
                        <div className="tm-prize">{prize2}</div>
                      </div>

                      <div className="tm-buttons">
                        {(!tr.activated || hhSelected) && <div className="tm-btn" data-id={!tr.enrolled ? tr.id : tr.player_mission_id} data-type={!tr.enrolled ? 'enroll' : 'activate'} onClick={clickHandler}>
                          <div className="inner">
                            {tr.enroll ? t("Play") : t("Join")}
                          </div>
                        </div>}
                        {(tr.activated && !hhSelected) && <img className="activated" src={GreenCircle} alt="" />}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          })}

          {hhIsRunning && <div className="tm-tournament hh">
            <div className="tm-wrapper">
              <div className="tm-wrapper-inner hh">
                <div className="tm-top">
                  <img src={HappyHourImage} alt="" />
                </div>
                <div className="tm-wrapper-right">
                  <div className="tm-hd">
                    <div className="tm-left">
                      <div className="tm-name">{hhStatus.name}</div>
                    </div>
                    <div className="tm-timer">
                      <Timer date={(hhStatus.start_time + hhStatus.duration) * 1000}>
                        {({ days, hours, mins, secs }) => {
                          let muted = 0;
                          if (!days || days.toString() === "0") muted += 1;
                          if ((!hours || hours.toString() === "00") && muted === 1) muted += 1;
                          if ((!mins || mins.toString() === "00") && muted === 2) muted += 1;
                          if ((!secs || secs.toString() === "00") && muted === 3) muted += 1;

                          return <React.Fragment>
                            {!!days && <span><span className={muted >= 1 ? 'muted days' : ' days'}>{days}{i18n.language === "ro" ? "z" : "d"}&nbsp;&nbsp;</span></span>}<span className={muted >= 2 ? 'muted' : ''}>{hours}&nbsp;:&nbsp;</span><span className={muted >= 3 ? 'muted' : ''}>{mins}&nbsp;:&nbsp;</span><span className={muted >= 4 ? 'muted' : ''}>{secs}</span>
                          </React.Fragment>;
                        }}</Timer>
                    </div>
                  </div>
                  <div className="tm-foot">
                    <div className="tm-prizes">
                      <div className="tm-prize">{moment(hhStatus.start_time * 1000).format("ddd, DD MMM")}</div>
                      <div className="tm-prize">{hhStatus.total_prizes} {t("awards")}</div>
                    </div>
                    <div className="tm-buttons">
                      {(!hhSelected && tournaments.length !== 0) && <div className="tm-btn" onClick={handleHappyHoursSelect}>
                        <div className="inner">
                          {t("Join")}
                        </div>
                      </div>}
                      {(hhSelected || tournaments.length === 0) && <img className="activated" src={GreenCircle} alt="" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>

      </div>
    </Dialog>
  );
}

const mapStateToProps = (state, props) => {
  return {
    hhRunning: state.happyHour.running,
    hhStatuses: state.happyHour.status,
    groups: state.tournamentsMissions ? state.tournamentsMissions.groups.list : [],
    hhSelected: state.tournamentsMissions ? state.tournamentsMissions.groups.hhSelected : false,
  };
};

const actions = {
  tournamentActivate, tournamentEnroll, toggleHappyHoursSelected
};

export default withTranslation()(connect(mapStateToProps, actions)(DialogSwitchTournament));



