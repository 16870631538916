import { getBetsState } from './betData';
import { createSelector } from 'reselect';

const getSelectedBets = (state, props) => {
	const bst = getBetsState(state);
	return bst.betsSlip.tickets[bst.betsSlip.currentTicket][props.mType].selected;
};
const getSelectedOdd = (state, props) => props.oddSelected;

export const makeGetSelectedBet = () =>
	createSelector([getSelectedBets, getSelectedOdd], (bets, idMbo) =>
		bets.find(b => b.idMbo === idMbo)
	);
