import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";

import "./index.scss";
import NewtonLogo from "./assets/newton.png";
import { ReactComponent as NewtonVector } from "./assets/newton-vector.svg";
import { ReactComponent as GradientCircular } from "./assets/circular-progress.svg";
import { ReactComponent as Loader } from "./assets/loader.svg";

const NewtonLoader = ({ t, theme }) => {

  if (window.config.brand_name_short === 'Winner') {
    return (<div className={`newton-loader-winner ${theme ? theme : ''}`}>
      <div style={{ width: "150px" }}>
        <div>
          <div style={{ background: "transparent", margin: "0px auto", outline: "none", overflow: "hidden", width: "150px", textAlign: "center" }}>
            <Loader />
          </div>
        </div>
      </div>
    </div>);
  }

  return (<div className={`newton-loader ${theme ? theme : ''}`}>
    <GradientCircular className="nl-circular-progress" />
    <CircularProgress className="nl-circular-progress" size={120} thickness={3} />
    <div className="nl-arrange-center">
      <div className="nl-top-title">{t("Powered By")}</div>
      <div className="nl-logo"><img src={NewtonLogo} alt="Newton" /></div>
      <div className="nl-bottom-title"><NewtonVector /></div>
    </div>
  </div>);
}

export default withTranslation()(NewtonLoader);