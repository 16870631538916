import { takeEvery, takeLatest, put } from "redux-saga/effects";

import { alerts } from "../actions/actionTypes";
import BackendClient from "../../BackendClient";

export function* updateMarketingAgreementsSaga(action) {
    if (typeof action.params.sendToServer !== 'undefined') {
        if (action.params.sendToServer === false) {
            return ;
        }
    }

    const axios = BackendClient.getInstance();
    const store = BackendClient.getStore();
    const state = store.getState();

    if (
        ["user", "token"].indexOf(state.authentication.auth_type) === -1 ||
        state.authentication.access_token === null
    ) {
        return new Promise((resolve, reject) => {
            reject(new Error("Not Authenticated"));
        });
    }
    let channel;
    let value;
    if (typeof action.params.marketing_receive_sms !== "undefined") {
        channel = 0;
        value = action.params.marketing_receive_sms;
    } else {
        value = action.params.value;
        switch (action.params.channel) {
            case 'marketing_receive_sms':
                channel = 1;
                break;
            case 'marketing_receive_email':
                channel = 2;
                break;
            case 'marketing_receive_phone':
                channel = 3;
                break;
            default:
                break;
        }
    }

    return new Promise((resolve) => {
        axios({
            url: `/api/profile/updateMarketingAgreement`,
            method: 'post',
            data: {
                channel: channel,
                value: value
            }
        })
    });
};

export default function* watchAlertsSaga() {
    yield takeEvery(alerts.SET_MARKETING_AGREEMENTS, updateMarketingAgreementsSaga);
}
