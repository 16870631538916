import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import "./MapContainer.scss";
import Icons from "../../../../../../assets/Icons/Icons";
import { Button } from "reactstrap";
import Utils from "../../../../../../utils/Utils";
import * as stateActions from "./../../../../../../store/actions";

class MapContainer extends Component {
    constructor(props) {
        super(props);

        this.markers = [];
        this.markerClickHandler.bind(this);
        this.once = false;

        this.refMarkers = {};

        this.points = [];

        this.sitePath = window.config.front_url;
        this.currentPosition = this.sitePath + '/img/current_location.png';
        // this.casinoPosition = this.sitePath + '/img/casino_location.png';
        this.selectedPosition = this.sitePath + '/img/selected_location.png';

        this.timer = 0;
        this.buttonTimer = 0;
        this.zoom = 11;
    }

    state = {
        infoState: false,
        title: "",
        desc: "",
        marker: null,
        coords: null,
        locationId: null,
    }

    componentDidUpdate() {
        clearTimeout(this.timer);

        // chack if you need to reset actual location
        if (this.props.geolocation.resetActualPosition && this.markers.length > 0) {
            this.markers[0] = this.getActualMarker();
            this.props.actualPositionResetted();
        }

        if (!this.props.selectedLocation) {
            return;
        }

        this.activateWithdrawButton();

        if (this.props.selectedLocation.id === this.state.locationId) {
            return;
        }

        let location = this.props.selectedLocation;
        let marker = this.refMarkers[location.id];

        this.timer = setTimeout(() => {
            this.markerClickHandler(location, marker.props, marker.marker);
        }, 500);
    }

    activateWithdrawButton() {
        if (this.props.notWithdraw) {
            return;
        }

        clearTimeout(this.buttonTimer);

        let withdrawButton = document.getElementById("gmIwButton");
        if (withdrawButton) {
            this.removeListers(withdrawButton);
            withdrawButton.addEventListener('click', this.withdrawHandler.bind(this));
            withdrawButton.addEventListener('touchend', this.withdrawHandler.bind(this));

            return;
        }

        this.buttonTimer = setTimeout(this.activateWithdrawButton.bind(this), 200);
    }

    componentWillUnmount() {
        let withdrawButton = document.getElementById("gmIwButton");
        if (withdrawButton) {
            this.removeListers(withdrawButton);
        }
    }

    removeListers(element) {
        element.removeEventListener('click', this.withdrawHandler.bind(this));
        element.removeEventListener('touchend', this.withdrawHandler.bind(this));
    }

    createMarkers() {
        if (this.markers.length > 0) {
            return;
        }

        if (!(this.props.locations.length > 0)) {
            return;
        }

        const { google } = this.props;

        let actualMarker = this.getActualMarker();
        this.markers.push(actualMarker);

        // let casinoIcon = this.casinoPosition;
        this.props.locations.forEach((location, index) => {
            let marker = <Marker key={`casino_marker_${index}`}
                title={location.locationName}
                name={location.formattedAddress}
                position={{
                    lat: location.latitude,
                    lng: location.longitude,
                }}
                animation={google.maps.Animation.DROP}
                locationId={location.id}
                ref={(elem) => { this.refMarkers[location.id] = elem; }}
                onClick={this.markerClickHandler.bind(this, location)}
                />;

            this.markers.push(marker);

            if (index >= this.props.locations.length - 1) {
                this.props.setMarkers(this.refMarkers);
                this.props.clickMarker(this.markerClickHandler.bind(this));
            }
        });
    }

    getActualMarker() {
        let actualMarker = <Marker
            key={`current_position`}
            name={"Actual position"}
            title={"Actual position"}
            position={{
                lat: this.props.lat,
                lng: this.props.long,
            }}
            icon={{
                url: `${this.currentPosition}`,
                // anchor: new google.maps.Point(43, 43),
                scaledSize: new this.props.google.maps.Size(43, 43)
            }} />

        return actualMarker;
    }

    changeIcons(location) {
        Object.keys(this.refMarkers).forEach((locationId) => {
            let nrLocationId = Number.parseInt(locationId, 10);
            let marker = this.refMarkers[locationId];

            if (nrLocationId === location.id) {
                marker.marker.setIcon({
                    url: `${this.selectedPosition}`,
                    scaledSize: new this.props.google.maps.Size(43, 43)
                });
                return;
            }

            marker.marker.setIcon({
                url: `${this.casinoPosition}`,
                scaledSize: new this.props.google.maps.Size(43, 43)
            });
        });
    }

    markerClickHandler(location, props, marker, e) {
        clearTimeout(this.timer);
        this.changeIcons(location);

        this.zoom = (11 === this.zoom) ? 13 : 11;

        this.setState({
            infoState: true,
            title: marker.title,
            desc: marker.name,
            marker: marker,
            coords: [`${location.latitude}, ${location.longitude}`],
            locationId: location.id,
        });

        this.props.setLocation(location);

        this.activateWithdrawButton();
    }

    resetHandler() {
        this.setState({
            infoState: false,
            title: "",
            desc: "",
            marker: null,
            coords: null,
            locationId: null,
        });
    }

    setGoogle() {
        const { google } = this.props;
        this.props.googleHandler(google);
    }

    withdrawHandler(event) {
        this.props.withdrawHandler();
    }

    renderWithdrawButton() {
        if (this.props.notWithdraw) {
            return null;
        }

        return (
            <div className={"withdrawButton"}>
                <Button color={"success"} id={"gmIwButton"}>
                    {this.props.t("Retrage aici")} {Utils.formatAmount(this.props.withdrawals.amount, null, this.props.wallet.currency)}
                </Button>
            </div>
        );
    }

    setPoints() {
        if (!(this.props.locations.length > 0)) {
            return;
        }

        if (this.points.length > 0) {
            return;
        }

        this.props.locations.forEach((location, index) => {
            if (location.hasOwnProperty("etaTime") && location.etaTime < 3600) {
                this.points.push({ lat: Number.parseFloat(location.latitude), lng: Number.parseFloat(location.longitude) });
            }
        });
    }

    render() {
        this.createMarkers();

        const mapStyle = {
            width: '74%',
            height: '100%',
        };

        this.setGoogle();

        this.setPoints();

        let latitude = this.props.lat;
        let longitude = this.props.long;
        if (this.state.coords) {
            let coords = this.state.coords[0];
            coords = coords.split(",");
            latitude = Number.parseFloat(coords[0]);
            longitude = Number.parseFloat(coords[1]);
        }

        return (
            <div className="MapContainer">
                <Map
                    google={this.props.google}
                    zoom={this.zoom}
                    style={mapStyle}
                    center={{
                        lat: latitude,
                        lng: longitude,
                    }}
                    // bounds={bounds}
                    scrollwheel={true}>
                    {this.markers}
                    <InfoWindow
                        visible={this.state.infoState}
                        marker={this.state.marker}
                        onClose={this.resetHandler.bind(this)} >
                        <div className={"gmInfoWindow"}>
                            <h4>{this.state.title}</h4>
                            <p className={"gmIwAddress"}>{this.state.desc}</p>
                            {this.renderWithdrawButton()}
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
        geolocation: state.geolocation,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        actualPositionResetted: () => dispatch(stateActions.actualPositionResetted()),
    };
};

export default GoogleApiWrapper({
    apiKey: window.config.google_maps_api_key,
})(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MapContainer)));
