import React from 'react';

import "./index-front-special-prize.scss";
import TermsDialog from "../../components/terms-dialog";

const HappyHoursFrontSpecial = (props) => {
  const { data, className } = props;

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  }

  const prize = data.prize_current.custom;

  return (
    <React.Fragment>
      <div className={`bets-special-prize ${className ? className : ''}`} onClick={openDialog}>
        <div className="hh-image">
          <img src={prize.image} alt="" />
        </div>
        <div className="hh-title">{data.name}</div>
        <div className="hh-footer">
          {prize.description_front ? prize.description_front : prize.description}
        </div>
        <div className="hh-help">?</div>
      </div>
      <TermsDialog open={open} prize={prize} name={data.name} onClose={closeDialog} />
    </React.Fragment>
  );
}

export default HappyHoursFrontSpecial;