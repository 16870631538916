import { leaderBoardConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  top: {},
  loading: false,
  loadingPlayer: false,
  player: {}
};

const leaderBoardReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case leaderBoardConstants.LOADED: {
        draft.top[action.tournamentId] = action.data ? action.data : [];
        draft.loading = false;
        break;
      }
      case leaderBoardConstants.LOADING: {
        draft.loading = true;
        break;
      }
      case leaderBoardConstants.LOADED_PLAYER: {
        draft.player[action.tournamentId] = action.data ? action.data : { player: [] };
        draft.loadingPlayer = false;
        break;
      }
      case leaderBoardConstants.LOADING_PLAYER: {
        draft.loadingPlayer = true;
        break;
      }
      default:
      /* noop */
    }
  });

export default leaderBoardReducer;
