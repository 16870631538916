import * as actionTypes from './actionTypes';

export const openLocationModal = () => {
    return {
        type: actionTypes.geolocation.OPEN_MODAL,
    };
};

export const closeLocationModal = () => {
    return {
        type: actionTypes.geolocation.CLOSE_MODAL,
    };
};

export const openLocationWModal = () => {
    return {
        type: actionTypes.geolocation.OPEN_MODAL,
    };
};

export const closeLocationWModal = () => {
    return {
        type: actionTypes.geolocation.CLOSE_MODAL,
    };
};

export const resetActualPosition = () => {
    return {
        type: actionTypes.geolocation.RESET_POSITION,
    }
};

export const actualPositionResetted = () => {
    return {
        type: actionTypes.geolocation.POSITION_RESETTED,
    }
};

export const permissionRequested = () => {
    return {
        type: actionTypes.geolocation.REQUESTED,
    };
};

export const setCoords = (coords) => {
    return {
        type: actionTypes.geolocation.SET_COORDS,
        coords: coords,
    }
};