import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import StepWizard from 'react-step-wizard';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import * as stateActions from "../../../../store/actions";
import "./WithdrawModal.scss";
import Amount from "./Screens/Amount";
import Location from "./Screens/Location";
import Success from "./Screens/Success";
import Error from "./Screens/Error";
import ActiveBonuses from "./Screens/ActiveBonuses";
import WPermissionModal from "../PermissionModal/WPermissionModal";

class WithdrawModal extends Component {
    constructor(props) {
        super(props);

        this.sitePath = window.config.front_url;
        this.loaderPath = this.sitePath + '/img/withdraw.gif';
        this.loaderStyle = {
            backgroundImage: `url('${this.loaderPath}')`,
        };
    }

    componentDidUpdate() {
        if (!this.props.withdrawals.modal) {
            return;
        }

    }

    close() {
        this.props.closeModal();
    }

    checkDocumentsNotVerified() {
        let docsUnderReview = false;
        if (this.props.rightMenu.alerts.documents.length > 0) {
            this.props.rightMenu.alerts.documents.forEach((document) => {
                if (1 === Number.parseInt(document.type, 10) && 8 !== Number.parseInt(document.status, 10)) {
                    docsUnderReview = true;
                }
            });
        }
        return docsUnderReview;
    }

    notVerifiedModal() {
        let message = this.props.t("Accordingly to legal requirements, please verify your identity prior to withdrawing any money.");
        let successButtonMessage = this.props.t("Verify");
        let docsUnderReview = false;
        if (this.props.rightMenu.alerts.documents.length > 0) {
            this.props.rightMenu.alerts.documents.forEach((document) => {
                if (1 === Number.parseInt(document.type, 10) && 2 === Number.parseInt(document.status, 10)) {
                    docsUnderReview = true;
                }
            });
        }

        if (docsUnderReview) {
            message = this.props.t("Your identity documents you provided are under review.");
            successButtonMessage = this.props.t("View status");
        }

        return (
            <Modal isOpen={this.props.withdrawals.modal} className="SpentTimeModal h-100 d-flex flex-column justify-content-center my-0" backdrop="static" modalTransition={{ timeout: 300 }} zIndex={9999}>
                <ModalBody className={"SpentTimeModalBody"}>
                    <div className={"messageSupport"}>
                        <p>{this.props.t(message)}</p>
                    </div>
                    <div className={"buttonSupport text-center"}>
                        <Button color="success" className={"verifyButton"} onClick={this.verifyHandler.bind(this)}>{this.props.t(successButtonMessage)}</Button>
                        <Button color="danger" onClick={this.close.bind(this)}>{this.props.t("Cancel")}</Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    verifyHandler() {
        let profilePage = this.props.application.menu["profile"];

        if (!profilePage) {
            return false;
        }

        let subpage = profilePage.submenus.find((spage) => {
            return "documents" === spage.code;
        });

        if (!subpage) {
            return false;
        }

        let url = `/${profilePage.code}/${subpage.code}`;

        this.props.history.push(url);

        this.props.onSubpageChanged(subpage);

        this.close();
    }

    getInnerRef(ref) {
        this.innerRef = ref;
    }

    getLocation() {
        this.innerRef && this.innerRef.getLocation();
    }

    hasPermission() {
        if (!localStorage.getItem('location_permission')) {
            return false;
        }

        return true;
    }

    hasActiveBonuses() {
        let has = false;
        if (this.props.wallet.bonuses.length > 0) {
            has = true;
        }
        if (this.props.freeBets.length > 0) {
            has = true;
        }

        return has;
    }

    getModalContent(custom) {
        if (this.hasActiveBonuses()) {
            return (
                <ActiveBonuses />
            );
        }

        return (
            <StepWizard transitions={custom}>
                <Amount stage={1} />
                <Location ref={this.getInnerRef.bind(this)} />
                <Amount stage={3} />
                <Success />
                <Error />
            </StepWizard>
        );
    }

    render() {
        if (!this.props.profile.accountVerified || this.checkDocumentsNotVerified()) {
            return this.notVerifiedModal();
        }

        let modalClassName = [
            "WithdrawModal", "h-100", "d-flex", "flex-column", "justify-content-center", "my-0"
        ];
        if (this.props.withdrawals.bigSizeModal) {
            modalClassName.push("bigSizeModal");
        }

        let custom = {
            enterRight: 'fadeIn',
            enterLeft: 'fadeIn',
        };

        return (
            <Fragment>
                <Modal isOpen={this.props.withdrawals.modal} className={modalClassName.join(" ")} backdrop={'static'} modalTransition={{ timeout: 300 }} zIndex={9999}>
                    <ModalHeader toggle={this.close.bind(this)} className="WithdrawModalHeader">{this.props.t("withdrawal")}</ModalHeader>
                    <ModalBody className="WithdrawModalBody" style={this.loaderStyle}>

                        {this.getModalContent(custom)}

                    </ModalBody>
                </Modal>
                <WPermissionModal request={this.getLocation.bind(this)} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        application: state.application,
        profile: state.profile,
        rightMenu: state.rightMenu,
        geolocation: state.geolocation,
        freeBets: state.freeBets.freeBets,
        wallet: state.wallet,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => dispatch(stateActions.openWithdrawalModal()),
        closeModal: () => dispatch(stateActions.closeWithdrawalModal()),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
        permissionRequested: () => dispatch(stateActions.permissionRequested()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(WithdrawModal)));
