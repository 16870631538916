import Phaser from 'phaser';
import SpineWebGLPlugin from "phaser/plugins/spine/dist/SpineWebGLPlugin";
import MainScene from "./scenes/MainScene";
import PreloadScene from "./scenes/PreloadScene";

class BannerApp extends Phaser.Game {
    constructor(container, emitter, config = null) {
        if (!config) {
            // let bannerElem = document.getElementById("SpineBannerSupport");
            config = {
                type: Phaser.WEBGL,
                width: container.clientWidth,
                height: container.clientHeight,
                backgroundColor: 0x000000,
                // transparent: true,
                parent: container,
                plugins: {
                    scene: [{
                        key: 'SpineWebGLPlugin',
                        plugin: SpineWebGLPlugin,
                        start: true,
                        sceneKey: 'spine',
                    }]
                },
                scene: [PreloadScene, MainScene],
                banner: true,
                scale: {
                    mode: Phaser.DOM.RESIZE,
                    parent: container,
                    width: '100%',
                    height: '100%',
                }
            };
        }

        super(config);

        this.emitter = emitter;

        this.parentContainer = container;

        this.activateListeners();
    }

    activateListeners() {
        window.addEventListener("resize", this.resizeHandler.bind(this), false);

        this.emitter.on('screen', (action, params = null) => {
            if ('reload' !== action) {
                return;
            }

            this.resizeHandler();
        });
    }

    resizeHandler() {
        let canvasHeight = Number.parseInt(this.parentContainer.clientHeight, 10);
        let canvasWidth = canvasHeight * Number.parseFloat(1943/496).toFixed(1);
        if (canvasWidth < 1304) {
            canvasWidth = 1304;
        }

        this.canvas.width = canvasWidth;
        this.canvas.height = canvasHeight;
        // this.renderer.resize(canvasWidth, canvasHeight);


        this.emitter.emit('screen', 'resized', canvasWidth, canvasHeight);
    }
}

export default BannerApp;