import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AddCircleIcon from '@material-ui/icons/AddCircle';

import WinnerFunDrawer from "../../../winner-fun/views/WinnerFunDrawer";

const useStyles = makeStyles({
  base: {
    height: "0",
    overflow: "hidden",
    willChange: "height",
    transform: "translate(0,0,0)",
    position: "relative",
    margin: "-19px -12px 12px",
    borderRadius: "10px 10px 0 0",
  },
  root: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    minHeight: "75px",
    overflow: "hidden",
    borderBottom: "4px solid #FF196E",
    background: "#586682",
    color: "#fff",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px 10px 0 0",
    padding: "0 10px 0 18px"
  },
  text: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold"
  },
  button: {
    boxShadow: "0px 3px 6px rgba(0,0,0,.3)",
    minHeight: "40px",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    borderRadius: "9px",
    padding: "8px 30px 0",
    background:
      "linear-gradient(90deg, #F8991C 0%, #FF196E 100%)",
    "& svg": {
      verticalAlign: "middle!important"
    },
  },
  animate: {
    height: "75px",
    transition: "height .2s ease"
  }
})

const InsufficientBalance = (props) => {
  const { t, isWinnerFun, exitFull } = props;
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  }

  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    let m = true;
    if (m) {
      setTimeout(() => {
        if (m) setMounted(true);
      }, 100);
    }
    return () => {
      m = false;
    }
  }, []); // eslint-disable-line

  const handleClick = () => {
    if (isWinnerFun) {
      setOpen(true);
      return;
    }
    history.push("/deposit");
  };

  return (
    <div className={`${classes.base} ${mounted ? classes.animate : ''}`}>
      <div className={`${classes.root}`}>
        <div className={classes.text}>{t("Insufficient Funds")}</div>
        <div className={classes.button} onClick={handleClick}>
          {!isWinnerFun ? t("Deposit") : t("Collect")} <AddCircleIcon />
        </div>
      </div>
      {open && <WinnerFunDrawer open={open} onModalClose={handleClose} />}
    </div >
  );
}

export default withTranslation()(InsufficientBalance);