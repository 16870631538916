import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./Score.scss";

import { getWinnerFunState } from "../store/selectors/winnerFunData";
import { scoreLoad, scoreClear } from "../store/actions/score";
import { chooseTextLanguage, styleToObj, choseImage, backendImage } from "../utils/functions";
import ChooseNickname from "./modals/ChooseNickname";
import WinnerLoader from "../../winner-loader";
import TicketsHistory from "../components/TicketsHistory/TicketsHistory";
import Players from "../assets/players.png";

const interceptClickEvent = (e) => {
  const target = e.target || e.srcElement;
  if (target.tagName === 'A') {
    const href = target.getAttribute('href');

    if (href.indexOf("pdf") > -1) {
      if (window.config.cordova) {
        e.preventDefault();
        window.cordova.InAppBrowser.open(href, '_system');
        return;
      }
    }
  }
};

const Score = (props) => {
  const { t, i18n, subsection, subsectionType, score, player, teams, isAuthenticated, seasons, scoreLoad, scoreClear, opened, className, goToHistoric } = props;

  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const imageType = isDesktop1024 ? "desktop" : "mobile";

  const defState = {
    open: false,
    type: "",
    player: "",
    seasonId: "",
    eventId: "",
  };

  const [stateTickets, setStateTickets] = React.useState(defState);
  const handleTicketsHistoryClose = () => {
    setStateTickets(defState);
  };
  const showTicketsHistory = (type, player, seasonId, eventId) => () => {
    setStateTickets({
      open: true,
      type, player, seasonId, eventId
    });
  };

  const [nicknameModal, setNicknameModal] = React.useState({
    open: false,
    first: true,
  });
  const handleOpen = (e) => {
    if (e && typeof e.stopPropagation === "function") e.stopPropagation();

    setNicknameModal(v => ({
      ...v,
      open: true
    }));
  };
  const handleClose = () => {
    setNicknameModal(v => ({
      ...v,
      open: false
    }));
  };

  React.useEffect(() => {
    if (opened && isAuthenticated) {
      if (!player.nickname) {
        setNicknameModal(v => {
          if (v.first) {
            return { open: true, first: false };
          }
          return v;
        });
      }
    }
  }, [opened, isAuthenticated]); // eslint-disable-line

  const rootRef = React.useRef(null);
  React.useEffect(() => {
    if (window.config.cordova) {
      if (rootRef.current) {
        rootRef.current.addEventListener('click', interceptClickEvent, false);
      }
      return () => {
        if (rootRef.current) {
          rootRef.current.removeEventListener('click', interceptClickEvent, false);
        }
      }
    }
  }, [rootRef.current]);

  React.useEffect(() => {
    const root = document.documentElement;
    let innerHeight = window.innerHeight;
    root.style.setProperty('--inner-height', `${innerHeight}px`);

    let tid = setInterval(() => {
      if (innerHeight !== window.innerHeight) {
        root.style.setProperty('--inner-height', `${innerHeight}px`);
        innerHeight = window.innerHeight;
      }
    }, 300);

    scoreLoad();

    return () => {
      clearInterval(tid);
      scoreClear();
    }
  }, []); // eslint-disable-line

  const findById = (id) => {
    let found = null;
    seasons.forEach(season => {
      if (found) return;

      if (season.id === id) {
        found = season;
        return;
      }

      season.events.forEach(event => {
        if (found) return;

        if (event.id === id) {
          found = event;
          return;
        }
      });
    });
    return found;
  };

  const data = [];

  if (score && score.data && seasons) {
    score.data.forEach(season => {
      const event = findById(season.id);
      data.push({ ...season, ui: event ? event : {} });
    });
  }

  let defRankingIndex = 0;
  if (subsectionType === "event" && data.length > 1) {
    defRankingIndex = 1;
  }

  let defDetailsIndex = 0;
  if (subsection === "regulations") {
    defDetailsIndex = 1;
  }

  const [state, setState] = React.useState({
    rankingIndex: defRankingIndex,
    detailsIndex: defDetailsIndex,
  });
  const handleRankingIndex = (index) => () => {
    setState(v => ({
      ...v,
      rankingIndex: index,
      detailsIndex: 0,
    }));
  };
  const handleDetailsIndex = (index) => () => {
    setState(v => ({
      ...v,
      detailsIndex: index
    }));
  };

  const itemsPerPage = 20;

  const [viewPages, setViewPages] = React.useState([{}, {}]);
  const setViewPagesData = (event) => {
    const target = event.currentTarget;

    if (target) {
      const page = target.dataset.page;

      const newViewPages = [...viewPages];
      newViewPages[state.rankingIndex][page] = true;
      setViewPages(newViewPages);
    }
  };

  const [show, setShow] = React.useState([]);
  const showMoreHandler = () => {
    let ns = show[state.rankingIndex] + itemsPerPage;
    if (ns > data[state.rankingIndex].top.length) {
      ns = data[state.rankingIndex].top.length;
    }
    setShow(v => {
      const nv = [...v];
      nv[state.rankingIndex] = ns;
      return nv;
    });
  }

  React.useEffect(() => {
    const showMore = [];

    score.data.forEach(season => {
      showMore.push(season.top.length > itemsPerPage ? itemsPerPage : season.top.length);
    });

    setShow(showMore);
  }, [score]);

  const teamLogos = {};
  teams.forEach(t => {
    teamLogos[t.id] = t.logo;
  });

  const genPlayer = (pl, index, me, noPos) => {
    let level = "wf-regular";
    if (pl.pos === 1) level = "wf-gold";
    if (pl.pos === 2) level = "wf-silver";
    if (pl.pos === 3) level = "wf-bronze";

    let isCurrent = false;
    let name = pl.nickname;
    if (me && me.pos === pl.pos) {
      isCurrent = true;

      if (!(player.nickname && player.approved && player.nickname === name)) {
        name = <div onClick={handleOpen} className="wf-edit-nickname">{pl.nickname} <div className="wf-edit-icon"><EditIcon /></div></div>;
      }
    }

    return <div
      className={`wf-row ${isCurrent ? "wf-active" : ''} ${pl.pos < 4 ? `wf-row-${pl.pos - 1}` : ''}`}
      key={`player-${index}`}
      onClick={showTicketsHistory(data[state.rankingIndex].type, pl, seasonId, state.rankingIndex === 0 ? null : eventId)}
    >
      <div className="wf-col wf-col1">
        <div className={level}>{noPos ? "-" : pl.pos}</div>
      </div>
      <div className="wf-col wf-col2"><img src={backendImage(pl.team_id ? teamLogos[pl.team_id] : "")} alt="" /></div>
      <div className="wf-col wf-col3">{name}</div>
      <div className="wf-col wf-col4 wf-right">{pl.award ? pl.award : "-"}</div>
      <div className="wf-col wf-col5 wf-right">{pl.balance}</div>
    </div>
  };

  const genPlayersList = (list, me) => {
    const displayList = [];
    let index = 0;

    const first20 = list.slice(0, 20);

    first20.forEach(pl => {
      const ret = genPlayer(pl, index++, me);
      displayList.push(ret);
    });

    let hasAbbrv = false;

    for (let i = 20; i < list.length; i++) {
      const pl = list[i];

      if (me && pl.pos - 1 <= me.pos && me.pos <= pl.pos + 1 && !(viewPages && state && viewPages[state.rankingIndex][parseInt(i / 20)])) {
        if (me.pos === pl.pos) {
          if (pl.pos - 2 > 20) {
            displayList.push(<div className="wf-row wf-show-more" key={index++} data-page={parseInt(i / 20)} onClick={setViewPagesData}>
              <div className="wf-col wf-col-full">
                <img src={Players} alt="" /> ...
              </div>
            </div>);
            displayList.push(genPlayer(list[pl.pos - 2], index++, me));
          }
          displayList.push(genPlayer(list[pl.pos - 1], index++, me));
          if (pl.pos < list.length) displayList.push(genPlayer(list[pl.pos], index++, me));
        }
      } else if ((viewPages && state && viewPages[state.rankingIndex][parseInt(i / 20)]) || ((pl.pos % 20) === 0 && pl.pos > 20)) {
        if (!(viewPages && state && viewPages[state.rankingIndex][parseInt(i / 20)])) {
          displayList.push(<div className="wf-row wf-show-more" key={index++} data-page={parseInt(i / 20)} onClick={setViewPagesData}>
            <div className="wf-col wf-col-full">
              <img src={Players} alt="" /> ...
            </div>
          </div>);
        }

        displayList.push(genPlayer(pl, index++, me));
      } else if (i + 1 === list.length) {
        hasAbbrv = true;
        displayList.push(<div className="wf-row wf-show-more" key={index++} data-page={parseInt(i / 20)} onClick={setViewPagesData}>
          <div className="wf-col wf-col-full">
            <img src={Players} alt="" /> ...
          </div>
        </div>);
      }
    }

    if (me && me.pos > list.length) {
      if (me.pos !== list.length && !hasAbbrv) {
        displayList.push(<div className="wf-row wf-show-more" key={index++}>
          <div className="wf-col wf-col-full">
            <img src={Players} alt="" /> ...
          </div>
        </div>);
      }
      displayList.push(genPlayer(me, index++, me, true));
    }

    return displayList;
  };

  if (!score || !score.data || !seasons) return null;

  if (data.length === 0) return <div className={`wf-score ${className ? className : ''}`}>
    <div><WinnerLoader /></div>
  </div>;

  const seasonId = data.length ? data[0].id : "";
  const eventId = data.length === 2 ? data[1].id : "";

  return (
    <div className={`wf-score ${className ? className : ''}`} ref={rootRef}>
      {!stateTickets.open && <React.Fragment>
        <div className="wf-switcher">
          {data.map((season, i) => {
            if (!season.ui || !season.ui.name || !season.ui.name.text) return null;
            return <div className={`wf-tab ${state.rankingIndex === i ? "wf-active" : ""}`} onClick={handleRankingIndex(i)} key={`tab-${i}`}>
              <img src={choseImage(season.ui.rankingTabImage, imageType)} alt="" />
              <div className="wf-text">{chooseTextLanguage(season.ui.name.text, i18n.language)}</div>
            </div>;
          })}
        </div>
        <div>
          <div className="wf-header-title">
            {data[state.rankingIndex].type === "season" ? t("Season") : t("Competition")} "{chooseTextLanguage(data[state.rankingIndex].ui.name.text, i18n.language)}"
          </div>
          <div className="wf-tabs">
            <div className={`wf-tab ${state.detailsIndex === 0 ? "wf-active" : ""}`} onClick={handleDetailsIndex(0)}>{t("RANKING")}</div>
            <div className={`wf-tab ${state.detailsIndex === 1 ? "wf-active" : ""}`} onClick={handleDetailsIndex(1)}>{t("REGULATIONS")}</div>
            <div className={`wf-tab`} onClick={goToHistoric}>{t("HISTORIC")}</div>
          </div>
          {state.detailsIndex === 0 && <div className="wf-ranking">
            <div className="wf-row wf-header">
              <div className="wf-col wf-col1 wf-header wf.wf-center">{t("POS")}</div>
              <div className="wf-col wf-col2 wf-header"></div>
              <div className="wf-col wf-col3 wf-header wf-left">{t("PLAYER")}</div>
              <div className="wf-col wf-col4 wf-header wf-right">{t("AWARD")}</div>
              <div className="wf-col wf-col5 wf-header wf-right">{t("BALANCE")}</div>
            </div>
            <div className="wf-rows">
              {/* 
              {data[state.rankingIndex].top.slice(0, show[state.rankingIndex]).map((pl, i) => {
                let level = "wf-regular";
                if (i === 0) level = "wf-gold";
                if (i === 1) level = "wf-silver";
                if (i === 2) level = "wf-bronze";

                let isCurrent = false;
                let name = pl.nickname;
                if (data[state.rankingIndex].me && data[state.rankingIndex].me.pos === pl.pos) {
                  isCurrent = true;

                  if (!(player.nickname && player.approved && player.nickname === name)) {
                    name = <div onClick={handleOpen} className="wf-edit-nickname">{pl.nickname} <div className="wf-edit-icon"><EditIcon /></div></div>;
                  }
                }

                return <div
                  className={`wf-row ${isCurrent ? "wf-active" : ''} ${i < 3 ? `wf-row-${i}` : ''}`}
                  key={`player-${i}`}
                  onClick={showTicketsHistory(data[state.rankingIndex].type, pl, seasonId, state.rankingIndex === 0 ? null : eventId)}
                >
                  <div className="wf-col wf-col1">
                    <div className={level}>{pl.pos}</div>
                  </div>
              <div className="wf-col wf-col3">{name}</div>
              <div className="wf-col wf-col4 wf-right">{pl.award ? pl.award + " Lei" : "-"}</div>
              <div className="wf-col wf-col5 wf-right">{pl.balance}</div>
            </div>
              })}
            {data[state.rankingIndex].top.length > show[state.rankingIndex] && <div className="wf-row wf-show-more" onClick={showMoreHandler}>
              <div className="wf-col wf-col-full">
                {t("Show more")}
              </div>
            </div>}
            {
              data[state.rankingIndex].me &&
              (
                (data[state.rankingIndex].me.pos > show[state.rankingIndex] && data[state.rankingIndex].me.pos < data[state.rankingIndex].top.length)
                ||
                (data[state.rankingIndex].me.pos > data[state.rankingIndex].top.length)
              ) &&
              <div
                className={`wf-row wf-active`}
                key={`current-player`}
                onClick={showTicketsHistory(data[state.rankingIndex].type, data[state.rankingIndex].me, seasonId, state.rankingIndex === 0 ? null : eventId)}
              >
                <div className="wf-col wf-col1">
                  <div className={"wf-regular"}>{data[state.rankingIndex].me.pos < data[state.rankingIndex].top.length ? data[state.rankingIndex].me.pos : '-'}</div>
                </div>
              <div className="wf-col wf-col3">
                {
                  !(player.nickname && player.approved)
                    ?
                    <div onClick={handleOpen} className="wf-edit-nickname">{data[state.rankingIndex].me.nickname} <div className="wf-edit-icon"><EditIcon /></div></div>
                    :
                    data[state.rankingIndex].me.nickname
                }
              </div>
              <div className="wf-col wf-col4 wf-right">{data[state.rankingIndex].me.award ? data[state.rankingIndex].me.award + " Lei" : "-"}</div>
              <div className="wf-col wf-col5 wf-right">{data[state.rankingIndex].me.balance}</div>
            </div>}
            */}
              {genPlayersList(data[state.rankingIndex].top, data[state.rankingIndex].me)}
            </div>
          </div>}
          {state.detailsIndex === 1 && <React.Fragment>
            <div className="wf-regulations" style={styleToObj(data[state.rankingIndex].ui.regulations.style)} dangerouslySetInnerHTML={{
              __html: chooseTextLanguage(data[state.rankingIndex].ui.regulations.text, i18n.language)
            }} />

          </React.Fragment>}
          <div className="wf-spacer"></div>
        </div>

        {nicknameModal.open && <ChooseNickname open={nicknameModal.open} handleClose={handleClose} player={player} defaultNickname={player.default_nickname} />}
      </React.Fragment>}

      {stateTickets.open && <TicketsHistory handleClose={handleTicketsHistoryClose} type={stateTickets.type} player={stateTickets.player} seasonId={stateTickets.seasonId} eventId={stateTickets.eventId} />}
    </div >
  );
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);

  let isAuthenticated = true;
  if (["user", "token"].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    isAuthenticated = false;
  }

  return {
    isAuthenticated: isAuthenticated,
    player: wfs.player,
    score: wfs.score,
    seasons: wfs.config.seasons,
    teams: wfs.teams.teams,
  };
};
const mapActions = {
  scoreLoad,
  scoreClear
};

export default withTranslation()(connect(mapStateToProps, mapActions)(Score));
