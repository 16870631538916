import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getBetsState } from "../../store/selectors/betData";
import BetSelectSingleMobile from "./BetSelectSingleMobile";
import BetSelectSingleDesktop from "./BetSelectSingleDesktop";

const BootStrap = props => {
  const { layoutType, second } = props;

  //console.log("BETSELECT", props);

  let isDesktop = useMediaQuery("(min-width:1281px)");

  if (layoutType !== 1) return null;

  if (isDesktop) return <BetSelectSingleDesktop {...props} />;

  if (second) return null;

  return <BetSelectSingleMobile {...props} matchGroup={props.matchGroup + "_0"} />;
};

BootStrap.displayName = "BetSelectSingle";

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const bst = getBetsState(state);

    return {
      layoutType: bst.layout.layoutType
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(BootStrap);
