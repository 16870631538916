import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VelocityTransitionGroup } from 'velocity-react';
import * as stateActions from '../../../store/actions';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Game from './../Game/Game';
import './../Games.css';
import './FavouriteGames.css';
import Icons from '../../../assets/Icons/Icons';
import { withTranslation } from "react-i18next";

class FavouriteGames extends Component {
    constructor(props) {
        super(props);

        this.baseUrl = window.config.front_url;
    }

    genFavsList(favourites) {
        if (!(favourites.length > 0)) {
            return undefined;
        }

        let favIocon = Icons.get('heart', 'my-favourite-games');

        const transitionOptions = {
            transitionName: "fadeGameCard",
            transitionEnterTimeout: 500,
            transitionLeaveTimeout: 500,
        };

        return (
            <div id="FavouriteGames">
                <h1>
                    <span className="icon">{favIocon}</span>
                    <span className="text">{this.props.t("my favourites")}</span>
                    <div style={{
                        clear: "both",
                    }}></div>
                </h1>
                <div>
                    <ReactCSSTransitionGroup {...transitionOptions} className="GamesCards">
                        {favourites}
                    </ReactCSSTransitionGroup>
                </div>
            </div>
        );
    }

    render() {
        if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
            return null;
        }

        let favourites = [];
        let stateFavs = this.props.games.favourites;

        if (stateFavs.length > 0) {
            stateFavs.forEach((element) => {
                favourites.push(
                    <Game key={"fav_" + element.id} gameItem={element} section="favouritesGames" />
                );
            });
        }

        return (
            <VelocityTransitionGroup enter={{ animation: "slideDown" }} leave={{ animation: "slideUp" }} runOnMount={true} duration={1000}>
                {this.genFavsList(favourites)}
            </VelocityTransitionGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        games: state.games,
        authentication: state.authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        add: (game) => dispatch(stateActions.addToFavourites(game)),
        remove: (game) => dispatch(stateActions.removeFromFavourites(game)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FavouriteGames));