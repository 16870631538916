import * as actionTypes from '../actions/actionTypes';

const initialState = {
    show: false,
    text: "",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.lm.OPEN:
            return {
                ...state,
                show: true,
                text: action.text
            };

        case actionTypes.lm.CLOSE:
            return {
                ...state,
                show: false,
                text: "",
            };
    
        default:
            return state;
    }
};

export default reducer;