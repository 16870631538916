import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {

  },
  uc: {
    textTransform: "uppercase"
  },
  divider: {
    width: "calc(100% - 13px)",
    margin: "17px auto",
    borderTop: "1px solid #707070",
    height: "0px"
  },
  oddsText: {
    color: "#2D3038",
    fontSize: "14px",
    fontWeight: "600",
    margin: "0 13px 5px 13px",
  },
  stakeText: {
    color: "#262931",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0 13px",
    textTransform: "uppercase",
    "& .val": {
      fontSize: "15px",
      textTransform: "none",
    }
  },
  bonusWrapper: {
    borderLeft: "2px solid #ECECEC",
    margin: "0 13px",
  },
  bonusWinnerText: {
    color: "#CF7700",
    fontSize: "14px",
    margin: "0 0 0 13px",
    "& .val": {
      fontWeight: "bold",
    }
  },
  winningText: {
    color: "#262931",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0 13px",
    "& .val": {
      fontSize: "20px",
      color: "#DE2635"
    }
  },
});

const DetailsVariant = ({ ticket, t }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.divider}></div>
      <div className={`d-flex flex-nowrap align-items-center justify-content-between ${classes.stakeText}`}>
        <div>{t("Stake")}</div>
        <div className="val">{ticket.payment}</div>
      </div>
      <div className={classes.divider}></div>
	  {ticket.status !== "Open" && 
		<React.Fragment>
		<div className={`d-flex flex-nowrap align-items-center justify-content-between ${classes.winningText}`}>
			<div>
			<span className={classes.uc}>{ticket.status === "OPEN" ? t("Potential Winning") : t("Winning")}</span>
			</div>
			<div className="val">{ticket.payoutAmount ? ticket.payoutAmount : "0.00"} Lei</div>
		</div>
		<div className={classes.divider}></div>
		</React.Fragment>
	  }
    </div>
  );
};

export default withTranslation()(DetailsVariant);
