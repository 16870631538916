import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Trans } from 'react-i18next';

import Mask from "./assets/bgMask.svg";
import avatar from "./../NavBar/assets/user.svg";
import Delete from "./assets/deleteWhite.svg";
import DeleteModal from "./DeleteModal";
import {
  formatCurrency,
  formatAmount,
  formatAmount2,
  formatAmountWithDecimals
} from "../../utils/formatters";

import {
  betsSlipClearLottoTicket,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betsSlipSetTicketOnline,
  betSlipLottoInitializeTickets,
  betSlipLottoSetFreeBetIndex,
  betSlipLottoFreeBetClear,
  betSlipLottoBonusRequestEvaluation
} from "../../store/actions/betsSlip";

import { getLottoState } from "../../store/selectors/lottoData";
import TicketSwitch from "./TicketSwitch";
import BounceButton from "../../elements/BounceButton";
import TicketModal from "./TicketModal";

import { doLogin } from "../../../store/actions/authentication";

import InsufficientBalance from "./InsufficientBalance";

import LuckyNumberDialog from "../dialogs/lucky-number-dialog";

import { ReactComponent as DiamondSVG } from "./assets/diamond.svg";

import { getLottoBonuses, getLottoRingFences } from "../../store/selectors/wallet";

const noop = () => { };

const twoDecimalsFloat = vf => {
  let v = parseFloat(vf)
  if (isNaN(v)) {
    return v;
  }
  v = parseFloat(v.toFixed(2));
  return v;
}
const formatMessage = (...args) => {
  const txt = args[0];
  const parts = txt.split(/(%[dfs])/);
  let res = [];
  let found = 0;
  parts.forEach((p, i) => {
    if (/^%[dfs]$/.test(p)) {
      res.push(args[found + 1]);
      found += 1;
    } else {
      res.push(p);
    }
  })
  return res.join("");
}

const useStyles = makeStyles({
  root: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "19px 12px 0 12px",
    "& *": {
      boxSizing: "border-box"
    }
  },
  soldHolder: {
    position: "relative",
    "&.has-bonuses": {
      marginTop: "10px"
    }
  },
  sold: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D"
  },
  hr: {
    backgroundColor: "rgba(183,205,216,.35)",
    height: "1px",
    margin: "15px 0 5px"
  },
  hr2: {
    backgroundColor: "rgba(183,205,216,.35)",
    height: "1px",
    margin: "5px 0 15px"
  },
  stakeTitle: {
    fontSize: "10px",
    fontWeight: "600",
    color: "#586682",
    paddingLeft: "5px",
    marginTop: "8px"
  },
  stakeHolder: {
    backgroundColor: "rgba(183,205,216,.35)",
    borderRadius: "12px",
    padding: "8px 15px 10px 20px",
    marginTop: "9px",
    height: "55px"
  },
  stakeSubtitle: {
    fontSize: "11px",
    fontWeight: "600",
    color: "#586682"
  },
  stakeAmount: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#162A57"
  },
  currency: {
    fontSize: "11px",
    fontWeight: "300"
  },
  textCenter: {
    textAlign: "left"
  },
  but: {
    width: "28px",
    height: "28px",
    textAlign: "center",
    lineHeight: "28px",
    color: "#fff",
    backgroundColor: "#009ED9",
    borderRadius: "50%",
    fontWeight: "bold",

    fontSize: "14px",
    position: "relative",
    top: "2px",
    touchAction: "manipulation",
    "&.Mui-disabled": {
      opacity: ".5",
      pointerEvents: "none"
    }
  },
  amount: {
    backgroundColor: "#E6EEF1",
    border: "1px solid #E6EEF1",
    borderRadius: "10px",
    fontWeight: "bold",

    fontSize: "14px",
    color: "#17214D",
    margin: "0 5px",
    width: "150px"
  },
  amountInput: {
    textAlign: "right",
    padding: "0",
    height: "32px"
  },
  amountEnd: {
    marginLeft: "4px",
    paddingRight: "4px",
    "& p": {
      fontWeight: "bold",

      fontSize: "14px",
      whiteSpace: "nowrap"
    }
  },
  messageErr: {
    color: "#D72400",
    fontSize: "12px",
    fontWeight: "bold",
  },
  payButton: {
    padding: "6px 20px 5px 20px",
    background: props =>
      props.placeTicketEnabled
        ? "linear-gradient(90deg, #F8991C 0%, #FF196E 100%)"
        : "linear-gradient(90deg, #A7A7A7 0%, #DBDBDB 100%)",

    boxShadow: props => (props.placeTicketEnabled ? "0px 3px 6px rgba(0,0,0,.4)" : "none"),
    color: "#fff",
    borderRadius: "11px",
    height: "42px",
    width: "100%"
  },
  payAgencyButton: {
    padding: "6px 20px 5px 20px",
    background: props =>
      props.placeTicketEnabled
        ? "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)"
        : "linear-gradient(90deg, #A7A7A7 0%, #DBDBDB 100%)",

    boxShadow: props => (props.placeTicketEnabled ? "0px 3px 6px rgba(0,0,0,.4)" : "none"),
    color: "#fff",
    borderRadius: "11px",
    height: "42px",
    width: "100%"
  },
  loggedInButton: {
    padding: "6px 20px 5px 20px",
    background: "#0430D5",

    boxShadow: "0px 3px 6px rgba(0,0,0,.4)",
    color: "#fff",
    borderRadius: "11px",
    height: "42px",
    width: "100%"
  },
  incrementsHolder: {
    margin: "16px 0",
    "&.has-free-bets": {
      overflowY: "auto",
      overflowX: "visible",
      margin: "8px 0 8px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& > button": {
        marginRight: "6px",
        minWidth: "72px",
        maxWidth: "72px",
        whiteSpace: "nowrap",
      }
    }
  },
  increment: {
    border: "1px solid #B7CDD8",
    padding: "8px 20px",
    background: "#fff",

    color: "#586682",
    fontSize: "11px",
    borderRadius: "13px",
    touchAction: "manipulation",
    whiteSpace: "nowrap"
  },
  switchHolder: {
    marginTop: "16px"
  },
  switchLabel: {
    color: "#586682",
    fontSize: "10px",
    fontWeight: "600"
  },
  tabs: {
    marginTop: "20px",
    marginBottom: "10px",
    "& .MuiTabs-indicator": {
      background: "none"
    },
    minHeight: "initial",
    borderRadius: "9px",
    backgroundColor: "#E6EEF1",
    "& .Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#586682",
      borderRadius: "9px"
    }
  },
  tab: {

    color: "#17214D",
    fontWeight: "bold",
    fontSize: "10px",
    paddingTop: "3px",
    paddingBottom: "3px",
    minHeight: "initial"
  },
  buttonTitle: {
    fontWeight: "600",
    fontSize: "14px"
  },
  mask: {
    maskImage: `url(${Mask})`,
    maskRepeat: "no-repeat",
    maskPosition: "0 0",
    maskSize: "100% 100%",
    height: "55px",
    width: "163px",
    background:
      "linear-gradient(90deg, #7B8F97 0px, #6E88A6 26.6px, #586192 163px, #149AD4 163px, #1878E5 189px, #022CFF 326px, #14A5D4 326px, #1878E5 352px, #A902FF 489px)",
    backgroundSize: "489px, 55px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px, 0px",
    margin: "-8px -15px -10px 0",
    borderBottomRightRadius: "12px",
    borderTopRightRadius: "12px",
    position: "relative",
    animation: "smallAnim .33s ease",
    "& .subtitle": {
      color: "#C8DAFF",
      marginTop: "8px",
      marginLeft: "25px"
    },
    "& .title": {
      color: "#FFFFFF",
      fontSize: "20px",
      marginTop: "-4px",
      marginLeft: "25px"
    },
    "& .currency": {
      color: "#FFFFFF"
    },
    "&.medium": {
      backgroundPosition: "-163px, 0px",
      animation: "mediumAnim .33s ease"
    },
    "&.large": {
      backgroundPosition: "-326px, 0px",
      animation: "largeAnim .33s ease"
    }
  },
  bDown: {
    position: "absolute",
    bottom: "5px",
    left: "0px",
    right: "5px",
    width: "130px"
  },
  footer: {
    padding: "11px 12px",
    margin: "28px -12px 0",
    backgroundColor: "#E6EEF1",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px"
  },
  error: {
    color: "#fb2e7a",
    fontSize: "11px",
    fontWeight: "600",
    marginTop: "16px",
    textAlign: "center"
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px",
    "& img": {
      width: "27px",
      height: "34px"
    }
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogActions: {
    padding: "60px 16px 40px",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dialogNoAction: {
    backgroundColor: "#16214c",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px"
  },
  dialogYesAction: {
    backgroundColor: "#fb1a6e",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px"
  },
  avatar: {
    height: "17px",
    marginRight: "5px",
    position: "relative",
    top: "3px"
  },
  minWin: {
    textAlign: "right",
    fontSize: "10px",
    fontWeight: "600",
    color: "#586682",
    opacity: 0.7,
    paddingRight: "20px"
  },
  footerText: {
    color: "#fb2e7a",
    fontSize: "11px",
    fontWeight: "600",
    marginBottom: "10px",
    textAlign: "center"
  },
  footerTextLink: {
    "&>a": {
      color: "#000!important",
      textDecoration: "none!important",
    },
    background: "#fff",
    borderRadius: "10px",
    padding: "5px",
    cursor: "pointer",
    fontSize: "11px",
    fontWeight: "600",
    marginBottom: "10px",
    textAlign: "center",
  },
  xtra: {
    fontSize: "12px",
    fontWeight: "300",
    color: "#4A547D",
    textAlign: "center"
  },
  deleteButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FF1A6F",
    borderRadius: "11px",
    height: "42px",
    width: "52px",
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 3px 6px",
    marginRight: "10px",
    display: "flex",
    "&>img": {
      width: "15px",
      height: "18px"
    }
  },
  disabledButton: {
    background: "linear-gradient(90deg, #A7A7A7 0%, #DBDBDB 100%)!important",
    boxShadow: "none!important",
    pointerEvents: "none"
  },
  bonuses: {
    position: "absolute",
    top: "-45px",
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  hasBonuses: {
    marginTop: "15px"
  },
  bonus: {
    display: "flex",
    alignItems: "center",
    borderRadius: "2px 2px 5px 5px",
    border: "2px solid #D2DBE7",
    marginRight: "4px",
    padding: "5px",
    background: "#fff",
    "&.lucky-number": {
      "& .bonus-icon>svg": {
        display: "block",
        width: "12px",
        height: "16px",
        marginRight: "3px",
        "& path": {
          fill: "#FF7600"
        }
      },
      "& .bonus-name": {
        fontSize: "9px",
        fontWeight: "600",
        color: "#FF7600"
      },
      "& .bonus-q": {
        border: "1px solid rgba(0,0,0,.13)",
        background: "rgba(0,0,0,.05)",
        borderRadius: "50%",
        width: "15px",
        height: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "bold",
        color: "rgba(0,0,0,.17)",
        marginLeft: "10px"
      }
    },
    "&.lotto-chance": {
      "& .bonus-icon>svg": {
        display: "block",
        width: "12px",
        height: "16px",
        marginRight: "3px",
        "& path": {
          fill: "#890EE4"
        }
      },
      "& .bonus-name": {
        fontSize: "9px",
        fontWeight: "600",
        color: "#890EE4"
      },
      "& .bonus-q": {
        border: "1px solid rgba(0,0,0,.13)",
        background: "rgba(0,0,0,.05)",
        borderRadius: "50%",
        width: "15px",
        height: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "bold",
        color: "rgba(0,0,0,.17)",
        marginLeft: "10px"
      }
    },
  },
  freeBetButton: {
    position: "relative",
    fontSize: "12px",
    padding: "0 20px 6px",
    height: "33px",
    alignItems: "flex-end",
    "& b": {
      fontSize: "14px",
    }
  },
  freeBetButtonSelected: {
    border: "1px solid #FAC125",
    background: "linear-gradient(to right, #FFB011, #FD6130)",
    color: "#fff"
  },
  freeBetTicker: {
    position: "absolute",
    top: "-8px",
    right: "5px",
    fontSize: "11px",
    fontWeight: "600",
    color: "#A06000",
    background: "#FFC820",
    borderRadius: "2px",
    height: "15px",
    width: "60px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
  soldSmall: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#17214D",
    margin: "3px 0"
  },
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  soldSmallAmount: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
    margin: "3px 0",
    whiteSpace: "nowrap",
  },
  bonusHasErrors: {
    color: "#FF1A6C",
    fontWeight: "700",
    fontSize: "12px",
    paddingRight: "4px"
  },
  bonusHasWarnings: {
    color: "#F8991D",
    fontWeight: "700",
    fontSize: "12px",
    paddingRight: "4px"
  },
  bonusBalanceErrors: {
    marginLeft: "10px"
  },
  bonusErrorBar: {
    background: "#FF1A6C",
    height: "19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0 0 15px",
    fontSize: "9px",
    fontWeight: "bold",
    textAlign: "left",
    borderRadius: "10px",
    margin: "6px 10px",
    cursor: "pointer",
    "& > .txt": {
      color: "#fff",
      fontSize: "9px",
      fontWeight: "bold",
      textAlign: "left",
    },
    "& > .bar-question-mark": {
      color: "#fff",
      background: "#FF5491",
      fontSize: "15px",
      fontWeight: "bold",
      textAlign: "center",
      boxSizing: "border-box",
      width: "23px",
      height: "23px",
      border: "2px solid #fff",
      borderRadius: "50%",
      position: "relative",
      right: "-2px",
      padding: 0,
      lineHeight: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  bonusWarningBar: {
    background: "#FF7700",
    "& > .bar-question-mark": {
      background: "#FF9940",
    }
  },
  stakeBonusBalanceErrors: {
    marginTop: "10px",
    textAlign: "center"
  },
  lottoBonusWarning: {
    border: "1px solid #FF9E00",
    borderRadius: "10px",
    lineHeight: "12px",
    display: "flex",
    alignItems: "center",
    fontSize: "10px",
    color: "#BB7400",
    textAlign: "left",
    padding: "5px 10px",
    marginTop: "10px",
    "& strong": {
      marginLeft: "0.3em"
    }
  },
  pr: {
    position: "relative",
  },
  disable: {
    filter: "grayscale(100%)",
    pointerEvents: "none"
  }
});

const LottoSoldCard = props => {
  const classes = useStyles(props);
  const {
    extraLotteries,
    amount,
    //totalLines,
    winAmount,
    minWinAmount,

    stakeInc,
    stakeDec,
    stakeSet,

    placeTicketEnabled,

    clearBets,
    auth,
    ticketOnline,
    betsSlipSetTicketOnline,
    lottoTicket,

    wallet,
    profile,

    exitFull,
    betSlipLottoInitializeTickets,

    betSlipLottoSetFreeBetIndex,
    betSlipLottoFreeBetClear,

    freeBets,
    selectedFreeBet,
    bonusGivers,

    bonus,
    walletBonusEvaluate,
    walletBonuses,
    walletRingFences,
    requestBonusEvaluation,

    t,
  } = props;

  const history = useHistory();
  const [stakeAmount, setStakeAmount] = React.useState(amount)
  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openLuckyNumberInfo, setOpenLuckyNumberInfo] = React.useState(false);

  let lottoBonusGivers = [];

  if (selectedFreeBet === -1) {
    if (bonusGivers && typeof bonusGivers.lotto_lucky_number !== "undefined") {
      lottoBonusGivers.push({
        name: "Lucky Number",
        type: "lucky-number",
      });
    }

    if (bonusGivers && typeof bonusGivers.lotto_chance !== "undefined") {
      lottoBonusGivers.push({
        name: "Lotto Chance",
        type: "lotto-chance",
      });
    }
  }

  const handleLuckyNumberInfo = (event) => {
    if (typeof event.stopPropagation === "function") event.stopPropagation();
    setOpenLuckyNumberInfo(true);
  };
  const closeLuckyNumberInfo = () => {
    setOpenLuckyNumberInfo(false);
  };

  React.useEffect(() => {
    setStakeAmount(amount);
  }, [amount]);

  React.useEffect(() => {
    //console.log("wallet update", wallet);
    requestBonusEvaluation();
  }, [wallet, profile, lottoTicket, amount, requestBonusEvaluation]); // eslint-disable-line

  const handlePlaceBetClose = type => {
    setOpenPlaceBet(false);
    if (type === true) {
      window.location.hash = "#tickets-details";
    }
    clearBets();
  };

  const handleTicketLocation = online => {
    betsSlipSetTicketOnline(online);
  };

  const onStakeInc = (s, e) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    betSlipLottoFreeBetClear();
    stakeInc(s);
  };

  const onStakeDec = (s, e) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    betSlipLottoFreeBetClear();
    stakeDec(s);
  };

  const handleStakeChange = e => {
    let ev = e.target.value;
    if (selectedFreeBet !== -1) return;

    // let them input an empty string
    if (ev === "") {
      setStakeAmount("");
      stakeSet("");
      return;
    }

    // don't let them put an amount < 1
    if (ev === "0") {
      return;
    }

    // convert , to .
    ev = ev.split(",").join(".");

    // only one .
    const sev = ev.split(".");
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      return;
    }

    // only valid numbers
    if (isNaN(+ev)) {
      return;
    }

    let v = "";

    if (ev !== "") {
      // amounts ending with . have the same value
      if (ev.endsWith(".")) {
        setStakeAmount(ev);
        return;
      }

      // convert amount
      v = parseFloat(ev);
      if (isNaN(v)) return;
    }

    // update amount
    setStakeAmount(ev);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  let bgCls = "";
  if (winAmount > 100) {
    bgCls = "large";
  } else if (winAmount > 50) {
    bgCls = "medium";
  }

  //console.log("lottoTicket", lottoTicket);

  const createTicket = () => {
    const tickets = [];
    lottoTicket.allSelectedEvents.forEach((s, i) => {
      if (lottoTicket.allSelectedEventsFull && lottoTicket.allSelectedEventsFull[i] && lottoTicket.allSelectedEventsFull[i].event_date) {
        const ticketData = {
          numbers: lottoTicket.numbers,
          systems: lottoTicket.systems,
          event_id: s,
          amount: amount,
          date: lottoTicket.allSelectedEventsFull[i].event_date
        };

        if (lottoTicket.extra) {
          const tmp = { ...lottoTicket.extra };

          if (selectedFreeBet > -1) {
            const freeBet = freeBets[selectedFreeBet];
            tmp.free_bet = freeBet.code;
          }

          const bonus_user_data = [];

          if (tmp.free_bet) {
            bonus_user_data.push({
              type: "free_bet",
              id: tmp.free_bet,
              data: "{}"
            });
          } else {
            if (tmp.luckyNumber && bonusGivers && typeof bonusGivers.lotto_lucky_number !== "undefined") {
              bonus_user_data.push({
                type: "lotto_lucky_number",
                id: bonusGivers.lotto_lucky_number.code,
                data: JSON.stringify({
                  number: tmp.luckyNumber,
                })
              });
            }

            if (bonusGivers && typeof bonusGivers.lotto_chance !== "undefined") {
              bonus_user_data.push({
                type: "lotto_chance",
                id: bonusGivers.lotto_chance.code,
                data: "{}"
              });
            }
          }

          ticketData.bonus_user_data = JSON.stringify(bonus_user_data);
        }
        tickets.push(ticketData);
      }
    });

    const event = {
      event_name: lottoTicket.event.event_name,
    };

    betSlipLottoInitializeTickets({ event, tickets });
    setOpenPlaceBet(true);
  };

  const handleLogin = () => {
    if (typeof exitFull === "function") exitFull();
    localStorage.setItem("redirect_after_login", props.location.pathname);
    history.push("/connect");
  };

  let loggedIn = false;
  if (auth && auth.auth_type === "user") {
    loggedIn = true;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleYesClose = () => {
    setOpen(false);
    clearBets();
  };

  const onFreeBet = (index) => () => {
    if (selectedFreeBet === index) {
      betSlipLottoFreeBetClear();
    } else {
      betSlipLottoSetFreeBetIndex(index, freeBets[index]);
      const freeBetAmount = freeBets[index].amount_small === 1 ? freeBets[index].amount / 100 : freeBets[index].amount;
      stakeSet(freeBetAmount);
    }
  };

  const lottoBonusWarning = [];
  let lottoBonusDisable = false;
  let lottoLuckyBetDisable = false;

  if (freeBets && freeBets.length) {
    if (lottoTicket && lottoTicket.numbers && lottoTicket.numbers.length < 3) {
      lottoBonusWarning.push(
        <div key="lb1" className={classes.lottoBonusWarning}>
          <Trans i18nKey="luckyBetWarning1">Minimum 3 numbers to use <strong>Lucky Bet/Free Bet</strong></Trans>
        </div>
      );
      lottoLuckyBetDisable = true;
      if (selectedFreeBet !== -1) {
        betSlipLottoFreeBetClear();
      }
    } else if (lottoTicket && lottoTicket.numbers && lottoTicket.systems && (lottoTicket.systems.length !== 1 || lottoTicket.systems[0] !== lottoTicket.numbers.length)) {
      lottoBonusWarning.push(
        <div key="lb2" className={classes.lottoBonusWarning}>
          <Trans i18nKey="luckyBetWarning2">You need a simple ticket for <strong>Lucky Bet/Free Bet</strong></Trans>
        </div>
      );
      lottoLuckyBetDisable = true;
      if (selectedFreeBet !== -1) {
        betSlipLottoFreeBetClear();
      }
    }
  }

  if (lottoBonusGivers && lottoBonusGivers.length) {
    if (lottoTicket && lottoTicket.numbers && lottoTicket.numbers.length < 3) {
      lottoBonusWarning.push(
        <div key={`lbg1`} className={classes.lottoBonusWarning}>
          <Trans i18nKey="luckyNumberWarning1">Minimum 3 numbers to unlock <strong>Lucky Number</strong></Trans>
        </div>
      );
      lottoBonusDisable = true;
    } else if (lottoTicket && lottoTicket.numbers && lottoTicket.systems && (lottoTicket.systems.length !== 1 || lottoTicket.systems[0] !== lottoTicket.numbers.length)) {
      lottoBonusWarning.push(
        <div key={`lbg2`} className={classes.lottoBonusWarning}>
          <Trans i18nKey="luckyNumberWarning2">You need a simple ticket for <strong>Lucky Number</strong></Trans>
        </div>
      );
      lottoBonusDisable = true;
    }
  }

  const buildFreeBets = () => {
    const buttons = [];

    if (!ticketOnline) return buttons;
    const now = Math.floor(+ new Date() / 1000);

    freeBets.forEach((fb, i) => {
      if (fb && fb.product === "lotto" && fb.count && fb.expires > now) {
        if (lottoTicket && lottoTicket.event && lottoTicket.event.system_id) {
          if (fb.lottery_systems && fb.lottery_systems.indexOf(lottoTicket.event.system_id) > -1) {
            const amount = fb.amount_small === 1 ? fb.amount / 100 : fb.amount;

            buttons.push((<ButtonBase
              key={`freeBet_${i}`}
              onClick={onFreeBet(i)}
              variant="contained"
              className={`${classes.increment} ${classes.freeBetButton} ${selectedFreeBet === i ? classes.freeBetButtonSelected : ''} ${lottoLuckyBetDisable ? classes.disable : ''}`}
            >
              <b>{fb.count}</b>&nbsp;x&nbsp;<b>{amount}</b>&nbsp;Lei
              <div className={classes.freeBetTicker} title={t(fb.name)}>{fb.name && fb.name.toLowerCase() === "lucky bet" ? t(fb.name) : "Free Bet"}</div>
            </ButtonBase>))
          }
        }
      }
    });

    return buttons;
  };



  let ticketAppliedBonus = null;
  if (bonus && bonus.ticketAppliedBonus) {
    ticketAppliedBonus = (
      <div
        className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}
      >
        <div className={classes.soldSmall}>
          {t("Progressive Bonus")} {bonus.ticketAppliedBonus.maxPercentage}%
        </div>
        <div className={classes.soldSmallAmount}>
          +{formatAmount(bonus.ticketAppliedBonus.amountMax, false, false)}{" "}
          {formatCurrency(wallet.currency)}
        </div>
      </div>
    );
  }

  let ticketBonusEligibles = null;
  if (bonus && bonus.ticketBonusEligibles && bonus.ticketBonusEligibles.length) {
    const bonuses = [];
    bonus.ticketBonusEligibles.forEach(b => {
      if (b.eligible) {
        bonuses.push(b.bonus.name);
      }
    });
    ticketBonusEligibles = (
      <div
        className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}
      >
        <div className={classes.soldSmall}>{t("Eligible Bonuses")}</div>
        <div className={classes.soldSmallAmount}>{bonuses.join(" + ")}</div>
      </div>
    );
  }

  const walletBonusesHash = {};
  if (walletBonuses) {
    walletBonuses.forEach(wb => {
      walletBonusesHash[wb.id] = wb.name
    });
  }

  let bonusEvaluateMessagesEligible = null;
  let hasBonusEvaluateMessages = false;
  let stakeErrorBonuses = [];

  let free_money_used = 0;
  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  const can_ticket_be_evalued = lottoTicket && lottoTicket.numbers && lottoTicket.systems && lottoTicket.numbers.length && lottoTicket.systems.length ? true : false;

  if (window.config.useBonusEvaluation === "1" && can_ticket_be_evalued) {
    const bonuses = [];
    let usedBonuses = [];
    let usedRingFences = [];
    let errorBonuses = [];
    //let eligible = false;

    if (walletBonusEvaluate && walletBonusEvaluate.success && walletBonusEvaluate.data && walletBonusEvaluate.data.bonus) {
      bLimit = free_money_used = twoDecimalsFloat(walletBonusEvaluate.data.free_money_used);
      usedBonuses = walletBonusEvaluate.data.bonus ? walletBonusEvaluate.data.bonus : [];
      usedRingFences = walletBonusEvaluate.data.ring_fence ? walletBonusEvaluate.data.ring_fence : [];
      errorBonuses = walletBonusEvaluate.data.details ? walletBonusEvaluate.data.details : [];
      //eligible = walletBonusEvaluate.data.eligible;
      valid = walletBonusEvaluate.data.valid;
    } else {
      valid = bLimit >= amount;
    }

    walletBonuses.forEach(wb => {
      let total_used = 0;
      let balance_total = wb.amount;

      // collect what would the total amount ring fence + bonus would be
      const rf = walletRingFences.find(rf => wb.id === rf.id);
      if (rf) {
        balance_total += rf.amount;
      }

      // used ring fence
      const urf = usedRingFences.find(rf => wb.id === rf.id);
      if (urf) {
        bLimit += twoDecimalsFloat(urf.balance_used);
        total_used += twoDecimalsFloat(urf.balance_used);
      }

      // used bonus
      const ub = usedBonuses.find(b => wb.id === b.id);
      if (ub) {
        bLimit += twoDecimalsFloat(ub.balance_used);
        total_used += twoDecimalsFloat(ub.balance_used);
      }

      total_used = twoDecimalsFloat(total_used);
      bonuses.push({ id: wb.id, name: wb.name, balance: balance_total, balance_used: total_used ? -total_used : 0 });
    });

    bLimit = twoDecimalsFloat(bLimit);
    //bonuses.sort((a, b) => a.balance - b.balance);

    //console.log("bonuses", bonuses, errorBonuses);

    bonusEvaluateMessagesEligible = (
      <div>
        {bonuses.map((b, i) => {
          const eb = errorBonuses.filter(eb => eb.id === b.id);
          //console.log("errorBonuses", eb);

          hasBonusEvaluateMessages = true;

          return (
            <div key={"bonus_" + i}>
              <div className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center`} key={i}>
                <div className={`${classes.soldSmall} ${classes.ellipsis}`}>
                  {!valid && eb.length > 0 && (<span className={classes.bonusHasErrors}>!</span>)}
                  {valid && eb.length > 0 && (<span className={`${classes.bonusHasWarnings}`}>!</span>)}
                  {b.name}
                </div>
                <div className={classes.soldSmallAmount}>{formatAmountWithDecimals(b.balance, 2, true)} Lei (<span className={classes.messageErr}>{formatAmountWithDecimals(b.balance_used, 2, true)}</span>)</div>
              </div>
              {!valid && eb.length > 0 && <div className={`${classes.bonusErrorBar}`}>
                <div className="txt">{t("Conditiile BONUS nu sunt indeplinite")}</div>
                <div className="bar-question-mark">?</div>
              </div>}
              {valid && eb.length > 0 && <div className={`${classes.bonusErrorBar} ${classes.bonusWarningBar}`}>
                <div className="txt">{t("Acest bilet nu va contribui la rulajul bonusului")}</div>
                <div className="bar-question-mark">?</div>
              </div>}
              <div className={classes.bonusBalanceErrors}>
                {!valid && eb.filter(e => e.error && e.error.type === "ticket").map((e, i) => {
                  const args = [...e.error.args];
                  args[0] = (walletBonusesHash[eb.id] ? walletBonusesHash[eb.id] + ": " : "") + t(args[0]); // see if we have a translation for that message

                  if (e.error.section === "generic") {
                    return (<div key={eb.id + "_" + i} className={classes.message}>{formatMessage(...args)}</div>);
                  } else if (e.error.section === "stake") {
                    stakeErrorBonuses.push(<div key={eb.id + "_" + i} className={classes.message}>{formatMessage(...args)}</div>)
                  }
                  return null;
                })}
              </div>
            </div>
          )
        })}
      </div>
    );
  }

  let disableLottoTicketInShop = false;
  if (typeof window.config.disableLottoTicketInShop !== "undefined" && window.config.disableLottoTicketInShop === "1") {
    disableLottoTicketInShop = true;
    if (!ticketOnline) {
      setTimeout(() => {
        betsSlipSetTicketOnline(true);
      }, 0);
    }
  }

  return (
    <div className={`${classes.root} ${ticketOnline && lottoBonusGivers.length ? classes.hasBonuses : ""}`}>
      {loggedIn && (
        <div className={classes.pr}>
          {valid && ticketOnline && <div className={classes.bonuses}>
            {lottoBonusGivers.map((b, i) => {

              let clickHandler = noop;
              if (b.type === "lucky-number") clickHandler = handleLuckyNumberInfo;

              return <div className={`${classes.bonus} ${b.type} ${lottoBonusDisable ? classes.disable : ''}`} key={`bonus_${i}`} onClick={clickHandler}>
                <div className="bonus-icon"><DiamondSVG /></div>
                <div className="bonus-name">{t(b.name)}{b.type === "lucky-number" && lottoTicket && lottoTicket.extra && lottoTicket.extra.luckyNumber !== 0 && <span>&nbsp;({lottoTicket.extra.luckyNumber})</span>}</div>
                <div className="bonus-q">?</div>
              </div>
            })}
          </div>}
          {lottoTicket && !valid && (selectedFreeBet === -1) && <InsufficientBalance />}
          {lottoBonusWarning.length > 0 && <div className={`${classes.soldHolder} ${lottoBonusGivers.length && valid && ticketOnline ? "has-bonuses" : ""}`}>
            {lottoBonusWarning}
          </div>}
          <div
            className={`${classes.soldHolder} ${lottoBonusGivers.length && valid && ticketOnline ? "has-bonuses" : ""} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
          >
            <div className={classes.sold}>{t("AVAILABLE")}</div>
            <div className={classes.sold}>
              {formatAmountWithDecimals(wallet.main, 2, true)} {formatCurrency(wallet.currency)}
              {!!free_money_used && <span>
                &nbsp;(<span className={classes.messageErr}>-{formatAmountWithDecimals(free_money_used, 2, true)}</span>)
              </span>}
            </div>
          </div>
        </div>
      )}
      {ticketBonusEligibles}
      {bonusEvaluateMessagesEligible}
      {(!!ticketAppliedBonus || !!ticketBonusEligibles || hasBonusEvaluateMessages) && <div className={classes.hr2}></div>}
      <div className={classes.stakeTitle}>{t("Choose Stake")}</div>
      <div
        className={`${classes.stakeHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
      >
        <div className={classes.textCenter}>
          <div className={classes.stakeSubtitle}>{t("Total Stake")} ({extraLotteries} {t("draws")})</div>
          <div className={classes.stakeAmount}>
            {amount * extraLotteries}{" "}
            <span className={`${classes.currency}`}>{formatCurrency(wallet.currency)}</span>
          </div>
        </div>
        <div className={`${classes.textCenter} ${classes.mask} ${bgCls}`}>
          <div className={`${classes.stakeSubtitle} subtitle`}>{t("Max. Winning per Ticket")}</div>
          <div className={`${classes.stakeAmount} title`}>
            {winAmount === "" ? 0 : formatAmount2(winAmount)}{" "}
            <span className={`${classes.currency} currency`}>
              {formatCurrency(wallet.currency)}
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row flex-nowrap justify-content-between">
        <div>
          &nbsp;
        </div>
        <div>
          <div className={classes.minWin}>
            {t("Min. Winning")}: {formatAmount2(minWinAmount)} {formatCurrency(wallet.currency)}
          </div>
        </div>
      </div>
      <div className={classes.xtra}>x{extraLotteries} {t("draws")}</div>
      <div
        className={`${classes.payHolder} d-flex flex-row flex-nowrap align-items-center justify-content-center`}
      >
        <BounceButton>
          <ButtonBase onClick={e => onStakeDec(-1, e)} variant="contained" className={classes.but} disabled={selectedFreeBet !== -1}>
            -
          </ButtonBase>
        </BounceButton>
        <InputBase
          type="text"
          onChange={handleStakeChange}
          className={classes.amount}
          value={stakeAmount}
          inputProps={{
            className: classes.amountInput,
            "aria-label": "stake",
            pattern: "[0-9\.]*"
          }}
          endAdornment={
            <InputAdornment className={classes.amountEnd} position="end">
              Lei&nbsp;<span style={{ fontWeight: "normal" }}>/ {t("draw")}</span>
            </InputAdornment>
          }
        />
        <BounceButton>
          <ButtonBase onClick={e => onStakeInc(-1, e)} variant="contained" className={classes.but} disabled={selectedFreeBet !== -1}>
            +
          </ButtonBase>
        </BounceButton>
      </div>
      <div
        className={`${classes.incrementsHolder} ${freeBets && freeBets.length !== 0 ? 'has-free-bets' : ''} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
      >
        {freeBets.length !== 0 && buildFreeBets()}
        <ButtonBase
          onClick={e => onStakeInc(5, e)}
          variant="contained"
          className={classes.increment}
        >
          +5 Lei
        </ButtonBase>
        <ButtonBase
          onClick={e => onStakeInc(10, e)}
          variant="contained"
          className={classes.increment}
        >
          +10 Lei
        </ButtonBase>
        <ButtonBase
          onClick={e => onStakeInc(20, e)}
          variant="contained"
          className={classes.increment}
        >
          +20 Lei
        </ButtonBase>
        <ButtonBase
          onClick={e => onStakeInc(50, e)}
          variant="contained"
          className={classes.increment}
        >
          +50 Lei
        </ButtonBase>
      </div>
      <div className={`${classes.payButtonHolder} d-flex align-items-center ${disableLottoTicketInShop ? 'pb-4' : ''}`}>
        <div className={classes.deleteButton} onClick={handleClickOpen}>
          <img src={Delete} alt="" />
        </div>
        {(loggedIn || !ticketOnline) && (
          <React.Fragment>
            {ticketOnline && (
              <ButtonBase
                variant="contained"
                className={`${classes.payButton} ${!placeTicketEnabled || (!valid && selectedFreeBet === -1) || (amount === 0 || amount === "") || extraLotteries === 0 ? classes.disabledButton : ""}`}
                onClick={createTicket}
                disabled={!placeTicketEnabled || (!valid && selectedFreeBet === -1) || (amount === 0 || amount === "") || extraLotteries === 0}
              >
                <div>
                  <div className={classes.buttonTitle}>{t("PLACE TICKET ONLINE")}</div>
                </div>
              </ButtonBase>
            )}
            {!ticketOnline && (
              <ButtonBase
                variant="contained"
                className={`${classes.payAgencyButton}`}
                onClick={createTicket}
                disabled={!placeTicketEnabled || (amount === 0 || amount === "")}
              >
                <div>
                  <div className={classes.buttonTitle}>{t("PLACE TICKET IN SHOP")}</div>
                </div>
              </ButtonBase>
            )}
          </React.Fragment>
        )}
        {!loggedIn && ticketOnline && (
          <ButtonBase
            variant="contained"
            className={`${classes.loggedInButton}`}
            onClick={handleLogin}
          >
            <div>
              <div className={classes.buttonTitle}>
                <img src={avatar} alt="" className={classes.avatar} /> {t("ENTER YOUR ACCOUNT")}
              </div>
            </div>
          </ButtonBase>
        )}
      </div>
      {!disableLottoTicketInShop && <div className={classes.footer}>
        <TicketSwitch online={ticketOnline} onChange={handleTicketLocation} />
      </div>}
      <TicketModal openPlaceBet={openPlaceBet} handlePlaceBetClose={handlePlaceBetClose} />
      <DeleteModal open={open} handleClose={handleClose} handleYesClose={handleYesClose} />
      {openLuckyNumberInfo && <LuckyNumberDialog open={openLuckyNumberInfo} onClose={closeLuckyNumberInfo} />}
    </div>
  );
};

LottoSoldCard.propTypes = {
  bets: PropTypes.array,
};

LottoSoldCard.defaultProps = {
  bets: [],
};

const mapStateToProps = state => {
  const bst = getLottoState(state);
  const ct = bst.betsSlip;

  return {
    wallet: state.wallet,
    profile: state.profile,

    amount: ct.amount,
    placeTicketEnabled: ct.placeTicketEnabled,
    placeTicketErrorMessage: ct.placeTicketErrorMessage,
    errorMessage: ct.errorMessage,
    auth: state.authentication,
    ticketOnline: ct.ticketOnline,
    lottoTicket: ct.lottoTicket,
    freeBets: state.freeBets.freeBets,
    selectedFreeBet: ct.selectedFreeBet,

    bonus: ct.lottoTicket && ct.lottoTicket.bonus ? ct.lottoTicket.bonus : null,
    walletBonusEvaluate: ct.lottoTicket && ct.lottoTicket.bonusEvaluate ? ct.lottoTicket.bonusEvaluate : null,
    walletBonuses: getLottoBonuses(state),
    walletRingFences: getLottoRingFences(state),
  };
};

const actionCreators = {
  clearBets: betsSlipClearLottoTicket,
  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  betsSlipSetTicketOnline: betsSlipSetTicketOnline,
  betSlipLottoInitializeTickets: betSlipLottoInitializeTickets,
  betSlipLottoSetFreeBetIndex: betSlipLottoSetFreeBetIndex,
  betSlipLottoFreeBetClear: betSlipLottoFreeBetClear,
  doLogin: doLogin,
  requestBonusEvaluation: betSlipLottoBonusRequestEvaluation,
};

export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(LottoSoldCard)));
