import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEqual from "lodash/fp/isEqual";
import { withTranslation } from "react-i18next";
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { getTopTournamentsList, getPrematchCategories } from "../../../../bets/store/selectors/betData";
import { getBaseRouterPath } from "../../../utils/config";

const TopOffer = props => {
  const { topTournaments, categories, history, onNavigate, t } = props;
  const basePath = getBaseRouterPath();

  const [state, setState] = React.useState({
    topTournaments: []
  });

  React.useEffect(() => {
    setState(v => {
      let isModified = false;
      let tmp = { ...v };
      const values = Object.values(topTournaments);
      if (!isEqual(v.topTournaments, values)) {
        tmp.topTournaments = values;
        isModified = true;
      }
      if (isModified) return tmp;
      return v;
    });
  }, [topTournaments]);

  const handleNavigate = (idSport, idCategory, idTournament) => () => {
    if (typeof onNavigate === "function") onNavigate();
    if (idSport && idCategory && idTournament) {
      history.push(`${basePath}/prematch/tournament/${idSport}/${idCategory}/${idTournament}`);
    } else if (idSport && idCategory) {
      history.push(`${basePath}/prematch/category/${idSport}/${idCategory}`);
    } else if (idSport) {
      history.push(`${basePath}/prematch/sport/${idSport}`);
    }
  };

  const goToStart = () => {
    if (typeof onNavigate === "function") onNavigate();
    history.push(`${basePath}/prematch`);
  };

  const getFlag = (category) => {
    if (category.categoryDLId) {
      return `digflag2 f${category.categoryDLId}`;
    }
    return `flag flag-${category.categoryIsoName ? category.categoryIsoName.toLowerCase() : "undef"}`;
  };

  const buildTopTournamentsList = () => {
    const list = [];

    list.push(
      <div
        className={`navigation-item  full`}
        key={`to_tournament_start_page`}
        title={t("Favorite")}
        onClick={goToStart}
      >
        <div className="icon"><StarBorderIcon /></div>
        <div className="label">{t("Start")}</div>
        <div className="match-count no-right"></div>
      </div>
    );

    state.topTournaments.forEach((tournament, i) => {
      const idTournament = tournament.idTournament;
      const idCategory = tournament.idCategory;
      const idSport = tournament.idSport;
      const category = categories[idCategory];
      list.push(
        <div
          className={`navigation-item full`}
          key={`to_tournament_${idTournament}`}
          title={tournament.tournamentName}
          onClick={handleNavigate(idSport, idCategory, idTournament)}
        >
          <div className="icon"><div className={`${getFlag(category)}`}></div></div>
          <div className="label">{tournament.tournamentName}</div>
          <div className="match-count no-right">{tournament.numberOfEvents}</div>
        </div>
      );
    });
    return list;
  };

  return (
    <React.Fragment>
      <div className="navigation-group navigation-group-1">{t("Top Offer")}</div>
      {buildTopTournamentsList()}
    </React.Fragment>
  );
};

const emptyArray = [];

const mapStateToProps = state => {
  return {
    topTournaments: state.bets.prematch && state.bets.prematch.topOffer && state.bets.prematch.topOffer.tournaments ? state.bets.prematch.topOffer.tournaments : emptyArray,
    categories: getPrematchCategories(state)
  };
};

const actionCreators = {};

export default withTranslation()(connect(mapStateToProps, actionCreators)(withRouter(TopOffer)));
