import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import './SubMenuItem.css';
import Icons from './../../../../assets/Icons/Icons';
import * as stateActions from "../../../../store/actions";
import { withTranslation } from "react-i18next";
import { t } from 'i18next';

class SubMenuItem extends Component {
    constructor(props) {
        super(props);

        this.activeClass = "";
    }

    componentWillMount(){
        this.props.isRequestGameCollections();
    }

    clickHandler() {
        this.props.onSubPageChanged(this.props.row);

        let activeCls = "";
        if ("selected" !== this.activeClass) {
            activeCls = "selected";
        }

        this.activeClass = activeCls;

        return true;
    }

    checkState() {
        const subpage = this.props.row;

        if (!this.props.subpage) {
            return;
        }

        if (this.props.subpage.code === subpage.code) {
            this.activeClass = "selected";
            return;
        }

        if ("" === this.activeClass) {
            return;
        }

        this.activeClass = "";
    }

    getCollectionDetails(jackpot = false){
        let collectionDetails = [];
        if(!this.props.games.loadingGameCollections && this.props.games.gameCollections && this.props.games.gameCollections.length > 0){
            let collections = this.props.games.gameCollections.filter(Boolean);
            Object.values(this.props.games.gameCollections).forEach((item, index) => {
                collections[item.id] = item;
            });

            if(jackpot && collections.length > 0){
                for (let i = 0; i < collections.length; i++) {
                    if(collections[i].jackpot_id){
                        collectionDetails = [{
                            id: collections[i].id,
                        }];
                        break;
                    }
                }
            }
            let collectionID = window.location.pathname.replace("/cazino/collection/","");
            if (collectionID !== "undefined" && collectionID !== '' && !isNaN(collectionID)) {
                if(collections[collectionID]){
                    collectionDetails = [{
                        id: collectionID,
                        title: collections[collectionID].title,
                    }];
                }else{
                    collectionDetails = [{
                        id: collectionID,
                        title: this.props.t('Collection Unavailable'),
                    }];
                }
            }
        }
        return collectionDetails;
    }

    openSpribe() {
        this.props.onOpenGame({id: window.config.spribe_promo_top_menu});
        this.props.history.push('/play/' + window.config.spribe_promo_top_menu)
    }

    render() {
        const page = this.props.page;
        let row = this.props.row;
        let stringUrl = '/' + page.code + '/' + row.code;
        row.name = this.props.t(row.name);
        let icon = Icons.get(row.icon, row.icon);

        this.checkState();

        let comingSoon = "";
        if (!row.available) {
            comingSoon = (
                <div className={"coming-soon"}>
                    <span>{this.props.t("coming soon")}</span>
                </div>
            );
        }

        let collectionDetails = '';
        if(row.code === 'collection'){
            collectionDetails = this.getCollectionDetails();
            if(collectionDetails.length > 0){
                icon = '';
                row.name = collectionDetails[0].title;
                stringUrl = stringUrl + "/" + collectionDetails[0].id;
            }else{
                return '';
            }
        }

        if(row.code === 'jackpot'){
            collectionDetails = this.getCollectionDetails(true);
            if(collectionDetails.length > 0){
                stringUrl = "/cazino/collection/" + collectionDetails[0].id;
            }
        }
        let item = [];
        item.push(
            <div className="SubMenuItem" key={'submenu_'+row.name}>
                <Link to={stringUrl} onClick={this.clickHandler.bind(this)} className={this.activeClass} title={row.name.toUpperCase()}>
                    {icon}
                    <span className="item-text">{row.name}</span>
                    {comingSoon}
                </Link>
            </div>
        );
        if(window.config && typeof window.config.spribe_promo_top_menu !== 'undefined' && window.config.spribe_promo_top_menu !== '0' && row.code === 'jackpot') {
            item.push(
                <div className="SubMenuItem" key={'submenu_spribe_aviator'}>
                    <Link to={'/play/' + window.config.spribe_promo_top_menu} onClick={this.openSpribe.bind(this)} className={this.activeClass} title={row.name.toUpperCase()}>
                        {Icons.get('aviator-sign')}
                        <span className="item-text">{Icons.get('aviator-text')}</span>
                    </Link>
                </div>
            );
        }

        return (
            <Fragment>
                {item}
            </Fragment>
        );

    }
}

const mapStateToProps = state => {
    return {
        page: state.application.page,
        subpage: state.application.subpage,
        games: state.games,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        isRequestGameCollections: () => dispatch(stateActions.requestGameCollections()),
        onOpenGame: (game, x, y) => dispatch(stateActions.openGame(game, x, y)),
        onSubPageChanged: (subpage) => {
            // dispatch({
            //     type: actionTypes.application.SUBPAGE,
            //     item: subpage
            // })
            dispatch(stateActions.changeSubpage(subpage));
        }
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SubMenuItem)));