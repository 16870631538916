import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import isEqual from "lodash/fp/isEqual";

class Page extends React.Component {
  constructor(props) {
    super(props);

    let key = props.location.pathname.split("/");
    if (key.length > 3) {
      key.pop();
    }
    key = key.join("/");

    this.state = {
      path: key,
      show: false
    };
  }

  componentDidMount() {
    this.setState({
      show: true
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ("doRender" in this.props && "doRender" in nextProps) {
      if (!isEqual(this.props.doRender, nextProps.doRender)) {
        return true;
      }
    }

    if (this.props.location.pathname !== nextProps.location.pathname) {
      let key = nextProps.location.pathname.split("/");
      if (key.length > 3) {
        key.pop();
      }
      key = key.join("/");
      if (key !== this.key) {
        return true;
      }
    }

    if (this.state.show !== nextState.show) {
      return true;
    }

    return false;
  }

  render() {
    const { show, path } = this.state;
    const { children } = this.props;

    return (
      <div className="page" data-id={path}>
        {show && <div className="page__inner">{children}</div>}
      </div>
    );
  }
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
};

Page.defaultProps = {};

export default withRouter(Page);
