import React from 'react';
import moment from 'moment';

const Timer = ({ date }) => {
  const [state, setState] = React.useState({
    hrs: "00",
    min: "00",
    sec: "00"
  });

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;

    if (mounted) {
      const calcTime = () => {
        const dt = moment(date);
        const dtNow = moment();

        const hours = dt.diff(dtNow, 'hours');
        const mins = dt.diff(dtNow, 'minutes') - hours * 60;
        const secs = dt.diff(dtNow, 'seconds') - mins * 60;

        if (hours <= 0 && mins <= 0 && secs <= 0) {
          clearInterval(intervalID);
          setState({
            hrs: "00",
            min: "00",
            sec: "00"
          });
          return;
        }

        setState({
          hrs: ("0" + hours).slice(-2),
          min: ("0" + mins).slice(-2),
          sec: ("0" + secs).slice(-2)
        });
      };
      intervalID = setInterval(() => {
        calcTime();
      }, 1000);
      calcTime();
    }
    return () => {
      mounted = false;
      clearInterval(intervalID);
    }
  }, []); // eslint-disable-line

  return <span>
    <span className={`${state.hrs === "00" ? "disabled" : ""}`}>{state.hrs}</span>:
    <span className={`${state.hrs === "00" && state.min === "00" ? "disabled" : ""}`}>{state.min}</span>:
    <span className={`${state.hrs === "00" && state.min === "00" && state.sec === "00" ? "disabled" : ""}`}>{state.sec}</span>
  </span>;
}

export default Timer;
