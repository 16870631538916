import React from 'react';
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";

import "./happy-hours.scss";
import PortraitMode from "./portrait";
import LandscapeMode from "./landscape";

import PortraitModeSpecialPrize from "./portrait-special-prize";
import LandscapeModeSpecialPrize from "./landscape-special-prize";

const HappyHoursMobile = (props) => {

  const { onChange } = props;
  const isPortrait = useMediaQuery("(orientation: portrait)");
  const [collapsed, setCollapsed] = React.useState(false);
  const onCollapsedChange = () => {
    setCollapsed(v => {
      return !v;
    });
    if (typeof onChange === "function") onChange(!collapsed);
  };

  if (!props.data || !props.running) return null;
  if (props.data.event_type !== "online-slots") return null;

  const data = Object.assign({}, props.data);
  data.date = moment(data.start_time * 1000).add(data.duration, "seconds");
  data.enabled = props.running;

  const hasSpecialPrize =
    typeof data.prize_current !== "undefined"
      && !data.prize_current.amount
      && typeof data.prize_current.custom !== "undefined" ? true : false;

  if (isPortrait) {
    if (hasSpecialPrize) {
      return <PortraitModeSpecialPrize collapsed={collapsed} onCollapsed={onCollapsedChange} data={data} betInfo={props.betInfo} />
    }

    return <PortraitMode collapsed={collapsed} onCollapsed={onCollapsedChange} data={data} betInfo={props.betInfo} />;
  }

  if (hasSpecialPrize) {
    return <LandscapeModeSpecialPrize collapsed={collapsed} onCollapsed={onCollapsedChange} data={data} betInfo={props.betInfo} />
  }

  return <LandscapeMode collapsed={collapsed} onCollapsed={onCollapsedChange} data={data} betInfo={props.betInfo} />;
};

export default HappyHoursMobile;
