/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import axios from "axios";

import { hotDaysConstants } from "../actions/constants";
import { loadHotDays } from "../actions/hotDays";

let apiUrl;
if (process.env.NODE_ENV === "development") {
  apiUrl = "http://localhost:3001/betsapi/hot-days/all";
} else {
  apiUrl = "/betsapi/hot-days/all";
}

function axiosObject(url) {
  return axios.get(url);
}

function* fetchHotDaysSaga(action) {
  try {
    let { data } = yield call(axiosObject, apiUrl);
    if (data) {
      const rows = data.rows;
      yield put(loadHotDays(rows));
    }
  } catch (e) {
    //console.log("[fetchHotDaysSaga] err", e);
  }
}

export default function* watchHotDays() {
  //console.log("[saga] watching hot days requests");
  yield takeEvery(hotDaysConstants.FETCH, fetchHotDaysSaga);
}
