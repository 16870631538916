/* eslint-disable no-undef */
import React, { Component } from 'react'

export default class GoogleAddress extends Component {
    constructor (props) {
        if (!props.apikey) throw new Error('Must supply apikey');
        super(props);
        this.state = {
            location: null
        }
    }

    componentDidMount () {
        const { apikey, value, lang } = this.props;
        loadmaps({key: apikey, lang: lang})
            .then(() => this.initAutocomplete())
            .then(() => this.findPlace(value))
            .catch((err) => console.log(err))
    }

    geolocate () {
        geolocate().then(({
                              latitude: lat,
                              longitude: lng,
                              accuracy: radius
                          }) => {
            if (this.autocomplete) {
                const center = { lat, lng };
                const circle = new google.maps.Circle({ center, radius });
                this.autocomplete.setBounds(circle.getBounds());
            }
            this.setState({ location: [lat, lng] })
        })
            .catch(err => console.log(err))
    }

    findPlace (value) {
        clearTimeout(this.timerId);
        const { timer=300 } = this.props;
        const input = value && value.trim();
        if (input) {
            this.timerId = setTimeout(() => {
                if (typeof this.getPlacePredictions !== "function") {
                    this.initAutocomplete();
                }
                this.getPlacePredictions({ input })
                    .then(first)
                    .then(getPlaceId)
                    .then(this.getPlaceDetails)
                    .then(details => this.handleChange(details))
                    .catch(err => console.log(err))
            }, timer)
        }
    }

    initAutocomplete () {
        if (!this.autocomplete) {
            this.autocomplete = new google.maps.places.Autocomplete(this.target, {types: ['geocode']});
            this.autocomplete.addListener('place_changed', this.fillInAddress.bind(this));
            this.placesService = new google.maps.places.PlacesService(this.target);
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.getPlacePredictions = promiseify(this.autocompleteService.getPredictions.bind(this.autocompleteService));
            this.getPlaceDetails = promiseify(this.placesService.getDetails.bind(this.placesService));
        }
    }

    setTarget (element) {
        if (element) {
            this.target = element
        }
    }

    fillInAddress () {
        const place = this.autocomplete.getPlace();
        this.handleChange(place);
    }

    handleChange (place) {
        if (!place || !place.geometry) return;
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(place)
        } else {
            console.warn('Address changed', place)
        }
    }

    render () {
        const { style, className, value, apikey, timer, ...props } = this.props;
        return <input
            {...props}
            defaultValue={value}
            style={style}
            className={className}
            ref={this.setTarget.bind(this)}
            onChange={ev => this.findPlace(ev.target.value)}
            onFocus={this.geolocate.bind(this)} />
    }
}

function loadmaps (attr) {
    if (window.google) return Promise.resolve();
    if (window.loading_google) {
        return Promise.resolve()
            .then(() => window.loading_google)
    }
    if (typeof attr.libraries === "undefined") {
        attr.libraries = 'places';
    }
    window.loading_google = new Promise(function (res, rej) {
        const script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?' + qs(attr);
        script.onload = (result) => res(result);
        script.onerror = (err) => rej(err);
        document.body.appendChild(script)
    });
    return window.loading_google
}

function geolocate () {
    return new Promise(function (res, rej) {
        navigator.geolocation.getCurrentPosition(function(position, error) {
            if (error) return rej(error);
            res(position.coords)
        })
    })
}

function qs (obj) {
    return Object.keys(obj || {})
        .map(key => key + '=' +obj[key])
        .join('&')
}

function promiseify (fn) {
    return function (...args) {
        return new Promise(function (res) {
            fn(...args, res)
        })
    }
}

function first (arr) {
    return arr[0]
}

function getPlaceId ({ place_id: placeId }) {
    return { placeId }
}

export function loadPlaceDetails ({ apikey }) {
    const autocomplete = loadmaps(apikey)
        .then(() => {
            const element = document.createElement();
            const placesService = new google.maps.places.PlacesService(element);
            const autocompleteService = new google.maps.places.AutocompleteService();
            const getPlacePredictions = promiseify(autocompleteService.getPredictions.bind(autocompleteService));
            const getPlaceDetails = promiseify(placesService.getDetails.bind(placesService));

            return function (input) {
                return getPlacePredictions({ input })
                    .then(first)
                    .then(getPlaceId)
                    .then(getPlaceDetails)
                    .catch(err => {
                        // console.log(err);
                        return null
                    })
            }
        });

    return function (input) {
        return autocomplete
            .then((fn) => fn(input))
    }
}
