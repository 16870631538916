import { takeEvery, put } from "redux-saga/effects";
import axios from "axios";
import getStore from "../../store";
import { ticketConstants } from "../actions/constants";
import { debug, uuidv4 } from "../../../bets/utils";
import moment from "moment";

import {
	ticketOpenedListReceived,
	ticketSettledListReceived,
	ticketReservedListReceived,
	ticketListError,
	ticketListRequesting,
	ticketCheckCodePending,
	ticketCheckCodeData,
	ticketCheckCodeError,
	ticketHandleUpdated
} from "../actions/tickets";
import { getVirtualsState } from "../selectors/virtualsData";

//import preMatchTickets from "./pre-match-tickets.json";
//import preMatchTicketsReserved from "./pre-match-tickets-reserved.json";

import { appSaveErrorLog } from "../../../bets/store/actions/app";

let apiUrl, wsUrl;

if (0 && process.env.NODE_ENV === "development") {
	apiUrl = "http://localhost:3001/betsapi";
	wsUrl = "http://localhost:3001";
} else {
	apiUrl = process.env.CFW.betsApiUrl + "/betsapi";
	wsUrl = process.env.CFW.betsApiUrl;
}

export function* playerApi(requireAuth, method, url, params) {
	const headers = {};

	if (requireAuth) {
		if (requireAuth === true) {
			/*
			const { auth } = getBetsState(getStore().getState());
			//console.log("auth", auth);
			if (auth.details === null) {
				throw Error("not authenticated");
			}

			headers["Authorization"] = auth.details.smToken;
			*/

			const { authentication } = getStore().getState();

			if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
				throw Error("not authenticated");
			}

			headers["Authorization"] = "Bearer " + authentication.access_token;
		} else {
			headers["Authorization"] = requireAuth;
		}

		headers["Environment"] =
			process.env.NODE_ENV === "development" ||
			(window.config.environment && window.config.environment !== "production")
				? "staging"
				: "production";
	}

	if (method === "GET") {
		let qp = {
			headers
		};
		if (params) {
			qp = {
				...qp,
				params
			};
		}
		return yield axios.get(apiUrl + url, qp);
	}

	return yield axios.post(apiUrl + url, params, { headers });
}

/*
export const liveTicketHistoryRequest = (
	status = "OPEN",
	isFullHistoryRequest,
	timeFrom,
	timeTo
) => {
	const state = getStore().getState();
	const bst = getBetsState(state);

	const { auth } = bst;

	if (!auth || !auth.details || !auth.details.accessToken) {
		throw Error("Not fully authenticated");
	}

	const requestUuid = uuidv4();

	const params = {
		requestUuid,
		count: 1000,
		id_language: "ro",
		status,
		isFullHistoryRequest: isFullHistoryRequest ? true : false,
		product: nSoftLive.productName,
		timezone: "Europe/Bucharest"
	};

	if (timeFrom) {
		params["timeFrom"] = timeFrom;
	}

	if (timeTo) {
		params["timeTo"] = timeTo;
	}

	return axios.get(nSoftLive.ticketsUrl + "/web/ticketsHistory/" + nSoftLive.cpvUuid + ".json", {
		params,
		headers: {
			Authorization: "Bearer " + auth.details.accessToken,
			"SEVEN-TP-TOKEN": auth.details.tpToken,
			"SEVEN-LOCALE": "en",
			"HTTP-X-NAB-DP": "Web",
			"HTTP-X-SEVEN-CLUB-UUID": nSoftLive.companyUuid,
			"HTTP-X-NAB-PRODUCTNAME": nSoftLive.productName,
			"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftLive.productInstanceId
		}
	});
};

export const prematchTicketHistoryRequest = (status, timeFrom) => {
	const state = getStore().getState();
	const bst = getBetsState(state);

	const { auth } = bst;

	if (!auth || !auth.details || !auth.details.accessToken) {
		throw Error("Not fully authenticated");
	}

	const requestUuid = uuidv4();

	const params = {
		companyUuid: nSoftPrematch.companyUuid,
		requestUuid,
		count: 1000,
		"filter[resolutionStatus]": status,
		"filter[startDate]": timeFrom,
		timezone: "Europe/Bucharest"
	};

	return axios.get(
		nSoftPrematch.gatewayUrl + "/report/punters/" + auth.details.Uuid + "/ticket-list",
		{
			params,
			headers: {
				Authorization: "Bearer " + auth.details.accessToken,
				"SEVEN-LOCALE": "ro",
				companyUuid: nSoftPrematch.companyUuid
				//"HTTP-X-NAB-DP": "Web",
				//"HTTP-X-SEVEN-CLUB-UUID": nSoftPrematch.companyUuid,
				//"HTTP-X-NAB-PRODUCTNAME": nSoftPrematch.productName,
				//"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftPrematch.productInstanceId
			}
		}
	);
};
*/

export const ticketHistoryRequest = timeFrom => {
	const headers = {};

	const { authentication } = getStore().getState();

	if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
		throw Error("not authenticated");
	}

	headers["Authorization"] = "Bearer " + authentication.access_token;

	headers["Environment"] =
		process.env.NODE_ENV === "development" ||
		(window.config.environment && window.config.environment !== "production")
			? "staging"
			: "production";

	const now = moment()
		.endOf("day")
		.format("YYYY-MM-DD hh:mm:ss");

	return axios.post(
		apiUrl + "/player/tickets/virtuals",
		{
			start: timeFrom,
			end: now
		},
		{ headers }
	);
};

function* handleRequestError(e, requestUuid) {
	yield put(
		appSaveErrorLog(
			JSON.stringify({ requestUuid }),
			"Failed to get open ticket list",
			JSON.stringify(e)
		)
	);
}

const openTicketLimit = 100;

function* ticketOpenedListSaga(action) {
	const state = getStore().getState();
	const vst = getVirtualsState(state);
	yield put(ticketListRequesting({ val: true, key: "opened" }));

	try {
		const { data } = yield playerApi(true, "POST", "/player/tickets/virtuals", {
			status: ["o"],
			offset: (action.data.page - 1) * openTicketLimit,
			limit: openTicketLimit
		});

		yield put(
			ticketOpenedListReceived({
				rows: data,
				page: action.data.page,
				showMore: data.length === openTicketLimit
			})
		);
	} catch (e) {
		//console.log("failed to get ticket list", e);
		yield put(appSaveErrorLog("", "Failed to get virtual open ticket list", JSON.stringify(e)));
		yield put(ticketListRequesting({ val: false, key: "opened" }));
		yield put(ticketListError(e.toString()));
	}
}

const settledTicketLimit = 100;

function* ticketSettledListSaga(action) {
	//console.log("ticketSettledListSaga: action", action);
	const state = getStore().getState();
	const vst = getVirtualsState(state);

	//console.log("bst", bst);
	yield put(ticketListRequesting({ val: true, key: "settled" }));

	try {
		const { data } = yield playerApi(true, "POST", "/player/tickets/virtuals", {
			status: ["w", "l", "p", "wa", "wo"],
			offset: (action.data.page - 1) * settledTicketLimit,
			limit: settledTicketLimit
		});

		yield put(
			ticketSettledListReceived({
				rows: data,
				page: action.data.page,
				showMore: data.length === settledTicketLimit
			})
		);
	} catch (e) {
		//console.log("failed to get ticket list", e);
		yield put(
			appSaveErrorLog("", "Failed to get virtual settled ticket list", JSON.stringify(e))
		);
		yield put(ticketListRequesting({ val: false, key: "settled" }));
		yield put(ticketListError(e.toString()));
	}
}

export default function* watchTicketsSaga() {
	debug("watching virtual tickets");
	yield takeEvery(ticketConstants.OPENED_LIST_REQUEST, ticketOpenedListSaga);
	yield takeEvery(ticketConstants.SETTLED_LIST_REQUEST, ticketSettledListSaga);
}
