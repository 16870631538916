import * as actionTypes from './actionTypes';
import BackendClient from '../../BackendClient';
import * as modalActions from './authModal';
import * as stateActions from './index';
import * as MethodTypes from './../../elements/AuthenticationModal/MethodTypes';

export const changeAuthButton = (newState = 'normal') => {
    return dispatch => {
        dispatch({
            type: ("loading" === newState) ? actionTypes.authButton.LOADING : actionTypes.authButton.NORMAL
        });
    };
};

export const authButtonPressed = (parentType) => {
    return dispatch => {
        let state = BackendClient.getStore().getState();
        // console.info('Next button pressed');

        if ("error" === state.authModal.method) {
            return;
        }
        
        if (!([MethodTypes.METHODS.phone, MethodTypes.METHODS.email].indexOf(state.authModal.method) > -1)) {
            let uValidation = stateActions.validateUsername(state.authModal.username);
            switch (uValidation) {
                case MethodTypes.IS_EMAIL:
                    dispatch(stateActions.setModalMethod(MethodTypes.METHODS.email));
                    return dispatch(authButtonPressed(parentType));
                
                case MethodTypes.IS_PHONE:
                    dispatch(stateActions.setModalMethod(MethodTypes.METHODS.phone));
                    return dispatch(authButtonPressed(parentType));
                
                case MethodTypes.IS_UNDEFINED:
                case MethodTypes.IS_NOT:
                default:
                    return;
            }
        }
        // console.info(`[MODAL] Method is: ${state.authModal.method}`);

        if ('username' === parentType) {
            // check username in state
            let username = state.authModal.username;
            if (null !== username) {
                dispatch(modalActions.changeModalStep('password'));
            }

            return;
        }
        
        if (state.authModal.loading) {
            return;
        }
        // console.info('Modal not loading!');

        if (null == state.authModal.password || !(state.authModal.password.length > 3)) {
            return;
        }
        
        dispatch(stateActions.auth(state.authModal.username, state.authModal.password));
    };
};