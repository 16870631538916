import * as actionTypes from './actionTypes';

export const happyHourBetInfo = data => ({
	type: actionTypes.happyHour.BET_INFO,
	data
});

export const happyHourStatus = data => ({
	type: actionTypes.happyHour.STATUS,
	data
});

export const happyHourStatusCompleted = data => ({
	type: actionTypes.happyHour.STATUS_COMPLETED,
	data
});

export const happyHourPrizeWon = data => ({
	type: actionTypes.happyHour.PRIZE_WON,
	data
});

export const happyHourClearPrizeWon = data => ({
	type: actionTypes.happyHour.CLEAR_PRIZE_WON,
	data
});

export const happyHourClearStatus = data => ({
	type: actionTypes.happyHour.CLEAR_STATUS,
	data
});

export const happyHourClearStatusCompleted = data => ({
	type: actionTypes.happyHour.CLEAR_STATUS_COMPLETED,
	data
});

export const happyHourHideInfo = data => ({
	type: actionTypes.happyHour.HIDE_INFO
});

export const happyHourShowInfo = data => ({
	type: actionTypes.happyHour.SHOW_INFO
});

export const happyHourUpdateStatus = data => ({
	type: actionTypes.happyHour.UPDATE_STATUS,
	data
});
