import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation } from "react-i18next";

import Live from "./Live";
import Prematch from "./Prematch";
import LiveHeader from "./LiveHeader";
import PrematchHeader from "./PrematchHeader";
import BetSelect from "../BetSelect";
import Intervals from "./Intervals";
import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";
import { getBetsState } from "../../store/selectors/betData";
import MatchUK from "../MatchUK";
import { showMatchWithDataSet } from "../../utils/betUtils";
import { ReactComponent as InfoOff } from "../../assets/icons/info-off.svg";
import TError from "../../../tournaments-missions/assets/error.svg";

import {
	makeGetSports,
	makeGetCategories,
	makeGetTournaments,
	makeGetMatchBettingStatus,
	makeGetStateMatch
} from "../../store/selectors/betData";

import { formatTicketBetTitle } from "../../utils/formatters";
import StatsScoreTimeline from "../Timeline/StatsScoreTimeline";
import TopLiveBG from "../../assets/bg-top-live-image.png";
import LockedBets from "./LockedBets";

const useStyles = makeStyles({
	eventWrapper: {
		padding: props => (props.padding ? props.padding : "5px 12px 5px 18px"),
		backgroundColor: props => (props.bgColor ? props.bgColor : props.dark ? "#101320" : "#fff"),
		borderRadius: 7,
		margin: props => (props.margin ? props.margin : "0 0 10px 0"),
		cursor: "pointer",
		"-webkit-tap-highlight-color": "transparent"
	},
	topLiveBG: {
		backgroundImage: `url(${TopLiveBG})`,
		backgroundPosition: "cover",
		backgroundRepeat: "no-repeat",
	},
	dark: {
		backgroundColor: "#070D29",
		color: "#FFFFFF"
	},
	betSelectWrapper: {
		padding: "0px 2px 0px 0px",
		//marginBottom: "4px"
	},
	betsLocked: {
		height: "50px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center"
	},
	lockIcon: {
		color: "#999",
		"& svg": {
			fill: "#999"
		}
	},
	lockText: {
		color: "#999",
		fontSize: "12px"
	},
	betOdd: {
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center",
		justifyContent: "space-between",
		fontSize: "9px",
		fontWeight: "600",
		marginBottom: "4px",
		"& .bet-title": {
			flex: "2 1 auto",
			color: "#979DAB",
			textTransform: "uppercase"
		},
		"& .bet-outcome": {
			color: "#FF196E",
			minWidth: "30px",
			paddingLeft: "10px",
			textAlign: "right"
		},
		"& .bet-value": {
			color: "#17214D",
			minWidth: "30px",
			paddingLeft: "10px",
			textAlign: "right",
			whiteSpace: "nowrap"
		}
	},
	desktopView: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		alignItems: "center",
		"&>div": {
			flex: "1 1 auto"
		},
		"&>div:first-child": {
			paddingRight: "0",
			width: "30%"
		},
		"&>div:last-child": {
			paddingLeft: "10px",
			width: "70%"
		}
	},
	desc: {
		borderTop: "1px solid #EEF0F1",
		margin: "0 -12px 0 -18px",
		padding: "5px 12px 0 18px",
		fontSize: "10px",
		fontStyle: "italic",
		color: "#989898",
		"&.dark": {
			borderTop: "1px solid #1E2751",
		}
	},
	descIconWrapper: {
		cursor: "pointer",
		padding: "10px 20px 0 10px",
		margin: "-10px -10px 0 -10px",
		"&.dark": {
			"& circle": {
				fill: "#1E2751"
			}
		}
	},
	descCollapsed: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis"
	},
	messages: {

	},
	evaluateMessageError: {
		color: "#FF1A6C",
		fontSize: "10px",
		fontWeight: "600",
		marginLeft: "16px",
	},
	evaluateMessageWarning: {
		color: "#F8991D",
		fontSize: "10px",
		fontWeight: "600",
		marginLeft: "16px",
	},
	evaluateBorderWarning: {
		border: "2px solid #F8991D"
	},
	evaluateBorderError: {
		border: "2px solid #E286A7"
	},
	marginFix: {
		margin: "-5px -12px 0 -18px",
		borderTopLeftRadius: "7px",
		borderTopRightRadius: "7px",
		overflow: "hidden"
	},
	tournamentError: {
		display: "flex",
		alignItems: "center",
		borderRadius: "0 0 7px 7px",
		padding: "3px 15px 5px 5px",
		fontStyle: "italic",
		fontSize: 10,
		fontWeight: "normal",
		color: "rgba(0,0,0,.7)",
		background: "#f2f4f7",
		margin: "5px -12px -5px -18px",
		"&>img": {
			width: "24px",
			height: "13px",
			marginRight: 5,
			position: "relative",
			top: 1,
		}
	}
});

const Match = props => {
	const classes = useStyles(props);

	const {
		showMatchDetails,
		layoutType,
		dark,
		simplified,
		oddSelected,
		refDate,
		showFixed,
		inBetSlip,
		fixedOdd,
		disableDetails,
		inGroup,
		showFav,
		className,
		bv,
		rv,
		t,
		matchBettingStatus,
		evaluateMessages,
		evaluateValid,
		matchGroup,
		topLiveModel,
		noStatScore,
		noDescription,
		description,
		idMatch,
		idSport,
		mType,
		statScore,
		matchTitle,
		sportName,
		hasTournamentEvalError,
	} = props;

	let isDesktop = useMediaQuery("(min-width:1400px)");
	if (inBetSlip) isDesktop = false;

	const [showInfo, setShowInfo] = React.useState(false);
	const toggleShowInfo = (e) => {
		if (e && typeof e.stopPropagation === "function") e.stopPropagation();
		setShowInfo(v => !v);
	};

	if (!idMatch) return null;

	if (layoutType === 1 && !topLiveModel) {
		// uk layout
		return <MatchUK idMatch={idMatch} mType={mType} {...props} />;
	}

	let hasDescription = description ? true : false;

	let sport = sportName;
	const title = matchTitle;

	const onMatchDetails = (e) => {
		if (typeof showMatchDetails === "function") {
			showMatchDetails(mType, idMatch);
			return;
		}
		showMatchWithDataSet(e);
	};

	let matchHeader = null;
	let matchBody = null;
	if (mType === "live") {
		matchHeader = <React.Fragment>
			{topLiveModel && <div className={classes.marginFix}>
				<StatsScoreTimeline idMatch={idMatch} idSport={idSport} topLiveModel={true} />
			</div>}
			<LiveHeader
				idMatch={idMatch}
				mType={mType}
				title={title}
				dark={dark}
				disableDetails={disableDetails}
				inGroup={inGroup}
				showFav={showFav}
				noStatScore={noStatScore}
			//topLiveModel={topLiveModel}
			/>
		</React.Fragment>;

		matchBody = <Live
			idMatch={idMatch}
			mType={mType}
			title={title}
			dark={dark}
			disableDetails={disableDetails}
			inGroup={inGroup}
			showFav={showFav}
			inBetSlip={inBetSlip}
		//topLiveModel={topLiveModel}
		/>;
	} else {
		matchHeader = <PrematchHeader
			idMatch={idMatch}
			mType={mType}
			title={title}
			sport={sport}
			dark={dark}
			refDate={refDate}
			disableDetails={disableDetails}
			inGroup={inGroup}
			showFav={showFav}
		/>;

		matchBody = <Prematch
			idMatch={idMatch}
			mType={mType}
			title={title}
			dark={dark}
			refDate={refDate}
			disableDetails={disableDetails}
			inGroup={inGroup}
			showFav={showFav}
			inBetSlip={inBetSlip}
		/>;
	}

	let intervalsBody = null;
	if (simplified === false && mType === "live") {
		intervalsBody = <Intervals idMatch={idMatch} mType={mType} dark={dark} />;
	}

	const rootClass = clsx(
		classes.eventWrapper,
		{
			[classes.dark]: dark,
			"wf-dark-mode": dark,
			"wf-light-mode": !dark,
			"wf-live-match": mType === "live",
			"wf-pre-match": mType !== "live",
		},
		className,
		"match-card-container",
		evaluateMessages && evaluateMessages.length !== 0 ? evaluateValid ? classes.evaluateBorderWarning : classes.evaluateBorderError : ""
	);

	return (
		<div
			className={rootClass}
			data-id={idMatch}
			data-type={mType}
			data-idmatch={idMatch}
			data-mtype={mType}
			data-statscore={statScore}
			onClick={typeof showMatchDetails === "function" ? onMatchDetails : showMatchWithDataSet}
		>
			<div>
				{matchHeader}
			</div>
			<div className={isDesktop && !topLiveModel ? classes.desktopView : ""}>
				<div>
					{matchBody}
					{intervalsBody}
					{simplified === false && !!fixedOdd === true && (
						<div className={classes.betSelectWrapper}>
							<div className={classes.betOdd}>
								<div className="bet-title">
									{formatTicketBetTitle(fixedOdd.bet)}
								</div>
								<div className="bet-outcome">
									{fixedOdd.betOutcome.betOutcomeDisplayName}
								</div>
								<div className="bet-value">{fixedOdd.oddValue}</div>
							</div>
						</div>
					)}
				</div>
				<div>
					{simplified === false && (
						<div className={classes.betSelectWrapper}>
							{!!fixedOdd === false && matchBettingStatus && !props.hasError && (
								<BetSelect
									idMatch={idMatch}
									mType={mType}
									matchGroup={matchGroup ? matchGroup : null}
									//matchGroup={matchGroup && (matchGroup.indexOf("_wa") > -1 || matchGroup.indexOf("_wp") > -1) ? matchGroup : null}
									dark={dark}
									oddSelected={oddSelected}
									showFixed={showFixed}
									betSlip={inBetSlip}
									topLiveModel={topLiveModel}
									bv={bv}
									rv={rv}
								/>
							)}
							{!!fixedOdd === false && !matchBettingStatus && <LockedBets dark={dark} />}
						</div>
					)}
				</div>
				{evaluateMessages && evaluateMessages.length !== 0 && <div className={classes.messages}>
					{
						evaluateMessages.map((m, i) => (<div className={evaluateValid ? classes.evaluateMessageWarning : classes.evaluateMessageError} key={i}>{m}</div>))
					}
				</div>}
			</div>
			{(!noDescription && hasDescription) && <div className={`d-flex flex-row align-items-start ${classes.desc} ${dark ? 'dark' : ''}`}>
				<div
					className={`${classes.descIconWrapper} ${dark ? 'dark' : ''}`}
					onClick={toggleShowInfo}
				>
					<InfoOff />
				</div>
				<div className={`w100 ${!showInfo ? '' : ''} text-left`}>{description}</div>
			</div>}
			{hasTournamentEvalError && <div className={classes.tournamentError}>
				<img src={TError} alt='' />
				{t("This bet does not contribute points to the tournament")}
			</div>}
		</div>
	);
};

//Match.whyDidYouRender = true;

Match.propTypes = {
	simplified: PropTypes.bool,
	oddSelected: PropTypes.string,
	rv: PropTypes.number
};

Match.defaultProps = {
	simplified: false,
	oddSelected: "",
	rv: null
};

const getSports = makeGetSports();
const getCategories = makeGetCategories();
const getTournaments = makeGetTournaments();
const getMatchBettingStatus = makeGetMatchBettingStatus();
const getStateMatch = makeGetStateMatch();

const mapStateToProps = (state, props) => {
	const bst = getBetsState(state);

	const sports = getSports(state, props);
	const categories = getCategories(state, props);
	const tournaments = getTournaments(state, props);
	const stateMatch = getStateMatch(state, props);

	if (!stateMatch) {
		return {
			idMatch: null,
		};
	}

	const titleParts = [];
	let sport = "";
	if (
		sports &&
		typeof sports[stateMatch.idSport] !== "undefined" &&
		sports[stateMatch.idSport].sportName
	) {
		titleParts.push(sports[stateMatch.idSport].sportName);
		sport = sports[stateMatch.idSport].sportName;
	}
	if (
		categories &&
		typeof categories[stateMatch.idCategory] !== "undefined" &&
		categories[stateMatch.idCategory].categoryName
	)
		titleParts.push(categories[stateMatch.idCategory].categoryName);
	if (
		tournaments &&
		typeof tournaments[stateMatch.idTournament] !== "undefined" &&
		tournaments[stateMatch.idTournament].tournamentName
	)
		titleParts.push(tournaments[stateMatch.idTournament].tournamentName);

	return {
		matchTitle: titleParts.join(" / "),
		sportName: sport,
		layoutType: bst.layout.layoutType,
		matchBettingStatus: getMatchBettingStatus(state, props),
		description: stateMatch.description,
		idCategory: stateMatch.idCategory,
		idMatch: stateMatch.idMatch,
		idSport: stateMatch.idSport,
		idTournament: stateMatch.idTournament,
		mType: stateMatch.mType,
		statScore: stateMatch.statScore,
	};
};

Match.displayName = "Match";

export default withTranslation()(connect(mapStateToProps)(Match));
