import React from "react";
// import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter } from "react-router-dom";

import LeagueDetails from "../views/LeagueDetails";
//import MyTickets from "../views/MyTickets";
import MatchDetails from "../views/MatchDetails";
import Filter from "../views/Filter";
import FilterLeague from "../views/FilterLeague";

import { searchUpdateLeague } from "../store/actions/search";
import { searchUpdate } from "../store/actions/search";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0"
    }
  },
  h100vh: {
    height: "100vh"
  },
  rootFilters: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0"
    },
    "&.MuiDialog-root": {
      zIndex: "1303!important"
    }
  },
  minHeight: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "1024px"
    }
  },
  minHeightSmall: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "612px"
    }
  }
});

const noLeague = {
  dataType: "",
  idSport: "",
  idCategory: "",
  idTournament: ""
};

const noMatch = {
  dataType: "",
  idMatch: "",
  statScore: false,
};

const clearHash = () => {
  if (window.location.href[window.location.href.length - 1] === "#") {
    window.history.replaceState({}, "", window.location.href.slice(0, -1));
  }
};

let lastScroll = 0;

function Modals({ searchUpdate, searchUpdateLeague, location }) {
  const classes = useStyles();

  const isDesktop = useMediaQuery("(min-width:1281px)");

  const [modalStates, setModalStates] = React.useState({
    league: false,
    tickets: false,
    filter: false,
    match: false,
    filterLeague: false
  });
  const [lItem, setLItem] = React.useState(noLeague);
  const [mItem, setMItem] = React.useState(noMatch);
  const [activeModal, setActiveModal] = React.useState("");

  const handleClose = () => {
    window.location.hash = "";
  };

  const handleStateChange = (type, value) => {
    const newModalStates = { ...modalStates };
    if (type) {
      newModalStates[type] = value;
    }
    if (value === false) {
      searchUpdate("");
      searchUpdateLeague("");
    }
    setModalStates(newModalStates);
  };
  const handleActiveModalChange = modal => {
    if (modal === "") {
      handleStateChange(activeModal, false);
    } else {
      handleStateChange(modal, true);
    }
    setActiveModal(modal);
  };

  const handleHashChange = () => {
    let newHash = window.location.hash;

    if (newHash.indexOf("#league-details-") > -1) {
      const dtAndId = newHash.replace("#league-details-", "");
      const dai = dtAndId.split("-");
      if (dai.length === 5) {
        setLItem({
          dataType: dai[0],
          idSport: dai[1],
          idCategory: dai[2],
          idTournament: dai[3]
        });
        lastScroll = dai[5];
      } else {
        setLItem(noLeague);
      }
      newHash = "#league-details";
    } else {
      setLItem(noLeague);
    }

    let dtAndId = "";
    let dai = [];
    if (newHash.indexOf("#match-details-") > -1) {
      dtAndId = newHash.replace("#match-details-", "");
      dai = dtAndId.split("-");

      if (dai.length >= 3) {
        setMItem({
          dataType: dai[0],
          idMatch: dai[1],
          statScore: dai[3] === "1" ? true : false,
        });
        lastScroll = dai[2];
      } else {
        setMItem(noMatch);
      }
      newHash = "#match-details";
    } else {
      setMItem(noMatch);
    }

    switch (newHash) {
      case "#league-details":
        handleActiveModalChange("league");
        break;
      case "#tickets-details-disabled":
        handleActiveModalChange("tickets");
        break;
      case "#filter":
        handleActiveModalChange("filter");
        break;
      case "#match-details": {
        handleActiveModalChange("match");
        break;
      }
      case "#filter-league":
        handleActiveModalChange("filterLeague");
        break;
      default: {
        let isNumber = false;
        try {
          let val = parseInt(newHash.substr(1), 10);
          if (val) isNumber = true;
        } catch (err) { }

        if (newHash !== "#tickets-details" && !isNumber) {
          handleActiveModalChange("");
          clearHash();
        }
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("hashchange", handleHashChange, false);
    handleHashChange();
    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (!window.location.hash) {
      handleActiveModalChange("");
    }
  }, [location.pathname]);

  const fullScreen = isDesktop ? false : true;

  const root = document.body;

  if (modalStates.league || modalStates.match) {
    if (!window.config.cordova && false) {
      root.style.position = "fixed";
      root.style.top = `-${lastScroll}px`;
      root.style.bottom = "0";
      root.style.left = "0";
      root.style.right = "0";
    }
    root.classList.add("no-scroll-modals");
  } else {
    if (root.classList.contains("no-scroll-modals")) {
      if (!window.config.cordova && false) {
        root.style.position = "";
        root.style.top = "";
        root.style.bottom = "";
        root.style.left = "";
        root.style.right = "";
      }
      document.documentElement.scrollTop = lastScroll;
      root.classList.remove("no-scroll-modals");
    }
  }

  if (!modalStates.league && !modalStates.match && !modalStates.filter && !modalStates.filterLeague)
    return null;

  return (
    <div className="modalsBets" key="modalsBets">
      <Dialog
        fullScreen={fullScreen}
        disableEnforceFocus
        open={modalStates.league}
        TransitionComponent={Transition}
        maxWidth={!fullScreen ? "lg" : false}
        className={`${classes.root} ${isDesktop ? classes.minHeight : `${classes.h100vh} mobile-league`
          } betsModal`}
      >
        <LeagueDetails
          mType={lItem.dataType}
          idSport={lItem.idSport}
          idCategory={lItem.idCategory}
          idTournament={lItem.idTournament}
        />
      </Dialog>
      {/*
      <Dialog
        fullScreen={fullScreen}
        disableEnforceFocus
        open={modalStates.tickets}
        TransitionComponent={Transition}
        maxWidth={!fullScreen ? "lg" : false}
        className={`${classes.rootFilters} ${isDesktop ? classes.minHeightSmall : `${classes.h100vh} `} betsModal`}
      >
        <MyTickets />
      </Dialog>
      */}
      <Dialog
        fullScreen={fullScreen}
        disableEnforceFocus
        open={modalStates.match}
        TransitionComponent={Transition}
        maxWidth={!fullScreen ? "lg" : false}
        className={`${classes.root} ${isDesktop ? classes.minHeight : `match`} betsModal match-details`}
        onClose={handleClose}
      >
        <MatchDetails mType={mItem.dataType} idMatch={mItem.idMatch} forceStatScore={mItem.statScore} fullScreen={fullScreen} />
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        disableEnforceFocus
        open={modalStates.filter}
        TransitionComponent={Transition}
        maxWidth={!fullScreen ? "lg" : false}
        className={`${classes.rootFilters} ${isDesktop ? classes.minHeight : `${classes.h100vh} `
          } betsModal`}
      >
        <Filter />
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        disableEnforceFocus
        open={modalStates.filterLeague}
        TransitionComponent={Transition}
        maxWidth={!fullScreen ? "lg" : false}
        className={`${classes.rootFilters} ${isDesktop ? classes.minHeight : `${classes.h100vh} `
          } betsModal`}
      >
        <FilterLeague />
      </Dialog>
    </div>
  );
}

Modals.propTypes = {};

Modals.defaultProps = {};

const actionCreators = {
  searchUpdate: searchUpdate,
  searchUpdateLeague: searchUpdateLeague
};

export default connect(null, actionCreators)(withRouter(Modals));
