import { makeStyles } from "@material-ui/core/styles";
import isFunction from "lodash/fp/isFunction";

const commonStyles = makeStyles({
	scrollable: {
		overflowX: "auto",
		overflowY: "hidden",
		msOverflowStyle: "none",
		overflow: "-moz-scrollbars-none",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollSnapType: "both proximity",
		scrollPadding: 0
	},
	scrollableItem: {
		scrollSnapAlign: "start"
	},
	horizontalFlex: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "flex-start",
		alignItems: "center",
		alignContent: "center"
	}
});

const mergeStyles = (...sf) => {
	let styles = {};

	for (let len = sf.length, key = 0; key < len; key++) {
		if (isFunction(sf[key])) {
			styles = Object.assign(styles, sf[key]());
		} else {
			styles = Object.assign(styles, sf[key]);
		}
	}

	return styles;
};

const withCommonStyles = (...sf) => {
	return mergeStyles(commonStyles, ...sf);
};

export { commonStyles, mergeStyles, withCommonStyles };
