import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Delete from "./assets/delete.svg";

const useStyles = makeStyles({
  dialogRoot: {
    zIndex: "1301!important",
    "& .MuiPaper-rounded": {
      borderRadius: "13px"
    }
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#FF196E",
    padding: "34px 0 16px",
    "& img": {
      width: "27px",
      height: "34px"
    }
  },
  dialogContent: {
    fontSize: "12px",
    color: "#17214D",
    fontWeight: "bold",
    textAlign: "center"
  },
  dialogActions: {
    padding: "60px 16px 40px",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dialogNoAction: {
    backgroundColor: "#16214c",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#111a3b",
    }
  },
  dialogYesAction: {
    backgroundColor: "#fb1a6e",
    color: "#fff",
    height: "45px",
    width: "128px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#e20355",
    }
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteModal = (props) => {
  const { open, handleClose, handleYesClose, t } = props;
  const classes = useStyles();
  if (!open) return null;
  return (<Dialog
    open={open}
    fullWidth={true}
    maxWidth={"xs"}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    className={classes.dialogRoot}
  >
    <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">
      <img src={Delete} alt="" />
      <div>{t("Delete Ticket?")}</div>
    </DialogTitle>
    <DialogContent className={classes.dialogContent}>
      <div>{t("The ticket selection will be deleted.")}</div>
      <div>{t("Are you sure?")}</div>
    </DialogContent>
    <DialogActions className={classes.dialogActions}>
      <Button onClick={handleClose} className={classes.dialogNoAction}>
        {t("NO")}
      </Button>
      <Button onClick={handleYesClose} className={classes.dialogYesAction}>
        {t("YES")}
      </Button>
    </DialogActions>
  </Dialog>
  );
}

export default withTranslation()(DeleteModal);