import {put, takeEvery} from "redux-saga/effects";
import {bonusAvailable, bonuses, eligible_bonuses} from "../actions/actionTypes";
import RequestManager from "../../utils/RequestManager";
import * as actions from "../../../src/store/actions";

function* callback_getBonusAvailable(response,id) {
    try {
        if (!response.status) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES message not sent!`);
        }

    } catch (error) {
        yield put(actions.requestFailed(id))
    }

}

export function* getBonusAvailableSaga(action) {
    // const axios = yield Utils.getAxiosWithToken();

    try {
        const response = yield RequestManager.request("get-bonus-available",{
            url: '/api/casino/getBonusAvailable',
            method: 'post',
            data: {
                platform: window.config.platformType
            }
        },callback_getBonusAvailable);

    } catch (error) {
        return;
    }
}

export function* getEligibleBonuses(action) {
    let paymentMethods = [];
    if (action.paymentMethods && action.paymentMethods.length > 0) {
        paymentMethods = action.paymentMethods;
    } else {
        paymentMethods.push(2); // safecharge
        if (window.config && window.config.vivaDesktopEnabled === '1') {
            paymentMethods.push(3); // viva
        }

        if (window.config && window.config.okto_enabled === '1') {
            paymentMethods.push(4); // okto
        }
    }

    let requestId = 'get-eligible-bonuses';
    if (paymentMethods.length > 0) {
        requestId += '-' + paymentMethods.join('-');
    }


    yield RequestManager.request(requestId, {
        url: '/api/casino/getBonuses',
        method: 'post',
        data: {
            platform: window.config.platformType,
            paymentMethodList: paymentMethods
        }
    },callback_getBonusAvailable);
}

export function* getBonuses(action) {

    let data = action.data;
    data.platform = window.config.platformType;

    yield RequestManager.request('get-bonuses', {
        url: '/api/casino/getPlayerBonuses',
        method: 'post',
        data: data
    },callback_getBonusAvailable);
}




export default function* watchBonusAvailableSaga() {
    yield takeEvery(bonusAvailable.GET, getBonusAvailableSaga);
    yield takeEvery(eligible_bonuses.GET, getEligibleBonuses);
    yield takeEvery(bonuses.GET, getBonuses)
}