import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import "./providers.scss";

import { getText, styleToObj, openItem, checkItemAvailability, getIndexById } from "../../utils/functions";
import { ReactComponent as LobbyArrow } from "../../assets/arrow.svg";

const empty = {};

const Providers = (props) => {
  const { data, i18n, source } = props;

  const lang = i18n.language;

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (data.data_source && source.length) {
      setItems(source);
    }
  }, [data.data_source, source]);

  if (items.length === 0) return null;

  const filteredItems = items.filter(item => (checkItemAvailability(item)));
  if (filteredItems.length === 0) return null;

  const hasNavigate = data && data.navigate && data.navigate.text && (data.navigate.text.ro || data.navigate.text.en) && data.navigate.url;

  return (
    <div className={`component-providers component`}
      style={styleToObj(data.style)}
    >

      <div className="cgl-header">
        <div className="title" style={styleToObj(data.title.style)}>
          {getText(data, `title.text.${lang}`, "")}
        </div>
        {hasNavigate && <div className="link"
          style={styleToObj(data.navigate.style)}
          data-type={"navigate"}
          data-item={JSON.stringify({ url: data && data.navigate && data.navigate.url ? data.navigate.url : "" })}
          onClick={openItem}
        >
          {getText(data, `navigate.text.${lang}`, "")}
          {filteredItems.length !== 0 && <span>({filteredItems.length})</span>}
          <LobbyArrow />
        </div>}
      </div>

      <div className="cgl-list-wrapper">
        <div className="cgl-list" style={styleToObj(data.data_source_style)}>
          <div className={`cgl-grid ${data.thumbnail_size === "large" ? "large" : ""}`}>
            {filteredItems.map((item, i) => {
              const realIndex = getIndexById(items, item.id);
              let itemStyle = typeof data.data_source_items[realIndex.toString()] !== "undefined" ? styleToObj(data.data_source_items[realIndex].style) : empty;
              itemStyle = Object.assign({ minWidth: data.card_width }, itemStyle);
              const outerStyle = { paddingTop: `${data.card_height}%` };

              return <div
                key={i}
                className={`cgl-item ${item.isEmpty ? "empty" : ""}`}
                style={itemStyle}
                data-type={"navigate"}
                data-item={JSON.stringify({ url: item.data && item.data.url ? item.data.url : "" })}
                onClick={openItem}
              >
                <div className="outer" style={outerStyle}>
                  <div className="inner">
                    <div className="image-wrapper">
                      <div className="image-holder">
                        {!!item.data.thumbnail && <img src={item.data.thumbnail} alt="" />}
                      </div>
                    </div>
                    <div className="provider-name">
                      {getText(item, `data.title.text.${lang}`, "")}
                    </div>
                  </div>
                </div>
              </div>;
            })}
          </div>
        </div>
      </div>

      <div className="cgl-footer" style={styleToObj(data.footer.style)}>
        {getText(data, `footer.text.${lang}`, "")}
      </div>

    </div>
  );
}

const mapStateToProps = (state, props) => {
  let source = [];
  let dsId = props.data && props.data.data_source && props.data.data_source.id ? props.data.data_source.id : null;
  if (dsId) {
    if (typeof state.config.lobbyDataSources[dsId] !== "undefined") {
      source = state.config.lobbyDataSources[dsId].meta ? state.config.lobbyDataSources[dsId].meta : [];
    }
  }

  return {
    source: source,
  }
};

export default withTranslation()(connect(mapStateToProps)(Providers));