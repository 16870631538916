import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { getBetsState } from "../../store/selectors/betData";

import { searchUpdate } from "../../store/actions/search";

import { ReactComponent as BackIcon } from "./assets/arrow.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",
    fontFamily: "OpenSans",
    height: "56px"
  },
  back: {
    padding: "24px 23px",
    fill: "#fff"
  },
  backSvg: {
    height: "17px",
    width: "auto"
  },
  input: {
    position: "relative",
    padding: "0 20px 0 0",
    "& input": {
      border: "1px solid rgba(0,158,217,.35)",
      backgroundColor: "#070D29",
      color: "#FFFFFF",
      fontFamily: "OpenSans",
      fontSize: "16px",
      borderRadius: "10px",
      padding: "6px 20px 6px 10px",
      height: "36px",
      width: "100%"
    },
    "& input:focus": {
      outline: "none",
      border: "1px solid rgba(0,158,217,.75)"
    }
  },
  searchIcon: {
    display: "block",
    fill: "#fff",
    position: "absolute",
    right: "25px",
    top: "5px"
  },
  button: {
    padding: "5px"
  },
  delete: {
    borderRadius: "50%",
    backgroundColor: "#14259B",
    width: "30px",
    height: "30px",
    fontSize: "12px",
    fontWeight: "600"
  },
  move: {
    right: "25px",
    top: "3px"
  },
  dn: {
    display: "none"
  }
});

const FilterHeader = props => {
  const classes = useStyles();
  const { goBack, query, searchUpdate } = props; // eslint-disable-line

  const [value, setValue] = React.useState(query);
  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleSubmit = event => {
    searchUpdate(value);
    event.preventDefault();
  };

  const handleClear = event => {
    setValue("");
    searchUpdate("");
  };

  let actionIcon = (
    <div className={classes.searchIcon}>
      <ButtonBase className={classes.button} onClick={handleSubmit}>
        <SearchIcon />
      </ButtonBase>
    </div>
  );
  if (value !== "") {
    actionIcon = (
      <div className={`${classes.searchIcon} ${classes.move}`}>
        <ButtonBase className={`${classes.button} ${classes.delete}`} onClick={handleClear}>
          X
        </ButtonBase>
      </div>
    );
  }

  return (
    <div
      className={`${classes.root} d-flex flex-row flex-nowrap align-items-center justify-content-between search-modal-top`}
    >
      <div>
        <ButtonBase className={classes.back} onClick={goBack}>
          <BackIcon className={classes.backSvg} />
        </ButtonBase>
      </div>
      <div className={`${classes.input} flex-grow-1`}>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={value}
            onChange={handleChange}
            placeholder="Cauta Ligi, Echipe, Jucatori"
          />
          {actionIcon}
          <input type="submit" value="Submit" className={classes.dn} />
        </form>
      </div>
    </div>
  );
};

FilterHeader.propTypes = {
  query: PropTypes.string,
  goBack: PropTypes.func,
  searchUpdate: PropTypes.func
};

FilterHeader.defaultProps = {
  query: "",
  goBack: () => { }
};

const mapStateToProps = state => {
  const bst = getBetsState(state);
  return {
    query: bst.search.query
  };
};

const actionCreators = {
  searchUpdate: searchUpdate
};

export default connect(mapStateToProps, actionCreators)(FilterHeader);
