import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import isArray from "lodash/fp/isArray";
import LockIcon from '@material-ui/icons/Lock';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import {
  getBetsState,
  getLiveSportInfoRetail,
  makeGetCategories,
  makeGetTournaments,
  makeGetBets,
  getLiveMatchData
} from "../../store/selectors/betData";
import SearchLive from "../../components/search-live";
import { betsSlipToggle } from "../../../bets/store/actions/betsSlip";
import { getBaseRouterPath } from "../../utils/config";
import { formatOddValue, formatOddName, formatBetTitle } from "../../utils/formatters";
import { stdLiveScore } from "../../../bets/utils/matchUtils";
import { sortArrayByKey2 } from "../../../bets/utils";
import BetFilter from "../../components/bet-filter";
import { getMatchMarketGroups } from "../../../bets/utils/matchUtils";
import Icons from "../../components/icons";
import { ReactComponent as CornerIcon } from "../../assets/corner.svg";
import { ReactComponent as YellowCardIcon } from "../../assets/yellow-card.svg";
import { ReactComponent as RedCardIcon } from "../../assets/red-card.svg";

const Event = (props) => {
  //console.log("Event", props);
  const { matchData, match, categories, tournaments, history, betAbbr, betDesc, bets, selected, toggleBet, t } = props;

  const [filter, setFilter] = React.useState("");
  const [matchBets, setMatchBets] = React.useState([]);
  const [allMatchBets, setAllMatchBets] = React.useState([]); // eslint-disable-line
  const [favoriteBets, setFavoriteBets] = React.useState({}); // eslint-disable-line
  const [hasDescription, setHasDescription] = React.useState({}); // eslint-disable-line

  const [marketGroups, setMarketGroups] = React.useState([]);
  const [selectedMarketGroup, setSelectedMarketGroup] = React.useState(null);

  const handleMarketGroupsSelections = (i) => {
    setSelectedMarketGroup(marketGroups[i]);
  };

  const [params, setParams] = React.useState({
    idSport: match.params.idSport,
    idCategory: match.params.idCategory,
    idTournament: match.params.idTournament,
    idMatch: match.params.idMatch,
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setParams({
      idSport: match.params.idSport,
      idCategory: match.params.idCategory,
      idTournament: match.params.idTournament,
      idMatch: match.params.idMatch,
    });
  }, [match.params]);

  const handleBetClick = (idSport, idMatch, idBet, idMb, idBo, idMbo) => () => {
    toggleBet(matchData.mType, idSport, idMatch, idBet, idMb, idBo, idMbo, {
      ...matchData
    });
  };

  React.useEffect(() => {
    //console.log("Recalc bets");
    let mb = matchData && matchData.matchBets ? [...matchData.matchBets] : [];

    if (matchData) {
      const mkg = getMatchMarketGroups(matchData);
      setMarketGroups(mkg);

      if (mkg.length > 0) {
        if (selectedMarketGroup === null) {
          setSelectedMarketGroup(mkg[0]);
        } else {
          const gIdx = mkg.findIndex(g => g.id === selectedMarketGroup.id);
          if (gIdx === -1) {
            setSelectedMarketGroup(mkg[0]);
          } else {
            setSelectedMarketGroup(mkg[gIdx]);
          }
        }
      } else {
        setSelectedMarketGroup(null);
      }

      // sort bets by NSoft order
      sortArrayByKey2(mb, "mbPosition", "mbSpecialValue");

      // load favorite bets
      const fb = localStorage.getItem("mdFavoriteBets_" + matchData.idSport);
      if (fb !== null) {
        const jfb = JSON.parse(fb);
        setFavoriteBets(jfb);
      }

      // load bets order
      let bo = [];
      const jbo = localStorage.getItem("mdBetsOrder_" + matchData.mType + "_" + matchData.idSport);
      if (jbo !== null) {
        bo = JSON.parse(jbo);
      }

      // extract from matchData bets those we need to order (put first)
      let smb = [];

      bo.forEach(id => {
        let idx = mb.findIndex(b => b.idBet === id);
        if (idx !== -1) {
          smb.push(mb[idx]);
          mb.splice(idx, 1);
        }
      });

      // rebuild bets list
      mb = [...smb, ...mb];

      let idx = 0;

      // put winner avantaj or plus at the top
      if ((idx = mb.findIndex(b => b.winnerPlus || b.winnerAdv)) !== -1) {
        const b = mb.splice(idx, 1);
        mb.unshift(b[0]);
      }

      const hDescription = {};
      const descriptions = betDesc[matchData.mType];
      mb.forEach(m => {
        const title = formatBetTitle(m, matchData, bets);
        if (title && typeof descriptions[title.toLowerCase()] !== "undefined") {
          hDescription[m.idBet] = descriptions[title.toLowerCase()];
        }
      });

      setAllMatchBets(mb);
      setMatchBets(mb);
      setHasDescription(hDescription);
    }
  }, [matchData]); // eslint-disable-line


  const handleSearchChange = (type, val) => {
    if (type === "tab" && val === 0) {
      history.push(`${getBaseRouterPath()}/live`);
      return;
    }

    if (type === "filter") {
      setFilter(v => {
        if (v !== val) {
          return val;
        }
        return v;
      });
    }
  };

  if (!matchData) return (
    <div className="content-wrap has-left-nav has-sidebar sport-page">
      <SearchLive currentIndex={1} filter={filter} onChange={handleSearchChange} />
      <div className="elements">
        <div className="empty">{t("Currently there is no match for selected parameters!")}</div>
      </div>
    </div>
  );

  let categoryName = "";
  let tournamentName = "";
  if (typeof categories[params.idCategory] !== "undefined") {
    categoryName = categories[params.idCategory].categoryName;
  }
  if (typeof tournaments[params.idTournament] !== "undefined") {
    tournamentName = tournaments[params.idTournament].tournamentName;
  }
  const scoreData = stdLiveScore(matchData, matchData.currentStatus);

  const serving = scoreData.teamServing;
  const winning = scoreData.scoreNoFormatData.currentScore[0] > scoreData.scoreNoFormatData.currentScore[1] ? 1 :
    scoreData.scoreNoFormatData.currentScore[0] < scoreData.scoreNoFormatData.currentScore[1] ? 2 : 0;

  const inverseSet = (set) => {
    if (/^(\d+)[RS]$/.test(set)) return set.split("").reverse().join("");
    return set;
  }

  let filteredBets = matchBets;
  if (selectedMarketGroup) {
    if (!selectedMarketGroup.isAll) {
      filteredBets = matchBets.filter(mb => selectedMarketGroup.matchBets.indexOf(mb.idMb) > -1);
    }
  }

  return (
    <div className="content-wrap has-left-nav has-sidebar live sport-page">

      <SearchLive currentIndex={1} filter={filter} onChange={handleSearchChange} className="live" />

      <div className="elements">
        <div className={`match-results sport-color-${params.idSport}`}>
          <div className="match-stats">
            <div className="match-tournament d-top">
              <div className="match-tournament-name">
                <div className="match-time">
                  <div className="match-time-current">{scoreData.intervalName} {scoreData.intervalTime}</div>
                </div>
                <span>{categoryName}</span>: <span>{tournamentName}</span>
              </div>
            </div>
            <div className="match-tournament">
              <div className="match-tournament-name">
                <div className="d-bottom">
                  <div className="match-time">
                    <div className="match-time-current">{scoreData.intervalName} {scoreData.intervalTime}</div>
                  </div>
                  <span>{categoryName}</span>: <span>{tournamentName}</span>
                </div>
              </div>
              <div className={`match-team-col match-team-score`}>{Icons.get(matchData.idSport, "", "live")}</div>
              {scoreData.scoreNoFormatData.halfNames.map((h, i) => {
                let cnt = inverseSet(h);
                if (h === "CORNERS") {
                  cnt = <CornerIcon />;
                } else if (h === "YELLOW-CARDS") {
                  cnt = <YellowCardIcon />;
                } else if (h === "RED-CARDS") {
                  cnt = <RedCardIcon />;
                }
                let active = false;
                if (cnt === scoreData.intervalName) {
                  active = true;
                } else if (cnt === "P") {
                  active = true;
                }
                return (<div key={i} className={`match-team-col match-team-stat ${active ? 'active' : ''}`}>{cnt}</div>);
              })}
            </div>
            <div className="match-teams match-teams-time">
              <div className="match-team">
                <div className="match-team-row clearfix">
                  <div className="match-team-col match-team-name">
                    <span>{matchData.team1Name}</span>
                    {serving === 1 && <div className={`serving-dot`}>
                      <svg><circle cx="5" cy="5" r="3" fill="yellow" /></svg>
                    </div>}
                  </div>
                  <div className={`match-team-col match-team-score ${winning === 1 ? 'winning' : winning === 2 ? 'loosing' : ''}`}>
                    {scoreData.scoreNoFormatData.currentScore[0]}
                  </div>
                  {scoreData.scoreNoFormatData.score.map((s, i) => {
                    let active = false;
                    if (inverseSet(scoreData.scoreNoFormatData.halfNames[i]) === scoreData.intervalName) {
                      active = true;
                    } else if (scoreData.scoreNoFormatData.halfNames[i] === "P") {
                      active = true;
                    }
                    return (<div key={i} className={`match-team-col match-team-stat ${active ? 'active' : ''}`}>{s[0]}</div>)
                  })}
                </div>
              </div>
              <div className="match-team">
                <div className="match-team-row clearfix">
                  <div className="match-team-col match-team-name">
                    <span>{matchData.team2Name}</span>
                    {serving === 2 && <div className={`serving-dot`}>
                      <svg><circle cx="5" cy="5" r="3" fill="yellow" /></svg>
                    </div>}
                  </div>
                  <div className={`match-team-col match-team-score  ${winning === 2 ? 'winning' : winning === 1 ? 'loosing' : ''}`}>
                    {scoreData.scoreNoFormatData.currentScore[1]}
                  </div>
                  {scoreData.scoreNoFormatData.score.map((s, i) => {
                    let active = false;
                    if (inverseSet(scoreData.scoreNoFormatData.halfNames[i]) === scoreData.intervalName) {
                      active = true;
                    } else if (scoreData.scoreNoFormatData.halfNames[i] === "P") {
                      active = true;
                    }
                    return (<div key={i} className={`match-team-col match-team-stat ${active ? 'active' : ''}`}>{s[1]}</div>)
                  })}

                </div>
              </div>
            </div>
          </div>
        </div>

        <BetFilter
          marketGroups={marketGroups}
          onChange={handleMarketGroupsSelections}
          className="live"
        />

        {matchData && matchData.bettingStatus && (
          <React.Fragment>
            {filteredBets.map((mb, listIndex) => {


              const outcomes = [...mb.mbOutcomes];
              sortArrayByKey2(outcomes, "mboPosition", "mbSpecialValue");

              // don't show inactive odss and/or markets
              let hasActive = false;
              outcomes.forEach((bo, i) => {
                if (bo.mboActive) {
                  hasActive = true;
                }
              });
              if (!hasActive) return null;
              if (!mb.mbActive) return null;

              const len = outcomes.length;
              const title = formatBetTitle(mb, matchData, bets);
              if (filter && title.toLowerCase().indexOf(filter.toLowerCase()) === -1) return null;

              return (
                <div className="bets-list" key={listIndex}>
                  <div className="bets-header">{title}</div>
                  <div className={`odds-container ${len > 2 && len !== 4 ? 'bets-three' : ''} clearfix`}>
                    {outcomes.map((bet, i) => {
                      let mboActive = bet.mboActive;

                      if (!mboActive) return null;

                      const type = formatOddName(mb.idBet, bet, matchData, bets);
                      const value = formatOddValue(bet.mboOddValue);
                      let active = false;
                      if (selected.find(e => e.idMbo === bet.idMbo)) {
                        active = true;
                      }

                      const changeDir = bet.changeDir;

                      return (
                        <div key={i}
                          disabled={!mboActive}
                          className={`button-wrapper ${active ? 'active' : ''} outcome`}
                          onClick={mboActive ? handleBetClick(params.idSport, params.idMatch, mb.idBet, mb.idMb, bet.idBo, bet.idMbo) : () => { }}>
                          <div key={`odd_${bet.idMbo}_${bet.mboType}_${bet.mboOddValue}_${bet.changeDir}`} className={`button ${changeDir ? `change-${changeDir}` : ''}`}>
                            <span className="type">{type}</span>
                            {mboActive && <span className="value">{value}</span>}
                            {!mboActive && <span className="value"><LockIcon /></span>}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>);
            })}
            {matchBets.length === 0 && <div className="empty">
              <LockOutlinedIcon />
              {t("Currently there are no bets available for this match! Stay tuned.")}
            </div>
            }
          </React.Fragment>
        )}
        {matchData && !matchData.bettingStatus && (
          <div className="empty">
            <LockOutlinedIcon />
            {t("Currently there are no bets available for this match! Stay tuned.")}
          </div>
        )}
      </div>
      <div className="spc"></div>
    </div>
  );
}

const mapStateToProps = () => {
  const getBets = makeGetBets();
  const getCategories = makeGetCategories();
  const getTournaments = makeGetTournaments();

  return (state, props) => {
    const bst = getBetsState(state);
    const init = {
      mType: "live",
      idSport: props.match.params.idSport,
      idCategory: props.match.params.idCategory,
      idTournament: props.match.params.idTournament,
      idMatch: props.match.params.idMatch,
    };

    return {
      betAbbr: bst.config.betAbbr,
      betDesc: bst.config.betDesc,
      bets: getBets(state, init),
      categories: getCategories(state, init),
      tournaments: getTournaments(state, init),
      sport: getLiveSportInfoRetail(state, init),
      matchData: getLiveMatchData(state, init),
      selected: bst.betsSlip.tickets[bst.betsSlip.currentTicket].live.selected,
    };
  };
};

const actionCreators = {
  toggleBet: betsSlipToggle
};

export default connect(mapStateToProps, actionCreators)(withTranslation()(Event));
