export const authentication = {
    AUTH: '/api/auth/login',
    SMS: '/api/auth/sms',
    APP: '/api/auth/index',
};

export const menu = {
    ALL: '/application/menu',
};

export const documents = {
    MAX_SIZE: '/api/documents/max-upload-size',
};
export const marketing_offer = {
    GET: '/api/casino/marketingOffer'
}