import React from 'react';
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./MaintenanceFullWebsite.scss";
import { getElementText, getElementStyle } from "./utils/functions";

const MaintenancePage = (props) => {
  const { params, t, i18n } = props;
  const history = useHistory();

  const handleNavigation = () => {
    if (params && params.data && params.data.button && params.data.button.url) {
      try {
        if (params.data.button.url.toLowerCase().indexOf("http") === 0) {
          window.location.href = params.data.button.url;
        } else {
          history.push(params.data.button.url);
        }
      } catch (err) { }
      return;
    }
    if (window.config && window.config.cordova) {
      window.location = "/index.html"
    } else {
      window.location.reload();
    }
  };

  let title = '';

  switch (params.type) {
    case "full":
      title = getElementText("title", params.data, i18n, t("We'll be back online soon!"));
      break;
    default:
    /* noop */
  }

  return (
    <div className="maintenance-full-page">
      <div className="mp-header">
        <div>{t("Your casino is getting better")}</div>
      </div>
      <div className="mp-body">
        <div className="mp-title" style={getElementStyle("title", params.data)} dangerouslySetInnerHTML={{ __html: title }}></div>
        <div className="mp-content">
          <div
            style={getElementStyle("content", params.data)}
            dangerouslySetInnerHTML={{ __html: getElementText("content", params.data, i18n, t("THANK YOU FOR YOUR PATIENCE!	YOU WILL RETURN TO AN UPGRADED SITE.")) }}
          />
        </div>
        <div className="mp-button" style={getElementStyle("button", params.data)} onClick={handleNavigation}>
          {getElementText("button", params.data, i18n, t("Refresh"))}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(MaintenancePage);