import React from 'react';
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { expiredLottoEvents } from "../../store/actions/lotto-events";

const Timer = (props) => {
  const { date, expiredEvents, extended, t } = props;

  const [time, setTime] = React.useState(moment(date, "YYYY-MM-DD HH:mm:ss").format("D MMM HH:mm"));

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;

    if (mounted) {
      const calcTime = () => {
        const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        const dtNow = moment();

        const hours = dt.diff(dtNow, 'hours');
        const mins = dt.diff(dtNow, 'minutes') - hours * 60;
        const secs = dt.diff(dtNow, 'seconds') - mins * 60;

        if (hours < 0 || mins < 0 || secs < 0) {
          setTime(t("Drawing") + (extended ? " " + dt.format("HH:mm") : ""));
          clearInterval(intervalID);
          if (typeof expiredEvents === "function") expiredEvents();
          return;
        }

        let fDate = dt.format("D MMM HH:mm");
        if (hours === 0) {
          fDate = [
            ("0" + hours).slice(-2),
            ("0" + mins).slice(-2),
            ("0" + secs).slice(-2)
          ].join(":");
        }

        setTime(fDate);
      };
      intervalID = setInterval(() => {
        calcTime();
      }, 1000);
      calcTime();
    }

    return () => {
      mounted = false;
      clearInterval(intervalID);
    }
  }, []); // eslint-disable-line

  if (!date) return null;

  return (<React.Fragment>{time}</React.Fragment>);
}

const actions = {
  expiredEvents: expiredLottoEvents
};

export default withTranslation()(connect(null, actions)(Timer));