import * as countries from './countriesMap.json';
import NotAvailable from "../assets/notAvailable.svg";

const reqSvgs = require.context('../assets/countries', true, /\.svg$/);

const svgs = reqSvgs
  .keys()
  .reduce((images, path) => {
    let key = path.replace(".svg", "");
    key = key.replace("./", "");
    key = key.toUpperCase();
    images[key] = reqSvgs(path)
    return images
  }, {});

const getFlagISO3 = (iso3Code) => {
  if (!iso3Code) return NotAvailable;
  const ucIso3Code = iso3Code.toUpperCase();

  if (typeof countries.default[ucIso3Code] !== "undefined") {
    const iso2Code = countries.default[ucIso3Code].iso2Name;
    if (typeof svgs[iso2Code] !== "undefined") {
      if (typeof svgs[iso2Code] === "object" && typeof svgs[iso2Code].default !== "undefined") return svgs[iso2Code].default;
      return svgs[iso2Code];
    }
  }
  return NotAvailable;
}

const getFlagISO2 = (iso2Code) => {
  if (!iso2Code) return NotAvailable;
  if (iso2Code === "SUA") iso2Code = "US";
  const ucIso2Code = iso2Code.toUpperCase();
  if (typeof svgs[ucIso2Code] !== "undefined") {
    if (typeof svgs[ucIso2Code] === "object" && typeof svgs[ucIso2Code].default !== "undefined") return svgs[ucIso2Code].default;
    return svgs[ucIso2Code];
  }
  return NotAvailable;
}

export { getFlagISO3, getFlagISO2 };