import { takeEvery, put } from 'redux-saga/effects';
import openSocket from 'socket.io-client';
import axios from 'axios';
import pako from 'pako';
import getStore from '../../store';
import isArray from 'lodash/fp/isArray';
import { liveConstants, digitainConstants } from '../actions/constants';
import {
  digitainSetState,
  digitainMergeQueue,
  digitainQueueUpdates,
  digitainClearQueue,
  digitainLiveUpdates,
  digitainPrematchUpdates,
} from '../actions/live_digitain';

import { betsSlipLiveCreateTicketSuccess, betsSlipLiveCreateTicketError, betsSlipLoad } from '../actions/betsSlip';

import { appSaveErrorLog } from '../actions/app';

import { normalizeDigitainLiveTickets } from '../../utils/normalizeDigitainTickets';
import { ticketStoreOpen, ticketWinnerFunStoreOpen, ticketWinnerFunStoreOpenPrepare } from '../actions/tickets';

import { getBetsState } from '../selectors/betData';
import { debug, uuidv4 } from '../../utils';

import { unstable_trace as trace } from 'scheduler/tracing';

import { digitainConfig, digitainWinnerFunConfig } from '../../api/config/digitain';

import { requestWallet } from '../../../store/actions/wallet';

import { activeWinnerFunCampaigns } from '../../utils/winnerFunUtils';

import { getLanguage } from '../../../utils/i18n';

//const wsUrl = "https://sportsbook-public-api-staging.nsoft.com";
//const ticketsUrl = "https://services-staging.7platform.com";
//const cpv = "3657d0f3-f54d-4dc8-9077-578940875c8d";
//const productName = "LiveBetting";
//const socketCompanyUuid = "b99752b3-443c-4c80-b559-945a95c4b805";
//const companyUuid = "28ec1e93-68f0-49fd-9ab5-639d88169625";
//const product = "live";
//const protocol = "sio1";
//const productInstanceId = 1017294;
//const productInstanceUuid = "8a3f73e2-0a45-42d5-9488-9b6ec164923a";

// live
//const ticketsUrl = "https://services-ro2.7platform.com";
//const wsUrl = "https://sportsbook-public-api.nsoft.com";
//const cpv = "d8d00130-5509-4e9c-b2b9-94af43812fc8";
//const companyUuid = "04301c5a-6b6c-4694-aaf5-f81bf665498c";

let socket = null;
let mergeInterval = 0;
let keepAliveInterval = 0;
let pendingTickets = {};
let liveUpdates = [];
let prematchUpdates = [];

const DEBUG = false;

export const connect = () => {
  const bst = getBetsState(getStore().getState());

  const { auth, app } = bst;

  debug('********** connect digitain live socket', app.isWinnerFun);

  if (socket !== null) {
    // check if are connected where we need
    if (socket.isWinnerFun === app.isWinnerFun) return;

    // clear previous connection
    socket.disconnect();
    socket = null;

    clearInterval(mergeInterval);
    mergeInterval = 0;

    clearInterval(keepAliveInterval);
    keepAliveInterval = 0;

    getStore().dispatch(digitainClearQueue());

    liveUpdates = [];
    prematchUpdates = [];
  }

  /*
  mergeInterval = setInterval(() => {
    trace("merge digitain queues", performance.now(), () => {
      // return getStore().dispatch(digitainMergeQueue());
      if (liveUpdates.length > 0) {
        getStore().dispatch(digitainLiveUpdates(liveUpdates));
        liveUpdates = [];
      }
      if (prematchUpdates.length > 0) {
        getStore().dispatch(digitainPrematchUpdates(prematchUpdates));
        prematchUpdates = [];
      }
    });
  }, 10000);
  */

  let digitain = digitainConfig();
  let authDetails = auth.details;

  if (app.isWinnerFun) {
    digitain = digitainWinnerFunConfig();
    authDetails = auth.winnerFunDetails;
  }

  debug(`wsUrl = ${digitain.wsUrl}`);

  socket = openSocket(digitain.wsUrl, {
    path: digitain.wsPath,
    forceNew: true,
    transports: ['websocket'],
    query: {
      contentEncoding: 'deflate',
    },
  });

  socket.isWinnerFun = app.isWinnerFun;

  //debug("socket", socket);

  const keepAlive = () => {
    DEBUG && debug('Digitain socket keepalive', new Date());
    socket.emit('message', {
      type: 'serviceAlive',
    });
  };

  const subscribe = () => {
    debug('Digitain socket subscribing...');

    const bst = getBetsState(getStore().getState());

    // socket.emit("subscribe", {
    // 	language: getLanguage(),
    // 	deliveryPlatform: "Web",
    // 	encoding: "deflate",
    // 	subscribeMode: "topMatchBet",
    // 	subscribeOptions: {
    // 		autoSubscribe: true,
    // 		betCount: 10,
    // 		excludeMeta: false,
    // 		fullBetMeta: true,
    // 		subscribeMatches: true,
    // 		excludeBetGroups: false,
    // 		betsCacheKey: bst.live.betsCacheKey,
    // 		betGroupsCacheKey: bst.live.betGroupsCacheKey
    // 	}
    // });

    if (authDetails !== null) {
      socket.emit('message', {
        type: 'login',
        data: {
          uuid: authDetails.Uuid,
        },
      });
    }

    if (keepAliveInterval !== 0) clearInterval(keepAliveInterval);
    keepAliveInterval = setInterval(keepAlive, 10000);
  };

  socket.on('connect', () => {
    debug('Digitain socket connected');

    subscribe();
  });

  socket.on('event', function (data) {
    //debug("Socket event data", data);
  });

  socket.on('error', error => {
    debug('Digitain socket error', error);
    //socket.close();
    //setTimeout(connect, 0);
  });

  socket.on('connect_error', error => {
    debug('Digitain socket connect error', error);
    //socket.close();
    //setTimeout(connect, 0);
  });

  socket.on('message', message => {
    const st = performance.now();

    // debug("**** digitain message", message);

    let msg;

    if (message && message.compressed) {
      try {
        msg = JSON.parse(pako.inflate(message.data, { to: 'string' }));
      } catch (e) {
        console.error('failed to inflate', e);
      }
    } else {
      msg = message.data;
    }

    if (msg === null) {
      return;
    }

    let logMessage = true;

    switch (message.type) {
      case 'state':
        // debug("set state", msg);
        getStore().dispatch(digitainSetState(msg));
        // getStore().dispatch(betsSlipLoad());
        break;
      case 'update':
        // getStore().dispatch(digitainQueueUpdates(msg));
        break;
      default:
        debug('message not handled', message);
    }

    if (logMessage) {
      // debug("message handled", message);
    }

    debug('state took', performance.now() - st);
  });

  socket.on('update', data => {
    // debug("update", data);
    // getStore().dispatch(digitainQueueUpdates(data));
    if (data.mType) {
      if (data.mType === 'live') {
        liveUpdates.push(data);
      } else if (data.mType === 'prematch') {
        prematchUpdates.push(data);
      } else {
        console.error('unknown update mType', data);
      }
    } else {
      liveUpdates.push(data);
      prematchUpdates.push(data);
    }
  });

  socket.on('cupdates', data => {
    const st = performance.now();

    let updates = [];

    try {
      updates = JSON.parse(pako.inflate(data, { to: 'string' }));
    } catch (e) {
      console.error('failed to inflate', e);
    }

    if (updates) {
      for (const update of updates) {
        if (update.mType) {
          if (update.mType === 'live') {
            liveUpdates.push(update);
          } else if (update.mType === 'prematch') {
            prematchUpdates.push(update);
          } else {
            console.error('unknown update mType', update);
          }
        } else {
          liveUpdates.push(update);
          prematchUpdates.push(update);
        }
      }
    }

    if (liveUpdates.length > 0) {
      getStore().dispatch(digitainLiveUpdates(liveUpdates));
      liveUpdates = [];
    }
    if (prematchUpdates.length > 0) {
      getStore().dispatch(digitainPrematchUpdates(prematchUpdates));
      prematchUpdates = [];
    }

    DEBUG && debug('cupdates took', performance.now() - st);
  });
};

const handleTicketUpdate = data => {
  //debug("handle ticket update", data);

  const bst = getBetsState(getStore().getState());

  if (data.status.value === 'PENDING') {
    debug('need to wait some more', data);
    return;
  }

  if (data.status.value === 'OPEN') {
    getStore().dispatch(betsSlipLiveCreateTicketSuccess(''));

    const newTicket = normalizeDigitainLiveTickets([data], bst);

    if (bst.app.isWinnerFun) {
      getStore().dispatch(ticketWinnerFunStoreOpenPrepare(newTicket));
    } else {
      getStore().dispatch(ticketStoreOpen(newTicket));
    }
  } else {
    debug('error creating live ticket', data);

    // t("Error creating live ticket")
    let err = 'Error creating live ticket';

    if (data.message) {
      err = data.message;
    }

    let details = [];

    if (data.details && isArray(data.details)) {
      details = data.details.map(d => d.message);
    }

    getStore().dispatch(betsSlipLiveCreateTicketError(err, details));
    getStore().dispatch(appSaveErrorLog('', 'Error creating live ticket', JSON.stringify(data)));
  }

  const requestUuid = data.requestUuid;

  if (requestUuid in pendingTickets) {
    //debug("clear pending interval");
    clearInterval(pendingTickets[requestUuid]);
    delete pendingTickets[requestUuid];
  }

  //getStore().dispatch(requestWallet());
};

export const liveDisconnect = () => {
  return;
  /*
  if (socket !== null) {
    socket.disconnect();
    socket = null;

    clearInterval(mergeInterval);
    mergeInterval = 0;

    clearInterval(keepAliveInterval);
    keepAliveInterval = 0;
  }
  */
};

function* liveInitializeSaga() {
  yield connect();
}

function* liveCreateTicketSaga(action) {
  const state = getStore().getState();
  const bst = getBetsState(state);

  const { betsSlip, app, config } = getBetsState(state);

  let digitain = digitainConfig();
  // let authDetails = auth.details;

  if (app.isWinnerFun) {
    digitain = digitainWinnerFunConfig();
    // authDetails = auth.winnerFunDetails;
  }

  const ct = betsSlip.tickets[betsSlip.currentTicket];
  const bsLive = ct.live;
  const bsPrematch = ct.prematch;
  const isWinnerFun = app.isWinnerFun ? true : false;
  let winnerFunData = activeWinnerFunCampaigns(config.winnerFunCampaigns, true);
  const free_bet_code = action.free_bet_code ? action.free_bet_code : null;
  const free_bet_name = action.free_bet_name ? action.free_bet_name : null;
  const free_bet_pid = action.free_bet_pid ? action.free_bet_pid : null;
  const free_bet_redeemable = action.free_bet_redeemable ? action.free_bet_redeemable : null;

  if (ct.useWinnerFunBoost) {
    winnerFunData['use_boost'] = true;
  }

  //debug("live create bsLive", bsLive);

  if (bsLive.selected.length === 0 && bsPrematch.selected.length === 0) {
    yield put(betsSlipLiveCreateTicketSuccess(''));
    return;
  }

  debug('state.authentication', state.authentication);

  if (!(state.authentication && ['user', 'token'].indexOf(state.authentication.auth_type) > -1)) {
    // t("You are not authenticated")
    yield put(betsSlipLiveCreateTicketError('You are not authenticated'));
    return;
  }

  let bankers = 0;
  const ticketBetsLive = bsLive.selected.reduce((acc, s) => {
    if (!s.valid) {
      return acc;
    }

    if (s.fixed) {
      bankers++;
    }

    return acc.concat({
      stakeId: parseInt(s.idMbo, 10),
      eventId: parseInt(s.idMatch, 10),
      isLive: true,
      ticketBetBank: ct.ticketType !== 'system' ? 1 : s.fixed ? 1 : 0,
      ticketBetWay: 0,
      mboOddValue: s.odd,
    });
  }, []);

  const ticketBetsPrematch = bsPrematch.selected.reduce((acc, s) => {
    if (!s.valid) {
      return acc;
    }

    if (s.fixed) {
      bankers++;
    }

    return acc.concat({
      stakeId: parseInt(s.idMbo, 10),
      eventId: parseInt(s.idMatch, 10),
      isLive: false,
      ticketBetBank: ct.ticketType !== 'system' ? 1 : s.fixed ? 1 : 0,
      ticketBetWay: 0,
      mboOddValue: s.odd,
    });
  }, []);

  const ticketBets = ticketBetsLive.concat(ticketBetsPrematch);

  // const ticketType = ct.ticketType === "system" ? 2 : 1;

  // /*
  // let ticketCombinationGroups = [
  // 	{
  // 		parlays: 1,
  // 		events: ticketBets.length
  // 	}
  // ];
  // */
  let ticketCombinationGroups = [];

  if (ct.ticketType === 'system') {
    ticketCombinationGroups = [];

    bsLive.systems.forEach(sy => {
      ticketCombinationGroups.push({
        parlays: sy,
        events: ticketBets.length - bankers,
      });
    });
  }

  const requestUuid = uuidv4();

  const createReq = {
    ticket: {
      bets: ticketBets,
      payin: ct.amount,
      ticketComment: app.isWinnerFun ? 'winner_fun' : 'winner',
      ticketType: ct.ticketType,
      ticketSystems: ct.systems,
      // ticketCombinationGroups: ticketCombinationGroups,
      autoAcceptOddChange: ct.autoAcceptOddChange ? true : false,
    },
  };

  if (isWinnerFun) {
    createReq.clientVal = JSON.stringify(winnerFunData);
  } else {
    if (free_bet_code) {
      createReq.clientVal = JSON.stringify({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    }
  }

  createReq.lang = getLanguage();

  try {
    // yield put(betsSlipLiveCreateTicketPending(betsSlip.currentTicket, requestUuid));
    // pendingTickets[requestUuid] = ptid;

    const result = yield axios.post(digitain.ticketsUrl + '/place', createReq, {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    });

    debug('live ticket create result', result);

    yield put(betsSlipLiveCreateTicketSuccess(requestUuid));

    const newTicket = normalizeDigitainLiveTickets([result.data], bst);

    if (bst.app.isWinnerFun) {
      yield put(ticketWinnerFunStoreOpenPrepare(newTicket));
    } else {
      yield put(ticketStoreOpen(newTicket));
    }
  } catch (e) {
    //debug("live ticket error", e);
    yield put(appSaveErrorLog(JSON.stringify(createReq), e.toString(), JSON.stringify(e)));

    //let err = e.toString();
    let err = 'An error occurred: Error creating ticket';
    let details = [];

    if (e.response) {
      debug('error response', e.response);
      /*
      {
          "type": "place_status_exception",
          "data": {
              "Limit": 0,
              "MinAmount": 0,
              "MaxAmount": 0,
              "ErrorCode": 131,
              "ErrorMessage": "BetBlockedForPrematch"
          }
      }
      */

      if (e.response.data && e.response.data.type && e.response.data.type === 'place_status_exception') {
        details = [e.response.data.data];
      }
    }

    yield put(betsSlipLiveCreateTicketError(err, details));
  }
}

const checkTicket = (nsoft, authDetails, requestUuid) => {
  axios
    .get(nsoft.ticketsUrl + '/web/tickets/request/' + requestUuid + '/product/' + nsoft.productName + '/check.json', {
      headers: {
        Authorization: 'Bearer ' + authDetails.accessToken,
        'SEVEN-TP-TOKEN': authDetails.tpToken,
        //companyUuid: ticketCompanyUuid,
        'SEVEN-LOCALE': getLanguage(),
        'HTTP-X-NAB-DP': 'Web',
        'HTTP-X-SEVEN-CLUB-UUID': nsoft.companyUuid,
        'HTTP-X-NAB-PRODUCTNAME': nsoft.productName,
        'HTTP-X-NAB-PRODUCTINSTANCE-ID': nsoft.productInstanceUuid,
      },
    })
    .then(res => {
      //debug("live check ticket result", res);

      /*
      if (res.data.status.value === "OPEN") {
        getStore().dispatch(betsSlipLiveCreateTicketSuccess(""));
        getStore().dispatch(ticketStoreOpen(normalizeLiveTickets([res.data])));
      } else {
        getStore().dispatch(betsSlipLiveCreateTicketError("Error creating live ticket"));
      }

      if (requestUuid in pendingTickets) {
        debug("clear pending interval");
        clearInterval(pendingTickets[requestUuid]);
        delete pendingTickets[requestUuid];
      } else {
        debug("pending interval not found", requestUuid);
      }
      */
      handleTicketUpdate(res.data);
    })
    .catch(e => {
      //debug("live check ticket error", e);
      getStore().dispatch(
        appSaveErrorLog(JSON.stringify({ requestUuid }), 'Error checking live ticket', JSON.stringify(e))
      );
    });
};

function* livePlayerLoginSaga(action) {
  //debug("action", action, socket);

  const state = getStore().getState();

  const { auth, app } = getBetsState(state);

  let authDetails = auth.details;

  if (app.isWinnerFun) {
    authDetails = auth.winnerFunDetails;
  }

  if (socket !== null) {
    // authenticate websocket
    yield socket.emit('message', {
      type: 'login',
      data: {
        uuid: authDetails.Uuid,
      },
    });
  }

  /*
  // request bonus settings
  const { nsoft } = getBetsState(getStore().getState());

  debug("nsoft", nsoft);

  try {
    const { data } = yield axios.get(nsoft.config.bonusConfigUrl, {
      params: {
        cpvUuid: nSoftLive.cpvUuid,
        playerUuid: action.uuid
      }
    });

    debug("bonus data", data);

    getStore().dispatch(nSoftSetBonus(data));
  } catch (e) {
    debug("failed to load nsoft bonus", e);
  }
  */
}

function* livePlayerLogoutSaga() {
  if (socket !== null) {
    // authenticate websocket
    yield socket.emit('message', {
      type: 'logout',
    });
  }
}

export default function* watchLiveSaga() {
  //debug("watching live");
  yield takeEvery(liveConstants.INITIALIZE, liveInitializeSaga);
  yield takeEvery(liveConstants.CREATE_TICKET_REQUEST, liveCreateTicketSaga);
  yield takeEvery(digitainConstants.PLAYER_LOGIN, livePlayerLoginSaga);
  yield takeEvery(digitainConstants.PLAYER_LOGOUT, livePlayerLogoutSaga);
}
