import React from 'react';
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';

import "./MaintenancePage.scss";
import MinionImg from "./assets/minion-working.png";
import { getElementText, getElementStyle } from "./utils/functions";

import { closeGameLauncher } from "../store/actions/games";

const MaintenancePage = (props) => {
  const { params, t, i18n } = props;
  const history = useHistory();

  const handleNavigation = () => {
    if (params && params.data && params.data.button && params.data.button.url) {
      try {
        if (params.data.button.url.toLowerCase().indexOf("http") === 0) {
          window.location.href = params.data.button.url;
        } else {
          history.push(params.data.button.url);
        }
      } catch (err) { }
      return;
    }
    try {
      props.closeGameLauncher();
    } catch (err) { }
    history.push("/");
  };

  const slot_providers = {
    1: "EGT",
    2: "AMATIC",
    3: "SKYWIND",
    4: "CT GAMING",
    5: "NOVOMATIC",
  };

  let title = '';

  switch (params.type) {
    case "full":
      title = getElementText("title", params.data, i18n, t("The website is currently unavailable."));
      break;
    case "upgrade_version":
      // do something on 'ios' or 'android' platform
      const platform = params.platform; // eslint-disable-line
      title = getElementText("title", params.data, i18n, t("Please upgrade to the latest version of the application."));
      break;
    case "casino":
      title = getElementText("title", params.data, i18n, t('"Casino" games are currently unavailable.'));
      break;
    case "bets-prematch":
      title = getElementText("title", params.data, i18n, t('"Prematch Betting" events are currently unavailable.'));
      break;
    case "bets-live":
      title = getElementText("title", params.data, i18n, t('"LIVE Betting" events are currently unavailable.'));
      break;
    case "casino-live":
      title = getElementText("title", params.data, i18n, t('"Casino LIVE" games are currently unavailable.'));
      break;
    case "lotto":
      title = getElementText("title", params.data, i18n, t('"Lotto" events are currently unavailable.'));
      break;
    case "virtuals":
      title = getElementText("title", params.data, i18n, t('"Virtual" games are currently unavailable.'));
      break;
    case "provider":
      title = getElementText("title", params.data, i18n, t(`"{{PROVIDER}}" games are currently unavailable.`, { PROVIDER: slot_providers[params.id] }));
      break;
    case "slot":
      title = getElementText("title", params.data, i18n, t(`This game is currently unavailable.`));
      break;
    case "virtual":
      title = getElementText("title", params.data, i18n, t(`This game is currently unavailable.`));
      break;
    default:
      title = t("The section is currently unavailable.");
  }

  return (
    <div className="maintenance-page">
      <div className="mp-header">
        <div>{t("Pause for improvements")}</div>
      </div>
      <div className="mp-body">
        <div className="mp-image">
          <img src={MinionImg} alt="" />
        </div>
        <div className="mp-title" style={getElementStyle("title", params.data)} dangerouslySetInnerHTML={{ __html: title }}></div>
        <div className="mp-content">
          <div
            style={getElementStyle("content", params.data)}
            dangerouslySetInnerHTML={{ __html: getElementText("content", params.data, i18n, t("We apologize for this situation and we are working to fix the problem as soon as possible.")) }}
          />
        </div>
        <div className="mp-button" style={getElementStyle("button", params.data)} onClick={handleNavigation}>
          {getElementText("button", params.data, i18n, t("I understand"))}
        </div>
      </div>
    </div>
  );
}

const actionCreators = {
  closeGameLauncher
};

export default connect(null, actionCreators)(withTranslation()(MaintenancePage));