import * as React from "react";
import { useSpring, useTransition, animated } from "react-spring";

import Trophy from "../assets/trophy.svg";

const newText = (text, isHtml) => ({ key: `${Date.now()}`, data: isHtml ? <div dangerouslySetInnerHTML={{ __html: text }} /> : text });

const TextTransition = ({
  text,
  direction,
  inline,
  delay,
  className,
  style,
  noOverflow,
  springConfig,
  isHtml,
  award
}) => {
  const placeholderRef = React.useRef(null);
  const [content, setContent] = React.useState(() => newText(text ? text.toString() : "", isHtml));
  const [timeoutId, setTimeoutId] = React.useState(0);
  const [isFirstRun, setIsFirstRun] = React.useState(true);
  const [width, setWidth] = React.useState({ width: inline ? 0 : "auto" });
  const transitions = useTransition(content, (item) => item.key, {
    from: { opacity: 0, transform: `translateY(${direction === "down" ? "-100%" : "100%"})` },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: `translateY(${direction === "down" ? "100%" : "-100%"})` },
    config: springConfig,
    immediate: isFirstRun,
    onDestroyed: () => {
      setIsFirstRun(false);
    }
  });
  const animatedProps = useSpring({
    to: width,
    config: springConfig,
    immediate: isFirstRun,
  });
  React.useEffect(() => {
    if (text) {
      setTimeoutId(
        setTimeout(() => {
          if (!placeholderRef.current) return;
          placeholderRef.current.innerHTML = text.toString();
          if (inline) setWidth({ width: placeholderRef.current.offsetWidth });
          setContent(newText(text.toString(), isHtml));
        }, delay)
      );
    }
  }, [text]); // eslint-disable-line

  React.useEffect(() => () => clearTimeout(timeoutId), []);  // eslint-disable-line

  return (
    <animated.div
      className={`text-transition ${className}`}
      style={{
        ...animatedProps,
        whiteSpace: inline ? "nowrap" : "normal",
        display: inline ? "inline-block" : "block",
        position: "relative",
        ...style,
      }}
    >
      <span ref={placeholderRef} style={{ visibility: "hidden" }} className="text-transition_placeholder" />
      <div
        className="text-transition_inner"
        style={{
          overflow: noOverflow ? "hidden" : "visible",
          display: "block",
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%"
        }}
      >
        {
          transitions.map(({ item, props, key }) => (
            <animated.div key={key} style={{ ...props, position: "absolute" }} className="text-transition-animated-div">
              {!!award && <img src={Trophy} alt="" />}
              {item.data}
            </animated.div>
          ))
        }
      </div>
    </animated.div>
  );
};

export default TextTransition;