/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 22/01/2019
 * Time: 15:27
 */
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import "./FAQ.scss";
import Translate from "../../../utils/Translate";

class FAQ extends Component {
    render() {
        return (
            <div className={"faq"}>
                <ol>{window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <li>
                    <h1>{this.props.t('What is "Deposits & Withdrawals in Location"?')}</h1>
                    <p className={"textContent"}>
                        {this.props.t('We made it simpler so you can play as you want. If you want to stay away from Credit Cards and other digital payment systems, you are free to deposit and withdraw in any casino location using only your ID card.')}
                    </p>
                </li>}
                    {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <li>
                        <h1>{this.props.t('Can I cash-in in any Location?')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('You can cash-in up to 200 Lei per day in any location. Withdrawal amounts greater than 200 Lei can only be cashed-in in the selected location.')}
                        </p>
                    </li>}
                    {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <li>
                        <h1>{this.props.t('How do I Deposit in Location?')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('Visit any casino location with the money and your ID card. Your account will receive the funds instantly and you will receive confirmation by SMS.')}
                        </p>
                    </li>}
                    {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <li>
                        <h1>{this.props.t('How do I initiate a Withdrawal?')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('Visit the section Account - Wallet & Bonus - Withdraw or directly from the “Locations” section. Your request will be processed as soon as possible.')}
                        </p>
                    </li>}
                    {window.config && window.config.hide_agencies === '1' && window.config.okto_enabled !== '1' && <li>
                        <h1>{this.props.t('How do I initiate a Withdrawal?')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('Visit the section Account - Wallet & Bonus - Withdraw. Your request will be processed as soon as possible.')}
                        </p>
                    </li>}
                    {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <li>
                        <h1>{this.props.t('How do I cash-in my withdrawn money in location?')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('You will receive a SMS Code once your withdrawn has been approved. Go in the chosen location')}
                        </p>
                    </li>}
                    {window.config && window.config.okto_enabled === '1' && <li>
                        <h1>{this.props.t('What is OKTO.CASH?')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('OKTO.CASH is a payment method that allows you to fund your betting account with cash, without the need for a bank card.')}
                        </p>
                    </li>}

                    {window.config && window.config.okto_enabled === '1' && <li>
                        <h1>{this.props.t('How can I find an OKTO.CASH location?')}</h1>
                        <p className={"textContent"}>
                            <Translate text={"Access the ${startUrl}OKTO.CASH${endUrl} section from the homepage. Then, depending on your location, select the nearest terminal."}
                                placeholder={{
                                    startUrl: "<a href='/deposit-oktocash' target='_blank' rel='noopener noreferrer' >",
                                    endUrl: "</a>"
                                }           }
                            />
                        </p>
                    </li>}

                    {window.config && window.config.okto_enabled === '1' && <li>
                        <h1>{this.props.t('How do I make a deposit through OKTO.CASH?')}</h1>
                        <p className={"textContent"}>
                            <div>
                                - {this.props.t('Open the Winner app.')}
                            </div>
                            <div>
                                - {this.props.t('Go to the Deposit section.')}
                            </div>
                            <div>
                                - {this.props.t('Choose the OKTO.CASH payment method.')}
                            </div>
                            <div>
                                - {this.props.t('Enter the amount you wish to deposit. You can only deposit fixed amounts, according to the following values: 20, 50, 100, 150, 250, 500, 1000, 1500, 2500, 3000, 4500 Lei.')}
                            </div>
                            <div>
                                - {this.props.t('Find the nearest terminal where you can make the deposit.')}
                            </div>
                            <div>
                                - {this.props.t('Save the payment code generated in your player account.')}
                            </div>
                            <div>
                                - {this.props.t('On the terminal, select the Gift Card & Games category - Betting - Winner.')}
                            </div>
                            <div>
                                - {this.props.t('Type the payment code.')}
                            </div>
                            <div>
                                - {this.props.t('Your funds will instantly reach your player account.')}
                            </div>
                        </p>
                    </li>}

                    <li>
                        <h1>{this.props.t('How do I initiate a Withdrawal?')}</h1>
                        <p className={"textContent"}>
                            {this.props.t("Access your account and you can initiate a withdrawal from the 'Wallet' section by selecting the amount you wish to withdraw. Your request will be processed as quickly as possible.")}
                        </p>
                    </li>
                    {window.config?.okto_enabled !== '1' && <li>
                        <h1>{this.props.t('I want to cancel a withdrawal and return the funds to my play account.')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('Visit the section Account - Withdrawals. For every withdrawal there is an option to cancel funds partially or completely. This will not affect the approval time.')}
                        </p>
                    </li>}
                    <li>
                        <h1>{this.props.t("I'm trying to initiate a withdrawal without success.")}</h1>
                        <p className={"textContent"}>
                            {this.props.t('In most of the cases there will be an error message explaining the reason. The main causes are (1) you have not completed your Identity Verification process or (2) you have active BONUSES that have wager requirements in progress.')}
                        </p>
                    </li>
                    <li>
                        <h1>{this.props.t('What kind of currency can be used to deposit and withdraw?')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('Right now, the following currency can be used')}: {this.props.t('Lei (RON)')}.
                        </p>
                    </li>
                    <li>
                        <h1>{this.props.t('What taxes apply on withdrawals?')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('Starting from 1st of August 2022, according to the Government Ordinance no. 16/2022, amending and supplementing the Law no. 227/2015 on the Fiscal Code, gambling income obtained by individuals as a result of participating in online gambling, is taxed according to the following thresholds')}:
                        </p>
                        <div className={"taxesTable"}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{this.props.t('to')} 10.000 RON, {this.props.t('inclusive')}</td>
                                        <td>3%</td>
                                    </tr>
                                    <tr>
                                        <td>{this.props.t('From')} 10.000 RON {this.props.t('to')} 66.750 RON, {this.props.t('inclusive')}</td>
                                        <td>300 RON + 20% {this.props.t('applied to the amount over')} 10.000 RON</td>
                                    </tr>
                                    <tr>
                                        <td>{this.props.t('Over')} 66.750 RON</td>
                                        <td>11.650 + 40% {this.props.t('applied to the amount over')} 66.750 RON</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                    <li>
                        <h1>{this.props.t('I have cashed-in less money than the amount requested in the withdrawal.')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('We do not tax you in any way; the difference comes from the state taxes accordingly to the law.')}
                        </p>
                    </li>
                    <li>
                        <h1>{this.props.t('My wallet received less money than the deposit value.')}</h1>
                        <p className={"textContent"}>
                            {this.props.t('Based on the Government’s Emergency Decree 114/2018, every deposit is taxed 2% of its value, transferred to the State Budget.')}
                        </p>
                    </li>
                    <li>
                        <h1>{this.props.t('What is "PEP"?')}</h1>
                        <p className={"textContent"}>
                            <div>
                                {this.props.t('“Publicly exposed person” (in the sense of the AML Law) means a natural person who is or who has been entrusted with prominent public functions. Prominent public functions mean:')}
                            </div>
                            <ol type={"a"}>
                                <li>
                                    {this.props.t("head of State, heads of government, ministers and deputy ministers or state secretaries;")}
                                </li>
                                <li>
                                    {this.props.t("members of parliament or of similar central legislative bodies;")}
                                </li>
                                <li>
                                    {this.props.t("members of the governing bodies of political parties;")}
                                </li>
                                <li>
                                    {this.props.t("members of supreme courts, of constitutional courts or of other high-level judicial bodies, the decisions of which are not subject to further appeal, except in exceptional circumstances;")}
                                </li>
                                <li>
                                    {this.props.t("members of governing bodies of courts of auditors or of the boards of central banks;")}
                                </li>
                                <li>
                                    {this.props.t("ambassadors, chargés d'affaires and high-ranking officers in the armed forces;")}
                                </li>
                                <li>
                                    {this.props.t("members of the administrative, management or supervisory bodies of State-owned enterprises, regie autonomous or national companies;")}
                                </li>
                                <li>
                                    {this.props.t("directors, deputy directors and members of the board or equivalent function of an international organisation.")}
                                </li>
                            </ol>
                            <div>
                                {this.props.t("No public function referred to in points (a) to (h) shall be understood as covering middle-ranking or more junior officials.")}
                            </div>
                        </p>
                        <p className={"textContent"}>
                            <div>
                                {this.props.t("“family members” of a publicly exposed person are (in the sense of the AML Law):")}
                            </div>
                            <ol type={'a'}>
                                <li>
                                    {this.props.t("the spouse of a publicly exposed person or the person considered to be equivalent to a spouse of a publicly exposed person;")}
                                </li>
                                <li>
                                    {this.props.t("the children and their spouses, or persons considered to be equivalent to a spouse, of a publicly exposed person;")}
                                </li>
                                <li>
                                    {this.props.t("the parents of a publicly exposed person.")}
                                </li>
                            </ol>
                        </p>
                        <p className={"textContent"}>
                            <div>
                                {this.props.t("“persons known to be close associates” of a publicly exposed person are (in the sense of the AML Law):")}
                            </div>
                            <ol type={"a"}>
                                <li>
                                    {this.props.t("natural persons who are known to have joint beneficial ownership of legal entities or legal arrangements, or any other close business relations, with a publicly exposed person;")}
                                </li>
                                <li>
                                    {this.props.t("natural persons who have sole beneficial ownership of a legal entity or legal arrangement which is known to have been set up for the de facto benefit of a publicly exposed person.")}
                                </li>
                            </ol>
                        </p>
                    </li>
                </ol>
            </div>
        );
    }
}

export default withTranslation()(FAQ);