import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../../store/actions";
import { withTranslation } from "react-i18next";
import "./RegisterBonus.scss";
import ExpiringLocalStorage from "../../../../utils/ExpiringLocalStorage";
import Utils from "../../../../utils/Utils";

const TIMER_ANIMATION_DELAY = 0;
const TIMER_ANIMATION_TIME = 1500;
const MAX_FIELDS = 14;

class RegisterBonus extends Component {



    constructor(props) {
        super(props);
        props.getMarketingOffer(ExpiringLocalStorage.get('moid'), props.lastProductId);
    }

    state = {
        beginReward: 0,
        reward: 0,
        totalReward: 0,
        timerAnimationTime: false,
        increment: 0,
    };

    componentDidMount() {
        Utils.delayedFunction("rewardRegister", this.reward.bind(this), 0);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.fieldsValidated !== prevProps.fieldsValidated) {
            Utils.delayedFunction("rewardRegister", this.reward.bind(this), 200);
        } else {
            if (this.state.reward < this.state.totalReward) {
                if (this.state.timerAnimationTime !== false) {
                    let delay = prevState.timerAnimationTime === false ? TIMER_ANIMATION_DELAY : this.state.timerAnimationTime;
                    setTimeout(this.animate.bind(this), delay);
                }
            }
        }
    }

    reward() {
        let valuePerValidation = this.props.marketingOffer.marketingOffer.register_value_units_numeric / MAX_FIELDS;
        if (isNaN(valuePerValidation)) {
            Utils.delayedFunction("rewardRegister", this.reward.bind(this), 500);
            return false;
        }

        let validated = 0;
        Object.keys(this.props.fieldsValidated).forEach((field) => {
            if (field !== 'marketing') {
                if (this.props.fieldsValidated[field]) {
                    validated++;
                }
            }
            if (field === 'confirmPassword' && this.props.fieldsValidated[field]) {
                validated = MAX_FIELDS;
            }
        })
        if (validated > MAX_FIELDS) {
            validated = MAX_FIELDS;
        }
        let total = (valuePerValidation * validated);
        let diffReward = parseInt(total - this.state.reward, 10);
        if (total + valuePerValidation > this.props.marketingOffer.marketingOffer.register_value_units_numeric) {
            total = this.props.marketingOffer.marketingOffer.register_value_units_numeric;
        }

        // PO-6449
        if (parseInt(total, 10) === 0) {
            try {
                if (typeof this.props.marketingOffer.marketingOffer.ui_elements !== "undefined" && this.props.marketingOffer.marketingOffer.ui_elements) {
                    let elements = JSON.parse(this.props.marketingOffer.marketingOffer.ui_elements);
                    if (elements && typeof elements.register_starting_value !== "undefined") {
                        let value = parseFloat(elements.register_starting_value, 10);
                        if (!isNaN(value) && value < this.props.marketingOffer.marketingOffer.register_value_units_numeric) {
                            total = value;
                        }
                    }

                }
            } catch (er) { }
        }

        this.setState({
            ...this.state,
            totalReward: parseInt(total, 10),
            timerAnimationTime: TIMER_ANIMATION_TIME / diffReward,
            increment: 1,
            beginReward: this.state.reward,
        });
    }
    animate() {
        let newReward = this.state.reward + this.state.increment;
        if (newReward > this.state.totalReward) {
            newReward = this.state.totalReward;
        }
        let hasFinished = newReward === this.state.totalReward;

        this.setState({
            ...this.state,
            reward: newReward,
            increment: hasFinished ? 0 : this.state.increment,
            timerAnimationTime: hasFinished ? false : this.state.timerAnimationTime
        });
    }

    render() {
        if (this.props.marketingOffer.inProgress || this.props.signedUp) {
            return null;
        }

        return (
            <div className="register-bonus">
                <div className={"bonus-wrapper"} >
                    <div className="bonus-image">
                        <img src={this.props.marketingOffer.marketingOffer.register_image} alt={this.props.marketingOffer.marketingOffer.register_value_sub_title_text} />
                    </div>
                    <div className="bonus-info">
                        <div className="bonus-value">{this.state.reward} <small>{this.props.marketingOffer.marketingOffer.register_value_units_text}</small></div>
                        <div className="small-description">
                            {this.props.marketingOffer.marketingOffer.register_value_sub_title_text}
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        marketingOffer: state.marketingOffer,
        lastProductId: state.application.lastProductId,
        signedUp: state.register.signedUp,
        fieldsValidated: state.register.validated
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMarketingOffer: (moid, productId) => dispatch(storeActions.getMarketingOffer(moid, productId)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RegisterBonus));
