/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 11/12/2018
 * Time: 13:04
 */
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as stateActions from "../../../store/actions";
import Identity from "./Identity";
import NewPassword from "./NewPassword";
import FinalStep from "./FinalStep";

class Steps extends Component {
    render() {
        const AllComponents = {
            Identity: Identity,
            NewPassword: NewPassword,
            FinalStep: FinalStep,
        };
        const Type = AllComponents[this.props.form];
        if (!Type) {
            return <Fragment></Fragment>;
        }

        return (
            <div>
                <Type goToStep={this.props.goToStep} currentStep={this.props.currentStep} {...this.props} />
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        preventGoToStep: () => dispatch(stateActions.goToStep(false))
    };
};

const mapStateToProps = state => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Steps);