import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './MenuItem.css';
import * as stateActions from './../../../../store/actions';
import Icons from './../../../../assets/Icons/Icons';
import { withTranslation } from "react-i18next";

class MenuItem extends Component {
    clickHandler() {
        // commented so that a click on "Slots" link will load the default slots page (if the user is on jackpots page)
        // otherwise will do nothing
        //if (this.props.page.code === this.props.row.code) {
        //    return false;
        //}

        this.props.onPageChanged(this.props.row);

        return true;
    }

    render() {
        const row = this.props.row;

        let stringUrl = '/' + row.code;

        let icon = row.icon ? Icons.get(row.icon, row.icon) : '';

        let menuKeys = Object.keys(this.props.menu);

        let clsName = "";
        if (typeof this.props.menu[menuKeys[1]] !== "undefined") {

            if (row.code === this.props.menu[menuKeys[1]].code && this.props.page.code === row.code) {
                clsName = "selected";
            }
        } else {
            clsName = "selected";
        }

        let comingSoon = "";
        if (!row.available) {
            comingSoon = (
                <div className={"coming-soon"}>
                    <span>{this.props.t("coming soon")}</span>
                </div>
            );
        }

        return (
            <div className={"MenuItem" + " " + row.code}>
                <NavLink to={stringUrl} exact activeClassName="selected" onClick={this.clickHandler.bind(this)} className={clsName} title={row.name.toUpperCase()}>
                    {icon}
                    <span className="align-top item-text">{this.props.t(row.name)}</span>
                    {comingSoon}
                </NavLink>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        page: state.application.page,
        loading: state.application.loading,
        menu: state.application.menu,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        showMainBanner: () => dispatch(stateActions.showMainBanner()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MenuItem));