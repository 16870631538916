import React from "react";
//import { makeStyles } from "@material-ui/core/styles";

import Collapsed from "./Collapsed";

//const useStyles = makeStyles({
//});

const Simple = ({ ticket }) => {
	//const classes = useStyles();

	return (<Collapsed ticket={ticket} />);
};

export default Simple;
