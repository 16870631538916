import { makeStyles } from "@material-ui/core/styles";

export const topMargin = 95;
const topSticky = `${topMargin}px`;

const desktopStyles = makeStyles({
  wrapper: {
    //maxWidth: "1920px",
    margin: "0 auto"
  },
  tabs: {
    backgroundColor: "#162151",
    minHeight: "calc(100vh - 56px)",
    position: "relative",
    "&>div": {
      top: topSticky,
      position: "sticky",
      width: "250px"
    }
  },
  tabsDesktop: {
    backgroundColor: "transparent",
    minHeight: "calc(100vh - 56px)",
    position: "relative",
    "&>div": {
      top: topSticky,
      position: "sticky",
      width: "300px",
      minHeight: `calc(100vh - ${topSticky})`,
    }
  },
  over: {
    width: "100%",
    overflow: "auto",
    backgroundColor: "#fff",
    scrollbarColor: "#c1c1c1 #f1f1f1",
    scrollbarWidth: "thin",
    scrollbarHeight: "thin",
    "& .toolbar-nav": {
      overflow: "initial"
    },
    '&::-webkit-scrollbar': {
      backgroundColor: "#f1f1f1",
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: "#f1f1f1",
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: "5px",
      backgroundColor: '#c1c1c1',
    }
  },
  cnt: {
    flex: "1 1 auto",
    width: 0,
    '& .pre-match-calendar-filter': {
      scrollbarColor: "#c1c1c1 #f1f1f1",
      scrollbarWidth: "thin",
      scrollbarHeight: "thin",
    },
    '& .pre-match-calendar-filter::-webkit-scrollbar': {
      backgroundColor: "#f1f1f1",
      width: '8px',
      height: '8px',
    },
    '& .pre-match-calendar-filter::-webkit-scrollbar-track': {
      backgroundColor: "#f1f1f1",
    },
    '& .pre-match-calendar-filter::-webkit-scrollbar-thumb': {
      borderRadius: "5px",
      backgroundColor: '#c1c1c1',
    }
  },
  px: {
    padding: "0 10px",
  },
  betSlip: {
    width: "400px",
    position: "relative",
    backgroundColor: "transparent",
    padding: "0 10px",

    "&>div": {
      top: topSticky,
      position: "sticky",
      background: "#D2DBE7",
      boxShadow: "0px 3px 6px rgba(0,0,0,.16)",
      borderRadius: "14px 14px 0 0",
    }
  },
  w50: {
    flex: "1 1 auto",
    width: "50%",
    marginBottom: "5px",
    "&:first-child": {
      paddingRight: "5px"
    },
    "&:last-child": {
      paddingLeft: "5px"
    }
  }
});

export default desktopStyles;