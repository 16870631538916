import React, { Component } from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import './DruidScanner.scss';
import * as storeActions from "../../store/actions";
import Icons from "../../assets/Icons/Icons";
import ProgressBar from "../UI/ProgressBar/ProgressBar";
import {DialogTemplate} from "../UI/Dialog/DialogTemplate";

export const DRUID_CONFIDENCE = window.config.druid_scan_threshold ? window.config.druid_scan_threshold : 0.8;
export const DRUID_FACE = 0.8;

const FIRST_INTERVAL_TIME = 3000; // ms
const FIRST_INTERVAL_INCREMENT = 5; // ms
const END_INTERVAL_INCREMENT = 1; // ms
const FIRST_INTERVAL_END = 75; // %
const PROGRESS_TIMEOUT = 15000; // ms
const FEEDBACK_TIME = 2000; // ms

class DruidScanner extends Component
{

    constructor(props) {
        super(props);
        this.inputOpenFileRef = React.createRef();
        this.files = [];
        this.defaultPopupMessage = props.t("Se verifică documentul...");
        this.state.popupMessage = this.defaultPopupMessage;
    }

    state = {
        progress: 0,
        popupMessage: '',
        open: false,
        timeout: false,
        sizeError: false
    };

    componentDidMount() {
        this.props.getMaxUploadSize();
    }

    triggerLoading() {
        let _self = this;
        this.firstInterval = setInterval(() => {
            let newProgress = _self.state.progress + FIRST_INTERVAL_INCREMENT;
            if(newProgress > FIRST_INTERVAL_END) {
                newProgress = FIRST_INTERVAL_END;
            }
            _self.setState({
                ..._self.state,
                progress: newProgress
            })
            if (newProgress === FIRST_INTERVAL_END) {
                _self.endInterval = setInterval(() => {
                    let newProgress = _self.state.progress + END_INTERVAL_INCREMENT;
                    if (newProgress > 100) {
                        newProgress=100;
                    }
                    _self.setState({
                        ..._self.state,
                        progress: newProgress,
                    })
                    if (newProgress >= 100) {
                        _self.setState({
                            ..._self.state,
                            timeout: true
                        })
                        clearInterval(_self.endInterval);
                    }
                },(PROGRESS_TIMEOUT-FIRST_INTERVAL_TIME)/(100-FIRST_INTERVAL_END)/END_INTERVAL_INCREMENT);
                clearInterval(_self.firstInterval);
            }
        },FIRST_INTERVAL_TIME/(FIRST_INTERVAL_END/FIRST_INTERVAL_INCREMENT))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.druid !== prevProps.druid && prevProps.scanInProgress !== this.props.scanInProgress) {
            this.setState({
               timeout: false,
               progress: 100
            });
        }
        if (prevProps.scanInProgress !== this.props.scanInProgress) {
            if (this.props.scanInProgress) {
                this.setState({
                    ...this.state,
                    open: true,
                    timeout:false,
                    popupMessage: this.defaultPopupMessage
                });
                this.triggerLoading();
            } else {
                this.setState({
                    ...this.state,
                    progress: 100
                });
                clearInterval(this.endInterval);
                clearInterval(this.firstInterval);
            }
        }
        if (prevState.progress !== this.state.progress) {
            if (this.state.progress === 100) {

                this.setState({
                    ...this.state,
                    popupMessage: this.getStatusMessage(true)
                })
                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        open: false,
                        progress: 0
                    })
                },FEEDBACK_TIME)
            }
        }
    }

    dragHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        return false;
    }

    hasFiles(event) {
        let hasFiles = false;

        if (null === event.dataTransfer) {
            return hasFiles;
        }

        const types = event.dataTransfer.types;
        for (const keyOrIndex in types) {
            if (types[keyOrIndex] === 'Files') {
                hasFiles = true;
            }
        }

        return hasFiles;
    }

    dropHandler(event) {
        event.preventDefault();
        event.stopPropagation();

        event.persist();

        if (false === this.hasFiles(event)) {
            this.setState({
                ...this.state,
                open: false,
                progress: 0,
                sizeError: false
            })
            return false;
        }

        if (!event.dataTransfer) {
            return false;
        }

        let files = event.dataTransfer.files;

        this.upload(files);
        return false;
    }

    showOpenFileDlg() {
        this.inputOpenFileRef.current.files = null;
        this.inputOpenFileRef.current.value = '';
        this.inputOpenFileRef.current.click();
    }

    inputFilesHandler(event) {

        let files = this.inputOpenFileRef.current.files;
        if (!files) {
            this.setState({
                ...this.state,
                open: false,
                progress: 0,
                sizeError: false
            })
            return false;
        }

        this.upload(files);
    }
    upload(files) {
        let error = false;
        this.files = [];
        let size = this.props.documentsMaxFileSize.replace('Mb','');
        Object.keys(files).forEach((attr) => {
            if(files[attr].size/(1048576) < size) {
                this.files.push(files[attr]);
            } else {
                error  = true;
                this.setState({
                    ...this.state,
                    sizeError: true
                })
            }
        });

        if (!error) {
            this.props.scanDocument(this.files);
        }
    }
    getStatusMessage(popup) {
        let message, className;
        if (!this.state.sizeError) {
            if (!this.state.timeout) {
                if (!(this.props.druid.Status || this.props.druid.title)) {
                    return null
                }
            }

            message = popup ? this.props.t("EROARE DOCUMENT") : this.props.t("Documentul este neclar sau are informații acoperite. Încearcă cu o altă poză");
            className = 'error';
            if (scanOk(this.props.druid, this.state.timeout)) {
                message = popup ? this.props.t("SUCCES!") : this.props.t("Documentul a fost citit cu success");
                className = 'success';
            }
        } else {
            let size = this.props.documentsMaxFileSize;
            message = this.props.t(`The maximum file size is ${size}`);
            className = 'error';
        }

        return <div className={className}>{message}</div>;
    }

    getLoadingScreen() {
        return (
            <DialogTemplate
                open={this.state.open}
                className={`druid-modal`}
                title={this.props.t('Citire Document')}
                icon={Icons.get('scan-document')}
            >
                <div className="druid-modal-message">
                    {this.state.popupMessage}
                    <ProgressBar value={this.state.progress} />
                </div>
            </DialogTemplate>
        )
    }

    render() {

        return (
            <div className={'druid-scanner-wrapper' + (this.props.druid.Status ? scanOk(this.props.druid,this.state.timeout) ? ' success' : " error" : "")}>
                <div className={'druid-scanner'}
                     onDragOver={this.dragHandler.bind(this)}
                     onDragLeave={this.dragHandler.bind(this)}
                     onDrop={this.dropHandler.bind(this)}
                     onClick={this.showOpenFileDlg.bind(this)}
                >

                    <div className="labels">
                        <div className="danger">{this.props.t('NEW')}</div>
                        <div className="warning">{this.props.t('Verified identity')}</div>
                    </div>
                    <div className="content">
                        <div className="text">
                            <div className="title">
                                {this.props.t("SCAN & GO")}
                            </div>
                            <div className="description">
                                {this.props.t("Proces rapid de verificare")}
                            </div>
                        </div>
                        <div className="upload-image-wrapper">
                            <div className="upload-image">
                                <img
                                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS44NzQiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyNS44NzQgMjEiPg0KICA8ZyBpZD0icGhvdG8tY2FtZXJhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiPg0KICAgIDxnIGlkPSJHcm91cF83OTQiIGRhdGEtbmFtZT0iR3JvdXAgNzk0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDQ2LjE1KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF85MTEiIGRhdGEtbmFtZT0iUGF0aCA5MTEiIGQ9Ik0wLDUyLjU3NlY2My45OTJBMy4xNTksMy4xNTksMCwwLDAsMy4xNTgsNjcuMTVIMjIuNzE2YTMuMTU5LDMuMTU5LDAsMCwwLDMuMTU4LTMuMTU4VjUyLjU3NmEzLjAwNiwzLjAwNiwwLDAsMC0zLTNoLTQuMmwtLjEtLjQzOGEzLjgzOCwzLjgzOCwwLDAsMC0zLjc2LTIuOTgzSDExLjA2MkEzLjg0NywzLjg0NywwLDAsMCw3LjMsNDkuMTMzbC0uMS40MzhIM0EzLjAxLDMuMDEsMCwwLDAsMCw1Mi41NzZabTcuNzItMS43MTFhLjY0NC42NDQsMCwwLDAsLjYyOC0uNWwuMjE2LS45NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUtMS45OGgzLjc0NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUsMS45OGwuMjE2Ljk0YS42NDkuNjQ5LDAsMCwwLC42MjguNWg0LjcxNWExLjcxLDEuNzEsMCwwLDEsMS43MTEsMS43MTFWNjMuOTkyYTEuODY0LDEuODY0LDAsMCwxLTEuODY0LDEuODY0SDMuMTU4YTEuODY0LDEuODY0LDAsMCwxLTEuODY0LTEuODY0VjUyLjU3NkExLjcxLDEuNzEsMCwwLDEsMyw1MC44NjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiIGZpbGw9IiNmZmYiLz4NCiAgICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTQ1IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTQ1IiBjeD0iMC44NjYiIGN5PSIwLjg2NiIgcj0iMC44NjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTExIDYuNjExKSIgZmlsbD0iI2ZmZiIvPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfOTEyIiBkYXRhLW5hbWU9IlBhdGggOTEyIiBkPSJNMTQ3LjUzNCwxODYuMDE3YTUuNDMzLDUuNDMzLDAsMSwwLTUuNDM0LTUuNDMzQTUuNDQyLDUuNDQyLDAsMCwwLDE0Ny41MzQsMTg2LjAxN1ptMC05LjU3M2E0LjE0LDQuMTQsMCwxLDEtNC4xNCw0LjE0QTQuMTQ3LDQuMTQ3LDAsMCwxLDE0Ny41MzQsMTc2LjQ0NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzQuNTk3IC0xNjguMzM4KSIgZmlsbD0iI2ZmZiIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo="
                                    alt="camera" />
                            </div>
                        </div>
                    </div>
                    <input type="file" style={{display: "none"}}  accept="image/png, image/jpeg" ref={this.inputOpenFileRef} onChange={this.inputFilesHandler.bind(this)} />
                </div>
                {this.getStatusMessage()}
                {this.getLoadingScreen()}

            </div>
        );
    }
}

export const scanOk = (data, timeout) => {
    if (timeout || data.title) {
        return false;
    }
    let ok = true;

        switch (data.Status) {
            case "Complete":
                if (data.Confidence < DRUID_CONFIDENCE || data.Data.Face < DRUID_FACE) {
                    ok = false;
                }
                break;
            case "Partial":
                ok = false;
                break;
            default:
                ok = false;
                break;
        }

    return ok;
}

const mapStateToProps = state => {

    return {
        druid: state.druid.data,
        scanInProgress: state.druid.inProgress,
        documentsMaxFileSize: state.documents.maxFileSize
    }
};
const mapDispatchToProps = dispatch => {
    return {
        scanDocument: (file) => dispatch(storeActions.scanDocument(file)),
        getMaxUploadSize: () => dispatch(storeActions.requestMaxFileSize())
    }

}
export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(DruidScanner));
