/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 28/11/2018
 * Time: 14:19
 */
import React, { Component , Fragment} from 'react';
import CheckboxInput from "../../../../Register/Form/CheckboxInput/CheckboxInput";
import SelfExclusionChoice from "./Parts/SelfExclusionChoice";
import { VelocityComponent } from "velocity-react";
import "./SelfExclusion.scss";
import { withTranslation } from "react-i18next";
import BackendClient from "../../../../../BackendClient";
import { sprintf } from 'sprintf-js';
import Icons from "../../../../../assets/Icons/Icons";
import * as stateActions from "../../../../../store/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Menus from "./../../../../Utils/Menus/Menus";
import TakeABreakModal from './Modals/TakeABreakModal/TakeABreakModal';
import { Button } from 'reactstrap';
import Translate from '../../../../../utils/Translate';
import SelfExclusionModal from './Modals/SelfExclusionModal/SelfExclusionModal';
import { animateScroll as scroll } from 'react-scroll';

const LIMIT_1_DAY       = 4;
const LIMIT_3_DAYS      = 5;
const LIMIT_1_WEEK      = 6;
const LIMIT_6_MONTHS    = 7;
const LIMIT_1_YEAR      = 8;
const LIMIT_3_YEARS     = 9;

export const TAKE_A_BREAK = 1;
export const SELF_EXCLUSION = 2;

const CONTDOWN_TIMER = 10

class SelfExclusion extends Component {
    constructor(props) {
        super(props);
        this.defaultChoice = props.type === SELF_EXCLUSION ? LIMIT_6_MONTHS : LIMIT_1_DAY;
        this.checkboxId = "checkbox-self-exclusion";
        this.redirectURL = '/customer-support/responsible-gaming';
    }

    /**
     * help poedit to scan and find the text
     */
    t(string){
        return string;
    }

    state = {
        isValidKYC: false,
        activeChoice: false,
        agree: 0,
        needConfirmation: false,
        buttonTime: CONTDOWN_TIMER,
        inactiveButton: true,
    };

    getPageItem(uri) {

        //remove hash if any
        if (uri.indexOf('#') > -1) {
            uri = uri.split("#")[0];
        }

        uri = uri.substring(1).split('/');
        let item = {};
        Object.keys(this.props.pages).forEach((key) => {
            if (this.props.pages[key].code === uri[0]) {
                item = this.props.pages[key];
                return true;
            }
        });

        return item;
    }

    componentDidMount(){
        this.checkKYC()
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.profile.accountVerified !== prevProps.profile.accountVerified){
            this.checkKYC()
        }

        // if(this.state.isValidKYC !== prevState.isValidKYC){
        //     if (this.props.type === TAKE_A_BREAK || (this.props.type === SELF_EXCLUSION && this.state.isValidKYC)) {
        //         this.setState({
        //             ...this.state,
        //            activeChoice: this.defaultChoice,
        //            agree: this.getRealAgree(),
        //         })
        //     }
        // }

        if(this.state.needConfirmation !== prevState.needConfirmation){
            this.scrollToTop();
            let buttonCountdown = setInterval(() => {
                if (this.state.buttonTime === 1) {
                    this.setState({
                        ...this.state,
                        inactiveButton: false,
                    });
                    clearInterval(buttonCountdown);
                }else{
                    this.setState({
                        ...this.state,
                        buttonTime: (this.state.buttonTime - 1),
                    });
                }
            }, 1000);

            if(this.state.buttonTime < CONTDOWN_TIMER){
                clearInterval(buttonCountdown);
                this.setState({
                    ...this.state,
                    buttonTime: CONTDOWN_TIMER,
                    inactiveButton: true,
                });
            }
        }
    }

    triggerConfirmation() {
        this.setState({
            ...this.state,
            needConfirmation: !this.state.needConfirmation,
        })
    }

    logoutHandler() {
        this.props.hide();
        this.props.history.push(this.redirectURL);
        this.props.onPageChanged(this.getPageItem(this.redirectURL));
        this.props.onLogout();
    }

    getRealAgree() {
        let el = document.getElementById(this.checkboxId);
        return el ? el.checked : false;
    }

    checkKYC() {
        if(this.props.profile.accountVerified && this.props.profile.accountStatus === 1 && this.props.profile.accountSubStatus === 3){
            this.setState({
                ...this.state,
                isValidKYC: true,
                activeChoice: this.defaultChoice,
                agree: this.getRealAgree(),
            })
        }
    }

    onClickHandler() {
        this.setState({
            ...this.state,
            agree: this.getRealAgree(),
        });

    }

    onClickHandlerChoice(value) {
        this.setState({
            ...this.state,
            activeChoice: value,
        })
    }

    getChoiceLabel(choice) {
        switch (choice) {
            case LIMIT_1_DAY:
                return sprintf(this.props.t("%s day"), 1);
            case LIMIT_3_DAYS:
                return sprintf(this.props.t('%s days'), 3);
            case LIMIT_1_WEEK:
                return sprintf(this.props.t('%s days'),7);
            case LIMIT_6_MONTHS:
                return sprintf(this.props.t('%s months'), 6);
            case LIMIT_1_YEAR:
                return sprintf(this.props.t('%s year'), 1);
            case LIMIT_3_YEARS:
                return sprintf(this.props.t('%s years'), 3);
            default:

        }
    }

    getChoices() {
        let comp = [];
        switch (this.props.type) {
            case SELF_EXCLUSION:
                comp.push(
                    <SelfExclusionChoice value={LIMIT_6_MONTHS}
                        label={this.getChoiceLabel(LIMIT_6_MONTHS)}
                        active={this.state.activeChoice}
                        onClickHandler={this.onClickHandlerChoice.bind(this)}
                        disabled={this.state.needConfirmation}
                        key={"selfExclusionChoice" + LIMIT_6_MONTHS} />
                );
                comp.push(
                    <SelfExclusionChoice value={LIMIT_1_YEAR}
                        label={this.getChoiceLabel(LIMIT_1_YEAR)}
                        active={this.state.activeChoice}
                        onClickHandler={this.onClickHandlerChoice.bind(this)}
                        disabled={this.state.needConfirmation}
                        key={"selfExclusionChoice" + LIMIT_1_YEAR} />
                );
                comp.push(
                    <SelfExclusionChoice value={LIMIT_3_YEARS}
                        label={this.getChoiceLabel(LIMIT_3_YEARS)}
                        active={this.state.activeChoice}
                        onClickHandler={this.onClickHandlerChoice.bind(this)}
                        disabled={this.state.needConfirmation}
                        key={"selfExclusionChoice" + LIMIT_3_YEARS} />
                );
                break;
            case TAKE_A_BREAK:
                comp.push(
                    <SelfExclusionChoice value={LIMIT_1_DAY}
                        label={this.getChoiceLabel(LIMIT_1_DAY)}
                        active={this.state.activeChoice}
                        onClickHandler={this.onClickHandlerChoice.bind(this)}
                        disabled={this.state.needConfirmation}
                        key={"selfExclusionChoice" + LIMIT_1_DAY} />
                );
                comp.push(
                    <SelfExclusionChoice value={LIMIT_3_DAYS}
                        label={this.getChoiceLabel(LIMIT_3_DAYS)}
                        active={this.state.activeChoice}
                        onClickHandler={this.onClickHandlerChoice.bind(this)}
                        disabled={this.state.needConfirmation}
                        key={"selfExclusionChoice" + LIMIT_3_DAYS} />
                );
                comp.push(
                    <SelfExclusionChoice value={LIMIT_1_WEEK}
                        label={this.getChoiceLabel(LIMIT_1_WEEK)}
                        active={this.state.activeChoice}
                        onClickHandler={this.onClickHandlerChoice.bind(this)}
                        disabled={this.state.needConfirmation}
                        key={"selfExclusionChoice" + LIMIT_1_WEEK} />
                );
                break;
            default:
                break;
        }

        return comp;
    }

    saveChoice() {
        if(this.props.type === TAKE_A_BREAK){
            this.props.openTakeABreakModal();
        }else{
            this.props.openSelfExclusionModal();
        }
    }

    getDeleteAccountWarning() {
        let comp = [];

        if (this.expiresIn && this.expiresIn > 0) {
            comp.push(<p key={'verified-account-warning-text-1'}>
                    {this.props.t(sprintf("Your Identity verification period will expire after %s days.", this.expiresIn))}
                </p>
            );
        }
        comp.push(<p key={'verified-account-warning-text-2'}>
            {this.props.t("If you self-exclude, you will be unable to complete it before the deadline and, accordingly to the legal requirements, you account will be closed and the funds will be sent to the State Budget.")}
        </p>);
        return comp;
    }
    getVerifiedAccountWarning() {
        let comp = [];
        comp.push(<p key={'verified-account-warning-text-1'}>
            {this.props.t("Your account and your funds are safe.")}
        </p>
        );
        comp.push(<p key={'verified-account-warning-text-2'}>
            {this.props.t("You can come back when the chosen period expires")}
        </p>);
        return comp;
    }

    KYCWillExpire() {

        if (!this.props.accountVerified) {
            let expireKYCTimestamp = parseInt(this.props.accountCreated) + 2678400; // after 31 days
            let currentTimestamp = new Date().getTime() / 1000;
            this.expiresIn = parseInt((parseInt(expireKYCTimestamp) - currentTimestamp) / 86400);
            switch (this.state.activeChoice) {
                case LIMIT_6_MONTHS:
                case LIMIT_1_YEAR:
                case LIMIT_3_YEARS:
                    return true;
                case LIMIT_1_DAY:
                    if (expireKYCTimestamp - currentTimestamp < 86400) return true;
                    break;
                case LIMIT_3_DAYS:
                    if (expireKYCTimestamp - currentTimestamp < 86400 * 3) return true;
                    break;
                case LIMIT_1_WEEK:
                    if (expireKYCTimestamp - currentTimestamp < 86400 * 7) return true;
                    break;
            }
        }

        return false;
    }

    getWarningText() {

        if (this.KYCWillExpire()) {
            return this.getDeleteAccountWarning();
        } else {
            return this.getVerifiedAccountWarning();
        }
    }

    getSelfExclusion() {
        if (!(TAKE_A_BREAK === this.props.type)) {
            return (
                <div className={"take-a-break-button-support"}>
                    <Menus parent={"simple"} pageType={"profile"} pageCode={"take-a-break"} buttonText={this.props.t("Take a short break instead")} />
                </div>
            );
        }

        return (
            <div className={"self-exclusion-button-support"}>
                <Menus parent={"simple"} pageType={"profile"} pageCode={"self-exclusion"} buttonText={this.props.t("More options")} />
            </div>
        );
    }

    getVerifyKYC(){
        if (this.props.type === TAKE_A_BREAK || (this.props.type === SELF_EXCLUSION && this.state.isValidKYC)) {
            return <CheckboxInput checked={this.state.agree} required disabled={this.state.needConfirmation} inputLabel={this.props.checkbox.label} onClickHandler={this.onClickHandler.bind(this)} id={this.checkboxId} />;
        }else{
            return (
                <div className="InvalidKYC">
                    <small className="text-danger text-center">{this.props.t("Verify your account and identity before you can self-exclude")}</small>
                </div>
            );
        }
    }

    scrollToTop() {
        scroll.scrollToTop(100);
    }

    getPageContent(){
        let warningIcon = Icons.get('exWarning', 'exWarning');
        let warningText = this.getWarningText();

        if(this.props.type === TAKE_A_BREAK){
            return (
                <Fragment>
                    <VelocityComponent duration={100} animation={!this.state.needConfirmation ? { height: 0, opacity: 0 , display: 'none'} : { opacity: 1 , height: "100%", display: 'block'}}>
                        <div className={"self-exclusion-confirmation row" + (!this.state.needConfirmation ? "" : " need-confirmation")}>
                            <div className="col-3">
                                {warningIcon}
                            </div>
                            <div className={"col-9"}>
                                <p>
                                    {this.props.t("Period")}: {this.getChoiceLabel(this.state.activeChoice)}
                                </p>
                                {warningText}
                            </div>
                            <div className="col-12">
                                <div className={"btn btn-danger"} onClick={this.saveChoice.bind(this)}>
                                    {this.props.t("Lock the account for")} {this.getChoiceLabel(this.state.activeChoice)}
                                </div>
                                <div className={"btn btn-outer btn-success"} onClick={this.triggerConfirmation.bind(this)}>
                                    {this.props.t("Cancel")}
                                </div>
                            </div>
                        </div>
                    </VelocityComponent>
                    <TakeABreakModal activeChoice={this.state.activeChoice}/>
                </Fragment>
            );
        }else{
            let buttonIcon = '';
            if(this.state.buttonTime === 1 && !this.state.inactiveButton){
                buttonIcon = <span className={"icon text-center"}>{Icons.get('login-next', 'chp-next-step')}</span>;
            }
            return (
                <Fragment>
                    <VelocityComponent duration={100} animation={!this.state.needConfirmation ? { height: 0, opacity: 0 , display: 'none'} : { opacity: 1, display: 'block'}}>
                        <div className={!this.state.needConfirmation ? 'd-none' : 'd-block' }>
                            <div className="TextContent">
                                <p className={'EmailSent'}>
                                    <Translate text={this.t('We sent you an email with the next steps required to activate your self-exclusion.')}/>
                                </p>
                                <p>
                                    <Translate
                                        text={this.t('By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}${brandNameFull}${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.')}
                                        placeholder={{pinkBoldStart: '<span class="pinkBold">', pinkBoldEnd: '</span>',brandNameFull: window.config.brand_name_full}}
                                    />
                                </p>
                                <p>
                                    <Translate text={this.t('Inform yourself to full extent about this decision. You will not be able to revert it.')} />
                                </p>
                            </div>
                            <div className="AlertContent">
                                <p>
                                    {Icons.get('closeIcon', 'closeIcon')}
                                    <Translate text={this.t('! CANNOT BE REVERTED !')} />
                                </p>
                                <p>
                                    {Icons.get('closeIcon', 'closeIcon')}
                                    <Translate text={this.t('! You will be excluded from all Play Online Network casinos !')} />
                                </p>
                                <p>
                                    {Icons.get('closeIcon', 'closeIcon')}
                                    <Translate text={this.t('! You will not regain access to your account, without exceptions !')} />
                                </p>
                            </div>
                            <Button color="warning" onClick={this.saveChoice.bind(this)} disabled={this.state.inactiveButton} className="nextButton">
                                {buttonIcon}
                                <span className="text text-left">{(this.state.buttonTime === 1 && !this.state.inactiveButton) ? this.props.t('Identity Confirmation') : this.state.buttonTime}</span>
                            </Button>
                            <div className={"btn btn-outer btn-success backButton"} onClick={this.triggerConfirmation.bind(this)}>
                                {this.props.t("Cancel")}
                            </div>
                        </div>
                    </VelocityComponent>
                    <SelfExclusionModal activeChoice={this.state.activeChoice}/>
                </Fragment>
            );
        }
    }

    render() {

        let choices = this.getChoices();

        return (
            <div className={"self-exclusion"}>
                <h1 className={"title text-center"}>{this.props.title}</h1>
                <VelocityComponent duration={100} animation={this.state.needConfirmation ? {height: 0, opacity: 0, display: 'none'} : {opacity: 1, display: 'block'}}>
                    <div className={this.state.needConfirmation ? 'd-none' : 'd-block' }>
                        <p className={"description"}>{this.props.description}</p>
                        <h6 className={"text-left subtitle"}>{this.props.subtitle}</h6>
                        <div className="choices">
                            {choices}
                        </div>
                        { this.getVerifyKYC() }
                        <div className={"btn btn-warning" + (this.state.agree ? "" : " disabled")} onClick={this.triggerConfirmation.bind(this)}>
                            {this.props.t("Lock the account for")} {this.getChoiceLabel(this.state.activeChoice)}
                        </div>
                        { this.getSelfExclusion() }
                    </div>
                </VelocityComponent>
                {this.getPageContent()}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openTakeABreakModal: () => dispatch(stateActions.openTakeABreakModal()),
        openSelfExclusionModal: () => dispatch(stateActions.openSelfExclusionModal()),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onLogout: () => dispatch(stateActions.logout()),
        hide: () => dispatch(stateActions.closeRightMenu()),
    };
};

const mapStateToProps = state => {
    return {
        pages: state.application.menu,
        profile: state.profile,
        accountCreated: state.profile.accountCreated,
        accountVerified: state.profile.accountVerified
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelfExclusion)));
