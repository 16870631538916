import { historyConstants } from "../actions/constants";
import { produce } from "immer";

const initialState = {
  loading: false,
  tournaments: {},
  loadingLeaderboard: false,
  leaderboards: {},
  loadingPrizes: false,
  prizes: {},
};

const leaderBoardReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case historyConstants.LOADING: {
        draft.loading = true;
        break;
      }

      case historyConstants.LOADED: {
        draft.tournaments[action.groupId] = action.data ? action.data : {};
        draft.loading = false;
        break;
      }

      case historyConstants.LOADING_LEADER_BOARD: {
        draft.loadingLeaderboard = true;
        break;
      }
      case historyConstants.LOADED_LEADER_BOARD: {
        draft.leaderboards[action.tournamentId] = action.data ? action.data : {};
        draft.loadingLeaderboard = false;
        break;
      }
      case historyConstants.CLAIMED_PRIZE: {
        if (action.tournamentId && action.groupId) {
          if (typeof draft.tournaments[action.groupId] !== "undefined") {
            draft.tournaments[action.groupId].forEach((t, i) => {
              if (t.id === action.tournamentId) {
                if (t.me && t.me.claim_available) {
                  draft.tournaments[action.groupId][i].me.claim_available = false;
                }
              }
            });
          }

          if (typeof draft.leaderboards[action.groupId] !== "undefined" && draft.leaderboards[action.groupId].me && draft.leaderboards[action.groupId].me.claim_available) {
            draft.leaderboards[action.groupId].me.claim_available = false;
          }
        }

        break;
      }
      case historyConstants.LOAD_PRIZES: {
        draft.loadingPrizes = true;
        break;
      }
      case historyConstants.LOADED_PRIZES: {
        draft.prizes = action.data ? action.data : {};
        draft.loadingPrizes = false;
        break;
      }
      default:
      /* noop */
    }
  });

export default leaderBoardReducer;
