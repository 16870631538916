import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from '@material-ui/icons/Clear';

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as TicketIcon } from "./assets/ticket.svg";

const useStyles = makeStyles({
  root: {
    width: "100%",
    //borderBottom: "1px solid #2545BD",
    position: "relative",
    borderRadius: "14px 14px 0 0",
  },
  rootTicket: {
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    marginBottom: "10px",
    background: "transparent",
  },
  rootTicketLayout: {
    width: "100%",
    position: "relative",
    borderRadius: "7px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap"
  },
  ticketIcon: {
    width: "45px",
    minWidth: "45px",
    borderTopLeftRadius: "7px",
    borderBottomLeftRadius: "7px",
    background: "#001D8A",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  iconSearch: {
    width: "75px",
    minWidth: "75px",
    borderTopRightRadius: "7px",
    borderBottomRightRadius: "7px",
    background: "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  header: {
    borderRadius: "7px 7px 0 0",
    background: "linear-gradient(270deg, #070D29 0%, #14259B 100%)",
    color: "#E9ECFF",
    fontSize: "12px",
    fontWeight: 600,
    padding: "6px 13px",
  },
  input: {
    background: "#fff",
    //background: "#ECF5FF",
    color: "#3A4671",
    width: "100%",
    border: "0",
    height: "45px",
    fontSize: "14px",
    //fontSize: "12px",
    //height: "40px",
    padding: "0 40px 0 10px",
    boxShadow: "inset 0 -7px 9px -7px rgba(37, 69, 189,0.4)",
    borderRadius: "14px 14px 0 0",
    outline: 0,
  },
  inputLayout: {
    boxShadow: "none",
    borderRadius: "0",
    padding: "0 10px",
    borderTop: "1px solid #2545BD",
    borderBottom: "1px solid #2545BD",
    color: "#586682",
    fontWeight: "600"
  },
  icon: {
    position: "absolute",
    bottom: "12px",
    //bottom: "10px",
    right: "10px",
    color: "#2545BD",
    cursor: "pointer",
    "&>.searchIcon path": {
      fill: "#2545BD",
    },
    "&>.clearIcon": {
      position: "relative",
      top: "3px",
    }
  },
  noBrd: {
    borderRadius: "0",
  },
  bTop: {
    borderTop: "1px solid rgba(37, 69, 189,0.4)"
  },
});

const SearchTemplate = props => {
  const classes = useStyles();

  const {
    search,
    onChange,
    onSearch,
    noRadius,
    //title,
    placeholder,
    ticketLayout
  } = props;

  const [state, setState] = React.useState({
    icon: 0,
    value: "",
  });

  React.useEffect(() => {
    if (!search) {
      setState({
        icon: 0,
        value: ""
      });
    }
  }, [search]);

  const handleSearchValueChange = event => {
    onChange(event.target.value);
    if (event.target.value !== state.value) {
      setState({
        icon: 0,
        value: ""
      });
    }
  };
  const handleSubmitSearchValue = () => {
    setState({
      icon: 1,
      value: search
    });
    onSearch();
  };
  const handleClearSearchValue = () => {
    setState({
      icon: 0,
      value: ""
    });
    onChange("", "clear");
  };

  const handleKeyDown = (event) => {
    if (event && event.key === 'Enter') {
      handleSubmitSearchValue();
    }
  }

  let iconContent = null;
  if (state.icon === 1) {
    iconContent = <div className={ticketLayout ? classes.iconSearch : classes.icon} onClick={handleClearSearchValue}>
      <ClearIcon className={"clearIcon"} />
    </div>;
  } else {
    iconContent = <div className={ticketLayout ? classes.iconSearch : classes.icon} onClick={handleSubmitSearchValue}>
      <SearchIcon className={"searchIcon"} />
    </div>;
  }

  if (ticketLayout) {
    return (
      <div className={`${classes.rootTicket}`}>
        <div className={`${classes.rootTicketLayout}`}>
          <div className={classes.ticketIcon}><TicketIcon /></div>
          <input
            onChange={handleSearchValueChange}
            onKeyDown={handleKeyDown}
            value={search}
            type="text"
            placeholder={placeholder}
            className={`${classes.input} ${classes.inputLayout}`}
          />
          {iconContent}
        </div>
      </div>
    );
  }

  return (
    <div className={`${classes.root} ${noRadius ? `${classes.noBrd} ${classes.bTop}` : ''}`}>
      {/*<div className={classes.header}>{title}</div>*/}
      <input
        onChange={handleSearchValueChange}
        onKeyDown={handleKeyDown}
        value={search}
        type="text"
        placeholder={placeholder}
        className={`${classes.input} ${noRadius ? classes.noBrd : ''}`}
      />
      {iconContent}
    </div>
  );
};

SearchTemplate.propTypes = {
  search: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

SearchTemplate.defaultProps = {
  search: "",
  title: "TITLE",
  placeholder: "PLACEHOLDER",
  onChange: () => { },
  onSearch: () => { },
};

export default React.memo(SearchTemplate);
