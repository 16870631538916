import React from 'react';
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import "../utils/EvolutionGaming";
import Category from "../views/Category";
import Table from "../views/Table";
import { appInitialize, subscribe, unsubscribe } from "../store/actions/app";

const LayoutWeb = (props) => {
  const { appInitialize, subscribe, unsubscribe } = props;

  React.useEffect(() => {
    appInitialize();
    subscribe();

    return () => {
      unsubscribe();
    }
  }, []); // eslint-disable-line

  return (
    <Switch>
      <Route exact path="/cazino_live/:category/:table"><Table /></Route>
      <Route exact path="/cazino_live/:category?"><Category /></Route>
    </Switch>
  );
}

const actionCreators = { appInitialize, subscribe, unsubscribe };

export default connect(null, actionCreators)(LayoutWeb);