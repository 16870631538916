import React from 'react';
import { withRouter } from "react-router-dom";
import MatchDetails from "./MatchDetails";

const InfoMatch = (props) => {
  const { mType, idMatch } = props.match.params;

  if (!mType || !idMatch) return null;

  return (<MatchDetails mType={mType} idMatch={idMatch} infoMatch={true} />);
}

export default withRouter(InfoMatch);