import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import "./main-view-dialog.scss";
import EvBusMain from "../../utils/evbus";
import MainView from "./main-views";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MainViewDialog = (props) => {

  const [dialog, setDialog] = React.useState({
    open: false,
    path: ""
  });

  const onClose = () => {
    setDialog({
      open: false,
      path: ""
    });
  };

  React.useEffect(() => {
    const handleOpenEvent = ({ path }) => {
      setDialog({
        open: true,
        path: path
      });
    };
    const handleCloseEvent = () => {
      setDialog({
        open: false,
        path: ""
      });
    }

    EvBusMain.on("OPEN-TOURNAMENTS-GROUPS-DIALOG", handleOpenEvent);
    EvBusMain.on("CLOSE-TOURNAMENTS-GROUPS-DIALOG", handleCloseEvent);

    return () => {
      EvBusMain.remove("OPEN-TOURNAMENTS-GROUPS-DIALOG", handleOpenEvent);
      EvBusMain.remove("CLOSE-TOURNAMENTS-GROUPS-DIALOG", handleCloseEvent);
    }
  }, []);

  if (!dialog.open) return null;

  return (
    <Dialog
      open={dialog.open}
      onClose={onClose}
      TransitionComponent={Transition}
      className={`tournaments-groups-main-view-dialog ${window.location.pathname.indexOf("/play/") > -1 ? 'over' : ''}`}
    >
      <MainView onClose={onClose} path={dialog.path} />
    </Dialog>
  );
}

export default MainViewDialog;