import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import BetSelect from "../../BetSelect";
import crests from "../../../utils/crests";
import { showMatch } from "../../../utils/betUtils";
import { ReactComponent as Avantaj } from "../../../assets/icons/meciuri.svg";
import { debug } from "../../../utils";
import { formatDateWithTime } from "../../../utils/formatters";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "0 8px",
    position: "relative",
    fontFamily: "OpenSans, 'Open Sans'"
  },
  header: {
    textAlign: "center",
    marginBottom: "5px"
  },
  betWrapper: {
    display: "block",
    borderRadius: "5px",
    background: "linear-gradient(90deg, #1F83E6 0%, #06D2BD 100%)",
    padding: "6px 14px 8px 14px",
    minHeight: "64px",
    position: "relative"
  },
  title: {
    fontSize: "9px",
    color: "#fff",
    lineHeight: "13px",
    marginBottom: "2px"
  },
  date: {
    display: "inline-block",
    color: "#192640",
    backgroundColor: "#FFC400",
    padding: "2px 6px",
    fontSize: "7px",
    fontWeight: "bold",
    borderRadius: "3px",
    textTransform: "uppercase",
    fontFamily: "OpenSans, 'Open Sans'"
  },
  matchVs: {
    fontSize: "10px",
    fontFamily: "OpenSans, 'Open Sans'",
    padding: "0 5px",
    position: "relative",
    color: "#fff",
    top: "2px",
    textShadow: "0px 0px 15px #000000"
  },
  matchName: {
    fontSize: "15px",
    fontFamily: "OpenSans, 'Open Sans'",
    color: "#fff",
    textShadow: "0px 0px 15px #000000",
    display: "inline-block",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "120px",
    overflow: "hidden",
    verticalAlign: "middle"
  },
  matchFlag: {
    display: "inline-block!important",
    position: "relative",
    top: "2px",
    height: "16px",
    margin: "0 5px"
  },
  question: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "22px"
  },
  subquestion: {
    color: "#ccc"
  },
  avantajSvg: {
    position: "absolute",
    top: "-4px",
    right: "-5px"
  }
});

const BetBanner = props => {
  const classes = useStyles();
  const { match, i18n } = props;

  //debug("betbanner match", match);

  if (!match) return null;

  const lang = i18n.language;
  const logoTeam1 = match && match.idSport === "1" ? crests(match.team1Name, match, 1) : "";
  const logoTeam2 = match && match.idSport === "1" ? crests(match.team2Name, match, 2) : "";

  const teams = (
    <React.Fragment>
      <div>
        {logoTeam1 !== "" && <img className={classes.matchFlag} src={logoTeam1} alt="" />}
        <span className={classes.matchName}>{match.team1Name}</span>
      </div>
      <div className={classes.matchVs}>vs</div>
      <div>
        <span className={classes.matchName}>{match.team2Name}</span>
        {logoTeam1 !== "" && <img className={classes.matchFlag} src={logoTeam2} alt="" />}
      </div>
    </React.Fragment>
  );

  const history = useHistory;
  const handleClick = () => {
    showMatch(match, history)();
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.date}>{formatDateWithTime(parseInt(match.matchDateTime, 10), lang)}</div>
        <div
          className={`${classes.match} d-flex flex-row flex-nowrap align-items-center justify-content-center`}
          onClick={handleClick}
        >
          {teams}
        </div>
      </div>
      <div className={classes.betWrapper}>
        {match && (
          <BetSelect
            idMatch={match.idMatch}
            mType={match.mType}
            titleColor="#FFF"
            bannerWP={true}
            maxOutcomes={3}
            lockedBet
          />
        )}
        {match && match.winnerAdv && <Avantaj className={classes.avantajSvg} />}
      </div>
    </div>
  );
};

BetBanner.propTypes = {
  match: PropTypes.object
};

BetBanner.defaultProps = {
  match: null
};

export default React.memo(withTranslation()(BetBanner));
