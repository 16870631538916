import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';

import "./main-view.scss";
import * as storeActions from "../../store/actions";
import BonusWidget from "../components/BonusWidget";
import Icons from "../../assets/Icons/Icons";
import DepositTicketDialog from "./deposit-ticket";
import FluxAML from "./flux-aml";
import AccountLimits from "./account-limits";

const MainView = (props) => {
  const { serial, pin, type, amount, bonuses, onClose, t, limits, player } = props;

  const [state, setState] = React.useState({
    bonuses: {},
    bonus_available_id: null,
    flux_aml: false,
    start: false,
    limits: false,
    extra: {},
  });

  React.useEffect(() => {
    if (props.authenticated) {
      props.getEligibleBonuses();
    }
  }, []); // eslint-disable-line

  const checkOverLimits = (player, limits, amount) => {
    let isValidKYC = false;
    if (window.config.platformType.toString() === "1") {
      isValidKYC = player.accountVerified && player.accountStatus === 1 && player.accountSubStatus === 3;
    } else {
      isValidKYC = player.verified && player.account_status === 1 && player.account_sub_status === 3;
    }

    if (limits !== null || !isValidKYC) {
      if (limits) {
        let limitId = limits["1"] && limits["1"].id ? "1" : limits["2"] && limits["2"].id ? "2" : limits["3"] && limits["3"].id ? "3" : null;
        let limit = limitId ? limits[limitId] : null;

        let limitAmmount = 0;
        if (!isValidKYC && limits["KYC"] && limits["KYC"]["limit_amount"]) {
          limitAmmount = limits["KYC"]["limit_amount"];
        } else if (limit && limit["limit_amount"]) {
          limitAmmount = limit["limit_amount"];
        }

        if (amount > limitAmmount) {
          return true;
        }
      }
    }
    return false;
  }

  React.useEffect(() => {
    if (serial && pin && type && amount) {
      let toSet = {
        bonuses: {},
        bonus_available_id: null,
        flux_aml: false,
        start: true,
        limits: false,
      };

      let amlLimit = 9500;
      if (window.config.amlCheckLimit && window.config.amlCheckLimit !== "") {
        let configLimit = parseFloat(window.config.amlCheckLimit);
        if (!isNaN(configLimit)) amlLimit = configLimit
      }
      if (parseFloat(amount) > amlLimit) {
        toSet = {
          bonuses: {},
          bonus_available_id: null,
          flux_aml: true,
          start: false,
          limits: false,
        }
      }

      if (checkOverLimits(player, limits, amount)) {
        toSet.flux_aml = false;
        toSet.start = false;
        toSet.limits = true;
      }

      setState(toSet);
    } else {
      props.history.push("/");
    }
  }, [bonuses, serial, pin, type, amount, props.history, limits, player]);

  const onCloseFluxAml = (ev, extra) => {
    if (extra) {
      setState(v => {
        return {
          ...v,
          flux_aml: false,
          start: true,
          extra: extra
        }
      });
    } else {
      setState(v => {
        return {
          ...v,
          flux_aml: false,
        }
      });
      if (typeof onClose === "function") {
        return onClose();
      }
      props.history.push("/");
    }
  };

  const onBack = () => {
    setState(v => {
      if (v.start) {
        return {
          ...v,
          start: false,
          bonus_available_id: null,
          limits: false,
          flux_aml: false,
        }
      }
      return v;
    });
    if (typeof onClose === "function") {
      return onClose();
    }
    props.history.push("/");
  };

  const onCloseDialog = () => {
    setState(v => {
      if (v.start) {
        return {
          ...v,
          start: false,
          bonus_available_id: null,
          limits: false,
          flux_aml: false,
        }
      }
      return v;
    });
    if (typeof onClose === "function") {
      return onClose();
    }
  };

  const onClickedBonus = (bonus_available_id, bonus) => {
    setState(v => {
      let start = false;
      let flux_aml = false;

      let amlLimit = 9500;
      if (window.config.amlCheckLimit && window.config.amlCheckLimit !== "") {
        let configLimit = parseFloat(window.config.amlCheckLimit);
        if (!isNaN(configLimit)) amlLimit = configLimit
      }

      if (parseFloat(amount) > amlLimit) {
        flux_aml = true;
      } else {
        start = true;
      }

      return {
        ...v,
        flux_aml: flux_aml,
        start: start,
        bonus_available_id: bonus_available_id > 0 ? bonus_available_id : null
      }
    });
  };

  return (
    <div className="deposit-ticket-main-view">
      <div className="title-bar titleBar MainMenu">
        <div>
          <ButtonBase
            className={"returnButton"}
            onClick={onBack}
            color="inherit"
            aria-label="Return">
            {Icons.get('arrowBack', 'ArrowBackIcon')}
          </ButtonBase>
          <div className="title">{t("Deposit Ticket")}</div>
        </div>
      </div>
      <div className="content">
        {Object.keys(state.bonuses).length > 0 && <BonusWidget type={'list'} bonuses={state.bonuses} onClickedBonus={onClickedBonus} depositValue={amount} cta_text={props.t("Deposit with bonus")} />}
        {Object.keys(state.bonuses).length === 0 &&
          <div className="empty_wrapper">
            <div className={"BonusWidget loading"}>
              <div className="details  no-bonus">
                <div className="img" style={{ backgroundImage: "url(https://" + window.config.cdn_address + "/public/bonus/img-bonuscard-nobonus-luminosity@3x.png)" }} />
                <span></span>
                <div className="message">
                  {t("You cannot get other BONUSES right now. Please try again later.")}
                </div>
              </div>
            </div>
          </div>}
      </div>

      {state.flux_aml &&
        <FluxAML
          open={state.flux_aml}
          onClose={onCloseFluxAml}
        />
      }

      {state.start &&
        <DepositTicketDialog
          open={state.start}
          onClose={onBack}
          serial={serial}
          pin={pin}
          type={type}
          amount={amount}
          extra={state.extra}
          bonusAvailableId={state.bonus_available_id}
        />
      }

      {state.limits && <AccountLimits open={state.limits} onClose={onCloseDialog} amount={amount} limits={limits} />}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    serial: state.depositTicket.depositTicket.serial,
    pin: state.depositTicket.depositTicket.pin,
    type: state.depositTicket.depositTicket.type,
    amount: state.depositTicket.depositTicket.amount,
    limits: state.depositTicket.depositTicket.limits,
    player: state.profile,

    authenticated: state.authentication.auth_type === "user",
    bonuses: state.application.eligibleBonuses,
    freeBets: state.freeBets.freeBets,
    freeSpins: state.freeSpins.freeSpins,
  };
};


const actionCreators = {
  getEligibleBonuses: storeActions.getEligibleBonuses,
  getFreeBets: storeActions.freeBetsFetch,
  getFreeSpins: storeActions.freeSpinsFetch,
};

export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(MainView)));