import React from "react";
import PropTypes from "prop-types";
import _uniqueId from "lodash/uniqueId";
import { withTranslation } from "react-i18next";

import "./TicketSwitch.scss";

import { ReactComponent as Online } from "../assets/icon-betslip-online.svg";
import { ReactComponent as Location } from "../assets/house.svg";

const TicketSwitch = ({ online, onChange, isDisabled, t }) => {
  const [id] = React.useState(_uniqueId("react-switch-new-ticket-switch-"));

  //console.log("checked", online);

  //const [checked, setChecked] = React.useState(status);
  const handleChange = () => {
    //setChecked(!checked);
    onChange(!online);
  };

  const clsTheme = `rsc-ticket-switch ${isDisabled ? 'rsc-ticket-switch-disabled' : ''}`;

  const offText = (
    <span>
      <Online /> {t("ONLINE TICKET")}
    </span>
  );

  const onText = (
    <span>
      <Location /> {t("TICKET IN SHOP")}
    </span>
  );

  return (
    <div className={clsTheme}>
      <input checked={!online} onChange={handleChange} className="rsc" id={id} type="checkbox" />
      <label className="rsl" htmlFor={id}>
        <div className="rls-icon">
          <Online /> {t("ONLINE TICKET")}
        </div>
        <div className="rls-icon">
          <Location /> {t("TICKET IN SHOP")}
        </div>
        <span className={`rsb`}>{online ? offText : onText}</span>
      </label>
    </div>
  );
};

TicketSwitch.propTypes = {
  status: PropTypes.bool,
  onChange: PropTypes.func
};

TicketSwitch.defaultProps = {
  status: false,
  onChange: () => { }
};

export default withTranslation()(TicketSwitch);
