import React from 'react';
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./egt_jackpot_details.scss";

import JackpotV2, { WinHistory, requestJackpots } from "../../../common/jackpot";

const EgtJackpot = (props) => {
  const { data, jackpotList } = props;

  const isDesktop = useMediaQuery("(min-width:992px)");
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (window.config.platformType.toString() === "1") {
      try {
        props.requestJackpots();
      } catch (err) {/* noop */ }
    }
  }, []); // eslint-disable-line

  const onClickHandler = () => { setShowModal(true) };

  if (!data.jackpot_id) return null
  if (typeof jackpotList[data.jackpot_id.toString()] === "undefined") return null;

  const jackpot_id = data.jackpot_id.toString();
  const jackpot = jackpotList[jackpot_id];
  const theme_id = data.theme_id ? parseInt(data.theme_id, 10) : 0;

  return <React.Fragment>
    <JackpotV2
      key={isDesktop ? "desktop" : "mobile"}
      integration={isDesktop ? "desktop" : "mobile"}
      jackpotId={theme_id}
      collectionId={theme_id}
      front={false}
      onClick={!isDesktop ? onClickHandler : null}
      name={"EGT"}
      value={jackpot.maxValue}
      jackpot={jackpot}
      currency={jackpot.currency}
      className="component-egt_jackpot_details"
    />
    {!isDesktop && <WinHistory
      show={showModal}
      jackpotId={jackpot_id}
      closeModal={() => setShowModal(false)}
      name={"EGT Spades"}
    />}
  </React.Fragment>;
}

const mapStateToProps = (state, props) => {
  return {
    jackpotList: state.jackpotList.list,
  }
};

const mapActions = {
  requestJackpots,
}

export default connect(mapStateToProps, mapActions)(EgtJackpot);