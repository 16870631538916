import React from 'react';
import { connect } from "react-redux";

// import FavouriteGames from "../../../Games/FavouriteGames/FavouriteGames";
// import Games from "../../../Games/Games";
// import HotEarnings from "../../../Games/HotEarnings/HotEarnings";
import Icons from "../../../../assets/Icons/Icons";
// import PromotedGames from "../../../Games/PromotedGames/PromotedGames";
// import JackpotGames from "./../../../Games/JackpotGames/JackpotGames";
// import JackpotsLobbySection from "./../../../Jackpots/LobbySection";
import { withTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import Collections from "../../../Collections/Collections";
import HappyHourFront from "../../../HappyHours/views/index-front";

const Lobby = (props) => {

    let iconFire = Icons.get('fire', 'all-casino-games');

    let isHHRunning = props.hhRunning["online-slots"] ? true : false;

    return (
        <div className={"LobbyScreen"}>
            <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={500}>
                <div>
                    {isHHRunning && <h1 style={{ maxWidth: "1280px", margin: "20px auto 0" }}>
                        <span className="icon">{iconFire}</span>
                        <span className="text">{props.t("Events in progress")}</span>
                        <div style={{
                            clear: "both",
                        }}></div>
                    </h1>}
                    <HappyHourFront event_type="online-slots" />
                    <Collections />
                </div>
            </Animated>
        </div>
    );
};

const mapStateToProps = state => ({
    hhRunning: state.happyHour.running,
});

export default withTranslation()(connect(mapStateToProps)(Lobby));