import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import FilterHeader from "../components/ModalHeader/FilterHeader";
import { withTranslation } from "react-i18next";

import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getBetsState } from "../store/selectors/betData";

import { ReactComponent as FavIcon } from "../assets/star.svg";
import FilterItems from "../components/Filter/FilterItems";

import NavList from "../components/NavList";

import {
  queryLiveMatches,
  queryPrematchMatches,
  queryLiveTournaments,
  queryPrematchTournaments
} from "../store/selectors/search";

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: "transparent",
    border: "0",
    marginBottom: "15px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "0 auto 15px"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "#A1BED4",
    border: "1px solid #A1BED4",
    fontFamily: "OpenSans",
    fontSize: "12px",
    fontWeight: "600",
    paddingLeft: "20px",
    paddingRight: "20px",
    textTransform: "uppercase",
    color: "#17214D",
    borderRadius: "5px",
    minHeight: 45,
    height: 45,
    "&$expanded": {
      minHeight: 45
      //margin: '12px 0',
    }
  },
  content: {
    "&$expanded": {
      //margin: '12px 0',
    }
  },
  expandIcon: {
    "&$expanded": {
      transform: "none",
      "& .icon": {
        transform: "rotate(180deg)"
      }
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles({
  root: {},
  fav: {
    position: "relative",
    top: "1px",
    display: "inline-block",
    marginRight: "13px",
    fill: "#fff"
  },
  noResults: {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "600"
  },
  mb: {
    marginBottom: "1rem"
  },
  p10: {
    padding: "10px 0 0"
  },
  icon: {
    width: "18px",
    height: "18px",
    transform: "rotate(0deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:first-child": {
      position: "relative",
      top: 4
    },
    "&:last-child": {
      position: "relative",
      top: -4
    }
  },
  mg: {
    margin: "15px 14px"
  },
  w100: {
    width: "100%"
  }
});

const groupItems = [
  {
    name: "Sport",
    id: "sport"
  },
  {
    name: "Category",
    id: "category"
  }
];

const Filter = props => {
  const classes = useStyles();

  const {
    history,
    //query,
    liveMatches,
    prematchMatches,
    liveTournaments,
    prematchTournaments,
    t
  } = props;

  //console.log("filter props", props);

  const goBack = () => {
    history.goBack();
  };

  const [panels, setPanels] = React.useState({
    matches: true,
    leagues: true
  });

  const handleChange = type => (event, newExpanded) => {
    const newPanels = { ...panels };
    newPanels[type] = newExpanded;
    setPanels(newPanels);
  };

  const handleFav = type => event => {
    alert("fav " + type);
    event.stopPropagation();
  };

  const allMatches = [...liveMatches, ...prematchMatches];
  const allLeagues = [...liveTournaments, ...prematchTournaments];

  const [groupBy, setGroupBy] = React.useState(""); // eslint-disable-line
  const handleGroupBy = React.useCallback(
    value => {
      if (groupBy !== value) {
        setGroupBy(value);
      }
    },
    [groupBy]
  );

  return (
    <div className={classes.root}>
      <FilterHeader goBack={goBack} />
      <FilterItems
        padding="21px 19px 21px 19px"
        active={0}
        items={groupItems}
        onChange={handleGroupBy}
      />
      <div className={classes.mg}>
        <ExpansionPanel
          square
          expanded={panels.matches}
          onChange={handleChange("matches")}
          TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        >
          <ExpansionPanelSummary
            aria-controls="matches-content"
            id="matches-header"
            expandIcon={
              <div className="d-flex flex-column">
                <ExpandLessIcon className={`${classes.icon} icon`} />
                <ExpandMoreIcon className={`${classes.icon} icon`} />
              </div>
            }
          >
            <FavIcon className={classes.fav} onClick={handleFav("matches")} /> {t("Matches")}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={`${classes.p10} ${classes.w100}`}>
              {allMatches.length > 0 ? (
                <NavList type="match" items={allMatches} />
              ) : (
                <React.Fragment>
                  <div className={`${classes.noResults} ${classes.mb}`}>{t("No results")}</div>
                  <div className={classes.noResults}>
                    {t("Enter a team or championship name to start the search.")}
                  </div>
                </React.Fragment>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={panels.leagues}
          onChange={handleChange("leagues")}
          TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        >
          <ExpansionPanelSummary
            aria-controls="leagues-content"
            id="leagues-header"
            expandIcon={
              <div className="d-flex flex-column">
                <ExpandLessIcon className={`${classes.icon} icon`} />
                <ExpandMoreIcon className={`${classes.icon} icon`} />
              </div>
            }
          >
            <FavIcon className={classes.fav} onClick={handleFav("leagues")} />
            {t("Leagues")}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={`${classes.p10} ${classes.w100}`}>
              {allLeagues.length > 0 ? (
                <NavList type="league" items={allLeagues} />
              ) : (
                <React.Fragment>
                  <div className={`${classes.noResults} ${classes.mb}`}>{t("No results")}</div>
                  <div className={classes.noResults}>
                    {t("Enter a team or championship name to start the search.")}
                  </div>
                </React.Fragment>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>
  );
};

Filter.propTypes = {
  query: PropTypes.string,
  history: PropTypes.object
};

Filter.defaultProps = {
  query: "",
  history: { goBack: () => { } }
};

const mapStateToProps = state => {
  const bst = getBetsState(state);

  return {
    query: bst.search.query,
    liveMatches: queryLiveMatches(state),
    prematchMatches: queryPrematchMatches(state),
    liveTournaments: queryLiveTournaments(state),
    prematchTournaments: queryPrematchTournaments(state)
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(Filter)));
