import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import { formatValue, formatTotalOddsValue } from "../../utils/formatters";
import MultiplierIcon from "./assets/multiplier-icon.png";

const useStyles = makeStyles({
	ticketDetails: {
		//margin: "7px 11px",
		padding: "11px 22px",
		backgroundColor: "#B7CDD8",
		borderRadius: 12,
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-between"
	},
	ticketDetailsItem: {
		flex: "1 1 0",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		alignContent: "center"
	},
	ticketDetailsTitle: {
		fontSize: 9,
		fontWeight: 600,
		margin: "0 auto",
		color: "#586682"
	},
	ticketDetailsContent: {
		position: "relative",
		fontSize: 11,
		fontWeight: 700,
		margin: "0 auto",
		color: "#17214D",
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center",
		whiteSpace: "nowrap",
	},
	small: {
		fontWeight: "normal",
		fontSize: "10px",
		display: "inline-block",
		paddingRight: "3px",
	},
	icon: {
		position: "absolute",
		display: "inline-block",
		height: "15px",
		top: "1px",
		left: "-18px",
	},
	freeBetName: {
		display: "inline-block",
		maxWidth: "80px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	dinb: {
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "center"
	}
});

const Details = ({ ticket, winnerFun, t }) => {
	const classes = useStyles();

	let stake_is_free_bet = false;
	let free_bet_name = "";

	let amount = ticket.status === "OPEN" ? ticket.winning : ticket.payout;

	if (ticket && ticket.clientVal && typeof ticket.clientVal.free_bet_code !== "undefined") {
		stake_is_free_bet = true;
		//free_bet_name = ticket.clientVal.free_bet_name ? ticket.clientVal.free_bet_name : "FREE BET";
		free_bet_name = "FREE BET";

		if (typeof ticket.clientVal.free_bet_redeemable !== "undefined") {
			let free_bet_redeemable = ticket.clientVal.free_bet_redeemable === 1;

			if (!free_bet_redeemable) {
				amount = amount - ticket.stake;
			}
		}
	}

	return (
		<div className={classes.ticketDetails}>
			{ticket.type === "SIMPLU" && <div className={classes.ticketDetailsItem}>
				<div className={classes.ticketDetailsTitle}>{t("Total odds")}</div>
				<div className={classes.ticketDetailsContent}>
					{winnerFun && ticket.eval_res && ticket.eval_res.event_balance_multiplier > 1 && <img src={MultiplierIcon} alt="" className={classes.icon} />}
					{winnerFun && ticket.eval_res && ticket.eval_res.event_balance_multiplier > 1 ? <div className={classes.small}>{ticket.eval_res.event_balance_multiplier}x </div> : ''}
					{formatValue(ticket.totalOdd)}
				</div>
			</div>}
			<div className={classes.ticketDetailsItem}>
				<div className={classes.ticketDetailsTitle}>{stake_is_free_bet && free_bet_name ? free_bet_name : t("Stake")}</div>
				<div className={classes.ticketDetailsContent}>{formatValue(ticket.stake, true)} {winnerFun ? "W" : "Lei"}</div>
			</div>
			<div className={classes.ticketDetailsItem}>
				<div className={classes.ticketDetailsTitle}>
					{ticket.status === "OPEN" ? t("Potential Winning") : t("Winning")}
				</div>
				<div className={classes.ticketDetailsContent}>
					{formatValue(amount)} {winnerFun ? "W" : "Lei"}
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(Details);
