import React, { useCallback, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import evbus from "./../../../utils/evbus";

import { gsap, TimelineMax, TweenMax, Power3, Power2, Linear, Draggable } from "gsap/all";

import BetSelect from "../BetSelect";
import { showMatch } from "../../utils/betUtils";
import crests from "../../utils/crests";
import { ReactComponent as LockOutlinedIcon } from "../../assets/lock.svg";

//import { getSuperBannerMatch } from "../../store/selectors/betData";
import { formatDateWithTime } from "../../utils/formatters";

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, Power2, Power3, Linear);

const useStyles = makeStyles({
  root: {
    //width: "100%",
    padding: "0 20px",
    position: "relative",
    bottom: "-145px",
    fontFamily: "OpenSans",
    zIndex: 10,
    boxSizing: "border-box"
  },
  rootShown: {
    bottom: 0
  },
  full: {
    paddingLeft: "calc(9% + 10px)",
    paddingRight: "calc(9% + 10px)",
    paddingBottom: "10px",
    paddingTop: "20px"
  },
  betWrapper: {
    display: "block",
    borderRadius: "5px",
    background: "linear-gradient(90deg, #1F83E6 0%, #06D2BD 100%)",
    padding: "6px 14px 8px 14px",
    minHeight: "64px",
    boxSizing: "border-box",
    boxShadow: "0px 3px 6px rgba(0,0,0,.5)",
    pointerEvents: "all"
  },
  betWrapperSm: {
    padding: "6px 14px 8px 14px"
  },
  lightPass: {
    background: "linear-gradient(90deg, #FF1D96, #247DFF, #FF1D96, #247DFF)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "400% 400%",
    animation: "buttonAnim 10s ease infinite"
  },
  betWrapperText: {
    fontSize: 14,
    fontWeight: 600,
    padding: "6px",
    lineHeight: "24px",
    borderRadius: "5px",
    transform: "translate3d(0,0,0)"
  },
  betWrapperTextFull: {
    fontSize: "calc(14px + (25 - 14) * ((100vw - 960px) / (2560 - 960)))",
    padding: "20px 50px 20px"
  },
  question: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "18px",
    textShadow: "0px 2px 1px #000000D1"
  },
  questionFull: {
    fontSize: "calc(18px + (30 - 18) * ((100vw - 960px) / (2560 - 960)))"
  },
  subQuestion: {
    color: "#D5D5D5",
    fontWeight: 500,
    fontSize: "12px",
    textShadow: "0px 2px 2px #000000C7"
  },
  subQuestionFull: {
    fontSize: "calc(12px + (20 - 12) * ((100vw - 960px) / (2560 - 960)))"
  },
  text: {
    textAlign: "left",
    paddingLeft: "24px",
    paddingBottom: "8px"
    //transition: "transform 300ms ease-in-out",
    //transform: "translate3d(0, 135px, 0)",
    //willChange: "transform"
  },
  data: {
    //transition: "transform 300ms ease-in-out",
    //transform: "translate3d(0, 135px, 0)",
    //willChange: "transform"
  },
  delayedTransition: {
    transition: "transform 300ms ease-in-out 300ms"
  },
  enter: {
    transform: "translate3d(0, 0, 0)"
  },
  bg: {
    opacity: 0,
    pointerEvents: "none",
    position: "absolute",
    bottom: "22px",
    left: "0",
    right: "0",
    top: "0",
    background:
      "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.4) 15%, rgba(0,0,0,.6) 50%, rgba(15, 52, 90, 0.8) 100%)",
    transition: "opacity 0.5s ease-in-out"
  },
  bgFull: {
    bottom: "0"
  },
  matchVs: {
    //fontSize: "10px",
    fontSize: "calc(10px + (14 - 10) * ((100vw - 960px) / (2560 - 960)))",
    fontFamily: "OpenSans",
    padding: "0 5px",
    position: "relative",
    color: "#fff",
    top: "2px",
    textShadow: "0px 0px 15px #000000"
  },
  matchName: {
    fontWeight: "normal",
    //fontSize: "16px",
    fontSize: "calc(16px + (20 - 16) * ((100vw - 960px) / (2560 - 960)))",
    fontFamily: "OpenSans",
    color: "#fff",
    textShadow: "0px 0px 15px #000000"
  },
  matchFlag: {
    position: "relative",
    top: "2px",
    height: "16px",
    margin: "0 5px"
  },
  header: {
    textAlign: "center",
    marginBottom: "5px"
  },
  title: {
    fontSize: "9px",
    color: "#fff",
    lineHeight: "13px",
    marginBottom: "2px"
  },
  date: {
    display: "inline-block",
    color: "#192640",
    backgroundColor: "#FFC400",
    padding: "2px 6px",
    //fontSize: "7px",
    fontSize: "calc(11px + (12 - 11) * ((100vw - 960px) / (2560 - 960)))",
    fontWeight: "bold",
    borderRadius: "3px",
    textTransform: "uppercase",
    fontFamily: "OpenSans"
  },
  betsLocked: {
    height: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  lockIcon: {
    color: "#fff",
    "& svg": {
      fill: "#fff"
    }
  },
  lockText: {
    color: "#fff",
    fontSize: "12px"
  },
  noMatch: {
    position: "absolute",
    background: "rgba(0,0,0,.01)",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  live: {
    display: "inline-block",
    color: "#FFFFFF",
    backgroundColor: "#FF196E",
    padding: "2px 6px",
    //fontSize: "7px",
    fontSize: "calc(11px + (12 - 11) * ((100vw - 960px) / (2560 - 960)))",
    fontWeight: "bold",
    borderRadius: "3px",
    textTransform: "uppercase",
    fontFamily: "OpenSans"
  }
});

const FootballBanner = ({ pMatch, eventName, width, hasMatch, t, i18n }) => {
  const classes = useStyles();
  const history = useHistory();

  const lang = i18n.language;

  const root = React.createRef();
  const bgRef = React.useRef();

  const [visible, setVisible] = useState(false);
  const [match, setMatch] = useState(pMatch);

  useEffect(() => {
    setMatch(pMatch);
  }, [pMatch]);

  const onClick = useCallback(
    () => {
      showMatch(match, history)();
    },
    [match, history]
  )

  const onEnter = useCallback(
    tl => {
      //console.log("enter", tl, root);

      if (root.current === null) {
        //console.log("null root");
        return;
      }

      let pos = "-=1";
      if (tl === null) {
        tl = gsap;
        pos = "";
      }

      tl.to(
        root.current,
        {
          duration: 1,
          y: -145
        },
        pos
      );

      setVisible(() => true);

      tl.eventCallback("onReverseComplete", () => {
        //console.log("fotball banner hidden");
        setVisible(() => false);
      });

      if (bgRef && bgRef.current) {
        bgRef.current.style.opacity = "1";
      }
    },
    [root]
  );

  const onExit = useCallback(
    tl => {
      //console.log("exit", tl, root);

      if (root.current === null) {
        //console.log("null root");
        return;
      }

      tl.to(
        root.current,
        {
          duration: 1,
          y: 145
        },
        "-=1"
      );

      tl.eventCallback("onComplete", () => {
        //console.log("football banner hidden");
        setVisible(() => false);
      });

      if (bgRef && bgRef.current) {
        bgRef.current.style.opacity = "0";
      }
    },
    [root]
  );

  React.useEffect(() => {
    evbus.on(eventName + "_click", onClick);
    evbus.on(eventName + "_enter", onEnter);
    evbus.on(eventName + "_exit", onExit);

    return () => {
      evbus.remove(eventName + "_click", onClick);
      evbus.remove(eventName + "_enter");
      evbus.remove(eventName + "_exit");
    };
  }, [eventName, onExit, onEnter]);

  let clsText = classes.text;
  let clsData = `${classes.data} ${width !== "md" && width !== "sm" && width !== "xs" ? classes.delayedTransition : ""
    }`;

  let classRoot =
    width !== "md" && width !== "sm" && width !== "xs"
      ? `${classes.root} ${classes.full}`
      : classes.root;
  let classBg =
    width !== "sm" && width !== "xs"
      ? `${classes.bg} ${classes.bgFull}`
      : classes.bg;
  let classQuestion =
    width !== "md" && width !== "sm" && width !== "xs"
      ? `${classes.question} ${classes.questionFull}`
      : classes.question;
  let classSubQuestion =
    width !== "md" && width !== "sm" && width !== "xs"
      ? `${classes.subQuestion} ${classes.subQuestionFull}`
      : classes.subQuestion;
  let classBetWrapper =
    width !== "md" && width !== "sm" && width !== "xs"
      ? `${classes.betWrapper}`
      : `${classes.betWrapper} ${classes.betWrapperSm}`;

  let teams = "";
  let logoTeam1 = "";
  let logoTeam2 = "";
  let team1Name = "";
  let team2Name = "";
  if (match) {
    if (
      match.idSport === "1"
    ) {
      logoTeam1 = crests(match.team1Name, match, 1);
      logoTeam2 = crests(match.team2Name, match, 2);
    }
    team1Name = match.team1Name;
    team2Name = match.team2Name;
  }

  if (match !== null) {
    teams = (
      <React.Fragment>
        <div>
          {logoTeam1 !== "" && <img className={classes.matchFlag} src={logoTeam1} alt="" />}
          <span className={classes.matchName}>{team1Name}</span>
        </div>
        <div className={classes.matchVs}>vs</div>
        <div>
          <span className={classes.matchName}>{team2Name}</span>
          {logoTeam1 !== "" && <img className={classes.matchFlag} src={logoTeam2} alt="" />}
        </div>
      </React.Fragment>
    );
  }

  const handleClick = () => {
    showMatch(match, history)();
  };

  //let isDesktop = useMediaQuery("(min-width:1920px)");
  const lgCols = 12;
  const isDesktop1024 = useMediaQuery("(min-width:1025px)");

  if (!hasMatch) {
    return null
  }

  return (
    <div onClick={handleClick}>
      <div ref={bgRef} className={classBg}></div>
      <div ref={root} className={classRoot}>
        {visible && (
          <React.Fragment>
            <Grid container alignItems="center">
              {/*width !== "md" && width !== "sm" && width !== "xs" && isDesktop && (
                <Grid item md={8} className={clsText}>
                  <div className={classQuestion}>
                    {match !== null && (
                      <span>
                        {match.team1Name} {t("or")} {match.team2Name}?
                      </span>
                    )}
                  </div>
                  <div className={classSubQuestion}>
                    {t("Decide who goes home with the trophy")}
                  </div>
                </Grid>
                    )*/}
              {hasMatch && <Grid item xs={12} lg={lgCols} className={clsData}>
                <div className={classes.header}>
                  {match && match.mType === "prematch" && (
                    <div className={classes.date}>
                      {formatDateWithTime(parseInt(match.matchDateTime, 10), lang)}
                    </div>
                  )}
                  {match && match.mType === "live" && (
                    <div className={classes.live}>
                      {t("LIVE")}
                    </div>
                  )}
                  <div
                    className={`d-flex flex-row flex-nowrap align-items-center justify-content-center`}
                  >
                    {teams}
                  </div>
                </div>
                <div className={classBetWrapper}>
                  {match && match.bettingStatus && (
                    <BetSelect
                      match={match}
                      titleColor="#FFF"
                      banner={true}
                      lockedBet
                      maxOutcomes={isDesktop1024 ? 6 : 3}
                    />
                  )}
                  {match && !match.bettingStatus && (
                    <div className={classes.betsLocked}>
                      <div className={classes.lockIcon}>
                        <LockOutlinedIcon />
                      </div>
                      <div className={classes.lockText}>{t("EVENT BETS LOCKED")}</div>
                    </div>
                  )}
                </div>
              </Grid>}
            </Grid>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};


FootballBanner.propTypes = {
  onClick: PropTypes.func
};

FootballBanner.defaultProps = {
  onClick: () => { }
};


const mapStateToProps = state => ({
  //pMatch: getSuperBannerMatch(state)
  //pMatches: getPromotedMatches(state)
});

export default withTranslation()(withWidth()(connect(mapStateToProps)(FootballBanner)));
