/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 07/11/2018
 * Time: 13:41
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import * as stateActions from "../../../../../store/actions";
import Utils from "../../../../../utils/Utils";
import { withRouter } from "react-router-dom";

class TotalWallet extends Component {
    

    render() {
        

        return (
            <div className={"total"}>
                <div className="value">
                    {Utils.formatSmallDecimals(this.props.total, this.props.currency)}
                </div>
                <div className="description">
                    {this.props.t("Total amount")}
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setGlobalMoneyWidgetFontSize: (number) => dispatch(stateActions.setGlobalMoneyWidgetFontSize(number)),
        moneyWidgetLoaded: (id) => dispatch(stateActions.moneyWidgetLoaded(id)),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
        openWithdrawalModal: () => dispatch(stateActions.openWithdrawalModal()),
    };
};

const mapStateToProps = state => {
    return {
        total: state.wallet.total,
        currency: state.wallet.currency,
        profile: state.profile,
        application: state.application,
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TotalWallet)));