import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import JackpotCounter from "../NewCounter";
import BackgroundImageWrapper from "./../assets/img_jackpot_egt_web_bgjpsticky.jpg";
import BackgroundImage from "./../assets/img-bg-jackpot-header-front@3x.png";
import WinHistoryBG from "./../assets/winhistorybg.svg";

import "./WinHistorySticky.scss";
import { formatDate, formatNumber, ucFirst } from "./../utils/formatters";

const styleBgWrapper = {
    backgroundImage: `url(${BackgroundImageWrapper})`
};

const styleBg = {
    backgroundImage: `url(${WinHistoryBG})`
};

const wrapperBg = {
    backgroundImage: `url(${BackgroundImage})`
};

class WinHistorySticky extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                mounted: true
            });
        }, 1000);
    }

    render() {
        const { mounted } = this.state;
        const { show } = this.props;

        const names = {
            "0": "Spades",
            "1": "Hearts",
            "2": "Diamonds",
            "3": "Clubs"
        };

        const currency = ucFirst(this.props.currency);

        const cls = `winHistoryStickyWrapper ${mounted ? "op1" : ""} ${show ? "show" : ""}`;

        const clonedLevels = JSON.parse(JSON.stringify(this.props.levels));
        clonedLevels.sort((a, b) => {
            if (a.current < b.current) {
                return 1;
            }
            if (a.current > b.current) {
                return -1;
            }
            return 0;
        });

        return (
            <div className={cls}>
                <div className="winHistorySticky" style={styleBgWrapper}>
                    {clonedLevels.map((level, index) => (
                        <div className="wrapperAll" key={index}>
                            <div className="wrapper" style={wrapperBg} key={index}>
                                <div className="title" style={styleBg}>
                                    {this.props.name} {names[index]}
                                </div>
                                <div className="counter">
                                    {/*
                                    <JackpotCounter
                                        value={level.current / 100}
                                        storageKey={`EGT_${index}`}
                                        format="( ddd).DD"
                                        theme="simplejackpot"
                                        minIntegerLen={1}
                                    />
                                    */}
                                    <JackpotCounter
                                        value={level.current / 100}
                                        storageKey={`EGT_${index}`}
                                        theme="simple-counter"
                                        digits={5}
                                    />
                                    <div className="currency">{currency}</div>
                                </div>
                            </div>
                            <div className="wrapperText">
                                <div className="biggestWin">
                                    <div className="text-left">
                                        <div className="muted-text">
                                            {this.props.t("Highest win")}
                                        </div>
                                        <div className="amount">
                                            {formatNumber(level.largestWin / 100)}{" "}
                                            <span className="cap">{currency}</span>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <div className="muted-text">{level.largestWinUser}</div>
                                        <div className="date">
                                            {formatDate(level.largestWinDate, this.props.i18n)}
                                        </div>
                                    </div>
                                </div>
                                <div className="delimiter"></div>
                                <div className="latestWin">
                                    <div className="text-left">
                                        <div className="muted-text">
                                            {this.props.t("Most recent win")}
                                        </div>
                                        <div className="amount">
                                            {formatNumber(level.lastWin / 100)}{" "}
                                            <span className="cap">{currency}</span>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <div className="muted-text">{level.lastWinUser}</div>
                                        <div className="date">
                                            {formatDate(level.lastWinDate, this.props.i18n)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

WinHistorySticky.propTypes = {
    name: PropTypes.string,
    levels: PropTypes.array,
    onClick: PropTypes.func
};

WinHistorySticky.defaultProps = {
    name: "",
    levels: []
};

const mapStateToProps = state => {
    return {
        levels: state.jackpots.egtParsed.levels,
        currency: state.jackpots.egtParsed.currency
    };
};

export default withTranslation("translations")(connect(mapStateToProps)(WinHistorySticky));
