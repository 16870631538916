import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import eq from "lodash/fp/eq";
import { withTranslation } from "react-i18next";

import { ReactComponent as Alert } from "../assets/icons/alert.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import { ReactComponent as BackIcon } from "../assets/icons/arrow.svg";

import FilterSwitch from "../components/FilterSwitch";
import FilterBy from "../components/Filter/FilterBy";
import { filterSetSelected } from "../store/actions/preMatchFilter";
import { searchUpdateLeague } from "../store/actions/search";
import {
  getSelected,
  makeQueryLeagues
} from "../store/selectors/preMatchFilter";
import { getBetsState } from "../store/selectors/betData";

import "../components/LeftNav/icons-digitain.scss";

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: "transparent",
    border: "0",
    marginBottom: 5,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "0 auto 5px"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "#A1BED4",
    border: "1px solid #A1BED4",
    fontFamily: "OpenSans",
    fontSize: "12px",
    fontWeight: "600",
    paddingLeft: "20px",
    paddingRight: "20px",
    textTransform: "uppercase",
    color: "#17214D",
    borderRadius: "5px",
    minHeight: 45,
    height: 45,
    "&$expanded": {
      minHeight: 45
      //margin: '12px 0',
    }
  },
  content: {
    "&$expanded": {
      //margin: '12px 0',
    }
  },
  expandIcon: {
    "&$expanded": {
      transform: "none",
      "& .icon": {
        transform: "rotate(180deg)"
      }
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles({
  root: {
    position: "relative"
  },
  fav: {
    position: "relative",
    top: "1px",
    display: "inline-block",
    marginRight: "13px",
    fill: "#fff"
  },
  noResults: {
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "600"
  },
  mb: {
    marginBottom: "1rem"
  },
  p10: {
    padding: 10
  },
  py10: {
    paddingTop: 5,
    paddingBottom: 5
  },
  icon: {
    width: "18px",
    height: "18px",
    transform: "rotate(0deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:first-child": {
      position: "relative",
      top: 4
    },
    "&:last-child": {
      position: "relative",
      top: -4
    }
  },
  countryFlag: {
    position: "relative",
    top: 3,
    marginRight: 10
  },
  countryTitle: {
    color: "#17214D",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "8px"
  },
  countryCounter: {
    color: "#17214D",
    fontSize: "10px",
    fontWeight: 600
  },
  header: {
    borderLeft: "3px solid #F8991C",
    fontSize: 22,
    fontWeight: 600,
    paddingLeft: 15,
    color: "#17214D",
    marginBottom: 14
  },
  mg: {
    margin: "15px 14px"
  },
  w100: {
    width: "100%"
  },
  mt14: {
    marginTop: "14px"
  },
  alert: {
    margin: "5px 14px",
    backgroundColor: "#858A9F",
    padding: "0 3px",
    borderRadius: "5px",
    fontSize: "12px",
    color: "#fff",
    boxSizing: "border-box",
    minHeight: "23px",
    display: "flex",
    alignItems: "center",
    "& svg": {
      display: "inline-block",
      width: "17px",
      height: "17px",
      position: "relative",
      margin: "0 10px"
    }
  },
  footer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 0"
  },
  footerBack: {
    backgroundColor: "#17214D",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "13px 26px",
    color: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 3px 11px 0px rgba(0,0,0,0.5)",
    cursor: "pointer"
  },
  footerConfirm: {
    backgroundColor: "#FF196E",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "13px 26px",
    color: "#fff",
    borderRadius: "10px",
    margin: "0 10px",
    boxShadow: "0px 3px 11px 0px rgba(0,0,0,0.5)",
    cursor: "pointer"
  },
  search: {
    backgroundColor: "#FFFFFF",
    color: "#B5B8BB",
    fontSize: "15px",
    borderRadius: "9px",
    display: "flex",
    alignItems: "center",
    margin: "10px 14px"
  },
  searchText: {
    flex: "1 1 auto",
    padding: "0 15px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #009ED9",
    boxSizing: "border-box",
    borderTopLeftRadius: "9px",
    borderBottomLeftRadius: "9px",
    "& input": {
      border: "0",
      fontSize: "18px",
      color: "#000",
      backgroundColor: "#FFFFFF",
      height: "32px",
      width: "100%"
    }
  },
  searchIconWrapper: {
    flex: "0 0 auto",
    width: "60px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(90deg, #009ED9 0%, #14259B 100%)",

    borderTopRightRadius: "9px",
    borderBottomRightRadius: "9px",
    "& svg": {
      width: "17px",
      height: "18px",
      fill: "#fff"
    }
  },
  searchWrapper: {
    backgroundColor: "#fff"
  },
  searchInput: {
    width: "100%",
    fontSize: "20px",
    color: "#000",
    border: "0",
    backgroundColor: "#fff"
  },
  searchBack: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    cursor: "pointer",
    "& svg": {
      fill: "#2E4B9B",
      width: "18px",
      height: "18px"
    }
  },
  searchGlass: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    cursor: "pointer",
    "& svg": {
      width: "19px",
      height: "18px",
      "& path": {
        fill: "#2545BD!important"
      }
    }
  },
  searchClose: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    fontSize: "20px",
    fontWeight: "600",
    color: "#373737",
    cursor: "pointer",
  },
  searchFilter: {
    backgroundColor: "#E4E8E9",
    padding: "9px 14px 6px"
  },
  searchFilterContent: {
    backgroundColor: "#6176B0",
    borderRadius: "5px"
  },
  searchFilterText: {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    padding: "4px 10px",
    fontSize: "12px",
    color: "#fff",
    flex: "1 1 auto"
  },
  searchFilterAction: {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    backgroundColor: "#2E4B9B",
    padding: "4px 10px",
    fontSize: "12px",
    fontWeight: "600",
    color: "#fff",
    cursor: "pointer"
  },
  info: {
    display: "inline-block",
    textAlign: "center",
    marginRight: "10px",
    color: "#fff",
    border: "2px solid #fff",
    width: "17px",
    height: "17px",
    lineHeight: "13px",
    borderRadius: "50%"
  },
  headerSearch: {
    fontSize: "12px",
    fontWeight: "600",
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",
    padding: "5px 13px"
  }
});

const isSelected = (selected, id) => {
  if (typeof selected[id] !== "undefined") {
    return true;
  }
  return false;
};

function FilterLeague(props) {
  const classes = useStyles();

  const {
    history,
    matchDataAll,
    matchData,
    //filterType,
    idSport,
    categories,
    tournaments,
    sports,
    recommendedList,
    //groupedByCountry,
    selected,
    setSelected,
    searchUpdateLeague,
    singleMode,
    singleSelection,
    t
  } = props;

  //console.log("filter league", props);

  const [selectedOptions, setSelectedOptions] = React.useState(selected);
  React.useEffect(() => {
    setSelectedOptions(selected);
  }, [selected]);

  const handleChange = (id, checked) => {
    const p = id.split("_");

    const category = categories[p[1]] ? categories[p[1]] : {};
    const tournament = tournaments[p[2]] ? tournaments[p[2]] : {};

    let cName = "";
    if (category.categoryIsoName) {
      cName = category.categoryIsoName + " / ";
    } else if (category.categoryShortName) {
      cName = category.categoryShortName + " / ";
    } else if (category.categoryName) {
      cName = category.categoryName + " / ";
    }

    if (singleMode) {
      if (typeof singleSelection === "function") {
        const name = cName + tournament.tournamentName;
        singleSelection(id, name);
      }
      return;
    }

    setSelectedOptions(so => {
      const sso = { ...so };
      if (checked) {
        if (Object.keys(sso).length < 5) {
          sso[id] = cName + tournament.tournamentName;
        }
      } else {
        delete sso[id];
      }
      return sso;
    });
  };

  const goBackAndSave = () => {
    if (!eq(selected)(selectedOptions)) {
      setSelected(selectedOptions);
    }
    searchUpdateLeague("");
    history.goBack();
  };

  const goBack = () => {
    searchUpdateLeague("");
    history.goBack();
  };

  const expandIcon = (
    <div className="d-flex flex-column">
      <ExpandLessIcon className={`${classes.icon} icon`} />
      <ExpandMoreIcon className={`${classes.icon} icon`} />
    </div>
  );

  const [searchValue, setSearchValue] = React.useState("");
  const [searchedOpened, setSearchOpened] = React.useState(singleMode ? true : false);
  const inputRef = React.useRef();
  const rootRef = React.useRef();

  const handleSearchValueChange = event => {
    setSearchValue(event.target.value);
    searchUpdateLeague(searchValue);
  };

  const handleSubmit = event => {
    searchUpdateLeague(searchValue);
    event.preventDefault();
  };

  const clearAndToggleSearch = () => {
    setSearchValue("");
    searchUpdateLeague("");
    if (singleMode) {
      if (typeof singleSelection === "function") {
        singleSelection("", "");
      }
      return;
    }
    setSearchOpened(false);
  };
  const clearSearch = () => {
    setSearchValue("");
    searchUpdateLeague("");
    inputRef.current.focus();
  };
  const onSearchGlass = () => {
    searchUpdateLeague(searchValue);
  };

  const openSearch = () => {
    if (!searchedOpened) {
      setSearchOpened(true);
      setTimeout(() => {
        if (inputRef && inputRef.current) inputRef.current.focus();
        if (rootRef && rootRef.current) rootRef.current.parentElement.scrollTop = 0;
      }, 0);
    }
  };

  let rootCls = `${classes.root} filter-league`;
  if (searchedOpened) {
    rootCls = `${classes.root} filter-league animated`;
  }

  let filteredLeagues = 0;
  let allLeagues = 0;

  if (matchData == null) {
    return <Redirect to={window.location.pathname} />;
  }

  const categoriesKeys = Object.keys(matchData);
  if (categoriesKeys && categoriesKeys.length)
    categoriesKeys.sort((a, b) => {
      if (a && b && categories[a] && categories[b])
        return categories[a].categoryPosition - categories[b].categoryPosition;
      return 0;
    });

  Object.keys(matchData).forEach(idCategory => {
    Object.keys(matchData[idCategory]).forEach(idTournament => {
      filteredLeagues += 1;
    });
  });

  Object.keys(matchDataAll).forEach(idCategory => {
    Object.keys(matchDataAll[idCategory]).forEach(idTournament => {
      allLeagues += 1;
    });
  });

  const getFlag = (category) => {
    if (category.categoryDLId) {
      return `digflag f${category.categoryDLId}`;
    }
    return `flag flag-${category.categoryIsoName ? category.categoryIsoName.toLowerCase() : "undef"}`;
  };

  return (
    <div className={rootCls} ref={rootRef}>
      <div className={`league-actual-search`}>
        <div className={`${classes.headerSearch} league-header`}>
          {t("Search for Championships")}
        </div>
        <div
          className={`${classes.searchWrapper} d-flex searchInput flex-row flex-nowrap align-items-center justify-content-between`}
        >
          <div className={classes.searchBack} onClick={clearAndToggleSearch}>
            <BackIcon />
          </div>
          <input
            ref={inputRef}
            onChange={handleSearchValueChange}
            onClick={openSearch}
            value={searchValue}
            type="text"
            className={`${classes.searchInput} match-search-input`}
          />
          <div className={classes.searchClose} onClick={clearSearch}>
            X
          </div>
          <div className={classes.searchGlass} onClick={onSearchGlass}>
            <SearchIcon />
          </div>
        </div>
      </div>
      <div className={`${classes.alert} league-alert`}>
        <Alert /> {t("You can follow up to 5 championships at the same time.")}
      </div>
      <div className={`${classes.mg} ${classes.header} league-title`}>
        {sports[idSport].sportName} | {t("Your championships")}
      </div>

      {!singleMode && <FilterBy items={selectedOptions} noAdd={true} onDelete={handleChange} />}

      {filteredLeagues !== allLeagues && (
        <div className={`${classes.searchFilter} league-filtered`}>
          <div
            className={`${classes.searchFilterContent} d-flex flex-row flex-wrap align-items-center justify-content-between`}
          >
            <div className={classes.searchFilterText}>
              <span className={classes.info}>!</span>
              {t("Filtered championships")}: {filteredLeagues} / {allLeagues} {t("visible")}
            </div>
            <div
              className={classes.searchFilterAction}
              onClick={singleMode ? clearSearch : clearAndToggleSearch}
            >
              {t("Show All")}
            </div>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit} className={`league-fake-search`}>
        <div
          className={`${classes.search} fakeSearch d-flex flex-row align-items-center justify-content-between`}
        >
          <div className={classes.searchText}>
            <input
              type="text"
              value={searchValue}
              onClick={openSearch}
              onChange={handleSearchValueChange}
              placeholder={t("Search League")}
            />
          </div>
          <div className={classes.searchIconWrapper}>
            <SearchIcon />
          </div>
        </div>
      </form>
      <div className={classes.mg}>
        {false && recommendedList.length && (
          <div className={classes.header}>{t("Recommended")}</div>
        )}

        {false &&
          recommendedList.map((item, i) => (
            <FilterSwitch
              key={i}
              settings={{
                id: item.id,
                country: item.flag,
                code: item.code,
                title: item.title,
                logo: item.logo,
                selected: isSelected(selectedOptions, item.id)
              }}
              onChange={handleChange}
            />
          ))}

        <div className={`${classes.header} ${classes.mt14}`}>{t("By Countries")}</div>

        {categoriesKeys.map((idCategory, index) => {
          let iso = "unk";
          if (
            categories[idCategory]
          ) {
            iso = getFlag(categories[idCategory]);
          } else {
            //console.log("ERROR: MISSING ", idCategory, categories[idCategory]);
          }

          let name = "";
          if (
            categories[idCategory] &&
            typeof categories[idCategory].categoryName !== "undefined"
          ) {
            name = categories[idCategory].categoryName;
          }

          let len = 0;
          if (matchData[idCategory]) {
            len = Object.keys(matchData[idCategory]).length;
          }

          const tournamentsKeys = Object.keys(matchData[idCategory]);
          if (tournamentsKeys && tournamentsKeys.length)
            tournamentsKeys.sort((a, b) => {
              if (a && b && tournaments[a] && tournaments[b])
                return tournaments[a].tournamentPosition - tournaments[b].tournamentPosition;

              return 0;
            });

          return (
            <ExpansionPanel
              square
              defaultExpanded={index === 0}
              key={index}
              TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            >
              <ExpansionPanelSummary
                aria-controls="matches-content"
                id="matches-header"
                expandIcon={expandIcon}
              >
                <div
                  className={`${classes.w100} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
                >
                  <div className={classes.countryTitle}>
                    {iso !== "unk" && (
                      <span className="icon adjust">
                        <div className={iso}></div>
                      </span>
                    )}{" "}
                    {name}
                  </div>
                  <div className={classes.countryCounter}>
                    {len} {t("Leagues")}
                  </div>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={`${classes.py10} ${classes.w100}`}>
                  {tournamentsKeys.map((idTournament, i) => {
                    const idSel = idSport + "_" + idCategory + "_" + idTournament;
                    //console.log(selectedOptions, idSel);
                    return (
                      <FilterSwitch
                        key={i}
                        settings={{
                          id: idSel,
                          //country: "GER",
                          //code: "1",
                          title: tournaments[idTournament]
                            ? tournaments[idTournament].tournamentName
                            : "",
                          logo: null,
                          selected: isSelected(selectedOptions, idSel),
                          singleMode
                        }}
                        onChange={handleChange}
                      />
                    );
                  })}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
      <div style={{ height: "85px" }}>&nbsp;</div>
      {!singleMode && (
        <div className={classes.footer}>
          <div className={classes.footerBack} onClick={goBack}>
            &lt;
          </div>
          <div className={classes.footerConfirm} onClick={goBackAndSave}>
            {t("CONFIRM")}
          </div>
          <div style={{ width: "60px" }}>&nbsp;</div>
        </div>
      )}
    </div>
  );
}

FilterLeague.propTypes = {
  queryLeague: PropTypes.string,
  history: PropTypes.object,
  select: PropTypes.func,
  unselect: PropTypes.func
};

FilterLeague.defaultProps = {
  queryLeague: "",
  history: { goBack: () => { } },
  select: () => { },
  unselect: () => { }
};

const queryLeagues = makeQueryLeagues();
const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  if (!bst.preMatchFilter.filterType) {
    return {
      matchData: null
    };
  }

  return {
    matchDataAll: bst.preMatchFilter.matchData,
    matchData: queryLeagues(state, {
      mType: bst.preMatchFilter.filterType
    }),
    filterType: bst.preMatchFilter.filterType,
    idSport: bst.preMatchFilter.idSport,
    categories: bst[bst.preMatchFilter.filterType].categories,
    tournaments: bst[bst.preMatchFilter.filterType].tournaments,
    sports: bst[bst.preMatchFilter.filterType].sports,
    queryLeague: bst.search.queryLeague,
    selected: getSelected(state, props)
  };
};

const actionCreators = {
  setSelected: filterSetSelected,
  searchUpdateLeague: searchUpdateLeague
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, actionCreators)(FilterLeague))
);
