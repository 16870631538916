import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import * as stateActions from "../../../../store/actions";
import { withTranslation } from "react-i18next";
import "./TransactionsHistory.scss";
import { VelocityComponent, VelocityTransitionGroup } from "velocity-react";
import { withRouter, Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import DepositsAndWithdrawals from './Lists/DepositsAndWithdrawals';
import GamesSpins from './Lists/GamesSpins';
import EmptyList from './Lists/EmptyList';
import Lotto from "../../../../lotto/components/TransactionsHistoryLotto/Lotto";
import SportsList from "../../../../bets/components/TransactionsHistorySports/Sports";
import Virtuals from "../../../../virtuals/components/TransactionsHistoryVirtuals/Virtuals";
import AllBonuses from "./Aggregates/AllBonuses";

class TransactionsHistory extends Component {
    constructor(props) {
        super(props);

        this.profilePage = null;
        this.transactionsPage = null;
        this.pageUrl = null;

        this.menuItems = {
            "deposits_withdrawals": {
                'name': "In & Out",
                'available': true,
                'requestType': 2,
            },
            "bonus": {
                'name': "bonus",
                'available': true,
                'requestType': 3,
            },
            "game": {
                'name': "game",
                'available': true,
                'requestType': 1,
            },
        };

        if (window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")) {
            this.menuItems["sports"] = {
                'name': "sports",
                'available': true,
                'requestType': 4,
            }
        }

        if (window.config && window.config.lottoEnabled === "1") {
            this.menuItems["lotto"] = {
                'name': "lotto",
                'available': true,
                'requestType': 6,
            }
        }

        if (window.config && window.config.virtualsEnabled === "1") {
            this.menuItems["virtuals"] = {
                'name': "virtuals",
                'available': true,
                'requestType': 5,
            }
        }

        this.csPage = null;
        this.tcPage = null;

        if (this.props.application.menu.hasOwnProperty("customer-support")) {
            this.csPage = this.props.application.menu["customer-support"];
        }

        if (this.csPage && this.csPage.hasOwnProperty("submenus")) {
            this.tcPage = this.csPage.submenus.find((elem) => {
                return "terms&conditions" === elem.code;
            });
        }
    }

    state = {
        selectedMenuItem: null,
    };

    componentDidMount() {
        this.setState({
            selectedMenuItem: this.menuItems["deposits_withdrawals"],
        });

        this.setSelfPage();
    }

    setSelfPage() {
        if (this.profilePage) {
            return;
        }

        let profilePage = this.props.application.menu["profile"];
        if (!profilePage) {
            return;
        }

        this.profilePage = profilePage;
        if (!this.profilePage.hasOwnProperty("submenus")) {
            return;
        }

        this.pageUrl = `/${this.profilePage.code}`;

        this.transactionsPage = this.profilePage.submenus.find((subpage) => {
            return "transactions" === subpage.code;
        });

        if (!this.transactionsPage) {
            return;
        }

        this.pageUrl += `/${this.transactionsPage.code}`;
    }

    clickHandler(menuItem, evt = null) {
        if (evt) {
            evt.nativeEvent.stopImmediatePropagation();
            evt.preventDefault();
            evt.stopPropagation();
        }

        if (this.state.selectedMenuItem.requestType === menuItem.requestType) {
            return false;
        }

        this.setState({
            selectedMenuItem: menuItem,
        });

        this.props.history.push(this.pageUrl);

        if (menuItem.requestType !== 4 && menuItem.requestType !== 5 && menuItem.requestType !== 6) {
        this.props.changeList(this.transactionsPage, menuItem.requestType);
        }

        return true;
    }

    createTopMenu() {
        if (!this.state.selectedMenuItem) {
            return;
        }

        let items = this.menuItems;

        let menuItems = [];
        Object.keys(items).forEach((attr, index) => {
            let mElement = items[attr];
            let subSection = "";
            let elemClassName = ["limit-item"];

            if (this.state.selectedMenuItem.requestType === mElement.requestType) {
                elemClassName.push("active");
            }

            if (!mElement.available) {
                elemClassName.push("coming-soon");
                subSection = (
                    <div className={"coming-soon"}>
                        <span>{this.props.t("coming soon")}</span>
                    </div>
                );
            }

            let item = (
                <div className={elemClassName.join(" ")} key={"history-" + attr} data-type={attr} onClick={this.clickHandler.bind(this, mElement)}>
                    {this.props.t(mElement.name)}
                    {subSection}
                </div>
            );

            menuItems.push(item);
        });

        return (
            <div className="header-limits" key={"header-limits"}>
                {menuItems}
            </div>
        );
    }

    downloadCsv(listType, event) {
        event.nativeEvent.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        this.props.changeList(this.transactionsPage, listType, true);

        return false;
    }

    createContent() {
        if (window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")) {
	        if (this.state.selectedMenuItem !== null && this.state.selectedMenuItem.requestType === 4) {
	            return (
	                <SportsList />
	            );
	        }
	    }

        if (this.state.selectedMenuItem !== null && this.state.selectedMenuItem.requestType === 6) {
            return (
                <Lotto />
            );
        }

        if (window.config && window.config.virtualsEnabled === "1") {
            if (this.state.selectedMenuItem !== null && this.state.selectedMenuItem.requestType === 5) {
                return (
                    <Virtuals />
                );
            }
        }

        if (!this.props.transactions.show) {
            return undefined;
        }

        if (2 === this.props.transactions.responseType) {
            return (
                <DepositsAndWithdrawals downloadCsv={this.downloadCsv.bind(this)} />
            );
        }

        if (1 === this.props.transactions.responseType) {
            return (
                <GamesSpins downloadCsv={this.downloadCsv.bind(this)} />
            );
        }

        if (3 === this.props.transactions.responseType) {
            return (
                <AllBonuses />
            );
        }

        return (
            <EmptyList />
        );
    }

    handleTermsAndConditions = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        let win = window.open(url, '_blank');
        if(win !== null){
            win.focus();
        }
    }

    render() {
        let tcPageName = null;
        if (this.tcPage.hasOwnProperty("name")) {
            tcPageName = this.tcPage.name;
        }

        return (
            <div className={"transactionsHistory"}>
                {this.createTopMenu()}
                <div className={"transactions-content"}>
                    <VelocityComponent animation={!this.props.transactions.show ? { opacity: 1, top: 70 } : { opacity: 0, top: 0 }} duration={200}>
                        <div className={"historyLoader"}>
                            <PulseLoader />
                        </div>
                    </VelocityComponent>
                    <VelocityTransitionGroup enter={{ animation: "slideDown" }} leave={{ animation: "slideUp" }} runOnMount={true} duration={1000}>
                        {this.createContent()}
                    </VelocityTransitionGroup>
                </div>
                <div className={"tcLinkArea"}>
                    <a onClick={() => this.handleTermsAndConditions()}>
                        <span className={"dText"}>{this.props.t(tcPageName)}</span>
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        transactions: state.transactions,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // test: dispatch(stateActions.withdrawalRequestsReceived()),
        changeList: (subpage, requestType, exportCsv = false) => dispatch(stateActions.requestChangeHistoryList(subpage, requestType, exportCsv)),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionsHistory)));