import React from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import "./index.scss";
import { getBetsState } from "../../store/selectors/betData";
import { getTr } from '../../utils/nsoft';

const Breadcrumbs = ({ idSport, location, sports, categories, t, tournaments, basePath }) => {

  const [state, setState] = React.useState({
    idSport: "",
    idCategory: "",
    idTournament: ""
  });

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    const IDs = query.get('f');
    if (IDs) {
      const IDsp = IDs.split("_");
      if (IDsp.length === 3) {
        setState({
          idSport: IDsp[0],
          idCategory: IDsp[1],
          idTournament: IDsp[2]
        });
      } else if (IDsp.length === 2) {
        setState({
          idSport: IDsp[0],
          idCategory: IDsp[1],
          idTournament: ""
        });
      }
    } else {
      setState({
        idSport: idSport,
        idCategory: "",
        idTournament: ""
      });
    }
  }, [location, idSport]);

  let index = 0;

  const parts = [
    <Link key={`el_${index++}`} className={"bread-cell"} to={basePath}>
      {t("Pariuri")}
    </Link>,
  ];

  if (state.idSport && sports[state.idSport]) {
    parts.push(
      <div key={`el_${index++}`} className="separator">&gt;</div>
    );

    //console.log("sports[state.idSport]", sports[state.idSport]);

    parts.push(
      <Link key={`el_${index++}`} className={"bread-cell"} to={`${basePath}/pre-match/calendar`}>
        {getTr(sports[state.idSport].sportName)}
      </Link>
    );
  }

  if (state.idCategory && categories[state.idCategory]) {
    parts.push(
      <div key={`el_${index++}`} className="separator">&gt;</div>
    );
    const uri = `${basePath}/pre-match/calendar?f=${state.idSport}_${state.idCategory}&l=${encodeURIComponent(categories[state.idCategory].categoryName)}`
    parts.push(
      <Link key={`el_${index++}`} className={"bread-cell"} to={uri}>
        {getTr(categories[state.idCategory].categoryName)}
      </Link>
    );
  }

  if (state.idTournament && tournaments[state.idTournament]) {
    parts.push(
      <div key={`el_${index++}`} className="separator">&gt;</div>
    );
    const uri = `${basePath}/pre-match/calendar?f=${state.idSport}_${state.idCategory}_${state.idTournament}&l=${encodeURIComponent(tournaments[state.idTournament].tournamentName)}`
    parts.push(
      <Link key={`el_${index++}`} className={"bread-cell"} to={uri}>
        {getTr(tournaments[state.idTournament].tournamentName)}
      </Link>
    );
  }

  return (
    <div className="prematch-breadcrumbs">
      {parts}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    sports: bst.prematch.sports,
    categories: bst.prematch.categories,
    tournaments: bst.prematch.tournaments,
    idSport: bst.navSports.prematch,
    basePath: bst.app.basePath
  };
};

export default withTranslation()(connect(mapStateToProps)(withRouter(Breadcrumbs)));