import getStore from "../../store";
import axios from "axios";

let apiUrl = process.env.CFW.betsApiUrl + "/retail-tickets/online/cash/process";

async function depositTicket({ serial, pin, type, amount, bonusAvailableId, extra }) {
  const { authentication } = getStore().getState();

  if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const headers = {
    "Authorization": "Bearer " + authentication.access_token,
  };

  try {
    const resp = await axios.post(
      apiUrl,
      { serial, pin, brand: window.config.brand_name_short.toLowerCase(), bonus_id: bonusAvailableId, extra },
      { headers }
    );
    return resp.data;

  } catch (err) {
    console.log("depositTicket[ERR]:", err);
    if (err && err.response && err.response.data && typeof err.response.data.error_code != "undefined") {
      return err.response.data;
    }
    throw err;
  }
}

async function getAccountLimits() {
  const { authentication } = getStore().getState();

  if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const headers = {
    "Authorization": "Bearer " + authentication.access_token,
  };

  try {
    const resp = await axios.get(
      `${window.config.front_url}/api/profile/responsibly`,
      { headers }
    );
    return resp.data;

  } catch (err) {
    console.log("depositTicket[ERR]:", err);
    if (err && err.response && err.response.data && typeof err.response.data.error_code != "undefined") {
      return err.response.data;
    }
    throw err;
  }
}

export { depositTicket, getAccountLimits };