import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';

import BetSelectSingle from '../components/BetSelectSingle';
import Match from '../components/Match';
import CollapsibleSimple from '../components/Collapsible/Simple';

const showMatchDetails = () => {
	window.location.hash = '#match-details';
};

export const renderMatchGroup = g => {
	//console.log("rendering group", g);

	return (
		<div>
			<BetSelectSingle matches={g.matches} matchGroup={g.group} />

			{g.matches.map((m, i) => {
				return (
					<Match
						key={i}
						idMatch={m.idMatch}
						mType={m.mType}
						onClick={showMatchDetails}
						showFav={true}
						matchGroup={g.group}
					/>
				);
			})}
		</div>
	);
};

export const renderMatchGroupWithSport = (g, bst) => {
	if (g && g.matches && g.matches.length === 0) return null;

	let sport = null;
	if (bst && g.idSport && g.mType) {
		sport =
			bst[g.mType] && bst[g.mType].sports && bst[g.mType].sports[g.idSport]
				? bst[g.mType].sports[g.idSport]
				: null;
	} else if (g.matches && g.matches.length) {
		const idSport = g.matches[0].idSport;
		const mType = g.matches[0].mType;
		sport =
			bst[mType] && bst[mType].sports && bst[mType].sports[idSport]
				? bst[mType].sports[idSport]
				: null;
	}

	let mCurrDate = moment(g.matches[0].matchDateTime);
	let currDate = g.matches[0].matchDateTime;

	const matchesContent = (
		<React.Fragment>
			<BetSelectSingle matches={g.matches} matchGroup={g.group} time={currDate} dark={g.dark ? true : false} />
			{g.matches.map((m, i) => {
				const match = <Match
					key={i}
					idMatch={m.idMatch}
					mType={m.mType}
					onClick={showMatchDetails}
					showFav={true}
					matchGroup={g.group}
					dark={g.dark ? true : false}
				/>;

				if (mCurrDate) {
					const mDate = moment(m.matchDateTime);
					if (mCurrDate.format('YYYY-MM-DD') !== mDate.format('YYYY-MM-DD')) {
						mCurrDate = mDate;

						return (
							<React.Fragment key={i}>
								<BetSelectSingle
									matches={g.matches}
									matchGroup={g.group}
									second={true}
									time={m.matchDateTime}
									dark={g.dark ? true : false}
								/>
								{match}
							</React.Fragment>
						);
					}
				}

				return match;
			})}
		</React.Fragment>
	);

	if (!sport) return <div key={'k' + g.group}>{matchesContent}</div>;

	return (
		<CollapsibleSimple
			type={g.mType === 'live' ? 'live' : 'preMatch'}
			idSport={g.idSport}
			league={sport.sportName}
			key={'c' + g.group}
			opened={true}
			dark={g.dark ? true : false}
		>
			<div key={'k' + g.group}>{matchesContent}</div>
		</CollapsibleSimple>
	);
};

const defaultCardStyle = {
	backgroundColor: '#fff',
	padding: '9px 12px 5px 18px',
	marginBottom: '10px',
	borderRadius: '7px'
};
const defaultElemStyle = {
	backgroundColor: '#ccc',
	marginBottom: '7px'
};

export const MatchPlaceholder = ({ cardStyle, elemStyle }) => {
	if (typeof cardStyle === 'undefined' || cardStyle === null) {
		cardStyle = defaultCardStyle;
	}

	if (typeof elemStyle === 'undefined' || elemStyle === null) {
		elemStyle = defaultElemStyle;
	}

	return (
		<div style={cardStyle}>
			<Skeleton variant="text" width={315} height={15} style={elemStyle} />
			<Skeleton variant="rect" width={150} height={48} style={elemStyle} />
			<Skeleton variant="rect" width={315} height={3} style={elemStyle} />
			<Skeleton variant="text" width={30} height={13} style={elemStyle} />
			<Skeleton variant="rect" width={315} height={35} style={elemStyle} />
		</div>
	);
};

export const renderMatchPlaceholder = (phKey, phNr, cardStyle, elemStyle) => {
	return (
		<div key={phKey}>
			{Array.from(Array(phNr), (e, i) => (
				<MatchPlaceholder key={phKey + i} cardStyle={cardStyle} elemStyle={elemStyle} />
			))}
		</div>
	);
};
