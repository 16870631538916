import { takeEvery, put } from "redux-saga/effects";
//import * as actionTypes from "../../../store/actions/actionTypes";
import {
  loadingHistory,
  loadedHistory,
  loadingLeaderBoard,
  loadedLeaderBoard,
  claimedPrize,
  loadedPrizes
} from "../actions/history";
import { historyConstants } from "../actions/constants";
import getStore from "..";
import axios from "axios";

let apiTournamentsUrl = process.env.CFW.betsApiUrl + "/missions";
let apiPrizesUrl = process.env.CFW.betsApiUrl + "/prizes";

const listRequests = {};
function* loadHistorySaga(action) {
  if (listRequests[action.groupId]) return;

  const { authentication } = getStore().getState();

  const groupId = action.groupId;
  if (!groupId) return;
  listRequests[action.groupId] = true;

  yield put(loadingHistory());

  let headers = {};
  if (authentication && ["user", "token"].indexOf(authentication.auth_type) > -1) {
    headers = {
      "Authorization": "Bearer " + authentication.access_token,
    };
  }

  try {
    const resp = yield axios.get(apiTournamentsUrl + "/tournaments/history/list/" + groupId,
      { headers });
    yield put(loadedHistory({ groupId, data: resp.data }));
    delete listRequests[action.groupId];
  } catch (err) {
    console.log("loadLeaderBoardSaga[ERR]:", err);
    yield put(loadedHistory({ groupId, data: [] }));
    delete listRequests[action.groupId];
  }
}

const leadRequests = {};
function* loadLeaderBoardSaga(action) {

  if (leadRequests[action.tournamentId]) return;

  const { authentication } = getStore().getState();

  const tournamentId = action.tournamentId;
  if (!tournamentId) return;
  leadRequests[tournamentId] = true;

  yield put(loadingLeaderBoard());

  let headers = {};
  if (authentication && ["user", "token"].indexOf(authentication.auth_type) > -1) {
    headers = {
      "Authorization": "Bearer " + authentication.access_token,
    };
  }
  try {
    const resp = yield axios.get(apiTournamentsUrl + "/tournaments/history/leaderboard/" + tournamentId,
      { headers });
    yield put(loadedLeaderBoard({ tournamentId, data: resp.data }));
    delete leadRequests[tournamentId];

  } catch (err) {
    console.log("loadPlayerLeaderBoardSaga[ERR]:", err);
    yield put(loadedLeaderBoard({ tournamentId, data: {} }));
    delete leadRequests[tournamentId];
  }
}

function* claimPrizeSaga(action) {
  const { authentication } = getStore().getState();

  const tournamentId = action.tournamentId;
  const groupId = action.groupId;
  const cb = action.cb ? action.cb : () => { };

  if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  let headers = {
    "Authorization": "Bearer " + authentication.access_token,
  };

  try {
    const resp = yield axios.get(apiTournamentsUrl + "/tournament/claim/" + tournamentId, { headers });
    if (resp && resp.data && resp.data.failed_count === 0) {
      yield put(claimedPrize({ tournamentId, groupId }));
      cb(true);
    } else {
      cb(false);
    }
  } catch (err) {
    console.log("claimPrizeSaga[ERR]:", err);
    cb(false);
  }
}

function* claimPrizeByIdSaga(action) {
  const { authentication } = getStore().getState();

  const id = action.id;
  const cb = action.cb;
  if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  let headers = {
    "Authorization": "Bearer " + authentication.access_token,
  };

  try {
    const resp = yield axios.get(apiPrizesUrl + "/player/claim/" + id, { headers });
    if (resp && resp.data && resp.data.success) {
      cb(true);
    } else {
      cb(false);
    }
  } catch (err) {
    console.log("claimPrizeByIdSaga[ERR]:", err);
    cb(false);
  }
}

function* loadPrizesSaga(action) {
  const { authentication } = getStore().getState();

  const cb = action.cb ? action.cb : () => { };

  if (!(authentication && ["user", "token"].indexOf(authentication.auth_type) > -1)) {
    return;
  }
  let headers = {
    "Authorization": "Bearer " + authentication.access_token,
  };

  try {
    // const resp = false;
    const resp = yield axios.get(apiPrizesUrl + "/player/list", { headers });

    if (resp && resp.data) {
      yield put(loadedPrizes({ data: resp.data }));
      cb(true);
    } else {
      cb(false);
    }
  } catch (err) {
    console.log("loadPrizes[ERR]:", err);
    cb(false);
  }
}

export default function* watchLeaderBoardSaga() {
  yield takeEvery(historyConstants.LOAD, loadHistorySaga);
  yield takeEvery(historyConstants.LOAD_LEADER_BOARD, loadLeaderBoardSaga);
  yield takeEvery(historyConstants.CLAIM_PRIZE, claimPrizeSaga);
  yield takeEvery(historyConstants.CLAIM_PRIZE_BY_ID, claimPrizeByIdSaga);
  yield takeEvery(historyConstants.LOAD_PRIZES, loadPrizesSaga);
}