import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import "./NotificationsPreference.scss";
import "./NotificationsSettings.scss";
import { ReactComponent as WantPromotionsIcon } from "./assets/want-promotions.svg";
import Switch from "./components/Switch";

import * as storeActions from "../../store/actions";

const NotificationsPreference = (props) => {
  const { t } = useTranslation();


  const handleChange = (value,type) => {
    const checked = value.target.checked;
    if (typeof type === "undefined") {
      props.setMarketingAgreements({
        marketing_receive_sms: checked ? 1 : 0,
        marketing_receive_email: checked ? 1 : 0,
        marketing_receive_phone: checked ? 1 : 0
      });
    } else {
      props.setMarketingAgreements({channel: type, value: checked ? 1 : 0});
    }
  };
  const backHandler = () => {
    props.history.goBack();
  };

  return (
      <div id={'ProfilePage'}>
        <div className="page-header">
          <div className="page-header-top subpage_15">
            <div className="pageIcon">
              <div className="icon"></div>
            </div>
            <div className="pageName">{t("Preferinte de marketing")}</div>
            <div className="clear"></div>
          </div>

        </div>
        <div className="notifications-preference-content">
          <div className="settings notifications-settings">
            <div className="notification-row">
              <div className="row-icon">
                <WantPromotionsIcon />
              </div>
              <div className="row-content">
                <div className="row-title">
                  {t("Vreau promotii")}
                </div>
                <div className="row-text">
                  {t("Te putem contacta pentru a-ti oferi promotii si bonusuri")}
                </div>
              </div>
              <div className="row-switch">
                <Switch checked={props.marketing_agreements.marketing_receive_sms || props.marketing_agreements.marketing_receive_email || props.marketing_agreements.marketing_receive_phone} onChange={handleChange} />
              </div>
            </div>
            <div className="row-sep"></div>
            {/*<div className="notification-row">*/}
            {/*  <div className="row-icon">*/}
            {/*  </div>*/}
            {/*  <div className="row-content">*/}
            {/*    <div className="row-title">*/}
            {/*      {t("SMS")}*/}
            {/*    </div>*/}
            {/*    <div className="row-text">*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="row-switch">*/}
            {/*    <Switch checked={props.marketing_agreements.marketing_receive_sms} onChange={(e)=> handleChange(e,'marketing_receive_sms')} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="row-sep"></div>*/}
            {/*<div className="notification-row">*/}
            {/*  <div className="row-icon">*/}
            {/*  </div>*/}
            {/*  <div className="row-content">*/}
            {/*    <div className="row-title">*/}
            {/*      {t("Email")}*/}
            {/*    </div>*/}
            {/*    <div className="row-text">*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="row-switch">*/}
            {/*    <Switch checked={props.marketing_agreements.marketing_receive_email} onChange={(e)=> handleChange(e,'marketing_receive_email')} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="row-sep"></div>*/}
            {/*<div className="notification-row">*/}
            {/*  <div className="row-icon">*/}
            {/*  </div>*/}
            {/*  <div className="row-content">*/}
            {/*    <div className="row-title">*/}
            {/*      {t("Phone calls")}*/}
            {/*    </div>*/}
            {/*    <div className="row-text">*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="row-switch">*/}
            {/*    <Switch checked={props.marketing_agreements.marketing_receive_phone} onChange={(e)=> handleChange(e,'marketing_receive_phone')} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="row-sep"></div>*/}
          </div>
        </div>
      </div>
  );
}

NotificationsPreference.propTypes = {
  data: PropTypes.object
};

NotificationsPreference.defaultProps = {
  data: {}
};

const mapStateToProps = state => {
  return {
    marketing_agreements: state.alerts.settings.marketing_agreements,
  }
};
const mapDispatchToProps = dispatch => {
  return {
    setMarketingAgreements: params => dispatch(storeActions.setMarketingAgreements(params)),
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationsPreference));