import React from "react";
import PropTypes from "prop-types"; // eslint-disable-line
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import { ReactComponent as BackIcon } from "./assets/arrow.svg";
import Favorite from "../../elements/Favorite";

import { LSPGenerator } from "../StatScoreGenerator";
import { debug } from "../../utils";


const useStyles = makeStyles({
  root: {
    position: "relative"
  },
  topper: {
    background: "linear-gradient(90deg, #14259B 0%, #070D29 100%)",
    color: "#fff",
    fontFamily: "OpenSans",
    height: "56px"
    //padding: "0 23px"
  },
  back: {
    padding: "24px 23px",
    fill: "#fff"
  },
  backSvg: {
    height: "17px",
    width: "auto"
  },
  title: {
    fontSize: 13
  },
  inPageButton: {
    position: "relative",
    top: -5,
    padding: "10px",
    color: "#ddd",
    "&:hover": {
      color: "#fff"
    }
  },
  top: {
    background: "#0e5bb3",
    minHeight: "50px",
    "& iframe": {
      display: "block"
    }
  },
  dn: {
    display: "none"
  },
  error: {
    minHeight: "50px",
    textAlign: "center",
    color: "#fff",
    background: "#0e5bb3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const MatchLiveHeader = props => {
  const classes = useStyles();
  const { match, goBack, mType, idMatch, inPage, className, t, i18n, onResize, noHeader } = props; // eslint-disable-line

  const [hasError, setHasError] = React.useState(false);

  const generator = React.useRef(null);
  const initedForId = React.useRef("");
  React.useEffect(() => {
    if (idMatch && initedForId.current !== idMatch) {
      const elem = document.getElementById("Tracker-1");
      if (elem) {
        //elem.innerHTML = "";
        setHasError(false);
        if (generator.current === null) {
          const lspGenerator = LSPGenerator();
          if (!lspGenerator) return;
          generator.current = lspGenerator.create("#Tracker-1", "tracker1", idMatch, 929, { lang: i18n.language, timezone: 'Local', useMappedId: "1" });
        } else {
          generator.current.setEvent(parseInt(idMatch, 10));
          generator.current.refresh();
        }
        initedForId.current = idMatch;
      }
    } else if (!idMatch) {
      const elem = document.getElementById("Tracker-1");
      if (elem) {
        elem.innerHTML = "";
      }
    }
  }, [idMatch, i18n.language]);

  const handleError = () => {
    debug("TRACKER error");
    setHasError(true);
  };
  const handleResize = () => {
    debug("TRACKER resize");
    if (typeof onResize === "function") onResize();
  };

  React.useEffect(() => {
    window.addEventListener('statscore.livematchpro.tracker1.error', handleError);
    window.addEventListener('statscore.livematchpro.tracker1.resize', handleResize);
    window.addEventListener('statscore.livematchpro.error', handleError);

    return () => {
      window.removeEventListener('statscore.livematchpro.tracker1.error', handleError);
      window.removeEventListener('statscore.livematchpro.tracker1.resize', handleResize);
      window.removeEventListener('statscore.livematchpro.error', handleError);
      if (generator.current) generator.current.remove();
      debug("MatchStatScore cleanup");
    }
  }, []); // eslint-disable-line

  if (!match) return null;

  return (
    <div className={`${classes.root} match-live-header ${className ? className : ''}`}>
      {!noHeader && <div
        className={`${classes.topper} bar-top match-modal-top d-flex flex-nowrap align-items-center justify-content-between`}
      >
        <div>
          {!inPage && <ButtonBase className={classes.back} onClick={goBack}>
            <BackIcon className={classes.backSvg} />
          </ButtonBase>}
          {inPage && <ButtonBase className={classes.inPageButton} onClick={goBack}>
            <HighlightOffOutlinedIcon />
          </ButtonBase>}
        </div>
        <div className={classes.title}>{t("Match details")}</div>
        <div>
          <Favorite mType={mType} idMatch={idMatch} type="header" />
        </div>
      </div>}
      <div className={`${classes.top} ${hasError ? classes.dn : ''}`} id="Tracker-1">
      </div>
      {hasError && <div className={classes.error}>
        {t("No live event data")}
      </div>}
    </div >
  );
};

MatchLiveHeader.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  goBack: PropTypes.func,
  onAction: PropTypes.func,
  children: PropTypes.node,
  liveText: PropTypes.array
};

MatchLiveHeader.defaultProps = {
  title: "Title",
  id: "_id_",
  active: false,
  type: "fav",
  goBack: () => { },
  onAction: () => { },
  children: null,
  liveText: []
};

export default withTranslation()(MatchLiveHeader);
