import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './ClaimPopup.scss';
import Icons from "../../../../src/assets/Icons/Icons";
import {connect} from "react-redux";
import * as storeActions from "../../../store/actions";
import Button from "@material-ui/core/Button";
import Utils from "../../../utils/Utils";
import Translate from "../../../utils/Translate";
import {bonusAvailable} from "../../../store/actions/actionTypes";

const ClaimPopup = (props) => {

    const { t } = useTranslation();
    const [loadingBonuses,setLoadingBonuses] = useState(false);

    const claim = () => {
        props.reset();
        props.getBonus()
    }

    const goToProduct = () => {
        let product = 1;
        props.resetRequest();
        props.reset();
        props.resetBonusAvailable();
        if(typeof props.selectedBonus.eligible_products !== 'undefined') {
            product = props.selectedBonus.eligible_products[0];
        }

        switch (product) {
            case "1":
            case 1:
                return props.history.push('/cazino');
            case 2:
            case "2":
                if (window.config && window.config.betsEnabled === '1') {
                    return props.history.push('/pariuri');
                } else if (window.config && window.config.nsoftEnabled === '1') {
                    return props.history.push('/pariuri/prematch');
                }
                return props.history.push('/cazino');
            case 3:
            case "3":
                if (window.config && window.config.lottoEnabled === '1') {
                    return props.history.push('/lotto/home');
                }
                return props.history.push('/cazino');
        }
    }

    const goToHome = () => {
        props.reset();
        props.history.push('/')
    }

    const goToWallet = () => {
        props.reset();
        props.history.push('/profile/wallet')
    }

    const getContent = () => {

        if (!props.claimError) {
            return (
                    <div className="Content Success">
                        <div className="icon-success">
                            {Icons.get('checkmarkFilled')}
                        </div>
                        <h6>{props.t('Success!')}</h6>
                        <p>
                            <Translate text={"${bonusName} has been successfully been awarded to your account! GOOD LUCK!"}
                                placeholder={{
                                    bonusName: props.selectedBonus.bonus_available_name
                                }}
                            />
                        </p>

                        <Button fullWidth  size="large" className={'play'} onClick={goToProduct}>
                            {props.t("LET'S PLAY")}
                        </Button>
                    </div>
                )
        } else {
            if (typeof props.claimError.canRetry !== "undefined") {

                if (props.claimError.canRetry) {
                    return (
                        <div className="Content Warning">
                            <div className="icon-warning">
                                {Icons.get('exWarning')}
                            </div>
                            <h6>{props.t('Cancel active bonus?')}</h6>
                            <p>{props.t("Total BONUS funds in your account:")}
                                <div><strong>{Utils.formatAmount(props.claimError.amount/100,2,'ron')}</strong></div>
                            </p>
                            <p>
                                {Utils.t("T&C agreement prevents activating two bonuses at the same time. Cancel ${totalValue} and claim this bonus?",{
                                    "totalValue": Utils.formatAmount(props.claimError.amount/100,2,'ron')
                                })}
                            </p>
                            <Button fullWidth  size="large" className={'activate'} onClick={claim}>
                                {props.t("CLAIM BONUS")}
                            </Button>
                            <Button fullWidth  size="large" className={'cancel'} onClick={goToHome}>
                                {props.t("CLAIM LATER")}
                            </Button>

                        </div>
                    );
                } else {
                    return (
                        <div className="Content Warning">
                            <div className="icon-warning">
                                {Icons.get('exWarning')}
                            </div>
                            <h6>{props.t('Bonus conflict')}</h6>

                            <p>{props.t("The requested bonus cannot be active at the same time with the bonuses currently active in your account.")}</p>

                            <Button fullWidth  size="large" className={'see-bonuses'} onClick={goToWallet}>
                                {props.t("WALLET & BONUS")}
                            </Button>
                            <Button fullWidth  size="large" className={'activate-later'} onClick={goToHome}>
                                {props.t("CLAIM LATER")}
                            </Button>
                        </div>
                    );
                }

            } else {
                return (
                    <div className="Content Error">
                        <div className="icon-error">
                            {Icons.get('exWarning')}
                        </div>

                        <p>{props.t("We apologize for this inconvenience. We have run into a problem and the bonus has not been added to your account. Please try again later. If the issue continues, please contact the Customer Support.")}</p>

                        <Button fullWidth  size="large" className={'play'} onClick={goToHome}>
                            {props.t("OK")}
                        </Button>
                    </div>
                );
            }
        }
    }

    if (props.claimError === null) return null;

    return (
        <div id={"ClaimPopup"} className={`${props.popup ? 'popup' : ''}`}>
            <div className="ClaimWrapper">
                {getContent()}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        claimError: state.application.bonusClaimError
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getBonusAvailable: () => dispatch({type:bonusAvailable.GET}),//storeActions.getBonusAvailable()),
        resetRequest: () => dispatch(storeActions.resetOneRequest('get-bonus-available')),
        resetBonusAvailable: () => dispatch(storeActions.setBonusAvailable([])),
        reset: () => dispatch(storeActions.resetBonusClaimStatus())
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ClaimPopup)));
