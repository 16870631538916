import React from "react";
import PropTypes from "prop-types";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleIcon from '@material-ui/icons/People';
import { withRouter } from "react-router-dom";

import {
  formatCurrency,
  formatAmount,
  formatAmount2,
  formatAmountWithDecimals,
  formatTotalOddsValue,
  forceRoundIfAny
} from "../../../bets/utils/formatters";
import { debug, formatMessage, twoDecimalsFloat } from "../../../bets/utils";

import {
  betsSlipClear,
  betsSlipClearCreateStatus,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betsSlipToggleAutoAcceptOddChange,
  betsSlipToggleAllowTicketPlaceWithInvalidEvents,
  betsSlipSetTicketOnline,
  betsBonusRequestEvaluation,
  betSlipSetFreeBetIndex,
  betSlipFreeBetClear,
} from "../../../bets/store/actions/betsSlip";

import { getBetsBonuses, getBetsRingFences } from "../../../bets/store/selectors/wallet";
import { prematchCreateTicketRequest } from "../../../bets/store/actions/prematch";
import { liveCreateTicketRequest } from "../../../bets/store/actions/live";
import { getBetsState } from "../../../bets/store/selectors/betData";

import SimpleSwitch from "./SimpleSwitch";
import TicketSwitch from "./TicketSwitch";

import TicketModal from "./TicketModal";
import { doLogin } from "../../../store/actions/authentication";

import FixHorizontalScroll from "../../../bets/components/BetSlip/fix-horizontal-scroll";
import WinnerFunHelpDialog from "../../../bets/components/BetSlip/rule-dialogs";
const SoldCard = props => {
  const {
    amount,
    tax,
    taxPercent,
    totalOdds,
    winAmount,
    autoAcceptOddChange,
    stakeSet,
    toggleAutoAcceptOddChange,
    placeTicketEnabled,
    placeTicketErrorMessage,
    liveCreateTicket,
    prematchCreateTicket,
    clearBets,
    clearCreateStatus,
    auth,
    ticketOnline,
    betsSlipSetTicketOnline,
    minWinAmount,
    ticketType,
    wallet,
    profile,
    exitFull,
    liveBets,
    prematchBets,
    bonus,
    bonusEvaluate,
    walletBonusEvaluate,
    walletBonuses,
    walletRingFences,
    requestBonusEvaluation,
    currentTicket,

    allowTicketPlaceWithInvalidEvents,
    toggleAllowTicketPlaceWithInvalidEvents,

    freeBets,
    selectedFreeBet,
    selectedFreeBetSubIndex,
    betSlipFreeBetClear,
    betSlipSetFreeBetIndex,
    freeBetsEvaluate,

    t,
  } = props;

  const [stakeAmount, setStakeAmount] = React.useState(amount)
  const [ruleDialog, setRuleDialog] = React.useState({
    open: false,
  });
  const [disableSwitch, setDisableSwitch] = React.useState(false);

  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const handlePlaceBetClose = (type = 0) => {
    setOpenPlaceBet(false);
    if (type === 1) {
      window.location.hash = "#tickets-details";
    }
    if (type !== 2) {
      clearBets();
    } else {
      clearCreateStatus();
    }
  };

  const handleDelete = () => {
    clearBets();
  };
  React.useEffect(() => {
    if (freeBetsEvaluate && freeBetsEvaluate.data && freeBets && selectedFreeBet !== -1) {
      try {
        if (freeBets[selectedFreeBet] && freeBets[selectedFreeBet].code) {
          const code = freeBets[selectedFreeBet].code;
          if (typeof freeBetsEvaluate.data[code] !== "undefined" && !freeBetsEvaluate.data[code]) {
            betSlipFreeBetClear();
          }
        }
      } catch (err) {
        /*noop*/
      }
    }
  }, [freeBetsEvaluate, freeBets, selectedFreeBet, betSlipFreeBetClear]);

  React.useEffect(() => {
    if (liveBets && liveBets.length) {
      setDisableSwitch(true);
      if (!ticketOnline) {
        betsSlipSetTicketOnline(true);
      }
    } else {
      setDisableSwitch(false);
    }
  }, [ticketOnline, liveBets, betsSlipSetTicketOnline]);

  React.useEffect(() => {
    if (auth && ["user", "token"].indexOf(auth.auth_type) === -1) {
      betsSlipSetTicketOnline(false);
    } else {
      betsSlipSetTicketOnline(true);
    }
  }, [auth, betsSlipSetTicketOnline]);

  React.useEffect(() => {
    requestBonusEvaluation();
  }, [liveBets, prematchBets, wallet, profile, requestBonusEvaluation, selectedFreeBet]);

  React.useEffect(() => {
    setStakeAmount(amount);
  }, [amount]);

  const handleChange = event => {
    toggleAutoAcceptOddChange();
  };
  const handlePlaceChange = event => {
    toggleAllowTicketPlaceWithInvalidEvents();
  };

  const handleTicketLocation = online => {
    //console.log(online ? "online" : "agentie");
    betsSlipSetTicketOnline(online);
  };

  const handleStakeChange = e => {
    //console.log("stake change", e.target.value)
    if (selectedFreeBet !== -1) return;

    let ev = e.target.value;

    // let them input an empty string
    if (ev === "") {
      setStakeAmount("");
      stakeSet("");
      return;
    }

    // don't let them put an amount < 1
    if (ev === "0") {
      return;
    }

    // convert , to .
    ev = ev.split(",").join(".");

    // only one .
    const sev = ev.split(".");
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      ev = sev[0] + "." + sev[1].substring(0, 2);
    }

    let v = "";

    if (ev !== "") {
      // amounts ending with . have the same value
      if (ev.endsWith(".")) {
        setStakeAmount(ev);
        return;
      }

      // only valid numbers
      if (isNaN(+ev)) {
        return;
      }

      // convert amount
      v = forceRoundIfAny(ev);
      if (isNaN(v)) return;
    }

    // update amount
    setStakeAmount(v);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  const createTicket = () => {
    setOpenPlaceBet(true);

    let free_bet_code = null;
    let free_bet_name = null;
    let free_bet_pid = null;
    let free_bet_redeemable = 0;
    if (selectedFreeBet > -1) {
      const freeBet = freeBets[selectedFreeBet];
      free_bet_code = freeBet.code;
      free_bet_name = freeBet.name;
      free_bet_pid = freeBet.fbid;
      free_bet_redeemable = freeBet.redeemable;
    }

    liveCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    //prematchCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    betSlipFreeBetClear();
  };

  const handleLogin = () => {
    if (typeof exitFull === "function") exitFull();
    localStorage.setItem("redirect_after_login", props.location.pathname);
    if (window.config.platformType === 2) {
      // mobile - do a redirect
      props.history.push("/connect");
    } else {
      // web - show modal
      props.doLogin();
    }
  };

  let loggedIn = false;
  if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const onFreeBet = (index) => (subIndex) => () => {
    if (selectedFreeBet === index && selectedFreeBetSubIndex === subIndex) {
      betSlipFreeBetClear();
      requestBonusEvaluation();
    } else {
      betSlipSetFreeBetIndex(index, freeBets[index], subIndex);
      const freeBetAmount = freeBets[index].amount_small === 1 ? freeBets[index].amount / 100 : freeBets[index].amount;
      stakeSet(freeBetAmount);
    }
  };

  const buildFreeBets = () => {
    const buttons = [];

    if (!ticketOnline) return buttons;
    const now = Math.floor(+ new Date() / 1000);

    freeBets.forEach((fb, i) => {
      if ((fb.product === "sport" || fb.product === "sport.live" || fb.product === "sport.prematch") && fb.count && fb.expires > now) {
        const amount = fb.amount_small === 1 ? fb.amount / 100 : fb.amount;

        let freeBetDisabled = false;
        if (freeBetsEvaluate && freeBetsEvaluate.data && typeof freeBetsEvaluate.data[fb.code] !== "undefined" && !freeBetsEvaluate.data[fb.code]) {
          freeBetDisabled = true;
        }
        if (liveBets && liveBets.length && fb.product !== "sport.live") {
          freeBetDisabled = true;
        }
        if (prematchBets && prematchBets.length && fb.product !== "sport.prematch") {
          freeBetDisabled = true;
        }

        const onClickHandler = onFreeBet(i);

        [...Array(fb.count).keys()].forEach(j => {
          let clickHandler = () => { };
          if (freeBetDisabled) {
            clickHandler = handleRuleOpen("freeBet", fb.fbid);
          } else {
            clickHandler = onClickHandler(j);
          }

          buttons.push((<ButtonBase
            key={`freeBet_${i}_${j}`}
            onClick={clickHandler}
            variant="contained"
            className={`
              fb_increment fb_freeBetButton
              ${selectedFreeBet === i && selectedFreeBetSubIndex === j ? 'fb_freeBetButtonSelected' : ''} 
              ${freeBetDisabled ? 'fb_freeBetButtonDisable' : ''}
            `}
          >
            <b>{amount}&nbsp;<span className={"smaller"}>Lei</span></b>
            <div className={`fb_freeBetTicker freeBetTicker`} title={t(fb.name)}>Free Bet</div>
            {(freeBetDisabled) && <div className={`fb_freeBetQuery pulse-anim freeBetQuery`} title={t(fb.name)}>?</div>}
          </ButtonBase>))
        });
      }
    });

    return buttons;
  };

  //console.log("BONUS", bonus, "AMOUNT", amount);

  let ticketAppliedBonus = null;
  if (bonus && bonus.ticketAppliedBonus) {
    ticketAppliedBonus = (
      <div
        className={`sold-holder d-flex flex-nowrap align-items-center justify-content-between`}
      >
        <div className={'sold-small'}>
          {t("Progressive Bonus")} {bonus.ticketAppliedBonus.maxPercentage}%
        </div>
        <div className={'sold-small'}>
          +{formatAmount(bonus.ticketAppliedBonus.amountMax, false, false)}{" "}
          {formatCurrency(wallet.currency)}
        </div>
      </div>
    );
  }

  let ticketBonusEligibles = null;
  if (bonus && bonus.ticketBonusEligibles && bonus.ticketBonusEligibles.length) {
    const bonuses = [];
    bonus.ticketBonusEligibles.forEach(b => {
      if (b.eligible) {
        bonuses.push(b.bonus.name);
      }
    });
    ticketBonusEligibles = (
      <div
        className={`sold-holder d-flex flex-nowrap align-items-center justify-content-between`}
      >
        <div className={'sold-small'}>{t("Eligible Bonuses")}</div>
        <div className={'sold-small'}>{bonuses.join(" + ")}</div>
      </div>
    );
  }

  let bonusEvaluateMessagesEligible = null;
  let hasBonusEvaluateMessages = false;
  let stakeErrorBonuses = [];

  let free_money_used = 0;
  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  const hasBets = liveBets.length + prematchBets.length > 0;

  if (window.config.useBonusEvaluation === "1" && hasBets) {
    const walletBonusesHash = {};
    if (walletBonuses) {
      walletBonuses.forEach(wb => {
        walletBonusesHash[wb.id] = wb.name
      });
    }

    const bonuses = [];
    let usedBonuses = [];
    let usedRingFences = [];
    let errorBonuses = [];
    //let eligible = false;

    if (walletBonusEvaluate && walletBonusEvaluate.success && walletBonusEvaluate.data && walletBonusEvaluate.data.bonus) {
      bLimit = free_money_used = twoDecimalsFloat(walletBonusEvaluate.data.free_money_used);
      usedBonuses = walletBonusEvaluate.data.bonus ? walletBonusEvaluate.data.bonus : [];
      usedRingFences = walletBonusEvaluate.data.ring_fence ? walletBonusEvaluate.data.ring_fence : [];
      errorBonuses = walletBonusEvaluate.data.details ? walletBonusEvaluate.data.details : [];
      //eligible = walletBonusEvaluate.data.eligible;
      valid = walletBonusEvaluate.data.valid;
    } else {
      valid = bLimit >= amount;
    }

    walletBonuses.forEach(wb => {
      let total_used = 0;
      let balance_total = wb.amount;

      // collect what would the total amount ring fence + bonus would be
      const rf = walletRingFences.find(rf => wb.id === rf.id);
      if (rf) {
        balance_total += rf.amount;
      }

      // used ring fence
      const urf = usedRingFences.find(rf => wb.id === rf.id);
      if (urf) {
        bLimit += twoDecimalsFloat(urf.balance_used);
        total_used += twoDecimalsFloat(urf.balance_used);
      }

      // used bonus
      const ub = usedBonuses.find(b => wb.id === b.id);
      if (ub) {
        bLimit += twoDecimalsFloat(ub.balance_used);
        total_used += twoDecimalsFloat(ub.balance_used);
      }

      total_used = twoDecimalsFloat(total_used);
      bonuses.push({ id: wb.id, name: wb.name, balance: balance_total, balance_used: total_used ? -total_used : 0 });
    });

    bLimit = twoDecimalsFloat(bLimit);
    //bonuses.sort((a, b) => a.balance - b.balance);

    //console.log("bonuses", bonuses, errorBonuses); 

    bonusEvaluateMessagesEligible = (
      <div>
        {bonuses.map((b, i) => {
          const eb = errorBonuses.filter(eb => eb.id === b.id);
          //console.log("errorBonuses", eb);

          hasBonusEvaluateMessages = true;

          return (
            <div key={"bonus_" + i}>
              <div className={`sold-holder d-flex flex-row flex-nowrap justify-content-between align-items-center}`} key={i}>
                <div className={'sold-small'}>
                  {!valid && eb.length > 0 && (<span className={'bonusHasErrors'}>!</span>)}
                  {valid && eb.length > 0 && (<span className={'bonusHasWarnings'}>!</span>)}
                  {b.name}
                </div>
                <div className={'sold-small'}>{formatAmountWithDecimals(b.balance, 2, true)} Lei (<span className={'messageErr'}>{formatAmountWithDecimals(b.balance_used, 2, true)}</span>)</div>
              </div>
              {!valid && eb.length > 0 && <div className={`bonusErrorBar`}>
                <div className="txt">{t("Conditiile BONUS nu sunt indeplinite")}</div>
                <div className="bar-question-mark">?</div>
              </div>}
              {valid && eb.length > 0 && <div className={`bonusWarningBar`}>
                <div className="txt">{t("Acest bilet nu va contribui la rulajul bonusului")}</div>
                <div className="bar-question-mark">?</div>
              </div>}
              <div className={'bonusBalanceErrors'}>
                {!valid && eb.filter(e => e.error && e.error.type === "ticket").map((e, i) => {
                  const args = [...e.error.args];
                  args[0] = (walletBonusesHash[eb.id] ? walletBonusesHash[eb.id] + ": " : "") + t(args[0]); // see if we have a translation for that message

                  if (e.error.section === "generic") {
                    return (<div key={i} className={'message'}>{formatMessage(...args)}</div>);
                  } else if (e.error.section === "stake") {
                    stakeErrorBonuses.push(<div key={i} className={'message'}>{formatMessage(...args)}</div>)
                  }
                  return null;
                })}
              </div>
            </div>
          )
        })}
      </div>
    );
  }

  const handleRuleOpen = (type, eventName) => () => {
    debug(`handleRuleOpen: type = ${type}, eventName = ${eventName}`)
    setRuleDialog({
      open: true,
      eventName: eventName,
      type: type
    })
  };
  const handleRuleClose = () => {
    setRuleDialog({
      open: false,
      eventName: "",
      type: ""
    })
  };

  //const onlineTicketDisabled =
  //    (amount > bLimit && bLimit !== 0 && selectedFreeBet === -1) || !placeTicketEnabled || amount < 2 || (!valid);

  let onlineTicketDisabled = false;
  if (selectedFreeBet === -1 && !(bLimit !== 0 && bLimit >= amount)) {
    onlineTicketDisabled = true;
  } else if (!placeTicketEnabled) {
    onlineTicketDisabled = true;
  } else if (amount < 2) {
    onlineTicketDisabled = true;
  } else if (!valid) {
    onlineTicketDisabled = true;
  }

  let disableBetTicketInShop = false;
  if (typeof window.config.disableBetTicketInShop !== "undefined" && window.config.disableBetTicketInShop === "1") {
    disableBetTicketInShop = true;
    if (!ticketOnline) {
      setTimeout(() => {
        betsSlipSetTicketOnline(true);
      }, 0);
    }
  }

  return (<div className="sold-card">
    <div className="totals">
      <div className="rw clearfix odds">
        <div className="label">{t("Total odds")}</div>
        <div className="value">{formatTotalOddsValue(totalOdds)}</div>
      </div>
      <div className="rw clearfix available">
        <div className="label">{t("AVAILABLE").toLowerCase()}</div>
        <div className="value">
          {formatAmountWithDecimals(wallet.main, 2, true)} {formatCurrency(wallet.currency)}
          {!!free_money_used && <span>
            &nbsp;(<span className={'messageErr'}>-{formatAmountWithDecimals(selectedFreeBet !== -1 ? 0 : free_money_used, 2, true)}</span>)
          </span>}
        </div>
      </div>
      {(!!ticketAppliedBonus || !!ticketBonusEligibles || !!bonusEvaluateMessagesEligible) && <div className={'hr2'}></div>}
      {ticketAppliedBonus}
      {ticketBonusEligibles}
      {bonusEvaluateMessagesEligible}
      {(!!ticketAppliedBonus || !!ticketBonusEligibles || !!bonusEvaluateMessagesEligible) && <div className={'hr2'}></div>}
      <div className="rw clearfix stk">
        <div className="label">{t("Total Stake")}</div>
        <div className="value">
          {selectedFreeBet !== -1 && <div className="fb_stake">Free Bet {stakeAmount} {formatCurrency(wallet.currency)}</div>}
          {selectedFreeBet === -1 && <input
            type="text"
            className={`input ${stakeAmount < 2 ? "warning-input" : ""}`}
            onChange={handleStakeChange}
            value={stakeAmount}
          />}
        </div>
      </div>
      {window.config.useBonusEvaluation === "1" && stakeErrorBonuses.length !== 0 && <div className={'stakeBonusBalanceErrors'}>
        {stakeErrorBonuses}
      </div>}
      {stakeAmount < 2 && <div className={"warning-text"}>{t("Minim stake of")} 2 Lei</div>}
      {(tax > 0 || ticketType === "system") && (
        <div className="bonuses">
          <div className="rw clearfix">
            <div className="label">{t("Payin Tax")} ({taxPercent}%)</div>
            <div className="value">{formatAmount2(tax)}</div>
          </div>
        </div>
      )}
      <div className="rw clearfix important">
        <div className="label text-uc">{t("Possible Winning")}</div>
        <div className="value">{winAmount === "" ? 0 : formatAmount2(winAmount)}</div>
      </div>
      {ticketType === "system" &&
        <div className="rw clearfix">
          <div className="label text-uc">{t("Min. Winning")}</div>
          <div className="value">{winAmount === "" ? 0 : formatAmount2(minWinAmount)}</div>
        </div>
      }

    </div>

    <FixHorizontalScroll
      className={`fb_incrementsHolder ${freeBets && freeBets.length !== 0 ? 'has-free-bets' : ''} d-flex flex-row flex-nowrap justify-start align-items-center`}
    >
      {freeBets.length !== 0 && buildFreeBets()}
    </FixHorizontalScroll>

    <div className="controls clearfix">
      <div>
        <div className="col empty">
          <button className="button del" onClick={handleDelete}>
            <DeleteIcon />
          </button>
        </div>
        <div className="col payin">
          {(loggedIn || !ticketOnline) && (
            <React.Fragment>
              {ticketOnline && (
                <button className="button important user text-cp"
                  onClick={createTicket}
                  disabled={onlineTicketDisabled}>
                  {t("PLACE TICKET ONLINE")}
                </button>
              )}
              {!ticketOnline && (
                <button className="button important user text-cp"
                  onClick={createTicket}
                  disabled={!placeTicketEnabled || amount < 2}>
                  {t("PLACE TICKET IN SHOP")}
                </button>
              )}
            </React.Fragment>
          )}
          {!loggedIn && ticketOnline && (
            <button className="button important user text-cp" onClick={handleLogin}>
              <PeopleIcon /> {t("Sign-in")}
            </button>
          )}
        </div>
      </div>
    </div>
    {
      !placeTicketEnabled && placeTicketErrorMessage !== "" && (
        <div className={'footer-text'}>{t(placeTicketErrorMessage)}</div>
      )
    }
    <div className="accept-odds">
      <div className="label">{t("Automatically accept odds changes")}</div>
      <SimpleSwitch checked={autoAcceptOddChange} onChange={handleChange} />
    </div>
    <div className="accept-odds">
      <div className="label">{t("Place automatically without unavailable events")}</div>
      <SimpleSwitch checked={allowTicketPlaceWithInvalidEvents} onChange={handlePlaceChange} />
    </div>


    {!disableBetTicketInShop && <div className="switch-place">
      {disableSwitch &&
        <div className="footer-text">{t("Live tickets can only be played online.")}</div>
      }
      <TicketSwitch online={ticketOnline} isDisabled={disableSwitch} onChange={handleTicketLocation} />
    </div>}

    {openPlaceBet && <TicketModal openPlaceBet={openPlaceBet} handlePlaceBetClose={handlePlaceBetClose} />}
    {ruleDialog.open && <WinnerFunHelpDialog open={ruleDialog.open} onClose={handleRuleClose} eventName={ruleDialog.eventName} type={ruleDialog.type} />}
  </div>)
};

SoldCard.propTypes = {
  bets: PropTypes.array,
};

SoldCard.defaultProps = {
  bets: [],
};

const mapStateToProps = state => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    wallet: state.wallet,
    profile: state.profile,
    amount: ct.amount,
    tax: ct.tax,
    taxPercent: ct.taxPercent,
    winAmount: ct.totalMaxWinAmount,
    minWinAmount: ct.totalMinWinAmount,
    totalOdds: ct.totalOdds,
    autoAcceptOddChange: ct.autoAcceptOddChange,
    allowTicketPlaceWithInvalidEvents: ct.allowTicketPlaceWithInvalidEvents,
    placeTicketEnabled: ct.placeTicketEnabled,
    placeTicketErrorMessage: ct.placeTicketErrorMessage,
    errorMessage: ct.errorMessage,
    auth: state.authentication,
    ticketOnline: ct.ticketOnline,
    ticketType: ct.ticketType,
    liveBets: ct.live.selected,
    prematchBets: ct.prematch.selected,
    bonus: ct.bonus,
    walletBonusEvaluate: ct.bonusEvaluate,
    walletBonuses: getBetsBonuses(state),
    walletRingFences: getBetsRingFences(state),
    currentTicket: ct,
    freeBets: state.freeBets.freeBets,
    selectedFreeBet: bst.betsSlip.selectedFreeBet,
    selectedFreeBetSubIndex: bst.betsSlip.selectedFreeBetSubIndex,
    freeBetsEvaluate: ct.freeBetsEvaluate,
  };
};

const actionCreators = {
  clearBets: betsSlipClear,
  clearCreateStatus: betsSlipClearCreateStatus,
  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  toggleAutoAcceptOddChange: betsSlipToggleAutoAcceptOddChange,
  toggleAllowTicketPlaceWithInvalidEvents: betsSlipToggleAllowTicketPlaceWithInvalidEvents,
  liveCreateTicket: liveCreateTicketRequest,
  prematchCreateTicket: prematchCreateTicketRequest,
  betsSlipSetTicketOnline: betsSlipSetTicketOnline,
  doLogin: doLogin,
  requestBonusEvaluation: betsBonusRequestEvaluation,
  betSlipSetFreeBetIndex: betSlipSetFreeBetIndex,
  betSlipFreeBetClear: betSlipFreeBetClear,
};

export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(SoldCard)));
