import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "./happy-hours.scss";
import Timer from "../../components/timer";
import GiftImage from "../../assets/gift@2x.png";
import { ReactComponent as UpArrowIcon } from "../../assets/up-arrow.svg";
import { ReactComponent as UpDownIcon } from "../../assets/down-arrow.svg";
import { happyHourShowInfo } from "../../../../store/actions/happy_hour";

import { closeGameLauncher } from "../../../../store/actions/games";
import DialogSwitchTournament from "../../../../tournaments-missions/dialogs/dialog-switch-tournament";

import IconDots from "../../assets/icon-dots.svg";
import IconClose from "../../assets/icon-close.svg";

const LandscapeMode = ({ collapsed, onCollapsed, data, betInfo, happyHourShowInfo, t, closeGameLauncher, history }) => {

  const [openTournamentsDialog, setOpenTournamentsDialog] = React.useState(false);

  const [state, setState] = React.useState(data);
  React.useEffect(() => {
    setState(data);
  }, [data]);

  const [bet, setBet] = React.useState(betInfo);
  React.useEffect(() => {
    setBet(betInfo);
  }, [betInfo]);

  if (!state.enabled) return null;

  const toggleExpanded = (e) => {
    onCollapsed();
    e.stopPropagation();
  };

  const showInfo = (e) => {
    happyHourShowInfo();
  };

  const handleCloseTournamentsDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(false);
  };
  const showMenu = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(true);
  };
  const closeGame = () => {
    closeGameLauncher();
    if (window._CanGoBack) {
      history.goBack();
      return;
    }
    history.push("/cazino/jocuri");
  };

  const showWarning = bet && !bet.eligible;

  if (!collapsed) {
    return (
      <div className={`hh-landscape-parent ${showWarning ? 'warning' : ''}`} onClick={showInfo}>
        <div className="hh-footer-txt">
          <div className="hh-ft">{t("Minimum bet for")}<br />{state.name}: {bet && bet.min_bet ? bet.min_bet : 0} Lei</div>
        </div>
        <div className={`hh-landscape ${showWarning ? 'warning' : ''}`}>
          <div className="hh-buttons">
            <div className="hh-fi-close" onClick={closeGame}>
              <img src={IconClose} alt="" />
            </div>
            <div className="hh-fi-dots" onClick={showMenu}>
              <img src={IconDots} alt="" />
            </div>
          </div>
          <div className="hh-name">
            {state.name}
          </div>
          <div>
            <Timer date={state.date} />
          </div>

          <div>
            <div className="hh-pills">
              <div className="hh-pill op-20"></div>
              <div className="hh-pill op-35"></div>
              <div className="hh-pill op-49"></div>
              <div className="hh-pill active"></div>
              <div className="hh-pill op-49"></div>
              <div className="hh-pill op-35"></div>
              <div className="hh-pill op-20"></div>
            </div>
            <div className="hh-pills-text">
              <span className="hh-all-prizes hidden">&nbsp;/ {state.total_prizes}</span>
              <span className="hh-current-prize">{state.current_prize}</span>
              <span className="hh-all-prizes">&nbsp;/ {state.total_prizes}</span>
            </div>
          </div>

          <div className="hh-prizes">
            <div className="hh-prize-current">
              <div className="hh-img">
                {/*<GiftIcon />*/}
                <img src={GiftImage} alt="" />
              </div>
              <div className="hh-txt">
                {state.prize_current ? state.prize_current.amount : 0} Lei
              </div>
              <div className="hh-bet">{t("Minimum Bet")}: {state.prize_current ? state.prize_current.min_bet : 0}</div>
            </div>
          </div>
        </div>
        {openTournamentsDialog && <DialogSwitchTournament open={openTournamentsDialog} onClose={handleCloseTournamentsDialog} />}
      </div>
    );
  }

  const genPrize = (prize, className) => {
    const isSpecialPrize = prize && !prize.amount && typeof prize.custom !== "undefined" ? true : false;
    if (!isSpecialPrize) {
      return (
        <React.Fragment>
          <span className={`hh-val ${className ? className : ''}`}>{prize ? prize.amount : 0}</span> <span className="hh-currency">Lei</span>
        </React.Fragment>
      )
    }

    return (
      <span className={`hh-special-prize-img`}><img src={prize.custom.thumbnail} alt="" /></span>
    )
  };

  return <div className={`hh-landscape-parent-simple ${showWarning ? 'warning' : ''}`} onClick={showInfo}>
    <div className="hh-landscape-collapsed">
      <div className="hh-name">
        {state.name}
      </div>
      <div className="hh-pills-text">
        <span className="hh-current-prize">{state.current_prize}</span>
        <span className="hh-all-prizes">&nbsp;/ {state.total_prizes}</span>
      </div>
      <div className="hh-content">
        <div className="hh-txt">
          {genPrize(state.prize_before)}
        </div>
        <div className="hh-txt">
          <span className="gt">&rsaquo;</span>
        </div>
        <div className="hh-flx">
          <div className="hh-img">
            {/*<GiftIcon />*/}
            <img src={GiftImage} alt="" />
          </div>
          <div className="hh-txt">
            {state.prize_current ? state.prize_current.amount : 0} Lei
          </div>
        </div>
        <div className="hh-txt">
          <span className="gt">&rsaquo;</span>
        </div>
        <div className="hh-txt">
          {genPrize(state.prize_after, "gold")}
        </div>
      </div>
      <Timer date={state.date} type="simple" />
      <div className="hh-fi" onClick={toggleExpanded}>
        <UpDownIcon />
      </div>
    </div>
    <div className="hh-footer-txt hh-full-height" onClick={toggleExpanded}>
      <div className="hh-ft">{t("Minimum bet for")} {state.name}: {bet && bet.min_bet ? bet.min_bet : 0} Lei</div>
      <div className="hh-fi">
        <UpDownIcon />
      </div>
    </div>
  </div>
};

const actions = {
  happyHourShowInfo,
  closeGameLauncher
};

export default withRouter(connect(null, actions)(withTranslation()(LandscapeMode)));
