import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "./happy-hours.scss";
import Timer from "../../components/timer";

import { ReactComponent as UpArrowIcon } from "../../assets/up-arrow.svg";
import { ReactComponent as UpDownIcon } from "../../assets/down-arrow.svg";
import { happyHourShowInfo } from "../../../../store/actions/happy_hour";
import TermsDialog from "../../components/terms-dialog";

import { closeGameLauncher } from "../../../../store/actions/games";
import DialogSwitchTournament from "../../../../tournaments-missions/dialogs/dialog-switch-tournament";

import IconDots from "../../assets/icon-dots.svg";
import IconClose from "../../assets/icon-close.svg";

const LandscapeMode = ({ collapsed, onCollapsed, data, happyHourShowInfo, t, closeGameLauncher, history }) => {

  const [state, setState] = React.useState(data);
  React.useEffect(() => {
    setState(data);
  }, [data]);

  const [open, setOpen] = React.useState(false);

  const [openTournamentsDialog, setOpenTournamentsDialog] = React.useState(false);
  const handleCloseTournamentsDialog = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(false);
  };

  if (!state.enabled) return null;

  const showMenu = (e) => {
    if (e) e.stopPropagation();
    setOpenTournamentsDialog(true);
  };

  const closeGame = () => {
    closeGameLauncher();
    if (window._CanGoBack) {
      history.goBack();
      return;
    }
    history.push("/cazino/jocuri");
  };

  const openDialog = (e) => {
    setOpen(true);
  };
  const closeDialog = (e) => {
    setOpen(false);
  }

  const toggleExpanded = (e) => {
    onCollapsed();
    e.stopPropagation();
  };

  const prize = data.prize_current.custom;

  if (!collapsed) {
    return (
      <div className={`hh-landscape-parent`}>
        <div className={`hh-landscape hh-landscape-special-prize`} onClick={openDialog}>
          <div className="hh-buttons">
            <div className="hh-fi-close" onClick={closeGame}>
              <img src={IconClose} alt="" />
            </div>
            <div className="hh-fi-dots" onClick={showMenu}>
              <img src={IconDots} alt="" />
            </div>
          </div>
          <div className="hh-name">
            {state.name}
          </div>
          <div>
            <Timer date={state.date} />
          </div>

          <div className="hh-prizes">
            <div className="hh-special-prize">
              <div className="hh-img">
                <img src={prize.image_landscape} alt="" />
              </div>
            </div>
          </div>

          <div className="hh-sp-footer">
            &nbsp;
          </div>
          <div className="hh-sp-help">?</div>
        </div>
        <TermsDialog open={open} prize={prize} name={data.name} onClose={closeDialog} />
        {openTournamentsDialog && <DialogSwitchTournament open={openTournamentsDialog} onClose={handleCloseTournamentsDialog} />}
      </div>
    );
  }



  return <div className={`hh-landscape-parent-simple hh-landscape-special-prize`} >
    <div className="hh-special-prize-collapsed-img">
      <img src={prize.image_landscape_collapsed} alt="" />
    </div>

    <div className="hh-landscape-collapsed hh-landscape-special-prize" onClick={openDialog}>
      <div className="hh-name">
        {state.name}
      </div>
      <div className="hh-pills-text">
        <div className="hh-sp-help">?</div>
      </div>
      <div className="hh-content">
        &nbsp;
      </div>
      <Timer date={state.date} type="simple" />
      <div className="hh-fi" onClick={toggleExpanded}>
        <UpDownIcon />
      </div>
    </div>
    <TermsDialog open={open} prize={prize} name={data.name} onClose={closeDialog} />
  </div>
};

const actions = {
  happyHourShowInfo,
  closeGameLauncher
};

export default withRouter(connect(null, actions)(withTranslation()(LandscapeMode)));
