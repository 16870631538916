import React, { Component } from 'react';
import { PulseLoader } from 'react-spinners';
import { VelocityTransitionGroup } from 'velocity-react';
import { connect } from 'react-redux';
import * as stateActions from './../../../store/actions';

import './ContentLoader.css';

class ContentLoader extends Component {
    componentDidUpdate() {
        if (null === document.getElementById('ContentPage')) {
            return;
        }
        // measure height
        let newHeight = document.getElementById('ContentPage').clientHeight;
        // console.log(`[PAGE CONTENT COMPONENT] ContentPage height is: ${document.getElementById('ContentPage').clientHeight}`);
        // trigger new height action
        this.props.onPageHeight(newHeight);
    }

    componentDidMount() {
        if (null === document.getElementById('ContentPage')) {
            return;
        }
        // measure height
        let newHeight = document.getElementById('ContentPage').clientHeight;
        // console.log(`[PAGE CONTENT COMPONENT] ContentPage height is: ${document.getElementById('ContentPage').clientHeight}`);
        // trigger new height action
        this.props.onPageHeight(newHeight);
    }

    render() {
        let loaderState = this.props.contentLoader.show;
        return (
            <VelocityTransitionGroup enter={{ opacity: 1, top: 30 }} leave={{ opacity: 0, top: -50 }} duration={200}>
                {loaderState && <div className="ContentLoader">
                    <PulseLoader />
                </div>}
            </VelocityTransitionGroup>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPageHeight: (height) => dispatch(stateActions.newPageHeight(height)),
    };
};

const mapStateToProps = state => {
    return {
        contentLoader: state.contentLoader,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentLoader);