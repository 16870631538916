import React, { Component } from 'react';
import './MaterialInputField.scss';

class MaterialInputField extends Component {
    constructor(props) {
        super(props);

        this.inputLabel = "Input field";
        this.focusTimer = 0;
        this.allowFocus = true;
        if (true === props.noFocus) {
            this.allowFocus = false;
        }

        this.oneFocus = false;
    }

    componentDidMount() {
        if (!this.allowFocus) {
            return;
        }

        clearTimeout(this.focusTimer);
        this.focusTimer = setTimeout(() => {
            this.startFocus();
        }, 500);
    }

    componentDidUpdate() {
        if (!this.props.activeField) {
            return;
        }

        if (!this.allowFocus) {
            return;
        }

        if (true === this.oneFocus) {
            return;
        }

        clearTimeout(this.focusTimer);
        this.focusTimer = setTimeout(() => {
            this.startFocus();

            if (true === this.props.onlyOneFocus) {
                this.oneFocus = true;
                // console.log('setting new password to only one focus');
            }
        }, 500);
    }

    componentWillUnmount() {
        this.allowFocus = false;
        clearTimeout(this.focusTimer);
    }

    startFocus() {
        this.nameInput.focus();
    }

    render() {
        let inputValue = "";
        if (null !== this.props.inputValue) {
            inputValue = this.props.inputValue;
        }
        let elemIcon = "";
        if (null !== this.props.elemIcon) {
            elemIcon = this.props.elemIcon;
        }

        if (this.props.inputLabel) {
            this.inputLabel = this.props.inputLabel;
        }

        return (
            <div className="MaterialInputField">
                <div className="space-top"></div>
                <div className="input-centered">
                    <div className={"input-group " + this.props.groupClassName}>
                        {elemIcon}
                        <input
                            type={this.props.inputType}
                            id={this.props.inputIdName}
                            onChange={this.props.changeHandler}
                            value={inputValue}
                            required
                            ref={(input) => { this.nameInput = input; }}
                            onKeyPress={this.props.keyPressedHandler}
                            maxLength={this.props.maxLengthHandler ? this.props.maxLengthHandler : 38}
                            onFocus={this.props.focusHandler}
                            onBlur={this.props.blurHandler}
                            className="inputFieldMaterial"
                        />
                        <label htmlFor={this.props.inputIdName}>{this.inputLabel}</label>
                        <div className="input-bar"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MaterialInputField;