import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import moment from "moment";
import { withTranslation } from "react-i18next";

import Section from "../layout/Section";
import Collapsible from "../components/Collapsible";
import Match from "../components/Match";
import { getBetsState } from "../store/selectors/betData";
import BetSelectSingle from "../components/BetSelectSingle";
import InPageMD from "./InPageMD";
import { sortArrayByKey } from "../utils";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#EDF0F0",
    padding: "16px 14px"
  },
  empty: {
    textAlign: "center",
    padding: "30px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "#17214d"
  }
});

function PreMatchTopLeague(props) {
  const { idSport, topOffer, matchData, categories, tournaments, t } = props;
  const classes = useStyles();

  if (topOffer === null) {
    return null;
  }

  const to = Object.values(topOffer.tournaments).filter(t => t.idSport === idSport);
  sortArrayByKey(to, "tournamentPosition");

  let expanded = 0;

  let hasLeague = false;
  const chs = to.reduce((acc, t, i) => {
    if (!matchData ||
      typeof matchData[t.idSport] === "undefined" ||
      typeof matchData[t.idSport][t.idCategory] === "undefined" ||
      typeof matchData[t.idSport][t.idCategory][t.idTournament] === "undefined") {
      acc = [];
      return acc;
    }
    let ms = Object.values(matchData[t.idSport][t.idCategory][t.idTournament]);
    ms = ms.filter(m => m.active);

    sortArrayByKey(ms, "matchDateTime");

    const matchGroup = "mg_prematch_" + idSport + "_" + t.idCategory + "_" + t.idTournament;

    const displayMatches = [];
    let mCurrDate = ms && ms.length ? moment(ms[0].matchDateTime) : null;
    let currDate = ms && ms.length ? ms[0].matchDateTime : null;

    displayMatches.push(<BetSelectSingle
      key={displayMatches.length}
      matches={ms}
      match={ms.length ? ms[0] : null}
      mType="prematch"
      matchGroup={matchGroup}
      time={currDate}
    />);

    ms.forEach((m, i) => {
      if (mCurrDate) {
        const mDate = moment(m.matchDateTime);
        if (mCurrDate.format("YYYY-MM-DD") !== mDate.format("YYYY-MM-DD")) {
          mCurrDate = mDate;

          displayMatches.push(<BetSelectSingle
            key={displayMatches.length}
            matches={ms}
            match={ms.length ? ms[0] : null}
            mType="prematch"
            matchGroup={matchGroup}
            second={true}
            time={m.matchDateTime}
          />);
        }
      }
      hasLeague = true;
      displayMatches.push(<Match
        key={displayMatches.length}
        mType={m.mType}
        idMatch={m.idMatch}
        inGroup={true}
        matchGroup={matchGroup}
      />);
    });

    acc = acc.concat(
      <Collapsible
        type="preMatch"
        idSport={idSport}
        region={categories[t.idCategory].categoryIsoName}
        categoryDLId={categories[t.idCategory].categoryDLId}
        league={tournaments[t.idTournament].tournamentName}
        description={tournaments[t.idTournament].description}
        key={"c" + t.idCategory + t.idTournament}
        leagueDetails={`#league-details-prematch-${t.idSport}-${t.idCategory}-${t.idTournament}`}
        opened={expanded < 5}
        events={ms ? ms.length : 0}
      >
        {displayMatches}
      </Collapsible>
    );

    expanded++;

    return acc;
  }, []);

  return (
    <Section doRender={[idSport, chs]}>
      <InPageMD>
        {hasLeague && <div className={classes.root}>{chs}</div>}
      </InPageMD>
      {!hasLeague && <div className={classes.empty}>{t("There are no top leagues")}</div>}
    </Section>
  );
}

PreMatchTopLeague.propTypes = {
  idSport: PropTypes.string
};

PreMatchTopLeague.defaultProps = {
  idSport: "0"
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    idSport: bst.navSports.prematchTopLeagues,
    topOffer: bst.prematch.topOffer,
    matchData: bst.prematch.data,
    matches: bst.prematch.matches,
    categories: bst.prematch.categories,
    tournaments: bst.prematch.tournaments
  };
};

export default withTranslation()(connect(mapStateToProps)(PreMatchTopLeague));
