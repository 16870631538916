import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { mergeStyles } from "../../styles";
import eventStyles from "./styles";

import Team from "../../elements/TeamUK";
//import crests from "../../utils/crests";

import { stdLiveScore } from "../../utils/matchUtils";
import { makeGetMatchStatus, makeGetStateMatch } from "../../store/selectors/betData";
import Favorite from "../../elements/Favorite";

const useStyles = makeStyles({
	root: {
		position: "relative",
	},
	startTime: {
		fontSize: 9,
		fontWeight: 600,
		backgroundColor: "#313F80",
		borderRadius: 5,
		color: "#fff",
		padding: "4px 8px 4px",
		textTransform: "capitalize"
	},
	liveWrapper: {
		//padding: "7px 4px 0px 0",
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "space-around",
		alignItems: "center",
		alignContent: "center",
		cursor: "pointer",
		minHeight: "46px",
		padding: "2px 0",
		width: "100%",
		marginBottom: "3px",
		minWidth: "50px"
	},
	liveEventInterval: {
		minWidth: 24,
		fontSize: 12,
		fontWeight: 600,
		color: "#06D2BD"
	},
	liveEventTime: {
		marginTop: "7px",
		minWidth: 24,
		fontSize: 12,
		fontWeight: 600,
		color: "#009ED9"
	},
	emptyEventTime: {
		"&:after": {
			content: "'\\200b'"
		}
	},
	teamLine: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "baseline"
	},
	teamScore: {
		fontSize: 12,
		fontWeight: 600,
		marginLeft: "4px",
		whiteSpace: "nowrap",
		"&.colored": {
			color: "#01AE3B",
			fontWeight: "bold"
		}
	},
	tennisScoreWrapper: {
		marginLeft: "5px"
	},
	teamTennisScore: {
		color: "#06D2BD",
		fontSize: "10px",
		margin: "0 1px",
		lineHeight: "11px",
		flex: "1 1 0",
		textAlign: "center",
		"&:first-child": {
			marginLeft: "0"
		},
		"&:last-child": {
			marginRight: "0"
		}
	},
	teamSetScore: {
		fontSize: "14px",
		fontWeight: "600",
		color: "#06D2BD",
		"&.absolute": {
			position: "absolute",
			right: "-3px"
		},
		"&.top": {
			top: "-16px"
		},
		"&.bottom": {
			top: "23px"
		},
		"&.r2": {
			position: "relative",
			top: "3px"
		}
	},
	bDown: {
		borderBottom: "1px solid #666",
		paddingBottom: "2px"
	},
	bDownOnlyOneRow: {
		borderBottom: "1px solid #666",
		paddingBottom: "3px"
	},
	teamScoreTotal: {
		color: "#06D2BD"
	},
	eventTitle: {
		textTransform: "uppercase",
		fontSize: 9,
		fontWeight: 700,
		padding: "1px 0",
		borderRadius: 4,
		color: "#979DAB"
	},
	dark: {
		color: "#fff"
	},
	muted: {
		opacity: 0.6,
		color: "#cecece",
		"&.colored": {
			opacity: 1,
			color: "#DF2222"
		}
	},
	light: {
		color: "#000"
	},
	w100: {
		width: "100%"
	},
	pl: {
		paddingLeft: "5px"
	},
	liveBadge: {
		position: "relative",
		left: "-5px"
	},
	wrapperDesktop: {
		"& .teamName": {
			fontSize: "13px"
		},
		"& .teamFlag>img": {
			position: "relative",
			top: "-1px"
		}
	},
	minWidth0: {
		minWidth: 0
	},
	bCollapse: {
		borderCollapse: "collapse"
	},
	time: {
		position: "relative",
		paddingRight: "5px",
		margin: "-5px 0 -5px -17px",
		width: "74px",
		minWidth: "74px",
		textAlign: "center",
		minHeight: "92px",
		borderRight: "1px solid #202640",
		paddingTop: "25px",
		"&.not-dark": {
			borderRightColor: "#DFE7EF"
		}
	},
	live: {
		background: "#FF196E",
		borderTopLeftRadius: "7px",
		borderBottomRightRadius: "7px",
		padding: "4px 0",
		textAlign: "center",
		color: "#fff",
		fontSize: "8px",
		fontWeight: "600",
		fontStyle: "italic",
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		paddingRight: "5px"
	},
	intervalLetter: {
		fontSize: 14,
		fontWeight: 600,
		color: "#06D2BD",
		marginBottom: "7px"
	},
	intervalTime: {
		fontSize: 16,
		fontWeight: 600,
		color: "#009ED9"
	},
	cards: {
		textAlign: "right",
		whiteSpace: "nowrap",
		marginRight: "3px"
	},
	cardBottom: {
		marginBottom: "0"
	},
	cardTop: {
		marginTop: "0"
	},
	RED: {
		width: "6px",
		height: "10px",
		background: "#DF2222",
		display: "inline-block",
		marginRight: "3px"
	},
	YELLOW: {
		width: "6px",
		height: "10px",
		background: "#ffb300",
		display: "inline-block",
		marginRight: "3px"
	},
	favorite: {
		position: "absolute",
		top: "5px",
		right: "0"
	}
});

const Live = props => {
	const classes = mergeStyles(useStyles, eventStyles);

	let isDesktop = useMediaQuery("(min-width:1280px)");
	let isDesktop1600 = useMediaQuery("(min-width:1600px)");
	const { match, matchStatus, dark, inBetSlip, t } = props;

	if (!match) return null;

	if (inBetSlip) {
		isDesktop1600 = false;
	}

	//console.log("Live", props);

	let teamName = "#17214D";
	if (dark) {
		teamName = "#fff";
	}

	const teamNameMuted = "#586682";

	let teamScore = classes.teamScore;
	if (dark) {
		teamScore = `${classes.teamScore} ${classes.dark}`;
	}

	const scoreData = stdLiveScore(match, matchStatus);

	const losing = scoreData.teamLosing;
	const serving = scoreData.teamServing;

	const formatScore = (e, row, onlyOneRow = false) => {
		let isMuted = e[0] < e[1];
		let isColored = e[2].isColored;
		let isBig = e[2].isBig;
		let keystring = e[3];
		let isUnderlined = e[4];

		return (
			<td
				className={clsx(
					teamScore,
					{
						[`${classes.muted} ${!dark ? classes.light : ""}`]: isMuted,
						colored: isColored,
						[classes.bDown]: isUnderlined && !onlyOneRow,
						[classes.bDownOnlyOneRow]: isUnderlined && onlyOneRow,
						[`${classes.teamSetScore} ${row === 2 ? "r2" : ""}`]: isBig
					},
					`${classes.teamTennisScore} ${row === 2 ? "r2" : ""}`
				)}
			>
				&nbsp;
				{e[0] === "." && (
					<span className={`${classes.muted} ${!dark ? classes.light : ""}`}>
						&middot;
					</span>
				)}
				{e[0] !== "." ? e[0] : null}
				&nbsp;
			</td>
		);
	};

	let scores = null;
	let tr1 = scoreData.scoreWithFormatData.map((s, i) => {
		return [s[0], s[1], s[2], `s0_${i}`, true];
	});
	let tr2 = scoreData.scoreWithFormatData.map((s, i) => {
		return [s[1], s[0], s[2], `s1_${i}`, false];
	});

	const cards = {
		home: [],
		away: []
	};

	if (match && match.currentStatus) {
		const redCardsTotal1 = match.currentStatus.redCardsTotal1 ? match.currentStatus.redCardsTotal1 : 0;
		const redCardsTotal2 = match.currentStatus.redCardsTotal2 ? match.currentStatus.redCardsTotal2 : 0;

		if (redCardsTotal1)
			Array.from(Array(redCardsTotal1).keys()).forEach(i => {
				cards.home.push("RED");
				cards.away.push(null);
			});

		if (redCardsTotal2)
			Array.from(Array(redCardsTotal2).keys()).forEach(i => {
				cards.away.push("RED");
				cards.home.push(null);
			});

		/*
		const yellowCards1 = match.currentStatus.yellowCards1 ? match.currentStatus.yellowCards1 : 0;
		const yellowCards2 = match.currentStatus.yellowCards2 ? match.currentStatus.yellowCards2 : 0;
		if (yellowCards1)
			Array.from(Array(yellowCards1).keys()).forEach(i => {
				cards.home.push("YELLOW");
				cards.away.push(null);
			});

		if (yellowCards2)
			Array.from(Array(yellowCards2).keys()).forEach(i => {
				cards.away.push("YELLOW");
				cards.home.push(null);
			});
		*/
	}

	scores = (
		<div className={`${classes.tennisScoreWrapper} text-right pos-relative`}>
			<table className={classes.bCollapse}>
				<tbody>
					<tr>
						{cards.home.length > 0 && <td className={teamScore}>
							<div className={classes.cards}>
								{cards.home.map((c, i) => {
									let card = null;
									if (c) {
										card = <div key={i} className={`${classes[c]} ${classes.cardBottom}`}></div>
									}
									return card
								})}
							</div>
						</td>}
						{tr1.map(s => formatScore(s, 1, tr1.length === 1))}
					</tr>
					<tr>
						{cards.away.length > 0 && <td className={teamScore}>
							<div className={classes.cards}>
								{cards.away.map((c, i) => {
									let card = null;
									if (c) {
										card = <div key={i} className={`${classes[c]} ${classes.cardTop}`}></div>
									}
									return card
								})}
							</div>
						</td>}
						{tr2.map(s => formatScore(s, 2, tr2.length === 1))}
					</tr>
				</tbody>
			</table>
		</div>
	);

	let teamContent = (
		<div className={classes.liveWrapper}>
			<div
				className={`${classes.contentSlice}  ${classes.w100} ${classes.minWidth0} ${isDesktop ? classes.wrapperDesktop : ""
					} ${isDesktop1600 ? classes.pl : ""}`}
			>
				<Team
					color={losing === 1 ? teamNameMuted : teamName}
					name={match.team1Name}
					badge={null}
					serving={serving === 0 ? null : serving === 1 ? true : false}
					fullWidth
				/>
				<Team
					color={losing === 2 ? teamNameMuted : teamName}
					name={match.team2Name}
					badge={null}
					serving={serving === 0 ? null : serving === 2 ? true : false}
					fullWidth
				/>
			</div>

			{scores}
		</div>
	);

	if (isDesktop1600) {
		return (
			<div className={classes.root}>
				{isDesktop1600 && <div className={classes.favorite}>
					<Favorite
						justSpecial={true}
						mType={match.mType}
						dark={dark}
						idMatch={match.idMatch}
						winnerPlus={match.winnerPlus}
						winnerAdv={match.winnerAdv}
						winnerSpecial={match.winnerSpecial}
					/>
				</div>}
				<div className="d-flex flex-row flex-nowrap align-items-end">
					<div
						className={`d-flex flex-column flex-nowrap justify-content-center ${classes.time
							} ${!dark ? "not-dark" : ""}`}
					>
						<div className={classes.live}>{t("LIVE")}</div>
						<div>
							{scoreData.intervalTime !== "" && (
								<React.Fragment>
									<div className={classes.intervalLetter}>
										{/*scoreData.intervalLetter*/}
										{/*scoreData.currentInterval*/}
										{scoreData.intervalName}
									</div>
									<div className={classes.intervalTime}>
										{scoreData.intervalTime}
									</div>
								</React.Fragment>
							)}
							{scoreData.intervalTime === "" && (
								<div className={classes.intervalTime}>
									{/*scoreData.intervalLetter*/}
									{/*scoreData.currentInterval*/}
									{scoreData.intervalName}
								</div>
							)}
						</div>
					</div>
					{teamContent}
				</div>
			</div>
		);
	}

	return <div className={classes.root}>{teamContent}</div>;
};

const getStateMatch = makeGetStateMatch();
const getMatchStatus = makeGetMatchStatus();

const makeMapStateToProps = (state, props) => ({
	match: getStateMatch(state, props),
	matchStatus: getMatchStatus(state, props)
});


export default withTranslation()(connect(makeMapStateToProps)(Live));