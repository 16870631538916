import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEqual from "lodash/fp/isEqual";

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import {
  getLiveSportsListRetail,
} from "../../store/selectors/betData";
import { getBaseRouterPath } from "../../utils/config";
import Icons from "../icons";
import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import evBus from "../../utils/evbus";

const Search = (props) => {
  const { currentIndex, filter, t, onChange, className, liveSports, location, history } = props;

  const basePath = getBaseRouterPath();

  const [state, setState] = React.useState({
    sports: [],
  });

  React.useEffect(() => {
    setState(v => {
      let isModified = false;
      let tmp = { ...v };
      if (!isEqual(v.sports, liveSports)) {
        tmp.sports = liveSports;
        isModified = true;
      }
      if (isModified) return tmp;
      return v;
    });
  }, [liveSports]);

  const [active, setActive] = React.useState({
    idSport: "",
    path: ""
  });

  React.useEffect(() => {
    const path = location.pathname;
    const params = path.split("/").filter(v => !!v);

    const tmp = {
      idSport: "",
      path,
    };

    if (path.indexOf(`${basePath}/live/sport/`) > -1) {
      tmp.idSport = params[params.length - 1];
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });
    } else if (path.indexOf(`${basePath}/live/event/`) > -1) {
      tmp.idSport = params[params.length - 4];
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });

    } else if (path.indexOf(`${basePath}/live`) > -1) {
      setActive(v => {
        if (v.path !== tmp.path) {
          return tmp;
        }
        return v;
      });
    }
  }, [location, basePath]);

  const handleNavigate = (id) => () => {
    if (id) {
      history.push(`${basePath}/live/sport/${id}`);
    } else {
      history.push(`${basePath}/live`);
    }
  };

  const handleExpandMenu = () => {
    history.push(`/`);
    //evBus.emit("NSOFT-TOGGLE-LIVE-NAVBAR");
  };

  const handleFilterChange = (e) => {
    const val = e.target.value;
    onChange("filter", val);
  }
  const handleTabChange = (index) => () => {
    onChange("tab", index);
  }
  const clearText = () => {
    onChange("filter", "");
  };

  return (
    <div className={`search ${className ? className : ''}`}>
      <div className="tabs">
        <div className="tabs-wrap">
          <div className={`tab ${currentIndex === 0 ? 'active' : ''}`} onClick={handleTabChange(0)}>
            {t("Overview")}
          </div>
          <div className={`tab ${currentIndex === 1 ? 'active' : ''}`} onClick={handleTabChange(1)}>
            {t("Event view")}
          </div>
        </div>
      </div>
      <div className="nav-bar">
        <div className="home-wrapper" onClick={handleExpandMenu}>
          <div className="topIcon"><HomeIcon /></div>
          <div>{t("Home")}</div>
        </div>
        <div className="sport-wrapper">
          <div className={`navigation-item sport sport-border-bottom-color-0 ${'' === active.idSport ? 'active' : ''}`} key={`sport_${0}`} title={t("All")} onClick={handleNavigate(0)}>
            <div className="icon ">{Icons.get(0, "", "live")}</div>
          </div>
          {state.sports.map((sport, i) => {
            const idSport = sport.idSport;

            return (
              <div className={`navigation-item sport sport-border-bottom-color-${idSport} ${idSport === active.idSport ? 'active' : ''}`} key={`sport_${idSport}`} onClick={handleNavigate(idSport)}>
                <div className="icon">{Icons.get(idSport, "", "live")}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`input full ${currentIndex === 0 ? 'overview' : 'event-view'}`}>
        <input type="text" className="textbox" placeholder={t("Search")} value={filter} onChange={handleFilterChange} />
        <button className="button">
          {!filter && <SearchIcon />}
          {filter && <ClearIcon onClick={clearText} />}
        </button>
      </div>
    </div>
  );
}

Search.propTypes = {
  dateList: PropTypes.array,
  currentIndex: PropTypes.number,
  filter: PropTypes.string,

  sort: PropTypes.number,
  onChange: PropTypes.func
};

Search.defaultProps = {
  dateList: [],
  currentIndex: 0,
  filter: "",
  sort: 0,
  onChange: () => { }
};

const mapStateToProps = () => {
  return (state, props) => {
    const init = {
      mType: "live"
    };
    return {
      liveSports: getLiveSportsListRetail(state, init),
    };
  };
};

export default connect(mapStateToProps)(withTranslation()(withRouter(React.memo(Search))));