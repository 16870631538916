import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import * as stateActions from "../../../../store/actions";
import "./NotLogged.scss";

class NotLogged extends Component {
    componentDidMount() {
        if (this.props.contentLoader.show) {
            this.props.contentLoaded();
        }
    }

    render() {
        return (
            <div className={"notLogged"}>
                <div className={""}>
                    <h1>{this.props.t("Not logged in, please log in to continue")}!</h1>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentLoader: state.contentLoader,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        contentLoaded: () => dispatch(stateActions.hideContentLoader()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NotLogged));