import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/ro.js";

import {
  getBetsState,
} from "../../../bets/store/selectors/betData";
//import { getBaseRouterPath } from "../../utils/config";
import { getHeaderMarkets } from "../../utils/bets";

import Search from "../../components/search";
import TournamentHeader from "../../components/tournament-header";
import DateHeader from "../../components/date-header";
import MatchRowPreMatch from "../../components/match-row-prematch";

const Favorite = props => {
  const { favorite, matches, sports, t, i18n } = props;

  const calendarMatches = {};
  let allMatches = [];
  Object.keys(matches).forEach(k => {
    if (favorite.favorite_prematch_matches.indexOf(k) > -1) {

      calendarMatches[
        moment(matches[k].matchDateTime)
          .startOf("day")
          .valueOf()
      ] = true;

      allMatches.push(matches[k]);
    }
  });

  const dates = [
    { t: 0, label: "All" }, // t("All")
    { t: 1, label: "3 days" }, // t("3 days")
    { t: 2, label: "Today" }, // t("Today")
    { t: 3, label: "Soon (+3h)" }, // t("Soon")
  ];
  const filterFormat = "YYYY.MM.DD"; //DD/MM/YYYY
  const dayFormat = "DD/MM/YYYY"; //DD/MM/YYYY

  Object.keys(calendarMatches).sort().forEach(t => {
    if (moment(parseInt(t, 10)).format(filterFormat) === moment().format(filterFormat)) return;
    dates.push({
      t: t
    });
  });

  const dateList = dates;
  const [state, setState] = React.useState({
    currentIndex: 0,
    sort: 0,
    filter: "",
    collapsedDay: {},
  });

  const toggleCollapsedDay = (day) => () => {
    const collapsedDay = { ...state.collapsedDay };
    if (typeof collapsedDay[day] === "undefined") {
      collapsedDay[day] = true;
    } else {
      collapsedDay[day] = !collapsedDay[day];
    }

    setState(v => ({ ...v, collapsedDay }));
  };

  const handleSearchChange = (type, val) => {
    setState(v => {
      const tmp = { ...v };
      if (type === "tab") {
        tmp.currentIndex = val;
      } else if (type === "filter") {
        tmp.filter = val;
      } else if (type === "sort") {
        tmp.sort = val;
      }
      return tmp;
    });
  };

  const filterByDate = (m, currentDate) => {
    if (state.currentIndex === 0) {
      // "All" matches
      return true;
    } else if (state.currentIndex === 1) {
      // "Three days" matches
      return moment(parseInt(m.matchDateTime, 10)).format(filterFormat) < moment().startOf("day").add(3, 'days').startOf("day").format(filterFormat);
    } else if (state.currentIndex === 2) {
      // "Today" matches
      return moment(parseInt(m.matchDateTime, 10)).format(filterFormat) === moment().format(filterFormat);
    } else if (state.currentIndex === 3) {
      // "Soon" matches
      return m.matchDateTime <= moment().add(3, 'hours').valueOf();
    }

    // "[DAY_OF_THE_WEEK]" matches
    return moment(parseInt(m.matchDateTime, 10)).format(filterFormat) === moment(currentDate).format(filterFormat);
  };

  const sportGroups = (matches) => {
    const s = {};

    matches.forEach(m => {
      if (typeof s[m.idSport] === "undefined") {
        s[m.idSport] = [];
      }
      s[m.idSport].push(m);
    });

    return s;
  };

  const list = [];
  if (state && allMatches && dates[state.currentIndex]) {
    const currentDate = parseInt(dates[state.currentIndex].t, 10);
    const filter = state.filter;

    // sort by grouped
    let filteredMatches = allMatches.filter(m => {
      if (
        filter &&
        `${m.team1Name} - ${m.team2Name}`.toLowerCase().indexOf(filter.toLowerCase()) === -1
      )
        return false;
      return filterByDate(m, currentDate);
    });
    filteredMatches.sort((a, b) => a.matchDateTime - b.matchDateTime);

    const sportMatches = sportGroups(filteredMatches);

    Object.keys(sportMatches).forEach(s => {
      const sport = sports[s];

      const matches = sportMatches[s];

      if (matches.length === 0) return;

      list.push(<div key={`sport-id-${s}`} className={`sport-header sport-color-${s}`}>{sport.sportName}</div>);

      let nowDate = matches.length ? parseInt(matches[0].matchDateTime, 10) : moment().valueOf();
      let cDate = moment(nowDate).format(dayFormat);
      let d;
      if (i18n.language === "ro") {
        d = moment(nowDate).locale("ro", localization);
      } else {
        d = moment(nowDate).locale("en");
      }
      let mCurrentDay = d.format("dddd");
      if (d.format("YYYYMMDD") === moment().format("YYYYMMDD")) {
        mCurrentDay = t("Today");
      } else if (d.format("YYYYMMDD") === moment().add(1, "day").format("YYYYMMDD")) {
        mCurrentDay = t("Tomorrow");
      }

      let mg = `mg_sort_days_favorite_${cDate}_${mCurrentDay}`;

      if (matches.length) {
        const tournamentBets = getHeaderMarkets(matches[0]);
        let tmpMatches = [];

        matches.forEach((m, i) => {
          let newDate = moment(parseInt(m.matchDateTime, 10)).format(dayFormat);

          if (cDate !== newDate) {
            if (tmpMatches.length) {

              let cnt = null;
              if (!state.collapsedDay[cDate]) {
                cnt = <React.Fragment>
                  <DateHeader date={cDate} matchGroup={mg} matches={matches} />
                  {tmpMatches}
                </React.Fragment>;
              }

              list.push(
                <div className="tournaments" key={`day_${cDate}_${mCurrentDay}`}>
                  <TournamentHeader
                    name={mCurrentDay}
                    bets={tournamentBets.bets}
                    onClick={toggleCollapsedDay(cDate)}
                    collapsed={typeof state.collapsedDay[cDate] !== "undefined" ? state.collapsedDay[cDate] : false}
                    matchGroup={mg}
                    matches={matches}
                  />
                  {cnt}
                </div>
              );
            }

            cDate = newDate;

            let d;
            if (i18n.language === "ro") {
              d = moment(parseInt(m.matchDateTime, 10)).locale("ro", localization);
            } else {
              d = moment(parseInt(m.matchDateTime, 10)).locale("en");
            }
            mCurrentDay = d.format("dddd");
            if (d.format("YYYYMMDD") === moment().format("YYYYMMDD")) {
              mCurrentDay = t("Today");
            } else if (d.format("YYYYMMDD") === moment().add(1, "day").format("YYYYMMDD")) {
              mCurrentDay = t("Tomorrow");
            }
            tmpMatches = [];
          }

          let newFilterDate = moment(parseInt(m.matchDateTime, 10)).format(filterFormat);
          let futureDay = moment().add(8, "days").format(filterFormat);
          if (newFilterDate >= futureDay) {// allow only for maxim 7 days
            return;
          }

          tmpMatches.push(<MatchRowPreMatch
            key={"pm_" + m.idMatch}
            idMatch={m.idMatch}
            matchGroup={mg}
            extended={true}
          />);
        });

        if (tmpMatches.length) {

          let cnt = null;
          if (!state.collapsedDay[cDate]) {
            cnt = <React.Fragment>
              <DateHeader date={cDate} matchGroup={mg} matches={matches} />
              {tmpMatches}
            </React.Fragment>;
          }

          list.push(
            <div className="tournaments" key={`day_${cDate}_${mCurrentDay}`}>
              <TournamentHeader
                name={mCurrentDay}
                bets={tournamentBets.bets}
                onClick={toggleCollapsedDay(cDate)}
                collapsed={typeof state.collapsedDay[cDate] !== "undefined" ? state.collapsedDay[cDate] : false}
                matchGroup={mg}
                matches={matches}
              />
              {cnt}
            </div>
          );
        }
      }

    });

  }

  return (
    <React.Fragment>
      <Search
        className="prematch"
        dateList={dateList}
        currentIndex={state.currentIndex}
        sort={state.sort}
        filter={state.filter}
        onChange={handleSearchChange}
        noFilters={true}
      />

      <div className="elements">
        {/*<div className={`sport-header sport-color-${idSport}`}>{sport.sportName}</div>*/}
        {list}
        {list.length === 0 && (
          <div className="empty">
            {t("Currently there are no matches for selected parameters!")}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return (state, props) => {
    const bst = getBetsState(state);

    return {
      favorite: bst.favorite,
      matches: bst.prematch.matches,
      bets: bst.prematch.bets,
      sports: bst.prematch.sports,
    };
  };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(withTranslation()(Favorite));
