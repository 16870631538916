import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import "./keys_status.scss";
import { ReactComponent as IconKey } from "../assets/icon-key.svg";
import KeysRulesDialog from "./keys-rules";

const KeysStatus = (props) => {
  const { wheelStatus, t, hideInfo } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (!wheelStatus) return null;
  if (!wheelStatus.keys_spin) return null;
  if (wheelStatus && wheelStatus.status === "not_eligible") {
    if (wheelStatus.reason && wheelStatus.reason.conflicts && typeof wheelStatus.reason.conflicts[1002] !== "undefined") {
      return null;
    }
  }

  const style = {
    width: `${wheelStatus.keys_spin.next_key_progress}%`
  }

  const shouldHide = wheelStatus.keys_spin.available >= wheelStatus.keys_spin.max;

  return (
    <div className="wheel-keys-status">
      <div className="ProductsSection AwardActivity">
        <h3 className="SectionTitle">{t('Activity Reward')}:</h3>
        <div className="ProductsContainer">
          <div className="ProductBox ">
            <div className="BoxIcon">
              <IconKey />
            </div>
            <div className="BoxContent">
              <table className="box-content-table">
                <tbody>
                  <tr className="first-row">
                    <td colSpan="2" className="tar">
                      <p className="BoxTitle">{t("Keys")}</p>
                    </td>
                  </tr>
                  <tr className="first-row">
                    <td className={`spc ${shouldHide ? 'o-none' : ''}`}>{wheelStatus.keys_spin.next_key_progress > -1 ? `${wheelStatus.keys_spin.next_key_progress}%` : ''}</td>
                    <td className="tar">
                      <h2 className="BoxAmount">
                        <span>{wheelStatus.keys_spin.available}</span>
                      </h2>
                    </td>
                  </tr>
                  {wheelStatus.keys_spin.next_key_progress !== -1 && <tr className={`last-row ${shouldHide ? 'd-none' : ''}`}>
                    <td colSpan="2">
                      <div className="prg-wrapper">
                        <div className="prg" style={style}></div>
                      </div>
                    </td>
                  </tr>}
                </tbody>
              </table>
              {!hideInfo && <div className="keys-info" onClick={handleOpen}>
                <div className="keys-info-wrapper">?</div>
              </div>}
            </div>
          </div>
        </div>
      </div>
      {open && <KeysRulesDialog open={open} onClose={handleClose} type="keys" />}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    wheelStatus: state.wheel && state.wheel.wheel && state.wheel.wheel.status ? state.wheel.wheel.status : null,
  }
};

export default connect(mapStateToProps)(withTranslation()(withRouter(KeysStatus)));