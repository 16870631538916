import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Animated } from "react-animated-css";
import * as stateActions from "../../../../../store/actions";
import Utils from "../../../../../utils/Utils";
import Icons from "../../../../../assets/Icons/Icons";
import "./Success.scss";
import { sprintf } from 'sprintf-js';
import Translate from "../../../../../utils/Translate";

class Success extends Component {
    constructor(props) {
        super(props);

        this.icons = {
            success: Icons.get("pwSuccess", "pwSuccess"),
        };
    }

    closeModal() {
        let menuItems = Object.values(this.props.application.menu);

        let profileItem = menuItems.find((item) => {
            return "profile" === item.code;
        });

        let withdrawalSub = profileItem.submenus.find((elem) => {
            return "pending-withdrawals" === elem.code;
        });

        let stringUrl = `/${profileItem.code}/${withdrawalSub.code}`;

        this.props.history.push(stringUrl);

        this.props.onPageChanged(profileItem);

        this.props.closeModal();
    }

    render() {
        return (
            <Animated animationIn="zoomIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={200}>
                <div className={"WithdrawSuccess"}>
                    <div className={"top"}>
                        <h1>{this.props.t("Success")}!</h1>
                    </div>
                    <div className={"middle"}>
                        <Animated animationIn="bounceIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={400} isVisible={true} animationInDelay={1000}>
                            <div className={"successChar"}>{this.icons.success}</div>
                        </Animated>
                        <p><Translate text={this.props.t("The amount of ${amount} was sent for approval.")} placeholder={{ amount: Utils.formatAmount(this.props.withdrawals.amount, null, this.props.wallet.currency) }} /></p>
                        {/*<p>{this.props.t("We will notify you when status changes occur for this request")}.</p>*/}
                    </div>
                    <div className={"bottom"}>
                        <Button color={"success"} onClick={this.closeModal.bind(this)}>{this.props.t("Am inteles")}</Button>
                    </div>
                </div>
            </Animated>
        );
    }
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(stateActions.closeWithdrawalModal()),
        onPageChanged: (page) => dispatch(stateActions.changePage(page)),
        onSubpageChanged: (subpage) => {
            dispatch(stateActions.changeSubpage(subpage));
            dispatch(stateActions.startProfilePageLoading());
        },
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Success)));
