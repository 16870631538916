import React from 'react';
import { connect } from "react-redux";

import "./banner_component.scss";
import { styleToObj } from "../../utils/functions";
import Slider from "../../../common/slider";

const BannerComponent = (props) => {
  const { data, supperBanner, eventPrefix } = props;

  if (!supperBanner) return null;

  return (
    <div className={`component-banner_component component`} style={styleToObj(data.style)}>
      {supperBanner && <Slider config={supperBanner} eventPrefix={eventPrefix} />}
    </div>
  );
}

const mapStateToProps = (state, props) => {

  let supperBanner = null;
  let eventPrefix = "";

  if (props && props.data && props.data.banner_type) {
    if (props.data.banner_type === "casino") {
      eventPrefix = "home";
      supperBanner = state.config && state.config.superBannerCasino ? state.config.superBannerCasino : null;
    } else if (props.data.banner_type === "bets") {
      eventPrefix = "bets";
      supperBanner =
        state.bets && state.bets.config && state.bets.config.homepageGroupsImages && state.bets.config.homepageGroupsImages.super_banner
          ? state.bets.config.homepageGroupsImages.super_banner : null;
    } else if (props.data.banner_type === "winnerFun") {
      eventPrefix = "bets";
      supperBanner =
        state.winnerFun && state.winnerFun.config && state.winnerFun.config.super_banner
          ? state.winnerFun.config.super_banner : null;
    }
  }
  return {
    eventPrefix,
    supperBanner
  }
};

export default connect(mapStateToProps)(BannerComponent);