import React from 'react';
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./happy-hours-front.scss";
import Timer from "../../components/timer";
import GiftImage from "../../assets/gift@2x.png";
import SimpleTimer from "../../components/simple-timer";
import { openGame } from '../../../../store/actions/games';

const FrontDesktop = (props) => {
  const { data, games, lobbyGames, onOpenGame, t } = props;
  const history = useHistory();

  const navigateToPromotions = () => {
    history.push("/cazino/promotii");
  };

  const navigateToGame = () => {
    if (games && games.length) {
      const randomGame = games[Math.floor(Math.random() * games.length)];
      if (randomGame) {
      history.push(`/play/${randomGame.id}`);
      onOpenGame(randomGame, 0, 0);
    }
      return;
    }
    if (lobbyGames && lobbyGames.length) {
      const randomGame = lobbyGames[Math.floor(Math.random() * lobbyGames.length)];
      if (randomGame) {
        history.push(`/play/${randomGame.id}`);
        onOpenGame(randomGame, 0, 0);
      }
      return;
    }
  };

  const hasSpecialPrize = typeof data.next_special_prize !== "undefined" && data.next_special_prize ? true : false;

  const genPrize = (className, prize) => {
    const isSpecialPrize = prize && !prize.amount && typeof prize.custom !== "undefined" ? true : false;
    if (!isSpecialPrize) {
      return (
        <div className={`${className} ${!prize ? 'hidden' : ''}`}>{prize ? prize.amount : 0} Lei</div>
      )
    }

    return (
      <div className={`hh-special-prize-front ${!prize ? 'hidden' : ''}`}>
        <img className="hh-sp-img" src={prize.custom.thumbnail} alt="" />
        <div className="hh-sp-title-front">{prize.custom.title}</div>
      </div>
    )
  };

  return (
    <div className="hh-front-desktop">
      <div className="hh-wrapper">
        <div className="hh-front-root">
          <div className="hh-header">

            {hasSpecialPrize && <div className="hh-sp-left">
              <div className="hh-name">
                {data.name}
              </div>
              <Timer date={data.date} />
            </div>}
            {!hasSpecialPrize && <div className="hh-name">
              {data.name}
            </div>}

            <div className="hh-center">
              <div className="hh-pills">
                <div className="hh-pill op-29"></div>
                <div className="hh-pill op-60"></div>
                <div className="hh-pill op-88"></div>
                <div className="hh-pill active"></div>
                <div className="hh-pill op-88"></div>
                <div className="hh-pill op-60"></div>
                <div className="hh-pill op-29"></div>
              </div>
              <div className="hh-pills-text">
                <span className="hh-all-prizes hidden">&nbsp;/ {data.total_prizes}</span>
                <span className="hh-current-prize">{data.current_prize}</span>
                <span className="hh-all-prizes">&nbsp;/ {data.total_prizes}</span>
              </div>
            </div>

            {hasSpecialPrize && <div className="hh-sp-right">
              <div className="hh-sp-title-front">
                {t("SPECIAL PRIZE")}
              </div>
              <div className="hh-sp-name">
                {data.next_special_prize.custom.title}
              </div>
            </div>}
            {!hasSpecialPrize && <Timer date={data.date} />}
          </div>
          <div className="hh-prizes">
            {genPrize("hh-prize-before", data.prize_before)}
            <div className="hh-prize-current">
              <div className="hh-flx">
                <div className="hh-img">
                  {/*<GiftIcon />*/}
                  <img src={GiftImage} alt="" />
                </div>
                <div className="hh-txt">
                  {data.prize_current ? data.prize_current.amount : 0} Lei
                </div>
              </div>
              <div className="hh-bet">{t("Minimum Bet")}: {data.prize_current ? data.prize_current.min_bet : 0}</div>
            </div>
            {genPrize("hh-prize-after", data.prize_after)}
          </div>
        </div>
      </div>
      <div className="hh-campaign-info">
        <div className="hh-title" onClick={navigateToPromotions}>{t("Campaign Details")}</div>
        <div className="hh-cta" onClick={navigateToGame}>
          {t("Enter to play")}
          <div className="hh-date"><SimpleTimer date={data.date} /></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  games: state.games.items,
  lobbyGames: state.lobby && state.lobby.games && state.lobby.games.items ? state.lobby.games.items : []
});

const mapDispatchToProps = dispatch => {
  return {
    onOpenGame: (game, x, y) => dispatch(openGame(game, x, y)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FrontDesktop));