import * as actionTypes from '../actions/actionTypes';

const initialState = {
    modal: false,
    wmodal: false,
    resetActualPosition: false,
    requested: false,
    coords: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.geolocation.OPEN_MODAL:
            return {
                ...state,
                modal: true,
            };
        case actionTypes.geolocation.CLOSE_MODAL:
            return {
                ...state,
                modal: false,
            };
        case actionTypes.geolocation.OPEN_WMODAL:
            return {
                ...state,
                wmodal: true,
            };
        case actionTypes.geolocation.CLOSE_WMODAL:
            return {
                ...state,
                wmodal: false,
            };
        case actionTypes.geolocation.RESET_POSITION:
            return {
                ...state,
                resetActualPosition: true,
            };
        case actionTypes.geolocation.POSITION_RESETTED:
            return {
                ...state,
                resetActualPosition: false,
            };
        case actionTypes.geolocation.REQUESTED:
            return {
                ...state,
                requested: true,
            };
        case actionTypes.geolocation.SET_COORDS:
            return {
                ...state,
                coords: action.coords,
            };
        default:
            return state;
    }
};

export default reducer;

