import { layoutConstants } from './constants';

export const setLayoutType = lType => ({
  type: layoutConstants.CHANGE_LAYOUT,
  lType
});

export const setLayoutTypeWithoutShow = lType => ({
  type: layoutConstants.CHANGE_LAYOUT_WITHOUT_SHOW,
  lType
});

export const hideLayoutHighlighter = () => ({
  type: layoutConstants.HIDE_LAYOUT_HIGHLIGHTER
});