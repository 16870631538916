import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../AuthenticationModal/Username/Username.css';
import TextInput from "../TextInput/TextInput";
import * as stateActions from "../../../../store/actions";
import CNPValidator from "../../../../utils/CNPValidator";
import BackendClient from "../../../../BackendClient";
import SocialSecurityNumberValidator from "../../../../utils/SocialSecurityNumberValidator";
import Utils from "../../../../utils/Utils";
import { withTranslation } from "react-i18next";
import GTM from "../../../../utils/GTM";

class CNP extends Component {
    constructor(props) {
        super(props);
        this.defaultGroupClassName = "input-group";
        this.timerId = 'cnpComponent';
    }

    state = {
        groupClassName: `${this.defaultGroupClassName} notValid`,
        message: {
            type: "",
            text: "",
        },
        ok: false,
        fieldInput: ''
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.fieldInput !== this.props.cnp && prevProps.cnp !== this.props.cnp && this.props.cnp.length > 1) {
            this.setState({
                ...this.state,
                fieldInput: this.props.cnp
            })
            this.blurHandler()
        }
    }
    serverSideValidation(cnp) {
        let axios = BackendClient.getInstance();
        axios({
            url: 'api/validate/cnp',
            method: 'post',
            data: {
                cnp: cnp,
            },
        }).catch(e => {
            // console.log(`[CATCHED **CNP VALIDATION** ERROR] => ${e}`);
        });

        this.props.onServerSideValidation();
    }

    triggerHandler() {
        let groupClassName = undefined;
        let message = "";
        let valid = this.props.isRo ? CNPValidator.validate(this.state.fieldInput) : SocialSecurityNumberValidator.validate(this.state.fieldInput);
        let ok = this.state.ok;

        if (valid.type !== 'success') {
            groupClassName = `${this.defaultGroupClassName} ${valid.type}`;
            switch (valid.text) {
                case 1:
                    message = this.props.t('CNP must be 13 chars long');
                    break;
                case 2:
                    message = this.props.t('CNP must contain only numbers');
                    break;
                case 3:
                    message = this.props.t("Minimum age accepted for registration is 18 years");
                    break;
                case 10:
                    message = this.props.t('Social Security Number is too short');
                    break;
                default:
                    message = this.props.t("Inserted value is invalid");
                    break;
            }

            if (ok) {
                ok = false;
            }
        } else {
            this.serverSideValidation(this.state.fieldInput);
            groupClassName = `${this.defaultGroupClassName} valid`;
            if (!ok) {
                ok = true;
            }
        }

        this.setState({
            ...this.state,
            groupClassName: groupClassName,

            message: {
                ...this.state.message,
                type: (message !== "" ? "error" : ""),
                text: message,
            },
            ok: ok,
        });

        this.props.onChange({ cnp: this.state.fieldInput, validated: ok });
    }
    sendGAEvent() {
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "CNP" + (this.state.ok ? "" : " - " + this.state.message.text));
    }
    blurHandler() {
        Utils.delayedFunction('sendGAEvent',this.sendGAEvent.bind(this),50);

        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this), 0);
    }

    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.cnp;
        } else {
            fieldInput = event.target.value;
        }

        if (fieldInput !== this.state.fieldInput && this.state.fieldInput !== '') {
            this.props.resetOCR();
        }

        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this));

        this.setState({
            ...this.state,
            fieldInput: fieldInput,
        });

        this.props.onChange({ cnp: fieldInput, validated: false });
    }

    keyPressedHandler(event) {
        if (this.props.isRo && (event.charCode < 48 || event.charCode > 57)) {
            event.preventDefault();
        }
    }

    render() {
        let errorMessage = this.state.message;
        if (errorMessage.type === "" && this.props.error !== "") {
            errorMessage = {
                type: "error",
                text: this.props.error, // error from server
            }
        }
        let className = this.state.groupClassName;
        if (errorMessage.type === 'error') {
            className = this.defaultGroupClassName + ' error'; // overwrite className
        }
        let inputLabel = this.props.isRo ? this.props.t("CNP") : this.props.t("Social Security Number");

        let startAnimation = (errorMessage.type !== "error" && this.props.reward && !this.props.pendingValidation ? true : false);

        return (

            <TextInput loading={this.props.pendingValidation}
                       reward={(typeof this.props.showReward !== "undefined" ? this.props.showReward : true)}

                       animateReward={startAnimation}
                iconName="id-check"
                iconClassName="login-check"
                groupClassName={className}
                inputType="text"
                inputIdName={inputLabel}
                inputValue={this.props.cnp}
                inputLabel={inputLabel}
                blurHandler={this.blurHandler.bind(this)}
                changeHandler={this.changeHandler.bind(this)}
                keyPressedHandler={this.keyPressedHandler.bind(this)}
                message={errorMessage} />
        );
    }
}

function isRoumanian(citizenship, defaultCountry) {
    return citizenship === "RO" || (citizenship === "" && defaultCountry === "RO");
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => {
            dispatch(stateActions.fieldEntered({field: "cnp", value: fieldInput.cnp, validated: fieldInput.validated}));
            dispatch(stateActions.fieldEntered({field: "nin", value: fieldInput.cnp, validated: fieldInput.validated}))
        },
        onServerSideValidation: () => dispatch(stateActions.startRequest('cnp')),
        resetOCR: () => dispatch(stateActions.fieldEntered({field:"ocr_scan",value:"",validated: true}))
    };
};

const mapStateToProps = state => {

    return {
        cnp: state.registerWizard.fields.cnp,
        error: state.registerWizard.errors.cnp,
        isRo: isRoumanian(state.registerWizard.fields.citizenship, state.registerWizard.defaultCountry),
        reward: state.registerWizard.rewards.cnp,
        pendingValidation: state.registerWizard.serverSideValidation.cnp,
        lang: state.application.language
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CNP));