import * as storeActions from "../store/actions";
import ClaimPopup from "../elements/ClaimBonus/ClaimPopup/ClaimPopup";
const claimBonus = storeActions.claimBonus;
const resetClaimError = storeActions.resetBonusClaimStatus;
const getBonusAvailable = storeActions.getBonusAvailable;

export {
    ClaimPopup ,
    claimBonus,
    resetClaimError,
    getBonusAvailable,
};