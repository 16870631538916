import md5 from 'md5';
import Fingerprint from "./Fingerprint";

const CRC_FINGERPRINT_LOCAL_KEY = 'fingerprint';
const CRC_SESSION_FINGERPRINT_LOCAL_KEY = 'session_fingerprint';

class PlayerAbuseChecker {
    static  getInfo() {
        const fingerprint = this.generateFingerPrint();
        const isNewToken = this.isNewToken();
        const sessionFingerPrint = this.generateSessionFingerPrint();

        return {
            dvfp: fingerprint,
            nwfp:sessionFingerPrint,
            newfp: isNewToken
        };
    }

    static isNewToken() {
        const sessionFingerPrint = window.localStorage.getItem(CRC_SESSION_FINGERPRINT_LOCAL_KEY);
        return sessionFingerPrint ? 0 : 1;
    }

    static generateSessionFingerPrint() {
        if (!this.isNewToken()) {
            return window.localStorage.getItem(CRC_SESSION_FINGERPRINT_LOCAL_KEY);
        }
        const fingerprint = this.generateFingerPrint();
        const sessionFingerPrint = md5(fingerprint + (new Date().getTime()) + Math.random());
        window.localStorage.setItem(CRC_SESSION_FINGERPRINT_LOCAL_KEY,sessionFingerPrint);
        return sessionFingerPrint;
    }
    static  generateFingerPrint() {
        let fingerprint = window.localStorage.getItem(CRC_FINGERPRINT_LOCAL_KEY);
        if (fingerprint) {
            return fingerprint;
        }
        fingerprint =  Fingerprint.generate();
        fingerprint = md5(fingerprint);
        window.localStorage.setItem(CRC_FINGERPRINT_LOCAL_KEY,fingerprint);
        return fingerprint;
    }
}

export default PlayerAbuseChecker;
