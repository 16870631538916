import React, {useState, useEffect, Fragment} from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import { withRouter } from "react-router";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import BackendClient from "../../BackendClient";
import  '../PlayerProfile/Modals/Withdraw/WithdrawModal.scss'
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";

const DepositFinal = (props) => {
    const [amount, setAmount] = useState(0);
    const [depositData,setDepositData] = useState([]);
    const [bonusId,setBonusId] = useState(-1);
    const [serverResponse, setServerResponse] = useState(false);

    useEffect( () => {
        let templayerData = window.localStorage.getItem('deposit');
        window.localStorage.removeItem('deposit');
        try {
            templayerData = JSON.parse(templayerData);
            setAmount(templayerData.amount);
            setDepositData(templayerData.data);
            setBonusId(templayerData.bonusId);
        }catch (e) {
            // props.history.push('/');
        }
    }, []);

    useEffect(() => {
        if (props.profile.client_player_id !== null) {
            sendVivaChargeToken(depositData.chargeToken)
        }
    },[props.profile.client_player_id])
    useEffect(() => {
        if (props.paymentCheckout.serverResponse === 'success') {
            props.getWallet();
            props.history.push('/payment/success?currency=RON&totalAmount=' + (amount/100))
        }
    }, [props.paymentCheckout.serverResponse]);

    const sendVivaChargeToken = (chargeToken) => {
        const axios = BackendClient.getInstance();
        let pac = PlayerAbuseChecker.getInfo();
        let data = {
            "chargeToken": chargeToken,
            "amount": amount,
            "bonusId": bonusId ? bonusId : -1
        };
        data = {
            ...pac,
            ...data
        }
        axios({
            url: '/api/pay-checkout/charge-token',
            method: 'post',
            data: data
        }).then(response => {
            if (!response) {
                throw new Error(`[ERROR] Viva Charge Token response is empty!`);
            }
            if (!response.hasOwnProperty('result')) {
                throw new Error(`[ERROR] Viva Charge Tokenresponse has no 'data' property`);
            }
            if (response.result.hasOwnProperty('ResponseCode')) {
                setServerResponse(`${response.result.ResponseMessage} (${response.result.ResponseCode})`);
            } else {
                if (response.status === 'OK') {
                    props.history.push('/payment/success?currency=RON&totalAmount=' + (amount/100))
                } else {
                    setServerResponse(`Something went wrong`);
                }
            }
        })
    }
    let modalClassName = [
        "WithdrawModal", "h-100", "d-flex", "flex-column", "justify-content-center", "my-0"
    ];
    return (<Fragment>
        <Modal isOpen={true} className={modalClassName.join(' ')} backdrop={'static'} modalTransition={{ timeout: 300 }} zIndex={9999}>
            <ModalHeader toggle={() => props.history.push('/deposit')} className="WithdrawModalHeader">{props.t("Deposit")}</ModalHeader>
            <ModalBody className="WithdrawModalBody">
                <div className={"DepositOnlineScreen-final"}>
                    {serverResponse
                        ? (<div className="ReturnMessage"><p>{serverResponse}</p></div>)
                        : <CircularProgress className={'Loading'}/>
                    }
                    {serverResponse && <button className={'nextSCButton'} onClick={() => props.history.push('/deposit')}>
                        {props.t("Retry")}
                    </button>}
                </div>
            </ModalBody>
        </Modal>
    </Fragment>)
}
const mapStateToProps = state => {
    return {
        profile: state.profile,
        paymentCheckout: state.paymentCheckout,

    }
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DepositFinal)));