export const getText = (data, path, def) => {
  const elements = path.split(".");
  let tmp = data;
  if (data) {
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];

      if (element.indexOf("[") > -1) { // array
        let parts = element.split(/\[|\]/);
        if (parts.length === 3) {
          if (typeof tmp[parts[0]] !== "undefined") {
            tmp = tmp[parts[0]];
            element = parseInt(parts[1]);
          } else {
            tmp = null;
            break;
          }
        }
      }

      if (typeof tmp[element] !== "undefined") {
        if ((element === "ro" || element === "en")) { // there is no translation for that language check the remaining
          let txtLang = tmp[element];
          if (!txtLang) {
            if (element === "ro") {
              txtLang = tmp["en"];
            } else if (element === "en") {
              txtLang = tmp["ro"];
            }
          }
          tmp = txtLang;
        } else {
          tmp = tmp[element];
        }
      } else {
        tmp = null;
        break;
      }
    }
  }

  if (tmp) return tmp;
  return def;
}

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export const styleToObj = (style) => {
  const ret = {};

  if (!style) return ret;
  if (typeof style !== "string") return ret;

  const parts = style.split(";");

  parts.forEach(p => {
    const line = p.trim();

    if (line && line.indexOf(":") > -1) {
      let [key, value] = line.split(":", 2);
      key = toCamel(key.trim());
      value = value.trim();
      ret[key] = value;
    }
  });

  return ret;
};

