import * as actionTypes from './../actions/actionTypes';
import { produce } from "immer";

const initialState = {
  freeBets: [],
  freeBetsHistory: [],
  freeBetsHistoryHasMore: true,
  freeBetsFilters: {},
};

const freeBetsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.freeBets.FREE_BETS_LOADED: {
        draft.freeBets = action.freeBets;
        break;
      }
      case actionTypes.freeBets.FREE_BETS_FILTERS_LOADED: {
        draft.freeBetsFilters = Object.assign(draft.freeBetsFilters, action.freeBetsFilters);
        break;
      }
      case actionTypes.freeBets.FREE_BETS_FILTERS_UPDATE: {
        draft.freeBetsFilters = Object.assign(draft.freeBetsFilters, action.freeBetsFilters);
        break;
      }
      case actionTypes.freeBets.FREE_BETS_SET_HISTORY: {
        if (action.page === 0) {
          draft.freeBetsHistory = action.items;
          draft.freeBetsHistoryHasMore = action.items.length > 0 ? true : [];
        } else {
          if (action.items.length) {
            draft.freeBetsHistory = [...draft.freeBetsHistory, ...action.items];
            draft.freeBetsHistoryHasMore = true;
          } else {
            draft.freeBetsHistoryHasMore = false;
          }
        }

        break;
      }
      default:
        break;
    }
  });

export default freeBetsReducer;
