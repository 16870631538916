import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { VelocityComponent } from 'velocity-react';
import './MainContent.scss';
import ContentLoader from './ContentLoader/ContentLoader';
import Content from './Content/Content';
import PaymentStatus from "../Deposit/Screens/PaymentStatus";
import Token from "../Register/Token/Token";
import DepositFinal from "../Viva/DepositFinal";
import CasinoLive from "../../live-casino/layout/LayoutWeb";
import ClaimBonus from "../ClaimBonus/ClaimBonus";
import NotLogged from "./ContentPages/NotLogged/NotLogged";
import Deposit from "../Deposit/Deposit";
import VirtualGamePage from "../../virtuals/views/GamePageWeb";
import VirtualGames from "../../virtuals/views/MainPageWeb";


class MainContent extends Component {
    render() {
        if (this.props.application.notAllowed) {
            return null;
        }

        let menuItems = this.props.application.menu;
        const routes = [];

        if (window.config && window.config.liveCasinoEnabled === "1") {
            let casinoLiveRoute = <Route path={'/cazino_live'} key={'cazino_live'} render={(props) => <CasinoLive {...props} />} />;
            routes.push(
                casinoLiveRoute
            );
        }
        if (window.config.lobbyEnabled === "1") {
            let defUrl = "/lobby";
            if (window.config.lobbyEnabled === "1" && window.config.rootPaths && window.config.rootPaths.casino) {
                defUrl = "/" + window.config.rootPaths.casino;
            }
            routes.push(<Route exact path="/cazino" key={'redirect-to-slots'}>
                <Redirect to={defUrl + "/slots"} />
            </Route>);
            routes.push(<Route exact path="/cazino/jocuri" key={"redirect-cazino-old-page-to-new-one"}>
                <Redirect to={defUrl + "/slots"} />
            </Route>);
            routes.push(<Route exact path="/cazino/promotii" key={"redirect-promotii-old-page-to-new-one"}>
                <Redirect to={defUrl + "/promotii"} />
            </Route>);
        }
        let routeFun = <Route path={['/fun']} exact key={'route_fun'}>
            <Redirect to="/winner-fun" />
        </Route>;
        routes.push(routeFun);

        let resetPasswordRoute = <Route path={'/reset-password/:token?'} key={'route_reset-password'} render={(props) => <Content contentType={'reset-password'} {...props} />} />;
        routes.push(
            resetPasswordRoute
        );

        let depositRoute = <Route path={'/deposit'} exact key={'route_deposit'} render={() => {

            // this function was created here because in <Content doesn't work anymore and I don't really understand why
            // return (<Content contentType='deposit'/>);

            if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
                return <NotLogged />;
            }
            return (
                <VelocityComponent
                    animation={
                        this.props.application.loadedContent
                            ? { opacity: 1, top: 0 }
                            : { opacity: 0, top: "40px" }
                    }
                    duration={2000}
                    runOnMount={true}
                >
                    <Deposit />
                </VelocityComponent>
            );
        }} />;
        routes.push(
            depositRoute
        );

        if (window?.config?.okto_enabled === "1") {
            let depositOktoRoute = <Route path={'/deposit-oktocash'} exact key={'route_deposit_oktocash'} render={() => {

                // this function was created here because in <Content doesn't work anymore and I don't really understand why
                // return (<Content contentType='deposit'/>);

                if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
                    return <NotLogged />;
                }
                return (
                    <VelocityComponent
                        animation={
                            this.props.application.loadedContent
                                ? { opacity: 1, top: 0 }
                                : { opacity: 0, top: "40px" }
                        }
                        duration={2000}
                        runOnMount={true}
                    >
                        <Deposit paymentMethod={4}/>
                    </VelocityComponent>
                );
            }} />;
            routes.push(
                depositOktoRoute
            );
        }
        let locationsRoute = <Route path={'/locations'} exact key={'route_locations'} render={() => <Content contentType={'locations'} />} />;
        routes.push(
            locationsRoute
        );
        let routePayment = <Route path={['/payment', `/payment/:paymentStatus`]} exact key={'route_payment'} component={PaymentStatus} />;
        routes.push(routePayment);

        let routeWithdraw = <Route path={['/withdraw', `/withdraw/:paymentStatus`]} exact key={'route_payment'} component={PaymentStatus} />;
        routes.push(routeWithdraw);

        let routeToken = <Route path={['/token-email-validation/:token', `/token_email_validation/:token`]} exact key={'route_token'} component={Token} />;
        routes.push(routeToken);

        let routeDepositFinal = <Route path={['/deposit-final']} exact key={'route_depositFinal'} component={DepositFinal} />;
        routes.push(routeDepositFinal);

        let routeClaimBonus = <Route path={['/claim-bonus']} exact key={'route_claimBonus'} component={ClaimBonus} />;
        routes.push(routeClaimBonus);

        if (
            (typeof window.config.virtualsEnabled !== "undefined" && window.config.virtualsEnabled === '1') ||
            (typeof window.config.virtuals_enabled !== "undefined" && window.config.virtuals_enabled === '1')
        ) {
            const virtualUrl = `/virtuale`;

            const virtualGameRoute = (
                <Route
                    path={`${virtualUrl}/jocuri/:id`}
                    exact
                    key={`route_casino_virtual_game`}
                    render={() => (
                        <VelocityComponent
                            animation={{opacity: 1, top: "21px"}
                            }
                            duration={500}
                            runOnMount={true}
                        >
                            <div className="ContentPage virtuals-cs" id="ContentPage">
                                <VirtualGamePage />
                            </div>
                        </VelocityComponent>
                    )}
                />
            );
            routes.push(virtualGameRoute);

            const virtualRoute = (
                <Route
                    path={virtualUrl}
                    exact
                    key={`route_casino_virtual`}
                    render={() => (
                        <VelocityComponent
                            animation={{opacity: 1, top: "21px"}}
                            duration={500}
                            runOnMount={true}
                        >
                            <div className="ContentPage virtuals-cs" id="ContentPage">
                                <VirtualGames />
                            </div>
                        </VelocityComponent>
                    )}
                />
            );
            routes.push(virtualRoute);
        }



        //console.log("virtualRoutes", virtualRoutes);


        //let test = <Route path={'/test'} exact key={'route_test'} render={ () => <Test/>} />
        //routes.push(test);

        if (Object.keys(menuItems).length > 0) {
            Object.keys(menuItems).forEach((menuId) => {
                let menuItem = menuItems[menuId];

                if (window.config.lobbyEnabled === "1") {
                    if ("cazino" === menuItem.code) {
                        return;
                    }
                    if (window.config.rootPaths && window.config.rootPaths.casino && window.config.rootPaths.casino === menuItem.code) {
                        return;
                    }
                }

                if (window.config && window.config.liveCasinoEnabled === "1" && menuItem.code === "cazino_live") return;

                if (menuItem.code === 'virtuale') return;

                let route = <Route path={'/' + menuItem.code} key={'route_' + menuId} render={() => <Content contentType={menuItem.code} pageRow={menuItem} />} />;
                routes.push(
                    route
                );

                if (!(window.config.lobbyEnabled === "1")) {
                    if ("cazino" === menuItem.code) {
                        let baseRoute = <Route path={'/'} exact key={'route_0'} render={() => <Content contentType={menuItem.code} pageRow={menuItem} />} />;
                        routes.push(
                            baseRoute
                        );
                    }
                }
            });
        }

        return (
            <VelocityComponent animation={{ minHeight: this.props.application.pageHeight }} duration={500} runOnMount={true}>
                <div className="MainContent" id="MainContent">
                    <ContentLoader />
                    <Switch>
                        {routes}
                    </Switch>
                </div>
            </VelocityComponent>
        );
    }
}

const mapStateToProps = state => {
    return {
        application: state.application,
        authentication: state.authentication,
    }
};

export default withRouter(connect(mapStateToProps)(MainContent));
