import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { ReactComponent as RightIcon } from "./assets/arrowRight.svg";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import EventTimer from "./Timer";
import OddsTable from "../OddsTable";
import evBus from "../../utils/evbus";
import { getLottoState } from "../../store/selectors/lottoData";
import {
  betsSlipAddLottoTicket
} from "../../store/actions/betsSlip";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: "7px",
    margin: "15px 7px 0px 7px",
    padding: "9px 13px",
  },
  eventName: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "10px",
    lineHeight: "10px",
    color: "#9EA1AC",
    position: "relative",
    top: "-2px"
  },
  header: {
    marginTop: "3px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between"
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  title: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#17214D",
  },
  description: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#17214D",
  },
  ticketData: {
    flex: "1 1 0",
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "right",
    color: "#000",
    padding: "3px 0 4px",
    display: "inline-block",
    whiteSpace: "nowrap"
  },
  won: {
    borderRight: "3px solid #06D2BD",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block",
    "& svg": {
      display: "inline-block"
    }
  },
  lost: {
    borderRight: "3px solid #FF196E",
    color: "#FF196E",
    paddingRight: "10px",
    marginRight: "10px",
    display: "inline-block"
  },
  possibleWin: {
    display: "inline-block",
    marginRight: "10px"
  },
  rightSvg: {
    height: "14px",
    width: "auto",
    fill: "#7f7f7f",
    display: "inline-block",
    verticalAlign: "middle",
    float: "right",
    position: "relative",
    top: "2px"
  },
  hr: {
    borderTop: "1px solid #B7CDD8",
    height: "1px",
    width: "100%",
    margin: "3px auto 10px"
  },
  balls: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto"
  },
  ball: {
    width: "29px",
    minWidth: "29px",
    maxWidth: "29px",
    height: "29px",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#121934",
    lineHeight: "27px",
    margin: "3px 0",
    border: "1.5px solid #3D4D8E",
    background: "#fff",
  },
  ballsFlexStart: {
    justifyContent: "flex-start",
    "&>div": {
      margin: "3px 2px",
    }
  },
  ballWinner: {
    color: "#fff",
    border: "1.5px solid #00C7B2",
    background: "linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)",
  },
  ballWinnerNow: {
    color: "#fff",
    border: "1.5px solid #fff",
    background: "linear-gradient(180deg, #F20732 0%, #F2CA00 100%)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.57)"
  },
  ballLuckyNumber: {
    color: "#906821",
    border: "1.5px solid #A67C18",
    background: "#FFDF78",
  },
  ballLuckyNumberWon: {
    color: "#906821",
    border: "1.5px solid #00C7B2",
    background: "#FFDF78",
  },
  ballEmpty: {
    color: "#E9E9E9",
    border: "1.5px solid #E9E9E9",
    background: "#fff",
  },
  ballLost: {
    border: "1.5px solid #FF196E",
    background: "#FF196E",
    color: "#fff",
  },
  infoPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "13px",
  },
  timer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    fontSize: 11,
    fontWeight: 700,
    color: "#17214D"
  },
  muted: {
    opacity: .5,
    fontWeight: "normal"
  },
  timerTitle: {
    fontSize: "11px",
    fontWeight: "300",
    color: "#5A6798",
  },
  stake: {
    backgroundColor: "#E6EEF1",
    borderRadius: "12px",
    padding: "12px 34px"
  },
  stakeHeader: {
    textAlign: "center",
    fontSize: "9px",
    fontWeight: "600",
    color: "#586682",
  },
  stakeAmount: {
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bold",
    color: "#17214D",
  },
  results: {
    margin: "15px -5px",
    borderRadius: "7px",
    padding: "2px 5px",
    backgroundColor: "#E6EEF1"
  },
  link: {
    color: "#17214D",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "600",
    margin: "10px 0"
  },
  mr0: {
    marginRight: "0"
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  bigButton: {
    alignSelf: "stretch",
    width: "45%",
    padding: "6px 8px",
    borderRadius: 11,
    fontSize: 14,
    color: "#FFFFFF",
    height: "42px"
  },
  recoverButton: {
    background: "linear-gradient(270deg, #1F83E6 0%, #06D2BD 100%)",
    width: "100%"
  },
  subText: {
    fontSize: "9px",
    fontWeight: "600",
    color: "#979DAB",
    maxWidth: "150px",
    marginTop: "2px",
    marginLeft: "10px"
  },
});

const noop = () => { };

const months = {
  "1": "Ian",
  "2": "Feb",
  "3": "Mart",
  "4": "Apr",
  "5": "Mai",
  "6": "Iun",
  "7": "Iul",
  "8": "Aug",
  "9": "Sept",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec"
};

const Ticket = (props) => {
  const classes = useStyles();
  const { ticket, lottoEvents, addTicket, className, onLive, noArrow, t } = props;

  const [showDetails, setShowDetails] = React.useState(false);
  const history = useHistory();

  const handleClick = (e) => {
    if (e) e.stopPropagation();
    setShowDetails(v => !v);
  };

  let rootCls = classes.root;
  if (className) {
    rootCls += ` ${className}`;
  }

  let description = '';
  if (ticket.systems && ticket.systems.length) {
    if (ticket.systems.length === 1 && ticket.numbers.length === ticket.systems[0]) {
      description = `| ${t("SIMPLE")} ${ticket.systems[0]}/${ticket.numbers.length}`;
    } else {
      description = `| ${t("SYSTEM")} ${ticket.systems.join(",")}/${ticket.numbers.length}`;
    }
  }

  const status = ticket.status;

  const event_date = moment(ticket.event_time / 1e+6).format("YYYY-MM-DD HH:mm:ss");
  const ticket_time = moment(ticket.time / 1e+6);

  const sd = ticket_time.format("D") + " " + months[ticket_time.format("M")];
  const tm = ticket_time.format("HH:mm");
  const ticket_date = `${sd}, ${tm}`;

  const results = ticket.event_results ? ticket.event_results : [];

  const numbers = [];
  const winner_numbers = {};
  ticket.numbers.forEach(no => {
    numbers.push({ n: no, s: 0 });
    winner_numbers[no] = true;
  });

  const recoverTicket = () => {
    const nextEvent = lottoEvents.find(e => e.system_id === ticket.system_id);
    if (nextEvent) {
      const now = moment().format("YYYY-MM-DD HH:mm:ss");
      const tkDate = moment(Math.floor(ticket.event_time / 1e+6), "x").format("YYYY-MM-DD HH:mm:ss");
      const nextEvents = lottoEvents.filter(
        e => e && e.system_id === ticket.system_id && (e.event_date.localeCompare(tkDate) >= 0 && e.event_date.localeCompare(now) >= 0)
      );
      const viewNextEvents = nextEvents.splice(0, 5);
      const nextSelectedEvents = viewNextEvents.length ? [viewNextEvents[0].event_id] : [];

      let selectedEvents = nextSelectedEvents;
      let allSelectedEvents = nextSelectedEvents;
      let allSelectedEventsFull = viewNextEvents.length ? [viewNextEvents[0]] : [];
      let additionalEvents = 0;

      const ticketData = {
        event: nextEvent,
        numbers: ticket.numbers,
        selectedEvents: selectedEvents,
        systems: ticket.systems,
        additionalEvents: additionalEvents,
        allSelectedEvents: allSelectedEvents,
        allSelectedEventsFull: allSelectedEventsFull,
        src: "tickets"
      };

      let bData = ticket.bonus_user_data ? ticket.bonus_user_data : "[]";
      try {
        bData = JSON.parse(bData);
      } catch (err) {
        bData = [];
      }

      bData.forEach(b => {
        if (b.type === "lotto_lucky_number") {
          if (b.data) {
            let data = b.data ? b.data : "{}";
            try {
              data = JSON.parse(data);
            } catch (err) {
              data = {};
            }
            if (typeof data["number"] !== "undefined" && data["number"]) {
              ticketData.extra = { luckyNumber: data["number"] };
            }
          }
        }
      });

      addTicket(ticketData);
      window.location.hash = "";
      history.push(`/lotto/details/${nextEvent.system_id}/${nextEvent.event_id}`);
    }
  };
  const hasNextEVent = lottoEvents.find(e => e.system_id === ticket.system_id);
  let canRecover = lottoEvents && hasNextEVent ? true : false;

  let displayResults = null;
  if (results && results.length) {
    const line1 = [...Array(ticket.event_R > 10 ? 10 : ticket.event_R).keys()].map(i => {
      if (typeof results[i] !== "undefined") {
        let no = results[i];
        const rootCls = `${classes.ball} ${winner_numbers[no] ? classes.ballWinner : classes.ballLost}`;
        return <div key={i} className={rootCls}>{no}</div>;
      }
      return <div key={i} className={classes.ballEmpty}>-</div>;
    });
    let line2 = null;
    if (ticket.event_R > 10) {
      line2 = [...Array(ticket.event_R - 10).keys()].map(ix => {
        const i = ix + 10;
        if (typeof results[i] !== "undefined") {
          let no = results[i];
          const rootCls = `${classes.ball} ${winner_numbers[no] ? classes.ballWinner : classes.ballLost}`;
          return <div key={i} className={rootCls}>{no}</div>;
        }
        return <div key={i} className={classes.ballEmpty}>-</div>;
      });
    }
    if (showDetails) {
      displayResults = (
        <React.Fragment>
          <div className={classes.results}>
            <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>{line1}</div>
            {!!line2 && <div className={`${classes.balls} ${ticket.event_R - 10 < 10 ? classes.ballsFlexStart : ''}`}>{line2}</div>}
          </div>
          <div>
            <OddsTable ticket={ticket} />
          </div>
          <div className={classes.link} onClick={handleClick}>
            {t("Hide details")}
          </div>
        </React.Fragment>
      );
    } else {
      displayResults = (
        <div
          className={`${classes.listItem} d-flex flex-row align-items-start justify-content-between`}
        >
          <div className={classes.link} onClick={handleClick}>
            {t("Details")}
          </div>
          {canRecover && (
            <div>
              <div>
                <Button
                  onClick={recoverTicket}
                  className={`${classes.bigButton} ${classes.recoverButton}`}
                >
                  {t("Place again")}
                </Button>
                <div className={classes.subText}>
                  *{t("Place the same ticket for the next available event")}
                </div>
              </div>
            </div>
          )}
        </div>)
    }
  }

  const onClick = () => {
    evBus.emit("OPEN_TICKET_DETAILS", { event_name: ticket.event_name });
  };


  let lucky_number = 0;
  let lucky_number_won = false;
  let stake_is_free_bet = false;
  let stake_is_redeemable = false;
  let free_bet_name = "";
  let bonuses = [];

  if (ticket && ticket.bonus_data) {
    bonuses = [];
    let bonus_data = [];
    try {
      bonus_data = JSON.parse(ticket.bonus_data);
    } catch (err) { }

    bonus_data.forEach(b => {
      if (b && b.type === "lotto_lucky_number") {
        if (b.data) {
          let data = {};
          try {
            data = JSON.parse(b.data);
          } catch (err) { }

          if (data && typeof data.number !== "undefined" && ticket.numbers.indexOf(data.number) > -1) {
            lucky_number = data.number;
            bonuses.push("Lotto Lucky Number");
          }
        }
      } else if (b && b.type === "lotto_chance") {
        if (b.data) {
          let data = {};
          try {
            data = JSON.parse(b.data);
          } catch (err) { }

          if (data && typeof data.systems !== "undefined") {
            if (data.systems.indexOf(`${ticket.numbers.length}/${ticket.numbers.length}`) > -1) {
              bonuses.push("Lotto Chance");
            }
          }
        }
      } else if (b && b.type === "free_bet") {
        if (b.data) {
          let data = {};
          try {
            data = JSON.parse(b.data);
          } catch (err) { }

          if (data && typeof data.consumed !== "undefined" && data.consumed) {
            stake_is_free_bet = true;
            free_bet_name = data.name ? data.name : 'N/A';
            stake_is_redeemable = typeof data.redeemable !== "undefined" && data.redeemable === 1 ? true : false;
          }
        }
      }
    });
  }

  let free_bet_line = "";

  if (ticket && ticket.bonus_settle_data) {
    bonuses = [];
    let bonus_settle_data = [];
    try {
      bonus_settle_data = JSON.parse(ticket.bonus_settle_data);
    } catch (err) { }

    bonus_settle_data.forEach(b => {
      if (b && b.type === "lotto_lucky_number") {
        if (b.data) {
          let data = {};
          try {
            data = JSON.parse(b.data);
          } catch (err) { }

          if (data && typeof data.number !== "undefined" && ticket.numbers.indexOf(data.number) > -1 && lucky_number === data.number) {
            lucky_number_won = true;
            bonuses.push("Lotto Lucky Number");
          }

          if (data && typeof data.free_bet_token !== "undefined" && typeof data.free_bet_amount_small !== "undefined" && typeof data.free_bet_amount !== "undefined") {
            free_bet_line = `${data.free_bet_name ? data.free_bet_name : 'Name'}: ${data.free_bet_token * (data.free_bet_amount_small === 1 ? data.free_bet_amount / 100 : data.free_bet_amount)} Lei`;
          }
        }
      } else if (b && b.type === "lotto_chance") {
        bonuses.push("Lotto Chance");
        if (b.data) {
          let data = {};
          try {
            data = JSON.parse(b.data);
          } catch (err) { }

          if (data && typeof data.free_bet_token !== "undefined" && typeof data.free_bet_amount_small !== "undefined" && typeof data.free_bet_amount !== "undefined") {
            free_bet_line = `${data.free_bet_name ? data.free_bet_name : 'Name'}: ${data.free_bet_token * (data.free_bet_amount_small === 1 ? data.free_bet_amount / 100 : data.free_bet_amount)} Lei`;
          }
        }
      }
    });
  } else if (ticket && ticket.status !== "OPEN") {
    bonuses = [];
  }

  let amount = "0.00";
  if (ticket.status === "OPEN") {
    amount = ticket.max_winning - (stake_is_free_bet && !stake_is_redeemable ? ticket.amount : 0);
  } else if (ticket.status === "WON") {
    amount = ticket.gross_winning_amount - (stake_is_free_bet && !stake_is_redeemable ? ticket.amount : 0);
  } else if (ticket.status === "VOID") {
    amount = ticket.gross_winning_amount;
  } else if (ticket.status === "LOST") {
    amount = "0.00";
  }

  //console.log("lucky_number", lucky_number, "lucky_number_won", lucky_number_won, "ticket", ticket);

  return (
    <div className={rootCls} onClick={status === "OPEN" ? onClick : noop}>
      <div className={classes.eventName}>
        <div>{ticket.event_name} #{ticket.event_code}</div>
        <div>{ticket.serial} - {ticket_date}</div>
      </div>
      <div className={classes.header}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>{t("LOTTO")}</div>
          <div className={classes.description}>&nbsp;{description}</div>
        </div>
        <div className={classes.ticketData}>
          {(status === "WON" || status === "VOID") && (
            <div className={classes.won}>
              <CheckIcon />
            </div>
          )}
          {status === "LOST" && <div className={classes.lost}>X</div>}
          <div className={`${classes.possibleWin} ${noArrow ? classes.mr0 : ''}`}>{free_bet_line !== "" && status === "LOST" ? free_bet_line : `${amount} Lei`}</div>
          {status === "OPEN" && !noArrow && <RightIcon className={classes.rightSvg} />}
        </div>
      </div>
      <div className={classes.hr}></div>
      <div className={classes.infoPanel}>
        <div className={classes.timer}>
          <EventTimer date={event_date} onLive={onLive} status={status} />
        </div>
        {bonuses.length !== 0 && <div className={classes.timer}>
          <div className={classes.muted}>{t("Bonus")}:</div>
          <div>{bonuses.map((b, i) => (<span key={`bonus_${i}`}>{b}<br /></span>))}</div>
        </div>}
        <div className={classes.stake}>
          <div className={classes.stakeHeader}>{t("Stake")}</div>
          <div className={classes.stakeAmount}><span className={classes.noWrap}>{ticket.amount} Lei</span> {stake_is_free_bet ? <span className={classes.noWrap}>({free_bet_name})</span> : ''}</div>
        </div>
      </div>
      <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>
        {[...Array(ticket.event_R > 10 ? 10 : ticket.event_R).keys()].map(i => {
          if (typeof numbers[i] !== "undefined") {
            const no = numbers[i];
            const winner = results && results.indexOf(no.n) > -1 ? true : false;
            const isLuckyNumber = ticket.status !== "WON" && no.n === lucky_number ? true : false;
            const isLuckyNumberWon = ticket.status !== "WON" && no.n === lucky_number && lucky_number_won ? true : false;
            const rootCls = `${classes.ball} ${winner ? classes.ballWinner : ''} ${isLuckyNumber ? classes.ballLuckyNumber : ''} ${isLuckyNumberWon ? classes.ballLuckyNumberWon : ''}`;

            return <div key={i} className={rootCls}>{no.n}</div>;
          }
          return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
        })}
      </div>
      {ticket.event_R > 10 && <div className={`${classes.balls} ${ticket.event_R < 10 ? classes.ballsFlexStart : ''}`}>
        {[...Array(ticket.event_R - 10).keys()].map(i => {
          const ix = i + 10;
          if (typeof numbers[ix] !== "undefined") {
            const no = numbers[ix];
            const winner = results && results.indexOf(no.n) > -1 ? true : false;
            const isLuckyNumber = ticket.status !== "WON" && no.n === lucky_number ? true : false;
            const isLuckyNumberWon = ticket.status !== "WON" && no.n === lucky_number && lucky_number_won ? true : false;
            const rootCls = `${classes.ball} ${winner ? classes.ballWinner : ''} ${isLuckyNumber ? classes.ballLuckyNumber : ''} ${isLuckyNumberWon ? classes.ballLuckyNumberWon : ''}`;

            return <div key={i} className={rootCls}>{no.n}</div>;
          }
          return <div key={i} className={`${classes.ball} ${classes.ballEmpty}`}>-</div>;
        })}
      </div>}
      {displayResults}
    </div>
  );
}

Ticket.propTypes = {
  ticket: PropTypes.object,
  className: PropTypes.string,
  onLive: PropTypes.func
};

Ticket.defaultProps = {
  ticket: {},
  className: "",
  onLive: () => { }
};

const mapStateToProps = (state, props) => {
  const lst = getLottoState(state);

  return {
    lottoEvents: lst.lottoEvents.items,
  };
};

const actionCreators = {
  addTicket: betsSlipAddLottoTicket,
};

export default connect(mapStateToProps, actionCreators)(withTranslation()(Ticket));