import React from 'react';
import { withTranslation } from "react-i18next";

const EntryPoint = ({ t }) => {

  return (
    <div className="n-page">
      <div className="error404">{t("Page Not Found")}</div>
    </div>
  );
}

export default withTranslation()(EntryPoint);